import actionTypes from 'store/operators/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.GET_ALL_OPERATOR:
        try {
          next({ type: actionTypes.GET_ALL_OPERATOR_LOADING });
          const response = await request.getAllOperator();
          next({
            type: actionTypes.GET_ALL_OPERATOR_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_OPERATOR_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ONE_OPERATOR:
        try {
          next({ type: actionTypes.GET_ONE_OPERATOR_LOADING });
          const response = await request.getOneOperator({
            ...action.payload,
          });

          next({
            type: actionTypes.GET_ONE_OPERATOR_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_OPERATOR_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_ONE_OPERATOR_ROLE:
          try {
            next({ type: actionTypes.GET_ONE_OPERATOR_ROLE_LOADING });
            const response = await request.addOneOperatorRole({
              ...action.payload,
            });
            next({
              type: actionTypes.GET_ONE_OPERATOR_ROLE_SUCCESS,
              payload: response.data.data,
            });
            store.dispatch(messageHandler.successMessage(''));
          } catch (error) {
            next({
              type: actionTypes.GET_ONE_OPERATOR_ROLE_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
          break;

      case actionTypes.UPDATE_RIGHT_OPERATOR:
        try {
          next({ type: actionTypes.UPDATE_RIGHT_OPERATOR_LOADING });
          await request.updateOperatorRights({
            ...action.payload,
          });
          next({
            type: actionTypes.UPDATE_RIGHT_OPERATOR_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_RIGHT_OPERATOR_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
