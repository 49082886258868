import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import {
  Divider,
  Grid,
  Header,
  Message,
  Segment,
  Table,
  Icon,
} from 'semantic-ui-react';
import theme from 'theme';

import { Step } from 'components/core';
import ButtonValidationModal from 'components/ButtonValidationModal';
import Button from 'components/Button';

import ButtonBack from 'components/ButtonBack/ButtonBack';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import ModalAddDocuments from './components/ModalAddDocuments/ModalAddDocuments';
import ModalAddCustomsDocuments from './components/ModalAddDocuments/ModalAddCustomsDocuments';
import ModalAddChed from './components/ModalAddChed/ModalAddChed';
import ModalCancelDeclaration from './components/ModalCancelDeclaration/ModalCancelDeclaration';
import ModalAddIntranetReference from './components/ModalAddIntranetReference/ModalAddIntranetReference';
import ModalEditDeclaration from './components/ModalEditDeclaration/ModalEditDeclaration';
import ModalEditTransit from './components/ModalEditTransit/ModalEditTransit';
import ModalEditAccises from './components/ModalEditAccises/ModalEditAccises';
import ModalChedDetails from './components/ModalChedDetails/ModalChedDetails';
import CustomsDocuments from './components/CustomsDocuments/CustomsDocuments';
import CustomsDeclarationChat from './components/CustomsDeclarationChat/CustomsDeclarationChat';
import CustomsDeclarationProgressBar from './components/CustomsDeclarationProgressBar/CustomsDeclarationProgressBar';
import { longDate, meanTime, onlyDate, shortDate, shortDateFR } from 'services/helper/dateFormater';
import { 
  AskForNotification, 
  ChangeStatus, 
  CoiTypes, 
  DriverPause, 
  DriverStatus, 
  FolderLink, 
  FolderWrapper, 
  KeyInfos, 
  Comment, 
  LinesComments, 
  ResponsiveWrapper, 
  Type, 
  Linked} from './customsDeclarationDetailsStyles';
import { paths } from 'routes/fiscal/kyanite';
import { paths as pathToClerk } from 'routes/customs/customsHelper';
import { paths as pathToCompany } from 'routes/companies';
import { paths as pathToCustoms } from 'routes/customs/declaration';
import { chedI, ServiceI } from 'interfaces/data/customsClerkInterfaces';
import ModalViewBillings from './components/ModalViewBillings/ModalViewBillings';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';
import ModalAddLines from './components/ModalAddLines/ModalAddLines';
import PartnersDocumentsToAdd from '../Components/PartnersDocumentsToAdd/PartnersDocumentsToAdd';
import PartnersList from '../Components/PartnersList/PartnersList';
import Documents from './components/Documents/Documents';
import { Link } from 'react-router-dom';
import TransitProgressBar from './components/CustomsDeclarationProgressBar/TransitProgressBar';
import ModalMailFollowers from './components/ModalMailFollowers/ModalMailFollowers';
import { formatPrice } from 'services/helper/priceFormater';


const CustomsDeclarationDetails = ({
  dispatch,
  match,
  history,
  declaration,
  companySelected,
  user,
  deleteDocumentLoading,
  addDocumentsLoading,
  downloadLoading,
  checkSetDone,
  declarationChat,
  chatLoading,
  updateDeclarationLoading,
  cdHasBillings,
  allChed,
  billings,
  company,
  companyLoading,
  detailsLoading,
  commentsSuccess,
  checkDelivery,
  contact,
  allCustomsDocuments,
  editCompany,
}) => {
  const colors = theme.getTheme().colors;
  const userAdminOrOp = user.operator || user.admin;
  const userFullName = `${user.lastName}-${user.firstName}`;
  const isSuperOperatorCompany = 49;
  const companyId = (userAdminOrOp || user.partnership) ? match.params.companyId : companySelected;
  const isOwner =  Number(match.params.companyId) === Number(companySelected);

  const [accisesPanel, setAccisesPanel]: any = useState(null);
  const [transitPanel, setTransitPanel]: any = useState(null);

  const [modalAddDocumentsStatus, setmodalAddDocumentsStatus] = useState(false);
  const [modalAddCustomsDocumentsStatus, setModalAddCustomsDocumentsStatus]: any = useState(false);
  const [modalAddChed, setmodalAddChed] = useState(false);
  const [modalAddLines, setmodalAddLines]: any = useState({open: false, data: []});
  const [modalCancelDeclaration, setmodalCancelDeclaration] = useState(false);

  const [modalEditDeclaration, setmodalEditDeclaration] = useState(false);
  const [modalEditTransit, setmodalEditTransit] = useState(false);
  const [modalEditAccises, setmodalEditAccises] = useState(false);

  const [followersMailModal, setAddFollowersMailModal]:any = useState(false)
  const [modalAddIntranetReference, setmodalAddIntranetReference] = useState(false);
  const [modalViewBillings, setModalViewBillings] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [newValue, setNewValue]: any = useState(null)

  const [selectedChed, setselectedChed] = useState(null);
  const [billingsList, setbillingList] = useState([]);
  const [load, setLoad] = useState(false);
  const [isFollow, setisFollow] = useState(false);
  const [docList, setDocsListAdd]: any = useState([]);
  const [docFullList, setDocsListRead]: any = useState([]);
  const [chatActive, setChatActive] = useState(null);
  const [chedActive, setChedActive] = useState(null);
  const [billingActive, setBillingActive] = useState(null);
  const [docsActive, setDocsActive] = useState(null);
  const [rightsComment, setRightsComment] = useState('');
  const [checkthisDelevery, setcheckDelivery] = useState(null);
  const [sharedDocuments, setSharedDocument] = useState([])
  const [filtered, setFiltered] = useState([])
  const [filteredLoaded, setFilteredLoaded] = useState(false)
  const [declarationState, setDeclarationState]: any = useState(null)
  const [atimer, setTimer]: any = useState(null)

  const timer = () => {
    setTimeout(() => {
      setTimer(true)
      dispatch(
        actions.customsDeclaration.getOne({
          params: { companyId, declarationId: match.params.declarationId },
        })
      );
    }, 3000);
  }

  useEffect(() => {
    dispatch(
      actions.customsDeclaration.getOne({
        params: { companyId, declarationId: match.params.declarationId },
      })
    );
    dispatch(
      actions.customsDeclaration.getOneHosted({
        params: { companyId, declarationId: match.params.declarationId },
      })
    );
    if(!isOwner && !userAdminOrOp){
      dispatch(
        actions.contact.getContactRights({
          params: { companyId, contactId: companySelected},
        })
      );
    }
    dispatch(
      actions.customsDeclaration.getAllChed({
        params: { companyId, declarationId: match.params.declarationId },
      })
    );
    dispatch(
      actions.customsDeclaration.getChat({
        params: { companyId, declarationId: match.params.declarationId },
      })
    );
    dispatch(actions.customsClerk.getBillings());
    dispatch(
      actions.customsClerk.getCDHasBillings({
        params: {
          customsDeclarationId: match.params.declarationId,
        },
      })
    );
    dispatch(
      actions.customsDeclaration.getConex({
        params: {
          companyId,
          declarationId: match.params.declarationId,
        },
      })
    );
    // dispatch(
    //   actions.customsDeclaration.checkConexDelivery({
    //     params: {
    //       companyId,
    //       declarationId: match.params.declarationId,
    //     },
    //   })
    // );
    dispatch(actions.customsDeclaration.getAllCategoriesDocuments({
      query: {
        type: 0
      }
    }));
    dispatch(
      actions.socket.subscribeUpdateOneCustomsDeclaration(
        companyId,
        match.params.declarationId
      )
    );
    dispatch(
      actions.socket.subscribeUpdateOneHostedCustomsDeclaration(
        companyId, 
        match.params.declarationId
        )
    );
    dispatch(
      actions.socket.subscribeUpdateCustomsDeclarationChat(
        companyId,
        match.params.declarationId
      )
    );
    dispatch( 
      actions.socket.subscribeRefreshCustomsDeclarationChed(
        companyId,
        match.params.declarationId
      )
    );
    return () => {
      dispatch(
        actions.customsDeclaration.reset([
          'declaration',
          'declarationChat',
          'checkConexDelivery',
          'checkDelivery',
          'checkSetDone',
          'checkConex',
          'categoriesDocuments',
          'company',
          'billings',
          'conex',
          'getcomments',
          'allChed',
          'chedFile',
          'error',
        ])
      );
      dispatch(
        actions.socket.unsubscribeUpdateOneCustomsDeclaration(
          companyId,
          match.params.declarationId
        )
      );
      dispatch(
        actions.socket.unsubscribeUpdateCustomsDeclarationChat(
          companyId,
          match.params.declarationId
        )
      );
      dispatch(
        actions.socket.unsubscribeRefreshCustomsDeclarationChed(
          companyId,
          match.params.declarationId
        )
      );
    }
  }, [dispatch, companyId, companySelected, isOwner, match.params.declarationId, userAdminOrOp]);

  useEffect(() => {
    if(declaration && declaration.intranetReference === null && atimer){
      setTimeout(() => {
        dispatch(
          actions.customsDeclaration.getOne({
            params: { companyId, declarationId: match.params.declarationId },
          })
        );
      }, 3000);
    }else{
      setTimer(null)
    }
  }, [declaration, atimer, companyId, dispatch, match.params.declarationId])

  useEffect(() => {
    if(declaration && userAdminOrOp){
      dispatch(
        actions.companyKeyInfo.checkKeyInfoSetDone({
          params: {
            companyId,
            customsId: match.params.declarationId,
            emitter: userFullName
          },
        }));
      dispatch(
        actions.customsDeclaration.reset([
          'checkSetDone',
          'error',
        ]));
    }

    switch(
      declaration && 
      declaration.CustomsDeclarationCategories.length>0 && 
      declaration.CustomsDeclarationCategories[0].name
      ){
      case 'accises':
        setAccisesPanel(true)
        break;
      case 'transit':
        setTransitPanel(true)
        break;

      default:
        setAccisesPanel(null)
        setTransitPanel(null)
    }

  }, [declaration, userFullName, dispatch, companyId, match.params.declarationId, userAdminOrOp]);


  const hideJpeg = (doc) => {
    const el = doc.name.split('.')
    const reg = /_barCode/;
    const match = el[0].match(reg)
    return (
      match || 
      (doc.CustomsDeclarationDocumentCategory_Id === 13 || 
      doc.CustomsDeclarationDocumentCategory_Id === 14))  ? true : false
  }


  const InternalDocs = (docName) => {
    return docName === 'Internal ASD' ? true : false
  }


  useEffect(() => {
    if(declaration && user){
      setisFollow(declaration.Users.find((e) => e.id === user.id))
      setDeclarationState(declaration.state)
      dispatch(
        actions.quickfixes.checkQuickproofExist({
          params: {
            companyId,
            clientReference: declaration.clientReference, 
            customsId: match.params.declarationId,
          },
        })
      );


      // build array with use status
      let sharedDocumentsArray: any = []
      let documentsArray: any = []
      declaration.CustomsDeclarationDocuments.length>0 && 
      declaration.CustomsDeclarationDocuments.forEach((document) => {
        if(document.type === 1){
          if(hideJpeg(document) === true && userAdminOrOp){
            sharedDocumentsArray.push(document)
          }else if(InternalDocs(document.CustomsDeclarationDocumentCategory.name) === true && userAdminOrOp){
            sharedDocumentsArray.push(document)
          }else if(InternalDocs(document.CustomsDeclarationDocumentCategory.name) === false && userAdminOrOp === false){
            sharedDocumentsArray.push(document)
          }else if(InternalDocs(document.CustomsDeclarationDocumentCategory.name) === false && userAdminOrOp){
            sharedDocumentsArray.push(document)
          }
        }else if(document.type === 2){
          documentsArray.push(document)
        }else if(hideJpeg(document) === true){
          if(hideJpeg(document) === true && userAdminOrOp){
            sharedDocumentsArray.push(document)
          }else if(hideJpeg(document) === false && userAdminOrOp === false){
            documentsArray.push(document)
          }
        }else{
          documentsArray.push(document)
        }
      })

      documentsArray.length>0 ? 
      setDocuments(documentsArray) : 
      setDocuments([]) 

      sharedDocumentsArray.length>0 ? 
        setSharedDocument(sharedDocumentsArray) : 
        setSharedDocument([]) 
    }

    return () => {
        dispatch(
          actions.customsDeclaration.reset([
          'checkDelivery'
          ])
        );
      }
    }, [declaration, user, companyId, dispatch, match.params.declarationId, userAdminOrOp])

  useEffect(() => {
    if (declarationChat) {
      dispatch(
        actions.customsDeclaration.readChat({
          params: { companyId, declarationId: match.params.declarationId },
        })
      );
    }
  }, [declarationChat, companyId, dispatch, match.params.declarationId]);

  // update ower permission list
  useEffect(() => {
    if(load && commentsSuccess){
      dispatch(
        actions.customsDeclaration.getOneHosted({
          params: { companyId: companyId, declarationId: match.params.declarationId },
        })
      );
      setLoad(false)
    }
  }, [match, commentsSuccess, companyId, dispatch, load]);



  useEffect(()=> {
    if(contact){
      let documentListAdd = []
      let documentListRead = []
          // if PARTNERS
          contact.documents && 
          contact.documents.length>0 && 
          contact.documents.forEach((right) => {    
              if(right.add === true){
                // add list
                documentListAdd.push(right)
              }
              if(right.read === true){
                // read list
                documentListRead.push(right)
              }
          })
          if(contact.ched.length>0){
            // if partner CHED perms exist
            setChedActive(contact.ched[0])
          }else{
            // default partner CHED perms
            setChedActive({add: true, read: true, delete: true})
          }
          // view billings activation
          if(contact.billing !== null){
            setBillingActive(contact.billing)
          }
          if(contact.comments !== null){
            setRightsComment(contact.comments)
          }
          // chat activation
          setChatActive(contact.chat)
          setDocsActive(true)
    
      setDocsListRead(documentListRead)
      setDocsListAdd(documentListAdd)
    }
  }, [company, companySelected, contact])

  useEffect(() => {
    if(checkDelivery){
      setcheckDelivery(checkDelivery)
    }

  }, [checkDelivery, allCustomsDocuments])


  useEffect(() => {
    // Add Billing List
    if(billings){
      billings.sort((prev, next) => {
        return prev.id - next.id
      })
      setbillingList(
        billings.map((e, index) => {
          return {
            key: index,
            value: {"category": e.category, "unitPrice": e.amount, "id": e.id},
            text: `${e.category} [${e.amount} €]`,
            }
          }
        )
      )
    }
  }, [billings])

  useEffect(() => {

     // Key infos
    let tab: any = []
    let acceptedKeyInfos: any = null
    let roles:any = []
    user.role && user.role.length>0 && user.role.forEach((e, index) => {
      roles[index] = e.role
    })

    // operators
    if(declaration && declaration.Company.CompanyKeyInfos.length>0 && roles.length>0){
      declaration.Company.CompanyKeyInfos.sort((a, b) => {
        return a.id - b.id
      })
      declaration.Company.CompanyKeyInfos.forEach((e, index) => {
          acceptedKeyInfos = e.group.find((role, index) => roles.includes(role))
          if(acceptedKeyInfos !== undefined){
            tab.push(declaration.Company.CompanyKeyInfos[index])
          }
      })
    // superOperator
    }else if(declaration && declaration.Company.CompanyKeyInfos.length>0){
      declaration.Company.CompanyKeyInfos.sort((a, b) => {
        return a.id - b.id
      })
      declaration.Company.CompanyKeyInfos.forEach((e) => { 
          tab.push(e)
      })
    }

    if(checkSetDone){
      let createIndex, findIndex
      checkSetDone.forEach((el) => {
        if(el.emitter.toString() === userFullName.toString()){
          createIndex = tab.find(v => v.id === el.KeyInfo_Id)
          findIndex = tab.indexOf(createIndex);
            tab.splice(findIndex, 1)
        }
      })
    }
    setFiltered(tab);
    setFilteredLoaded(true)

  }, [declaration, checkSetDone, user, userFullName])

  const handleFollow = (values, companyUsers) => {
    let mailFollowersTab = []         
    companyUsers.forEach((user, index) => {
      if(values.mailFollowers.find((e) => e === user.id)) {              
        mailFollowersTab.push( 
          {
            id: companyUsers[index].id, 
            name: `${companyUsers[index].firstName}-${companyUsers[index].lastName}`
          }
        )
      }
    })
    dispatch(
      actions.customsDeclaration.followDeclaration({
        params: {
          declarationId: match.params.declarationId,
          companyId,
        },
        data: {
          mailFollowers: mailFollowersTab.length ? mailFollowersTab : null
        }
      })
    );
    setAddFollowersMailModal(false)
  };
  
  const follow = () => {
    dispatch(
      actions.customsDeclaration.followDeclaration({
        params: {
          declarationId: match.params.declarationId,
          companyId,
        }
      })
    );
  };

  const unfollow = () => {
    dispatch(
      actions.customsDeclaration.unfollowDeclaration({
        params: {
          declarationId: match.params.declarationId,
          companyId,
        },
      })
    );
  };

  const updateStep = (step: number, categoryId: number) => {
    dispatch(
      actions.customsDeclaration.updateStep({
        params: {
          declarationId: match.params.declarationId,
          companyId: Number(companyId),
        },
        data: {
          step,
          categoryId,
        },
      })
    );
  };

  const cancelDeclaration = (values: { reason: string }): void => {
    dispatch(
      actions.customsDeclaration.cancelDeclaration({
        params: {
          declarationId: match.params.declarationId,
          companyId: Number(companyId),
        },
        data: {
          reason: values.reason,
        },
      })
    );
    setmodalCancelDeclaration(false);
  };

  const editTransit = (values: {
      preTaxInvoice: number,
      customsDutyRate: number,
      transitVatRate: number,
      accisesTaxAmount: number, 
      departure:string,
      arrival: string,
      startDate: string,
      endDate: string,
      estimatedTimeDeparture: string,
      customsOffice: number | string,
      purchaseNumber: string,
      transporter: any,
      transporterPhoneNumber: string,
      clientReference: string
  }): void => {
    dispatch(
      actions.customsDeclaration.updateTransit({
        params: {
          declarationId: match.params.declarationId,
          companyId: Number(companyId),
        },
        data: {
          preTaxInvoice: values.preTaxInvoice,
          customsDutyRate: values.customsDutyRate*0.01,
          transitVatRate: values.transitVatRate*0.01,
          accisesTaxAmount: values.accisesTaxAmount, 
          departure: values.departure,
          arrival: values.arrival,
          startDate: values.startDate,
          endDate: values.endDate,
          estimatedTimeDeparture: values.estimatedTimeDeparture,
          customsOffice: values.customsOffice === '' ? null : values.customsOffice,
          purchaseNumber: values.purchaseNumber,
          transporter: values.transporter,
          transporterPhoneNumber: values.transporterPhoneNumber,
          clientReference: values.clientReference
        },
      }))
      setmodalEditTransit(false);
  }

  const editAccises = (values: {
    clientReference: string,
    departure: string,
    arrival: string,
    startDate: string,
    endDate: string,
}): void => {
  dispatch(
    actions.customsDeclaration.updateAccises({
      params: {
        declarationId: match.params.declarationId,
        companyId: Number(companyId),
      },
      data: {
        clientReference: values.clientReference,
        departure: values.departure,
        arrival: values.arrival,
        startDate: values.startDate,
        endDate: values.endDate,
      },
    }))
    setmodalEditAccises(false);
}

  const editDeclaration = useCallback((values: {
    currentCompanyId: number;
    Company_Id: number;
    types: Array<string>;
    transporterId: number;
    recipientId: number;
    transporterPhoneNumber: string;
    clientReference: string;
    truckLicensePlate: string;
    truckOrigin: string;
    trailerLicensePlate: string;
    trailerOrigin: string;
    customsOffice: number;
    driver: any;
    fortyTwoRegime: boolean;
    indRepresentation: boolean;
    purchaseNumber: string;
    transporter: any;
    crossingStatus: string;
    customsTypes: any;
    estimatedDateArrival: string;
    estimatedTimeArrival: string;
    permissions: any;
    intranetRef: string;
  }): void => { 

  dispatch(
      actions.customsDeclaration.updateDeclaration({
        params: {
          declarationId: match.params.declarationId,
          companyId: Number(companyId),
        },
        data: {
          currentCompanyId: values.currentCompanyId,
          Company_Id: values.Company_Id,
          types: values.types,
          estimatedDateTimeArrival: new Date(
            `${values.estimatedDateArrival}T${values.estimatedTimeArrival}`
          ),
          transporterId: values.transporterId,
          recipientId: values.recipientId,
          transporterPhoneNumber: values.transporterPhoneNumber,
          clientReference: values.clientReference,
          truckLicensePlate: values.truckLicensePlate,
          truckOrigin: values.truckOrigin,
          trailerLicensePlate: values.trailerLicensePlate,
          trailerOrigin: values.trailerOrigin,
          customsOffice: values.customsOffice,
          driver: values.driver,
          fortyTwoRegime: values.fortyTwoRegime,
          indRepresentation: values.indRepresentation,
          purchaseNumber: values.purchaseNumber,
          transporter: values.transporter,
          crossingStatus: values.crossingStatus,
          permissions: values.permissions,
          intranetRef: values.intranetRef,
        },
      })
    );

  
      if(editCompany){
        window.location.replace(
          `${process.env.REACT_APP_API_URL}/customs/declaration/company/${values.Company_Id}/${match.params.declarationId}`
        )
      }
      setmodalEditDeclaration(false);
  }, [
    dispatch, 
    editCompany, 
    companyId, 
    match.params.declarationId
  ]);


  const addStep = (category: string) => {
    dispatch(
      actions.customsDeclaration.addStep({
        params: {
          declarationId: match.params.declarationId,
          companyId: Number(companyId),
        },
        data: {
          category,
        },
      })
    );
  };

  const addIntranetReference = (intranetReference: string) => {
    dispatch(
      actions.customsDeclaration.addIntranetReference({
        params: {
          declarationId: match.params.declarationId,
          companyId: Number(companyId),
        },
        data: {
          intranetReference,
        },
      })
    );
    setmodalAddIntranetReference(false);
  };


  const downloadDocuments = (valuesType) => {
    dispatch(
      actions.customsDeclaration.downloadDocuments({
        params: {
          declarationId: match.params.declarationId,
          companyId,
        },
        data: {
          downloadType: valuesType
        }
      })
    );
  };

  const handleShowDocument = (documentId: number) => {
    dispatch(
      actions.customsDeclaration.downloadDocument({
        params: {
          declarationId: match.params.declarationId,
          companyId,
          documentId,
        },
      })
    );
  };

  const handleDeleteDocument = (documentId: number) => {
    dispatch(
      actions.customsDeclaration.deleteDocument({
        params: {
          declarationId: match.params.declarationId,
          companyId,
          documentId,
        },
      })
    );
  };

  const handleAddDocuments = (values: {
    type: number;
    documents: Array<File>;
    documentCategories: Array<{
      id: number;
      reference: string | null;
    }>;
  }) => {
    dispatch(
      actions.customsDeclaration.addDocuments({
        params: {
          declarationId: match.params.declarationId,
          companyId: companyId,
        },
        data: {
          type: values.type,
          documents: values.documents,
          documentCategories: values.documentCategories,
        },
      })
    );
    setmodalAddDocumentsStatus(false);
    setModalAddCustomsDocumentsStatus(false);
  };

  const handleEditInvoiced = () => {
    dispatch(
      actions.customsDeclaration.updateDeclarationsInvoice({
        params: {
          declarationId: match.params.declarationId,
        },
      })
    );
    timer()
  };

  const handleSubmitMessage = (value: { message: string }) => {
    dispatch(
      actions.customsDeclaration.sendMessage({
        params: {
          declarationId: match.params.declarationId,
          companyId,
        },
        data: value,
      })
    );
  };

  const getNewValue = (e) => {
    setNewValue(e)
  }

  const handleSubmitChed = (values: chedI) => {
    if(values.file !== null){
      values.file = newValue
    }
    dispatch(
      actions.customsDeclaration.addChed({
        params: {
          companyId,
          declarationId: match.params.declarationId,
        },
        data: values,
      })
    );
    setmodalAddChed(false);
  };

  const showChedDetails = (chedId: number) => {
    setselectedChed(allChed.find((e) => e.id === chedId));
  };

  const handleReloadLogisticom = () => {
    dispatch(
      actions.customsDeclaration.reloadLogisticom({
        params: {
          companyId,
          declarationId: match.params.declarationId,
        },
      })
    );
  };

  const handleSendMail = () => {
    dispatch(
      actions.customsDeclaration.sendNoticeMail({
        params: {
          companyId,
          declarationId: match.params.declarationId,
        },
      })
    );
  };

  const closeCustom = () => {
    declaration.CustomsDeclarationCategories.map((category) => (
      !updateDeclarationLoading &&
      userAdminOrOp && updateStep(3, category.id)
    ))
  }

  const handleSubmitLines = (values: {
    action: 'Traces' | 'Douane';
    pricing: number;
    //cost: number;
    service: ServiceI;
    quantity: number;
    addTo: boolean;
    comments?: string;
  }) => {

    dispatch(
      actions.customsDeclaration.addLines({
        params: {
          companyId: companyId,
          declarationId: match.params.declarationId
        },
        data: {
          action: values.action,
          comments: values.comments,
          service: values.service, 
          //cost: values.cost,
          quantity: values.quantity,
          customsClerkFileId: cdHasBillings ? cdHasBillings.id : null,
          addTo: values.addTo
        },
      })
    );
    setmodalAddLines(false);
  };


  const handleUpdateLines = (values: {
    action: 'Traces' | 'Douane';
    id: number;
    pricing: number;
    cost: number;
    service: ServiceI;
    quantity: number;
    addTo: boolean;
    comments?: string;
  }) => {
    dispatch(
      actions.customsDeclaration.updateLines({
        params: {
          companyId: companyId,
          declarationId: match.params.declarationId,
        },
        data: {
          id: values.id,
          action: values.action,
          comments: values.comments,
          service: values.service, 
          quantity: values.quantity,
          cost: values.cost,
          customsClerkFileId: cdHasBillings ? cdHasBillings.id : null,
          addTo: values.addTo
        },
      })
    );
    setmodalAddLines(false);
  };

  const handleDeleteLine = (id) => {
    dispatch(
      actions.customsDeclaration.deleteLines({
        params: {
          companyId: companyId,
          declarationId: match.params.declarationId,
          id: id,
          fileId: cdHasBillings ? cdHasBillings.id : 'none'
        }
      })
    );
  }

  const downloadCheds = (declarationId) => {
    dispatch(actions.customsDeclaration.downloadCheds({
      params: {
        companyId: companyId,
        declarationId: declarationId,
      }
    }))
    return () => {
      dispatch(actions.customsDeclaration.reset(['error', 'chedFiles']))  
    }
  }


  const setKeyInfoDone = (customsId, Type_Id, id) => {
    dispatch(
      actions.companyKeyInfo.keyInfoSetDone({
        params: {
          companyId: companyId,
        },
        data: {
          customsId: customsId,
          emitter: userFullName,
          Type_Id: Type_Id,
          KeyInfo_Id: id
        }
      })
    );
  }

  // const updateFileStatus = (item: string, status:string) => {
  //   dispatch(
  //     actions.customsDeclaration.updateDeclaration({
  //       params: {
  //         declarationId: match.params.declarationId,
  //         companyId: Number(companyId),
  //       },
  //       data: JSON.stringify({
  //         [item]: status,
  //         files: true
  //       }),
  //     }))
  // }

  const askForNotification = (declaration) => {
    dispatch(actions.customsDeclaration.sendNotificationQueue({
      params: {
        uniqueId: declaration.CompanyDriver.Unique_Id, 
        declarationId: declaration.id,
        companyId: companyId,
        notificationType: 'Inspection'
      }
    }))
  }


  const formatTime = (value) => {
    return value.split('.')[0]
  }

  const buildAddress = (address) => {
    return `${address.charAt(0).toUpperCase()}${address.slice(1)}`
  }
 

  return (
    <Wrapper display='flex' justifyContent='center'>
      {modalViewBillings && (
        <ModalViewBillings
          open={modalViewBillings}
          cdHasBillings={cdHasBillings}
          customsDeclarationId={declaration.id}
          handleClose={() => setModalViewBillings(false)}
          dispatch={dispatch}
        />
      )}
      {followersMailModal && (
        <ModalMailFollowers
          open={followersMailModal}
          dispatch={dispatch}
          companyId={companyId}
          customsDeclarationId={declaration.id}
          data={declaration.mailFollowers}
          handleFollow={handleFollow}
          handleClose={() => setAddFollowersMailModal(false)}
       />
      )}
      {modalAddDocumentsStatus && (accisesPanel !== null || transitPanel !== null || true) && (
        <ModalAddDocuments
          open={modalAddDocumentsStatus}
          accises={accisesPanel}
          transit={transitPanel}
          handleClose={() => setmodalAddDocumentsStatus(false)}
          handleAddDocuments={handleAddDocuments}
          dispatch={dispatch}
        />
      )}
      {modalAddCustomsDocumentsStatus && (
        <ModalAddCustomsDocuments
          open={modalAddCustomsDocumentsStatus}
          handleClose={() => setModalAddCustomsDocumentsStatus(false)}
          handleAddDocuments={handleAddDocuments}
          dispatch={dispatch}
        />
      )}
      {modalAddChed && (
        <ModalAddChed
          open={modalAddChed}
          chedDetail={allChed}
          handleClose={() => setmodalAddChed(false)}
          handleAddChed={handleSubmitChed}
          getNewValue={getNewValue}
        />
      )}
      {/* {modalAccises && (
        <ModalAccises
          dispatch={dispatch}
          companyId={companyId}
          data={modalAccises}
          category={declaration}
          billingsList={billingsList}
          handleClose={() => setmodalAccises({open: false})}
          handleAddLines={handleSubmitLines}
          handleUpdateLines={handleUpdateLines}
        />
      )} */}
      {modalAddLines && (
        <ModalAddLines
          dispatch={dispatch}
          companyId={companyId}
          data={modalAddLines}
          category={declaration}
          billingsList={billingsList}
          handleClose={() => setmodalAddLines({open: false})}
          handleAddLines={handleSubmitLines}
          handleUpdateLines={handleUpdateLines}
        />
      )}
      {selectedChed && (
        <ModalChedDetails
          handleClose={() => setselectedChed(null)}
          chedDetails={selectedChed}
          chedData={allChed}
          dispatch={dispatch}
          companyId={companyId}
          canEdit={(!userAdminOrOp||!isOwner)?chedActive?.add:null}
          canDelete={(!userAdminOrOp||!isOwner)?chedActive?.delete:null}
          userAdminOrOp={userAdminOrOp}
          isAuthorized={userAdminOrOp||isOwner||(!user.operator && !user.admin)}
        />
      )}
      {modalCancelDeclaration && (
        <ModalCancelDeclaration
          open={modalCancelDeclaration}
          handleClose={() => setmodalCancelDeclaration(false)}
          handleCancelDeclaration={cancelDeclaration}
        />
      )}
      {modalAddIntranetReference && (
        <ModalAddIntranetReference
          open={modalAddIntranetReference}
          handleClose={() => setmodalAddIntranetReference(false)}
          handleAddIntranetReference={addIntranetReference}
        />
      )}
      {modalEditDeclaration && (
        <ModalEditDeclaration
          open={modalEditDeclaration}
          handleClose={() => setmodalEditDeclaration(false)}
          handleEditDeclaration={editDeclaration}
          dispatch={dispatch}
          userAdminOrOp={userAdminOrOp}
          partners={company}
          actualData={{
            Company_Id: declaration.Company_Id,
            recipientId: declaration.Recipient_Id,
            transporterPhoneNumber: declaration.transporterPhoneNumber,
            clientReference: declaration.clientReference,
            truckLicensePlate: declaration.truckLicensePlate,
            truckOrigin: declaration.truckOrigin,
            trailerLicensePlate: declaration.trailerLicensePlate,
            trailerOrigin: declaration.trailerOrigin,
            customsOffice: declaration.CustomsOffice_Id,
            customsTrade: declaration.customsTrade,
            driver: declaration.CompanyDriver,
            transporter: declaration.transporter,
            fortyTwoRegime: declaration.fortyTwoRegime,
            indRepresentation: declaration.indRepresentation,
            purchaseNumber: declaration.purchaseNumber,
            estimatedDateTimeArrival: declaration.estimatedDateTimeArrival,
            crossingStatus: declaration.crossingStatus,
            types: declaration.CustomsDeclarationCategories.map((e) => e.name),
            permissions: declaration.permissions,
            intranetRef: declaration.intranetReference
          }}
        />
      )}

      {modalEditTransit && (
        <ModalEditTransit
          open={modalEditTransit}
          handleClose={() => setmodalEditTransit(false)}
          handleEditTransit={editTransit}
          dispatch={dispatch}
          companyId={declaration.Company_Id}
          actualData={{
            transit: declaration.CustomsDeclarationTransit,
            transporter: declaration.transporter,
            customsOffice: declaration.CustomsOffice_Id,
            purchaseNumber: declaration.purchaseNumber,
            transporterPhoneNumber: declaration.transporterPhoneNumber,
            clientReference: declaration.clientReference,
            departure: declaration.departure,
            arrival: declaration.arrival,
          }}
        />
      )}
    
    {modalEditAccises && (
        <ModalEditAccises
          open={modalEditAccises}
          handleClose={() => setmodalEditAccises(false)}
          handleEditAccises={editAccises}
          dispatch={dispatch}
          companyId={declaration.Company_Id}
          actualData={{
            clientReference: declaration.clientReference || '',
            departure: declaration.departure,
            arrival: declaration.arrival,
            startDate: declaration.startDate ? declaration.startDate.split('T')[0] : '',
            endDate: declaration.endDate ? declaration.endDate.split('T')[0] : '',
          }}
        />
      )}
                
        <Wrapper height='100%' width='80%' overflow='auto'>
          <Divider hidden />
          <Wrapper display='flex'>
            <ButtonBack history={history}/>

          </Wrapper>
          <Divider hidden />       
            <Wrapper
              display='flex'
              justifyContent='center'
              height='100%'
              width='100%'
            >
              <Wrapper width='80%'>
              {((downloadLoading ||
              addDocumentsLoading ||
              deleteDocumentLoading ||
              companyLoading ||
              detailsLoading
              ) && <Loader />) || (
              <>
              {declaration !== null && (
                 <>
                {declaration.CustomsDeclarationCategories.map((category, i) => (
                  <Wrapper key={i}>
                    <p>{category.description} progress:</p>
                    {(category.name !== 'transit' && 
                    <CustomsDeclarationProgressBar
                      loading={updateDeclarationLoading}
                      onClick={
                        !updateDeclarationLoading &&
                        userAdminOrOp &&
                        ((step) => {
                          step !==
                            category.CustomsDeclarationHasCategories.step &&
                            updateStep(step, category.id);
                        })
                      }
                      maxStep={3}
                      actualStep={category.CustomsDeclarationHasCategories.step}
                    />) || 
                    <TransitProgressBar
                      loading={updateDeclarationLoading}
                      onClick={
                        !updateDeclarationLoading &&
                        userAdminOrOp &&
                        ((step) => {
                          step !==
                            category.CustomsDeclarationHasCategories.step &&
                            updateStep(step, category.id);
                        })
                      }
                      maxStep={3}
                      actualStep={category.CustomsDeclarationHasCategories.step}
                    />}
                    <Divider hidden />
                  </Wrapper>
                ))}

                {(accisesPanel && (
                <Segment>
                <Grid columns={2} divided padded>
                  <Grid.Column> 
                  <div><b>Accises: </b>{declaration.id}</div> 
                  <div><b>Created at: </b>{shortDate(new Date(declaration.createdAt))}</div> 
                  <div><b>Client reference: </b>{declaration.clientReference}</div> 
                </Grid.Column>
                <Grid.Column>               
                  <div><b>Country departure: </b>{declaration.departure}</div>                                                                            
                  <div><b>Country arrival: </b>{declaration.arrival}</div>  
                  <div><b>Period: </b>from {onlyDate(new Date(declaration.startDate))} to {onlyDate(new Date(declaration.endDate))}</div> 
                  {declaration.Users.length > 0 && (<>
                    <div>
                      <b>Followers: </b>
                      {declaration.Users.map((user, i) => (
                          <li
                            key={i}
                          >{`${user.lastName}-${user.firstName}`}</li>
                        ))}
                    </div>
                    </>)} 
                  <FolderWrapper>
                    <b>Intranet reference: </b>
                      {declaration.intranetReference && (
                      <FolderLink>
                        <Linked href={`${process.env.REACT_APP_INTRANET_DOUANE_URL}${declaration.intranetReference}`} className='folder_link_link'>
                          {declaration.intranetReference}</Linked>  
                          <Icon
                            className='customs-link-folder'
                            name='folder open'
                            size='small'
                            color='black'                    
                          />                             
                      </FolderLink> 
                      )}                                          
                  </FolderWrapper>                                
                </Grid.Column>
                </Grid>
                {
                  (declaration.state !== 'completed' || userAdminOrOp) &&
                  declaration.state !== 'cancelled' && (
                  <Button
                    icon='edit'
                    type='button'
                    size='small'
                    color='blue'
                    content='Edit data'
                    margin='10px 0 0 0'
                    onClick={() => accisesPanel && setmodalEditAccises(true)}
                />)}
                </Segment>
                )) || (
                <Segment>
                  <Grid columns={2} divided padded>
                    <Grid.Column>
                    {declaration.departure && (
                      <div><b>Departure: </b>{declaration.departure} - <b>Arrival: </b>{declaration.arrival}</div>
                    )}

                    {declaration.customsTrade && (
                      <div><b>Trade type: </b>{declaration.customsTrade}</div>
                    )}
                     

                    {declaration.fortyTwoRegime && (
                      <div><b>Regime: </b>42</div>
                    )}
                  
                    <FolderWrapper className='folder_wrapper'>
                      <b>Company: </b>
                      <FolderLink><Link to={pathToCompany.companyDetails.replace(':companyId', companyId)} className='folder_link_link'>{declaration.Company.name}</Link></FolderLink>
                      <Link to={pathToCustoms.customsDeclarationCompany.replace(':companyId', companyId)}>
                        <Icon
                          className='customs-link-folder'
                          name='folder open'
                          size='small'
                          color='black'                    
                        />
                      </Link>                         
                    </FolderWrapper>
                    {!declaration.CustomsDeclarationTransit && !declaration.CustomsDeclarationDeb && (
                      <>
                        <div><b>Truck license plate: </b>{declaration.truckLicensePlate}</div>
                        <div><b>Truck origin: </b>{declaration.truckOrigin}</div>
                        <div><b>Trailer license plate: </b>{declaration.trailerLicensePlate}</div>
                        <div><b>Trailer origin: </b>{declaration.trailerOrigin}</div>
                      </>
                    )}

                    {declaration.CustomsDeclarationTransit && (
                        <>
                        <div><b>Start of transit: </b>{shortDate(new Date(declaration.CustomsDeclarationTransit.startDate))}</div>
                        <div><b>Estimated Departure Time: </b>{formatTime(declaration.CustomsDeclarationTransit.estimatedTimeDeparture)}</div>
                        <div><b>End of transit: </b>{shortDate(new Date(declaration.CustomsDeclarationTransit.endDate))}</div>
                        <br></br>
                        <div><b>Customs surety bond: </b>{formatPrice(declaration.CustomsDeclarationTransit.customsSuretyBond)}</div>
                        <div><b>Customs Duty: </b>{formatPrice(declaration.CustomsDeclarationTransit.customsDuty)}</div>
                        <div><b>Transit Vat: </b>{formatPrice(declaration.CustomsDeclarationTransit.vatAmount)}</div>
                        <div><b>Accises Tax Amount: </b>{formatPrice(declaration.CustomsDeclarationTransit.accisesTaxAmount)}</div>
                      </>
                    )}

                    {(declaration.CompanyDriver && (
                    <div>                     
                      <b>Driver: </b>  
                      {declaration.crossingStatus && (
                        <li key={0}>Status : <DriverStatus>{declaration.crossingStatus}</DriverStatus></li>
                      )} 
                      {declaration.breaktime && (
                        <li key={1}>End of break : <DriverPause>{new Date(declaration.breaktime).toUTCString()}</DriverPause></li>
                      )}                    
                      {declaration.CompanyDriver.pseudo && (<>
                      <li key={2}>
                        Pseudo : {declaration.CompanyDriver.pseudo} 
                        {userAdminOrOp && !declaration.CustomsDeclarationTransit && (
                          <AskForNotification onClick={() => askForNotification(declaration)}>Send notification</AskForNotification>
                        )}
                      </li>
                      {declaration.transporter && (
                        <li key={3}>Transporter : {
                          declaration.transporter.name && 
                          declaration.transporter.address &&
                          declaration.transporter.address.city ? 
                          (`${declaration.transporter.name} - ${buildAddress(declaration.transporter.address.city)}`)  : declaration.Company.name}
                        </li>
                      )}
                      {declaration.destinationPort && (
                        <li key={4}><strong>Destination port :</strong> {declaration.destinationPort}</li>
                      )}
                      </>)}
                      {declaration.CompanyDriver.phone && (
                      <li key={5}>Phone : {declaration.CompanyDriver.code && (<>{declaration.CompanyDriver.code}</>)}
                      {declaration.CompanyDriver.phone}</li>
                      )}                                            
                      </div>)) || (declaration.transporterPhoneNumber && (
                        <div>
                          <b>Driver's phone number: </b>
                          {declaration.transporterPhoneNumber}
                        </div>
                      ))}
                       {declaration.CustomsDeclarationTransit && (
                        <div>
                        <b>Transporter :</b> {
                          declaration.transporter && 
                          declaration.transporter.name &&
                          declaration.transporter.address &&  
                          declaration.transporter.address.city ? 
                          (`${declaration.transporter.name} - ${buildAddress(declaration.transporter.address.city)}`)  : declaration.Company.name}
                        </div>
                      )}

                      {!declaration.CustomsDeclarationDeb && (
                        <div>
                          <b>Customs office: </b>
                          {declaration.CustomsOffice && declaration.CustomsOffice.name}
                        </div>
                      )}
                     

                      {declaration.estimatedDateTimeArrival && 
                      !declaration.CustomsDeclarationTransit && 
                      !declaration.CustomsDeclarationDeb && (
                        <>
                        <div>       
                          <b>Estimated date and time of arrival: </b>
                          {declaration.estimatedDateTimeArrival && declaration.meanTimeOffset && (`(fr) 
                            ${meanTime(new Date(declaration.estimatedDateTimeArrival))}`
                          )}
                          {declaration.estimatedDateTimeArrival && !declaration.meanTimeOffset && (`(fr) 
                            ${shortDateFR(new Date(declaration.estimatedDateTimeArrival))}`
                          )}
                        </div>
                        </>)
                      }
                     
                    </Grid.Column>
                    <Grid.Column>
                      <div>
                        <b>Id: </b>
                        {declaration.id}
                      </div>
                      {declaration.indRepresentation  && (
                          <div>
                        <b> Indirect Representation</b>
                        </div>
                      )}
                      <div>
                        <b>Created at: </b>
                        {longDate(new Date(declaration.createdAt))}
                      </div>
                      <div>
                        <b>Emitter: </b>
                        {declaration.emitter}
                      </div>
                      <div>
                        <b>Followers: </b>
                        {declaration.Users.length > 0 ? 
                          declaration.Users.map((user, i) => (
                              <li
                                key={i}
                              >{`${user.lastName}-${user.firstName}`}</li>
                            ))
                          : 'none'}
                      </div>
                      {!userAdminOrOp && declaration.mailFollowers && (
                        <>
                        <Divider hidden/>
                        <div>
                          <b>Users Followers: </b>
                          {declaration.mailFollowers.map((user, i) => (
                                <li
                                key={i}
                              >{`${user.name}`}</li>
                              ))
                            || 'none'}
                        </div>
                        </>
                      )}
                    
                      {declaration.clientReference && (                  
                        <div>
                          <b>Client reference: </b>
                          {declaration.clientReference === '' ? '' : declaration.clientReference}
                        </div>               
                      )}
                    
                      {userAdminOrOp && declaration.CustomsDeclarationTransit === null && (                                         
                        <FolderWrapper>
                          <b>Intranet reference: </b>
                          {declaration.intranetReference && (
                          <FolderLink>
                            <Linked href={`${process.env.REACT_APP_INTRANET_DOUANE_URL}${declaration.intranetReference}`} className='folder_link_link'>
                              {declaration.intranetReference}</Linked>  
                              <Icon
                                className='customs-link-folder'
                                name='folder open'
                                size='small'
                                color='black'                    
                              />                             
                          </FolderLink> 
                          )}                                          
                        </FolderWrapper>                                                                               
                      )}
                      {declaration.purchaseNumber && (
                        <div><b>PO: </b>{declaration.purchaseNumber}</div>
                      )}                     
                      {(userAdminOrOp || !isOwner) && (docList && docList.length > 0) && (
                        <PartnersDocumentsToAdd
                          docList={docList}
                          comments={rightsComment}
                        />
                      )}
                      {(userAdminOrOp || isOwner) && (docList.length === 0) && (                     
                         <PartnersList
                          userData={company.userData}
                          hostedUsers={company.hostedUsers}
                        />                   
                      )}
                    </Grid.Column>
                  </Grid>
                  {
                    (declaration.state !== 'completed' || userAdminOrOp) &&
                    declaration.state !== 'cancelled' && (
                      <Button
                        icon='edit'
                        type='button'
                        size='small'
                        color='blue'
                        content='Edit data'
                        margin='10px 0 0 0'
                        onClick={() => transitPanel ? setmodalEditTransit(true) : setmodalEditDeclaration(true)}
                      />
                    )}
                  </Segment>
                )}
                {userAdminOrOp && filtered.length>0 && filteredLoaded && (           
                  filtered.map((e, index) => (
                    <Message key={`mess-${index}`} color={e.priority === 2 ? 'yellow' :  e.priority === 1 ? 'blue' : 'red'}>
                    <KeyInfos key={`info-${index}`}><strong>{e.group.map((e, i) => i===0 ? e : `, ${e}`)}: </strong>{e.info}<br></br><strong>emitter: </strong>{e.emitter}
                      <ChangeStatus key={`status-${index}`}
                        onClick={() => {
                          setKeyInfoDone(declaration.id, e.Type_Id, e.id)
                        }}>x</ChangeStatus>
                    </KeyInfos></Message>
                  )) || (<Loader/>)                                                       
                )}       
                {userAdminOrOp  && declaration.CustomsDeclarationBillings.length>0 && (
                <Segment>
                <Grid columns={2} divided padded verticalAlign='middle'>
                    <Grid.Column width={6}>
                      <Button
                        icon='plus'
                        type='button'
                        size='small'
                        color='blue'
                        content='Add billing lines'
                        margin='5px'
                        onClick={() => setmodalAddLines({open: true})}
                      />
                    </Grid.Column>
                    <Grid.Column width={10}>
                    {declaration.CustomsDeclarationBillings.length>0 && (
                      declaration.CustomsDeclarationBillings.map((e, i) => (
                        <>
                        <Grid.Row key={`bill-${i}`}>
                        <Wrapper>
                          <Grid columns={5} verticalAlign='middle'>
                          <Grid.Column width={9}>
                            <div>
                              {e.action}<br></br>
                              <em>{e.description && e.description}</em></div>
                              <LinesComments>{e.comments}</LinesComments>
                          </Grid.Column>
                          <Grid.Column width={3}>{e.service}€ X {e.quantity}<br></br>{Number(e.service*e.quantity).toFixed(2)} €</Grid.Column>
                          <Grid.Column width={2}>
                            <Button
                              icon='edit'
                              type='button'
                              size='small'
                              color='blue'
                              margin='5px'
                              onClick={() => {
                                setmodalAddLines({open: true, data: e})
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column width={1}>
                            <Button
                              icon='delete'
                              type='button'
                              size='small'
                              color='blue'
                              margin='5px'
                              onClick={() => {
                                  handleDeleteLine(e.id)
                                }
                              }
                            />
                          </Grid.Column>
                          </Grid>
                          </Wrapper>
                        </Grid.Row>
                        </>
                        ))
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>
                )}
                <Divider hidden />
                {!declaration.errorMessage && (
                  <Grid centered>
                     {/* {
                    userAdminOrOp && 
                    declaration.CustomsDeclarationBillings.length === 0 && 
                    !transitPanel && (
                       <Button
                        icon='plus'
                        type='button'
                        size='large'
                        color='blue'
                        content='Define Accises billings'
                        margin='5px'
                        onClick={() => setmodalAccises({open: true})}
                      />
                    )}  */}
                    {
                    userAdminOrOp && 
                    declaration.CustomsDeclarationBillings.length === 0 && 
                    !accisesPanel && !transitPanel && (
                       <Button
                        icon='plus'
                        type='button'
                        size='large'
                        color='blue'
                        content='Add additional billing'
                        margin='5px'
                        onClick={() => setmodalAddLines({open: true})}
                      />
                    )} 
                    {declaration.CustomsDeclarationDocuments.length > 0 && (
                      <Button
                        icon='download'
                        type='button'
                        size='large'
                        color='blue'
                        content='Download all validated documents in one .zip'
                        margin='5px'
                        onClick={() => {
                          downloadDocuments('CHEDDAU')
                        }}
                      />
                    )}
                    {(billingActive || isOwner || userAdminOrOp) && cdHasBillings && (
                      <Button
                        icon='folder open'
                        type='button'
                        size='large'
                        color='blue'
                        content='Clerks billings'
                        margin='5px'
                        onClick={() => setModalViewBillings(true)} 
                      />
                    )}
                    {userAdminOrOp && (
                      <Button
                        icon='plus'
                        type='button'
                        size='large'
                        color='blue'
                        content='add intranet reference'
                        margin='5px'
                        onClick={() => setmodalAddIntranetReference(true)}
                      />
                    )}
                    {
                    userAdminOrOp && 
                    companyId && 
                    declaration.state === 'completed' && 
                    checkthisDelevery === null && 
                    !accisesPanel && !transitPanel && (
                      <LinkedButton 
                        icon='reply'
                        type='button' 
                        size='large'
                        margin='5px'
                        content='Quickproof Delivery'
                        path={`${paths.prefilledDeliveryOperator.replace(
                        ':companyId',
                        userAdminOrOp ? isSuperOperatorCompany : companyId
                        ).replace(':customsId', declaration.id)}`}/>
                    )}

                    {userAdminOrOp &&
                    checkthisDelevery && (
                      <LinkedButton 
                        icon='reply'
                        type='button' 
                        size='large'
                        margin='5px'
                        content='Delivery'
                        path={`${paths.deliveriesManageOperator.replace(
                        ':companyId',
                        userAdminOrOp ? isSuperOperatorCompany : companyId
                        ).replace(':deliveryId', checkthisDelevery.id)}`}/>
                    )}

                    {!userAdminOrOp && (
                      <Button
                        icon='mail'
                        type='button'
                        size='small'
                        color='blue'
                        content='Receive news feed by email'
                        margin='5px'
                        onClick={() => {
                          setAddFollowersMailModal(true)
                        }}
                      />
                    )}

                    {userAdminOrOp && 
                    declaration.state !== 'cancelled' && (
                      <Button
                        icon='user md'
                        type='button'
                        size='large'
                        color='blue'
                        content={isFollow ? 'Unfollow' : 'handleFollow'}
                        margin='5px'
                        onClick={isFollow ? unfollow : follow}
                      />
                    )}
                  {userAdminOrOp &&
                    declaration.CustomsDeclarationCategories.length === 1 &&
                    declaration.CustomsDeclarationCategories[0].type === 'customsClearance' &&
                      declaration.state !== 'cancelled' && (
                        <Button
                          icon='plus'
                          type='button'
                          size='large'
                          color='blue'
                          content='Add a customs control'
                          margin='5px'
                          onClick={() => addStep('customsClerk')}
                        />
                    )}

                  {userAdminOrOp && 
                    !declaration.invoiced && (
                      <ButtonValidationModal
                        key={`valid-1`}
                        trgrContent='Invoicing'
                        headerContent='Invoicing'
                        modalContent='Are you sure you want to validate the invoicing ?'
                        trgrColor='blue'
                        trgrIcon='money'
                        onClick={handleEditInvoiced}
                        margin='5px'
                        size='large'
                      />
                    )}

                  {userAdminOrOp &&
                    declaration.state !== 'cancelled' &&
                    declaration.CustomsDeclarationDocuments.find((e) => e.CustomsDeclarationDocumentCategory_Id === 4) && 
                    (
                      <ButtonValidationModal
                      key={`valid-2`}
                        trgrContent='Reload logisticom'
                        headerContent='Reload logisticom'
                        modalContent='By validating a new logisticom will be generated in Conex, a file with the correct "Packing list CSV" category is needed'
                        trgrColor='blue'
                        trgrIcon='truck'
                        onClick={handleReloadLogisticom}
                        margin='5px'
                        size='large'
                      />
                    )}

                  {userAdminOrOp &&
                    declaration.state !== 'cancelled' &&
                    declaration.CustomsDeclarationDocuments.find((e) => e.CustomsDeclarationDocumentCategory_Id === 8) && (
                        <ButtonValidationModal
                          key={`valid-3`}
                          trgrContent='Send mail with notice'
                          headerContent='Send mail with notice'
                          modalContent='By validating you will send an email to the customer with the sanitary certificate notices'
                          trgrColor='blue'
                          trgrIcon='certificate'
                          onClick={handleSendMail}
                          margin='5px'
                          size='large'
                        />
                      )}

                  {userAdminOrOp && 
                    declaration.CustomsClerkFile && (
                      <Button
                        icon='external alternate'
                        type='button'
                        size='large'
                        content='Go to clerk file'
                        margin='5px'
                        onClick={() => {
                          window.open(
                            `${pathToClerk.customsHelperFileEdition.replace(
                              ':fileId',
                              declaration.CustomsClerkFile.id
                            )}`
                          );
                        }}
                      />
                    )}

                  {userAdminOrOp &&
                    declaration.state !== 'cancelled' &&
                    declaration.state !== 'completed' && (
                      <Button
                        icon='cancel'
                        type='button'
                        size='large'
                        color='red'
                        content='Cancel declaration'
                        margin='5px'
                        onClick={() => setmodalCancelDeclaration(true)}
                      />
                    )}

                  {userAdminOrOp && 
                    declaration.state !== 'completed' && 
                    !accisesPanel && 
                    (
                      <Button
                        type='button'
                        color='blue'
                        size='large'
                        margin='5px'
                        content='Close custom'
                        onClick={() => closeCustom()}
                      />
                    )}
                </Grid>
                )}

                {!declaration.errorMessage && (
                  <>
                    <Divider hidden />
                    <Divider />
                    {(docsActive || (isOwner || userAdminOrOp)) && (
                    <>
                    <ResponsiveWrapper>
                      <Wrapper margin='5px' width='100%'>                    
                        <Wrapper display='flex' justifyContent='space-between'>
                          <Header>Customer's documents</Header>
                          {declaration.state !== 'cancelled' && (
                            <Button
                              icon='plus'
                              type='button'
                              color='blue'
                              content='Add documents'
                              onClick={() => setmodalAddDocumentsStatus(true)}
                            />
                          )}
                        </Wrapper>                       
                        <Table striped columns='4' selectable>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>File name</Table.HeaderCell>
                              <Table.HeaderCell>Category</Table.HeaderCell>
                              <Table.HeaderCell>Date</Table.HeaderCell>
                              <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                          {
                            (documents.length===0 &&
                              <EmptyTable colNumber={4} />
                            ) || documents.map(
                                (document, index) => 
                                (                      
                                  (
                                    (document.type !== 1) && 
                                    docFullList.length !== 0 &&                                    
                                    docFullList.find((e) => Number(e.id) === document.CustomsDeclarationDocumentCategory_Id && (e.read === true))                                 
                                  ) && (
                                    <Documents
                                      docList={docList}
                                      document={document}
                                      declarationState={declaration.state !== 'completed'}
                                      index={index}
                                      handleShowDocument={handleShowDocument}
                                      handleDeleteDocument={handleDeleteDocument} 
                                    />
                                  )
                                ) || (
                                  (
                                    (document.type !== 1) && 
                                    docFullList.length === 0 && 
                                    !userAdminOrOp
                                  ) && (
                                    <Documents
                                      docList={docList}
                                      document={document}
                                      declarationState={declaration.state !== 'completed'}
                                      index={index}
                                      handleShowDocument={handleShowDocument}
                                      handleDeleteDocument={handleDeleteDocument} 
                                    />
                                  )
                                ) || (
                                  (
                                    (document.type !== 1) && 
                                    docFullList && 
                                    (userAdminOrOp || isOwner)                               
                                  ) && (
                                    <Documents
                                      docList={docList}
                                      document={document}
                                      declarationState={declaration.state !== 'completed'}
                                      index={index}
                                      handleShowDocument={handleShowDocument}
                                      handleDeleteDocument={handleDeleteDocument} 
                                    />
                                  )
                                )                               
                              )}
                          </Table.Body>
                        </Table>
                      </Wrapper>                  
                      {!chedActive && (userAdminOrOp || isOwner) && !accisesPanel && (
                      <Wrapper margin='5px' width='100%'>                    
                        <Wrapper display='flex' justifyContent='space-between'>                       
                        <Header>Sanitary documents</Header>
             
                        <Button
                          icon='download'
                          type='button'
                          color='blue'
                          content='Download all'
                          onClick={() => downloadCheds(declaration.id)}
                        />
                          {declaration.state !== 'cancelled' && (
                            <Button
                              icon='plus'
                              type='button'
                              color='blue'
                              content='Add documents'
                              onClick={() => setmodalAddChed(true)}
                            />
                          )}
                        </Wrapper>
                        <Table striped columns='6' selectable>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Number</Table.HeaderCell>
                              <Table.HeaderCell>Product</Table.HeaderCell>
                              <Table.HeaderCell>Lines</Table.HeaderCell>
                              <Table.HeaderCell>Types</Table.HeaderCell>
                              <Table.HeaderCell>COI</Table.HeaderCell>
                              <Table.HeaderCell>State</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                        <Table.Body>
                            {((!allChed || allChed.length === 0) && (
                              <EmptyTable colNumber={6} />
                            )) ||
                              allChed.map((ched, index) => (
                                <Table.Row
                                  key={index}
                                  className='cursor-pointer'
                                  onClick={() => {
                                    (userAdminOrOp || ched.state !== 'draft') && (showChedDetails(ched.id))
                                  }}
                                >
                                  <Table.Cell>
                                  <Type>{ched.type === 1 ? 'CHED' : ched.type === 2 ? 'Phytosanitary certificat' : ched.type === 3 ? 'Export health certificat' : null}</Type>
                                  {(userAdminOrOp || ched.state !== 'draft') && (ched.number)}
                                  </Table.Cell>
                                  <Table.Cell>{ched.productType}</Table.Cell>
                                  <Table.Cell>{(ched.countLines && (<div>{ched.countLines}</div>)) || (<div>Add lines</div>)}</Table.Cell>
                                  <Table.Cell>

                                    {ched.veto && ched.phyto
                                      ? 'veto/phyto'
                                      : ched.veto
                                      ? 'veto'
                                      : ched.phyto ? 'phyto' : null}
                                      
                                  </Table.Cell>
                                  <Table.Cell>
                                      {ched.coi === 'coiDouane' ? (<CoiTypes>Douane</CoiTypes>) :  ched.coi === 'coiSivep' ? (<CoiTypes>SIVEP</CoiTypes>): null}
                                      </Table.Cell>
                                  <Table.Cell>
                                    <Step
                                      label={ched.state}
                                      color={
                                        ched.state === 'validated'
                                          ? colors.validatedStep
                                          : ched.state === 'new'
                                          ? colors.finishedStep 
                                          : ched.state === 'cancelled' 
                                          ? colors.cancellededStep
                                          : colors.inProgressStep
                                      }
                                    />
                                  {(ched.state === 'replaced') && 
                                  (<Comment>by CHED: {ched.replaced}</Comment>)}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                          </Table.Body>
                        </Table>                                        
                      </Wrapper>
                      )} 
                      {chedActive && ( // partners
                      <Wrapper margin='5px' width='100%'>
                            <Wrapper display='flex' justifyContent='space-between'>                            
                              <Header>Sanitary documents</Header> 
                              <Button
                                icon='download'
                                type='button'
                                color='blue'
                                content='Download all'
                                onClick={() => downloadCheds(declaration.id)}
                              />                      
                              {declaration.state !== 'cancelled' && chedActive && (
                                <Button
                                  icon='plus'
                                  type='button'
                                  color='blue'
                                  content='Add documents'
                                  onClick={() => setmodalAddChed(true)}
                                />
                              )}
                            </Wrapper>
                            <Table striped columns='6' selectable>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Number</Table.HeaderCell>
                                  <Table.HeaderCell>Product</Table.HeaderCell>
                                  <Table.HeaderCell>Lines</Table.HeaderCell>
                                  <Table.HeaderCell>Types</Table.HeaderCell>
                                  <Table.HeaderCell>COI</Table.HeaderCell>
                                  <Table.HeaderCell>State</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {((!allChed || allChed.length === 0) && (
                                  <EmptyTable colNumber={6} />
                                )) ||
                                  allChed.map((ched, index) => (
                                    <Table.Row
                                      key={index}
                                      className='cursor-pointer'
                                      onClick={() => chedActive.read && ched.state !== 'draft' && showChedDetails(ched.id)}
                                    >
                                      <Table.Cell>
                                      <Type>{ched.type === 1 ? 'CHED' : ched.type === 2 ? 'Phytosanitary certificat' : ched.type === 3 ? 'Export health certificat' : null}</Type>
                                      {chedActive.read && ched.state !== 'draft' && (ched.number)}
                                      </Table.Cell>
                                      <Table.Cell>{ched.productType}</Table.Cell>
                                      <Table.Cell>{(ched.countLines && (<div>{ched.countLines}</div>)) || (<div>Add lines</div>)}</Table.Cell>
                                      <Table.Cell>
                                        {ched.veto && ched.phyto
                                          ? 'veto/phyto'
                                          : ched.veto
                                          ? 'veto'
                                          : 'phyto'} 
                                      </Table.Cell>
                                      <Table.Cell>
                                      {ched.coi === 'coiDouane' ? (<CoiTypes>Douane</CoiTypes>) :  ched.coi === 'coiSivep' ? (<CoiTypes>SIVEP</CoiTypes>): null}
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Step
                                          label={ched.state}
                                          color={
                                            ched.state === 'validated'
                                              ? colors.validatedStep
                                              : ched.state === 'new'
                                              ? colors.finishedStep
                                              : colors.inProgressStep
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                  ))}
                              </Table.Body>
                            </Table>
                          </Wrapper>
                    )}
                    </ResponsiveWrapper>
                    </>
                    )}
                    </>
                    )}
                    {declaration.errorMessage && (
                      <Message error>
                        <Message.Header>Error</Message.Header>
                        <Divider hidden />
                        {declaration.errorMessage}
                      </Message>
                    )}
                </>
              )}
              </>
              )}
              </Wrapper>
              <Wrapper width='40%' minHeight='767px' padding='0 0 0 20px'>
              {(userAdminOrOp || isOwner || chatActive) && (             
                  (chatLoading && <Loader />) || (
                    <CustomsDeclarationChat
                      messages={declarationChat}
                      isOwner={isOwner}
                      userRole={
                        user.operator
                          ? 'operator'
                          : !user.operator && !user.admin
                          ? 'customer'
                          : null
                      }
                      handleSubmitMessage={handleSubmitMessage}
                    />
                  )                        
              )} 
             
              <Divider hidden/>
              <Divider hidden/>

              {!accisesPanel && !transitPanel && (
              <>
              <Wrapper display='flex' justifyContent='space-between'>
                    <Header>Customs documents</Header>
                    {declarationState !== 'cancelled' &&  userAdminOrOp && (
                      <Button
                        icon='plus'
                        type='button'
                        color='blue'
                        content='Add documents'
                        onClick={() => setModalAddCustomsDocumentsStatus(true)}
                      />
                    )}
                  </Wrapper>
                  <Table striped columns='4' selectable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>File name</Table.HeaderCell>
                          <Table.HeaderCell>Category</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                          <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {(
                          sharedDocuments.length === 0 &&                               
                          (
                            <EmptyTable colNumber={4} />
                          )) ||
                          sharedDocuments.map(
                            (document, index) => (                                
                            <CustomsDocuments 
                              document={document}
                              key={index}
                              declarationState={declarationState !== 'completed'}
                              handleShowDocument={handleShowDocument}
                              handleDeleteDocument={handleDeleteDocument}
                            />                                 
                          ))}
                      </Table.Body>
                  </Table>
                  </>
              )}
            </Wrapper>                
            </Wrapper>     
        </Wrapper>  
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    contact: selectors.contact.getContactRightsSelector(state),
    companySelected: selectors.auth.companySelected(state),
    declaration: selectors.customsDeclaration.declarationSelector(state),
    company: selectors.customsDeclaration.getOneHostedSelector(state),
    companyLoading: selectors.customsDeclaration.getOneHostedLoadingSelector(state),
    allChed: selectors.customsDeclaration.allChedSelector(state),
    allLines: selectors.customsDeclaration.addLinesSelector(state),
    declarationChat: selectors.customsDeclaration.declarationChatSelector(state),
    chatLoading: selectors.customsDeclaration.declarationChatLoadingSelector(state),
    deleteDocumentLoading: selectors.customsDeclaration.deleteDocumentSelector(state),
    addDocumentsLoading: selectors.customsDeclaration.addDocumentsLoadingSelector(state),
    downloadLoading: selectors.customsDeclaration.downloadLoadingSelector(state),
    updateDeclarationLoading: selectors.customsDeclaration.updateDeclarationLoadingSelector(state),
    conex: selectors.customsDeclaration.getConexSelector(state),
    checkConexDelivery: selectors.customsDeclaration.checkConexDeliverySelector(state),
    comments: selectors.customsDeclaration.addPartnerCommentSelector(state),
    commentsSuccess: selectors.customsDeclaration.addPartnerCommentSuccessSelector(state),
    commentsLoading: selectors.customsDeclaration.addPartnerCommentLoadingSelector(state),
    getcomments: selectors.customsDeclaration.getPartnerCommentSelector(state),
    getcommentsSuccess: selectors.customsDeclaration.getPartnerCommentSuccessSelector(state),
    getcommentsLoading: selectors.customsDeclaration.getPartnerCommentLoadingSelector(state),
    detailsLoading: selectors.customsDeclaration.detailsLoadingSelector(state),
    categoriesDocuments: selectors.customsDeclaration.getAllCategoriesDocumentsSelector(state),
    categoriesDocListLoading: selectors.customsDeclaration.getAllCategoriesDocumentsLoadingSelector(state),
    cdHasBillings: selectors.customsClerk.cdBillingsSelector(state),
    billings: selectors.customsClerk.billingsSelector(state),
    checkDelivery: selectors.quickfixes.checkQuickproofExistSelector(state),
    checkSetDone: selectors.companyKeyInfo.checkKeyInfosSelector(state),
    checkSetDoneLoading: selectors.companyKeyInfo.checkKeyInfosLoadingSelector(state),
    editCompany: selectors.customsDeclaration.updateCompany(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(CustomsDeclarationDetails);
