import { orderBy } from 'lodash';
import { Field, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Divider,
  Form,
  Grid,
  Header,
  List,
  Modal,
  Transition,
} from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { FiscalPeriod, Input } from 'components/Form';
import { collectionbox } from 'interfaces/formSchema/collectionboxValidators';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import Dropzone from 'react-dropzone';
import { Aside, AsideWrapper, DocList, HeaderDocuments, Paragraph } from './styles';
import { DropP, DropWrapper } from 'views/Fiscal/Vat/Operator/New/newVatStyles';


const Index = ({
  dispatch,
  companyId,
  iso,
  open,
  userAdminOrOp,
  ownerId,
  match,
  handleClose,
  euCountries,
  obligations,
  obligationsLoading,
  intl,
  loadingHandlerData,
  categoriesDocuments,
  categoriesDocumentsLoading,
  categories,
  categoriesLoading,
  contacts,
  contactsLoading,
}: CollectionboxProps) => {

  const isFillingCountry = !iso ? false : iso.toUpperCase()
  const [countriesList, setcountriesList]: any = useState([]);
  const [contactList, setContactList]: any = useState(null);
  const [fileList, setFileList]: any = useState(null);
  const [none, setNode]: any = useState(true)
  const [acceptedFiles, setAcceptedFiles]: any = useState(null);
  const [categoriesList, setCategoriesList]: any = useState(null);
  const [documentCatList, setDocumentCatList]: any = useState(null);

  
  useEffect(() => {
      dispatch(actions.collectionbox.getCategories({
        params: {
          companyId: companyId
        }
      }));
      dispatch(actions.collectionbox.getObligations({
        params: {
          companyId: companyId
        }
      }));
  
      return () => {
        dispatch(
          actions.collectionbox.reset([
            'obligations',
            'categories',
            'error',
          ]));
      };

    
   
  }, [dispatch, companyId]);


  useEffect(() => {
    if(obligations){
        let tab = []
        obligations.forEach((o, index) => {
            tab.push({
              key: index,
              value: {iso: o.iso, periodicity: o.periodicity, Country_Id: o.Country_Id}, 
              text: 
              <DropWrapper>
                <div>{intl.formatMessage({id: `countries.name.${o.iso}`})}</div>
                <DropP>{o.periodicity}</DropP>
              </DropWrapper>,
              flag: o.iso.toLowerCase(),
            })
          
        })
      setcountriesList(tab)
    }

  }, [obligations, intl]);

  useEffect(() => {
    if(categories){
      const list = orderBy(categories, ['name', 'ASC'])
      setCategoriesList(list.map((e) => {
        return {
          key: e.id,
          value: {id: e.id, type: e.type},
          text: e.name
        }
      }))
    }
  }, [categories])

  useEffect(() => {
    if(contacts){
      setContactList(contacts.map((e, index) => {
        return {
          key: index,
          value: {id: e.id, name: e.name},
          text: e.name
        }
      }))
    }
  }, [contacts])


  useEffect(() => {
    if(categoriesDocuments){
      setDocumentCatList(categoriesDocuments.map((e) => {
        return {
          key: e.id,
          value: {id: e.id, type: e.type},
          text: e.name
        }
      }))
    }
  }, [categoriesDocuments])
 

  const createClientCollectionbox = async (values) => {
      values.documents[0].files = acceptedFiles[0].docs
      dispatch(
        actions.collectionbox.createClientCollectionbox({
          params: {
            companyId: companyId,
          },
          data: {
            ownerId: ownerId,
            type: values.type.id,
            taxPeriod: values.taxPeriod,
            country: values.country,
            clientReference: values.clientReference, 
            documents: values.documents,
            documentCategories: values.documentCategories,
          },
        })
      )
    handleClose()
  }

  // const createNoneCollectionbox = (values) => {
  //   dispatch(
  //     actions.collectionbox.createNoneCollectionbox({
  //       params: {
  //         companyId: companyId,
  //       },
  //       data: {
  //         none: true,
  //         period: values.period,
  //         filingCountry: values.filingCountry,
  //         clientReference: values.clientReference, 
  //       },
  //     })
  //   )
  //   handleClose()
  // }
   

  const constfiles = (acceptedFiles, index) => {
    let obj = []
    obj.push({id: index, docs: acceptedFiles})
    setAcceptedFiles(obj)
    setFileList(
      acceptedFiles.map(file => (
        <DocList key={file.name}>
          {file.name} - {file.size} bytes
        </DocList>
      )))
  };

  
  return (
    <>
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content='New Collectionbox' />
      <Formik
        initialValues={{
          type: '',
          country: '',
          taxPeriod: '',
          clientReference: '', 
          documents: [
            {
              id: undefined,
              files: [],
            },
          ],
          contact: ''
        }}
        onSubmit={createClientCollectionbox}
        validationSchema={collectionbox}
        enableReinitialize={true}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
        }) => (
          <>
          <Modal.Content>
            <Form onSubmit={handleSubmit}>   
            <Grid>
            <Grid.Row>
            <Grid.Column>
              {/* <Field
                mandatory
                label='Néant / None'
                name='none'
                component={Checkbox}
                meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                }}
                onChange={() => {
                  setNode(!none)
                }}
              /> */}
            </Grid.Column>     
            </Grid.Row>
            <Grid.Row columns={2}>
            <Grid.Column> 
            <Field
              mandatory
              label='Vat declaration type'
              placeholder='Choose vat declaration type...'
              name='type'
              id='type'
              component={Dropdown}
              loading={categoriesLoading}
              options={categoriesList}
              meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
            onChange={(value) => {
              if(value){
                dispatch(actions.collectionbox.getDocsFromCat({
                  query: {
                    type: value.type
                  }
                }))
                return () => {
                  dispatch(actions.collectionbox.reset(['getDocsFromCat', 'error']))
                }
              }
            }}
            />
            </Grid.Column>
            {/* {!userAdminOrOp && (
              <Grid.Column>
              <Field
                label='Contacts'
                name='contact'
                placeholder='choose...'
                component={Dropdown}
                loading={contactsLoading}
                options={contactList}
                meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                }}
              />
              </Grid.Column>
            )} */}
            <Grid.Column>
              <Field
                mandatory
                label='Country'
                name='country'
                placeholder='choose...'
                component={Dropdown}
                loading={obligationsLoading}
                options={countriesList}
                meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                }}
              />
            </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
            <Grid.Column>
            <Field
              mandatory
              label='Tax Period'
              name='taxPeriod'
              component={FiscalPeriod}
              type='date'
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
            </Grid.Column> 
            {!isFillingCountry && (

              <Grid.Column>
                <Field
                  label='Client reference'
                  name='clientReference'
                  placeholder='REF5765...'
                  component={Input}
                  meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                  }}
                />
              </Grid.Column>
         
          )}    
         </Grid.Row>
          </Grid>            
            <FieldArray
              name='documents'
              render={() => (
                <Transition.Group
                  as={List}
                  duration={200}
                  size='medium'
                  verticalAlign='middle'
                >
                  <Grid columns='3'>
                    {values.documents.length > 0 &&
                      values.documents.map((document, index) => {
                        return (
                          <Grid.Row key={index}>
                            <Grid.Column width='5'>
                              <Field
                                label='Category'
                                name={`documents[${index}].id`}
                                arrayName='documents'
                                placeholder='Choose a Category'
                                component={Dropdown}
                                options={documentCatList}
                                loading={categoriesDocumentsLoading}
                                meta={{
                                  errors,
                                  touched,
                                  setFieldValue,
                                  setFieldTouched,
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column width='11'>
                            <HeaderDocuments>Documents {none && (<MandatoryFieldLabel>*</MandatoryFieldLabel>)}</HeaderDocuments>  
                            <Dropzone 
                              onDrop={acceptedFiles => constfiles(acceptedFiles, index)}>
                              {({getRootProps, getInputProps}) => (
                                <section>
                                  <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <Paragraph>Drag 'n' drop some files here, or click to select files</Paragraph>
                                  </div>
                                {fileList && (
                                  <AsideWrapper>
                                    <Aside>
                                      {fileList}                                  
                                    </Aside>
                                  </AsideWrapper>                                   
                                )}
                                </section>
                              )}
                            </Dropzone>
                            </Grid.Column>
                            <Divider hidden />
                          </Grid.Row>
                        );
                      })}
                  </Grid>
                </Transition.Group>
              )}
            />   
            <Divider hidden />
            <Button type='submit' content='Submit' />
            </Form>
          </Modal.Content>
          </>
        )}
      </Formik>
    </Modal>
    </>
  );
};

interface CollectionboxProps {
  match,
  userAdminOrOp,
  dispatch: (value: any) => void;
  companyId: number;
  ownerId: number;
  iso: string;
  open: boolean;
  handleClose: () => void;
  euCountries;
  intl;
  loadingHandlerData;
  categories;
  contacts;
  contactsLoading;
  categoriesDocuments;
  categoriesDocumentsLoading;
  categoriesLoading;
  obligationsLoading;
  obligations;
}


const mapStateToProps = (state) => {
  return {
    contacts: selectors.contact.contactsSelector(state),
    contactsLoading: selectors.contact.getContactsLoadingSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    categories: selectors.collectionbox.categoriesSelector(state),
    categoriesLoading: selectors.collectionbox.categoriesLoadingSelector(state),
    obligationsloading: selectors.collectionbox.obligationGetLoadingSelector(state),
    obligations: selectors.collectionbox.obligationGetSelector(state),
    categoriesDocuments: selectors.collectionbox.getDocsFromCatSelector(state),
    categoriesDocumentsLoading: selectors.collectionbox.getDocsFromCatLoadingSelector(state),
  };
  
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);


