import { Formiz, useForm } from '@formiz/core';
import React, { FunctionComponent } from 'react';
import { Header, Modal, Grid } from 'semantic-ui-react';

import Button from 'components/Button';
import splitDateTime from 'services/helper/splitDateTime';
import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';
import FormizTextAreaInput from 'components/FormizField/FormizTextAreaInput/FormizTextAreaInput';
import Spacer from 'components/core/Spacer/Spacer';
import { Wrapper } from 'components/core';
import { issueTimeI } from 'interfaces/data/customsClerkInterfaces';

type TimeModalProps = {
  open: boolean;
  handleSubmit: (values: any, id: number) => void;
  handleClose: () => void;
  edit: null | issueTimeI;
};

const TimeModal: FunctionComponent<TimeModalProps> = ({
  open,
  handleSubmit,
  handleClose,
  edit,
}) => {
  const timeForm = useForm();

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='time' content='Add an control time slot' />
      <Formiz
        initialValues={{
          startDate: splitDateTime(
            edit?.startingAt ? new Date(edit.startingAt) : new Date()
          ).date,
          startTime: splitDateTime(
            edit?.startingAt ? new Date(edit.startingAt) : new Date()
          ).time,
          ...(edit?.endingAt && {
            endDate: splitDateTime(new Date(edit.endingAt)).date,
            endTime: splitDateTime(new Date(edit.endingAt)).time,
          }),
          comment: edit?.comment,
        }}
        connect={timeForm}
        onValidSubmit={() => handleSubmit(timeForm.values, edit?.id)}
      >
        <form onSubmit={timeForm.submit} noValidate>
          <Wrapper margin='20px'>
            <Grid>
              <Grid.Row columns='2'>
                <Grid.Column>
                  <FormizSimpleInput
                    type='date'
                    name='startDate'
                    label='Control start date'
                    required='Start date required'
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormizSimpleInput
                    type='time'
                    name='startTime'
                    label='Control start time'
                    required='Start time is required'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='2'>
                <Grid.Column>
                  <FormizSimpleInput
                    type='date'
                    name='endDate'
                    label='Control end date'
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormizSimpleInput
                    type='time'
                    name='endTime'
                    label='Control end time'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='1'>
                <Grid.Column>
                  <FormizTextAreaInput name='comment' label='Comment' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Spacer height='20px' />
            <Button type='submit' content='Submit' />
          </Wrapper>
        </form>
      </Formiz>
    </Modal>
  );
};

export default TimeModal;
