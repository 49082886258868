import React from "react";
import { getIn, ErrorMessage } from "formik";

import { Wrapper, Input } from "./styles";
import { Error } from "components/Form";
import { Grid } from "semantic-ui-react";

const Index = ({
  meta,
  placeholder,
  type,
  field,
  disabled,
  label,
  margin,
  labelPosition,
  labelVerticalPosition,
  justifyContent
}: any) => {
  return (
    <Wrapper
      margin={margin}
      labelPosition={labelPosition}
      labelVerticalPosition={labelVerticalPosition}
      justifyContent={justifyContent}
    >
      <Grid.Column width="7">{label && <label>{label}</label>}</Grid.Column>
      <Grid.Column width="7">
        <Input
          placeholder={placeholder}
          name={field.name}
          type={type}
          value={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
          disabled={disabled}
          error={
            getIn(meta.touched, field.name) && getIn(meta.errors, field.name)
              ? true
              : false
          }
        />
        <ErrorMessage name={field.name} render={msg => <Error msg={msg} />} />
      </Grid.Column>
    </Wrapper>
  );
};

export default Index;
