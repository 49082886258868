import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getAllTransportersByTokenErrorSelector = (state) =>
  state.companyByToken.errors.getAllTransportersByToken;
const createTransporterByTokenErrorSelector = (state) =>
  state.companyByToken.errors.createTransporterByToken;
const getTransporterVatsErrorSelector = (state) =>
  state.companyByToken.errors.getTransporterVats;

const errorsSelector = createErrorsSelector([
  getAllTransportersByTokenErrorSelector,
  createTransporterByTokenErrorSelector,
  getTransporterVatsErrorSelector,
]);

// Loading
const getAllTransportersByTokenLoadingSelector = (state) =>
  state.companyByToken.loading.getAllTransportersByToken;
const createTransporterByTokenLoadingSelector = (state) =>
  state.companyByToken.loading.createTransporterByToken;
const getTransporterVatsLoadingSelector = (state) =>
  state.companyByToken.loading.getTransporterVats;

const loadingSelector = createLoadingSelector([
  getAllTransportersByTokenLoadingSelector,
  createTransporterByTokenLoadingSelector,
  getTransporterVatsLoadingSelector,
]);

// Success
const getAllTransportersByTokenSuccessSelector = (state) =>
  state.companyByToken.success.getAllTransportersByToken;
const createTransporterByTokenSuccessSelector = (state) =>
  state.companyByToken.success.createTransporterByToken;
const getTransporterVatsSuccessSelector = (state) =>
  state.companyByToken.success.getTransporterVats;

const successSelector = createSuccessSelector([
  getAllTransportersByTokenSuccessSelector,
  createTransporterByTokenSuccessSelector,
  getTransporterVatsSuccessSelector,
]);

const transportersByTokenSelector = (state) =>
  state.companyByToken.transportersByToken;
const transporterVatsSelector = (state) => state.companyByToken.transporterVats;

//Errors Handlers
const errorSelector = (state) => state.companyByToken.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  errorSelector,
  transportersByTokenSelector,
  transporterVatsSelector,
};
