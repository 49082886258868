import styled from "@emotion/styled";

export const ClipBoardW = styled.div`
  &&& {
    width: 200px;
    display: block;
    position: relative;
    margin: 0 20px 0 0;
    height: 40px;
    padding: 1rem 0.5rem;
    float: left;
    text-align: left;
    font-size: 12px;
    cursor: pointer;
    background: #f8f8f8;
    border: 1px solid #d4d4d5;
    border-radius: 3px;
  }
  &&&:hover {
    background: #ffffd0;
    border: 1px solid #dcdc00;
    color: #929200;
  }
  
  &&&:active .pointerHover {
    opacity: 1
  }
  `
export const Wrapper = styled.div(() => ({
  width: '130px',
  height: '20px',
  position: 'absolute',
  top: '50%',
  marginTop: '-9px',
  left: '10px',
  overflow: 'hidden',
}))

export const Pointer = styled.div(() => ({
  width: '30px',
  height: '34px',
  position: 'absolute',
  zIndex: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '0',
  right: '0px',
}))

export const PointerHover = styled.div(() => ({
  width: '120px',
  height: '30px',
  position: 'absolute',
  display: 'flex',
  opacity: 0,
  zIndex: 10,
  alignItems: 'center',
  justifyContent: 'center',
  top: '-43px',
  right: '0',
  color: 'white',
  borderRadius: '3px',
  background: 'rgb(0 0 0 / 78%)',
}))

export const ClickIcon = styled.div(() => ({
  width: '10000px',
  height: '20px',
  position: 'absolute',
  overflow: 'hidden',
}))

