import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  getAllOperator() {
    return {
      type: actionTypes.GET_ALL_OPERATOR,
    };
  },

  getOneOperator(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_OPERATOR,
      payload: data,
    };
  },

  addOneOperatorRole(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_OPERATOR_ROLE,
      payload: data,
    };
  },


  updateOperatorRights(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_RIGHT_OPERATOR,
      payload: data,
    };
  },

  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
};