import React from "react";
import { Message } from "semantic-ui-react";

const Index = ({ header, message, onDismiss = null }: any) => {
  return (
    <Message negative onDismiss={onDismiss}>
      <Message.Header>{header}</Message.Header>
      <p>{message}</p>
    </Message>
  );
};

export default Index;
