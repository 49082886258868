// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import DashboardButton from './dashboardButton';
import DashboardInformation from './dashboardInformation';

// Resources
import reload from '../../assets/images/logos/reload.png';
import add from '../../assets/images/logos/whiteAdd.png';

// Stylesheets
import './dashboard.css';

/**
 * @name dashboard.tsx
 *
 * @path /components/dashboard/dashboard.tsx
 *
 * @description Defines the structure of homepage's dashboard
 */
const Dashboard: React.FC = ({ user }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version.
  const { t } = useTranslation();

  const [missionInProgress, setMissionInProgress] = useState<string>();
  const [missionAwaitingDocuments, setMissionAwaitingDocuments] =
    useState<string>();
  const [inspectionInProgress, setInspectionInProgress] = useState<string>();
  const [missionAwaitingPayement, setMissionAwaitingPayement] =
    useState<string>();
  const [allMissions, setAllMissions] = useState<string>();
  const [inspectionAwaitingDocuments, setInspectionAwaitingDocuments] =
    useState<string>();
  const adminOrOperator = user.admin || user.operator;

  let companyId: number;

  async function checkIfUserHasOneCompany(userId: number) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/userHasOneCompany`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: userId }),
        }
      );

      const result = await response.json();

      return result.companyId;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  // Defines the action that must be executed when user performed a click on missions in progress button
  const handleClickMissionsInProgressButton = async () => {
    window.sessionStorage.setItem('missionFilter', 'missionsInProgress');
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/missions/` + companyId;
  };

  // Defines the action that must be executed when user performed a click on mission awaiting documents button
  const handleClickMissionsAwaitingDocumentsButton = async () => {
    window.sessionStorage.setItem('missionFilter', 'missionsAwaitingDocuments');
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/missions/` + companyId;
  };

  // Defines the action that must be executed when user performed a click on inspections in progress button
  const handleClickInspectionsInProgressButton = async () => {
    window.sessionStorage.setItem('missionFilter', 'inspectionsInProgress');
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/missions/` + companyId;
  };

  // Defines the action that must be executed when user performed a click on missions awaiting payement button
  const handleClickMissionsAwaitingPayementButton = async () => {
    window.sessionStorage.setItem('missionFilter', 'missionsAwaitingPayement');
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/missions/` + companyId;
  };

  // Defines the action that must be executed when user performed a click on all missions button
  const handleClickAllMissionsButton = async () => {
    window.sessionStorage.setItem('missionFilter', 'allMissions');
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/missions/` + companyId;
  };

  // Defines the action that must be executed when user performed a click on inspections awaiting documents
  const handleClickInspectionsAwaitingDocumentsButton = async () => {
    window.sessionStorage.setItem(
      'missionFilter',
      'inspectionsAwaitingDocuments'
    );
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/missions/` + companyId;
  };

  // Defines the action that must be executed when user performed a click on refresh button
  const handleClickRefreshButton = () => {
    window.location.reload();
  };

  // Defines the action that must be executed when user performed a click on add mission button
  const handleClickAddMissionButton = () => {
    window.location.href = `/spw/order`;
  };

  const getDashboardDetailsAsAdminOrOperator = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getDashboardDetailsAsAdminOrOperator`,
        {
          method: 'GET',
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setMissionInProgress(result.data.missionInProgress);
        setMissionAwaitingDocuments(result.data.missionAwaitingDocuments);
        setInspectionInProgress(result.data.inspectionInProgress);
        setMissionAwaitingPayement(result.data.missionAwaitingPayement);
        setAllMissions(result.data.allMissions);
        setInspectionAwaitingDocuments(result.data.inspectionAwaitingDocuments);

        return result;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const getDashboardDetails = async () => {
    try {
      companyId = await checkIfUserHasOneCompany(user.id);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getDashboardDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: companyId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setMissionInProgress(result.data.missionInProgress);
        setMissionAwaitingDocuments(result.data.missionAwaitingDocuments);
        setInspectionInProgress(result.data.inspectionInProgress);
        setMissionAwaitingPayement(result.data.missionAwaitingPayement);
        setAllMissions(result.data.allMissions);
        setInspectionAwaitingDocuments(result.data.inspectionAwaitingDocuments);

        return result;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  useEffect(() => {
    async function exec() {
      adminOrOperator
        ? await getDashboardDetailsAsAdminOrOperator()
        : await getDashboardDetails();
    }
    window.sessionStorage.setItem('missionFilter', 'allMissions');
    exec();
  }, []);

  return (
    <div className='dashboardContainer'>
      {/* <div className='dashboardContainerTitle'>
        <h2>{t('dashboardStrings.dashboard')}</h2>
      </div> */}
      <div className='dashboardContainerTop'>
        <DashboardInformation
          title={t('dashboardStrings.missionInProgress')}
          value={missionInProgress}
          informationAction={handleClickMissionsInProgressButton}
        />
        <DashboardInformation
          title={t('dashboardStrings.missionAwaitingDocuments')}
          value={missionAwaitingDocuments}
          informationAction={handleClickMissionsAwaitingDocumentsButton}
        />
        <DashboardInformation
          title={t('dashboardStrings.inspectionInProgress')}
          value={inspectionInProgress}
          informationAction={handleClickInspectionsInProgressButton}
        />
      </div>
      <div className='dashboardContainerBottom'>
        <DashboardInformation
          title={t('dashboardStrings.missionAwaitingPayement')}
          value={missionAwaitingPayement}
          informationAction={handleClickMissionsAwaitingPayementButton}
        />
        <DashboardInformation
          title={t('dashboardStrings.allMissions')}
          value={allMissions}
          informationAction={handleClickAllMissionsButton}
        />
        <DashboardInformation
          title={t('dashboardStrings.inspectionAwaitingDocuments')}
          value={inspectionAwaitingDocuments}
          informationAction={handleClickInspectionsAwaitingDocumentsButton}
        />
      </div>
      <div className='dashboardContainerButton'>
        <DashboardButton
          title={t('dashboardStrings.refresh')}
          logo={reload}
          buttonAction={handleClickRefreshButton}
        />
        <DashboardButton
          title={t('dashboardStrings.addMission')}
          logo={add}
          buttonAction={handleClickAddMissionButton}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Dashboard);
