import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { actions, selectors } from 'store';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import InputFlexCustom from 'components/QuickFixes/DeliveryForm/InputFlexCustom';
import Dropdown from 'components/Dropdown';
import { compose } from 'redux';

const Index = (props: buyerProps) => {

  const {
    errors, 
    dispatch, 
    loadingHandlerContact, 
    contacts, 
    userAdminOrOp, 
    setFieldValue, 
    setFieldTouched, 
    touched, 
    names, 
    noAddress, 
    disabled, 
    companyBuyerName,
    companyBuyerId,
    companyBuyerVat,
    companyId,
    value,
    getBuyerVatsValue
  } = props

  const [companiesId, setCompaniesId]: any = useState(null)
  const [vatId, setVatId]: any = useState(null)

  useEffect(() => {
    if(disabled === false){
      dispatch(
        actions.contact.getAllContacts({
          params: { companyId: companyId },
        })
      );
    }
    return () => {
      dispatch(actions.contact.reset(['contacts', 'error']));
    };
  }, [dispatch, disabled, companyId])

  useEffect(() => {
  if(contacts){
    setCompaniesId(contacts.map((e, index) => {
      return {
        key: index,
        value: e.id,
        text: e.name,
      }
    }))

    let tab: any = []
    contacts.forEach((el, i) => {
        if(el.Vats.length>0){ 
     
          if(el.Vats[0].Company_Id === companyBuyerId){
            el.Vats.forEach((vat, j) => {
              tab.push({
                key: j,
                value: parseInt(vat.id),
                text: vat.vatNumber,
              })
            })
          }
        }
      } 
    )
    tab.length>0 && setVatId(tab)

  }
  }, [contacts, companyBuyerId])

  const changeAllValues = (value) => {
      setVatId(null)
      contacts.forEach((e) => {
        if(value === e.id) {

          getBuyerVatsValue({
            vatId: e.Vats[0].id
          })

          setFieldValue(names.name, e.name)
          e.Addresses.length>0 ? setFieldValue(names.buyerAddressId, e.Addresses[0].id) : 
          setFieldValue(names.buyerAddressId, '') 
          e.Addresses.length>0 ? setFieldValue(names.addressCountry, e.Addresses[0].country) : 
            setFieldValue(names.addressCountry, '') 
          e.Addresses.length>0 ? setFieldValue(names.addressCity, e.Addresses[0].city) : 
            setFieldValue(names.addressCity, '')
          e.Addresses.length>0 ? setFieldValue(names.address1, e.Addresses[0].address1) : 
            setFieldValue(names.address1, '') 
          e.email ? setFieldValue(names.email, e.email) : 
            setFieldValue(names.email, '') 
          e.phoneNumber ? setFieldValue(names.phone, e.phoneNumber) : 
            setFieldValue(names.phone, '')  
          e.Vats.length>0 ? setFieldValue(names.vat, e.Vats[0].vatNumber) : 
            setFieldValue(names.vat, '')
        }
      })

      let tab: any = []
      contacts.forEach((el) => {
          if(el.Vats.length>0){  
            if(el.Vats[0].Company_Id === value){
              el.Vats.forEach((vat, j) => {
                tab.push({
                  key: j,
                  value: vat.id,
                  text: vat.vatNumber,
                })
              })
            }
          }
        } 
      )
      tab.length>0 && setVatId(tab)
  }


  return (
    <>
    {(userAdminOrOp && disabled === false && (
      <>
        <Field
          name={names.buyerAddressId}
          type='hidden'
          component={InputFlexCustom}
          meta={{ errors, touched }}
        />
        <Field
          placeholder={`${companyBuyerName}`}
          name={names.name}
          value={value}
          options={companiesId}
          component={Dropdown}
          loading={loadingHandlerContact}
          width='auto'
          margin='5px'
          meta={{errors, touched, setFieldValue, setFieldTouched}}
          onChange={(e) => {
            if(e){
              changeAllValues(e)
            }      
          }}
        />
        </>
      )) || ( 
        <Field
          label='Company'
          name={names.name}
          type='text'
          component={InputFlexCustom}
          disabled={disabled}
          meta={{ errors, touched }}
          margin='5px'
          columns='2'
          justifyContent='space-between'
          widthLabel='5'
          widthInput='11'
        />
      )}
      <Field
        label='Country'
        name={names.addressCountry}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        justifyContent='space-between'
        widthLabel='5'
        widthInput='11'
      />
      <Field
        label='City'
        name={names.addressCity}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        justifyContent='space-between'
        widthLabel='5'
        widthInput='11'
      />
      {!noAddress && (
        <Field
          label='Address'
          name={names.address1}
          type='text'
          component={InputFlexCustom}
          disabled={disabled}
          meta={{ errors, touched }}
          margin='5px'
          columns='2'
          justifyContent='space-between'
          widthLabel='5'
          widthInput='11'
        />
      )}
      <Field
        label='Email'
        name={names.email}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        justifyContent='space-between'
        widthLabel='5'
        widthInput='11'
      />
      <Field
        label='Phone'
        name={names.phone}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        justifyContent='space-between'
        widthLabel='5'
        widthInput='11'
      />
      {(userAdminOrOp && disabled === false && 
  
        <Field
          placeholder={`${vatId ? vatId[0].text : companyBuyerVat}`}
          name={names.vat}
          options={vatId}
          loading={vatId ? false: true}
          component={Dropdown}
          width='auto'
          margin='5px'
          meta={{errors, touched, setFieldValue, setFieldTouched}}
          onChange={(e) => {
            if(e){
              getBuyerVatsValue({
                vatId: e
              })
            }
          }}
        />
  
      ) || (
      <Field
        label='VAT'
        name={names.vat}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        justifyContent='space-between'
        widthLabel='5'
        widthInput='11'
      />
      )}
  </>
  );
};

export interface buyerProps {
  errors: any, 
  dispatch: any, 
  loadingHandlerContact: any, 
  contacts: any, 
  userAdminOrOp: any, 
  setFieldValue: any, 
  setFieldTouched: any, 
  touched: any, 
  names: any, 
  noAddress: any, 
  disabled: any, 
  companyBuyerName: any,
  companyBuyerId: any,
  companyBuyerVat: any,
  companyId: any,
  value: any,
  getBuyerVatsValue?: (data: any) => void;
}


const mapStateToProps = (state) => {
  return {
    contacts: selectors.contact.contactsSelector(state),
    loadingHandlerContact: selectors.contact.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
