import React, { FunctionComponent, useEffect, useState } from 'react';
import { Formiz, useForm } from '@formiz/core';
import { Header, Modal, Grid, Message } from 'semantic-ui-react';

import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';
import FormizCheckbox from 'components/FormizField/FormizCheckbox/FormizCheckbox';
import FormizFileInput from 'components/FormizField/FormizFileInput/FormizFileInput';
import FormizDropdownInput from 'components/FormizField/FormizDropdownInput/FormizDropdownInput';
import Button from 'components/Button';
import Spacer from 'components/core/Spacer/Spacer';
import ErrorHandler from 'components/ErrorHandler';
import { isNumber } from '@formiz/validations';
import { chedI } from 'interfaces/data/customsClerkInterfaces';
import FormizSimpleOutput from 'components/FormizField/FormizSimpleOutput/FormizSimpleOutput';
import styled from '@emotion/styled';

type ModalAddChedProps = {
  open: boolean;
  chedDetail?: chedI;
  chedData?: any;
  handleAddChed: (values: {
    number: string;
    type: any;
    state: 'draft' | 'new' | 'validated' | 'refused' | 'replaced';
    phyto: boolean;
    veto: boolean;
    coi: string;
    productNames: string;
    productType: string;
    weight: number;
    taxAmount: number;
    countLines: number;
    file: File;
    replacingNumber?: number;
  }) => void;
  handleClose: () => void;
  getNewValue?: (newValue) => void
};


const ModalAddChed: FunctionComponent<ModalAddChedProps> = ({
  open,
  chedDetail,
  chedData,
  handleAddChed,
  handleClose,
  getNewValue,
}) => {
  const addChedForm = useForm();
  const [calculatedPrice, setcalculatedPrice]: any = useState(0)
  const [price, setPrice]: any = useState(0)
  const [isOpen, setOpen]: any = useState(true)
  const [isChecked, setChecked]: any = useState(false)
  const [chedNumbers, setChedNumbers]: any = useState([])
  const [suffix, setSuffix]: any = useState(null)
  
  useEffect(() => {

    chedDetail?.coi && setChecked(chedDetail.coi)
  
  }, [chedDetail])

  
  const priceCalculation = (value) => {
    // value en tones
    setOpen(true)
    let base, final;
    if(value <= 5000){
      base = 30.49;
    }else if(value > 5000){
      base = (value * 0.00610);
    }
    final = base.toFixed(2)
    setcalculatedPrice(final)
    if(calculatedPrice){
      setPrice(calculatedPrice)
    }
  }


  useEffect(() => {
    if(calculatedPrice){
      setPrice(calculatedPrice)
    }else{
      if(chedDetail && chedDetail.weight){
        let base, final;
        if(chedDetail.weight <= 5000){
          base = 30.49;
        }else if(chedDetail.weight > 5000){
          base = (chedDetail.weight * 0.00610);
        }
        final = base.toFixed(2)
        setcalculatedPrice(final)
  
        setPrice(calculatedPrice)
      
      }
    }
  }, [calculatedPrice, isOpen, price, chedDetail])

  useEffect(() => {
    let filter: any = []
    let ched: any
    ched = (chedDetail && chedDetail.number !== undefined) ? chedData : chedDetail
    ched.forEach((e, i) => {
      if(e.number !== chedDetail.number){
        filter.push({
          key: Number(i+1), 
          text: `${e.number}`, 
          value: e.number
        })
      }
    })
    filter.length && chedNumbers.length === 0 && setChedNumbers(filter)
    chedDetail && setChecked(chedDetail.veto)
  }, [chedDetail, chedNumbers, chedData])

  const getSuffix = (e: any) => {
    // ched
    if(addChedForm.values.type.id === 1){
        // hide mrn jpeg
        const file = e.name.replace('.pdf', '')
        const newText = file.toString().split('.')[3] 
        const clean = newText.split(' ')
        const txtslice = clean[0].slice(-7)
        const first = txtslice.charAt(0)
        const newChed = Number(first) === 0 ? txtslice.slice(1) : txtslice

        setSuffix(newChed)
    }else if(addChedForm.values.type.id === 3){
      // Health
      const file = e.name.replace('.pdf', '')
      const newText = file.toString().split('.')[4]  
      const txtslice = newText.slice(-8)
      const first = txtslice.charAt(0)
      const newChed = Number(first) === 0 ? txtslice.slice(1) : txtslice

      setSuffix(newChed)
    }
}

// const getSuffixPhyto = (e) => {
//   // hide mrn jpeg
//   const file = e.name.replace('.pdf', '')
//   // new ched length format 6 / old 5
//   const newText = file.toString().split('.')[3] 
//   const clean = newText.split(' ')
//   const txtslice = clean[0].slice(-7)
//   const first = txtslice.charAt(0)
//   const newChed = Number(first) === 0 ? txtslice.slice(1) : txtslice
//   setSuffix(newChed)
// }


const Mandatory = styled.span(() => ({
  color: 'red'
}));

const CheckboxLabels = styled.div(() => ({
  margin: '10px 0 0 0 !important',
  padding: '0 10px !important',
  float: 'left',
  display: 'inline'
}));


  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header
        icon={`${chedDetail ? 'edit' : 'plus circle'}`}
        content={`${chedData ? 'Update' : 'New'} sanitary document`}
      />
      <Modal.Content>
        <Formiz
          connect={addChedForm}
          onValidSubmit={handleAddChed}
          initialValues={{
            type: chedDetail?.type ? { 
                id: chedDetail?.type, 
                name: chedDetail?.type === 2 ? 'Phytosanitary Certificat' : chedDetail?.type === 3 ? 'Export Health Certificat' : 'CHED' 
              } : {
                id: 1, 
                name: 'CHED'
              },
            number: chedDetail?.number,
            productNames: chedDetail?.productNames || '',
            productType: chedDetail?.productType || '',
            weight: chedDetail?.weight,
            countLines: chedDetail?.countLines,
            taxAmount: chedDetail?.taxAmount || price || '',
            state: chedDetail?.state || 'draft',
            phyto: chedDetail?.phyto || false,
            veto: chedDetail?.veto || false,
            coi: chedDetail?.coi || '',
            replacingNumber: chedDetail?.replacingNumber || null,
          }}
        >
          <form onSubmit={addChedForm.submit}>
            <Grid>
              <Grid.Row columns='3'>
              <Grid.Column>
              <FormizDropdownInput
                    name='type'
                    label='Type'
                    defaultValue={{id: 1, name: 'CHED'}}
                    required
                    options={[
                      {
                        key: 1,
                        value: {id: 1, name: 'CHED'},
                        text: 'CHED',
                      },
                      {
                        key: 2,
                        value: {id: 2, name: 'Phytosanitary Certificat'},
                        text: 'Phytosanitary Certificat',
                      },
                      {
                        key: 3,
                        value: {id: 3, name: 'Export Health Certificat'},
                        text: 'Export Health Certificat',
                      }
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='3'>
     
                  <Grid.Column>
                    <FormizSimpleInput
                      type='text'
                      name='number'
                      label={`${addChedForm.values.type ? addChedForm.values.type.name : 'Ched'} number`}
                      placeholder='Ched number'
                      suffix={suffix}
                      required='Number is required'
                      formatValue={(val) => (val || '').trim()}
                    />
                  </Grid.Column>
       

                <Grid.Column>
                <FormizSimpleInput
                    type='number'
                    name='weight'
                    label='Weight'
                    placeholder='weight'
                    required='Weight is required'
                    formatValue={(val) => val && parseFloat(val)}
                    validations={[
                      {
                        rule: isNumber(),
                        message: 'This is not a number',
                      },
                    ]}
                    onChange={(e) => priceCalculation(e)}
                  />
                </Grid.Column>
                <Grid.Column verticalAlign='middle'>
                  <FormizDropdownInput
                    name='state'
                    label='Status'
                    required
                    options={[
                      {
                        key: 1,
                        value: 'draft',
                        text: 'Draft',
                      },
                      {
                        key: 2,
                        value: 'new',
                        text: 'New',
                      },
                      {
                        key: 3,
                        value: 'validated',
                        text: 'Validated',
                      },
                      {
                        key: 4,
                        value: 'refused',
                        text: 'Refused',
                      },
                      {
                        key: 5,
                        value: 'replaced',
                        text: 'Replaced',
                      },
                      {
                        key: 6,
                        value: 'cancelled',
                        text: 'Cancelled',
                      },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='3'>
                <Grid.Column>
                  <FormizSimpleInput
                    name='productNames'
                    label='Products'
                    placeholder='Chicken...'
                    required='Product is required'
                    formatValue={(val) => (val || '').trim()}
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormizSimpleInput
                    name='productType'
                    label='Product type'
                    placeholder='Composite'
                    required='Product type is required'
                    formatValue={(val) => (val || '').trim()}
                  />
                </Grid.Column>
                {addChedForm.values.type && addChedForm.values.type.id === 1 && ( 
                <Grid.Column>
                  <FormizSimpleOutput
                    getData={price}
                    type='input'
                    label={`Tax amount ${price} € calculated estimation`}
                    name='taxAmount'
                    required='tax amount is required'
                    placeholder={parseInt(price) !== undefined ? parseInt(price) : ''}
                    value={price}
                    formatValue={(val) => val && parseFloat(val)}
                    validations={[
                      {
                        rule: isNumber(),
                        message: 'This is not a number',
                      },
                    ]}
                  />          
                </Grid.Column>
              )} 
                {addChedForm.values.type && addChedForm.values.type.id !== 1 && ( 
                <Grid.Column>
                <FormizSimpleInput
                  name='countLines'
                  type='number'
                  label={`${addChedForm.values.type.name} counted lines`}
                  placeholder='Number of lines'
                  required='Number of lines is required'
                  formatValue={(val) => val && parseFloat(val)}
                  validations={[
                    {
                      rule: isNumber(),
                      message: 'This is not a number',
                    },
                  ]}
                />
              </Grid.Column>
              )}
              </Grid.Row>
              <Grid.Row columns='3'>
              {addChedForm.values.type && addChedForm.values.type.id === 1 && ( 
                <Grid.Column>
                  <FormizSimpleInput
                    name='countLines'
                    type='number'
                    label={`${addChedForm.values.type.name} counted lines`}
                    placeholder='Number of lines'
                    required='Number of lines is required'
                    formatValue={(val) => val && parseFloat(val)}
                    validations={[
                      {
                        rule: isNumber(),
                        message: 'This is not a number',
                      },
                    ]}
                  />
                </Grid.Column>
              )}
              {(addChedForm.values.state === 'replaced' && 
                <Grid.Column>
                  <FormizDropdownInput
                    name='replacingNumber'
                    label='Replaced by:'
                    placeholder='Choose file...'
                    options={chedNumbers}
                  />
                </Grid.Column>
                ) || (<Grid.Column></Grid.Column>)} 
             
                <Grid.Column></Grid.Column>
              </Grid.Row>
              {addChedForm.values.type && addChedForm.values.type.id === 1 && (
              <>
              <CheckboxLabels>Ched type <Mandatory>*</Mandatory></CheckboxLabels>
              <Grid.Row columns='3'>
                <Grid.Column>
                  <FormizCheckbox name='veto' label='Veto' />
                </Grid.Column>
                <Grid.Column>
                  <FormizCheckbox
                    name='phyto'
                    label='Phyto'
                    validations={[
                      {
                        rule: (value) => addChedForm.values.veto || addChedForm.values.other || value,
                        deps: [addChedForm.values.veto, addChedForm.values.other],
                      },
                    ]}
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormizCheckbox
                    name='other'
                    label='Other'
                    validations={[
                      {
                        rule: (value) => addChedForm.values.veto || addChedForm.values.phyto || value,
                        deps: [addChedForm.values.veto, addChedForm.values.phyto],
                      },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>

              {addChedForm.isSubmitted &&
                !(addChedForm.values.veto || addChedForm.values.phyto) && (
                  <Grid.Row centered>
                    <ErrorHandler message='the phyto or veto type must be selected' />
                  </Grid.Row>
                )}
             
            
                                  
              <Grid.Row columns='2'>
                <Grid.Column>
                <FormizDropdownInput
                    name='coi'
                    label='COI'
                    placeholder='Choose COI' 
                    required
                    options={[
                      {
                        key: 1,
                        value: 'None',
                        text: 'None',
                      },
                      {
                        key: 2,
                        value: 'coiSivep',
                        text: 'COI Sivep',
                      },
                      {
                        key: 3,
                        value: 'coiDouane',
                        text: 'COI Douane',
                      }
                    ]}
                    onChange={(e) => {
                      setChecked(e)
                    }}
                  />
        
                </Grid.Column>
                <Grid.Column>

                {(isChecked || chedDetail?.coi) === 'coiSivep' && (
                  <Grid.Row centered>
                    <Message size='mini' info>Produits origine animale + produits alimentation animale. Contrôler si case 10 COI indique Calais ou CALAIS FR20001, sinon sera traité au pays de destination</Message>
                  </Grid.Row>
                )}

                {(isChecked || chedDetail?.coi) === 'coiDouane'  && (
                  <Grid.Row centered>
                     <Message size='mini' info>Produits phyto + produits CHED D. Contrôler si case 10 COI indique Calais Bureau FR000740 sinon sera traité au pays de destination</Message>
                  </Grid.Row>
                )}
               
                </Grid.Column>
               
              </Grid.Row>

              {addChedForm.isSubmitted &&
                (isChecked === '') && (
                  <Grid.Row centered>
                    <ErrorHandler message='One of the COI Sivep or Phyto or None must be selected' />
                  </Grid.Row>
                )}

              </>
              )}
              {(addChedForm.values.state === 'new' ||
                addChedForm.values.state === 'validated' || 
                addChedForm.values.state === 'refused') && (
                <Grid.Row columns='1'>
                  <Grid.Column>
                    <FormizFileInput
                      name='file'
                      label={`${addChedForm.values.type.name} file`}
                      acceptedFile={['application/pdf']}
                      required={
                        chedDetail?.s3Path ? false : 'Ched file is required'
                      }
                      getSuffix={getSuffix}
                      getNewValue={getNewValue}
                      mandates={false}
                   
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
           
            </Grid>
            <Spacer height='20px' />     
              <Button size='large' type='submit' content='Save' />
          </form>
        </Formiz>
      </Modal.Content>
    </Modal>
  );
};

export default ModalAddChed;
