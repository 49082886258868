import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

export const Wrapper = styled.div(({ margin }: any) => ({
  margin: "0"
}));

export const TextAreaStyled = styled(Form.TextArea)`
  &&& {
  }
`;
