import { selectors } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import {Buffer} from 'buffer'
import { IframeViewer } from './styles';


const CollectionboxIframe = ({companyId, documentId, fileLoading, file}) => {

  const [data, setData]: any = useState(null)

  useEffect(() => {
    if(file){
      const bufferReader = Buffer.from(file)
      bufferReader && setData(bufferReader)
    }
  }, [file])


  return (
    (data === null && fileLoading && <Loader />) || (
      <IframeViewer src={data || `${process.env.REACT_APP_DEV_API_URL}/api/v1/user/${companyId}/splitSingle/${documentId}/collectionbox`}></IframeViewer>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    file: selectors.collectionbox.getArchiveSelector(state),
    fileLoading: selectors.collectionbox.getArchiveLoadingSelector(state)
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(CollectionboxIframe);
