class DateTimeHelper {
  private formatDigit = (value) => {
    if (value < 10) {
      return `0${value}`;
    } else {
      return value;
    }
  };

  public formatDateTime = (dateTime: any) => {
    const format = (dateTime) => {
      const formatedDateTime = new Date(dateTime);

      return (
        `${this.formatDigit(formatedDateTime.getDate())}/${this.formatDigit(
          formatedDateTime.getMonth() + 1
        )}/${formatedDateTime.getFullYear()}` +
        '\xa0\xa0' +
        `${this.formatDigit(formatedDateTime.getHours())}:${this.formatDigit(
          formatedDateTime.getMinutes()
        )}`
      );
    };

    return format(dateTime);
  };

  public dateTimeToDate = (dateTime: string) => {
    const format = (dateTime) => {
      const formatedDateTime = new Date(dateTime);

      return `${formatedDateTime.getFullYear()}-${this.formatDigit(
        formatedDateTime.getMonth() + 1
      )}-${this.formatDigit(formatedDateTime.getDate())}`;
    };
    return format(dateTime);
  };

  public dateTimeToTime = (dateTime: string) => {
    const format = (dateTime) => {
      const formatedDateTime = new Date(dateTime);

      return `${this.formatDigit(
        formatedDateTime.getHours()
      )}:${this.formatDigit(formatedDateTime.getMinutes())}`;
    };
    return format(dateTime);
  };

  public concatDateTime = (date: any, time: any) => {
    const format = (date, time) => {
      const formatedDateTime = new Date(`${date}T${time}`);
      return formatedDateTime;
    };
    return format(date, time);
  };
}

export default DateTimeHelper;
