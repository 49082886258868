import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Header, Modal, Icon, Divider } from 'semantic-ui-react';
import { selectors } from 'store';
import Wrapper from 'components/core/Wrapper/Wrapper';
import SpanCustom from 'components/core/SpanCustom/Span';
import Spacer from 'components/core/Spacer/Spacer';

const Index = ({ open, handleClose, news}) => {
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Wrapper alignItems='center'>
        <Wrapper
          minWidth='25rem'
          margin='30px 0 0 0'
          display='flex'
          justifyContent='center'
        >
          <Header size='large'>Important Message</Header>
          <Icon name='warning circle' color='red' size='big' />
        </Wrapper>
        <Wrapper minWidth='25rem' margin='0 30px 30px 30px'>
          <Divider />
          <Spacer />
          <SpanCustom whiteSpace='pre-wrap'>{news?.children}</SpanCustom>
        </Wrapper>
      </Wrapper>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    services: selectors.service.servicesSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
