import styled from "@emotion/styled";
import { Form, Button } from "semantic-ui-react";

export const Wrapper = styled.div(({ margin }: any) => ({
  display: "block",
  margin: "5px",
  paddingLeft: "25rem",
  paddingRight: "25rem"
}));

export const AddressWrapper = styled.div(({ margin }: any) => ({
  display: "block",
  margin: "10px",
  paddingLeft: "7rem",
  paddingRight: "7rem"
}));

export const WrapperInline = styled.div(({ margin }: any) => ({
  margin: "0",
  display: "inline-block",
  paddingLeft: "25rem",
  paddingRight: "25rem"
}));

export const BtnWrapper = styled.div(({ margin }: any) => ({
  display: "flex",
  flexDirection: "row",
  margin: "0"
}));

export const FormGroupStyled = styled(Form.Group)`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`;

export const GoupButtonStyled = styled(Button.Group)`
  justify-content: center;
`;
