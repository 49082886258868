import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Form,
  Tab,
  Container,
  Header,
  Table,
  Modal,
  Flag,
} from 'semantic-ui-react';

import EmptyTable from 'components/EmptyTableCurve';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { VatList } from '../styles';
import TableSpacedRowsSmall from 'components/TableSpacedRowsSmall';
import { Formik, Field } from 'formik';
import { vatSchema } from 'interfaces/formSchema';
import { Input, Checkbox } from 'components/Form';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

const Index = ({
  company,
  dispatch,
  countries,
  errorHandlerData,
  loadingHandlerVat,
  intl,
}) => {
  const [countriesList, setcountriesList]: any = useState([]);
  const [addVatModal, setAddVatModal] = useState(false);

  useEffect(() => {
    dispatch(actions.vat.reset(['error']));
    dispatch(actions.data.getEuCountries());

    return () => {
      dispatch(actions.data.reset(['euCountries', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    if (countries !== null) { 
      setcountriesList(
        countries.map((country, index) => {
          return {
            key: index,
            value: country.country_code,
            text: intl.formatMessage({
              id: `countries.name.${country.country_code}`,
            }),
            flag: country.country_code === 'XI' ? 'gb' : country.country_code.toLowerCase(),
          };
        }),
      );
    }
  }, [countries]);

  const addVats = (values) => {
    dispatch(actions.vat.reset(['error']));
    dispatch(
      actions.vat.createVat({
        data: {
          country: values.country,
          vatNumber: values.vatNumber,
          isOss: values.isOss,
        },
        params: { companyId: company.id },
      })
    );

    setAddVatModal(false);
  };

  const handleDelete = (vatId) => {
    dispatch(actions.vat.reset(['error']));
    dispatch(
      actions.vat.deleteVat({
        params: { companyId: company.id, vatId: vatId },
      })
    );
  };

  const findCountryName = (countryCode) => {
    const countryName = countriesList.find(
      (country) => country.value === countryCode
    );

    return countryName && countryName.text;
  };

  return (
    <Tab.Pane
      loading={!company || loadingHandlerVat || countriesList.length === 0}
    >
      <Container>
        {addVatModal === true && (
          <Modal open={addVatModal} onClose={() => setAddVatModal(false)}>
            <Modal.Content>
              <Wrapper padding='2rem'>
                <Header>Add a new registration number:</Header>
                <Formik
                  initialValues={{ country: '', vatNumber: '', isOss: false }}
                  onSubmit={addVats}
                  validationSchema={vatSchema}
                >
                  {({
                    errors,
                    touched,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    values,
                  }: any) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group widths='equal'>
                        <Form.Field>
                          <Field
                            label='Country'
                            mandatory
                            placeholder='Choose country'
                            name={`country`}
                            options={countriesList}
                            component={Dropdown}
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                            uniqueError={errorHandlerData.includes(
                              'allCountries'
                            )}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Field
                            label='VAT'
                            mandatory
                            placeholder='VAT number:'
                            name={`vatNumber`}
                            type='text'
                            component={Input}
                            meta={{ errors, touched }}
                          />
                        </Form.Field>
                      </Form.Group>

                      <Form.Group widths='equal'>
                        <Form.Field>
                          <Field
                            label='OSS ?'
                            name={`isOss`}
                            type='text'
                            component={Checkbox}
                            checked={values.isOss === true}
                            onChange={() => {
                              if (values.isOss === true) {
                                setFieldValue('isOss', false);
                              } else {
                                setFieldValue('isOss', true);
                              }
                            }}
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                            margin='20px'
                          />
                        </Form.Field>
                      </Form.Group>
                      <Button
                        disabled={isSubmitting}
                        type='submit'
                        content={intl.formatMessage({
                          id: 'button.save',
                        })}
                        margin='20px'
                        position='center'
                        color='blue'
                      />
                    </Form>
                  )}
                </Formik>
              </Wrapper>
            </Modal.Content>
          </Modal>
        )}
        <Wrapper display='flex' padding='2rem 5rem 2rem'>
          <Button
            icon='plus'
            type='submit'
            content='Add'
            onClick={() => {
              setAddVatModal(true);
            }}
          />
        </Wrapper>

        {countriesList.length > 0 && (
          <Wrapper
            display='flex'
            justifyContent='center'
            padding='0 10rem 5rem'
          >
            <Wrapper display='inline-flex' margin='0 3rem 0 0' width='100%'>
              <VatList>
                <TableSpacedRowsSmall>
                  <Table.Body>
                    {(company.Vats.length === 0 && (
                      <EmptyTable colNumber={1} />
                    )) ||
                      company.Vats.map((vat, i) => {
                        return (
                          i % 2 === 0 && (
                            <Table.Row key={i}>
                              <Table.Cell>
                                <>
                                  <Flag name={vat.country.toLowerCase()} />
                                  {`${findCountryName(vat.country)}: `}
                                  <Wrapper display='inline' margin='0 0.5rem' />
                                  {` ${vat.vatNumber}`}
                                </>
                              </Table.Cell>
                              <Table.Cell>
                                <Wrapper display='inline' margin='0 0.5rem'>
                                  <strong>{` ${
                                    vat.isOss ? 'OSS' : ''
                                  }`}</strong>
                                </Wrapper>
                              </Table.Cell>
                              <Table.Cell>
                                <Wrapper
                                  display='flex'
                                  justifyContent='flex-end'
                                >
                                  <Button
                                    content='Delete'
                                    icon='delete'
                                    type='button'
                                    color='red'
                                    labelPosition='right'
                                    onClick={() => handleDelete(vat.id)}
                                    margin='5px'
                                  />
                                </Wrapper>
                              </Table.Cell>
                            </Table.Row>
                          )
                        );
                      })}
                  </Table.Body>
                </TableSpacedRowsSmall>
              </VatList>
            </Wrapper>
            <Wrapper display='inline-flex' width='100%'>
              <VatList>
                <TableSpacedRowsSmall>
                  <Table.Body>
                    {(company.Vats.length === 0 && (
                      <EmptyTable colNumber={1} />
                    )) ||
                      company.Vats.map((vat, i) => {
                        return (
                          i % 2 !== 0 && (
                            <Table.Row key={i}>
                              <Table.Cell>
                                <>
                                  <Flag name={vat.country.toLowerCase()} />
                                  {`${findCountryName(vat.country)}: `}
                                  <Wrapper display='inline' margin='0 0.5rem' />
                                  {` ${vat.vatNumber}`}
                                </>
                              </Table.Cell>
                              <Table.Cell>
                                <>
                                  <Wrapper display='inline' margin='0 0.5rem' />
                                  <strong>{` ${
                                    vat.isOss ? 'OSS' : ''
                                  }`}</strong>
                                </>
                              </Table.Cell>
                              <Table.Cell>
                                <Wrapper
                                  display='flex'
                                  justifyContent='flex-end'
                                >
                                  <Button
                                    content='Delete'
                                    icon='delete'
                                    type='button'
                                    color='red'
                                    labelPosition='right'
                                    onClick={() => handleDelete(vat.id)}
                                    margin='5px'
                                  />
                                </Wrapper>
                              </Table.Cell>
                            </Table.Row>
                          )
                        );
                      })}
                  </Table.Body>
                </TableSpacedRowsSmall>
              </VatList>
            </Wrapper>
          </Wrapper>
        )}
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.data.euCountriesSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    loadingHandlerVat: selectors.vat.loadingSelector(state),
    userPermissions: selectors.companyUser.userPermissionsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
