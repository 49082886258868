export default {
  startDeliveryOperator: '/fiscal/kyanite/:companyId/start-delivery',
  prefilledDeliveryOperator: '/fiscal/kyanite/:companyId/start-delivery/:customsId',
  massIntegrationsOperator:
    '/fiscal/kyanite/seller/:companyId/mass-integrations',
  massIntegrationOperator:
    '/fiscal/kyanite/seller/:companyId/mass-integrations/:massIntegrationId',
  deliveriesManageOperator: '/fiscal/kyanite/:companyId/:deliveryId',
  kyaniteOperator: '/fiscal/kyanite/:companyId',
};
