import { resetState } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    getAllOperator: null,
    getOneOperator: null,
    updateOperatorRights: null,
    addOneOperatorRole: null,
  },
  errors: {
    getAllOperator: null,
    getOneOperator: null,
    updateOperatorRights: null,
    addOneOperatorRole: null,
  },
  loading: {
    getAllOperator: false,
    getOneOperator: false,
    updateOperatorRights: false,
    addOneOperatorRole: false,
  },

  allOperator: null,
  oneOperator: null,
  addOneOperatorRole: null,

  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // GetAllOperator
    case actionTypes.GET_ALL_OPERATOR_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllOperator = true;
      });

    case actionTypes.GET_ALL_OPERATOR_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllOperator = null;
        draft.loading.getAllOperator = false;
        draft.success.getAllOperator = true;
        draft.allOperator = action.payload;
      });

    case actionTypes.GET_ALL_OPERATOR_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllOperator = true;
        draft.loading.getAllOperator = false;
        draft.success.getAllOperator = false;
        draft.error.includes('getOperator') && draft.error.push('getOperator');
      });

    // GetOneOperator
    case actionTypes.GET_ONE_OPERATOR_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneOperator = true;
      });

    case actionTypes.GET_ONE_OPERATOR_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneOperator = null;
        draft.loading.getOneOperator = false;
        draft.success.getOneOperator = true;
        draft.oneOperator = action.payload;
      });

    case actionTypes.GET_ONE_OPERATOR_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneOperator = true;
        draft.loading.getOneOperator = false;
        draft.success.getOneOperator = false;
        draft.error.includes('getOneOperator') &&
          draft.error.push('getOneOperator');
      });

    // addOneOperatorRole
    case actionTypes.GET_ONE_OPERATOR_ROLE_LOADING:
      return produce(state, (draft) => {
        draft.loading.addOneOperatorRole = true;
      });

    case actionTypes.GET_ONE_OPERATOR_ROLE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addOneOperatorRole = null;
        draft.loading.addOneOperatorRole = false;
        draft.success.addOneOperatorRole = true;
        draft.addOneOperatorRole = action.payload;
      });

    case actionTypes.GET_ONE_OPERATOR_ROLE_ERROR:
      return produce(state, (draft) => {
        draft.errors.addOneOperatorRole = true;
        draft.loading.addOneOperatorRole = false;
        draft.success.addOneOperatorRole = false;
        draft.error.includes('addOneOperatorRole') && 
        draft.error.push('addOneOperatorRole');
      });

    // updateOperatorRights
    case actionTypes.UPDATE_RIGHT_OPERATOR:
      return produce(state, (draft) => {
        draft.loading.updateOperatorRights = true;
      });

    case actionTypes.UPDATE_RIGHT_OPERATOR_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateOperatorRights = null;
        draft.loading.updateOperatorRights = false;
        draft.success.updateOperatorRights = true;
      });

    case actionTypes.UPDATE_RIGHT_OPERATOR_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateOperatorRights = true;
        draft.loading.updateOperatorRights = false;
        draft.success.updateOperatorRights = false;
        draft.error.includes('updateOperatorRights') &&
          draft.error.push('updateOperatorRights');
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
