import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Container,
  Divider,
  Form,
  Message,
  Modal,
  Table,
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import { actions, selectors } from 'store';
import JSZip from 'jszip';

import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import PhotoModal from './components/PhotoModal/photoModal';
import Thumbnail from './components/Thumbnail/Thumbnail';
import TimeModal from './components/TimeModal/TimeModal';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Input, TextArea, Checkbox } from 'components/Form';
import DateTimeHelper from '../../../../../helpers/DateTimeHelper/dateTimeHelper';
import TextCustom from 'components/core/TextCustom/TextCustom';
import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import { controlSchema } from 'interfaces/formSchema/customsClerk';
import { shortDate } from 'services/helper/dateFormater';
import EmptyTable from 'components/EmptyTable';
import { issueTimeI } from 'interfaces/data/customsClerkInterfaces';

const NewIssue = ({
  dispatch,
  match,
  editData,
  readOnly,
  picturesIssuesZip,
  loading,
  manageSteps,
  file,
  issueTimes,
  trailerLicensePlate,
  isEdit,
  user
}) => {
  const formikRef = useRef();
  const dateTimeHelper = new DateTimeHelper();

  const [newPhotoModal, setnewPhotoModal] = useState<boolean>(false);
  const [
    trailerLicensePlateModal,
    settrailerLicensePlateModal,
  ] = useState<boolean>(false);

  const [timeModal, setTimeModal] = useState<boolean>(false);
  const [timeEdit, setTimeEdit] = useState<null | issueTimeI>(null);
  const [unzippedPictures, setunzippedPictures] = useState([]);
  const [trailerLicensePlatePicture, settrailerLicensePlatePicture] = useState(null);
  const [imageDisplayed, setimageDisplayed] = useState(null);
  const [errorTrailerLicensePlateLeft, seterrorTrailerLicensePlateLeft] = useState(false);


  useEffect(() => {
    dispatch(
      actions.customsClerk.getIssueTimes({
        params: { fileId: match.params.fileId },
      })
    );

    dispatch(
      actions.customsClerk.downloadPicturesIssuesZIP({
        params: { fileId: match.params.fileId },
      })
    );

    dispatch(actions.socket.subscribeUpdateIssueTime(match.params.fileId));
    dispatch(actions.socket.subscribeUpdateIssuePicture(match.params.fileId));
    dispatch(actions.socket.subscribeUpdateTrailerLicensePlate(match.params.fileId));

    return () => {
      dispatch(actions.socket.unsubscribeUpdateIssueTime(match.params.fileId));
      dispatch(actions.socket.unsubscribeUpdateIssuePicture(match.params.fileId));
      dispatch(actions.socket.unsubscribeUpdateTrailerLicensePlate(match.params.fileId));
      dispatch(
        actions.customsClerk.reset(['issueTimes', 'picturesIssuesZip', 'error'])
      );
    };
  }, [dispatch, match.params.fileId]);

  useEffect(() => {
    if (editData) {
      if (file?.trailerLicensePlateS3Path) {
        dispatch(
          actions.customsClerk.getTrailerLicensePlate({
            params: {
              fileId: match.params.fileId,
            },
          })
        );
      }

      const ref: any = formikRef.current;
      ref.setFieldValue('nature', editData.nature || '');
      ref.setFieldValue('comment', editData.comment || '');
      ref.setFieldValue('documentaryControl', editData.documentaryControl);
      ref.setFieldValue(
        'documentaryControlAskedDocuments',
        editData.documentaryControlAskedDocuments || ''
      );
      ref.setFieldValue(
        'documentaryControlInstructionsGiven',
        editData.documentaryControlInstructionsGiven || ''
      );
      ref.setFieldValue('packagingSealBroken', editData.packagingSealBroken);
      ref.setFieldValue('physicalControl', editData.physicalControl);
      ref.setFieldValue(
        'physicalControlInstructionsGiven',
        editData.physicalControlInstructionsGiven || ''
      );
      ref.setFieldValue('reSealingNumber', editData.reSealingNumber || '');
      ref.setFieldValue('sampleTaken', editData.sampleTaken);
    }
    return () => {
      dispatch(
        actions.customsClerk.reset([
          'picturesIssuesZip',
          'trailerLicensePlate',
          'error',
        ])
      );
    };
  }, [editData, dispatch, file?.trailerLicensePlateS3Path, match.params.fileId]);

  useEffect(() => {
    if(picturesIssuesZip && picturesIssuesZip instanceof Blob){
      let zip = new JSZip();
      let pictureArr = [];

      zip.loadAsync(picturesIssuesZip).then((unzipped) => {
        const files = Object.keys(unzipped.files);
        if (files.length === 0) setunzippedPictures([]);

        for (let i = 0; i < files.length; i++) {
          zip
            .file(Object.keys(unzipped.files)[i])
            .async('blob')
            .then((blob) => {
              if (blob && blob instanceof Blob) {
                pictureArr.push({
                  name: Object.values(unzipped.files)[i].name,
                  file: blob,
                });
              }
            })
            .finally(() => {
              if (pictureArr.length === Object.keys(unzipped.files).length) {
                setunzippedPictures(pictureArr);
              }
            });
        }
      });
    }
  }, [picturesIssuesZip]);


  useEffect(() => {
    if (trailerLicensePlate && trailerLicensePlate instanceof Blob) {
      settrailerLicensePlatePicture(trailerLicensePlate);
      seterrorTrailerLicensePlateLeft(false);
    }
  }, [trailerLicensePlate]);

  const save = (values) => {
    if(!readOnly){
      dispatch(
        actions.customsClerk.updateIssue({
          params: {
            fileId: match.params.fileId,
          },
          data: {
            nature: values.nature,
            comment: values.comment,
            documentaryControl: values.documentaryControl,
            documentaryControlAskedDocuments:
              values.documentaryControlAskedDocuments,
            documentaryControlInstructionsGiven:
              values.documentaryControlInstructionsGiven,
            physicalControl: values.physicalControl,
            physicalControlInstructionsGiven:
              values.physicalControlInstructionsGiven,
            packagingSealBroken: values.packagingSealBroken,
            reSealingNumber: values.reSealingNumber,
            sampleTaken: values.sampleTaken,
          },
        })
      );

      manageSteps(3);
    }
  };

  const addTrailerLicensePlate = (values) => {
    dispatch(
      actions.customsClerk.addTrailerLicensePlate({
        params: {
          fileId: match.params.fileId,
        },
        data: {
          trailerLicensePlate: values.photo,
        },
      })
    );

    settrailerLicensePlateModal(false);
  };

  const deleteTrailerLicensePlate = () => {
    settrailerLicensePlatePicture(null);

    dispatch(
      actions.customsClerk.removeTrailerLicensePlate({
        params: {
          fileId: match.params.fileId,
        },
      })
    );
  };

  const addPicture = (values) => {
    dispatch(
      actions.customsClerk.addIssuePictures({
        params: {
          fileId: match.params.fileId,
        },
        data: {
          file: values.photo,
        },
      })
    );

    setnewPhotoModal(false);
  };

  const deletePicture = (pictureId: string) => {
    dispatch(
      actions.customsClerk.deleteIssuePicture({
        params: {
          fileId: match.params.fileId,
          issuePictureId: parseInt(pictureId.split('-')[0]),
        },
      })
    );
  };

  const addOrUpdateTime = (values, id: number) => {
    const data = {
      startingAt: dateTimeHelper.concatDateTime(
        values.startDate,
        values.startTime
      ),
      ...(values.endDate &&
        values.endTime && {
          endingAt: dateTimeHelper.concatDateTime(
            values.endDate,
            values.endTime
          ),
        }),
      comment: values.comment,
      CustomsOffice_Id: file.customsOffice,
      companyId: file.CustomsDeclaration.Company.id
    };

    if (id) {
      dispatch(
        actions.customsClerk.updateIssueTime({
          params: {
            fileId: match.params.fileId,
            issueTimeId: id,
          },
          data,
        })
      );
    } else {
      dispatch(
        actions.customsClerk.addIssueTime({
          params: {
            fileId: match.params.fileId,
          },
          data,
        })
      );
    }

    setTimeEdit(null);
    setTimeModal(false);
  };

  const removeTime = (id: number) => {
    setTimeEdit(null);

    dispatch(
      actions.customsClerk.removeIssueTime({
        params: {
          fileId: match.params.fileId,
          issueTimeId: id,
        },
      })
    );
  };

  return (
    <Wrapper padding='2rem'>
      <Container>
        <Divider hidden />
        {errorTrailerLicensePlateLeft && (
          <Message negative>
            <Message.Header>Error</Message.Header>
            <p>The trailer license plate photo is needed</p>
          </Message>
        )}
        {imageDisplayed && (
          <Modal
            open={imageDisplayed && true}
            onClose={() => {
              setimageDisplayed(null);
            }}
            closeIcon
          >
            <Wrapper padding='1rem'>
              <ImageCustom src={URL.createObjectURL(imageDisplayed)} />
            </Wrapper>
          </Modal>
        )}
        <TimeModal
          open={timeModal}
          handleClose={() => {
            setTimeModal(false);
            setTimeEdit(null);
          }}
          handleSubmit={addOrUpdateTime}
          edit={timeEdit}
        />
        <PhotoModal
          open={newPhotoModal}
          handleClose={() => setnewPhotoModal(false)}
          handleSubmit={addPicture}
        />
        <PhotoModal
          open={trailerLicensePlateModal}
          handleClose={() => settrailerLicensePlateModal(false)}
          handleSubmit={addTrailerLicensePlate}
        />
        <Formik
          initialValues={{
            nature: '',
            comment: '',
            documentaryControl: false,
            documentaryControlAskedDocuments: '',
            documentaryControlInstructionsGiven: '',
            physicalControl: false,
            physicalControlInstructionsGiven: '',
            packagingSealBroken: false,
            sampleTaken: false,
            reSealingNumber: '',
          }}
          ref={formikRef}
          onSubmit={save}
          validationSchema={controlSchema}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
          }) => (
            <Form onSubmit={handleSubmit} loading={loading}>
              <Form.Field>
                <Field
                  {...(readOnly && { disabled: true})}
                  mandatory
                  label='Control nature'
                  placeholder='Nature'
                  name='nature'
                  options={[
                    {
                      key: 0,
                      value: 'CI',
                      text: 'CI',
                    },
                    {
                      key: 1,
                      value: 'CP',
                      text: 'CP',
                    },
                    {
                      key: 2,
                      value: 'DOUANE',
                      text: 'DOUANE',
                    },
                  ]}
                  component={Dropdown}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Field
                  {...(readOnly && { disabled: true})}
                  label='Comment'
                  placeholder='Comment'
                  name='comment'
                  type='text'
                  component={TextArea}
                  meta={{ errors, touched }}
                />
              </Form.Field>
              <Divider hidden />
              {(trailerLicensePlatePicture &&
                trailerLicensePlatePicture.type !== 'application/json' && (
                  <Wrapper
                    display='flex'
                    justifyContent='space-around'
                    flexWrap='wrap'
                  >
                    <Thumbnail
                      file={trailerLicensePlatePicture}
                      canDelete={!readOnly}
                      deletePicture={deleteTrailerLicensePlate}
                      handleClick={() =>
                        setimageDisplayed(trailerLicensePlatePicture)
                      }
                    />
                  </Wrapper>
                )) || (
                  <>
                  <p>Don't forget Trailer licence plate before validation <span style={{color: 'red'}}>!</span></p>
                <Button
                  icon='plus'
                  type='button'
                  content='Add trailer license plate'
                  labelPosition='right'
                  onClick={() => {
                    settrailerLicensePlateModal(true);
                  }}
                />
                </>
              )}
              <Divider hidden />
              <Form.Field>
                <Field
                  {...(readOnly && { disabled: true})}
                  name='documentaryControl'
                  label='Documentary Control'
                  checked={true === values.documentaryControl}
                  onChange={() => {
                    values.documentaryControl === true
                      ? setFieldValue('documentaryControl', false)
                      : setFieldValue('documentaryControl', true);
                  }}
                  component={Checkbox}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Form.Field>
              {values.documentaryControl && (
                <>
                  <Form.Field>
                    <Field
                      {...(readOnly && { disabled: true })}
                      label='Documentary Control Asked Documents'
                      placeholder='Documentary Control Asked Documents'
                      name='documentaryControlAskedDocuments'
                      type='text'
                      component={Input}
                      meta={{ errors, touched }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Field
                      {...(readOnly && { disabled: true })}
                      label='Documentary Control Instructions Given'
                      placeholder='Documentary Control Instructions Given'
                      name='documentaryControlInstructionsGiven'
                      type='text'
                      component={TextArea}
                      meta={{ errors, touched }}
                    />
                  </Form.Field>
                </>
              )}
              <Divider hidden />
              <Form.Field>
                <Field
                  {...(readOnly && { disabled: true })}
                  name='physicalControl'
                  label={'Physical Control'}
                  checked={true === values.physicalControl}
                  onChange={() => {
                    values.physicalControl === true
                      ? setFieldValue('physicalControl', false)
                      : setFieldValue('physicalControl', true);
                  }}
                  component={Checkbox}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Form.Field>

              {values.physicalControl && (
                <>
                  <Wrapper width='100%' margin='0 0 0 0.5rem'>
                    <Divider hidden />
                    <Form.Field>
                      <Field
                        {...(readOnly && { disabled: true })}
                        label='Re sealing number'
                        placeholder='Re sealing number'
                        name='reSealingNumber'
                        type='text'
                        component={Input}
                        meta={{ errors, touched }}
                      />
                    </Form.Field>
                  </Wrapper>
                  <Divider hidden />
                  <Wrapper>
                    <Wrapper
                      display='flex'
                      margin='0 2rem 0 0'
                      justifyContent='space-around'
                    >
                      <Form.Field>
                        <Field
                          {...(readOnly && { disabled: true })}
                          name='packagingSealBroken'
                          label='Packaging Seal Broken'
                          checked={true === values.packagingSealBroken}
                          onChange={() => {
                            values.packagingSealBroken === true
                              ? setFieldValue('packagingSealBroken', false)
                              : setFieldValue('packagingSealBroken', true);
                          }}
                          component={Checkbox}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Field
                          {...(readOnly && { disabled: true })}
                          name='sampleTaken'
                          label='Sample Taken'
                          checked={true === values.sampleTaken}
                          onChange={() => {
                            values.sampleTaken === true
                              ? setFieldValue('sampleTaken', false)
                              : setFieldValue('sampleTaken', true);
                          }}
                          component={Checkbox}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                      </Form.Field>
                    </Wrapper>
                  </Wrapper>
                  <Divider hidden />

                  <Form.Field>
                    <Field
                      {...(readOnly && { disabled: true })}
                      label='Physical Control Instructions Given'
                      placeholder='Physical Control Instructions Given'
                      name='physicalControlInstructionsGiven'
                      type='text'
                      component={TextArea}
                      meta={{ errors, touched }}
                    />
                  </Form.Field>
                </>
              )}
              <Divider hidden />
              <Wrapper>
                {unzippedPictures?.length > 0 && (
                  <>
                    <Wrapper>
                      <TextCustom>Pictures:</TextCustom>
                    </Wrapper>
                    <Wrapper
                      display='flex'
                      justifyContent='space-around'
                      flexWrap='wrap'
                    >
                      {unzippedPictures.map((unzippedPicture, i) => (
                        <Thumbnail
                          key={i}
                          file={unzippedPicture.file}
                          canDelete={editData}
                          deletePicture={() =>
                            deletePicture(unzippedPicture.name)
                          }
                          handleClick={() =>
                            setimageDisplayed(unzippedPicture.file)
                          }
                        />
                      ))}
                    </Wrapper>
                  </>
                )}
                <Divider hidden />
                {!readOnly && (
                  <Button
                    icon='plus'
                    type='button'
                    content='Add photo'
                    labelPosition='right'
                    onClick={() => {
                      setnewPhotoModal(true);
                    }}
                  />
                )}
              </Wrapper>
              <Divider hidden />
              <Table columns={4} stackable striped compact celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Start at</Table.HeaderCell>
                    <Table.HeaderCell>End at</Table.HeaderCell>
                    <Table.HeaderCell>Total time</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {(issueTimes?.length === 0 && <EmptyTable colNumber={4} />) ||
                    (issueTimes &&
                      issueTimes.map((time, i) => (
                        <>
                          <Table.Row key={i}>
                            <Table.Cell>
                              {shortDate(new Date(time.startingAt))}
                            </Table.Cell>
                            <Table.Cell>
                              {(time.endingAt &&
                                shortDate(new Date(time.endingAt))) ||
                                'No end date'}
                            </Table.Cell>
                            <Table.Cell>
                              {(time.endingAt &&
                                (
                                  ((new Date(time.endingAt).getTime() -
                                    new Date(time.startingAt).getTime()) /
                                    1000 /
                                    60 /
                                    60) *
                                  60
                                ).toFixed(0)) ||
                                0}{' '}
                              minute(s)
                            </Table.Cell>
                            <Table.Cell rowSpan='2'>
                              <Wrapper
                                display='flex'
                                justifyContent='space-evenly'
                              >
                                <Button
                                  disabled={readOnly}
                                  type='button'
                                  icon='edit'
                                  onClick={() => {
                                    setTimeEdit(time);
                                    setTimeModal(true);
                                  }}
                                />
                                <Button
                                  disabled={readOnly}
                                  type='button'
                                  icon='remove'
                                  color='red'
                                  onClick={() => removeTime(time.id)}
                                />
                              </Wrapper>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell colSpan='3' textAlign='center'>
                              {time?.comment || 'No comment'}
                            </Table.Cell>
                          </Table.Row>
                        </>
                      )))}
                </Table.Body>
              </Table>
              <Divider hidden />
              {!readOnly && (
                <>
                  <Button
                    icon='plus'
                    type='button'
                    content='Add time'
                    labelPosition='right'
                    onClick={() => {
                      setTimeModal(true);
                    }}
                  />
                  <Divider hidden />
                </>
              )}
              <Button
                type={!readOnly ? 'submit' : 'button'}
                size='large'
                icon='right arrow'
                labelPosition='right'
                color='blue'
                content={!readOnly ? 'Save and next' : 'Next'}
                margin='5px'
                {...(readOnly && {
                  onClick: () => {
                    manageSteps(3);
                  },
                })}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    picturesIssuesZip: selectors.customsClerk.picturesIssuesZipSelector(state),
    issueTimes: selectors.customsClerk.issueTimesSelector(state),
    trailerLicensePlate: selectors.customsClerk.trailerLicensePlateSelector(
      state
    ),
    loading: selectors.customsClerk.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(NewIssue);
