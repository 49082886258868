import * as Yup from 'yup';

import config from 'config';




export const reasonForCancel = Yup.object().shape({
  reason: Yup.string().required('Required'),
});

export const monitoringFiltersSchema = Yup.object().shape({
  id: Yup.string().nullable(true),
  companyId: Yup.number().nullable(true),
  fromTo: Yup.string().nullable(true),
  year: Yup.string().nullable(true),
  month: Yup.string().nullable(true),
  country: Yup.string().nullable(true),
  intranetReference: Yup.string().nullable(true),
  showAll: Yup.boolean().nullable(true),
});

export const createVatDeclarationsSchema = Yup.object().shape({
  // type: Yup.number().required(),
  companyId: Yup.string().required(),
  taxPeriod: Yup.string().required(),
  country:  Yup.object().shape({
    iso: Yup.string(), 
    periodicity: Yup.string(),
    Country_Id: Yup.number(),
  }),
  clientReference: Yup.string().nullable(true),
});

export const createExchangeSchema = Yup.object().shape({
  date: Yup.string().required(),
  contact: Yup.number().required(),
  ref: Yup.string().nullable(true),
  clientReference: Yup.string().nullable(true),
});


export const statisticsFiltersSchema = Yup.object().shape({
  period: Yup.string().nullable(true),
  company: Yup.number().nullable(true),
  startDate: Yup.string(),
  endDate: Yup.string(),
  showAll: Yup.boolean().nullable(true),
});

export const extractDocumentsSchema = Yup.object().shape({
  startDate: Yup.string().required('Required'),
  endDate: Yup.string().required('Required'),
  dau: Yup.boolean(),
  dauBae: Yup.boolean(),
});
