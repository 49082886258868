import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Container, Divider } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import { agencySchema } from 'interfaces/formSchema';
import { Input } from 'components/Form';
import Button from 'components/Button';
import { GoupButtonStyled } from '../styles';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import ButtonValidationModalNoWrapper from 'components/ButtonValidationModalNoWrapper';

const Index = ({
  agencyData,
  countries,
  history,
  dispatch,
  errorHandlerAgency,
  loadingHandlerAgency,
  errorHandlerData,
}) => {
  const [agency, setagency] = useState(null);
  const [runningAction, setRunningAction] = useState(null);
  const [countriesList, setcountriesList]: any = useState([]);

  useEffect(() => {
    dispatch(actions.agency.reset(['error']));

    return () => {
      dispatch(actions.data.reset(['allCountries', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    if (agencyData) {
      setagency(agencyData);
    }
  }, [agencyData]);

  useEffect(() => {
    dispatch(actions.data.getAllCountries());

    return () => {
      dispatch(actions.data.reset(['allCountries', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    if (countries !== null) {
      setcountriesList(
        countries.map((country, index) => {
          return {
            key: index,
            value: country.iso_code,
            text: country.english_name,
            flag: country.iso_code.toLowerCase(),
          };
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    if (runningAction !== null && !loadingHandlerAgency) {
      if (!errorHandlerAgency.includes(runningAction)) {
        history.push('./');
      }

      setRunningAction(null);
    }
  }, [loadingHandlerAgency]);

  const deleteAgency = () => {
    dispatch(actions.agency.remove({ params: { agencyId: agency.id } }));
    setRunningAction('remove');
  };

  const save = (values) => {
    dispatch(actions.agency.reset(['error']));
    dispatch(
      actions.agency.update({
        params: { agencyId: agency.id },
        data: {
          name: values.name,
          vat: values.vat,
          phoneNumber: values.phoneNumber,
          country: values.country,
          city: values.city,
          address1: values.address1,
          address2: values.address2,
          zipCode: values.zipCode,
        },
      })
    );
    setRunningAction('update');
  };

  return (
    <Segment>
      <Container>
        {((loadingHandlerAgency || !agency) && <Loader />) || (
          <Formik
            initialValues={{
              name: agency.name,
              vat: agency.Vat.vatNumber,
              phoneNumber: agency.Address.phoneNumber,
              country: agency.Address.country,
              city: agency.Address.city,
              address1: agency.Address.address1,
              address2: agency.Address.address2 || '',
              zipCode: agency.Address.zipCode,
            }}
            onSubmit={save}
            validationSchema={agencySchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }: any) => (
              <Form onSubmit={handleSubmit}>
                <>
                  <Wrapper padding='1rem 5rem'>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          mandatory
                          label="Agency's name"
                          placeholder="Agency's name"
                          name='name'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>

                      <Form.Field>
                        <Field
                          mandatory
                          label="Agency's VAT"
                          placeholder="Agency's VAT"
                          name='vat'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>
                    </Form.Group>

                    <Divider hidden />
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          mandatory
                          label='Phone number'
                          placeholder='Phone number'
                          name='phoneNumber'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>

                      <Form.Field>
                        <Field
                          mandatory
                          placeholder='Choose country'
                          label='Country'
                          name='country'
                          options={countriesList}
                          component={Dropdown}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                          uniqueError={errorHandlerData.includes(
                            'allCountries'
                          )}
                        />
                      </Form.Field>
                    </Form.Group>

                    <Divider hidden />
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          mandatory
                          label='City'
                          placeholder='City'
                          name={`city`}
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Field
                          mandatory
                          label='Zip Code'
                          placeholder='Zip Code'
                          name={`zipCode`}
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          mandatory
                          label='Address 1'
                          placeholder='Address'
                          name={`address1`}
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Field
                          label='Address 2'
                          placeholder='Building, floor ...'
                          name={`address2`}
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Wrapper>
                  <Divider hidden />

                  <GoupButtonStyled fluid>
                    <Button
                      content='Save'
                      icon='save'
                      type='submit'
                      color='blue'
                      labelPosition='right'
                      margin='5px 5px 5px 0px'
                      float-right
                    />
                    <Wrapper margin='5px'>
                      <ButtonValidationModalNoWrapper
                        trgrContent='Delete Agency'
                        headerContent='Delete the agency ?'
                        modalContent='By confirming, the agency will be deleted.'
                        trgrColor='red'
                        trgrIcon='delete'
                        labelPosition='right'
                        noSubmit
                        headerIcon='check'
                        onClick={() => {
                          deleteAgency();
                        }}
                      />
                    </Wrapper>
                  </GoupButtonStyled>
                </>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.data.allCountriesSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    errorHandlerAgency: selectors.agency.errorSelector(state),
    loadingHandlerAgency: selectors.agency.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
