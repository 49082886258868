import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const addUserErrorSelector = (state) => state.companyUser.errors.addUser;
const removeUserErrorSelector = (state) => state.companyUser.errors.removeUser;
const updateUserErrorSelector = (state) => state.companyUser.errors.updateUser;
const updateUserPermissionsErrorSelector = (state) =>
  state.companyUser.errors.updateUserPermissions;
const getUserPermissionsErrorSelector = (state) =>
  state.companyUser.errors.getUserPermissions;
const getOneUserErrorSelector = (state) => state.companyUser.errors.getOneUser;
const getAllUsersErrorSelector = (state) =>
  state.companyUser.errors.getAllUsers;

const errorsSelector = createErrorsSelector([
  addUserErrorSelector,
  removeUserErrorSelector,
  updateUserErrorSelector,
  updateUserPermissionsErrorSelector,
  getUserPermissionsErrorSelector,
  getOneUserErrorSelector,
  getAllUsersErrorSelector,
]);

// Loading
const addUserLoadingSelector = (state) => state.companyUser.loading.addUser;
const removeUserLoadingSelector = (state) =>
  state.companyUser.loading.removeUser;
const updateUserLoadingSelector = (state) =>
  state.companyUser.loading.updateUser;
const updateUserPermissionsLoadingSelector = (state) =>
  state.companyUser.loading.updateUserPermissions;
const getUserPermissionsLoadingSelector = (state) =>
  state.companyUser.loading.getUserPermissions;
const getOneUserLoadingSelector = (state) =>
  state.companyUser.loading.getOneUser;
const getAllUsersLoadingSelector = (state) =>
  state.companyUser.loading.getAllUsers;

const loadingSelector = createLoadingSelector([
  addUserLoadingSelector,
  removeUserLoadingSelector,
  updateUserPermissionsLoadingSelector,
  updateUserLoadingSelector,
  getUserPermissionsLoadingSelector,
  getOneUserLoadingSelector,
  getAllUsersLoadingSelector,
]);

// Success
const addUserSuccessSelector = (state) => state.companyUser.success.addUser;
const removeUserSuccessSelector = (state) =>
  state.companyUser.success.removeUser;
const updateUserSuccessSelector = (state) =>
  state.companyUser.success.updateUser;
const updateUserPermissionsSuccessSelector = (state) =>
  state.companyUser.success.updateUserPermissions;
const getUserPermissionsSuccessSelector = (state) =>
  state.companyUser.success.getUserPermissions;
const getOneUserSuccessSelector = (state) =>
  state.companyUser.success.getOneUser;
const getAllUsersSuccessSelector = (state) =>
  state.companyUser.success.getAllUsers;

const successSelector = createSuccessSelector([
  addUserSuccessSelector,
  removeUserSuccessSelector,
  updateUserSuccessSelector,
  updateUserPermissionsSuccessSelector,
  getUserPermissionsSuccessSelector,
  getOneUserSuccessSelector,
  getAllUsersSuccessSelector,
]);

const userSelector = (state) => state.companyUser.user;
const usersSelector = (state) => state.companyUser.users;
const userPermissionsSelector = (state) => state.companyUser.userPermissions;
const userCompaniesSelectedSelector = (state) =>
  state.companyUser.userCompaniesSelected;
const userCompaniesSelector = (state) => state.companyUser.userCompanies;

const errorSelector = (state) => state.companyUser.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  userSelector,
  usersSelector,
  userPermissionsSelector,
  userCompaniesSelectedSelector,
  userCompaniesSelector,
  errorSelector,
  getAllUsersLoadingSelector,
};
