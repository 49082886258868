import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  getAllTransportersByToken(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_TRANSPORTERS_BY_TOKEN,
      payload: data,
    };
  },
  createTransporterByToken(data: dataReqI) {
    return {
      type: actionTypes.CREATE_TRANSPORTER_BY_TOKEN,
      payload: data,
    };
  },
  getTransporterVats(data: dataReqI) {
    return {
      type: actionTypes.GET_TRANSPORTER_VATS,
      payload: data,
    };
  },

  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
