import React, { FunctionComponent } from 'react';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { FloatingCardSingleStyled } from './FloatingCardStyles';
import { Icon } from 'semantic-ui-react';
import { TextCustom } from '..';
import {
  SemanticICONS,
  SemanticCOLORS,
} from 'semantic-ui-react/dist/commonjs/generic';

type FloatingCardSingleProps = {
  item?: any;
  height?: string;
  children?: any;
  title?: string;
  icon?: SemanticICONS;
  close?: any;
  onClick?: any;
  iconColor?: SemanticCOLORS;
};

const FloatingCardSingle: FunctionComponent<FloatingCardSingleProps> = ({
  item,
  children,
  title,
  icon,
  close,
  onClick,
  iconColor,
}) => {

  return (
    <FloatingCardSingleStyled
      key={item}
      item={item}
      {...(onClick && {
        onClick: () => {
          onClick();
        },
      })}
    >
      {(close || title) && (
        <Wrapper display='flex' justifyContent='space-between'>
          <Wrapper>
            {title && (
              <Wrapper display='flex'>
                <Wrapper margin='0 5px 5px 0'>
                  <TextCustom fontSize='1.4rem' fontWeight='bold'>
                    {title}
                  </TextCustom>
                </Wrapper>
                <Wrapper margin='0 5px 0 5px'>
                  {icon && (
                    <Icon name={icon} color={iconColor} size={'large'} />
                  )}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
          <Wrapper>
            {close && <Icon name='close' onClick={() => close()} />}
          </Wrapper>
        </Wrapper>
      )}
      <Wrapper textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
        {children}
      </Wrapper>
    </FloatingCardSingleStyled>
  );
};

export default FloatingCardSingle;
