import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    addDriver: false,
    removeDriver: false,
    updateDriver: false,
    updateDriverPermissions: false,
    getDriverPermissions: false,
    getOneDriver: false,
    getAllDrivers: false,
    getDrivers: false,
    addDriverToCompany: false,
  },
  errors: {
    addDriver: null,
    removeDriver: null,
    updateDriver: null,
    updateDriverPermissions: null,
    getDriverPermissions: null,
    getOneDriver: null,
    getAllDrivers: null,
    getDrivers: null,
    addDriverToCompany: null,
  },
  loading: {
    addDriver: false,
    removeDriver: false,
    updateDriver: false,
    updateDriverPermissions: false,
    getDriverPermissions: false,
    getOneDriver: false,
    getAllDrivers: false,
    getDrivers: false,
    addDriverToCompany: false,
  },
  driver: null,
  getAllDrivers: null,
  driverPermissions: null,
  driverCompanies: null,
  addDriverToCompany: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Get Driver companies
    case actionTypes.GET_DRIVER_COMPANIES_LOADING:
      return produce(state, (draft) => {
        draft.loading.getDrivers = true;
      });

    case actionTypes.GET_DRIVER_COMPANIES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getDrivers = null;
        draft.loading.getDrivers = false;
        draft.success.getDrivers = true;
        draft.driverCompanies = action.payload;
      });

    case actionTypes.GET_DRIVER_COMPANIES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getDrivers = true;
        draft.loading.getDrivers = false;
        draft.success.getDrivers = false;
        !draft.error.includes("DriverCompanies") &&
          draft.error.push("DriverCompanies");
      });
    // Add Driver
    case actionTypes.ADD_DRIVER_LOADING:
      return produce(state, (draft) => {
        draft.loading.addDriver = true;
      });

    case actionTypes.ADD_DRIVER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addDriver = null;
        draft.loading.addDriver = false;
        draft.success.addDriver = true;
      });

    case actionTypes.ADD_DRIVER_ERROR:
      return produce(state, (draft) => {
        draft.errors.addDriver = true;
        draft.loading.addDriver = false;
        draft.success.addDriver = false;
        !draft.error.includes("addDriver") && draft.error.push("addDriver");
      });

        // addDriverToCompany
    case actionTypes.ADD_DRIVER_TO_COMPANY_LOADING:
      return produce(state, (draft) => {
        draft.loading.addDriverToCompany = true;
      });

    case actionTypes.ADD_DRIVER_TO_COMPANY_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addDriverToCompany = null;
        draft.loading.addDriverToCompany = false;
        draft.success.addDriverToCompany = true;
      });

    case actionTypes.ADD_DRIVER_TO_COMPANY_ERROR:
      return produce(state, (draft) => {
        draft.errors.addDriverToCompany = true;
        draft.loading.addDriverToCompany = false;
        draft.success.addDriverToCompany = false;
        !draft.error.includes("addDriverToCompany") && draft.error.push("addDriverToCompany");
      });

    // remove Drivers
    case actionTypes.REMOVE_DRIVER_LOADING:
      return produce(state, (draft) => {
        draft.loading.removeDriver = true;
      });

    case actionTypes.REMOVE_DRIVER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removeDriver = null;
        draft.loading.removeDriver = false;
        draft.success.removeDriver = true;
      });

    case actionTypes.REMOVE_DRIVER_ERROR:
      return produce(state, (draft) => {
        draft.errors.removeDriver = true;
        draft.loading.removeDriver = false;
        draft.success.removeDriver = false;
        !draft.error.includes("removeDriver") && draft.error.push("removeDriver");
      });

    // update Drivers
    case actionTypes.UPDATE_DRIVER_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateDriver = true;
      });

    case actionTypes.UPDATE_DRIVER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateDriver = null;
        draft.loading.updateDriver = false;
        draft.success.updateDriver = true;
      });

    case actionTypes.UPDATE_DRIVER_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateDriver = true;
        draft.loading.updateDriver = false;
        draft.success.updateDriver = false;
        !draft.error.includes("updateDriver") && draft.error.push("updateDriver");
      });

    // Update Driver permissions
    case actionTypes.UPDATE_DRIVER_PERMISSIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateDriverPermissions = true;
        draft.loading.updateDriverPermissions = true;
      });

    case actionTypes.UPDATE_DRIVER_PERMISSIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateDriverPermissions = null;
        draft.loading.updateDriverPermissions = false;
        draft.success.updateDriverPermissions = true;
      });

    case actionTypes.UPDATE_DRIVER_PERMISSIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateDriverPermissions = true;
        draft.loading.updateDriverPermissions = false;
        draft.success.updateDriverPermissions = false;
        !draft.error.includes("updateDriverPermissions") &&
          draft.error.push("updateDriverPermissions");
      });

    // Get Driver permissions
    case actionTypes.GET_DRIVER_PERMISSIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getDriverPermissions = true;
      });

    case actionTypes.GET_DRIVER_PERMISSIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getDriverPermissions = null;
        draft.loading.getDriverPermissions = false;
        draft.success.getDriverPermissions = true;
        draft.driverPermissions = action.payload;
      });

    case actionTypes.GET_DRIVER_PERMISSIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getDriverPermissions = true;
        draft.loading.getDriverPermissions = false;
        draft.success.getDriverPermissions = false;
        !draft.error.includes("getDriverPermissions") &&
          draft.error.push("getDriverPermissions");
      });

    // Add one Driver
    case actionTypes.GET_ONE_DRIVER_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneDriver = true;
      });

    case actionTypes.GET_ONE_DRIVER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneDriver = null;
        draft.loading.getOneDriver = false;
        draft.success.getOneDriver = true;
        draft.driver = action.payload;
      });

    case actionTypes.GET_ONE_DRIVER_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneDriver = true;
        draft.loading.getOneDriver = false;
        draft.success.getOneDriver = false;
        !draft.error.includes("Driver") && draft.error.push("Driver");
      });

    // Get all Drivers
    case actionTypes.GET_ALL_DRIVERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllDrivers = true;
      });

    case actionTypes.GET_ALL_DRIVERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllDrivers = null;
        draft.loading.getAllDrivers = false;
        draft.success.getAllDrivers = true;
        draft.getAllDrivers = action.payload;
      });

    case actionTypes.GET_ALL_DRIVERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllDrivers = true;
        draft.loading.getAllDrivers = false;
        draft.success.getAllDrivers = false;
        !draft.error.includes("getAllDrivers") && draft.error.push("getAllDrivers");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
