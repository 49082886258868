import React, { useEffect, useRef, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Formik } from 'formik';
import { actions, selectors } from 'store';
import {
  Button as Btn,
  Container,
  Divider,
  Header,
  Grid,
  Segment,
  Form,
  Message,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import Buyer from 'components/QuickFixes/StartADelivery/DeliveryValidation/Buyer';
import DeliveryDetails from 'components/QuickFixes/StartADelivery/DeliveryValidation/DeliveryDetails';
import ValuesDetails from 'components/QuickFixes/StartADelivery/DeliveryValidation/ValuesDetails/ValuesDetails';
import Seller from 'components/QuickFixes/StartADelivery/DeliveryValidation/Seller';
import Transporter from 'components/QuickFixes/StartADelivery/DeliveryValidation/Transporter';
import ProofsManager from 'components/QuickFixes/DeliveryForm/ProofsManager';
import Button from 'components/Button';
import Loader from 'components/Loader';
import ButtonValidationModalNoWrapper from 'components/ButtonValidationModalNoWrapper';
import AskForProofs from 'components/QuickFixes/StartADelivery/DeliveryValidation/AskForProofs';
import { paths } from 'routes/customs/declaration';
import {paths as kyanitePath} from 'routes/fiscal/kyanite';
import UploadDocumentModal from './Components/UploadDocumentModal/UploadDocumentModal';
import SplitDocumentModal from './Components/SplitDocumentModal';
import ClipBoard from './Components/ClipBoard/ClipBoard';
import Wrapper from 'components/core/Wrapper/Wrapper';


const Index = ({
  history,
  match,
  user,
  dispatch,
  delivery,
  loadingHandlerQuickFixes,
  companySelected,
  downloadLoading,
  vatLoading,
  customs,
  customsLoading,
  hasSubscribedTo,
  vat,
  deliveryLoading,
  company,
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const isSuperOperatorCompany = 49;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected;
  const clientId = !userAdminOrOp && !match.params.companyId && companySelected 

  //const companyisOwner = userAdminOrOp ? clientId ? (delivery.Seller_Id !== clientId) ? delivery.Buyer_Id : delivery.Seller_Id: companySelected : companySelected; 
 
  const [deliveryInformations, setDeliveryInformations]: any = useState(null);
  const [allProofs, setProofs] = useState({});

  //const [runningAction, setRunningAction] = useState(null);
  //const [vatId, setVatId] = useState(null);
  const [askForProofsModal, setAskForProofsModal] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSplitOpen, setIsSlitOpen] = useState(false);
  const [updates, setUpdates] = useState(false);
  const [disabled, setDisabled] = useState(true)
  const [companyType, setCompanyType] = useState(null);
  const [invoiceNumbers, setinvoiceNumbers] = useState(null);
  const [actorsId, setActorsId] = useState({});
  const [currentSeller, setCurrentSeller]: any = useState(null);
  const [transporterVatId, setTransporterVatId] = useState(null);
  const [buyerVatId, setBuyerVatId] = useState(null);
  const [customsCompanyId, setCustomsCompanyId] = useState(null)
  const formikForm = useRef(null);


  useEffect(() => {
    dispatch(
      actions.company.hasSubscribedTo({
        params: {
          companyId: companyId,
          serviceName: 'customsDeclaration',
        }
      }));
   
    dispatch(
      actions.company.getOne({
        params: { 
          companyId: companyId 
        },
      })
    );
    dispatch(
      actions.quickfixes.getOne({
        params: {
          companyId,
          deliveryId: match.params.deliveryId,
          type: userAdminOrOp ? 'operator': 'client',
        },
      })
    );
    dispatch(actions.socket.subscribeUpdateDelivery(match.params.deliveryId));

    return () => {
      dispatch(actions.socket.unsubscribeUpdateDelivery(match.params.deliveryId));
      dispatch(actions.quickfixes.reset(['oneDelivery', 'company', 'error']));
    };
  }, [dispatch, companyId, userAdminOrOp, match.params.deliveryId]);

  useEffect(() => {
    if(delivery){
      // id format AZ-4565465454 | AZ4565465454 | (15 || 16)
      let item
      if(delivery.reference.length === 15 || delivery.reference.length === 16){
        // object => customs
        item = delivery.reference.split('-')
      }else{
        // string => no customs
        item = delivery.reference
      }
     
      // secure format
      const declarationId = (typeof item === 'object') ? item[0].length === 2 ? 
              `${delivery.reference}` : 
              `${delivery.reference}`.slice(0, 2) + '-' + `${delivery.reference}`.slice(2) : item
                
      // if has custom get custom
      if(typeof item === 'object'){
        dispatch(
          actions.customsDeclaration.getOneByReference({
            params: { companyId: companyId || clientId, declarationId: declarationId},
          })
        );
        return () => {
          dispatch(actions.customsDeclaration.reset(['customs', 'error']));
        }
      }
      
    }
  }, [dispatch, delivery, companyId, clientId])

  useEffect(() => {
    customs && setCustomsCompanyId(customs.Company_Id)
  }, [customs])

  const findCompanyType = useCallback(() => {
    switch (+companyId) {
      case delivery.Buyer_Id:
        setCompanyType('buyer');
        break;
      case delivery.Seller_Id:
        setCompanyType('seller');
        break;
      case delivery.Transporter_Id:
        setCompanyType('transporter');
        break;
    }
  }, [delivery, setCompanyType, companyId]);

  useEffect(() => {
    if (delivery !== null) {
      setUpdates(true)
      setDeliveryInformations(null)
      setCurrentSeller(delivery.Seller_Id)
      setDeliveryInformations({
        storage: delivery.storage,
        deliveryInfo: {
          ref: delivery.reference,
          date: delivery.createdAt,
          departureCountry: delivery.departureCountry,
          arrivalCountry: delivery.arrivalCountry,
          transporterInCharge: delivery.ShippingAgent_Id === delivery.Seller_Id
              ? delivery.Sellers.name
              : delivery.Buyers.name,
          invoiceNumbers: delivery.DeliveryInvoices,
          imaNumber: delivery.imaNumber,
          cmr: delivery.cmr,
          customsDeclarationId: delivery.customsDeclarationId,
        },
        seller: {
          company: '',
          name: delivery.Sellers.name,
          addressCountry: delivery.SellerAddresses.country,
          addressCity: delivery.SellerAddresses.city,
          email: delivery.Sellers.email,
          phone: delivery.Sellers.phoneNumber1,
          vat: delivery.SellerVats.vatNumber,
        },
        buyer: {
          company: '',
          name: delivery.Buyers.name,
          addressCountry: delivery.BuyerAddresses.country,
          addressCity: delivery.BuyerAddresses.city,
          email: delivery.Buyers.email,
          phone: delivery.Buyers.phoneNumber1,
          vat: delivery.BuyerVats.vatNumber,
          buyerAddressId: delivery.BuyerAddress_Id
        },
        transporter: {
          company: '',
          name: (delivery.Transporters && delivery.Transporters.name) || '',
          email: (delivery.Transporters && delivery.Transporters.email) || '',
          phone: (delivery.Transporters && delivery.Transporters.phoneNumber) || '',
          vat:
            delivery.Transporters &&
            delivery.TransporterVats &&
            delivery.TransporterVats.vatNumber,
        },
        values: {
          packageMethod: delivery.packageMethod,
          packageNumber: delivery.packageNumber,
          natureOfGoods: delivery.natureOfGoods,
          grossWeight: delivery.grossWeight,
          grossWeightUnit: delivery.grossWeightUnit,
          volume: delivery.volume,
          volumeUnit: delivery.volumeUnit,
          value: delivery.totalHT,
        },
      });
    
      setActorsId({
        seller: delivery.Seller_Id,
        buyer: delivery.Buyer_Id,
        transporter: delivery.Transporter_Id,
      });

      const proofs = {
        [delivery.Seller_Id]: [],
        [delivery.Buyer_Id]: [],
        [delivery.Transporter_Id]: [],
      };

      delivery.ProofsOfDeliveries.length>0 && 
      delivery.ProofsOfDeliveries.forEach((proof) => {
        proofs[proof.Company_Id].push(proof);
      });

      setinvoiceNumbers(delivery.DeliveryInvoices);
      setProofs(proofs); 
      
      findCompanyType();
      setUpdates(false)
    }
  }, [delivery, findCompanyType]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSplitClose = () => {
    setIsSlitOpen(false)
  }


  const deliveryDetailsFieldsNames = {
    ref: 'ref',
    date: 'date',
    from: 'departureCountry',
    to: 'arrivalCountry',
    transporterInCharge: 'transporterInCharge',
    invoiceNumbers: 'invoiceNumbers',
    imaNumber: 'imaNumber',
    cmr: 'cmr',
    customsDeclarationId: 'customsDeclarationId',
  };

  const buyerFieldsNames = {
    company: 'buyer.company',
    name: 'buyer.name',
    address: 'buyer.address',
    addressCountry: 'buyer.addressCountry',
    addressCity: 'buyer.addressCity',
    address1: 'buyer.address1',
    email: 'buyer.email',
    phone: 'buyer.phone',
    vat: 'buyer.vat',
    buyerAddressId: 'buyer.buyerAddressId',
  };
  const sellerFieldsNames = {
    company: 'seller.company',
    name: 'seller.name',
    address: 'seller.address',
    addressCountry: 'seller.addressCountry',
    addressCity: 'seller.addressCity',
    address1: 'seller.address1',
    email: 'seller.email',
    phone: 'seller.phone',
    vat: 'seller.vat',
  };
  const transporterFieldsNames = {
    company: 'transporter.company',
    name: 'transporter.name',
    address: 'transporter.address',
    addressCountry: 'transporter.addressCountry',
    addressCity: 'transporter.addressCity',
    address1: 'transporter.address1',
    email: 'transporter.email',
    phone: 'transporter.phone',
    vat: 'transporter.vat',
  };

  const valuesFieldsNames = {
    packageMethod: 'values.packageMethod',
    packageNumber: 'values.packageNumber',
    natureOfGoods: 'values.natureOfGoods',
    grossWeight: 'values.grossWeight',
    grossWeightUnit: 'values.grossWeightUnit',
    volume: 'values.volume',
    volumeUnit: 'values.volumeUnit',
    value: 'values.value',
  };

  const downloadAll = () => {
    dispatch(
      actions.quickfixes.downloadAllProof({
        params: {
          deliveryId: match.params.deliveryId,
          companyId,
        },
      })
    );
  };

  const downloadAttestation = (deliveryId) => {
    dispatch(
      actions.quickfixes.downloadAttestation({
        params: {
          deliveryId,
          companyId
        },
      })
    );
  };

  const downloadCustomsRegimeInvoice = (deliveryId) => {
    dispatch(
      actions.quickfixes.downloadCustomsRegimeInvoice({
        params: {
          deliveryId,
          companyId,
        },
      })
    );
  };

  const validateTheDelivery = () => {
    dispatch(
      actions.quickfixes.validateDelivery({
        params: {
          deliveryId: match.params.deliveryId,
          companyId,
        },
      })
    );
  };

  const getBuyerVatsValue = (data) => {
    setBuyerVatId(null)
    setBuyerVatId(data.vatId)
  }


  const getTransporterVatsValue = (data) => {
    setTransporterVatId(null)
    setTransporterVatId(data.vatId)
  }

  const updateDelivery = (values, transporterVatId, buyerVatId) => {
    dispatch(actions.quickfixes.reset(['error']));
    if(
      values.ref &&
      values.arrivalCountry &&
      values.departureCountry &&
      values.values.packageMethod &&
      values.values.packageNumber &&
      values.values.natureOfGoods &&
      values.values.grossWeight &&
      values.transporterInCharge &&
      values.seller.vat
    ){

      let buyerVat: any
      if(typeof values.buyer.vat === 'string'){
        buyerVat = delivery.BuyerVat_Id
      }else if(typeof values.buyer.vat === 'number' && !buyerVatId){
        buyerVat = values.buyer.vat
      }else if(buyerVatId){
        buyerVat = buyerVatId
      }

      let transporterVat: any
      if(typeof values.buyer.vat === 'string'){
        transporterVat = delivery.TransporterVat_Id
      }else if(typeof values.buyer.vat === 'number' && !transporterVatId){
        transporterVat = values.transporter.vat
      }else if(buyerVatId){
        transporterVat = transporterVatId
      }

      let buyerAddress = typeof values.buyer.buyerAddressId === 'string' ? delivery.BuyerAddress_Id : values.buyer.buyerAddressId;

      dispatch(actions.quickfixes.update({
          params: { 
            companyId, 
            deliveryId: match.params.deliveryId
          },
          data: {
            Buyer_Id: typeof values.buyer.name === 'string' ? delivery.Buyer_Id : values.buyer.name,
            Transporter_Id: typeof values.transporter.name === 'string' ? delivery.Transporter_Id : values.transporter.name,
            ShippingAgent_Id: values.transporterInCharge !== 'ASD_France' ? values.transporterInCharge === 'the buyer' ? values.buyer.name : values.seller.name : isSuperOperatorCompany,
            SellerVat_Id:  delivery.SellerVat_Id,
            BuyerVat_Id: buyerVat,
            BuyerAddress_Id: buyerAddress,
            TransporterVat_Id: transporterVat,
            reference: values.ref,
            arrivalCountry: values.arrivalCountry,
            departureCountry: values.departureCountry,
            totalHT: values.values.value,
            packageMethod: values.values.packageMethod,
            packageNumber: values.values.packageNumber,
            natureOfGoods: values.values.natureOfGoods,
            grossWeight: values.values.grossWeight,
            grossWeightUnit: values.values.grossWeightUnit,
            volume: values.values.volume || 0,
            volumeUnit: values.values.volumeUnit,
            imaNumber: values.imaNumber,
            cmr: values.cmr,
          },
      }))
    }
    setDisabled(disabled === false ? true : false)
  };

  
// const ClickIcon: any = styled(Button)`
//   &&& {
//     background-color: red;
//     border-radius: 2em !important;
//     color: #ffff;
//   }
//   &&&:hover {
//     background-color: red;
//     border-radius: 2em !important;
//     color: #ffff;
//   }
// `;


  return (
    <Wrapper margin='auto' width='90%'>
      <Divider hidden />
      <Btn.Group>
        {askForProofsModal && (
          <AskForProofs
            isModalOpen={askForProofsModal !== null}
            deliveryId={askForProofsModal}
            companyId={companyId}
            handleClose={() => setAskForProofsModal(null)}
            dispatch={dispatch}
            setReceiverChoiceState
          />
        )}
        <Button
          floated='left'
          labelPosition='left'
          icon='angle left'
          position='flex-end'
          type='button'
          content='Back to dashboard'
          margin='5px'
          onClick={() =>
            history.push(
              userAdminOrOp
                ? kyanitePath.kyaniteOperator.replace(':companyId', companyId)
                : kyanitePath.deliveries
            )
          }
          color='blue'
        />
      </Btn.Group>

      <Container fluid>
        <Container fluid>
          <Header textAlign='center' size='huge'>
            Delivery Details
          </Header>
          <Divider hidden />
          {((!deliveryInformations || downloadLoading || loadingHandlerQuickFixes || vatLoading || customsLoading) && <Loader />) ||
            (deliveryInformations && (
              <Formik             
                initialValues={{
                  ref: deliveryInformations.deliveryInfo.ref || '',
                  date: deliveryInformations.deliveryInfo.date || '',
                  imaNumber: deliveryInformations.deliveryInfo.imaNumber || '',
                  cmr: deliveryInformations.deliveryInfo.cmr || '',
                  customsDeclarationId: deliveryInformations.customsDeclarationId || '',
                  departureCountry: deliveryInformations.deliveryInfo.departureCountry || '',
                  arrivalCountry: deliveryInformations.deliveryInfo.arrivalCountry || '',
                  transporterInCharge: deliveryInformations.deliveryInfo.transporterInCharge || '',
                  seller: {
                    company: deliveryInformations.seller.company || '',
                    name: deliveryInformations.seller.name || '',
                    addressCountry: deliveryInformations.seller.addressCountry || '',
                    addressCity: deliveryInformations.seller.addressCity || '',
                    address1: deliveryInformations.seller.address1 || '',
                    email: deliveryInformations.seller.email || '',
                    phone: deliveryInformations.seller.phone || '',
                    vat: deliveryInformations.seller.vat || '',

                  },
                  buyer: {
                    company: deliveryInformations.buyer.company || '',
                    name: deliveryInformations.buyer.name || '',
                    addressCountry: deliveryInformations.buyer.addressCountry || '',
                    addressCity: deliveryInformations.buyer.addressCity || '',
                    address1: deliveryInformations.buyer.address1 || '',
                    email: deliveryInformations.buyer.email || '',
                    phone: deliveryInformations.buyer.phone || '',
                    vat: deliveryInformations.buyer.vat || '',
                    buyerReminderAt: deliveryInformations.buyer.buyerReminderAt || '',
                    buyerAddressId: deliveryInformations.buyer.buyerAddressId || '',
                  },
                  transporter: {
                    company: deliveryInformations.transporter.company || '',
                    name: deliveryInformations.transporter.name || '',
                    addressCountry: deliveryInformations.transporter.addressCountry || '',
                    addressCity: deliveryInformations.transporter.addressCity || '',
                    address1: deliveryInformations.transporter.address1 || '',
                    email: deliveryInformations.transporter.email || '',
                    phone: deliveryInformations.transporter.phone || '',
                    vat: deliveryInformations.transporter.vat || '',
                    transporterReminderAt: deliveryInformations.transporter.transporterReminderAt || '',
                  },
                  values: {
                    packageMethod: deliveryInformations.values.packageMethod || '',
                    packageNumber: deliveryInformations.values.packageNumber || '',
                    natureOfGoods: deliveryInformations.values.natureOfGoods || '',
                    grossWeight: deliveryInformations.values.grossWeight || '',
                    grossWeightUnit: deliveryInformations.values.grossWeightUnit || '',
                    volume: deliveryInformations.values.volume || '',
                    volumeUnit: deliveryInformations.values.volumeUnit || '',
                    value: deliveryInformations.values.value || '',
                  },
                  documentType: '',
                  file: undefined,
                  proofLevel: null,
                }}
                onSubmit={(values) => {
                  updateDelivery(values, transporterVatId, buyerVatId)
                }}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  handleChange,
                  values,
                }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange} computer={12} ref={formikForm}>
                    <Segment size='small'>
                      <Header textAlign='center'>
                        <Wrapper display='flex' justifyContent='center'>
                          {userAdminOrOp && (Number(currentSeller) === Number(companyId)) && (
                            <Button
                              icon={disabled === false ? `close` : `edit`}
                              type='button'
                              size='small'
                              color='blue'
                              content={disabled === false ? `close` : `Edit`}
                              margin='5px'
                              onClick={(e) => {
                                if(e){
                                  setDisabled(disabled === false ? true : false)
                                  if(disabled === false){
                                    setFieldValue(buyerFieldsNames.name, deliveryInformations.buyer.name)
                                    setFieldValue(buyerFieldsNames.addressCountry, deliveryInformations.buyer.addressCountry)
                                    setFieldValue(buyerFieldsNames.addressCity, deliveryInformations.buyer.addressCity)
                                    setFieldValue(buyerFieldsNames.address1, deliveryInformations.buyer.address1)
                                    setFieldValue(buyerFieldsNames.email, deliveryInformations.buyer.email)
                                    setFieldValue(buyerFieldsNames.phone, deliveryInformations.buyer.phone)
                                    setFieldValue(buyerFieldsNames.vat, deliveryInformations.buyer.vat)
                                    setFieldValue(transporterFieldsNames.name, deliveryInformations.transporter.name)
                                    setFieldValue(transporterFieldsNames.email, deliveryInformations.transporter.email)
                                    setFieldValue(transporterFieldsNames.phone, deliveryInformations.transporter.phone)
                                    setFieldValue(transporterFieldsNames.vat, deliveryInformations.transporter.vat)
                                  }
                                }
                              }}
                            />
                            )}
                            {disabled === false && (
                              <Button
                                type='button'
                                size='small'
                                color='blue'
                                content='Save'
                                margin='5px'
                                onClick={handleSubmit}
                              />
                            )}
                        </Wrapper>
                        Delivery summary</Header>
                      <Divider hidden />
                      <Grid columns={5}>
                        <Grid.Row>
                          <Grid.Column mobile={16} tablet={8} computer={3}>
                            <Header textAlign='center' size='medium'>
                              Delivery
                            </Header>
                            <DeliveryDetails
                              names={deliveryDetailsFieldsNames}
                              invoiceNumbers={invoiceNumbers}
                              customsId={delivery.customsDeclarationId}
                              cmr={delivery.cmr}
                              disabled={disabled}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={8} computer={3}>
                            <Header textAlign='center' size='medium'>
                              Seller
                            </Header>
                            <Seller
                              names={sellerFieldsNames}
                              noAddress={true}
                              disabled={disabled}
                              companyId={companyId}
                              company={company}
                              dispatch={dispatch}
                              deliveryId={delivery.id}
                              valuesVat={deliveryInformations.seller.vat}
                              userAdminOrOp={userAdminOrOp}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={8} computer={3}>
                            <Header textAlign='center' size='medium'>
                              Buyer
                            </Header>
                            <Buyer 
                              names={buyerFieldsNames} 
                              noAddress={true}  
                              userAdminOrOp={userAdminOrOp}  
                              disabled={disabled}
                              dispatch={dispatch}
                              companyId={companyId}
                              companyBuyerName={deliveryInformations.buyer.name}
                              companyBuyerVat={delivery.BuyerVat_Id}
                              companyBuyerId={delivery.Buyer_Id}
                              companyBuyerAddress={delivery.BuyerAddress_Id}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              getBuyerVatsValue={getBuyerVatsValue}
                            />
                            {delivery &&
                              delivery.status !== 'validated' && (
                                <Message info>
                                  <p>
                                    Last buyer reminder :{' '}
                                    {(delivery.buyerReminderAt &&
                                      new Date(
                                        delivery.buyerReminderAt
                                      ).toLocaleDateString('en', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      })) ||
                                      'never'}
                                  </p>
                                </Message>
                              )}
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={8} computer={3}>
                            <Header textAlign='center' size='medium'>
                              Transporter
                            </Header>
                            <Transporter 
                              names={transporterFieldsNames} 
                              noAddress={true}  
                              userAdminOrOp={userAdminOrOp}  
                              disabled={disabled}
                              dispatch={dispatch}
                              companyId={companyId}
                              companyTransporterName={deliveryInformations.transporter.name}
                              companyTransporterVat={deliveryInformations.transporter.vat}
                              companyTransporterId={delivery.Transporter_Id}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              values={values} 
                              getTransporterVatsValue={getTransporterVatsValue}                      
                            />
                            {delivery &&
                              delivery.status !== 'validated' && (
                                <Message info>
                                  <p>
                                    Last transporter reminder :{' '}
                                    {(delivery.transporterReminderAt &&
                                      new Date(
                                        delivery.transporterReminderAt
                                      ).toLocaleDateString('en', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      })) ||
                                      'never'}
                                  </p>
                                </Message>
                              )}
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={8} computer={3}>
                            <Header textAlign='center' size='medium'>
                              Values
                            </Header>
                            <ValuesDetails names={valuesFieldsNames}  disabled={disabled}/>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered>
                        {delivery !== null &&
                          delivery.status !== 'validated' && (
                            <>
                              {delivery.ProofsOfDeliveries.length >= 1 && (
                                <ButtonValidationModalNoWrapper
                                  trgrContent='Validate the delivery'
                                  headerContent='Validate the delivery?'
                                  modalContent="By confirming, the delivery will be validated and you won't be able to invalidate it afterwards."
                                  trgrColor='blue'
                                  trgrIcon='check'
                                  noSubmit
                                  headerIcon='check'
                                  onClick={() => {
                                    validateTheDelivery();
                                  }}
                                  margin='5px'
                                />
                              )}
                              <Button
                                icon='bell'
                                type='button'
                                size='large'
                                color='blue'
                                content='Reminder'
                                margin='5px'
                                onClick={() =>
                                  setAskForProofsModal(delivery.id)
                                }
                              />
                            </>
                          )}
                          <Button
                            icon='download'
                            type='button'
                            size='large'
                            color='blue'
                            content='Download blank attestation'
                            margin='5px'
                            onClick={() => downloadAttestation(delivery.id)}
                          />
                          {delivery.customsRegime && (
                            <Button
                              icon='download'
                              type='button'
                              size='large'
                              color='blue'
                              content='Download customs regime invoice'
                              margin='5px'
                              onClick={() =>
                                downloadCustomsRegimeInvoice(delivery.id)
                              }
                            />
                          )}

                          {clientId && (delivery.Seller_Id !== 49) && delivery.customsDeclarationId && (
                            <Link
                            to={paths.customsDeclarationDetails
                              .replace(':companyId', (
                                delivery.Seller_Id === clientId) ? 
                                  delivery.Seller_Id : 
                                    (delivery.Buyer_Id === clientId) ? 
                                      delivery.Buyer_Id : 
                                        (delivery.Transporter_Id === clientId) ? 
                                          delivery.Transporter_Id : delivery.Buyer_Id)
                              .replace(':declarationId', delivery.customsDeclarationId[0].id)}
                            ><Button
                                icon='reply'
                                type='button'
                                size='large'
                                margin='5px'
                                content='go to Declaration'
                              /></ Link>
                          )}

                          {clientId && (delivery.Seller_Id === 49) && delivery.customsDeclarationId && customsCompanyId && (
                            <Link
                            to={paths.customsDeclarationDetails
                              .replace(':companyId', clientId)
                              .replace(':declarationId', delivery.customsDeclarationId[0].id)}
                            ><Button
                                icon='reply'
                                type='button'
                                size='large'
                                margin='5px'
                                content='go to Declaration'
                              /></ Link>
                          )}                        
                          {userAdminOrOp && delivery.customsDeclarationId && delivery.customsDeclarationId[0].id && (
                            <Link
                            to={paths.customsDeclarationDetails
                              .replace(':companyId', customsCompanyId)
                              .replace(':declarationId', delivery.customsDeclarationId[0].id)}
                            ><Button
                                icon='reply'
                                type='button'
                                size='large'
                                margin='5px'
                                content='go to Declaration'
                              /></ Link>
                          )}                                                                                          
                        </Grid.Row>                      
                      </Grid>
                    </Segment>
                  </Form>
                )}
              </Formik>                       
            ))}
            
            <Divider hidden /> 
            <Grid columns='2'>
            <Grid.Column>
            {(delivery && delivery.buyerToken !== null && delivery.buyerToken) && (
              <ClipBoard
                target={'Link: Buyer: '}
                link='buyer'
                token={delivery.buyerToken}
                  onClick={() => {
                    navigator.clipboard.writeText(`${process.env.REACT_APP_DEV_API_URL}/fiscal/quick-fixes/buyer/proof-management/${delivery.buyerToken}`);
                  }}
                ></ClipBoard>
              )}
            {(delivery && delivery.transporterToken !== null && delivery.transporterToken) && (
              <ClipBoard
                target={'Link: Transporter: '}
                link='transporter'
                token={delivery.transporterToken}
                onClick={() => {
                  navigator.clipboard.writeText(`${process.env.REACT_APP_DEV_API_URL}/fiscal/quick-fixes/transporter/proof-management/${delivery.transporterToken}`);
                }}
              ></ClipBoard>
            )}
            </Grid.Column>
            </Grid>             
            <UploadDocumentModal
              dispatch={dispatch}
              deliveryId={match.params.deliveryId}
              isModalOpen={isModalOpen}
              handleClose={handleClose}
              companyId={companyId}
              companyType={companyType}
            />
            {deliveryInformations && (<SplitDocumentModal 
              dispatch={dispatch}           
              isSplitOpen={isSplitOpen}
              deliveryId={match.params.deliveryId}
              storage={deliveryInformations.storage}
              handleClose={handleSplitClose}
              companyId={companyId}
              companyType={companyType}
            />)}
          <Divider hidden />
          {(companyType === null && allProofs === null && updates === true && <Loader />) ||
            (companyType !== null && allProofs !== null && !updates && (
              <>
                <Segment>
                  <ProofsManager
                    companyType={companyType}
                    proofs={allProofs}
                    actorsId={actorsId}
                    buttonsColor={'blue'}
                    history={history}
                    user={user}
                    deliveryId={match.params.deliveryId}
                    companyId={companyId}
                  />
                  <Divider hidden />
                  <Button
                    icon='upload'
                    type='button'
                    size='large'
                    color='blue'
                    content='Upload a document'
                    margin='5px'
                    onClick={() => setIsModalOpen(true)}
                  />
                   <Button
                    icon='upload'
                    type='button'
                    size='large'
                    color='blue'
                    content='Split document'
                    margin='5px'
                    onClick={() => setIsSlitOpen(true)}
                  />
                </Segment>
                <Divider hidden />
                <Button
                  icon='download'
                  type='button'
                  size='large'
                  color='blue'
                  content='Download all validated documents in one .zip'
                  margin='5px'
                  onClick={downloadAll}
                />
              </>
            ))}
        </Container>
      </Container>
      <Divider hidden />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    hasSubscribedTo: selectors.company.hasSubscribedToSelector(state),
    hasSubscribedToLoading: selectors.company.hasSubscribedToLoadingSelector(state),
    vat: selectors.quickfixes.setVatSelector(state),
    vatLoading: selectors.quickfixes.setVatLoadingSelector(state),
    vatError: selectors.quickfixes.setVatErrorSelector(state),
    company: selectors.company.companySelector(state),
    delivery: selectors.quickfixes.oneDeliverySelector(state),
    customs: selectors.customsDeclaration.getOneByReferenceSelector(state),
    customsLoading: selectors.customsDeclaration.getOneByReferenceLoadingSelector(state),
    loadingHandlerQuickFixes: selectors.quickfixes.loadingSelector(state),
    companySelected: selectors.auth.companySelected(state),
    downloadLoading: selectors.quickfixes.downloadLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
