export default {
  "intrastat.deb": "GOODS",
  "intrastat.des": "SERVICES",
  "intrastat.expedition": "Dispatches",
  "intrastat.introduction": "Arrivals",
  "intrastat.credits": "Credit",
  "intrastat.non_conforme": "Not compliant",
  "intrastat.none": "None",
  "intrastat.specifications": "Customer specifications",
  "intrastat.addIntranetRef": "add intranet reference",
  "intrastat.follow": "HandleFollow",
  "intrastat.unfollow": "Unfollow",
  "intrastat.Close": "Close",
  "intrastat.close": "close",
  "intrastat.addDocs": "Add documents",
  "intrastat.fileName": "File name",
  "intrastat.category": "Category",
  "intrastat.date": "Date",
  "intrastat.actions": "Actions",
  "intrastat.control": "Control",
  "intrastat.show": "Show",
  "intrastat.delete": "delete",
  "intrastat.Delete": "Delete",
  "intrastat.validation": "Validation",
  "intrastat.operator": "Operator",
  "intrastat.company": "Company:",
  "intrastat.error": "Error",
  "intrastat.regularization": "regularization",
  "intrastat.filingPeriod": "Filing period:",
  "intrastat.filingCountry": "Filing country:",
  "intrastat.flow": "Flow:",
  "intrastat.createdAt": "Created at:",
  "intrastat.emitter": "Emitter:",
  "intrastat.followers": "Followers:",
  "intrastat.clientReference": "Client reference:",
  "intrastat.intranetReference": "Intranet reference:",
  "intrastat.purchaseOrder": "PO",
  "intrastat.doc.action": "{decision}, {action} the document",
  "intrastat.yes": "Yes",
  "intrastat.no": "No",
  "intrastat.validation.alert": "Do you confirm the validation of this document?",
  "intrastat.deletion.alert": "Do you confirm the deletion of this document?",
  "intrastat.doc.validation": "",
  "intrastat.editData": "Edit data",
  "intrastat.reportingPeriod": "Reporting period",
  "intrastat.declarationType": "Declaration type",
  "intrastat.cDType": "Choose declaration type...",
  "intrastat.cFlow": "Choose flow type...",
  "intrastat.choose": "Choose...",
  "intrastat.submit": "Submit",
  "intrastat.edit": "Edit {declarationType}",
  "intrastat.action.Open": "Open",
  "intrastat.action.open": "open",
  "intrastat.action.Close": "Close",
  "intrastat.action.close": "close",
  "intrastat.detail.cancel": "Cancel declaration",
  "intrastat.detail.open": "Open declaration",
  "intrastat.detail.Close": "Close declaration",
  "intrastat.addIntranetReference": "Add intranet reference",
  "intrastat.intranetRefRequired": "Intranet reference is required",
  "intrastat.closeIntrastat.header": "{openOrClose} {declarationType}",
  "intrastat.closeIntrastat": "Are you sure you want to {status} this declaration ?",
  "intrastat.closeIntrastat.alert": "You will no longer be allowed to make changes",
  "intrastat.reason": "Reason for cancellation",
  "intrastat.lReason": "Reason",
  "intrastat.addDocsArs": "Add {declarationType} documents",
  "intrastat.pCategory": "Category...",
  "intrastat.allowedFiles": "Allowed files: compressed .zip files, .pdf, .xlsx, .xls, and .jpg",
  "intrastat.drop": "Drag and drop your files, or click to select your files",
  "intrastat.lSplitDoc": "Split document",
  "intrastat.split.info": 'Split and send PDF Documents to any repositories. Don\'t delete your local stored list data until you decide to definitly use the function "Split document"',
  "intrastat.split.header": "Manage tags : {id} {IntrastatFlow}",
  "intrastat.split.start": "Start",
  "intrastat.split.pnumber": "Number...",
  "intrastat.split.end": "End",
  "intrastat.split.name": "Name",
  "intrastat.split.pname": "Type name...",
  "intrastat.split.docCategory": "Document category: {type}",
  "intrastat.split.pdocCategory": "Document category...",
  "intrastat.split.lDeclaCat": "Tag name",
  "intrastat.split.pDeclaCat": "Declaration category...",
  "intrastat.split.from": "From page:",
  "intrastat.split.to": "to:",
  "intrastat.split.sendTo": "- Sent to:",
  "intrastat.split.btn": "Split document",
  "intrastat.tag.header": "Manage tags : {flow} {type}",
  "intrastat.tag.empty": "Empty",
  "intrastat.monit.displayedWithFilters": "Numbers of declarations displayed with filter:",
  "intrastat.monit.searchFilters": "Search filters",
  "intrastat.monit.pOperator": "Operator...",
  "intrastat.monit.pFilingPeriod": "Filing period...",
  "intrastat.monit.pCountry": "Country...",
  "intrastat.search.pIntrastatReference": "Intrastat reference...",
  "intrastat.search.pCompany": "Company...",
  "intrastat.search.pType": "Type...",
  "intrastat.search.pFlow": "Flow...",
  "intrastat.search.pIntranetReference": "Intranet reference...",
  "intrastat.search.pFilingPeriod": "Filing period...",
  "intrastat.search.pArchives": "Archives",
  "intrastat.search.apply": "Apply",
  "intrastat.search.reset": "Reset",
  "intrastat.note.close": "Close",
  "intrastat.table.FilingPeriod": "Filing period",
  "intrastat.table.Country": "Country",
  "intrastat.table.CreatedAt": "Created at",
  "intrastat.table.Customer": "Customer",
  "intrastat.table.Type": "Type",
  "intrastat.table.Flow": "Flow",
  "intrastat.table.Emitter": "Emitter",
  "intrastat.table.Followers": "Followers",
  "intrastat.table.State": "State",
  "intrastat.table.Dashboard": "Dashboard",
  "intrastat.prog.cancelled": "Cancelled",
  "intrastat.prog.0": "Awaiting processing",
  "intrastat.prog.1": "ASD Compliance",
  "intrastat.prog.2": "Customer validation",
  "intrastat.prog.3": "Completed processing",
  "intrastat.prog.header": "progress",
  "intrastat.newShort": 'New declaration',
  "intrastat.new": 'New intrastat',
  "intrastat.new.None": 'None',
  "intrastat.new.Reg": 'Regularization',
  "intrastat.new.DeclaType": 'Declaration type',
  "intrastat.new.FlowType": 'Flow type',
  "intrastat.new.Period": 'Reporting period',
  "intrastat.new.FilingCountry": 'Filing country',
  "intrastat.new.ClientRef": 'Filing country',
  "intrastat.new.pDeclaType": 'Choose declaration type...',
  "intrastat.new.pFlowType": 'Choose flow...',
  "intrastat.new.pFilingCountry": 'Choose...',
  "intrastat.new.pClientRef": 'REF5765...',
  "intrastat.monit.subHeader": 'Intra-stats (operators)',
  "intrastat.monit.goToMonitoring": 'Got to monitoring',
  "intrastat.monit.Name": 'Name',
  "intrastat.monit.Email": 'E-mail',
  "intrastat.monit.Country": 'Country',
  "intrastat.monit.Code": 'Code',
};
