// Dependencies
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import NavbarDropdownProperties from '../../interfaces/navbarDropdownProperties';

// Stylesheets
import './navbarDropdown.css';

/**
 * @name navbarDropdown.tsx
 * @implements navbarDropdownProperties.tsx
 *
 * @path /components/navbarDropdown/navbarDropdown.tsx
 *
 * @description Defines a dropdown button in header's navbar
 *
 * @param title button's title
 * @param options button's options
 * @param isRight is button aligns on the right side of the page or not
 */
const NavbarDropdown: React.FC<NavbarDropdownProperties> = ({
  title,
  options,
  isRight,
  isHidden,
  createCompany,
}) => {
  // Defines the classname of dropdown-options thanks to isRight property.
  const classname = isRight
    ? 'dropdown-options-right'
    : 'dropdown-options-left';

  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  // References to get an access to button and options
  const buttonRef = useRef<HTMLButtonElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  // Setters for dropdown button's visibility
  const [isOpen, setIsOpen] = useState(false);

  // Defines the action that must be executed when the user performed a click on an option
  const handleItemClick = (action: () => void) => {
    action();
    setIsOpen(false);
  };

  // Defines the action that must be performed when the user clicks outside of the component
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      buttonRef.current &&
      optionsRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  // Configuration of page's current state when handleOutsideClick is performed
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <>
      {!isHidden && (
        <div className='dropdown'>
          <button
            ref={buttonRef}
            className={
              createCompany
                ? 'dropdown-button-create-company'
                : 'dropdown-button'
            }
            onClick={() => setIsOpen(!isOpen)}
          >
            {title} <p>{isOpen ? '▲' : '▼'}</p>
          </button>
          {isOpen && (
            <div className={classname}>
              {options.map((option) => (
                <div
                  ref={optionsRef}
                  key={option.label}
                  className='dropdown-option'
                  onClick={() => handleItemClick(option.action)}
                >
                  {option.flag}
                  {t(option.label)}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NavbarDropdown;
