import React, { useState } from 'react';
import { useField } from '@formiz/core';

import TextAreaInput from 'components/core/TextAreaInput/TextAreaInput';

const FormizTextAreaInput = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const { errorMessage, isValid, isSubmitted, setValue, value } = useField(
    props
  );
  const { name, label, placeholder, required, disabled, autoFocus } = props;
  const showError = !isValid && (isTouched || isSubmitted);

  return (
    <TextAreaInput
      autoFocus={autoFocus}
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => setIsTouched(true)}
      required={required}
      disabled={disabled}
      error={showError && errorMessage}
    />
  );
};

export default FormizTextAreaInput;
