import QuickFixes from "views/Fiscal/QuickFixes";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.quickFixes,
    component: QuickFixes,
    exact: true,
    authorizedRoles: [],
  },
];
