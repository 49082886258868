import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button as Btn, Container, Divider, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import ButtonValidationModal from 'components/ButtonValidationModal';
import { Main } from 'components/Layouts';
import Loader from 'components/Loader';
import Search from 'components/Search';
import Wrapper from 'components/core/Wrapper/Wrapper';

const Index = ({ dispatch, users, history, loadingHandlerusers, user }) => {
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [resetSearch, setresetSearch] = useState(false);

  const userAdminOrOp = user.operator || user.admin;

  useEffect(() => {
    dispatch(actions.user.getUsers());
    dispatch(actions.socket.subscribeUpdateUsers());
  
    return () => {
      dispatch(actions.user.reset(['users', 'error']));
      dispatch(actions.socket.unsubscribeUpdateUsers());
    };
  }, [dispatch]);

  useEffect(() => {
    if(!userAdminOrOp){
      history.push({pathname: `/`})
    }
  }, [userAdminOrOp, history])

  useEffect(() => {
    setdata(_.orderBy(users, ['lastName'], ['desc']));
    resetSearch && setresetSearch(false);
  }, [users, resetSearch]);

  useEffect(() => {
    if(!userAdminOrOp){
      history.push({pathname: `/`})
    }
  }, [userAdminOrOp, history])

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['name'], ['asc']));
  };

  const handleSort = (lastName) => {
    if (column !== lastName) {
      setcolumn(lastName);
      setdirection('descending');
      setdata(_.sortBy(data, [lastName]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const handleDelete = (userId) => {
    dispatch(actions.user.deleteUser({ params: { userId } }));
  };

  const superAdminSwitchUser = (userEmail) => {
    dispatch(actions.auth.login({ data: { email: userEmail, type: 'bypass'} }));
}

  return (
    <Main>
      <Divider hidden />
      {(data.length === 0 && loadingHandlerusers && <Loader />) || (
        <Container>
          <Wrapper>
            <Search
              dataSource={users}
              onSearchResultChange={onSearchResultChange}
              setResetSearch={setresetSearch}
            />
          </Wrapper>

          <Table size='large' columns='6' sortable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'lastName' ? direction : null}
                  onClick={() => handleSort('lastName')}
                >
                  Last name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'firstName' ? direction : null}
                  onClick={() => handleSort('firstName')}
                >
                  First name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'email' ? direction : null}
                  onClick={() => handleSort('email')}
                >
                  E-mail
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'admin' ? direction : null}
                  onClick={() => handleSort('admin')}
                >
                  Admin
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'operator' ? direction : null}
                  onClick={() => handleSort('operator')}
                >
                  Operator
                </Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((user, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{user.lastName}</Table.Cell>
                    <Table.Cell>{user.firstName}</Table.Cell>
                    <Table.Cell>
                      {user.email}
                        {userAdminOrOp && (
                            <Button
                            content='Switch'
                            icon='edit'
                            type='submit'
                            labelPosition='right'
                            onClick={async (e) =>
                              await superAdminSwitchUser(user.email)                          
                            }
                            margin='5px'
                            />
                        )}
                      </Table.Cell>
                    <Table.Cell>{user.admin ? 'Yes' : 'No'}</Table.Cell>
                    <Table.Cell>{user.operator ? 'Yes' : 'No'}</Table.Cell>
                    <Table.Cell>
                      <Btn.Group vertical widths='two'>
                        <Button
                          content='Edit'
                          icon='edit'
                          type='submit'
                          labelPosition='right'
                          onClick={() =>
                            history.push(`/admin/users/edit/${user.id}`)
                          }
                          margin='5px'
                        />
                        <Button
                          content='Show'
                          icon='eye'
                          type='submit'
                          labelPosition='right'
                          onClick={() =>
                            history.push(`/admin/users/${user.id}`)
                          }
                          margin='5px'
                        />
                        <ButtonValidationModal
                          trgrContent='Delete'
                          headerContent='Delete'
                          modalContent='Do you confirm the deletion of this client?'
                          trgrColor='red'
                          trgrIcon='trash'
                          headerIcon='trash'
                          onClick={() => {
                            handleDelete(user.id);
                          }}
                          margin='5px'
                        />
                      </Btn.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Container>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    users: selectors.user.usersSelector(state),
    errorHandlerusers: selectors.user.errorSelector(state),
    loadingHandlerusers: selectors.user.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
