import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Tab,
  Container,
  Table,
  Modal,
  Header,
  Divider,
} from 'semantic-ui-react';

import TableSpacedRowsSmall from 'components/TableSpacedRowsSmall';
import EmptyTable from 'components/EmptyTableCurve';
import Wrapper from 'components/core/Wrapper/Wrapper';
import ClickableText from 'components/ClickableText';
import TextCustom from 'components/core/TextCustom/TextCustom';
import Button from 'components/Button';
import Payzen from 'components/Payzen';

const Index = ({ company, invoices, dispatch, paymentToken, user }) => {
  const isInternal = user.admin || user.operator || user.superOperator;

  const [paymentTokenForm, setPaymentTokenForm] = useState(false);

  useEffect(() => {
    setPaymentTokenForm(false);
    dispatch(
      actions.companyInvoice.getAll({
        params: { companyId: company.id },
      })
    );
    dispatch(actions.socket.subscribeUpdateInvoiceCompany(company.id));

    return () => {
      dispatch(actions.socket.unsubscribeUpdateInvoiceCompany(company.id));
      dispatch(actions.companyInvoice.reset(['invoices', 'error']));
      dispatch(actions.companyInvoice.reset(['paymentToken', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    paymentToken && setPaymentTokenForm(paymentToken);
  }, [paymentToken]);

  const downloadInvoice = (invoiceId) => {
    dispatch(
      actions.companyInvoice.getOne({
        params: { companyId: company.id, invoiceId: invoiceId },
      })
    );
  };

  const handlePay = (invoiceId) => {
    dispatch(
      actions.companyInvoice.payInvoice({
        params: { companyId: company.id, invoiceId },
      })
    );
  };

  const handleCredit = (invoiceId) => {
    dispatch(
      actions.companyInvoice.createCredit({
        params: { companyId: company.id, invoiceId },
      })
    );
  }

  const handleClosePay = () => {
    setPaymentTokenForm(false);
    dispatch(actions.companyInvoice.reset(['paymentToken']));
  };

  return (
    <Tab.Pane loading={!company}>
      <Container>
        {paymentTokenForm && (
          <Modal open={paymentTokenForm} onClose={handleClosePay} closeIcon>
            <Header textAlign='center'>Pay the invoice:</Header>
            <Divider hidden />
            <Payzen formToken={paymentTokenForm} onSubmit={handleClosePay} />
          </Modal>
        )}
        <Wrapper padding='0 5rem'>
          <TableSpacedRowsSmall trHeight='3rem'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Invoice id</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Total excl. tax</Table.HeaderCell>
                <Table.HeaderCell>Tax</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell>Payment date</Table.HeaderCell>
                <Table.HeaderCell>Paid</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(invoices && invoices.length === 0 && (
                <EmptyTable colNumber={6} />
              )) ||
                (invoices &&
                  invoices.map((invoice, i) => {          
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>
                          <ClickableText
                            onClick={() => downloadInvoice(invoice.invoiceId)}
                            color='hyperlink'
                          >
                            {invoice.invoiceId}
                          </ClickableText>
                        </Table.Cell>
                        <Table.Cell>
                          {new Date(invoice.invoiceDate).toLocaleDateString(
                            'en',
                            {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {invoice.Order.totalHT.toFixed(2)}€
                        </Table.Cell>
                        <Table.Cell>{invoice.Order.vat.toFixed(2)}€</Table.Cell>
                        <Table.Cell>
                          <TextCustom fontWeight='bold'>
                            {(
                              invoice.Order.vat + invoice.Order.totalHT
                            ).toFixed(2)}
                            €
                          </TextCustom>
                        </Table.Cell>
                        <Table.Cell>
                          {invoice.paymentDate &&
                            new Date(invoice.paymentDate).toLocaleDateString(
                              'en',
                              {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              }
                            )}
                        </Table.Cell>
                        <Table.Cell>{invoice.payed ? 'yes' : 'no'}</Table.Cell>
                      
                          <Table.Cell>
                            {!invoice.payed && (
                              <Button
                                content='Pay'
                                icon='payment'
                                onClick={() => handlePay(invoice.invoiceId)}
                              />
                            )}
                            {invoice.payed && isInternal && (!/C/.test(invoice.invoiceId)) && (
                               <Button
                               content='Credit'
                               icon='payment'
                               onClick={() => handleCredit(invoice.invoiceId)}
                             />
                            )
                            }
                          </Table.Cell>
                   
                      </Table.Row>
                    );
                  }))}
            </Table.Body>
          </TableSpacedRowsSmall>
        </Wrapper>
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.data.allCountriesSelector(state),
    invoices: selectors.companyInvoice.invoices(state),
    paymentToken: selectors.companyInvoice.paymentToken(state),
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
