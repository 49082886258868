import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Divider, Transition, Message } from 'semantic-ui-react';
import { Field, FieldArray, Formik, ErrorMessage } from 'formik';

import Wrapper from 'components/core/Wrapper/Wrapper';
import { Error } from 'components/Form';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const Index = ({
  intl,
  company,
  dispatch,
  countries,
  countriesLoading,
  euCountries,
}) => {
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [countriesList, setcountriesList] = useState([]);

  useEffect(() => {
    dispatch(actions.companyCollectionBoxSettings.getAssignedCountries({
      params: { companyId: company.id }
    }));
    dispatch(actions.data.getEuCountries());

    dispatch(actions.socket.subscribeGetCollectionBoxAssignedCountry(company.id))

    return () => {
      dispatch(actions.socket.unsubscribeGetCollectionBoxAssignedCountry(company.id))
      dispatch(actions.companyCollectionBoxSettings.reset(['countries', 'error']));
      dispatch(actions.data.reset(['euCountries', 'error']));
    };
  }, [dispatch, company]);


  useEffect(() => {
    if(countries){
      let tab: any = []
      const companyId = company.id
      for(const c of countries){
        tab.push(
          {
            Company_Id: companyId,
            Country_Id: c.Country_Id, 
            name: c.name, 
            iso: c.iso
          })
      }
      setSelectedCountries(tab)
      }else{
      setSelectedCountries(null)
      }  
  }, [countries, company]);

  useEffect(() => {
    if(euCountries !== null){
      const companyId = company.id
      setcountriesList(
        euCountries.map((c, index) => {
          return {
            key: index,
            value:  {
              Company_Id: companyId,
              Country_Id: c.id, 
              name: c.country_name, 
              iso: c.country_code
            },
            text: intl.formatMessage({
              id: `countries.name.${c.country_code}`,
            }),
            flag: c.country_code.toLowerCase(),
          };
        })
      );
    }
  }, [euCountries, intl, company]);


  const updateAssignedCountries = (values: any) => {
    dispatch(
      actions.companyCollectionBoxSettings.updateAssignedCountries({
        params: { companyId: company.id },
        data: {
          countries: values.countries
        },
      })
    );
  };


  return (
    <Wrapper  
      display='flex'
      justifyContent='center'
      height='400px'
      padding='2rem 10rem 5rem'
    >
    <Wrapper 
      padding='0' 
      display='flex' 
      flexDirection='column' 
      alignItems='center'
      width='300px'
    >
    <Formik
      initialValues={{
        countries: selectedCountries || [],
      }}
      enableReinitialize={true}
      onSubmit={updateAssignedCountries}
    >
      {({
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
      }: any) => (<>
          <Form onSubmit={handleSubmit} loading={countriesLoading}>         
            <FieldArray
              name='countries'
              render={(arrayHelpers) => (
                <>
                <Transition.Group
                  duration={200}
                > 
                 
                             
                  {values.countries.map((country: any, index) => {
                    return (          
                    <Wrapper 
                      key={`a-${index}`} 
                      display='flex' 
                      width='300px' 
                      height='65px' 
                      alignItems='center' 
                      justifyContent='space-between' 
                      flexWrap='nowrap'
                    >  
                      <Wrapper 
                        key={`b-${index}`} 
                        width='300px' 
                        margin='0 10px 0 0'
                      >                         
                      <Field
                        key={`c-${index}`}
                        placeholder='select country...'
                        name={`countries[${index}]`}
                        component={Dropdown}
                        options={countriesList}
                        loading={countriesLoading}
                        value={country}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                      />
                      </Wrapper>
                      <Button
                        icon='minus'
                        type='button'
                        color='red'
                        onClick={() => {
                          arrayHelpers.remove(index)
                        }}
                        maxHeight='2.7rem'
                      />                   
                    </Wrapper>
                  );
                })}
              </Transition.Group>
              {!selectedCountries && (
                <Message info>In whitch country are you .....</Message>
              )}
              <Divider hidden/>
              <Button
                icon='plus'
                type='button'
                onClick={() =>                       
                    arrayHelpers.push({
                      file: undefined,
                      id: undefined,
                      number: '',
                    })                           
                }
                margin='0px'
                position='center'
              /> 
            </>
          )}                                            
          />            
      
            <ErrorMessage
              name='countries'
              render={(msg) => <Error msg={msg} />}
            />
            <Divider hidden />
            <Button type='submit' content='Submit' margin='0px' />
          </Form>
          <Divider hidden/>   
        </>
      )}
    </Formik>
    </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    euCountries: selectors.data.euCountriesSelector(state),
    euCountriesLoading: selectors.data.euCountriesLoadingSelector(state),
    countries: selectors.companyCollectionBoxSettings.getCountriesSelector(state),
    countriesLoading: selectors.companyCollectionBoxSettings.getCountriesLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
