import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  contact(data: dataReqI) {
    return {
      type: actionTypes.CONTACT,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
