import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

const createErrorSelector = (state) => state.service.errors.create;
const getOneErrorSelector = (state) => state.service.errors.getOne;
const updateErrorSelector = (state) => state.service.errors.update;
const getAllErrorSelector = (state) => state.service.errors.getAll;
const removeErrorSelector = (state) => state.service.errors.remove;
const createProductErrorSelector = (state) =>
  state.service.errors.createProduct;
const getOneProductErrorSelector = (state) =>
  state.service.errors.getOneProduct;
const updateProductErrorSelector = (state) =>
  state.service.errors.updateProduct;
const getProductOfServiceErrorSelector = (state) =>
  state.service.errors.getProductOfService;
const getProductOfServiceSubErrorSelector = (state) =>
  state.service.errors.getProductOfServiceSub;
const removeProductErrorSelector = (state) =>
  state.service.errors.removeProduct;

const errorsSelector = createErrorsSelector([
  createErrorSelector,
  getOneErrorSelector,
  updateErrorSelector,
  getAllErrorSelector,
  removeErrorSelector,
  createProductErrorSelector,
  getOneProductErrorSelector,
  updateProductErrorSelector,
  getProductOfServiceErrorSelector,
  removeProductErrorSelector,
  getProductOfServiceSubErrorSelector,
]);

// Loading
const createServiceLoadingSelector = (state) => state.service.loading.create;
const getOneLoadingSelector = (state) => state.service.loading.getOne;
const updateLoadingSelector = (state) => state.service.loading.update;
const getAllLoadingSelector = (state) => state.service.loading.getAll;
const removeLoadingSelector = (state) => state.service.loading.remove;
const createProductLoadingSelector = (state) =>
  state.service.loading.createProduct;
const getOneProductLoadingSelector = (state) =>
  state.service.loading.getOneProduct;
const updateProductLoadingSelector = (state) =>
  state.service.loading.updateProduct;
const getProductOfServiceLoadingSelector = (state) =>
  state.service.loading.getProductOfService;
const removeProductLoadingSelector = (state) =>
  state.service.loading.removeProduct;
const getProductOfServiceSubLoadingSelector = (state) =>
  state.service.loading.getProductOfServiceSub;

const loadingSelector = createLoadingSelector([
  createServiceLoadingSelector,
  getOneLoadingSelector,
  updateLoadingSelector,
  getAllLoadingSelector,
  removeLoadingSelector,
  createProductLoadingSelector,
  getOneProductLoadingSelector,
  updateProductLoadingSelector,
  getProductOfServiceLoadingSelector,
  removeProductLoadingSelector,
  getProductOfServiceSubLoadingSelector,
]);

// Success
const createServiceSuccessSelector = (state) => state.service.success.create;
const getOneSuccessSelector = (state) => state.service.success.getOne;
const updateSuccessSelector = (state) => state.service.success.update;
const getAllSuccessSelector = (state) => state.service.success.getAll;
const removeSuccessSelector = (state) => state.service.success.remove;
const createProductSuccessSelector = (state) =>
  state.service.success.createProduct;
const getOneProductSuccessSelector = (state) =>
  state.service.success.getOneProduct;
const updateProductSuccessSelector = (state) =>
  state.service.success.updateProduct;
const getProductOfServiceSuccessSelector = (state) =>
  state.service.success.getProductOfService;
const removeProductSuccessSelector = (state) =>
  state.service.success.removeProduct;
const getProductOfServiceSubSuccessSelector = (state) =>
  state.service.success.getProductOfServiceSub;

const successSelector = createSuccessSelector([
  createServiceSuccessSelector,
  getOneSuccessSelector,
  updateSuccessSelector,
  getAllSuccessSelector,
  removeSuccessSelector,
  createProductSuccessSelector,
  getOneProductSuccessSelector,
  updateProductSuccessSelector,
  getProductOfServiceSuccessSelector,
  removeProductSuccessSelector,
  getProductOfServiceSubSuccessSelector,
]);

// Service
const serviceSelector = (state) => state.service.service;

// Services
const servicesSelector = (state) => state.service.services;

// Product
const productSelector = (state) => state.service.product;

// productsOfService
const productsOfServiceSelector = (state) => state.service.productsOfService;

// productsOfServiceSub
const productsOfServiceSubSelector = (state) =>
  state.service.productsOfServiceSub;

// Error handler
const errorSelector = (state) => state.service.error;

// loading
const serviceLoadingSelector = (state) => state.service.loading.getService;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  serviceSelector,
  servicesSelector,
  errorSelector,
  serviceLoadingSelector,
  productSelector,
  productsOfServiceSelector,
  productsOfServiceSubSelector,
};
