import styled from '@emotion/styled';
import { Button } from 'semantic-ui-react';
import theme from 'theme';

const colors = theme.getTheme().colors;

const applyColor = (props) => {
  switch (props.btncolor) {
    case 'blue':
      return colors.mainButton;
    case 'red':
      return colors.deleteButton;
    default:
      return colors.mainButton;
  }
};

const applyHoverColor = (props) => {
  switch (props.btncolor) {
    case 'blue':
      return colors.mainButtonHover;
    case 'red':
      return colors.deleteButtonHover;
    default:
      return colors.mainButtonHover;
  }
};

export const ButtonStyled: any = styled(Button)`
  &&& {
    margin: 0;
    width: 30%;
    text-align: ${(props) => applyColor(props)};
    background-color: #0a326d;
    color: #ffff;
    border-radius: 5px 0 0 5px;
  }
  &&&:hover {
    background-color: ${(props) => applyHoverColor(props)};
    border-radius: 5px 0 0 5px;
    color: #ffff;
  }
`;

export const Wrapper = styled.div(({ error }: any) => ({
  margin: '0',
  display: 'flex',
  cursor: 'pointer',
  border: 'solid 1px rgb(204 204 204)',
  borderRadius: '5px',
  backgroundColor: 'white',
  ...(error && { backgroundColor: '#fff6f6', color: '#9f3a38' }),
}));

export const ChosenFile = styled.span(() => ({
  width: '70%',
  textAlign: 'center',
  margin: 'auto',
}));

export const File = styled.input(() => ({
  display: 'none',
}));
