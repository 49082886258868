import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getAllErrorSelector = (state) => state.companyInvoice.errors.getAll;
const getOneErrorSelector = (state) => state.companyInvoice.errors.getOne;
const payInvoiceErrorSelector = (state) => state.companyInvoice.errors.payInvoice;
const createCreditErrorSelector = (state) => state.companyInvoice.errors.createCredit;

const errorsSelector = createErrorsSelector([
  getAllErrorSelector,
  getOneErrorSelector,
  payInvoiceErrorSelector,
  createCreditErrorSelector,
]);

// Loading
const getAllLoadingSelector = (state) => state.companyInvoice.loading.getAll;
const getOneLoadingSelector = (state) => state.companyInvoice.loading.getOne;
const payInvoiceLoadingSelector = (state) =>
  state.companyInvoice.loading.payInvoice;
const createCreditLoadingSelector = (state) => state.companyInvoice.loading.createCredit;

const loadingSelector = createLoadingSelector([
  getAllLoadingSelector,
  getOneLoadingSelector,
  payInvoiceLoadingSelector,
  createCreditLoadingSelector,
]);

// Success
const getAllSuccessSelector = (state) => state.companyInvoice.success.getAll;
const getOneSuccessSelector = (state) => state.companyInvoice.success.getOne;
const payInvoiceSuccessSelector = (state) =>
  state.companyInvoice.success.payInvoice;
const createCreditSuccessSelector = (state) => state.companyInvoice.success.createCredit;

const successSelector = createSuccessSelector([
  getAllSuccessSelector,
  getOneSuccessSelector,
  payInvoiceSuccessSelector,
  createCreditSuccessSelector,
]);

const invoices = (state) => state.companyInvoice.invoices;
const invoice = (state) => state.companyInvoice.invoice;
const paymentToken = (state) => state.companyInvoice.paymentToken;
const unpaidInvoices = (state) => state.companyInvoice.unpaidInvoices;
const createCreditSelector = (state) => state.companyInvoice.createCredit;

const errorSelector = (state) => state.companyInvoice.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  invoices,
  invoice,
  errorSelector,
  paymentToken,
  unpaidInvoices,
  createCreditSelector,
};
