import React from 'react';

import { FooterStyled, BgBottom } from './styles';
import Wrapper from 'components/core/Wrapper/Wrapper';
import ClickableText from 'components/ClickableText';

const Index = (props: any) => {
  return (
    <>
      <BgBottom></BgBottom>
      <FooterStyled>
        {props.user && (
          <Wrapper position='absolute'>
            <ClickableText
              color='white'
              onClick={() => {
                props.history.push('/about');
              }}
            >
              About
            </ClickableText>
          </Wrapper>
        )}
        Copyright © ASD Group {new Date().getUTCFullYear()} - All rights reserved
      </FooterStyled>
    </>
  );
};

export default Index;
