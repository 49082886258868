
import CompaniesMonitoring from 'views/Fiscal/Collectionbox/Operator/CompaniesMonitoring';
import Monitoring from 'views/Fiscal/Collectionbox/Monitoring';
import MainMonitoring from 'views/Fiscal/Collectionbox/Operator/MainMonitoring';
import SubscribedCompaniesIntrastat from 'views/Fiscal/Collectionbox';
import CollectionboxDetails from 'views/Fiscal/Collectionbox/CollectionboxDetails';
import CompanyService from 'views/Fiscal/Collectionbox/Operator/Service/CompanyService';
import paths from "./paths";


export default [
  {
    private: true,
    path: paths.collectionbox,
    component: SubscribedCompaniesIntrastat,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.collectionboxMonitoring,
    component: MainMonitoring,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.collectionboxList,
    component: Monitoring,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.collectionboxCountryList,
    component: CompanyService,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.collectionboxCompanyList,
    component: Monitoring,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.companiescollectionboxDetails,
    component: CollectionboxDetails,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.collectionboxDetails,
    component: CollectionboxDetails,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.collectionboxDetails,
    companent: CollectionboxDetails,
    exact: true,
    authorizedRoles: [],
  },
];
