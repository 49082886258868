import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import {
  SegmentContent,
  PlaceholderLeft,
  PlaceholderRight,
  Separation,
} from './notificationPlaceholderStyles';

const NotificationPlaceholder = () => {
  return (
    <>
      <SegmentContent raised>
        <PlaceholderLeft>
          <Placeholder.Header>
            <Placeholder.Line length='medium' />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length='full' />
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='full' />
          </Placeholder.Paragraph>
        </PlaceholderLeft>
        <PlaceholderRight>
          <div>
            <Placeholder.Image square />
            <Separation />
            <Placeholder.Image square />
          </div>
        </PlaceholderRight>
      </SegmentContent>
      <SegmentContent raised>
        <PlaceholderLeft>
          <Placeholder.Header>
            <Placeholder.Line length='medium' />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length='full' />
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='full' />
          </Placeholder.Paragraph>
        </PlaceholderLeft>
        <PlaceholderRight>
          <div>
            <Placeholder.Image square />
            <Separation />
            <Placeholder.Image square />
          </div>
        </PlaceholderRight>
      </SegmentContent>
      <SegmentContent raised>
        <PlaceholderLeft>
          <Placeholder.Header>
            <Placeholder.Line length='medium' />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length='full' />
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='full' />
          </Placeholder.Paragraph>
        </PlaceholderLeft>
        <PlaceholderRight>
          <div>
            <Placeholder.Image square />
            <Separation />
            <Placeholder.Image square />
          </div>
        </PlaceholderRight>
      </SegmentContent>
      <SegmentContent raised>
        <PlaceholderLeft>
          <Placeholder.Header>
            <Placeholder.Line length='medium' />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length='full' />
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='full' />
          </Placeholder.Paragraph>
        </PlaceholderLeft>
        <PlaceholderRight>
          <div>
            <Placeholder.Image square />
            <Separation />
            <Placeholder.Image square />
          </div>
        </PlaceholderRight>
      </SegmentContent>
    </>
  );
};

export default NotificationPlaceholder;
