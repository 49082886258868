import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const getAllStatusErrorSelector = state => state.status.errors.getAllStatus;

const errorsSelector = createErrorsSelector([getAllStatusErrorSelector]);


// Loading
const getAllStatusLoadingSelector = state => state.status.loading.getAllStatus;

const loadingSelector = createLoadingSelector([ 
  getAllStatusLoadingSelector
]);

// Success
const getAllStatusSuccessSelector = state => state.status.success.getAllStatus;

const successSelector = createSuccessSelector([getAllStatusSuccessSelector]);

// Countries
const allStatusSelector = state => state.status.allStatus;



// Error handler
const errorSelector = state => state.status.error;


export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  errorSelector,
  allStatusSelector,
};
