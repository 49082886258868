import styled from '@emotion/styled';
import theme from 'theme';

const colors = theme.getTheme().colors;

const selectColor = (color) => {
  return Object.keys(colors).includes(color) ? colors[color] : color;
};

export const WrapperStyled = styled.div(({ props }: any) => ({
  display: props.display,
  position: props.position,
  justifyContent: props.justifyContent,
  alignItems: props.alignItems,
  flexDirection: props.flexDirection,
  flexWrap: props.flexWrap,
  flexFlow: props.flexFlow,
  textAlign: props.textalign || props.textAlign,
  verticalAlign: props.verticalAlign,
  float: props.float,
  overflow: props.overflow,
  wordBreak: props.wordBreak,
  textOverflow: props.textOverflow,
  whiteSpace: props.whiteSpace,
  margin: props.margin,
  width: props.width,
  height: props.height,
  maxWidth: props.maxWidth,
  maxHeight: props.maxHeight,
  minWidth: props.minWidth,
  minHeight: props.minHeight,
  padding: props.padding,
  border: props.border,
  borderColor: selectColor(props.borderColor),
  borderRadius: props.borderRadius,
  boxShadow: props.boxShadow,
  backgroundColor: selectColor(props.backgroundColor),
  '> *': {
    display: props.childrenDisplay,
    margin: props.childrenMargin,
    verticalAlign: props.childrenVerticalAlign,
    fontSize: props.childrenFontSize,
  },
}));
