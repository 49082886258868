import actionTypes from './actionTypes';

export default {
  successMessage(message: string) {
    return {
      type: actionTypes.SUCCESS_MESSAGE,
      payload: message,
    };
  },
  errorMessage(status: number, message: string) {
    return {
      type: actionTypes.ERROR_MESSAGE,
      payload: { status, message },
    };
  },
  getErrorMessage(status: number, message: string) {
    return {
      type: actionTypes.GET_ERROR_MESSAGE,
      payload: { status, message },
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
};
