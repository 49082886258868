import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Formik } from 'formik';
import {
  Button as Btn,
  Container,
  Divider,
  Header,
  Grid,
  Segment,
  Form,
  Label,
} from 'semantic-ui-react';

import Buyer from 'components/QuickFixes/StartADelivery/DeliveryValidation/Buyer';
import DeliveryDetails from 'components/QuickFixes/StartADelivery/DeliveryValidation/DeliveryDetails';
import ValuesDetails from 'components/QuickFixes/StartADelivery/DeliveryValidation/ValuesDetails/ValuesDetails';
import Seller from 'components/QuickFixes/StartADelivery/DeliveryValidation/Seller';
import Transporter from 'components/QuickFixes/StartADelivery/DeliveryValidation/Transporter';
import ProofsManagerTransporterToken from 'components/QuickFixes/DeliveryForm/ProofsManagerTransporterToken';
import Button from 'components/Button';
import { actions, selectors } from 'store';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import UploadDocumentModal from '../../Seller/DeliveryDocumentsChecking/Components/UploadDocumentModal/UploadDocumentModal';
import Spacer from 'components/core/Spacer/Spacer';


const Index = ({
  match,
  dispatch,
  deliveryToken,
  errorHandlerQuickfixesToken,
  loadingHandlerQuickfixesToken,
}) => {
  const [deliveryInformations, setDeliveryInformations] = useState(null);
  const [proofs, setProofs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deliveryTokenCount, setDeliveryTokenCount] = useState(0);
  const [delivery, setDelivery] = useState(null);
  const [proofUploadStarted, setProofUploadStarted] = useState(null);

  useEffect(() => {
    
    dispatch(actions.quickfixesToken.reset(['deliveryByToken', 'error']));
    dispatch(
      actions.quickfixesToken.getDeliveryByToken({
        params: {
          token: match.params.token,
        },
      })
    );
    return () => {
      dispatch(actions.quickfixesToken.reset(['deliveryByToken', 'error']));
    };
  }, [dispatch, match]);

  useEffect(() => {
    if (deliveryToken === null) {
      setDeliveryTokenCount(prevDeliveryTokenCount => prevDeliveryTokenCount + 1);
    } else {
      if (deliveryTokenCount > 0) {
        setDelivery(deliveryToken);
      } else {
        setDeliveryTokenCount(prevDeliveryTokenCount => prevDeliveryTokenCount + 1);
      }
    }
  }, [deliveryToken, deliveryTokenCount]);

  useEffect(() => {
    if (proofUploadStarted && !loadingHandlerQuickfixesToken) {
      setProofUploadStarted(false);
      handleClose();
      if (!errorHandlerQuickfixesToken.includes('uploadProof')) {
        dispatch(actions.quickfixesToken.reset(['deliveryByToken', 'error']));
        dispatch(
          actions.quickfixesToken.getDeliveryByToken({
            params: {
              token: match.params.token,
            },
          })
        );
      }
    }
  }, [
    dispatch, 
    loadingHandlerQuickfixesToken, 
    errorHandlerQuickfixesToken, 
    match.params.token,
    proofUploadStarted
  ]);

  useEffect(() => {
    if (delivery !== null) {
      setDeliveryInformations({
        deliveryInfo: {
          ref: delivery.reference,
          date: delivery.createdAt,
          departureCountry: delivery.departureCountry,
          arrivalCountry: delivery.arrivalCountry,
          customsInfo: delivery.customsDeclarationId,
          transporterInCharge:
            delivery.ShippingAgent_Id === delivery.Sellers.id
              ? delivery.Sellers.name
              : delivery.Buyers.name,
        },
        seller: {
          company: '',
          name: delivery.Sellers.name,
          addressCountry: delivery.SellerAddresses.country,
          addressCity: delivery.SellerAddresses.city,
          address1: delivery.SellerAddresses.address1,
          email: delivery.Sellers.email,
          phone: delivery.Sellers.phoneNumber || '',
          vat: delivery.SellerVats.vatNumber,
        },
        buyer: {
          company: '',
          name: delivery.Buyers.name,
          addressCountry: delivery.BuyerAddresses.country,
          addressCity: delivery.BuyerAddresses.city,
          address1: delivery.BuyerAddresses.address1,
          email: delivery.Buyers.email,
          phone: delivery.Buyers.phoneNumber || '',
          vat: delivery.BuyerVats.vatNumber,
        },
        transporter: {
          company: '',
          name: (delivery.Transporters && delivery.Transporters.name) || '',
          email: (delivery.Transporters && delivery.Transporters.email) || '',
          phone:
            (delivery.Transporters && delivery.Transporters.phoneNumber) || '',
          vat:
            delivery.Transporters &&
            delivery.TransporterVats &&
            delivery.TransporterVats.vatNumber,
        },
        deliveryValue: {
          packageMethod: delivery.packageMethod,
          packageNumber: delivery.packageNumber,
          natureOfGoods: delivery.natureOfGoods,
          grossWeight: delivery.grossWeight,
          grossWeightUnit: delivery.grossWeightUnit,
          volume: delivery.volume,
          volumeUnit: delivery.volumeUnit,
        },
      });
      setProofs(delivery.ProofsOfDeliveries);
    }
  }, [delivery]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const deliveryDetailsFieldsNames = {
    ref: 'ref',
    date: 'date',
    from: 'departureCountry',
    to: 'arrivalCountry',
    transporterInCharge: 'transporterInCharge',
  };

  const buyerFieldsNames = {
    company: 'buyer.company',
    name: 'buyer.name',
    address: 'buyer.address',
    addressCountry: 'buyer.addressCountry',
    addressCity: 'buyer.addressCity',
    address1: 'buyer.address1',
    email: 'buyer.email',
    phone: 'buyer.phone',
    vat: 'buyer.vat',
  };
  const sellerFieldsNames = {
    company: 'seller.company',
    name: 'seller.name',
    address: 'seller.address',
    addressCountry: 'seller.addressCountry',
    addressCity: 'seller.addressCity',
    address1: 'seller.address1',
    email: 'seller.email',
    phone: 'seller.phone',
    vat: 'seller.vat',
  };
  const transporterFieldsNames = {
    company: 'transporter.company',
    name: 'transporter.name',
    address: 'transporter.address',
    addressCountry: 'transporter.addressCountry',
    addressCity: 'transporter.addressCity',
    address1: 'transporter.address1',
    email: 'transporter.email',
    phone: 'transporter.phone',
    vat: 'transporter.vat',
  };
  const valuesFieldsNames = {
    packageMethod: 'deliveryValue.packageMethod',
    packageNumber: 'deliveryValue.packageNumber',
    natureOfGoods: 'deliveryValue.natureOfGoods',
    grossWeight: 'deliveryValue.grossWeight',
    grossWeightUnit: 'deliveryValue.grossWeightUnit',
    volume: 'deliveryValue.volume',
    volumeUnit: 'deliveryValue.volumeUnit',
  };

  const downloadAttestation = () => {
    dispatch(
      actions.quickfixesToken.downloadAttestation({
        params: {
          token: match.params.token,
        },
      })
    );
  };

  const downloadCustomsRegimeInvoice = (deliveryId) => {
    dispatch(
      actions.quickfixesToken.downloadCustomsRegimeInvoice({
        params: {
          token: match.params.token,
        },
      })
    );
  };

  return (
    <Wrapper margin='auto' width='90%'>
      <Container fluid>
        <Container fluid>
          <Divider hidden />
          {(loadingHandlerQuickfixesToken === true && <Loader />) || (
            <>
              <Header textAlign='center' size='huge'>
                Delivery Details
              </Header>
              <Divider hidden />
              {(deliveryInformations === null && <Loader />) ||
                (deliveryInformations !== null && (
                  <Formik
                    initialValues={{
                      ref: deliveryInformations.deliveryInfo.ref,
                      date: deliveryInformations.deliveryInfo.date,
                      departureCountry:
                        deliveryInformations.deliveryInfo.departureCountry,
                      arrivalCountry:
                        deliveryInformations.deliveryInfo.arrivalCountry,
                      transporterInCharge:
                        deliveryInformations.deliveryInfo.transporterInCharge,
                      seller: {
                        company: deliveryInformations.seller.company,
                        name: deliveryInformations.seller.name,
                        addressCountry:
                          deliveryInformations.seller.addressCountry,
                        addressCity: deliveryInformations.seller.addressCity,
                        address1: deliveryInformations.seller.address1,
                        email: deliveryInformations.seller.email,
                        phone: deliveryInformations.seller.phone,
                        vat: deliveryInformations.seller.vat,
                      },
                      buyer: {
                        company: deliveryInformations.buyer.company,
                        name: deliveryInformations.buyer.name,
                        addressCountry:
                          deliveryInformations.buyer.addressCountry,
                        addressCity: deliveryInformations.buyer.addressCity,
                        address1: deliveryInformations.buyer.address1,
                        email: deliveryInformations.buyer.email,
                        phone: deliveryInformations.buyer.phone,
                        vat: deliveryInformations.buyer.vat,
                      },
                      transporter: {
                        company: deliveryInformations.transporter.company,
                        name: deliveryInformations.transporter.name,
                        addressCountry:
                          deliveryInformations.transporter.addressCountry,
                        addressCity:
                          deliveryInformations.transporter.addressCity,
                        address1: deliveryInformations.transporter.address1,
                        email: deliveryInformations.transporter.email,
                        phone: deliveryInformations.transporter.phone,
                        vat: deliveryInformations.transporter.vat,
                      },
                      deliveryValue: {
                        packageMethod:
                          deliveryInformations.deliveryValue.packageMethod,
                        packageNumber:
                          deliveryInformations.deliveryValue.packageNumber,
                        natureOfGoods:
                          deliveryInformations.deliveryValue.natureOfGoods,
                        grossWeight:
                          deliveryInformations.deliveryValue.grossWeight,
                        grossWeightUnit:
                          deliveryInformations.deliveryValue.grossWeightUnit,
                        volume: deliveryInformations.deliveryValue.volume,
                        volumeUnit:
                          deliveryInformations.deliveryValue.volumeUnit,
                      },
                      documentType: '',
                      file: {},
                      proofLevel: null,
                    }}
                    onSubmit={() => {}}
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      setFieldValue,
                      setFieldTouched,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Segment size='small'>
                          <Header textAlign='center'>Delivery summary</Header>
                          <Divider hidden />
                          <Grid columns={5}>
                            <Grid.Row>
                              <Grid.Column mobile={16} tablet={8} computer={3}>
                                <Header textAlign='center' size='medium'>
                                  Delivery
                                </Header>
                                <DeliveryDetails
                                  names={deliveryDetailsFieldsNames}
                                />
                              </Grid.Column>
                              <Grid.Column mobile={16} tablet={8} computer={3}>
                                <Header textAlign='center' size='medium'>
                                  Seller
                                </Header>
                                <Seller names={sellerFieldsNames} />
                              </Grid.Column>
                              <Grid.Column mobile={16} tablet={8} computer={3}>
                                <Header textAlign='center' size='medium'>
                                  Buyer
                                </Header>
                                <Buyer names={buyerFieldsNames} />
                              </Grid.Column>
                              <Grid.Column mobile={16} tablet={8} computer={3}>
                                <Header textAlign='center' size='medium'>
                                  Transporter
                                </Header>
                                <Transporter names={transporterFieldsNames} />
                              </Grid.Column>
                              <Grid.Column mobile={16} tablet={8} computer={3}>
                                <Header textAlign='center' size='medium'>
                                  Values
                                </Header>
                                <ValuesDetails names={valuesFieldsNames} />
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                {delivery && delivery.customsDeclarationId && (
                                  <>
                                  <Label>Customs ID: {delivery.customsDeclarationId[0].id}</Label>
                                  <Spacer height="5px" />
                                  </>
                                )}                       
                                {delivery && delivery.customsDeclarationId && (
                                  <>
                                  <Label>Client reference: {delivery.customsDeclarationId[0].ref}</Label>
                                  <Divider hidden />
                                  </>
                                )}
                                 {delivery && delivery.customsDeclarationId && (
                                    <>
                                      <Spacer height="5px" />
                                      <Label>CMR : {delivery.customsDeclarationId[0].cmr}</Label>
                                    </>
                                  )}
                                </Grid.Column>
                              </Grid.Row>                       
                              {deliveryInformations && deliveryInformations.transporter.name !== '' && (
                                <Grid.Row centered>
                                  <Btn.Group>
                                    <Button
                                      size='large'
                                      type='button'
                                      color='blue'
                                      content='Download the attestation'
                                      margin='5px'
                                      onClick={() => downloadAttestation()}
                                    />
                                    {delivery.customsRegime && (                                   
                                      <Button
                                        icon='download'
                                        type='button'
                                        size='large'
                                        color='blue'
                                        content='Download customs regime invoice'
                                        margin='5px'
                                        onClick={() =>
                                          downloadCustomsRegimeInvoice(
                                            delivery.id
                                          )
                                        }
                                      />                                    
                              
                                    )}                                   
                                  </Btn.Group>
                                </Grid.Row>
                              )}
                          </Grid>
                        </Segment>
                        <UploadDocumentModal
                          dispatch={dispatch}
                          isModalOpen={isModalOpen}
                          handleClose={handleClose}
                          token={match.params.token}
                          companyType='buyer'
                        />
                      </Form>
                    )}
                  </Formik>
                ))}
              <Divider hidden />
              {delivery === null || (proofs === null && <Loader />) || (
                <Segment>
                  <ProofsManagerTransporterToken
                    proofs={proofs}
                    transporterId={delivery.Transporters.id}
                    buttonsColor={'blue'}
                    token={match.params.token}
                  />

                  <Divider hidden />
                  <Button
                    type='button'
                    size='large'
                    color='blue'
                    content='Upload a document'
                    margin='5px'
                    onClick={() => setIsModalOpen(true)}
                  />
                </Segment>
              )}
            </>
          )}
        </Container>
      </Container>
      <Divider hidden />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    deliveryToken: selectors.quickfixesToken.deliveryByTokenSelector(state),
    errorHandlerQuickfixesToken: selectors.quickfixesToken.errorSelector(state),
    loadingHandlerQuickfixesToken: selectors.quickfixesToken.loadingSelector(
      state
    ),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
