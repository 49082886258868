import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const BillingApi = routesApi.company.clerksPricing;
  return {
    createClerksPricing(data: dataReqI) {
      return api.request({
        method: BillingApi.createClerksPricing.method,
        route: BillingApi.createClerksPricing.path,
        params: data.params,
        data: data.data
      });
    },
    getOneClerksPricing(data: dataReqI) {
      return api.request({
        method: BillingApi.getOneClerksPricing.method,
        route: BillingApi.getOneClerksPricing.path,
        params: data.params,
      });
    },
    updateClerksPricing(data: dataReqI) {
      return api.request({
        method: BillingApi.updateClerksPricing.method,
        route: BillingApi.updateClerksPricing.path,
        params: data.params,
        data: data.data
      });
    },
    deleteClerksPricing(data: dataReqI) {
      return api.request({
        method: BillingApi.deleteClerksPricing.method,
        route: BillingApi.deleteClerksPricing.path,
        params: data.params
      });
    },
  };
};
