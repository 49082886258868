import Login from 'views/Auth/Login';
import SignUp from 'views/Auth/SignUp';
import ForgottenPassword from 'views/Auth/ForgottenPassword';
import UpdatePassword from 'views/Auth/ForgottenPassword/UpdatePassword';
import paths from './paths';

export default [
  {
    private: false,
    path: paths.login,
    component: Login,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: false,
    path: paths.signUp,
    component: SignUp,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: false,
    path: paths.forgottenPassword,
    component: ForgottenPassword,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: false,
    path: paths.validateAccount,
    component: Login,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: false,
    path: paths.updatePassword,
    component: UpdatePassword,
    exact: true,
    authorizedRoles: [],
  },
];
