import styled from "@emotion/styled";

export const Wrapper = styled.div(({ labelPosition, labelVerticalPosition, float }: any) => ({
  alignItems: (labelVerticalPosition === "center") ? "center" : "unset",
  display: "flex",
  flexDirection: (labelPosition === "left") ? "row" : "column",
  margin: "0",
  float: float
}));


export const Output = styled.div`
    margin: 0;
    line-height: 1.21428571em;
    padding: 0.67857143em 2.1em 0.67857143em 1em;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
    min-height: 38px;
`;
