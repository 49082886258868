import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import {
  Grid,
  Header,
  Modal,
  Dropdown as Drop,
  Icon,
  Input
} from 'semantic-ui-react';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Iframe from './Iframe';
import { 
  Inner, 
  Left, 
  LeftArea, 
  Listing, 
  ListingEmpty, 
  ListingWrapper, 
  Nav, 
  RightArea, 
  SelectionArea, 
  SelectionLabel, Title, VertSep, content, drop, fsIcon, iframe } from './styles';
import CBoxIndeterminate from './CBoxIndeterminate';



const ModalFilterTagManager = ({
  intl,
  companyId,
  intrastatId,
  intrastatFlow,
  data,
  filterTagArchiveLoading,
  listFiles,
  listFilesLoading,
  groupFilesLoading,
  deleteFilesLoading,
  fileStateLoading,
  categoriesDocuments,
  categoriesLoading,
  handleClose,
  dispatch,
  userAdminOrOp,
}: ModalTagManagerProps) => {

  const [documentUrl, setDocumentUrl]: any = useState(null)
  const [dropValue, setDropValue]: any = useState(null)
  const [categoryValue, setCategoryValue]: any = useState(null)
  const [documentList, setDocumentList]: any = useState(null)
  const [isChecked, setIsChecked]: any = useState(null)
  const [selectedValue, setSelectedValue]: any = useState(null)
  const [fullscreenTv, setFullScreen]: any = useState(null)
  const [categories, setCategories]: any = useState(null)
  const [selectedMenu, setSelectedMenu]: any = useState(null)

  const tHempty = intl.formatMessage({id: `intrastat.tag.empty`})

  // Function to enable group selection
const  enableGroupSelection = (selector) => {
  const checkboxes = Array.from(document.querySelectorAll(selector));
  // Create a MutationObserver instance
  const observer = new MutationObserver((mutations, observer) => {
     mutations.forEach(mutation => {
       if (mutation.type === 'attributes' && mutation.attributeName === 'checked') {
         const checkbox: any = mutation.target;
         if (lastChecked && checkbox.checked !== lastChecked.checked) {
           const start = checkboxes.indexOf(checkbox);
           const end = checkboxes.indexOf(lastChecked);
           const isChecked = checkbox.checked;
 
           checkboxes
             .slice(Math.min(start, end), Math.max(start, end) + 1)
             .forEach(cb => cb.checked = isChecked);
         }
       }
     });
     observer.disconnect();
  });
 
  // Options to observe changes in the 'checked' attribute
  const config = { attributes: true, attributeFilter: ['checked'] };
  let lastChecked = null;
  checkboxes.forEach((checkbox, index) => {
     checkbox.addEventListener('click', event => {
       if (!lastChecked) {
         lastChecked = checkbox;
       } else if (event.shiftKey) {
         const start = checkboxes.indexOf(checkbox);
         const end = checkboxes.indexOf(lastChecked);
 
         checkboxes
           .slice(Math.min(start, end), Math.max(start, end) + 1)
           .forEach(cb => cb.checked = lastChecked.checked);
       }
       lastChecked = checkbox;
     });
 
     // Start observing the checkbox
     observer.observe(checkbox, config);
  });
 }
 // Initialize the group selection feature
 enableGroupSelection('.ui.input input[type="checkbox"]');


  let fileList = []
  const folder = data.document.name.split('.')[0].split(' ').join('-').toLowerCase()

  const adaptedStatus = data.document.status === 'zip' ? 
    data.document.name.split('.')[0] : data.document.name.split('(')[1] ? 
    data.document.name.split(')')[1].toLowerCase() : data.document.name.toLowerCase();

  const intrastatOptions = intrastatId && [
    {key: 'a1', value: `${intrastatId.split('-')[0].toLowerCase()}-intr`, text: `${intrastatId.split('-')[0]} Intro`},
    {key: 'a2', value: `${intrastatId.split('-')[0].toLowerCase()}-expe`, text: `${intrastatId.split('-')[0]} Expe`},
    {key: 'a3', value: 'avoir-intr', text: 'Avoir Intro'},
    {key: 'a4', value: 'avoir-expe', text: 'Avoir Expe'},
    {key: 'a5', value: 'non_traite', text: 'Non traité'},
    {key: 'a6', value: 'auto_liquidation', text: 'Auto-liquidation'},
    // {key: 7, value: 'non_conforme', text: 'Non conforme'},
  ]


  useEffect(() => {

    dispatch(actions.intrastat.getDocsFromCat({
      query: {type: data.flowCat}
    }));

    dispatch(actions.intrastat.getFilesFullList({
      params: {
        companyId: companyId,
        intrastatId: data.intrastatId,
        documentId: data.document.id,
        folder: `${data.document.name.split('.')[0]}`,
        status: 'archive'
      }
    }))

    dispatch(actions.socket.subscribeIntrastatFiles(
      companyId, 
      data.intrastatId, 
      data.document.id,
      adaptedStatus.split('.')[0],
      data.document.status
    ))

    return () => {
      dispatch(actions.intrastat.reset(['listFiles', 'error']))
      dispatch(actions.socket.unsubscribeIntrastatFiles(
        companyId, 
        data.intrastatId,
        data.document.id, 
        adaptedStatus.split('.')[0],
        data.document.status
        ))
        dispatch(actions.intrastat.reset(['docsFromCat']));
    }

  }, [dispatch, intrastatId, data, companyId, adaptedStatus])

  const fileExtention = (file) => {
    let extention
 
    switch(file){
      case file.endsWith('.pdf'):
        extention = file.slice(-3);
        break;

      case file.endsWith('.xls'):
        extention = file.slice(-3);
        break;

      case file.endsWith('.xlsx'):
        extention = file.slice(-4);
        break;

      default:
        extention = file.slice(-3);
    } 

    return extention.toLowerCase()
  }

  useEffect(() => {
    let tab = []
    if(listFiles && listFiles.Contents && listFiles.Contents.length>0){
      // from tmp folder
      if(listFiles.url){
        setDocumentUrl({
          url: listFiles[0].url, 
          context: 'session', 
          ext: fileExtention(listFiles[0].url),
        })
        setDocumentList(listFiles)
      }else{
       // from s3 repository
        listFiles.Contents.forEach((el, index) => {
          if(index === 0){
            setDocumentUrl({
              url: el.Key, 
              context: 's3', 
              ext: fileExtention(el.Key),
              tag: (el.tag && el.tag.Value) || null
            })
          }
          tab.push({
            name: el.Key.split('/')[3], 
            url: el.Key, 
            context: 's3', 
            ext: fileExtention(el.Key),
            tag: (el.tag && el.tag.Value) || null
          })
        })
        setDocumentList(tab)
      }
    }
    
    if(listFiles && !listFiles.Contents){
      let fileList = []
      listFiles.forEach(file => {
        fileList.push({
          id: file.id,
          name: file.name,
          url: `${data.document.path}/${file.name}`,
          context: 's3',   
          ext: fileExtention(file.name),
          tag: (file.tag && file.tag) || null,
          ch: false,
        }) 
      })
      setDocumentList(fileList)

      listFiles.forEach((el, index) => {
        if(index === 0){
          setDocumentUrl({
            url: `${data.document.path}/${el.name}`, 
            context: 's3', 
            ext: fileExtention(el.name),
            tag: (el.tag && el.tag) || null
          })
        }
      })
    }

  }, [listFiles, data.document.path])


  useEffect(() => {
    if(categoriesDocuments){
      setCategories(categoriesDocuments.map((e, index) => {
        return {
          key: index,
          value: e.id,
          text: e.fr
        }
    }))
    }
  }, [categoriesDocuments])


    const filterTagArchives = (tag: string, category: number) => {
      if(tag && category){
        let checkbox = document.querySelectorAll('input')
        let selectedPages = []

        checkbox.forEach((box, i) => {                   
          if(checkbox[i].checked){
            selectedPages.push(box.value)
          }
        })

        dispatch(actions.intrastat.filterTagArchives({
          params: {
            companyId: companyId,
            intrastatId: intrastatId,
            selectedTag: tag,
            documentId: data.document.id,
            category: data.document.IntrastatDocumentCategory_Id,
          },
          data:  {
            periodString: data.periodString,
            repository: folder,
            documents: selectedPages,    
            status: data.document.status,
            state: category
          }
        }))
        return () => {
          dispatch(actions.intrastat.reset(['filterTagArchives', 'error']))
        }
      }
    }

    const deleteFiles = () => {
      let checkbox = document.querySelectorAll('input')
      let selectedFiles = []
      checkbox.forEach((box, i) => {                   
        if(checkbox[i].checked){
          selectedFiles.push(box.value)
        }
      })

      dispatch(actions.intrastat.deleteFiles({
        params: {
          companyId: companyId,
          intrastatId: intrastatId,
          folder: folder,
        },
        data:{
          selectedFiles: selectedFiles
        }
      })) 
      return () => {
        dispatch(actions.intrastat.reset(['deleteFiles', 'error']))
      }  
    }

    const createGroupedFile = () => {
      let checkbox = document.querySelectorAll('input')
      let selectedPages = []
      checkbox.forEach((box, i) => {                   
        if(checkbox[i].checked && checkbox[i].value !== ''){
          selectedPages.push(box.value)
        }
      })
      dispatch(actions.intrastat.groupFiles({
        params: {
          companyId: companyId,
          intrastatId: intrastatId,
          documentId: data.document.id,
        },
        data:{
          selectedPages: selectedPages
        }
      })) 
      return () => {
        dispatch(actions.intrastat.reset(['groupFiles', 'error']))
      }  
    }

    const loadDocument = (documentData: any) => {
      dispatch(
        actions.intrastat.downloadDocument({
          params: {
            intrastatId: intrastatId,
            companyId: companyId,
            documentId: documentData.id
          },
          query: {
            documentName: documentData.name
          }
        })
      );
      return () => {
        dispatch(actions.intrastat.reset(['downloadDoc', 'error']))
      }
    };

    const changeFileState = (fileId?: number, check?: boolean) => {
      let selectedFiles = []
      if(fileId === undefined){
        // select multiple
        let checkbox = document.querySelectorAll('input')
        checkbox.forEach((box, i) => {                      
          if(checkbox[i].checked){ 
            selectedFiles.push(checkbox[i].value)
          }
        })
        // loaders
        // fileTag must be true or false
        setSelectedValue(check)
      }else{
        // fileId: a file has been selected
        // loaders
        // fileTag must be true or false
        setSelectedValue(!check)
      }


      (selectedFiles.length || fileId) && dispatch(actions.intrastat.changeFileState({
        params: {
          companyId: companyId,
          intrastatId: intrastatId,
          folder: folder,
          state: check ? true : 'null'
        },
        data:{
            selectedFiles: fileId ? fileId : selectedFiles
          }
      })) 

      // unset check values all to null
      selectedFiles.length && setIsChecked(null)

      return () => {
        dispatch(actions.intrastat.reset(['changeFileState', 'error']))
      }  
    }

    const changeGlobalState = async (value: any, changeIndex: number, list: any) => {
      list.forEach((file, index) => {
        if(index === changeIndex){
          fileList.push({
            id: file.id,
            name: file.name,
            url: `${data.document.path}/${file.name}`,
            context: 's3',   
            ext: fileExtention(file.name),
            tag: (file.tag && file.tag) || null,
            ch: value,
          }) 
        }else{
          fileList.push({
            id: file.id,
            name: file.name,
            url: `${data.document.path}/${file.name}`,
            context: 's3',   
            ext: fileExtention(file.name),
            tag: (file.tag && file.tag) || null,
            ch: list[index].ch,
          }) 
        }
      })
      setDocumentList(fileList)
    }

    const changeAllState = async (value: any, list: any) => {
      list.forEach((file) => {
          fileList.push({
            id: file.id,
            name: file.name,
            url: `${data.document.path}/${file.name}`,
            context: 's3',   
            ext: fileExtention(file.name),
            tag: (file.tag && file.tag) || null,
            ch: value,
          }) 
        })
      setDocumentList(fileList)
      setIsChecked(!isChecked)
    }

   
    const downloadFolder = (folderId, folderName) => {
      console.log(folderId)
      dispatch(
        actions.intrastat.downloadDocument({
          params: {
            intrastatId: intrastatId,
            companyId: companyId,
            documentId: folderId
          },
          query: {
            folderName: folderName
          }
        })
      );
      return () => {
        dispatch(actions.intrastat.reset(['downloadDoc', 'error']))
      }
    }

  return (
    <Modal style={fullscreenTv ? {'position': 'unset'} : {'position': 'relative', 'height': '90%'}} open={data.open} onClose={handleClose}  size='fullscreen' closeIcon>
      {fullscreenTv && (
        <Icon 
          name='tv'
          style={fsIcon}
          onClick={() => {
            setFullScreen(null)
          }} 
        />
      )}
      <Header icon='plus circle' content={intl.formatMessage(
        {id: `intrastat.tag.header`}, 
        {
          flow: intrastatFlow, 
          type: data.document.IntrastatDocumentCategory.name
        })}/>
      <Modal.Content style={content}>
        {!userAdminOrOp && (
          <SelectionArea>
            <LeftArea>  
            <Button
            key={'but1'}  
            disabled={filterTagArchiveLoading}
            {...(filterTagArchiveLoading && {
              icon: <Icon loading name='spinner' />,
            })}
            type='button'
            content={intl.formatMessage({
              id: `button.download` 
            })}
            color='blue'
            onClick={() => {
              downloadFolder(data.document.id, data.document.name)
            }}
          />
            </LeftArea>
          </SelectionArea>    
        )}
      {userAdminOrOp && (
        <SelectionArea>
        <LeftArea>  
          <Button
            key={'but1'}  
            disabled={filterTagArchiveLoading}
            {...(filterTagArchiveLoading && {
              icon: <Icon loading name='spinner' />,
            })}
            type='button'
            content={intl.formatMessage({
              id: `button.download` 
            })}
            color='blue'
            onClick={() => {
              downloadFolder(data.document.id, data.document.name)
            }}
          />
            <VertSep/>
          <Icon 
            name='tv'
            style={{'cursor': 'pointer'}}
            onClick={() => {
                setFullScreen(true)
            }} 
          />       
          {/* <CBoxIndeterminate 
            id='Indeterminate' 
            name='Indeterminate'
            checked={isChecked}
            defaultIndeterminate={isChecked}
            onChange={async () => { 
              await changeAllState(!isChecked, documentList)
            }}
          />     
          <VertSep/>
          <SelectionLabel>Select</SelectionLabel> */}
        </LeftArea>
        {/* <RightArea> 
         <Inner>         
             <Drop 
              key={'drop1'}                     
              name='drops' 
              style={drop} 
              placeholder='Choose state...' 
              search 
              selection
              fluid
              clearable
              options={intrastatOptions}
              onChange={(e, { value }) => {
                if(value){
                  setDropValue(value)

                  const val = typeof value === 'string' && value.split('-')[1]
                  if(val === 'intr'){
                    dispatch(actions.intrastat.getDocsFromCat({
                      query: {type: 1}
                    }));
                  }
                  if(val === 'expe'){
                    dispatch(actions.intrastat.getDocsFromCat({
                      query: {type: 0}
                    }));
                  }             
                }
               
              }}
            /> 
          </Inner>
          <Inner>                                     
            <Drop
              key={'cat2'}       
              name='categories' 
              style={drop} 
              placeholder='Choose category...' 
              search 
              selection 
              fluid
              clearable
              loading={categoriesLoading}
              options={categories}
              onChange={(e, { value }) => {             
                setCategoryValue(value)
              }}
            />
          </Inner>
        
            <Button
              key={'but1'}  
              disabled={filterTagArchiveLoading}
              {...(filterTagArchiveLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Submit'
              color='blue'
              onClick={() => {
                filterTagArchives(dropValue, categoryValue)
              }}
            />

         
            <VertSep/>
            <Button
              disabled={groupFilesLoading}
              {...(groupFilesLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Group files'
              color='blue'
              onClick={() => {
                createGroupedFile()
              }}
            />
            <VertSep/>
            <Button
              disabled={deleteFilesLoading}
              {...(deleteFilesLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Delete files'
              color='blue'
              onClick={() => {
                deleteFiles()
              }}
            />
            <VertSep/>
            <Button
              disabled={selectedValue && fileStateLoading}
              {...(selectedValue && fileStateLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Check'
              color='blue'
              onClick={() => {
                changeFileState(undefined, true)
              }}
            />
            <Button
              disabled={!selectedValue && fileStateLoading}
              {...(!selectedValue && fileStateLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Uncheck'
              color='blue'
              onClick={() => {
                changeFileState(undefined, false)
              }}
            />
            <VertSep/>
            <Icon 
             name='tv'
             style={{'cursor': 'pointer'}}
             onClick={() => {
                setFullScreen(true)
             }} 
             />
         
      </RightArea> */}
      </SelectionArea>
   
        )}
       
      <Grid columns={2}>   
          <Grid.Column width={7} style={ListingWrapper}> 
              {(
                (filterTagArchiveLoading || 
                listFilesLoading ||
                documentList === null)
               && 
              <Loader />) || (
                <Listing>              
                {documentList.length>0 && documentList.map((file, index) => {                                 
                  return (
                    <Nav key={index} className={selectedMenu === index ? 'pdfSelected': ''}> 
                    <Left> 
                      {/* {userAdminOrOp && (
                        <Input 
                          key={`i-${index}`} type="checkbox" checked={documentList[index].ch} value={file.name} 
                          data-file={file.id} style={{'margin': '9px', 'float': 'left', 'cursor': 'pointer'}}
                          onChange={async () => { 
                                             
                            if(documentList[index].ch === false){                           
                              await changeGlobalState(true, index, documentList)                                          
                            }else{ 
                              setIsChecked(null)                      
                              await changeGlobalState(false, index, documentList)       
                            }                                          
                          }}
                        />                      
                      )} */}
                     
                      <Title
                        onClick={() => {
                          setDocumentUrl(prevState => ({
                            ...prevState,
                              url: file.url,
                              ext: file.ext
                            }))
                          setSelectedMenu(index)
                        }}
                      >{file.ext} - {file.name}</Title>
               
                      {/* {userAdminOrOp && (
                        <Icon 
                        size='large' 
                        key={`p-${index}`} 
                        name={file.tag ? "check circle" : "check circle outline"} 
                        data-file={{id: file.id, file: file.name}} 
                        style={{'margin': '9px', 'float': 'left', 'cursor': 'pointer'}}
                        onClick={() => {
                          changeFileState(file.id, file.tag)
                        }}
                        />
                      )} */}

                    </Left>  
                    </Nav>
                  )})
                }
                {documentList.length === 0 && (
                  <ListingEmpty>{tHempty}</ListingEmpty>
                )}
              </Listing>
              )}      
          </Grid.Column>
      <Grid.Column width={9} style={fullscreenTv ? {'position': 'unset'} : {'position': 'relative'}}>
        {documentList && documentUrl && (
          <Iframe 
            style={iframe} 
            src={documentUrl}
            companyId={companyId}
            fullscreenTv={fullscreenTv}
            dispatch={dispatch}
            loadDocument={loadDocument}
            >
          </Iframe>
        )}
      </Grid.Column>
      </Grid>
    </Modal.Content>
  </Modal>);
};

interface ModalTagManagerProps {
  intl: any;
  companyId: number;
  documentId: number;
  intrastatId: string;
  intrastatFlow: string;
  data: {
    open: boolean;
    flowCat: number;
    document: any;
    companyName: string;
    intrastatId: string;
    periodString: string;
  },
  handleClose: () => void;
  handleTagOperations: () => void;
  finalize: (listFiles, session) => void;
  dispatch: (value: any) => void;
  filterTagArchiveLoading,
  listFiles,
  listFilesLoading,
  groupFilesLoading,
  deleteFilesLoading,
  fileStateLoading,
  userAdminOrOp,
  categoriesDocuments,
  categoriesLoading,
}

const mapStateToProps = (state) => {
  return {
    listFiles: selectors.intrastat.getFilesFullListSelector(state),
    listFilesLoading: selectors.intrastat.getFilesFullListLoadingSelector(state),
    filterTag: selectors.intrastat.tagSelector(state), 
    filterTagLoading: selectors.intrastat.filterTagLoadingSelector(state),
    filterTagArchive: selectors.intrastat.filterTagArchivesSelector(state), 
    filterTagArchiveLoading: selectors.intrastat.filterTagArchivesLoadingSelector(state),
    tag: selectors.intrastat.tagSelector(state),
    tagLoading: selectors.intrastat.tagLoadingSelector(state),
    tagSuccess: selectors.intrastat.tagSuccessSelector(state),
    singleFile: selectors.intrastat.createSingleFileSelector(state),
    singleFileLoading: selectors.intrastat.createSingleFileLoadingSelector(state),
    archiveAll: selectors.intrastat.archiveAllSelector(state),
    archiveAllLoading: selectors.intrastat.archiveAllLoadingSelector(state),
    bulkFilter: selectors.intrastat.bulkFilterSelector(state),
    bulkFilterLoading: selectors.intrastat.bulkFilterLoadingSelector(state),
    groupFilesLoading: selectors.intrastat.groupFilesLoadingSelector(state),
    groupFiles: selectors.intrastat.groupFilesSelector(state),
    deleteFilesLoading: selectors.intrastat.deleteFilesLoadingSelector(state),
    fileStateLoading: selectors.intrastat.fileStateLoadingSelector(state),
    categoriesDocuments: selectors.intrastat.getDocsFromCatSelector(state),
    categoriesLoading: selectors.intrastat.getDocsFromCatLoadingSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(ModalFilterTagManager);
