import React, { FunctionComponent, useEffect, useState} from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Header, Modal, Grid, Table, Loader, Segment, Message } from 'semantic-ui-react';
import { TextCustom, Wrapper } from 'components/core';
import Thumbnail from 'views/Customs/Clerk/NewFile/components/IssueStep/components/IssueForm/components/Thumbnail/Thumbnail';


type ModalViewBillingsProps = {
  open: boolean;
  handleClose: () => void;
  dispatch: any;
  customsDeclarationId: string;
  cdHasBillings: any;
  fileHasBillings;
  countryVatRate;
  trailerLicensePlate;
  loading;
};

const ModalViewBillings: FunctionComponent<ModalViewBillingsProps> = ({
  open,
  handleClose,
  dispatch,
  customsDeclarationId,
  fileHasBillings,
  countryVatRate,
  trailerLicensePlate,
  loading,
  cdHasBillings
}) => {

  const [data, setdata] = useState([]);
  const [trailerLicensePlatePicture, settrailerLicensePlatePicture] = useState(null);

  useEffect(() => {

    if(cdHasBillings){
      dispatch(
        actions.customsClerk.getFileHasBillings({
          params: {
            fileId: cdHasBillings.id,
          },
        })
      );

      dispatch(
        actions.customsClerk.downloadPicturesIssuesZIP({
          params: {
            fileId: cdHasBillings.id,
          },
        })
      );

      dispatch(
        actions.customsClerk.getTrailerLicensePlate({
          params: {
            fileId: cdHasBillings.id,
          },
        })
      );
    }

    return () => {
      dispatch(
        actions.customsClerk.reset(['fileHasBillings', 'cdHasBillings', 'trailerLicensePlate', 'error'])
      );
    };
  }, [dispatch, cdHasBillings]);

  useEffect(() => {
    if (trailerLicensePlate && trailerLicensePlate instanceof Blob) {
      settrailerLicensePlatePicture(trailerLicensePlate);
    }
  }, [trailerLicensePlate]);

  useEffect(() => {
    if (fileHasBillings) {
      setdata(fileHasBillings);
    }
  }, [fileHasBillings]);

  const getTableTotal = (vatFree?: string) => {
    let total = 0;
    data.forEach((line) => {
      total += line.customsAmount * line.quantity;
    });
    if (!vatFree && countryVatRate) {
      total = total + (total * countryVatRate) / 100;
    }
    return total;
  };

  const segmentStyle = {
    margin: '0',
    paddingTop: '1.5rem',
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Segment loading={loading} style={segmentStyle}>
        {(data.length === 0 && (
          <>
          <Header
           icon='chevron circle right'
           content={`Customs Cleck Billings:`}
          />
          <Message info>
           No billing data available for this Customs declaration
          </Message>
          </>
        )) || (
          <>
      <Header
        icon='chevron circle right'
        content={`Customs Cleck Billings: ${customsDeclarationId}`}
      />
      <Modal.Content>
        <Grid>   
            <Grid.Column width={12}>           
              <Table columns={5} striped size='small'> 
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Category</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Quantity</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
             
                <Table.Body>
                  {(data && data.length === 0 && (
                    <Loader active inline='centered' />
                  )) || data.map((e, i) => 
                        (e.inCustoms !== false) &&
                        (
                          <Table.Row key={i}>
                            <Table.Cell width={7}>
                              {e.CustomsClerkBilling.category}
                            </Table.Cell>
                            <Table.Cell width={4}>
                              {e.CustomsClerkBilling.description}
                            </Table.Cell>
                            <Table.Cell width={2}>{e.customsAmount || '0'}</Table.Cell>
                            <Table.Cell width={1}>{e.quantity}</Table.Cell>
                            <Table.Cell width={4}>{`${e.quantity * e.customsAmount} € `}</Table.Cell>
                          </Table.Row>
                        )
                    )}
                </Table.Body>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell>
                      <TextCustom fontWeight='bold'>TOTAL</TextCustom> (before tax)
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan='5'>
                      {`${getTableTotal('vatFree')} €`}
                      {countryVatRate && `(before tax)`}
                      <br />
                      {countryVatRate &&
                        `${getTableTotal()} € (VAT included ${countryVatRate}%)`}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
            <Grid.Column width={4}>
            {(trailerLicensePlatePicture &&
              trailerLicensePlatePicture.type !== 'application/json' && (        
                <Wrapper display='flex'>
                  <Thumbnail
                    file={trailerLicensePlatePicture}                 
                  />
                </Wrapper>
            ))}
            </Grid.Column>
        </Grid>   
      </Modal.Content>
      </>
      )}
      </Segment>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    fileHasBillings: selectors.customsClerk.fileHasBillingsSelector(state),
    trailerLicensePlate: selectors.customsClerk.trailerLicensePlateSelector(state),
    loading: selectors.customsClerk.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(ModalViewBillings);
