// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import About from '../about/about';
import Popup from '../popup/popup';

// Stylesheets
import './footer.css';

/**
 * @name footer.tsx
 *
 * @path /components/footer/footer.tsx
 *
 * @description Defines footer's content
 */
const Footer: React.FC = () => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  // Variables
  const startingDate = '2019';
  const currentYear = new Date().getFullYear();

  // References to get an access to button and popup into return
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const childRefAbout = useRef<HTMLDivElement>(null);

  // Setters for popup's visibility
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  // Defines the action that must be executed when user performed a click on about button
  const handleAboutClick = () => {
    setPopupVisible(!popupVisible);
  };

  // Define the action that must be performed when the user clicks outside of the component
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      buttonRef.current &&
      popupRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      !popupRef.current.contains(event.target as Node)
    ) {
      setPopupVisible(false);
    }
  };

  const handleClick = () => {
    setPopupVisible(false);
  };

  // Configuration of page's current state when handleOutsideClick is performed
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className='footerContainer'>
      <div className='aboutButton'>
        <button
          ref={buttonRef}
          className='footerButtonContainer'
          onClick={handleAboutClick}
        >
          <p>{t('footerStrings.about')}</p>
        </button>
      </div>

      <p className='copyright'>
        © {startingDate} - {currentYear} - ASD International -{' '}
        {t('footerStrings.allRightsReserved')}
      </p>

      {popupVisible ? (
        <Popup
          myRef={popupRef}
          title={t('popupStrings.aboutTitle')}
          onClickClose={handleClick}
          withButtonHidden={true}
        >
          {<About ref={childRefAbout}></About>}
        </Popup>
      ) : null}
    </div>
  );
};

export default Footer;
