import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    ticketSsr: false,
    sendResults: false,
    getFormSchemas: false,
  },
  errors: {
    ticketSsr: null,
    sendResults: null,
    getFormSchemas: null,
  },
  loading: {
    ticketSsr: false,
    sendResults: false,
    getFormSchemas: false,
  },
  formSchemas: null,
  processResult: null,

  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // ticketSsr
    case actionTypes.TICKETSSR_LOADING:
      return produce(state, (draft) => {
        draft.loading.ticketSsr = true;
      });
    case actionTypes.TICKETSSR_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.ticketSsr = null;
        draft.loading.ticketSsr = false;
        draft.success.ticketSsr = true;
        draft.processResult = action.payload;
      });
    case actionTypes.TICKETSSR_ERROR:
      return produce(state, (draft) => {
        draft.errors.ticketSsr = true;
        draft.loading.ticketSsr = false;
        draft.success.ticketSsr = false;
        !draft.error.includes('processResult') && draft.error.push('processResult');
      });

    //sendResults
    case actionTypes.SEND_RESULTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.sendResults = true;
      });
    case actionTypes.SEND_RESULTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.sendResults = null;
        draft.loading.sendResults = false;
        draft.success.sendResults = true;
      });
    case actionTypes.SEND_RESULTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.sendResults = true;
        draft.loading.sendResults = false;
        draft.success.sendResults = false;
        !draft.error.includes('sendResults') && draft.error.push('sendResults');
      });

    // getFormSchemas
    case actionTypes.GET_FORM_SCHEMAS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getFormSchemas = true;
      });
    case actionTypes.GET_FORM_SCHEMAS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getFormSchemas = null;
        draft.loading.getFormSchemas = false;
        draft.success.getFormSchemas = true;
        draft.formSchemas = action.payload;
      });
    case actionTypes.GET_FORM_SCHEMAS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getFormSchemas = true;
        draft.loading.getFormSchemas = false;
        draft.success.getFormSchemas = false;
        !draft.error.includes('formSchemas') &&
          draft.error.push('formSchemas');
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
