import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Form, Grid, Header, Container, Tab } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import { Wrapper } from 'components/core';
import Button from 'components/Button';
import { Field, Formik } from 'formik';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import { Input } from 'components/Form';
import styled from '@emotion/styled';

const Index = ({ dispatch, company, threshold, loadingHandler }) => {
  const [data, setdata]: any = useState(null);

  const monitoring = useRef()

  useEffect(() => {
    dispatch(actions.socket.subscribeMonitoring(company.id));
    dispatch(actions.configurations.getThreshold({
      params: { companyId: company.id },
    }));

    return () => {
      dispatch(actions.socket.unsubscribeMonitoring(company.id));
      dispatch(actions.configurations.reset(['threshold', 'error']));
    };

  }, [dispatch, company]);

  useEffect(() => {
    if(threshold){
      const ref: any = monitoring.current;
      ref.setFieldValue('threshold', threshold.threshold)
      setdata(threshold)
    }
  }, [threshold])


  const submit = (values) => {
    dispatch(actions.configurations.createThreshold({
      params: {
        companyId: company.id
      },
      data: {
        threshold: values.threshold
      }
    }));
  }

  const update = (values) => {
    dispatch(actions.configurations.updateThreshold({
      params: {
        companyId: company.id
      },
      data: {
        threshold: values.threshold
      }
    }));
  }

  const formatPrice = (value) => {
    return new Intl.NumberFormat(
      window.navigator.language, 
      {style: "currency", currency: "EUR"}
      ).format(value)
  }

  const Result = styled.div(() => ({
    width: '200px',
    position: 'relative',
    display: 'flex',
    height: '57px',
    float: 'left',
    lineHeight: '37px',
  }));

  const Values = styled.div(() => ({
    padding: '20px 0 0 0',
  }));


  return (
      <Tab.Pane
          loading={loadingHandler}
        >
        <Container>
        <Divider hidden />
        <Wrapper
          display='flex'
          justifyContent='space-between'
          padding='2rem 10rem 2rem'
        >
              <Formik
                ref={monitoring}
                initialValues={{
                  threshold: null,
                }}
                onSubmit={data && data.threshold>0 ? update : submit}
                validationSchema={false}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  values,
                  isSubmitting,
                }) => (
                  <>
                    <Form onSubmit={handleSubmit}>
                    <Header>Transit: Threshold value</Header>
                    <Divider hidden />
                    <Grid columns='3' verticalAlign='bottom'>
                      <Grid.Column>    
                      <Form.Field>
                        <Field
                          label={<MandatoryFieldLabel content='Threshold' />}
                          placeholder='0.00'
                          name='threshold'
                          type='number'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field> 
                      </Grid.Column>
                      <Grid.Column>
                   
                       
                        
                          <Result>{data && (<Values><strong>Inserted value :</strong> <em>{formatPrice(data.threshold)}</em></Values>)}</Result>     
                                                           
                      </Grid.Column>
                      <Grid.Column>                   
                      <Button
                        type='submit'
                        content='Send'
                        color='green'
                      />
                      </Grid.Column>
                      </Grid>
                    </Form>
                  </>
                )}
            </Formik>
        </Wrapper>
        </Container>
        </Tab.Pane>
    )
};

const mapStateToProps = (state) => {
  return {
    threshold: selectors.configurations.thresholdGetSelector(state),
    errorHandler: selectors.configurations.errorSelector(state),
    loadingHandler: selectors.configurations.loadingSelector(state),
    services: selectors.service.servicesSelector(state),
    transitRisk: selectors.customsDeclaration.getTransitRiskSelector(state),
    transitRiskLoading: selectors.customsDeclaration.getTransitRiskLoadingSelector(state), 
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
