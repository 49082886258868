import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    create: false,
    getAll: false,
    getOne: false,
    remove: false,
    update: false,
  },
  errors: {
    create: null,
    getAll: null,
    getOne: null,
    remove: null,
    update: null,
  },
  loading: {
    create: false,
    getAll: false,
    getOne: false,
    remove: false,
    update: false,
  },
  agencies: null,
  agency: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Create
    case actionTypes.CREATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.create = true;
      });

    case actionTypes.CREATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.create = null;
        draft.loading.create = false;
        draft.success.create = true;
      });

    case actionTypes.CREATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.create = true;
        draft.loading.create = false;
        draft.success.create = false;
        !draft.error.includes("create") && draft.error.push("create");
      });

    // Get all
    case actionTypes.GET_ALL_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAll = true;
      });

    case actionTypes.GET_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAll = null;
        draft.loading.getAll = false;
        draft.success.getAll = true;
        draft.agencies = action.payload;
      });

    case actionTypes.GET_ALL_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAll = true;
        draft.loading.getAll = false;
        draft.success.getAll = false;
        !draft.error.includes("agencies") && draft.error.push("agencies");
      });

    // Get one
    case actionTypes.GET_ONE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOne = true;
      });

    case actionTypes.GET_ONE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOne = null;
        draft.loading.getOne = false;
        draft.success.getOne = true;
        draft.agency = action.payload;
      });

    case actionTypes.GET_ONE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOne = true;
        draft.loading.getOne = false;
        draft.success.getOne = false;
        !draft.error.includes("agency") && draft.error.push("agency");
      });

    // remove
    case actionTypes.REMOVE_LOADING:
      return produce(state, (draft) => {
        draft.loading.remove = true;
      });

    case actionTypes.REMOVE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.remove = null;
        draft.loading.remove = false;
        draft.success.remove = true;
      });

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.errors.remove = true;
        draft.loading.remove = false;
        draft.success.remove = false;
        !draft.error.includes("remove") && draft.error.push("remove");
      });

    // Update
    case actionTypes.UPDATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.update = true;
      });

    case actionTypes.UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.update = null;
        draft.loading.update = false;
        draft.success.update = true;
      });

    case actionTypes.UPDATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.update = true;
        draft.loading.update = false;
        draft.success.update = false;
        !draft.error.includes("update") && draft.error.push("update");
      });



    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
