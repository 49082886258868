import actionTypes from 'store/company/payment/actionTypes';
import routes from './routes';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.GET_PAYMENT:
        try {
          next({ type: actionTypes.GET_PAYMENT_LOADING });
          const response = await request.getPayment({ ...action.payload });
          next({
            type: actionTypes.GET_PAYMENT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_PAYMENT_ERROR,
            error,
          });
        }
        break;
      case actionTypes.REMOVE_PAYMENT:
        try {
          next({ type: actionTypes.REMOVE_PAYMENT_LOADING });
          await request.removePayment({ ...action.payload });
          next({
            type: actionTypes.REMOVE_PAYMENT_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.REMOVE_PAYMENT_ERROR,
            error,
          });
        }
        break;
      default:
        next(action);
        break;
    }
  };
}
