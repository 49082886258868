import StartDelivery from 'views/Fiscal/QuickFixes/Seller/StartDelivery';
import MassIntegrations from 'views/Fiscal/QuickFixes/Seller/MassIntegrations';
import MassIntegration from 'views/Fiscal/QuickFixes/Seller/MassIntegrations/Details';
import DeliveryDocumentsChecking from 'views/Fiscal/QuickFixes/Seller/DeliveryDocumentsChecking';
import Seller from 'views/Fiscal/QuickFixes/Seller';
import paths from './paths';

export default [
  {
    private: true,
    path: paths.startDelivery,
    component: StartDelivery,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.massIntegrations,
    component: MassIntegrations,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.massIntegration,
    component: MassIntegration,
    exact: true,
    authorizedRoles: [],
  },
  {
    privaye: true,
    path: paths.deliveries,
    component: Seller,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.deliveriesManage,
    component: DeliveryDocumentsChecking,
    exact: true,
    authorizedRoles: [],
  },
];
