import actionTypes from "./actionTypes";
import { dataReqI } from 'interfaces';

export default {
  subscribe(data: dataReqI) {
    return {
      type: actionTypes.SUBSCRIBE,
      payload: data,
    };
  },
  unsubscribe(data: dataReqI) {
    return {
      type: actionTypes.UNSUBSCRIBE,
      payload: data,
    };
  },
  getCategories(data: dataReqI){
    return {
      type: actionTypes.GET_CATEGORIES,
      payload: data,
    };
  },
  getAllVatDeclarations(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_VAT_DECLARATIONS,
      payload: data,
    };
  },
  getVatDeclaration(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_VAT_DECLARATION,
      payload: data,
    };
  },
  getAllCompanyVatDeclarations(data: dataReqI){
    return {
      type: actionTypes.GET_ALL_COMPANY_VAT_DECLARATIONS,
      payload: data,
    };
  },
  getAllCountryVatDeclarations(data: dataReqI){
    return {
      type: actionTypes.COUNTRY,
      payload: data,
    };
  },
  createVatDeclaration(data: dataReqI) {
    return {
      type: actionTypes.CREATE_VAT_DECLARATION,
      payload: data,
    };
  },
  updateVatDeclaration(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_VAT_DECLARATION,
      payload: data,
    };
  },
  updateStep(data: dataReqI){
    return {
      type: actionTypes.UPDATE_STEP,
      payload: data,
    };
  },
  followVat(data: dataReqI){
    return {
      type: actionTypes.FOLLOW_VAT_DECLARATION,
      payload: data,
    };
  },
  unfollowVat(data: dataReqI){
    return {
      type: actionTypes.UNFOLLOW_VAT_DECLARATION,
      payload: data,
    };
  },
  addDocuments(data: dataReqI) {
    return {
      type: actionTypes.VAT_REPO_ADD_DOCUMENTS,
      payload: data,
    };
  },
  getDocsFromCat(data: dataReqI){
    return {
      type: actionTypes.VAT_REPO_GET_DOC_CAT,
      payload: data,
    };
  },
  getMapping(){
    return {
      type: actionTypes.GET_MAPPING,
    };
  },
  updateDocument(data: dataReqI){
    return {
      type: actionTypes.UPDATE_DOC,
      payload: data,
    };
  },
  deleteDocument(data: dataReqI){
    return {
      type: actionTypes.DELETE_DOC,
      payload: data,
    };
  },
  downloadDocument(data: dataReqI){
    return {
      type: actionTypes.DOWNLOAD_DOC,
      payload: data,
    };
  },
  getCompanySubscribed() {
    return {
      type: actionTypes.GET_COMPANY_SUBSCRIBED,
    };
  },
  getOperatorAssignedCountries(data: dataReqI) {
    return {
      type: actionTypes.GET_ASSIG_COUNTRIES,
      payload: data,
    };
  },
  getFilesFullList(data: dataReqI) {
    return {
      type: actionTypes.GET_FULL_LIST_DOCUMENTS,
      payload: data,
    };
  },
  createObligation(data: dataReqI) {
    return {
      type: actionTypes.OBLIGATION_CREATE,
      payload: data,
    };
  },
  updateObligation(data: dataReqI) {
    return {
      type: actionTypes.OBLIGATION_UPDATE,
      payload: data,
    };
  },
  deleteObligation(data: dataReqI) {
    return {
      type: actionTypes.OBLIGATION_DELETE,
      payload: data,
    };
  },
  getObligation(data: dataReqI) {
    return {
      type: actionTypes.GET_OBLIGATION,
      payload: data,
    };
  },
  getVatDeclarationExcel(data: dataReqI){
    return {
      type: actionTypes.GET_EXCEL,
      payload: data,
    };
  },
  updateVatDeclarationExcel(data: dataReqI){
    return {
      type: actionTypes.UPDATE_EXCEL,
      payload: data,
    };
  },
  deleteFiles(data: dataReqI){
    return {
      type: actionTypes.DELETE_FILES,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys
      }
    };
  }
};
