import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const contactErrorSelector = state => state.contactRequest.errors.contact;
const errorsSelector = createErrorsSelector([
  contactErrorSelector,
]);


// Loading
const contactLoadingSelector = state => state.contactRequest.loading.contact;
const loadingSelector = createLoadingSelector([
  contactLoadingSelector,
]);


// Success
const contactSuccessSelector = state => state.contactRequest.success.contact;
const successSelector = createSuccessSelector([
  contactSuccessSelector,
]);


const errorSelector = state => state.contactRequest.error;


export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  errorSelector,
};
