import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const declareErrorSelector = (state) => 
state.customsDeclaration.errors.declare;
const getAllErrorSelector = (state) => 
state.customsDeclaration.errors.getAll;
const getOneErrorSelector = (state) => 
state.customsDeclaration.errors.getOne;
const downloadDocumentsErrorSelector = (state) =>
state.customsDeclaration.errors.downloadDocuments;
const extractDocumentsErrorSelector = (state) =>
state.customsDeclaration.errors.extractDocuments;
const getAllTracesErrorSelector = (state) =>
state.customsDeclaration.errors.getAllTraces;
const subscribeErrorSelector = (state) =>
state.customsDeclaration.errors.subscribe;
const unsubscribeErrorSelector = (state) =>
state.customsDeclaration.errors.unsubscribe;
const getDeclarationsInvoice = (state) =>
state.customsDeclaration.errors.declarationInvoice;
const getAllCategoriesDocumentsErrorSelector = (state) =>
state.customsDeclaration.errors.getAllCategoriesDocuments;
const getDeclarationsInvoiceErrorSelector = (state) =>
state.customsDeclaration.errors.declarationInvoice;
const getDeclarationsInvoiceByFiltersErrorSelector = (state) =>
state.customsDeclaration.errors.declarationsInvoiceByFilters;
const reloadLogisticomErrorSelector = (state) => 
state.customsDeclaration.errors.reloadLogisticom;
const addMandatesErrorsSelector = (state) => 
state.customsDeclaration.errors.addMandates;
const getMandatesErrorsSelector = (state) => 
state.customsDeclaration.errors.getMandates;
const computedDeclarationsErrorsSelector = (state) => 
state.customsDeclaration.error.computedDeclarations
const genBaeExtractErrorsSelector = (state) => 
state.customsDeclaration.error.baeExtract
const getComputedChedsErrorSelector = (state) => 
state.customsDeclaration.errors.getComputedCheds;
const getAllHostedErrorSelector = (state) => 
state.customsDeclaration.errors.getAllHosted;
const getOneHostedErrorSelector = (state) => 
state.customsDeclaration.errors.getOneHosted;
const addPartnersErrorsSelector = (state) => 
state.customsDeclaration.errors.addPartner;
const addPartnerCommentErrorsSelector = (state) => 
state.customsDeclaration.errors.addPartnersComments;
const getPartnerCommentErrorsSelector = (state) => 
state.customsDeclaration.errors.getPartnersComments;
const addLinesErrorsSelector = (state) => 
state.customsDeclaration.errors.addLines;
const checkConexDeliveryErrorsSelector = (state) => 
state.customsDeclaration.errors.checkConex;
const getOneByReferenceErrorSelector = (state) => 
state.customsDeclaration.errors.getOneByRef;
const getParkingErrorsSelector = (state) => 
state.customsDeclaration.errors.getParking;
const getCategoriesErrorSelector = (state) => 
state.customsDeclaration.errors.getCategories;
const addConsignmentErrorSelector = (state) => 
state.customsDeclaration.errors.addConsignment;
const updateConsignmentErrorSelector = (state) => 
state.customsDeclaration.error.updateConsignment;
const getAllLinesErrorSelector = (state) => 
state.customsDeclaration.error.getAllLines;
const createAccisesErrorSelector = (state) => 
state.customsDeclaration.error.accises;
const getAccisesErrorSelector = (state) => 
state.customsDeclaration.error.getAccises;
const getAllAccisesErrorSelector = (state) => 
state.customsDeclaration.error.getAllAccises;
const addTransitErrorsSelector = (state) => 
state.customsDeclaration.error.transit;
const updateTransitErrorsSelector = (state) => 
state.customsDeclaration.error.updateTransit;
const getTransitRatesErrorsSelector = (state) => 
state.customsDeclaration.error.transitRates;
const getTransitRiskErrorsSelector = (state) => 
state.customsDeclaration.error.transitRisk;
const updateErrorCompany = (state) => 
  state.customsDeclaration.error.updateDeclaration;


const errorsSelector = createErrorsSelector([
  declareErrorSelector,
  subscribeErrorSelector,
  unsubscribeErrorSelector,
  getAllErrorSelector,
  getOneErrorSelector,
  downloadDocumentsErrorSelector,
  extractDocumentsErrorSelector,
  getAllTracesErrorSelector,
  getDeclarationsInvoice,
  getAllCategoriesDocumentsErrorSelector,
  getDeclarationsInvoiceErrorSelector,
  getDeclarationsInvoiceByFiltersErrorSelector,
  reloadLogisticomErrorSelector,
  computedDeclarationsErrorsSelector,
  genBaeExtractErrorsSelector,
  getComputedChedsErrorSelector,
  getAllHostedErrorSelector,
  getOneHostedErrorSelector,
  addPartnersErrorsSelector,
  addMandatesErrorsSelector,
  getMandatesErrorsSelector,
  addPartnerCommentErrorsSelector,
  getPartnerCommentErrorsSelector,
  getParkingErrorsSelector,
  addLinesErrorsSelector,
  checkConexDeliveryErrorsSelector,
  getOneByReferenceErrorSelector,
  getCategoriesErrorSelector,
  addConsignmentErrorSelector,
  getAllLinesErrorSelector,
  createAccisesErrorSelector,
  addTransitErrorsSelector,
  updateTransitErrorsSelector,
  getTransitRatesErrorsSelector,
  updateErrorCompany,
]);

// Loading
const declareLoadingSelector = (state) =>
  state.customsDeclaration.loading.declare;
const getAllLoadingSelector = (state) =>
  state.customsDeclaration.loading.getAll;
const getOneLoadingSelector = (state) =>
  state.customsDeclaration.loading.getOne;
const downloadDocumentsLoadingSelector = (state) =>
  state.customsDeclaration.loading.downloadDocuments;
const extractDocumentsLoadingSelector = (state) =>
  state.customsDeclaration.loading.extractDocuments;
  const getAllTracesLoadingSelector = (state) =>
  state.customsDeclaration.loading.getAllTraces;
const subscribeLoadingSelector = (state) =>
  state.customsDeclaration.loading.subscribe;
const unsubscribeLoadingSelector = (state) =>
  state.customsDeclaration.loading.unsubscribe;
const getDeclarationsInvoiceLoadingSelector = (state) =>
  state.customsDeclaration.loading.declarationInvoice;
const getAllCategoriesDocumentsLoadingSelector = (state) =>
  state.customsDeclaration.loading.getAllCategoriesDocuments;
const getDeclarationsInvoiceByFiltersLoadingSelector = (state) =>
  state.customsDeclaration.loading.declarationsInvoiceByFilters;
const reloadLogisticomLoadingSelector = (state) => 
state.customsDeclaration.loading.reloadLogisticom
const computedDeclarationsLoadingSelector = (state) => state.customsDeclaration.loading.computedDeclarations
const genBaeExtractLoadingsSelector = (state) => state.customsDeclaration.loading.baeExtract

const getComputedChedsLoadingSelector = (state) => state.customsDeclaration.loading.getComputedCheds;
const getAllHostedLoadingSelector = (state) => state.customsDeclaration.loading.getAllHosted;
const getOneHostedLoadingSelector = (state) => state.customsDeclaration.loading.getOneHosted;
const addPartnersLoadingSelector = (state) => state.customsDeclaration.loading.addPartners;
const addMandatesLoadingSelector = (state) => state.customsDeclaration.loading.addMandates;
const getMandatesLoadingSelector = (state) => state.customsDeclaration.loading.getMandates;
const addPartnerCommentLoadingSelector = (state) => state.customsDeclaration.loading.addPartnersComments;
const getPartnerCommentLoadingSelector = (state) => state.customsDeclaration.loading.getPartnersComments;
const getConexLoadingSelector = (state) => state.customsDeclaration.loading.getConex;
const addLinesLoadingSelector = (state) => state.customsDeclaration.loading.addLines;
const getParkingLoadingSelector = (state) => state.customsDeclaration.loading.getParking;
const checkConexDeliveryLoadingSelector = (state) => state.customsDeclaration.loading.checkConex;
const declarationChatLoadingSelector = (state) => state.customsDeclaration.loading.declarationChat;
const getOneByReferenceLoadingSelector = (state) => state.customsDeclaration.loading.getOneByRef;
const getCategoriesLoadingSelector = (state) => 
state.customsDeclaration.loading.getCategories;
const addConsignmentLoadingSelector = (state) => 
state.customsDeclaration.loading.addConsignment;
const updateConsignmentLoadingSelector = (state) => 
state.customsDeclaration.loading.updateConsignment;
const allChedLoadingSelector = (state) => 
state.customsDeclaration.loading.allChed;
const getAllLinesLoadingSelector = (state) => 
state.customsDeclaration.loading.getAllLines;
const createAccisesLoadingSelector = (state) => 
state.customsDeclaration.loading.accises;
const getAccisesLoadingSelector = (state) => 
state.customsDeclaration.loading.getAccises;
const getAllAccisesLoadingSelector = (state) => 
state.customsDeclaration.loading.getAllAccises;
const addTransitLoadingSelector = (state) => 
state.customsDeclaration.loading.transit;
const updateTransitLoadingSelector = (state) => 
state.customsDeclaration.loading.updateTransit;
const getTransitRatesLoadingSelector = (state) => 
state.customsDeclaration.loading.transitRates;
const getTransitRiskLoadingSelector = (state) => 
state.customsDeclaration.loading.transitRisk;
const downloadChedLoadingSelector = (state) => 
state.customsDeclaration.loading.chedFiles
const updateLoadingCompany = (state) => 
  state.customsDeclaration.loading.updateDeclaration;


const loadingSelector = createLoadingSelector([
  declareLoadingSelector,
  subscribeLoadingSelector,
  unsubscribeLoadingSelector,
  getAllLoadingSelector,
  getOneLoadingSelector,
  downloadDocumentsLoadingSelector,
  extractDocumentsLoadingSelector,
  getAllTracesLoadingSelector,
  getDeclarationsInvoiceLoadingSelector,
  getAllCategoriesDocumentsLoadingSelector,
  getDeclarationsInvoiceByFiltersLoadingSelector,
  reloadLogisticomLoadingSelector,
  computedDeclarationsLoadingSelector,
  genBaeExtractLoadingsSelector,
  getComputedChedsLoadingSelector,
  getAllHostedLoadingSelector,
  getOneHostedLoadingSelector,
  addPartnersLoadingSelector,
  addMandatesLoadingSelector,
  getMandatesLoadingSelector,
  getPartnerCommentLoadingSelector,
  getConexLoadingSelector,
  addLinesLoadingSelector,
  checkConexDeliveryLoadingSelector,
  getCategoriesLoadingSelector,
  addConsignmentLoadingSelector,
  createAccisesLoadingSelector,
  addTransitLoadingSelector,
  updateTransitLoadingSelector,
  getTransitRatesLoadingSelector,
  downloadChedLoadingSelector,
  updateLoadingCompany
]);

// Success
const declareSuccessSelector = (state) =>
  state.customsDeclaration.success.declare;
const getAllSuccessSelector = (state) =>
  state.customsDeclaration.success.getAll;
const getOneSuccessSelector = (state) =>
  state.customsDeclaration.success.getOne;
const downloadDocumentsSuccessSelector = (state) =>
  state.customsDeclaration.success.downloadDocuments;
const extractDocumentsSuccessSelector = (state) =>
  state.customsDeclaration.success.extractDocuments;
  const getAllTracesSuccessSelector = (state) =>
  state.customsDeclaration.success.getAllTraces;
const subscribeSuccessSelector = (state) =>
  state.customsDeclaration.success.subscribe;
const unsubscribeSuccessSelector = (state) =>
  state.customsDeclaration.success.unsubscribe;
const getDeclarationsInvoiceSuccessSelector = (state) =>
  state.customsDeclaration.success.declarationsInvoice;
const getAllCategoriesDocumentsSuccessSelector = (state) =>
  state.customsDeclaration.success.getAllCategoriesDocuments;
const getDeclarationsInvoiceByFiltersSuccessSelector = (state) =>
  state.customsDeclaration.success.declarationsInvoiceByFilters;
const reloadLogisticomSuccessSelector = (state) => 
state.customsDeclaration.success.reloadLogisticom
const computedDeclarationsSuccessSelector = (state) => 
state.customsDeclaration.success.computedDeclarations
const genBaeExtractSuccessSelector = (state) => 
state.customsDeclaration.success.baeExtract
const getComputedChedsSuccessSelector = (state) =>
  state.customsDeclaration.success.getComputedCheds;
const getAllHostedSuccessSelector = (state) => 
  state.customsDeclaration.success.getAllHosted;
const addMandatesSuccessSelector = (state) => state.customsDeclaration.success.addMandates;
const getMandatesSuccessSelector = (state) => state.customsDeclaration.success.getMandates;
const getOneHostedSuccessSelector = (state) => 
state.customsDeclaration.success.getOneHosted;
const addPartnersSuccessSelector = (state) => 
state.customsDeclaration.success.addPartners;
const addPartnerCommentSuccessSelector = (state) => 
state.customsDeclaration.success.addPartnersComments;
const getPartnerCommentSuccessSelector = (state) => 
state.customsDeclaration.success.getPartnersComments;
const getParkingSuccessSelector = (state) => 
state.customsDeclaration.success.getParking;
const addLinesSuccessSelector = (state) => 
state.customsDeclaration.success.addLines;
const checkConexDeliverySuccessSelector = (state) => 
state.customsDeclaration.success.checkConex;
const getOneByReferenceSuccessSelector = (state) => 
state.customsDeclaration.success.getOneByRef;
const getCategoriesSuccessSelector = (state) => 
state.customsDeclaration.success.getCategories;
const addConsignmentSuccessSelector = (state) => 
state.customsDeclaration.success.addConsignment;
const updateConsignmentSuccessSelector = (state) => 
state.customsDeclaration.success.updateConsignment;
const allChedSuccessSelector = (state) => 
  state.customsDeclaration.success.allChed;
const getAllLinesSuccessSelector = (state) => 
state.customsDeclaration.success.getAllLines;
const createAccisesSuccessSelector = (state) => 
state.customsDeclaration.success.accises;
const getAccisesSuccessSelector = (state) => 
state.customsDeclaration.success.getAccises;
const getAllAccisesSuccessSelector = (state) => 
state.customsDeclaration.success.getAllAccises;
const addTransitSuccessSelector = (state) => 
state.customsDeclaration.success.transit;
const updateTransitSuccessSelector = (state) => 
state.customsDeclaration.success.updateTransit;
const getTransitRatesSuccessSelector = (state) => 
state.customsDeclaration.success.transitRates;
const getTransitRiskSuccessSelector = (state) => 
state.customsDeclaration.success.transitRisk;
const downloadChedsSuccessSelector = (state) => 
state.customsDeclaration.success.chedFiles;
const updateSuccessCompany = (state) => 
  state.customsDeclaration.success.updateDeclaration;

const successSelector = createSuccessSelector([
  declareSuccessSelector,
  subscribeSuccessSelector,
  unsubscribeSuccessSelector,
  getAllSuccessSelector,
  getOneSuccessSelector,
  downloadDocumentsSuccessSelector,
  extractDocumentsSuccessSelector,
  getAllTracesSuccessSelector,
  getDeclarationsInvoiceSuccessSelector,
  getAllCategoriesDocumentsSuccessSelector,
  getDeclarationsInvoiceByFiltersSuccessSelector,
  reloadLogisticomSuccessSelector,
  computedDeclarationsSuccessSelector,
  genBaeExtractSuccessSelector,
  getComputedChedsSuccessSelector,
  getAllHostedSuccessSelector,
  getOneHostedSuccessSelector,
  addPartnersSuccessSelector,
  getMandatesSuccessSelector,
  addMandatesSuccessSelector,
  addPartnerCommentSuccessSelector,
  getPartnerCommentSuccessSelector,
  getParkingSuccessSelector,
  addLinesSuccessSelector,
  checkConexDeliverySuccessSelector,
  getOneByReferenceSuccessSelector,
  getCategoriesSuccessSelector,
  addConsignmentSuccessSelector,
  allChedSuccessSelector,
  createAccisesSuccessSelector,
  addTransitSuccessSelector,
  updateTransitSuccessSelector,
  getTransitRatesSuccessSelector,
  downloadChedsSuccessSelector,
  updateSuccessCompany
]);

const declarationsSelector = (state) => 
  state.customsDeclaration.declarations;
const convertFileLoadingSelector = (state) =>
  state.customsDeclaration.loading.convertFile;
const declarationsCountSelector = (state) =>
  state.customsDeclaration.declarationsCount;
const hostedDeclarationsCountSelector = (state) =>
  state.customsDeclaration.hostedDeclarationsCount;
const declarationSelector = (state) => state.customsDeclaration.declaration;
const declarationChatSelector = (state) =>
  state.customsDeclaration.declarationChat;
const processingDeclarationsLoadingSelector = (state) =>
  state.customsDeclaration.loading.getProcessingDeclarations;
const processingDeclarationsSelector = (state) =>
  state.customsDeclaration.processingDeclarations;
const processingDeclarationsByFiltersLoadingSelector = (state) =>
  state.customsDeclaration.loading.getProcessingDeclarationsByFilters;
const processingDeclarationsByFiltersSelector = (state) =>
  state.customsDeclaration.processingDeclarationsByFilters;
const processingDeclarationsCountSelector = (state) =>
  state.customsDeclaration.processingDeclarationsCount;
const deleteDocumentSelector = (state) =>
  state.customsDeclaration.loading.deleteDocument;
const addDocumentsLoadingSelector = (state) =>
  state.customsDeclaration.loading.addDocuments;
const createDeclarationLoadingSelector = (state) =>
  state.customsDeclaration.loading.declare;
const getAllCustomsOfficeSelector = (state) =>
  state.customsDeclaration.customsOffices;
const getAllCustomsOfficeLoadingSelector = (state) =>
  state.customsDeclaration.loading.getAllCustomsOffice;
const companySubscribedSelector = (state) => 
  state.customsDeclaration.companySubscribed;
const updateDeclarationLoadingSelector = (state) => 
  state.customsDeclaration.loading.updateStep;
const declarationsInvoiceSelector = (state) => 
  state.customsDeclaration.declarationsInvoice;
const declarationsInvoiceByFiltersSelector = (state) => 
  state.customsDeclaration.declarationsInvoiceByFilters;
const declarationsInvoiceCountSelector = (state) => 
  state.customsDeclaration.declarationsInvoiceCount;
const getAllCategoriesDocumentsSelector = (state) => 
    state.customsDeclaration.categoriesDocuments;
const allChedSelector = (state) => 
  state.customsDeclaration.allChed;
const reloadLogisticomSelector = (state) => 
  state.customsDeclaration.reloadLogisticom;
const computedDeclarationsSelector = (state) => 
  state.customsDeclaration.computedDeclarations
const genBaeExtractSelector = (state) => 
  state.customsDeclaration.baeExtract
const getComputedChedsSelector = (state) => 
  state.customsDeclaration.computedCheds;
const getAllTracesSelector = (state) => 
  state.customsDeclaration.getAllTraces;
const getAllHostedSelector = (state) => 
  state.customsDeclaration.getAllHosted;
const getOneHostedSelector = (state) => 
  state.customsDeclaration.oneHosted;
const addPartnersSelector = (state) => 
  state.customsDeclaration.addPartners;
const addPartnerCommentSelector = (state) => 
  state.customsDeclaration.addPartnersComments;
const getPartnerCommentSelector = (state) => 
  state.customsDeclaration.getPartnersComments;
const createDeclarationSelector = (state) => 
  state.customsDeclaration.declare;
const getParkingSelector = (state) => 
  state.customsDeclaration.getParking;
const getConexSelector = (state) => 
  state.customsDeclaration.getConex;
const addLinesSelector = (state) => 
  state.customsDeclaration.addLines;
const checkConexDeliverySelector = (state) => 
  state.customsDeclaration.checkConex;
const addMandatesSelector = (state) => 
  state.customsDeclaration.addMandates;
const getMandatesSelector = (state) => 
  state.customsDeclaration.getMandates;
const getOneByReferenceSelector = (state) => 
  state.customsDeclaration.getOneByRef;
const getCategoriesSelector = (state) => 
  state.customsDeclaration.getCategories;
const addConsignmentSelector = (state) => 
  state.customsDeclaration.addConsignment;
const updateConsignmentSelector = (state) => 
  state.customsDeclaration.updateConsignment;
const deleteConsignmentSelector = (state) => 
  state.customsDeclaration.deleteConsignment;
const getAllLinesSelector = (state) => 
  state.customsDeclaration.getAllLines;
const createAccisesSelector = (state) => 
  state.customsDeclaration.accises;
const getAccisesSelector = (state) => 
  state.customsDeclaration.getAccises;
const getAllAccisesSelector = (state) => 
  state.customsDeclaration.getAllAccises;
const addTransitSelector = (state) => 
state.customsDeclaration.transit;
const updateTransitSelector = (state) => 
state.customsDeclaration.updateTransit;
const getTransitRatesSelector = (state) => 
state.customsDeclaration.transitRates;
const getTransitRiskSelector = (state) => 
state.customsDeclaration.transitRisk;
const downloadChedsSelector = (state) => 
state.customsDeclaration.chedFiles;
const updateCompany = (state) => 
  state.customsDeclaration.updateDeclaration;

const downloadLoadingSelector = createLoadingSelector([
  (state) => state.customsDeclaration.loading.chedFiles,
  (state) => state.customsDeclaration.loading.downloadDocuments,
  (state) => state.customsDeclaration.loading.downloadDocument,
]);

const companySubscribedLoadingSelector = (state) => 
  state.customsDeclaration.loading.companySubscribed;

const detailsLoadingSelector = createLoadingSelector([
  (state) => state.customsDeclaration.loading.getOne,
  (state) => state.customsDeclaration.loading.deleteDocument,
  (state) => state.customsDeclaration.loading.updateStep,
  (state) => state.customsDeclaration.loading.addStep,
  (state) => state.customsDeclaration.loading.cancelDeclaration,
  (state) => state.customsDeclaration.loading.getAllChed,
  (state) => state.customsDeclaration.loading.addChed,
  (state) => state.customsDeclaration.loading.removeChed,
  (state) => state.customsDeclaration.loading.getComputedCheds,
  (state) => state.customsDeclaration.loading.addPartnersComments,
  (state) => state.customsDeclaration.loading.getPartnersComments,
  (state) => state.customsDeclaration.loading.addLines,
  (state) => state.customsDeclaration.success.checkConex,
]);

const monitoringLoadingSelector = createLoadingSelector([
  (state) => state.customsDeclaration.loading.getProcessingDeclarations,
  (state) => state.customsDeclaration.loading.processingDeclarationsByFilters,
  (state) => state.customsDeclaration.loading.processingDeclarationsCount,
  (state) => state.customsDeclaration.loading.declarationsInvoice,
  (state) => state.customsDeclaration.loading.declarationsInvoiceByFilters,
  (state) => state.customsDeclaration.loading.declarationsInvoiceCount,
  (state) => state.customsDeclaration.loading.transitRisk,
]);

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  getAllHostedSelector,
  getOneHostedSelector,
  declarationsSelector,
  declarationSelector,
  getAllLoadingSelector,
  getAllHostedLoadingSelector,
  getOneLoadingSelector,
  deleteDocumentSelector,
  addDocumentsLoadingSelector,
  createDeclarationLoadingSelector,
  createDeclarationSelector,
  declarationChatLoadingSelector,
  downloadLoadingSelector,
  extractDocumentsLoadingSelector,
  getAllTracesSelector,
  declarationsCountSelector,
  hostedDeclarationsCountSelector,
  getOneHostedLoadingSelector,
  getOneHostedSuccessSelector,
  declarationChatSelector,
  processingDeclarationsSelector,
  processingDeclarationsLoadingSelector,
  processingDeclarationsByFiltersLoadingSelector,
  processingDeclarationsByFiltersSelector,
  processingDeclarationsCountSelector,
  getAllCustomsOfficeSelector,
  getAllCustomsOfficeLoadingSelector,
  companySubscribedSelector,
  updateDeclarationLoadingSelector,
  declarationsInvoiceSelector,
  declarationsInvoiceCountSelector,
  getAllCategoriesDocumentsSelector,
  getAllCategoriesDocumentsLoadingSelector,
  declarationsInvoiceByFiltersSelector,
  allChedSelector,
  detailsLoadingSelector,
  convertFileLoadingSelector,
  reloadLogisticomSelector,
  computedDeclarationsSelector,
  genBaeExtractSelector,
  genBaeExtractLoadingsSelector,
  getComputedChedsSelector,
  addPartnersSelector,
  getMandatesSelector,
  addMandatesSelector,
  getMandatesLoadingSelector,
  addMandatesLoadingSelector,
  getMandatesErrorsSelector,
  addPartnersLoadingSelector,
  addPartnersSuccessSelector,
  addPartnerCommentSelector,
  addPartnerCommentSuccessSelector,
  getPartnerCommentSelector,
  getPartnerCommentLoadingSelector,
  getPartnerCommentSuccessSelector,
  addPartnerCommentLoadingSelector,
  getParkingLoadingSelector,
  getParkingSelector,
  getConexSelector,
  addLinesSelector,
  checkConexDeliverySelector,
  getOneByReferenceLoadingSelector,
  getOneByReferenceSelector,
  getCategoriesSelector,
  getCategoriesLoadingSelector,
  addConsignmentSelector,
  updateConsignmentSelector,
  allChedLoadingSelector,
  deleteConsignmentSelector,
  getAllLinesSelector,
  getAllLinesLoadingSelector,
  createAccisesSelector,
  getAccisesSelector,
  getAllAccisesSelector,
  getAccisesErrorSelector,
  getAllAccisesErrorSelector,
  getAllAccisesLoadingSelector,
  getAccisesLoadingSelector,
  addTransitSelector,
  updateTransitSelector,
  getTransitRatesSelector,
  getTransitRatesLoadingSelector,
  getTransitRiskSelector,
  getTransitRiskLoadingSelector,
  downloadChedsSelector,
  downloadChedLoadingSelector,
  monitoringLoadingSelector,
  companySubscribedLoadingSelector,
  updateCompany
};
