import React, { useRef } from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Icon } from 'semantic-ui-react';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import { monitoringFiltersSchema } from 'interfaces/formSchema/taxflowValidators';
import { Input, YearInput, MonthInput} from 'components/Form';
import Dropdown from 'components/Dropdown';
import { VertSep } from './styles';


const Index = ({
  dispatch,
  declarationsLoading,
  applyFilters,
  searchLoading,
  resetFilters,
  isoList,
  isVisible,
}: any) => {
  const searchRef = useRef()

  type session = {
    limit: number;
    page: number;
    id: number;
    fromTo: string;
    year: string;
    month: string;
    companyId: string;
    country: string;
    intranetReference: string;
    showAll: boolean;
  }

  const data: session = JSON.parse(sessionStorage.getItem('c_taxflow_search'))

  return (
    <Segment
      {...(!isVisible && { display: 'none' })}
      margin='1rem 0 0 0'
      width='100%'
    >
      <Formik
        ref={searchRef}
        enableReinitialize={true} 
        validateOnChange={true}
        initialValues={data ? {
          id: data.id || '',
          fromTo: data.fromTo || '',
          year: data.year || '',
          month: data.month || '',
          country: data.country || '',
          intranetReference: data.intranetReference || '',
          showAll: data.showAll ? data.showAll : false,
        } : {
          id: '',
          fromTo: '',
          year: '',
          month: '',
          country: '',
          intranetReference: '',
          showAll: false,
        }}

        onSubmit={applyFilters}
        validationSchema={monitoringFiltersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper
              display='flex'
              justifyContent='center'
              flexDirection='column'
            >
             
              <Wrapper 
                display='flex' 
                margin='0.5rem' 
                justifyContent='center'
              >
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Declaration reference...'
                    name='id'
                    id='taxflowId'
                    type='text'
                    component={Input}
                    meta={{ 
                      errors, 
                      touched 
                    }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Country...'
                    name='country'
                    id='countryId'
                    options={isoList}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>                                                                                                         
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Intranet reference'
                    name='intranetReference'
                    id='intranetReference'
                    type='text'
                    component={Input}
                    meta={{ 
                      errors, 
                      touched 
                    }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Year'
                    name='year'
                    id='year'
                    component={YearInput}
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Month'
                    name='month'
                    id='month'
                    component={MonthInput}
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>
            <Wrapper display='flex' margin='1rem' justifyContent='center' alignItems='center'>
                <Field              
                  name='showAll'
                  id='showAll'
                  label='Archives'
                  margin='20px'
                  checked={values.showAll}
                  component={Checkbox}
                  meta={{ 
                    errors, 
                    touched,
                    setFieldValue, 
                    setFieldTouched 
                  }}
                  onChange={() => {
                    setFieldValue('showAll', !values.showAll)
                  }}
                />
              <VertSep />
              <Button
                disabled={declarationsLoading}
                {...(declarationsLoading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Button
                type='button'
                content='Reset'
                color='red'
                onClick={() => {
                  resetFilters();
                  resetForm();
                }}
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

const mapStateToProps = (state) => {};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);

