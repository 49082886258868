import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  hasPaidSubscription(data: dataReqI){
    return {
      type: actionTypes.HAS_PAID_SUBSCRIPTION,
      payload: data,
    };
  },
  checkQuickproofExist(data: dataReqI){
    return {
      type: actionTypes.CHECK_QUICKPROOF_EXIST,
      payload: data,
    };
  },
  changeVatNumber(data: dataReqI){
    return {
      type: actionTypes.SET_VAT,
      payload: data,
    };
  },
  getCompanySubscribed() {
    return {
      type: actionTypes.GET_COMPANY_SUBSCRIBED,
    };
  },
  subscribe(data: dataReqI) {
    return {
      type: actionTypes.SUBSCRIBE,
      payload: data,
    };
  },
  subscribeWithCode(data: dataReqI) {
    return {
      type: actionTypes.SUBSCRIBE_WITH_CODE,
      payload: data,
    };
  },
  contact(data: dataReqI) {
    return {
      type: actionTypes.CONTACT,
      payload: data,
    };
  },
  unsubscribe() {
    return {
      type: actionTypes.UNSUBSCRIBE,
    };
  },
  getAll() {
    return {
      type: actionTypes.GET_ALL,
    };
  },
  downloadAttestation(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_ATTESTATION,
      payload: data,
    };
  },
  downloadCustomsRegimeInvoice(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE,
      payload: data,
    };
  },
  askForProof(data: dataReqI) {
    return {
      type: actionTypes.ASK_FOR_PROOF,
      payload: data,
    };
  },
  getOne(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE,
      payload: data,
    };
  },
  getPrefilledDelivery(data: dataReqI) {
    return {
      type: actionTypes.GET_PREFILLED,
      payload: data,
    };
  },
  getPODOfOneTransporter(data: dataReqI) {
    return {
      type: actionTypes.GET_KYANITE_OF_ONE_TRANSPORTER,
      payload: data,
    };
  },
  getPODOfOneBuyer(data: dataReqI) {
    return {
      type: actionTypes.GET_KYANITE_OF_ONE_BUYER,
      payload: data,
    };
  },
  getPODOfOneSeller(data: dataReqI) {
    return {
      type: actionTypes.GET_KYANITE_OF_ONE_SELLER,
      payload: data,
    };
  },
  getPODOfOneSellerByFilters(data: dataReqI) {
    return {
      type: actionTypes.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS,
      payload: data,
    };
  },
  getCDIDs(data: dataReqI) {
    return {
      type: actionTypes.GET_CUSTOM_DECLARATIONS_ID,
      payload: data,
    };
  },
  getBuyersTransporters(data: dataReqI) {
    return {
      type: actionTypes.GET_FOR_ONE_ALL_BT,
      payload: data,
    };
  },
  searchForClientRef(data: dataReqI) {
    return {
      type: actionTypes.GET_CLIENT_REF,
      payload: data,
    };
  },
  create(data: dataReqI) {
    return {
      type: actionTypes.CREATE,
      payload: data,
    };
  },
  update(data: dataReqI) {
    return {
      type: actionTypes.UPDATE,
      payload: data,
    };
  },
  getTotalVatRisk(data: dataReqI) {
    return {
      type: actionTypes.GET_TOTAL_VAT_RISK,
      payload: data,
    };
  },
  getNumberOfReceived(data: dataReqI) {
    return {
      type: actionTypes.GET_NUMBER_OF_RECEIVED,
      payload: data,
    };
  },
  getNumberOfPending(data: dataReqI) {
    return {
      type: actionTypes.GET_NUMBER_OF_PENDING,
      payload: data,
    };
  },
  getNumberOfProofPending(data: dataReqI) {
    return {
      type: actionTypes.GET_NUMBER_OF_PROOF_PENDING,
      payload: data,
    };
  },
  uploadProof(data: dataReqI) {
    return {
      type: actionTypes.UPLOAD_PROOF,
      payload: data,
    };
  },
  handleSplitProof(data: dataReqI) {
    return {
      type: actionTypes.UPLOAD_SPLIT_PROOF,
      payload: data,
    };
  },
  downloadAllProof(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_ALL_PROOF,
      payload: data,
    };
  },
  downloadOneProof(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_ONE_PROOF,
      payload: data,
    };
  },

  getVatRisk(data: dataReqI) {
    return {
      type: actionTypes.GET_VAT_RISK,
      payload: data,
    };
  },

  validateProof(data: dataReqI) {
    return {
      type: actionTypes.VALIDATE_PROOF,
      payload: data,
    };
  },
  refuseProof(data: dataReqI) {
    return {
      type: actionTypes.REFUSE_PROOF,
      payload: data,
    };
  },
  validateDelivery(data: dataReqI) {
    return {
      type: actionTypes.VALIDATE_DELIVERY,
      payload: data,
    };
  },
  getOneForBuyer(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_FOR_BUYER,
      payload: data,
    };
  },
  getOneForTransporter(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_FOR_TRANSPORTER,
      payload: data,
    };
  },
  createMassIntegration(data: dataReqI) {
    return {
      type: actionTypes.CREATE_MASSINTEGRATION,
      payload: data,
    };
  },
  getMassIntegrations(data: dataReqI) {
    return {
      type: actionTypes.GET_MASSINTEGRATIONS,
      payload: data,
    };
  },
  downloadMassIntegrationTemplate(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_MASSINTEGRATION_TEMPLATE,
      payload: data,
    };
  },
  getOneMassIntegration(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_MASSINTEGRATION,
      payload: data,
    };
  },
  deleteMassIntegration(data: dataReqI) {
    return {
      type: actionTypes.DELETE_MASSINTEGRATION,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
