import { resetState } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    getAllNotification: null,
    getNumberUnreadNotification: null,
    notificationIsRead: null,
    notificationAllRead: null,
    notificationAllDelete: null,
  },
  errors: {
    getAllNotification: null,
    getNumberUnreadNotification: null,
    notificationIsRead: null,
    notificationAllRead: null,
    notificationAllDelete: null,
  },
  loading: {
    getAllNotification: false,
    getNumberUnreadNotification: false,
    notificationIsRead: false,
    notificationAllRead: false,
    notificationAllDelete: false,
  },

  openNotification: false,
  allNotification: null,
  unreadNotification: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // openNotification
    case actionTypes.OPEN_NOTIFICATION:
      return produce(state, (draft) => {
        draft.openNotification = !draft.openNotification;
      });

    // GetAllNotifications
    case actionTypes.GET_ALL_NOTIFICATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllNotification = true;
      });

    case actionTypes.GET_ALL_NOTIFICATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllNotification = null;
        draft.loading.getAllNotification = false;
        draft.success.getAllNotification = true;
        draft.allNotification = action.payload;
      });

    case actionTypes.GET_ALL_NOTIFICATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllNotification = true;
        draft.loading.getAllNotification = false;
        draft.success.getAllNotification = false;
        draft.error.includes('allNotification') &&
          draft.error.push('allNotification');
      });

    // GetNumberReadNotifications
    case actionTypes.GET_NUMBER_UNREAD_NOTIFICATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.getNumberUnreadNotification = true;
      });

    case actionTypes.GET_NUMBER_UNREAD_NOTIFICATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getNumberUnreadNotification = null;
        draft.loading.getNumberUnreadNotification = false;
        draft.success.getNumberUnreadNotification = true;
        draft.unreadNotification = action.payload;
      });

    case actionTypes.GET_NUMBER_UNREAD_NOTIFICATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.getNumberUnreadNotification = true;
        draft.loading.getNumberUnreadNotification = false;
        draft.success.getNumberUnreadNotification = false;
        draft.error.includes('unreadNotification') &&
          draft.error.push('unreadNotification');
      });

    // PostUpdateOneNotifation
    case actionTypes.NOTIFICATION_IS_READ_LOADING:
      return produce(state, (draft) => {
        draft.loading.notificationIsRead = true;
      });
    case actionTypes.NOTIFICATION_IS_READ_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.notificationIsRead = null;
        draft.loading.notificationIsRead = false;
        draft.success.notificationIsRead = true;
      });
    case actionTypes.NOTIFICATION_IS_READ_ERROR:
      return produce(state, (draft) => {
        draft.errors.notificationIsRead = true;
        draft.loading.notificationIsRead = false;
        draft.success.notificationIsRead = false;
      });

    // notificationAllRead
    case actionTypes.NOTIFICATION_ALL_READ_LOADING:
      return produce(state, (draft) => {
        draft.loading.notificationAllRead = true;
      });
    case actionTypes.NOTIFICATION_ALL_READ_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.notificationAllRead = null;
        draft.loading.notificationAllRead = false;
        draft.success.notificationAllRead = true;
      });
    case actionTypes.NOTIFICATION_ALL_READ_ERROR:
      return produce(state, (draft) => {
        draft.errors.notificationAllRead = true;
        draft.loading.notificationAllRead = false;
        draft.success.notificationAllRead = false;
      });

    // notificationAllDelete
    case actionTypes.NOTIFICATION_ALL_DELETE_LOADING:
      return produce(state, (draft) => {
        draft.loading.notificationAllDelete = true;
      });
    case actionTypes.NOTIFICATION_ALL_DELETE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.notificationAllDelete = null;
        draft.loading.notificationAllDelete = false;
        draft.success.notificationAllDelete = true;
      });
    case actionTypes.NOTIFICATION_ALL_DELETE_ERROR:
      return produce(state, (draft) => {
        draft.errors.notificationAllDelete = true;
        draft.loading.notificationAllDelete = false;
        draft.success.notificationAllDelete = false;
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
