import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  handleSettings(data: dataReqI) {
    return {
      type: actionTypes.CREATE_SETTING,
      payload: data
    };
  },
  getCompanySetting(data: dataReqI) {
    return {
      type: actionTypes.GET_SETTING,
      payload: data
    };
  },
  deleteSetting(data: dataReqI) {
    return {
      type: actionTypes.DELETE_SETTING,
      payload: data
    };
  },
  updateSetting(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_SETTING,
      payload: data
    };
  },
  getAssignedCountries(data: dataReqI){
    return {
      type: actionTypes.GET_ASSIGNED_COUNTRIES,
      payload: data
    };
  },
  updateAssignedCountries(data: dataReqI){
    return {
      type: actionTypes.UPDATE_ASSIGNED_COUNTRIES,
      payload: data
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys
      }
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys
      }
    };
  }
};
