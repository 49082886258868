import React from 'react';
import { Table } from 'semantic-ui-react';

import Text from 'components/Text';

const Index = ({ statement }: any) => {

  const streams = Object.keys(statement);
  const values: any = Object.values(statement);
  
  return (
    <Table selectable striped celled columns={4}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>
            <Text>HT</Text>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Text>TAXE</Text>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Text>TTC</Text>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {streams.map(
          (stream, i) =>        
            values[i].HT !== 0 && (
              <Table.Row key={i}>
                <Table.Cell>
                  <Text>{stream}</Text>
                </Table.Cell>
                <Table.Cell>{values[i].HT.toFixed(2)}</Table.Cell>
                <Table.Cell>{values[i].TAXE.toFixed(2)}</Table.Cell>
                <Table.Cell>{values[i].TTC && values[i].TTC.toFixed(2)}</Table.Cell>
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  );
};

export default Index;
