import styled from "@emotion/styled";


export const Paragraph = styled.p(() => ({
  float: 'left',
  lineHeight: '38px',
  padding: '0 10px',
  marginBottom: '10xp',
  width: '100%',
  border:'1px dashed rgb(204,204,204)',
  borderRadius: '10px',
  cursor: 'pointer'
}))

export const DocList = styled.div(() => ({
  lineHeight: '13px',
  float: 'left',
  padding: '2px 0',
}))

export const AsideWrapper = styled.div(() => ({
  float: 'left',
  marginTop: '10px',
  width: '100%',
  borderRadius: '10px',
  overflow: 'hidden',
  boxSizing: 'border-box',
}))

export const Aside = styled.aside(() => ({
  float: 'left',
  width: '100%',
  fontSize: '12px',
  maxHeight: '82px',
  overflowY: 'scroll',
  padding: '5px 10px',
  border: 'none',
  background: 'rgb(203 203 203 / 38%)',
  borderRadius: '.28571429rem',
  boxSizing: 'border-box',
}))

export const Alert = styled.div(() => ({
  fontSize: '12px',
  textAlign: 'center',
  padding: '0 1rem',
  margin: '0.5rem 0',
  lineHeight: '12px',
}));

export const Mandatory = styled.span(() => ({
  color: 'red'
}))

