import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Divider, TextArea } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import { operatorSchema } from 'interfaces/formSchema';

const Index = ({
  dispatch,
  company,
  settings,
  settingsLoading,
}) => {
  const [notesT, setNotes] = useState(null);

  useEffect(() => {
    dispatch(actions.companyIntrastatSettings.getCompanySetting({
      params: {
        companyId: company.id
      }
    }));

    dispatch(actions.socket.subscribeGetIntrastSetting(company.id))

    return () => {
      dispatch(actions.companyIntrastatSettings.reset(['getCompanySetting', 'error']));
      dispatch(actions.socket.unsubscribeGetIntrastSetting(company.id))
    };
  }, [dispatch, company.id]);

  useEffect(() => {
    settings && setNotes(settings.notes)
  }, [settings]);


  const save = (values) => {
    dispatch(
        actions.companyIntrastatSettings.handleSettings({
          params: { 
            companyId: company.id 
          },
          data: {
            action: 'create',
            notes: values.notes
        }})
      );
  };

  const handleDelete = () => {
    dispatch(
      actions.companyIntrastatSettings.handleSettings({
        params: { 
          companyId: company.id 
        },
        data: {
          action: 'delete',
          settings: true
      }})
    );
  }


  return (
    <Wrapper
      display='flex'
      justifyContent='center'
      height='400px'
      padding='2rem 10rem 5rem'> 

      <Wrapper 
        display='flex' 
        width='100%' 
        flexDirection='column' 
        justifyContent='flex-start' 
        alignItems='center'> 

          <Formik
            enableReinitialize={true}
            initialValues={{
              notes: notesT || '', 
            }}
            onSubmit={save}
            validationSchema={operatorSchema}
          >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            values,
            isValid,
          }) => (
         
            <Form onSubmit={handleSubmit}>
                              
                <Wrapper margin='0 0.5rem 0.5rem 0.5rem' maxWidth='600px' minWidth='600px'>                         
                  <Field  
                    label='Notes'            
                    placeholder='Write notes...'
                    defaultValue={values.notes}
                    name='notes'
                    component={TextArea} 
                    style={{ 
                      minHeight: 250, 
                      overflow: 'auto',   
                      boxSizing: 'border-box',
                      resize: 'none',                        
                    }}       
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    onChange={(e, {value}) => {
                      e && setFieldValue('notes', value)
                    }}
                  />
                  <Divider hidden />
                  <Wrapper 
                      display='flex'
                      justifyContent='center'> 
                    <Button type='submit' content='Submit'/>
                   
                  </Wrapper>

                </Wrapper> 
              </Form>
            
          )}
        </Formik>

        <Button 
          color='red'
          content='Delete'
          onClick={() => handleDelete()}
        />

   
    <Divider hidden/>   
    </Wrapper>
    </Wrapper>
  
  );
};

const mapStateToProps = (state) => {
  return {
    generalPermissions: selectors.admin.permissionsSelector(state),
    settingsLoading: selectors.companyIntrastatSettings.getCompanySettingsLoadingSelector(state),
    settings: selectors.companyIntrastatSettings.companyIntrastatSettingsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
