import styled from '@emotion/styled';

export const StepWrapper = styled.div`
  background-color: ${(props) => props.color || '#717171'};
  height: 25px;
  line-height: 25px;
  min-width: ${(props: any) => props.width || '185px'};
  max-width: ${(props: any) => props.width || '185px'};
  color: white;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 0.5em 0 0.5em 0;
`;
