import actionTypes from 'store/notifications/actionTypes';
import routes from './routes';
import { actions as notificationHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.GET_ALL_NOTIFICATION:
        try {
          next({ type: actionTypes.GET_ALL_NOTIFICATION_LOADING });
          setTimeout(async () => {
            const response = await request.getAllNotification();
            next({
              type: actionTypes.GET_ALL_NOTIFICATION_SUCCESS,
              payload: response.data.data,
            });
          }, 300);
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_NOTIFICATION_ERROR,
            error,
          });
          store.dispatch(
            notificationHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_NUMBER_UNREAD_NOTIFICATION:
        try {
          next({ type: actionTypes.GET_NUMBER_UNREAD_NOTIFICATION_LOADING });
          const response = await request.getNumberUnreadNotification();
          next({
            type: actionTypes.GET_NUMBER_UNREAD_NOTIFICATION_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_NUMBER_UNREAD_NOTIFICATION_ERROR,
            error,
          });
          store.dispatch(
            notificationHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.NOTIFICATION_IS_READ:
        try {
          next({ type: actionTypes.NOTIFICATION_IS_READ });
          const response = await request.notificationIsRead({
            ...action.payload,
          });
          next({
            type: actionTypes.NOTIFICATION_IS_READ_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.NOTIFICATION_IS_READ_ERROR,
            error,
          });
          store.dispatch(
            notificationHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.NOTIFICATION_ALL_READ:
        try {
          next({ type: actionTypes.NOTIFICATION_ALL_READ });
          const response = await request.notificationAllRead();
          next({
            type: actionTypes.NOTIFICATION_ALL_READ_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.NOTIFICATION_ALL_READ_ERROR,
            error,
          });
          store.dispatch(
            notificationHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.NOTIFICATION_IS_SHOW:
        try {
          next({ type: actionTypes.NOTIFICATION_IS_SHOW });
          const response = await request.notificationIsShow({
            ...action.payload,
          });
          next({
            type: actionTypes.NOTIFICATION_IS_SHOW_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.NOTIFICATION_IS_SHOW_ERROR,
            error,
          });
          store.dispatch(
            notificationHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.NOTIFICATION_ALL_DELETE:
        try {
          next({ type: actionTypes.NOTIFICATION_ALL_DELETE });
          await request.notificationAllDelete({
            ...action.payload,
          });
          next({
            type: actionTypes.NOTIFICATION_ALL_DELETE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.NOTIFICATION_ALL_DELETE_ERROR,
            error,
          });
          store.dispatch(
            notificationHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
