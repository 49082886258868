import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Header, Icon, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import { Link } from 'react-router-dom';
import theme from 'theme';

import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Pagination from 'components/Pagination';
import { paths } from 'routes/customs/customsHelper';
import EmptyTable from 'components/EmptyTable';
import { shortDate } from 'services/helper/dateFormater';
import Button from 'components/Button';
import { Spacer, Step } from 'components/core';
import SearchFilters from './Components/SearchFilters/searchFilters';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const CustomsClerk = ({
  history,
  dispatch,
  files,
  filesCount,
  filesByFilters,
  filesByFiltersCount,
  pochetteLoading,
}) => {
  const colors = theme.getTheme().colors;
  const colorsStep = {
    inProgress: colors.inProgressStep,
    finished: colors.finishedStep,
    validated: colors.validatedStep,
  };
  const limitPerPage = 50;
  const [filter, setFilter] = useState('all');
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterBar, setfilterBar] = useState(false);
  const [searchFilters, setsearchFilters] = useState(null);
  const [resetPagination, setresetPagination] = useState(false);
  const [searchStarted, setsearchStarted] = useState(false);
  const [filtered, setfiltered] = useState(false);

  useEffect(() => {
    dispatch(
      actions.customsClerk.getAllFiles({
        query: {
          limit: limitPerPage,
          page: 1,
        },
      })
    );

    setresetPagination(true);

    dispatch(actions.socket.subscribeRefreshCustomsClerkFiles());

    return () => {
      dispatch(actions.socket.unsubscribeRefreshCustomsClerkFiles());
      dispatch(
        actions.customsClerk.reset([
          'allFiles',
          'allFilesCount',
          'allFilesByFilters',
          'allFilesByFiltersCount',
          'error',
        ])
      );
    };
  }, [dispatch]);

  const getAllFilesByFilters = useCallback(() => {
    dispatch(
      actions.customsClerk.getAllFilesByFilters({
        data: searchFilters,
        query: {
          limit: limitPerPage,
          page: 1,
        },
      })
    );
  }, [dispatch, searchFilters]);

  useEffect(() => {
    if (searchStarted || filtered) {
      dispatch(actions.socket.unsubscribeRefreshCustomsClerkFiles());
      getAllFilesByFilters();
      setresetPagination(true);
    }
  }, [dispatch, searchFilters, filtered, searchStarted, getAllFilesByFilters]);

  useEffect(() => {
    if (searchFilters) {
      let newSearchFilters = searchFilters;
      newSearchFilters['inProgress'] = filter === 'inProgress';
      newSearchFilters['finished'] = filter === 'finished';
      newSearchFilters['validated'] = filter === 'validated';
      setsearchFilters(newSearchFilters);
      setfiltered(true);
    } else if (filter !== 'all') {
      let newSearchFilters = {
        inProgress: filter === 'inProgress',
        finished: filter === 'finished',
        validated: filter === 'validated',
        declarationId: '',
        company: '',
        startDate: '',
        endDate: '',
        paidBy: '',
        minimalAmount: undefined,
        maximalAmount: undefined,
        licensePlate: '',
      };
      setsearchFilters(newSearchFilters);
      setfiltered(true);
    }
    setresetPagination(true);
  }, [filter, searchFilters]);

  useEffect(() => {
    if (filtered) {
      setfiltered(false);
    }
  }, [filtered]);

  useEffect(() => {
    if (files) {
      const inProgress = [];
      const finished = [];
      const validated = [];
      files.forEach((file) => {
        if (file.validated) validated.push(file);
        else if (file.signatureEndS3Path) finished.push(file);
        else inProgress.push(file);
      });

      setData(inProgress.concat(finished, validated));
      setLoading(false);
    }
  }, [files]);

  useEffect(() => {
    if (filesByFilters) {
      setData(filesByFilters);
      setLoading(false);
    }
  }, [filesByFilters]);

  useEffect(() => {
    if (data) {
      setresetPagination(false);
    }
  }, [data]);


  const getDataBySearchFilter = (newSearchFilters) => {
    if (!_.isEqual(searchFilters, newSearchFilters)) {
      dispatch(
        actions.customsClerk.reset([
          'allFilesByFilters',
          'allFilesByFiltersCount',
          'error',
        ])
      );
      if (newSearchFilters.minimalAmount === '') {
        newSearchFilters.minimalAmount = undefined;
      }
      if (newSearchFilters.maximalAmount === '') {
        newSearchFilters.maximalAmount = undefined;
      }

      setsearchFilters({
        inProgress: filter === 'inProgress' || false,
        finished: filter === 'finished' || false,
        validated: filter === 'validated' || false,
        ...newSearchFilters,
      });
      setsearchStarted(true);
    }
  };

  const resetSearchFilters = () => {
    if (searchFilters) {
      setsearchStarted(false);
      setsearchFilters(null);
      setFilter('all');
      dispatch(
        actions.customsClerk.reset([
          'allFilesByFilters',
          'allFilesByFiltersCount',
          'error',
        ])
      );
      setData(files);
    }
  };

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setData(_.sortBy(data, [name]));
      return;
    }
    setData(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const handlePageChange = (event, data) => {
    if (filter !== 'all' || searchFilters) {
      dispatch(
        actions.customsClerk.getAllFilesByFilters({
          data: searchFilters,
          query: {
            limit: limitPerPage,
            page: Math.ceil(data.activePage),
          },
        })
      );
    } else {
      dispatch(
        actions.customsClerk.getAllFiles({
          query: {
            limit: limitPerPage,
            page: Math.ceil(data.activePage),
          },
        })
      );
    }
  };

  const createPochette = (issue) => {
    dispatch(
      actions.customsClerk.reset([
        'clerkControlPdfFile',
        'error',
      ])
    );
    dispatch(
      actions.customsClerk.createPochettePDF({
        params: {
          fileId: issue.id,
          companyId: issue.CustomsDeclaration.Company_Id,
          declarationId: issue.CustomsDeclaration_Id,
        },
        data: {
          row: issue
        }
      })
    );
  }

  const Consignation = styled.div(() => ({
    width: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  }))

  const NoteWrapper = styled.div(() => ({
    width: '80px',
    marginRight: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }))

  const Pochette = styled.div(() => ({
    width: '80px',
    marginRight: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }))

  const Note = styled.div`
    width: 25px;
    height: 25px;
    margin: 0 4px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    border-radius: 15px;
    line-height: 25px;
    color: #fff;
    background: ${props => props.color};
   
    `

  return (
    <Wrapper overflow='auto' width='90%' margin='1rem auto'>
      <Spacer height='20px' />
      <Wrapper display='flex' justifyContent='center'>
        <Header size='huge'>Customs Clerk dashboard</Header>
      </Wrapper>
      <Spacer />
        <Button
          labelPosition='right'
          position='center'
          icon='plus circle'
          type='button'
          content='New file'
          size='big'
          onClick={() => {
            history.push(paths.customsHelperNewFile.replace(':fileId', ''));
          }}
        />
      <Spacer />
      {((loading || pochetteLoading) && <Loader content='Loading' />) || (
        <Wrapper>
          <Spacer />
          <Wrapper display='flex' justifyContent='space-between'>
            <p>Number of files displayed: {data.length}</p>
            <p>
              Total number of files: {filesByFiltersCount ?? filesCount ?? 0}
            </p>
          </Wrapper>
          <Wrapper display='flex' justifyContent='space-between'>
            <Button
              icon='filter'
              type='button'
              content='Search Filters'
              color='blue'
              onClick={() => setfilterBar(!filterBar)}
            />
            <Wrapper display='flex' justifyContent='center'>
              <Button
                type='button'
                content='All'
                {...(filter === 'all'
                  ? { color: 'activeBlue' }
                  : { color: 'blue' })}
                onClick={() => setFilter('all')}
              />
              <Button
                type='button'
                content='In progress'
                // color='blue'
                {...(filter === 'inProgress'
                  ? { color: 'activeBlue' }
                  : { color: 'blue' })}
                onClick={() => setFilter('inProgress')}
              />
              <Button
                type='button'
                content='Finished'
                {...(filter === 'finished'
                  ? { color: 'activeBlue' }
                  : { color: 'blue' })}
                onClick={() => setFilter('finished')}
              />
              <Button
                type='button'
                content='Validated'
                {...(filter === 'validated'
                  ? { color: 'activeBlue' }
                  : { color: 'blue' })}
                onClick={() => setFilter('validated')}
              />
            </Wrapper>
          </Wrapper>
          <Wrapper display='flex' justifyContent='flex-end'>
            <SearchFilters
              isVisible={filterBar}
              applyFilters={getDataBySearchFilter}
              resetFilters={resetSearchFilters}
            />
          </Wrapper>
          <Table columns={6} stackable sortable selectable striped>
            <Table.Header>
              <Table.Row>
              <Table.HeaderCell style={{'width': '100px'}}>Summary</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'company' ? direction : null}
                  onClick={() => handleSort('company')}
                >
                  Company
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'declarationId' ? direction : null}
                  onClick={() => handleSort('declarationId')}
                >
                  Declaration ID
                </Table.HeaderCell>
                <Table.HeaderCell>Trailer</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Lock by</Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data && data.length === 0 && <EmptyTable colNumber={6} />) ||
                data.map((e, i) => {
                  const isFinished = e.signatureEndS3Path ? true : false;
                  const isValidated = e.validated ? true : false;
                  return (
                    <Table.Row key={i}>
                      <Table.Cell style={{'width': '100px', 'cursor': 'grab'}}>
                        {!isFinished && (
                          <Pochette onClick={() => {
                            createPochette(e)
                          }}>
                          <Icon
                          className='sticky note outline icon'
                          name='folder open'
                          size='large'
                          color='black'                    
                          />
                          </Pochette>
                        )}
                      </Table.Cell>
                      <Link
                        to={paths.customsHelperFileEdition.replace(
                          ':fileId',
                          e.id
                        )}
                        className='table-link'
                      >
                        <Table.Cell data-label='Company'>
                        {e.CustomsDeclaration.Company.name} 
                       
                        </Table.Cell>
                        <Table.Cell data-label='Declaration id'>
                        <Consignation>
                            <div>{e.CustomsDeclaration.id ?? 'Not filled'}</div>
                            <NoteWrapper css={css`
                                @media (max-width: 500px) {
                                  flex-direction: column;
                                }
                              `}>{e.Consignments.length>0 && e.Consignments.map((e, i) => (
                              i===0 ? <Note key={i} color={'#dda80b'}>{e.status.charAt(0).toUpperCase()}</Note> : 
                              <Note key={i} color={'#00619b'}>{e.status.charAt(0).toUpperCase()}</Note>
                              ))}</NoteWrapper>
                        </Consignation>
                          
                        </Table.Cell>
                        <Table.Cell>
                          {e.CustomsDeclaration.trailerLicensePlate}
                        </Table.Cell>
                        <Table.Cell data-label='Creation date'>
                          {shortDate(new Date(e.createdAt)) ?? 'Not filled'}
                        </Table.Cell>
                        <Table.Cell data-label='Creation date'>
                          {e.Operator_Id
                            ? `${e.Operator.lastName} - ${e.Operator.firstName}`
                            : 'Not locked'}
                        </Table.Cell>
                        <Table.Cell data-label='state'>
                          <Step
                            label={
                              isFinished
                                ? isValidated
                                  ? 'Validated'
                                  : 'Finished'
                                : 'In progress'
                            }
                            color={
                              colorsStep[
                                isFinished
                                  ? isValidated
                                    ? 'validated'
                                    : 'finished'
                                  : 'inProgress'
                              ]
                            }
                          />
                        </Table.Cell>
                      </Link>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>
      )}
      <Divider hidden />
      {!resetPagination && (
        <Wrapper display='flex' justifyContent='center'>
          <Pagination
            defaultActivePage={1}
            totalPages={
              filesByFiltersCount !== null
                ? filesByFiltersCount / limitPerPage
                : filesCount / limitPerPage
            }
            onChange={handlePageChange}
            resetPagination={resetPagination}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    files: selectors.customsClerk.allFilesSelector(state),
    filesCount: selectors.customsClerk.allFilesCountSelector(state),
    filesByFilters: selectors.customsClerk.allFilesByFiltersSelector(state),
    filesByFiltersCount: selectors.customsClerk.allFilesByFiltersCountSelector(
      state
    ),
    company: selectors.company.companySelector(state),
    pochetteLoading: selectors.customsClerk.pochetteLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(CustomsClerk);
