export default {
  DECLARE: 'MY_ASD.FISCAL.VATDECLARATION.DECLARE',
  DECLARE_LOADING: 'MY_ASD.FISCAL.VATDECLARATION.DECLARE_LOADING',
  DECLARE_SUCCESS: 'MY_ASD.FISCAL.VATDECLARATION.DECLARE_SUCCESS',
  DECLARE_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.DECLARE_ERROR',

  GET_COMPANY_SUBSCRIBED: 'MY_ASD.FISCAL.VATDECLARATION.GET_COMPANY_SUBSCRIBED',
  GET_COMPANY_SUBSCRIBED_LOADING:
    'MY_ASD.FISCAL.VATDECLARATION.GET_COMPANY_SUBSCRIBED_LOADING',
  GET_COMPANY_SUBSCRIBED_SUCCESS:
    'MY_ASD.FISCAL.VATDECLARATION.GET_COMPANY_SUBSCRIBED_SUCCESS',
  GET_COMPANY_SUBSCRIBED_ERROR:
    'MY_ASD.FISCAL.VATDECLARATION.GET_COMPANY_SUBSCRIBED_ERROR',

  GETDECLARATIONS: 'MY_ASD.FISCAL.VATDECLARATION.GETDECLARATIONS',
  GETDECLARATIONS_LOADING:
    'MY_ASD.FISCAL.VATDECLARATION.GETDECLARATIONS_LOADING',
  GETDECLARATIONS_SUCCESS:
    'MY_ASD.FISCAL.VATDECLARATION.GETDECLARATIONS_SUCCESS',
  GETDECLARATIONS_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.GETDECLARATIONS_ERROR',

  SUBSCRIBE: 'MY_ASD.FISCAL.VATDECLARATION.SUBSCRIBE',
  SUBSCRIBE_LOADING: 'MY_ASD.FISCAL.VATDECLARATION.SUBSCRIBE_LOADING',
  SUBSCRIBE_SUCCESS: 'MY_ASD.FISCAL.VATDECLARATION.SUBSCRIBE_SUCCESS',
  SUBSCRIBE_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.SUBSCRIBE_ERROR',

  UNSUBSCRIBE: 'MY_ASD.FISCAL.VATDECLARATION.UNSUBSCRIBE',
  UNSUBSCRIBE_LOADING: 'MY_ASD.FISCAL.VATDECLARATION.UNSUBSCRIBE_LOADING',
  UNSUBSCRIBE_SUCCESS: 'MY_ASD.FISCAL.VATDECLARATION.UNSUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.UNSUBSCRIBE_ERROR',

  GETMAPPING: 'MY_ASD.FISCAL.VATDECLARATION.GETMAPPING',
  GETMAPPING_LOADING: 'MY_ASD.FISCAL.VATDECLARATION.GETMAPPING_LOADING',
  GETMAPPING_SUCCESS: 'MY_ASD.FISCAL.VATDECLARATION.GETMAPPING_SUCCESS',
  GETMAPPING_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.GETMAPPING_ERROR',

  GETTA: 'MY_ASD.FISCAL.VATDECLARATION.GETTA',
  GETTA_LOADING: 'MY_ASD.FISCAL.VATDECLARATION.GETTA_LOADING',
  GETTA_SUCCESS: 'MY_ASD.FISCAL.VATDECLARATION.GETTA_SUCCESS',
  GETTA_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.GETTA_ERROR',

  GETLINES: 'MY_ASD.FISCAL.VATDECLARATION.GETLINES',
  GETLINES_LOADING: 'MY_ASD.FISCAL.VATDECLARATION.GETLINES_LOADING',
  GETLINES_SUCCESS: 'MY_ASD.FISCAL.VATDECLARATION.GETLINES_SUCCESS',
  GETLINES_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.GETLINES_ERROR',

  GETDECLARATION: 'MY_ASD.FISCAL.VATDECLARATION.GETDECLARATION',
  GETDECLARATION_LOADING: 'MY_ASD.FISCAL.VATDECLARATION.GETDECLARATION_LOADING',
  GETDECLARATION_SUCCESS: 'MY_ASD.FISCAL.VATDECLARATION.GETDECLARATION_SUCCESS',
  GETDECLARATION_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.GETDECLARATION_ERROR',

  VALIDATE: 'MY_ASD.FISCAL.VATDECLARATION.VALIDATE',
  VALIDATE_LOADING: 'MY_ASD.FISCAL.VATDECLARATION.VALIDATE_LOADING',
  VALIDATE_SUCCESS: 'MY_ASD.FISCAL.VATDECLARATION.VALIDATE_SUCCESS',
  VALIDATE_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.VALIDATE_ERROR',

  REMOVE: 'MY_ASD.FISCAL.VATDECLARATION.REMOVE',
  REMOVE_LOADING: 'MY_ASD.FISCAL.VATDECLARATION.REMOVE_LOADING',
  REMOVE_SUCCESS: 'MY_ASD.FISCAL.VATDECLARATION.REMOVE_SUCCESS',
  REMOVE_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.REMOVE_ERROR',

  RESET: 'MY_ASD.FISCAL.VATDECLARATION.RESET',
  REFRESH_ERROR: 'MY_ASD.FISCAL.VATDECLARATION.REFRESH_ERROR',
};
