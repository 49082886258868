import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const vatApi = routesApi.company.vat;
  return {
    createVat(data: dataReqI) {
      return api.request({
        method: vatApi.createVat.method,
        route: vatApi.createVat.path,
        params: data.params,
        data: data.data
      });
    },
    getAllVat(data: dataReqI) {
      return api.request({
        method: vatApi.getAllVat.method,
        route: vatApi.getAllVat.path,
        params: data.params
      });
    },
    getOneVat(data: dataReqI) {
      return api.request({
        method: vatApi.getOneVat.method,
        route: vatApi.getOneVat.path,
        params: data.params,
      });
    },
    updateVat(data: dataReqI) {
      return api.request({
        method: vatApi.updateVat.method,
        route: vatApi.updateVat.path,
        params: data.params,
        data: data.data
      });
    },
    deleteVat(data: dataReqI) {
      return api.request({
        method: vatApi.deleteVat.method,
        route: vatApi.deleteVat.path,
        params: data.params
      });
    },
  };
};
