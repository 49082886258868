import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  addKeyInfo(data: dataReqI) {
    return {
      type: actionTypes.ADD_KEYINFO,
      payload: data,
    };
  },
  updateKeyInfo(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_KEYINFO,
      payload: data,
    };
  },
  removeKeyInfo(data: dataReqI) {
    return {
      type: actionTypes.REMOVE_KEYINFO,
      payload: data,
    };
  },
  getKeyInfos(data: dataReqI) {
    return {
      type: actionTypes.GET_KEYINFOS,
      payload: data,
    };
  },
  keyInfoSetDone(data: dataReqI) {
    return {
      type: actionTypes.SET_DONE_KEYINFO,
      payload: data,
    };
  },
  checkKeyInfoSetDone(data: dataReqI) {
    return {
      type: actionTypes.CHECK_DONE_KEYINFO,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
