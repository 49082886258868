import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Divider, Accordion } from 'semantic-ui-react';
import { Field, FieldArray, Formik, ErrorMessage } from 'formik';

import { Checkbox } from 'components/Form';
import SimpleCheckbox from 'components/Checkbox';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Error } from 'components/Form';
import Button from 'components/Button';
import { userPermissionsSchema } from 'interfaces/formSchema';

const Index = ({
  dispatch,
  errorHandlerCompany,
  errorHandlerCompanyUser,
  companyId,
  userId,
  userNotificationPerm,
  selectedUser,
  companyPermissionsData,
  userPermissionsData,
  loadingHandlerCompanyUser,
}) => {
  const [runningAction, setRunningAction] = useState(null);
  const [companyPermissions, setCompanyPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [userPerms, setUserPerms] = useState([]);

  useEffect(() => {
    dispatch(actions.company.reset(['error']));

    dispatch(
      actions.company.getPermissions({params: {companyId: companyId}})
    );
    dispatch(
      actions.companyUser.getUserPermissions({
        params: { companyId: companyId, userId: userId },
      })
    );

    return () => {
      dispatch(actions.company.reset(['permissions', 'error']));
    };
  }, [dispatch, companyId, userId]);

  useEffect(() => {
    if (runningAction !== null && !loadingHandlerCompanyUser) {
      if (!errorHandlerCompanyUser.includes(runningAction)){
        dispatch(
          actions.companyUser.getUserPermissions({
            params: { companyId: companyId, userId: userId },
          })
        );
      }
      setRunningAction(null);
    }
  }, [
    dispatch, 
    loadingHandlerCompanyUser, 
    userId, 
    companyId, 
    errorHandlerCompanyUser,
    runningAction
  ]);

  useEffect(() => {
    companyPermissionsData && setCompanyPermissions(companyPermissionsData);
    userPermissionsData && setUserPermissions(userPermissionsData);
  }, [companyPermissionsData, userPermissionsData]);

  useEffect(() => {
    let formatedPermissions = {};
    companyPermissions.forEach((permission) => {
      const groupName = permission.Service
        ? permission.Service.name
        : permission.group;
      formatedPermissions[groupName]
        ? formatedPermissions[groupName].push(permission)
        : (formatedPermissions[groupName] = [permission]);
    });

    setGroupedPermissions(formatedPermissions);
  }, [companyPermissions]);

  useEffect(() => {
    setUserPerms(userPermissions.map((e) => e.Permission.id));
  }, [userPermissions]);

  const firstLetterUpperCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const save = (values) => {
    dispatch(
      actions.companyUser.updateUserPermissions({
        data: {
          permissions: values.permissions, 
          notification: values.notification,
          emitterNotification: values.emitterNotification, 
        },
        params: { companyId: companyId, userId: userId},
      })
    );
    setRunningAction('updateUserPermissions');
  };

  return (
    groupedPermissions && (
      <Formik
        initialValues={{
          permissions: userPerms,
      
        }}
        enableReinitialize={true}
        onSubmit={save}
        validationSchema={userPermissionsSchema}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
        }: any) => (
          <Wrapper>
            {(groupedPermissions === null && <Loader />) || (
              <Form
                onSubmit={handleSubmit}
                loading={
                  companyPermissions === null &&
                  userPermissions === null &&
                  errorHandlerCompany.length === 0 &&
                  errorHandlerCompanyUser.length
                }
              >
                
               

                <Wrapper padding='0 0 0 14rem'>
                  {Object.values(groupedPermissions).map(
                    (permissions: any[], i) => {
                      const permissionsId = permissions.map((e) => e.id);
                      let isGroupFull = !permissionsId.some(
                        (e) => !values.permissions.includes(e)
                      );
                      const panels = [
                        {
                          key: i,
                          title: (
                            <Wrapper margin='0 0 0.5rem -1.5rem' key={i}>
                              <SimpleCheckbox
                                label={firstLetterUpperCase(
                                  Object.keys(groupedPermissions)[i]
                                )}
                                checked={isGroupFull}
                                onChange={(checked) => {
                                  const newPerms = !isGroupFull
                                    ? Array.from(
                                        new Set(
                                          values.permissions.concat(
                                            permissionsId
                                          )
                                        )
                                      )
                                    : values.permissions.filter(
                                        (e) => !permissionsId.includes(e)
                                      );
                                  setFieldValue('permissions', newPerms);
                                }}
                              />
                            </Wrapper>
                          ),
                          content: (
                            <FieldArray
                              name='permissions'
                              render={(arrayHelper) => (
                                <>
                                  {permissions.map((permission: any, i) => {
                                    return (
                                      <Wrapper key={i}>
                                        <Wrapper>
                                          <Wrapper>
                                            <Field
                                              key={i}
                                              name={`permissions[${i}]`}
                                              component={Checkbox}
                                              label={firstLetterUpperCase(
                                                permission.action
                                              )}
                                              popupContent={
                                                permission.description
                                              }
                                              onChange={() => {
                                                if (
                                                  values.permissions.includes(
                                                    permission.id
                                                  )
                                                ) {
                                                  arrayHelper.remove(
                                                    values.permissions.findIndex(
                                                      (e) => e === permission.id
                                                    )
                                                  );
                                                } else {
                                                  arrayHelper.push(
                                                    permission.id
                                                  );
                                                }
                                              }}
                                              checked={values.permissions.includes(
                                                permission.id
                                              )}
                                              meta={{
                                                errors,
                                                touched,
                                                setFieldValue,
                                                setFieldTouched,
                                              }}
                                              uniqueError={errorHandlerCompany.includes(
                                                'permissions'
                                              )}
                                            />
                                          </Wrapper>
                                        </Wrapper>
                                      </Wrapper>
                                    );
                                  })}
                                </>
                              )}
                            />
                          ),
                        },
                      ];
                      return (
                        <Wrapper
                          display='inline-grid'
                          padding='0 0 5rem 5rem'
                          width='18rem'
                          key={i}
                        >
                          <Accordion panels={panels} />
                        </Wrapper>
                      );
                    }
                  )}
                </Wrapper>
                <ErrorMessage
                  name='permissions'
                  render={(msg) => <Error msg={msg} />}
                />

                <Divider hidden />
                <Button
                  type='submit'
                  content='Submit'
                  color='green'
                  margin='0px'
                />
              </Form>
            )}
          </Wrapper>
        )}
      </Formik>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    companyPermissionsData: selectors.company.permissionsSelector(state),
    userPermissionsData: selectors.companyUser.userPermissionsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
