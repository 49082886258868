import styled from '@emotion/styled';

export const CardWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',

  '@media (max-width: 940px)': {
    flexDirection: 'column',
  },
}));

export const ExternalLink = styled.a(() => ({}));
