import React from 'react';
import { getIn } from 'formik';
import { Wrapper, CheckboxStyled, CheckboxIcon } from './styles';
import { Icon, Popup } from 'semantic-ui-react';

const Index = ({
  label,
  field,
  meta,
  value,
  check,
  checked,
  onChange,
  disabled,
  popupContent,
  radio,
}: CheckboxI) => {
  return (
    <Wrapper>
      <CheckboxStyled
        radio={radio}
        disabled={disabled}
        value={value}
        name={field.name}
        label={label}
        defaultChecked={check}
        checked={checked}
        error={
          meta.touched[field.name.split('[')[0]] &&
          getIn(meta.errors, field.name)
            ? true
            : false
        }
        onChange={(e, { name, value }) => {
          if (onChange) onChange(value);
          else meta.setFieldValue(name, value);
        }}
      />
      {popupContent && (
        <Popup
          content={popupContent}
          trigger={
            <CheckboxIcon>
              <Icon name='info circle' />
            </CheckboxIcon>
          }
        />
      )}
    </Wrapper>
  );
};

interface CheckboxI {
  label: string;
  field: any;
  meta: any;
  value: string;
  check: boolean;
  checked: boolean;
  onChange: any;
  disabled: boolean;
  popupContent?: string;
  radio?: boolean;
}

export default Index;
