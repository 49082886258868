export default {
  "button.connection": "Connexion",
  "button.next": "Suivant",
  "button.back": "Retour",
  "button.registration": "Inscription",
  "button.login": "Connexion",
  "button.send": "Envoyer",
  "button.save": "Sauvegarder",
  "button.submit": "Valider",
  "button.download": "Télécharger",
};
