import styled from '@emotion/styled';

export const Cost = styled.div(() => ({
    display: 'flex',
    height: '37.6px',
    padding: '.67857143em 1em',
    border: '1px solid rgb(204,204,204)',
    borderRadius: '10px',
}));

export const Label = styled.div(() => ({
    fontSize: '14px'
}))
