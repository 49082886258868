import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Modal, Header, Message } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import { actions, selectors} from 'store';

import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import ErrorHandler from 'components/ErrorHandler';
import { Input } from 'components/Form';
import { addPartnerSchema } from 'interfaces/formSchema';

const Index = ({
  errorHandler,
  open,
  handleClose,
  intl,
  dispatch,
  companyId,
  setRunningAction,
}) => {
  const submit = (values) => {
    dispatch(
      actions.contact.addContactPartner({
        params: { companyId: companyId, uniqueNumber: values.uniqueNumber },
      })
    );
    setRunningAction('addPartners');
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Content>
        <Wrapper>
          <Header>Add a new partner:</Header>
          <Wrapper display='flex' justifyContent='left'
              padding='0rem 1rem 1rem 1rem'  width='100%'>
              <Message info>
                You may use the <em>"Unique number"</em> of your partner counterparts to send a validation link by email.
              </Message>
            </Wrapper>
          <Formik
            initialValues={{
              uniqueNumber: '',
            }}
            onSubmit={submit}
            validationSchema={addPartnerSchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              values,
            }) => (
              <Form onSubmit={handleSubmit} width='15px'>
                {errorHandler && (
                  <>
                    <ErrorHandler
                      header='Fail to send request.'
                      message='Try to refresh the page and contact an administrator if the error persists.'
                    />
                  </>
                )}
                <Wrapper>
                  <Wrapper margin='10px'>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          mandatory
                          label='Unique number'
                          placeholder='unique number...'
                          name='uniqueNumber'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>
                    </Form.Group>
                  </Wrapper>
                </Wrapper>
                <Button
                  disabled={isSubmitting}
                  type='submit'
                  content='Save'
                  onClick={() => {
                    handleSubmit();
                  }}
                  margin='0px'
                />
              </Form>
            )}
          </Formik>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    companiesNotInContact: selectors.contact.companiesNotInContactSelector(
      state
    ),
    errorHandlerContact: selectors.contact.errorSelector(state),
    loadingHandlerContact: selectors.contact.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
