import styled from "@emotion/styled";

export const Message = styled.div(() => ({
  float: 'left',
  width: '100%',
  fontSize: '15px',
  textAlign: 'center',
  margin: '0 0 10px 0'
}))

export const Span = styled.span(() => ({
  float: 'left',
  fontSize: '14px',
  width: '100%',
  textAlign: 'center',
}))

