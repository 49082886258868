import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const driverApi = routesApi.company.driver;
  return {
    addDriver(data: dataReqI) {
      return api.request({
        method: driverApi.addDriver.method,
        route: driverApi.addDriver.path,  
        params: data.params,
        data: data.data,
      });
    },
    getDrivers(data: dataReqI) {
      return api.request({
        method: driverApi.getDrivers.method,
        route: driverApi.getDrivers.path,
        params: data.params,
      });
    },
    removeDriver(data: dataReqI) {
      return api.request({
        method: driverApi.removeDriver.method,
        route: driverApi.removeDriver.path,
        params: data.params,
      });
    },
    updateDriver(data: dataReqI) {
      return api.request({
        method: driverApi.updateDriver.method,
        route: driverApi.updateDriver.path,
        params: data.params,
        data: data.data,
      });
    },
    updateDriverPermissions(data: dataReqI) {
      return api.request({
        method: driverApi.updateDriverPermissions.method,
        route: driverApi.updateDriverPermissions.path,
        params: data.params,
        data: data.data,
      });
    },
    getDriverPermissions(data: dataReqI) {
      return api.request({
        method: driverApi.getDriverPermissions.method,
        route: driverApi.getDriverPermissions.path,
        params: data.params,
      });
    },
    getOneDriver(data: dataReqI) {
      return api.request({
        method: driverApi.getOneDriver.method,
        route: driverApi.getOneDriver.path,
        params: data.params,
      });
    },
    addDriverToCompany(data: dataReqI){
      return api.request({
        method: driverApi.addDriverToCompany.method,
        route: driverApi.addDriverToCompany.path,
        params: data.params,
      })
    },
    getAllDrivers(data: dataReqI) {
      return api.request({
        method: driverApi.getAllDrivers.method,
        route: driverApi.getAllDrivers.path,
        params: data.params,
      });
    },
  };
};
