import _ from 'lodash';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Segment,
  Divider,
  Header,
  Message,
  List,
  Transition,
  Search,
  Grid,
} from 'semantic-ui-react';
import Dropdown from 'components/Dropdown';
import { Field, FieldArray } from 'formik';
import Input from 'components/Form/Input';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import styled from "@emotion/styled";

const Index = ({
  dispatch,
  setFieldValue,
  setFieldTouched,
  errorHandlerData,
  loadingHandlerData,
  values,
  manageSteps,
  freezeSteps,
  euCountries,
  companyId,
  selectedCDs,
  customsData,
  searchCustomsId,
  searchCustomsIdLoading,
  errors,
  touched,
  vats,
  intl,
  isSuperOperatorCompany,
}: any) => {
  const [euCountriesList, setEuCountriesList]: any = useState([]);
  const [searchResult, setSearchResult]: any = useState([]);
  const [clientRef, setClientRef]: any = useState(false);
  const [
    areSelectedCountriesDifferent,
    setAreSelectedCountriesDifferent,
  ] = useState(true);

  const [vatCountryError, setVatCountryError] = useState(false);
  const timeoutRef = useRef()

  const Selector = styled.div(() => ({
      float: 'left',
      margin: '9px 10px 0 26px'
  }))

  const manageIfCountriesAreIdentical = useCallback(() => {
    setAreSelectedCountriesDifferent(false);
    freezeSteps(1);
  }, [freezeSteps]);


  const manageVatCountryError = useCallback(() => {
    setVatCountryError(true);
    freezeSteps(1);
  }, [freezeSteps]);

  const handleSearchForClientRef = (term: string) => {
    clearTimeout(timeoutRef.current)

    let withCompany

    if(Number(companyId) !== isSuperOperatorCompany){
      withCompany = Number(companyId)
    } 

    if(term.length >= 2){
      dispatch(actions.quickfixes.searchForClientRef({
        query: {
          value: term, 
          clientRef: clientRef,
          ...withCompany && {withCompany: withCompany}
        }
      }))
    }

    return () => {
      dispatch(actions.quickfixes.reset(['searchAllDeclId', 'error']))
    }
  }

  useEffect(() => {
   if(Number(companyId) !== isSuperOperatorCompany){   // ASD_FRANCE
      dispatch(
        actions.quickfixes.getCDIDs({
          params: { companyId: companyId },
    
        })
      );
    }

    return () => {
      dispatch(actions.quickfixes.reset(['getCDIDs', 'error']));
    };

  }, [dispatch, isSuperOperatorCompany, companyId]);

  useEffect(() => {
    values.deliveryInfos.departureCountry ===
    values.deliveryInfos.arrivalCountry &&
    values.deliveryInfos.departureCountry !== '' &&
    values.deliveryInfos.arrivalCountry !== ''
      ? manageIfCountriesAreIdentical()
      : setAreSelectedCountriesDifferent(true);
  }, [values, manageIfCountriesAreIdentical]);

  useEffect(() => {
    dispatch(actions.data.getEuCountries());
    return () => {
      dispatch(actions.data.reset(['euCountries', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    let vatCountryArr = [];
    if (vats !== null) {
      vats.forEach((vat) => {
        vatCountryArr.push(vat.country);
      });
      setFieldValue('seller.vatsCountries', vatCountryArr);
    }
  }, [vats, customsData, setFieldValue]);


  useEffect(() => {
    let refList = []
    let fullList: any
   
    // ASD_FRANCE || client
    fullList = searchCustomsId || selectedCDs
    if (fullList && refList.length === 0) {
      fullList.forEach((cdID, index) => {
          // Company_Id(lambda) ASD_FRANCE
          if(cdID.Company_Id && cdID.Company_Id === companyId){
            if(cdID.clientReference !== null && cdID.clientReference.length>0){
              refList.push({
                title: cdID.id,
                description: cdID.clientReference,
              })
              // refList.push({
              //   key: index,
              //   value: { 
              //     id: cdID.id, 
              //     ref: cdID.clientReference 
              //   },
              //   text: `${cdID.id} - Client Ref : ${cdID.clientReference}`,
              // });
            }
          }else{
            if(cdID.clientReference !== null && cdID.clientReference.length>0){
              // refList.push({
              //   key: index,
              //   value: { 
              //     id: cdID.id, 
              //     ref: cdID.clientReference 
              //   },
              //   text: `${cdID.id} - Client Ref : ${cdID.clientReference}`,
              // });
              refList.push({
                title: cdID.id,
                description: cdID.clientReference,
              })
            }
          }
        })
        setSearchResult(refList)
      // setFieldValue('deliveryInfos.customsDeclarationId');
    }
  
  }, [selectedCDs, searchCustomsId, customsData, companyId, isSuperOperatorCompany]);

  useEffect(() => {
    if (euCountries) {
      setEuCountriesList(
        _.orderBy(
          euCountries.map((country, index) => {
            return {
              key: index,
              value: country.country_code,
              text: intl.formatMessage({
                id: `countries.name.${country.country_code}`,
              }),
              flag: country.country_code.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [euCountries, intl]);

  const manageVatCountryNoError = useCallback((departureCountry) => {
    setVatCountryError(false);
    if (departureCountry === '') {
      setFieldValue('seller.vat', '');
      setFieldValue('seller.vatId', '');
    } else {
      vats.forEach((vat) => {
        if (vat.country === departureCountry) {
          setFieldValue('seller.vat', vat.vatNumber);
          setFieldValue('seller.vatId', vat.id);
        }
      });
    }
  }, [setFieldValue, vats]);

  const checkVats = useCallback((departureCountry) => {
    departureCountry !== '' &&
    values.seller.vatsCountries.includes(departureCountry) === false
      ? manageVatCountryError()
      : manageVatCountryNoError(departureCountry);
  }, [values.seller.vatsCountries, manageVatCountryError, manageVatCountryNoError]);

  useEffect(() => {
    checkVats(values.deliveryInfos.departureCountry);
  }, [values.deliveryInfos.departureCountry, checkVats]);



  return (
    <Wrapper width='30%' margin='auto' minWidth='400px'>
      <Segment>
        <>
          <Field
            label={<MandatoryFieldLabel content='Reference of the delivery' />}
            name={'deliveryInfos.deliveryRef'}
            type='text'
            component={Input}
            meta={{ errors, touched }}
            margin='5px'
            columns='2'
            labelVerticalPosition='center'
            justifyContent='space-between'
          />
          <Divider />
        
     
            {/* <Field
              label='Customs declaration Ref'
              placeholder='Choose declaration'
              name='deliveryInfos.customsDeclarationId'
              options={cDeclarationsList}
              component={Dropdown}
              meta={{ errors, touched, setFieldValue, setFieldTouched }}
              loading={searchCustomsIdLoading}
              uniqueError={errorHandlerData.includes('searchCustomsId') ? true : false}
            /> 
             <Divider />
            */}
           
       
       
     
            <Grid>
                <Grid.Row verticalAlign='middle'>
                <Selector>
                  <Field
                    label='Client Ref:'
                    name="ref"
                    component={Checkbox}
                    checked={clientRef}
                    onChange={(e) => {
                      setClientRef(!clientRef)
                    }}
                  />
                </Selector>  
         
                <Search
                  aligned='middle'
                  name='deliveryInfos.customsDeclarationId'
                  fluid={true}
                  loading={searchCustomsIdLoading}
                  placeholder='Search...'
                  onResultSelect={(event, data) => {
                    if(event){
                      const result = {id: data.result.title, ref: data.result.description}
                      setFieldValue('deliveryInfos.deliveryRef', data.result.title.split('-').join(''))
                      setFieldValue('deliveryInfos.customsDeclarationId',  result)
                    }                  
                  }}
                  onSearchChange={(event, data) => {
                    event && handleSearchForClientRef(data.value)
                  }}
                  results={searchResult}
                  value={searchResult.value}
                />
             
              </Grid.Row>
           </Grid>
      
     

        <Header size='tiny'>Invoice numbers</Header>
        <FieldArray
          name='deliveryInfos.invoiceNumbers'
          render={(arrayHelpers) => (
            <Transition.Group
              as={List}
              duration={200}
              size='medium'
              verticalAlign='middle'
            >
              {values.deliveryInfos.invoiceNumbers.map(
                (invoiceNumber, index) => {
                  return (
                    <List.Item key={index}>
                      <label>{`Invoice number n° ${index + 1}:`}</label>
                      <Wrapper display='flex' justifyContent='space-evenly'>
                        <Field
                          placeholder='Invoice number'
                          name={`deliveryInfos.invoiceNumbers[${index}]`}
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                        <Button
                          icon='minus'
                          type='button'
                          color='red'
                          onClick={() => arrayHelpers.remove(index)}
                          maxHeight='2.7rem'
                        />
                      </Wrapper>
                      <Divider hidden />
                    </List.Item>
                  );
                }
              )}

              <Button
                icon='plus'
                type='button'
                onClick={() => arrayHelpers.push('')}
                margin='0px'
                position='center'
              />
            </Transition.Group>
          )}
        />
        <Divider />
        <Checkbox
          label='Customs regime 42'
          name='deliveryInfos.customsRegime'
          checked={values.deliveryInfos.customsRegime}
          onChange={() => {
            setFieldValue(
              'deliveryInfos.customsRegime',
              !values.deliveryInfos.customsRegime
            );
          }}
        />
        <Divider />
        <Field
          label='CMR reference'
          name={`deliveryInfos.cmr`}
          type='text'
          component={Input}
          meta={{ errors, touched }}
        />
        {values.deliveryInfos.customsRegime && (
          <>
            <Divider hidden />
            <Field
              label={<MandatoryFieldLabel content='IMA number:' />}
              placeholder='IMA number'
              name='deliveryInfos.imaNumber'
              type='text'
              component={Input}
              meta={{ errors, touched }}
            />
          </>
        )}
        <Divider />
        <Field
          label={<MandatoryFieldLabel content='Departure country' />}
          placeholder='Choose country'
          name='deliveryInfos.departureCountry'
          options={euCountriesList}
          component={Dropdown}
          meta={{ errors, touched, setFieldValue, setFieldTouched }}
          loading={loadingHandlerData}
          uniqueError={errorHandlerData.includes('euCountries') ? true : false}
        />
        <Divider hidden />
        <Field
          label={<MandatoryFieldLabel content='Arrival country' />}
          placeholder='Choose country'
          name='deliveryInfos.arrivalCountry'
          options={euCountriesList}
          component={Dropdown}
          meta={{ errors, touched, setFieldValue, setFieldTouched }}
          loading={loadingHandlerData}
          uniqueError={errorHandlerData.includes('euCountries') ? true : false}
        />
        {!areSelectedCountriesDifferent && (
          <Message negative>
            Departure and arrival countries must be different.
          </Message>
        )}
        {vatCountryError && (
          <Message negative>
            You must have a vat number in the country of departure to be able to
            start a delivery from there.
          </Message>
        )}
      </>
      </Segment>
      <Button
        type='button'
        size='large'
        {...(!areSelectedCountriesDifferent && { disabled: true })}
        {...(vatCountryError && { disabled: true })}
        icon='right arrow'
        labelPosition='right'
        color='blue'
        content='Next'
        margin='5px'
        onClick={() => {
         if(customsData){
          setFieldValue('deliveryInfos.customsDeclarationId', customsData)
         }
          manageSteps(2)      
        }}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    searchCustomsId: selectors.quickfixes.getAllCDIDsSelector(
      state
    ),
    selectedCDs: selectors.quickfixes.getCustomsDeclarationsIDsSelector(
      state
    ),
    searchCustomsIdLoading: selectors.quickfixes.getAllCDIDsLoadingSelector(state),
    customsLoading: selectors.quickfixes.getCustomsDeclarationsIDsLoadingSelector(state),
    euCountries: selectors.data.euCountriesSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    loadingHandlerData: selectors.data.euCountriesLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
