import styled from '@emotion/styled';
import theme from 'theme';

const colors = theme.getTheme().colors;

const selectColor = (color) => {
  return Object.keys(colors).includes(color) ? colors[color] : color;
};

export const StyledText = styled.span(({ props }: any) => ({
  display: props.display,
  fontSize: props.fontSize,
  fontWeight: props.fontWeight,
  margin: props.margin,
  color: selectColor(props.color),
  maxWidth: props.maxWidth,
  wordWrap: props.wordWrap,
  lineHeight: 'normal',
}));
