import React from "react";
import { Card } from "semantic-ui-react";

const Index = ({ info }) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>{info.companyName}</Card.Header>
        <Card.Meta>{info.created_at}</Card.Meta>
        <Card.Description>
          <p>Activity: {info.activity}</p>
          <p>Tier: {info.tier}</p>
          <p>country: {info.country}</p>
          <p>workshop: {info.workshop}</p>
          <p>Emitter: {info.emitter}</p>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>{info.state}</Card.Content>
    </Card>
  );
};

export default Index;
