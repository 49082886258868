import api from "./api";
import storage from "./storage";

export default (config) => {
  const services: any = {};
  services.storage = storage();
  services.api = api({ baseURL: config.api.url_api });

  return {
    get(name) {
      return services[name];
    },
  };
};
