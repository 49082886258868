import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Divider,
  Form,
  Grid,
  Header,
  Modal,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import { updateCollectionbox } from 'interfaces/formSchema/collectionboxValidators';
import Dropdown from 'components/Dropdown';
import { FiscalPeriod, Input } from 'components/Form';
import { DropP, DropWrapper } from 'views/Fiscal/Vat/Operator/New/newVatStyles';

const ModalEditCollectionbox = ({
  open,
  actualData,
  handleEditCollectionbox,
  handleClose,
  intl,
  dispatch,
  categories,
  categoriesLoading,
  obligations,
  obligationsloading,
  companyId,
}: ModalEditCollectionboxProps) => {

  const [categoriesList, setCategories]: any = useState([]);
  const [countriesList, setcountriesList]: any = useState([]);

  useEffect(() => {
    dispatch(actions.collectionbox.getCategories({
      params: {
        companyId: companyId
      }
    }));
    dispatch(actions.collectionbox.getObligations({
      params: {
        companyId: companyId
      }
    }));

    return () => {
      dispatch(
        actions.collectionbox.reset([
          'obligations',
          'categories',
          'error',
        ]));
    };
  }, [dispatch, companyId]);


  useEffect(() => {
    if(obligations){
        let tab = []
        obligations.forEach((o, index) => {
            tab.push({
              key: index,
              value: o.iso, 
              text: 
              <DropWrapper>
                <div>{intl.formatMessage({id: `countries.name.${o.iso}`})}</div>
                <DropP>{o.periodicity}</DropP>
              </DropWrapper>,
              flag: o.iso.toLowerCase(),
            })
          
        })
      setcountriesList(tab)
    }

  }, [obligations, intl]);

  useEffect(() => {
    if(categories){
      setCategories(categories.map((e) => {
        return {
          key: e.id,
          value: e.id,
          text: e.name
        }
      }))
    }

  }, [categories])


  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='edit' content='Edit VAT Collection box' />
      <Formik
        initialValues={{
          type: actualData.type || '',
          country: actualData.country || '',
          taxPeriod: actualData.taxPeriod || '',
          clientReference: actualData.clientReference || '', 
        }}
        enableReinitialize={true}
        onSubmit={handleEditCollectionbox}
        validationSchema={updateCollectionbox}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
        <Modal.Content>
        <Form onSubmit={handleSubmit}>
        <Grid>
        <Grid.Row columns='2'>
        <Grid.Column> 
          <Field
            mandatory
            label='Declaration type'
            name='type'
            placeholder='Choose declaration type...'
            component={Dropdown}
            options={categoriesList}
            loading={categoriesLoading}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Field
            mandatory
            label='Country'
            name='country'
            placeholder='choose...'
            component={Dropdown}
            options={countriesList}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
        <Grid.Column>
          <Field
            mandatory
            label='Tax period'
            name='taxPeriod'
            component={FiscalPeriod}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column> 
        <Grid.Column>
          <Field
            label='Client reference'
            name='clientReference'
            placeholder='REF5765...'
            component={Input}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
          </Grid.Column>    
        </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
          <Button
            disabled={isSubmitting}
            type='submit'
            content='Submit'
          />
        </Form>
      </Modal.Content>
      )}
    </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: selectors.collectionbox.categoriesSelector(state),
    categoriesLoading: selectors.collectionbox.categoriesLoadingSelector(state),
    obligationsloading: selectors.collectionbox.obligationGetLoadingSelector(state),
    obligations: selectors.collectionbox.obligationGetSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

type declarationData = {
  type: number,
  country: string,
  taxPeriod: string,
  clientReference: string, 
};

interface ModalEditCollectionboxProps {
  open: boolean;
  actualData: declarationData;
  handleEditCollectionbox: (values: declarationData) => void;
  handleClose: () => void;
  dispatch: any;
  intl;
  euCountries;
  euCountriesLoading;
  categories;
  categoriesLoading;
  obligations;
  obligationsloading;
  companyId;
}

export default enhance(ModalEditCollectionbox);
