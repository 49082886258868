import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Header, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import TableSpacedRows from 'components/TableSpacedRows';
import SendResultsForm from './SendResultsForm';

const Index = ({
  history,
  dispatch,
  processResult,
  clientProspect,
  ssrLoading,
  ssrError,
}) => {
  const [resultsModal, setsendResultsModal] = useState(false);
  const [runningAction, setrunningAction] = useState(null);

  useEffect(() => {
    if (runningAction !== null && !ssrLoading) {
      if (!ssrError.includes(runningAction)) {
        history.push('/');
      }
      setrunningAction(null);
    }
  }, [ssrLoading, ssrError, history, runningAction]);

  // const isCaseSupported = (flow) => {
  //   let isSupported = Object.keys(flow).some(
  //     (key) => key !== 'X.Index' && key !== 'description' && flow[key] !== null
  //   );
  //   return isSupported;
  // };

  const submit = (values) => {
    const data = { ...values, clientProspect, processResult };
    dispatch(actions.ssrRequest.sendResults({ data: data }));
    setrunningAction('sendResults');
  };

  return (
    <Wrapper margin='0 5rem' textAlign='center'>
      <Header size='large' content='Process Results' />

      {processResult.length > 0 && (
        <>
          <Button
            type='button'
            content='Send results'
            onClick={() => {
              setsendResultsModal(true);
            }}
            margin='1rem 0 0 0'
            position='center'
          />
          {resultsModal && (
            <SendResultsForm
              open={resultsModal}
              submitAction={submit}
              handleClose={() => setsendResultsModal(false)}
            />
          )}
          <Wrapper margin='0 0 5rem 0'>
            <TableSpacedRows
              thPosition='sticky'
              thTop='0'
              thBackgroundColor='mainBackgroundColor'
              thZIndex='1'
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Return ASD Contract 1</Table.HeaderCell>
                  <Table.HeaderCell>
                    Return ASD Contract Country 1
                  </Table.HeaderCell>
                  <Table.HeaderCell>Return ASD Contract 2</Table.HeaderCell>
                  <Table.HeaderCell>
                    Return ASD Contract Country 2
                  </Table.HeaderCell>
                  <Table.HeaderCell>Refund ASD Contract</Table.HeaderCell>
                  <Table.HeaderCell>
                    Refund ASD Contract Country
                  </Table.HeaderCell>
                  <Table.HeaderCell>Prospect VAT Number</Table.HeaderCell>
                  <Table.HeaderCell>Buyer VAT Number</Table.HeaderCell>
                  <Table.HeaderCell>VAT rate</Table.HeaderCell>
                  <Table.HeaderCell>Reference</Table.HeaderCell>
                  <Table.HeaderCell>
                    Etat Return ASD Contract 1
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Intrastat Return ASD Contract 1
                  </Table.HeaderCell>
                  <Table.HeaderCell>Marketplace ASD Contract</Table.HeaderCell>
                  <Table.HeaderCell>
                    Marketplace ASD Contract Country{' '}
                  </Table.HeaderCell>
                  <Table.HeaderCell>Marketplace VAT Number</Table.HeaderCell>
                  <Table.HeaderCell>Marketplace VAT Rate</Table.HeaderCell>
                  <Table.HeaderCell>Marketplace Etat</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>\
                {processResult.map((data, index) => {
                  if (data['N.triggeredRulesCount'] === 1) {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{data['I.Description']}</Table.Cell>
                        <Table.Cell>{data['O.Return_ASDContract1']}</Table.Cell>
                        <Table.Cell>
                          {data['O.Return_ASDContractCountry1']}
                        </Table.Cell>
                        <Table.Cell>{data['O.Return_ASDContract2']}</Table.Cell>
                        <Table.Cell>
                          {data['O.Return_ASDContractCountry2']}
                        </Table.Cell>
                        <Table.Cell>{data['O.Refund_ASDContract']}</Table.Cell>
                        <Table.Cell>
                          {data['O.Refund_ASDContractCountry']}
                        </Table.Cell>
                        <Table.Cell>{data['O.SellerVATNumber']}</Table.Cell>
                        <Table.Cell>{data['O.BuyerVATNumber']}</Table.Cell>
                        <Table.Cell>{data['O.VATRate']}</Table.Cell>
                        <Table.Cell>{data['O.Reference']}</Table.Cell>
                        <Table.Cell>
                          {data['O.Etat_Return_ASDContract1']}
                        </Table.Cell>
                        <Table.Cell>
                          {data['O.Intrastat_Return_ASDContract1']}
                        </Table.Cell>
                        <Table.Cell>
                          {data['O.Marketplace_ASDContract']}
                        </Table.Cell>
                        <Table.Cell>
                          {data['O.Marketplace_ASDContractCountry']}
                        </Table.Cell>
                        <Table.Cell>
                          {data['O.Marketplace_VATNumber']}
                        </Table.Cell>
                        <Table.Cell>{data['O.Marketplace_VATRate']}</Table.Cell>
                        <Table.Cell>{data['O.Marketplace_Etat']}</Table.Cell>
                      </Table.Row>
                    );
                  } else {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{data['Description']}</Table.Cell>
                        <Table.Cell colSpan='11'>
                          <Wrapper textAlign='center'>
                            Case not supported by So simple report, please
                            contact the technical office.
                          </Wrapper>
                        </Table.Cell>
                      </Table.Row>
                    );
                  }
                })}
              </Table.Body>
            </TableSpacedRows>
          </Wrapper>
        </>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ssrLoading: selectors.ssrRequest.loadingSelector(state),
    ssrError: selectors.ssrRequest.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
