import actionTypes from "./actionTypes";

export default {
  getAllStatus() {
    return {
      type: actionTypes.GET_STATUS,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys
      }
    };
  }
};
