import styled from '@emotion/styled';

export const ResponsiveWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',

  '@media (max-width: 1100px)': {
    flexDirection: 'column',
  },
}));

export const Comment = styled.div(() => ({
  fontSize: '11px',
  textAlign: 'center',
  lineHeight: '11px'
}));

export const LinesComments = styled.div(() => ({
  fontSize: '11px',
  textAlign: 'left',
  lineHeight: '15px',
  padding: '0.3rem',
  margin: '0',
  float: 'left',
  width: '100%',
  borderRadius: '0.3rem',
  background: 'rgba(0,0,0,.10)'
}));

export const DriverStatus = styled.div(() => ({
  color: 'white', 
  fontWeight: 'bold',
  display: 'inline',
  fontSize: '10px',
  lineHeight: '10px',
  padding: '0.2rem 0.7rem',
  background: 'green',
  borderRadius: '1.6rem',
  textTransform: 'uppercase',
}));


export const AskForNotification = styled.div`
    color: white; 
    font-weight: bold;
    display: inline;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    line-height: 11px;
    padding: 0.2rem 0.7rem;
    background-color: #d11b1be0;
    border-radius: 1.6rem;
    first-letter: uppercase;
    &&&:hover {
      background-color: #d11b1bb3;
    }`;

export const DriverPause = styled.div(() => ({
  color: 'white', 
  fontWeight: 'bold',
  display: 'inline',
  fontSize: '10px',
  padding: '0.2rem 0.5rem',
  background: '#0a326d',
  borderRadius: '1.6rem',
}));

export const CoiTypes = styled.div(() => ({
  color: '#c60000',
  textTranform: 'uppcase',
  margin: '0',
  padding: '0',
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 'bold'
}));

export const KeyInfos = styled.div(() => ({
  fontSize: '12px',
  padding: '0 1rem',
  margin: '0.5rem 0',
  lineHeight: '12px',
}));

export const ChangeStatus = styled.button(() => ({
  display: 'inline',
  color: 'rgb(68 68 68 / 77%)',
  width: '1.5rem',
  height: '1.5rem',
  fontSize: '10px',
  lineHeight: '20px',
  padding: '0 0.5rem',
  position: 'absolute',
  top: '0.65rem',
  right: '1rem',
  background: '#f0f0f0',
  borderRadius: '0',
  border: `1px solid rgb(68 68 68 / 46%)`,
  cursor: 'pointer',
  textTransform: 'uppercase',
}));

export const FolderWrapper = styled.div(() => ({
  display: 'flex',
  float: 'left',
  width: '100%'
}))

export const FolderLink = styled.div(() => ({
  padding: '0 0.5rem 0 0.3rem ',
  textDecoration: 'none',
  color: 'rgba(0,0,0,.87)',
  cursor: 'pointer',
}))

export const Type = styled.div(() => ({
  lineHeight: '14px'
}))

export const Linked = FolderLink.withComponent('a')
