import styled from "@emotion/styled";
import { Menu, Dropdown } from "semantic-ui-react";
import bg from "assets/images/bg-header.png";

export const MenuStyled = styled(Menu)`
  &&& {
    background: url(${bg}) 100%;
    background-size: cover;
  }
`;

export const MenuItemStyled = styled(Menu.Item)`
  &&& {
    color: white;
  }
`;

export const MenuItemWrapper = styled.div(
  ({ margin, position, maxHeight }: any) => ({
    display: "-webkit-inline-box",
    flexDirection: "row",
    maxHeight: maxHeight ? maxHeight : "none",
    justifyContent: position ? position : "center",
    margin
  })
);

export const DropdownItemStyled = styled(Dropdown.Item)`
  &&& {
  }
`;

export const DropdownMenuStyled = styled(Dropdown.Menu)`
  &&& {
  }
`;

export const DropdownStyled = styled(Dropdown)`
  &&& {
    color: white;
  }
`;
