import { addLocaleData } from 'react-intl';
import fr from 'react-intl/locale-data/fr';
import en from 'react-intl/locale-data/en';
import lang from './lang';

addLocaleData([...fr, ...en]);

export default {

  getMessages(region) {
    return lang[region] || lang.fr;
  }
  
};
