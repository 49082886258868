import styled from "@emotion/styled";

export const SplitWrapper = {'height': 'calc(90vh - 200px)'}
export const SplitButton = {'zIndex': '1000', 'position': 'relative'}
export const FullScreen = {'height': '90%', 'position': 'relative'}

export const Listing = styled.div(() => ({
  float: 'left',
  position: 'relative',
  boxSizing: 'border-box',
  width: '100%',
  height: '90%'
}))

export const Delete = styled.div(() => ({
  float: 'right',
  height: '20px',
  margin: '5px',
  lineHeight: '12px',
  padding: '4px 6px',
  fontSize: '11px',
  fontWeight: 'bold',
  background: '#cccccc',
  border: '1px solid #cccccc',
  borderRadius: '5px',
  boxShadow: 'inset 0px 0px 5px 0px #cbcbcb',
  boxSizing: 'border-box',
  cursor: 'pointer'
}))

export const ListItems = styled.div(() => ({
  height: '30px',
  lineHeight: '30px',
  fontSize: '14px',
}))

export const Span = styled.span(() => ({
  height: '30px',
  lineHeight: '30px',
  fontSize: '14px',
  padding: '0 5px 0 5px',
}))

export const Strong = styled.span(() => ({
  height: '30px',
  lineHeight: '11px',
  margin: '0 0 0 5px',
  padding: '1px 4px',
  fontSize: '11px',
  fontWeight: 'bold',
  border: '1px solid #cccccc',
  borderRadius: '5px',
  boxSizing: 'border-box',
}))

export const ListingInput = styled.input(() => ({
  float: 'left',
  boxSizing: 'border-box',
}))

export const Nav = styled.div(() => ({
  float: 'left',
  width: '100%',
  fontSize: '12px',
  height: '40px',
  padding: '3px 0',
  borderBottom: '1px solid rgba(34,36,38,.15)',
  cursor: 'pointer',
}))

export const Title = styled.div(() => ({
  float: 'left',
  display: 'inline',
  width: '60%',
  padding: '0 10px',
  fontSize: '12px',
  lineHeight: '31px',
  height: '34px'
}))

export const IframeViewer = styled.embed(() => ({
  border: 'none',
  width: '100%',
  height: 'calc(90vh - 250px)',
})) 


