import * as buyer from './buyer';
import * as seller from './seller';
import * as transporter from './transporter';
import * as operator from './operator';

import { default as quickFixesPaths } from './paths';
import { default as quickFixesRoutes } from './routes';

export const routes = [
  ...quickFixesRoutes,
  ...transporter.routes,
  ...seller.routes,
  ...buyer.routes,
  ...operator.routes,
];

export const paths: any = {
  ...buyer.paths,
  ...seller.paths,
  ...quickFixesPaths,
  ...transporter.paths,
  ...operator.paths,
};
