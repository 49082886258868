import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    getComputedCheds: null,
    getOneChed: null,
    getDeclarations: null,
    chedStats: null,
    globalStats: null,
    clerkStats: null,
    lastCD: null,
    comparisonStats: null,
  },
  errors: {
    getComputedCheds: null,
    getOneChed: null,
    getDeclarations: null,
    chedStats: null,
    globalStats: null,
    clerkStats: null,
    lastCD: null,
    comparisonStats: null,
  },
  loading: {
    getComputedCheds: false,
    getOneChed: false,
    getDeclarations: false,
    chedStats: false,
    globalStats: false,
    clerkStats: false,
    lastCD: false,
    comparisonStats: false,
  },

  computedCheds: null,
  oneChed: null,
  getDeclarations: null,
  chedStats: null,
  globalStats: null,
  clerkStats: null,
  lastCD: null,
  comparisonStats: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  
  switch (action.type) {

      case actionTypes.GET_CUSTOMS_COMPARISON_LOADING:
        return produce(state, (draft) => {
          draft.loading.comparisonStats = true;
        });
      case actionTypes.GET_CUSTOMS_COMPARISON_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.comparisonStats = null;
          draft.loading.comparisonStats = false;
          draft.success.comparisonStats = true;
          draft.comparisonStats = action.payload;
        });
      case actionTypes.GET_CUSTOMS_COMPARISON_ERROR:
        return produce(state, (draft) => {
          draft.errors.comparisonStats = true;
          draft.loading.comparisonStats = false;
          draft.success.comparisonStats = false;
          !draft.error.includes('comparisonStats') && draft.error.push('comparisonStats');
        });


    case actionTypes.GET_LAST_CUSTOMS_LOADING:
      return produce(state, (draft) => {
        draft.loading.lastCD = true;
      });
    case actionTypes.GET_LAST_CUSTOMS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.lastCD = null;
        draft.loading.lastCD = false;
        draft.success.lastCD = true;
        draft.lastCD = action.payload;
      });
    case actionTypes.GET_LAST_CUSTOMS_ERROR:
      return produce(state, (draft) => {
        draft.errors.lastCD = true;
        draft.loading.lastCD = false;
        draft.success.lastCD = false;
        !draft.error.includes('lastCD') && draft.error.push('lastCD');
      });


    case actionTypes.GET_CLERK_STATS_LOADING:
      return produce(state, (draft) => {
        draft.loading.clerkStats = true;
      });
    case actionTypes.GET_CLERK_STATS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.clerkStats = null;
        draft.loading.clerkStats = false;
        draft.success.clerkStats = true;
        draft.clerkStats = action.payload;
      });
    case actionTypes.GET_CLERK_STATS_ERROR:
      return produce(state, (draft) => {
        draft.errors.clerkStats = true;
        draft.loading.clerkStats = false;
        draft.success.clerkStats = false;
        !draft.error.includes('clerkStats') && draft.error.push('clerkStats');
      });


     // getCompanyStats
     case actionTypes.GET_STATS_BY_FILTERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.globalStats = true;
      });
    case actionTypes.GET_STATS_BY_FILTERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.globalStats = null;
        draft.loading.globalStats = false;
        draft.success.globalStats = true;
        draft.globalStats = action.payload;
      });
    case actionTypes.GET_STATS_BY_FILTERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.globalStats = true;
        draft.loading.globalStats = false;
        draft.success.globalStats = false;
        !draft.error.includes('globalStats') && draft.error.push('globalStats');
      });

       // getCompanyStats
     case actionTypes.GET_FILTERED_STATS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getDeclarations = true;
      });
    case actionTypes.GET_FILTERED_STATS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getDeclarations = null;
        draft.loading.getDeclarations = false;
        draft.success.getDeclarations = true;
        draft.getDeclarations = action.payload;
      });
    case actionTypes.GET_FILTERED_STATS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getDeclarations = true;
        draft.loading.getDeclarations = false;
        draft.success.getDeclarations = false;
        !draft.error.includes('getDeclarations') && draft.error.push('getDeclarations');
      });

    // getComputedCheds
    case actionTypes.GET_COMPUTED_CHEDS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getComputedCheds = true;
      });
    case actionTypes.GET_COMPUTED_CHEDS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getComputedCheds = null;
        draft.loading.getComputedCheds = false;
        draft.success.getComputedCheds = true;
        draft.computedCheds = action.payload;
      });
    case actionTypes.GET_COMPUTED_CHEDS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getComputedCheds = true;
        draft.loading.getComputedCheds = false;
        draft.success.getComputedCheds = false;
        !draft.error.includes('getComputedCheds') && draft.error.push('getComputedCheds');
      });

     // getChedStats
     case actionTypes.GET_CHED_STATS_LOADING:
      return produce(state, (draft) => {
        draft.loading.chedStats = true;
      });
    case actionTypes.GET_CHED_STATS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.chedStats = null;
        draft.loading.chedStats = false;
        draft.success.chedStats = true;
        draft.chedStats = action.payload;
      });
    case actionTypes.GET_CHED_STATS_ERROR:
      return produce(state, (draft) => {
        draft.errors.chedStats = true;
        draft.loading.chedStats = false;
        draft.success.chedStats = false;
        !draft.error.includes('chedStats') && draft.error.push('chedStats');
      });

    // getOneChed
    case actionTypes.GET_ONE_CHED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneChed = true;
      });
    case actionTypes.GET_ONE_CHED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneChed = null;
        draft.loading.getOneChed = false;
        draft.success.getOneChed = true;
        draft.oneChed = action.payload;
      });
    case actionTypes.GET_ONE_CHED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneChed = true;
        draft.loading.getOneChed = false;
        draft.success.getOneChed = false;
        !draft.error.includes('getOneChed') && draft.error.push('getOneChed');
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
