import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import TextCustom from 'components/core/TextCustom/TextCustom';
import Wrapper from 'components/core/Wrapper/Wrapper';
import ServiceCard from './components/ServiceCard/ServiceCard';
import services from 'config/services.json';
import { CardWrapper, ExternalLink } from './dashboardStyles';
import logoCustoms from '../../assets/images/logoCustomsBlanc.png';
import logoIntrastat from '../../assets/images/logoIntrastatBlanc.png';
import logoQuickproof from '../../assets/images/logoQuickproofBlanc.png';
import logoSPW from '../../assets/images/logoSPWBlanc.png';

const Dashboard = () => {
  return (
    <Wrapper textAlign='center' padding='2rem'>
      <Wrapper padding='1rem' textAlign='center'>
        <TextCustom fontSize='3rem' fontWeight='bold'>
          Welcome to My ASD
        </TextCustom>
      </Wrapper>
      <Wrapper padding='1rem' textAlign='center'>
        <TextCustom fontSize='2rem' fontWeight='bold'>
          our multi-service platform
        </TextCustom>
      </Wrapper>
      <CardWrapper>
        <Link to={services.kyanite.path}>
          <ServiceCard
            name='Quickproof'
            logo={logoQuickproof}
            tags={['Enter the app']}
            description='Manage your proofs of transport in all simplicity'
            color='#4a448e'
            bgPosition={{ x: '100%', y: '95%' }}
          />
        </Link>
        <Link to={services.customsDeclaration.path}>
          <ServiceCard
            name='Customs'
            logo={logoCustoms}
            tags={['Enter the app']}
            description='With you at the border 24 hours a day'
            color='#c24a34'
            bgPosition={{ x: '0%', y: '30%' }}
          />
        </Link>
        <Link to={services.Intrastat.path}>
          <ExternalLink
            target='_blank'
            rel='noreferrer'
            href={services.Intrastat.path}
          >
            <ServiceCard
              name='Intrastat'
              logo={logoIntrastat}
              tags={['Enter the app']}
              description='Manage your Intrastat declarations'
              color='#c24a34'
              bgPosition={{ x: '50%', y: '50%' }}
            />
          </ExternalLink>
        </Link>
      </CardWrapper>

      <CardWrapper>
        <div onClick={() => (window.location.href = `/spw`)}>
          <ServiceCard
            name='So Posting Worker'
            logo={logoSPW}
            tags={['Enter the app']}
            description='The simplified posting of workers'
            color='#ed6723'
            bgPosition={{ x: '50%', y: '50%' }}
          />
        </div>
      </CardWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Dashboard);
