import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const keyinfoApi = routesApi.company.keyinfo;
  return {
    addKeyInfo(data: dataReqI) {
      return api.request({
        method: keyinfoApi.addKeyinfo.method,
        route: keyinfoApi.addKeyinfo.path,  
        params: data.params,
        data: data.data,
      });
    },
    getKeyInfos(data: dataReqI) {
      return api.request({
        method: keyinfoApi.getKeyinfos.method,
        route: keyinfoApi.getKeyinfos.path,
        params: data.params,
      });
    },
    keyInfoSetDone(data: dataReqI) {
      return api.request({
        method: keyinfoApi.keyInfoSetDone.method,
        route: keyinfoApi.keyInfoSetDone.path,
        params: data.params,
        data: data.data
      });
    },
    checkKeyInfoSetDone(data: dataReqI) {
      return api.request({
        method: keyinfoApi.checkKeyInfoSetDone.method,
        route: keyinfoApi.checkKeyInfoSetDone.path,
        params: data.params,
      });
    },
    removeKeyInfo(data: dataReqI) {
      return api.request({
        method: keyinfoApi.removeKeyinfo.method,
        route: keyinfoApi.removeKeyinfo.path,
        params: data.params,
      });
    },
    updateKeyInfo(data: dataReqI) {
      return api.request({
        method: keyinfoApi.updateOneKeyinfo.method,
        route: keyinfoApi.updateOneKeyinfo.path,
        params: data.params,
        data: data.data,
      });
    },
  };
};
