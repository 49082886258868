import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

export const Wrapper = styled.div(({ labelPosition, labelVerticalPosition, float }: any) => ({
  alignItems: (labelVerticalPosition === "center") ? "center" : "unset",
  display: "flex",
  flexDirection: (labelPosition === "left") ? "row" : "column",
  margin: "0",
  float: float
}));



export const Input = styled(Form.Input)`
  &&& {
    margin: 0;
  }
  &&& div {
    // max-height: 2rem;
  }
  
  &&& div input {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
  }

  &&& div input:focus {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
  }

  &&&.disabled {
    opacity: 1;
  }
`;
