import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Modal, Header, Container, Divider, Checkbox } from 'semantic-ui-react';
import { Field, FieldArray, Formik } from 'formik';
import { actions, selectors } from 'store';

import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { ContainerPadded } from 'components/AddContact/styles';
import ErrorHandler from 'components/ErrorHandler';
import { Input } from 'components/Form';
import 'react-phone-input-2/lib/style.css'
import { keyInfoSchema } from 'interfaces/formSchema';


const Index = ({
  errorHandler,
  actionType,
  selectedInfo,
  dispatch,
  companyId,
  handleClose,
  user
}) => {

  const [list, setList]: any = useState([])


  const memoOptions = useMemo(() =>  [
    {key: 0, value: "declarant", text: "Declarant"},
    {key: 1, value: "commis", text: "Commis"},
    {key: 2, value: "traces", text: "Traces"},
  ], []);

  const operation = (values) => {
    let sorted: any = values.group.sort((a, b) => {
      return a-b
    })
    let type: string = ''
    let names: any = []
    memoOptions.forEach((option) => {
      sorted.forEach((number) => {
        if(option.key === number.key && number.checked){
          names.push(option.value)
        }
      })
    })
    sorted.forEach((number) => {
      if(number.checked){
        type += `${number.key}`
      }
    })
    return {names, type, values}
  }

  const priorityOptions = [
    {key: 1, value: 1, text: "Low"},
    {key: 2, value: 2, text: "Moderate"},
    {key: 3, value: 3, text: "High"},
  ];

  const saveKeyInfo = (values) => {
      const result = operation(values)
      dispatch(
        actions.companyKeyInfo.addKeyInfo({
          data: { 
            priority: values.priority, 
            group: result.names,
            info: values.info,
            Type_Id: parseInt(result.type),
            emitter: user.lastName
          
          },
          params: { companyId: companyId }
        })
      );
      handleClose();
  };

  const updateKeyInfo = (values) => {
    const result = operation(values)
    dispatch(
      actions.companyKeyInfo.updateKeyInfo({
        data: { 
            priority: values.priority,  
            group: result.names,
            info: values.info,
            Type_Id: parseInt(result.type),
            emitter: user.lastName
        },
        params: { companyId: companyId, id: selectedInfo?.id }
      })
    );
    handleClose();
};



useEffect(() => {
  if(selectedInfo && actionType === 'update'){
    let operatorTypes: any = []
    memoOptions.forEach((option, i) => {
        if(selectedInfo.group.includes(option.value)){
          operatorTypes.push({key: i, value: option.value, checked: true})
        }else{
          operatorTypes.push({key: i, value: option.value, checked: false})
        }
    })
    operatorTypes.length === memoOptions.length && setList(operatorTypes) 
  }else{
    setList(memoOptions)
  }
}, [selectedInfo, actionType, memoOptions])


  return (
    <Modal open={actionType !== null} onClose={handleClose} closeIcon>
      <Modal.Content>
      <ContainerPadded textAlign='center'>
          <Container>
            <Header size='huge'>{actionType} key infos</Header>
            <Divider hidden />
          <Formik
            initialValues={{
              priority: selectedInfo?.priority || '',
              group: list,
              info: selectedInfo?.info || '',
            }}
            enableReinitialize={true}
            onSubmit={actionType === 'add' ? saveKeyInfo : updateKeyInfo}
            validationSchema={keyInfoSchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              resetForm,
              values,
              isValidating,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form onSubmit={handleSubmit} width='15px'>
                {errorHandler && (
                  <>
                    <ErrorHandler
                      header='Fail to send request.'
                      message='Try to refresh the page and contact an administrator if the error persists.'
                    />
                  </>
                )}
                <Wrapper>
                <Wrapper margin='10px 100px'>
                   <Form.Group widths='equal'>                  
                      <Form.Field>
                        <Field
                          mandatory
                          label='Priority'
                          name='priority'
                          placeholder='select priority...'
                          options={priorityOptions}
                          component={Dropdown}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Wrapper>
                  <Wrapper margin='10px 100px'>
                  <FieldArray
                      name='group'
                      render={(arrayHelper) => (
                        <>
                          {list.map((type: any, i) => {
                            return (
                              <Wrapper key={i}>
                                <Wrapper>
                                  <Wrapper>
                                    <Field
                                      mandatory
                                      key={i}
                                      name={`group[${i}]`}
                                      component={Checkbox}
                                      label={`${type.value}`}
                                      onChange={() => {
                                        if (
                                          list[i].checked === true
                                        ) {
                                  
                                          arrayHelper.replace(
                                            values.group.findIndex(
                                              (e) => e.key === type.key
                                            ), {key: type.key, value: type.value, checked: false}
                                          );
                                          list[values.group.findIndex((e) => e.key === type.key)] = {key: type.key, value: type.value, checked: false}                                 
                                        } else {
                                      
                                          arrayHelper.replace(
                                            values.group.findIndex(
                                              (e) => e.key === type.key
                                            ), {key: type.key, value: type.value, checked: true}
                                          );
                                          list[values.group.findIndex((e) => e.key === type.key)] = {key: type.key, value: type.value, checked: true}
                                      
                                        }                                     
                                      }}
                                      checked={type.checked || false}
                                      meta={{
                                        errors,
                                        touched,
                                        setFieldValue,
                                        setFieldTouched,
                                      }}
                                    />
                                  </Wrapper>
                                </Wrapper>
                              </Wrapper>
                              );
                            })}
                          </>
                        )}
                      />
                     {touched.group && !values.group.find((e) => e.checked === true) && (
                     <Wrapper margin='10px 100px'><div className="ui red pointing basic label css-0">Required</div></Wrapper>
                     )}
                 
                  </Wrapper>
                  <Wrapper margin='10px 100px'>
                    <Form.Group widths='equal'>
                      <Form.Field> 
                        <Field
                          mandatory
                          label='Information'
                          placeholder='type...'
                          name='info'
                          component={Input}
                          type='text'
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                          margin='20px'
                        />
                        </Form.Field>                      
                    </Form.Group>
                  </Wrapper>
                </Wrapper>
                <Button
                  type='submit'
                  content='save'
                  margin='20px'
                  position='center'
                  color='blue'
                />
              </Form>
            )}
          </Formik>
        </Container>
        </ContainerPadded>
      </Modal.Content>
    </Modal>
  );
};


const mapStateToProps = (state) => {
  return {
    addLoading: selectors.companyKeyInfo.addKeyInfoLoadingSelector(state),
    updateLoading: selectors.companyKeyInfo.updateKeyInfoLoadingSelector(state)
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);


