import React, { useState, useEffect } from "react";
import { BtnWrapper } from "./styles";
import { actions } from "store";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Formik, Field } from "formik";
import { Form, Modal, Header, Divider } from "semantic-ui-react";
import * as Yup from "yup";
import TextArea from "components/Form/TextArea";
import Button from "components/Button";

const Index = ({
  isModalOpen,
  handleClose,
  dispatch,
  deliveryId,
  proofId,
  companyId,
}: any) => {
  const [isDecisionPositive, setIsDecisionPositive] = useState(null);
  const [runningAction, setRunningAction] = useState(null);

  useEffect(() => {
    if (runningAction !== null) {
      handleClose();
    }
  }, [runningAction, handleClose]);

  const onSubmit = (values) => {
    if (values.refusalMessage !== "") {
      setIsDecisionPositive(true);
      sendResponse(false, values.refusalMessage);
    }
  };

  const sendResponse = (decision, message = "") => {
    dispatch(actions.quickfixes.reset(["error"]));
    decision === true
      ? dispatch(
          actions.quickfixes.validateProof({
            params: {
              companyId: companyId,
              deliveryId: deliveryId,
              proofId: proofId,
            },
          })
        ) && setRunningAction("validateProof")
      : dispatch(
          actions.quickfixes.refuseProof({
            params: {
              companyId: companyId,
              deliveryId: deliveryId,
              proofId: proofId,
            },
            data: {
              reason: message,
            },
          })
        ) && setRunningAction("refuseProof");
  };

  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      {
      (
        <Modal.Content>
          <Formik
            initialValues={{
              refusalMessage: "",
            }}
            validationSchema={Yup.object().shape({
              refusalMessage: Yup.string().required("Required"),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                {isDecisionPositive === null && (
                  <>
                    <Header textAlign="center">
                      Is the document compliant and does it correspond to the
                      correct shipment?
                    </Header>
                    <Divider hidden />

                    <BtnWrapper>
                      <Button
                        type="button"
                        content="Yes, accept the document"
                        icon="check"
                        color="green"
                        onClick={() => {
                          setIsDecisionPositive(true);
                          sendResponse(true);
                        }}
                        margin="5px"
                      />
                      <Button
                        type="button"
                        content="No, refuse the document"
                        icon="delete"
                        color="red"
                        onClick={() => setIsDecisionPositive(false)}
                        margin="5px"
                      />
                    </BtnWrapper>
                  </>
                )}

                {isDecisionPositive === false && (
                  <>
                    <Header textAlign="center">
                      Are you sure you want to refuse this document?
                    </Header>
                    <Divider hidden />
                    If you confirm the refusal, an e-mail and an alert will be
                    sent to the buyer.
                    <Field
                      name="refusalMessage"
                      type="text"
                      component={TextArea}
                      placeholder="Add a message"
                      meta={{
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        values,
                      }}
                    />
                    <Divider hidden />
                    <BtnWrapper>
                      <Button
                        type="submit"
                        content="Yes, I confirm"
                        icon="check"
                        color="green"
                        margin="5px"
                      />
                      <Button
                        type="button"
                        content="No, I'm not confirming"
                        icon="delete"
                        color="red"
                        onClick={() => handleClose()}
                        margin="5px"
                      />
                    </BtnWrapper>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Content>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
