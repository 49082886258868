import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button as Btn,
  Divider,
  Header,
  Step,
  Message,
} from 'semantic-ui-react';
import { selectors, actions } from 'store';

import Wrapper from 'components/core/Wrapper/Wrapper';
import CreateFileStep from './components/CreateFileStep/CreateFileStep';
import IssueStep from './components/IssueStep/issueStep';
import BillingTableStep from './components/BillingTableStep/billingTableStep';
import NewSummaryStep from './components/NewSummaryStep/newSummaryStep';
import Button from 'components/Button';
import { paths } from 'routes/customs/customsHelper';
import Loader from 'components/Loader';

const NewFile = ({
  dispatch,
  history,
  match,
  intl,
  fileLoading,
  file,
  user,
}) => {
  const isEdit = match.url.split('/')[3] === 'edit-file';

  const [stepState, setStepState] = useState(0);
  const [highestStep, setHighestStep] = useState(0);

  useEffect(() => {
    
    isEdit &&
      dispatch(
        actions.customsClerk.getOneFile({
          params: {
            fileId: match.params.fileId,
          },
        })
      );

    dispatch(
      actions.socket.subscribeValidateCustomsClerkFiles(match.params.fileId)
    );

    return () => {  
      dispatch(
        actions.socket.unsubscribeValidateCustomsClerkFiles(match.params.fileId)
      );

      dispatch(
        actions.customsClerk.reset([
          'declarationInProgress',
          'fileDocuments',
          'oneFile',
          'error',
        ])
      );
    };
  }, [dispatch, isEdit, match.params.fileId]);

  useEffect(() => {
    if(file){
      const max = file.paidBy ? 4 : file.trailerLicensePlateS3Path ? 3 : 2;
      setHighestStep(max);
      if (file.signatureEndS3Path) setStepState(max);
    }
  }, [file]);

  const manageSteps = (step) => {
    setStepState(step);
    step > highestStep && setHighestStep(step);
  };

  return (
    <Wrapper width='90%' maxWidth='900px' margin='1rem auto'>
      <Btn.Group>
        <Button
          labelPosition='left'
          icon='angle left'
          type='button'
          content='Back'
          onClick={() => history.push(paths.customsHelper)}
        />
      </Btn.Group>
      <Wrapper textAlign='center'>
        <Header size='huge'>
          {match.url.match('edit-file') ? 'Edit File' : 'New File'}
        </Header>
        {file && file?.Operator_Id !== null && file?.Operator_Id !== user.id && (
          <Message warning>
            {file?.Operator?.firstName} {file?.Operator?.lastName} has locked
            the file
          </Message>
        )}
        <Step.Group size='big'>
          <Step active={stepState === 1} onClick={() => manageSteps(1)}>
            <Step.Content>
              <Step.Title>1</Step.Title>
              <Step.Description>Start</Step.Description>
            </Step.Content>
          </Step>
          <Step
            active={stepState === 2}
            disabled={highestStep < 2}
            onClick={() => manageSteps(2)}
          >
            <Step.Content>
              <Step.Title>2</Step.Title>
              <Step.Description>Control</Step.Description>
            </Step.Content>
          </Step>
          <Step
            active={stepState === 3}
            disabled={highestStep < 3}
            onClick={() => manageSteps(3)}
          >
            <Step.Content>
              <Step.Title>3</Step.Title>
              <Step.Description>Billing</Step.Description>
            </Step.Content>
          </Step>
          <Step
            active={stepState === 4}
            disabled={highestStep < 4}
            onClick={() => manageSteps(4)}
          >
            <Step.Content>
              <Step.Title>4</Step.Title>
              <Step.Description>Summary</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        <Divider hidden />
        {fileLoading && stepState === 0 && <Loader />}
  
        {!fileLoading && stepState === 1 && (
          <CreateFileStep
            history={history}
            match={match}
            manageSteps={manageSteps}
            isEdit={isEdit}
            user={user}
            file={file}
          />
        )}
        {!fileLoading && stepState === 2 && (
          <IssueStep
            intl={intl}
            manageSteps={manageSteps}
            match={match}
            file={file}
            isEdit={isEdit}
            user={user}
          />
        )}
        {!fileLoading && stepState === 3 && (
          <BillingTableStep
            intl={intl}
            manageSteps={manageSteps}
            match={match}
            file={file}
            user={user}
          />
        )}
        {!fileLoading && stepState === 4 && (
          <NewSummaryStep
            intl={intl}
            manageSteps={manageSteps}
            match={match}
            file={file}
          />
        )}
      </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    file: selectors.customsClerk.oneFileSelector(state),
    fileLoading: selectors.customsClerk.oneFileLoadingSelector(state),
    createdFileId: selectors.customsClerk.createdFileIdSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(NewFile);
