import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Divider, Header, List } from 'semantic-ui-react';

import Wrapper from 'components/core/Wrapper/Wrapper';
import TextCustom from 'components/core/TextCustom/TextCustom';
import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import KyaniteOfferTable from 'components/KyaniteOfferTable';
import { paths as paymentPath } from 'routes/payment';
import Button from 'components/Button';
import services from 'config/services.json';

const ListNumber = (children) => {
  return (
    <TextCustom fontSize='20px' margin='0 10px 0 0' color='lightBlueText'>
      {children}
    </TextCustom>
  );
};

const faqQuestion = (children) => {
  return (
    <TextCustom fontWeight='bold' fontSize='1.7rem' margin='2rem 0 0 0'>
      {children}
    </TextCustom>
  );
};

const faqAnswer = (children) => {
  return (
    <TextCustom display='block' margin='1rem 0 3rem 0' maxWidth='60rem'>
      {children}
    </TextCustom>
  );
};

const Index = ({ history }) => {
  return (
    <Container fluid>
      <Wrapper
        margin='50px 0 100px'
        textAlign='center'
        childrenDisplay='inline'
        childrenVerticalAlign='middle'
      >
        <ImageCustom
          src={`${process.env.REACT_APP_BACKEND}/${services.kyanite.image}`}
          alt='Logo ASD'
          height='100%'
          width='12.5rem'
          margin='0px 50px 0px 0px'
        />
        <TextCustom fontSize='2.7rem' fontWeight='bold'>
          WITH ASD QUICKPROOF, BRING YOUR VAT INTO COMPLIANCE
        </TextCustom>
      </Wrapper>
      <Wrapper
        margin='50px auto 70px'
        maxWidth='94rem'
        textAlign='justify'
        childrenFontSize='1.25rem'
      >
        <p>
          To limit the risk of fraud, the EU VAT exemption laws, called "QUICK
          FIXES", have come into force on January 1, 2020, and controls will be
          tightened. To benefit from the VAT exemption and to comply with this
          new reform, you must collect several proofs of transport for each of
          your deliveries, as the authorities can question all your activities
          for the last 3 years.
        </p>
      </Wrapper>
      <Wrapper
        textAlign='justify'
        margin='0 auto 6rem'
        width='30rem'
        backgroundColor='mainBackgroundColor'
      >
        <Header size='large'>WHAT ARE YOUR ADVANTAGES?</Header>

        <List>
          <p>
            {ListNumber(`01.`)}
            Save up to 20% of your turnover
          </p>
          <p>
            {ListNumber(`02.`)}
            Simplify your procedures by digitizing your proofs of transport
          </p>
          <p>
            {ListNumber(`03.`)}
            Protect yourself in the event of an official inspection
          </p>
          <p>
            {ListNumber(`04.`)}
            Keep track of your documents in real time
          </p>
          <p>
            {ListNumber(`05.`)}
            Easily find all your centralized and classified proofs
          </p>
        </List>
      </Wrapper>
      <Wrapper
        textAlign='left'
        margin='0 0 4rem'
        padding='10rem 10rem 10rem'
        backgroundColor='transparentLightGreyBg'
      >
        <Wrapper margin='0 auto' maxWidth='80rem'>
          <KyaniteOfferTable />
          <Button
            onClick={() => {
              history.push(paymentPath.payment.replace(':service', 'kyanite'));
            }}
            type='button'
            content='Sign up'
            margin='20px'
            position='center'
            size='big'
          />
        </Wrapper>
      </Wrapper>
      <Wrapper margin='50px auto 10rem' maxWidth='90rem'>
        <Wrapper textAlign='center'>
          <TextCustom fontWeight='bold' fontSize='3rem'>
            FAQ
          </TextCustom>
        </Wrapper>
        <Divider hidden />
        <Divider hidden />
        <Wrapper textAlign='justify'>
          {faqQuestion(`How do I know if I am affected by this new law?`)}
          {faqAnswer(
            `If you make intra-community deliveries, you are affected by these new conditions and you must keep the proofs of deliveries as defined in the law.`
          )}

          {faqQuestion(`What is the VAT risk?`)}
          {faqAnswer(
            <p>
              The VAT reforms implemented on January 1, 2020, set new conditions
              for exemption from intra-community deliveries.
              <br />
              The supplier can invoice excluding VAT if the following 3
              conditions are met: <br />
              - If the buyer has a valid VAT number in the VIES database at the
              time of the sale,
              <br />
              - If the operation is included in the DEB/Intrastat and EC Sales
              List deposited in the target country,
              <br />
              - If the seller is able to provide proof of transport between the
              2 EU countries.
              <br />
              In the event of at least one of these 3 conditions not being met,
              the supplier is required to invoice with VAT in effect in his
              country.
              <br />
              QUICKPROOF allows you to store proofs of transport listed in the VAT
              reform, relating to these intra-community movements.
            </p>
          )}

          {faqQuestion(
            `I chose the “annual” plan, can I cancel the contract before the anniversary date?`
          )}
          {faqAnswer(
            <p>
              When you are in your customer space, go to the " manage my
              subscription " tab and select the action you want to perform:{' '}
              <br />
              - Termination of my offer (the offer will be automatically
              deactivated from the anniversary of your contract)
              <br />- Switching to " Monthly " mode (at the end of your one-year
              commitment, billing for your subscription will be done monthly).
            </p>
          )}

          {faqQuestion(
            `I chose the “monthly” plan, can I switch to the “annual” plan at any time?`
          )}
          {faqAnswer(
            <p>
              When you are in your customer space, go to the " manage my
              subscription " tab and select the action you want to perform:{' '}
              <br />
              - Termination of my offer (the offer will be automatically
              deactivated from the anniversary of your contract)
              <br />
              - I would like to join the " Annual " plan (billing for 12 months
              of subscription will be taken from your next invoice,
              <br />
              your 12-month commitment will start at that time).
            </p>
          )}

          {faqQuestion(
            `What is the difference between the annual plan and the monthly plan?`
          )}
          {faqAnswer(
            <p>
              When you select the annual plan, you pay a 1-year subscription at
              once. This subscription includes 20 operations / month.
              <br />
              From the 21st operation, an additional package will be invoiced.
              <br />
              In the event of termination before the anniversary date, no refund
              will be made on the annual package.
              <br />
              When you select the monthly offer, you pay the subscription
              monthly allowing you to access the software and enter your
              transactions.
              <br />
              Similarly, the monthly subscription includes 20 operations /
              month.
            </p>
          )}

          {/* {faqQuestion(
            `Can I import my own customer, supplier or transporter listing into KYANITE?`
          )}
          {faqAnswer(
            <p>
              Yes, you just need to download the template file available in your
              personal space, fill it in and import it by following the method
              defined in the User Guide accessible from your personal space.
            </p>
          )} */}
        </Wrapper>
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
