import styled from "@emotion/styled";
import { Input } from "semantic-ui-react";

export const Wrapper = styled.div(
  ({ labelPosition, labelVerticalPosition, margin, justifyContent }: any) => ({
    alignItems: labelVerticalPosition === "center" ? "center" : "unset",
    display: "inline-block",
    flexDirection: labelPosition === "left" ? "row" : "column",
    margin: margin,
    justifyContent: justifyContent,
  })
);

export const InputStyled = styled(Input)`
  &&& {
    margin: 0;
    text-align: right;
    display: inline-block;
  }
  &&& div input {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
  }
  &&& div.ui.dropdown.label {
    border-top: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    border-radius: 0 4px 4px 0;
  }
  &&& div.ui.label {
    border-top: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    border-radius: 0 4px 4px 0;
  }
  &&&.disabled {
    opacity: 1;
  }
`;
