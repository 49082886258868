import React from 'react';
import TextCustom from 'components/core/TextCustom/TextCustom';
import styled from '@emotion/styled';

const Error = styled.div(() => ({
    color: '#db2828',
    padding: '0.3rem',
    fontSize: '12px',
    display: 'inline-block',
    lineHeight: '12px'
}));

const MandatoryFieldLabel = (props) => {
  return (
    <TextCustom>
      {props.content}
      <TextCustom color='red'> *</TextCustom>
      {props.error === true && (<Error>{props.errorMessage}</Error>)}
    </TextCustom>
  );
};

export default MandatoryFieldLabel;
