// Dependencies
import React from 'react';

// Components
import ContentButton from './contentButton';

//Interfaces
import ContentProperties from '../../interfaces/contentProperties';

// Stylesheets
import './content.css';

/**
 * @name spwContent.tsx
 * @implements spwContentProperties.tsx
 *
 * @path /components/SPWContent/spwContent.tsx
 *
 * @description Defines the global structure of page's body
 *
 * @param title page's title
 * @param homepage boolean that add give permission to add back button if page's not the homepage
 */
const SPWContent: React.FC<ContentProperties> = ({
  title,
  homepage,
  history,
}) => {
  return (
    <div className='contentContainer'>
      <pre>
        <h1>{title}</h1>
        {!homepage && (
          <ContentButton title='globalStrings.back' history={history} />
        )}
      </pre>
    </div>
  );
};

export default SPWContent;
