// Dependencies
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Content from '../components/content/content';
import OrderForm from '../components/orderForm/orderForm';

// Stylesheets
import '../index.css';

/**
 * @name orderPage.tsx
 *
 * @path /pages/orderPage.tsx
 *
 * @description Defines order page's content
 */
const OrderPage: React.FC = ({ history }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  return (
    <div className='pages'>
      <Helmet>
        <title>
          {t('orderPageStrings.orderTitle')}
          {t('helmetString')}
        </title>
      </Helmet>
      <Header />
      <Content
        title={t('orderPageStrings.orderTitle')}
        homepage={false}
        history={window.location.href.split('/').slice(0, -1).join('/')}
      />
      <OrderForm />
      <Footer />
    </div>
  );
};

export default OrderPage;
