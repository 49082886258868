import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const addressApi = routesApi.company.address;
  return {
    createAddress(data: dataReqI) {
      return api.request({
        method: addressApi.createAddress.method,
        route: addressApi.createAddress.path,
        params: data.params,
        data: data.data
      });
    },
    getAllAddresses(data: dataReqI) {
      return api.request({
        method: addressApi.getAllAddresses.method,
        route: addressApi.getAllAddresses.path,
        params: data.params
      });
    },
    getOneAddress(data: dataReqI) {
      return api.request({
        method: addressApi.getOneAddress.method,
        route: addressApi.getOneAddress.path,
        params: data.params,
      });
    },
    updateAddress(data: dataReqI) {
      return api.request({
        method: addressApi.updateAddress.method,
        route: addressApi.updateAddress.path,
        params: data.params,
        data: data.data
      });
    },
    deleteAddress(data: dataReqI) {
      return api.request({
        method: addressApi.deleteAddress.method,
        route: addressApi.deleteAddress.path,
        params: data.params
      });
    },
  };
};
