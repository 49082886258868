/**
 * Dependencies.
 */
import React, { Component, ChangeEventHandler } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * CountriesSelectorProps interface defines the structure of props
 * expected by the CountriesSelector component.
 */
interface CountrySelectorProps extends WithTranslation {
  divClassName: string;
  selectorClassName: string;
  selectorValue: string;
  selectorOnChange: ChangeEventHandler<HTMLSelectElement>;
  countriesList: any[];
}

/**
 * CountriesSelector component displays a selector with all
 * countries defines in assets, according to the current
 * page language, and gives the possibility to chose one of them
 * as new current value of this selector.
 */
class CountrySelector extends Component<CountrySelectorProps> {
  render() {
    const {
      divClassName,
      selectorClassName,
      selectorValue,
      selectorOnChange,
      countriesList,
    } = this.props;

    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    return (
      <div className={divClassName}>
        <select
          className={selectorClassName}
          value={selectorValue}
          onChange={selectorOnChange}
        >
          <option key='' value=''>
            {t('popupStrings.missionDetailsPopupStrings.chooseACountry')}
          </option>
          {countriesList.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default withTranslation()(CountrySelector);
