import React, { FunctionComponent } from 'react';
import { Popup } from 'semantic-ui-react';

import { StepWrapper } from './stepStyles';

type StepProps = {
  label: string;
  color?: string;
  width?: string;
  popupContent?: string;
};

const Step: FunctionComponent<StepProps> = (props) => {
  return (
    <Popup
      disabled={!props.popupContent}
      content={props.popupContent}
      trigger={
        <StepWrapper color={props.color && props.color} width={props.width}>
          {props.label}
        </StepWrapper>
      }
    />
  );
};

export default Step;
