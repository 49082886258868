import styled from "@emotion/styled";

export const GridWrapper = {'position': 'relative'}

export const ListingWrapper = {'height': 'calc(90vh - 171px)'}
export const SplitWrapper = {'height': 'calc(90vh - 200px)'}

export const Listing = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  float: 'left',
  boxSizing: 'border-box',
  width: '100%',
  border: '1px solid rgba(34,36,38,.15)',
  height: 'calc(90vh - 200px)',
  overflowY: 'auto'
}))

export const ListingEmpty = styled.div(() => ({
 margin: '20px auto',
 textAlign: 'center',
 lineHeight: '20px',
 width: '50%',
 padding: '10px 20px',
 border: '1px solid rgba(34,36,38,.15)',
 borderRadius: '10px',
 color: 'rgb(34 36 38 / 74%)'
}))

export const Left = styled.div(() => ({
  float: 'left',
  width: '100%',
}))

export const SelectionLabel = styled.div(() => ({
  float: 'left',
  margin: '0 0 0 10px',
}))

export const SelectionArea = styled.div(() => ({
  width: '100%',
  float: 'left',
  clear: 'both',
  marginBottom: '10px'
}))

export const LeftArea = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  float: 'left',
  height: '38px',
  padding: '0 10px',
  width: 'auto',
}))

export const RightArea = styled.div(()=> ({
  display: 'flex ',
  justifyContent: 'space-between',
  alignItems: 'center',
  float: 'left',
  paddingRight: '5px',
}))

export const VertSep = styled.div(() => ({
  float: 'left',
  height: '18px',
  margin: '10px',
  background: 'rgba(34,36,38,.15)',
  width: '2px',
}))

export const Right = styled.div(()=> ({
  display: 'flex ',
  justifyContent: 'space-between',
  float: 'left',
  width: '280px',
  paddingRight: '5px',
}))
export const Inner = styled.div(() => ({
  display: 'inline-block',
  position: 'relative',
  margin: '0 10px 0 0',
  width: '158px',
}))

export const Nav = styled.div(() => ({
  float: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
  width: '100%',
  height: '40px',
  padding: '3px 0',
  borderBottom: '1px solid rgba(34,36,38,.15)',
  cursor: 'pointer',
}))

export const Title = styled.div(() => ({
  float: 'left',
  display: 'inline',
  padding: '0 10px',
  fontSize: '12px',
  lineHeight: '31px',
  height: '34px',
  width: '85%',
  overflow: 'hidden',
}))

export const IframeViewer = styled.iframe(() => ({
  border: 'none',
  width: '100%',
  height: 'calc(90vh - 200px)',
}))

export const IframeViewerFull = styled.iframe(() => ({
  position: 'absolute',
  border: 'none',
  margin: '0',
  padding: '0',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: 2000,
})) 

export const drop = {
  'selected:boxShadow': '0 2px 3px 0 rgba(34,36,38,.15)', 'borderRadius': '10px', 
  'borderColor':'rgb(204,204,204)' 
}
export const row = {
  'background': 'white', 'position': 'absolute', 
  'bottom': '0', 'height': '41px', 'marginTop': '0px', 'padding': '5px 0 0 0'
}
export const content = {
  'height': '90%', 'overflow': 'hidden'
}
export const iframe = {
  'height': 'calc(90vh - 200px)'
}

export const SplitIframe = {
  'height': 'calc(90vh - 250px)'
}

export const fsIcon = {'cursor': 'pointer', 'position': 'absolute', 'top': '-30px', 'color': 'white', 'right': '0', 'zIndex': 5000}
export const fsUnset = {'position': 'unset'}
export const fsRelative = {'position': 'relative', 'height': '90%'}
