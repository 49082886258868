import styled from "@emotion/styled";

export const ResponsiveWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',

  '@media (max-width: 1100px)': {
    flexDirection: 'column',
  },
}));

export const FolderWrapper = styled.div(() => ({
  display: 'flex',
  float: 'left',
  width: '100%'
}))

export const FolderLink = styled.div(() => ({
  padding: '0 0.5rem 0 0.3rem ',
  textDecoration: 'none',
  color: 'rgba(0,0,0,.87)',
  cursor: 'pointer',
}))

export const SpecificsH4 = styled.h4(({css}) => ({
 padding: `${css}`,
 float: 'left',
 width: '100%',
 fontSize: '1.08rem',
 lineHeight: '1.28571429em',
 textTransform: 'none',
 color: 'rgba(0, 0, 0, .87)',
}))

export const Specifics = styled.div(() => ({
  padding: '1rem',
  borderRadius: '.28571429rem',
  border: '1px solid rgba(34, 36, 38, .15)',
  background: 'white',
  boxShadow: '0 1px 2px 0 rgba(34, 36, 38, .15)',
  width: '100%',
  float: 'left',
  margin: '0 0 1rem 0',
  cursor: 'pointer',
  whiteSpace: 'pre-line',
}))

export const Linked = FolderLink.withComponent('a')

