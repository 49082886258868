import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Search from 'components/SearchTruck';
import { Divider, Table, Header, Label} from 'semantic-ui-react';
import { actions, selectors } from 'store';


import Loader from 'components/Loader';
import Button from 'components/Button';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { shortDate } from 'services/helper/dateFormater';
import { Spacer } from 'components/core';

const CustomsDeclarationParking = ({
  dispatch,
  history,
  parking,
  parkingLoading,
}) => {

  const [filter, setFilter] = useState('all');
  const [filterControls, setControls] = useState(false);
  const [resetSearch, setresetSearch] = useState(false);
  const [data, setdata] = useState([]);

  useEffect(() => {
    dispatch(actions.customsDeclaration.getParking());
    dispatch(actions.socket.subscribeParkingList());

    return () => {
      dispatch(actions.customsDeclaration.reset(['parking','error']));
      dispatch(actions.socket.unsubscribeParkingList());
    }
  }, [dispatch]);

  useEffect(()=> {
    parking && setdata(_.orderBy(parking, ['truckLicensePlate'], ['desc']));
    resetSearch && setresetSearch(false);
  }, [parking, resetSearch])

  const onSearchResultChange = (truckLicensePlate) => {
    setdata(_.orderBy(truckLicensePlate, ['truckLicensePlate'], ['desc']));
  };

  const handleFiltering = (type, control) => {
    let tab: any
    switch(type){
      case 'active':
        tab = parking.filter((e) => !e.status)
        setFilter(type)
        break
      case 'paused':
        tab = parking.filter((e) => e.status)
        setFilter(type)
        break
      default:
        tab = parking
        setFilter(type)
        setControls(control)
    }
    setdata(tab)
  }

  const handleFilteringControls = (controls) => {
    let tab: any
    switch(controls){
      case true:
        tab = parking.filter((e) => e.controls)
        setControls(controls)
        break
      default:
        tab = parking
        setControls(controls)
    }
    setdata(tab)
  }


  
  return (
    <Wrapper overflow='auto' width='90%' margin='auto'>
           <Divider hidden />
      <Wrapper display='flex'>
        <ButtonBack history={history} />
      </Wrapper>
      <Divider hidden />
      {(parkingLoading && <Loader content='Loading' />) || (
        <Wrapper>
          <Wrapper display='flex' justifyContent='center'>
            <Header size='huge'>Parking monitoring</Header>
          </Wrapper>
          <Spacer height='30px' />
          <Wrapper display='flex' justifyContent='space-between'>
            <p>Number of trucks: {data.length}</p>
            <p></p>
          </Wrapper>
          <Wrapper display='flex' justifyContent='space-between'>
            <Wrapper display='flex' justifyContent='space-between'>
              <Search
                dataSource={parking}
                onSearchResultChange={onSearchResultChange}
                setResetSearch={setresetSearch}
              />
            </Wrapper>
            <Wrapper display='flex' justifyContent='center'>
              <Button
                type='button'
                content='All'
                color={filter === 'all' ? 'activeBlue' : 'blue'}
                onClick={() => handleFiltering('all', false)}
              />
              <Button
                type='button'
                content='Active'
                color={filter === 'paused' ? 'activeBlue' : 'blue'}
                onClick={() => handleFiltering('paused', false)}
              />
              <Button
                type='button'
                content='Paused'
                color={filter === 'active' ? 'activeBlue' : 'blue'}
                onClick={() => handleFiltering('active', false)}
              />
              <Button
                type='button'
                content='Controls'
                color={filterControls === true ? 'activeBlue' : 'blue'}
                onClick={() => handleFilteringControls(true)}
              />
            </Wrapper>
          </Wrapper>

          <Wrapper display='flex' justifyContent='flex-end'>
           
          </Wrapper>
          <Table columns={5} striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Truck license plate</Table.HeaderCell>
                <Table.HeaderCell>parking number</Table.HeaderCell>
                <Table.HeaderCell>Arrival</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Current status</Table.HeaderCell>
                <Table.HeaderCell>Controls</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={5} />) ||
                data.map((park, i) => {
                  return (
                    <Table.Row key={i} warning={park.operatorNewMessage}>
                       <Table.Cell>{park.truckLicensePlate}</Table.Cell>
                        <Table.Cell>{park.parkingNumber}</Table.Cell>
                        <Table.Cell>{shortDate(new Date(park.createdAt))}</Table.Cell>
                        <Table.Cell textAlign='center'><Label circular color={park.status ? 'green': 'red'} empty key={park.status ? 'green': 'red'} /></Table.Cell>
                        <Table.Cell>{park.controls && (<Label color='blue' horizontal>Control passed</Label>)}</Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    parking: selectors.customsDeclaration.getParkingSelector(
      state
    ),

    parkingLoading: selectors.customsDeclaration.getParkingLoadingSelector(
      state
    ),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(CustomsDeclarationParking);
