export default {
  intrastat: '/intrastat',
  intrastatClient: '/intrastat-dashboard',
  intrastatMonitoring: '/intrastat-monitoring',
  intrastatList: '/intrastat/:companyId',
  intrastatCountryList: '/intrastat/country/:iso',
  intrastatCompanyList: '/intrastat/country/:iso/:companyId',
  companiesIntrastatDetails: '/intrastat/country/:iso/:companyId/:intrastatId',
  intrastatDetails: '/intrastat/:companyId/:intrastatId',
  intrastatDetailsClient: '/intrastat-dashboard/:companyId/:intrastatId',
  intrastatMonitoringDetail: '/intrastat-monitoring/:companyId/:intrastatId',
};
