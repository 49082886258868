import actionTypes from 'store/company/driver/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.ADD_DRIVER:
        try {
          next({ type: actionTypes.ADD_DRIVER_LOADING });
          await request.addDriver({ ...action.payload });
          next({
            type: actionTypes.ADD_DRIVER_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.ADD_DRIVER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.REMOVE_DRIVER:
        try {
          next({ type: actionTypes.REMOVE_DRIVER_LOADING });
          await request.removeDriver({ ...action.payload });
          next({
            type: actionTypes.REMOVE_DRIVER_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.REMOVE_DRIVER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UPDATE_DRIVER:
        try {
          next({ type: actionTypes.UPDATE_DRIVER_LOADING });
          await request.updateDriver({ ...action.payload });
          next({
            type: actionTypes.UPDATE_DRIVER_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_DRIVER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UPDATE_DRIVER_PERMISSIONS:
        try {
          next({ type: actionTypes.UPDATE_DRIVER_PERMISSIONS_LOADING });
          await request.updateDriverPermissions({ ...action.payload });
          next({
            type: actionTypes.UPDATE_DRIVER_PERMISSIONS_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_DRIVER_PERMISSIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_DRIVER_PERMISSIONS:
        try {
          next({ type: actionTypes.GET_DRIVER_PERMISSIONS_LOADING });

          const response = await request.getDriverPermissions({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_DRIVER_PERMISSIONS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_DRIVER_PERMISSIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_DRIVER_COMPANIES:
        try {
          next({ type: actionTypes.GET_DRIVER_COMPANIES_LOADING });
          const response = await request.getDrivers({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_DRIVER_COMPANIES_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_DRIVER_COMPANIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
        case actionTypes.ADD_DRIVER_TO_COMPANY:
          try {
            next({ type: actionTypes.ADD_DRIVER_TO_COMPANY_LOADING });
            const response = await request.addDriverToCompany({
              ...action.payload,
            });
            next({
              type: actionTypes.ADD_DRIVER_TO_COMPANY_SUCCESS,
              payload: response.data.data,
            });
            store.dispatch(messageHandler.successMessage(''));
          } catch (error) {
            next({
              type: actionTypes.ADD_DRIVER_TO_COMPANY_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
          break;
      case actionTypes.GET_ONE_DRIVER:
        try {
          next({ type: actionTypes.GET_ONE_DRIVER_LOADING });
          const response = await request.getOneDriver({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_ONE_DRIVER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_DRIVER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ALL_DRIVERS:
        try {
          next({ type: actionTypes.GET_ALL_DRIVERS_LOADING });
          const response = await request.getAllDrivers({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_ALL_DRIVERS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_DRIVERS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
