// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import MissionProperties from '../../interfaces/missionProperties';

// Stylesheets
import './missions.css';

/**
 * @name mission.tsx
 *
 * @path /components/missions/mission.tsx
 *
 * @description Defines cell's content for Missions component
 */
const Mission: React.FC<MissionProperties> = ({
  reference,
  company,
  companyId,
  startDate,
  endDate,
  type,
  sector,
  numberOfEmployees,
  personInCharge,
  tags,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  return (
    <div className='missionContainer'>
      {/* Reference */}
      <div className='missionReference'>
        <p>{reference}</p>
      </div>

      {/* Company Name */}
      <div className='missionInformation'>
        <p>{company}</p>
      </div>

      {/* Start Date */}
      <div className='missionInformation'>
        <p>{startDate}</p>
      </div>

      {/* End Date */}
      <div className='missionInformation'>
        <p>{endDate}</p>
      </div>

      {/* Type */}
      <div className='missionInformation'>
        <p>{t('orderPageStrings.missionType.customMade')}</p>
      </div>

      {/* Sector */}
      <div className='missionInformation'>
        <p>
          {sector == 'Construction'
            ? t('orderPageStrings.missionSector.construction')
            : t('orderPageStrings.missionSector.other')}
        </p>
      </div>

      {/* Number of Employees */}
      <div className='missionInformation'>
        <p>{numberOfEmployees}</p>
      </div>

      {/* Person in charge */}
      <div className='missionInformation'>
        <p>{personInCharge}</p>
      </div>

      {/* Tags */}
      <div className='missionTags'>
        {tags[0] == 1 ? (
          <div className='missionTagPaid'>
            {t('missionPageStrings.tags.paid')}
          </div>
        ) : (
          <div className='missionTagUnpaid'>
            {t('missionPageStrings.tags.unpaid')}
          </div>
        )}
        {tags[1] == 1 ? (
          <div className='missionTagInspection'>
            {t('missionPageStrings.tags.inspection')}
          </div>
        ) : (
          <></>
        )}
      </div>

      <button
        className='openMissionDetails'
        onClick={() =>
          (window.location.href = `/spw/missions/${companyId}/details/${reference}`)
        }
      >
        ▶
      </button>
    </div>
  );
};

export default Mission;
