import styled from '@emotion/styled';

export const ImageCustomStyled = styled.img((props: any) => {
  return {
    height: props.height || '100%',
    width: props.width || '100%',
    maxWidth: props.maxWidth || '100%',
    maxHeight: props.maxHeight || '100%',
    margin: props.margin || '0px',
  };
});
