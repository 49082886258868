/**
 * Dependencies
 */
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources
 */
import './missionDetailsManagement.css';
import Loader from 'components/Loader';
import EditButton from '../editButton/editButton';
import TextInput from '../textInput/textInput';
import saveLogo from '../../assets/images/logos/save.png';
import extension from '../../assets/images/logos/extension.png';
import switcher from '../../assets/images/logos/switcher.png';
import FileUploader from '../fileUploader/fileUploader';
import axios from 'axios';
import UploadConfirmation from '../uploadConfirmation/uploadConfirmation';

/**
 *
 */
interface MissionDetailsManagementProps extends WithTranslation {
  companyId: number;
  reference: string;
}

/**
 *
 */
interface MissionDetailsManagementState {
  isLoading: boolean;
  error: string | null;
  employeesFromCompany: EmployeeData[];
  employeesRelatedToMission: EmployeeData[];
  documentsLinkedToMission: DocumentData[];
  missionId?: number;
  nbOfEmployees?: number;
  addDocumentIsVisible: boolean;
  fileName?: string;
  isErrorHidden: boolean;
  file?: File;
  fileUrl?: string;
  fileUpload: boolean;
  confirmation: boolean;
  downloadUrl?: string;
}

/**
 *
 */
interface EmployeeData {
  id: number;
  firstName: string;
  lastName: string;
  companyId: number;
}

/**
 *
 */
interface DocumentData {
  documentId: number;
  documentName: string;
  s3Path: string;
}

/**
 *
 */
class MissionDetailsManagement extends Component<
  MissionDetailsManagementProps,
  MissionDetailsManagementState
> {
  constructor(props: MissionDetailsManagementProps) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      employeesFromCompany: [],
      employeesRelatedToMission: [],
      documentsLinkedToMission: [],
      missionId: undefined,
      nbOfEmployees: undefined,
      addDocumentIsVisible: false,
      fileName: undefined,
      isErrorHidden: true,
      file: null,
      fileUrl: undefined,
      fileUpload: false,
      confirmation: false,
      downloadUrl: undefined,
    };

    this.selectorOnChange = this.selectorOnChange.bind(this);
    this.switchPopupVisibility = this.switchPopupVisibility.bind(this);
    this.toggleErrorHidden = this.toggleErrorHidden.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleClickExtensionButton =
      this.handleClickExtensionButton.bind(this);
    this.handleClickSwitchButton = this.handleClickSwitchButton.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  toggleLoading = (bool: boolean) => {
    this.setState(() => ({
      isLoading: bool,
    }));
  };

  toggleErrorHidden = (bool: boolean) => {
    this.setState(() => ({
      isErrorHidden: bool,
    }));
  };

  handleChangeFileName = (value: string) => {
    this.setState(() => ({
      fileName: value,
    }));
  };

  handleUpload() {
    this.setState(() => ({
      confirmation: true,
    }));

    setTimeout(
      () =>
        this.setState(() => ({
          confirmation: false,
        })),
      3000
    );
  }

  componentDidMount(): void {
    if (window.sessionStorage.getItem('fileUpload') === 'true') {
      this.handleUpload();
    }
    window.sessionStorage.setItem('fileUpload', 'false');
    this.getMissionId().then(() => {
      this.getAllEmployeesFromCompany();
      this.getAllEmployeesRelatedToMission();
      this.getAllDocumentLinkedToMission();
    });
  }

  getAllEmployeesRelatedToMission = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getEmployeesRelatedToMission`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ missionId: this.state.missionId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonResponse = await response.json();

      let employees = [];
      console.log(JSON.stringify(jsonResponse));

      if (JSON.stringify(jsonResponse) != '{}') {
        const formattedJsonResponse = jsonResponse.data.map((obj) => [
          obj.employeeId,
          obj.civility,
          obj.firstName,
          obj.lastName,
          obj.nationality,
          obj.dateOfBirth,
          obj.placeOfBirth,
          obj.countryOfBirth,
          obj.companyId,
          obj.addressId,
        ]);

        console.log(formattedJsonResponse);

        employees = formattedJsonResponse.map((person: any[]) => ({
          id: person[0],
          firstName: person[2],
          lastName: person[3],
          companyId: person[8],
        }));
      }
      this.setState({ isLoading: false, employeesRelatedToMission: employees });
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
      console.log(error.message);
    }
  };

  getAllEmployeesFromCompany = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAllEmployeesNotRelatedToMission`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            missionId: this.state.missionId,
            companyId: this.props.companyId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonResponse = await response.json();

      let employees = [];
      console.log(JSON.stringify(jsonResponse));

      if (JSON.stringify(jsonResponse) != '{}') {
        const formattedJsonResponse = jsonResponse.data.map((obj) => [
          obj.employeeId,
          obj.civility,
          obj.firstName,
          obj.lastName,
          obj.nationality,
          obj.dateOfBirth,
          obj.placeOfBirth,
          obj.countryOfBirth,
          obj.companyId,
          obj.addressId,
        ]);

        employees = formattedJsonResponse.map((person: any[]) => ({
          id: person[0],
          firstName: person[2],
          lastName: person[3],
          companyId: person[8],
        }));
      }

      this.setState({ isLoading: false, employeesFromCompany: employees });
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
      console.log(error.message);
    }
  };

  getAllDocumentLinkedToMission = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAllDocumentsLinkedToMission`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            missionId: this.state.missionId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonResponse = await response.json();

      let documents = [];
      console.log(JSON.stringify(jsonResponse));

      if (JSON.stringify(jsonResponse) != '{}') {
        const formattedJsonResponse = jsonResponse.data.map((obj) => [
          obj.missionDocumentId,
          obj.name,
          obj.s3Path,
        ]);

        documents = formattedJsonResponse.map((document: any[]) => ({
          documentId: document[0],
          documentName: document[1],
          s3Path: document[2],
        }));
      }

      this.setState({ isLoading: false, documentsLinkedToMission: documents });
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
      console.log(error.message);
    }
  };

  getMissionId = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getMissionDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ reference: this.props.reference }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonResponse = await response.json();
      const missionId = jsonResponse.data.missionId;
      const nbOfEmployees = jsonResponse.data.numberOfEmployees;

      this.setState({ missionId: missionId, nbOfEmployees: nbOfEmployees });
    } catch (error: any) {
      this.setState({ error: error.message });
      console.log(error.message);
    }
  };

  linkEmployeeToMission = async (selectedNumber: number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/newMissionToEmployee`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            missionId: this.state.missionId,
            employeeId: selectedNumber,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error: any) {
      this.setState({ error: error.message });
      console.log(error.message);
    }
  };

  switchPopupVisibility() {
    this.setState((prevState) => ({
      addDocumentIsVisible: !prevState.addDocumentIsVisible,
    }));
  }

  returnToMissionDetails() {
    let url = window.location.href;
    const lastSlashIndex = url.lastIndexOf('/');

    window.location.href =
      lastSlashIndex !== -1 ? url.substring(0, lastSlashIndex) : url;
  }

  selectorOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.linkEmployeeToMission(Number(event.target.value));
    window.location.reload();
  }

  uniqid(mimeType: string) {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, '').padEnd(14, '0');
    return `${id}.${mimeType}`;
  }

  async createDocumentOnS3() {
    this.toggleLoading(true);
    const filePath = `mission_${this.state.missionId}/${this.state.fileName}`;
    const formData = new FormData();
    let newFile = this.uniqid('pdf');
    formData.append('file', this.state.file, newFile);
    formData.append('filePath', filePath);

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/newMissionDocumentOnS3`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(await this.createDocument(newFile));

      window.sessionStorage.setItem('fileUpload', 'true');
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  async createDocument(uniqidFilename: string) {
    const url = `/mission_${this.state.missionId}/${this.state.fileName}/${uniqidFilename}`;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/newMissionDocument`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            missionId: this.state.missionId,
            name: this.state.fileName,
            s3Path: url,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  handleClickSave() {
    if (
      this.state.fileName == undefined ||
      this.state.fileName.trim() === '' ||
      this.state.file == null
    ) {
      this.toggleErrorHidden(false);
    } else {
      this.toggleErrorHidden(true);
      this.createDocumentOnS3().then(() => window.location.reload());
    }
  }

  handleFileUpload(uploadedFile: File) {
    this.setState(() => ({
      file: uploadedFile,
      fileUrl: URL.createObjectURL(uploadedFile),
      fileUpload: true,
    }));
  }

  handleClickExtensionButton() {
    window.open(this.state.fileUrl, '_blank');
  }

  handleClickSwitchButton() {
    this.setState(() => ({
      file: null,
      fileUrl: undefined,
      fileUpload: false,
    }));
  }

  async handleClickDownload(filePath: string) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/downloadMissionDocument`,
        { filePath }, // Envoyer le nom du fichier dans le corps de la requête
        {
          responseType: 'blob', // Attendre une réponse de type blob (fichier binaire)
        }
      );
      const fileType = response.headers['content-type']; // Récupérer le type MIME depuis les headers
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: fileType })
      );

      window.open(url, '_blank');
    } catch (error) {
      console.error('Error during file download:', error);
    }
  }

  render() {
    const {
      isLoading,
      error,
      employeesFromCompany,
      employeesRelatedToMission,
      documentsLinkedToMission,
      missionId,
      nbOfEmployees,
      addDocumentIsVisible,
      fileName,
      isErrorHidden,
      file,
      fileUrl,
      fileUpload,
      confirmation,
    } = this.state;
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return <div>Erreur: {error}</div>;
    }

    return (
      <>
        <div className='missionDetailsManagementContainer'>
          {/* <div className='missionDetailsManagementReturnSection'>
            <button
              className='missionDetailsManagementReturnButton'
              onClick={this.returnToMissionDetails}
            >
              {t('missionDetailsManagementPageStrings.backButton')}
            </button>
          </div> */}
          <div className='missionDetailsManagementContent'>
            <div className='missionDetailsManagementEmployeesContainer'>
              <div className='missionDetailsManagementEmployeesContainerTitle'>
                <h4>
                  {t(
                    'missionDetailsManagementPageStrings.employeesContainer.title'
                  )}
                </h4>
              </div>
              <div className='missionDetailsManagementEmployeesContainerContent'>
                <div className='missionDetailsManagementEmployeesLinked'>
                  {employeesRelatedToMission.length == 0 ? (
                    <p>
                      {t(
                        'missionDetailsManagementPageStrings.employeesContainer.noLinkedEmployees'
                      )}
                    </p>
                  ) : (
                    <div className='employeesLinkedSorted'>
                      {employeesRelatedToMission.map((employee) => (
                        <div className='employeeLinked'>
                          {employee.id +
                            ' - ' +
                            employee.firstName +
                            ' ' +
                            employee.lastName}
                          <button
                            className='missionDetailsManagementReturnButton'
                            onClick={() => {
                              window.location.href = `/spw/company/employees/${employee.companyId}/details/${employee.id}`;
                            }}
                          >
                            {t(
                              'missionDetailsManagementPageStrings.employeesContainer.openDetails'
                            )}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className='missionDetailsManagementEmployeesContainerButton'>
                {employeesRelatedToMission.length < nbOfEmployees && (
                  <>
                    <select
                      className={'employeeSelector'}
                      onChange={this.selectorOnChange}
                    >
                      <option value=''>
                        {t(
                          'missionDetailsManagementPageStrings.employeesContainer.chooseAnEmployeeToAddContent'
                        )}
                      </option>
                      {employeesFromCompany.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.id +
                            ' - ' +
                            employee.firstName +
                            employee.lastName}
                        </option>
                      ))}
                    </select>
                    <p>
                      {t(
                        'missionDetailsManagementPageStrings.employeesContainer.chooseAnEmployeeToAddTitle'
                      )}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className='missionDetailsManagementDocumentsContainer'>
              <div className='missionDetailsManagementEmployeesContainerTitle'>
                <h4>
                  {t(
                    'missionDetailsManagementPageStrings.documentsContainer.title'
                  )}
                </h4>
              </div>
              <div className='missionDetailsManagementDocumentsContainerContent'>
                <div className='missionDetailsManagementEmployeesLinked'>
                  {documentsLinkedToMission.length == 0 ? (
                    <p>
                      {t(
                        'missionDetailsManagementPageStrings.documentsContainer.noLinkedDocuments'
                      )}
                    </p>
                  ) : (
                    <div className='employeesLinkedSorted'>
                      {documentsLinkedToMission.map((document) => (
                        <div className='employeeLinked'>
                          {document.documentName}
                          <button
                            className='missionDetailsManagementReturnButton'
                            onClick={() =>
                              this.handleClickDownload(document.s3Path)
                            }
                          >
                            {t(
                              'missionDetailsManagementPageStrings.documentsContainer.download'
                            )}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className='missionDetailsManagementDocumentsContainerButton'>
                <EditButton
                  popupVisible={addDocumentIsVisible}
                  title={t(
                    'missionDetailsManagementPageStrings.documentsContainer.popupTitle'
                  )}
                  action={this.switchPopupVisibility}
                >
                  <>
                    <TextInput
                      classname='missionDocumentFileName'
                      placeholder={t(
                        'missionDetailsManagementPageStrings.documentsContainer.popupPlaceholder'
                      )}
                      initialValue={fileName}
                      onSave={this.handleChangeFileName}
                    />
                    {file ? (
                      <div className='iframeMission'>
                        <iframe
                          title='PDF Viewer'
                          src={URL.createObjectURL(file)}
                          className='pdfViewer'
                        />
                        <div className='pdfViewerButtons'>
                          <img
                            src={extension}
                            alt='extensionLogo'
                            className='pdfViewerButton'
                            onClick={this.handleClickExtensionButton}
                          />
                          <img
                            src={switcher}
                            alt='switchLogo'
                            className='pdfViewerButton'
                            onClick={this.handleClickSwitchButton}
                          />
                        </div>
                      </div>
                    ) : (
                      <FileUploader
                        onFileUpload={this.handleFileUpload}
                        hidden={fileUpload}
                        fileType={'pdf'}
                        classname='fileUploaderMission'
                      />
                    )}
                    <div>
                      <div className='space'></div>
                      {isErrorHidden ? (
                        <></>
                      ) : (
                        <div className='companyError'>
                          <p>{t('orderPageStrings.error')}</p>
                        </div>
                      )}
                      <div className='saveButtonContainer'>
                        <button
                          className='saveButton'
                          onClick={this.handleClickSave}
                        >
                          <p>{t('popupStrings.saveText')}</p>
                          <img
                            src={saveLogo}
                            alt={t('popupStrings.saveLogo')}
                            className='saveLogo'
                          />
                        </button>
                      </div>
                    </div>
                  </>
                </EditButton>
              </div>
            </div>
          </div>
        </div>{' '}
        {confirmation && (
          <UploadConfirmation
            message={t('globalStrings.fileUploadSuccessfully')}
            duration={3000}
            onClose={() =>
              this.setState(() => ({
                confirmation: false,
              }))
            }
          />
        )}
      </>
    );
  }
}

export default withTranslation()(MissionDetailsManagement);
