import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Divider, Header, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Search from 'components/Search';
import { Main } from 'components/Layouts';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths } from 'routes/fiscal/kyanite';

const Index = ({ dispatch, companies, history }) => {
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [resetSearch, setresetSearch] = useState(false);

  useEffect(() => {
    dispatch(actions.quickfixes.getCompanySubscribed());
    return () => {
      dispatch(actions.quickfixes.reset(['companySubscribed', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    setdata(_.orderBy(companies, ['name'], ['asc']));
    resetSearch && setresetSearch(false);
  }, [companies, resetSearch]);

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['name'], ['asc']));
  };

  return (
    <Main>
      <Divider hidden />
      <Wrapper padding='2rem'>
        <Header size='huge' textAlign='center'>
          Quickproof Operator
          <Header.Subheader>Subscribed Companies</Header.Subheader>
        </Header>
      </Wrapper>

      {(companies === null && <Loader />) || (
        <Container>
          <Search
            dataSource={companies}
            onSearchResultChange={onSearchResultChange}
            setResetSearch={setresetSearch}
          />
          <Table size='large' columns='3' sortable selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'name' ? direction : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'email' ? direction : null}
                  onClick={() => handleSort('email')}
                >
                  E-mail
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                  Country
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={3} />) ||
                data.map((company, i) => {
                  return (
                    company.Deliveries.length>0 && (
                    <Table.Row key={i}>
                      <Link
                        to={paths.kyaniteOperator.replace(
                          ':companyId',
                          company.id
                        )}
                        className='table-link'
                      >
                        <Table.Cell>{company.name}</Table.Cell>
                        <Table.Cell>{company.email}</Table.Cell>
                        <Table.Cell>{company.country}</Table.Cell>
                      </Link>
                    </Table.Row>
                    )
                  );
                })}
            </Table.Body>
          </Table>
        </Container>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.quickfixes.companySubscribedSelector(state),
    errorHandlerClients: selectors.company.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
