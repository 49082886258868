import News from "views/Admin/News/news";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.notifications,
    component: News,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.news,
    component: News,
    exact: true,
    authorizedRoles: ['admin']
  },
];
