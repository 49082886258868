import React from 'react';
import { Header, Modal } from 'semantic-ui-react';

const ModalMonitoringTutorial = ({ isModalOpen, handleClose }) => {
  return (
    <Modal open={isModalOpen} onClose={handleClose} closeIcon>
      <Header icon='video' content='Tutorial' />
      <iframe
        width='560'
        height='315'
        title='tutorial'
        style={{ display: 'block', margin: '1rem auto', borderRadius: '10px' }}
        src='https://www.youtube.com/embed/GRzMGGQKTO0'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </Modal>
  );
};

export default ModalMonitoringTutorial;
