export default {
  customsDeclaration: '/customs/declaration',
  customsDeclarationMonitoring: '/customs/declaration/monitoring',
  customsDeclarationParking: '/customs/declaration/parking',
  customsDeclarationCompany: '/customs/declaration/company/:companyId',
  customsDeclarationDetails: '/customs/declaration/company/:companyId/:declarationId',
  customsDeclarationMandates: '/customs/declaration/mandates/company/:companyId',
  customsDeclarationAccises: '/customs/declaration/accises/company/:companyId',
  customsDeclarationDeb: '/customs/declaration/deb/company/:companyId',
  customsDeclarationDebDetail: '/customs/declaration/deb/company/:companyId/:debId',
  customsDeclarationStatistic: '/customs/declaration/statistics',
  customsDeclarationStatisticDetail: '/customs/declaration/statistics/:companyId',
};
