import React from 'react';
import { Grid, Header, Divider, Tab, Label, Menu } from 'semantic-ui-react';
import ProofsDecisions from 'components/QuickFixes/DeliveryForm/ProofsDecisions';

const Index = ({
  companyType,
  deliveryId,
  actorsId,
  proofs,
  companyId,
  user,
}: any) => {

  const userAdminOrOp = user.operator || user.admin;

  return (
    <>
      <Header textAlign='center'>Check Delivery Proofs</Header>
      <Divider hidden />
      <Tab
        panes={
          (companyType === 'seller' || companyType === 'buyer' || companyType === 'transporter') // new
            ? [
                {
                  menuItem: (
                    <Menu.Item key={actorsId.seller}>
                      My proofs
                      <Label>{proofs[actorsId.seller].length}</Label>
                    </Menu.Item>
                  ),
                  render: () => (
                    <Tab.Pane>
                      <Grid columns='2' divided>
                        <Grid.Column>
                          <Header textAlign='center' size='small'>
                            Level 1 proofs
                          </Header>
                          <ProofsDecisions
                            key={1}
                            receivedData={proofs[actorsId.seller].filter(
                              (proof) => proof.level === 1
                            )}
                            proofsLevel={1}
                            deliveryId={deliveryId}
                            companyId={companyId}
                            userAdminOrOp={userAdminOrOp}
                            companyType={companyType}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Header textAlign='center' size='small'>
                            Level 2 proofs
                          </Header>
                          <ProofsDecisions
                            key={2}
                            receivedData={proofs[actorsId.seller].filter(
                              (proof) => proof.level === 2
                            )}
                            proofsLevel={2}
                            deliveryId={deliveryId}
                            companyId={companyId}
                            userAdminOrOp={userAdminOrOp}
                            companyType={companyType}
                          />
                        </Grid.Column>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: (
                    <Menu.Item key={actorsId.buyer}>
                      Buyer's Proofs
                      <Label>{proofs[actorsId.buyer].length}</Label>
                    </Menu.Item>
                  ),
                  render: () => (
                    <Tab.Pane>
                      <Grid columns='2' divided>
                        <Grid.Column>
                          <Header textAlign='center' size='small'>
                            Level 1 proofs
                          </Header>
                          <ProofsDecisions
                            key={3}
                            receivedData={
                              proofs[actorsId.buyer] &&
                              proofs[actorsId.buyer].filter(
                                (proof) => proof.level === 1
                              )
                            }
                            proofsLevel={1}
                            deliveryId={deliveryId}
                            companyId={companyId}
                            userAdminOrOp={userAdminOrOp}
                            companyType={companyType}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Header textAlign='center' size='small'>
                            Level 2 proofs
                          </Header>
                          <ProofsDecisions
                            key={4}
                            receivedData={
                              proofs[actorsId.buyer] &&
                              proofs[actorsId.buyer].filter(
                                (proof) => proof.level === 2
                              )
                            }
                            proofsLevel={2}
                            deliveryId={deliveryId}
                            companyId={companyId}
                            userAdminOrOp={userAdminOrOp}
                            companyType={companyType}
                          />
                        </Grid.Column>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: (
                    <Menu.Item key={actorsId.transporter}>
                      Transporter's Proofs
                      <Label>{proofs[actorsId.transporter].length}</Label>
                    </Menu.Item>
                  ),
                  render: () => (
                    <Tab.Pane>
                      <Grid columns='2' divided>
                        <Grid.Column>
                          <Header textAlign='center' size='small'>
                            Level 1 proofs
                          </Header>
                          <ProofsDecisions
                            key={5}
                            receivedData={proofs[actorsId.transporter].filter(
                              (proof) => proof.level === 1
                            )}
                            proofsLevel={1}
                            deliveryId={deliveryId}
                            companyId={companyId}
                            userAdminOrOp={userAdminOrOp}
                            companyType={companyType}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Header textAlign='center' size='small'>
                            Level 2 proofs
                          </Header>
                          <ProofsDecisions
                            key={6}
                            receivedData={proofs[actorsId.transporter].filter(
                              (proof) => proof.level === 2
                            )}
                            proofsLevel={2}
                            deliveryId={deliveryId}
                            companyId={companyId}
                            userAdminOrOp={userAdminOrOp}
                            companyType={companyType}
                          />
                        </Grid.Column>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
              ]
            : [
                {
                  menuItem: 'My Proofs',
                  render: () => (
                    <Tab.Pane>
                      <Grid columns='2' divided>
                        <Grid.Column>
                          <Header textAlign='center' size='small'>
                            Level 1 proofs
                          </Header>
                          <ProofsDecisions
                            key={1}
                            receivedData={proofs.filter(
                              (proof) =>
                                proof.level === 1 &&
                                proof.Company_Id === actorsId[companyType]
                            )}
                            proofsLevel={1}
                            deliveryId={deliveryId}
                            userAdminOrOp={userAdminOrOp}
                            companyType={companyType}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Header textAlign='center' size='small'>
                            Level 2 proofs
                          </Header>
                          <ProofsDecisions
                            key={2}
                            receivedData={proofs.filter(
                              (proof) =>
                                proof.level === 2 &&
                                proof.Company_Id === actorsId[companyType]
                            )}
                            proofsLevel={2}
                            deliveryId={deliveryId}
                            userAdminOrOp={userAdminOrOp}
                            companyType={companyType}
                          />
                        </Grid.Column>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
            ]
        }
      />
    </>
  );
};

export default Index;
