export default {
  GET_COMPUTED_CHEDS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_COMPUTED_CHEDS',
  GET_COMPUTED_CHEDS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_COMPUTED_CHEDS_LOADING',
  GET_COMPUTED_CHEDS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_COMPUTED_CHEDS_SUCCESS',
  GET_COMPUTED_CHEDS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_COMPUTED_CHEDS_ERROR',

  GET_CHED_STATS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CHED_STATS',
  GET_CHED_STATS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CHED_STATS_LOADING',
  GET_CHED_STATS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CHED_STATS_SUCCESS',
  GET_CHED_STATS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CHED_STATS_ERROR',

  GET_FILTERED_STATS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_FILTERED_STATS',
  GET_FILTERED_STATS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_FILTERED_STATS_LOADING',
  GET_FILTERED_STATS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_FILTERED_STATS_SUCCESS',
  GET_FILTERED_STATS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_FILTERED_STATS_ERROR',


  GET_ONE_CHED: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_ONE_CHED',
  GET_ONE_CHED_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_ONE_CHED_LOADING',
  GET_ONE_CHED_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_ONE_CHED_SUCCESS',
  GET_ONE_CHED_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_ONE_CHED_ERROR',
  RESET: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.RESET',
  REFRESH_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.REFRESH_ERROR', 

  GET_STATS_BY_FILTERS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_STATS_BY_FILTERS',
  GET_STATS_BY_FILTERS_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_STATS_BY_FILTERS_LOADING', 
  GET_STATS_BY_FILTERS_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_STATS_BY_FILTERS_SUCCESS', 
  GET_STATS_BY_FILTERS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_STATS_BY_FILTERS_ERROR',
  
  GET_CLERK_STATS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CLERK_STATS',
  GET_CLERK_STATS_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CLERK_STATS_LOADING',
  GET_CLERK_STATS_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CLERK_STATS_SUCCESS',
  GET_CLERK_STATS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CLERK_STATS_ERROR',

  GET_LAST_CUSTOMS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_LAST_CUSTOMS',
  GET_LAST_CUSTOMS_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_LAST_CUSTOMS_LOADING',
  GET_LAST_CUSTOMS_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_LAST_CUSTOMS_SUCCESS',
  GET_LAST_CUSTOMS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_LAST_CUSTOMS_ERROR',

  GET_CUSTOMS_COMPARISON: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CUSTOMS_COMPARISON',
  GET_CUSTOMS_COMPARISON_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CUSTOMS_COMPARISON_LOADING',
  GET_CUSTOMS_COMPARISON_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CUSTOMS_COMPARISON_SUCCESS',
  GET_CUSTOMS_COMPARISON_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.STATS.GET_CUSTOMS_COMPARISON_ERROR',

};
