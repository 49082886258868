import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Table, Tab } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import AddContact from 'components/AddContact/addContact';
import Wrapper from 'components/core/Wrapper/Wrapper';
import TableSpacedRowsSmall from 'components/TableSpacedRowsSmall';
import EmptyTable from 'components/EmptyTableCurve';
import Details from './Details';
import Search from 'components/Search';
import AddPartnerModal from './AddPartnerModal';
import RightsModal from './RightsModal';

const Index = ({
  dispatch,
  errorHandlerContact,
  loadingHandlerContact,
  contact,
  contacts,
  addPartnersSuccess,
  addContactsRightsLoading,
  addContactsRights,
  getContactsRights,
  loadingRights,
  company,
  user,
}) => {
  const [addPartnersModal, setAddPartnersModal] = useState(false);
  const [addContactModal, setAddContactModal] = useState(false);
  const [rightsModal, setRightsModal] = useState(null);
  const [runningAction, setRunningAction] = useState(null);
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [showDetails, setshowDetails] = useState(false);
  const [resetSearch, setresetSearch] = useState(false);

  const isInternal = user.admin || user.operator || user.superOperator;
  const companyId = company.id

  useEffect(() => {
    dispatch(
      actions.contact.getAllContacts({
        params: { companyId: companyId },
      })
    );
    setshowDetails(false);

    return () => {
      dispatch(actions.contact.reset(['contacts', 'error']));
    };
  }, [dispatch, companyId]);


  useEffect(()=>{
      if(runningAction && !loadingHandlerContact){
        dispatch(
          actions.contact.getAllContacts({
            params: { companyId: companyId },
          })
        );
        setRunningAction(null);
      }

      if(runningAction && addPartnersSuccess){
        dispatch(
          actions.contact.getAllContacts({
            params: { companyId: companyId },
          })
        );
        setRunningAction(null);
      }

  }, [dispatch, runningAction, loadingHandlerContact, addPartnersSuccess, companyId])

 

  useEffect(() => {
    if(contacts){
      setdata(_.orderBy(contacts, ['name'], ['asc']));
    }
    resetSearch && setresetSearch(false);
  }, [resetSearch, contacts]);



  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['name'], ['asc']));
  };

  const getContact = (contactId) => {
      dispatch(
        actions.contact.getOneContact({
          params: { companyId: company.id, contactId: contactId },
        })
      );
      return () => {
        dispatch(actions.contact.reset(['contact', 'error']));
      }
  };

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const handleDelete = (contactId) => {
    dispatch(
      actions.contact.deleteContact({
        params: { companyId: company.id, contactId: contactId },
      })
    );
    setshowDetails(false)
    setRunningAction('removeContact');
  };

  const handeSubmitRights = (values: { 
    comments: string, 
    permissions: [], 
    chat: string, 
    ched: [], 
    state: boolean, 
    billing: boolean, 
    contracts: [], 
    contactId: number
  }) =>
  {
    dispatch(
      actions.contact.addContactRights({
        params: {
          companyId: company.id,
          contactId: values.contactId
        },
        data: {
          comments: values.comments,
          permissions: values.permissions,
          chat: values.chat !== "" ? values.chat : false,
          ched: values.ched,
          billing: values.billing,
          contracts: values.contracts,
          state: values.state
        },
      })
    );
    setRightsModal(null)
  }

  return (
    <Tab.Pane loading={!contacts || loadingHandlerContact || addContactsRightsLoading}>
      <Container>
        {addContactModal && (
          <AddContact
            open={addContactModal}
            handleClose={() => setAddContactModal(false)}
            setRunningAction={setRunningAction}
            companyId={company.id}
          />
        )}
        {addPartnersModal && (
          <AddPartnerModal
            open={addPartnersModal}
            handleClose={() => setAddPartnersModal(false)}
            setRunningAction={setRunningAction}
            companyId={company.id}
          />
        )}
        {rightsModal && (
          <RightsModal
            open={rightsModal.open}
            data={rightsModal.data}

            handeSubmitRights={handeSubmitRights}
            handleClose={() => setRightsModal(null)}
            setRunningAction={setRunningAction}
            companyId={company.id}
          />
        )}
        {!showDetails && (
          <>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              padding='1rem 10rem 0'
            >
             {(isInternal &&
              <Button
                icon='plus'
                type='submit'
                content='Add'
                onClick={() => {
                  setAddContactModal(true);
                }}
              />
             ) || (
              <Button
                icon='plus'
                type='submit'
                content='Add partner'
                onClick={() => {
                  setAddPartnersModal(true);
                }}
              />)
             }
            <Search
              dataSource={contacts}
              onSearchResultChange={onSearchResultChange}
              setResetSearch={setresetSearch}
            />
            </Wrapper>
            <Wrapper
              display='flex'
              justifyContent='center'
              padding='0 10rem 5rem'
            >
              <Wrapper display='inline-flex' width='100%'>
                <TableSpacedRowsSmall sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={column === 'name' ? direction : null}
                        onClick={() => handleSort('name')}
                      >
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === 'email' ? direction : null}
                        onClick={() => handleSort('email')}
                      >
                        Email
                      </Table.HeaderCell>

                      <Table.HeaderCell />
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {(data.length === 0 && <EmptyTable colNumber={4} />) ||
                      data.map((contact, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Wrapper display='flex'>{contact.name}</Wrapper>
                            </Table.Cell>
                            <Table.Cell>
                              <Wrapper display='flex'>{contact.email}</Wrapper>
                            </Table.Cell>
                            {contact.verified && (
                              <Table.Cell>
                                <Wrapper display='flex'>contact verified</Wrapper>
                              </Table.Cell>
                            )}
                            <Table.Cell />
                            <Table.Cell>
                              <Wrapper display='flex' justifyContent='flex-end'>
                                <Button
                                  content='Rights'
                                  icon='eye'
                                  type='button'
                                  color='red'
                                  labelPosition='right'
                                  onClick={() => {                                  
                                    setRightsModal({open: true, data: contact})
                                  }}
                                  margin='5px'
                                />
                                <Button
                                  content='Show'
                                  icon='eye'
                                  type='button'
                                  color='blue'
                                  labelPosition='right'
                                  onClick={() => {
                                    setshowDetails(true);
                                    getContact(contact.id);
                                  }}
                                  margin='5px'
                                />
                              </Wrapper>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </TableSpacedRowsSmall>
              </Wrapper>
            </Wrapper>
          </>
        )}

        {showDetails && (
          <Wrapper padding='1rem 10rem 2rem' display='flex' flexDirection='column' alignItems='flex-start'>
            <Button
              icon='reply'
              type='button'
              content='Back'
              onClick={() => {
                dispatch(actions.contact.reset(['contact']));
                setshowDetails(false);
              }}
            />
            <Details
              contact={contact}
              user={user}
              errorHandlerContact={errorHandlerContact}
              loadingHandlerContact={loadingHandlerContact}
              companyId={company.id}
              handleDelete={handleDelete}
            />
          </Wrapper>
        )}
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: selectors.contact.contactSelector(state),
    contacts: selectors.contact.contactsSelector(state),
    errorHandlerContact: selectors.contact.errorSelector(state),
    successHandlerContact: selectors.contact.errorSelector(state),
    loadingHandlerContact: selectors.contact.loadingSelector(state),
    addPartnersSuccess: selectors.contact.addContactPartnerSuccessSelector(state),
    userPermissions: selectors.companyUser.userPermissionsSelector(state),
    addContactsRights: selectors.contact.addContactRightsSelector(state),
    addContactsRightsLoading: selectors.contact.addContactRightsLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
