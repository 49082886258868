import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const getAllMsgErrorSelector = state => state.chat.errors.getAllMsgs;
const readChatErrorSelector = state => state.chat.errors.read;

const errorsSelector = createErrorsSelector([
  getAllMsgErrorSelector
]);

// Loading
const getAllMsgLoadingSelector = state => state.chat.loading.getAllMsgs;
const readChatLoadingSelector = state => state.intrastat.loading.read;

const loadingSelector = createLoadingSelector([
  getAllMsgLoadingSelector
]);

// Success
const getAllMsgSuccessSelector = state => state.chat.success.getAllMsgs;
const readChatSuccessSelector = state => state.intrastat.success.read;

const successSelector = createSuccessSelector([
  getAllMsgSuccessSelector
]);

// Countries
const getAllMsgSelector = state => state.chat.allMsgs;
const readChatSelector = state => state.intrastat.read;


// Error handler
const errorSelector = state => state.chat.error;


export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  getAllMsgSelector,
  getAllMsgLoadingSelector,
  errorSelector,
  readChatSelector, 
  readChatLoadingSelector
};
