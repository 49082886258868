import actionTypes from 'store/user/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.VALIDATE_USER:
        try {
          next({ type: actionTypes.VALIDATE_USER_LOADING });
          await request.validateUser({ ...action.payload });
          next({
            type: actionTypes.VALIDATE_USER_SUCCESS,
          });
          store.dispatch(
            messageHandler.successMessage('your account has been validated')
          );
        } catch (error) {
          next({
            type: actionTypes.VALIDATE_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_USERS:
        try {
          next({ type: actionTypes.GET_USERS_LOADING });
          const response = await request.getUsers();
          next({
            type: actionTypes.GET_USERS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_USERS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      
      case actionTypes.GET_USER:
        try {
          next({ type: actionTypes.GET_USER_LOADING });
          const response = await request.getUser({ ...action.payload });
          next({
            type: actionTypes.GET_USER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DELETE_USER:
        try {
          next({ type: actionTypes.DELETE_USER_LOADING });
          await request.deleteUser({ ...action.payload });
          next({
            type: actionTypes.DELETE_USER_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.DELETE_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.CREATE_USER:
        try {
          next({ type: actionTypes.CREATE_USER_LOADING });
          await request.createUser({
            ...action.payload,
          });
          next({
            type: actionTypes.CREATE_USER_SUCCESS,
          });
          store.dispatch(
            messageHandler.successMessage(
              'We have sent you a validation email. You need to validate your account in order to log in.'
            )
          );
        } catch (error) {
          next({
            type: actionTypes.CREATE_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_USER:
        try {
          next({ type: actionTypes.UPDATE_USER_LOADING });
          const response = await request.updateUser({
            ...action.payload,
          });
          next({
            type: actionTypes.UPDATE_USER_SUCCESS,
            payload: response.data.data,
          });
            store.dispatch(messageHandler.successMessage(''))
        } catch (error) {
          next({
            type: actionTypes.UPDATE_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.RESET_PASSWORD_USER:
        try {
          next({ type: actionTypes.RESET_PASSWORD_USER_LOADING });
          await request.resetPassword({
            ...action.payload,
          });
          next({
            type: actionTypes.RESET_PASSWORD_USER_SUCCESS,
          });
          store.dispatch(
            messageHandler.successMessage(
              'We have sent you a link by email that will allow you to change your password.'
            )
          );
        } catch (error) {
          next({
            type: actionTypes.RESET_PASSWORD_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.WORKMAIL_USER:
          try {
            next({ type: actionTypes.WORKMAIL_USER_LOADING });
            await request.workmailRenewal({
              ...action.payload,
            });
            next({
              type: actionTypes.WORKMAIL_USER_SUCCESS,
            });
            store.dispatch(
              messageHandler.successMessage(
                'WorkMail password reset: done !'
              )
            );
          } catch (error) {
            next({
              type: actionTypes.WORKMAIL_USER_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;

      case actionTypes.UPDATE_PASSWORD_USER:
        try {
          next({ type: actionTypes.UPDATE_PASSWORD_USER_LOADING });
          await request.updatePassword({
            ...action.payload,
          });
          next({
            type: actionTypes.UPDATE_PASSWORD_USER_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_PASSWORD_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_PASSWORD_BY_TOKEN_USER:
        try {
          next({ type: actionTypes.UPDATE_PASSWORD_BY_TOKEN_USER_LOADING });
          await request.updatePasswordWithToken({
            ...action.payload,
          });
          next({
            type: actionTypes.UPDATE_PASSWORD_BY_TOKEN_USER_SUCCESS,
          });
          store.dispatch(
            messageHandler.successMessage('your password has been changed')
          );
        } catch (error) {
          next({
            type: actionTypes.UPDATE_PASSWORD_BY_TOKEN_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
