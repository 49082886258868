import Oss from "views/Fiscal/Oss/";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.oss,
    component: Oss,
    exact: true,
    authorizedRoles: ["operator"]
  },
];
