import React, { useState, useEffect, useCallback } from 'react';
import { Segment, Divider, Container, List, Message } from 'semantic-ui-react';
import { Field } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import Dropdown from './Dropdown';
import Loader from 'components/Loader';
import Wrap from 'components/core/Wrapper/Wrapper';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import Wrapper from 'components/core/Wrapper/Wrapper';
import NewCompanyModal from 'views/Companies/New/NewCompanyModal/NewCompanyModal';

const Index = ({
  dispatch,
  setFieldValue,
  setFieldTouched,
  values,
  manageSteps,
  customsData,
  errors,
  touched,
  contacts,
  contact,
  companyId,
  buyerAddress,
  setBuyerAddress,
  loadingHandlerContact,
}: any) => {
  const [buyerList, setBuyerList]: any = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [vatList, setVatList] = useState([]);
  const [buyersData, setBuyersData] = useState([]);
  const [selectedBuyer, setSelectedBuyer] = useState(
    values.transport.shippingAgent.receiverId
  );
  const [selectedAddressId, setSelectedAddressId] = useState(
    values.transport.shippingAgent.receiverAddressId
  );
  const [selectedBuyerData, setSelectedBuyerData] = useState(
    values.transport.shippingAgent
  );
  const [createContactModal, setCreateContactModal] = useState(false);

  useEffect(() => {
    dispatch(
      actions.contact.getAllContacts({
        params: { companyId: companyId },
      })
    );

    return () => {
      dispatch(actions.contact.reset(['contacts', 'error']));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    contacts &&
      setBuyerList(
        contacts.map((contact, index) => {
          return {
            key: index,
            value: contact.id,
            text: contact.name,
          };
        })
      );
    contacts &&
      setBuyersData(
        contacts.map((contact) => {
          return {
            id: contact.id,
            name: contact.name,
            data: {
              country: contact.country,
              email: contact.email,
              phone: contact.phoneNumber || '',
            },
          };
        })
      );
  }, [contacts]);

  const manageIfBuyerIsFound = (buyer) => {
    setSelectedBuyerData(buyer.data);
    setFieldValue('transport.shippingAgent.receiverId', buyer.id);
    setFieldValue('transport.shippingAgent.receiverName', buyer.name);
  };

  const manageSelectedAddress = useCallback((selectedAddress) => {
    if (selectedAddress.length === undefined) {
      setFieldValue(
        'transport.shippingAgent.addressCountry',
        selectedAddress.country
      );
      setFieldValue(
        'transport.shippingAgent.addressCity',
        selectedAddress.city
      );
      setFieldValue(
        'transport.shippingAgent.address1',
        selectedAddress.address1
      );
      setBuyerAddress({
        country: selectedAddress.country,
        city: selectedAddress.city,
        address1: selectedAddress.address1,
        headquarters: selectedAddress.headquarters,
      });
    }
  }, [setFieldValue]);

  useEffect(() => {
    if (
      selectedBuyer !== '' &&
      values.transport.shippingAgent.receiverName !== selectedBuyer &&
      selectedBuyer !== null
    ) {
      dispatch(
        actions.contact.getOneContact({
          params: { companyId: companyId, contactId: selectedBuyer },
        })
      );
    }
    return () => {
      dispatch(actions.contact.reset(['contact', 'error']));
    };
  }, [dispatch, companyId, selectedBuyer, values.transport.shippingAgent.receiverName]);

  const manageAddressChange = useCallback((value) => {
    if (value === '') {
      setFieldValue('transport.shippingAgent.addressCountry', '');
      setFieldValue('transport.shippingAgent.addressCity', '');
      setFieldValue('transport.shippingAgent.address1', '');
      setSelectedAddressId('');
    } else {
      contact.Addresses.forEach((address) => {
        if (value === address.id) {
          setFieldValue(
            'transport.shippingAgent.addressCountry',
            address.country
          );
          setSelectedAddressId(value);
          manageSelectedAddress(address);
        }
      });
    }
  }, [setFieldValue, contact, manageSelectedAddress]);

  const manageVatChange = useCallback((value) => {
    if (value === '') {
      setFieldValue('transport.shippingAgent.vatCountry', '');
      setFieldValue('transport.shippingAgent.vat', '');
    } else {
      contact.Vats.forEach((vat) => {
        if (value === vat.id) {
          setFieldValue('transport.shippingAgent.vatCountry', vat.country);
          setFieldValue('transport.shippingAgent.vat', vat.vatNumber);
        }
      });
    }
  }, [setFieldValue, contact]);

  useEffect(() => {
    let vatsArr = [];
    let addressesArr = [];
    if (contact) {
      if (contact.Vats) {
        contact.Vats.forEach((vat, index) => {
          vatsArr.push({
            key: index,
            value: vat.id,
            text: vat.vatNumber,
            flag: vat.country.toLowerCase(),
          });
        });
        setVatList(vatsArr);
        if (vatsArr.length === 1) {
          setFieldValue('transport.shippingAgent.vatId', vatsArr[0].value);
          manageVatChange(vatsArr[0].value);
        } else if (
          !vatsArr.find(
            (vat) => vat.value === values.transport.shippingAgent.vatId
          )
        ) {
          setFieldValue('transport.shippingAgent.vatId', '');
          setFieldTouched('transport.shippingAgent.vatId', '');
          manageVatChange('');
        }
      }

      if (contact.Addresses) {
        contact.Addresses.forEach((address, index) => {
          addressesArr.push({
            key: index,
            value: address.id,
            text: address.country,
            flag: address.country.toLowerCase(),
          });
        });
        setAddressList(addressesArr);
        if (addressesArr.length === 1) {
          setFieldValue(
            'transport.shippingAgent.receiverAddressId',
            addressesArr[0].value
          );
          manageAddressChange(addressesArr[0].value);
        } else if (
          !addressesArr.find(
            (address) =>
              address.value === values.transport.shippingAgent.receiverAddressId
          )
        ) {
          setFieldValue('transport.shippingAgent.receiverAddressId', '');
          setFieldTouched('transport.shippingAgent.receiverAddressId', '');
          manageAddressChange('');
        }
      }
    }
  }, [
    contact, 
    setFieldValue, 
    setFieldTouched, 
    values.transport.shippingAgent.receiverAddressId, 
    values.transport.shippingAgent.vatId, 
    manageAddressChange, 
    manageVatChange]);

  useEffect(() => {
    selectedBuyer !== ''
      ? buyersData.forEach((buyer) => {
          buyer.id === selectedBuyer && manageIfBuyerIsFound(buyer);
        })
      : setSelectedBuyerData({});
  }, [selectedBuyer]);

  useEffect(() => {
    Object.entries(selectedBuyerData).map((data, key) => {
      switch (data[0]) {
        case 'company':
          setFieldValue('transport.shippingAgent.company', data[1]);
          break;
        case 'country':
          setFieldValue('transport.shippingAgent.country', data[1]);
          break;
        case 'address':
          setFieldValue('transport.shippingAgent.address', data[1]);
          break;
        case 'email':
          setFieldValue('transport.shippingAgent.email', data[1]);
          break;
        case 'phone':
          setFieldValue('transport.shippingAgent.phone', data[1]);
          break;
        case 'vat':
          setFieldValue('transport.shippingAgent.vat', data[1]);
          break;
      }
    });
  }, [selectedBuyerData]);

  const manageBuyerChange = (value) => {
    setSelectedBuyer(value);
    if (value === '') {
      setFieldValue('transport.shippingAgent.receiverName', '');
      setFieldValue('transport.shippingAgent.country', '');
      setFieldValue('transport.shippingAgent.email', '');
      setFieldValue('transport.shippingAgent.phone', '');
    }
    setFieldValue('transport.shippingAgent.receiverAddressId', null);
    setFieldValue('transport.shippingAgent.vatId', null);
    dispatch(actions.address.reset(['address', 'error']));
  };


  return (
    <Wrapper width='30%' margin='auto' minWidth='400px'>
      {createContactModal && (
        <NewCompanyModal
          open={createContactModal}
          handleClose={() => setCreateContactModal(false)}
          addContact
          companyId={companyId}
        />
      )}
      {(buyerList === null && <Loader />) || (
        <Segment
          {...(values.transport.shippingAgent.receiverName !== '' && {
            attached: true,
          })}
        >
          <Wrap display='flex'>
            <Button
              icon='plus'
              type='submit'
              content='Create'
              onClick={() => {
                setCreateContactModal(true);
              }}
            />
          </Wrap>
          <Field
            //label={<MandatoryFieldLabel content='Buyer' />}
            label='Buyer'
            placeholder='Choose a buyer'
            name='transport.shippingAgent.receiverId'
            options={buyerList}
            loading={loadingHandlerContact}
            component={Dropdown}
            meta={{ errors, touched, setFieldValue, setFieldTouched }}
            setterFunction={manageBuyerChange}
          />
        </Segment>
      )}
      {selectedBuyer !== null && selectedBuyer !== '' && (
        <Segment attached='bottom'>
          <Container textAlign='left'>
            <List>
              {values.transport.shippingAgent.country && (
                <List.Item>
                  <List.Content>
                    <b>Country: </b>
                    {values.transport.shippingAgent.country}
                  </List.Content>
                </List.Item>
              )}
              {values.transport.shippingAgent.email && (
                <List.Item>
                  <List.Content>
                    <b>Email: </b>
                    {values.transport.shippingAgent.email}
                  </List.Content>
                </List.Item>
              )}
              {values.transport.shippingAgent.phone && (
                <List.Item>
                  <List.Content>
                    <b>Phone number: </b>
                    {values.transport.shippingAgent.phone}
                  </List.Content>
                </List.Item>
              )}
            </List>
          </Container>
        </Segment>
      )}
      <Divider hidden />

      {contact !== null &&
        selectedBuyer !== null &&
        selectedBuyer !== '' &&
        contact.Vats !== undefined && (
          <Segment>
            <Field
              label={<MandatoryFieldLabel content="Buyer's VAT" />}
              placeholder="Choose the buyer's VAT"
              name='transport.shippingAgent.vatId'
              options={vatList}
              component={Dropdown}
              meta={{ errors, touched, setFieldValue, setFieldTouched }}
              setterFunction={manageVatChange}
            />
            {contact.Vats.length === 0 && (
              <Message error>The buyer doesn't have a VAT number.</Message>
            )}
          </Segment>
        )}
      <Divider hidden />
      {contact !== null &&
        selectedBuyer !== null &&
        selectedBuyer !== '' &&
        contact.Addresses !== null && (
          <>
            <Segment
              {...(selectedAddressId && {
                attached: true,
              })}
            >
              <Field
                label={<MandatoryFieldLabel content="Buyer's address" />}
                placeholder="Choose the buyer's address"
                name='transport.shippingAgent.receiverAddressId'
                options={addressList}
                component={Dropdown}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
                setterFunction={manageAddressChange}
              />
              {contact.Addresses.length === 0 && (
                <Message error>The buyer doesn't have address.</Message>
              )}
            </Segment>
            {selectedAddressId !== null && selectedAddressId && buyerAddress && (
              <Segment attached='bottom'>
                <Container textAlign='left'>
                  <List>
                    <List.Item>
                      <List.Content>
                        <b>Headquarters: </b>
                        {buyerAddress.headquarters === true ? 'Yes' : 'No'}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <b>City: </b>
                        {buyerAddress.city}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <b>Address 1: </b>
                        {buyerAddress.address1}
                      </List.Content>
                    </List.Item>
                  </List>
                </Container>
              </Segment>
            )}
          </>
        )}

      {values.transport.shippingAgent.vatCountry !== '' &&
        values.transport.shippingAgent.vatCountry !==
          values.deliveryInfos.arrivalCountry && (
          <Message warning>
            The buyer's VAT number doesn't match to the country of arrival.
          </Message>
        )}
      <Divider hidden />
      <Button
        type='button'
        size='large'
        icon='right arrow'
        labelPosition='right'
        color='blue'
        content='Next'
        margin='5px'
        onClick={() => manageSteps(4)}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: selectors.contact.contactsSelector(state),
    contact: selectors.contact.contactSelector(state),
    loadingHandlerContact: selectors.contact.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
