import styled from "@emotion/styled";
import { Dropdown } from "semantic-ui-react";

export const IntrastatDropdown = styled(Dropdown)(({ props }: any) => ({
    borderRadius: "10px !important",
    border: "1px solid rgba(34,36,38,.15)",
    marginLeft: "10px",
}))

export const Absolute = styled.div`
    position: absolute;
    top: 0px;
    left: 380px;
    `
