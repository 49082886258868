import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const agencyApi = routesApi.agency;
  return {
    create(data: dataReqI) {
      return api.request({
        method: agencyApi.create.method,
        route: agencyApi.create.path,
        data: data.data,
      });
    },
    getAll() {
      return api.request({
        method: agencyApi.getAll.method,
        route: agencyApi.getAll.path,
      });
    },
    getOne(data: dataReqI) {
      return api.request({
        method: agencyApi.getOne.method,
        route: agencyApi.getOne.path,
        params: data.params,
      });
    },
    remove(data: dataReqI) {
      return api.request({
        method: agencyApi.remove.method,
        route: agencyApi.remove.path,
        params: data.params,
      });
    },
    update(data: dataReqI) {
      return api.request({
        method: agencyApi.update.method,
        route: agencyApi.update.path,
        params: data.params,
        data: data.data,
      });
    },

  };
};
