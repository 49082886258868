class HTTPError {
  private status;
  private message;

  constructor(status, data) {
    this.status = status;
    this.message = data.message;
  }
}

export default HTTPError;
