import { ButtonWrapper } from 'components/Button/styles';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { ButtonStyled } from './linkButtonStyles';

type LinkedButtonProps = {
  color?: 'blue' | 'red';
  disabled?: boolean;
  type?: string;
  content: string;
  icon?: string;
  fluid?: boolean;
  labelPosition?: string;
  size?:
    | 'mini'
    | 'tiny'
    | 'small'
    | 'medium'
    | 'large'
    | 'big'
    | 'huge'
    | 'massive';
  margin?: string;
  float?: string;
  path: string;
};

const LinkButton: FunctionComponent<LinkedButtonProps> = ({
  color,
  disabled,
  type,
  content,
  icon,
  fluid,
  labelPosition,
  size,
  margin,
  path,
  float
}: any) => {
  return (
 
    <Link to={path}>
    <ButtonWrapper
      margin={margin}
      display='block'
      maxHeight='36px'
      float={float}
    >
      <ButtonStyled
        fluid={fluid}
        icon={icon}
        labelPosition={labelPosition}
        disabled={disabled}
        type={type || 'button'}
        btncolor={color}
        content={content}
        size={size}
        margin={margin}
      />
    </ButtonWrapper>
    </Link>

  );
};

export default LinkButton;
