import { resetState } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    postMsg: null,
    getAllMsgs: null,
    read: null,
  },
  errors: {
    postMsg: null,
    getAllMsgs: null,
    read: null,
  },
  loading: {
    postMsg: false,
    getAllMsgs: false,
    read: false,
  },
  postMsg: null,
  allMsgs: null,
  read: null,
  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

      // read chat
      case actionTypes.READ_CHAT_LOADING:
        return produce(state, (draft) => {
          draft.loading.read = true;
        });
      case actionTypes.READ_CHAT_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.read = null;
          draft.loading.read = false;
          draft.success.read = true;
          draft.read = action.payload;
        });
      case actionTypes.READ_CHAT_ERROR:
        return produce(state, (draft) => {
          draft.errors.read = true;
          draft.loading.read = false;
          draft.success.read = false;
          !draft.error.includes('read') &&
            draft.error.push('read');
        });

      // update debs
      case actionTypes.POST_CHATS_LOADING:
        return produce(state, draft => {
          draft.loading.postMsg = true;
        });
  
      case actionTypes.POST_CHATS_SUCCESS:
        return produce(state, draft => {
          draft.errors.postMsg = null;
          draft.loading.postMsg = false;
          draft.success.postMsg = true;
          draft.postMsg = action.payload;
        });
  
      case actionTypes.POST_CHATS_ERROR:
        return produce(state, draft => {
          draft.errors.postMsg = true;
          draft.loading.postMsg = false;
          draft.success.postMsg = false;
          !draft.error.includes("postMsg") && draft.error.push("postMsg");
        });

     // update debs
     case actionTypes.GET_CHATS_LOADING:
      return produce(state, draft => {
        draft.loading.getAllMsgs = true;
      });

    case actionTypes.GET_CHATS_SUCCESS:
      return produce(state, draft => {
        draft.errors.getAllMsgs = null;
        draft.loading.getAllMsgs = false;
        draft.success.getAllMsgs = true;
        draft.allMsgs = action.payload;
      });

    case actionTypes.GET_CHATS_ERROR:
      return produce(state, draft => {
        draft.errors.getAllMsgs = true;
        draft.loading.getAllMsgs = false;
        draft.success.getAllMsgs = false;
        !draft.error.includes("allMsgs") && draft.error.push("allMsgs");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
