import React, { FunctionComponent, useEffect, useState} from 'react';
import { actions, selectors } from 'store';
import { Field, Formik } from 'formik';
import { Header, Modal, Grid, Segment, Form } from 'semantic-ui-react';
import Dropdowns from 'components/Dropdown';
import Button from 'components/Button';
import { injectIntl } from 'react-intl';
import Spacer from 'components/core/Spacer/Spacer';
import { connect } from 'react-redux';
import { compose } from 'redux';


type ModalMailFollowersProps = {
  dispatch: (value: any) => void;
  companyId: number;
  customsDeclarationId: string;
  data: any[];
  open: boolean;
  handleClose: () => void;
  handleFollow: (values: any, companyUsers) => void;
  companyUsersLoading;
  companyUsers;
};

const MailFollowers: FunctionComponent<ModalMailFollowersProps> = ({
  dispatch,
  companyId,
  customsDeclarationId,
  open,
  data,
  handleClose,
  handleFollow,
  companyUsersLoading,
  companyUsers,
}) => {

  const [userList, setUserList] = useState([])
  const [uJsonbData, setJsonbData] = useState([])

  useEffect(() => {
    dispatch(
      actions.companyUser.getAllUsers({
        params: { companyId: companyId },
      })
    );
    return () => {
      dispatch(actions.companyUser.reset(['users', 'error']));
    };
  }, [dispatch, companyId])


  useEffect(() => {
    if(companyUsers){
      let tab = []
      companyUsers.forEach((user, index) => {
        tab.push({
          key: index,
          value: user.id,
          text: `${user.firstName} ${user.lastName}`
        })
      })
      setUserList(tab)
    }
    if(data){
      let tab = []
      data.forEach((user) => {
        tab.push(user.id)
      })
      setJsonbData(tab)
    }
  }, [companyUsers, data])

  
const segmentStyle = {
  margin: '0',
  paddingTop: '1.5rem',
};


  return (
    <Modal open={open} onClose={handleClose} closeIcon>
    <Segment style={segmentStyle}>
      <Header
        icon='plus circle'
        content='Send emails to followers'
      />
      <Formik
        initialValues={{
          mailFollowers: uJsonbData || '',
        }}
        enableReinitialize={true}
        onSubmit={(values) => handleFollow(values, companyUsers)}
        //validationSchema={newMailFollowers}
      
      >
      {({
         errors,
         touched,
         handleSubmit,
         setFieldValue,
         setFieldTouched,
         values,
         isSubmitting,
      }) => (
      <Modal.Content>
          <Form onSubmit={handleSubmit}>
          <Grid columns={1}>
            <Grid.Column>
              <Grid.Row>
              <Field
                label="Add Users to email notification list"
                name='mailFollowers'
                placeholder='Choose user'
                options={userList}
                fluid 
                multiple                   
                clearable
                selection
                component={Dropdowns}
                loading={companyUsersLoading}
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}             
              />
            </Grid.Row>       
            </Grid.Column>
          </Grid>
          <Spacer height='20px' />     
          <Button 
            disabled={isSubmitting}
            size='large' 
            type='submit' 
            content='Save' 
          />
       </Form>
     </Modal.Content>
   )}
  </Formik>
  </Segment>
  </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
  companyUsers: selectors.companyUser.usersSelector(state),
  companyUsersLoading: selectors.companyUser.getAllUsersLoadingSelector(state),
  }
};


const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(MailFollowers);
