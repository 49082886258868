export default {
  RESET: 'MY_ASD.INTERNAL_TOOLS.SSR.RESET',
  REFRESH_ERROR: 'MY_ASD.INTERNAL_TOOLS.SSR.REFRESH_ERROR',

  TICKETSSR: 'MY_ASD.INTERNAL_TOOLS.SSR.TICKETSSR',
  TICKETSSR_LOADING: 'MY_ASD.INTERNAL_TOOLS.SSR.TICKETSSR_LOADING',
  TICKETSSR_SUCCESS: 'MY_ASD.INTERNAL_TOOLS.SSR.TICKETSSR_SUCCESS',
  TICKETSSR_ERROR: 'MY_ASD.INTERNAL_TOOLS.SSR.TICKETSSR_ERROR',

  SEND_RESULTS: 'MY_ASD.INTERNAL_TOOLS.SSR.SEND_RESULTS',
  SEND_RESULTS_LOADING: 'MY_ASD.INTERNAL_TOOLS.SSR.SEND_RESULTS_LOADING',
  SEND_RESULTS_SUCCESS: 'MY_ASD.INTERNAL_TOOLS.SSR.SEND_RESULTS_SUCCESS',
  SEND_RESULTS_ERROR: 'MY_ASD.INTERNAL_TOOLS.SSR.SEND_RESULTS_ERROR',

  GET_FORM_SCHEMAS: 'MY_ASD.INTERNAL_TOOLS.SSR.GET_FORM_SCHEMAS',
  GET_FORM_SCHEMAS_LOADING:
    'MY_ASD.INTERNAL_TOOLS.SSR.GET_FORM_SCHEMAS_LOADING',
  GET_FORM_SCHEMAS_SUCCESS:
    'MY_ASD.INTERNAL_TOOLS.SSR.GET_FORM_SCHEMAS_SUCCESS',
  GET_FORM_SCHEMAS_ERROR: 'MY_ASD.INTERNAL_TOOLS.SSR.GET_FORM_SCHEMAS_ERROR',
};
