import { combineReducers } from 'redux';
import { actionTypes as appActionTypes } from './app';
import { actionTypes as authActionTypes } from './auth';
import { reducer as authReducer } from './auth';
import { reducer as dataReducer } from './data';
import { reducer as customsDeclarationReducer } from './customs/declaration';
import { reducer as customsStatisticsReducer } from './customs/statistiques';
import { reducer as customsClerkReducer } from './customs/clerk';
import { reducer as intrastatReducer } from './intrastat';
import { reducer as chatReducer } from './chat';
import { reducer as fiscalDeclarationReducer } from './fiscal/declaration';
import { reducer as fiscalOssReducer } from './fiscal/oss';
import { reducer as fiscalVatReducer } from './fiscal/vat';
import { reducer as quickfixesReducer } from './fiscal/quickFixes';
import { reducer as quickfixesTokenReducer } from './fiscal/quickFixes/token';
import { reducer as collectionboxReducer } from './fiscal/collectionBox';
import { reducer as socketReducer } from './socket';
import { reducer as userReducer } from './user';
import { reducer as serviceReducer } from './service';
import { reducer as adminReducer } from './admin';
import { reducer as companyReducer } from './company';
import { reducer as contactReducer } from './company/contact';
import { reducer as companyUserReducer } from './company/user';
import { reducer as companyDriverReducer } from './company/driver';
import { reducer as companyKeyInfoReducer } from './company/keyinfo';
import { reducer as companyClerksPricingReducer } from './company/clerksPricing';
import { reducer as companyIntrastatSettingsReducer } from './company/settings/intrastat';
import { reducer as companyCollectionBoxSettingsReducer } from './company/settings/collectionbox';
import { reducer as companyInvoiceReducer } from './company/invoice';
import { reducer as vatReducer } from './company/vat';
import { reducer as addressReducer } from './company/address';
import { reducer as companyPaymentReducer } from './company/payment';
import { reducer as companyByTokenReducer } from './company/token';
import { reducer as messageReducer } from './message';
import { reducer as agencyReducer } from './agencies';
import { reducer as paymentReducer } from './payment';
import { reducer as contactRequestReducer } from './contactRequest';
import { reducer as ssrRequestReducer } from './internalTools/ssrRequest';
import { reducer as commercialReducer } from './internalTools/commercial';
import { reducer as notificationReducer } from './notifications';
import { reducer as configurationsReducer } from './company/configurations';
import { reducer as operatorReducer } from './operators';
import { reducer as newsReducer } from './news';
import { reducer as statusReducer } from './status';

const reducers = combineReducers({
  auth: authReducer,
  customsDeclaration: customsDeclarationReducer,
  customsClerk: customsClerkReducer,
  customsStatistics: customsStatisticsReducer,
  intrastat: intrastatReducer,
  chat: chatReducer,
  fiscalOss: fiscalOssReducer,
  fiscalDeclaration: fiscalDeclarationReducer,
  fiscalVat: fiscalVatReducer,
  collectionbox: collectionboxReducer,
  quickfixes: quickfixesReducer,
  quickfixesToken: quickfixesTokenReducer,
  data: dataReducer,
  socket: socketReducer,
  user: userReducer,
  service: serviceReducer,
  admin: adminReducer,
  company: companyReducer,
  contact: contactReducer,
  companyUser: companyUserReducer,
  companyDriver: companyDriverReducer,
  companyKeyInfo: companyKeyInfoReducer,
  companyClerksPricing: companyClerksPricingReducer,
  companyIntrastatSettings: companyIntrastatSettingsReducer,
  companyCollectionBoxSettings: companyCollectionBoxSettingsReducer,
  companyInvoice: companyInvoiceReducer,
  vat: vatReducer,
  address: addressReducer,
  companyByToken: companyByTokenReducer,
  message: messageReducer,
  agency: agencyReducer,
  payment: paymentReducer,
  companyPayment: companyPaymentReducer,
  contactRequest: contactRequestReducer,
  ssrRequest: ssrRequestReducer,
  commercial: commercialReducer,
  notifications: notificationReducer,
  operators: operatorReducer,
  news: newsReducer,
  status: statusReducer,
  configurations: configurationsReducer,
});

export default (state, action) => {
  if (
    action.type === appActionTypes.RESET ||
    action.type.includes(authActionTypes.LOGOUT) ||
    action.type === authActionTypes.RESET
  ) {
    state = undefined;
  }
  return reducers(state, action);
};
