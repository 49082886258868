// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditText } from 'react-edit-text';

// Components
import Loader from 'components/Loader';
import AddressBlock from '../addressBlock/addressBlock';

// Interfaces
import UpdateAddressesCompanyDetailsProperties from '../../interfaces/updateAddressesCompanyDetailsProperties';

// Resources
import { currentLanguage } from '../../../../views';
import saveLogo from '../../assets/images/logos/save.png';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';

// Stylesheets
import './forms.css';

/**
 * @name updateAddressesCompanyDetails.tsx
 * @implements updateFormsProperties.tsx
 *
 * @path /components/updateForms/updateAddressesCompanyDetails.tsx
 *
 * @description Defines content for update addresses company details popup in CompanyInformation component
 *
 * @param ref popup content reference
 * @returns UpdateAddressesCompanyDetails component
 */
const UpdateAddressesCompanyDetails: React.FC<
  UpdateAddressesCompanyDetailsProperties
> = ({
  ref,
  deliveryId,
  defaultDeliveryMainAddress,
  defaultDeliveryAdditionalAddress,
  defaultDeliveryPostalCode,
  defaultDeliveryCity,
  defaultDeliveryCountry,
  billingId,
  defaultBillingMainAddress,
  defaultBillingAdditionalAddress,
  defaultBillingPostalCode,
  defaultBillingCity,
  defaultBillingCountry,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);

  const [deliveryMainAddress, setDeliveryMainAddress] = useState<string>(
    defaultDeliveryMainAddress
  );
  const [deliveryAdditionalAddress, setDeliveryAdditionalAddress] =
    useState<string>(defaultDeliveryAdditionalAddress);
  const [deliveryPostalCode, setDeliveryPostalCode] = useState<string>(
    defaultDeliveryPostalCode
  );
  const [deliveryCity, setDeliveryCity] = useState<string>(defaultDeliveryCity);

  const [billingMainAddress, setBillingMainAddress] = useState<string>(
    defaultBillingMainAddress
  );
  const [billingAdditionalAddress, setBillingAdditionalAddress] =
    useState<string>(defaultBillingAdditionalAddress);
  const [billingPostalCode, setBillingPostalCode] = useState<string>(
    defaultBillingPostalCode
  );
  const [billingCity, setBillingCity] = useState<string>(defaultBillingCity);

  const handleDeliveryMainAddressChange = (value: string) => {
    setDeliveryMainAddress(value);
  };

  const handleDeliveryAdditionalAddressChange = (value: string) => {
    setDeliveryAdditionalAddress(value);
  };

  const handleDeliveryPostalCodeChange = (value: string) => {
    setDeliveryPostalCode(value);
  };

  const handleDeliveryCityChange = (value: string) => {
    setDeliveryCity(value);
  };

  const handleBillingMainAddressChange = (value: string) => {
    setBillingMainAddress(value);
  };

  const handleBillingAdditionalAddressChange = (value: string) => {
    setBillingAdditionalAddress(value);
  };

  const handleBillingPostalCodeChange = (value: string) => {
    setBillingPostalCode(value);
  };

  const handleBillingCityChange = (value: string) => {
    setBillingCity(value);
  };

  // Create a state variable to store the selected option
  const [selectedOptionDeliveryCountry, setSelectedOptionDeliveryCountry] =
    useState<string>(defaultDeliveryCountry);
  const [deliveryCountries, setDeliveryCountries] = useState<any[]>([]);

  // Attach an event handler to update the selected option when the user makes a selection
  const handleSelectChangeDeliveryCountry = (value: string) => {
    setSelectedOptionDeliveryCountry(value);
  };

  // Create a state variable to store the selected option
  const [selectedOptionBillingCountry, setSelectedOptionBillingCountry] =
    useState<string>(defaultBillingCountry);
  const [billingCountries, setBillingCountries] = useState<any[]>([]);

  // Attach an event handler to update the selected option when the user makes a selection
  const handleSelectChangeBillingCountry = (value: string) => {
    setSelectedOptionBillingCountry(value);
  };

  useEffect(() => {
    if (currentLanguage == 'fr') {
      setDeliveryCountries(
        countriesFR.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
      setBillingCountries(
        countriesFR.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    } else {
      setDeliveryCountries(
        countriesEN.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
      setBillingCountries(
        countriesEN.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    }
  }, []);

  const updateAddress = async (
    id: number,
    mainAddress: string,
    additionalAddress: string,
    postalCode: string,
    city: string,
    country: string
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateAddress/companyAddress`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            addressId: id,
            addressMainAddress: mainAddress,
            addressAdditionalAddress: additionalAddress,
            addressPostalCode: postalCode,
            addressCity: city,
            addressCountry: country,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const execUpdateAddressForDelivery = async () => {
    setIsLoading(true);

    const result = await updateAddress(
      deliveryId,
      deliveryMainAddress,
      deliveryAdditionalAddress,
      deliveryPostalCode,
      deliveryCity,
      selectedOptionDeliveryCountry
    );

    return result;
  };

  const execUpdateAddressForBilling = async () => {
    setIsLoading(true);

    const result = await updateAddress(
      billingId,
      billingMainAddress,
      billingAdditionalAddress,
      billingPostalCode,
      billingCity,
      selectedOptionBillingCountry
    );

    return result;
  };

  const isEmpty = (string: string) => {
    return string.trim() === '';
  };

  const handleClickSave = () => {
    if (
      deliveryMainAddress == undefined ||
      deliveryPostalCode == undefined ||
      deliveryCity == undefined ||
      selectedOptionDeliveryCountry == undefined ||
      isEmpty(deliveryMainAddress) ||
      isEmpty(deliveryPostalCode) ||
      isEmpty(deliveryCity) ||
      isEmpty(selectedOptionDeliveryCountry) ||
      billingMainAddress == undefined ||
      billingPostalCode == undefined ||
      billingCity == undefined ||
      selectedOptionBillingCountry == undefined ||
      isEmpty(billingMainAddress) ||
      isEmpty(billingPostalCode) ||
      isEmpty(billingCity) ||
      isEmpty(selectedOptionBillingCountry)
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execUpdateAddressForDelivery();
      execUpdateAddressForBilling();
      setIsLoading(false);
      window.location.reload();
    }
  };

  return (
    <>
      {(isLoading && <Loader />) ||
        (!isLoading && (
          <>
            <div className='companyPopupContainer' ref={ref}>
              <AddressBlock
                divClassName='updateAddresses'
                title={t('companyPageStrings.deliveryTitle')}
                initialMainAddress={deliveryMainAddress}
                initialAdditionalAddress={deliveryAdditionalAddress}
                initialPostalCode={deliveryPostalCode}
                initialCity={deliveryCity}
                initialCountry={selectedOptionDeliveryCountry}
                onSaveMainAddress={handleDeliveryMainAddressChange}
                onSaveAdditionalAddress={handleDeliveryAdditionalAddressChange}
                onSavePostalCode={handleDeliveryPostalCodeChange}
                onSaveCity={handleDeliveryCityChange}
                onSaveCountry={handleSelectChangeDeliveryCountry}
              />
              <AddressBlock
                divClassName='updateAddresses'
                title={t('companyPageStrings.billingTitle')}
                initialMainAddress={billingMainAddress}
                initialAdditionalAddress={billingAdditionalAddress}
                initialPostalCode={billingPostalCode}
                initialCity={billingCity}
                initialCountry={selectedOptionBillingCountry}
                onSaveMainAddress={handleBillingMainAddressChange}
                onSaveAdditionalAddress={handleBillingAdditionalAddressChange}
                onSavePostalCode={handleBillingPostalCodeChange}
                onSaveCity={handleBillingCityChange}
                onSaveCountry={handleSelectChangeBillingCountry}
              />
            </div>
            <div>
              <div className='space'></div>
              {isErrorHidden ? (
                <></>
              ) : (
                <div className='companyError'>
                  <p>{t('orderPageStrings.error')}</p>
                </div>
              )}
              <div className='saveButtonContainer'>
                <button className='saveButton' onClick={handleClickSave}>
                  <p>{t('popupStrings.saveText')}</p>
                  <img
                    src={saveLogo}
                    alt={t('popupStrings.saveLogo')}
                    className='saveLogo'
                  />
                </button>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default UpdateAddressesCompanyDetails;
