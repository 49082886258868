import React, { FunctionComponent, useEffect, useState } from 'react';
import { actions } from 'store';
import { Modal } from 'semantic-ui-react';
import theme from 'theme';

import Wrapper from 'components/core/Wrapper/Wrapper';
import { Step } from 'components/core';
import Button from 'components/Button';
import ModalAddChed from '../ModalAddChed/ModalAddChed';
import ButtonValidationModal from 'components/ButtonValidationModal';
import ClickableText from 'components/ClickableText';
import { chedI, consI } from 'interfaces/data/customsClerkInterfaces';
import ModalAddConsignment from '../ModalAddConsignment/ModalAddConsignment';


type ModalChedDetailsProps = {
  chedDetails: chedI;
  handleClose: () => void;
  dispatch: any;
  companyId: number;
  isCompleted: boolean;
  truckLicensePlate: string;
  clerkId?: number;
  destination?: string;
};

const ModalChedDetails: FunctionComponent<ModalChedDetailsProps> = ({
  handleClose,
  chedDetails,
  truckLicensePlate,
  clerkId,
  companyId,
  dispatch,
  isCompleted,
  destination
}) => {
  const colors = theme.getTheme().colors;

  const [modalEdit, setmodalEdit] = useState(false);
  const [consignmentEdit, setmodalConsignmentEdit] = useState(false);
  const [consignment, setConsignment] = useState(null);

  useEffect(() => {
    
    chedDetails && 
    chedDetails.Consignments && 
    setConsignment(chedDetails.Consignments)

  }, [chedDetails])

  const downloadChed = () => {
    dispatch(
      actions.customsDeclaration.getOneChed({
        params: {
          companyId,
          declarationId: chedDetails.CustomsDeclaration_Id,
          chedId: chedDetails.id,
        },
      })
    );
  };

  const handleDeleteChed = () => {
    dispatch(
      actions.customsDeclaration.removeChed({
        params: {
          declarationId: chedDetails.CustomsDeclaration_Id,
          companyId,
          chedId: chedDetails.id,
        },
      })
    );

    handleClose();
  };

  const handleSubmitChed = (values: chedI) => {
    dispatch(
      actions.customsDeclaration.updateChedData({
        params: {
          companyId,
          declarationId: chedDetails.CustomsDeclaration_Id,
          chedId: chedDetails.id,
        },
        data: values,
      })
    );
    setmodalEdit(false);
    handleClose();
  };

  const handleSubmitConsignment = (values: consI) => {
    if(consignment.length>0){
      dispatch(
        actions.customsDeclaration.updateConsignmentData({
          params: {
            companyId,
            declarationId: chedDetails.CustomsDeclaration_Id,
            chedId: chedDetails.id,
          },
          data: {
            consignmentArray: values.consignmentArray,
            startedAt: consignment.startedAt,
            destination: destination,
            truckLicensePlate: truckLicensePlate,
            clerkId: clerkId,
          },
        })
      ); 
    }else{
      dispatch(
        actions.customsDeclaration.addConsignmentData({
          params: {
            companyId,
            declarationId: chedDetails.CustomsDeclaration_Id,
            chedId: chedDetails.id,
          },
          data: {
            consignmentArray: values.consignmentArray,
            destination: destination,
            truckLicensePlate: truckLicensePlate,
            clerkId: clerkId
          },
        })
      );
    }
   setmodalEdit(false);
   handleClose();
  };

  const deleteConsignment = () => {
    dispatch(
      actions.customsDeclaration.deleteConsignmentData({
        params: {
          companyId,
          declarationId: chedDetails.CustomsDeclaration_Id,
          consignmentId: consignment[0].Ched_Id
        },
      })
    );
    handleClose();
  }


  return (
    <Modal
      open={chedDetails ? true : false}
      onClose={handleClose}
      closeIcon
      size='small'
    >
      {modalEdit && (
        <ModalAddChed
          open={modalEdit}
          handleClose={() => setmodalEdit(false)}
          handleAddChed={handleSubmitChed}
          chedData={chedDetails}
        />
      )}
      {consignmentEdit &&  (
      <ModalAddConsignment
         open={consignmentEdit}
         handleClose={() => setmodalConsignmentEdit(false)}
         handleConsignment={handleSubmitConsignment}
         chedData={chedDetails}
       />
      )}
      <Modal.Header>
        <Wrapper
          display='flex'
          justifyContent='space-between'
          alignItems='baseline'
        >
          <ClickableText
            onClick={() => {
              window.open(
                `https://webgate.ec.europa.eu/tracesnt/certificate/ched/search#!?query=${chedDetails.number}&sort=-lastUpdateOn`,
                '_blank'
              );
            }}
          >
            CHED N° {chedDetails.number}
          </ClickableText>
          <Step
            label={chedDetails.state}
            color={
              chedDetails.state === 'validated'
                ? colors.validatedStep
                : chedDetails.state === 'new'
                ? colors.finishedStep
                : colors.inProgressStep
            }
          />
        </Wrapper>
      </Modal.Header>
      <Modal.Content>
        <Wrapper
          display='flex'
          justifyContent='space-between'
          alignItems='baseline'
        >
          <Wrapper display='flex'>
            {chedDetails.veto && <Step width='80px' label='Veto' />}
            {chedDetails.phyto && <Step width='80px' label='Phyto' />}
          </Wrapper>
          <p>
            Tax:{' '}
            {chedDetails?.taxAmount ? `${chedDetails.taxAmount} €` : 'Unknow'}
          </p>
        </Wrapper>
        <Wrapper
          display='flex'
          justifyContent='space-between'
          alignItems='baseline'
        >
          <p>
            Product: {chedDetails.productNames} - {chedDetails.productType}
          </p>
          <p>Weight: {chedDetails.weight}</p>
        </Wrapper>
        <Wrapper textAlign='center'>
          <p>Add at: {chedDetails.createdAt}</p>
          {chedDetails.validatedAt && (
            <p>Validated at: {chedDetails.validatedAt}</p>
          )}
        </Wrapper>
      </Modal.Content>
      <Modal.Actions>
        <Wrapper display='flex' justifyContent='center'>
          {!isCompleted && (
            <Button
              icon='edit'
              type='button'
              color='blue'
              content='Edit'
              onClick={() => setmodalEdit(true)}
            />
          )}
          <Button
            icon='download'
            type='button'
            color='blue'
            content='Download'
            onClick={downloadChed}
          />
          {!isCompleted && (
            <ButtonValidationModal
              trgrIcon='cancel'
              noSubmit
              trgrContent='Delete'
              headerContent='Delete'
              modalContent='Do you confirm the deletion of this ched?'
              trgrColor='red'
              onClick={handleDeleteChed}
            />
          )}
           {!isCompleted && (
            <Button
              icon='edit'
              type='button'
              color='blue'
              content='Consignment'
              onClick={() => setmodalConsignmentEdit(true)}
            />
          )}
           {!isCompleted && consignment && consignment.length>0 && (
            <Button
              icon='delete'
              type='button'
              color='red'
              content='Delete consignment'
              onClick={() => deleteConsignment()}
            />
          )}
        </Wrapper>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalChedDetails;
