import actionTypes from 'store/fiscal/quickFixes/token/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.GET_DELIVERY_BY_TOKEN:
        try {
          next({ type: actionTypes.GET_DELIVERY_BY_TOKEN_LOADING });
          const response = await request.getDeliveryByToken({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_DELIVERY_BY_TOKEN_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_DELIVERY_BY_TOKEN_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.TRANSPORTER_SELECTION:
        try {
          next({ type: actionTypes.TRANSPORTER_SELECTION_LOADING });
          await request.transporterSelection({ ...action.payload });
          next({
            type: actionTypes.TRANSPORTER_SELECTION_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.TRANSPORTER_SELECTION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPLOAD_PROOF:
        try {
          next({ type: actionTypes.UPLOAD_PROOF_LOADING });
          await request.uploadProof({
            ...action.payload,
          });
          next({
            type: actionTypes.UPLOAD_PROOF_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPLOAD_PROOF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ONE_PROOF:
        try {
          next({ type: actionTypes.GET_ONE_PROOF_LOADING });
          const response = await request.getOneProof({
            ...action.payload,
          });

          const file = new Blob([response.data], {
            type: response.data.type,
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          next({
            type: actionTypes.GET_ONE_PROOF_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_PROOF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DOWNLOAD_ATTESTATION:
        try {
          next({ type: actionTypes.DOWNLOAD_ATTESTATION_LOADING });
          const response = await request.downloadAttestation({
            ...action.payload,
          });
          saveAs(
            response.data,
            response.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.DOWNLOAD_ATTESTATION_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_ATTESTATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE:
        try {
          next({ type: actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_LOADING });
          const response = await request.downloadCustomsRegimeInvoice({
            ...action.payload,
          });

          const file = new Blob([response.data], {
            type: response.data.type,
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          next({
            type: actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
