import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

export const Wrapper = styled.div(({ labelPosition, labelVerticalPosition, margin, justifyContent }: any) => ({
  alignItems: (labelVerticalPosition === "center") ? "center" : "unset",
  flexDirection: (labelPosition === "left") ? "row" : "column",
  margin: margin,
  justifyContent: justifyContent
}));



export const Input = styled(Form.Input)`
  &&& {
    margin: 0;
    text-align: center;
    width: 100%;
  }
  &&& div.disabled {
    opacity: 0.6;
  }
  &&& div input {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
  } 
  &&& div input:focus {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
  }
  .input{
    width: 80%;
  }
  &&&.disabled {
    opacity: 1;
  }
`;
