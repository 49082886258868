import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    lock: null,
    unlock: null,
    createFile: null,
    getIssue: null,
    downloadPicturesIssuesZIP: null,
    getSummary: null,
    getAllFiles: null,
    getAllFilesByFilters: null,
    getDeclarationInProgress: null,
    getOneFile: null,
    getStartSignature: null,
    getTrailerLicensePlate: null,
    addTrailerLicensePlate: null,
    removeTrailerLicensePlate: null,
    getPaymentReceipt: null,
    getEndSignature: null,
    updateFile: null,
    updateIssue: null,
    addIssuePictures: null,
    deleteIssuePicture: null,
    getBillings: null,
    getFileHasBillings: null,
    addInvoicingLine: null,
    updateInvoicingLine: null,
    removeInvoicingLine: null,
    validateFile: null,
    updateInvoicingStep: null,
    getIssueTimes: null,
    addIssueTime: null,
    updateIssueTime: null,
    removeIssueTime: null,
    validateFileState: null,
    getpdfFile: null,
    createClerkControlPdfFile: null,
    cdBillings: null,
    pochette: null,
  },
  errors: {
    lock: null,
    unlock: null,
    createFile: null,
    getIssue: null,
    downloadPicturesIssuesZIP: null,
    getSummary: null,
    getAllFiles: null,
    getAllFilesByFilters: null,
    getDeclarationInProgress: null,
    getOneFile: null,
    getStartSignature: null,
    getTrailerLicensePlate: null,
    addTrailerLicensePlate: null,
    removeTrailerLicensePlate: null,
    getPaymentReceipt: null,
    getEndSignature: null,
    updateFile: null,
    updateIssue: null,
    addIssuePictures: null,
    deleteIssuePicture: null,
    getBillings: null,
    getFileHasBillings: null,
    addInvoicingLine: null,
    updateInvoicingLine: null,
    removeInvoicingLine: null,
    validateFile: null,
    updateInvoicingStep: null,
    getIssueTimes: null,
    addIssueTime: null,
    updateIssueTime: null,
    removeIssueTime: null,
    validateFileState: null,
    getpdfFile: null,
    createClerkControlPdfFile: null,
    cdBillings: null,
    pochette: null,
  },
  loading: {
    lock: false,
    unlock: false,
    createFile: false,
    getIssue: false,
    downloadPicturesIssuesZIP: false,
    getSummary: false,
    getAllFiles: false,
    getAllFilesByFilters: false,
    getDeclarationInProgress: false,
    getOneFile: false,
    getStartSignature: false,
    getTrailerLicensePlate: false,
    addTrailerLicensePlate: false,
    removeTrailerLicensePlate: false,
    getPaymentReceipt: false,
    getEndSignature: false,
    updateFile: false,
    updateIssue: false,
    addIssuePictures: false,
    deleteIssuePicture: false,
    getBillings: false,
    getFileHasBillings: false,
    addInvoicingLine: false,
    updateInvoicingLine: false,
    removeInvoicingLine: false,
    validateFile: false,
    updateInvoicingStep: false,
    getIssueTimes: false,
    addIssueTime: false,
    updateIssueTime: false,
    removeIssueTime: false,
    validateFileState: false,
    getpdfFile: false,
    createClerkControlPdfFile: false,
    cdBillings: false,
    pochette: false,
  },

  oneFile: null,
  createdFileId: null,
  fileDocuments: null,

  startSignature: null,
  trailerLicensePlate: null,
  paymentReceipt: null,
  endSignature: null,

  declarationInProgress: null,
  summary: null,
  invoice: null,
  allFiles: null,
  allFilesCount: null,
  allFilesByFilters: null,
  allFilesByFiltersCount: null,
  filesPictures: null,
  picturesIssuesZip: null,

  issue: null,
  issueTimes: null,
  billings: null,
  fileHasBillings: null,
  getpdfFile: null,
  clerkControlPdfFile: null,
  cdBillings: null,
  pochette: null,

  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

     // pochettes
     case actionTypes.CREATE_POCHETTE_PDF_LOADING:
      return produce(state, (draft) => {
        draft.loading.pochette = true;
      });
    case actionTypes.CREATE_POCHETTE_PDF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.pochette = null;
        draft.loading.pochette = false;
        draft.success.pochette = true;
        draft.pochette = action.payload;
      });
    case actionTypes.CREATE_POCHETTE_PDF_ERROR:
      return produce(state, (draft) => {
        draft.errors.pochette = true;
        draft.loading.pochette = false;
        draft.success.pochette = false;
      });


    // lock
    case actionTypes.LOCK_LOADING:
      return produce(state, (draft) => {
        draft.loading.lock = true;
      });
    case actionTypes.LOCK_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.lock = null;
        draft.loading.lock = false;
        draft.success.lock = true;
      });
    case actionTypes.LOCK_ERROR:
      return produce(state, (draft) => {
        draft.errors.lock = true;
        draft.loading.lock = false;
        draft.success.lock = false;
      });

    // unlock
    case actionTypes.UNLOCK_LOADING:
      return produce(state, (draft) => {
        draft.loading.unlock = true;
      });
    case actionTypes.UNLOCK_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unlock = null;
        draft.loading.unlock = false;
        draft.success.unlock = true;
      });
    case actionTypes.UNLOCK_ERROR:
      return produce(state, (draft) => {
        draft.errors.unlock = true;
        draft.loading.unlock = false;
        draft.success.unlock = false;
      });

    // createFile
    case actionTypes.CREATE_FILE_LOADING:
      return produce(state, (draft) => {
        draft.loading.createFile = true;
      });
    case actionTypes.CREATE_FILE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createFile = null;
        draft.loading.createFile = false;
        draft.success.createFile = true;
        draft.createdFileId = action.payload;
      });
    case actionTypes.CREATE_FILE_ERROR:
      return produce(state, (draft) => {
        draft.errors.createFile = true;
        draft.loading.createFile = false;
        draft.success.createFile = false;
      });

    // updateFile
    case actionTypes.UPDATE_FILE_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateFile = true;
      });
    case actionTypes.UPDATE_FILE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateFile = null;
        draft.loading.updateFile = false;
        draft.success.updateFile = true;
      });
    case actionTypes.UPDATE_FILE_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateFile = true;
        draft.loading.updateFile = false;
        draft.success.updateFile = false;
      });


    // getStartSignature
    case actionTypes.GET_START_SIGNATURE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getStartSignature = true;
      });
    case actionTypes.GET_START_SIGNATURE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getStartSignature = null;
        draft.loading.getStartSignature = false;
        draft.success.getStartSignature = true;
        draft.startSignature = action.payload;
      });
    case actionTypes.GET_START_SIGNATURE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getStartSignature = true;
        draft.loading.getStartSignature = false;
        draft.success.getStartSignature = false;
        !draft.error.includes('startSignature') &&
          draft.error.push('startSignature');
      });

    // getTrailerLicensePlate
    case actionTypes.GET_TRAILER_LICENSE_PLATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getTrailerLicensePlate = true;
      });
    case actionTypes.GET_TRAILER_LICENSE_PLATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getTrailerLicensePlate = null;
        draft.loading.getTrailerLicensePlate = false;
        draft.success.getTrailerLicensePlate = true;
        draft.trailerLicensePlate = action.payload;
      });
    case actionTypes.GET_TRAILER_LICENSE_PLATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getTrailerLicensePlate = true;
        draft.loading.getTrailerLicensePlate = false;
        draft.success.getTrailerLicensePlate = false;
        !draft.error.includes('trailerLicensePlate') &&
          draft.error.push('trailerLicensePlate');
      });

    // addTrailerLicensePlate
    case actionTypes.ADD_TRAILER_LICENSE_PLATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.addTrailerLicensePlate = true;
      });
    case actionTypes.ADD_TRAILER_LICENSE_PLATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addTrailerLicensePlate = null;
        draft.loading.addTrailerLicensePlate = false;
        draft.success.addTrailerLicensePlate = true;
      });
    case actionTypes.ADD_TRAILER_LICENSE_PLATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.addTrailerLicensePlate = true;
        draft.loading.addTrailerLicensePlate = false;
        draft.success.addTrailerLicensePlate = false;
      });

    // removeTrailerLicensePlate
    case actionTypes.DELETE_TRAILER_LICENSE_PLATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.removeTrailerLicensePlate = true;
      });
    case actionTypes.DELETE_TRAILER_LICENSE_PLATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removeTrailerLicensePlate = null;
        draft.loading.removeTrailerLicensePlate = false;
        draft.success.removeTrailerLicensePlate = true;
      });
    case actionTypes.DELETE_TRAILER_LICENSE_PLATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.removeTrailerLicensePlate = true;
        draft.loading.removeTrailerLicensePlate = false;
        draft.success.removeTrailerLicensePlate = false;
      });

    // getPaymentReceipt
    case actionTypes.GET_PAYMENT_RECEIPT_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPaymentReceipt = true;
      });
    case actionTypes.GET_PAYMENT_RECEIPT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPaymentReceipt = null;
        draft.loading.getPaymentReceipt = false;
        draft.success.getPaymentReceipt = true;
        draft.paymentReceipt = action.payload;
      });
    case actionTypes.GET_PAYMENT_RECEIPT_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPaymentReceipt = true;
        draft.loading.getPaymentReceipt = false;
        draft.success.getPaymentReceipt = false;
        !draft.error.includes('paymentReceipt') &&
          draft.error.push('paymentReceipt');
      });
      
    // getEndSignature
    case actionTypes.GET_END_SIGNATURE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getEndSignature = true;
      });
    case actionTypes.GET_END_SIGNATURE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getEndSignature = null;
        draft.loading.getEndSignature = false;
        draft.success.getEndSignature = true;
        draft.endSignature = action.payload;
      });
    case actionTypes.GET_END_SIGNATURE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getEndSignature = true;
        draft.loading.getEndSignature = false;
        draft.success.getEndSignature = false;
        !draft.error.includes('endSignature') &&
          draft.error.push('endSignature');
      });

    // getDeclarationInProgress
    case actionTypes.GET_DECLARATION_IN_PROGRESS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getDeclarationInProgress = true;
      });
    case actionTypes.GET_DECLARATION_IN_PROGRESS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getDeclarationInProgress = null;
        draft.loading.getDeclarationInProgress = false;
        draft.success.getDeclarationInProgress = true;
        draft.declarationInProgress = action.payload;
      });
    case actionTypes.GET_DECLARATION_IN_PROGRESS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getDeclarationInProgress = true;
        draft.loading.getDeclarationInProgress = false;
        draft.success.getDeclarationInProgress = false;
        !draft.error.includes('declarationInProgress') &&
          draft.error.push('declarationInProgress');
      });

    // getIssueTimes
    case actionTypes.GET_ISSUE_TIMES_LOADING:
      return produce(state, (draft) => {
        draft.loading.getIssueTimes = true;
      });
    case actionTypes.GET_ISSUE_TIMES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getIssueTimes = null;
        draft.loading.getIssueTimes = false;
        draft.success.getIssueTimes = true;
        draft.issueTimes = action.payload;
      });
    case actionTypes.GET_ISSUE_TIMES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getIssueTimes = true;
        draft.loading.getIssueTimes = false;
        draft.success.getIssueTimes = false;
        !draft.error.includes('issueTimes') && draft.error.push('issueTimes');
      });

    // addIssueTime
    case actionTypes.ADD_ISSUE_TIME_LOADING:
      return produce(state, (draft) => {
        draft.loading.addIssueTime = true;
      });
    case actionTypes.ADD_ISSUE_TIME_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addIssueTime = null;
        draft.loading.addIssueTime = false;
        draft.success.addIssueTime = true;
      });
    case actionTypes.ADD_ISSUE_TIME_ERROR:
      return produce(state, (draft) => {
        draft.errors.addIssueTime = true;
        draft.loading.addIssueTime = false;
        draft.success.addIssueTime = false;
      });

    // updateIssueTime
    case actionTypes.UPDATE_ISSUE_TIME_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateIssueTime = true;
      });
    case actionTypes.UPDATE_ISSUE_TIME_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateIssueTime = null;
        draft.loading.updateIssueTime = false;
        draft.success.updateIssueTime = true;
      });
    case actionTypes.UPDATE_ISSUE_TIME_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateIssueTime = true;
        draft.loading.updateIssueTime = false;
        draft.success.updateIssueTime = false;
      });

    // removeIssueTime
    case actionTypes.DELETE_ISSUE_TIME_LOADING:
      return produce(state, (draft) => {
        draft.loading.removeIssueTime = true;
      });
    case actionTypes.DELETE_ISSUE_TIME_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removeIssueTime = null;
        draft.loading.removeIssueTime = false;
        draft.success.removeIssueTime = true;
      });
    case actionTypes.DELETE_ISSUE_TIME_ERROR:
      return produce(state, (draft) => {
        draft.errors.removeIssueTime = true;
        draft.loading.removeIssueTime = false;
        draft.success.removeIssueTime = false;
      });

    // getIssue
    case actionTypes.GET_ISSUE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getIssue = true;
      });
    case actionTypes.GET_ISSUE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getIssue = null;
        draft.loading.getIssue = false;
        draft.success.getIssue = true;
        draft.issue = action.payload;
      });
    case actionTypes.GET_ISSUE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getIssue = true;
        draft.loading.getIssue = false;
        draft.success.getIssue = false;
        !draft.error.includes('issue') && draft.error.push('issue');
      });

    // downloadPicturesIssuesZIP
    case actionTypes.DOWNLOAD_PICTURES_ISSUES_ZIP_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadPicturesIssuesZIP = true;
      });
    case actionTypes.DOWNLOAD_PICTURES_ISSUES_ZIP_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadPicturesIssuesZIP = null;
        draft.loading.downloadPicturesIssuesZIP = false;
        draft.success.downloadPicturesIssuesZIP = true;
        draft.picturesIssuesZip = action.payload;
      });
    case actionTypes.DOWNLOAD_PICTURES_ISSUES_ZIP_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadPicturesIssuesZIP = true;
        draft.loading.downloadPicturesIssuesZIP = false;
        draft.success.downloadPicturesIssuesZIP = false;
        !draft.error.includes('picturesIssuesZip') &&
          draft.error.push('picturesIssuesZip');
      });

    // getSummary
    case actionTypes.GET_SUMMARY_LOADING:
      return produce(state, (draft) => {
        draft.loading.getSummary = true;
      });
    case actionTypes.GET_SUMMARY_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getSummary = null;
        draft.loading.getSummary = false;
        draft.success.getSummary = true;
        draft.summary = action.payload;
      });
    case actionTypes.GET_SUMMARY_ERROR:
      return produce(state, (draft) => {
        draft.errors.getSummary = true;
        draft.loading.getSummary = false;
        draft.success.getSummary = false;
        !draft.error.includes('summary') && draft.error.push('summary');
      });

    // getAllFiles
    case actionTypes.GET_ALL_FILES_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllFiles = true;
      });
    case actionTypes.GET_ALL_FILES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllFiles = null;
        draft.loading.getAllFiles = false;
        draft.success.getAllFiles = true;
        draft.allFiles = action.payload.rows;
        draft.allFilesCount = action.payload.count;
      });
    case actionTypes.GET_ALL_FILES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllFiles = true;
        draft.loading.getAllFiles = false;
        draft.success.getAllFiles = false;
        !draft.error.includes('allFiles') && draft.error.push('allFiles');
      });

    // getAllFilesByFilters
    case actionTypes.GET_ALL_FILES_BY_FILTERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllFilesByFilters = true;
      });
    case actionTypes.GET_ALL_FILES_BY_FILTERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllFilesByFilters = null;
        draft.loading.getAllFilesByFilters = false;
        draft.success.getAllFilesByFilters = true;
        draft.allFilesByFilters = action.payload.rows;
        draft.allFilesByFiltersCount = action.payload.count;
      });
    case actionTypes.GET_ALL_FILES_BY_FILTERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllFilesByFilters = true;
        draft.loading.getAllFilesByFilters = false;
        draft.success.getAllFilesByFilters = false;
        !draft.error.includes('allFilesByFilters') &&
          draft.error.push('allFilesByFilters');
      });

    // updateIssue
    case actionTypes.UPDATE_ISSUE_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateIssue = true;
      });
    case actionTypes.UPDATE_ISSUE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateIssue = null;
        draft.loading.updateIssue = false;
        draft.success.updateIssue = true;
      });
    case actionTypes.UPDATE_ISSUE_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateIssue = true;
        draft.loading.updateIssue = false;
        draft.success.updateIssue = false;
      });

    // addIssuePictures
    case actionTypes.ADD_ISSUE_PICTURES_LOADING:
      return produce(state, (draft) => {
        draft.loading.addIssuePictures = true;
      });
    case actionTypes.ADD_ISSUE_PICTURES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addIssuePictures = null;
        draft.loading.addIssuePictures = false;
        draft.success.addIssuePictures = true;
      });
    case actionTypes.ADD_ISSUE_PICTURES_ERROR:
      return produce(state, (draft) => {
        draft.errors.addIssuePictures = true;
        draft.loading.addIssuePictures = false;
        draft.success.addIssuePictures = false;
      });

    // deleteIssuePicture
    case actionTypes.DELETE_ISSUE_PICTURE_LOADING:
      return produce(state, (draft) => {
        draft.loading.deleteIssuePicture = true;
      });
    case actionTypes.DELETE_ISSUE_PICTURE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteIssuePicture = null;
        draft.loading.deleteIssuePicture = false;
        draft.success.deleteIssuePicture = true;
      });
    case actionTypes.DELETE_ISSUE_PICTURE_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteIssuePicture = true;
        draft.loading.deleteIssuePicture = false;
        draft.success.deleteIssuePicture = false;
      });

    // getBillings
    case actionTypes.GET_BILLINGS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getBillings = true;
      });
    case actionTypes.GET_BILLINGS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getBillings = null;
        draft.loading.getBillings = false;
        draft.success.getBillings = true;
        draft.billings = action.payload;
      });
    case actionTypes.GET_BILLINGS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getBillings = true;
        draft.loading.getBillings = false;
        draft.success.getBillings = false;
        !draft.error.includes('billings') && draft.error.push('billings');
      });

    // getFileHasBillings
    case actionTypes.GET_FILE_HAS_BILLINGS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getFileHasBillings = true;
      });
    case actionTypes.GET_FILE_HAS_BILLINGS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getFileHasBillings = null;
        draft.loading.getFileHasBillings = false;
        draft.success.getFileHasBillings = true;
        draft.fileHasBillings = action.payload;
      });
    case actionTypes.GET_FILE_HAS_BILLINGS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getFileHasBillings = true;
        draft.loading.getFileHasBillings = false;
        draft.success.getFileHasBillings = false;
        !draft.error.includes('fileHasBillings') &&
          draft.error.push('fileHasBillings');
      });

      // getCDBillings
      case actionTypes.GET_CD_HAS_BILLINGS_LOADING:
        return produce(state, (draft) => {
          draft.loading.cdBillings = true;
        });
      case actionTypes.GET_CD_HAS_BILLINGS_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.cdBillings = null;
          draft.loading.cdBillings = false;
          draft.success.cdBillings = true;
          draft.cdBillings = action.payload;
        });
      case actionTypes.GET_CD_HAS_BILLINGS_ERROR:
        return produce(state, (draft) => {
          draft.errors.cdBillings = true;
          draft.loading.cdBillings = false;
          draft.success.cdBillings = false;
          !draft.error.includes('cdBillings') &&
            draft.error.push('cdBillings');
        });

    // addInvoicingLine
    case actionTypes.ADD_INVOICING_LINE_LOADING:
      return produce(state, (draft) => {
        draft.loading.addInvoicingLine = true;
      });
    case actionTypes.ADD_INVOICING_LINE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addInvoicingLine = null;
        draft.loading.addInvoicingLine = false;
        draft.success.addInvoicingLine = true;
      });
    case actionTypes.ADD_INVOICING_LINE_ERROR:
      return produce(state, (draft) => {
        draft.errors.addInvoicingLine = true;
        draft.loading.addInvoicingLine = false;
        draft.success.addInvoicingLine = false;
      });

    // updateInvoicingLine
    case actionTypes.UPDATE_INVOICING_LINE_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateInvoicingLine = true;
      });
    case actionTypes.UPDATE_INVOICING_LINE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateInvoicingLine = null;
        draft.loading.updateInvoicingLine = false;
        draft.success.updateInvoicingLine = true;
      });
    case actionTypes.UPDATE_INVOICING_LINE_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateInvoicingLine = true;
        draft.loading.updateInvoicingLine = false;
        draft.success.updateInvoicingLine = false;
      });

    // removeInvoicingLine
    case actionTypes.REMOVE_INVOICING_LINE_LOADING:
      return produce(state, (draft) => {
        draft.loading.removeInvoicingLine = true;
      });
    case actionTypes.REMOVE_INVOICING_LINE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removeInvoicingLine = null;
        draft.loading.removeInvoicingLine = false;
        draft.success.removeInvoicingLine = true;
      });
    case actionTypes.REMOVE_INVOICING_LINE_ERROR:
      return produce(state, (draft) => {
        draft.errors.removeInvoicingLine = true;
        draft.loading.removeInvoicingLine = false;
        draft.success.removeInvoicingLine = false;
      });

    // validateFile
    case actionTypes.VALIDATE_FILE_LOADING:
      return produce(state, (draft) => {
        draft.loading.validateFile = true;
      });
    case actionTypes.VALIDATE_FILE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.validateFile = null;
        draft.loading.validateFile = false;
        draft.success.validateFile = true;
      });
    case actionTypes.VALIDATE_FILE_ERROR:
      return produce(state, (draft) => {
        draft.errors.validateFile = true;
        draft.loading.validateFile = false;
        draft.success.validateFile = false;
      });

    // updateInvoicingStep
    case actionTypes.UPDATE_INVOICING_STEP_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateInvoicingStep = true;
      });
    case actionTypes.UPDATE_INVOICING_STEP_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateInvoicingStep = null;
        draft.loading.updateInvoicingStep = false;
        draft.success.updateInvoicingStep = true;
      });
    case actionTypes.UPDATE_INVOICING_STEP_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateInvoicingStep = true;
        draft.loading.updateInvoicingStep = false;
        draft.success.updateInvoicingStep = false;
      });

    // validateFileState
    case actionTypes.VALIDATE_FILE_STATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.validateFileState = true;
      });
    case actionTypes.VALIDATE_FILE_STATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.validateFileState = null;
        draft.loading.validateFileState = false;
        draft.success.validateFileState = true;
      });
    case actionTypes.VALIDATE_FILE_STATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.validateFileState = true;
        draft.loading.validateFileState = false;
        draft.success.validateFileState = false;
      });

     // getPdfFile
     case actionTypes.DOWNLOAD_PDF_LOADING:
      return produce(state, (draft) => {
        draft.loading.getpdfFile = true;
      });
    case actionTypes.DOWNLOAD_PDF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getpdfFile = null;
        draft.loading.getpdfFile = false;
        draft.success.getpdfFile = true;
      });
    case actionTypes.DOWNLOAD_PDF_ERROR:
      return produce(state, (draft) => {
        draft.errors.getpdfFile = true;
        draft.loading.getpdfFile = false;
        draft.success.getpdfFile = false;
      });

       // createPdfFile
     case actionTypes.CREATE_CONTROL_PDF:
      return produce(state, (draft) => {
        draft.loading.createClerkControlPdfFile = true;
      });
    case actionTypes.CREATE_CONTROL_PDF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createClerkControlPdfFile = null;
        draft.loading.createClerkControlPdfFile = false;
        draft.success.createClerkControlPdfFile = true;
        draft.clerkControlPdfFile = action.payload;
      });
    case actionTypes.CREATE_CONTROL_PDF_ERROR:
      return produce(state, (draft) => {
        draft.errors.createClerkControlPdfFile = true;
        draft.loading.createClerkControlPdfFile = false;
        draft.success.createClerkControlPdfFile = false;
        !draft.error.includes('clerkControlPdfFile') && draft.error.push('clerkControlPdfFile');
      });

       // getOneFile
    case actionTypes.GET_ONE_FILE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneFile = true;
      });
    case actionTypes.GET_ONE_FILE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneFile = null;
        draft.loading.getOneFile = false;
        draft.success.getOneFile = true;
        draft.oneFile = action.payload;
      });
    case actionTypes.GET_ONE_FILE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneFile = true;
        draft.loading.getOneFile = false;
        draft.success.getOneFile = false;
        !draft.error.includes('oneFile') && draft.error.push('oneFile');
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
