import React from 'react';
import { Table, Button as Btn } from 'semantic-ui-react';

import Button from 'components/Button';
import ButtonValidationModal from 'components/ButtonValidationModal';
import styled from '@emotion/styled';



const CustomsDocuments = ({
  document,
  key,
  declarationState,
  handleShowDocument,
  handleDeleteDocument,
}: CustomsDocumentsProps) => {

  const disabled =  (document.CustomsDeclarationDocumentState && document.CustomsDeclarationDocumentState.id !== 4)
  const msg =  (document.CustomsDeclarationDocumentState && document.CustomsDeclarationDocumentState.id === 3)


  const State = styled.div(() => ({
    float: 'left',
    marginTop: '0.4rem',
    padding: '0 0.7rem',
    borderRadius: '0.7rem',
    background: '#e9eef3',
    fontSize: '10px',
  }))

    return (
        <>
        <Table.Row key={key}>
            <Table.Cell>{document.name}{msg && (<State>{document.CustomsDeclarationDocumentState.state}</State>)}</Table.Cell>
            <Table.Cell>
              {document.CustomsDeclarationDocumentCategory.name}
            </Table.Cell>
            <Table.Cell>
              {new Date(
                document.createdAt
              ).toLocaleDateString('en', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Table.Cell>
            <Table.Cell>
              <Btn.Group vertical widths='two'>
                <Button
                  margin='5px'
                  content='Show'
                  color='blue'
                  disabled={disabled}
                  onClick={() =>
                    handleShowDocument(document.id)
                  }
                />
                {declarationState && (
                    <ButtonValidationModal
                    noSubmit
                    trgrContent='Delete'
                    headerContent='Delete'
                    modalContent='Do you confirm the deletion of this document?'
                    trgrColor='red'
                    disabled={disabled}
                    onClick={() => {
                      handleDeleteDocument(document.id);
                    }}
                    margin='5px'
                  />          
                )}      
              
              </Btn.Group>
            </Table.Cell>
          </Table.Row>
       </>
      )
}

interface CustomsDocumentsProps {
    document: any,
    key: number,
    declarationState: boolean,
    handleShowDocument: (docId) => void;
    handleDeleteDocument: (docId) => void;
  }
  
  export default CustomsDocuments;