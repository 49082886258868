import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import IssueForm from './components/IssueForm/issueForm';

const IssueStep = ({ dispatch, manageSteps, match, issue, file, isEdit, user }: any) => {
  const [issueData, setissueData] = useState(null);

  useEffect(() => {
    dispatch(
      actions.customsClerk.getIssue({
        params: { fileId: match.params.fileId },
      })
    );
    return () => {
      dispatch(actions.customsClerk.reset(['issue', 'error']));
    };
  }, [dispatch,  match.params.fileId]);

  useEffect(() => {
    if (issue) {
      setissueData(issue);
    }
  }, [issue]);

  return (
    <Wrapper>
      <Segment>
        {!file?.signatureEndS3Path || issueData ? (
          <IssueForm
            editData={issueData}
            match={match}
            readOnly={file?.signatureEndS3Path && true}
            manageSteps={manageSteps}
            file={file}
            isEdit={isEdit}
            user={user}
          />
        ) : (
          <Wrapper margin='0.5rem 0'>This file has no control data.</Wrapper>
        )}
      </Segment>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    issue: selectors.customsClerk.issueSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(IssueStep);
