import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container } from 'semantic-ui-react';

import { Main } from 'components/Layouts';
import Wrapper from 'components/core/Wrapper/Wrapper';
import TextCustom from 'components/core/TextCustom/TextCustom';
import Segment from 'components/core/Segment/Segment';
import { ClickableText } from 'components/ClickableText/styles';

const Index = (props: any) => {
  return (
    <Main>
      <Container>
        <Wrapper padding='3rem' display='flex' justifyContent='center'>
          <TextCustom fontSize='2rem' fontWeight='bold'>
            About My ASD
          </TextCustom>
        </Wrapper>
        <Segment textAlign='center' margin='auto' maxWidth='40rem'>
          <TextCustom fontSize='1rem'></TextCustom>
          <Wrapper padding='0.5rem'>
            <ClickableText
              color='blue'
              onClick={() => {
                window.open(
                  'https://my-asd.s3-eu-west-1.amazonaws.com/legal/LEGAL_NOTICE_MY-ASD_EN.pdf',
                  '_blank'
                );
              }}
            >
              Legal notice
            </ClickableText>
          </Wrapper>
          <Wrapper padding='0.5rem'>
            <ClickableText
              color='blue'
              onClick={() => {
                window.open(
                  'https://my-asd.s3-eu-west-1.amazonaws.com/legal/COOKIE_USAGE_POLICY_MY-ASD_EN.pdf',
                  '_blank'
                );
              }}
            >
              Cookie usage policy
            </ClickableText>
          </Wrapper>

          <Wrapper padding='0.5rem'>
            <ClickableText
              color='blue'
              onClick={() => {
                window.open(
                  'https://my-asd.s3-eu-west-1.amazonaws.com/legal/PRIVACY_AND_PERSONAL_DATA_PROTECTION_POLICY_EN.pdf',
                  '_blank'
                );
              }}
            >
              Privacy and personal data protection policy
            </ClickableText>
          </Wrapper>
        </Segment>
      </Container>
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
