export default {
  REPORTBUG: 'MY_ASD.ADMIN.REPORTBUG',
  REPORTBUG_LOADING: 'MY_ASD.ADMIN.REPORTBUG_LOADING',
  REPORTBUG_SUCCESS: 'MY_ASD.ADMIN.REPORTBUG_SUCCESS',
  REPORTBUG_ERROR: 'MY_ASD.ADMIN.REPORTBUG_ERROR',

  GET_PERMISSIONS: 'MY_ASD.ADMIN.GET_PERMISSIONS',
  GET_PERMISSIONS_LOADING: 'MY_ASD.ADMIN.GET_PERMISSIONS_LOADING',
  GET_PERMISSIONS_SUCCESS: 'MY_ASD.ADMIN.GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_ERROR: 'MY_ASD.ADMIN.GET_PERMISSIONS_ERROR',

  RESET: 'MY_ASD.ADMIN.RES',
  REFRESH_ERROR: 'MY_ASD.ADMIN.REFRESH_ERROR',
};
