import { saveAs } from 'file-saver';

import actionTypes from 'store/fiscal/quickFixes/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.UPLOAD_SPLIT_PROOF:
        try {
          next({ type: actionTypes.UPLOAD_SPLIT_PROOF_LOADING });
          const response = await request.handleSplitProof({...action.payload});
          next({
            type: actionTypes.UPLOAD_SPLIT_PROOF_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.UPLOAD_SPLIT_PROOF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.HAS_PAID_SUBSCRIPTION:
        try {
          next({ type: actionTypes.HAS_PAID_SUBSCRIPTION_LOADING });
          const response = await request.hasPaidSubscription({...action.payload});
          next({
            type: actionTypes.HAS_PAID_SUBSCRIPTION_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.HAS_PAID_SUBSCRIPTION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.CHECK_QUICKPROOF_EXIST:
        try {
          next({ type: actionTypes.CHECK_QUICKPROOF_EXIST_LOADING });
          const response = await request.checkQuickproofExist({...action.payload});
          next({
            type: actionTypes.CHECK_QUICKPROOF_EXIST_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.CHECK_QUICKPROOF_EXIST_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;


      case actionTypes.SET_VAT:
        try {
          next({ type: actionTypes.SET_VAT_LOADING });
          const response = await request.setVatNumber({...action.payload});
          next({
            type: actionTypes.SET_VAT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.SET_VAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_COMPANY_SUBSCRIBED:
        try {
          next({ type: actionTypes.GET_COMPANY_SUBSCRIBED_LOADING });
          const response = await request.getCompanySubscribed();
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_PREFILLED:
          try {
            next({ type: actionTypes.GET_PREFILLED_LOADING });
            const response = await request.getPrefilledDelivery({...action.payload});
            next({
              type: actionTypes.GET_PREFILLED_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_PREFILLED_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
          break;

        case actionTypes.GET_FOR_ONE_ALL_BT:
          try {
            next({ type: actionTypes.GET_FOR_ONE_ALL_BT_LOADING });
            const response = await request.getBuyersTransporters({ ...action.payload });
            next({
              type: actionTypes.GET_FOR_ONE_ALL_BT_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_FOR_ONE_ALL_BT_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
          break;

      case actionTypes.SUBSCRIBE:
        try {
          next({ type: actionTypes.SUBSCRIBE_LOADING });
          const resp = await request.subscribe({ ...action.payload });
          next({
            type: actionTypes.SUBSCRIBE_SUCCESS,
            payload: resp.data.data,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.SUBSCRIBE_WITH_CODE:
        try {
          next({ type: actionTypes.SUBSCRIBE_WITH_CODE_LOADING });
          await request.subscribeWithCode({ ...action.payload });
          next({
            type: actionTypes.SUBSCRIBE_WITH_CODE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SUBSCRIBE_WITH_CODE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.CONTACT:
        try {
          next({ type: actionTypes.CONTACT_LOADING });
          await request.contact({ ...action.payload });
          next({
            type: actionTypes.CONTACT_SUCCESS,
          });
          store.dispatch(
            messageHandler.successMessage(
              'Thank you for your interest in ASD Quickproof. You will receive your login information shortly.'
            )
          );
        } catch (error) {
          next({
            type: actionTypes.CONTACT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UNSUBSCRIBE:
        try {
          next({ type: actionTypes.UNSUBSCRIBE_LOADING });
          await request.unsubscribe({ ...action.payload });
          next({
            type: actionTypes.UNSUBSCRIBE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UNSUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ALL:
        try {
          next({ type: actionTypes.GET_ALL_LOADING });
          const response = await request.getAll();
          next({
            type: actionTypes.GET_ALL_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GET_ONE:
        try {
          next({ type: actionTypes.GET_ONE_LOADING });
          const response = await request.getOne({ ...action.payload });
          next({
            type: actionTypes.GET_ONE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_KYANITE_OF_ONE_TRANSPORTER:
        try {
          next({ type: actionTypes.GET_KYANITE_OF_ONE_TRANSPORTER_LOADING });
          const response = await request.getPODOfOneTransporter({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_KYANITE_OF_ONE_TRANSPORTER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_KYANITE_OF_ONE_TRANSPORTER_ERROR,
            error,
          });
        }
        break;
      case actionTypes.GET_KYANITE_OF_ONE_BUYER:
        try {
          next({ type: actionTypes.GET_KYANITE_OF_ONE_BUYER_LOADING });
          const response = await request.getPODOfOneBuyer({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_KYANITE_OF_ONE_BUYER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_KYANITE_OF_ONE_BUYER_ERROR,
            error,
          });
        }
        break;
      case actionTypes.GET_KYANITE_OF_ONE_SELLER:
        try {
          next({ type: actionTypes.GET_KYANITE_OF_ONE_SELLER_LOADING });
          const response = await request.getPODOfOneSeller({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_KYANITE_OF_ONE_SELLER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_KYANITE_OF_ONE_SELLER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS:
        try {
          next({
            type: actionTypes.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_LOADING,
          });
          const response = await request.getPODOfOneSellerByFilters({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.CREATE:
        try {
          next({ type: actionTypes.CREATE_LOADING });
          await request.create({ ...action.payload });
          next({
            type: actionTypes.CREATE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;
      case actionTypes.UPDATE:
        try {
          next({ type: actionTypes.UPDATE_LOADING });
          await request.update({ ...action.payload });
          next({
            type: actionTypes.UPDATE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;
      case actionTypes.ASK_FOR_PROOF:
        try {
          next({ type: actionTypes.ASK_FOR_PROOF_LOADING });
          await request.askForProof({ ...action.payload });
          next({
            type: actionTypes.ASK_FOR_PROOF_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.ASK_FOR_PROOF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.DOWNLOAD_ATTESTATION:
        try {
          next({ type: actionTypes.DOWNLOAD_ATTESTATION_LOADING });
          const response = await request.downloadAttestation({
            ...action.payload,
          });
          saveAs(
            response.data,
            response.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.DOWNLOAD_ATTESTATION_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_ATTESTATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_TOTAL_VAT_RISK:
        try {
          next({ type: actionTypes.GET_TOTAL_VAT_RISK_LOADING });
          const response = await request.getTotalVatRisk({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_TOTAL_VAT_RISK_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_TOTAL_VAT_RISK_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_NUMBER_OF_RECEIVED:
        try {
          next({ type: actionTypes.GET_NUMBER_OF_RECEIVED_LOADING });
          const response = await request.getNumberOfReceived({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_NUMBER_OF_RECEIVED_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_NUMBER_OF_RECEIVED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_NUMBER_OF_PENDING:
        try {
          next({ type: actionTypes.GET_NUMBER_OF_PENDING_LOADING });
          const response = await request.getNumberOfPending({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_NUMBER_OF_PENDING_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_NUMBER_OF_PENDING_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_NUMBER_OF_PROOF_PENDING:
        try {
          next({ type: actionTypes.GET_NUMBER_OF_PROOF_PENDING_LOADING });
          const response = await request.getNumberOfProofPending({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_NUMBER_OF_PROOF_PENDING_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_NUMBER_OF_PROOF_PENDING_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UPLOAD_PROOF:
        try {
          next({ type: actionTypes.UPLOAD_PROOF_LOADING });
          await request.uploadProof({
            ...action.payload,
          });
          next({
            type: actionTypes.UPLOAD_PROOF_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPLOAD_PROOF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.DOWNLOAD_ALL_PROOF:
        try {
          next({ type: actionTypes.DOWNLOAD_ALL_PROOF_LOADING });
          const response = await request.downloadAllProof({
            ...action.payload,
          });
          saveAs(
            response.data,
            response.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.DOWNLOAD_ALL_PROOF_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_ALL_PROOF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.DOWNLOAD_ONE_PROOF:
        try {
          next({ type: actionTypes.DOWNLOAD_ONE_PROOF_LOADING });
          const response = await request.downloadOneProof({
            ...action.payload,
          });

          const file = new Blob([response.data], {
            type: response.data.type,
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          next({
            type: actionTypes.DOWNLOAD_ONE_PROOF_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_ONE_PROOF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE:
        try {
          next({ type: actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_LOADING });
          const response = await request.downloadCustomsRegimeInvoice({
            ...action.payload,
          });

          const file = new Blob([response.data], {
            type: response.data.type,
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          next({
            type: actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_VAT_RISK:
          try {
            next({ type: actionTypes.GET_VAT_RISK_LOADING });
            const response = await request.getVatRisk({ ...action.payload });
            next({
              type: actionTypes.GET_VAT_RISK_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_VAT_RISK_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;

        // getCustomsDeclarationIds
        case actionTypes.GET_CUSTOM_DECLARATIONS_ID:
          try {
            next({ type: actionTypes.GET_CUSTOM_DECLARATIONS_ID_LOADING });
            const response = await request.getCustomsDeclarationsIDs({ ...action.payload });
            next({
              type: actionTypes.GET_CUSTOM_DECLARATIONS_ID_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_CUSTOM_DECLARATIONS_ID_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
        break;

         // getAllCDs
         case actionTypes.GET_CLIENT_REF:
          try {
            next({ type: actionTypes.GET_CLIENT_REF_LOADING });
            const response = await request.searchForClientRef({ ...action.payload });
            next({
              type: actionTypes.GET_CLIENT_REF_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_CLIENT_REF_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
        break;

      case actionTypes.VALIDATE_PROOF:
        try {
          next({ type: actionTypes.VALIDATE_PROOF_LOADING });
          const response = await request.validateProof({ ...action.payload });
          next({
            type: actionTypes.VALIDATE_PROOF_SUCCESS,
            payload: response.data.data,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.VALIDATE_PROOF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.REFUSE_PROOF:
        try {
          next({ type: actionTypes.REFUSE_PROOF_LOADING });
          await request.refuseProof({ ...action.payload });
          next({
            type: actionTypes.REFUSE_PROOF_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.REFUSE_PROOF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.VALIDATE_DELIVERY:
        try {
          next({ type: actionTypes.VALIDATE_DELIVERY_LOADING });
          await request.validateDelivery({
            ...action.payload,
          });
          next({
            type: actionTypes.VALIDATE_DELIVERY_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.VALIDATE_DELIVERY_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ONE_FOR_BUYER:
        try {
          next({ type: actionTypes.GET_ONE_FOR_BUYER_LOADING });
          const response = await request.getOneForBuyer({ ...action.payload });
          next({
            type: actionTypes.GET_ONE_FOR_BUYER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_FOR_BUYER_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GET_ONE_FOR_TRANSPORTER:
        try {
          next({ type: actionTypes.GET_ONE_FOR_TRANSPORTER_LOADING });
          const response = await request.getOneForTransporter({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_ONE_FOR_TRANSPORTER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_FOR_TRANSPORTER_ERROR,
            error,
          });
        }
        break;

      case actionTypes.CREATE_MASSINTEGRATION:
        try {
          next({ type: actionTypes.CREATE_MASSINTEGRATION_LOADING });
          await request.createMassIntegration(action.payload);
          next({
            type: actionTypes.CREATE_MASSINTEGRATION_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_MASSINTEGRATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DOWNLOAD_MASSINTEGRATION_TEMPLATE:
        try {
          next({ type: actionTypes.DOWNLOAD_MASSINTEGRATION_TEMPLATE_LOADING });
          const response = await request.downloadMassIntegrationTemplate({
            ...action.payload,
          });
          saveAs(
            response.data,
            response.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.DOWNLOAD_MASSINTEGRATION_TEMPLATE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_MASSINTEGRATION_TEMPLATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_MASSINTEGRATIONS:
        try {
          next({ type: actionTypes.GET_MASSINTEGRATIONS_LOADING });
          const response = await request.getMassIntegrations(action.payload);
          next({
            type: actionTypes.GET_MASSINTEGRATIONS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_MASSINTEGRATIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ONE_MASSINTEGRATION:
        try {
          next({ type: actionTypes.GET_ONE_MASSINTEGRATION_LOADING });
          const response = await request.getOneMassIntegration(action.payload);

          next({
            type: actionTypes.GET_ONE_MASSINTEGRATION_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_MASSINTEGRATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DELETE_MASSINTEGRATION:
        try {
          next({ type: actionTypes.DELETE_MASSINTEGRATION_LOADING });
          await request.deleteMassIntegration(action.payload);
          next({
            type: actionTypes.DELETE_MASSINTEGRATION_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.DELETE_MASSINTEGRATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_CUSTOMS_DECLARATION_IDS:
          try {
            next({ type: actionTypes.GET_CUSTOMS_DECLARATION_IDS_LOADING });
            const response = await request.getCDIDs(action.payload);
            next({
              type: actionTypes.GET_CUSTOMS_DECLARATION_IDS_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_CUSTOMS_DECLARATION_IDS_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
          break;

      default:
        next(action);
        break;
    }
  };
}
