export const customsCategories = {
  customsClearance: 'Customs clearance',
  traces: 'Traces',
  customsClerk: 'Customs clerk',
  customsClearancegbExport: 'UK Export customs clearance',
  customsClearanceEUImport: 'EU Import customs clearance',
  gmr: 'GMR'
};

export const customsDeclarationSteps = [
  'Awaiting processing',
  'Current processing',
  'Completed processing',
];

export const customsDeclarationStepColors = ['#cd2121', '#dda80b', '#21ba45'];
