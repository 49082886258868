import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Button as Btn, Table } from 'semantic-ui-react';

import ButtonValidationModal from 'components/ButtonValidationModal';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';

const Index = ({ dispatch, match, user, history, loadingHandlerusers }) => {
  useEffect(() => {
    dispatch(actions.user.getUser({ params: { userId: match.params.userId } }));

    return () => {
      dispatch(actions.user.reset(['user', 'error']));
    };
  }, [dispatch, match]);

  const handleDelete = (userId) => {
    dispatch(actions.user.deleteUser({ params: { userId } }));
    history.push(`/admin/users`);
  };

  return (
    <Wrapper margin='5rem auto' width='70%'>
      {((user === null || Object.getOwnPropertyNames(user).length === 0) &&
        loadingHandlerusers && <Loader />) || (
        <>
          {user !== null && (
            <>
              <Table basic columns='2'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <b>First name:</b>
                    </Table.Cell>
                    <Table.Cell>{user.firstName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <b>Last name:</b>
                    </Table.Cell>
                    <Table.Cell>{user.lastName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <b>Email:</b>
                    </Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <b>Admin:</b>
                    </Table.Cell>
                    <Table.Cell>{user.admin ? 'Yes' : 'No'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <b>Operator:</b>
                    </Table.Cell>
                    <Table.Cell>{user.operator ? 'Yes' : 'No'}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Btn.Group floated='right'>
                <Button
                  content='Edit'
                  icon='edit'
                  type='submit'
                  labelPosition='right'
                  onClick={() =>
                    history.push(`/admin/users/edit/${match.params.userId}`)
                  }
                  margin='5px 5px 5px 0px'
                  float-righ
                />
                <ButtonValidationModal
                  trgrContent='Delete'
                  headerContent='Delete'
                  modalContent='Do you confirm the deletion of this client?'
                  trgrColor='red'
                  trgrIcon='trash'
                  headerIcon='trash'
                  onClick={() => {
                    handleDelete(user.id);
                  }}
                  margin='5px'
                />
              </Btn.Group>
            </>
          )}
          <Btn.Group floated='left'>
            <Button
              content='Back to users'
              icon='reply'
              type='submit'
              labelPosition='right'
              onClick={() => history.push(`/admin/users`)}
              margin='5px'
            />
          </Btn.Group>
        </>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.user.userSelector(state),
    errorHandlerClient: selectors.user.errorSelector(state),
    loadingHandlerusers: selectors.user.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
