import React from "react";

import { ErrorLabel } from "./styles";

const Index = ({ msg }) => {
  return (
    <ErrorLabel basic color="red" pointing>
      {msg}
    </ErrorLabel>
  );
};

export default Index;
