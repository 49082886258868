import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link  } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Header, Table, Icon, Container } from 'semantic-ui-react';

import Button from 'components/Button';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import { actions, selectors } from 'store';
import NewDeclaration from 'views/Customs/Declaration/Service/New';
import DownloadFilesModal from 'views/Customs/Declaration/Service/DownloadFilesModal/downloadFilesModal';
import EmptyTable from 'components/EmptyTable';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Search from 'components/Search';
import { paths } from 'routes/customs/declaration';
import Pagination from 'components/Pagination';
import { Step } from 'components/core';
import {
  customsDeclarationSteps,
  customsDeclarationStepColors
} from 'constants/customsDeclarationCategories';
import FollowersList from '../Components/FollowersList/FollowersList';
import ClickableText from 'components/ClickableText';

const Index = ({
  dispatch,
  history,
  declarations,
  hostedDeclarations,
  error,
  match,
  companySelected,
  company,
  user,
  getAllHostedDeclsLoading,
  getAllDeclarationsLoading,
  createDeclarationLoading,
  declarationsCount,
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected;
  const limitPerPage = 50;

  const [modalStatus, setmodalStatus] = useState(false);
  const [downloadFilesModal, setdownloadFilesModal] = useState(false);
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [mandates, setHasSubscribedToMandates]: any = useState(null);
  const [resetSearch, setresetSearch] = useState(false);

  useEffect(() => {
    dispatch(actions.customsDeclaration.reset(['error']));
    dispatch(
      actions.customsDeclaration.getAllHosted({
        params: { companyId },
        query: {
          limit: limitPerPage,
          page: 1,
        },
      })
    );

    dispatch(actions.company.getOne({
      params: {
        companyId
      }
    }))

    dispatch(actions.socket.subscribeUpdateCustomsDeclaration(companyId));

    return () => {
      dispatch(
        actions.customsDeclaration.reset([
          'declarations',
          'company',
          'declarationsCount',
          'error',
        ])
      );
      dispatch(actions.socket.unsubscribeUpdateCustomsDeclaration(companyId));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    if(declarations !== null){   
      declarations.forEach((e) => (e.createdAt = new Date(e.createdAt)));
      setdata(_.orderBy(declarations, ['createdAt'], ['desc']));
      resetSearch && setresetSearch(false);
    }

    if(company !== null && mandates === null){
      setHasSubscribedToMandates(company.mandates)
    }

  }, [declarations, resetSearch, company, mandates]);


 

  const extractDocuments = (data) => {
    dispatch(
      actions.customsDeclaration.extractDocuments({
        params: {
          companyId,
          from: data.startDate,
          to: data.endDate,
        },
        data: {
          withDocuments: true,
          docType: 'dau',
          attributes: [
            'id',
            'createdAt',
            'state',
            'trailerLicensePlate',
            'truckLicensePlate',
            'truckOrigin',
          ],
        },
      })
    );
    setdownloadFilesModal(false);
  };

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['createdAt'], ['desc']));
  };

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const handlePageChange = (event, data) => {
    dispatch(
      actions.customsDeclaration.getAllHosted({
        params: { companyId },
        query: {
          limit: limitPerPage,
          page: Math.ceil(data.activePage),
        },
      })
    );
  };

  return (
    <Wrapper width='80%' margin='auto'>
      {userAdminOrOp && (
        <>
          <Divider hidden />
          <Wrapper display='flex'>
            <ButtonBack history={history} />
          </Wrapper>
        </>
      )}
      <Divider hidden />
      <Header size='huge' textAlign='center'>Customs declaration dashboard</Header>
      <Container textAlign='center'>
        (
          <ClickableText
          color='black'
          onClick={() => {
            window.open(
              'https://my-asd.s3-eu-west-1.amazonaws.com/kyanite/user_guide.pdf',
              '_blank'
            );
            }}
          >
          User guide
        </ClickableText>
        )
      </Container>
      <Divider hidden />
      <Wrapper display='flex' justifyContent='space-between'>
        {!error && (
          <Wrapper display='flex' justifyContent='space-around'>
            <Button
              labelPosition='right'
              position='left'
              icon='plus circle'
              type='button'
              content='New declaration'
              onClick={() => setmodalStatus(true)}
            />
            {mandates && (
                <Link
                to={paths.customsDeclarationMandates
                  .replace(':companyId', companyId)}
                ><Button
                    labelPosition='right'
                    position='left'
                    icon='address card'
                    type='button'
                    content='Mandates'
                  /></ Link>
            )}
          
            <Link
            to={paths.customsDeclarationAccises
              .replace(':companyId', companyId)}
            ><Button
              labelPosition='right'
              position='right'
              icon='pin'
              type='button'
              content='Accises'
            /></ Link>
            {/* <Link
              to={paths.customsDeclarationDeb.replace(':companyId', companyId)}
              >
                <Button
                  labelPosition='right'
                  position='left'
                  icon='plus circle'
                  type='button'
                  content='DEB'
                />
            </Link> */}
            {user.admin && (
              <Button
                labelPosition='right'
                position='left'
                icon='download'
                type='button'
                content='Download files'
                onClick={() => setdownloadFilesModal(true)}
              />
            )}
          </Wrapper>
        )}
        <Search
          dataSource={declarations}
          onSearchResultChange={onSearchResultChange}
          setResetSearch={setresetSearch}
        />
      </Wrapper>

      <Divider hidden />
      {modalStatus && (
        <NewDeclaration
          open={modalStatus}
          handleClose={() => setmodalStatus(false)}
          match={match}
        />
      )}
      {downloadFilesModal && (
        <DownloadFilesModal
          open={downloadFilesModal}
          handleClose={() => setdownloadFilesModal(false)}
          match={match}
          extractDocuments={extractDocuments}
        />
      )}
      {((getAllDeclarationsLoading || getAllHostedDeclsLoading || createDeclarationLoading) && (
        <Loader content='Loading' />
      )) || (
        <Wrapper overflow='auto'>
          <p>Number of declarations: {declarationsCount}</p>
          {user.admin && (
        <>
      </>
      )}    
          <Table columns='8' stackable sortable selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'id' ? direction : null}
                  onClick={() => handleSort('id')}
                >
                  Id
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'date' ? direction : 'ascending'}
                  onClick={() => handleSort('date')}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'trailerLicensePlate' ? direction : null}
                  onClick={() => handleSort('trailerLicensePlate')}
                >
                  Trailer license plate
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'truckLicensePlate' ? direction : null}
                  onClick={() => handleSort('truckLicensePlate')}
                >
                  Truck license plate
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'emitter' ? direction : null}
                  onClick={() => handleSort('emitter')}
                >
                  Emitter
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'followers' ? direction : null}
                  onClick={() => handleSort('followers')}
                >
                  Followers
                </Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={8} />) ||
                data.map((e, i) => {
                  let accises = data[i].CustomsDeclarationCategories.length>0 && data[i].CustomsDeclarationCategories[0].name === 'accises'
                  let transit = data[i].CustomsDeclarationCategories.length>0 && data[i].CustomsDeclarationCategories[0].name === 'transit'
                  return (
                    <Table.Row key={i} warning={e.customerNewMessage}>
                 
                 
                      <Link
                        to={paths.customsDeclarationDetails.replace(':companyId', e.Company_Id).replace(':declarationId', e.id)}
                        className='table-link'
                      >
                        <Table.Cell>
                          <Wrapper
                            display='flex'
                            justifyContent='space-between'
                          >                     
                            {e.id}
                            {(e.CustomsDeclarationUsers.length>0 && 
                              e.CustomsDeclarationUsers[0].owner_Id === companyId && 
                                <Icon 
                                  name='code branch' 
                                  size='small' 
                                  color='black'
                                  bordered
                                  circular
                                />
                              )}
                          
                            {e.customerNewMessage && (
                              <Icon name='envelope' color='red' size='large' />
                            )}
                          </Wrapper>
                        </Table.Cell>
                        <Table.Cell colSpan={(accises || transit) ? '3' : '0'}>

                          {new Date(e.createdAt).toLocaleDateString('en', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </Table.Cell>
                        {!accises && !transit && (
                          <>
                          <Table.Cell>{e.trailerLicensePlate}</Table.Cell>
                          <Table.Cell>{e.truckLicensePlate}</Table.Cell>
                          </>
                        )}
                        <Table.Cell>{e.emitter}</Table.Cell>
                        <Table.Cell>
                          {e.Users && (
                            <FollowersList users={e.Users} visibleNumber={2} />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {(e.state === 'cancelled' && (
                            <Step
                              popupContent='This declaration is cancelled'
                              label='Cancelled'
                            />
                          )) ||
                            e.CustomsDeclarationCategories.map(
                              (category, i) => (
                                <Step
                                  key={i}
                                  label={category.description}
                                  popupContent={
                                    customsDeclarationSteps[
                                      category.CustomsDeclarationHasCategories
                                        .step - 1
                                    ]
                                  }
                                  color={
                                    customsDeclarationStepColors[
                                      category.CustomsDeclarationHasCategories
                                        .step - 1
                                    ]
                                  }
                                />
                              )
                            )}
                        </Table.Cell>
                      </Link>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>
      )}
      <Divider hidden />
      <Wrapper display='flex' justifyContent='center'>
        <Pagination
          defaultActivePage={1}
          totalPages={declarationsCount / limitPerPage}
          onChange={handlePageChange}
        />
      </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    company: selectors.company.companySelector(state),
    declarations: selectors.customsDeclaration.declarationsSelector(state),
    hostedDeclarations: selectors.customsDeclaration.getAllHostedSelector(state),
    hostedDeclarationsCount: selectors.customsDeclaration.hostedDeclarationsCountSelector(state),
    declarationsCount: selectors.customsDeclaration.declarationsCountSelector(state),
    getAllDeclarationsLoading: selectors.customsDeclaration.getAllLoadingSelector(state),
    getAllHostedDeclsLoading: selectors.customsDeclaration.getAllHostedLoadingSelector(state),
    createDeclarationLoading: selectors.customsDeclaration.createDeclarationLoadingSelector(state),
    error: selectors.message.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
