import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    getThreshold(data: dataReqI) {
      const thresholdGetApi = routesApi.company.transit.getThreshold;
      return api.request({
        method: thresholdGetApi.method,
        route: thresholdGetApi.path,
        params: data.params,
      });
    },
    createThreshold(data: dataReqI) {
      const thresholdCreateApi = routesApi.company.transit.createThreshold;
      return api.request({
        method: thresholdCreateApi.method,
        route: thresholdCreateApi.path,
        params: data.params,
        data: data.data
      });
    },
    updateThreshold(data: dataReqI) {
      const thresholdCreateApi = routesApi.company.transit.updateThreshold;
      return api.request({
        method: thresholdCreateApi.method,
        route: thresholdCreateApi.path,
        params: data.params,
        data: data.data
      });
    },
    deleteThreshold(data: dataReqI) {
      const thresholdDeleteApi = routesApi.company.transit.deleteThreshold;
      return api.request({
        method: thresholdDeleteApi.method,
        route: thresholdDeleteApi.path,
        params: data.params,
      });
    },
  };
};
