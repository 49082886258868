import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const getAllCountriesErrorSelector = state => state.data.errors.getAllCountries;
const getEuCountriesErrorSelector = state => state.data.errors.getEuCountries;
const getCountryVatRateErrorSelector = state => state.data.errors.getCountryVatRate;

const errorsSelector = createErrorsSelector([getAllCountriesErrorSelector, getEuCountriesErrorSelector, getCountryVatRateErrorSelector]);


// Loading
const getAllCountriesLoadingSelector = state => state.data.loading.getAllCountries;
const getEuCountriesLoadingSelector = state => state.data.loading.getEuCountries;
const getCountryVatRateLoadingSelector = state => state.data.loading.getCountryVatRate;

const loadingSelector = createLoadingSelector([getAllCountriesLoadingSelector, getEuCountriesLoadingSelector, getCountryVatRateLoadingSelector]);

// Success
const getAllCountriesSuccessSelector = state => state.data.success.getAllCountries;
const getEuCountriesSuccessSelector = state => state.data.success.getEuCountries;
const getCountryVatRateSuccessSelector = state => state.data.success.getCountryVatRate;

const successSelector = createSuccessSelector([getAllCountriesSuccessSelector, getEuCountriesSuccessSelector, getCountryVatRateSuccessSelector]);

// Countries
const allCountriesSelector = state => state.data.allCountries;
const euCountriesSelector = state => state.data.euCountries;
const countryVatRateSelector = state => state.data.countryVatRate;


// Error handler
const errorSelector = state => state.data.error;

// loading
const allCountryLoadingSelector = state => state.data.loading.getAllCountries;
const euCountriesLoadingSelector = state => state.data.loading.getEuCountries;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  allCountriesSelector,
  euCountriesSelector,
  countryVatRateSelector,
  errorSelector,
  allCountryLoadingSelector,
  euCountriesLoadingSelector
};
