import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Divider,
  Grid,
  Header,
  Tab,
  Menu,
  Label,
} from 'semantic-ui-react';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import CollectionboxMainList from '../components/CollectionboxMainList/index';
import AddCollectionboxModal from '../New';
import Obligation from '../components/Obligations';
import Button from 'components/Button';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';
import { Absolute, CollectionboxDropdown } from './style';
import Pagination from 'components/Pagination';

const Index = ({
  dispatch,
  match,
  user,
  location,
  companySelected,
  collectionboxes,
  collectionboxesLoading,
}) => {
  const companyId = match.params.companyId || window.location.pathname.split('/')[3] 
  const fromCompanyListId = match.params.companyId
  const iso = match.params.iso
  const userAdminOrOp = user.operator || user.admin;
  const LIMIT = 100;

  const [CollectionboxModal, setCollectionboxModal]: any = useState(false);
  const [data, setData]: any = useState([]);
  const [currentData, setCurrentMonth]: any = useState([]);
  const [monthsList, setMonthList]: any = useState([])
  const [isoList, setIsoList]: any = useState([])
  const [session, setSession]: any = useState({period: '', iso: ''})
  const [modalObligations, setmodalObligations]: any = useState(false);
  let storedPage = JSON.parse(sessionStorage.getItem('collection_dash_page'))

  useEffect(() => {

    let queryObj = {}
    Object.assign(queryObj, {
      ...iso && {iso: iso},
      Company_Id: companyId,
      limit: LIMIT,
      state: 'processing',
      page: storedPage ? storedPage : 1,
    })

    sessionStorage.setItem('collection_dash_page', storedPage ? storedPage : 1)
  
    dispatch(
      actions.collectionbox.getCollectionboxesByFilters({
        query: queryObj
      })
    );
    dispatch(actions.socket.subscribeCollectionboxesALLFiltered(companyId));
    
    return () => {
      dispatch(
        actions.collectionbox.reset([
          'getByFilters',
          'error',
        ])
      );
      dispatch(actions.socket.unsubscribeCollectionboxesALLFiltered(companyId));
    };

  }, [dispatch, companyId, companySelected, iso]);


 const getAllCollectionboxes = (event, {activePage}) => {

    let queryObj = {}
    Object.assign(queryObj, {
      ...iso && {iso: iso},
      limit: LIMIT,
      state: 'processing',
      page: activePage,
    })
    sessionStorage.setItem('collection_dash_page', JSON.stringify(activePage));
    
    dispatch(
      actions.collectionbox.getCollectionboxesByFilters({
        query: queryObj
      })
    );

    return () => {
      dispatch(
        actions.collectionbox.reset([
          'error',
          'getByFilters'
        ])
      );
    };
 }


  useEffect(() => {
    if(collectionboxes){
      // current period tabpane
      const current = new Date()
      const currentCollectionboxs = collectionboxes.rows.filter(
        (e) => Number(new Date(e.taxPeriod).getMonth()) === Number(current.getMonth()-1) && e.state !== 'cancelled'
        )
      currentCollectionboxs && setCurrentMonth(currentCollectionboxs)
  
      // create filter lists
      const activePeriods: any = [], iso: any = [];
      for(const item of collectionboxes.rows){
        const found = activePeriods.some((value) => value === item.taxPeriod);
        const isoFound = iso.some((value) => value === item.country);
        if (!found) {
          activePeriods.push(item.taxPeriod);
        }
        if(!isoFound){
          iso.push(item.country)
        }
      }
      setMonthList(activePeriods.map((month, index) => {
        return {
          key: index,
          value: month,
          text: `${month.split('-')[0]} - ${new Date(month.split('-')[1]).toLocaleDateString('en', {
            month: 'long',
          })}`
        }
      }))
      setIsoList(iso.map((el, index) => {
        return {
          key: index,
          value: el,
          text: el,
        }}))

      // init filters with stored values
      const session = JSON.parse(sessionStorage.getItem(`collection_${companyId}`))
      session && setSession(prevState => ({
        ...prevState,
        iso: session.iso,
        taxPeriod: session.taxPeriod
      })) 
    }
  }, [collectionboxes, companyId, fromCompanyListId, companySelected]);


  useEffect(() => {
    // period and country filters
    const result = session && collectionboxes && collectionboxes.rows.filter(
      (value) => 
        (session.period !== '' && session.iso !== '') ?
          value.taxPeriod === session.period && value.country === session.iso 
        :
        (session.period === '' && session.iso) ?
          value.country === session.iso 
        :
        (session.iso === '' && session.period) ?
          value.taxPeriod === session.period 
        :
        (session.period === '' && session.iso === '') ?
          value : null
      );

      if(result){
        sessionStorage.setItem(`collection_${companyId}`, JSON.stringify(session))
        setData(result)
      }
  }, [collectionboxes, session, companyId])




 

  const getPrevMonth = (lower?) => {
    const date = new Date()
    const current = new Date(`${date.getMonth()}/1/${date.getFullYear()}`)
    const resetM = date.getMonth()
    current.setMonth(resetM-1)
    const month = current.toLocaleDateString('en', {
      month: 'long',
    })
    return (lower && month.toLocaleLowerCase()) || month
  }

  return (
    <Wrapper display='flex' justifyContent='center'>
    {CollectionboxModal && (
      <AddCollectionboxModal 
        open={CollectionboxModal}
        handleClose={() => setCollectionboxModal(false)}
        companyId={companyId}
        userAdminOrOp={userAdminOrOp}
      />
    )}

    {((collectionboxesLoading || data === null) && <Loader />)}

    {data !== null && (
      <Wrapper height='100%' width='80%' overflow='auto'>
        <Divider hidden />
        <Wrapper display='flex'>
      {location.state && location.state.country === 'country' && (
        <LinkedButton 
          labelPosition='left'
          icon='angle left'
          size='medium'
          type='button'
          content='Back'
          path={`/fiscal/collectionbox/country/:iso`.replace(':iso', location.state.iso)}
        />
      ) || (
       <LinkedButton 
          labelPosition='left'
          icon='angle left'
          size='medium'
          type='button'
          content='Back'
          path={`/fiscal/collectionbox`}
        />
      )}
         
             
        </Wrapper>
        <Divider hidden />
        <Header size='huge' textAlign='center'>Collectionbox dashboard</Header>
        <Divider hidden />
        <Divider hidden />
        <Wrapper display='flex' justifyContent='flex-end !important'>
          <Button
            labelPosition='right'
            position='left'
            icon='plus circle'
            type='button'
            content='Obligations'
            onClick={() => setmodalObligations(true)}
          />
          <Button
            labelPosition='right'
            position='left'
            icon='plus circle'
            type='button'
            content='New Declaration'
            onClick={() => setCollectionboxModal(true)}
          />
        </Wrapper>
        <Wrapper height='100%' overflow='auto' position='relative' minHeight='300px'>
        {modalObligations && (
          <Obligation
            open={modalObligations}
            companyId={companyId}
            handleClose={() => setmodalObligations(false)}
          />
        )}
        <Tab panes={[
            {
              menuItem: (
                <Menu.Item key={0} className='Collectionbox'>
                  Collectionbox
                  <Label>{data && data.length}</Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Grid>
                    <Grid.Column>           
                      <CollectionboxMainList
                        key={0}
                        data={data}
                        companyId={companyId}
                        iso={iso}
                      />
                    </Grid.Column>                     
                  </Grid>
                </Tab.Pane>
              ),
            },
            {
              menuItem: (
                <Menu.Item key={1} className='Collectionbox'>
                  Filing period ({getPrevMonth()})
                  <Label>{currentData && currentData.length}</Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Grid>
                    <Grid.Column>
                      <Header textAlign='center' size='small'>
                        INTRAT-STATS for {getPrevMonth(true)} 
                      </Header>                   
                      <CollectionboxMainList
                        key={0}
                        data={currentData}
                        companyId={companyId}
                        iso={iso}
                      />                                                                 
                    </Grid.Column>                     
                  </Grid>
                </Tab.Pane>
              ),
            }
        ]}/>
      <Absolute>
        <CollectionboxDropdown
          clearable
          search
          selection
          placeholder='Filing period...'
          options={monthsList}
          defaultValue={session.period || null}
          onChange={(e, {value}) => {
            e && setSession(prevState => ({
              ...prevState,
              period: value
            }))
          }}
        />

        <CollectionboxDropdown
          clearable
          search
          selection
          placeholder='Country...'
          options={isoList}
          defaultValue={session.iso || null}
          onChange={(e, {value}) => {
            e && setSession(prevState => ({
              ...prevState,
              iso: value
            }))
          }}
        />
      </Absolute>
      <Wrapper>
      <Divider hidden />
      {collectionboxes && collectionboxes.count>0 && (
          <Pagination
            totalPages={collectionboxes.count / LIMIT}
            storedPage={sessionStorage.getItem('collection_dash_page') !== null ? parseInt(sessionStorage.getItem('collection_dash_page')) : 1}
            onChange={getAllCollectionboxes}
          />
        )}   
      </Wrapper>
      </Wrapper>
  </Wrapper>
  )}
  </Wrapper> 
  )
}
const mapStateToProps = (state) => {
  return {
    companySelected: selectors.auth.companySelected(state),
    collectionboxes: selectors.collectionbox.getCollectionboxesByFiltersSelector(state),
    collectionboxesLoading: selectors.collectionbox.getCollectionboxesByFiltersLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
