import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Divider,
  Header
} from 'semantic-ui-react';
import { Spacer} from 'components/core'
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import IntrastatMainList from '../../components/IntrastatMainList/index';
import AddIntrastatModal from '../../New'
import Button from 'components/Button';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';
import { IntrastatDropdown, Right } from './style';
import SearchFilters from '../../components/SearchFilters/searchFilters';


const Index = ({
  intl,
  dispatch,
  match,
  user,
  subscribed,
  companySelected,
  intrastats,
  operators,
  operatorsLoading,
  intrastatsLoading,
  createIntrastatLoading,
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected;
  const LIMIT = 100;
  const monitoringPathToDetail = window.location.pathname.includes('monitoring')

  const [intrastatModal, setIntrastatModal]: any = useState(false);
  const [data, setData]: any = useState(null);
  const [operatorList, setOperators]: any = useState(null);
  const [monthsList, setMonthList]: any = useState([])
  const [isoList, setIsoList]: any = useState([])
  const [filterBar, setfilterBar] = useState(false);
  const [session, setSession]: any = useState({operator: '', period: '', iso: ''})
  //const [session, setSession]: any = useState({operator: `${user.lastName}-${user.firstName}`, period: '', iso: ''})
  let storedSearch = useMemo(() => JSON.parse(sessionStorage.getItem('intra_search')), [])

 
  useEffect(() => {

    dispatch(actions.operators.getAllOperator());

    let queryObj = {}
    Object.assign(queryObj, {
      limit: LIMIT,
      state: 'processing',
      ...storedSearch && storedSearch,
    })
    
    dispatch(
      actions.intrastat.getIntrastatsByFilters({
        query: queryObj
      })
    );
    dispatch(actions.intrastat.getCompanySubscribed());
    dispatch(actions.socket.subscribeIntrastatsByFilters(LIMIT, 'processing'));

      return () => {
        dispatch(actions.operators.reset(['allOperator', 'error']));
        dispatch(
          actions.intrastat.reset([
            'getByFilters',
            'error',
          ])
        );
        dispatch(actions.socket.unsubscribeIntrastatsByFilters(LIMIT, 'processing'));
      };
    
   
  }, [dispatch, LIMIT, storedSearch]);

  useEffect(() => {
    operators && setOperators(operators.map((e, index) => {
      return {
        key: index,
        value: `${e.lastName}-${e.firstName}`,
        text: `${e.lastName}-${e.firstName}` 
      }
    }));
  }, [operators]);


  const resetSearchFilters = () => {
    sessionStorage.removeItem('intra_search');
    dispatch(
      actions.intrastat.getIntrastatsByFilters({
        query: {
          limit: LIMIT,
          state: 'processing',
        },
      })
    );
    return () => {
      dispatch(
        actions.intrastat.reset(['getByFilters', 'error'])
      );
    }
};


  const getIntrastatsByFilters = (values: SearchIntrastats) => {

    let queryObj = {}
    Object.assign(queryObj, {
      limit: LIMIT,
      ...values.intrastatId && {intrastatId: values.intrastatId },
      ...values.companyId && {Company_Id: values.companyId },
      ...values.declarationType && {declarationType: values.declarationType },
      ...values.filingPeriod && {filingPeriod: values.filingPeriod },
      ...values.flow && {flow: values.flow },
      ...values.intranetReference && {intranetReference: values.intranetReference },
      state: 'processing',
      showAll: values.showAll
    })

    sessionStorage.setItem('intra_search', JSON.stringify(queryObj));

    dispatch(
      actions.intrastat.getIntrastatsByFilters({
        params: {
          companyId: companyId
        },
        query: queryObj
      })
    );
    return () => {
      dispatch(
        actions.intrastat.reset([
          'getByFilters',
          'error',
        ])
      );
    }
}

  useEffect(() => {
    if(intrastats){
      // create filter lists
      const activePeriods: any = [], iso: any = [];
      for(const item of intrastats){
        const found = activePeriods.some((value) => value === item.periodString);
        const isoFound = iso.some((value) => value === item.filingCountry);
        if (!found) {
          activePeriods.push(item.periodString);
        }
        if(!isoFound){
          iso.push(item.filingCountry)
        }
      }
      setMonthList(activePeriods.map((month, index) => {
        return {
          key: index,
          value: month,
          text: `${month.split('-')[0]} - ${new Date(month.split('-')[1]).toLocaleDateString('en', {
            month: 'long',
          })}`
        }
      }))
      setIsoList(iso.map((el, index) => {
        return {
          key: index,
          value: el,
          text: el,
        }}))

      // init filters with stored values
      const session = JSON.parse(sessionStorage.getItem(`intra_monit`))
      session && setSession(prevState => ({
        ...prevState,
        operator: session.operator,
        iso: session.iso,
        period: session.period
      })) 
    }
  }, [intrastats, companyId]);


  useEffect(() => {
    // period and country filters
    const result = session && intrastats && intrastats.filter(
      (value) => 
        (session.period !== '' && session.iso !== '') ?
          value.periodString === session.period && value.filingCountry === session.iso 
        :
        (session.period === '' && session.iso) ?
          value.filingCountry === session.iso 
        :
        (session.iso === '' && session.period) ?
          value.periodString === session.period 
        :
        (session.period === '' && session.iso === '') ?
          value : null
      );

      if(result){
        sessionStorage.setItem(`intra_monit`, JSON.stringify(session))
        // First follower is "Company Intrastat Setting" assigned
        const newData = 
          session.operator !== '' ? 
          result.filter((value) => (value.follower && value.follower[0].operator) === session.operator) : 
          result 

        setData(newData)
      }
  }, [intrastats, session, user])


  return (
    <Wrapper display='flex' justifyContent='center'>
    {intrastatModal && (
    <AddIntrastatModal 
      open={intrastatModal}
      handleClose={() => setIntrastatModal(false)}
      companyId={companyId}
    />
    )}
    {(( 
        data === null || 
        (intrastatsLoading && !filterBar) || 
        createIntrastatLoading
      ) && <Loader />) || (
      <Wrapper height='100%' width='80%' overflow='auto'>
        <Divider hidden />    
        <Wrapper display='flex'>
          {userAdminOrOp && (
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content={intl.formatMessage({
                id: `button.back`
              })}
              path={`/intrastat`}
            />
          )}       
        </Wrapper>
        <Divider hidden />
        <Header 
          size='huge' 
          textAlign='center'>EMEBI - INTRASTAT {intl.formatMessage({id: `intrastat.table.Dashboard`})}</Header>
        
        <Divider hidden />
        {intl.formatMessage({
          id: `intrastat.monit.displayedWithFilters`
          })} {data && data.length}
        <Spacer/>
        <Wrapper display='flex' justifyContent='space-between' alignItems='center'>
          <Right>
            <Button
              icon='filter'
              type='button'
              content={intl.formatMessage({
                id: `intrastat.monit.searchFilters`
              })}
              color='blue'
              onClick={() => setfilterBar(!filterBar)}
            />
            <IntrastatDropdown
              clearable
              search
              selection
              placeholder={intl.formatMessage({
                id: `intrastat.monit.pOperator`
              })}
              options={operatorList}
              loading={operatorsLoading}
              defaultValue={session.operator || null}
              onChange={(e, {value}) => {
                e && setSession(prevState => ({
                  ...prevState,
                  operator: value
                }))
              }}
            />
            <IntrastatDropdown
              clearable   
              selection
              placeholder={intl.formatMessage({
                id: `intrastat.monit.pFilingPeriod`
              })}
              options={monthsList}
              defaultValue={session.period || null}
              onChange={(e, {value}) => {
                e && setSession(prevState => ({
                  ...prevState,
                  period: value
                }))
              }}
            />
            <IntrastatDropdown
              clearable
              selection
              placeholder={intl.formatMessage({
                id: `intrastat.monit.pCountry`
              })}
              options={isoList}
              defaultValue={session.iso || null}
              onChange={(e, {value}) => {
                e && setSession(prevState => ({
                  ...prevState,
                  iso: value
                }))
              }}
            />
          </Right>    
        </Wrapper>
        <Wrapper display='flex' justifyContent='flex-end'>
            <SearchFilters
              intl={intl}
              isVisible={filterBar}
              companies={subscribed}
              applyFilters={getIntrastatsByFilters}
              resetFilters={resetSearchFilters}
              intrastatsLoading={intrastatsLoading}
            />
          </Wrapper>
        <Spacer height='15px' />
        <Wrapper height='100%' overflow='auto' position='relative' minHeight='300px'>
          {/* {downloadFilesModal && (
            <DownloadFilesModal
              accisesForWeek={accisesForWeek}
              open={downloadFilesModal}
              handleClose={handleClose}
              match={match}
            />
          )} */}
          <IntrastatMainList
            intl={intl}
            key={0}
            data={data}
            companyId={companyId}
            monitoringPathToDetail={monitoringPathToDetail}
          />                                                               
        <Wrapper>
      <Divider hidden />
      </Wrapper>
      </Wrapper>
  </Wrapper>
  )}
  </Wrapper> 
  )
}

type SearchIntrastats = {
  limit: number,
  state: string,
  intrastatId: string,
  companyId: number,
  declarationType: string,
  filingPeriod: string,
  flow: string,
  intranetReference: string,
  showAll: boolean
}


const mapStateToProps = (state) => {
  return {
    subscribed: selectors.intrastat.companySubscribedSelector(state),
    operators: selectors.operators.allOperatorSelector(state),
    operatorsLoading: selectors.operators.getAllOperatorLoadingSelector(state),
    companySelected: selectors.auth.companySelected(state),
    intrastats: selectors.intrastat.getIntrastatsByFiltersSelector(state),
    intrastatsLoading: selectors.intrastat.getIntrastatsByFiltersLoadingSelector(state),
    createIntrastatLoading: selectors.intrastat.createOneIntrastatLoadingSelector(state)
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
