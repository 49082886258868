export default {
 
  GET_THRESHOLD: 'MY_ASD.CONFIGURATIONS.GET_THRESHOLD',
  GET_THRESHOLD_LOADING: 'MY_ASD.CONFIGURATIONS.GET_THRESHOLD_LOADING',
  GET_THRESHOLD_SUCCESS: 'MY_ASD.CONFIGURATIONS.GET_THRESHOLD_SUCCESS',
  GET_THRESHOLD_ERROR: 'MY_ASD.CONFIGURATIONS.GET_THRESHOLD_ERROR',

  THRESHOLD_CREATE: 'MY_ASD.CONFIGURATIONS.THRESHOLD_CREATE',
  THRESHOLD_CREATE_LOADING: 'MY_ASD.CONFIGURATIONS.THRESHOLD_CREATE_LOADING',
  THRESHOLD_CREATE_SUCCESS: 'MY_ASD.CONFIGURATIONS.THRESHOLD_CREATE_SUCCESS',
  THRESHOLD_CREATE_ERROR: 'MY_ASD.CONFIGURATIONS.THRESHOLD_CREATE_ERROR',

  THRESHOLD_UPDATE: 'MY_ASD.CONFIGURATIONS.THRESHOLD_UPDATE',
  THRESHOLD_UPDATE_LOADING: 'MY_ASD.CONFIGURATIONS.THRESHOLD_UPDATE_LOADING',
  THRESHOLD_UPDATE_SUCCESS: 'MY_ASD.CONFIGURATIONS.THRESHOLD_UPDATE_SUCCESS',
  THRESHOLD_UPDATE_ERROR: 'MY_ASD.CONFIGURATIONS.THRESHOLD_UPDATE_ERROR',

  THRESHOLD_DELETE: 'MY_ASD.CONFIGURATIONS.THRESHOLD_DELETE',
  THRESHOLD_DELETE_LOADING: 'MY_ASD.CONFIGURATIONS.THRESHOLD_DELETE_LOADING',
  THRESHOLD_DELETE_SUCCESS: 'MY_ASD.CONFIGURATIONS.THRESHOLD_DELETE_SUCCESS',
  THRESHOLD_DELETE_ERROR: 'MY_ASD.CONFIGURATIONS.THRESHOLD_DELETE_ERROR',

  RESET: 'MY_ASD.CONFIGURATIONS.RESET',
};
