import styled from '@emotion/styled';

export const FloatingCardSingleStyled = styled.div(({ item }: FloatingCardStyledProps) => ({
  backgroundColor: "#fff",
  borderRadius: '15px',
  maxWidth: "400px",
  height: "100px",
  width: "20rem",
  zIndex: 2,
  position: "absolute",
  top: `${(120*item)+100}px`,
  right: "50px",
  //transition: 'box-shadow 0.3s ease 0.05s, transform 0.3s ease',
  padding: "10px 5px 10px 20px",
  color: "#444",
  //boxShadow: '15px 15px 20px 0px #60606052',
  cursor: "pointer",
  overflow: "hidden",

  '&&&:hover': {
    // boxShadow: '20px 20px 20px 0px #60606052',
    // transform: 'scale(1.05)',

    '&:before': {
      opacity: '100%',
      transition: 'opacity 0.3s ease',
    },
  },

  '&.level-3': {
    '&:hover': {
      '&:before': {
        boxShadow: "0 0 80px"
      }
    }
  },

}));


interface FloatingCardStyledProps {
  item?: any;
  height?: string;
}
