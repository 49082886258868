import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button as Btn, Container, Divider, Header, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import ButtonValidationModal from 'components/ButtonValidationModal';
import { Main } from 'components/Layouts';
import Loader from 'components/Loader';
import NewNewsModal from './NewNews/newNews';
import { Spacer, Wrapper } from 'components/core';
import NewsModal from 'components/NewsModal/newsModal';
import {reallyshortDate} from 'services/helper/dateFormater';

const News = ({ dispatch, news, loadingHandlerNews, services, user }) => {

  const isAdminOrOp = user.admin || user.operator || user.superOperator;

  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [resetSearch, setresetSearch] = useState(false);
  const [newNewsModalOpen, setnewNewsModalOpen] = useState(false);
  const [newsToDisplay, setnewsToDisplay] = useState(null);
  const [servicesList, setservicesList] = useState(null);

  useEffect(() => {
    if(user){ // client
      dispatch(
        actions.news.getAll()
      );
      dispatch(actions.socket.subscribeUpdateNews());

      return () => {
        dispatch(actions.news.reset(['news', 'error']))
        dispatch(actions.socket.unsubscribeUpdateNews());
      };
    }
  }, [dispatch, user]);

  useEffect(() => {
    setdata(_.orderBy(news, ['createdAt'], ['desc']));
    resetSearch && setresetSearch(false);
  }, [news, resetSearch]);

  useEffect(() => {
    services &&
      setservicesList([
        { id: 100, name: 'Global MyASD' },
        { id: 0, name: 'MyASD Dashboard' },
        ...services,
      ]);
  }, [services]);

  const handleSort = (lastName) => {
    if (column !== lastName) {
      setcolumn(lastName);
      setdirection('descending');
      setdata(_.sortBy(data, [lastName]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const handleDelete = (newsId) => {
    dispatch(actions.news.remove({ params: { newsId } }));
  };
  return (
    <Main>
      <Divider hidden />
      {(data.length === 0 && loadingHandlerNews) ? <Loader /> : (
        <Container>
          {isAdminOrOp ? (
          
              <Button
              content='Write News'
              size='big'
              icon='plus'
              type='button'
              labelPosition='right'
              onClick={() => setnewNewsModalOpen(true)}
              margin='5px'
              />
      
          ) : (
            <Wrapper textAlign='center'>
              <Header size='huge'>News</Header>
            </Wrapper>
            
          )}
          {newNewsModalOpen && (
            <NewNewsModal
              open={newNewsModalOpen}
              handleClose={() => setnewNewsModalOpen(false)}
            />
          )}
          <NewsModal
            open={newsToDisplay ? true : false}
            handleClose={() => {
              setnewsToDisplay(null);
            }}
            news={newsToDisplay}
          />
          <Spacer height='2rem' />
          <Table size='large' columns='6' sortable selectable>
            <Table.Header>
              <Table.Row>
                {!isAdminOrOp && (
                    <Table.HeaderCell
                    sorted={column === 'name' ? direction : null}
                    onClick={() => handleSort('name')}
                    >
                    Date
                    </Table.HeaderCell>
                )}
                <Table.HeaderCell
                  sorted={column === 'name' ? direction : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'service' ? direction : null}
                  onClick={() => handleSort('firstName')}
                >
                  Service
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'message' ? direction : null}
                  onClick={() => handleSort('message')}
                >
                  Message
                </Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((news, i) => {
                return (
                  <Table.Row key={i}>
                    {!isAdminOrOp && (
                    <Table.Cell>{reallyshortDate(new Date(news.createdAt))}</Table.Cell>
                    )}
                    <Table.Cell>{news.name}</Table.Cell>
                    <Table.Cell>
                      {servicesList &&
                        servicesList.forEach((service) => {
                          if (service.id === +news.service) {
                            return service.name;
                          }
                        })}
                    </Table.Cell>
                    <Table.Cell>
                      <Wrapper
                        maxWidth='40rem'
                        textOverflow='ellipsis'
                        overflow='hidden'
                        whiteSpace='nowrap'
                      >
                        {news.message}
                      </Wrapper>
                    </Table.Cell>
                    <Table.Cell>
                      <Btn.Group vertical widths='two'>
                        <Button
                          content='Show'
                          icon='eye'
                          type='submit'
                          labelPosition='right'
                          onClick={() => setnewsToDisplay(news)}
                          margin='5px'
                        />
                        {isAdminOrOp && (
                          <ButtonValidationModal
                            trgrContent='Delete'
                            headerContent='Delete'
                            modalContent='Do you confirm the deletion of this news?'
                            trgrColor='red'
                            trgrIcon='trash'
                            headerIcon='trash'
                            onClick={() => {
                              handleDelete(news.id);
                            }}
                            margin='5px'
                        />
                        )}
                      </Btn.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Container>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    news: selectors.news.allNewsSelector(state),
    errorHandlerNews: selectors.news.errorSelector(state),
    loadingHandlerNews: selectors.news.loadingSelector(state),
    services: selectors.service.servicesSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(News);
