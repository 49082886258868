import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Header, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import { Link } from 'react-router-dom';
import { paths } from 'routes/fiscal/collectionBox';
import Search from '../../components/Search';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import LinkButton from 'components/core/LinkButton/LinkButton';

const Index = ({ 
  dispatch, 
  companies,
  companiesLoading,
  userCompanies,
  permissions,
  contacts,
  contactsLoading,
  companySelected,
  user,
 }) => {

  const userAdminOrOp = user && (user.operator || user.admin);
  const isOwner = user && !user.operator;
  const userAdmin = user && user.admin;

  const [column, setcolumn] = useState(null);
  const [countrysList, setCountryList] = useState([]);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState(null);

  useEffect(() => {
  if(userAdminOrOp){
    dispatch(actions.collectionbox.getCompanySubscribed());
    dispatch(actions.collectionbox.getPermissions({
      params: {
        companyId: companySelected ? companySelected : 49,
        operatorId: user.id
      }
    }));
    return () => {
      dispatch(actions.collectionbox.reset(['companySubscribed', 'permissions', 'error']));
    };
  }else{
    dispatch(
      actions.contact.getAllContacts({
        params: { companyId: companySelected},
      })
    );
    dispatch(
    actions.companyUser.getUserCompanies({
      params: { userId: user.id},
    })
    );
    return () => {
      dispatch(actions.contact.reset(['contacts', 'error']));
      dispatch(actions.companyUser.reset(['companyUser', 'error']));
    }
  }
        
  }, [dispatch, user, companySelected, userAdminOrOp]);


  useEffect(() => {
    if(userAdminOrOp){
      if(companies){
        setdata(_.orderBy(companies, ['name'], ['asc']));
      }
    }else{
      if(contacts){
        setdata(_.orderBy(contacts, ['name'], ['asc']));
      }
    }

    if(
      permissions && 
      permissions.collectionboxPermissions && 
      permissions.collectionboxPermissions.length
    ){
      setCountryList(permissions.collectionboxPermissions.map((e, i) => {
        return {
          key: i,
          name: e.name,
          iso: e.iso,
        }
      }))
    }
  }, [companies, userCompanies, permissions, contacts, userAdminOrOp]);


  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['name'], ['asc']));
  };

  return (
    <Wrapper overflow='auto' width='80%' margin='auto'>
      <Divider hidden />
      <Wrapper padding='2rem' textAlign='center'>
        <Header size='huge'>
          VAT Collectionbox 
        <Header.Subheader>{isOwner && `(${user.firstName}-${user.lastName})` || `(Operator)`}</Header.Subheader>
        </Header>
        <Wrapper>
        <Wrapper display='flex' justifyContent='center'>
        <LinkButton
          content='Go to monitoring'
          color='blue'
          path={paths.collectionboxMonitoring}
        />
      </Wrapper>    
      </Wrapper>
      </Wrapper>
      {
      ((data === null || companiesLoading) && <Loader />) || 
      ((userAdminOrOp || isOwner) && (<>
      {(userAdminOrOp || isOwner) && (
        <Wrapper>
        <Wrapper display='flex' justifyContent='space-between'>
          <Search
            dataSource={companies}
            onSearchResultChange={onSearchResultChange}
          />
          </Wrapper>
          <Table size='large' columns='3' sortable selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'name' ? direction : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'email' ? direction : null}
                  onClick={() => handleSort('email')}
                >
                  E-mail
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                  Country
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={3} />) ||
                data.map((company, i) => {
                  return (
                    <Table.Row key={i}>
                      <Link
                        to={`/fiscal/collectionbox/${company.id}`}
                        className='table-link'
                      >
                        <Table.Cell>{company.name}</Table.Cell>
                        <Table.Cell>{company.email}</Table.Cell>
                        <Table.Cell>{company.country}</Table.Cell>
                      </Link>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>)}
        <Divider hidden />
        {userAdminOrOp && permissions && (
        <Wrapper>
          <Wrapper display='flex' justifyContent='space-between'>
          </Wrapper>
          <Table size='large' columns='2' sortable selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'country_name' ? direction : null}
                  onClick={() => handleSort('country_name')}
                >
                  Country
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country_code' ? direction : null}
                  onClick={() => handleSort('country_code')}
                >
                  Code
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(countrysList.length === 0 && <EmptyTable colNumber={2} />)}
              { countrysList.map((c, i) => {
                  return (
                    <Table.Row key={i}>
                      <Link
                        to={{
                          pathname: `/fiscal/collectionbox/country/:iso`
                          .replace(':iso', c.iso.toLowerCase()), 
                          state: c
                        }}
                        className='table-link'
                      >
                        <Table.Cell>{c.name.split('-')[1]}</Table.Cell>
                        <Table.Cell>{c.iso}</Table.Cell>                
                      </Link>
                    </Table.Row>
                  );
              })}
            </Table.Body>
          </Table>
        </Wrapper>)}
        </>
      ))}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: selectors.contact.contactsSelector(state),
    contactsLoading: selectors.contact.getContactsLoadingSelector(state),
    companies: selectors.collectionbox.companySubscribedSelector(state),
    companiesLoading: selectors.collectionbox.companySubscribedLoadingSelector(state),
    errorHandlerClients: selectors.company.errorSelector(state),
    userCompanies: selectors.companyUser.userCompaniesSelector(state),
    permissions: selectors.collectionbox.permsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
