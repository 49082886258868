import styled from "@emotion/styled";
import {
    Loader
} from "semantic-ui-react";

export const LoaderInline = styled(Loader)(() => (
    `&&& {
      z-index: 1;
    }`
));
