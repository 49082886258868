import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    createSetting: false,
    getCompanySetting: false,
    createCountries: false,
    countries: false
  },
  errors: {
    createSetting: null,
    getCompanySetting: null,
    createCountries: null,
    countries: null
  },
  loading: {
    createSetting: false,
    getCompanySetting: false,
    createCountries: false,
    countries: false
  },
  createSetting: null,
  getCompanySetting: null,
  createCountries: null,
  countries: null,
  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case actionTypes.GET_ASSIGNED_COUNTRIES_LOADING:
      return produce(state, draft => {
        draft.loading.countries = true;
      });
    case actionTypes.GET_ASSIGNED_COUNTRIES_SUCCESS:
      return produce(state, draft => {
        draft.errors.countries = null;
        draft.loading.countries = false;
        draft.success.countries = true;
        draft.countries = action.payload;
      });
    case actionTypes.GET_ASSIGNED_COUNTRIES_ERROR:
      return produce(state, draft => {
        draft.errors.getCompanySetting = true;
        draft.loading.getCompanySetting = false;
        draft.success.getCompanySetting = false;
        !draft.error.includes("countries") && 
        draft.error.push("countries");
      });

    case actionTypes.UPDATE_ASSIGNED_COUNTRIES_LOADING:
      return produce(state, draft => {
        draft.loading.createCountries = true;
      });
    case actionTypes.UPDATE_ASSIGNED_COUNTRIES_SUCCESS:
      return produce(state, draft => {
        draft.errors.createCountries = null;
        draft.loading.createCountries = false;
        draft.success.createCountries = true;
        draft.createCountries = action.payload;
      });
    case actionTypes.UPDATE_ASSIGNED_COUNTRIES_ERROR:
      return produce(state, draft => {
        draft.errors.createCountries = true;
        draft.loading.createCountries = false;
        draft.success.createCountries = false;
        !draft.error.includes("createCountries") && 
        draft.error.push("createCountries");
      });

    // Get all Settingess
    case actionTypes.CREATE_SETTING_LOADING:
      return produce(state, draft => {
        draft.loading.createSetting = true;
      });

    case actionTypes.CREATE_SETTING_SUCCESS:
      return produce(state, draft => {
        draft.errors.createSetting = null;
        draft.loading.createSetting = false;
        draft.success.createSetting = true;
      });

    case actionTypes.CREATE_SETTING_ERROR:
      return produce(state, draft => {
        draft.errors.createSetting = true;
        draft.loading.createSetting = false;
        draft.success.createSetting = false;
        !draft.error.includes("createSetting") &&
          draft.error.push("createSetting");
      });

    // Add Settinges
    case actionTypes.GET_SETTING_LOADING:
      return produce(state, draft => {
        draft.loading.getCompanySetting = true;
      });

    case actionTypes.GET_SETTING_SUCCESS:
      return produce(state, draft => {
        draft.errors.getCompanySetting = null;
        draft.loading.getCompanySetting = false;
        draft.success.getCompanySetting = true;
        draft.getCompanySetting = action.payload;
      });

    case actionTypes.GET_SETTING_ERROR:
      return produce(state, draft => {
        draft.errors.getCompanySetting = true;
        draft.loading.getCompanySetting = false;
        draft.success.getCompanySetting = false;
        !draft.error.includes("getCompanySetting") && 
        draft.error.push("getCompanySetting");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
