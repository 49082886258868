import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import { Image } from "semantic-ui-react";

const bounce = keyframes`
from, 20%, 53%, 80%, to {
  transform: translate3d(0,0,0);
}

40%, 43% {
  transform: translate3d(0, -30px, 0);
}

70% {
  transform: translate3d(0, -15px, 0);
}

90% {
  transform: translate3d(0,-4px,0);
}
`;

const rotate = keyframes`
to {
  transform:rotate(360deg);
}
`;

export const MainWrapper = styled.div((props: any) => {
  return {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: `url(${props.backgroundImg}) no-repeat fixed center`,
    fontSize: "30px",
    color: "white"
  };
});

export const NotFound = styled.h1((props: any) => {
  return {
    fontSize: "20em",
    color: "white",
    textShadow: "black 3px 5px 10px",
    userSelect: "none",
    animation: `${bounce} 1.5s ease infinite`
  };
});

export const Img = styled(Image)`
  width: 15%;
  height: 15%;
  src: ${(props: any) => props.src};
  animation: ${rotate} 2s linear infinite;
  position: relative;
  z-index: 10;
`;
