import actionTypes from 'store/customs/statistiques/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.GET_CUSTOMS_COMPARISON:
        try {
          next({ type: actionTypes.GET_CUSTOMS_COMPARISON_LOADING });
       
          const res = await request.getDeclarationForComparison(action.payload)
          next({
            type: actionTypes.GET_CUSTOMS_COMPARISON_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_CUSTOMS_COMPARISON_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_LAST_CUSTOMS:
        try {
          next({ type: actionTypes.GET_LAST_CUSTOMS_LOADING });
       
          const res = await request.getLastCustoms(action.payload);
          next({
            type: actionTypes.GET_LAST_CUSTOMS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_LAST_CUSTOMS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_STATS_BY_FILTERS:
        try {
          next({ type: actionTypes.GET_STATS_BY_FILTERS_LOADING });
       
          const res = await request.getDeclarationsStatsWithFilters(action.payload);
          next({
            type: actionTypes.GET_STATS_BY_FILTERS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_STATS_BY_FILTERS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_COMPUTED_CHEDS:
        try {
          next({ type: actionTypes.GET_COMPUTED_CHEDS_LOADING });
          const res = await request.getComputedCheds(action.payload);
          next({
            type: actionTypes.GET_COMPUTED_CHEDS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_COMPUTED_CHEDS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
  
      case actionTypes.GET_CLERK_STATS:
          try {
            next({ type: actionTypes.GET_CLERK_STATS_LOADING });
            const res = await request.getCustomsClerkStats(action.payload);
            next({
              type: actionTypes.GET_CLERK_STATS_SUCCESS,
              payload: res.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_CLERK_STATS_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;
  
      default:
          next(action);
          break;
    }
  };
}
