// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tippy from 'tippy.js';

import MissionTypeOption from './missionTypeOption';

import 'tippy.js/dist/tippy.css';
import './orderForm.css';

interface MissionTypeOptionsProperties {
  setCount: React.Dispatch<React.SetStateAction<number>>;
}

const MissionTypeOptions: React.FC<MissionTypeOptionsProperties> = ({
  setCount,
}) => {
  // Parameter that must be used before each translatable string to apply the good language
  const { t } = useTranslation();

  // Options setters
  const sipsiSelectedOption = window.sessionStorage.getItem(
    'sipsiSelectedOption'
  );
  const constructionCardSelectedOption = window.sessionStorage.getItem(
    'constructionCardSelectedOption'
  );

  // Reloader
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  // Tooltips references
  const sipsiTooltipRef = useRef(null);
  const representationTooltipRef = useRef(null);
  const constructionCardTooltipRef = useRef(null);

  // Sipsi options
  const sipsiOptions = [
    { id: 'true', label: t('orderPageStrings.missionTypeOptions.yesOption') },
    { id: 'false', label: t('orderPageStrings.missionTypeOptions.noOption') },
  ];

  // Construction card options
  const constructionCardOptions = [
    { id: 'true', label: t('orderPageStrings.missionTypeOptions.yesOption') },
    { id: 'false', label: t('orderPageStrings.missionTypeOptions.noOption') },
  ];

  // Defines the action that must be executed when use performed a click on a specific sipsi option
  const handleSipsiOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    window.sessionStorage.setItem('sipsiSelectedOption', event.target.value);
    handleIncrement();
  };

  // Defines the action that must be executed when use performed a click on a specific construction card option
  const handleConstructionCardOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    window.sessionStorage.setItem(
      'constructionCardSelectedOption',
      event.target.value
    );
    handleIncrement();
  };

  // Tooltip configuration
  useEffect(() => {
    if (sipsiTooltipRef.current) {
      tippy(sipsiTooltipRef.current, {
        content: t(
          'orderPageStrings.missionTypeOptions.missionOptionsTooltips.sipsiTooltip'
        ),
        placement: 'right',
        arrow: true,
      });
    }

    if (representationTooltipRef.current) {
      tippy(representationTooltipRef.current, {
        content: t(
          'orderPageStrings.missionTypeOptions.missionOptionsTooltips.representationTooltip'
        ),
        placement: 'right',
        arrow: true,
      });
    }

    if (constructionCardTooltipRef.current) {
      tippy(constructionCardTooltipRef.current, {
        content: t(
          'orderPageStrings.missionTypeOptions.missionOptionsTooltips.constructionCardTooltip'
        ),
        placement: 'right',
        arrow: true,
      });
    }
  });

  return (
    <div>
      <MissionTypeOption
        title={t('orderPageStrings.missionTypeOptions.sipsiTitle')}
        sessionStorageConst={sipsiSelectedOption}
        optionsList={sipsiOptions}
        tooltipReference={sipsiTooltipRef}
        handleChange={handleSipsiOptionChange}
        inputName='sipsiOptions'
      />
      {window.sessionStorage.getItem('isClickedConstruction') === 'true' ? (
        <MissionTypeOption
          title={t('orderPageStrings.missionTypeOptions.constructionCardTitle')}
          sessionStorageConst={constructionCardSelectedOption}
          optionsList={constructionCardOptions}
          tooltipReference={constructionCardTooltipRef}
          handleChange={handleConstructionCardOptionChange}
          inputName='constructionCardOptions'
        />
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default MissionTypeOptions;
