// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tippy from 'tippy.js';

// Components
import Loader from 'components/Loader';
import TextInput from '../textInput/textInput';

// Resources
import tooltip from '../../assets/images/logos/tooltip.png';
import saveLogo from '../../assets/images/logos/save.png';

// Interfaces
import UpdateGlobalCompanyDetailsProperties from '../../interfaces/updateGlobalCompanyDetailsProperties';

// Stylesheets
import './forms.css';
import 'tippy.js/dist/tippy.css';

/**
 * @name updateGlobalCompanyDetails.tsx
 * @implements updateFormsProperties.tsx
 *
 * @path /components/updateForms/updateGlobalCompanyDetails.tsx
 *
 * @description Defines content for update global company details popup in CompanyInformation component
 *
 * @param ref popup content reference
 * @returns UpdateGlobalCompanyDetails component
 */
const UpdateGlobalCompanyDetails: React.FC<
  UpdateGlobalCompanyDetailsProperties
> = ({
  ref,
  id,
  defaultCompanyName,
  defaultLegalStatus,
  defaultVatNumber,
  contactId,
  defaultContactLastName,
  defaultContactFirstName,
  defaultContactMailAddress,
  defaultContactPhoneNumber,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);

  // Variables to temporarily save values until they're send to backoffice
  const [companyName, setCompanyName] = useState<string>(defaultCompanyName);
  const [legalStatus, setLegalStatus] = useState<string>(defaultLegalStatus);
  const [vatNumber, setVatNumber] = useState<string>(defaultVatNumber);
  const [contactLastName, setContactLastName] = useState<string>(
    defaultContactLastName
  );
  const [contactFirstName, setContactFirstName] = useState<string>(
    defaultContactFirstName
  );
  const [contactMailAddress, setContactMailAddress] = useState<string>(
    defaultContactMailAddress
  );
  const [contactPhoneNumber, setContactPhoneNumber] = useState<string>(
    defaultContactPhoneNumber
  );

  const handleCompanyNameChange = (value: string) => {
    setCompanyName(value);
  };

  const handleLegalStatusChange = (value: string) => {
    setLegalStatus(value);
  };

  const handleVatNumberChange = (value: string) => {
    setVatNumber(value);
  };

  const handleContactLastNameChange = (value: string) => {
    setContactLastName(value);
  };

  const handleContactFirstNameChange = (value: string) => {
    setContactFirstName(value);
  };

  const handleContactMailAddressChange = (value: string) => {
    setContactMailAddress(value);
  };

  const handleContactPhoneNumberChange = (value: string) => {
    setContactPhoneNumber(value);
  };

  const legalStatusTooltipRef = useRef(null);
  const vatNumberTooltipRef = useRef(null);

  useEffect(() => {
    if (legalStatusTooltipRef.current) {
      tippy(legalStatusTooltipRef.current, {
        content: t('companyPageStrings.legalStatusTooltip'),
        placement: 'right',
        arrow: true,
      });
    }
    if (vatNumberTooltipRef.current) {
      tippy(vatNumberTooltipRef.current, {
        content: t('companyPageStrings.vatNumberTooltip'),
        placement: 'right',
        arrow: true,
      });
    }
  });

  const updateCompanyGlobalDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateCompany/globalDetails`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            companyId: id,
            companyName: companyName,
            legalStatus: legalStatus,
            vatNumber: vatNumber,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const updateContactDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateContact/contactDetails`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            contactId: contactId,
            lastName: contactLastName,
            firstName: contactFirstName,
            mailAddress: contactMailAddress,
            phoneNumber: contactPhoneNumber,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const execUpdateCompanyGlobalDetails = async () => {
    setIsLoading(true);

    await updateCompanyGlobalDetails();
  };

  const execUpdateContactDetails = async () => {
    setIsLoading(true);

    await updateContactDetails();
  };

  const isEmpty = (string: string) => {
    return string.trim() === '';
  };

  const handleClickSave = () => {
    if (
      companyName == undefined ||
      legalStatus == undefined ||
      vatNumber == undefined ||
      contactMailAddress == undefined ||
      contactPhoneNumber == undefined ||
      isEmpty(companyName) ||
      isEmpty(legalStatus) ||
      isEmpty(vatNumber) ||
      isEmpty(contactMailAddress) ||
      isEmpty(contactPhoneNumber)
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execUpdateCompanyGlobalDetails();
      execUpdateContactDetails();
      setIsLoading(false);
      window.location.reload();
    }
  };

  return (
    <>
      {(isLoading && <Loader />) ||
        (!isLoading && (
          <>
            <div className='companyGlobalInformationContainer' ref={ref}>
              <div className='companyGlobalInformationSubContainer'>
                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>{t('companyPageStrings.companyNameFieldPlaceHolder')}</b>
                  </p>
                  <TextInput
                    classname='updateCompanyGlobalField'
                    initialValue={companyName}
                    onSave={handleCompanyNameChange}
                  />
                </div>
                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>{t('companyPageStrings.legalStatusFieldPlaceHolder')}</b>
                  </p>
                  <div className='companyEditAndTooltipContainer'>
                    <TextInput
                      classname='updateCompanyGlobalField'
                      initialValue={legalStatus}
                      onSave={handleLegalStatusChange}
                    />
                    <img
                      src={tooltip}
                      alt='tooltipButton'
                      className='companyGlobalTooltip'
                      ref={legalStatusTooltipRef}
                    />
                  </div>
                </div>
                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>{t('companyPageStrings.vatNumberFieldPlaceHolder')}</b>
                  </p>
                  <div className='companyEditAndTooltipContainer'>
                    <TextInput
                      classname='updateCompanyGlobalField'
                      initialValue={vatNumber}
                      onSave={handleVatNumberChange}
                    />
                    <img
                      src={tooltip}
                      alt='tooltipButton'
                      className='companyGlobalTooltip'
                      ref={vatNumberTooltipRef}
                    />
                  </div>
                </div>
              </div>
              <div className='companyGlobalInformationSubContainer'>
                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>
                      {t('companyPageStrings.contactLastNameFieldPlaceHolder')}
                    </b>
                  </p>
                  <TextInput
                    classname='updateCompanyGlobalField'
                    initialValue={contactLastName}
                    onSave={handleContactLastNameChange}
                  />
                </div>

                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>
                      {t('companyPageStrings.contactFirstNameFieldPlaceHolder')}
                    </b>
                  </p>
                  <TextInput
                    classname='updateCompanyGlobalField'
                    initialValue={contactFirstName}
                    onSave={handleContactFirstNameChange}
                  />
                </div>

                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>
                      {t(
                        'companyPageStrings.contactMailAddressFieldPlaceHolder'
                      )}
                    </b>
                  </p>
                  <TextInput
                    classname='updateCompanyGlobalField'
                    initialValue={contactMailAddress}
                    onSave={handleContactMailAddressChange}
                  />
                </div>

                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>
                      {t(
                        'companyPageStrings.contactPhoneNumberFieldPlaceHolder'
                      )}
                    </b>
                  </p>
                  <TextInput
                    classname='updateCompanyGlobalField'
                    initialValue={contactPhoneNumber}
                    onSave={handleContactPhoneNumberChange}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className='space'></div>
              {isErrorHidden ? (
                <></>
              ) : (
                <div className='companyError'>
                  <p>{t('orderPageStrings.error')}</p>
                </div>
              )}
              <div className='saveButtonContainer'>
                <button className='saveButton' onClick={handleClickSave}>
                  <p>{t('popupStrings.saveText')}</p>
                  <img
                    src={saveLogo}
                    alt={t('popupStrings.saveLogo')}
                    className='saveLogo'
                  />
                </button>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default UpdateGlobalCompanyDetails;
