import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import { actions, selectors } from 'store';
import {
  Divider,
  Grid,
  Header,
  Tab,
  Menu,
  Label
} from 'semantic-ui-react';
import Button from 'components/Button';

import ButtonBack from 'components/ButtonBack/ButtonBack';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import UploadsModal from './UploadsModal';
import MandatesList from './MandatesList';

const Mandates = ({
  dispatch,
  match,
  history,
  user,
  companySelected,
  mandates,
  loadingHandlerMandates
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected; //companySelected 

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const thirdParty = "Exchange"

  useEffect(() => {
    dispatch(
      actions.customsDeclaration.getMandates({
        params: { companyId },
      })
    );

    dispatch(actions.socket.subscribeMandates(companyId));

    return () => {
      dispatch(
        actions.customsDeclaration.reset([
          'mandates',
          'error',
        ])
      );
      dispatch(actions.socket.unsubscribeMandates(companyId));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    mandates && setData(_.sortBy(mandates))
  }, [mandates]);

  // const handleSort = (name) => {
  //   if (column !== name) {
  //     setcolumn(name);
  //     setdirection('descending');
  //     setData(_.sortBy(data, [name]));
  //     return;
  //   }
  //   setData(data.reverse());
  //   setdirection(direction === 'descending' ? 'ascending' : 'descending');
  // };

  const handleClose = () => {
    setIsModalOpen(false)
  }

 
  return (
    <Wrapper display='flex' justifyContent='center'>
    {((loadingHandlerMandates) && <Loader />) || (
      <Wrapper height='100%' width='80%' overflow='auto'>
        <Divider hidden />
        <Wrapper display='flex'>
          <ButtonBack history={history} />
        </Wrapper>
        <Divider hidden />
        <Header size='huge' textAlign='center'>Mandates</Header>

    <Divider hidden />
      <Wrapper height='100%' overflow='auto'>
      {isModalOpen && (
        <UploadsModal
          open={isModalOpen}
          handleClose={handleClose}
          match={match}
        />
      )}
      {mandates && (
      <Tab panes={[
            {
              menuItem: (
                <Menu.Item key={0}>
                  {thirdParty}
                  <Label>{mandates.length}</Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Grid>
                    <Grid.Column>
                      <Header textAlign='center' size='small'>
                        Dropped mandates
                      </Header>
                        <MandatesList
                          key={0}
                          receivedData={mandates}
                          thirdParty={thirdParty}
                          companyId={companyId}
                          data={data}
                        
                        />                       
                    </Grid.Column>                     
                  </Grid>
                </Tab.Pane>
              ),
            }
        ]}/>
      )}
      <Wrapper>
      <Divider hidden />
          <Button
            type='button'
            size='large'
            icon='upload'
            color='blue'
            content='Upload a document'
            margin='5px'
            onClick={() => setIsModalOpen(true)}
          />
          </Wrapper>
      </Wrapper>
  </Wrapper> )}
  </Wrapper> 
  );
}
const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    loading: selectors.customsDeclaration.loadingSelector(state),
    mandates: selectors.customsDeclaration.getMandatesSelector(state),
    loadingHandlerMandates: selectors.customsDeclaration.getMandatesLoadingSelector(state),
    errorHandlerMandates: selectors.customsDeclaration.getMandatesErrorsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Mandates);
