import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button as Btn} from 'semantic-ui-react';
import Button from 'components/Button';
import { paths } from 'routes/fiscal/repository';
import ButtonValidationModalS from '../../components/ButtonValidationModal';
import ButtonValidationModal from '../../../../../components/ButtonValidationModal';
import { Status } from './DocumentsStyles';
import { shortDate } from 'services/helper/dateFormater';


const Documents = ({
  document,
  declarationState,
  index,
  userAdminOrOp,
  companyId,
  icon,
  declarationId,
  handleShowDocument,
  handleDeleteDocument,
  handleCheckDocument,
  openTagManager,
}: DocumentsProps) => {

    return (  
        <Table.Row key={index}>
            <Table.Cell>{document.name}</Table.Cell>
            <Table.Cell>
            {(userAdminOrOp && document.TaxFlowDocumentCategory.id === 1 &&
              <Link to={
                paths.repositoryExcel
                .replace(':companyId', companyId.toString())
                .replace(':declarationId', declarationId)
                .replace(':documentId', document.id)} className='folder_link_link'>
                {document.TaxFlowDocumentCategory.name}
              </Link>) || document.TaxFlowDocumentCategory.name
            }              
            </Table.Cell>
            <Table.Cell>
              {shortDate(new Date(document.createdAt))}
            </Table.Cell>
            <Table.Cell>
              <Status color={document.status === 'validated' ? 'green' : 'red'}>{document.status}</Status>
            </Table.Cell>    
            <Table.Cell> 
            {!userAdminOrOp && document.TaxFlowDocumentCategory.id === 2 && (              
              <Btn.Group vertical widths='one'>         
                <ButtonValidationModalS
                  noSubmit
                  trgrContent='Validate'
                  headerContent='Validate'
                  modalContent='Is the document compliant?'
                  trgrColor='bleu'
                  disabled={document.status !== null}
                  onClick={(status) => {
                    handleCheckDocument(document.id, status);
                  }}
                  margin='5px'
                />  
              </Btn.Group>  
            )}                                        
            </Table.Cell>    
            <Table.Cell>            
              <Btn.Group vertical widths='one'>
                <Button
                  type='submit'
                  margin='5px'
                  content='Show'
                  color='blue'
                  onClick={() => {
                    handleShowDocument(document.id)}}
                />          
                {declarationState && (
                  <ButtonValidationModal
                    noSubmit
                    trgrContent='Delete'
                    headerContent='Delete'
                    modalContent='Do you confirm the deletion of this document?'
                    trgrColor='red'
                    disabled={!declarationState || (!userAdminOrOp && document.TaxFlowDocumentCategory.id === 6)}
                    onClick={() => {
                      handleDeleteDocument(document.id)}}
                    margin='5px'
                  />  
                )}                       
              </Btn.Group>           
            </Table.Cell>
        </Table.Row>
    )
}

interface DocumentsProps {
    docList?: any,
    icon?: any,
    declarationState: boolean, 
    document: any,
    index: number,
    userAdminOrOp: boolean,
    companyId: number,
    declarationId: string,
    handleShowDocument: (docId) => void;
    handleDeleteDocument: (docId) => void;
    handleCheckDocument: (docId, status) => void;
    openTagManager?: () => void;
  }
  
  export default Documents;