import React, { useEffect, useState } from 'react';
import { Formiz, useForm } from '@formiz/core';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Header, Modal, Message } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Spacer from 'components/core/Spacer/Spacer';
import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';
import FormizDropdown from 'components/FormizField/FormizDropdown/FormizDropdown';
import FormizTextAreaInput from 'components/FormizField/FormizTextAreaInput/FormizTextAreaInput';

const Index = ({
  dispatch,
  open,
  handleClose,
  services,
  news,
  deleteNewsSuccess,
  deleteAllNewsSuccess,
  deleteNewsLoading,
  deleteAllNewsLoading,
}) => {
  const signUpForm = useForm();
  const [servicesList, setservicesList] = useState([]);
  const [selectedService, setselectedService] = useState(null);
  const [alertDelete, setalertDelete] = useState(false);
  const [existingNewsId, setexistingNewsId] = useState(null);
  const [deleteNewsHasLoaded, setdeleteNewsHasLoaded] = useState(false);
  const [createNewsValues, setcreateNewsValues] = useState(null);

  useEffect(() => {
    dispatch(actions.services.getAll());
    return () => {
      dispatch(actions.services.reset(['services', 'error']));
    };
  }, []);

  useEffect(() => {
    if (services !== null) {
      setservicesList([
        {
          key: 1000,
          value: 99,
          text: 'New Release',
        },
        { key: services.length + 2, value: 100, text: 'Global MyASD' },
        {
          key: services.length + 3,
          value: 0,
          text: 'MyASD Dashboard',
        },
        ...services.map((service, index) => {
          return {
            key: index,
            value: service.id,
            text: service.name,
          };
        }),
      ]);
    }
  }, [services]);


  useEffect(() => {
    if (deleteNewsLoading || deleteAllNewsLoading) {
      setdeleteNewsHasLoaded(true);
    } else if (
      !deleteNewsLoading &&
      deleteNewsHasLoaded &&
      deleteNewsSuccess &&
      alertDelete &&
      existingNewsId &&
      setcreateNewsValues
    ) {
      dispatch(
        actions.news.create({
          data: {
            name: createNewsValues.name,
            service: createNewsValues.service,
            message: createNewsValues.message,
          },
        })
      );
      handleClose();
    } else if (
      !deleteAllNewsLoading &&
      deleteNewsHasLoaded &&
      deleteAllNewsSuccess &&
      alertDelete &&
      setcreateNewsValues
    ) {
      dispatch(
        actions.news.create({
          data: {
            name: createNewsValues.name,
            service: createNewsValues.service,
            message: createNewsValues.message,
          },
        })
      );
      handleClose();
    }
  }, [deleteNewsLoading, deleteAllNewsLoading]);

  const save = (values) => {
    if (!alertDelete) {
      dispatch(
        actions.news.create({
          data: {
            name: values.name,
            service: values.service,
            message: values.message,
          },
        })
      );
      handleClose();
    } else if (selectedService !== 100 && alertDelete && existingNewsId) {
      setcreateNewsValues(values);
      dispatch(actions.news.remove({ params: { newsId: existingNewsId } }));
    } else if (selectedService === 100 && alertDelete) {
      setcreateNewsValues(values);
      dispatch(actions.news.removeAll());
    }
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Wrapper
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <Wrapper maxWidth='700px' minWidth='25rem' margin='30px'>
          <Header></Header>
          <Formiz connect={signUpForm} onValidSubmit={save}>
            <form onSubmit={signUpForm.submit}>
              <Wrapper display='center'>
                <FormizSimpleInput
                  type='name'
                  name='name'
                  label='News name'
                  placeholder='Name'
                  required='Name is required'
                />
                <Spacer />
                <FormizDropdown
                  type='service'
                  name='service'
                  label='Location'
                  placeholder='Display location'
                  required='Service is required'
                  mandatory={true}
                  options={servicesList}
                  {...(!servicesList && { loading: false })}
                  onChange={(value) => {
                    setselectedService(value);
                  }}
                />
                {alertDelete && (
                  <Message negative>
                    <p>
                      A news for this service or a global news already exists.
                      It will be erased by this one.
                    </p>
                  </Message>
                )}
                <Spacer />
                <Wrapper {...(selectedService === null && { display: 'none' })}>
                  <FormizTextAreaInput
                    type='message'
                    name='message'
                    label='Message'
                    placeholder='Message'
                    required='Message is required'
                  />
                </Wrapper>
              </Wrapper>
              <Spacer height='10px' />
              <Button size='large' type='submit' content='Create' />
              <Spacer height='10px' />
            </form>
          </Formiz>
        </Wrapper>
      </Wrapper>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    services: selectors.service.servicesSelector(state),
    news: selectors.news.allNewsSelector(state),
    createNewsSuccess: selectors.news.createNewsSuccessSelector(state),
    createNewsLoading: selectors.news.createNewsLoadingSelector(state),
    newsLoading: selectors.news.loadingSelector(state),
    deleteNewsLoading: selectors.news.deleteNewsLoadingSelector(state),
    deleteAllNewsLoading: selectors.news.deleteAllNewsLoadingSelector(state),
    deleteNewsSuccess: selectors.news.deleteNewsSuccessSelector(state),
    deleteAllNewsSuccess: selectors.news.deleteAllNewsSuccessSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
