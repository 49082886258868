import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Grid,
  Header,
  Modal,
  Message,
  Form,
  Divider,
  Icon,
} from 'semantic-ui-react';
import { Input } from 'components/Form';
import Button from 'components/Button';
import CollectionboxIframe from './CollectionBoxIframe';
import { Delete, ListItems, Listing, Span, SplitWrapper, SplitButton, Strong } from './styles';
import { Field, Formik } from 'formik';
import { chunks } from 'interfaces/formSchema/collectionboxValidators';
import Dropdown from 'components/Dropdown';


const ModalSplitSingleFile = ({
  companyId,
  collectionboxId,
  data,
  chuncks,
  chuncksLoading,
  chuncksSuccess,
  sessionFiles,
  sessionFilesLoading,
  splitLoading,
  split,
  handleClose,
  dispatch,
  categoriesDocuments,
  categoriesLoading,
}: ModalSplitSingleProps) => {

  const [storage, setStorage]: any = useState(null)
  const [close, setIsOpen]: any = useState(null)
  const [categoriesDocumentsList, setCategoriesDocumentsList]: any = useState([]);
  const formikRef = useRef()

  const session = data.session
  let storageTab = useMemo(
    () => localStorage.getItem(`${data.documentId}`) !== null ? JSON.parse(localStorage.getItem(`${data.documentId}`) || '') : []
    , [data.documentId])

  useEffect(() => {
    dispatch(actions.collectionbox.getDocsFromCat({
      query: {type: data.type }
    }));
    return () => {
      dispatch(actions.collectionbox.reset(['docsFromCat']));
    };
  }, [dispatch, data.type]);

  useEffect(() => {
    if(chuncks && chuncks.length>0){
      setIsOpen(false)
    }
    if(!storage){
      setStorage(storageTab)
    }
  }, [sessionFiles, chuncks, storageTab, chuncksLoading, storage])

  useEffect(() => {
    if (categoriesDocuments) {
          setCategoriesDocumentsList(
            categoriesDocuments.map((category, index) => {
              return {
                key: index,
                value: category.id,
                text: category.name,
              };
            })
          );
        }
  }, [categoriesDocuments]);


  const getStorage = () => {
    return localStorage.getItem(`${data.documentId}`) !== null ?
    JSON.parse(localStorage.getItem(`${data.documentId}`) || '') : []
  }

  const splitDocument = (sessionFiles, session) => {   
    dispatch(actions.collectionbox.splitDocument({
      params: {
        companyId: companyId,
        collectionboxId: collectionboxId,
        documentId: data.documentId,
      },
      data:{
        files: sessionFiles,
        sessionNumber: session,
        slices: getStorage(), // all
        documentName: data.docName,
        docCategory: data.docCategory,
      }
    }))
    return () => {
      dispatch(actions.collectionbox.reset(['split', 'error']))
    }  
  }



  const saveLocalStorage = (values: {category, start, end, tag, newName}, sessionFiles) => {
      storageTab.push({start: values.start, end: values.end, category: values.category, selectedTag: values.tag, newName: values.newName})
      localStorage.setItem(`${data.documentId}`, JSON.stringify(storageTab))
      if(sessionFiles && data){
        dispatch(actions.collectionbox.renameSendChunk({
          params: {
            companyId: companyId,
            collectionboxId: collectionboxId,
            documentId: data.documentId,
          },
          data:{
            sessionNumber: session,
            slices: storageTab, // last
            documentName: data.docName,
            periodString: data.periodString,
            docCategory: values.category,
          }
        }))

        const ref: any = formikRef.current
        ref.resetForm();
        setStorage(null)

        return () => {
          dispatch(actions.collectionbox.reset(['renameSendChunk', 'error']))
        }  
      }
  }

  const deleteLocalStorage = (index) => {
    storageTab.splice(index, 1)
    localStorage.setItem(
      `${data.documentId}`, JSON.stringify(storageTab)
      )
    setStorage(null)
  }

  return (
    <Modal style={{'height': '90%', 'position': 'relative'}} open={close ? close : data.open} onClose={handleClose} loading={sessionFilesLoading} size='fullscreen' closeIcon>
      <Header icon='plus circle' content={`Manage tags VAT: ${data.collectionboxFlow}`} />
      <Modal.Content>
      <Message info>Split and send PDF Documents to correct repository. Don't delete your stored local data until you decide to definitly use the function "Split document"</Message>
      <Grid style={SplitWrapper} columns={2}>   
      <Grid.Column width={5}> 
      <Listing>
      {
        storageTab !== null && 
        storageTab.length>0 && 
        storageTab.map((e, index) => (
            <ListItems key={index}>From page: <Span>{e.start}</Span> to: <Span>{e.end}</Span> - Sent to: <Strong>{e.selectedTag}</Strong>
              <Delete onClick={() => { deleteLocalStorage(index) }}>Delete</Delete>
            </ListItems>
        ))
        }
      <Divider hidden />
      <Formik
        ref={formikRef}
        initialValues={{
          start: '',
          end: '',
          newName: '',
          category: '',
          tag: '',
        }}
        enableReinitialize={true}
        onSubmit={saveLocalStorage}
        validationSchema={chunks}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
          <Grid>
          <Grid.Row columns={2}>
          <Grid.Column>
          <Field
              label='Start'
              name='start'
              component={Input}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
          </Grid.Column>
          <Grid.Column>
          <Field
              label='End'
              name='end'
              component={Input}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
          </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
          <Grid.Column>
          <Field
              label='Name'
              name='newName'
              component={Input}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
          </Grid.Column>
          <Grid.Column>
          <Field
              label='Document category'
              name='category'
              component={Dropdown}
              loading={categoriesLoading}
              options={categoriesDocumentsList}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
          </Grid.Column>
          </Grid.Row>
          </Grid>            
            <Divider hidden />
            <Field
              label='tag name'
              name='tag'
              component={Dropdown}
              options={[
                {key: 1, value: 1, text: 'Introduction'},
                {key: 2, value: 2, text: 'Expedition'},
              ]}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
            <Divider hidden />
            <Button
             disabled={chuncksLoading}
             {...(chuncksLoading && {
               icon: <Icon loading name='spinner' />,
             })}
              type='submit'
              content='Submit'
              color='green'
            />
          </Form>
          )}
          </Formik>
        </Listing>
      </Grid.Column>
      <Grid.Column width={11}>
          <CollectionboxIframe companyId={companyId} sessionId={data.session} collectionboxId={data.collectionboxId} documentId={data.documentId}></CollectionboxIframe>
      </Grid.Column>
      </Grid>
      <Grid.Row style={SplitButton}>
      <Button
        disabled={splitLoading}
        {...(splitLoading && {
          icon: <Icon loading name='spinner' />,
        })}
        type='submit'
        content='Split document'
        color='blue'
        onClick={() => (
          splitDocument(sessionFiles, data.session)
        )
        }
      />
      </Grid.Row>
     
      
    </Modal.Content>
  </Modal>
  );
};

interface ModalSplitSingleProps {
  companyId: number,
  collectionboxId: string,
  data: {
    type: number,
    open: boolean;
    documentId: number;
    session: string;
    docCategory: number;
    docName: string;
    periodString: string
    companyName: string;
    collectionboxId: string;
    collectionboxFlow: string;
  },
  handleClose: () => void;
  splitDocument: (sessionFiles, session) => void;
  dispatch: (value: any) => void;
  categoriesDocuments,
  categoriesLoading,
  sessionFiles,
  sessionFilesLoading,
  chuncksLoading,
  chuncksSuccess,
  chuncks,
  split,
  splitLoading,
}

const mapStateToProps = (state) => {
  return {
    sessionFiles: selectors.collectionbox.getFilesFullListSelector(state),
    sessionFilesLoading: selectors.collectionbox.getFilesFullListLoadingSelector(state),
    chuncks: selectors.collectionbox.renameSendChuncksSelector(state),
    chuncksLoading: selectors.collectionbox.renameSendChuncksLoadingSelector(state),
    chuncksSuccess: selectors.collectionbox.renameSendChuncksSuccessSelector(state),
    split: selectors.collectionbox.splitDocumentSelector(state),
    splitLoading: selectors.collectionbox.splitDocumentLoadingSelector(state),
    categoriesDocuments: selectors.collectionbox.getDocsFromCatSelector(state),
    categoriesLoading: selectors.collectionbox.getDocsFromCatLoadingSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(ModalSplitSingleFile);
