import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

// export const FormGroup = styled(Form.Group)(({ margin }: any) => ({
//   display: "block",
//   justifyContent: "center"
// }));

export const FormGroup = styled(Form.Group)`
&&&{
  display: flex;
  justify-content: center;
}
`
