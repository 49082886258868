import React from "react";

import TableSpacedRows from "components/TableSpacedRows";

const Index = (props) => {
  return (
    <TableSpacedRows
      {...props}
      tdPadding="0 10px"
      tdBackgroundColor="lightGreyBg"
      tdBorderColor="smallTableBorder"
      borderSpacing="0 1rem"
    >
      {props.children}
    </TableSpacedRows>
  );
};

export default Index;
