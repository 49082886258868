import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Container,
  Divider,
  Header,
  Step,
  Table,
  Button as Btn,
} from 'semantic-ui-react';
import { actions, selectors } from 'store';

import { paths } from 'routes/fiscal/kyanite';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Main } from 'components/Layouts';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import Pagination from 'components/Pagination';
import NewMassIntegration from './New';

const Index = ({
  history,
  match,
  dispatch,
  user,
  companySelected,
  massIntegrations,
  getMassIntegrationsLoading,
  totalMassIntegrations,
  deleteMassIntegrationLoading,
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected;

  const [modalStatus, setmodalStatus] = useState(false);
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    dispatch(
      actions.quickfixes.getMassIntegrations({
        params: {
          companyId,
        },
      })
    );

    dispatch(actions.socket.subscribeKyaniteMassIntegration(companyId));

    return () => {
      dispatch(
        actions.quickfixes.reset([
          'massIntegrations',
          'totalMassIntegrations',
          'error',
        ])
      );
      dispatch(actions.socket.unsubscribeKyaniteMassIntegration(companyId));
    };
  }, [companyId, dispatch]);

  useEffect(() => {
    massIntegrations && setdata(massIntegrations);
  }, [massIntegrations]);

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const handleDelete = (massIntegrationId) => {
    dispatch(
      actions.quickfixes.deleteMassIntegration({
        params: {
          companyId,
          massIntegrationId,
        },
      })
    );
  };

  const handlePageChange = (event, data) => {
    dispatch(
      actions.quickfixes.getMassIntegrations({
        params: {
          companyId,
        },
        query: {
          limit: 10,
          page: Math.ceil(data.activePage),
        },
      })
    );
  };

  return (
    <Main>
      {(getMassIntegrationsLoading || deleteMassIntegrationLoading) && (
        <Loader content='Loading' />
      )}
      <Divider hidden />
      <Container>
        <Wrapper display='flex' justifyContent='space-between'>
          <Button
            floated='left'
            labelPosition='left'
            icon='angle left'
            position='left'
            type='button'
            content='Back'
            margin='5px'
            onClick={() =>
              history.push(
                userAdminOrOp
                  ? paths.kyaniteOperator.replace(':companyId', companyId)
                  : paths.deliveries
              )
            }
          />
        </Wrapper>
      </Container>
      <Header size='huge'>
        Kyanite
        <Header.Subheader>Mass integration</Header.Subheader>
      </Header>
      <Container>
        <Wrapper display='flex' justifyContent='space-between'>
          <Button
            labelPosition='right'
            position='left'
            icon='plus circle'
            type='button'
            content='New mass integration'
            onClick={() => setmodalStatus(true)}
          />
        </Wrapper>
      </Container>

      <Divider hidden />
      {modalStatus && (
        <NewMassIntegration
          open={modalStatus}
          handleClose={() => setmodalStatus(false)}
          companyId={companyId}
        />
      )}
      {
        <Container textAlign='center'>
          <Table columns='3' sortable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'id' ? direction : null}
                  onClick={() => handleSort('id')}
                >
                  Id
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'date' ? direction : 'ascending'}
                  onClick={() => handleSort('date')}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'state' ? direction : null}
                  onClick={() => handleSort('state')}
                >
                  State
                </Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={4} />) ||
                data.map((e, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{e.id}</Table.Cell>
                      <Table.Cell>
                        {new Date(e.createdAt).toLocaleDateString('en', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </Table.Cell>
                      <Table.Cell>
                        <Step.Group size='small'>
                          <Step>{e.state}</Step>
                        </Step.Group>
                      </Table.Cell>
                      <Table.Cell>
                        <Btn.Group vertical widths='two'>
                          <Button
                            disabled={e.state === 'Processing'}
                            type='submit'
                            margin='5px'
                            content='Details'
                            color='blue'
                            onClick={() =>
                              history.push(
                                userAdminOrOp
                                  ? paths.massIntegrationOperator
                                      .replace(':companyId', companyId)
                                      .replace(':massIntegrationId', e.id)
                                  : paths.massIntegration.replace(
                                      ':massIntegrationId',
                                      e.id
                                    )
                              )
                            }
                          />
                          <Button
                            disabled={e.state === 'Processing'}
                            type='submit'
                            margin='5px'
                            content='Delete'
                            color='red'
                            onClick={() => handleDelete(e.id)}
                          />
                        </Btn.Group>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          <Pagination
            totalPages={totalMassIntegrations / 10}
            onChange={handlePageChange}
          />
        </Container>
      }
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    company: selectors.company.companySelector(state),
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    massIntegrations: selectors.quickfixes.massIntegrationsSelector(state),
    deleteMassIntegrationLoading: selectors.quickfixes.deleteMassIntegrationLoadingSelector(
      state
    ),
    totalMassIntegrations: selectors.quickfixes.totalMassIntegrationsSelector(
      state
    ),
    getMassIntegrationsLoading: selectors.quickfixes.getMassIntegrationsLoadingSelector(
      state
    ),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
