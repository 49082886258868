import styled from '@emotion/styled';

export const Alert = styled.span(() => ({
    color: 'red',
    margin: '0',
    padding: '0'
 }))

 export const Save = styled.div`
 &&& {
  height: 38.5px;
  line-height: 38.5px;
  margin: 0 0 0 5px;
  border-radius: 0.3rem;
  border: 1px solid rgba(34,36,38,.15);
  display: inline;
  float: right;
  padding: 0 10px;
  cursor: pointer;
  margin: 0 0 0 10px;
}
&&&:hover {
  background-color: rgba(128,135,139,.2);
 }
 `
