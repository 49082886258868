import styled from "@emotion/styled";

export const Description = styled.p(() => ({
  margin: '0',
  padding: '0',
  fontSize: '11px'
}));

export const Disappear = styled.span(() => ({
  height: '0'
}));

export const HeaderTitle = styled.h3(() => ({
  margin: '30px 0 15px 0 !important',
  padding: '0 !important',
  display: 'block',
  float: 'left',
  textAlign: 'center',
  width: '100%'
}));

export const LinkWrapper = styled.div(() => ({
  display: 'flex',
  margin: '0 auto',
  flexDirection: 'column',
  alignItems: 'center',
  width: '300px'
}))

export const LinkToCustoms = styled.div`
  width: 300px;
  float: left;
  margin: 10px;
  cursor: pointer;
  padding: 10px;
  display: block;
  border-radius: 2em !important;
  box-sizing: border-box;
  border: ${props => props.color ? '1' : '0'}px solid #80808054;
  `

export const LinkToClerks = styled.div`
  width: 300px;
  float: left;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  border-radius: 2em !important;
  border: ${props => props.color ? '1' : '0'}px solid #80808054;
  `