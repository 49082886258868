import { resetState } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    create: null,
    getOne: null,
    update: null,
    getAll: null,
    remove: null,
    createProduct: null,
    getOneProduct: null,
    updateProduct: null,
    getProductOfService: null,
    getProductOfServiceSub: null,
    removeProduct: null,
  },
  errors: {
    create: null,
    getOne: null,
    update: null,
    getAll: null,
    remove: null,
    createProduct: null,
    getOneProduct: null,
    updateProduct: null,
    getProductOfService: null,
    getProductOfServiceSub: null,
    removeProduct: null,
  },
  loading: {
    create: false,
    getOne: false,
    update: false,
    getAll: false,
    remove: false,
    createProduct: false,
    getOneProduct: false,
    updateProduct: false,
    getProductOfService: false,
    getProductOfServiceSub: false,
    removeProduct: false,
  },

  service: null,
  services: null,
  product: null,
  productsOfService: null,
  productsOfServiceSub: null,

  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // create
    case actionTypes.CREATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.create = true;
      });
    case actionTypes.CREATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.create = null;
        draft.loading.create = false;
        draft.success.create = true;
        draft.services = action.payload;
      });
    case actionTypes.CREATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.create = true;
        draft.loading.create = false;
        draft.success.create = false;
        !draft.error.includes("createService") &&
          draft.error.push("createService");
      });

    // getOne
    case actionTypes.GET_ONE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOne = true;
      });
    case actionTypes.GET_ONE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOne = null;
        draft.loading.getOne = false;
        draft.success.getOne = true;
        draft.services = action.payload;
      });
    case actionTypes.GET_ONE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOne = true;
        draft.loading.getOne = false;
        draft.success.getOne = false;
        !draft.error.includes("service") && draft.error.push("service");
      });

    // getAll
    case actionTypes.UPDATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.update = true;
      });
    case actionTypes.UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.update = null;
        draft.loading.update = false;
        draft.success.update = true;
        draft.services = action.payload;
      });
    case actionTypes.UPDATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.update = true;
        draft.loading.update = false;
        draft.success.update = false;
        !draft.error.includes("updateService") &&
          draft.error.push("updateService");
      });

    // getAll
    case actionTypes.GET_ALL_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAll = true;
      });

    case actionTypes.GET_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAll = null;
        draft.loading.getAll = false;
        draft.success.getAll = true;
        draft.services = action.payload;
      });

    case actionTypes.GET_ALL_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAll = true;
        draft.loading.getAll = false;
        draft.success.getAll = false;
        !draft.error.includes("services") && draft.error.push("services");
      });

    // remove
    case actionTypes.REMOVE_LOADING:
      return produce(state, (draft) => {
        draft.loading.remove = true;
      });

    case actionTypes.REMOVE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.remove = null;
        draft.loading.remove = false;
        draft.success.remove = true;
        draft.services = action.payload;
      });

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.errors.remove = true;
        draft.loading.remove = false;
        draft.success.remove = false;
        !draft.error.includes("removeService") &&
          draft.error.push("removeService");
      });

    // create product
    case actionTypes.CREATE_PRODUCT_LOADING:
      return produce(state, (draft) => {
        draft.loading.createProduct = true;
      });
    case actionTypes.CREATE_PRODUCT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createProduct = null;
        draft.loading.createProduct = false;
        draft.success.createProduct = true;
        draft.services = action.payload;
      });
    case actionTypes.CREATE_PRODUCT_ERROR:
      return produce(state, (draft) => {
        draft.errors.createProduct = true;
        draft.loading.createProduct = false;
        draft.success.createProduct = false;
        !draft.error.includes("createProduct") &&
          draft.error.push("createProduct");
      });

    // getOne product
    case actionTypes.GET_ONE_PRODUCT_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneProduct = true;
      });
    case actionTypes.GET_ONE_PRODUCT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneProduct = null;
        draft.loading.getOneProduct = false;
        draft.success.getOneProduct = true;
        draft.product = action.payload;
      });
    case actionTypes.GET_ONE_PRODUCT_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneProduct = true;
        draft.loading.getOneProduct = false;
        draft.success.getOneProduct = false;
        !draft.error.includes("product") && draft.error.push("product");
      });

    // update product
    case actionTypes.UPDATE_PRODUCT_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateProduct = true;
      });
    case actionTypes.UPDATE_PRODUCT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateProduct = null;
        draft.loading.updateProduct = false;
        draft.success.updateProduct = true;
        draft.services = action.payload;
      });
    case actionTypes.UPDATE_PRODUCT_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateProduct = true;
        draft.loading.updateProduct = false;
        draft.success.updateProduct = false;
        !draft.error.includes("updateProduct") &&
          draft.error.push("updateProduct");
      });

    // getProductOfService
    case actionTypes.GET_PRODUCT_OF_SERVICE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getProductOfService = true;
      });

    case actionTypes.GET_PRODUCT_OF_SERVICE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getProductOfService = null;
        draft.loading.getProductOfService = false;
        draft.success.getProductOfService = true;
        draft.productsOfService = action.payload;
      });

    case actionTypes.GET_PRODUCT_OF_SERVICE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getProductOfService = true;
        draft.loading.getProductOfService = false;
        draft.success.getProductOfService = false;
        !draft.error.includes("productsOfService") &&
          draft.error.push("productsOfService");
      });

    // getProductOfServiceSub
    case actionTypes.GET_PRODUCT_OF_SERVICE_SUB_LOADING:
      return produce(state, (draft) => {
        draft.loading.getProductOfServiceSub = true;
      });

    case actionTypes.GET_PRODUCT_OF_SERVICE_SUB_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getProductOfServiceSub = null;
        draft.loading.getProductOfServiceSub = false;
        draft.success.getProductOfServiceSub = true;
        draft.productsOfServiceSub = action.payload;
      });

    case actionTypes.GET_PRODUCT_OF_SERVICE_SUB_ERROR:
      return produce(state, (draft) => {
        draft.errors.getProductOfServiceSub = true;
        draft.loading.getProductOfServiceSub = false;
        draft.success.getProductOfServiceSub = false;
        !draft.error.includes("productsOfServiceSub") &&
          draft.error.push("productsOfServiceSub");
      });

    // remove product
    case actionTypes.REMOVE_PRODUCT_LOADING:
      return produce(state, (draft) => {
        draft.loading.removeProduct = true;
      });

    case actionTypes.REMOVE_PRODUCT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removeProduct = null;
        draft.loading.removeProduct = false;
        draft.success.removeProduct = true;
        draft.services = action.payload;
      });

    case actionTypes.REMOVE_PRODUCT_ERROR:
      return produce(state, (draft) => {
        draft.errors.removeProduct = true;
        draft.loading.removeProduct = false;
        draft.success.removeProduct = false;
        !draft.error.includes("removeProduct") &&
          draft.error.push("removeProduct");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
