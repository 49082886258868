import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    create: false,
    getAll: false,
    notOpenedNews: false,
    isReadByUser: false,
    readNews: false,
    getOne: false,
    remove: false,
    removeAll: false,
    update: false,
  },
  errors: {
    create: null,
    getAll: null,
    notOpenedNews: null,
    isReadByUser: null,
    readNews: null,
    getOne: null,
    remove: null,
    removeAll: null,
    update: null,
  },
  loading: {
    create: false,
    getAll: false,
    notOpenedNews: false,
    isReadByUser: false,
    readNews: false,
    getOne: false,
    remove: false,
    removeAll: false,
    update: false,
  },
  notOpenedNews: null,
  allNews: null,
  isRead: null,
  news: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Create
    case actionTypes.CREATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.create = true;
      });

    case actionTypes.CREATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.create = null;
        draft.loading.create = false;
        draft.success.create = true;
      });

    case actionTypes.CREATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.create = true;
        draft.loading.create = false;
        draft.success.create = false;
        !draft.error.includes("create") && draft.error.push("create");
      });

    // Get all
    case actionTypes.GET_ALL_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAll = true;
      });

    case actionTypes.GET_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAll = null;
        draft.loading.getAll = false;
        draft.success.getAll = true;
        draft.allNews = action.payload;
      });

    case actionTypes.GET_ALL_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAll = true;
        draft.loading.getAll = false;
        draft.success.getAll = false;
        !draft.error.includes("allNews") && draft.error.push("allNews");
      });

       // Get all filtered
    case actionTypes.GET_ALL_READ_LOADING:
      return produce(state, (draft) => {
        draft.loading.notOpenedNews = true;
      });

    case actionTypes.GET_ALL_READ_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.notOpenedNews = null;
        draft.loading.notOpenedNews = false;
        draft.success.notOpenedNews = true;
        draft.notOpenedNews = action.payload;
      });

    case actionTypes.GET_ALL_READ_ERROR:
      return produce(state, (draft) => {
        draft.errors.notOpenedNews = true;
        draft.loading.notOpenedNews = false;
        draft.success.notOpenedNews = false;
        !draft.error.includes("notOpenedNews") && draft.error.push("notOpenedNews");
      });

    // Is Read
    case actionTypes.IS_READ_BY_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.isReadByUser = true;
      });

    case actionTypes.IS_READ_BY_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.isReadByUser = null;
        draft.loading.isReadByUser = false;
        draft.success.isReadByUser = true;
        draft.isRead = action.payload;
      });

    case actionTypes.IS_READ_BY_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.isReadByUser = true;
        draft.loading.isReadByUser = false;
        draft.success.isReadByUser = false;
        !draft.error.includes("isRead") && draft.error.push("isRead");
      });

    // Get one
    case actionTypes.READ_NEWS_LOADING:
      return produce(state, (draft) => {
        draft.loading.readNews = true;
      });

    case actionTypes.READ_NEWS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.readNews = null;
        draft.loading.readNews = false;
        draft.success.readNews = true;
      });

    case actionTypes.READ_NEWS_ERROR:
      return produce(state, (draft) => {
        draft.errors.readNews = true;
        draft.loading.readNews = false;
        draft.success.readNews = false;
        !draft.error.includes("readNews") && draft.error.push("readNews");
      });

    // Get one
    case actionTypes.GET_ONE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOne = true;
      });

    case actionTypes.GET_ONE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOne = null;
        draft.loading.getOne = false;
        draft.success.getOne = true;
        draft.news = action.payload;
      });

    case actionTypes.GET_ONE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOne = true;
        draft.loading.getOne = false;
        draft.success.getOne = false;
        !draft.error.includes("news") && draft.error.push("news");
      });

    // remove
    case actionTypes.REMOVE_LOADING:
      return produce(state, (draft) => {
        draft.loading.remove = true;
      });

    case actionTypes.REMOVE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.remove = null;
        draft.loading.remove = false;
        draft.success.remove = true;
      });

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.errors.remove = true;
        draft.loading.remove = false;
        draft.success.remove = false;
        !draft.error.includes("remove") && draft.error.push("remove");
      });

    // removeAll
    case actionTypes.REMOVE_ALL_LOADING:
      return produce(state, (draft) => {
        draft.loading.removeAll = true;
      });

    case actionTypes.REMOVE_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removeAll = null;
        draft.loading.removeAll = false;
        draft.success.removeAll = true;
      });

    case actionTypes.REMOVE_ALL_ERROR:
      return produce(state, (draft) => {
        draft.errors.removeAll = true;
        draft.loading.removeAll = false;
        draft.success.removeAll = false;
        !draft.error.includes("removeAll") && draft.error.push("removeAll");
      });

    // Update
    case actionTypes.UPDATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.update = true;
      });

    case actionTypes.UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.update = null;
        draft.loading.update = false;
        draft.success.update = true;
      });

    case actionTypes.UPDATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.update = true;
        draft.loading.update = false;
        draft.success.update = false;
        !draft.error.includes("update") && draft.error.push("update");
      });



    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
