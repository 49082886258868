import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  sendNotificationQueue(data: dataReqI){
    return {
      type: actionTypes.SEND_QUEUE,
      payload: data,
    };
  },
  convertFile(data: dataReqI) {
    return {
      type: actionTypes.CONVERT_FILE,
      payload: data,
    };
  },
  addPartnerComment(data: dataReqI){
    return {
      type: actionTypes.ADD_PART_COMMENTS,
      payload: data,
    };
  },
  getPartnerComments(data: dataReqI){
    return {
      type: actionTypes.GET_PART_COMMENTS,
      payload: data,
    };
  },
  addPartner(data: dataReqI){ // CustomsUsers list part 1
    return {
      type: actionTypes.ADD_PARTNERS,
      payload: data,
    };
  },
  getAllPartners(data: dataReqI){ // CustomsUsers list part 1
    return {
      type: actionTypes.GET_PARTNERS,
      payload: data,
    };
  },
  getTransitRisk(data: dataReqI) {
    return {
      type: actionTypes.TRANSIT_RISK,
      payload: data,
    };
  },
  reloadLogisticom(data: dataReqI) {
    return {
      type: actionTypes.RELOAD_LOGISTICOM,
      payload: data,
    };
  },
  sendNoticeMail(data: dataReqI) {
    return {
      type: actionTypes.SEND_NOTICE_MAIL,
      payload: data,
    };
  },
  getAllChed(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_CHED,
      payload: data,
    };
  },
  getAllLines(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_LINES,
      payload: data,
    };
  },
  addLines(data: dataReqI) {
    return {
      type: actionTypes.CREATE_LINES,
      payload: data,
    };
  },
  updateLines(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_LINES,
      payload: data,
    };
  },
  deleteLines(data: dataReqI) {
    return {
      type: actionTypes.DELETE_LINES,
      payload: data,
    };
  },
  getOneChed(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_CHED,
      payload: data,
    };
  },
  addChed(data: dataReqI) {
    return {
      type: actionTypes.ADD_CHED,
      payload: data,
    };
  },
  removeChed(data: dataReqI) {
    return {
      type: actionTypes.REMOVE_CHED,
      payload: data,
    };
  },
  downloadCheds(data: dataReqI){
    return {
      type: actionTypes.DOWNLOAD_CHEDS,
      payload: data,
    }
  },
  updateChedData(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_CHED_DATA,
      payload: data,
    };
  },
  addConsignmentData(data: dataReqI) {
    return {
      type: actionTypes.ADD_CONSIGNMENT_DATA,
      payload: data,
    };
  },
  updateConsignmentData(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_CONSIGNMENT_DATA,
      payload: data,
    };
  },
  deleteConsignmentData(data: dataReqI) {
    return {
      type: actionTypes.DELETE_CONSIGNMENT_DATA,
      payload: data,
    };
  },
  readChat(data: dataReqI) {
    return {
      type: actionTypes.READ_CHAT,
      payload: data,
    };
  },
  getDeclarationsInvoice(data: dataReqI) {
    return {
      type: actionTypes.GET_DECLARATIONS_INVOICE,
      payload: data,
    };
  },
  getDeclarationsInvoiceByFilters(data: dataReqI) {
    return {
      type: actionTypes.GET_DECLARATIONS_INVOICE_BY_FILTERS,
      payload: data,
    };
  },
  addIntranetReference(data: dataReqI) {
    return {
      type: actionTypes.ADD_INTRANET_REFERENCE,
      payload: data,
    };
  },
  updateDeclarationsInvoice(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_DECLARATIONS_INVOICE,
      payload: data,
    };
  },
  getCompanySubscribed() {
    return {
      type: actionTypes.GET_COMPANY_SUBSCRIBED,
    };
  },
  getAllCustomsOffice() {
    return {
      type: actionTypes.GET_ALL_CUSTOMS_OFFICE,
    };
  },
  getProcessingDeclarations(data: dataReqI) {
    return {
      type: actionTypes.GET_PROCESSING_DECLARATIONS,
      payload: data,
    };
  },
  getProcessingDeclarationsByFilters(data: dataReqI) {
    return {
      type: actionTypes.GET_PROCESSING_DECLARATIONS_BY_FILTERS,
      payload: data,
    };
  },
  declare(data: dataReqI) {
    return {
      type: actionTypes.DELCARE,
      payload: data,
    };
  },
  createAccises(data: dataReqI) {
    return {
      type: actionTypes.CREATE_ACCISES,
      payload: data,
    };
  },
  updateAccises(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_ACCISES,
      payload: data,
    };
  },
  createTransit(data: dataReqI) {
    return {
      type: actionTypes.CREATE_TRANSIT,
      payload: data,
    };
  },
  createDeb(data: dataReqI) {
    return {
      type: actionTypes.CREATE_DEB,
      payload: data,
    };
  },
  updateTransit(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_TRANSIT,
      payload: data,
    };
  },
  updateDeb(data: dataReqI){
    return {
      type: actionTypes.UPDATE_DEB,
      payload: data,
    };
  },
  updateDeclaration(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_DECLARATION,
      payload: data,
    };
  },
  updateStep(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_STEP,
      payload: data,
    };
  },
  cancelDeclaration(data: dataReqI) {
    return {
      type: actionTypes.CANCEL_DECLARATION,
      payload: data,
    };
  },
  getAllCategoriesDocuments(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_CATEGORIES_DOCUMENTS,
      payload: data,
    };
  },
  addStep(data: dataReqI) {
    return {
      type: actionTypes.ADD_STEP,
      payload: data,
    };
  },
  getAll(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL,
      payload: data,
    };
  },
  getAllHosted(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_HOSTED,
      payload: data,
    };
  },
  getOneHosted(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_HOSTED,
      payload: data,
    };
  },
  getComputedDeclarations(data: dataReqI) {
    return {
      type: actionTypes.GET_COMPUTED,
      payload: data,
    };
  },
  getOne(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE,
      payload: data,
    };
  },
  getOneByReference(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_BY_REF,
      payload: data,
    };
  },
  getParking() {
    return {
      type: actionTypes.GET_PARKING,
    };
  },
  downloadDocuments(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_DOCUMENTS,
      payload: data,
    };
  },
  extractDocuments(data: dataReqI) {
    return {
      type: actionTypes.EXTRACT_DOCUMENTS,
      payload: data,
    };
  },
  getAllTraces() {
    return {
      type: actionTypes.EXTRACT_DOCUMENTS,
    };
  },
  addDocuments(data: dataReqI) {
    return {
      type: actionTypes.ADD_DOCUMENTS,
      payload: data,
    };
  },
  addMandate(data: dataReqI) {
    return {
      type: actionTypes.ADD_MANDATE,
      payload: data,
    };
  },
  getMandates(data: dataReqI) {
    return {
      type: actionTypes.GET_MANDATES,
      payload: data,
    };
  },
  downloadOneMandate(data: dataReqI){
    return {
      type: actionTypes.GET_ONE_MANDATE,
      payload: data,
    };
  },
  getAccises(data: dataReqI) {
    return {
      type: actionTypes.GET_ACCISES,
      payload: data,
    };
  },
  getAllAccises(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_ACCISES,
      payload: data,
    };
  },
  deleteDocument(data: dataReqI) {
    return {
      type: actionTypes.DELETE_DOCUMENT,
      payload: data,
    };
  },
  downloadDocument(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_DOCUMENT,
      payload: data,
    };
  },
  getChat(data: dataReqI) {
    return {
      type: actionTypes.GET_CHAT,
      payload: data,
    };
  },
  sendMessage(data: dataReqI) {
    return {
      type: actionTypes.SEND_MESSAGE,
      payload: data,
    };
  },
  followDeclaration(data: dataReqI) {
    return {
      type: actionTypes.FOLLOW_DECLARATION,
      payload: data,
    };
  },
  unfollowDeclaration(data: dataReqI) {
    return {
      type: actionTypes.UNFOLLOW_DECLARATION,
      payload: data,
    };
  },
  subscribe(data: dataReqI) {
    return {
      type: actionTypes.SUBSCRIBE,
      payload: data,
    };
  },
  unsubscribe(data: dataReqI) {
    return {
      type: actionTypes.UNSUBSCRIBE,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  generateBaeExtract(data: dataReqI) {
    return {
      type: actionTypes.GENERATE_BAE_EXTRACT,
      payload: data,
    }
  },
  getComputedCheds(data: dataReqI) {
    return {
      type: actionTypes.GET_COMPUTED_CHEDS,
      payload: data,
    };
  },
  getConex(data: dataReqI) {
    return {
      type: actionTypes.GET_CONEX,
      payload: data,
    };
  },
  checkConexDelivery(data: dataReqI) {
    return {
      type: actionTypes.CHECK_CONEX,
      payload: data,
    };
  },
  getCategories(data: dataReqI){
    return {
      type: actionTypes.GET_CATEGORIES,
      payload: data,
    }
  },
  getTransitRates(data: dataReqI){
    return {
      type: actionTypes.GET_RATES,
      payload: data,
    }
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
