import styled from '@emotion/styled';

export const ThumbnailWrapper = styled.div(() => ({
  width: '200px',
  height: '130px',
  padding: '10px',
  border: '1px solid rgba(34, 36, 38, 0.15)',
  borderRadius: '10px',
}));

export const RemoveButtonWrapper = styled.div(() => ({
  position: 'relative',
  top: '-20px',
  left: '100px',
}));
