import styled from '@emotion/styled';
import { Menu, Dropdown, Sidebar } from 'semantic-ui-react';
import bg from 'assets/images/bg-sidebar.png';
import theme from 'theme';

const colors = theme.getTheme().colors;

export const SidebarStyled = styled(Sidebar)`
  &&& {
    width: 230px !important;
    background: url(${bg}) 100%;
    background-size: cover;
  }
  @media (max-width: 600px) {
    &&& {
      width: 40% !important;
    }
  }
`;
export const SidebarPushable = styled(Sidebar.Pushable)`
  &&& {
  }
`;

export const SidebarPusher = styled(Sidebar.Pusher)`
  &&& {
  }
`;

export const MenuItemStyled = styled(Menu.Item)`
  &&& {
    color: white;
    text-align: left !important;
  }
`;

export const MenuItemWrapper = styled.div(
  ({ margin, position, maxHeight }: any) => ({
    display: '-webkit-inline-box',
    width: '100%',
    flexDirection: 'row',
    maxHeight: maxHeight ? maxHeight : 'none',
    justifyContent: position ? position : 'center',
    margin,
  })
);

export const DropdownItemStyled = styled(Dropdown.Item)`
  &&& {
  }
`;

export const DropdownMenuStyled = styled(Dropdown.Menu)`
  &&& {
    position: initial;
    background: ${colors.transparent} !important;
    padding: 1rem 0 0 0;
    border: none;
    box-shadow: none !important;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  &&& {
    color: white;
    width: 100%;
    text-align: left !important;
  }
`;
