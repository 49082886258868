let actionTypes = {
  ADD_DRIVER: "MY_ASD.COMPANY_DRIVER.ADD_DRIVER",
  ADD_DRIVER_LOADING: "MY_ASD.COMPANY_DRIVER.ADD_DRIVER_LOADING",
  ADD_DRIVER_SUCCESS: "MY_ASD.COMPANY_DRIVER.ADD_DRIVER_SUCCESS",
  ADD_DRIVER_ERROR: "MY_ASD.COMPANY_DRIVER.ADD_DRIVER_ERROR",

  REMOVE_DRIVER: "MY_ASD.COMPANY_DRIVER.REMOVE_DRIVER",
  REMOVE_DRIVER_LOADING: "MY_ASD.COMPANY_DRIVER.REMOVE_DRIVER_LOADING",
  REMOVE_DRIVER_SUCCESS: "MY_ASD.COMPANY_DRIVER.REMOVE_DRIVER_SUCCESS",
  REMOVE_DRIVER_ERROR: "MY_ASD.COMPANY_DRIVER.REMOVE_DRIVER_ERROR",

  UPDATE_DRIVER: "MY_ASD.COMPANY_DRIVER.UPDATE_DRIVER",
  UPDATE_DRIVER_LOADING: "MY_ASD.COMPANY_DRIVER.UPDATE_DRIVER_LOADING",
  UPDATE_DRIVER_SUCCESS: "MY_ASD.COMPANY_DRIVER.UPDATE_DRIVER_SUCCESS",
  UPDATE_DRIVER_ERROR: "MY_ASD.COMPANY_DRIVER.UPDATE_DRIVER_ERROR",

  UPDATE_DRIVER_PERMISSIONS: "MY_ASD.COMPANY_DRIVER.UPDATE_DRIVER_PERMISSIONS",
  UPDATE_DRIVER_PERMISSIONS_LOADING:
    "MY_ASD.COMPANY_DRIVER.UPDATE_DRIVER_PERMISSIONS_LOADING",
  UPDATE_DRIVER_PERMISSIONS_SUCCESS:
    "MY_ASD.COMPANY_DRIVER.UPDATE_DRIVER_PERMISSIONS_SUCCESS",
  UPDATE_DRIVER_PERMISSIONS_ERROR:
    "MY_ASD.COMPANY_DRIVER.UPDATE_DRIVER_PERMISSIONS_ERROR",

  GET_DRIVER_PERMISSIONS: "MY_ASD.COMPANY_DRIVER.GET_DRIVER_PERMISSIONS",
  GET_DRIVER_PERMISSIONS_LOADING:
    "MY_ASD.COMPANY_DRIVER.GET_DRIVER_PERMISSIONS_LOADING",
  GET_DRIVER_PERMISSIONS_SUCCESS:
    "MY_ASD.COMPANY_DRIVER.GET_DRIVER_PERMISSIONS_SUCCESS",
  GET_DRIVER_PERMISSIONS_ERROR: "MY_ASD.COMPANY_DRIVER.GET_DRIVER_PERMISSIONS_ERROR",

  GET_DRIVER_PERMISSIONS_FOR_NAVIGATION:
    "MY_ASD.COMPANY_DRIVER.GET_DRIVER_PERMISSIONS_FOR_NAVIGATION",
  GET_DRIVER_PERMISSIONS_FOR_NAVIGATION_LOADING:
    "MY_ASD.COMPANY_DRIVER.GET_DRIVER_PERMISSIONS_FOR_NAVIGATION_LOADING",
  GET_DRIVER_PERMISSIONS_FOR_NAVIGATION_SUCCESS:
    "MY_ASD.COMPANY_DRIVER.GET_DRIVER_PERMISSIONS_FOR_NAVIGATION_SUCCESS",
  GET_DRIVER_PERMISSIONS_FOR_NAVIGATION_ERROR:
    "MY_ASD.COMPANY_DRIVER.GET_DRIVER_PERMISSIONS_FOR_NAVIGATION_ERROR",

  GET_ONE_DRIVER: "MY_ASD.COMPANY_DRIVER.GET_ONE_DRIVER",
  GET_ONE_DRIVER_LOADING: "MY_ASD.COMPANY_DRIVER.GET_ONE_DRIVER_LOADING",
  GET_ONE_DRIVER_SUCCESS: "MY_ASD.COMPANY_DRIVER.GET_ONE_DRIVER_SUCCESS",
  GET_ONE_DRIVER_ERROR: "MY_ASD.COMPANY_DRIVER.GET_ONE_DRIVER_ERROR",

  GET_ALL_DRIVERS: "MY_ASD.COMPANY_DRIVER.GET_ALL_DRIVERS",
  GET_ALL_DRIVERS_LOADING: "MY_ASD.COMPANY_DRIVER.GET_ALL_DRIVERS_LOADING",
  GET_ALL_DRIVERS_SUCCESS: "MY_ASD.COMPANY_DRIVER.GET_ALL_DRIVERS_SUCCESS",
  GET_ALL_DRIVERS_ERROR: "MY_ASD.COMPANY_DRIVER.GET_ALL_DRIVERS_ERROR",

  GET_DRIVER_COMPANIES: "MY_ASD.COMPANY.GET_DRIVER_COMPANIES",
  GET_DRIVER_COMPANIES_LOADING: "MY_ASD.COMPANY.GET_DRIVER_COMPANIES_LOADING",
  GET_DRIVER_COMPANIES_SUCCESS: "MY_ASD.COMPANY.GET_DRIVER_COMPANIES_SUCCESS",
  GET_DRIVER_COMPANIES_ERROR: "MY_ASD.COMPANY.GET_DRIVER_COMPANIES_ERROR",

  ADD_DRIVER_TO_COMPANY: "MY_ASD.COMPANY.ADD_DRIVER_TO_COMPANY",
  ADD_DRIVER_TO_COMPANY_LOADING: "MY_ASD.COMPANY.ADD_DRIVER_TO_COMPANY_LOADING",
  ADD_DRIVER_TO_COMPANY_SUCCESS: "MY_ASD.COMPANY.ADD_DRIVER_TO_COMPANY_SUCCESS",
  ADD_DRIVER_TO_COMPANY_ERROR: "MY_ASD.COMPANY.ADD_DRIVER_TO_COMPANY_ERROR",

  RESET: "MY_ASD.COMPANY_DRIVER.RES",
  REFRESH_ERROR: "MY_ASD.COMPANY_DRIVER.REFRESH_ERROR",
};

export default actionTypes;
