import React from 'react';
import { Field } from 'formik';

import InputFlexCustom from 'components/QuickFixes/DeliveryForm/InputFlexCustom';

const ValuesDetails = ({ errors, touched, names, disabled }: any) => {
  return (
    <>
      <Field
        label='Package method'
        name={names.packageMethod}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px 0 5px 0'
        columns='2'
        justifyContent='center'
        widthLabel='8'
        widthInput='8'
      />
      <Field
        label='Package number'
        name={names.packageNumber}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px 0 5px 0'
        columns='2'
        justifyContent='center'
        widthLabel='8'
        widthInput='8'
      />
      <Field
        label='Nature of goods'
        name={names.natureOfGoods}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px 0 5px 0'
        columns='2'
        justifyContent='center'
        widthLabel='8'
        widthInput='8'
      />
      <Field
        label='Gross weight'
        name={names.grossWeight}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px 0 5px 0'
        justifyContent='center'
        widthLabel='8'
        widthInput='8'
      />
      <Field
        label='Gross weight unit'
        name={names.grossWeightUnit}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px 0 5px 0'
        justifyContent='center'
        widthLabel='8'
        widthInput='8'
      />
      <Field
        label='Volume'
        name={names.volume}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px 0 5px 0'
        justifyContent='center'
        widthLabel='8'
        widthInput='8'
      />
      <Field
        label='Volume unit'
        name={names.volumeUnit}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px 0 5px 0'
        justifyContent='center'
        widthLabel='8'
        widthInput='8'
      />
      {names.value && (
        <Field
          label='Value'
          name={names.value}
          type='text'
          component={InputFlexCustom}
          disabled={disabled}
          meta={{ errors, touched }}
          margin='5px 0 5px 0'
          justifyContent='center'
          widthLabel='8'
          widthInput='8'
        />
      )}
    </>
  );
};

export default ValuesDetails;
