import React, { Children } from "react";

import { TextColored } from "./styles";

const Index = ({ color, children, onClick }: any) => {
  return (
    <TextColored color={color} onClick={onClick}>
      {children}
    </TextColored>
  );
};

export default Index;
