import Operator from "views/Operator";
import Details from "views/Operator/Details";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.operator,
    component: Operator,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.operatorCompanyDetails,
    component: Details,
    exact: true,
    authorizedRoles: []
  }
];
