import React, { FunctionComponent, useEffect, useState } from 'react';
import { actions } from 'store';
import { Modal } from 'semantic-ui-react';
import theme from 'theme';

import Wrapper from 'components/core/Wrapper/Wrapper';
import { Spacer, Step } from 'components/core';
import Button from 'components/Button';
import ModalAddChed from '../ModalAddChed/ModalAddChed';
import ButtonValidationModal from 'components/ButtonValidationModal';
import ClickableText from 'components/ClickableText';
import { chedI } from 'interfaces/data/customsClerkInterfaces';


type ModalChedDetailsProps = {
  chedDetails: chedI;
  handleClose: () => void;
  dispatch: any;
  companyId: number;
  isAuthorized: boolean;
  truckLicensePlate?: string;
  canDelete?: boolean;
  canEdit?: boolean;
  user?: any;
  chedData?: any;
  userAdminOrOp?: boolean
  getChedNewValue?: (ched) => void
};

const ModalChedDetails: FunctionComponent<ModalChedDetailsProps> = ({
  handleClose,
  chedDetails,
  chedData,
  companyId,
  dispatch,
  userAdminOrOp,
  isAuthorized,
  canDelete,
  canEdit,
}) => {
  const colors = theme.getTheme().colors;
  const [modalEdit, setmodalEdit] = useState(false);
  const [consignement, setConsignment] = useState(null);
  const [newValue, setNewValue]: any = useState(null)

  const downloadChed = () => {
    dispatch(
      actions.customsDeclaration.getOneChed({
        params: {
          companyId,
          declarationId: chedDetails.CustomsDeclaration_Id,
          chedId: chedDetails.id,
        },
      })
    );
  };

  const handleDeleteChed = () => {
    dispatch(
      actions.customsDeclaration.removeChed({
        params: {
          declarationId: chedDetails.CustomsDeclaration_Id,
          companyId,
          chedId: chedDetails.id,
        },
      })
    );

    handleClose();
  };

  const getNewValue = (e) => {
    setNewValue(e)
  }

  const handleSubmitChed = (values: chedI) => {
    if(values.file !== null){
      values.file = newValue
    }
    dispatch(
      actions.customsDeclaration.updateChedData({
        params: {
          companyId,
          declarationId: chedDetails.CustomsDeclaration_Id,
          chedId: chedDetails.id,
        },
        data: values,
      })
    );
    setmodalEdit(false);
    handleClose();
  };

  useEffect(() => {
    chedDetails && 
    chedDetails.Consignments && 
    setConsignment(chedDetails.Consignments)
  }, [chedDetails])

  return (
    <Modal
      open={chedDetails ? true : false}
      onClose={handleClose}
      closeIcon
      size='tiny'
    >
      {modalEdit && (
        <ModalAddChed
          open={modalEdit}
          handleClose={() => setmodalEdit(false)}
          handleAddChed={handleSubmitChed}
          chedDetail={chedDetails}
          chedData={chedData}
          getNewValue={getNewValue}
        />
      )}
      <Modal.Header>
        <Wrapper
          display='flex'
          justifyContent='space-between'
          alignItems='baseline'
        >
          <ClickableText
            onClick={() => {
              window.open(
                  `https://webgate.ec.europa.eu/tracesnt/certificate/${
                    chedDetails.type === 2 ? 'phyto' : chedDetails.type === 3 ? 'eu-export' : 'ched'
                  }/search#!?query=${chedDetails.number}&sort=-lastUpdateOn`
                ,
                '_blank'
              );
            }}
          >
          {(isAuthorized || chedDetails.state !== 'draft') && (
            chedDetails.type === 2 ? 
            `Phyto ${chedDetails.number}` :
            chedDetails.type === 3 ? 
            `EXPORT EU N° ${chedDetails.number}` : 
            `CHED N° ${chedDetails.number}`
            )}
          </ClickableText>
          <Step
            label={chedDetails.state}
            color={
              chedDetails.state === 'validated'
                ? colors.validatedStep
                : chedDetails.state === 'new'
                ? colors.finishedStep
                : colors.inProgressStep
            }
          />
        </Wrapper>
      </Modal.Header>
      <Modal.Content>
        <Wrapper
          display='flex'
          justifyContent='space-between'
          alignItems='baseline'
        >
          <Wrapper display='flex'>
            {chedDetails.veto && <Step width='80px' label='Veto' />}
            {chedDetails.phyto && <Step width='80px' label='Phyto' />}
          </Wrapper>
          <p>
            Tax:{' '}
            {chedDetails?.taxAmount ? `${chedDetails.taxAmount} €` : 'Unknow'}
          </p>
        </Wrapper>
        {consignement && consignement.length>0 && (
          <>
          <Spacer />
          <Wrapper
            display='flex'
            justifyContent='space-between'
            alignItems='baseline'
          >
            <p>Consignment status: <strong>{consignement.status}</strong></p> 
            <p>Estimation: {(+'0.00' + consignement.estimatedPrice).toFixed(2)} €</p>
          </Wrapper>
          </>
        )}
        <Wrapper
          display='flex'
          justifyContent='space-between'
          alignItems='baseline'
        >
          <p>Product: {chedDetails.productNames} - {chedDetails.productType}</p>
          <p>Weight: {chedDetails.weight}</p>
        </Wrapper>
        <Wrapper textAlign='center'>
          <p>Add at: {chedDetails.createdAt}</p>
          {chedDetails.validatedAt && (
            <p>Validated at: {chedDetails.validatedAt}</p>
          )}
        </Wrapper>
      </Modal.Content>
      <Modal.Actions>
        <Wrapper display='flex' justifyContent='center'>
          {chedDetails.state === 'validated' && userAdminOrOp && (
            <Button
              icon='edit'
              type='button'
              color='blue'
              content='Edit'
              onClick={() => setmodalEdit(true)}
            />
          )}
          {chedDetails.state !== 'validated' && (canEdit === undefined || canEdit) && (
            <Button
              icon='edit'
              type='button'
              color='blue'
              content='Edit'
              onClick={() => setmodalEdit(true)}
            />
          )}
           {chedDetails.state !== 'draft' && (
              <Button
              icon='download'
              type='button'
              color='blue'
              content='Download'
              onClick={downloadChed}
            />
           )}
        
          {chedDetails.state !== 'validated' && (canDelete === undefined || canDelete) && (
            <ButtonValidationModal
              trgrIcon='cancel'
              noSubmit
              trgrContent='Delete'
              headerContent='Delete'
              modalContent='Do you confirm the deletion of this ched?'
              trgrColor='red'
              onClick={handleDeleteChed}
            />
          )}
        </Wrapper>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalChedDetails;
