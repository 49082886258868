// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import NavbarDropdown from './navbarDropdown';

// Resources
import { flagEmojis } from '../../../../views';
import flagUK from '../../assets/images/emojis/flagUK.png';
import flagFR from '../../assets/images/emojis/flagFR.png';

// Stylesheets
import './navbarDropdown.css';

interface NavbarDropwodnLanguageProperties {
  createCompany?: boolean;
}
const NavbarDropdownLanguage: React.FC<NavbarDropwodnLanguageProperties> = ({
  createCompany,
}) => {
  // Parameter that gives an access to changeLanguage method
  const { i18n } = useTranslation();

  // Modify the language value and stored her into a local storage
  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
    window.location.reload();
  };

  // Switch the language on english
  const switchToEN = () => {
    handleLanguageChange('en');
  };

  // Switch the language on french
  const switchToFR = () => {
    handleLanguageChange('fr');
  };

  // All language button's options
  const languagesOptions = [
    {
      flag: <img src={flagUK} alt='flagEmoji' className='flagEmoji' />,
      label: 'EN',
      action: switchToEN,
    },
    {
      flag: <img src={flagFR} alt='flagEmoji' className='flagEmoji' />,
      label: 'FR',
      action: switchToFR,
    },
  ];

  const currentLanguageAreaCode = i18n.language.toUpperCase().split('-')[0];
  const currentLanguageFlag =
    currentLanguageAreaCode === 'FR' ? flagEmojis['fr'] : flagEmojis['en'];

  return (
    <div className='languageContainer'>
      <img src={currentLanguageFlag} alt='flag' className='flagEmoji' />
      <NavbarDropdown
        title={currentLanguageAreaCode}
        options={languagesOptions}
        isRight={true}
        createCompany={createCompany}
      />
    </div>
  );
};

export default NavbarDropdownLanguage;
