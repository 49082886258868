import React  from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Table, Header, Segment, Loader } from 'semantic-ui-react';
import EmptyTable from 'components/EmptyTableCurve';


const Index = ({
  data,
  loadingHandlerAccises
}: any) => {

  return (
    <>
      {data === null && (
        <Segment>
          <Header
            textAlign='center'
            size='small'
          >{`No accises have been uploaded.`}</Header>
        </Segment>
      )}

      {((data === null || data.length === 0 || loadingHandlerAccises) && <Loader />) || (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Created at
              </Table.HeaderCell>
              <Table.HeaderCell>
                Document Reference
              </Table.HeaderCell>
              <Table.HeaderCell>
                ASD Reference
              </Table.HeaderCell>
              <Table.HeaderCell>
                Country departure
              </Table.HeaderCell>
              <Table.HeaderCell>
                Country arrival
              </Table.HeaderCell>
              <Table.HeaderCell>
                Closing
              </Table.HeaderCell>
             
            </Table.Row>
          </Table.Header>

          <Table.Body>
          {((data === null || data.length === 0) && (<EmptyTable colNumber={6} />)) ||
            data.map((e, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell key={`createdAt-${i}`} style={{ width: '200px'}}>
                    {new Date(e.createdAt).toLocaleDateString('en', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Table.Cell>
                  <Table.Cell key={`ref-${i}`} style={{ width: '250px'}}>{e.clientReference}</Table.Cell>
                  <Table.Cell key={`id-${i}`} style={{ width: '250px'}}>{e.id}</Table.Cell>
                  <Table.Cell key={`departure-${i}`}>{e.departure}</Table.Cell>
                  <Table.Cell key={`arrival-${i}`}>{e.arrival}</Table.Cell>                
                  <Table.Cell key={`completed-${i}`}>{e.CustomsDeclarationCategories.length>0 && 
                  e.CustomsDeclarationCategories[0].CustomsDeclarationHasCategories.completedAt}</Table.Cell>                
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

const mapStateToProps = (state) => {};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
