import styled from '@emotion/styled';
import background from '../../../../assets/images/background.jpg';


export const CardStyled = styled.div(
  ({ bgPosition }: { bgPosition: { x: string; y: string } }) => ({
    margin: '30px',
    width: '420px',
    height: '250px',
    borderRadius: '15px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    transition: 'box-shadow 0.3s ease 0.05s, transform 0.3s ease',
    color: 'white',
    overflow: 'hidden',
    cursor: 'pointer',

    '&:before': {
      content: '""',
      backgroundImage:
        `url(${background})`,
      backgroundRepeat:'no-repeat',
      backgroundSize: 'auto',
      //backgroundPosition: `${bgPosition.x} ${bgPosition.y}`,
      zIndex: -1,
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: '80%',
    },

    '&&&:hover': {
      boxShadow: '15px 15px 20px 10px #60606052',
      transform: 'scale(1.05)',

      '&:before': {
        opacity: '100%',
        transition: 'opacity 0.3s ease',
      },
    },

    '@media (max-width: 440px)': {
      width: '300px',
      height: '300px',
    },
  })
);

export const LogoStyled = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '65%',
  padding: '10px',
  borderRadius: '15px 15px 0 0',
}));

export const DescriptionStyled = styled.div(() => ({
  textAlign: 'center',
  padding: '10px',
  backgroundColor: '#ffffff3b',
  borderRadius: '0 0 15px 15px',
  height: '100%',
}));

export const TagsStyled = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
}));

export const TagStyled = styled.div(({ color }) => ({
  flexDirection: 'row',
  textAlign: 'center',
  marginTop: '5px',
  padding: '5px 30px 5px 30px',
  borderRadius: '7px',
  border: 'none',
  backgroundColor: color,
  color: 'white',
  fontWeight: 'bold',
  userSelect: 'none',
  width:'60%',
}));

export const TitleStyled = styled.div(() => ({
  fontSize: '1.6em',
  fontWeight: 'bolder',
  margin: 'auto',
  paddingBottom: '5px',
}));

export const DescriptionTextStyled = styled.div(() => ({
  fontSize: '1.2em',
  margin: 'auto',
}));
