import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const createClerksPricingErrorSelector = state => 
state.companyClerksPricing.errors.createClerksPricing;
const getClerksPricingErrorSelector = state => 
state.companyClerksPricing.errors.getOneClerksPricing;
const deleteClerksPricingErrorSelector = state => 
state.companyClerksPricing.errors.deleteClerksPricing;
const updateClerksPricingErrorSelector = state => 
state.companyClerksPricing.errors.updateClerksPricing;

const errorsSelector = createErrorsSelector([
  createClerksPricingErrorSelector,
  getClerksPricingErrorSelector,
  deleteClerksPricingErrorSelector,
  updateClerksPricingErrorSelector,
]);

// Loading
const createClerksPricingLoadingSelector = state => 
state.companyClerksPricing.loading.createClerksPricing;
const getOneClerksPricingLoadingSelector = state => 
state.companyClerksPricing.loading.getOneClerksPricing;
const deleteClerksPricingLoadingSelector = state => 
state.companyClerksPricing.loading.deleteClerksPricing;
const updateClerksPricingLoadingSelector = state => 
state.companyClerksPricing.loading.updateClerksPricing;

const loadingSelector = createLoadingSelector([
  createClerksPricingLoadingSelector,
  getOneClerksPricingLoadingSelector,
  deleteClerksPricingLoadingSelector,
  updateClerksPricingLoadingSelector,
]);

// Success
const createClerksPricingSuccessSelector = state => 
state.companyClerksPricing.success.createClerksPricing;
const getOneClerksPricingSuccessSelector = state => 
state.companyClerksPricing.success.getOneClerksPricing;
const deleteClerksPricingSuccessSelector = state => 
state.companyClerksPricing.success.deleteClerksPricing;
const updateClerksPricingSuccessSelector = state => 
state.companyClerksPricing.success.updateClerksPricing;

const successSelector = createSuccessSelector([
  createClerksPricingSuccessSelector,
  getOneClerksPricingSuccessSelector,
  deleteClerksPricingSuccessSelector,
  updateClerksPricingSuccessSelector,
]);

// ClerksPricings
const getOneClerksPricingSelector = state => 
state.companyClerksPricing.getOneClerksPricing;

const errorSelector = state => 
state.clerksPricing.error;


export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  getOneClerksPricingSelector,
  errorSelector
};
