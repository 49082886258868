import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tippy from 'tippy.js';

import constructionLogo from '../../assets/images/logos/construction.png';
import otherLogo from '../../assets/images/logos/other.png';

import './orderForm.css';
import 'tippy.js/dist/tippy.css';

interface MissionSectorProperties {
  setCount: React.Dispatch<React.SetStateAction<number>>;
}

const MissionSector: React.FC<MissionSectorProperties> = ({ setCount }) => {
  const { t } = useTranslation();

  const [isClickedConstruction, setIsClickedConstruction] =
    useState<boolean>(false);
  const [isClickedOther, setIsClickedOther] = useState<boolean>(false);

  const refConstructionTooltip = useRef(null);
  const refOtherTooltip = useRef(null);

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleClickContruction = () => {
    setIsClickedConstruction(!isClickedConstruction);
    window.sessionStorage.setItem(
      'isClickedConstruction',
      `${!isClickedConstruction}`
    );

    handleIncrement();

    setIsClickedOther(false);
    window.sessionStorage.setItem('isClickedOther', 'false');
  };

  const handleClickOther = () => {
    setIsClickedOther(!isClickedOther);
    window.sessionStorage.setItem('isClickedOther', `${!isClickedOther}`);

    handleIncrement();

    setIsClickedConstruction(false);
    window.sessionStorage.setItem('isClickedConstruction', 'false');
    window.sessionStorage.removeItem('constructionCardSelectedOption');
  };

  useEffect(() => {
    if (window.sessionStorage.getItem('isClickedConstruction') === 'true') {
      setIsClickedConstruction(true);
    }

    if (window.sessionStorage.getItem('isClickedOther') === 'true') {
      setIsClickedOther(true);
    }

    if (refConstructionTooltip.current) {
      tippy(refConstructionTooltip.current, {
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ac augue sed massa blandit vestibulum. In hac habitasse platea dictumst. Nunc scelerisque sapien in elit imperdiet, non tempus lorem porttitor. Nam rutrum ipsum ante, vel eleifend purus ornare vitae. Mauris dignissim hendrerit metus, ac elementum mauris. Nam sit amet congue purus. Phasellus in mattis nisl. Aenean bibendum condimentum elit, quis bibendum dui ultrices at. Suspendisse mattis sed ex id euismod.',
        placement: 'right',
        arrow: true,
      });
    }

    if (refOtherTooltip.current) {
      tippy(refOtherTooltip.current, {
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ac augue sed massa blandit vestibulum. In hac habitasse platea dictumst. Nunc scelerisque sapien in elit imperdiet, non tempus lorem porttitor. Nam rutrum ipsum ante, vel eleifend purus ornare vitae. Mauris dignissim hendrerit metus, ac elementum mauris. Nam sit amet congue purus. Phasellus in mattis nisl. Aenean bibendum condimentum elit, quis bibendum dui ultrices at. Suspendisse mattis sed ex id euismod.',
        placement: 'right',
        arrow: true,
      });
    }
  }, []);

  return (
    <div className='sectorsContainer'>
      <button
        className={`sectorsButton ${isClickedConstruction ? 'clicked' : ''}`}
        onClick={handleClickContruction}
        // ref={refConstructionTooltip}
      >
        <img src={constructionLogo} alt='Construction Logo' />
        <p>{t('orderPageStrings.missionSector.construction')}</p>
      </button>
      <button
        className={`sectorsButton ${isClickedOther ? 'clicked' : ''}`}
        onClick={handleClickOther}
        // ref={refOtherTooltip}
      >
        <img src={otherLogo} alt='Other Logo' />
        <p>{t('orderPageStrings.missionSector.other')}</p>
      </button>
    </div>
  );
};

export default MissionSector;
