let actionTypes = {
  CREATE: "MY_ASD.NEWS.CREATE",
  CREATE_LOADING: "MY_ASD.NEWS.CREATE_LOADING",
  CREATE_SUCCESS: "MY_ASD.NEWS.CREATE_SUCCESS",
  CREATE_ERROR: "MY_ASD.NEWS.CREATE_ERROR",

  GET_ALL: "MY_ASD.NEWS.GET_ALL",
  GET_ALL_LOADING: "MY_ASD.NEWS.GET_ALL_LOADING",
  GET_ALL_SUCCESS: "MY_ASD.NEWS.GET_ALL_SUCCESS",
  GET_ALL_ERROR: "MY_ASD.NEWS.GET_ALL_ERROR",

  GET_ALL_READ: "MY_ASD.NEWS.GET_ALL_READ",
  GET_ALL_READ_LOADING: "MY_ASD.NEWS.GET_ALL_READ_LOADING",
  GET_ALL_READ_SUCCESS: "MY_ASD.NEWS.GET_ALL_READ_SUCCESS",
  GET_ALL_READ_ERROR: "MY_ASD.NEWS.GET_ALL_READ_ERROR",

  IS_READ_BY_USER: "MY_ASD.NEWS.IS_READ_BY_USER",
  IS_READ_BY_USER_LOADING: "MY_ASD.NEWS.IS_READ_BY_USER_LOADING",
  IS_READ_BY_USER_SUCCESS: "MY_ASD.NEWS.IS_READ_BY_USER_SUCCESS",
  IS_READ_BY_USER_ERROR: "MY_ASD.NEWS.IS_READ_BY_USER_ERROR",

  READ_NEWS: "MY_ASD.NEWS.READ_NEWS",
  READ_NEWS_LOADING: "MY_ASD.NEWS.READ_NEWS_LOADING",
  READ_NEWS_SUCCESS: "MY_ASD.NEWS.READ_NEWS_SUCCESS",
  READ_NEWS_ERROR: "MY_ASD.NEWS.READ_NEWS_ERROR",

  GET_ONE: "MY_ASD.NEWS.GET_ONE",
  GET_ONE_LOADING: "MY_ASD.NEWS.GET_ONE_LOADING",
  GET_ONE_SUCCESS: "MY_ASD.NEWS.GET_ONE_SUCCESS",
  GET_ONE_ERROR: "MY_ASD.NEWS.GET_ONE_ERROR",

  REMOVE: "MY_ASD.NEWS.REMOVE",
  REMOVE_LOADING: "MY_ASD.NEWS.REMOVE_LOADING",
  REMOVE_SUCCESS: "MY_ASD.NEWS.REMOVE_SUCCESS",
  REMOVE_ERROR: "MY_ASD.NEWS.REMOVE_ERROR",

  REMOVE_ALL: "MY_ASD.NEWS.REMOVE_ALL",
  REMOVE_ALL_LOADING: "MY_ASD.NEWS.REMOVE_ALL_LOADING",
  REMOVE_ALL_SUCCESS: "MY_ASD.NEWS.REMOVE_ALL_SUCCESS",
  REMOVE_ALL_ERROR: "MY_ASD.NEWS.REMOVE_ALL_ERROR",

  UPDATE: "MY_ASD.NEWS.UPDATE",
  UPDATE_LOADING: "MY_ASD.NEWS.UPDATE_LOADING",
  UPDATE_SUCCESS: "MY_ASD.NEWS.UPDATE_SUCCESS",
  UPDATE_ERROR: "MY_ASD.NEWS.UPDATE_ERROR",

  RESET: "MY_ASD.NEWS.RES",
  REFRESH_ERROR: "MY_ASD.NEWS.REFRESH_ERROR",
};

export default actionTypes;
