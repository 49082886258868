import styled from '@emotion/styled';

export const NotificationNumber = styled.span({
  position: 'relative',
  left: '-15px',
  top: '-10px',
  backgroundColor: 'red',
  color: 'white',
  borderRadius: '50%',
  padding: '.1rem .4rem',
  fontSize: '.8em',
  fontWeight: 'bold',
  textAlign: 'center',
});

export const NotificationWrapper = styled.div({
  cursor: 'pointer',
  marginRight: '1em',
});
