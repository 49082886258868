import Payment from "views/Payment";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.payment,
    component: Payment,
    exact: true,
    authorizedRoles: [],
  },
];
