import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Divider, Transition, Message } from 'semantic-ui-react';
import { Field, FieldArray, Formik, ErrorMessage } from 'formik';

import Wrapper from 'components/core/Wrapper/Wrapper';
import { Error } from 'components/Form';
import Button from 'components/Button';
import { userPermissionsSchema } from 'interfaces/formSchema';
import Dropdown from 'components/Dropdown';

const Index = ({
  intl,
  dispatch,
  permissions,
  operatorId,
  generalPermissions,
  operatorLoading,
  euCountries,
  countriesLoading,
}) => {
  const [collectionboxPermissions, setDebPermissions] = useState(null);
  const [countriesList, setcountriesList] = useState([]);

  useEffect(() => {
    dispatch(actions.admin.getPermissions({
      params: {
        operatorId: operatorId
      }
    }));
    dispatch(actions.data.getEuCountries());

    return () => {
      dispatch(actions.admin.reset(['permissions', 'error']));
      dispatch(actions.data.reset(['euCountries', 'error']));
    };
  }, [dispatch, operatorId]);


  useEffect(() => {
    if(generalPermissions){
        let tab: any = []
        for(const perms of generalPermissions.collectionboxPermissions){
          tab.push({key: perms.Country_Id, value: perms.iso, text: perms.name})
        }
       if(generalPermissions.collectionboxPermissions){
        setDebPermissions(tab)
       }else{
        setDebPermissions(null)
       }  
    }
  }, [generalPermissions]);

  useEffect(() => {
    if (euCountries !== null) {
      setcountriesList(
        euCountries.map((country, index) => {
          return {
            key: index,
            value: {
              key: index,
              value: country.country_code,
              text:  intl.formatMessage({
                id: `countries.name.${country.country_code}`,
              }),
            },
            text: intl.formatMessage({
              id: `countries.name.${country.country_code}`,
            }),
            flag: country.country_code.toLowerCase(),
          };
        })
      );
    }

  }, [euCountries, intl]);


  const save = (values) => {
    let tab = []
    if(values.permissions.length){
      values.permissions.forEach((e) => {
        tab.push({
          Operator_Id: operatorId,
          Country_Id: e.key,
          name: e.text,
          iso: e.value,
        })
      })
    }
   
    dispatch(
      actions.operators.updateOperatorRights({
        params: { operatorId: operatorId },
        data: {
          collectionbox: true,
          collectionboxPerms: tab
        },
      })
    );
  };


  return (
    <Formik
      initialValues={{
        permissions: collectionboxPermissions || [],
      }}
      enableReinitialize={true}
      onSubmit={save}
      validationSchema={userPermissionsSchema}
    >
      {({
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
      }: any) => (
        <Wrapper>
          <Form onSubmit={handleSubmit} loading={operatorLoading}>
            <Wrapper padding='0' display='flex' flexDirection='column' alignItems='center'>
            <FieldArray
              name='permissions'
              render={(arrayHelpers) => (
                <>
                <Transition.Group
                  duration={200}
                  verticalAlign='middle'
                > 
                  {values.permissions.length === 0 && (
                    <Message info>Assign some countries to your acccont</Message>
                  )} 
                             
                  {values.permissions.map((permission: any, index) => {
                    return (          
                    <Wrapper key={index} display='flex' width='50%' height='65px' alignItems='center' justifyContent='space-between' flexWrap='nowrap'>  
                      <Wrapper key={index} width='88%'>                         
                      <Field
                        key={index}
                        placeholder='select country...'
                        name={`permissions[${index}]`}
                        component={Dropdown}
                        options={countriesList}
                        loading={countriesLoading}
                        value={permission}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                      />
                      </Wrapper>
                      <Button
                        icon='minus'
                        type='button'
                        color='red'
                        onClick={() => {
                          arrayHelpers.remove(index)
                        }}
                        maxHeight='2.7rem'
                      />                   
                    </Wrapper>
                  );
                })}
              </Transition.Group>
              <Divider hidden/>
              <Button
                icon='plus'
                type='button'
                onClick={() =>                       
                    arrayHelpers.push({
                      file: undefined,
                      id: undefined,
                      number: '',
                    })                           
                }
                margin='0px'
                position='center'
              /> 
            </>
          )}                                            
          />            
            </Wrapper>
            <ErrorMessage
              name='permissions'
              render={(msg) => <Error msg={msg} />}
            />
            <Divider hidden />
            <Button type='submit' content='Submit' color='green' margin='0px' />
          </Form>
          <Divider hidden/>   
        </Wrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    euCountries: selectors.data.euCountriesSelector(state),
    euCountriesLoading: selectors.data.euCountriesLoadingSelector(state),
    generalPermissions: selectors.admin.permissionsSelector(state),
    operatorLoading: selectors.operators.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
