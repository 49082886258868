import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  createThreshold(data: dataReqI) {
    return {
      type: actionTypes.THRESHOLD_CREATE,
      payload: data,
    };
  },
  updateThreshold(data: dataReqI) {
    return {
      type: actionTypes.THRESHOLD_UPDATE,
      payload: data,
    };
  },
  deleteThreshold(data: dataReqI) {
    return {
      type: actionTypes.THRESHOLD_DELETE,
      payload: data,
    };
  },
  getThreshold(data: dataReqI) {
    return {
      type: actionTypes.GET_THRESHOLD,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
};
