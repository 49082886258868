import { routesApi } from "config/api";
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    getCategories(data: dataReqI){
      const excelGetApi = routesApi.fiscal.fiscalVat.categories;
      return api.request({
        method: excelGetApi.method,
        route: excelGetApi.path,
        params: data.params,
      });
    },
    updateVatDeclarationExcel(data: dataReqI){
      const excelGetApi = routesApi.fiscal.fiscalVat.excel.update;
      return api.request({
        method: excelGetApi.method,
        route: excelGetApi.path,
        params: data.params,
        data: data.data,
        //responseType: 'blob',
      });
    },
    getVatDeclarationExcel(data: dataReqI){
      const excelGetApi = routesApi.fiscal.fiscalVat.excel.getDocument;
      return api.request({
        method: excelGetApi.method,
        route: excelGetApi.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    getObligation(data: dataReqI) {
      const obligationGetApi = routesApi.fiscal.fiscalVat.obligations.getObligation;
      return api.request({
        method: obligationGetApi.method,
        route: obligationGetApi.path,
        params: data.params,
      });
    },
    createObligation(data: dataReqI) {
      const obligationCreateApi = routesApi.fiscal.fiscalVat.obligations.createObligation;
      return api.request({
        method: obligationCreateApi.method,
        route: obligationCreateApi.path,
        params: data.params,
        data: data.data
      });
    },
    addVatDeclarationDocuments(data: dataReqI) {
      const fiscalVatApi = routesApi.fiscal.fiscalVat.addDocuments;

      const form: any = new FormData();
      form.append('type', data.data.type);
      data.data.documents.forEach((e) => {
        e.files.forEach((file) => {
          form.append('documents', file);
        })

        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: e.id,
            number: e.number,
          })
        );
      });

      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    getFilesFullList(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.archivesFiles;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
      });
    },
    getMapping(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.mapping;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        data: data.data
      });
    },
    getDocsFromCat(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.docsFromCat;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        query: data.query
      });
    },
    deleteDoc(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.deleteDoc;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params
      });
    },
    updateDocument(data: dataReqI) {
      const fiscalVatApi = routesApi.fiscal.fiscalVat.updateDocument;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params
      });
    },
    downloadDocument(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.getDocument;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    getAllVatDeclarations(data: dataReqI) {
      const fiscalVatApi = routesApi.fiscal.fiscalVat.getAllVatDeclarations;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        query: data.query
      });
    },
    getAllCompanyVatDeclarations(data: dataReqI) {
      const fiscalVatApi = routesApi.fiscal.fiscalVat.getAllCompanyVatDeclarations;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        query: data.query
      });
    },
    getVatDeclaration(data: dataReqI) {
      const fiscalVatApi = routesApi.fiscal.fiscalVat.getVatDeclaration;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
      });
    },
    updateVatDeclaration(data: dataReqI) {
      const fiscalVatApi = routesApi.fiscal.fiscalVat.updateVatDeclaration;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
        data: data.data
      });
    },
    deleteFiles(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.deleteFiles;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
        data: data.data,
      });
    },
    createVatDeclaration(data: dataReqI) {
      const createfiscalVatFile = routesApi.fiscal.fiscalVat.createVatDeclaration;
      const form: any = new FormData();
      form.set('type', data.data.type);
      form.set('taxPeriod', data.data.taxPeriod);
      form.set('country[]', JSON.stringify(data.data.country));
      form.set('clientReference', data.data.clientReference);
      data.data.documents.forEach((document) => {
        form.append('documents', document.file);
        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: document.id,
            number: document.number,
          })
        );
      });

      return api.request({
        method: createfiscalVatFile.method,
        route: createfiscalVatFile.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    updateStep(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.updateStep;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
        data: data.data
      });
    },
    followVatDeclaration(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.followVatDeclaration;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
        data: data.data
      });
    },
    getCompanySubscribed(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.subscribed;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        data: data.data
      });
    },
    getAllCountryVatDeclarations(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.getAllCountryVatDeclarations;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
      });
    },
    subscribe(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.subscribe;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
        data: data.data
      });
    },
    getOperatorAssignedCountries(data: dataReqI) {
      const countriesApi = routesApi.fiscal.fiscalVat.assigCountries;
      return api.request({
        method: countriesApi.method,
        route: countriesApi.path,
        params: data.params,
      });
    },
    unsubscribe(data: dataReqI){
      const fiscalVatApi = routesApi.fiscal.fiscalVat.unsubscribe;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params,
        data: data.data
      });
    },
  };
};
