import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const thresholdGetErrorsSelector = (state) => state.configurations.errors.threshold;
const thresholdCreateErrorsSelector = (state) => state.configurations.errors.thresholdCreate;
const thresholdUpdateErrorsSelector = (state) => state.configurations.errors.thresholdUpdate;

const errorsSelector = createErrorsSelector([
  thresholdGetErrorsSelector,
  thresholdCreateErrorsSelector,
  thresholdUpdateErrorsSelector,
]);

// Loading
const thresholdGetLoadingSelector = (state) => state.configurations.loading.threshold;
const thresholdCreateLoadingSelector = (state) => state.configurations.loading.thresholdCreate;
const thresholdUpdateLoadingSelector = (state) => state.configurations.loading.thresholdUpdate;

const loadingSelector = createLoadingSelector([
  thresholdGetLoadingSelector,
  thresholdCreateLoadingSelector,
  thresholdUpdateLoadingSelector,
]);

// Success
const thresholdGetSuccessSelector = (state) => state.configurations.success.threshold;
const thresholdCreateSuccessSelector = (state) => state.configurations.success.thresholdCreate;
const thresholdUpdateSuccessSelector = (state) => state.configurations.success.thresholdUpdate;

const successSelector = createSuccessSelector([
  thresholdGetSuccessSelector,
  thresholdCreateSuccessSelector,
  thresholdUpdateSuccessSelector,
]);

// configurations
const thresholdGetSelector = (state) => state.configurations.threshold;
const thresholdCreateSelector = (state) => state.configurations.thresholdCreate;
const thresholdUpdateSelector = (state) => state.configurations.thresholdUpdate;

// Error handler
const errorSelector = (state) => state.configurations.error;


export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  thresholdGetSelector,
  thresholdCreateSelector,
  thresholdUpdateSelector,
  errorSelector
};
