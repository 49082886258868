import actionTypes from 'store/company/settings/collectionbox/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.UPDATE_ASSIGNED_COUNTRIES:
        try {
          next({ type: actionTypes.UPDATE_ASSIGNED_COUNTRIES_LOADING });
          await request.updateAssignedCountries({ ...action.payload });
          next({
            type: actionTypes.UPDATE_ASSIGNED_COUNTRIES_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ASSIGNED_COUNTRIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;


      case actionTypes.GET_ASSIGNED_COUNTRIES:
        try {
          next({ type: actionTypes.GET_ASSIGNED_COUNTRIES_LOADING });
          const resp = await request.getAssignedCountries({ ...action.payload });
          next({
            type: actionTypes.GET_ASSIGNED_COUNTRIES_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ASSIGNED_COUNTRIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.CREATE_SETTING:
        try {
          next({ type: actionTypes.CREATE_SETTING_LOADING });
          await request.handleSettings({ ...action.payload });
          next({
            type: actionTypes.CREATE_SETTING_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_SETTING_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_SETTING:
        try {
          next({ type: actionTypes.GET_SETTING_LOADING });
          const response = await request.getCompanySetting({ ...action.payload });
          next({
            type: actionTypes.GET_SETTING_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_SETTING_ERROR,
            error,
          });
        }
        break;
     
      case actionTypes.DELETE_SETTING:
        try {
          next({ type: actionTypes.DELETE_SETTING_LOADING });
          await request.deleteSetting({ ...action.payload });
          next({
            type: actionTypes.DELETE_SETTING_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DELETE_SETTING_ERROR,
            error,
          });
        }
        break;
      case actionTypes.UPDATE_SETTING:
        try {
          next({ type: actionTypes.UPDATE_SETTING_LOADING });
          await request.updateSetting({ ...action.payload });
          next({
            type: actionTypes.UPDATE_SETTING_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_SETTING_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      default:
        next(action);
        break;
    }
  };
}
