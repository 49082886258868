import React, { FunctionComponent } from 'react';

import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import ButtonValidation from 'components/ButtonValidationModal';
import { RemoveButtonWrapper, ThumbnailWrapper } from './thumbnailStyles';

type ThumbnailProps = {
  file: Object;
  canDelete?: boolean;
  deletePicture?: () => void;
  handleClick?: () => void;
};

const Thumbnail: FunctionComponent<ThumbnailProps> = ({
  file,
  canDelete,
  deletePicture,
  handleClick,
}) => {
  return (
    <ThumbnailWrapper>
      <ImageCustom src={URL.createObjectURL(file)} onClick={handleClick} />
      {canDelete && (
        <RemoveButtonWrapper>
          <ButtonValidation
            headerContent='Delete this picture ?'
            modalContent='By confirming, this picture will be deleted.'
            trgrColor='red'
            trgrIcon='trash'
            noSubmit
            headerIcon='check'
            onClick={deletePicture}
          />
        </RemoveButtonWrapper>
      )}
    </ThumbnailWrapper>
  );
};

export default Thumbnail;
