export default {
  "countries.name.AD": "AD - Andorra",
  "countries.name.AE": "AE - United Arab Emirates",
  "countries.name.AF": "AF - Afghanistan",
  "countries.name.AG": "AG - Antigua and Barbuda",
  "countries.name.AI": "AI - Anguilla",
  "countries.name.AL": "AL - Albania",
  "countries.name.AM": "AM - Armenia",
  "countries.name.AO": "AO - Angola",
  "countries.name.AQ": "AQ - Antarctica",
  "countries.name.AR": "AR - Argentina",
  "countries.name.AS": "AS - American Samoa",
  "countries.name.AT": "AT - Austria",
  "countries.name.AU": "AU - Australia",
  "countries.name.AW": "AW - Aruba",
  "countries.name.AX": "AX - Åland Islands",
  "countries.name.AZ": "AZ - Azerbaijan",
  "countries.name.BA": "BA - Bosnia and Herzegovina",
  "countries.name.BB": "BB - Barbados",
  "countries.name.BD": "BD - Bangladesh",
  "countries.name.BE": "BE - Belgium",
  "countries.name.BF": "BF - Burkina Faso",
  "countries.name.BG": "BG - Bulgaria",
  "countries.name.BH": "BH - Bahrain",
  "countries.name.BI": "BH - Burundi",
  "countries.name.BJ": "BJ - Benin",
  "countries.name.BL": "BL - Saint Barthélemy",
  "countries.name.BM": "BM - Bermuda",
  "countries.name.BN": "BN - Brunei Darussalam",
  "countries.name.BO": "BO - Bolivia",
  "countries.name.BQ": "BQ - Bonaire, Sint Eustatius and Saba",
  "countries.name.BR": "BR - Brazil",
  "countries.name.BS": "BS - Bahamas",
  "countries.name.BT": "BT - Bhutan",
  "countries.name.BV": "BV - Bouvet Island",
  "countries.name.BW": "BW - Botswana",
  "countries.name.BY": "BY - Belarus",
  "countries.name.BZ": "BZ - Belize",
  "countries.name.CA": "CA - Canada",
  "countries.name.CC": "CC - Cocos (Keeling) Islands",
  "countries.name.CD": "CD - Congo, Democratic Republic of the",
  "countries.name.CF": "CF - Central African Republic",
  "countries.name.CG": "CG - Congo",
  "countries.name.CH": "CH - Switzerland",
  "countries.name.CI": "CI - Côte d'Ivoire",
  "countries.name.CK": "CK - Cook Islands",
  "countries.name.CL": "CL - Chile",
  "countries.name.CM": "CM - Cameroon",
  "countries.name.CN": "CN - China",
  "countries.name.CO": "CO - Colombia",
  "countries.name.CR": "CR - Costa Rica",
  "countries.name.CU": "CU - Cuba",
  "countries.name.CV": "CV - Cabo Verde",
  "countries.name.CW": "CW - Curaçao",
  "countries.name.CX": "CX - Christmas Island",
  "countries.name.CY": "CY - Cyprus",
  "countries.name.CZ": "CZ - Czechia",
  "countries.name.DE": "DE - Germany",
  "countries.name.DJ": "DJ - Djibouti",
  "countries.name.DK": "DK - Denmark",
  "countries.name.DM": "DM - Dominica",
  "countries.name.DO": "DO - Dominican Republic",
  "countries.name.DZ": "DZ - Algeria",
  "countries.name.EC": "EC - Ecuador",
  "countries.name.EE": "EE - Estonia",
  "countries.name.EG": "EG - Egypt",
  "countries.name.EH": "EH - Western Sahara",
  "countries.name.EL": "EL - Greece",
  "countries.name.ER": "ER - Eritrea",
  "countries.name.ES": "ES - Spain",
  "countries.name.ET": "ET - Ethiopia",
  "countries.name.FI": "FI- Finland",
  "countries.name.FJ": "FJ - Fiji",
  "countries.name.FK": "FK - Falkland Islands (Malvinas)",
  "countries.name.FM": "FM - Micronesia (Federated States of)",
  "countries.name.FO": "FO - Faroe Islands",
  "countries.name.FR": "FR - France",
  "countries.name.GA": "GA - Gabon",
  "countries.name.GB": "UK - United Kingdom",
  "countries.name.XI": "XI - Northern Ireland",
  "countries.name.GD": "GD - Grenada",
  "countries.name.GE": "GE - Georgia",
  "countries.name.GF": "GF - French Guiana",
  "countries.name.GG": "GG - Guernsey",
  "countries.name.GH": "GH - Ghana",
  "countries.name.GI": "GI - Gibraltar",
  "countries.name.GL": "GL - Greenland",
  "countries.name.GM": "GM - Gambia",
  "countries.name.GN": "GN - Guinea",
  "countries.name.GP": "GP - Guadeloupe",
  "countries.name.GQ": "GQ - Equatorial Guinea",
  "countries.name.GR": "GS - Greece",
  "countries.name.GS": "GS - South Georgia and the South Sandwich Islands",
  "countries.name.GT": "GT - Guatemala",
  "countries.name.GU": "GU - Guam",
  "countries.name.GW": "GW - Guinea-Bissau",
  "countries.name.GY": "GY - Guyana",
  "countries.name.HK": "HK - Hong Kong",
  "countries.name.HM": "HM - Heard Island and McDonald Islands",
  "countries.name.HN": "HN - Honduras",
  "countries.name.HR": "HR - Croatia",
  "countries.name.HT": "HT - Haiti",
  "countries.name.HU": "HU - Hungary",
  "countries.name.ID": "ID - Indonesia",
  "countries.name.IE": "IE - Ireland",
  "countries.name.IL": "IL - Israel",
  "countries.name.IM": "IM - Isle of Man",
  "countries.name.IN": "IN - India",
  "countries.name.IO": "IO - British Indian Ocean Territory",
  "countries.name.IQ": "IQ - Iraq",
  "countries.name.IR": "IR - Iran (Islamic Republic of)",
  "countries.name.IS": "IS - Iceland",
  "countries.name.IT": "IT - Italy",
  "countries.name.JE": "JE - Jersey",
  "countries.name.JM": "JM - Jamaica",
  "countries.name.JO": "JO - Jordan",
  "countries.name.JP": "JP - Japan",
  "countries.name.KE": "KE - Kenya",
  "countries.name.KG": "KG - Kyrgyzstan",
  "countries.name.KH": "KH - Cambodia",
  "countries.name.KI": "KI - Kiribati",
  "countries.name.KM": "KM - Comoros",
  "countries.name.KN": "KN - Saint Kitts and Nevis",
  "countries.name.KP": "KP - Korea (Democratic People's Republic of)",
  "countries.name.KR": "KR - Korea, Republic of",
  "countries.name.KW": "KW - Kuwait",
  "countries.name.KY": "KY - Cayman Islands",
  "countries.name.KZ": "KZ - Kazakhstan",
  "countries.name.LA": "LA - Lao People's Democratic Republic",
  "countries.name.LB": "LB - Lebanon",
  "countries.name.LC": "LC - Saint Lucia",
  "countries.name.LI": "LI - Liechtenstein",
  "countries.name.LK": "LK - Sri Lanka",
  "countries.name.LR": "LB - Liberia",
  "countries.name.LS": "LS - Lesotho",
  "countries.name.LT": "LT - Lithuania",
  "countries.name.LU": "LU - Luxembourg",
  "countries.name.LV": "LV - Latvia",
  "countries.name.LY": "LY - Libya",
  "countries.name.MA": "MA - Morocco",
  "countries.name.MC": "MC - Monaco",
  "countries.name.MD": "MD - Moldova, Republic of",
  "countries.name.ME": "ME - Montenegro",
  "countries.name.MF": "MF - Saint-Martin (France)",
  "countries.name.MG": "MG - Madagascar",
  "countries.name.MH": "MH - Marshall Islands",
  "countries.name.MK": "MK - North Macedonia",
  "countries.name.ML": "ML - Mali",
  "countries.name.MM": "MM - Myanmar",
  "countries.name.MN": "MN - Mongolia",
  "countries.name.MO": "MO - Macao",
  "countries.name.MP": "MP - Northern Mariana Islands",
  "countries.name.MQ": "MG - Martinique",
  "countries.name.MR": "MR - Mauritania",
  "countries.name.MS": "MS - Montserrat",
  "countries.name.MT": "MT - Malta",
  "countries.name.MU": "MU - Mauritius",
  "countries.name.MV": "MV - Maldives",
  "countries.name.MW": "MW - Malawi",
  "countries.name.MX": "MX - Mexico",
  "countries.name.MY": "MY - Malaysia",
  "countries.name.MZ": "MZ - Mozambique",
  "countries.name.NA": "NA - Namibia",
  "countries.name.NC": "NC - New Caledonia",
  "countries.name.NE": "NE - Niger",
  "countries.name.NF": "NF - Norfolk Island",
  "countries.name.NG": "NG - Nigeria",
  "countries.name.NI": "NI - Nicaragua",
  "countries.name.NL": "NL - Netherlands",
  "countries.name.NO": "NO - Norway",
  "countries.name.NP": "NP - Nepal",
  "countries.name.NR": "NR - Nauru",
  "countries.name.NU": "NU - Niue",
  "countries.name.NZ": "NZ - New Zealand",
  "countries.name.OM": "OM - Oman",
  "countries.name.PA": "PA - Panama",
  "countries.name.PE": "PE - Peru",
  "countries.name.PF": "PF - French Polynesia",
  "countries.name.PG": "PG - Papua New Guinea",
  "countries.name.PH": "PH - Philippines",
  "countries.name.PK": "PK - Pakistan",
  "countries.name.PL": "PL - Poland",
  "countries.name.PM": "PM - St. Pierre and Miquelon",
  "countries.name.PN": "PN - Pitcairn",
  "countries.name.PR": "PR - Puerto Rico",
  "countries.name.PS": "PS - Palestine",
  "countries.name.PT": "PT - Portugal",
  "countries.name.PW": "PW - Palau",
  "countries.name.PY": "PY - Paraguay",
  "countries.name.QA": "QA - Qatar",
  "countries.name.RE": "RE - Réunion",
  "countries.name.RO": "RO - Romania",
  "countries.name.RS": "RS - Serbia",
  "countries.name.RU": "RU - Russian Federation",
  "countries.name.RW": "RW - Rwanda",
  "countries.name.SA": "SA - Saudi Arabia",
  "countries.name.SB": "SB - Solomon Islands",
  "countries.name.SC": "SC - Seychelles",
  "countries.name.SD": "SD - Sudan",
  "countries.name.SE": "SE - Sweden",
  "countries.name.SG": "SG - Singapore",
  "countries.name.SH": "SH - Saint Helena, Ascension and Tristan da Cunha",
  "countries.name.SI": "SI - Slovenia",
  "countries.name.SJ": "SJ - Svalbard and Jan Mayen Islands",
  "countries.name.SK": "SK - Slovakia",
  "countries.name.SL": "SL - Sierra Leone",
  "countries.name.SM": "SM - San Marino",
  "countries.name.SN": "SN - Senegal",
  "countries.name.SO": "SO - Somalia",
  "countries.name.SR": "SR - Suriname",
  "countries.name.SS": "SS - South Sudan",
  "countries.name.ST": "ST - Sao Tome and Principe",
  "countries.name.SV": "SV - El Salvador",
  "countries.name.SX": "SX - Sint Maarten (Dutch part)",
  "countries.name.SY": "SY - Syrian Arab Republic",
  "countries.name.SZ": "SZ - Eswatini",
  "countries.name.TC": "TC - Turks and Caicos Islands",
  "countries.name.TD": "TD - Chad",
  "countries.name.TF": "TF - French Southern Territories",
  "countries.name.TG": "TG - Togo",
  "countries.name.TH": "TH - Thailand",
  "countries.name.TJ": "TJ - Tajikistan",
  "countries.name.TK": "TK - Tokelau",
  "countries.name.TL": "TL - Timor-Leste",
  "countries.name.TM": "TM - Turkmenistan",
  "countries.name.TN": "TN - Tunisia",
  "countries.name.TO": "TO - Tonga",
  "countries.name.TR": "TR - Turkey",
  "countries.name.TT": "TT - Trinidad and Tobago",
  "countries.name.TV": "TV - Tuvalu",
  "countries.name.TW": "TW - Taiwan, Province of China	",
  "countries.name.TZ": "TZ - Tanzania, United Republic of",
  "countries.name.UA": "UA - Ukraine",
  "countries.name.UG": "UG - Uganda",
  "countries.name.UM": "UM - United States Minor Outlying Islands",
  "countries.name.US": "US - United States",
  "countries.name.UY": "UY - Uruguay",
  "countries.name.UZ": "UZ - Uzbekistan",
  "countries.name.VA": "VA - Vatican",
  "countries.name.VC": "VC - Saint Vincent and the Grenadines",
  "countries.name.VE": "VE - Venezuela (Bolivarian Republic of)",
  "countries.name.VG": "VG - Virgin Islands (British)",
  "countries.name.VI": "VI - Virgin Islands (U.S.)",
  "countries.name.VN": "VN - Vietnam",
  "countries.name.VU": "VU - Vanuatu",
  "countries.name.WF": "WF - Wallis and Futuna",
  "countries.name.WS": "WS - Samoa",
  "countries.name.YE": "YE - Yemen",
  "countries.name.YT": "YT - Mayotte",
  "countries.name.ZA": "ZA - South Africa",
  "countries.name.ZM": "ZM - Zambia",
  "countries.name.ZW": "ZW - Zimbabwe"
};
