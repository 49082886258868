import actionTypes from 'store/data/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.GETALLCOUNTRIES:
        try {
          next({ type: actionTypes.GETALLCOUNTRIES_LOADING });
          const response = await request.getAllCountries();
          next({
            type: actionTypes.GETALLCOUNTRIES_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GETALLCOUNTRIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GETEUCOUNTRIES:
        try {
          next({ type: actionTypes.GETEUCOUNTRIES_LOADING });
          const response = await request.getEuCountries();
          next({
            type: actionTypes.GETEUCOUNTRIES_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GETEUCOUNTRIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_COUNTRY_VAT_RATE:
        try {
          next({ type: actionTypes.GET_COUNTRY_VAT_RATE_LOADING });
          const response = await request.getCountryVatRate(action.payload);
          next({
            type: actionTypes.GET_COUNTRY_VAT_RATE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_COUNTRY_VAT_RATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
