import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Header, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import { Link } from 'react-router-dom';

import Search from 'components/Search';
import LinkButton from 'components/core/LinkButton/LinkButton';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths } from 'routes/customs/declaration';
import ClickableText from 'components/ClickableText';

const Index = ({ 
  dispatch, 
  companies, 
  history, 
  user,
  parking,
  parkingLoading,
 }) => {

  const userAdminOrOp = user.operator || user.admin;
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [resetSearch, setresetSearch] = useState(false);

  useEffect(() => {
    dispatch(actions.customsDeclaration.getParking());
    dispatch(actions.customsDeclaration.getCompanySubscribed());

    return () => {
      dispatch(
        actions.customsDeclaration.reset(['companySubscribed', 'parking', 'error'])
      );
    };
  }, [dispatch]);

  useEffect(() => {
    setdata(_.orderBy(companies, ['name'], ['asc']));
    resetSearch && setresetSearch(false);
  }, [companies, resetSearch]);

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['name'], ['asc']));
  };

  return (
    <Wrapper overflow='auto' width='80%' margin='auto'>
      <Divider hidden />
      <Wrapper padding='2rem' textAlign='center'>
        <Header size='huge'>
          Customs declaration Operator
          <Header.Subheader>Subscribed Companies</Header.Subheader>
        </Header>
        <Wrapper>(
          {navigator.language === 'fr-FR' ? (
          <ClickableText
            color='black'
            onClick={() => {
              window.open(
                'https://my-asd.s3-eu-west-1.amazonaws.com/customs/Guide_de_l_utilisateur_-_Déclaration_en_douane.pdf',
                '_blank'
              );
              }}
            >
            Guide d'utilisation 
        </ClickableText>
       ) : (
        <ClickableText
          color='black'
          onClick={() => {
            window.open(
              'https://my-asd.s3-eu-west-1.amazonaws.com/customs/User_guide_-_Customs_declaration.pdf',
              '_blank'
            );
            }}
          >
        User guide 
        </ClickableText>
       )}
       
        )<Divider hidden />
        </Wrapper>
        <LinkButton
          content='Go to monitoring'
          color='blue'
          path={paths.customsDeclarationMonitoring}
        />
         <LinkButton
          content='Statistics'
          color='blue'
          path={paths.customsDeclarationStatistic}
        />
      </Wrapper>
      {(companies === null && parkingLoading && <Loader />) || (
        <Wrapper>
          <Wrapper display='flex' justifyContent='space-between'>
          <Search
            dataSource={companies}
            onSearchResultChange={onSearchResultChange}
            setResetSearch={setresetSearch}
          />
    
          {userAdminOrOp && (parking && parking.length>0) && (
            <LinkButton
              content='Parking'
              color='blue'
              path={paths.customsDeclarationParking}
            />
          )}

          </Wrapper>
          <Table size='large' columns='3' sortable selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'name' ? direction : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'email' ? direction : null}
                  onClick={() => handleSort('email')}
                >
                  E-mail
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                  Country
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={3} />) ||
                data.map((company, i) => {
                  return (
                    <Table.Row key={i}>
                      <Link
                        to={paths.customsDeclarationCompany.replace(
                          ':companyId',
                          company.id
                        )}
                        className='table-link'
                      >
                        <Table.Cell>{company.name}</Table.Cell>
                        <Table.Cell>{company.email}</Table.Cell>
                        <Table.Cell>{company.country}</Table.Cell>
                      </Link>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.customsDeclaration.companySubscribedSelector(state),
    errorHandlerClients: selectors.company.errorSelector(state),
    parking: selectors.customsDeclaration.getParkingSelector(state),
    parkingLoading: selectors.customsDeclaration.getParkingLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
