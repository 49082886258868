export default {
  collectionbox: "/fiscal/collectionbox",
  collectionboxMonitoring: '/fiscal/collectionbox-monitoring',
  collectionboxList: '/fiscal/collectionbox/:companyId',
  collectionboxCountryList: '/fiscal/collectionbox/country/:iso',
  collectionboxCompanyList: '/fiscal/collectionbox/country/:iso/:companyId',
  companiescollectionboxDetails: '/fiscal/collectionbox/country/:iso/:companyId/:collectionboxId',
  collectionboxDetails: '/fiscal/collectionbox/:companyId/:collectionboxId',
  collectionboxMonitoringDetail: '/fiscal/collectionbox-monitoring/:companyId/:collectionboxId',
};
