import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Header, Table, Container } from 'semantic-ui-react';
import Button from 'components/Button';
import { actions, selectors } from 'store';
import NewDeclaration from 'views/Fiscal/Vat/Operator/New';
import SearchFilters from '../components/SearchFilters';
import DownloadFilesModal from 'views/Customs/Declaration/Service/DownloadFilesModal/downloadFilesModal';
import { FList, Followers, State, HeaderSub, Period, Spacer, header_sub_css, DropDownVat } from '../Operator/CompanyDashboard/styles';
import EmptyTable from 'components/EmptyTable';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths } from 'routes/fiscal/repository';
import Pagination from 'components/Pagination';
import ClickableText from 'components/ClickableText';
import VatObligation from '../VatObligations';

const Index = ({
  dispatch,
  history,
  error,
  match,
  companySelected,
  user,
  company,
  companyLoading,
  declarationsLoading,
  declarations,
  euCountries,
  euCountriesLoading,
  obligationsloading,
  obligations,
  uniqueId
}) => {
  const companyId = companySelected;
  const LIMIT = 8;
  const PAGE = 1

  const [isoList, setIsoList]: any = useState([])
  const [modalStatus, setmodalStatus]: any = useState(false);
  const [modalObligations, setmodalObligations]: any = useState(false);
  const [monthsList, setMonthList]: any = useState([])
  const [downloadFilesModal, setdownloadFilesModal]: any = useState(false);
  const [column, setcolumn]: any = useState(null);
  const [direction, setdirection]: any = useState(null);
  const [data, setData]: any = useState([]);
  const [count, setCount]: any = useState(null);
  const [filterBar, setfilterBar] = useState(false);
  const [page, setPage]: any = useState(null);
  const [resetP, setResetP]: any = useState(null);
  const [resetPagination, setresetPagination] = useState(false);
  const [session, setSession]: any = useState({
    period: sessionStorage.getItem(`vat_${companyId}`) ? JSON.parse(sessionStorage.getItem(`vat_${companyId}`)).period : '', 
    iso: sessionStorage.getItem(`vat_${companyId}`) ? JSON.parse(sessionStorage.getItem(`vat_${companyId}`)).iso : ''
  })


  let storedPage = useMemo(() => sessionStorage.getItem('c_taxflow_page') ? Number(sessionStorage.getItem('c_taxflow_page')) : 1, [])
  let storedSearch = useMemo(() => JSON.parse(sessionStorage.getItem('c_taxflow_search')), [])

  const initSession = (storedPage, storedSearch) => {
    let storage = Number(sessionStorage.getItem('c_taxflow_page')) || false
    if(storage){
      setPage(storage)
    }else{
      if(storedSearch){
        sessionStorage.setItem('c_taxflow_page', storedSearch.page.toString())
        setPage(storedSearch.page)
      }else if(storedPage){
        setPage(storedPage)
      }
   
    }
  }


  useEffect(() => {

    initSession(storedPage, storedSearch)

    dispatch(actions.company.getOne({
      params: {
        companyId: companyId
      }
    }));

    let initQueryTab = {}
    Object.assign(initQueryTab, {
      limit: LIMIT,
      page: (storedSearch && storedSearch.page) ? storedSearch.page : storedPage,
      ...storedSearch && {
        ...storedSearch.showAll && {showAll: storedSearch.showAll},
        ...storedSearch.id && {id: storedSearch.id},
        ...storedSearch.companyId && {companyId: Number(storedSearch.companyId)},
        ...storedSearch.fromTo && {fromTo: storedSearch.fromTo},
        ...storedSearch.year && {year: storedSearch.year},
        ...storedSearch.month && {month: storedSearch.month},
        ...storedSearch.country && {country: storedSearch.country},
        ...storedSearch.intranetReference && {intranetReference: storedSearch.intranetReference},
      }
    })


    dispatch(
      actions.fiscalVat.getAllVatDeclarations({
        query: initQueryTab,
      })
    );

    dispatch(actions.fiscalVat.getObligation({
      params: {
        companyId: companyId
      }
    }));
    dispatch(actions.data.getEuCountries());
    dispatch(actions.socket.subscribeObligations(companyId))
    dispatch(actions.socket.subscribeVatDeclarations(companyId));
  
    return () => {
      dispatch(actions.fiscalVat.reset([
        'obligation', 
        'getVatDeclarations', 
        'error'
      ]));

      dispatch(actions.company.reset(['error','company']));
      dispatch(actions.data.reset(['euCountries', 'error']));

      dispatch(actions.socket.unsubscribeObligations(companyId))
      dispatch(actions.socket.unsubscribeVatDeclarations(companyId));
    }
  }, [dispatch, companyId, storedPage, storedSearch])

  useEffect(() => {
    if(declarations){
     let countries: any = []
     for(const item of declarations.rows){
       const found = countries.some((value) => value === item.country);
       if(!found){
         countries.push(item.country);
       }
     }
     setData(declarations.rows);
     setCount(declarations.count)
    }
   }, [declarations]);
 
  

  useEffect(() => {
    if(declarations){
      setData(declarations.rows)
      setCount(declarations.count)
     // resetSearch && setresetSearch(false);
    }
  }, [declarations]);

  useEffect(() => {
    if(data){
      setresetPagination(false);
    }
  }, [data]);

  const getDataBySearchFilter = useCallback((search, dataPage: number) => {
    let queryTab = {}
    // dataPage: pagination page number
    let newPage = 
    typeof dataPage === 'number' 
    ? dataPage 
    : 1

   Object.assign(queryTab, {
        limit: LIMIT,
        page: newPage || page || PAGE,
        companyId: companyId,
        showAll: search.showAll,
        ...search.id && {id: search.id},
        ...search.fromTo && {fromTo: search.fromTo},
        ...search.year && {year: search.year},
        ...search.month && {month: search.month},
        ...search.intranetReference && {intranetReference: search.intranetReference},
        ...search.country && {country: search.country},
      })
        
     dispatch(actions.fiscalVat.getAllVatDeclarations({
        query: queryTab,
      }))

    sessionStorage.setItem('c_taxflow_search', JSON.stringify(queryTab));
    sessionStorage.setItem('c_taxflow_page', newPage.toString())

    typeof dataPage === 'number' 
    ? setPage(newPage) 
    : setResetP(true)

    return () => {
      dispatch(actions.fiscalVat.reset([
        'getVatDeclarations', 
        'error'
      ]));
    }
  }, [dispatch, companyId, page]);

  const clean = useMemo(() => Boolean => {
    if(Boolean){
      sessionStorage.removeItem('c_taxflow_search')
      sessionStorage.setItem('c_taxflow_page', PAGE.toString());
    }
  }, [PAGE])

  const resetSearchFilters = useCallback(() => {
    sessionStorage.removeItem('c_taxflow_search')
    sessionStorage.setItem('c_taxflow_page', PAGE.toString());
    clean(true)
    setPage(null)
    setResetP(true)

    dispatch(
      actions.fiscalVat.getAllVatDeclarations({
        query: {
          limit: LIMIT,
          page: PAGE,
        },
      })
    );
    return () => {
      dispatch(
        actions.fiscalVat.reset(['getVatDeclarations', 'error'])
      );
    }
}, [dispatch, clean]);

const handlePageChange = useCallback((event, { activePage }) => {
  if(event){
    setResetP(null)
    const storedSearch = JSON.parse(sessionStorage.getItem('c_taxflow_search'))
    if(storedSearch){
         getDataBySearchFilter(storedSearch, activePage)
    }else{
      dispatch(
        actions.fiscalVat.getAllVatDeclarations({
          query: {
            limit: LIMIT,
            page: activePage,
          },
        })
      );
      setPage(activePage.toString())
      setResetP(null)
  }
}
}, [dispatch, getDataBySearchFilter]);



  useEffect(() => {
    if(declarations){
      // create filter lists
      const activePeriods: any = [], iso: any = [];
      for(const item of declarations.rows){
        const date = new Date(item.taxPeriod)
        const period = `${date.getMonth()}-${date.getFullYear()}`
        const found = activePeriods.some((value) => value === period);
        const isoFound = iso.some((value) => value === item.country);
        if(!found){
          activePeriods.push(period);
        }
        if(!isoFound){
          iso.push(item.country)
        }
      }
      setMonthList(activePeriods.map((period, index) => {
        return {
          key: index,
          value: period,
          text: `${new Date(period.split('-')[1], period.split('-')[0]).toLocaleDateString('en', {
            year: 'numeric',
            month: 'long',
          })}`
        }
      }))
      setIsoList(iso.map((el, index) => {
        return {
          key: index,
          value: el,
          text: el,
        }}))

      // init filters with stored values
      const session = JSON.parse(sessionStorage.getItem(`vat_${companyId}`))
      session && setSession(prevState => ({
        ...prevState,
        iso: session.iso,
        period: session.period
      })) 
    }
  }, [declarations, companyId]);

  useEffect(() => {
    // period and country filters
    const createPeriod = (taxPriod) => {
      let date = new Date(taxPriod)
      return `${date.getMonth()}-${date.getFullYear()}`
    }

    // period and country filters
    const result = session && declarations && declarations.rows.filter(
      (value) => 
        (session.period !== '' && session.iso !== '') ?
        createPeriod(value.taxPeriod) === session.period && value.country === session.iso 
        :
        (session.period === '' && session.iso) ?
        value.country === session.iso 
        :
        (session.iso === '' && session.period !== '') ?
        createPeriod(value.taxPeriod) === session.period 
        :
        (session.period === '' && session.iso === '') ?
        value : null      
      );

      if(result){
        sessionStorage.setItem(`vat_${companyId}`, JSON.stringify(session))
        setData(result)
      }
      
  }, [declarations, session, companyId])

 
  const extractDocuments = (data) => {
    dispatch(
      actions.customsDeclaration.extractDocuments({
        params: {
          companyId,
          from: data.startDate,
          to: data.endDate,
        },
        data: {
          withDocuments: true,
          docType: 'dau',
          attributes: [
            'id',
            'fortyTwoRegime',
            'createdAt',
            'state',
            'trailerLicensePlate',
            'truckLicensePlate',
            'truckOrigin',
          ],
        },
      })
    );
    setdownloadFilesModal(false);
  };

  const handleSort = (name) => {
    if(column !== name){
      setcolumn(name);
      setdirection('descending');
      setData(_.sortBy(data, [name]));
      return;

    }
    setData(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  return (
    <Wrapper width='80%' margin='auto'>
      <Divider hidden />
      <Header size='huge' textAlign='center' style={header_sub_css}>Vat declaration dashboard <HeaderSub>{company && company.name}</HeaderSub></Header>
      <Container textAlign='center'>
       {navigator.language === 'fr-FR' ? (
          <ClickableText
            color='black'
            onClick={() => {
              window.open(
                'https://my-asd.s3-eu-west-1.amazonaws.com/customs/Guide_de_l_utilisateur_-_Déclaration_en_douane.pdf',
                '_blank'
              );
              }}
            >
            Guide d'utilisation
        </ClickableText>
       ) : (
        <ClickableText
          color='black'
          onClick={() => {
            window.open(
              'https://my-asd.s3-eu-west-1.amazonaws.com/customs/User_guide_-_Customs_declaration.pdf',
              '_blank'
            );
            }}
          >
        User guide 
        </ClickableText>
       )}        
      </Container>
      <Divider hidden />
      <Wrapper display='flex' justifyContent='space-between'>
        {!error && (
          <Wrapper display='flex' justifyContent='space-around'>
            <Button
              icon='filter'
              type='button'
              content='Search Filters'
              color='blue'
              onClick={() => {
                setfilterBar(!filterBar)
              }}
            />
            <Button
              labelPosition='right'
              position='left'
              icon='plus circle'
              type='button'
              content='New declaration'
              onClick={() => setmodalStatus(true)}
            />
            <Button
              labelPosition='right'
              position='left'
              icon='plus circle'
              type='button'
              content='Obligations'
              onClick={() => setmodalObligations(true)}
            />

            {/* <Button
                labelPosition='right'
                position='left'
                icon='plus circle'
                type='button'
                content='Import intranet data'
                onClick={() => setmodalObligations(true)}
                />
            */}
            <DropDownVat
              clearable
              search
              selection
              placeholder='Tax period...'
              options={monthsList}
              defaultValue={session.period || null}
              onChange={(e, {value}) => {
                e && setSession(prevState => ({
                  ...prevState,
                  period: value
                }))
              }}
            />
            <DropDownVat
              clearable
              search
              selection
              placeholder='Country...'
              options={isoList}
              defaultValue={session.iso || null}
              onChange={(e, {value}) => {
                e && setSession(prevState => ({
                  ...prevState,
                  iso: value
                }))
              }}
            />        
        </Wrapper>
        )}

      {/* <SearchVat
        dataSource={declarations && declarations.rows}
        onSearchResultChange={onSearchResultChange}
        setResetSearch={setresetSearch}
      /> */}
      </Wrapper>
      <Divider hidden />
      {modalObligations && obligations && session && (
        <VatObligation
          open={modalObligations}
          companyId={Number(companyId)}
          companyName= {company.name}
          obligations={obligations}
          euCountries={euCountries}
          handleClose={() => setmodalObligations(false)}
        />
      )}
      {modalStatus && euCountries && obligations && (
        <NewDeclaration
          open={modalStatus}
          companyName={company.name}
          obligations={obligations}
          euCountries={euCountries}
          handleClose={() => setmodalStatus(false)}
          companyId={companyId}
          match={match}
        />
      )}
      {downloadFilesModal && (
        <DownloadFilesModal
          open={downloadFilesModal}
          handleClose={() => setdownloadFilesModal(false)}
          match={match}
          extractDocuments={extractDocuments}
        />
      )}

      {((
        declarations === null || 
        count === null || 
        data === null || 
        (declarationsLoading && !filterBar) ||
        euCountriesLoading ||
        obligationsloading ||
        companyLoading
      ) && <Loader content='Loading' />)}

      <Wrapper overflow='auto'>
      {filterBar && (<>
        <SearchFilters
          isVisible={filterBar}
          isoList={isoList}
          applyFilters={getDataBySearchFilter}
          resetFilters={resetSearchFilters}
          declarationsLoading={declarationsLoading}
        />
        <Divider hidden/>
        </>)}
      </Wrapper>
  
        <Wrapper overflow='auto'>
          <p>Number of declarations: {count}</p>
          <Table columns='8' stackable sortable selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'id' ? direction : null}
                  onClick={() => handleSort('id')}
                >
                  Declaration
                </Table.HeaderCell>
                <Table.HeaderCell
                   sorted={column === 'taxPeriod' ? direction : null}
                   onClick={() => handleSort('taxPeriod')}>
                  Tax Period
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'createdAt' ? direction : null}
                  onClick={() => handleSort('createdAt')}
                >
                  Created On
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                  Country
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'periodicity' ? direction : null}
                  onClick={() => handleSort('periodicity')}
                >
                  Periodicity
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'nth' ? direction : null}
                  onClick={() => handleSort('nth')}
                >
                  nth
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'emitter' ? direction : null}
                  onClick={() => handleSort('emitter')}
                >
                  Emitter
                </Table.HeaderCell>
                <Table.HeaderCell
                >
                  Follower
                </Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={9}/>) ||
                (data && data.map((e, i) => {
                  return (
                    <Table.Row key={i}>
                      <Link
                        to={paths.repositoryDetails
                          .replace(':companyId', e.Company_Id)
                          .replace(':declarationId', e.id)}
                        className='table-link'
                      >
                        <Table.Cell>{e.id}</Table.Cell>
                        <Table.Cell>
                        <Period>
                          {new Date(e.taxPeriod).toLocaleDateString('en', {
                            month: 'long',
                          })}
                        <Spacer>
                          {new Date(e.taxPeriod).toLocaleDateString('en', {
                            year: 'numeric',
                          })}
                        </Spacer>
                        </Period>                      
                        </Table.Cell>                     
                        <Table.Cell>
                          {new Date(e.createdAt).toLocaleDateString('en', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}             
                        </Table.Cell>                     
                        <Table.Cell>{e.country}</Table.Cell>
                        <Table.Cell>{e.periodicity && <Period>{e.periodicity}</Period>}</Table.Cell>    
                        <Table.Cell>{e.nth && <Period>{e.nth}</Period>}</Table.Cell>                       
                        <Table.Cell>{e.emitter.fullname}</Table.Cell>
                        <Table.Cell>
                          <Followers>
                          {
                            e.follower && 
                            e.follower.length &&  
                            e.follower.map((user, i) => (
                                  <FList key={i}>{`${user.operator}`}</FList>
                                ))
                          }
                          </Followers>                     
                        </Table.Cell>
                        <Table.Cell>
                          <State defaultValue={e.state === 'cancelled' ? 'red' : e.state === 'processing' ? '#dda80b' : '#00619b'}>{e.state}</State>
                        </Table.Cell>
                      </Link>
                    </Table.Row>
                  );
                }))}
            </Table.Body>
          </Table>
        </Wrapper>
   
      <Divider hidden />
      {!resetPagination && count > 0 && (
        <Wrapper display='flex' justifyContent='center'>
          <Pagination
            totalPages={count / LIMIT}
            defaultActivePage={PAGE}
            storedPage={page}
            resetPagination={resetP}
            onChange={handlePageChange}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    company: selectors.company.companySelector(state),
    companyLoading: selectors.company.getOneLoadingSelector(state),
    declarations: selectors.fiscalVat.getfiscalVatDeclarationsSelector(state),
    declarationsLoading: selectors.fiscalVat.getfiscalVatDeclarationsLoadingSelector(state),
    obligationsloading: selectors.fiscalVat.obligationLoadingSelector(state),
    obligations: selectors.fiscalVat.obligationGetSelector(state),
    euCountries: selectors.data.euCountriesSelector(state),
    euCountriesLoading: selectors.data.euCountriesLoadingSelector(state),
    error: selectors.message.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
