import React from 'react';

import {
  ProgressBar,
  Progression,
  Step,
} from './customsDeclarationProgressBarStyles';
import { customsDeclarationSteps } from 'constants/transitCategories';

const TransitProgressBar: React.FunctionComponent<TransitProgressBarProgressBarProps> = ({
  maxStep,
  actualStep,
  loading,
  onClick,
}) => {
  const defineStep = (stepKey: number) => {
    const STATES = {
      complete: 'complete',
      active: 'active',
      none: '',
    };

    if (actualStep > stepKey || actualStep === maxStep) return STATES.complete;
    else if (actualStep === stepKey) return STATES.active;
    else return STATES.none;
  };

  return (
    <ProgressBar>
      <Progression />
      {Array.from(Array(maxStep).keys()).map((e, i) => (
        <Step
          key={i}
          cliquable={actualStep !== i + 1 && Boolean(onClick)}
          loading={new Boolean(loading)}
          onClick={() => onClick && onClick(i + 1)}
          keyStep={i}
          state={defineStep(i + 1)}
        >
          {customsDeclarationSteps[i]}
        </Step>
      ))}
    </ProgressBar>
  );
};

interface TransitProgressBarProgressBarProps {
  maxStep: number;
  actualStep: number;
  loading?: boolean;
  onClick: (step: number) => void;
}

export default TransitProgressBar;
