import styled from '@emotion/styled';

export const DividerStyled = styled.div(
  ({ height, width, backgroundColor, margin }: SpacerStyledProps) => ({
    height,
    width,
    backgroundColor,
    ...(margin && { margin }),
  })
);

interface SpacerStyledProps {
  height: string;
  width: string;
  backgroundColor: string;
  margin?: string;
}
