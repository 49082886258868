import actionTypes from 'store/company/clerksPricing/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.CREATE_CLERKS_PRICING:
        try {
          next({ type: actionTypes.CREATE_CLERKS_PRICING_LOADING });
          await request.createClerksPricing({ ...action.payload });
          next({
            type: actionTypes.CREATE_CLERKS_PRICING_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_CLERKS_PRICING_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
     
      case actionTypes.GET_ONE_CLERKS_PRICING:
        try {
          next({ type: actionTypes.GET_ONE_CLERKS_PRICING_LOADING });
          const response = await request.getOneClerksPricing({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_ONE_CLERKS_PRICING_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_CLERKS_PRICING_ERROR,
            error,
          });
        }
        break;
      case actionTypes.DELETE_CLERKS_PRICING:
        try {
          next({ type: actionTypes.DELETE_CLERKS_PRICING_LOADING });
          await request.deleteClerksPricing({ ...action.payload });
          next({
            type: actionTypes.DELETE_CLERKS_PRICING_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DELETE_CLERKS_PRICING_ERROR,
            error,
          });
        }
        break;
      case actionTypes.UPDATE_CLERKS_PRICING:
        try {
          next({ type: actionTypes.UPDATE_CLERKS_PRICING_LOADING });
          await request.updateClerksPricing({ ...action.payload });
          next({
            type: actionTypes.UPDATE_CLERKS_PRICING_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_CLERKS_PRICING_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      default:
        next(action);
        break;
    }
  };
}
