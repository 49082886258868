import styled from '@emotion/styled';
import { Menu, Dropdown } from 'semantic-ui-react';
import bg from 'assets/images/bg-header.png';

export const MenuStyled = styled(Menu)`
  &&& {
    background: url(${bg}) 100%;
    background-size: cover;
  }
`;

export const MenuItemStyled = styled(Menu.Item)`
  &&& {
    color: white;
  }
`;

export const MenuItemWrapper = styled.div(
  ({ margin, position, maxHeight }: any) => ({
    display: '-webkit-inline-box',
    flexDirection: 'row',
    maxHeight: maxHeight ? maxHeight : 'none',
    justifyContent: position ? position : 'center',
    margin
  })
);

export const DropdownItemStyled = styled(Dropdown.Item)`
  &&& {
  }
`;

export const DropdownMenuStyled = styled(Dropdown.Menu)`
  &&& {
  }
`;

export const DropdownStyled = styled(Dropdown)`
  &&& {
    color: white;
  }
`;

export const DropdownSrollStyled = styled(Dropdown)`
  &&& {
    color: white;
  }
  &&& .visible.menu.transition {
    max-height: 328px;
    min-height: 33px;
    overflow: hidden;
    overflow-y: scroll;
  }
`;

export const Disconnect = styled.div(() => ({
  display: 'block',
  textAlign: 'center',
  lineHeight: '36px',
  position: 'relative',
  width: '100%',
  height: '36px',
  fontSize: '14px',
  backgroundColor: '#0a326d',
  color: 'white',
  zIndex: 5,
  '&&&:hover': {
    backgroundColor: '#0a326de0'
  },
}));

export const Btn = styled.div(() => ({
  float: 'left',
  position: 'absolute',
  top: '40%',
  left: '15px',
  width: '9px',
  height: '9px',
  cursor: 'pointer',
  borderRadius: '5px',
  backgroundColor: 'white',
}))

export const SubMenuWrapper = styled.div(() => ({
  position: 'absolute',
  right: '20px',
  marginTop: '1.7em',
  backgroundColor: 'white',
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 8%)',
  border: '1px solid rgba(34,36,38,.15)',
  borderRadius: '5px',
  zIndex: 3,
}));

export const SubMenuItem = styled.div(() => ({
  color: 'black',
  padding: '15px',
  '&&&:hover': {
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,.05)',
  },
}));

export const UserItems = styled.div(() => ({
  width: 'max-content',
  fontSize: '10px',
  fontWeight: 'normal',
  lineHeight: '22px',
  padding: '5px 15px',
  float: 'left',
  borderBottom: '1px solid rgb(204,204,204)',
  '&&&:last-child': {
    borderBottom: 'none'
  },
}))

export const UserItemsBullet = styled.div(() => ({
  background: 'green',
  width: '10px',
  float: 'left',
  height: '10px',
  borderRadius: '5px',
  margin: '6px 6px 6px 0',
}))
