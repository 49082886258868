import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  createClerksPricing(data: dataReqI) {
    return {
      type: actionTypes.CREATE_CLERKS_PRICING,
      payload: data
    };
  },
  getOneClerksPricing(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_CLERKS_PRICING,
      payload: data
    };
  },
  deleteClerksPricing(data: dataReqI) {
    return {
      type: actionTypes.DELETE_CLERKS_PRICING,
      payload: data
    };
  },
  updateClerksPricing(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_CLERKS_PRICING,
      payload: data
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys
      }
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys
      }
    };
  }
};
