import { Field, Formik, FieldArray } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Divider,
  Form,
  Header,
  Modal,
  Transition,
  List,
} from 'semantic-ui-react';
import { selectors } from 'store';
import Button from 'components/Button';
import {} from 'interfaces/formSchema';
import { Input } from 'components/Form';
import Wrapper from 'components/core/Wrapper/Wrapper';
import ButtonValidation from 'components/ButtonValidationModal';
import { ssrSendResultsSchema } from 'interfaces/formSchema';

const Index = ({ open, submitAction, handleClose, user }) => {
  const formikRef = useRef();
  const [initialValues, setinitialValues] = useState(null);

  useEffect(() => {
    setinitialValues({ ccEmails: [] });
  }, [user]);

  const submit = (values) => {
    submitAction(values);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Header icon='mail' content='Send results' />
      {initialValues && (
        <Formik
          ref={formikRef}
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={ssrSendResultsSchema}
          enableReinitialize={true}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
            isSubmitting,
          }) => (
            <Modal.Content>
              <Wrapper margin='0 5rem'>
                <Form onSubmit={handleSubmit}>
                  <Wrapper margin='1rem'>
                    <Header textAlign='center'>
                      <Header.Subheader>
                        This email will be sent to you and to the technical
                        office.
                      </Header.Subheader>
                      <Header.Subheader>
                        You can add more emails in Cc.
                      </Header.Subheader>
                    </Header>
                    <FieldArray
                      name='ccEmails'
                      render={({ remove, push }) => (
                        <Transition.Group
                          as={List}
                          duration={200}
                          verticalAlign='middle'
                        >
                          <Wrapper>
                            {values.ccEmails.map((data, index) => {
                              return (
                                <List.Item key={index}>
                                  <Wrapper
                                    display='flex'
                                    justifyContent='center'
                                  >
                                    <Wrapper margin='0 1rem 0 0'>
                                      <Field
                                        mandatory
                                        label={`Cc email n° ${index + 1}:`}
                                        labelPosition='left'
                                        placeholder='Cc email'
                                        name={`ccEmails[${index}]`}
                                        type='text'
                                        component={Input}
                                        meta={{ errors, touched }}
                                      />
                                    </Wrapper>
                                    <Button
                                      icon='minus'
                                      type='button'
                                      color='red'
                                      onClick={() => remove(index)}
                                      maxHeight='2.7rem'
                                    />
                                  </Wrapper>
                                  <Divider hidden />
                                </List.Item>
                              );
                            })}
                            <Button
                              icon='plus'
                              content='Add Cc Email'
                              labelPosition='right'
                              type='button'
                              color='blue'
                              onClick={() => push('')}
                              maxHeight='2.7rem'
                            />
                          </Wrapper>
                        </Transition.Group>
                      )}
                    />
                    <ButtonValidation
                      trgrContent='Send'
                      headerContent='Send the results ?'
                      modalContent='By confirming, the results will be sent.'
                      trgrColor='blue'
                      trgrIcon='check'
                      headerIcon='check'
                      onClick={() => {
                        handleSubmit();
                      }}
                      noSubmit
                    />
                  </Wrapper>
                </Form>
              </Wrapper>
            </Modal.Content>
          )}
        </Formik>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
