import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const socketApi = routesApi.user;

  return {
    removeCookies(data: dataReqI) {
      return api.request({
        method: socketApi.removeCookies.method,
        route: socketApi.removeCookies.path,
        data: data.data,
      });
    },
  };
};
