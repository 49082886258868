import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const createFileErrorSelector = (state) => state.customsClerk.errors.createFile;
const getIssuesByFilesIdErrorSelector = (state) =>
  state.customsClerk.errors.getIssuesByFilesId;
const downloadPicturesIssuesZIPErrorSelector = (state) =>
  state.customsClerk.errors.downloadPicturesIssuesZIP;
const getSummaryErrorSelector = (state) => state.customsClerk.errors.getSummary;
const getAllFilesErrorSelector = (state) =>
  state.customsClerk.errors.getAllFiles;
const getAllFilesByFiltersErrorSelector = (state) =>
  state.customsClerk.errors.getAllFilesByFilters;
const updateIssueErrorSelector = (state) =>
  state.customsClerk.errors.updateIssue;
const addIssuePicturesErrorSelector = (state) =>
  state.customsClerk.errors.addIssuePictures;
const deleteIssuePictureErrorSelector = (state) =>
  state.customsClerk.errors.deleteIssuePicture;
const getBillingsErrorSelector = (state) =>
  state.customsClerk.errors.getBillings;
const getFileHasBillingsErrorSelector = (state) =>
  state.customsClerk.errors.getFileHasBillings;
const addInvoicingLineErrorSelector = (state) =>
  state.customsClerk.errors.addInvoicingLine;
const updateInvoicingLineErrorSelector = (state) =>
  state.customsClerk.errors.updateInvoicingLine;
const removeInvoicingLineErrorSelector = (state) =>
  state.customsClerk.errors.removeInvoicingLine;
const validateFileErrorSelector = (state) =>
  state.customsClerk.errors.validateFile;
const updateInvoicingStepErrorSelector = (state) =>
  state.customsClerk.errors.updateInvoicingStep;
const validateFileStateErrorSelector = (state) =>
  state.customsClerk.errors.validateFileState;
const getStartSignatureErrorSelector = (state) =>
  state.customsClerk.errors.getStartSignature;
const getTrailerLicensePlateErrorSelector = (state) =>
  state.customsClerk.errors.getTrailerLicensePlate;
const getPaymentReceiptErrorSelector = (state) =>
  state.customsClerk.errors.getPaymentReceipt;
const getEndSignatureErrorSelector = (state) =>
  state.customsClerk.errors.getEndSignature;
const pdfFileErrorSelector = (state) => 
  state.customsClerk.errors.getpdfFile;
const createClerckControlPdfErrorSelector = (state) => 
  state.customsClerk.errors.createClerkControlPdfFile;
const cdBillingsErrorSelector = (state) => 
  state.customsClerk.errors.cdBillings;
  const pochetteErrorSelector = (state) => 
  state.customsClerk.errors.pochette;
  

const errorsSelector = createErrorsSelector([
  createFileErrorSelector,
  getIssuesByFilesIdErrorSelector,
  downloadPicturesIssuesZIPErrorSelector,
  getSummaryErrorSelector,
  getAllFilesErrorSelector,
  getAllFilesByFiltersErrorSelector,
  updateIssueErrorSelector,
  addIssuePicturesErrorSelector,
  deleteIssuePictureErrorSelector,
  getBillingsErrorSelector,
  getFileHasBillingsErrorSelector,
  addInvoicingLineErrorSelector,
  updateInvoicingLineErrorSelector,
  removeInvoicingLineErrorSelector,
  validateFileErrorSelector,
  updateInvoicingStepErrorSelector,
  validateFileStateErrorSelector,
  getStartSignatureErrorSelector,
  getTrailerLicensePlateErrorSelector,
  getPaymentReceiptErrorSelector,
  getEndSignatureErrorSelector,
  pdfFileErrorSelector,
  createClerckControlPdfErrorSelector,
  cdBillingsErrorSelector,
]);

// Loading
const createFileLoadingSelector = (state) =>
  state.customsClerk.loading.createFile;
const getIssuesByFilesIdLoadingSelector = (state) =>
  state.customsClerk.loading.getIssuesByFilesId;
const downloadPicturesIssuesZIPLoadingSelector = (state) =>
  state.customsClerk.loading.downloadPicturesIssuesZIP;
const getSummaryLoadingSelector = (state) =>
  state.customsClerk.loading.getSummary;
const getAllFilesLoadingSelector = (state) =>
  state.customsClerk.loading.getAllFiles;
const getAllFilesByFiltersLoadingSelector = (state) =>
  state.customsClerk.loading.getAllFilesByFilters;
const updateIssueLoadingSelector = (state) =>
  state.customsClerk.loading.updateIssue;
const addIssuePicturesLoadingSelector = (state) =>
  state.customsClerk.loading.addIssuePictures;
const deleteIssuePictureLoadingSelector = (state) =>
  state.customsClerk.loading.deleteIssuePicture;
const getBillingsLoadingSelector = (state) =>
  state.customsClerk.loading.getBillings;
const getFileHasBillingsLoadingSelector = (state) =>
  state.customsClerk.loading.getFileHasBillings;
const addInvoicingLineLoadingSelector = (state) =>
  state.customsClerk.loading.addInvoicingLine;
const updateInvoicingLineLoadingSelector = (state) =>
  state.customsClerk.loading.updateInvoicingLine;
const removeInvoicingLineLoadingSelector = (state) =>
  state.customsClerk.loading.removeInvoicingLine;
const validateFileLoadingSelector = (state) =>
  state.customsClerk.loading.validateFile;
const updateInvoicingStepLoadingSelector = (state) =>
  state.customsClerk.loading.updateInvoicingStep;
const validateFileStateLoadingSelector = (state) =>
  state.customsClerk.loading.validateFileState;
const getStartSignatureLoadingSelector = (state) =>
  state.customsClerk.loading.getStartSignature;
const getTrailerLicensePlateLoadingSelector = (state) =>
  state.customsClerk.loading.getTrailerLicensePlate;
const getPaymentReceiptLoadingSelector = (state) =>
  state.customsClerk.loading.getPaymentReceipt;
const getEndSignatureLoadingSelector = (state) =>
  state.customsClerk.loading.getEndSignature;
const pdfFileLoadingSelector = (state) => 
  state.customsClerk.loading.getpdfFile;
const createClerckControlPdfLoadingSelector = (state) => 
  state.customsClerk.loading.createClerkControlPdfFile;
const cdBillingsLoadingSelector = (state) => 
  state.customsClerk.loading.cdBillings;
  const billingsLoadingSelector = (state) => 
  state.customsClerk.loading.billings;
const pochetteLoadingSelector = (state) => 
  state.customsClerk.loading.pochette;


const loadingSelector = createLoadingSelector([
  createFileLoadingSelector,
  getIssuesByFilesIdLoadingSelector,
  downloadPicturesIssuesZIPLoadingSelector,
  getSummaryLoadingSelector,
  getAllFilesLoadingSelector,
  getAllFilesByFiltersLoadingSelector,
  updateIssueLoadingSelector,
  addIssuePicturesLoadingSelector,
  deleteIssuePictureLoadingSelector,
  getBillingsLoadingSelector,
  getFileHasBillingsLoadingSelector,
  addInvoicingLineLoadingSelector,
  updateInvoicingLineLoadingSelector,
  removeInvoicingLineLoadingSelector,
  validateFileLoadingSelector,
  updateInvoicingStepLoadingSelector,
  validateFileStateLoadingSelector,
  getStartSignatureLoadingSelector,
  getTrailerLicensePlateLoadingSelector,
  getPaymentReceiptLoadingSelector,
  getEndSignatureLoadingSelector,
  pdfFileLoadingSelector,
  createClerckControlPdfLoadingSelector,
  cdBillingsLoadingSelector,
]);

// Success
const createFileSuccessSelector = (state) =>
  state.customsClerk.success.createFile;
const getIssuesByFilesIdSuccessSelector = (state) =>
  state.customsClerk.success.getIssuesByFilesId;
const downloadPicturesIssuesZIPSuccessSelector = (state) =>
  state.customsClerk.success.downloadPicturesIssuesZIP;
const getSummarySuccessSelector = (state) =>
  state.customsClerk.success.getSummary;
const getAllFilesSuccessSelector = (state) =>
  state.customsClerk.success.getAllFiles;
const getAllFilesByFiltersSuccessSelector = (state) =>
  state.customsClerk.success.getAllFilesByFilters;
const updateIssueSuccessSelector = (state) =>
  state.customsClerk.success.updateIssue;
const addIssuePicturesSuccessSelector = (state) =>
  state.customsClerk.success.addIssuePictures;
const deleteIssuePictureSuccessSelector = (state) =>
  state.customsClerk.success.deleteIssuePicture;
const getBillingsSuccessSelector = (state) =>
  state.customsClerk.success.getBillings;
const getFileHasBillingsSuccessSelector = (state) =>
  state.customsClerk.success.getFileHasBillings;
const addInvoicingLineSuccessSelector = (state) =>
  state.customsClerk.success.addInvoicingLine;
const updateInvoicingLineSuccessSelector = (state) =>
  state.customsClerk.success.updateInvoicingLine;
const removeInvoicingLineSuccessSelector = (state) =>
  state.customsClerk.success.removeInvoicingLine;
const validateFileSuccessSelector = (state) =>
  state.customsClerk.success.validateFile;
const updateInvoicingStepSuccessSelector = (state) =>
  state.customsClerk.success.updateInvoicingStep;
const validateFileStateSuccessSelector = (state) =>
  state.customsClerk.success.validateFileState;
const getStartSignatureSuccessSelector = (state) =>
  state.customsClerk.success.getStartSignature;
const getTrailerLicensePlateSuccessSelector = (state) =>
  state.customsClerk.success.getTrailerLicensePlate;
const getPaymentReceiptSuccessSelector = (state) =>
  state.customsClerk.success.getPaymentReceipt;
const getEndSignatureSuccessSelector = (state) =>
  state.customsClerk.success.getEndSignature;
const pdfFileSuccessSelector = (state) => 
  state.customsClerk.success.getpdfFile;
const createClerckControlPdfSuccessSelector = (state) => 
  state.customsClerk.success.createClerkControlPdfFile;
const cdBillingsSuccessSelector = (state) => 
  state.customsClerk.success.cdBillings;
  const billingsSuccessSelector = (state) => 
state.customsClerk.success.billings;
const pochetteSuccessSelector = (state) => 
  state.customsClerk.success.pochette;


const successSelector = createSuccessSelector([
  createFileSuccessSelector,
  getIssuesByFilesIdSuccessSelector,
  downloadPicturesIssuesZIPSuccessSelector,
  getSummarySuccessSelector,
  getAllFilesSuccessSelector,
  getAllFilesByFiltersSuccessSelector,
  updateIssueSuccessSelector,
  addIssuePicturesSuccessSelector,
  deleteIssuePictureSuccessSelector,
  getBillingsSuccessSelector,
  getFileHasBillingsSuccessSelector,
  addInvoicingLineSuccessSelector,
  updateInvoicingLineSuccessSelector,
  removeInvoicingLineSuccessSelector,
  validateFileSuccessSelector,
  updateInvoicingStepSuccessSelector,
  validateFileStateSuccessSelector,
  getStartSignatureSuccessSelector,
  getTrailerLicensePlateSuccessSelector,
  getPaymentReceiptSuccessSelector,
  getEndSignatureSuccessSelector,
  pdfFileSuccessSelector,
  createClerckControlPdfSuccessSelector,
  cdBillingsSuccessSelector,
  billingsSuccessSelector
]);

const errorSelector = (state) => state.customsClerk.error;
const summarySelector = (state) => state.customsClerk.summary;
const invoiceSelector = (state) => state.customsClerk.invoice;
const allFilesLoadingSelector = (state) => state.customsClerk.loading.allFiles;
const allFilesSelector = (state) => state.customsClerk.allFiles;
const allFilesCountSelector = (state) => state.customsClerk.allFilesCount;
const allFilesByFiltersLoadingSelector = (state) =>
  state.customsClerk.loading.allFilesByFilters;
const allFilesByFiltersSelector = (state) =>
  state.customsClerk.allFilesByFilters;
const allFilesByFiltersCountSelector = (state) =>
  state.customsClerk.allFilesByFiltersCount;
const filesPicturesSelector = (state) => state.customsClerk.filesPictures;
const picturesIssuesZipSelector = (state) =>
  state.customsClerk.picturesIssuesZip;
const declarationInProgressSelector = (state) =>
  state.customsClerk.declarationInProgress;
const declarationInProgressLoadingSelector = (state) =>
  state.customsClerk.loading.getDeclarationInProgress;
const oneFileSelector = (state) => 
state.customsClerk.oneFile;
const oneFileLoadingSelector = (state) => 
state.customsClerk.loading.getOneFile;
const fileDocumentsSelector = (state) => 
state.customsClerk.fileDocuments;
const issueSelector = (state) => 
state.customsClerk.issue;
const billingsSelector = (state) => 
state.customsClerk.billings;
const fileHasBillingsSelector = (state) => 
state.customsClerk.fileHasBillings;
const createdFileIdSelector = (state) => 
state.customsClerk.createdFileId;
const issueTimesSelector = (state) => 
state.customsClerk.issueTimes;
const cdBillingsSelector = (state) => 
state.customsClerk.cdBillings;

const startSignatureSelector = (state) => state.customsClerk.startSignature;
const trailerLicensePlateSelector = (state) => state.customsClerk.trailerLicensePlate;
const paymentReceiptSelector = (state) => state.customsClerk.paymentReceipt;
const endSignatureSelector = (state) => state.customsClerk.endSignature;
const pdfFilesSelector = (state) => state.customsClerk.getpdfFile;
const createClerckControlPdfSelector = (state) => state.customsClerk.clerkControlPdfFile;
const pochetteSelector = (state) => 
  state.customsClerk.pochette;


export default {
  errorSelector,
  errorsSelector,
  loadingSelector,
  successSelector,
  summarySelector,
  invoiceSelector,
  allFilesSelector,
  createFileSuccessSelector,
  createFileLoadingSelector,
  filesPicturesSelector,
  picturesIssuesZipSelector,
  allFilesCountSelector,
  allFilesLoadingSelector,
  allFilesByFiltersSelector,
  allFilesByFiltersCountSelector,
  allFilesByFiltersLoadingSelector,
  declarationInProgressSelector,
  declarationInProgressLoadingSelector,
  oneFileSelector,
  oneFileLoadingSelector,
  fileDocumentsSelector,
  issueSelector,
  updateIssueLoadingSelector,
  addIssuePicturesLoadingSelector,
  deleteIssuePictureLoadingSelector,
  billingsSelector,
  fileHasBillingsSelector,
  addInvoicingLineLoadingSelector,
  updateInvoicingLineLoadingSelector,
  removeInvoicingLineLoadingSelector,
  createdFileIdSelector,
  issueTimesSelector,
  startSignatureSelector,
  trailerLicensePlateSelector,
  paymentReceiptSelector,
  endSignatureSelector,
  pdfFilesSelector,
  createClerckControlPdfSelector,
  cdBillingsSelector,
  billingsLoadingSelector,
  billingsSuccessSelector,
  pochetteSelector,
  pochetteLoadingSelector,
};
