import { orderBy } from 'lodash';
import { Field, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
  Divider,
  Form,
  Grid,
  Header,
  Modal
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { Input, InputLabeled } from 'components/Form';

import { updateTransit } from 'interfaces/formSchema/customsValidators';
import Wrapper from 'components/core/Wrapper/Wrapper';
import AddContact from 'components/AddContact/addContact';


const ModalEditTransit = ({
  open,
  actualData,
  handleEditTransit,
  handleClose,
  dispatch,
  customsOffice,
  companyId,
  intl,
  transitRates,
  transitRatesLoading,
  allCountries,
  allCountriesLoading,
  customsOfficeLoading,
  errorHandlerData,
  contactsTransporters,
  addcontactsSuccess,
  contactsTransportersLoading,
}: ModalEditTransitProps) => {

  const [allCountriesList, setAllCountriesList]: any = useState([]);
  const [customsOfficeList, setCustomsOfficeList]: any = useState([]);
  const [transporterList, setTransporterList]: any = useState([]);
  const [addContactModal, setaddContactModal] = useState(false);
  const [taxRate, setTaxRates] = useState(null)

  const formikRef = useRef();

  useEffect(() => {
    dispatch(actions.data.getAllCountries());
    dispatch(actions.customsDeclaration.getAllCustomsOffice());
    dispatch(actions.customsDeclaration.getCategories({
      params: { companyId: companyId},
      query: {
        type: true
      }
    }));
    dispatch(actions.customsDeclaration.getTransitRates({
      params: { companyId: companyId, type: 'all'},
    }));
    dispatch(actions.contact.getAllContactTransporters({
      params: {
        companyId:  companyId 
      }
    }));

    dispatch(actions.socket.subscribeAddContactTransporter(companyId))

    return () => {
      dispatch(actions.data.reset(['allCountries', 'error']));
      dispatch(actions.customsDeclaration.reset(['customsOffices', 'transitRates', 'error']));
      dispatch(actions.contact.reset(['contactsTransporters', 'error']));
      dispatch(actions.socket.unsubscribeAddContactTransporter(companyId))
  
    };
  }, [dispatch, companyId]);


  useEffect(() => {
    if (customsOffice) {
      setCustomsOfficeList(
        customsOffice.map((office, index) => {
          return {
            key: index,
            value: office.id,
            text: office.name,
          };
        })
      );
    }
  }, [customsOffice]);

  useEffect(() => {
    if(transitRates){
      let dutyRates = [] 
      let taxsRate = []
      transitRates.forEach((e, index) => {
        if(e.type === 1){
          dutyRates.push({key: index, value: e.rate, text: e.name})
        }else if(e.type === 2){
          taxsRate.push({key: index, value: e.rate, text: e.name})
        }
      })
      setTaxRates(taxsRate)
    }
  }, [transitRates, actualData])

  useEffect(() => {
    if (allCountries) {
      setAllCountriesList(
        orderBy(
          allCountries.map((country, index) => {
            return {
              key: index,
              value: country.iso_code,
              text: intl.formatMessage({
                id: `countries.name.${country.iso_code}`,
              }),
              flag: country.iso_code.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [allCountries, intl]);

  useEffect(() => {
    if(contactsTransporters){
      setTransporterList(
        contactsTransporters.map((el, index) => {    
            return {
              key: index,
              value: {id: el.id, name: el.name, address: el.Addresses[0], country: el.country},
              text: `${el.name} - (${el.country} - ${el.Addresses[0].city})`,
            };
        })
      )
    }
  }, [contactsTransporters])

  useEffect(() => {
    if (companyId) {
      dispatch(
        actions.company.getOne({
          params: { companyId: companyId },
        })
      );
    }
    const ref: any = formikRef.current;
    if(actualData.transit.transitVatRate === 0.025){
      ref.setFieldValue('vatRate', actualData.transit.transitVatRate);
    }else{
      ref.setFieldValue('vatRate', 1);
    }
  }, [dispatch, actualData, companyId]);



  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='edit' content='Edit Transit declaration' />
      <Formik
        ref={formikRef}
        initialValues={{
          preTaxInvoice: actualData.transit.preTaxInvoice || null,
          customsDutyRate: actualData.transit.customsDutyRate ? actualData.transit.customsDutyRate*100 :null,
          transitVatRate: actualData.transit.transitVatRate*100 || null,
          accisesTaxAmount: actualData.transit.accisesTaxAmount || null,
          departure: actualData.departure || '',
          arrival: actualData.arrival || '',
          startDate: actualData.transit.startDate.split('T')[0] || '',
          endDate: actualData.transit.endDate.split('T')[0] || '',
          estimatedTimeDeparture: actualData.transit.estimatedTimeDeparture || '',
          customsOffice: actualData.customsOffice || null,
          purchaseNumber: actualData.purchaseNumber || '',
          transporter: actualData.transporter || '',
          transporterPhoneNumber: actualData.transporterPhoneNumber || '',
          clientReference: actualData.clientReference || '',
          vatRate: null,
        }}
        enableReinitialize={true}
        onSubmit={handleEditTransit}
        validationSchema={updateTransit}
      >
      {({
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
        isSubmitting,
      }) => (
        <Modal.Content>
          <Form onSubmit={handleSubmit}>
              {addContactModal && (
                <AddContact
                  open={addContactModal}
                  handleClose={() => {
                    if(addcontactsSuccess){
                      setaddContactModal(false)
                    }               
                  }}
                  companyId={companyId}
                  fromOtherComponents={true}
                />
              )}
              <Wrapper display='flex' margin='0 0 1rem 0'>
              <Button
                icon='plus'
                type='button'
                content='Add contact'
                onClick={() => {
                  setaddContactModal(true);
                }}
              />         
              </Wrapper>
              <Grid columns='2'>
                <Grid.Column>
                <Field
                  mandatory
                  label="Transporter"
                  name='transporter'
                  placeholder='Choose transporter'
                  options={transporterList}
                  component={Dropdown}
                  loading={contactsTransportersLoading}
                  disabled={transporterList.length===0}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                  uniqueError={
                    errorHandlerData.includes('contactsTransporters')
                      ? true
                      : false
                  }
                  onChange={(value) => {
                    if(
                      value.address &&
                      value.address.length && 
                      value.address.phoneNumber
                      ){
                      setFieldValue('transporterPhoneNumber', value.address.phoneNumber)
                    }
                  }}
                />
                </Grid.Column>        
                <Grid.Column>
                  <Field
                    mandatory
                    label='Transporter Phone number'
                    name='transporterPhoneNumber'
                    placeholder='XXXXXXXXXX'
                    component={Input}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
              </Grid>
              <Grid columns='2'>
                <Grid.Column>
                  <Field
                    mandatory
                    label="Departure"
                    name='departure'
                    placeholder='Choose the country of origin'
                    options={allCountriesList}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    loading={allCountriesLoading}
                    uniqueError={
                      errorHandlerData.includes('allCountries')
                        ? true
                        : false
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    mandatory
                    label="Arrival"
                    name='arrival'
                    placeholder='Choose the country of arrival'
                    options={allCountriesList}
                    component={Dropdown}
                    loading={allCountriesLoading}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    uniqueError={
                      errorHandlerData.includes('allCountries')
                        ? true
                        : false
                    }
                  />
                </Grid.Column>                    
              </Grid>
              <Grid columns='3'>
                <Grid.Column>
                <Field
                  mandatory
                  label='Start date'
                  name='startDate'
                  component={Input}
                  type='date'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
                </Grid.Column>
                <Grid.Column>
                <Field
                  mandatory
                  label='Departure time'
                  name='estimatedTimeDeparture'
                  component={Input}
                  type='time'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
                </Grid.Column>
                <Grid.Column>
                <Field
                  mandatory
                  label='End date'
                  name='endDate'
                  component={Input}
                  type='date'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
                </Grid.Column>                    
              </Grid>
              <Grid columns='4'>
              <Grid.Column>
                <Field
                  mandatory
                  label="Pre-tax invoice"
                  name='preTaxInvoice'
                  placeholder='1000.00'
                  component={Input}
                  type='number'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}                   
                />
              </Grid.Column>
              <Grid.Column>
              <Field
                mandatory
                label="Customs duty rate"
                name='customsDutyRate'
                placeholder='type rate...'
                component={InputLabeled}
                labeled='%'
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }} 
              />
              </Grid.Column>
              <Grid.Column>
                <Field
                  label="Select tax rate"
                  name='vatRate'
                  placeholder='Choose rate...'
                  options={taxRate}
                  loading={transitRatesLoading}
                  component={Dropdown}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }} 
                  onChange={(e) => {
                    if(e === 0.025){
                      setFieldValue('transitVatRate', e*100)
                    }else{
                      setFieldValue('transitVatRate', '')
                    }
                    
                  }}
                />
                </Grid.Column>
                <Grid.Column>
                <Field
                  mandatory
                  label="Transit tax rate"
                  name='transitVatRate'
                  placeholder={values.transitVatRate}                
                  component={InputLabeled}
                  labeled='%'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }} 
                />
                </Grid.Column>
              </Grid>
              <Grid columns='2'>
              <Grid.Column>
                <Field
                  label='Accises amount'
                  name='accisesTaxAmount'
                  placeholder='1000...'
                  component={Input}
                  type='number'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  label='Customs office of arrival'
                  name='customsOffice'
                  placeholder='Customs office'
                  component={Dropdown}
                  options={customsOfficeList}
                  loading={customsOfficeLoading}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
            </Grid>                          
            <Divider hidden />
            <Grid columns='2'>
              <Grid.Column>
                <Field
                  label='Client reference'
                  name='clientReference'
                  placeholder='123456789'
                  component={Input}
                  type='text'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  label='PO'
                  name='purchaseNumber'
                  placeholder='po...'
                  component={Input}
                  type='text'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
            </Grid>
            <Divider hidden />
            <Button
              disabled={isSubmitting}
              type='submit'
              content='Submit'
              color='green'
            />
          </Form>
        </Modal.Content>
      )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    allCountries: selectors.data.allCountriesSelector(state),
    allCountriesLoading: selectors.data.allCountryLoadingSelector(state),
    transitRates: selectors.customsDeclaration.getTransitRatesSelector(state),
    transitRatesLoading: selectors.customsDeclaration.getTransitRatesLoadingSelector(state),
    customsOfficeLoading: selectors.customsDeclaration.getAllCustomsOfficeLoadingSelector(state),
    customsOffice: selectors.customsDeclaration.getAllCustomsOfficeSelector(state),
    company: selectors.company.companySelector(state),
    contactsTransporters: selectors.contact.contactsTransportersSelector(state),
    addcontactsSuccess : selectors.contact.addContactSuccessSelector(state),
    contactsTransportersLoading: selectors.contact.getAllContactTransportersLoadingSelector(state),
    contactError: selectors.contact.errorSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

type transitData = {
  preTaxInvoice: number,
  customsDutyRate: number,
  transitVatRate: number,
  accisesTaxAmount: number, 
  departure:string,
  arrival: string,
  startDate: string,
  endDate: string,
  estimatedTimeDeparture: string,
  customsOffice: number,
  purchaseNumber: string,
  transporter: any,
  transporterPhoneNumber: string,
  clientReference: string,
  vatRate: string,
};

type actualData = {
  transit: {
    preTaxInvoice: number;
    startDate: string,
    endDate: string,
    estimatedTimeDeparture: string,
    customsDutyRate: number
    transitVatRate: number,
    customsDuty: number,
    accisesTaxAmount: number,
  },
  clientReference: string | null,
  departure: string,
  arrival: string,
  transporter: any;
  customsOffice: number;
  purchaseNumber: string;
  transporterPhoneNumber: string;
};

interface ModalEditTransitProps {
  open: boolean;
  actualData: actualData;
  handleEditTransit: (values: transitData) => void;
  handleClose: () => void;
  dispatch: any;
  customsOffice;
  companyId: number;
  intl;
  allCountries;
  transitRates;
  transitRatesLoading;
  allCountriesLoading;
  customsOfficeLoading;
  errorHandlerData;
  contactError;
  company;
  contacts;
  contactsLoading;
  addcontactsSuccess;
  contactsTransporters;
  contactsTransportersLoading;
}

export default enhance(ModalEditTransit);
