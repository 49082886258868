import { saveAs } from 'file-saver';

import actionTypes from 'store/customs/declaration/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.CHECK_CONEX:
        try {
          next({ 
            type: actionTypes.CHECK_CONEX_LOADING });
            const res = await request.checkConexDelivery(action.payload);
          next({
            type: actionTypes.CHECK_CONEX_SUCCESS,
            payload: res.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.CHECK_CONEX_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.DOWNLOAD_CHEDS:
        try {
          next({ 
            type: actionTypes.DOWNLOAD_CHEDS_LOADING });
            const res = await request.downloadCheds(action.payload);
            saveAs(
              res.data,
              res.headers['content-disposition']
                .split('filename=')[1]
                .replace(/"/g, '')
            );
          next({
            type: actionTypes.DOWNLOAD_CHEDS_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_CHEDS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.TRANSIT_RISK:
        try {
          next({ 
            type: actionTypes.TRANSIT_RISK_LOADING });
            const resp = await request.getTransitRisk(action.payload);
          next({
            type: actionTypes.TRANSIT_RISK_SUCCESS,
            payload: resp.data.data 
          });
        } catch (error) {
          next({
            type: actionTypes.TRANSIT_RISK_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_RATES:
        try {
          next({ 
            type: actionTypes.GET_RATES_LOADING });
            const resp = await request.getTransitRates(action.payload);
          next({
            type: actionTypes.GET_RATES_SUCCESS,
            payload: resp.data.data 
          });
        } catch (error) {
          next({
            type: actionTypes.GET_RATES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.UPDATE_ACCISES:
        try {
          next({ 
            type: actionTypes.UPDATE_ACCISES_LOADING });
            const resp = await request.updateAccises(action.payload);
          next({
            type: actionTypes.UPDATE_ACCISES_SUCCESS,
            payload: resp.data.data 
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ACCISES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.UPDATE_TRANSIT:
        try {
          next({ 
            type: actionTypes.UPDATE_TRANSIT_LOADING });
            await request.updateTransit(action.payload);
          next({
            type: actionTypes.UPDATE_TRANSIT_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_TRANSIT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.CREATE_TRANSIT:
        try {
          next({ 
            type: actionTypes.CREATE_TRANSIT_LOADING });
            const resp = await request.createTransit(action.payload);
          next({
            type: actionTypes.CREATE_TRANSIT_SUCCESS,
            payload: resp.data.data
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_TRANSIT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_ALL_ACCISES:
        try {
          next({ 
            type: actionTypes.GET_ALL_ACCISES_LOADING });
            const resp = await request.getAllAccises(action.payload);
          next({
            type: actionTypes.GET_ALL_ACCISES_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_ACCISES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ACCISES:
        try {
          next({ type: actionTypes.GET_ACCISES_LOADING });
                const resp = await request.getAccises(action.payload);
          next({
            type: actionTypes.GET_ACCISES_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ACCISES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;


      case actionTypes.CREATE_ACCISES:
        try {
          next({ type: actionTypes.CREATE_ACCISES_LOADING });
                await request.createAccises(action.payload);
          next({
            type: actionTypes.CREATE_ACCISES_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_ACCISES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.SEND_QUEUE:
        try {
          next({ type: actionTypes.SEND_QUEUE_LOADING });
                await request.sendNotificationQueue(action.payload);
          next({
            type: actionTypes.SEND_QUEUE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SEND_QUEUE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DELETE_CONSIGNMENT_DATA:
        try {
          next({ type: actionTypes.DELETE_CONSIGNMENT_DATA_LOADING });
                await request.deleteConsigmentData(action.payload);
          next({
            type: actionTypes.DELETE_CONSIGNMENT_DATA_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_CONSIGNMENT_DATA_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_CONSIGNMENT_DATA:
        try {
          next({ type: actionTypes.UPDATE_CONSIGNMENT_DATA_LOADING });
          const resp = await request.updateConsigmentData(action.payload);
          next({
            type: actionTypes.UPDATE_CONSIGNMENT_DATA_SUCCESS,
            payload: resp.data.data
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_CONSIGNMENT_DATA_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.ADD_CONSIGNMENT_DATA:
        try {
          next({ type: actionTypes.ADD_CONSIGNMENT_DATA_LOADING });
          const resp = await request.addConsigmentData(action.payload);
          next({
            type: actionTypes.ADD_CONSIGNMENT_DATA_SUCCESS,
            payload: resp.data.data
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.ADD_CONSIGNMENT_DATA_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_CATEGORIES:
        try {
          next({ type: actionTypes.GET_CATEGORIES_LOADING });
          const resp = await request.getCategories(action.payload);
          next({
            type: actionTypes.GET_CATEGORIES_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_CATEGORIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_ALL_LINES:
          try {
            next({ type: actionTypes.GET_ALL_LINES_LOADING });
            const response = await request.getAllLines(action.payload);
            next({
              type: actionTypes.GET_ALL_LINES_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_ALL_LINES_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;

    
        case actionTypes.CREATE_LINES:
          try {
            next({ type: actionTypes.CREATE_LINES_LOADING });
            await request.addLines(action.payload);
            next({
              type: actionTypes.CREATE_LINES_SUCCESS,
            });
          } catch (error) {
            next({
              type: actionTypes.CREATE_LINES_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;

          case actionTypes.UPDATE_LINES:
            try {
              next({ type: actionTypes.UPDATE_LINES_LOADING });
              await request.updateLines(action.payload);
              next({
                type: actionTypes.UPDATE_LINES_SUCCESS,
              });
            } catch (error) {
              next({
                type: actionTypes.UPDATE_LINES_ERROR,
                error,
              });
              store.dispatch(
                messageHandler.errorMessage(error.status, error.message)
              );
            }
            break;

            case actionTypes.DELETE_LINES:
              try {
                next({ type: actionTypes.DELETE_LINES_LOADING });
                await request.deleteLines(action.payload);
                next({
                  type: actionTypes.DELETE_LINES_SUCCESS,
                });
              } catch (error) {
                next({
                  type: actionTypes.DELETE_LINES_ERROR,
                  error,
                });
                store.dispatch(
                  messageHandler.errorMessage(error.status, error.message)
                );
              }
              break;

        case actionTypes.GET_CONEX:
          try {
            next({ type: actionTypes.GET_CONEX_LOADING });
            const response = await request.getConex(action.payload);
            next({
              type: actionTypes.GET_CONEX_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_CONEX_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;
        
      case actionTypes.GET_ALL_CHED:
        try {
          next({ type: actionTypes.GET_ALL_CHED_LOADING });
          const response = await request.getAllChed(action.payload);
          next({
            type: actionTypes.GET_ALL_CHED_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_CHED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_PARKING:
          try {
            next({ type: actionTypes.GET_PARKING_LOADING });
            const response = await request.getParking();
            next({
              type: actionTypes.GET_PARKING_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_PARKING_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;

      case actionTypes.RELOAD_LOGISTICOM:
        try {
          next({ type: actionTypes.RELOAD_LOGISTICOM_LOADING });
          await request.reloadLogisticom(action.payload);
          next({
            type: actionTypes.RELOAD_LOGISTICOM_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.RELOAD_LOGISTICOM_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.SEND_NOTICE_MAIL:
        try {
          next({ type: actionTypes.SEND_NOTICE_MAIL_LOADING });
          await request.sendNoticeMail(action.payload);
          next({
            type: actionTypes.SEND_NOTICE_MAIL_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.SEND_NOTICE_MAIL_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.CONVERT_FILE:
        try {
          next({ type: actionTypes.CONVERT_FILE_LOADING });
          const response = await request.convertFile(action.payload);
          saveAs(
            response.data,
            response.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.CONVERT_FILE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.CONVERT_FILE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ONE_CHED:
        try {
          next({ type: actionTypes.GET_ONE_CHED_LOADING });
          const response = await request.getOneChed(action.payload);
          saveAs(
            response.data,
            response.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.GET_ONE_CHED_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_CHED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.ADD_CHED:
        try {
          next({ type: actionTypes.ADD_CHED_LOADING });
          await request.addChed(action.payload);
          next({
            type: actionTypes.ADD_CHED_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.ADD_CHED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.REMOVE_CHED:
        try {
          next({ type: actionTypes.REMOVE_CHED_LOADING });
          await request.removeChed(action.payload);
          next({
            type: actionTypes.REMOVE_CHED_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.REMOVE_CHED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_CHED_DATA:
        try {
          next({ type: actionTypes.UPDATE_CHED_DATA_LOADING });
          await request.updateChedData(action.payload);
          next({
            type: actionTypes.UPDATE_CHED_DATA_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_CHED_DATA_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.READ_CHAT:
        try {
          next({ type: actionTypes.READ_CHAT_LOADING });
          await request.readChat(action.payload);
          next({
            type: actionTypes.READ_CHAT_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.READ_CHAT_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GET_COMPANY_SUBSCRIBED:
        try {
          next({ type: actionTypes.GET_COMPANY_SUBSCRIBED_LOADING });
          const response = await request.getCompanySubscribed();
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DELCARE:
        try {
          next({ type: actionTypes.DELCARE_LOADING });
          const res = await request.declare(action.payload);
          next({
            type: actionTypes.DELCARE_SUCCESS,
            payload: res.data.data
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.DELCARE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.ADD_INTRANET_REFERENCE:
        try {
          next({ type: actionTypes.ADD_INTRANET_REFERENCE_LOADING });
          await request.addIntranetReference(action.payload);
          next({
            type: actionTypes.ADD_INTRANET_REFERENCE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.ADD_INTRANET_REFERENCE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.ADD_MANDATE:
        try {
          next({ type: actionTypes.ADD_MANDATE_LOADING });
          await request.addMandate(action.payload);
          next({
            type: actionTypes.ADD_MANDATE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.ADD_MANDATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_MANDATES:
        try {
          next({ type: actionTypes.GET_MANDATES_LOADING });
          const res = await request.getMandates(action.payload);
          next({
            type: actionTypes.GET_MANDATES_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_MANDATES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_ONE_MANDATE:
          try {
            next({ type: actionTypes.GET_ONE_MANDATE_LOADING });
            const res = await request.downloadMandates(action.payload);
            next({
              type: actionTypes.GET_ONE_MANDATE_SUCCESS,
              payload: res.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_ONE_MANDATE_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;

      case actionTypes.UPDATE_DECLARATION:
        try {
          next({ type: actionTypes.UPDATE_DECLARATION_LOADING });
          const resp = await request.updateDeclaration(action.payload);
          next({
            type: actionTypes.UPDATE_DECLARATION_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_DECLARATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_STEP:
        try {
          next({ type: actionTypes.UPDATE_STEP_LOADING });
          await request.updateStep(action.payload);
          next({
            type: actionTypes.UPDATE_STEP_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_STEP_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.CANCEL_DECLARATION:
        try {
          next({ type: actionTypes.CANCEL_DECLARATION_LOADING });
          await request.cancelDeclaration(action.payload);
          next({
            type: actionTypes.CANCEL_DECLARATION_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.CANCEL_DECLARATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.ADD_STEP:
        try {
          next({ type: actionTypes.ADD_STEP_LOADING });
          await request.addStep(action.payload);
          next({
            type: actionTypes.ADD_STEP_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.ADD_STEP_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ALL_CUSTOMS_OFFICE:
        try {
          next({ type: actionTypes.GET_ALL_CUSTOMS_OFFICE_LOADING });
          const res = await request.getAllCustomsOffice();
          next({
            type: actionTypes.GET_ALL_CUSTOMS_OFFICE_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_CUSTOMS_OFFICE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_PROCESSING_DECLARATIONS:
        try {
          next({ type: actionTypes.GET_PROCESSING_DECLARATIONS_LOADING });
          const res = await request.getProcessingDeclarations(action.payload);
          next({
            type: actionTypes.GET_PROCESSING_DECLARATIONS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_PROCESSING_DECLARATIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_PROCESSING_DECLARATIONS_BY_FILTERS:
        try {
          next({
            type: actionTypes.GET_PROCESSING_DECLARATIONS_BY_FILTERS_LOADING,
          });
          const res = await request.getProcessingDeclarationsByFilters(
            action.payload
          );
          next({
            type: actionTypes.GET_PROCESSING_DECLARATIONS_BY_FILTERS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_PROCESSING_DECLARATIONS_BY_FILTERS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_DECLARATIONS_INVOICE:
        try {
          next({ type: actionTypes.GET_DECLARATIONS_INVOICE_LOADING });
          const res = await request.getDeclarationsInvoice(action.payload);
          next({
            type: actionTypes.GET_DECLARATIONS_INVOICE_SUCCESS,
            payload: res.data.data,
          });
           // store.dispatch(messageHandler.successMessage('Please wait few seconds to access the intranet generated link'));
        } catch (error) {
          next({
            type: actionTypes.GET_DECLARATIONS_INVOICE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_DECLARATIONS_INVOICE_BY_FILTERS:
        try {
          next({
            type: actionTypes.GET_DECLARATIONS_INVOICE_BY_FILTERS_LOADING,
          });
          const res = await request.getDeclarationsInvoiceByFilters(
            action.payload
          );
          next({
            type: actionTypes.GET_DECLARATIONS_INVOICE_BY_FILTERS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_DECLARATIONS_INVOICE_BY_FILTERS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_DECLARATIONS_INVOICE:
        try {
          next({ type: actionTypes.UPDATE_DECLARATIONS_INVOICE_LOADING });
          await request.updateDeclarationsInvoice(action.payload);
          next({
            type: actionTypes.UPDATE_DECLARATIONS_INVOICE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_DECLARATIONS_INVOICE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ALL:
        try {
          next({ type: actionTypes.GET_ALL_LOADING });
          const res = await request.getAll(action.payload);
          next({
            type: actionTypes.GET_ALL_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
        
        // hosted customs declaration
        case actionTypes.GET_ALL_HOSTED:
          try {
            next({ type: actionTypes.GET_ALL_HOSTED_LOADING });
            const res = await request.getAllHosted(action.payload);
            next({
              type: actionTypes.GET_ALL_HOSTED_SUCCESS,
              payload: res.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_ALL_HOSTED_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
        break;

         // one hosted customs declaration width users
         case actionTypes.GET_ONE_HOSTED:
          try {
            next({ type: actionTypes.GET_ONE_HOSTED_LOADING });
            const res = await request.getOneHosted(action.payload);
            next({
              type: actionTypes.GET_ONE_HOSTED_SUCCESS,
              payload: res.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_ONE_HOSTED_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
        break;

         // one by ref
         case actionTypes.GET_ONE_BY_REF:
          try {
            next({ type: actionTypes.GET_ONE_BY_REF_LOADING });
            const res = await request.getOneByReference(action.payload);
            next({
              type: actionTypes.GET_ONE_BY_REF_SUCCESS,
              payload: res.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_ONE_BY_REF_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
        break;

         // one hosted customs declaration width users
        case actionTypes.ADD_PART_COMMENTS:
          try {
            next({ type: actionTypes.ADD_PART_COMMENTS_LOADING });
            const res = await request.addPartnerComment(action.payload);
            next({
              type: actionTypes.ADD_PART_COMMENTS_SUCCESS,
              payload: res.data.data,
            });           
            store.dispatch(messageHandler.successMessage(''));
          } catch (error) {
            next({
              type: actionTypes.ADD_PART_COMMENTS_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
        break;

         // one hosted customs declaration width users
         case actionTypes.GET_PART_COMMENTS:
          try {
            next({ type: actionTypes.GET_PART_COMMENTS_LOADING });
            const res = await request.getPartnerComments(action.payload);
            next({
              type: actionTypes.GET_PART_COMMENTS_SUCCESS,
              payload: res.data.data,
            });           
          } catch (error) {
            next({
              type: actionTypes.GET_PART_COMMENTS_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
        break;

        case actionTypes.GET_ONE:
          try {
            next({ type: actionTypes.GET_ONE_LOADING });
            const res = await request.getOne(action.payload);
            next({
              type: actionTypes.GET_ONE_SUCCESS,
              payload: res.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_ONE_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
        break;

        // case actionTypes.GET_COMPUTED:
        //   try {
        //     next({ type: actionTypes.GET_COMPUTED_LOADING });
        //     const res = await request.getComputedDeclarations(action.payload);
        //     next({
        //       type: actionTypes.GET_COMPUTED_SUCCESS,
        //       payload: res.data.data,
        //     });
        //   } catch (error) {
        //     next({
        //       type: actionTypes.GET_COMPUTED_ERROR,
        //       error,
        //     });
        //     store.dispatch(
        //       messageHandler.getErrorMessage(error.status, error.message)
        //     );
        //   }
        //   break;

      case actionTypes.ADD_DOCUMENTS:
        try {
          next({ type: actionTypes.ADD_DOCUMENTS_LOADING });
          await request.addDocuments(action.payload);
          next({
            type: actionTypes.ADD_DOCUMENTS_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.ADD_DOCUMENTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DELETE_DOCUMENT:
        try {
          next({ type: actionTypes.DELETE_DOCUMENT_LOADING });
          await request.deleteDocument(action.payload);
          next({
            type: actionTypes.DELETE_DOCUMENT_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.DELETE_DOCUMENT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ALL_CATEGORIES_DOCUMENTS:
        try {
          next({ type: actionTypes.GET_ALL_CATEGORIES_DOCUMENTS_LOADING });
          const res = await request.getAllCategoriesDocuments(action.payload);
          next({
            type: actionTypes.GET_ALL_CATEGORIES_DOCUMENTS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_CATEGORIES_DOCUMENTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DOWNLOAD_DOCUMENT:
        try {
          next({ type: actionTypes.DOWNLOAD_DOCUMENT_LOADING });
          const response = await request.downloadDocument(action.payload);
          saveAs(
            response.data,
            response.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.DOWNLOAD_DOCUMENT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_DOCUMENT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.DOWNLOAD_DOCUMENTS:
        try {
          next({ type: actionTypes.DOWNLOAD_DOCUMENTS_LOADING });
          const response = await request.downloadDocuments(action.payload);
          saveAs(
            response.data,
            response.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.DOWNLOAD_DOCUMENTS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_DOCUMENTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.EXTRACT_DOCUMENTS:
        try {
          next({ type: actionTypes.EXTRACT_DOCUMENTS_LOADING });
          const response = await request.extractDocuments(action.payload);
          saveAs(
            response.data,
            response.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.EXTRACT_DOCUMENTS_SUCCESS,
            payload: response.data.data,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.EXTRACT_DOCUMENTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
        case actionTypes.EXTRACT_TRACES:
          try {
            next({ type: actionTypes.EXTRACT_TRACES_LOADING });
            const response = await request.getAllTraces(action.payload);
            saveAs(
              response.data,
              response.headers['content-disposition']
                .split('filename=')[1]
                .replace(/"/g, '')
            );
            next({
              type: actionTypes.EXTRACT_TRACES_SUCCESS,
              payload: response.data.data,
            });
            store.dispatch(messageHandler.successMessage(''));
          } catch (error) {
            next({
              type: actionTypes.EXTRACT_TRACES_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;
        case actionTypes.GENERATE_BAE_EXTRACT:
          try {
            next({ type: actionTypes.GENERATE_BAE_EXTRACT_LOADING });
            const response = await request.generateBaeExtract(action.payload);
            saveAs(
              response.data,
              response.headers['content-disposition']
                .split('filename=')[1]
                .replace(/"/g, '')
            );
            next({
              type: actionTypes.GENERATE_BAE_EXTRACT_SUCCESS
            });
            store.dispatch(messageHandler.successMessage(''));
          } catch (error) {
            next({
              type: actionTypes.GENERATE_BAE_EXTRACT_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;
      case actionTypes.GET_CHAT:
        try {
          next({ type: actionTypes.GET_CHAT_LOADING });
          const res = await request.getChat(action.payload);
          next({
            type: actionTypes.GET_CHAT_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_CHAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.FOLLOW_DECLARATION:
        try {
          next({ type: actionTypes.FOLLOW_DECLARATION_LOADING });
          await request.followDeclaration(action.payload);
          next({
            type: actionTypes.FOLLOW_DECLARATION_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.FOLLOW_DECLARATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UNFOLLOW_DECLARATION:
        try {
          next({ type: actionTypes.UNFOLLOW_DECLARATION_LOADING });
          await request.unfollowDeclaration(action.payload);
          next({
            type: actionTypes.UNFOLLOW_DECLARATION_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UNFOLLOW_DECLARATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.SEND_MESSAGE:
        try {
          next({ type: actionTypes.SEND_MESSAGE_LOADING });
          await request.sendMessage(action.payload);
          next({
            type: actionTypes.SEND_MESSAGE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.SEND_MESSAGE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.SUBSCRIBE:
        try {
          next({ type: actionTypes.SUBSCRIBE_LOADING });
          await request.subscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.SUBSCRIBE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UNSUBSCRIBE:
        try {
          next({ type: actionTypes.UNSUBSCRIBE_LOADING });
          await request.unsubscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.UNSUBSCRIBE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UNSUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
