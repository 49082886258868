import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getUsersErrorSelector = (state) => state.user.errors.getUsers;
const getUserErrorSelector = (state) => state.user.errors.getUser;
const validateUserErrorSelector = (state) => state.user.errors.validateUser;
const deleteUserErrorSelector = (state) => state.user.errors.deleteUser;
const createUserErrorSelector = (state) => state.user.errors.createUser;
const updateErrorSelector = (state) => state.user.errors.update;
const resetPasswordErrorSelector = (state) => state.user.errors.resetPassword;
const updatePasswordErrorSelector = (state) => state.user.errors.updatePassword;
const updatePasswordByTokenErrorSelector = (state) => state.user.errors.updatePasswordWithToken;
const workmailRenewalErrorSelector = (state) => state.user.errors.workmailRenewal;

const errorsSelector = createErrorsSelector([
  getUsersErrorSelector,
  getUserErrorSelector,
  validateUserErrorSelector,
  deleteUserErrorSelector,
  createUserErrorSelector,
  updateErrorSelector,
  resetPasswordErrorSelector,
  updatePasswordErrorSelector,
  updatePasswordByTokenErrorSelector,
  workmailRenewalErrorSelector,
]);

// Loading
const getUsersLoadingSelector = (state) => state.user.loading.getUsers;
const getUserLoadingSelector = (state) => state.user.loading.getUser;
const validateUserLoadingSelector = (state) => state.user.loading.validateUser;
const deleteUserLoadingSelector = (state) => state.user.loading.deleteUser;
const createUserLoadingSelector = (state) => state.user.loading.createUser;
const updateLoadingSelector = (state) => state.user.loading.update;
const resetPasswordLoadingSelector = (state) => state.user.loading.resetPassword;
const updatePasswordLoadingSelector = (state) => state.user.loading.updatePassword;
const updatePasswordByTokenLoadingSelector = (state) => state.user.loading.updatePasswordWithToken;
const workmailRenewalLoadingSelector = (state) => state.user.loading.workmailRenewal;

const loadingSelector = createLoadingSelector([
  getUsersLoadingSelector,
  getUserLoadingSelector,
  validateUserLoadingSelector,
  deleteUserLoadingSelector,
  createUserLoadingSelector,
  updateLoadingSelector,
  resetPasswordLoadingSelector,
  updatePasswordLoadingSelector,
  updatePasswordByTokenLoadingSelector,
  workmailRenewalLoadingSelector,
]);

// Success
const getUsersSuccessSelector = (state) => state.user.success.getUsers;
const getUserSuccessSelector = (state) => state.user.success.getUser;
const validateUserSuccessSelector = (state) => state.user.success.validateUser;
const deleteUserSuccessSelector = (state) => state.user.success.deleteUser;
const createUserSuccessSelector = (state) => state.user.success.createUser;
const updateSuccessSelector = (state) => state.user.success.update;
const resetPasswordSuccessSelector = (state) => state.user.success.resetPassword;
const updatePasswordSuccessSelector = (state) => state.user.success.updatePassword;
const updatePasswordByTokenSuccessSelector = (state) => state.user.success.updatePasswordWithToken;
const workmailRenewalSuccessSelector = (state) => state.user.success.workmailRenewal;


const successSelector = createSuccessSelector([
  getUsersSuccessSelector,
  getUserSuccessSelector,
  validateUserSuccessSelector,
  deleteUserSuccessSelector,
  createUserSuccessSelector,
  updateSuccessSelector,
  resetPasswordSuccessSelector,
  updatePasswordSuccessSelector,
  updatePasswordByTokenSuccessSelector,
  workmailRenewalSuccessSelector,
]);

// users
const usersSelector = (state) => state.user.users;

// user
const userSelector = (state) => state.user.user;

// Errors Handlers
const errorSelector = (state) => state.user.error;

// Workmail
const workmailRenewalSelector = (state) => state.user.workmailRenewal;



export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  usersSelector,
  errorSelector,
  userSelector,
  validateUserSuccessSelector,
  updatePasswordByTokenSuccessSelector,
  updatePasswordByTokenLoadingSelector,
  workmailRenewalSelector,
};
