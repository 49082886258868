import React, { useState, useCallback } from "react";
import _ from "lodash";
import { InputCustom as Input } from "./styles";

const Index = (props) => {
  const { dataSource, onSearchResultChange } = props
  const [searchvalue, setsearchValue] = useState("");

  const handleChange = useCallback((event) => {
    if(event.target.value<1){
      setsearchValue("");
    }else{
      const re = new RegExp(_.escapeRegExp(event.target.value), "i");
      const isMatch = (result) =>
        re.test(result.name) ||
        re.test(result.email) ||
        re.test(result.country)

      onSearchResultChange(_.filter(dataSource, isMatch));
    }
    setsearchValue(event.target.value);
  }, [dataSource, onSearchResultChange]);

  return (
      <Input
        type="text"
        placeholder="Search..."
        icon="search"
        value={searchvalue}
        onChange={handleChange}
      />
  );
};

export default Index;
