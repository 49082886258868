import React, { FunctionComponent, useEffect, useState} from 'react';
import { Formiz, useForm} from '@formiz/core';
import { Header, Modal, Grid } from 'semantic-ui-react';

import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';
import FormizCheckbox from 'components/FormizField/FormizCheckbox/FormizCheckbox';
import FormizFileInput from 'components/FormizField/FormizFileInput/FormizFileInput';
import FormizDropdownInput from 'components/FormizField/FormizDropdownInput/FormizDropdownInput';
import Button from 'components/Button';
import Spacer from 'components/core/Spacer/Spacer';
import ErrorHandler from 'components/ErrorHandler';
import { isNumber } from '@formiz/validations';
import { chedI } from 'interfaces/data/customsClerkInterfaces';
import FormizSimpleOutput from 'components/FormizField/FormizSimpleOutput/FormizSimpleOutput';

type ModalAddChedProps = {
  open: boolean;
  chedData?: chedI;
  handleAddChed: (values: {
    number: string;
    type: any;
    state: 'draft' | 'new' | 'validated' | 'refused' | 'replaced';
    phyto: boolean;
    veto: boolean;
    productNames: string;
    productType: string;
    weight: number;
    taxAmount: number;
    countLines: number;
    replacingNumber: number;
    file: File;
  }) => void;
  handleClose: () => void;
};

const ModalAddChed: FunctionComponent<ModalAddChedProps> = ({
  open,
  chedData,
  handleAddChed,
  handleClose,
}) => {
  const addChedForm = useForm();
  const [calculatedPrice, setcalculatedPrice]: any = useState(0)
  const [price, setPrice]: any = useState(0)
  const [isOpen, setOpen]: any = useState(true)
  
  
  const priceCalculation = (value) => {
    // value en tones
    setOpen(true)
    let base, final;
    if(value <= 5000){
      base = 30.49;
    }else if(value > 5000){
      base = (value * 0.00610);
    }
    final = base.toFixed(2)
    setcalculatedPrice(final)
    if(calculatedPrice){
      setPrice(calculatedPrice)
    }
  }


  useEffect(() => {
    if(calculatedPrice){
      setPrice(calculatedPrice)
    }else{
      if(chedData && chedData.weight){
        let base, final;
        if(chedData.weight <= 5000){
          base = 30.49;
        }else if(chedData.weight > 5000){
          base = (chedData.weight * 0.00610);
        }
        final = base.toFixed(2)
        setcalculatedPrice(final)
  
        setPrice(calculatedPrice)
      
      }
    }
  }, [calculatedPrice, isOpen, price, chedData])

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header
        icon={`${chedData ? 'edit' : 'plus circle'}`}
        content={`${chedData ? 'Update' : 'Add'} ched`}
      />
      <Modal.Content>
        <Formiz
          connect={addChedForm}
          onValidSubmit={handleAddChed}
          initialValues={{
            number: chedData?.number,
            productNames: chedData?.productNames || '',
            productType: chedData?.productType || '',
            weight: chedData?.weight,
            countLines: chedData?.countLines,
            taxAmount: chedData?.taxAmount || price || '',
            state: chedData?.state || 'draft',
            phyto: chedData?.phyto || false,
            veto: chedData?.veto || false,
            replacingNumber: chedData?.replacingNumber || ''
          }}
        >
          <form onSubmit={addChedForm.submit}>
            <Grid>
              <Grid.Row columns='3'>
                <Grid.Column>
                  <FormizSimpleInput
                    type='text'
                    name='number'
                    label='Ched number'
                    placeholder='Ched number'
                    required='Number is required'
                    formatValue={(val) => (val || '').trim()}
                  />
                </Grid.Column>
                <Grid.Column>
                <FormizSimpleInput
                    type='number'
                    name='weight'
                    label='Weight'
                    placeholder='weight'
                    required='Weight is required'
                    formatValue={(val) => val && parseFloat(val)}
                    validations={[
                      {
                        rule: isNumber(),
                        message: 'This is not a number',
                      },
                    ]}
                    onChange={(e) => priceCalculation(e)}
                  />
                </Grid.Column>
                <Grid.Column verticalAlign='middle'>
                  <FormizDropdownInput
                    name='state'
                    label='State'
                    required
                    options={[
                      {
                        key: 1,
                        value: 'draft',
                        text: 'Draft',
                      },
                      {
                        key: 2,
                        value: 'new',
                        text: 'New',
                      },
                      {
                        key: 3,
                        value: 'validated',
                        text: 'Validated',
                      },
                      {
                        key: 4,
                        value: 'refused',
                        text: 'Refused',
                      },
                      {
                        key: 5,
                        value: 'Replaced',
                        text: 'Replaced',
                      },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='3'>
                <Grid.Column>
                  <FormizSimpleInput
                    name='productNames'
                    label='Products'
                    placeholder='Chicken...'
                    required='Product is required'
                    formatValue={(val) => (val || '').trim()}
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormizSimpleInput
                    name='productType'
                    label='Product type'
                    placeholder='Composite'
                    required='Product type is required'
                    formatValue={(val) => (val || '').trim()}
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormizSimpleOutput
                    getData={price}
                    type='input'
                    label={`Tax amount ${price} € calculated estimation`}
                    name='taxAmount'
                    required='tax amount is required'
                    placeholder={price !== undefined ? price : ''}
                    value={price}
                    formatValue={(val) => val && parseFloat(val)}
                    validations={[
                      {
                        rule: isNumber(),
                        message: 'This is not a number',
                      },
                    ]}
                  />
           
                </Grid.Column>
              </Grid.Row>
              <Grid.Row column='2'>
              <Grid.Column width='4'>
              <FormizSimpleInput
                  name='countLines'
                  type='number'
                  label='Ched counted lines'
                  placeholder='Number of lines'
                  required='Number of lines is required'
                  formatValue={(val) => val && parseFloat(val)}
                  validations={[
                    {
                      rule: isNumber(),
                      message: 'This is not a number',
                    },
                  ]}
                />
                </Grid.Column>

                {/* <Grid.Column width='4'>
                <FormizDropdownInput
                    name='chedType'
                    label='Ched type'
                    required='Ched type is required'
                    options={[
                      {
                        key: 1,
                        value: 'P',
                        text: 'P',
                      },
                      {
                        key: 2,
                        value: 'PP',
                        text: 'PP',
                      },
                      {
                        key: 3,
                        value: 'D',
                        text: 'D',
                      },
                      {
                        key: 4,
                        value: 'A',
                        text: 'A',
                      },
                    ]}
                  />
                </Grid.Column> */}

              </Grid.Row>
              <p>Ched type*</p>
              <Grid.Row columns='2'>
                <Grid.Column>
                  <FormizCheckbox name='veto' label='Veto' />
                </Grid.Column>
                <Grid.Column>
                  <FormizCheckbox
                    name='phyto'
                    label='Phyto'
                    validations={[
                      {
                        rule: (value) => addChedForm.values.veto || value,
                        deps: [addChedForm.values.veto],
                      },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              {addChedForm.isSubmitted &&
                !(addChedForm.values.veto || addChedForm.values.phyto) && (
                  <Grid.Row centered>
                    <ErrorHandler message='"Phyto" or "Veto" type must be selected' />
                  </Grid.Row>
                )}
              {(addChedForm.values.state === 'new' ||
                addChedForm.values.state === 'validated' || 
                addChedForm.values.state === 'refused') && (
                <Grid.Row columns='1'>
                  <Grid.Column>
                    <FormizFileInput
                      name='file'
                      label='CHED file'
                      acceptedFile={['application/pdf']}
                      required={
                        chedData?.s3Path ? false : 'Ched file is required'
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            <Spacer height='20px' />     
              <Button size='large' type='submit' content='Save' />
          </form>
        </Formiz>
      </Modal.Content>
    </Modal>
  );
};

export default ModalAddChed;
