import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getDeliveryByTokenErrorSelector = (state) =>
  state.quickfixesToken.errors.getDeliveryByToken;
const transporterSelectionErrorSelector = (state) =>
  state.quickfixesToken.errors.transporterSelection;
const uploadProofErrorSelector = (state) =>
  state.quickfixesToken.errors.uploadProof;
const getOneProofErrorSelector = (state) =>
  state.quickfixesToken.errors.getOneProof;
const downloadAttestationErrorSelector = (state) =>
  state.quickfixesToken.errors.downloadAttestation;

const errorsSelector = createErrorsSelector([
  getDeliveryByTokenErrorSelector,
  transporterSelectionErrorSelector,
  uploadProofErrorSelector,
  getOneProofErrorSelector,
  downloadAttestationErrorSelector,
]);

// Loading
const getDeliveryByTokenLoadingSelector = (state) =>
  state.quickfixesToken.loading.getDeliveryByToken;
const transporterSelectionLoadingSelector = (state) =>
  state.quickfixesToken.loading.transporterSelection;
const uploadProofLoadingSelector = (state) =>
  state.quickfixesToken.loading.uploadProof;
const getOneProofLoadingSelector = (state) =>
  state.quickfixesToken.loading.getOneProof;
const downloadAttestationLoadingSelector = (state) =>
  state.quickfixesToken.loading.downloadAttestation;

const loadingSelector = createLoadingSelector([
  getDeliveryByTokenLoadingSelector,
  transporterSelectionLoadingSelector,
  uploadProofLoadingSelector,
  getOneProofLoadingSelector,
  downloadAttestationLoadingSelector,
]);

// Success
const getDeliveryByTokenSuccessSelector = (state) =>
  state.quickfixesToken.success.getDeliveryByToken;
const transporterSelectionSuccessSelector = (state) =>
  state.quickfixesToken.success.transporterSelection;
const uploadProofSuccessSelector = (state) =>
  state.quickfixesToken.success.uploadProof;
const getOneProofSuccessSelector = (state) =>
  state.quickfixesToken.success.getOneProof;
const downloadAttestationSuccessSelector = (state) =>
  state.quickfixesToken.success.downloadAttestation;

const successSelector = createSuccessSelector([
  getDeliveryByTokenSuccessSelector,
  transporterSelectionSuccessSelector,
  uploadProofSuccessSelector,
  getOneProofSuccessSelector,
  downloadAttestationSuccessSelector,
]);

const errorSelector = (state) => state.quickfixesToken.error;
const deliveryByTokenSelector = (state) =>
  state.quickfixesToken.deliveryByToken;
const oneProofSelector = (state) => state.quickfixesToken.oneProof;
const downloadAttestationSelector = (state) =>
  state.quickfixesToken.downloadAttestation;

const downloadLoadingSelector = createLoadingSelector([
  (state) => state.quickfixesToken.loading.getOneProof,
  (state) => state.quickfixesToken.loading.downloadAttestation,
  (state) => state.quickfixesToken.loading.downloadCustomsRegimeInvoice,
]);

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  errorSelector,
  deliveryByTokenSelector,
  oneProofSelector,
  downloadAttestationSelector,
  downloadLoadingSelector,
};
