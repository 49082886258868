import Button from 'components/Button';
import { Input } from 'components/Form';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Field, FieldArray, Formik } from 'formik';
import { ssrCompleteSchema } from 'interfaces/formSchema';
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button as Btn,
  Divider,
  Form,
  Header,
  List,
  Transition,
  Table,
} from 'semantic-ui-react';
import TableSpacedRows from 'components/TableSpacedRows';
import { actions, selectors } from 'store';
import ActivityForm from './ActivityForm';
import Results from './Results';
import ButtonValidation from 'components/ButtonValidationModal';
import Loader from 'components/Loader';

const Index = ({
  dispatch,
  countries,
  formSchemas,
  processResult,
  ssrLoading,
  history,
}) => {
  const formikRef = useRef();
  const [countriesList, setcountriesList]: any = useState([]);
  const [formSchemasList, setformSchemasList]: any = useState([]);
  const [newActivityModal, setnewActivityModal] = useState(false);
  const [editActivityModalData, seteditActivityModalData] = useState(null);
  const [processResultData, setprocessResultData] = useState(null);
  const [showEntries, setshowEntries] = useState(false);
  const [clientProspect, setclientProspect] = useState(null);
  const [formOptions, setformOptions] = useState({
    yesOrNo: [
      {
        key: 0,
        text: 'No',
        value: false,
      },
      {
        key: 1,
        text: 'Yes',
        value: true,
      },
    ],
    purchaseOrSale: [
      {
        key: 0,
        value: 'PURCHASE',
        text: 'PURCHASE',
      },
      {
        key: 1,
        value: 'SALE',
        text: 'SALE',
      },
    ],
    person: [
      {
        key: 0,
        value: 'SUPPLIER',
        text: 'SUPPLIER',
      },
      {
        key: 1,
        value: 'PROSPECT',
        text: 'PROSPECT',
      },
      {
        key: 2,
        value: 'BUYER',
        text: 'BUYER',
      },
    ],
    gbCountry: [{ key: 0, value: 'GB', text: 'United Kingdom', flag: 'gb' }],
  });

  useEffect(() => {
    dispatch(actions.data.getAllCountries());
    dispatch(actions.data.getEuCountries());
    dispatch(actions.ssrRequest.getFormSchemas());
    dispatch(actions.ssrRequest.reset(['processResult']));

    return () => {
      dispatch(actions.data.reset(['allCountries', 'euCountries', 'error']));
      dispatch(
        actions.ssrRequest.reset(['formSchemas', 'processResult', 'error'])
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (countries !== null) {
      setcountriesList(
        countries.map((country, index) => {
          return {
            key: index,
            value: country.iso_code,
            text: country.english_name,
            flag: country.iso_code.toLowerCase(),
          };
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    setprocessResultData(null);
    if (processResult) {
      setprocessResultData(processResult.data);
      setshowEntries(false);
    }
  }, [processResult]);

  useEffect(() => {
    if (formSchemas !== null) {
      let schemasArr = [];
      let versionList = [];
      formSchemas.forEach((schema, index) => {
        schemasArr.push(schema.schema);
        versionList.push({
          key: index,
          value: schema.version,
          text: schema.schema.VersionDescription,
        });
      });
      let formOpts = { ...formOptions, versions: versionList };
      setformSchemasList(schemasArr);
      setformOptions(formOpts);
    }
  }, [formSchemas, formOptions]);

  const displayBool = (bool) => {
    if (bool === true) {
      return 'YES';
    } else if (bool === false) {
      return 'NO';
    }
  };

  const addNewActivity = (newActivityValues) => {
    const ref: any = formikRef.current;
    if (ref) {
      let updatedProcessData = ref.state.values.processData;
      updatedProcessData.push(newActivityValues);
      ref.setFieldValue('processData', updatedProcessData);
    }
  };

  const editActivity = (editedActivityValues) => {
    const ref: any = formikRef.current;
    if (ref) {
      let updatedProcessData = ref.state.values.processData;
      updatedProcessData.splice(
        editActivityModalData.index,
        1,
        editedActivityValues
      );
      ref.setFieldValue('processData', updatedProcessData);
    }
    seteditActivityModalData(null);
  };

  const submit = (values) => {
    setclientProspect(values.clientProspect);

    dispatch(actions.ssrRequest.reset(['processResult', 'error']));
    dispatch(actions.ssrRequest.ticketSsr({ data: values }));
  };

  return (
    <Wrapper>
      <>
        <Wrapper {...(!ssrLoading && { display: 'none' })}>
          <Loader />
        </Wrapper>
        <Wrapper
          {...(processResultData && !showEntries && { display: 'none' })}
        >
          <Btn.Group>
            <Button
              floated='left'
              labelPosition='left'
              icon='angle left'
              position='flex-end'
              type='button'
              content='Back'
              margin='5px'
              padding='1rem'
              onClick={() => history.push('/')}
            />
            {showEntries && (
              <Button
                floated='right'
                labelPosition='right'
                icon='angle right'
                position='flex-end'
                type='button'
                content='Results'
                margin='5px'
                onClick={() => setshowEntries(false)}
              />
            )}
          </Btn.Group>
          <Wrapper width='90%' textAlign='center' margin='1rem auto'>
            <Header size='huge'>SSR</Header>
            <Divider hidden />
            {newActivityModal && (
              <ActivityForm
                open={newActivityModal}
                submitAction={addNewActivity}
                handleClose={() => setnewActivityModal(false)}
                formOptions={formOptions}
                versionsSchemas={formSchemasList}
                countriesList={countriesList}
              />
            )}
            {editActivityModalData && (
              <ActivityForm
                open={editActivityModalData !== null}
                data={editActivityModalData.data}
                submitAction={editActivity}
                handleClose={() => seteditActivityModalData(null)}
                formOptions={formOptions}
                versionsSchemas={formSchemasList}
                countriesList={countriesList}
              />
            )}

            <Wrapper>
              <Formik
                ref={formikRef}
                initialValues={{
                  clientProspect: '',
                  processData: [],
                }}
                onSubmit={submit}
                validationSchema={ssrCompleteSchema}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  values,
                  isSubmitting,
                  submitForm,
                }) => (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <Wrapper
                        textAlign='center'
                        width='36rem'
                        margin='1rem auto'
                      >
                        <Form.Field>
                          <Field
                            mandatory
                            label='Client Prospect'
                            placeholder='Client Prospect'
                            name='clientProspect'
                            component={Input}
                            meta={{ errors, touched }}
                            margin='20px'
                          />
                        </Form.Field>
                      </Wrapper>

                      <Button
                        icon='plus'
                        type='button'
                        content='Add Activity'
                        labelPosition='left'
                        onClick={() => {
                          setnewActivityModal(true);
                        }}
                        margin='0px'
                        position='center'
                      />
                      <FieldArray
                        name='processData'
                        render={({ remove, push }) => (
                          <Transition.Group
                            as={List}
                            duration={200}
                            size='medium'
                            verticalAlign='middle'
                          >
                            <Wrapper overflow='auto' maxHeight='40rem'>
                              <TableSpacedRows
                                thPosition='sticky'
                                thTop='0'
                                thBackgroundColor='mainBackgroundColor'
                                thZIndex='1'
                              >
                                {Object.keys(values.processData).length > 0 && (
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell>
                                        Version
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Description
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Country Etablishment
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Country From
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Country To
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Country Import
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Importer
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Marketplace
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Transport Organiser
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Ds Threshold Exceeded
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Tax At Destination Option
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Value €
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Oss Choice
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Buyer Vat Reg Country Arrival
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Actions
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                )}
                                <Table.Body>
                                  {values.processData.map((data, index) => {
                                    return (
                                      <Table.Row key={index}>
                                        <Table.Cell>{data.Version}</Table.Cell>
                                        <Table.Cell>
                                          {data.Description}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data.CountryEtablishment}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data.CountryFrom}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data.CountryTo}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data.CountryImport}
                                        </Table.Cell>
                                        <Table.Cell>{data.Importer}</Table.Cell>
                                        <Table.Cell>
                                          {displayBool(data.Marketplace)}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data.TransportOrganiser}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {displayBool(
                                            data.DsThresholdExceeded
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {displayBool(
                                            data.TaxAtDestinationOption
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{data.Value}</Table.Cell>
                                        <Table.Cell>
                                          {displayBool(data.OssChoice)}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {displayBool(
                                            data.BuyerVATRegCountryTo
                                          )}
                                        </Table.Cell>

                                        <Table.Cell>
                                          <Wrapper
                                            display='flex'
                                            justifyContent='flex-end'
                                          >
                                            <Button
                                              icon='edit'
                                              type='button'
                                              color='blue'
                                              onClick={() =>
                                                seteditActivityModalData({
                                                  data: data,
                                                  index: index,
                                                })
                                              }
                                              maxHeight='2.7rem'
                                            />
                                            <ButtonValidation
                                              headerContent='Delete the activity ?'
                                              modalContent='By confirming, the activity will be deleted.'
                                              trgrColor='red'
                                              trgrIcon='trash'
                                              noSubmit
                                              headerIcon='check'
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            />
                                          </Wrapper>
                                        </Table.Cell>
                                      </Table.Row>
                                    );
                                  })}
                                </Table.Body>
                              </TableSpacedRows>
                            </Wrapper>
                          </Transition.Group>
                        )}
                      />
                      {values.processData.length > 0 && (
                        <ButtonValidation
                          trgrContent='Launch process'
                          headerContent='Validate all activities ?'
                          modalContent='By confirming, the process will start.'
                          trgrColor='blue'
                          trgrIcon='check'
                          headerIcon='check'
                          noSubmit
                          onClick={() => {
                            submitForm();
                          }}
                        />
                      )}
                    </Form>
                  </>
                )}
              </Formik>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </>
      {processResultData && clientProspect && !showEntries && (
        <Wrapper>
          <Btn.Group>
            <Button
              floated='left'
              labelPosition='left'
              icon='angle left'
              position='flex-end'
              type='button'
              content='Entered Data'
              margin='5px'
              onClick={() => setshowEntries(true)}
            />
          </Btn.Group>
          <Results
            processResult={processResultData}
            clientProspect={clientProspect}
            history={history}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.data.allCountriesSelector(state),
    formSchemas: selectors.ssrRequest.formSchemasSelector(state),
    processResult: selectors.ssrRequest.processResultSelector(state),
    ssrLoading: selectors.ssrRequest.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
