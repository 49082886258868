import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  getAllContacts(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_CONTACTS,
      payload: data
    };
  },
  getOneContact(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_CONTACT,
      payload: data
    };
  },
  getBothContact(data: dataReqI) {
    return {
      type: actionTypes.GET_BOTH_CONTACTS,
      payload: data
    };
  },
  createContact(data: dataReqI) {
    return {
      type: actionTypes.CREATE_CONTACT,
      payload: data
    };
  },
  addContactRights(data: dataReqI){
    return {
      type: actionTypes.ADD_CONTACT_RIGHTS,
      payload: data,
    };
  },
  getContactRights(data: dataReqI){
    return {
      type: actionTypes.GET_CONTACT_RIGHTS,
      payload: data,
    };
  },
  getAllContactRights(data: dataReqI){
    return {
      type: actionTypes.GET_ALL_CONTACT_RIGHTS,
      payload: data,
    };
  },
  validateContactPartner(data: dataReqI) {
    return {
      type: actionTypes.VALIDATE_CONTACT_PARTNER,
      payload: data
    }
  },
  addContact(data: dataReqI) {
    return {
      type: actionTypes.ADD_CONTACT,
      payload: data
    };
  },
  addContactPartner(data: dataReqI) {
    return {
      type: actionTypes.ADD_CONTACT_PARTNER,
      payload: data
    };
  },
  deleteContact(data: dataReqI) {
    return {
      type: actionTypes.DELETE_CONTACT,
      payload: data
    };
  },
  validateContact(data: dataReqI) {
    return {
      type: actionTypes.VALIDATE_CONTACT,
      payload: data
    };
  },
  refuseContact(data: dataReqI) {
    return {
      type: actionTypes.REFUSE_CONTACT,
      payload: data
    };
  },
  getCompaniesNotInContact(data: dataReqI) {
    return {
      type: actionTypes.GET_COMPANIES_NOT_IN_CONTACT,
      payload: data
    };
  },
  getPendingContact(data: dataReqI) {
    return {
      type: actionTypes.GET_PENDING_CONTACT,
      payload: data
    };
  },
  getRequestContact(data: dataReqI) {
    return {
      type: actionTypes.GET_REQUEST_CONTACT,
      payload: data
    };
  },
  getAllContactTransporters(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_CONTACT_TRANSPORTERS,
      payload: data
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys
      }
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys
      }
    };
  }
};
