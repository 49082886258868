import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const loginErrorSelector = (state) => state.auth.errors.login;
const newuserErrorSelector = (state) => state.auth.errors.newuser;
const logoutErrorSelector = (state) => state.auth.errors.logout;

const errorsSelector = createErrorsSelector([
  loginErrorSelector,
  logoutErrorSelector,
  newuserErrorSelector,
]);

// Loading
const loginLoadingSelector = (state) => state.auth.loading.login;
const newuserLoadingSelector = (state) => state.auth.loading.newuser;
const logoutLoadingSelector = (state) => state.auth.loading.logout;

const loadingSelector = createLoadingSelector([
  loginLoadingSelector,
  logoutLoadingSelector,
  newuserLoadingSelector,
]);

// Success
const loginSuccessSelector = (state) => state.auth.success.login;
const logoutSuccessSelector = (state) => state.auth.success.logout;
const newuserSuccessSelector = (state) => state.auth.success.newuser;

const successSelector = createSuccessSelector([
  loginSuccessSelector,
  logoutSuccessSelector,
  newuserSuccessSelector,
]);

// Error handler
const errorSelector = (state) => state.auth.error;
// Connected User
const connectedUserSelector = (state) => state.auth.connectedUser;
// User
const userSelector = (state) => state.auth.user;
// NewUser
const newuserSelector = (state) => state.auth.newuser;
// userPermissions
const userPermissions = (state) => state.auth.userPermissions;
// userCompanies
const userCompanies = (state) => state.auth.userCompanies;
const getUserCompaniesLoadingSelector = (state) => state.auth.loading.getUserCompanies;
// companySelected
const companySelected = (state) => state.auth.companySelected;

const isAuthenticatedSelector = createSuccessSelector([connectedUserSelector]);

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  isAuthenticatedSelector,
  errorSelector,
  userSelector,
  newuserSelector,
  userPermissions,
  userCompanies,
  companySelected,
  getUserCompaniesLoadingSelector,
};
