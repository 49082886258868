import StartDelivery from 'views/Fiscal/QuickFixes/Seller/StartDelivery';
import DeliveryDocumentsChecking from 'views/Fiscal/QuickFixes/Seller/DeliveryDocumentsChecking';
import MassIntegrations from 'views/Fiscal/QuickFixes/Seller/MassIntegrations';
import MassIntegration from 'views/Fiscal/QuickFixes/Seller/MassIntegrations/Details';
import Seller from 'views/Fiscal/QuickFixes/Seller';

import paths from './paths';

export default [
  {
    private: true,
    path: paths.startDeliveryOperator,
    component: StartDelivery,
    exact: true,
    authorizedRoles: ['operator'],
  },
  {
    private: true,
    path: paths.prefilledDeliveryOperator,
    component: StartDelivery,
    exact: true,
    authorizedRoles: ['operator'],
  },
  {
    private: true,
    path: paths.massIntegrationsOperator,
    component: MassIntegrations,
    exact: true,
    authorizedRoles: ['operator'],
  },
  {
    private: true,
    path: paths.massIntegrationOperator,
    component: MassIntegration,
    exact: true,
    authorizedRoles: ['operator'],
  },
  {
    private: true,
    path: paths.deliveriesManageOperator,
    component: DeliveryDocumentsChecking,
    exact: true,
    authorizedRoles: ['partnership'],
  },
  {
    private: true,
    path: paths.kyaniteOperator,
    component: Seller,
    exact: true,
    authorizedRoles: ['operator'],
  },
];
