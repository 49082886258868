import React from "react";
import { Wrapper, CheckboxStyled } from "./styles";

const Index = ({
  label,
  name,
  value,
  check,
  checked,
  disabled,
  onChange,
}: any) => {
  return (
    <Wrapper>
      <CheckboxStyled
        disabled={disabled}
        value={value}
        name={name}
        label={label}
        defaultChecked={check}
        checked={checked}
        onChange={() => onChange(checked)}
      />
    </Wrapper>
  );
};

export default Index;
