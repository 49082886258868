import React, { useState, useEffect, useRef } from 'react';
import {
  Divider,
  Form,
  Modal,
  Message,
  Table,
  Header,
} from 'semantic-ui-react';
import { Field } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Formik } from 'formik';
import * as Yup from 'yup';
import theme from 'theme';

import Loader from 'components/Loader';
import { Step } from 'components/core';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import Signature from 'components/Form/Signature/signature';
import { Checkbox, File as FileInput, TextArea, Input } from 'components/Form';
import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import TextCustom from 'components/core/TextCustom/TextCustom';
import Photo from 'components/Form/Photo/photo';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import BillingTable from './components/BillingTable/billingTable';
import { imageSchema } from 'interfaces/formSchema/customsClerk';
import { Spacer } from 'components/core';
import EmptyTable from 'components/EmptyTable';
import ModalChedDetails from 'views/Customs/Declaration/Service/Details/components/ModalChedDetails/ModalChedDetails';

const BillingTableStep = ({
  declarationInProgress,
  dispatch,
  manageSteps,
  match,
  endSignature,
  paymentReceipt,
  loading,
  file,
  allChed,
  user,
  allChedLoading,
  companyId,
}: any) => {
  const colors = theme.getTheme().colors;

  const [receiptField, setreceiptField] = useState<boolean>(false);
  const [receiptDate, setReceipDate]: any = useState(null);
  const [lastreceiptDate, setlastreceiptDate]: any = useState(null);
  const [hidePhoto, sethidePhoto] = useState<boolean>(false);
  const [displayPhotoFileField, setdisplayPhotoFileField] = useState<boolean>(
    true
  );
  const [validationSchema, setvalidationSchema] = useState(null);
  const [imageDisplayed, setimageDisplayed] = useState(null);
  const [isFileValidated, setisFileValidated] = useState<boolean>(false);
  const [isFileTerminated, setisFileTerminated] = useState<boolean>(false);
  const [editSignature, seteditSignature] = useState(false);
  const [editReceipt, seteditReceipt] = useState(false);
  const [backupSignature, setbackupSignature] = useState(null);
  const [backupReceipt, setbackupReceipt] = useState(null);
  const [lockSubmission, setlockSubmission] = useState(false);
  const [noPaymentMethod, setnoPaymentMethod] = useState(false);
  const [selectedChed, setselectedChed] = useState(null);
  //const [isClearance, setIsClearance] = useState(false);


  const formikRef = useRef();
  useEffect(() => {
    dispatch(
      actions.socket.subscribeUpdateCustomsClerkFilesInvoicing(
        match.params.fileId
      )
    );

    return () => {
      dispatch(
        actions.customsClerk.reset(['error', 'paymentReceipt', 'endSignature'])
      );
      dispatch(actions.customsDeclaration.reset(['error', 'allChed']));
      dispatch(
        actions.socket.unsubscribeUpdateCustomsClerkFilesInvoicing(
          match.params.fileId
        )
      );
    };
  }, [dispatch, match]);

  useEffect(() => {

    if (file) {
        file.validated && setisFileValidated(true);
        file.signatureEndS3Path && setisFileTerminated(true);

        dispatch(
          actions.customsDeclaration.getAllChed({
            params: {
              companyId: file.CustomsDeclaration.Company_Id,
              declarationId: file.CustomsDeclaration_Id,
            },
          })
        );

      if (file.signatureEndS3Path) {
        dispatch(
          actions.customsClerk.getEndSignature({
            params: {
              fileId: match.params.fileId,
            },
          })
        );
        if (file?.paidBy === 'creditCard' || file?.paidBy === 'cash') {
          dispatch(
            actions.customsClerk.getPaymentReceipt({
              params: {
                fileId: match.params.fileId,
              },
            })
          );
        }
      }

      dispatch(
        actions.socket.subscribeRefreshCustomsDeclarationChed(
          file.CustomsDeclaration.Company_Id,
          file.CustomsDeclaration_Id
        )
      );
    }

    return () => {
      dispatch(
        actions.socket.unsubscribeRefreshCustomsDeclarationChed(
          file.CustomsDeclaration.Company_Id,
          file.CustomsDeclaration_Id
        )
      );
    };
  }, [dispatch, file, declarationInProgress, match]);

  useEffect(() => {
    if (validationSchema) {
      const ref: any = formikRef.current;
      if (ref) {
        ref.submitForm();
      }
    }
  }, [validationSchema]);

  useEffect(() => {
    if (file?.paidBy) {
      const ref: any = formikRef.current;
      if (ref) {
        ref.setFieldValue(file.paidBy, true);
        paymentMethodSwitcher(file.paidBy, ref.setFieldValue);
      }
    }
  }, [file]);

  useEffect(() => {
    if (file?.receipDate) {
      const ref: any = formikRef.current;
      const date = file?.receipDate 
      const regex = new RegExp('\T.*');
      const res = date.toString().match(regex)
      const myDate = res.input.replace(res[0], '');
      if(myDate){
        setReceipDate(myDate)
      }
      if (ref) {
        ref.setFieldValue('receipDate', myDate) 
      }
    }
  }, [file]);

  useEffect(() => {
    if(receiptDate){
      setlastreceiptDate(receiptDate)
    }
  }, [receiptDate])

  useEffect(() => {
    if (file && file.signatureEndS3Path && endSignature instanceof Blob) {
      const ref: any = formikRef.current;
      const endSignatureImage = new File([endSignature], 'endSignature.jpg');
      ref.setFieldValue('signature', endSignatureImage);
    } else {
      const ref: any = formikRef.current;
      ref.setFieldValue('signature', null);
    }
  }, [endSignature, file]);

  useEffect(() => {
    if (file && file.signatureEndS3Path && paymentReceipt instanceof Blob) {
      const ref: any = formikRef.current;
      const paymentReceiptImage = new File(
        [paymentReceipt],
        'paymentReceipt.jpg'
      );
      ref.setFieldValue('paymentReceipt', paymentReceiptImage);
    } else {
      const ref: any = formikRef.current;
      ref.setFieldValue('paymentReceipt', null);
    }
  }, [paymentReceipt, file]);

  const paymentMethodSwitcher = (selectedMethod, setter) => {
    const paymentMethods = ['creditCard', 'asdPayzen', 'cash', 'provision'];
    paymentMethods.filter((method) => 
      method !== selectedMethod && setter(method, false)
    );
    setter('paidBy', selectedMethod);
    selectedMethod === 'creditCard' || selectedMethod === 'cash'
      ? setreceiptField(true)
      : setreceiptField(false);
  };

  const preSubmit = (values) => {
    if (
      (editSignature && values.signature === backupSignature) ||
      (editReceipt && values.paymentReceipt === backupReceipt)
    ) {
      setlockSubmission(true);
    } else {
      setlockSubmission(false);
      !values.paidBy ? setnoPaymentMethod(true) : setnoPaymentMethod(false);
      let validationSchemaShape = {
        paidBy: Yup.string().required('Required'),
        comment: Yup.string(),
        receipDate: Yup.date(),
      };
      if (!isFileTerminated || editSignature) {
        validationSchemaShape['signature'] = imageSchema;
      }
      if (
        receiptField &&
        (!isFileTerminated || editReceipt || !file.paymentReceiptS3Path)
      ) {
        validationSchemaShape = {
          ...validationSchemaShape,
          ...{
            paymentReceipt: imageSchema,
          },
        };
      }
      setvalidationSchema(Yup.object().shape(validationSchemaShape));
    }
  };

  const submit = (values) => {
    if (!isFileTerminated) {
      dispatch(
        actions.customsClerk.validateFile({
          params: {
            fileId: match.params.fileId,
          },
          data: {
            paidBy: values.paidBy,
            ...(values.receipDate && { receipDate: values.receipDate }),
            ...(receiptField && { paymentReceipt: values.paymentReceipt }),
            ...(values.comment && { comment: values.comment }),
            signatureEnd: values.signature,
            customsId: file.CustomsDeclaration_Id
          },
        })
      );
    } else if (!isFileValidated) {
      dispatch(
        actions.customsClerk.updateInvoicingStep({
          params: {
            fileId: match.params.fileId,
          },
          data: {
            paidBy: values.paidBy,
            isReceiptNeeded: receiptField,
            ...(values.receipDate && { receipDate: values.receipDate }),
            ...(values.comment && { comment: values.comment }),
            ...((editReceipt ||
              (receiptField && !file.paymentReceiptS3Path)) && {
              paymentReceipt: values.paymentReceipt,
            }),
            ...(editSignature && { signatureEnd: values.signature }),
          },
        })
      );
     
    }

    manageSteps(4);
  };

  return (
    <>
      {selectedChed && (
        <ModalChedDetails
          handleClose={() => setselectedChed(null)}
          chedDetails={selectedChed}
          dispatch={dispatch}
          companyId={file.CustomsDeclaration.Company_Id}
          isCompleted={file.CustomsDeclaration.state === 'completed'}
          truckLicensePlate={file.CustomsDeclaration.truckLicensePlate}
          clerkId={match.params.fileId}
          destination={file.CustomsDeclaration.CustomsOffice_Id}
        />
      )}
      {imageDisplayed && (
        <Modal
          open={imageDisplayed && true}
          onClose={() => {
            setimageDisplayed(null);
          }}
          closeIcon
        >
          <Wrapper padding='1rem'>
            <ImageCustom src={URL.createObjectURL(imageDisplayed)} />
          </Wrapper>
        </Modal>
      )}

      <Formik
        initialValues={{
          paidBy: file?.paidBy || '',
          paymentReceipt: null,
          receipDate: file?.receipDate || '',
          comment: file?.comment || '',
          signature: null,
        }}
        onSubmit={submit}
        ref={formikRef}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          values,
          handleSubmit,
        }: any) => (
          <Wrapper width='80%' margin='auto'>
            <BillingTable dispatch={dispatch} match={match} companyId={file.CustomsDeclaration.Company.id} />
            <Divider hidden />
            {/* <BillingLinesTable 
              dispatch={dispatch} 
              match={match} 
              customsId={file.CustomsDeclaration_Id} 
              companyId={file.CustomsDeclaration.Company.id}
            /> */}

            <Header>CHED</Header>
            <Table striped columns='4' selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Number</Table.HeaderCell>
                  <Table.HeaderCell>Lines</Table.HeaderCell>
                  <Table.HeaderCell>Product</Table.HeaderCell>
                  <Table.HeaderCell>State</Table.HeaderCell>
                  <Table.HeaderCell>Consignment</Table.HeaderCell>
                  <Table.HeaderCell>Types</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {((!allChed || allChed.length === 0) && (
                  <EmptyTable colNumber={6} />
                )) || (allChedLoading && <Loader />) || 
                  allChed.map((ched, index) => (
                    <>
                    <Table.Row
                      key={index}
                      className='cursor-pointer'
                      onClick={() => 
                        setselectedChed(allChed.find((e) => e.id === ched.id))
                      }
                    >
                      <Table.Cell>{ched.number}</Table.Cell>
                      {/* <Table.Cell>{ched.countLines} {isClearance}</Table.Cell> */}
                      <Table.Cell>{ched.countLines}</Table.Cell>
                      <Table.Cell>{ched.productType}</Table.Cell>
                      <Table.Cell>
                        {ched.veto && ched.phyto
                          ? 'veto/phyto'
                          : ched.veto
                          ? 'veto'
                          : 'phyto'}
                      </Table.Cell>
                      <Table.Cell>{ched.Consignment && ched.Consignment.CustomsOffice.name}</Table.Cell>
                      <Table.Cell>
                         <Step
                          label={ched.state}
                          color={
                            ched.state === 'validated'
                              ? colors.validatedStep
                              : ched.state === 'new'
                              ? colors.finishedStep
                              : colors.inProgressStep
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key={`${index}-b`}>
                      <Table.Cell colspan="6">
                          {(allChedLoading && <Loader />) || (
                            ched.Consignments && ched.Consignments.map((e, index) => (
                              (index === 0 && (<div>Consignment - Estimated price: {(+'0.00' + e.estimatedPrice).toFixed(2)} €</div>)) || 
                              (<div>{e.status} - Estimated price: {(+'0.00' + e.estimatedPrice).toFixed(2)} €</div>)
                              )))
                          }
                      </Table.Cell>
                      </Table.Row>
                    </>
                  ))}
              </Table.Body>
            </Table>
            <Form onSubmit={handleSubmit} loading={loading || !file}>
              <Divider hidden />
              <Wrapper display='flex' justifyContent='space-between'>
                <Form.Field>
                  <Field
                    radio
                    name='creditCard'
                    label='Credit card'
                    {...(isFileValidated && { disabled: true })}
                    checked={true === values.creditCard}
                    onChange={() => {
                      setnoPaymentMethod(false);
                      if (values.creditCard === true) {
                        setFieldValue('creditCard', false);
                        paymentMethodSwitcher('', setFieldValue);
                      } else {
                        setFieldValue('creditCard', true);
                        paymentMethodSwitcher('creditCard', setFieldValue);
                      }
                    }}
                    component={Checkbox}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Field
                    radio
                    name='asdPayzen'
                    label='ASD Payzen'
                    {...(isFileValidated && { disabled: true })}
                    checked={true === values.asdPayzen}
                    onChange={() => {
                      setnoPaymentMethod(false);
                      if (values.asdPayzen === true) {
                        setFieldValue('', false);
                        paymentMethodSwitcher('', setFieldValue);
                      } else {
                        setFieldValue('asdPayzen', true);
                        paymentMethodSwitcher('asdPayzen', setFieldValue);
                      }
                    }}
                    component={Checkbox}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Field
                    radio
                    name='cash'
                    label='Cash'
                    {...(isFileValidated && { disabled: true })}
                    checked={true === values.cash}
                    onChange={() => {
                      setnoPaymentMethod(false);
                      if (values.cash === true) {
                        setFieldValue('', false);
                        paymentMethodSwitcher('', setFieldValue);
                      } else {
                        setFieldValue('cash', true);
                        paymentMethodSwitcher('cash', setFieldValue);
                      }
                    }}
                    component={Checkbox}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Field
                    radio
                    name='provision'
                    label='Provision'
                    {...(isFileValidated && { disabled: true })}
                    checked={true === values.provision}
                    onChange={() => {
                      setnoPaymentMethod(false);
                      if (values.provision === true) {
                        setFieldValue('', false);
                        paymentMethodSwitcher('', setFieldValue);
                      } else {
                        setFieldValue('provision', true);
                        paymentMethodSwitcher('provision', setFieldValue);
                      }
                    }}
                    component={Checkbox}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Form.Field>
              </Wrapper>
              {noPaymentMethod && (
                <Message negative>
                  <Message.Header>Error</Message.Header>
                  <p>You must select a payment method</p>
                </Message>
              )}

              <Divider hidden />
              {!editReceipt &&
                receiptField &&
                isFileTerminated &&
                file.paymentReceiptS3Path && (
                  <Wrapper width='80%' margin='auto'>
                    <>
                      <Wrapper>
                        <TextCustom>Receipt photo:</TextCustom>
                      </Wrapper>
                      <Wrapper display='flex' justifyContent='center'>
                        <Segment margin='0' maxWidth='50%' padding='2rem'>
                          <ImageCustom
                            src={
                              values.paymentReceipt &&
                              URL.createObjectURL(values.paymentReceipt)
                            }
                            onClick={() => {
                              setimageDisplayed(values.paymentReceipt);
                            }}
                          />
                        </Segment>
                      </Wrapper>
                    </>
                </Wrapper>
              )}
              {!isFileValidated && receiptField && (
                <Wrapper>
                  {((!editReceipt && !isFileTerminated) ||
                    (isFileTerminated &&
                      (editReceipt || !file.paymentReceiptS3Path))) && (
                    <Wrapper>
                      <MandatoryFieldLabel content='Receipt photo' />
                      <Field
                        component={Photo}
                        name='paymentReceipt'
                        hidePhoto={hidePhoto}
                        mandatory
                        fileName='receipt'
                        onCapture={() => {
                          sethidePhoto(false);
                        }}
                        onCameraDisplay={() => {
                          setdisplayPhotoFileField(false);
                        }}
                        onCameraHide={() => {
                          setdisplayPhotoFileField(true);
                        }}
                        onClear={() => {
                          setdisplayPhotoFileField(true);
                        }}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit,
                          values,
                        }}
                      />
                      {displayPhotoFileField && (
                        <>
                          <TextCustom>Or</TextCustom>
                          <Field
                            mandatory
                            name='paymentReceipt'
                            component={FileInput}
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                            action={() => sethidePhoto(true)}
                            buttonType='button'
                          />
                        </>
                      )}
                    </Wrapper>
                  )}
                  {editReceipt && (
                    <Wrapper display='block' margin='0'>
                      <Button
                        type='button'
                        icon='close'
                        labelPosition='left'
                        color='blue'
                        content='Cancel receipt edition'
                        margin='5px'
                        onClick={() => {
                          seteditReceipt(false);
                          setFieldValue('paymentReceipt', backupReceipt);
                        }}
                      />
                    </Wrapper>
                  )}
                </Wrapper>
              )}
              {receiptField &&
                !isFileValidated &&
                !editReceipt &&
                isFileTerminated &&
                file.paymentReceiptS3Path && (
                  <Wrapper display='block' margin='0'>
                    <Button
                      type='button'
                      icon='edit'
                      labelPosition='right'
                      color='blue'
                      content='Edit receipt'
                      margin='5px'
                      onClick={() => {
                        seteditReceipt(true);
                        setbackupReceipt(values.paymentReceipt);
                      }}
                    />
                  </Wrapper>
                )}
              <Divider hidden />
        
              {!isFileValidated && receiptField && (
                <Wrapper display='flex' justifyContent='space-evenly'>
                  <Field
                    mandatory
                    label='Receipt date stamped'
                    name='receipDate'
                    component={Input}
                    type='date'
                    meta={{
                      errors,
                      touched
                    }}
                    onChange={() => {
                      if (values.receipDate) {
                        setFieldValue('receipDate', values.receipDate);
                      }
                    }}
                  />
                </Wrapper>
              )}
              <Divider hidden />
              <Field
                {...(isFileValidated && { disabled: true })}
                label='Comment'
                placeholder='Comment'
                name='comment'
                type='text'
                component={TextArea}
                meta={{ errors, touched }}
              />

              <Divider hidden />
              {!editSignature && isFileTerminated && (
                <>
                  <Wrapper>
                    <TextCustom>Signature:</TextCustom>
                  </Wrapper>
                  <Wrapper display='flex' justifyContent='center'>
                    <Segment margin='0' padding='2rem'>
                      <ImageCustom
                        src={
                          values.signature &&
                          URL.createObjectURL(values.signature)
                        }
                        onClick={() => {
                          setimageDisplayed(values.signature);
                        }}
                      />
                    </Segment>
                  </Wrapper>
                </>
              )}

              {!isFileValidated && (
                <Wrapper>
                  {((!editSignature && !isFileTerminated) ||
                    (isFileTerminated && editSignature)) && (
                    <Field
                      component={Signature}
                      name='signature'
                      label='Signature'
                      mandatory
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        values,
                      }}
                    />
                  )}

                  {editSignature && (
                    <Wrapper display='block' margin='0'>
                      <Button
                        type='button'
                        icon='close'
                        labelPosition='left'
                        color='blue'
                        content='Cancel signature edition'
                        margin='5px'
                        onClick={() => {
                          seteditSignature(false);
                          setFieldValue('signature', backupSignature);
                        }}
                      />
                    </Wrapper>
                  )}
                </Wrapper>
              )}

              {!editSignature && isFileTerminated && !isFileValidated && (
                <Wrapper display='block' margin='0'>
                  <Button
                    type='button'
                    icon='edit'
                    labelPosition='right'
                    color='blue'
                    content='Edit signature'
                    margin='5px'
                    onClick={() => {
                      seteditSignature(true);
                      setbackupSignature(values.signature);
                    }}
                  />
                </Wrapper>
              )}

              <Spacer height='3rem' />
              {lockSubmission && (
                <Message negative>
                  <Message.Header>Error</Message.Header>
                  <p>Receipt or signature field are under edition</p>
                </Message>
              )}
              <Wrapper display='block' margin='0'>
                <Button
                  type='button'
                  size='large'
                  icon='right arrow'
                  labelPosition='right'
                  color='blue'
                  content={!isFileValidated ? 'Save and next' : 'Next'}
                  margin='5px'
                  onClick={() => {
                    if (!isFileValidated) {
                      preSubmit(values);
                    } else {
                      manageSteps(4);
                    }
                  }}
                />
              </Wrapper>
            </Form>
          </Wrapper>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    endSignature: selectors.customsClerk.endSignatureSelector(state),
    paymentReceipt: selectors.customsClerk.paymentReceiptSelector(state),
    loading: selectors.customsClerk.loadingSelector(state),
    allChed: selectors.customsDeclaration.allChedSelector(state),
    allChedLoading: selectors.customsDeclaration.allChedLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(BillingTableStep);
