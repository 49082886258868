import React from 'react';

import {
  CardStyled,
  LogoStyled,
  DescriptionStyled,
  TagsStyled,
  TagStyled,
  DescriptionTextStyled,
} from './serviceCardStyles';

import whiteArrow from '../../../../assets/images/whiteArrow.png';
import './serviceCard.css';

type ServiceCardProps = {
  logo: string;
  name: string;
  description: string;
  tags: Array<string>;
  color: string;
  imageSize?: string;
  bgPosition: {
    x: string;
    y: string;
  };
};

const ServiceCard: React.FunctionComponent<ServiceCardProps> = (props) => {
  return (
    <CardStyled bgPosition={props.bgPosition}>
      <LogoStyled>
        <img
          height={props.imageSize || '80%'}
          src={props.logo}
          alt={`logo ${props.name}`}
        ></img>
      </LogoStyled>
      <DescriptionStyled>
        <DescriptionTextStyled>{props.description}</DescriptionTextStyled>
        <TagsStyled>
          {props.tags.map((tag, i) => (
            <TagStyled key={i} color={props.color}>
              {tag}
              <img src={whiteArrow} alt={'arrow logo'} className='tagLogo' />
            </TagStyled>
          ))}
        </TagsStyled>
      </DescriptionStyled>
    </CardStyled>
  );
};

export default ServiceCard;
