import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const createVatErrorSelector = state => state.vat.errors.createVat;
const getAllVatErrorSelector = state => state.vat.errors.getAllVat;
const getOneVatErrorSelector = state => state.vat.errors.getOneVat;
const deleteVatErrorSelector = state => state.vat.errors.deleteVat;
const updateVatErrorSelector = state => state.vat.errors.updateVat;

const errorsSelector = createErrorsSelector([
  createVatErrorSelector,
  getAllVatErrorSelector,
  getOneVatErrorSelector,
  deleteVatErrorSelector,
  updateVatErrorSelector,
]);

// Loading
const createVatLoadingSelector = state => state.vat.loading.createVat;
const getAllVatLoadingSelector = state => state.vat.loading.getAllVat;
const getOneVatLoadingSelector = state => state.vat.loading.getOneVat;
const deleteVatLoadingSelector = state => state.vat.loading.deleteVat;
const updateVatLoadingSelector = state => state.vat.loading.updateVat;

const loadingSelector = createLoadingSelector([
  createVatLoadingSelector,
  getAllVatLoadingSelector,
  getOneVatLoadingSelector,
  deleteVatLoadingSelector,
  updateVatLoadingSelector,
]);

// Success
const createVatSuccessSelector = state => state.vat.success.createVat;
const getAllVatSuccessSelector = state => state.vat.success.getAllVat;
const getOneVatSuccessSelector = state => state.vat.success.getOneVat;
const deleteVatSuccessSelector = state => state.vat.success.deleteVat;
const updateVatSuccessSelector = state => state.vat.success.updateVat;

const successSelector = createSuccessSelector([
  createVatSuccessSelector,
  getAllVatSuccessSelector,
  getOneVatSuccessSelector,
  deleteVatSuccessSelector,
  updateVatSuccessSelector,
]);

//Errors Handlers
const errorSelector = state => state.vat.error;

// vats
const vatsSelector = state => state.vat.vats;

// vat
const vatselector = state => state.vat.vat;




export default {
  errorsSelector,
  errorSelector,
  loadingSelector,
  successSelector,
  vatsSelector,
  vatselector,
};
