import actionTypes from 'store/service/actionTypes';
import routes from './routes';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.CREATE:
        try {
          next({ type: actionTypes.CREATE_LOADING });
          await request.create({ ...action.payload });
          next({
            type: actionTypes.CREATE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GET_ONE:
        try {
          next({ type: actionTypes.GET_ONE_LOADING });
          const response = await request.getOne({ ...action.payload });
          next({
            type: actionTypes.GET_ONE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_ERROR,
            error,
          });
        }
        break;

      case actionTypes.UPDATE:
        try {
          next({ type: actionTypes.UPDATE_LOADING });
          await request.update({ ...action.payload });
          next({
            type: actionTypes.UPDATE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GET_ALL:
        try {
          next({ type: actionTypes.GET_ALL_LOADING });
          const response = await request.getAll();
          next({
            type: actionTypes.GET_ALL_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_ERROR,
            error,
          });
        }
        break;

      case actionTypes.REMOVE:
        try {
          next({ type: actionTypes.REMOVE_LOADING });
          await request.remove({ ...action.payload });
          next({
            type: actionTypes.REMOVE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.REMOVE_ERROR,
            error,
          });
        }
        break;
      case actionTypes.CREATE_PRODUCT:
        try {
          next({ type: actionTypes.CREATE_PRODUCT_LOADING });
          await request.createProduct({ ...action.payload });
          next({
            type: actionTypes.CREATE_PRODUCT_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_PRODUCT_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GET_ONE_PRODUCT:
        try {
          next({ type: actionTypes.GET_ONE_PRODUCT_LOADING });
          const response = await request.getOneProduct({ ...action.payload });
          next({
            type: actionTypes.GET_ONE_PRODUCT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_PRODUCT_ERROR,
            error,
          });
        }
        break;

      case actionTypes.UPDATE_PRODUCT:
        try {
          next({ type: actionTypes.UPDATE_PRODUCT_LOADING });
          await request.updateProduct({ ...action.payload });
          next({
            type: actionTypes.UPDATE_PRODUCT_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_PRODUCT_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GET_PRODUCT_OF_SERVICE:
        try {
          next({ type: actionTypes.GET_PRODUCT_OF_SERVICE_LOADING });
          const response = await request.getProductsOfService({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_PRODUCT_OF_SERVICE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_PRODUCT_OF_SERVICE_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GET_PRODUCT_OF_SERVICE_SUB:
        try {
          next({ type: actionTypes.GET_PRODUCT_OF_SERVICE_SUB_LOADING });
          const response = await request.getProductsOfServiceSub({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_PRODUCT_OF_SERVICE_SUB_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_PRODUCT_OF_SERVICE_SUB_ERROR,
            error,
          });
        }
        break;

      case actionTypes.REMOVE_PRODUCT:
        try {
          next({ type: actionTypes.REMOVE_PRODUCT_LOADING });
          await request.removeProduct({ ...action.payload });
          next({
            type: actionTypes.REMOVE_PRODUCT_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.REMOVE_PRODUCT_ERROR,
            error,
          });
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
