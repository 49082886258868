// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';
import tippy from 'tippy.js';

// Components
import EditButton from '../editButton/editButton';
import LodgingMissionDetails from '../updateForms/lodgingMissionDetails';
import Popup from '../popup/popup';
import CreateInspection from '../createForms/createInspection';
import Loader from 'components/Loader';
import spwLogo from '../../../../assets/images/logoSPWCouleur.png';
import groupLogo from '../../../../assets/images/logoGroupCouleur.png';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';
import euCountries from '../../assets/json/euCountries.json';

// Interfaces
import MissionDetailsProperties from '../../interfaces/missionDetailsProperties';

// Resources
import addLogo from '../../assets/images/logos/add.png';
import { currentLanguage } from 'views';
import saveLogo from '../../assets/images/logos/save.png';

// Stylesheets
import './missions.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import LocationMissionDetails from '../updateForms/locationMissionDetails';
import axios from 'axios';
import tooltip from '../../assets/images/logos/tooltip.png';
import 'tippy.js/dist/tippy.css';

export interface PricingParams {
  representation: number;
  documentStorage: number;
  management: number;
  sipsiDeclaration: number;
  constructionCard: number;
  registeredMail1: number;
  registeredMail2: number;
  registeredMail3: number;
  informationDocument: number;
  updateDocument: number;
  assistance: number;
}

/**
 * @name missionDetails.tsx
 *
 * @path /components/missions/missionDetails.tsx
 *
 * @description Defines mission details structure
 */
const MissionDetails: React.FC = ({ user }: any) => {
  // Employee id to get all datas about it by requests
  const { id } = useParams<MissionDetailsProperties>();
  const { reference } = useParams<MissionDetailsProperties>();

  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();
  // Variables
  const numbers = Array.from({ length: 999 }, (_, index) => index + 1);
  const numberOfEmployeesSelectorRef = useRef<HTMLSelectElement>(null);
  const [isOpenEmployees, setIsOpenEmployees] = useState(false);

  // Tooltips
  const locationTooltipRef = useRef(null);
  const lodgingTooltipRef = useRef(null);

  useEffect(() => {
    if (locationTooltipRef.current) {
      tippy(locationTooltipRef.current, {
        content: t('missionDetailsPageStrings.tooltips.locationTooltip'),
        placement: 'right',
        arrow: true,
      });
    }
    if (lodgingTooltipRef.current) {
      tippy(lodgingTooltipRef.current, {
        content: t('missionDetailsPageStrings.tooltips.lodgingTooltip'),
        placement: 'right',
        arrow: true,
      });
    }
  });

  // Inspection popup
  const [inspection, setInspection] = useState<boolean>(false);
  const [paid, setPaid] = useState<boolean>(false);
  const [inspectionPopupVisible, setInspectionPopupVisible] =
    useState<boolean>(false);
  const inspectionPopupRef = useRef<HTMLDivElement>(null);

  const handleClickGoToInspection = () => {
    window.location.href = `/spw/missions/details/${id}/${reference}/inspection`;
  };

  const handleClickAddInspection = () => {
    setInspectionPopupVisible(!inspectionPopupVisible);
  };

  const handleClickCloseInspectionPopup = () => {
    setInspectionPopupVisible(false);
  };

  // Popups' references
  const childRefGlobal = useRef<HTMLDivElement>(null);
  const childRefEmployees = useRef<HTMLDivElement>(null);
  const childRefLodging = useRef<HTMLDivElement>(null);
  const childRefLocation = useRef<HTMLDivElement>(null);
  const globalInformationButtonRef = useRef<HTMLButtonElement>(null);
  const employeesButtonRef = useRef<HTMLButtonElement>(null);
  const lodgingButtonRef = useRef<HTMLButtonElement>(null);
  const globalInformationEditPopupRef = useRef<HTMLDivElement>(null);
  const employeesEditPopupRef = useRef<HTMLDivElement>(null);
  const lodgingEditPopupRef = useRef<HTMLDivElement>(null);

  // Manage popups' visibility
  const [
    globalInformationEditPopupIsVisible,
    setGlobalInformationEditPopupIsVisible,
  ] = useState<boolean>(false);
  const [employeesEditPopupIsVisible, setEmployeesEditPopupIsVisible] =
    useState<boolean>(false);
  const [lodgingEditPopupIsVisible, setLodgingEditPopupIsVisible] =
    useState<boolean>(false);
  // Defines the action that must be executed when user performed a click on global information edit button
  const handleClickEditGlobalInformationButton = () => {
    setGlobalInformationEditPopupIsVisible(
      !globalInformationEditPopupIsVisible
    );
  };

  // Defines the action that must be performed when the user clicks outside of the component
  const handleOutsideClickNbEmployees = (event: MouseEvent) => {
    if (
      numberOfEmployeesSelectorRef.current &&
      !numberOfEmployeesSelectorRef.current.contains(event.target as Node)
    ) {
      setIsOpenEmployees(false);
    }
  };

  // Configuration of page's current state when handleOutsideClick is performed
  useEffect(() => {
    document.addEventListener('click', handleOutsideClickNbEmployees);

    return () => {
      document.removeEventListener('click', handleOutsideClickNbEmployees);
    };
  }, []);

  // Defines the action that must be executed when user performed a click on employees edit button
  const handleClickEditEmployeesButton = () => {
    setEmployeesEditPopupIsVisible(!employeesEditPopupIsVisible);
  };

  // Defines the action that must be executed when user performed a click on lodging edit button
  const handleClickEditLodgingButton = () => {
    setLodgingEditPopupIsVisible(!lodgingEditPopupIsVisible);
  };

  // Popups' references
  const addButtonRef = useRef<HTMLButtonElement>(null);

  // State to hold the list of employees
  const [employees, setEmployees] = useState<string[]>([]);
  const adminOrOperator = user.admin || user.operator;

  const addEmployee = () => {
    setEmployees([...employees, '']);
  };

  const updatePersonInCharge = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateMission/updatePersonInCharge`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: reference,
            personInCharge: user.email.split('@')[0],
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const updateMissionIsPaid = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateMission/updateMissionIsPaid`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: reference,
            isPaid: true,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const handleClickTakeInCharge = () => {
    updatePersonInCharge();
    window.location.reload();
  };

  ////////////////////////////////////////////

  async function getCurrentInvoiceValue() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getLastAnnuallyInvoiceId`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: 2,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function updateCurrentInvoiceValue() {
    const currentInvoiceValue: number = await getCurrentInvoiceValue();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateLastAnnuallyInvoiceId`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: 2,
            updatedValue: currentInvoiceValue + 1,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  const [invoiceNumber, setInvoiceNumber] = useState<string>();

  async function createInvoiceReference() {
    let year = new Date().getFullYear() % 100;
    let newInvoiceReference: string = 'S' + year;

    let id = await updateCurrentInvoiceValue();

    while (id.toString().length < 5) {
      id = '0' + id.toString();
    }

    newInvoiceReference += id;
    setInvoiceNumber(newInvoiceReference);
    return newInvoiceReference;
  }

  async function getPricingPolicy() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getPricingPolicy`,
        {
          method: 'GET',
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        let pricingPolicy: PricingParams = {
          representation: result.data.representationPrice.pricing_amount,
          documentStorage: result.data.documentStoragePrice.pricing_amount,
          management: result.data.managementPrice.pricing_amount,
          sipsiDeclaration: result.data.sipsiDeclarationPrice.pricing_amount,
          constructionCard: result.data.constructionCardPrice.pricing_amount,
          registeredMail1: result.data.registeredMailPrice1.pricing_amount,
          registeredMail2: result.data.registeredMailPrice2.pricing_amount,
          registeredMail3: result.data.registeredMailPrice3.pricing_amount,
          informationDocument:
            result.data.informationDocumentPrice.pricing_amount,
          updateDocument: result.data.updateDocumentPrice.pricing_amount,
          assistance: result.data.assistancePrice.pricing_amount,
        };

        return pricingPolicy;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function createInvoice(
    invoiceNumber: string,
    missionId: number,
    fileName: string
  ) {
    const values = JSON.stringify({
      reference: invoiceNumber,
      date: new Date(),
      missionId: missionId,
      billerId: 1,
      companyId: companyId,
      s3Path: `invoice_mission_${missionId}/${invoiceNumber}/${fileName}`,
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/newInvoice`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: values,
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  function uniqid(mimeType: string) {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, '').padEnd(14, '0');
    return `${id}.${mimeType}`;
  }

  function calculateMaxTextWidth(doc: jsPDF, text: string): number {
    let lines: string[] = text.split('\n');
    let maxWidth = 0;

    lines.forEach((line) => {
      let lineWidth = doc.getTextWidth(line);
      if (lineWidth > maxWidth) {
        maxWidth = lineWidth;
      }
    });

    return maxWidth;
  }

  let infoDocList: string[] = [];

  useEffect(() => {
    if (hasEnglish) {
      infoDocList.push('en');
    }
    if (hasBulgarian) {
      infoDocList.push('bg');
    }
    if (hasCzech) {
      infoDocList.push('cz');
    }
    if (hasGerman) {
      infoDocList.push('de');
    }
    if (hasItalian) {
      infoDocList.push('it');
    }
    if (hasSpanish) {
      infoDocList.push('es');
    }
    if (hasPortuguese) {
      infoDocList.push('pt');
    }
    if (hasPolish) {
      infoDocList.push('pl');
    }
    if (hasRomanian) {
      infoDocList.push('ro');
    }
  }, []);

  async function generatePDF(
    pricingPolicy: PricingParams,
    invoiceNumber: string
  ): Promise<File> {
    const doc = new jsPDF();

    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    let rCompanyName;
    let rVatNumber;
    let rBillingId;
    let rBillingMain;
    let rBillingAdditional;
    let rBillingPostal;
    let rBillingCity;
    let rBillingCountry;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getCompanyDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: companyId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        rCompanyName = result.data.companyName;

        rVatNumber = result.data.vatOrRegistrationNumber;

        rBillingId = result.data.billingAddress;

        setIsLoading(false);

        try {
          const response = await fetch(
            `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAddress`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ id: rBillingId }),
            }
          );

          const result = await response.json();

          if (response == null) {
            return;
          } else {
            rBillingMain = result.data.mainAddress;
            rBillingAdditional = result.data.additionalAddress;
            rBillingPostal = result.data.postalCode;
            rBillingCity = result.data.city;
            rBillingCountry = result.data.country;

            let billingCountry: string;
            if (currentLanguage == 'fr') {
              let country = countriesFR.find(
                (country) => country.code === result.data.country
              );
              billingCountry = country ? country.name : 'Country not found';
            } else {
              let country = countriesEN.find(
                (country) => country.code === result.data.country
              );
              billingCountry = country ? country.name : 'Country not found';
            }

            setIsLoading(false);

            JSON.stringify(result.data);
          }
        } catch (error) {
          console.log('Erreur', error);
        }

        JSON.stringify(result.data.companyId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }

    // Defines invoice details
    const invoiceDetails = {
      date: formatDate(Date.now()),
      items: [],
      total: amount,
    };

    invoiceDetails.items.push({
      description: t('invoiceStrings.representation'),
      quantity: 1,
      amount: Number(pricingPolicy.representation),
    });

    if (withSipsi) {
      invoiceDetails.items.push({
        description: t('invoiceStrings.sipsi'),
        quantity: numberOfEmployees,
        amount: pricingPolicy.sipsiDeclaration * numberOfEmployees,
      });
    }

    if (withConstructionCard && numberOfCards == 0) {
      invoiceDetails.items.push({
        description: t('invoiceStrings.constructionCardModification'),
        quantity: numberOfEmployees,
        amount: pricingPolicy.updateDocument * numberOfEmployees,
      });
    } else if (withConstructionCard) {
      if (numberOfEmployees - numberOfCards == 0) {
        invoiceDetails.items.push({
          description: t('invoiceStrings.constructionCardCreation'),
          quantity: numberOfCards,
          amount: pricingPolicy.constructionCard * numberOfCards,
        });
      } else {
        invoiceDetails.items.push({
          description: t('invoiceStrings.constructionCardModification'),
          quantity: numberOfEmployees - numberOfCards,
          amount:
            pricingPolicy.updateDocument * (numberOfEmployees - numberOfCards),
        });
        invoiceDetails.items.push({
          description: t('invoiceStrings.constructionCardCreation'),
          quantity: numberOfCards,
          amount: pricingPolicy.constructionCard * numberOfCards,
        });
      }
    }

    if (infoDocList.length > 0) {
      invoiceDetails.items.push({
        description: t('invoiceStrings.informationDocument'),
        quantity: infoDocList.length,
        amount: pricingPolicy.informationDocument * infoDocList.length,
      });
    }

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const order = t('globalStrings.invoice.order') + reference;

    // ASD SPW logo
    doc.addImage(spwLogo, 'PNG', 15, 10, 30, 20);

    // Invoice number
    doc.setFontSize(16);
    doc.setFont('Muli-Bold', 'normal');
    doc.text(
      invoiceNumber,
      pageWidth - doc.getTextWidth(invoiceNumber) - 15,
      20
    );

    // MissionReference
    doc.setFontSize(10);
    doc.setFont('Muli-Regular', 'normal');
    doc.text(order, pageWidth - doc.getTextWidth(order) - 15, 25);

    // Issue date
    const issueDate = `${t('globalStrings.invoice.invoiceIssuedOn')}${
      invoiceDetails.date
    }`;
    doc.setFontSize(10);
    doc.text(issueDate, pageWidth - doc.getTextWidth(issueDate) - 15, 35);

    // Seller
    const issueBy = `${t('globalStrings.invoice.invoiceIssuedBy')}`;
    doc.setFont('Muli-Bold', 'normal');
    doc.text(issueBy, 15, 55);
    doc.setFont('Muli-Regular', 'normal');
    doc.text('ASD France', 15, 65);
    doc.text('310 rue du Vallon', 15, 75);
    doc.text('06560 Valbonne', 15, 80);
    doc.text('France', 15, 85);
    doc.text('FR51429463342', 15, 95);

    // Buyer
    const issueFor = `${t('globalStrings.invoice.invoiceIssuedFor')}`;
    doc.setFont('Muli-Bold', 'normal');
    doc.text(issueFor, pageWidth / 2, 55);
    doc.setFont('Muli-Regular', 'normal');
    doc.text(rCompanyName, pageWidth / 2, 65);
    doc.text(rBillingMain, pageWidth / 2, 75);
    doc.text(rBillingPostal + ' ' + rBillingCity, pageWidth / 2, 80);
    doc.text(rBillingCountry, pageWidth / 2, 85);
    doc.text(rVatNumber, pageWidth / 2, 95);
    doc.text(t('invoiceStrings.serviceProvision'), 15, 105);

    let sum = 0;
    invoiceDetails.items.forEach((item) => {
      sum += Number(item.amount);
    });

    invoiceDetails.items.push({
      description: `${t('invoiceStrings.periodAndEmployees')}\n(${formattedDate(
        start
      )} - ${formattedDate(end)} - ${numberOfEmployees} ${t(
        'invoiceStrings.employees'
      )})`,
      quantity: '',
      amount: Number((amount - sum).toFixed(2)),
    });

    // Order details
    (doc as any).autoTable({
      startY: 110,
      head: [
        [
          t('globalStrings.invoice.description'),
          t('globalStrings.invoice.pricePerUnit'),
          t('globalStrings.invoice.quantity'),
          t('globalStrings.invoice.price'),
        ],
      ],
      body: invoiceDetails.items.map((item) => [
        item.description,
        item.quantity != '' ? item.amount / item.quantity + ' €' : '',
        item.quantity,
        item.amount + ' €',
      ]),
      styles: {
        font: 'Muli-Regular',
        fontSize: 10,
      },
      headStyles: {
        fillColor: '#ed6723',
        font: 'Muli-Bold',
        fontSize: 12,
      },
      columnStyles: {
        0: { halign: 'left' },
        1: { halign: 'right' },
        2: { halign: 'right' },
        3: { halign: 'right' },
      },
      didParseCell: function (data) {
        if (data.section === 'head') {
          if (data.column.index === 0) {
            data.cell.styles.halign = 'left';
          } else if (
            data.column.index === 1 ||
            data.column.index === 2 ||
            data.column.index === 3
          ) {
            data.cell.styles.halign = 'right';
          }
        }
      },
    });

    // Bank details
    const bankDetails = `${t('globalStrings.invoice.bankDetails')}`;
    doc.setFont('Muli-Bold', 'normal');
    doc.text(bankDetails, 15, (doc as any).lastAutoTable.finalY + 20);
    doc.setFont('Muli-Regular', 'normal');
    doc.text(
      'CIC ALPES-MARITIMES ENTREPRISES',
      15,
      (doc as any).lastAutoTable.finalY + 30
    );
    doc.text('3 rue de la Liberté', 15, (doc as any).lastAutoTable.finalY + 40);
    doc.text('06000 Nice', 15, (doc as any).lastAutoTable.finalY + 45);
    doc.text('France', 15, (doc as any).lastAutoTable.finalY + 50);
    doc.text(
      `${t('globalStrings.invoice.iban')}FR7610096185830005329940189`,
      15,
      (doc as any).lastAutoTable.finalY + 60
    );
    doc.text(
      `${t('globalStrings.invoice.bic')}CMCIFRPP`,
      15,
      (doc as any).lastAutoTable.finalY + 65
    );

    let companyCountry: string = rVatNumber.slice(0, 2);

    // window.location.href.split('/')[5]

    let myVatValue = 0;
    let myMention = '';
    if (companyCountry === 'FR') {
      myVatValue = 20;
    } else {
      for (var i = 0; i < euCountries.length; i++) {
        if (companyCountry === euCountries[i].countryCode) {
          myMention = t('orderPageStrings.commandDetails.reverseChargeMention');
          break;
        } else {
          myMention = t(
            'orderPageStrings.commandDetails.vatNotApplicableMention'
          );
        }
      }
    }

    // Order amount
    const totalExludingVat = `${t(
      'orderPageStrings.commandDetails.totalExclVat'
    )} ${invoiceDetails.total} €`;
    const vatAmount = `${t(
      'orderPageStrings.commandDetails.vatAmount'
    )} (${myVatValue}%)${t('globalStrings.invoice.punctuation')} ${(
      (Number(amount) * myVatValue) /
      100
    ).toFixed(2)} €`;
    const totalIncludingVat = `${t(
      'orderPageStrings.commandDetails.totalInclVat'
    )} ${(Number(amount) + (Number(amount) * myVatValue) / 100).toFixed(2)} €`;

    doc.setFontSize(6);
    doc.setTextColor('#808080');
    doc.text(myMention, 15, (doc as any).lastAutoTable.finalY + 5);
    doc.setFontSize(10);
    doc.setTextColor('#000000');
    doc.text(
      totalExludingVat,
      pageWidth - doc.getTextWidth(totalExludingVat) - 15,
      (doc as any).lastAutoTable.finalY + 20
    );
    doc.text(
      vatAmount,
      pageWidth - doc.getTextWidth(vatAmount) - 15,
      (doc as any).lastAutoTable.finalY + 25
    );
    doc.setFont('Muli-Bold', 'normal');
    doc.setFontSize(12);
    doc.text(
      totalIncludingVat,
      pageWidth - doc.getTextWidth(totalIncludingVat) - 15,
      (doc as any).lastAutoTable.finalY + 35
    );

    // Footer
    const footerText =
      'ASD France - 310 rue du Vallon – BP 203 – 06904 Sophia Antipolis Cedex\nwww.asd-int.com | www.myasd.com | 04.92.38.08.05\nRCS Grasse - SIRET B 429 463 342 00049 - APE 8299Z – SARL au capital de 800 000€';
    const footerLogoWidth = 25;
    const footerLogoHeight = 25;
    const footerWidth = calculateMaxTextWidth(doc, footerText);
    const footerCentered = (pageWidth - footerWidth) / 2;

    doc.setFontSize(8);
    doc.setFont('Muli-Regular', 'normal');
    doc.line(10, pageHeight - 20, pageWidth - 10, pageHeight - 20);

    doc.addImage(
      groupLogo,
      'PNG',
      footerCentered + footerLogoWidth / 1.5,
      pageHeight - 22,
      footerLogoWidth,
      footerLogoHeight
    );
    doc.setTextColor(0, 0, 0);
    doc.text(
      footerText,
      footerCentered + 1.75 * footerLogoWidth,
      pageHeight - 12
    );

    // Save file
    setInvoiceNumber(invoiceNumber);
    const pdfData = doc.output('blob');
    return new File([pdfData], invoiceNumber + '.pdf', {
      type: 'application/pdf',
    });

    // doc.save(invoiceNumber + '.pdf');
  }

  async function createInvoiceDocumentOnS3(missionId: number) {
    setIsLoading(true);
    let invoiceNumber = await createInvoiceReference();
    let pricingPolicy = await getPricingPolicy();
    let generateFile = await generatePDF(pricingPolicy, invoiceNumber);
    const filePath = `invoice_mission_${missionId}/${invoiceNumber}`;
    const formData = new FormData();
    let newFile = uniqid('pdf');
    formData.append('file', generateFile, newFile);
    formData.append('filePath', filePath);

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateEmployee/uploadEmployeeDocuments`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(await createInvoice(invoiceNumber, missionId, newFile));

      window.sessionStorage.setItem('fileUpload', 'true');
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  /////////////////////////////////////////////////

  const handleClickMissionHasBeenPaid = async () => {
    await createInvoiceDocumentOnS3(missionId).then(() =>
      updateMissionIsPaid()
    );

    window.location.reload();
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);
  const [sector, setSector] = useState<string>();
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();
  const [type, setType] = useState<string>();
  const [numberOfEmployees, setNumberOfEmployees] = useState<number>();
  const [numberOfCards, setNumberOfCards] = useState<number>();
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [personInCharge, setPersonInCharge] = useState<string>();
  const [lodgingId, setLodgingId] = useState<string>();
  const [locationId, setLocationId] = useState<string>();

  const handleStartDateChange = (date: any) => {
    setStart(date);
  };

  const handleEndDateChange = (date: any) => {
    setEnd(date);
  };

  const handleNumberOfEmployeesChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionNumber = event.target.value;

    setNumberOfEmployees(Number(newSelectedOptionNumber));
  };

  const [companyId, setCompanyId] = useState<number>();
  const [hasFrench, setHasFrench] = useState<boolean>(true);
  const [hasEnglish, setHasEnglish] = useState<boolean>(false);
  const [hasBulgarian, setHasBulgarian] = useState<boolean>(false);
  const [hasCzech, setHasCzech] = useState<boolean>(false);
  const [hasGerman, setHasGerman] = useState<boolean>(false);
  const [hasItalian, setHasItalian] = useState<boolean>(false);
  const [hasSpanish, setHasSpanish] = useState<boolean>(false);
  const [hasPortuguese, setHasPortuguese] = useState<boolean>(false);
  const [hasPolish, setHasPolish] = useState<boolean>(false);
  const [hasRomanian, setHasRomanian] = useState<boolean>(false);
  const [withSipsi, setWithSipsi] = useState<boolean>(false);
  const [withConstructionCard, setWithConstructionCard] =
    useState<boolean>(false);
  const [s3Path, setS3Path] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const [updateAmount, setUpdateAmount] = useState<number>();
  const [
    numberOfConstructionCardToCreate,
    setNumberOfConstructionCardToCreate,
  ] = useState<number>(0);

  async function getCompanyDetails(rCompanyId: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getCompanyDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: rCompanyId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        return JSON.stringify(result.data.vatOrRegistrationNumber);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  const [missionId, setMissionId] = useState<number>();

  async function getMissionDetails() {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getMissionDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ reference: reference }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setCompanyId(result.data.companyId);

        let vatNumber = await getCompanyDetails(result.data.companyId);
        setSector(result.data.sector);
        setFrom(result.data.countryFrom);
        setTo(result.data.countryTo);
        setType(result.data.type);
        setNumberOfEmployees(result.data.numberOfEmployees);
        setNumberOfCards(result.data.numberOfCardsToCreate);
        setStart(result.data.startDate);
        setEnd(result.data.endDate);
        setPersonInCharge(result.data.personInCharge);
        setLodgingId(result.data.lodgingId);
        setLocationId(result.data.locationId);
        setPaid(result.data.paid);
        setHasFrench(result.data.frenchDocument);
        setHasEnglish(result.data.englishDocument);
        setHasBulgarian(result.data.bulgarianDocument);
        setHasCzech(result.data.czechDocument);
        setHasGerman(result.data.germanDocument);
        setHasItalian(result.data.italianDocument);
        setHasSpanish(result.data.spanishDocument);
        setHasPortuguese(result.data.portugueseDocument);
        setHasPolish(result.data.polishDocument);
        setHasRomanian(result.data.romanianDocument);
        setMissionId(result.data.missionId);
        setWithSipsi(result.data.withSipsi);
        setWithConstructionCard(result.data.withConstructionCard);
        setNumberOfConstructionCardToCreate(result.data.numberOfCardsToCreate);

        console.log(vatNumber.slice(1, 3));

        let myVatValue = 0;
        if (vatNumber.slice(1, 3) === 'FR') {
          myVatValue = 20;
        }

        let amount = result.data.amount;
        setAmount(Number(amount));
        console.log(amount);
        console.log(myVatValue);

        const updateAmount = (
          Number(amount) +
          (Number(amount) * myVatValue) / 100
        ).toFixed(2);
        setUpdateAmount(Number(updateAmount));

        await getLodgingDetails();
        await getLocationDetails();
        await getInvoiceDetails(result.data.missionId);

        return JSON.stringify(result.data.missionId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getInvoiceDetails(missionId: number) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getInvoiceRelatedToMission`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ missionId: missionId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setS3Path(result.data.s3Path);
        return JSON.stringify(result.data);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  const handleDownloadFRDocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=FR_2024.pdf'
    );
  };

  const handleDownloadENDocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=EN_2024.pdf'
    );
  };

  const handleDownloadBGDocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=BG_2024.pdf'
    );
  };

  const handleDownloadCZDocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=CZ_2024.pdf'
    );
  };

  const handleDownloadDEDocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=DE_2024.pdf'
    );
  };

  const handleDownloadITDocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=IT_2024.pdf'
    );
  };

  const handleDownloadESDocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=ES_2024.pdf'
    );
  };

  const handleDownloadPTDocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=PT_2024.pdf'
    );
  };

  const handleDownloadPLDocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=PL_2024.pdf'
    );
  };

  const handleDownloadRODocuments = () => {
    window.open(
      'https://nc.asd-int.com/s/kegzHZQgyxJEWXW/download?path=%2F&files=RO_2024.pdf'
    );
  };

  async function handleDownloadInvoice(filePath: string) {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/downloadMissionDocument`,
        { filePath }, // Envoyer le nom du fichier dans le corps de la requête
        {
          responseType: 'blob', // Attendre une réponse de type blob (fichier binaire)
        }
      );
      const fileType = response.headers['content-type']; // Récupérer le type MIME depuis les headers
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: fileType })
      );

      setIsLoading(false);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error during file download:', error);
    }
  }

  useEffect(() => {
    async function exec() {
      await getMissionDetails();
    }
    exec().then(() => {
      setIsLoading(false);
    });
  }, [lodgingId, locationId]);

  const formattedDate = (dataString: string): string => {
    let dateObject = new Date(dataString);

    let day = dateObject.getDate();
    let month = dateObject.getMonth() + 1;
    let year = dateObject.getFullYear();

    let formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    return formattedDate;
  };

  const updateDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateMission/details`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            reference: reference,
            startDate: start,
            endDate: end,
            numberOfEmployees: numberOfEmployees,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const execUpdateDetails = async () => {
    setIsLoading(true);

    const result = await updateDetails();

    return result;
  };

  const handleClickSaveDetails = () => {
    if (
      start == undefined ||
      end == undefined ||
      numberOfEmployees == undefined
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execUpdateDetails();
      setIsLoading(false);
      window.location.reload();
    }
  };

  const [lodgingStart, setLodgingStart] = useState<string>();
  const [lodgingEnd, setLodgingEnd] = useState<string>();
  const [lodgingType, setLodgingType] = useState<string>();
  const [lodgingTypeString, setLodgingTypeString] = useState<string>();
  const [lodgingMainAddress, setLodgingMainAddress] = useState<string>();
  const [lodgingAdditionalAddress, setLodgingAdditionalAddress] =
    useState<string>();
  const [lodgingPostalCode, setLodgingPostalCode] = useState<string>();
  const [lodgingCity, setLodgingCity] = useState<string>();
  const [lodgingCountry, setLodgingCountry] = useState<string>();
  const [locationName, setLocationName] = useState<string>();
  const [locationMainAddress, setLocationMainAddress] = useState<string>();
  const [locationAdditionalAddress, setLocationAdditionalAddress] =
    useState<string>();
  const [locationPostalCode, setLocationPostalCode] = useState<string>();
  const [locationCity, setLocationCity] = useState<string>();
  const [locationCountry, setLocationCountry] = useState<string>();

  async function getLodgingDetails() {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getLodgingDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: lodgingId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setLodgingStart(result.data.startDate);
        setLodgingEnd(result.data.endDate);
        setLodgingType(result.data.type);

        let lodgingOptions = [
          {
            id: 1,
            value: t('popupStrings.missionDetailsPopupStrings.apartment'),
          },
          {
            id: 2,
            value: t('popupStrings.missionDetailsPopupStrings.camping'),
          },
          { id: 3, value: t('popupStrings.missionDetailsPopupStrings.hotel') },
          { id: 4, value: t('popupStrings.missionDetailsPopupStrings.other') },
        ];
        setLodgingTypeString(
          lodgingOptions[Number(result.data.type) - 1].value
        );

        console.log(lodgingTypeString);

        await getLodgingAddress(result.data.addressId);

        return JSON.stringify(result.data.lodgingId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getLocationDetails() {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getLocationDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: locationId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setLocationName(result.data.name);

        await getLocationAddress(result.data.addressId);

        return JSON.stringify(result.data.lodgingId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getLodgingAddress(id: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAddress`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: id }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setLodgingMainAddress(result.data.mainAddress);
        setLodgingAdditionalAddress(result.data.additionalAddress);
        setLodgingPostalCode(result.data.postalCode);
        setLodgingCity(result.data.city);
        setLodgingCountry(result.data.country);

        return JSON.stringify(result.data.addressId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getLocationAddress(id: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAddress`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: id }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setLocationMainAddress(result.data.mainAddress);
        setLocationAdditionalAddress(result.data.additionalAddress);
        setLocationPostalCode(result.data.postalCode);
        setLocationCity(result.data.city);
        setLocationCountry(result.data.country);

        return JSON.stringify(result.data.addressId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  const handleClickOpenManagement = () => {
    console.log(id);
    window.location.href = `/spw/missions/${companyId}/details/${reference}/management`;
  };

  const handleDownloadRib = () => {
    const downloadUrl =
      'https://nc.asd-int.com/s/xx3isZFn9o36DYe/download?path=%2F&files=CIC_ASD_FRANCE.pdf';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.click();
  };

  return (
    <>
      {(isLoading && <Loader />) ||
        (!isLoading && (
          <>
            <div className='missionDetailsContainer'>
              <div className='informationContainer'>
                <div className='takeInChargeButtonContainer'>
                  {adminOrOperator && (
                    <div className='operatorButtons'>
                      <button
                        className='takeInChargeButton'
                        onClick={handleClickTakeInCharge}
                      >
                        {t('globalStrings.takeInCharge')}
                      </button>
                      {!paid && (
                        <button
                          className='takeInChargeButton'
                          onClick={handleClickMissionHasBeenPaid}
                        >
                          {t('missionDetailsPageStrings.missionHasBeenPaid')}
                        </button>
                      )}
                    </div>
                  )}
                  {hasFrench && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadFRDocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' FR'}
                    </button>
                  )}
                  {hasEnglish && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadENDocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' EN'}
                    </button>
                  )}
                  {hasBulgarian && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadBGDocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' BG'}
                    </button>
                  )}
                  {hasCzech && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadCZDocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' CZ'}
                    </button>
                  )}
                  {hasGerman && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadDEDocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' DE'}
                    </button>
                  )}
                  {hasItalian && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadITDocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' IT'}
                    </button>
                  )}
                  {hasSpanish && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadESDocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' ES'}
                    </button>
                  )}
                  {hasPortuguese && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadPTDocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' PT'}
                    </button>
                  )}
                  {hasPolish && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadPLDocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' PL'}
                    </button>
                  )}
                  {hasRomanian && (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadRODocuments}
                    >
                      {t('missionDetailsPageStrings.download') + ' RO'}
                    </button>
                  )}
                  {paid ? (
                    <button
                      className='informationDocumentButton'
                      onClick={() => handleDownloadInvoice(s3Path)}
                    >
                      {t('invoiceStrings.getInvoice')}
                    </button>
                  ) : (
                    <button
                      className='informationDocumentButton'
                      onClick={handleDownloadRib}
                    >
                      {t('invoiceStrings.getRib')}
                    </button>
                  )}
                </div>
                <div className='globalAndOperatorInformationContainer'>
                  <div className='globalInformationContainer'>
                    <h4>
                      {t('missionDetailsPageStrings.globalInformationTitle')}
                    </h4>
                    <div className='globalDetailsText'>
                      <div className='textArea'>
                        <p>
                          <b>
                            {t(
                              'missionPageStrings.globalInformation.reference'
                            )}
                          </b>
                          {reference}
                        </p>
                        <p>
                          <b>
                            {t('missionPageStrings.globalInformation.sector')}
                          </b>
                          {sector == 'Construction'
                            ? t('orderPageStrings.missionSector.construction')
                            : t('orderPageStrings.missionSector.other')}
                        </p>
                        <p>
                          <b>
                            {t(
                              'missionPageStrings.globalInformation.countryFrom'
                            )}
                          </b>
                          {from}
                        </p>
                        <p>
                          <b>
                            {t(
                              'missionPageStrings.globalInformation.countryTo'
                            )}
                          </b>
                          {to}
                        </p>
                        <p>
                          <b>
                            {t(
                              'missionPageStrings.globalInformation.missionType'
                            )}
                          </b>
                          {t('orderPageStrings.missionType.customMade')}
                        </p>
                      </div>
                      <div className='textArea'>
                        <p>
                          <b>
                            {t(
                              'missionPageStrings.globalInformation.employees'
                            )}
                          </b>
                          {numberOfEmployees}
                        </p>
                        <p>
                          <b>
                            {t(
                              'missionPageStrings.globalInformation.startDate'
                            )}
                          </b>
                          {formattedDate(start)}
                        </p>
                        <p>
                          <b>
                            {t('missionPageStrings.globalInformation.endDate')}
                          </b>
                          {formattedDate(end)}
                        </p>
                        <p>
                          <b>
                            {t('missionPageStrings.globalInformation.amount')}
                          </b>
                          {`${updateAmount} € - `}
                          {paid ? (
                            <i className='paid'>
                              {t('missionPageStrings.globalInformation.paid')}
                            </i>
                          ) : (
                            <i className='notPaid'>
                              {t(
                                'missionPageStrings.globalInformation.notPaid'
                              )}
                            </i>
                          )}
                        </p>
                        <p>
                          <b>
                            {t(
                              'missionPageStrings.globalInformation.personInCharge'
                            )}
                          </b>
                          {personInCharge == undefined ? (
                            <i>{t('globalStrings.onHold')}</i>
                          ) : (
                            personInCharge
                          )}
                        </p>
                      </div>
                    </div>
                    {(user.operator || user.admin) && (
                      <EditButton
                        popupVisible={globalInformationEditPopupIsVisible}
                        myRef={globalInformationButtonRef}
                        popupRef={globalInformationEditPopupRef}
                        title={t(
                          'popupStrings.missionDetailsPopupStrings.globalTitle'
                        )}
                        action={handleClickEditGlobalInformationButton}
                        children={
                          <>
                            <div ref={childRefGlobal}>
                              <div className='globalInformationPopUp'>
                                <div className='globalInformationPopUpField'>
                                  <p>
                                    <b>
                                      {t(
                                        'popupStrings.missionDetailsPopupStrings.startDate'
                                      )}
                                    </b>
                                  </p>
                                  <DatePicker
                                    value={start || ''}
                                    className='globalInformationSelectorDate'
                                    format='dd/MM/yyyy'
                                    onChange={handleStartDateChange}
                                    locale={
                                      currentLanguage
                                        .toUpperCase()
                                        .split('-')[0]
                                    }
                                  />
                                </div>
                                <div className='globalInformationPopUpField'>
                                  <p>
                                    <b>
                                      {t(
                                        'popupStrings.missionDetailsPopupStrings.endDate'
                                      )}
                                    </b>
                                  </p>
                                  <DatePicker
                                    value={end || ''}
                                    className='globalInformationSelectorDate'
                                    format='dd/MM/yyyy'
                                    onChange={handleEndDateChange}
                                    locale={
                                      currentLanguage
                                        .toUpperCase()
                                        .split('-')[0]
                                    }
                                  />
                                </div>
                                <div className='globalInformationPopUpField'>
                                  <p>
                                    <b>
                                      {t(
                                        'popupStrings.missionDetailsPopupStrings.numberOfEmployees'
                                      )}
                                    </b>
                                  </p>
                                  <select
                                    id='select'
                                    ref={numberOfEmployeesSelectorRef}
                                    className='globalInformationSelectorDate'
                                    onClick={() =>
                                      setIsOpenEmployees(!isOpenEmployees)
                                    }
                                    value={numberOfEmployees || ''}
                                    onChange={handleNumberOfEmployeesChange}
                                  >
                                    <option
                                      key='defaultNumberOfEmployees'
                                      value=''
                                    >
                                      {t('---')}
                                    </option>
                                    {numbers.map((number) => (
                                      <option key={number} value={number}>
                                        {number}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className='space'></div>
                            {!isErrorHidden && (
                              <div className='companyError'>
                                <p>{t('orderPageStrings.error')}</p>
                              </div>
                            )}
                            <div className='saveButtonContainer'>
                              <button
                                className='saveButton'
                                onClick={handleClickSaveDetails}
                              >
                                <p>{t('popupStrings.saveText')}</p>
                                <img
                                  src={saveLogo}
                                  alt={t('popupStrings.saveLogo')}
                                  className='saveLogo'
                                />
                              </button>
                            </div>
                          </>
                        }
                      />
                    )}
                  </div>
                  <div className='operatorContainer'>
                    <div className='textArea'>
                      <h4>{t('missionDetailsPageStrings.managementTitle')}</h4>
                      <p>{t('missionDetailsPageStrings.managementText')}</p>
                      <div className='textAreaButtonContainer'>
                        <button
                          className='textAreaButton'
                          onClick={handleClickOpenManagement}
                        >
                          {t('missionDetailsPageStrings.managementButton')}
                        </button>
                      </div>
                      {/* <h4>{t('missionDetailsPageStrings.payementTitle')}</h4>
                      {paid ? (
                        <p>
                          {t('missionDetailsPageStrings.payementSecondText')}
                        </p>
                      ) : (
                        <p>
                          {t('missionDetailsPageStrings.payementFirstText')}
                        </p>
                      )} */}
                      {/* <div className='textAreaButtonContainer'>
                        {!paid && (
                          <i>
                            Vous devez régler par virement le montant de la
                            mission
                          </i>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className='otherInformationContainer'>
                  <div className='locationInformation'>
                    <div className='locationInformationHeader'>
                      <h4>
                        {t(
                          'missionDetailsPageStrings.locationInformationTitle'
                        )}
                      </h4>
                      <img
                        src={tooltip}
                        alt='tooltipButton'
                        className='companyTooltip'
                        ref={locationTooltipRef}
                      />
                    </div>
                    <div className='textArea'>
                      <p>
                        <b>{t('locationDetailsPageStrings.name')}</b>
                        {locationName}
                      </p>
                      <p>
                        <b>{t('employeesDetailsPageStrings.mainAddress')}</b>
                        {locationMainAddress}
                      </p>
                      <p>
                        <b>
                          {t('employeesDetailsPageStrings.additionalAddress')}
                        </b>
                        {locationAdditionalAddress == '' ? (
                          <i>Not defined</i>
                        ) : (
                          locationAdditionalAddress
                        )}
                      </p>
                      <p>
                        <b>{t('employeesDetailsPageStrings.postalCode')}</b>
                        {locationPostalCode}
                      </p>
                      <p>
                        <b>{t('employeesDetailsPageStrings.city')}</b>
                        {locationCity}
                      </p>
                      <p>
                        <b>{t('employeesDetailsPageStrings.country')}</b>
                        {locationCountry}
                      </p>
                    </div>
                    {(user.operator || user.admin) && (
                      <EditButton
                        popupVisible={employeesEditPopupIsVisible}
                        myRef={employeesButtonRef}
                        popupRef={employeesEditPopupRef}
                        title={t(
                          'popupStrings.missionDetailsPopupStrings.employeesTitle'
                        )}
                        action={handleClickEditEmployeesButton}
                        children={
                          <LocationMissionDetails
                            ref={childRefLocation}
                            id={Number(locationId)}
                            defaultName={locationName}
                            defaultMainAddress={locationMainAddress}
                            defaultAdditionalAddress={locationAdditionalAddress}
                            defaultPostalCode={locationPostalCode}
                            defaultCity={locationCity}
                            defaultCountry={locationCountry}
                          />
                        }
                      />
                    )}
                  </div>
                  <div className='lodgingInformation'>
                    <div className='lodgingInformationHeader'>
                      <h4>
                        {t('missionDetailsPageStrings.lodgingInformationTitle')}
                      </h4>
                      <img
                        src={tooltip}
                        alt='tooltipButton'
                        className='companyTooltip'
                        ref={lodgingTooltipRef}
                      />
                    </div>
                    <div className='lodgingText'>
                      <div className='textArea'>
                        <p>
                          <b>{t('employeesDetailsPageStrings.mainAddress')}</b>
                          {lodgingMainAddress}
                        </p>
                        <p>
                          <b>
                            {t('employeesDetailsPageStrings.additionalAddress')}
                          </b>
                          {lodgingAdditionalAddress == '' ? (
                            <i>Not defined</i>
                          ) : (
                            lodgingAdditionalAddress
                          )}
                        </p>
                        <p>
                          <b>{t('employeesDetailsPageStrings.postalCode')}</b>
                          {lodgingPostalCode}
                        </p>
                        <p>
                          <b>{t('employeesDetailsPageStrings.city')}</b>
                          {lodgingCity}
                        </p>
                        <p>
                          <b>{t('employeesDetailsPageStrings.country')}</b>
                          {lodgingCountry}
                        </p>
                      </div>
                      <div className='textArea'>
                        <p>
                          <b>
                            {t(
                              'missionPageStrings.globalInformation.startDate'
                            )}
                          </b>
                          {formattedDate(lodgingStart)}
                        </p>
                        <p>
                          <b>
                            {t('missionPageStrings.globalInformation.endDate')}
                          </b>
                          {formattedDate(lodgingEnd)}
                        </p>
                        <p>
                          <b>
                            {t(
                              'missionPageStrings.globalInformation.lodgingType'
                            )}
                          </b>
                          {lodgingTypeString}
                        </p>
                      </div>
                    </div>
                    {(user.operator || user.admin) && (
                      <EditButton
                        popupVisible={lodgingEditPopupIsVisible}
                        myRef={lodgingButtonRef}
                        popupRef={lodgingEditPopupRef}
                        title={t(
                          'popupStrings.missionDetailsPopupStrings.lodgingTitle'
                        )}
                        action={handleClickEditLodgingButton}
                        children={
                          <LodgingMissionDetails
                            ref={childRefLodging}
                            id={Number(lodgingId)}
                            defaultStartDate={lodgingStart}
                            defaultEndDate={lodgingEnd}
                            defaultType={lodgingType}
                            defaultMainAddress={lodgingMainAddress}
                            defaultAdditionalAddress={lodgingAdditionalAddress}
                            defaultPostalCode={lodgingPostalCode}
                            defaultCity={lodgingCity}
                            defaultCountry={lodgingCountry}
                          />
                        }
                      />
                    )}
                  </div>
                </div>
              </div>

              {inspectionPopupVisible ? (
                <Popup
                  myRef={inspectionPopupRef}
                  title={t('createInspection.title')}
                  onClickClose={handleClickCloseInspectionPopup}
                >
                  {<CreateInspection id={id} reference={reference} />}
                </Popup>
              ) : null}
            </div>
          </>
        ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(MissionDetails);
