import React from "react";
import { getIn, ErrorMessage } from "formik";
import { MonthInput } from "semantic-ui-calendar-react";

import { BorderRadius, Wrapper } from "./styles";
import { Error } from "components/Form";

const Index = ({ meta, placeholder, field, label, margin }: any) => {
  return (
    <Wrapper margin={margin}>
      <label>{label}</label>
      <BorderRadius>
        <MonthInput
          closable
          closeOnMouseLeave
          placeholder={placeholder}
          name={field.name}
          onChange={(e, { name, value }) => {
            meta.setFieldValue(name, value);
          }}
          onBlur={field.onBlur}
          value={field.value}
          error={
            getIn(meta.touched, field.name) && getIn(meta.errors, field.name)
              ? true
              : false
          }
        />
      </BorderRadius>


      <ErrorMessage name={field.name} render={(msg) => <Error msg={msg} />} />
    </Wrapper>
  );
};

export default Index;
