// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import DashboardInformationProperties from '../../interfaces/dashboardInformationProperties';

// Stylesheets
import './dashboard.css';

/**
 * @name dashboardInformation.tsx
 * @implements dashboardProperties.tsx
 *
 * @path /components/dashboard/dashboardInformation.tsx
 *
 * @description Defines the content of each box into homepage's dashboard
 *
 * @param title missions' filter associated to the box
 * @param value number of missions associated to missions' filter in the box
 * @param informationAction what's executed when user performed a click on the button
 */
const DashboardInformation: React.FC<DashboardInformationProperties> = ({
  title,
  value,
  informationAction,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  return (
    <div className='dashboardContainerInformation'>
      <h1>{value}</h1>
      <button className='informationButton' onClick={informationAction}>
        {t(title)}
      </button>
    </div>
  );
};

export default DashboardInformation;
