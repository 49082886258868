import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    getAllContacts: false,
    getOneContact: false,
    createContact: false,
    addContact: false,
    deleteContact: false,
    validateContact: false,
    refuseContact: false,
    getCompaniesNotInContact: false,
    getPendingContact: false,
    getRequestContact: false,
    getAllContactTransporters: false,
    partners: false,
    checkedpartner: false,
    addContactRights: false,
    getContactRights: false,
    getAllContactRights: false,
    getBothContacts: false,
  },
  errors: {
    getAllContacts: null,
    getOneContact: null,
    createContact: null,
    addContact: null,
    deleteContact: null,
    validateContact: null,
    refuseContact: null,
    getCompaniesNotInContact: null,
    getPendingContact: null,
    getRequestContact: null,
    getAllContactTransporters: null,
    partners: null,
    checkedpartner: null,
    addContactRights: null,
    getContactRights: null,
    getAllContactRights: null,
    getBothContacts: null,
  },
  loading: {
    getAllContacts: false,
    getOneContact: false,
    createContact: false,
    addContact: false,
    deleteContact: false,
    validateContact: false,
    refuseContact: false,
    getCompaniesNotInContact: false,
    getPendingContact: false,
    getRequestContact: false,
    getAllContactTransporters: false,
    partners: false,
    checkedpartner: false,
    addContactRights: false,
    getContactRights: false,
    getAllContactRights: false,
    getBothContacts: false,
  },
  contacts: null,
  contact: null,
  companiesNotInContact: null,
  pendingContact: null,
  requestContact: null,
  contactsTransporters: null,
  partners: null,
  checkedpartner: null,
  addContactRights: null,
  getContactRights: null,
  getAllContactRights: null,
  getBothContacts: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

     // add contacts rights
     case actionTypes.GET_BOTH_CONTACTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getBothContacts = true;
      });
    case actionTypes.GET_BOTH_CONTACTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getBothContacts = null;
        draft.loading.getBothContacts = false;
        draft.success.getBothContacts = true;
        draft.getBothContacts = action.payload;
      });
    case actionTypes.GET_BOTH_CONTACTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getBothContacts = true;
        draft.loading.getBothContacts = false;
        draft.success.getBothContacts = false;
        !draft.error.includes("getBothContacts") && draft.error.push("getBothContacts");
      });

    // add contacts rights
    case actionTypes.GET_ALL_CONTACT_RIGHTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllContactRights = true;
      });
    case actionTypes.GET_ALL_CONTACT_RIGHTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllContactRights = null;
        draft.loading.getAllContactRights = false;
        draft.success.getAllContactRights = true;
        draft.getAllContactRights = action.payload;
      });
    case actionTypes.GET_ALL_CONTACT_RIGHTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllContactRights = true;
        draft.loading.getAllContactRights = false;
        draft.success.getAllContactRights = false;
        !draft.error.includes("getAllContactRights") && draft.error.push("getAllContactRights");
      });


     // add contacts rights
     case actionTypes.ADD_CONTACT_RIGHTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.addContactRights = true;
      });

    case actionTypes.ADD_CONTACT_RIGHTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addContactRights = null;
        draft.loading.addContactRights = false;
        draft.success.addContactRights = true;
        draft.addContactRights = action.payload;
      });

    case actionTypes.ADD_CONTACT_RIGHTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.addContactRights = true;
        draft.loading.addContactRights = false;
        draft.success.addContactRights = false;
        !draft.error.includes("addContactRights") && draft.error.push("addContactRights");
      });

     // get contacts rights
     case actionTypes.GET_CONTACT_RIGHTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getContactRights = true;
      });

    case actionTypes.GET_CONTACT_RIGHTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getContactRights = null;
        draft.loading.getContactRights = false;
        draft.success.getContactRights = true;
        draft.getContactRights = action.payload;
      });

    case actionTypes.GET_CONTACT_RIGHTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getContactRights = true;
        draft.loading.getContactRights = false;
        draft.success.getContactRights = false;
        !draft.error.includes("getContactRights") && draft.error.push("getContactRights");
      });

    // Get all contacts
    case actionTypes.GET_ALL_CONTACTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllContacts = true;
      });

    case actionTypes.GET_ALL_CONTACTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllContacts = null;
        draft.loading.getAllContacts = false;
        draft.success.getAllContacts = true;
        draft.contacts = action.payload;
      });

    case actionTypes.GET_ALL_CONTACTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllContacts = true;
        draft.loading.getAllContacts = false;
        draft.success.getAllContacts = false;
        !draft.error.includes("contacts") && draft.error.push("contacts");
      });

    // Get one contact
    case actionTypes.GET_ONE_CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneContact = true;
      });

    case actionTypes.GET_ONE_CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneContact = null;
        draft.loading.getOneContact = false;
        draft.success.getOneContact = true;
        draft.contact = action.payload;
      });

    case actionTypes.GET_ONE_CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneContact = true;
        draft.loading.getOneContact = false;
        draft.success.getOneContact = false;
        !draft.error.includes("contact") && draft.error.push("contact");
      });

    // Create contact
    case actionTypes.CREATE_CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.createContact = true;
      });

    case actionTypes.CREATE_CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createContact = null;
        draft.loading.createContact = false;
        draft.success.createContact = true;
      });

    case actionTypes.CREATE_CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.createContact = true;
        draft.loading.createContact = false;
        draft.success.createContact = false;
        !draft.error.includes("createContact") &&
          draft.error.push("createContact");
      });

    // Add contact
    case actionTypes.ADD_CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.addContact = true;
      });

    case actionTypes.ADD_CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addContact = null;
        draft.loading.addContact = false;
        draft.success.addContact = true;
        draft.contact = action.payload;
      });

    case actionTypes.ADD_CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.addContact = true;
        draft.loading.addContact = false;
        draft.success.addContact = false;
        !draft.error.includes("addContact") && 
        draft.error.push("addContact");
      });

       // Add contact partner
    case actionTypes.ADD_CONTACT_PARTNER_LOADING:
      return produce(state, (draft) => {
        draft.loading.partners = true;
      });

    case actionTypes.ADD_CONTACT_PARTNER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.partners = null;
        draft.loading.partners = false;
        draft.success.partners = true;
        draft.contact = action.payload;
      });

    case actionTypes.ADD_CONTACT_PARTNER_ERROR:
      return produce(state, (draft) => {
        draft.errors.partners = true;
        draft.loading.partners = false;
        draft.success.partners = false;
        !draft.error.includes("partners") && draft.error.push("partners");
      });

         // validate contact partner
    case actionTypes.VALIDATE_CONTACT_PARTNER_LOADING:
      return produce(state, (draft) => {
        draft.loading.checkedpartner = true;
      });

    case actionTypes.VALIDATE_CONTACT_PARTNER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.checkedpartner = null;
        draft.loading.checkedpartner = false;
        draft.success.checkedpartner = true;
        draft.checkedpartner = action.payload;
      });

    case actionTypes.VALIDATE_CONTACT_PARTNER_ERROR:
      return produce(state, (draft) => {
        draft.errors.checkedpartner = true;
        draft.loading.checkedpartner = false;
        draft.success.checkedpartner = false;
        !draft.error.includes("checkedpartner") && draft.error.push("checkedpartner");
      });

    // Delete contact
    case actionTypes.DELETE_CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.deleteContact = true;
      });

    case actionTypes.DELETE_CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteContact = null;
        draft.loading.deleteContact = false;
        draft.success.deleteContact = true;
      });

    case actionTypes.DELETE_CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteContact = true;
        draft.loading.deleteContact = false;
        draft.success.deleteContact = false;
        !draft.error.includes("deleteContact") &&
          draft.error.push("deleteContact");
      });

    // Validate Contact
    case actionTypes.VALIDATE_CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.validateContact = true;
      });

    case actionTypes.VALIDATE_CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.validateContact = null;
        draft.loading.validateContact = false;
        draft.success.validateContact = true;
      });

    case actionTypes.VALIDATE_CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.validateContact = true;
        draft.loading.validateContact = false;
        draft.success.validateContact = false;
        !draft.error.includes("validateContact") &&
          draft.error.push("validateContact");
      });

    // Refuse contact
    case actionTypes.REFUSE_CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.refuseContact = true;
      });

    case actionTypes.REFUSE_CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.refuseContact = null;
        draft.loading.refuseContact = false;
        draft.success.refuseContact = true;
      });

    case actionTypes.REFUSE_CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.refuseContact = true;
        draft.loading.refuseContact = false;
        draft.success.refuseContact = false;
        !draft.error.includes("refuseContact") &&
          draft.error.push("refuseContact");
      });

    // Get companies Not In Contact
    case actionTypes.GET_COMPANIES_NOT_IN_CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.getCompaniesNotInContact = true;
      });

    case actionTypes.GET_COMPANIES_NOT_IN_CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getCompaniesNotInContact = null;
        draft.loading.getCompaniesNotInContact = false;
        draft.success.getCompaniesNotInContact = true;
        draft.companiesNotInContact = action.payload;
      });

    case actionTypes.GET_COMPANIES_NOT_IN_CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.getCompaniesNotInContact = true;
        draft.loading.getCompaniesNotInContact = false;
        draft.success.getCompaniesNotInContact = false;
        !draft.error.includes("companiesNotInContact") &&
          draft.error.push("companiesNotInContact");
      });

    // Get pending contact
    case actionTypes.GET_PENDING_CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPendingContact = true;
      });

    case actionTypes.GET_PENDING_CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPendingContact = null;
        draft.loading.getPendingContact = false;
        draft.success.getPendingContact = true;
        draft.pendingContact = action.payload;
      });

    case actionTypes.GET_PENDING_CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPendingContact = true;
        draft.loading.getPendingContact = false;
        draft.success.getPendingContact = false;
        !draft.error.includes("pendingContact") &&
          draft.error.push("pendingContact");
      });

    // Get request contact
    case actionTypes.GET_REQUEST_CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.getRequestContact = true;
      });

    case actionTypes.GET_REQUEST_CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getRequestContact = null;
        draft.loading.getRequestContact = false;
        draft.success.getRequestContact = true;
        draft.requestContact = action.payload;
      });

    case actionTypes.GET_REQUEST_CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.getRequestContact = true;
        draft.loading.getRequestContact = false;
        draft.success.getRequestContact = false;
        !draft.error.includes("requestContact") &&
          draft.error.push("requestContact");
      });

    // Get All Contact Transporters
    case actionTypes.GET_ALL_CONTACT_TRANSPORTERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllContactTransporters = true;
      });

    case actionTypes.GET_ALL_CONTACT_TRANSPORTERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllContactTransporters = null;
        draft.loading.getAllContactTransporters = false;
        draft.success.getAllContactTransporters = true;
        draft.contactsTransporters = action.payload;
      });

    case actionTypes.GET_ALL_CONTACT_TRANSPORTERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllContactTransporters = true;
        draft.loading.getAllContactTransporters = false;
        draft.success.getAllContactTransporters = false;
        !draft.error.includes("contactsTransporters") &&
          draft.error.push("contactsTransporters");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
