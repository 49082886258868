import React from 'react';
import { Table } from 'semantic-ui-react';

const Index = ({ colNumber, message = 'Empty' }) => {
  return (
    <Table.Row>
      <Table.Cell collapsing warning />
      <Table.Cell colSpan={colNumber} warning textAlign="center" singleLine>
        {message}
      </Table.Cell>
      <Table.Cell collapsing warning />
    </Table.Row>
  );
};

export default Index;
