import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import {
  Divider,
  Grid,
  Header,
  Message,
  Segment,
  Table,
  Icon,
} from 'semantic-ui-react';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import EmptyTableSpecific from 'components/EmptyTableSpecifiic';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';
import ModalAddIntranetReference from '../components/ModalAddIntranetReference';
import ModalEditIntrastat from '../components/ModalEditIntrastat/ModalEditIntrastat';

import { longDate, shortDate } from 'services/helper/dateFormater';
import { ResponsiveWrapper, Specifics, SpecificsH4 } from './styles';
import { paths as pathToCompany } from 'routes/companies';
import { Link, useLocation} from 'react-router-dom';
import { FolderLink, FolderWrapper, Linked } from './styles';
import IntrastatChat from '../components/IntrastatChat/IntrastatChat';
import IntrastatNotes from '../components/IntrastatNotes';
import ModalCancelintrastat from '../components/ModalCancelIntrastat';
import IntrastatProgressBar from '../components/IntrastatProgressBar';
import Documents from '../components/IntrastatDocuments/Documents';
import ModalAddIntrastatDocuments from '../components/ModalAddIntrastatDocuments';
import ModalFilterTagManager from '../components/ModalFilterTagManager/ModalFilterTagManager';
import ModalSplitSingleFile from '../components/ModalSplitSingleFile/ModalSplitSingleFile';
import ModalCloseIntrastat from '../components/ModalCloseIntrastat/ModalCloseIntrastat';
import DocumentsClient from '../components/IntrastatDocuments/DocumentsClient';


const Index = ({
  dispatch,
  match,
  history,
  intrastat,
  intrastatLoading,
  chat,
  chatLoading,
  companySelected,
  user,
  deleteDocumentLoading,
  addDocumentsLoading,
  downloadLoading,
  intl
}) => {
  const intrastatId = match.params.intrastatId
  const userAdminOrOp = user.operator || user.admin;
  const location = useLocation();
  const isMontoring = (location.state && location.state.locPath.includes('monitoring')) || null
  const iso = match.params.iso
  const companyId = Number((userAdminOrOp || user.partnership) ? match.params.companyId : companySelected);
  const isOwner =  parseInt(match.params.companyId) === parseInt(companySelected);

  const [modalAddDocumentsStatus, setmodalAddDocumentsStatus] = useState({open: false, type: null});
  const [modalCancelintrastat, setmodalCancelintrastat] = useState(false);
  const [modalCloseintrastat, setmodalCloseintrastat] = useState(false);
  const [modalEditIntrastat, setmodalEditIntrastat] = useState(false);
  const [acceptedFiles, setAcceptedFiles]: any = useState(null);
  const [modalAddIntranetReference, setmodalAddIntranetReference] = useState(false);
  const [tagManagerModal, setTagManagerModal]: any = useState(null)
  const [tagFilterManagerModal, setTagFilterManagerModal]: any = useState(null)
  const [splitFileModal, setSplitFileModal]: any = useState(null)
  const [isFollowing, setIsFollowing] = useState(false);
  const [data, setData]: any = useState({type: null, data: []})
  const [docOpe, setOperators]: any = useState([]);
  const [permissions, setGetPermissions]: any = useState(null)
  const [specificEdit,  setSpecificEdit]: any = useState(null)


  useEffect(() => {

    dispatch(
      actions.intrastat.getOneIntrastat({
        params: { 
          companyId: companyId, 
          intrastatId: intrastatId
        },
      })
    );

    // emit GET_CHAT
    // dispatch(
    //   actions.chat.getAllChats({
    //     params: { 
    //       companyId: companyId,  
    //       id: intrastatId 
    //     },
    //   })
    // );

    dispatch(
      actions.chat.readChat({
        params: { 
          companyId: companyId, 
          declarationId: intrastatId 
        },
      })
    );

    dispatch(
      actions.socket.subscribeUpdateChat(
        companyId,
        intrastatId
      )
     );

    dispatch(
      actions.socket.subscribeOneIntrastat(
        companyId,
        intrastatId
      )
    );
    
    return () => {
      dispatch(
        actions.intrastat.reset([
          'intrastat',
          'error',
        ])
      );
      dispatch(
        actions.chat.reset([
          'chat',
          'read',
          'error',
        ])
      );
      dispatch(
        actions.socket.unsubscribeOneIntrastat(
          companyId,
          intrastatId
        )
      );
      dispatch(
        actions.socket.unsubscribeUpdateChat(
          companyId,
          intrastatId
        )
      );
    }
  }, [dispatch, companyId, intrastatId]);


  useEffect(() => {
    if(intrastat && user){
      const is = intrastat.follower && intrastat.follower.find((e) => e.id === user.id)
      is ? setIsFollowing(true) : setIsFollowing(false)

      if(user.userCompanyPerms && user.userCompanyPerms[0].Company_Id === companyId){
        setGetPermissions(user.userCompanyPerms.find((e) => e.Permission_Id === 62) ? true : false)
      }
    }

    if(intrastat){
      if(intrastat.IntrastatDocuments){
        if(intrastat.IntrastatDocuments.find((e) => e.type === 0)){
          setData(prevData => ({
            ...prevData,
            type: 'expe', 
            data: intrastat.IntrastatDocuments.filter((e) => e.type === 0)
          }))
        }else{
          setData(prevData => ({
            ...prevData,
            type: 'intro', 
            data: intrastat.IntrastatDocuments.filter((e) => e.type === 1)
          }))
        }
        setOperators(intrastat.IntrastatDocuments.filter((e) => e.type === 2))
      }
    }
  }, [intrastat, acceptedFiles, user, companyId])


  const follow = (action: boolean) => {
    dispatch(
      actions.intrastat.followIntrastat({
        params: {
          intrastatId,
          companyId,
        },
        data: {
          action: action
        }
      })
    );
  };

  const updateStep = (step: number) => {
    dispatch(
      actions.intrastat.updateStep({
        params: {
          intrastatId,
          companyId,
        },
        data: { step },
      })
    );
  };


  const cancelintrastat = (values: { reason: string }) => {
    dispatch(
      actions.intrastat.updateIntrastat({
        params: {
          intrastatId,
          companyId,
        },
        data: { 
          reason: values.reason,
        },
      })
    );
    setmodalCancelintrastat(false);
  };


  const editOneIntrastat = (values: {
    period: string;
    declarationType: string;
    filingCountry: string;
    flow: string;
    clientReference: string;
  }): void => {
  dispatch(
    actions.intrastat.updateIntrastat({
      params: {
        intrastatId,
        companyId,
      },
      data: {
        period: values.period,
        declarationType: values.declarationType,
        filingCountry: values.filingCountry, 
        flow: values.flow,
        clientReference: values.clientReference,
      },
    }))
   setmodalEditIntrastat(false);
  }

  
  const addIntranetReference = (intranetReference: string ) => {
    dispatch(actions.intrastat.updateIntrastat({
      params: {
        intrastatId,
        companyId,
      },
      data: {
       intranetReference: intranetReference
      },
    }))
    setmodalAddIntranetReference(false);
  };


  const handleShowDocument = (documentId: number) => {
    dispatch(
      actions.intrastat.downloadDocument({
        params: {
          intrastatId,
          companyId,
          documentId
        },
      })
    );
  };

  const handleDeleteDocument = (documentId: number) => {
    dispatch(
      actions.intrastat.deleteDocument({
        params: {
          intrastatId,
          companyId,
          documentId
        },
      })
    );
  };

  const handleCheckDocument = (documentId: number, status: string) => {
    dispatch(
      actions.intrastat.updateDocument({
        params: {
          companyId: companyId,
          intrastatId: intrastatId,
          documentId: documentId,
          validation: status
        },
      })
    );
  }

  const getAllFiles = (acceptedFiles: any[], index: number) => {
    let obj: any = []
    obj.push({id: index, docs: acceptedFiles})
    setAcceptedFiles(obj)
  }

  const handleAddDocuments = (values: {
    type: number;
    documents: any;
    documentCategories: Array<{
      id: number;
      number: string | null;
    }>;
    split: boolean;
  }) => {
    values.documents.forEach((e, i) => {
      values.documents[i].files = acceptedFiles[i].docs
    })

    dispatch(
      actions.intrastat.addDocuments({
        params: {
          intrastatId,
          companyId,
        },
        data: {
          type: modalAddDocumentsStatus.type,
          documents: values.documents,
          documentCategories: values.documentCategories,
          split: values.split || ''
        },
      })
    );

    setmodalAddDocumentsStatus(prevState => ({
      ...prevState,
      open: false,
      type: undefined
    }));

    return () => {
      dispatch(
        actions.intrastat.reset([
          'docs',
          'error',
        ])
      );
    }
  };

  const handleSubmitMessage = (values: { userId: number; message: string }) => {
    dispatch(
      actions.chat.postChats({
        params: {
          id: intrastatId,
          companyId,
        },
        data: {
          userId: user.id,
          message: values.message
        }
      })
    );
  };


  const closeIntrastat = (response: boolean, intrastatId: string, step: number) => {
    if(response){
      dispatch(
      actions.intrastat.updateStep({
        params: {
          intrastatId: intrastatId,
          companyId: companyId,
        },
        data: { step: step },
      }))

        setmodalCloseintrastat(false)
    }else{
        setmodalCloseintrastat(false)
    }
  }

  const handleTagOperations = (values: {
    documentId: number, 
    tag: string,
    session: string,
    docName: string,
    docCategory: number,
    flowCat: number
  }) => {
    tagManagerModal && 
    dispatch(actions.intrastat.handleTagOperations({
      params: {
        companyId: companyId,
        intrastatId: intrastatId,
        documentId: values.documentId 
      },
      data: {
        tag: values.tag,
        operation: 'move-to',
        session: tagManagerModal.session,
        docName: tagManagerModal.docName,
        docCategory: tagManagerModal.docCategory,
        flowCat: tagManagerModal.flowCat,
        period: intrastat && intrastat.periodString,      
      }
    }))
    setTagManagerModal(prevState => ({
      ...prevState,
      open: false,
      documentId: null,
      tag: null,
      docName: null,
      docCategory: null,
      flowCat: null,
      periodString: null
    }))
  }


  return (
    <Wrapper display='flex' justifyContent='center'>
      {tagFilterManagerModal && intrastat && (
      <ModalFilterTagManager
        intl={intl}
        companyId={companyId}
        intrastatId={intrastat && intrastat.id}
        intrastatFlow={intrastat && intrastat.flow}
        data={tagFilterManagerModal}
        handleTagOperations={handleTagOperations}
        handleClose={() => setTagFilterManagerModal(null)}
        dispatch={dispatch}
        userAdminOrOp={userAdminOrOp}
      />   
      )} 
      {splitFileModal && intrastat && (
        <ModalSplitSingleFile
          intl={intl}
          companyId={companyId}
          intrastatId={intrastatId}
          IntrastatFlow={intrastat && intrastat.flow}
          open={splitFileModal.open}
          data={splitFileModal}
          handleTagOperations={handleTagOperations}
          handleClose={() => setSplitFileModal(null)}
          dispatch={dispatch}
          userAdminOrOp={userAdminOrOp}
        />   
      )}
      {modalAddDocumentsStatus.open && (
        <ModalAddIntrastatDocuments
          intl={intl}
          userAdminOrOp={userAdminOrOp}
          type={modalAddDocumentsStatus.type}
          open={modalAddDocumentsStatus.open}
          declarationType={intrastat.declarationType}
          handleClose={() => setmodalAddDocumentsStatus(prevState => ({
            ...prevState,
            open: false,
            type: undefined
          }))}
          handleAddDocuments={handleAddDocuments}
          dispatch={dispatch}
          getAllFiles={getAllFiles}
        />
      )}
      {modalCancelintrastat && (
        <ModalCancelintrastat
          intl={intl}
          open={modalCancelintrastat}
          handleClose={() => setmodalCancelintrastat(false)}
          handleCancelIntrastat={cancelintrastat}
        />
      )}
      {modalCloseintrastat && (
        <ModalCloseIntrastat
          intl={intl}
          intrastatId={intrastatId}
          declarationType={intrastat.declarationType}
          isActive={intrastat.state === 'completed'}
          open={modalCloseintrastat}
          handleClose={() => setmodalCloseintrastat(false)}
          handleCloseIntrastat={closeIntrastat}
        />
      )}
      {modalAddIntranetReference && (
        <ModalAddIntranetReference
          intl={intl}
          open={modalAddIntranetReference}
          handleClose={() => setmodalAddIntranetReference(false)}
          handleAddIntranetReference={addIntranetReference}
        />
      )}
           
      {modalEditIntrastat && (
        <ModalEditIntrastat
          open={modalEditIntrastat}
          handleClose={() => setmodalEditIntrastat(false)}
          handleEditIntrastat={editOneIntrastat}
          dispatch={dispatch}
          companyId={intrastat.Company_Id}
          actualData={{
            period: intrastat.period,
            filingCountry: intrastat.filingCountry,
            declarationType: intrastat.declarationType,
            flow: intrastat.flow,
            clientReference: intrastat.clientReference,
            intranetReference: intrastat.intranetReference,
          }}
        />
      )}

      {(
        intrastatLoading ||
        deleteDocumentLoading ||
        downloadLoading ||
        addDocumentsLoading
        ) && (<Loader />)
      } 
        <Wrapper height='100%' width='80%'>
          <Divider hidden />
          <Wrapper display='flex'>
          {userAdminOrOp && !isMontoring && !iso && (
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content={intl.formatMessage({id: `button.back`})}
              path={`/intrastat/${companyId}`}
            />
          )}
          {!userAdminOrOp && !iso && !companyId && (
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content={intl.formatMessage({id: `button.back`})}
              path={`/intrastat`}
            />
          )} 
          {!userAdminOrOp && companyId && (
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content={intl.formatMessage({id: `button.back`})}
              path={`/intrastat-dashboard`}
            />
          )}     
          {userAdminOrOp && iso && (
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content={intl.formatMessage({id: `button.back`})}
              path={`/intrastat/country/${iso}/${companyId}`}
            />
          )}
          {isMontoring && userAdminOrOp && (
              <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content={intl.formatMessage({id: `button.back` })}
              path={`/intrastat-monitoring`}
            />
          )}
         
          </Wrapper>
            <Divider hidden />       
            <Wrapper
              display='flex'
              justifyContent='center'
              height='100%'
              width='100%'
            >
            <Wrapper width='80%'>
              {intrastat && intrastat.DeclarationHasCategory && (
                <Wrapper>
                  <p>{intl.formatMessage({
                        id: `intrastat.${intrastat.declarationType.toLowerCase()}`,
                      })} {intl.formatMessage({id: `intrastat.prog.header`})}
                  </p>                 
                  <IntrastatProgressBar
                    intl={intl}
                    loading={intrastatLoading}
                    maxStep={4}
                    actualStep={intrastat.DeclarationHasCategory.step}
                    onClick={
                      !intrastatLoading &&
                      userAdminOrOp &&
                      ((step) => {
                        step !==
                        intrastat.DeclarationHasCategory.step &&
                          updateStep(step);
                      })
                    }
                  />
                </Wrapper>
              )}
             {(<>
              {intrastat !== null && (
                 <>             
                <Segment>
                  <Grid columns={1} divided padded>
                    <Grid.Column>
                      <Header>{intl.formatMessage({
                        id: `intrastat.${intrastat.declarationType.replace('_', ' ').toLowerCase()}`,
                      })}</Header>
                      {intrastat.regularization && (<b>{intl.formatMessage({id: `intrastat.regularization`})}</b>)}         
                    </Grid.Column>
                  </Grid>
                 
                  <Grid columns={2} divided padded>
                    <Grid.Column>
                      <FolderWrapper className='folder_wrapper'>
                        <b>{intl.formatMessage({id: `intrastat.company`})} </b>
                        <FolderLink>
                          <Link to={pathToCompany.companyDetails.replace(':companyId', companyId.toString())} className='folder_link_link'>{intrastat.Company.name}</Link>
                        </FolderLink>
                      </FolderWrapper>
                      <div><b>{intl.formatMessage({id: `intrastat.filingPeriod`})} </b>{shortDate(new Date(intrastat.period))}</div>
                      <div><b>{intl.formatMessage({id: `intrastat.filingCountry`})} </b>{intrastat.filingCountry}</div>  
                      <div><b>{intl.formatMessage({id: `intrastat.flow`})} </b>{intrastat.flow}</div>          
                    </Grid.Column>
                    <Grid.Column>
                      <div>
                        <b>Id: </b>
                        {intrastat.id}
                      </div>
                      <div>
                        <b>{intl.formatMessage({id: `intrastat.createdAt`})} </b>
                        {longDate(new Date(intrastat.createdAt))}
                      </div>
                      <div>
                        <b>{intl.formatMessage({id: `intrastat.emitter`})} </b>
                        {intrastat.emitter}
                      </div>
                      <div>
                        <b>{intl.formatMessage({id: `intrastat.followers`})} </b>
                        {(intrastat.follower && 
                            intrastat.follower.length>0 &&  
                            intrastat.follower.map((user, i) => (
                                  <li
                                    key={i}
                                  >{`${user.operator}`}</li>
                                ))
                          ) || 'none'}
                      </div>
                      {intrastat.clientReference && (
                        <div>
                          <b>{intl.formatMessage({id: `intrastat.clientReference`})} </b>
                          {intrastat.clientReference === '' ? '' : intrastat.clientReference}
                        </div>
                      )}                   
                      {userAdminOrOp && (                                         
                        <FolderWrapper>
                          <b>{intl.formatMessage({id: `intrastat.intranetReference`})} </b>
                          {intrastat.intranetReference && (
                          <FolderLink>
                            <Linked href={`${process.env.REACT_APP_INTRANET_DOUANE_URL}${intrastat.intranetReference}`} className='folder_link_link'>
                              {intrastat.intranetReference}</Linked>  
                              <Icon
                                className='customs-link-folder'
                                name='folder open'
                                size='small'
                                color='black'                    
                              />                             
                          </FolderLink> 
                          )}                                          
                        </FolderWrapper>                                                                               
                      )}
                      {intrastat.purchaseNumber && (
                        <div><b>{intl.formatMessage(
                          {id: `intrastat.purchaseOrder`}
                        )} </b>{intrastat.purchaseNumber}</div>
                      )}                     
                    </Grid.Column>
                  </Grid>
                  <Divider hidden />
                  {
                    (
                      intrastat.state !== 'completed' &&
                      intrastat.state !== 'cancelled'
                    ) && (
                      <Button
                        icon='edit'
                        type='button'
                        size='small'
                        color='blue'
                        content={intl.formatMessage({
                          id: `intrastat.editData`
                        })}
                        margin='10px 0 0 0'
                        onClick={() => setmodalEditIntrastat(true)}
                      />
                    )}
                  </Segment>
                          
                {intrastat.Company.IntrastatHasOperator && userAdminOrOp && (  
                  <>
                  {!specificEdit && (  
                    <Specifics onClick={() => {
                      setSpecificEdit(!specificEdit)
                    }}>
                      <SpecificsH4 css={intrastat.Company.IntrastatHasOperator.notes && '0 0 0.3rem 0'}>{intl.formatMessage({id: `intrastat.specifications`})}</SpecificsH4>
                      {intrastat.Company.IntrastatHasOperator.notes}
                      </Specifics>
                  )}              
                 
                  {specificEdit && (
                    <Specifics>
                      <IntrastatNotes
                        intl={intl} 
                        companyId={companyId} 
                        intrastatId={intrastatId}
                        setSpecificEdit={setSpecificEdit}
                        notes={intrastat.Company.IntrastatHasOperator.notes}              
                      />
                    </Specifics>
                  )}
                  </>
                )}

                <Divider hidden />

                {!intrastat.errorMessage && (
                  <Grid centered>              
                    {(userAdminOrOp && intrastat.state !== 'cancelled') && (
                      <Button
                        icon='plus'
                        type='button'
                        size='large'
                        color='blue'
                        content={intl.formatMessage(
                          {id: `intrastat.addIntranetReference`})}
                        margin='5px'
                        onClick={() => setmodalAddIntranetReference(true)}
                      />
                    )}                   
                    {userAdminOrOp && 
                    intrastat.state !== 'cancelled' && (
                      <Button
                        icon='user md'
                        type='button'
                        size='large'
                        color='blue'
                        content={intl.formatMessage(
                          {id: isFollowing ? 'intrastat.unfollow' : 'intrastat.follow' }
                        )}
                        margin='5px'
                        onClick={() => {
                          isFollowing ? follow(false) : follow(true)
                        }}
                      />
                    )}
                    {userAdminOrOp &&
                    intrastat.state !== 'cancelled' &&
                    intrastat.state !== 'completed' && (
                      <Button
                        icon='cancel'
                        type='button'
                        size='large'
                        color='red'
                        content={intl.formatMessage(
                          {id: `intrastat.detail.cancel`})}
                        margin='5px'
                        onClick={() => setmodalCancelintrastat(true)}
                      />
                    )}
                    {userAdminOrOp && 
                    (intrastat.state !== 'cancelled') && (
                      <Button
                        type='button'
                        color='blue'
                        size='large'
                        margin='5px'
                        content={intl.formatMessage(
                          {id: (intrastat.state === 'completed' && `intrastat.action.Open`) || `intrastat.action.Close`})}
                        onClick={
                          () => setmodalCloseintrastat(true)
                        }
                      />
                    )}
                </Grid>
                )}
                {!intrastat.errorMessage && (
                  <>
                    <Divider hidden />
                    <Divider />                               
                    <ResponsiveWrapper>
                    {userAdminOrOp && (
                        <Wrapper margin='5px' width='100%'>                    
                         <Wrapper display='flex' justifyContent='space-between'>
                           <Header>{(intrastat.flow === 'expedition' && 'Expedition') || 'Introduction'}</Header>
                           {intrastat.state !== 'cancelled' && (
                             <Button
                               icon='plus'
                               type='button'
                               color='blue'
                               disabled={intrastat.state === 'completed'}
                               content={intl.formatMessage({id: `intrastat.addDocs` })}
                               onClick={() => setmodalAddDocumentsStatus(prevState => ({
                                ...prevState,
                                open: true,
                                type: intrastat.flow === 'expedition' ? 0 : 1
                              }))}
                             />
                           )}
                         </Wrapper>                       
                         <Table striped columns='4' selectable>
                           <Table.Header>
                             <Table.Row>
                               <Table.HeaderCell>{intl.formatMessage({id: `intrastat.fileName` })}</Table.HeaderCell>
                               <Table.HeaderCell>{intl.formatMessage({id: `intrastat.category` })}</Table.HeaderCell>
                               <Table.HeaderCell>{intl.formatMessage({id: `intrastat.date` })}</Table.HeaderCell>
                               <Table.HeaderCell>{intl.formatMessage({id: `intrastat.actions` })}</Table.HeaderCell>
                             </Table.Row>
                           </Table.Header>
                           <Table.Body>
                           {(data && data.data.length===0 && <EmptyTable colNumber={4} />) || 
                           data.data.map(
                              (document, index) => 
                              (<Documents
                                intl={intl}
                                key={`e-${index}`}
                                userAdminOrOp={userAdminOrOp}
                                user={user}
                                isOwner={isOwner}                       
                                document={document}
                                declarationState={intrastat.state !== 'processing'}
                                index={index}
                                handleShowDocument={handleShowDocument}
                                handleDeleteDocument={handleDeleteDocument} 
                                openTagManager={() => {                                                     
                                  if((document.status === 'archive' && userAdminOrOp && document.split === null) || (document.split === null && isOwner)){
                                    setTagFilterManagerModal(prevState => ({
                                      ...prevState,
                                      open: true, 
                                      document: document,
                                      flowCat: 0,
                                      periodString: intrastat.periodString,
                                      companyName: intrastat.Company.name || null,
                                      intrastatId:  intrastat.id || null,
                                    }))
                                  }else if(document.split && userAdminOrOp){
                                    setSplitFileModal(prevState => ({
                                      ...prevState,
                                      open: true,
                                      session: document.session,
                                      documentId: document.id,
                                      docName: document.name,
                                      periodString: intrastat.periodString,
                                      docCategory: document.IntrastatDocumentCategory.id,
                                      intrastatId: document.intrastat_Id || null,
                                    }))                                  
                                  }
                                }}
                              />
                              ))}
                           </Table.Body>
                         </Table>
                        </Wrapper>    
                    )} 


                    {isOwner && (  
                      <Wrapper margin='5px' width='100%'>                    
                        <Wrapper display='flex' justifyContent='space-between'>
                          <Header>{(intrastat.flow === 'expedition' && 'Expedition') || 'Introduction'}</Header>
                          {intrastat.state !== 'cancelled' && (
                            <>
                            {/* <Button
                              icon='plus'
                              type='button'
                              color='blue'
                              disabled={intrastat.state === 'completed'}
                              content='Add zip'
                              onClick={() => setmodalAddZipStatus(prevState => ({
                              ...prevState,
                              open: true,
                              type: intrastat.flow === 'expedition' ? 0 : 1
                            }))}
                            /> */}
                            <Button
                              icon='plus'
                              type='button'
                              color='blue'
                              disabled={intrastat.state === 'completed'}
                              content={intl.formatMessage({id: `intrastat.addDocs` })}
                              onClick={() => setmodalAddDocumentsStatus(prevState => ({
                              ...prevState,
                              open: true,
                              type: intrastat.flow === 'expedition' ? 0 : 1
                            }))}
                            />
                            </>
                          )}
                        </Wrapper>                       
                        <Table striped columns='6' selectable>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>{intl.formatMessage({id: `intrastat.fileName` })}</Table.HeaderCell>
                              <Table.HeaderCell>{intl.formatMessage({id: `intrastat.category` })}</Table.HeaderCell>
                              <Table.HeaderCell>{intl.formatMessage({id: `intrastat.date` })}</Table.HeaderCell>
                              <Table.HeaderCell>{intl.formatMessage({id: `intrastat.control` })}</Table.HeaderCell>
                              <Table.HeaderCell>{intl.formatMessage({id: `intrastat.validation` })}</Table.HeaderCell>
                              <Table.HeaderCell>{intl.formatMessage({id: `intrastat.actions` })}</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                          {(data && data.data.length===0 && <EmptyTable colNumber={6} />) || 
                            data.data.map(
                                 (document, index) => 
                                 (                                                      
                                  <DocumentsClient
                                    intl={intl}
                                    key={`e-${index}`}
                                    userAdminOrOp={userAdminOrOp}
                                    isOwner={isOwner}
                                    statementValidation={permissions}                       
                                    document={document}
                                    declarationState={intrastat.state === 'completed'}
                                    index={index}
                                    handleShowDocument={handleShowDocument}
                                    handleDeleteDocument={handleDeleteDocument} 
                                    handleCheckDocument={handleCheckDocument}
                                    openTagManager={() => {                                                     
                                      if((document.status === 'archive' && userAdminOrOp && document.split === null) || (document.split === null && isOwner)){
                                        setTagFilterManagerModal(prevState => ({
                                          ...prevState,
                                          open: true, 
                                          document: document,
                                          flowCat: 0,
                                          periodString: intrastat.periodString,
                                          companyName: intrastat.Company.name || null,
                                          intrastatId:  intrastat.id || null,
                                        }))
                                      }else if(document.split && userAdminOrOp){
                                        setSplitFileModal(prevState => ({
                                          ...prevState,
                                          open: true,
                                          session: document.session,
                                          documentId: document.id,
                                          docName: document.name,
                                          periodString: intrastat.periodString,
                                          docCategory: document.IntrastatDocumentCategory.id,
                                          intrastatId: document.intrastat_Id || null,
                                        }))                                  
                                      }
                                    }}
                                  />
                                 ))}
                           </Table.Body>
                         </Table>
                        </Wrapper> 
                      )}   
                      {docOpe && userAdminOrOp && (
                         <Wrapper margin='5px' width='100%'>                    
                         <Wrapper display='flex' justifyContent='space-between'>
                         <Header>{intl.formatMessage({id: `intrastat.operator` })}</Header>
                         {intrastat.state !== 'cancelled' && (
                           <Button
                             icon='plus'
                             type='button'
                             color='blue'
                             disabled={intrastat.state === 'completed'}
                             content={intl.formatMessage({id: `intrastat.addDocs` })}
                             onClick={() =>  setmodalAddDocumentsStatus(prevState => ({
                               ...prevState,
                               open: true,
                               type: 2
                             }))}
                           />
                         )}
                       </Wrapper>                       
                       <Table striped columns='4' selectable>
                         <Table.Header>
                           <Table.Row>
                             <Table.HeaderCell>{intl.formatMessage({id: `intrastat.fileName` })}</Table.HeaderCell>
                             <Table.HeaderCell>{intl.formatMessage({id: `intrastat.category` })}</Table.HeaderCell>
                             <Table.HeaderCell>{intl.formatMessage({id: `intrastat.date` })}</Table.HeaderCell>
                             <Table.HeaderCell>{intl.formatMessage({id: `intrastat.actions` })}</Table.HeaderCell>
                           </Table.Row>
                         </Table.Header>
                         <Table.Body>
                         {(docOpe.length===0 && <EmptyTable colNumber={4} />) || 
                          docOpe.map((document, index) => 
                              (                                                      
                               <Documents
                               intl={intl}
                                key={`o-${index}`}
                                userAdminOrOp={userAdminOrOp}
                                isOwner={isOwner}
                                user={user}                       
                                document={document}
                                declarationState={intrastat.state === 'completed'}
                                index={index}
                                handleShowDocument={handleShowDocument}
                                handleDeleteDocument={handleDeleteDocument} 
                                openTagManager={() => {                                                     
                                  if((document.status === 'archive' && userAdminOrOp && document.split === null) || (document.split === null && isOwner)){
                                    setTagFilterManagerModal(prevState => ({
                                      ...prevState,
                                      open: true, 
                                      document: document,
                                      flowCat: 0,
                                      periodString: intrastat.periodString,
                                      companyName: intrastat.Company.name || null,
                                      intrastatId:  intrastat.id || null,
                                    }))
                                  }}}
                                />
                              ))}
                         </Table.Body>
                       </Table>
                     </Wrapper>                  
                      )} 

                      {docOpe && !userAdminOrOp && (
                         <Wrapper margin='5px' width='100%'>                    
                         <Wrapper display='flex' justifyContent='space-between'>
                         <Header>{intl.formatMessage({id: `intrastat.operator` })}</Header>
                       </Wrapper>                       
                       <Table striped columns='4' selectable>
                         <Table.Header>
                           <Table.Row>
                             <Table.HeaderCell>{intl.formatMessage({id: `intrastat.fileName` })}</Table.HeaderCell>
                             <Table.HeaderCell>{intl.formatMessage({id: `intrastat.category` })}</Table.HeaderCell>
                             <Table.HeaderCell>{intl.formatMessage({id: `intrastat.date` })}</Table.HeaderCell>
                             <Table.HeaderCell>{intl.formatMessage({id: `intrastat.actions` })}</Table.HeaderCell>
                           </Table.Row>
                         </Table.Header>
                         <Table.Body>
                         {(
                          docOpe.length===0 && <EmptyTableSpecific colNumber={4} item='Only declaration' />) || 
                          docOpe.map((document, index) => ( 
                            (document.name.match(/déclaration/i) &&                                                      
                               <Documents
                                intl={intl}
                                key={`o-${index}`}
                                userAdminOrOp={userAdminOrOp}
                                isOwner={isOwner}
                                user={user}                       
                                document={document}
                                declarationState={intrastat.state !== 'completed'}
                                index={index}
                                handleShowDocument={handleShowDocument}
                                handleDeleteDocument={handleDeleteDocument} 
                                openTagManager={() => {                                                     
                                  if((document.status === 'archive' && userAdminOrOp && document.split === null) || (document.split === null && isOwner)){
                                    setTagFilterManagerModal(prevState => ({
                                      ...prevState,
                                      open: true, 
                                      document: document,
                                      flowCat: 0,
                                      periodString: intrastat.periodString,
                                      companyName: intrastat.Company.name || null,
                                      intrastatId:  intrastat.id || null,
                                    }))
                                  }}}
                                />
                              )))}
                         </Table.Body>
                       </Table>
                     </Wrapper>
                    )}                           
                    </ResponsiveWrapper>                          
                    </>
                    )}
                    {intrastat.errorMessage && (
                      <Message error>
                        <Message.Header>{intl.formatMessage({id: `intrastat.error` })}</Message.Header>
                        <Divider hidden />
                        {intrastat.errorMessage}
                      </Message>
                    )}
                </>
              )}
            </>
            )}
            </Wrapper>
            <Wrapper 
              width='40%' 
              minHeight='767px' 
              padding='0 0 0 20px'>
              {(userAdminOrOp || isOwner) && (
                <IntrastatChat
                  messages={chat}
                  chatLoading={chatLoading}
                  isOwner={isOwner}
                  userRole={
                    user.operator
                      ? 'operator'
                      : !user.operator && !user.admin
                      ? 'customer'
                      : null
                  }
                  handleSubmitMessage={handleSubmitMessage}
                />
              )}             
              <Divider hidden/>
              <Divider hidden/>            
            </Wrapper>                
            </Wrapper>     
        </Wrapper>  
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    intrastat: selectors.intrastat.oneIntrastatSelector(state),
    intrastatLoading: selectors.intrastat.getOneIntrastatLoadingSelector(state),
    readChat: selectors.chat.readChatSelector(state),
    readChatLoading: selectors.chat.readChatLoadingSelector(state),  
    chat: selectors.chat.getAllMsgSelector(state),
    chatLoading: selectors.chat.getAllMsgLoadingSelector(state),
    contact: selectors.contact.getContactRightsSelector(state),
    companySelected: selectors.auth.companySelected(state),
    deleteDocumentLoading: selectors.intrastat.deleteIntrastatDocumentsSelector(state),
    addDocumentsLoading: selectors.intrastat.addIntrastatDocumentsLoadingSelector(state),
    addDocuments: selectors.intrastat.addIntrastatDocumentsSelector(state),
    downloadLoading: selectors.intrastat.downloadIntrastatLoadingSelector(state),
    categoriesDocuments: selectors.customsDeclaration.getAllCategoriesDocumentsSelector(state),
    categoriesDocListLoading: selectors.customsDeclaration.getAllCategoriesDocumentsLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
