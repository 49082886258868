import { Field, Formik } from 'formik';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Form, Header, Modal } from 'semantic-ui-react';
import { actions } from 'store';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { File } from 'components/Form';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import { podProofUpload } from 'interfaces/formSchema';

const UploadDocumentModal = ({
  dispatch,
  deliveryId,
  isModalOpen,
  handleClose,
  companyId,
  companyType,
  token,
}) => {
  const uploadProof = (values) => {
    if (token) {
      dispatch(
        actions.quickfixesToken.uploadProof({
          params: {
            token,
          },
          data: {
            file: values.file,
            type: values.documentType,
            companyType: 'buyer',
          },
        })
      );
    } else {
      dispatch(
        actions.quickfixes.uploadProof({
          params: {
            companyId,
            deliveryId,
          },
          data: {
            file: values.file,
            type: values.documentType,
            companyType: companyType,
          },
        })
      );
    }

    handleClose();
  };

  return (
    <Modal open={isModalOpen} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content='New proof' />
      <Formik
        initialValues={{
          documentType: '',
          file: undefined,
        }}
        onSubmit={uploadProof}
        validationSchema={podProofUpload}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Modal.Content>
            <Form onSubmit={handleSubmit}>
              <Field
                mandatory
                label='Document type'
                name='documentType'
                placeholder='Document type'
                component={Dropdown}
                options={[
                  {
                    key: 0,
                    value: 'CMR',
                    text: 'CMR',
                  },
                  {
                    key: 1,
                    value: 'BillOfLading',
                    text: 'Bill of lading',
                  },
                  {
                    key: 2,
                    value: 'AirwayBill',
                    text: 'Airway bill',
                  },
                  {
                    key: 3,
                    value: 'CarrierInvoice',
                    text: "Carrier's invoice",
                  },
                  {
                    key: 4,
                    value: 'NotarialCertificate',
                    text: 'Notarial certificate',
                  },
                  {
                    key: 5,
                    value: 'EvidenceOfPayment',
                    text: 'Evidence of payment',
                  },

                  {
                    key: 6,
                    value: 'AttestationOfReceipt',
                    text: 'Attestation of receipt',
                  },
                  {
                    key: 7,
                    value: 'Other',
                    text: 'Other',
                  },
                ]}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
              />
              <Divider hidden />
              <Field
                label={<MandatoryFieldLabel content='File' />}
                name='file'
                component={File}
                error={errors.file}
                touched={touched.file}
                meta={{ setFieldValue, setFieldTouched }}
              />
              <Divider hidden />
              <Button
                type='submit'
                icon='check'
                color='green'
                margin='5px 5px 5px 0px'
              />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(UploadDocumentModal);
