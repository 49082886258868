import Users from "views/Admin/Users";
import Detail from "views/Admin/Users/Details";
import Edit from "views/Admin/Users/Edit";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.users,
    component: Users,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.userDetails,
    component: Detail,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.userEdit,
    component: Edit,
    exact: true,
    authorizedRoles: []
  }
];
