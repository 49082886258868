import React, { useState, useEffect } from "react";
import _ from "lodash";
import { InputCustom as Input } from "./styles";

const Index = (props) => {
  const [searchValue, setsearchValue] = useState("");
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (searchValue.length < 1) {
      setsearchValue("");
      props.setResetSearch(true);
      setisLoading(false);
    } else {
      const re = new RegExp(_.escapeRegExp(searchValue), "i");
      const isMatch = (result) =>
        re.test(result.name) ||
        re.test(result.email) ||
        re.test(result.firstName) ||
        re.test(result.lastName) ||
        re.test(result.client) ||
        re.test(result.emitter) ||
        re.test(result.workshop) ||
        re.test(result.reference) ||
        (props.kyanite &&
          ((result.Transporters && re.test(result.Transporters.name)) ||
            re.test(result.Buyers.name)));

      props.onSearchResultChange(_.filter(props.dataSource, isMatch));
      setisLoading(false);
    }
  }, [searchValue, props.dataSource]);

  const handleChange = (event) => {
    setsearchValue(event.target.value);
    setisLoading(true);
  };

  return (
    <>
      <Input
        type="text"
        placeholder="Search..."
        loading={isLoading}
        icon="search"
        value={searchValue}
        onChange={handleChange}
      />
    </>
  );
};

export default Index;
