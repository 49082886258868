import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    lock(data: dataReqI) {
      const lockApi = routesApi.customs.clerk.lock;

      return api.request({
        method: lockApi.method,
        route: lockApi.path,
        params: data.params,
      });
    },
    unlock(data: dataReqI) {
      const unlockApi = routesApi.customs.clerk.unlock;
      return api.request({
        method: unlockApi.method,
        route: unlockApi.path,
        params: data.params,
      });
    },
    createControlPDF(data: dataReqI) {
      const createControlPdfApi = routesApi.customs.clerk.createControlPDF;
      return api.request({
        method: createControlPdfApi.method,
        route: createControlPdfApi.path,
        params: data.params,
      });
    },
    getPdfFile(data: dataReqI) {
      const getPdfApi = routesApi.customs.clerk.getPdfFile;
      return api.request({
        method: getPdfApi.method,
        route: getPdfApi.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    createPochettePDF(data: dataReqI) {
      const createPochettePDFApi = routesApi.customs.clerk.pochette;
      return api.request({
        method: createPochettePDFApi.method,
        route: createPochettePDFApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    getIssueTimes(data: dataReqI) {
      const getIssueTimesApi = routesApi.customs.clerk.getIssueTimes;
      return api.request({
        method: getIssueTimesApi.method,
        route: getIssueTimesApi.path,
        params: data.params,
      });
    },
    addIssueTime(data: dataReqI) {
      const addIssueTimeApi = routesApi.customs.clerk.addIssueTime;

      return api.request({
        method: addIssueTimeApi.method,
        route: addIssueTimeApi.path,
        params: data.params,
        data: data.data,
      });
    },
    updateIssueTime(data: dataReqI) {
      const updateIssueTimeApi = routesApi.customs.clerk.updateIssueTime;

      return api.request({
        method: updateIssueTimeApi.method,
        route: updateIssueTimeApi.path,
        params: data.params,
        data: data.data,
      });
    },
    removeIssueTime(data: dataReqI) {
      const removeIssueTimeApi = routesApi.customs.clerk.removeIssueTime;

      return api.request({
        method: removeIssueTimeApi.method,
        route: removeIssueTimeApi.path,
        params: data.params,
      });
    },
    getOneFile(data: dataReqI) {
      const getOneFileApi = routesApi.customs.clerk.getOneFile;

      return api.request({
        method: getOneFileApi.method,
        route: getOneFileApi.path,
        params: data.params,
      });
    },
    getDeclarationInProgress(data: dataReqI) {
      const getDeclarationInProgressApi =
        routesApi.customs.clerk.getDeclarationInProgress;
      return api.request({
        method: getDeclarationInProgressApi.method,
        route: getDeclarationInProgressApi.path,
        params: data.params,
      });
    },

    getStartSignature(data: dataReqI) {
      const getStartSignatureApi = routesApi.customs.clerk.getStartSignature;

      return api.request({
        method: getStartSignatureApi.method,
        route: getStartSignatureApi.path,
        params: data.params,
        responseType: 'blob',
      });
    },

    getTrailerLicensePlate(data: dataReqI) {
      const getTrailerLicensePlateApi =
        routesApi.customs.clerk.getTrailerLicensePlate;

      return api.request({
        method: getTrailerLicensePlateApi.method,
        route: getTrailerLicensePlateApi.path,
        params: data.params,
        responseType: 'blob',
      });
    },

    removeTrailerLicensePlate(data: dataReqI) {
      const removeTrailerLicensePlate =
        routesApi.customs.clerk.removeTrailerLicensePlate;

      return api.request({
        method: removeTrailerLicensePlate.method,
        route: removeTrailerLicensePlate.path,
        params: data.params,
      });
    },

    addTrailerLicensePlate(data: dataReqI) {
      const addTrailerLicensePlateApi =
        routesApi.customs.clerk.addTrailerLicensePlate;

      const form: any = new FormData();
      form.append('trailerLicensePlate', data.data.trailerLicensePlate);

      return api.request({
        method: addTrailerLicensePlateApi.method,
        route: addTrailerLicensePlateApi.path,
        params: data.params,
        data: form,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },

    getPaymentReceipt(data: dataReqI) {
      const getPaymentReceiptApi = routesApi.customs.clerk.getPaymentReceipt;

      return api.request({
        method: getPaymentReceiptApi.method,
        route: getPaymentReceiptApi.path,
        params: data.params,
        responseType: 'blob',
      });
    },

    getPaymentReceiptTimeStamp() {
      const getReceiptDateApi = routesApi.customs.clerk.getPaymentReceiptTimeStamp;
      return api.request({
        method: getReceiptDateApi.method,
        route: getReceiptDateApi.path,
      });
    },

    getEndSignature(data: dataReqI) {
      const getEndSignatureApi = routesApi.customs.clerk.getEndSignature;

      return api.request({
        method: getEndSignatureApi.method,
        route: getEndSignatureApi.path,
        params: data.params,
        responseType: 'blob',
      });
    },

    createFile(data: dataReqI) {
      const createFileApi = routesApi.customs.clerk.createFile;
      const form: any = new FormData();
      form.append('signatureStart', data.data.signatureStart);
      form.set('startingAt', data.data.startingAt);
      form.set('transitNumber', data.data.transitNumber);
      form.set('declarationId', data.data.declarationId);
      if (!data.data.declarationId) {
        form.append(
          'declaration',
          JSON.stringify({
            trailerLicensePlate: data.data.declaration.trailerLicensePlate,
            truckLicensePlate: data.data.declaration.truckLicensePlate,
            truckOrigin: data.data.declaration.truckOrigin,
            trailerOrigin: data.data.declaration.trailerOrigin,
            customsOffice: data.data.declaration.customsOffice,
            transporterId: data.data.declaration.transporterId,
            recipientId: data.data.declaration.recipientId,
            estimatedDateTimeArrival: data.data.declaration.estimatedDateTimeArrival,
          })
        );
      }

      return api.request({
        method: createFileApi.method,
        route: createFileApi.path,
        params: data.params,
        data: form,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },

    updateFile(data: dataReqI) {
      const updateFileApi = routesApi.customs.clerk.updateFile;

      return api.request({
        method: updateFileApi.method,
        route: updateFileApi.path,
        params: data.params,
        data: data.data,
      });
    },

    getIssue(data: dataReqI) {
      const getIssueIdApi = routesApi.customs.clerk.getIssue;
      return api.request({
        method: getIssueIdApi.method,
        route: getIssueIdApi.path,
        params: data.params,
      });
    },

    downloadPicturesIssuesZIP(data: dataReqI) {
      const downloadPicturesIssuesZIPApi =
        routesApi.customs.clerk.downloadPicturesIssuesZIP;
      return api.request({
        method: downloadPicturesIssuesZIPApi.method,
        route: downloadPicturesIssuesZIPApi.path,
        params: data.params,
        responseType: 'blob',
      });
    },

    getSummary(data: dataReqI) {
      const getSummaryApi = routesApi.customs.clerk.getSummary;
      return api.request({
        method: getSummaryApi.method,
        route: getSummaryApi.path,
        params: data.params,
      });
    },

    getAllFiles(data: dataReqI) {
      const getAllFilesApi = routesApi.customs.clerk.getAllFiles;
      return api.request({
        method: getAllFilesApi.method,
        route: getAllFilesApi.path,
        query: data.query,
      });
    },

    getAllFilesByFilters(data: dataReqI) {
      const getAllFilesApi = routesApi.customs.clerk.getAllFilesByFilters;
      return api.request({
        method: getAllFilesApi.method,
        route: getAllFilesApi.path,
        query: data.query,
        data: data.data,
      });
    },

    updateIssue(data: dataReqI) {
      const updateIssueApi = routesApi.customs.clerk.updateIssue;

      return api.request({
        method: updateIssueApi.method,
        route: updateIssueApi.path,
        data: data.data,
        params: data.params,
      });
    },

    addIssuePictures(data: dataReqI) {
      const addIssuePicturesApi = routesApi.customs.clerk.addIssuePictures;
      const form: any = new FormData();

      form.append('file', data.data.file);

      return api.request({
        method: addIssuePicturesApi.method,
        route: addIssuePicturesApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },

    deleteIssuePicture(data: dataReqI) {
      const deleteIssuePictureApi = routesApi.customs.clerk.deleteIssuePicture;
      return api.request({
        method: deleteIssuePictureApi.method,
        route: deleteIssuePictureApi.path,
        params: data.params,
      });
    },
    getCDHasBillings(data: dataReqI){
      const getCDHasBillingsApi = routesApi.customs.clerk.cdBillings;
      return api.request({
        method: getCDHasBillingsApi.method,
        route: getCDHasBillingsApi.path,
        params: data.params,
        data: data.data,
      });
    },
    getBillings() {
      const getBillingsApi = routesApi.customs.clerk.getBillings;
      return api.request({
        method: getBillingsApi.method,
        route: getBillingsApi.path,
      });
    },
    getFileHasBillings(data: dataReqI) {
      const getFileHasBillingsApi = routesApi.customs.clerk.getFileHasBillings;
      return api.request({
        method: getFileHasBillingsApi.method,
        route: getFileHasBillingsApi.path,
        params: data.params,
      });
    },
    addInvoicingLine(data: dataReqI) {
      const addInvoicingLineApi = routesApi.customs.clerk.addInvoicingLine;
      return api.request({
        method: addInvoicingLineApi.method,
        route: addInvoicingLineApi.path,
        params: data.params,
        data: data.data,
      });
    },
    updateInvoicingLine(data: dataReqI) {
      const updateInvoicingLineApi =
        routesApi.customs.clerk.updateInvoicingLine;
      return api.request({
        method: updateInvoicingLineApi.method,
        route: updateInvoicingLineApi.path,
        params: data.params,
        data: data.data,
      });
    },
    removeInvoicingLine(data: dataReqI) {
      const removeInvoicingLineApi =
        routesApi.customs.clerk.removeInvoicingLine;
      return api.request({
        method: removeInvoicingLineApi.method,
        route: removeInvoicingLineApi.path,
        params: data.params,
      });
    },
    validateFile(data: dataReqI) {
      const validateFileApi = routesApi.customs.clerk.validateFile;
      const form: any = new FormData();

      form.append('signatureEnd', data.data.signatureEnd);
      if (data.data.paymentReceipt) {
        form.append('paymentReceipt', data.data.paymentReceipt);
      }

      form.set('paidBy', data.data.paidBy);

      data.data.comment && form.set('comment', data.data.comment);
      data.data.receipDate && form.set('receipDate', data.data.receipDate);
      data.data.customsId && form.set('customsId', data.data.customsId);
      

      return api.request({
        method: validateFileApi.method,
        route: validateFileApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    updateInvoicingStep(data: dataReqI) {
    
      const updateInvoicingStepApi =
        routesApi.customs.clerk.updateInvoicingStep;
      const form: any = new FormData();
      if (data.data.signatureEnd) {
        form.append('signatureEnd', data.data.signatureEnd);
      }
      if (data.data.paymentReceipt) {
        form.append('paymentReceipt', data.data.paymentReceipt);
      }
      form.set('paidBy', data.data.paidBy);
      form.set('isReceiptNeeded', data.data.isReceiptNeeded);
      data.data.receipDate && form.set('receipDate', data.data.receipDate);
      data.data.comment && form.set('comment', data.data.comment);

      return api.request({
        method: updateInvoicingStepApi.method,
        route: updateInvoicingStepApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    validateFileState(data: dataReqI) {
      const validateFileStateApi = routesApi.customs.clerk.validateFileState;
      return api.request({
        method: validateFileStateApi.method,
        route: validateFileStateApi.path,
        params: data.params,
      });
    },
  };
};
