import React from "react";
import { Table } from "semantic-ui-react";

const Index = ({ colNumber, item }) => {
  return (
    <Table.Row>
      <Table.Cell colSpan={colNumber} warning textAlign="center" singleLine>
        {item}
      </Table.Cell>
    </Table.Row>
  );
};

export default Index;
