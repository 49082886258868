import styled from "@emotion/styled";

export const ResponsiveWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',

  '@media (max-width: 1100px)': {
    flexDirection: 'column',
  },
}));

export const FolderWrapper = styled.div(() => ({
  display: 'flex',
  float: 'left',
  width: '100%'
}))

export const FolderLink = styled.div(() => ({
  padding: '0 0.5rem 0 0.3rem ',
  textDecoration: 'none',
  color: 'rgba(0,0,0,.87)',
  cursor: 'pointer',
}))

export const Linked = FolderLink.withComponent('a')

