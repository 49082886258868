import styled from "@emotion/styled";
import { Form, Container } from "semantic-ui-react";

export const Wrapper = styled.div(({ margin }: any) => ({
  display: "block",
  margin: "5px",
  paddingLeft: "12rem",
  paddingRight: "12rem"
}));

export const ContainerPadded = styled(Container)`
padding: 2rem;
`;

export const WrapperInline = styled.div(({ margin }: any) => ({
  margin: "0",
  display: "inline-block",
  paddingLeft: "25rem",
  paddingRight: "25rem"
}));

export const BtnWrapper = styled.div(({ margin }: any) => ({
  margin: "0",
  display: "inline-block"
}));

export const FormGroupStyled = styled(Form.Group)`
  display: flex;
  justify-content: space-around;
`;
