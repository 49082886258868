import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Container, Divider, Header } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import { actions, selectors } from 'store';

import { Main } from 'components/Layouts';
import Loader from 'components/Loader';
import { Wrapper, Segment } from 'components/core';
import { Input } from 'components/Form';
import Button from 'components/Button';
import { profileEditionSchema } from 'interfaces/formSchema';
import { changePasswordSchema } from 'interfaces/formSchema';

const Index = ({ dispatch, user, userData, loadingHandlerProfile, error }) => {
  const userAdminOrOp = user.operator || user.admin;
  const [edit, setedit]: any = useState(false);
  const [passwordEdition, setPasswordEdition]: any = useState(false);

  useEffect(() => {
    dispatch(
      actions.user.getUser({
        params: { userId: user.id },
      })
    );
    return () => {
      dispatch(actions.user.reset(['user', 'error']));
    };
  }, [dispatch, user]);

  useEffect(() => {
    if (error) {
      dispatch(
        actions.user.getUser({
          params: { userId: user.id },
        })
      );
    }
  }, [dispatch, error, user]);

  const save = (values) => {
    dispatch(
      actions.user.update({
        params: { userId: userData.id },
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          partnership: values.partnership,
        },
      })
    );
    setedit(false);
  };

  const saveNewPassword = (values) => {
    dispatch(
      actions.user.updatePassword({
        params: { userId: userData.id },
        data: { oldPassword: values.password, newPassword: values.newPassword },
      })
    );
    setPasswordEdition(false);
  };

  const workmailRenewal = () => {
    dispatch(
      actions.user.workmailRenewal({
        data: { userEmail: user.email }
      })
    );
  }

  return (
    <Main>
      <Divider hidden />
      <Header size='huge'>My Profile</Header>
      <Container>
        {((userData === null || loadingHandlerProfile) && <Loader />) || (
          <Wrapper>
            <Segment padding='2rem'>
              <Formik
                initialValues={{
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  email: userData.email,
                  partnership: userData.partnership,
                }}
                onSubmit={save}
                validationSchema={profileEditionSchema}
                enableReinitialize={true}
              >
                {({ 
                    errors, 
                    touched, 
                    handleSubmit, 
                    resetForm, 
                    setFieldValue,
                    setFieldTouched,
                    values, 
                  }: any) => (
                  <Wrapper maxWidth='30rem' margin='auto'>
                    <Form onSubmit={handleSubmit}>
                      <>
                        <Form.Group widths='equal'>
                          <Form.Field>
                            <Field
                              mandatory
                              disabled={!edit}
                              label='First name'
                              name='firstName'
                              type='text'
                              component={Input}
                              meta={{ errors, touched }}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Field
                              mandatory
                              disabled={!edit}
                              label='Last name'
                              name='lastName'
                              type='text'
                              component={Input}
                              meta={{ errors, touched }}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Divider hidden />
                        <Field
                          mandatory
                          disabled={!edit}
                          label='Email'
                          name='email'
                          type='email'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                        {/* <Divider hidden /> */}
                        {/* <Field
                          label='Partnership activation'
                          disabled={!edit}
                          checked={values.partnership}
                          name='partnership'
                          onClick={() => {
                            setFieldValue('partnership', !values.partnership);
                          }}
                          component={Checkbox}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        /> */}
                        <Divider hidden />
                        <Wrapper display='flex' justifyContent='center'>
                          {!edit && !passwordEdition && (
                            <Button
                              content='Edit'
                              icon='edit'
                              type='button'
                              color='blue'
                              labelPosition='right'
                              onClick={() => setedit(true)}
                              margin='5px 5px 5px 0px'
                            />
                          )}
                          {edit && (
                            <>
                              <Button
                                content='Validate'
                                icon='save'
                                type='submit'
                                color='green'
                                labelPosition='right'
                                margin='5px 5px 5px 0px'
                              />
                              <Button
                                content='Cancel'
                                icon='cancel'
                                type='button'
                                labelPosition='right'
                                color='red'
                                onClick={() => {
                                  setedit(false);
                                  resetForm();
                                }}
                                margin='5px 5px 5px 0px'
                              />
                            </>
                          )}
                          {!passwordEdition && !edit && (
                            <Button
                              content='Change my password'
                              icon='edit'
                              type='button'
                              color='blue'
                              labelPosition='right'
                              onClick={() => setPasswordEdition(true)}
                              margin='5px 5px 5px 0px'
                            />
                          )}
                        </Wrapper>
                      </>
                    </Form>
                  </Wrapper>
                )}
              </Formik>
              {userAdminOrOp && !passwordEdition && (
                <>
                <Wrapper 
                  margin='2rem 0 0 0' 
                  display='flex' 
                  justifyContent='center' 
                  flexDirection='column' 
                  alignItems='center'>                                
                    <Button
    
                      content='WorkMail - password renewal'
                      icon='refresh'
                      type='button'
                      color='red'
                      labelPosition='right'
                      margin='5px 5px 5px 0px'
                      onClick={() => { workmailRenewal() }}
                    />
                </Wrapper>
                </>
              )}           
              {passwordEdition && (
                <Wrapper margin='2rem 0 0 0'>
                  <Formik
                    initialValues={{
                      password: '',
                      newPassword: '',
                      newPasswordConfirmation: '',
                    }}
                    onSubmit={saveNewPassword}
                    validationSchema={changePasswordSchema}
                  >
                    {({ errors, touched, handleSubmit, resetForm }: any) => (
                      <Wrapper maxWidth='30rem' margin='auto'>
                        <Form onSubmit={handleSubmit}>
                          <>
                            <Form.Field>
                              <Field
                                mandatory
                                label='Your actual password'
                                placeholder='Your actual password'
                                name='password'
                                type='password'
                                component={Input}
                                meta={{ errors, touched }}
                              />
                            </Form.Field>
                            <Form.Group widths='equal'>
                              <Form.Field>
                                <Field
                                  mandatory
                                  label='Your new password'
                                  placeholder='Your new password'
                                  name='newPassword'
                                  type='password'
                                  component={Input}
                                  bulleted
                                  bullMsg='Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character. Allowed special characters: !@#$%^&*.'
                                  meta={{ errors, touched }}
                                />
                              </Form.Field>
                              <Form.Field>
                                <Field
                                  mandatory
                                  label='New password confirmation'
                                  placeholder='Your new password'
                                  name='newPasswordConfirmation'
                                  type='password'
                                  component={Input}
                                  meta={{ errors, touched }}
                                />
                              </Form.Field>
                            </Form.Group>
                            <Wrapper display='flex' justifyContent='center'>
                              <Button
                                content='Validate'
                                icon='save'
                                type='submit'
                                color='green'
                                labelPosition='right'
                                margin='5px 5px 5px 0px'
                              />
                              <Button
                                content='Cancel'
                                icon='cancel'
                                type='button'
                                labelPosition='right'
                                color='red'
                                onClick={() => {
                                  setPasswordEdition(false);
                                  resetForm();
                                }}
                                margin='5px 5px 5px 0px'
                              />
                            </Wrapper>
                          </>
                        </Form>
                      </Wrapper>
                    )}
                  </Formik>
                </Wrapper>
              )}
            </Segment>
          </Wrapper>
        )}
      </Container>
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: selectors.user.userSelector(state),
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    loadingHandlerProfile: selectors.user.loadingSelector(state),
    error: selectors.message.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
