import React from 'react';
import { List, Card } from 'semantic-ui-react';

const SelectedCompanyInfo = ({ company }) => {
  return (
    <Card centered fluid>
      <Card.Header>{company.name}</Card.Header>
      <Card.Meta>{company.email}</Card.Meta>
      <Card.Description>
        <List horizontal>
          <List.Item>{company.address1}</List.Item>
          <List.Item>{company.city}</List.Item>
          <List.Item>{company.zipCode}</List.Item>
          <List.Item>{company.country}</List.Item>
        </List>
      </Card.Description>
    </Card>
  );
};

export default SelectedCompanyInfo;
