import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  ticketSsr(data: dataReqI) {
    return {
      type: actionTypes.TICKETSSR,
      payload: data,
    };
  },
  sendResults(data: dataReqI) {
    return {
      type: actionTypes.SEND_RESULTS,
      payload: data,
    };
  },
  getFormSchemas() {
    return {
      type: actionTypes.GET_FORM_SCHEMAS,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
