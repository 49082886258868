import { orderBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Checkbox } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Input } from 'components/Form';
import Dropdown from 'components/Dropdown';
import { newCompanySchema } from 'interfaces/formSchema';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import euCountries from 'config/euCountries.json';

const FormFields = ({
  dispatch,
  countries,
  intl,
  errorHandlerData,
  loadingHandlerData,
  onSave,
  user
}) => {
  const [countriesList, setcountriesList]: any = useState([]);
  const [euCountry, setEuCountry]: any = useState(false);
  const [clients, setClients]: any = useState(true);

  useEffect(() => {
    dispatch(actions.data.getAllCountries());

    return () => {
      dispatch(actions.data.reset(['allCountries', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    if (countries !== null) {
      setcountriesList(
        orderBy(
          countries.map((country, index) => {
            return {
              key: index,
              value: country.iso_code,
              text: country.english_name,
              flag: country.iso_code.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [countries]);

  return (
    <Formik
      initialValues={{
        name: '',
        uniqueNumber: '',
        email: '',
        phoneNumber: '',
        country: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: '',
        transporter: false,
        vatNumber: '',
        superOperator: false
      }}
      onSubmit={onSave}
      validationSchema={newCompanySchema}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form onSubmit={handleSubmit} width='15px'>
          <Wrapper margin='10px'>
            <Form.Group widths='equal'>
              <Form.Field>
                <Field
                  label={<MandatoryFieldLabel content='Company Name' />}
                  placeholder='Company Name'
                  name='name'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Form.Field>
            </Form.Group>
          </Wrapper>
          <Wrapper margin='10px'>
            <Form.Group widths='equal'>
              <Form.Field>
                <Field
                  label={
                    <MandatoryFieldLabel
                      content={intl.formatMessage({
                        id: 'input.label.email',
                      })}
                    />
                  }
                  placeholder={intl.formatMessage({
                    id: 'input.label.email',
                  })}
                  name='email'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Form.Field>
              <Form.Field>
                <Field
                  label='Phone number'
                  placeholder='Phone number'
                  name='phoneNumber'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Form.Field>
            </Form.Group>
          </Wrapper>
          <Wrapper margin='10px'>
            <Form.Group widths='equal'>
              <Form.Field>
                <Field
                  loading={loadingHandlerData}
                  placeholder='Choose country'
                  label={<MandatoryFieldLabel content='Country' />}
                  name='country'
                  options={countriesList}
                  component={Dropdown}
                  onChange={(e) => {
                    setEuCountry(euCountries.includes(e));
                  }}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                  uniqueError={errorHandlerData.includes('allCountries')}
                />
              </Form.Field>
              <Form.Field>
                <Field
                  label={<MandatoryFieldLabel content='City' />}
                  placeholder='City'
                  name='city'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Form.Field>
            </Form.Group>
          </Wrapper>

          <Wrapper margin='10px'>
            <Form.Group widths='equal'>
              <Form.Field>
                <Field
                  label={<MandatoryFieldLabel content='Address' />}
                  placeholder='Address'
                  name='address1'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Form.Field>
              <Form.Field>
                <Field
                  label={<MandatoryFieldLabel content='Zip code' />}
                  placeholder='Zip code'
                  name='zipCode'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Form.Field>
            </Form.Group>
          </Wrapper>
          <Wrapper margin='10px'>
            <Form.Group widths='equal'>
              <Form.Field>
                <Field
                  label='Address 2'
                  placeholder='Address 2'
                  name='address2'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Form.Field>
              <Form.Field>
                <Wrapper padding='2rem 0 0'>
                  <Field
                    label='Transporter'
                    checked={values.transporter === true}
                    name='transporter'
                    onClick={() => {
                      values.transporter === false
                        ? setFieldValue('transporter', true)
                        : setFieldValue('transporter', false);
                    }}
                    component={Checkbox}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>
              </Form.Field>        
            </Form.Group>
            {euCountry && (
              <Form.Field>
                <Field
                  label={<MandatoryFieldLabel content='VAT Number' />}
                  placeholder='FR12123456789'
                  name='vatNumber'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Form.Field>
            )}
            {user && user.superOperator && (
               <Form.Field>
               <Wrapper padding='2rem 0 0'>
                 <Field
                   label='Set as a virtual super-admin account'
                   name='superOperator'
                   component={Checkbox}
                   meta={{
                     errors,
                     touched,
                     setFieldValue,
                     setFieldTouched,
                   }}
                   onClick={() => {
                    values.superOperator === false
                      ? setFieldValue('superOperator', true)
                      : setFieldValue('superOperator', false);
                  }}
                 />
               </Wrapper>
             </Form.Field>        
            )}
          </Wrapper>
          <Button
            disabled={isSubmitting}
            type='button'
            content={intl.formatMessage({
              id: 'button.save',
            })}
            margin='20px'
            position='center'
            color='blue'
            onClick={() => handleSubmit()}
          />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.data.allCountriesSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    loadingHandlerCompany: selectors.company.loadingSelector(state),
    loadingHandlerData: selectors.data.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(FormFields);
