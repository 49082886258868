import { routesApi } from "config/api";

export default ({ api }) => {
  return {
    getAllStatus() {
      const getAllStatusApi = routesApi.status.allStatus;
      return api.request({
        method: getAllStatusApi.method,
        route: getAllStatusApi.path
      });
    },
  };
};
