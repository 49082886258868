import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Divider,
  Form,
  Grid,
  Header,
  Modal,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import { updateVat } from 'interfaces/formSchema/customsValidators';
import Dropdown from 'components/Dropdown';
import { FiscalPeriod, Input } from 'components/Form';
import { DropP, DropWrapper } from './editvatStyles';

const ModalEditVat = ({
  open,
  actualData,
  handleEditVat,
  companyId,
  company,
  obligations,
  obligationLoading,
  dispatch,
  intl,
  mapping,
  ta,
  euCountries,
  euCountriesLoading,
  handleClose,
  loadingHandlerData,
}: ModalEditVatProps) => {

  const [countriesList, setcountriesList]: any = useState([]);

    useEffect(() => {
      if(companyId){
        dispatch(
          actions.company.getOne({
            params: { companyId: companyId },
          })
        );
        dispatch(actions.fiscalVat.getObligation({
          params: {
            companyId: companyId
          }
        }));
      }
      dispatch(actions.data.getEuCountries());
  
      return () => {
        dispatch(
          actions.fiscalDeclaration.reset([
            'companySubscribed',
            'error',
          ]));
        dispatch(actions.data.reset(['euCountries', 'error']));
        dispatch(actions.company.reset(['company', 'error']));
      };
    }, [dispatch, companyId]);
  
  
    useEffect(() => {
      if(obligations && euCountries){
          let tab = []
          obligations.forEach((o, index) => {
            if(euCountries.find((c) => c.id === o.Country_Id)){
              tab.push({
                key: index,
                value: {
                  iso: o.iso, 
                  periodicity: o.periodicity,
                  Country_Id: o.Country_Id
                },
                text: 
                <DropWrapper>
                  <div>{intl.formatMessage({id: `countries.name.${o.iso}`})}</div>
                  <DropP>{o.periodicity}</DropP>
                </DropWrapper>,
                flag: o.iso.toLowerCase(),
              })
            }
          })
        setcountriesList(tab)
      }
    }, [obligations, euCountries, intl]);
  
  
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='edit' content='Edit Vat' />
      <Formik
        initialValues={{
            taxPeriod: actualData.taxPeriod.split('T')[0],
            country: actualData.country,
            clientReference: actualData.clientReference,
        }}
        enableReinitialize={true}
        onSubmit={handleEditVat}
        validationSchema={updateVat}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
        <Modal.Content>
        <Form onSubmit={handleSubmit}>
        <Grid>     
        <Grid.Row columns={2}>
          <Grid.Column>
            <Field
              mandatory
              label='Tax Period'
              name='taxPeriod'
              component={FiscalPeriod}
              type='date'
              meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Field
              mandatory
              label='Country'
              name='country'
              placeholder='choose...'
              component={Dropdown}
              options={countriesList}
              loading={euCountriesLoading}
              meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              mandatory
              label='Client reference'
              name='clientReference'
              placeholder='REF5765...'
              component={Input}
              meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
              }}
            />
          </Grid.Column>
          </Grid.Row>
          </Grid>
          <Divider hidden />
          <Divider hidden />
              <Button
                disabled={isSubmitting}
                type='submit'
                content='Submit'
                color='green'
              />
        </Form>
      </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.fiscalDeclaration.companySubscribedSelector(state),
    company: selectors.company.companiesSelector(state),
    ta: selectors.fiscalDeclaration.taSelector(state),
    mapping: selectors.fiscalDeclaration.mappingSelector(state),
    euCountries: selectors.data.euCountriesSelector(state),
    euCountriesLoading: selectors.data.euCountriesLoadingSelector(state),
    obligationsloading: selectors.fiscalVat.obligationLoadingSelector(state),
    obligations: selectors.fiscalVat.obligationGetSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    errorHandlerCompany: selectors.company.errorSelector(state),
    errorHandlerDeclaration: selectors.fiscalDeclaration.errorSelector(state),
    loadingHandlerTA: selectors.fiscalDeclaration.taLoadingSelector(state),
    loadingHandlerData: selectors.data.allCountryLoadingSelector(state),
    loadingHandlercompanies: selectors.company.companiesLoadingSelector(state),
    loadingHandlerDeclaration: selectors.fiscalDeclaration.mappingLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

type declarationData = {
  taxPeriod: string,
  country: {},
  clientReference: string,
};

interface ModalEditVatProps {
  open: boolean;
  actualData: any;
  companyId: number;
  handleEditVat: (values: declarationData) => void;
  handleClose: () => void;
  dispatch: any;
  intl;
  company;
  mapping,
  ta,
  euCountries,
  euCountriesLoading,
  obligations,
  obligationLoading,
  errorHandlerData;
  categories;
  categoriesLoading;
  errorHandlerCompany;
  errorHandlerDeclaration;
  loadingHandlerTA;
  loadingHandlerData;
  loadingHandlercompanies;
  loadingHandlerDeclaration;
}

export default enhance(ModalEditVat);
