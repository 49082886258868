import React, { useEffect } from 'react';
import { useField } from '@formiz/core';

import SimpleInput from 'components/core/SimpleInput/SimpleInput';

const FormizSimpleOutput = (props) => {
  const { errorMessage, isValid, isSubmitted, setValue, value } = useField(
    props
  );
  const {
    name,
    type,
    label,
    placeholder,
    required,
    disabled,
    autoFocus,
  } = props;
  const showError = !isValid && isSubmitted;

  useEffect(() => {
    setValue(props.getData)
  }, [props.getData, setValue])

  return (
    <SimpleInput
      autoFocus={autoFocus}
      name={name}
      type={type}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      required={required}
      disabled={disabled}
      error={showError && errorMessage}
    />
  );
};

export default FormizSimpleOutput;
