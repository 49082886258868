import React from 'react';
import { useField } from '@formiz/core';

import Checkbox from 'components/core/Checkbox/Checkbox';

const FormizCheckbox = (props) => {
  const { setValue, value, isValid, isSubmitted, errorMessage } = useField(
    props
  );
  const { name, label, disabled, checked, id, float, className, checkHide } = props;
  const showError = !isValid && isSubmitted;

  return (
    <Checkbox
      name={name}
      label={label}
      value={value}
      id={id}
      className={className}
      checked={value ?? checked}
      onChange={(e) => (setValue(e.target.checked))}
      disabled={disabled}
      error={showError && errorMessage}
      float={float}
      checkHide={checkHide}
    />
  );
};

export default FormizCheckbox;
