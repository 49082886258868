import React from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Icon, Checkbox, Modal } from 'semantic-ui-react';

import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { FiscalPeriod } from 'components/Form';
import { extractDocumentsSchema } from 'interfaces/formSchema/customsValidators';

const DownloadFilesModal = ({
  extractDocuments,
  loading,
  open,
  onMount,
  handleClose,
}: any) => {
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Modal.Content>
        <Formik
          initialValues={{
            dau: false,
          }}
          onSubmit={extractDocuments}
          validationSchema={extractDocumentsSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Wrapper
                display='flex'
                justifyContent='center'
                flexDirection='column'
              >
                <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
                  <Wrapper margin='0.5rem'>
                    <Field
                      placeholder='From'
                      name='startDate'
                      component={FiscalPeriod}
                      meta={{ errors, touched, setFieldValue, setFieldTouched }}
                    />
                  </Wrapper>
                  <Wrapper margin='0.5rem'>
                    <Field
                      placeholder='To'
                      name='endDate'
                      component={FiscalPeriod}
                      meta={{ errors, touched, setFieldValue, setFieldTouched }}
                    />
                  </Wrapper>
                </Wrapper>
                <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
                  {!onMount && (
                  <Field
                    label='DAU only'
                    name='dau'
                    component={Checkbox}
                    checked={values.dau}
                    onClick={() => {
                      !values.dau
                        ? setFieldValue('dau', true)
                        : setFieldValue('dau', false);
                    }}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />)}
                </Wrapper>
              </Wrapper>
              <Wrapper display='flex' margin='1rem' justifyContent='center'>
                <Button
                  disabled={loading}
                  {...(loading && {
                    icon: <Icon loading name='spinner' />,
                  })}
                  type='submit'
                  content='Download'
                  color='blue'
                />
              </Wrapper>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(DownloadFilesModal);
