import React, { FunctionComponent } from 'react';

import InputErrorLabel from 'components/core/InputErrorLabel/InputErrorLabel';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import { InputStyled, InputWrapper } from './SimpleInputStyles';

type SimpleInputProps = {
  name: string;
  value?: string;
  error?: string;
  disabled?: true;
  placeholder?: string;
  type?: string;
  label?: string;
  required?: string;
  autoFocus?: true;
  handlePrice?: void;
  hide?: boolean; 
  ref?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sendData?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const SimpleInput: FunctionComponent<SimpleInputProps> = ({
  label,
  type,
  name,
  placeholder,
  error,
  disabled,
  required,
  value,
  hide,
  autoFocus,
  onChange,
  onFocus,
  onBlur,
}) => {
  return (
    <InputWrapper>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && <MandatoryFieldLabel />}
        </label>
      )}
      <InputStyled
        autoFocus={autoFocus}
        disabled={disabled}
        type={type ?? 'text'}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        error={error}
        hide={hide}
      />
      {error && <InputErrorLabel message={error} />}
    </InputWrapper>
  );
};

export default SimpleInput;
