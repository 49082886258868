// Dependencies
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Content from '../components/content/content';
import CompaniesList from '../components/companiesList/companiesList';

// Stylesheets
import '../index.css';

/**
 * @name companiesPage.tsx
 *
 * @path /pages/companiesPage.tsx
 */
const CompaniesPage: React.FC = ({ history }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  return (
    <div className='pages'>
      <Helmet>
        <title>
          {t('headerStrings.companyButtonStrings.companies')}
          {t('helmetString')}
        </title>
      </Helmet>
      <Header />
      <Content
        title={t('companyPageStrings.companiesTitle')}
        homepage={false}
        history={`/spw`}
      />
      <CompaniesList />
      <Footer />
    </div>
  );
};

export default CompaniesPage;
