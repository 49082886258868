// Dependencies
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Content from '../components/content/content';
import Employees from '../components/employees/employees';

// Stylesheets
import '../index.css';

interface EmployeesPageProperties {
  companyId: number;
}

/**
 * @name employeesPage.tsx
 *
 * @path /pages/employeesPage.tsx
 */
const EmployeesPage: React.FC = ({ history, user }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const { companyId } = useParams<EmployeesPageProperties>();
  const adminOrOperator: boolean = user.operator || user.admin;

  return (
    <div className='pages'>
      <Helmet>
        <title>
          {t('headerStrings.companyButtonStrings.employees')}{' '}
          {t('helmetString')}
        </title>
      </Helmet>
      <Header />
      <Content
        title={t('headerStrings.companyButtonStrings.employees')}
        homepage={false}
        history={
          adminOrOperator
            ? `/spw/companies`
            : window.location.href.split('/').slice(0, -3).join('/')
        }
      />
      <Footer />
      <Employees companyId={companyId} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(EmployeesPage);
