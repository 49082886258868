import { Field, Formik } from 'formik';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Form, Header, Modal } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import { File } from 'components/Form';
import { kyaniteMassIntegrationSchema } from 'interfaces/formSchema';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';

const Index = ({ dispatch, open, handleClose, companyId }) => {
  const create = (values) => {
    dispatch(
      actions.quickfixes.createMassIntegration({
        params: {
          companyId: companyId,
        },
        data: {
          file: values.file,
        },
      })
    );
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content='New mass integration' />
      <Formik
        initialValues={{
          file: null,
        }}
        onSubmit={create}
        validationSchema={kyaniteMassIntegrationSchema}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <Modal.Content>
            <Form onSubmit={handleSubmit}>
              <Field
                label={<MandatoryFieldLabel content='File' />}
                name='file'
                component={File}
                error={errors?.file}
                touched={touched?.file}
                meta={{ setFieldValue, setFieldTouched }}
              />
              <Divider hidden />
              <Button
                disabled={isSubmitting}
                type='submit'
                content='Submit'
                color='green'
              />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
