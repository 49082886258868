import React from 'react';
import { getIn, ErrorMessage } from 'formik';
import { Popup, Icon } from 'semantic-ui-react';
import { Wrapper, Input, Percent } from './styles';
import { Error } from 'components/Form';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';

const Index = ({
  meta,
  placeholder,
  type,
  field,
  disabled,
  labeled,
  label,
  margin,
  labelPosition,
  labelVerticalPosition,
  bulleted,
  bullMsg,
  mandatory,
  onChange,
}: any) => {
  return (
    <Wrapper
      margin={margin}
      labelPosition={labelPosition}
      labelVerticalPosition={labelVerticalPosition}
    >
      {label && (
        <label>
          {label}
          {mandatory && <MandatoryFieldLabel />}
          {bulleted && (
            <Wrapper float='right'>
              <Popup
                trigger={<Icon name='info circle' />}
                content={bullMsg}
                size='mini'
              />
            </Wrapper>
          )}
        </label>
      )}

      <Input
        autoComplete="off"
        placeholder={placeholder}
        name={field.name}
        type={type}
        value={field.value}
        onBlur={(e) => {
          e.currentTarget.type = type;
          field.onBlur();
        }}
        onChange={(e, { name, value }) => {
          if (onChange) onChange(value);
          field.onChange(e);
        }}
        onFocus={(e) => (e.currentTarget.type = type)}
        disabled={disabled}
        error={
          getIn(meta.touched, field.name) && getIn(meta.errors, field.name)
            ? true
            : false
        }
      />

      <Percent>{labeled}</Percent>

      <ErrorMessage name={field.name} render={(msg) => <Error msg={msg} />} />
    </Wrapper>
  );
};

export default Index;
