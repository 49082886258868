import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const addDriverErrorSelector = (state) => state.companyDriver.errors.addDriver;
const removeDriverErrorSelector = (state) => state.companyDriver.errors.removeDriver;
const updateDriverErrorSelector = (state) => state.companyDriver.errors.updateDriver;
const updatedriverPermissionsErrorSelector = (state) =>
  state.companyDriver.errors.updatedriverPermissions;
const getdriverPermissionsErrorSelector = (state) =>
  state.companyDriver.errors.getdriverPermissions;
const getOneDriverErrorSelector = (state) => 
  state.companyDriver.errors.getOneDriver;
const getAllDriversErrorSelector = (state) =>
  state.companyDriver.errors.getAllDrivers;
const addDriverToCompanyErrorSelector = (state) => 
  state.companyDriver.errors.addDriverToCompany;

const errorsSelector = createErrorsSelector([
  addDriverErrorSelector,
  removeDriverErrorSelector,
  updateDriverErrorSelector,
  updatedriverPermissionsErrorSelector,
  getdriverPermissionsErrorSelector,
  getOneDriverErrorSelector,
  getAllDriversErrorSelector,
  addDriverToCompanyErrorSelector,
]);

// Loading
const addDriverLoadingSelector = (state) => 
  state.companyDriver.loading.addDriver;
const removeDriverLoadingSelector = (state) =>
  state.companyDriver.loading.removeDriver;
const updateDriverLoadingSelector = (state) =>
  state.companyDriver.loading.updateDriver;
const updatedriverPermissionsLoadingSelector = (state) =>
  state.companyDriver.loading.updatedriverPermissions;
const getdriverPermissionsLoadingSelector = (state) =>
  state.companyDriver.loading.getdriverPermissions;
const getOneDriverLoadingSelector = (state) =>
  state.companyDriver.loading.getOneDriver;
const getAllDriversLoadingSelector = (state) =>
  state.companyDriver.loading.getAllDrivers;
const addDriverToCompanyLoadingSelector = (state) => 
  state.companyDriver.loading.addDriverToCompany;

const loadingSelector = createLoadingSelector([
  addDriverLoadingSelector,
  removeDriverLoadingSelector,
  updatedriverPermissionsLoadingSelector,
  updateDriverLoadingSelector,
  getdriverPermissionsLoadingSelector,
  getOneDriverLoadingSelector,
  getAllDriversLoadingSelector,
  addDriverToCompanyLoadingSelector,
]);

// Success
const addDriverSuccessSelector = (state) => 
  state.companyDriver.success.addDriver;
const removeDriverSuccessSelector = (state) =>
  state.companyDriver.success.removeDriver;
const updateDriverSuccessSelector = (state) =>
  state.companyDriver.success.updateDriver;
const updatedriverPermissionsSuccessSelector = (state) =>
  state.companyDriver.success.updatedriverPermissions;
const getdriverPermissionsSuccessSelector = (state) =>
  state.companyDriver.success.getdriverPermissions;
const getOneDriverSuccessSelector = (state) =>
  state.companyDriver.success.getOneDriver;
const getAllDriversSuccessSelector = (state) =>
  state.companyDriver.success.getAllDrivers;
const addDriverToCompanySuccessSelector = (state) => 
  state.companyDriver.success.addDriverToCompany;

const successSelector = createSuccessSelector([
  addDriverSuccessSelector,
  removeDriverSuccessSelector,
  updateDriverSuccessSelector,
  updatedriverPermissionsSuccessSelector,
  getdriverPermissionsSuccessSelector,
  getOneDriverSuccessSelector,
  getAllDriversSuccessSelector,
  addDriverToCompanySuccessSelector,
]);

const driverSelector = (state) => 
  state.companyDriver.driver;
const getAllDriversSelector = (state) => 
  state.companyDriver.getAllDrivers;
const driverPermissionsSelector = (state) => 
  state.companyDriver.driverPermissions;
const driverCompaniesSelectedSelector = (state) => 
  state.companyDriver.driverCompaniesSelected;
const driverCompaniesSelector = (state) => 
  state.companyDriver.driverCompanies;
const addDriverToCompanySelector = (state) => 
  state.companyDriver.addDriverToCompany;

const errorSelector = (state) => state.companyDriver.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  driverSelector,
  getAllDriversSelector,
  getAllDriversLoadingSelector,
  driverPermissionsSelector,
  driverCompaniesSelectedSelector,
  driverCompaniesSelector,
  addDriverToCompanySelector,
  addDriverToCompanyLoadingSelector,
  errorSelector,
};
