import React from 'react';
import { Header, Modal } from 'semantic-ui-react';

import Button from 'components/Button';

const ModalDeleteNotificationsCertificates = ({
  open,
  handleClose,
  deleteAll,
}: ModalDeleteNotificationsCertificatesProps) => {
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='trash alternate' content='Delete all notifications' />
      <Modal.Content>
        Are you sure you want to delete all notifications?
      </Modal.Content>
      <Modal.Actions>
        <Button
          content='Cancel'
          color='red'
          display='inline-block'
          margin='0 1rem 0 1rem'
          onClick={() => handleClose()}
        />
        <Button
          content='Validate'
          display='inline-block'
          margin='0 1rem 0 1rem'
          onClick={deleteAll}
        />
      </Modal.Actions>
    </Modal>
  );
};

interface ModalDeleteNotificationsCertificatesProps {
  open: boolean;
  handleClose: () => void;
  deleteAll: () => void;
}

export default ModalDeleteNotificationsCertificates;
