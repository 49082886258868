import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    getAllNotification() {
      const getAllNotificationApi = routesApi.notification.allNotification;
      return api.request({
        method: getAllNotificationApi.method,
        route: getAllNotificationApi.path,
      });
    },
    getNumberUnreadNotification() {
      const getNumberUnreadNotificationApi =
        routesApi.notification.notificationUnRead;
      return api.request({
        method: getNumberUnreadNotificationApi.method,
        route: getNumberUnreadNotificationApi.path,
      });
    },
    notificationIsRead(data: dataReqI) {
      const notificationIsReadApi = routesApi.notification.notificationIsRead;
      return api.request({
        method: notificationIsReadApi.method,
        route: notificationIsReadApi.path,
        params: data.params,
      });
    },
    notificationAllRead() {
      const notificationAllReadApi = routesApi.notification.notificationAllRead;
      return api.request({
        method: notificationAllReadApi.method,
        route: notificationAllReadApi.path,
      });
    },
    notificationIsShow(data: dataReqI) {
      const notificationIsShowApi = routesApi.notification.notificationIsShow;
      return api.request({
        method: notificationIsShowApi.method,
        route: notificationIsShowApi.path,
        params: data.params,
      });
    },
    notificationAllDelete(data: dataReqI) {
      const notificationAllDeleteApi = routesApi.notification.notificationAllDelete;
      return api.request({
        method: notificationAllDeleteApi.method,
        route: notificationAllDeleteApi.path,
        params: data.params,
      });
    },
  };
};
