import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Icon } from 'semantic-ui-react';
import { selectors, actions } from 'store';

import {
  NotificationNumber,
  NotificationWrapper,
} from './NotificationBellStyles';

const NotificationBell = ({ dispatch, numberNotifications, user }) => {
  const handleClick = () => dispatch(actions.notifications.openNotification());

  useEffect(() => {
    if(user){
      dispatch(actions.notifications.getNumberReadNotification());
      dispatch(actions.socket.subscribeNumberNotification(user.id));
  
      return () => {
        dispatch(actions.socket.unsubscribeNumberNotification(user.id));
      };
    }
  }, [dispatch, user]);

  return (
    <NotificationWrapper onClick={handleClick}>
      <Icon className='notificationBell' name='bell' inverted size='large' />
      {numberNotifications !== 0 && (
        <NotificationNumber className='notificationBell'>
          {numberNotifications > 100 ? '99+' : numberNotifications}
        </NotificationNumber>
      )}
    </NotificationWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    numberNotifications: selectors.notifications.numberUnreadNotificationSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(NotificationBell);
