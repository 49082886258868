import React from "react";

import ClickableText from "components/ClickableText";
import TextColored from "components/QuickFixes/DeliveryForm/TextColored";

import { Table } from "semantic-ui-react";

const Index = ({ data, path, history }: any) => {
  return data === "pending validation" ? (
    <Table.Cell>
      <ClickableText onClick={() => history.push(path)} color="red">
        Awaiting for validation
      </ClickableText>
    </Table.Cell>
  ) : (
    <Table.Cell>
      <TextColored color="limegreen">Signed by all parties</TextColored>
    </Table.Cell>
  );
};

export default Index;
