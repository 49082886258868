import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getAllNotificationErrorSelector = (state) => state.notifications.errors.getAllNotification;
const getNumberReadNotificationErrorSelector = (state) => state.notifications.errors.getNumberReadNotification;

const errorsSelector = createErrorsSelector([
  getAllNotificationErrorSelector,
  getNumberReadNotificationErrorSelector,
]);

// Loading
const getAllNotificationLoadingSelector = (state) => state.notifications.loading.getAllNotification;
const getNumberReadNotificationLoadingSelector = (state) => state.notifications.loading.getNumberReadNotification;

const loadingSelector = createLoadingSelector([
  getAllNotificationLoadingSelector,
  getNumberReadNotificationLoadingSelector,
]);

// Success
const getAllNotificationSuccessSelector = (state) => state.notifications.success.getAllNotification;
const getNumberReadNotificationSuccessSelector = (state) => state.notifications.success.getNumberReadNotification;

const successSelector = createSuccessSelector([
  getAllNotificationSuccessSelector,
  getNumberReadNotificationSuccessSelector,
]);

// Notifications
const allNotificationSelector = (state) => state.notifications.allNotification;
const numberUnreadNotificationSelector = (state) => state.notifications.unreadNotification;

// Error handler
const errorSelector = (state) => state.notifications.error;

// loading
const allNotificationLoadingSelector = (state) => state.notifications.loading.getAllNotification;
const numberUnreadNotificationLoadingSelector = (state) => state.notifications.loading.getNumberUnreadNotification;
const openNotificationSelector = (state) => state.notifications.openNotification;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  allNotificationSelector,
  numberUnreadNotificationSelector,
  errorSelector,
  allNotificationLoadingSelector,
  numberUnreadNotificationLoadingSelector,
  openNotificationSelector,
};
