import About from "views/About";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.about,
    component: About,
    exact: true,
    authorizedRoles: []
  }
];
