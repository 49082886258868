import actionTypes from 'store/company/keyinfo/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {


      case actionTypes.GET_KEYINFOS:
        try {
          next({ type: actionTypes.GET_KEYINFOS_LOADING });
          const response = await request.getKeyInfos({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_KEYINFOS_SUCCESS,
            payload: response.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_KEYINFOS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;
      case actionTypes.ADD_KEYINFO:
        try {
          next({ type: actionTypes.ADD_KEYINFO_LOADING });
          await request.addKeyInfo({ ...action.payload });
          next({
            type: actionTypes.ADD_KEYINFO_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.ADD_KEYINFO_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;
      case actionTypes.REMOVE_KEYINFO:
        try {
          next({ type: actionTypes.REMOVE_KEYINFO_LOADING });
          await request.removeKeyInfo({ ...action.payload });
          next({
            type: actionTypes.REMOVE_KEYINFO_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.REMOVE_KEYINFO_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;
      case actionTypes.UPDATE_KEYINFO:
        try {
          next({ type: actionTypes.UPDATE_KEYINFO_LOADING });
          await request.updateKeyInfo({ ...action.payload });
          next({
            type: actionTypes.UPDATE_KEYINFO_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_KEYINFO_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;
      case actionTypes.SET_DONE_KEYINFO:
        try {
          next({ type: actionTypes.SET_DONE_KEYINFO_LOADING });

          const response = await request.keyInfoSetDone({
            ...action.payload,
          });
          next({
            type: actionTypes.SET_DONE_KEYINFO_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.SET_DONE_KEYINFO_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
      break; 
      
      case actionTypes.CHECK_DONE_KEYINFO:
        try {
          next({ type: actionTypes.CHECK_DONE_KEYINFO_LOADING });

          const response = await request.checkKeyInfoSetDone({
            ...action.payload,
          });
          next({
            type: actionTypes.CHECK_DONE_KEYINFO_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.CHECK_DONE_KEYINFO_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
      break;  
      default:
        next(action);
        break;
    }
  };
}
