import styled from "@emotion/styled"

export const PopupTile = styled.span(() => ({
    cursor: 'pointer'
  }))

export const Instruction = styled.div(() => ({
    margin: '0.2rem 0',
    padding: '0.2rem 0'
  }))

  export const gridTraces = {minWidth: '168px'}
  export const gridClerk = {minWidth: '170px'}
  export const gridTypes = {maxWidth: '75px'} 
  export const gridCustoms = {minWidth: '180px'} 