import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getOneChedErrorSelector = (state) => 
state.customsStatistics.errors.getOneChed;
const getComputedChedsErrorSelector = (state) => 
state.customsStatistics.errors.getComputedCheds;
const getDeclarationStatsErrorSelector = (state) => 
state.customsStatistics.errors.getDeclarations;
const getStatsErrorSelector = (state) => 
state.customsStatistics.errors.globalStats;
const getChedStatsErrorSelector = (state) => 
state.customsStatistics.errors.chedStats;
const getClerksStatsErrorsSelector = (state) => 
state.customsStatistics.errors.clerkStats;
const getLastCustomsErrorSelector = (state) => 
state.customsStatistics.errors.lastCD;
const getCustomsForComparisonErrorSelector = (state) => 
state.customsStatistics.errors.comparisonStats;

const errorsSelector = createErrorsSelector([
  getOneChedErrorSelector,
  getComputedChedsErrorSelector,
  getDeclarationStatsErrorSelector,
  getChedStatsErrorSelector,
  getStatsErrorSelector,
  getClerksStatsErrorsSelector,
  getLastCustomsErrorSelector,
  getCustomsForComparisonErrorSelector
]);

// Loading
const getComputedChedsLoadingSelector = (state) =>
  state.customsDeclaration.loading.getComputedCheds;
const getOneChedLoadingSelector = (state) =>
  state.customsStatistics.loading.getOneChed;
const getDeclarationStatsLoadingSelector = (state) => 
state.customsStatistics.loading.getDeclarations;
const getStatsLoadingSelector = (state) => 
state.customsStatistics.loading.globalStats;
const getChedStatsLoadingSelector = (state) => 
state.customsStatistics.loading.chedStats;
const getClerksStatsLoadingSelector = (state) => 
state.customsStatistics.loading.clerkStats;
const getLastCustomsLoadingSelector = (state) => 
state.customsStatistics.loading.lastCD;
const getCustomsForComparisonLoadingSelector = (state) => 
state.customsStatistics.loading.comparisonStats;

const loadingSelector = createLoadingSelector([
  getComputedChedsLoadingSelector,
  getOneChedLoadingSelector,
  getDeclarationStatsLoadingSelector,
  getStatsLoadingSelector,
  getChedStatsLoadingSelector,
  //getChedStatsErrorSelector,
  getClerksStatsLoadingSelector,
  getCustomsForComparisonLoadingSelector,
]);

// Success
const getComputedChedsSuccessSelector = (state) =>
  state.customsDeclaration.success.getComputedCheds;
  const getOneChedSuccessSelector = (state) =>
  state.customsStatistics.success.getOneChed;
const getDeclarationStatsSuccessSelector = (state) => 
  state.customsStatistics.success.getDeclarations;
const getStatsSuccessSelector = (state) => 
state.customsStatistics.success.globalStats;
const getChedStatsSuccessSelector = (state) => 
state.customsStatistics.success.chedStats;
const getClerksStatsSuccessSelector = (state) => 
state.customsStatistics.success.clerkStats;
const getLastCustomsSuccessSelector = (state) => 
state.customsStatistics.success.lastCD;
const getCustomsForComparisonSuccessSelector = (state) => 
state.customsStatistics.success.comparisonStats;


const successSelector = createSuccessSelector([
  getComputedChedsSuccessSelector,
  getOneChedSuccessSelector,
  getDeclarationStatsSuccessSelector,
  getChedStatsSuccessSelector,
  getStatsSuccessSelector,
  getClerksStatsSuccessSelector,
  getCustomsForComparisonSuccessSelector
]);

// get
const getComputedChedsSelector = (state) => 
state.customsStatistics.computedCheds;
const getOneChedSelector = (state) => 
state.customsStatistics.oneChed;
const getDeclarationStatsSelector = (state) => 
state.customsStatistics.getDeclarations;
const getStatsSelector = (state) => 
state.customsStatistics.globalStats;
const getChedStatsSelector = (state) => 
state.customsStatistics.chedStats;
const getClerksStatsSelector = (state) => 
state.customsStatistics.clerkStats;
const getLastCustomsSelector = (state) => 
state.customsStatistics.lastCD;
const getCustomsForComparisonSelector = (state) => 
state.customsStatistics.comparisonStats;

const chedsLoadingSelector = createLoadingSelector([
  (state) => state.customsDeclaration.loading.getComputedCheds,
  (state) => state.customsDeclaration.loading.getOneChed,
]);

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  getComputedChedsSelector,
  getOneChedSelector,
  getDeclarationStatsSelector,
  getStatsSelector,
  getChedStatsSelector,
  getClerksStatsSelector,
  getLastCustomsLoadingSelector,
  getLastCustomsSuccessSelector,
  getLastCustomsSelector,
  getCustomsForComparisonSelector,
  getCustomsForComparisonLoadingSelector,
  chedsLoadingSelector,
};
