import React from "react";

import Error from "./Error";
import Success from "./Success";

const Index = ({ statement, error }: any) => {
  return (
    <>
      {(statement && <Success statement={statement} />) ||
        (error && <Error error={error} />)}
    </>
  );
};

export default Index;
