import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getAllOperatorErrorSelector = (state) =>
  state.operators.errors.getAllOperator;
const getOneOperatorErrorSelector = (state) =>
  state.operators.errors.getOneOperator;
const addOneOperatorRoleErrorSelector = (state) =>
  state.operators.errors.addOneOperatorRole;

const errorsSelector = createErrorsSelector([
  getAllOperatorErrorSelector,
  getOneOperatorErrorSelector,
]);

// Loading
const getAllOperatorLoadingSelector = (state) =>
  state.operators.loading.getAllOperator;
const getOneOperatorLoadingSelector = (state) =>
  state.operators.loading.getOneOperator;
  const addOneOperatorRoleLoadingSelector = (state) =>
  state.operators.loading.addOneOperatorRole;

const loadingSelector = createLoadingSelector([
  getAllOperatorLoadingSelector,
  getOneOperatorLoadingSelector,
]);

// Success
const getAllOperatorSuccessSelector = (state) =>
  state.operators.success.getAllOperator;
const getOneOperatorSuccessSelector = (state) =>
  state.operators.success.getOneOperator;
const addOneOperatorRoleSucessSelector = (state) =>
  state.operators.success.addOneOperatorRole;

const successSelector = createSuccessSelector([
  getAllOperatorSuccessSelector,
  getOneOperatorSuccessSelector
]);

// Operator
const allOperatorSelector = (state) => state.operators.allOperator;
const oneOperatorSelector = (state) => state.operators.oneOperator;
const addOneOperatorRoleSelector = (state) =>
  state.operators.addOneOperatorRole;

// Error handler
const errorSelector = (state) => state.operators.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  allOperatorSelector,
  getAllOperatorLoadingSelector,
  oneOperatorSelector,
  addOneOperatorRoleErrorSelector,
  addOneOperatorRoleSucessSelector,
  addOneOperatorRoleLoadingSelector,
  addOneOperatorRoleSelector,
  errorSelector,
};
