import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getAllTransporterErrorSelector = (state) =>
  state.company.errors.getAllTransporter;
const getUserCompaniesErrorSelector = (state) =>
  state.company.errors.getUserCompanies;
const getAllErrorSelector = (state) => state.company.errors.getAll;
const getPermissionsErrorSelector = (state) =>
  state.company.errors.getPermissions;
const getOneErrorSelector = (state) => state.company.errors.getOne;
const getOneForNavigationErrorSelector = (state) =>
  state.company.errors.getOneForNavigation;
const createCompanyErrorSelector = (state) => state.company.errors.create;
const updateCompanyErrorSelector = (state) => state.company.errors.update;
const deleteCompanyErrorSelector = (state) => state.company.errors.remove;

const errorsSelector = createErrorsSelector([
  getAllTransporterErrorSelector,
  getUserCompaniesErrorSelector,
  getAllErrorSelector,
  getPermissionsErrorSelector,
  getOneErrorSelector,
  getOneForNavigationErrorSelector,
  createCompanyErrorSelector,
  updateCompanyErrorSelector,
  deleteCompanyErrorSelector,
]);

// Loading
const getAllTransporterLoadingSelector = (state) =>
  state.company.loading.getAllTransporter;
const getUserCompaniesLoadingSelector = (state) =>
  state.company.loading.getUserCompanies;

const getAllLoadingSelector = (state) => state.company.loading.getAll;

const getPermissionsLoadingSelector = (state) =>
  state.company.loading.getPermissions;
const getOneLoadingSelector = (state) => state.company.loading.getOne;
const getOneForNavigationLoadingSelector = (state) =>
  state.company.loading.getOneForNavigation;
const createCompanyLoadingSelector = (state) => state.company.loading.create;
const updateCompanyLoadingSelector = (state) => state.company.loading.update;
const deleteCompanyLoadingSelector = (state) => state.company.loading.remove;

const loadingSelector = createLoadingSelector([
  getAllTransporterLoadingSelector,
  getUserCompaniesLoadingSelector,
  getAllLoadingSelector,
  getPermissionsLoadingSelector,
  getOneLoadingSelector,
  getOneForNavigationLoadingSelector,
  createCompanyLoadingSelector,
  updateCompanyLoadingSelector,
  deleteCompanyLoadingSelector,
]);

// Success
const getAllTransporterSuccessSelector = (state) =>
  state.company.success.getAllTransporter;
const getUserCompaniesSuccessSelector = (state) =>
  state.company.success.getUserCompanies;
const getAllSuccessSelector = (state) => state.company.success.getAll;

const getPermissionsSuccessSelector = (state) =>
  state.company.success.getPermissions;
const getOneSuccessSelector = (state) => state.company.success.getOne;
const getOneForNavigationSuccessSelector = (state) =>
  state.company.success.getOneForNavigation;
const createCompanySuccessSelector = (state) => state.company.success.create;
const updateCompanySuccessSelector = (state) => state.company.success.update;
const deleteCompanySuccessSelector = (state) => state.company.success.remove;

const successSelector = createSuccessSelector([
  getAllTransporterSuccessSelector,
  getUserCompaniesSuccessSelector,
  getAllSuccessSelector,
  getPermissionsSuccessSelector,
  getOneSuccessSelector,
  getOneForNavigationSuccessSelector,
  createCompanySuccessSelector,
  updateCompanySuccessSelector,
  deleteCompanySuccessSelector,
]);

// Transporters
const transportersSelector = (state) => state.company.transporters;

const hasSubscribedToSelector = (state) => state.company.hasSubscribedTo;
const hasSubscribedToLoadingSelector = (state) =>
  state.company.loading.hasSubscribedTo;

// companies
const companiesSelector = (state) => state.company.companies;

// permissions
const permissionsSelector = (state) => state.company.permissions;

// company
const companySelector = (state) => state.company.company;

//Errors Handlers
const errorSelector = (state) => state.company.error;

//Loading
const companiesLoadingSelector = (state) => state.company.loading.getAll;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  getOneLoadingSelector,
  transportersSelector,
  hasSubscribedToSelector,
  hasSubscribedToLoadingSelector,
  companiesSelector,
  permissionsSelector,
  companySelector,
  errorSelector,
  companiesLoadingSelector,
};
