import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Form, Header, Segment, Checkbox } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import { Input } from 'components/Form';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { updateUserSchema } from 'interfaces/formSchema';

const Index = ({ dispatch, user, match, history, loadingHandlerusers }) => {

  const userId = match.params.userId 

  useEffect(() => {
    dispatch(actions.user.getUser({ params: { userId: userId } }));

    return () => {
      dispatch(actions.user.reset(['user', 'error']));
    };
  }, [dispatch, userId]);

  const save = (values) => {
    dispatch(
      actions.user.update({
        data: { ...values },
        params: { userId: userId },
      })
    );
    history.push('/admin/users');
  };

  return ((loadingHandlerusers && <Loader />) || (
    <Wrapper margin='5rem auto' width='70%'>
      <Header icon='edit' content='Edit User' />
      {user && (
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            admin: user.admin,
            operator: user.operator,
          }}
          enableReinitialize={true}
          onSubmit={save}
          validationSchema={updateUserSchema}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
          }: any) => (
            <Wrapper>
              {((user === null ||
                Object.getOwnPropertyNames(user).length === 0) &&
                loadingHandlerusers && <Loader />) || (
                <>
                  <Form onSubmit={handleSubmit}>
                    <Header as='h3' attached='top'>
                      <Header.Content>User's information</Header.Content>
                    </Header>
                    <Segment attached>
                      <Field
                        label='First name :'
                        placeholder='First name'
                        name='firstName'
                        type='text'
                        component={Input}
                        meta={{ errors, touched }}
                      />
                      <Divider hidden />
                      <Field
                        label='Last name :'
                        placeholder='Last name'
                        name='lastName'
                        type='text'
                        component={Input}
                        meta={{ errors, touched }}
                      />
                      <Divider hidden />
                      <Field
                        label='Email :'
                        placeholder='Email'
                        name='email'
                        type='email'
                        component={Input}
                        meta={{ errors, touched }}
                      />
                      <Divider hidden />
                      <Field
                        label='Admin'
                        checked={values.admin}
                        name='admin'
                        onClick={() => {
                          setFieldValue('admin', !values.admin);
                        }}
                        component={Checkbox}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                      />
                      <Divider hidden />
                      <Field
                        label='Operator'
                        checked={values.operator}
                        name='operator'
                        onClick={() => {
                          setFieldValue('operator', !values.operator);
                        }}
                        component={Checkbox}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                      />
                    </Segment>
                    <Divider hidden />
                    <Button
                      type='submit'
                      content='Submit'
                      color='green'
                      margin='0px'
                    />
                  </Form>
                  <Divider hidden />
                  <Button
                    content='Back to users'
                    icon='reply'
                    type='submit'
                    labelPosition='right'
                    onClick={() => history.push(`/admin/users`)}
                    margin='5px'
                  />
                </>
              )}
            </Wrapper>
          )}
        </Formik>
      )}
    </Wrapper>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.user.userSelector(state),
    errorHandlerData: selectors.user.errorSelector(state),
    loadingHandlerusers: selectors.user.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
