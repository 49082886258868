export interface dataReqI {
  params?: any;
  data?: any;
  query?: any;
}

export const VATnumbersSchema = {
  AT: /^(AT)(U\d{8}$)/i, // Austria
  BE: /^(BE)(\d{10}$)/i, // Belgium
  BG: /^(BG)(\d{9,10}$)/i, // Bulgaria
  CY: /^(CY)([0-9A-Z]{9}$)/i, // Cyprus
  CZ: /^(CZ)(\d{8,10})?$/i, // Czech Republic
  DE: /^(DE)([1-9]\d{8}$)/i, // Germany
  DK: /^(DK)(\d{8}$)/i, // Denmark
  EE: /^(EE)(\d{9}$)/i, // Estonia
  EL: /^(EL)(\d{9}$)/i, // Greece
  ES: /^(ES)([0-9A-Z][0-9]{7}[0-9A-Z]$)/i, // Spain
  EU: /^(EU)(\d{9}$)/i, // EU-type
  FI: /^(FI)(\d{8}$)/i, // Finland
  FR: /^(FR)([0-9A-Z]{2}[0-9]{9})/i, // France
  GR: /^(GR)(\d{8,9}$)/i, // Greece
  HR: /^(HR)(\d{11}$)/i, // Croatia
  HU: /^(HU)(\d{8}$)/i, // Hungary
  IE: /^(IE)([0-9A-Z\*\+]{7}[A-Z]{1,2}$)/i, // Ireland
  IT: /^(IT)(\d{11}$)/i, // Italy
  LV: /^(LV)(\d{11}$)/i, // Latvia
  LT: /^(LT)(\d{9}$|\d{12}$)/i, // Lithunia
  LU: /^(LU)(\d{8}$)/i, // Luxembourg
  MT: /^(MT)([1-9]\d{7}$)/i, // Malta
  NL: /^(NL)(\d{9}B\d{2}$)/i, // Netherlands
  NO: /^(NO)(\d{9}$)/i, // Norway (not EU)
  PL: /^(PL)(\d{10}$)/i, // Poland
  PT: /^(PT)(\d{9}$)/i, // Portugal
  RO: /^(RO)([1-9]\d{1,9}$)/i, // Romania
  RU: /^(RU)(\d{10}$|\d{12}$)/i, // Russia
  RS: /^(RS)(\d{9}$)/i, // Serbia
  SI: /^(SI)([1-9]\d{7}$)/i, // Slovenia
  SK: /^(SK)([1-9]\d[(2-4)|(6-9)]\d{7}$)/i, // Slovakia Republic
  SE: /^(SE)(\d{10}01$)/i, // Sweden
};
