import styled from '@emotion/styled';
import { Segment, Placeholder } from 'semantic-ui-react';

export const SegmentContent = styled(Segment)`
  &&& {
    padding: 2rem 1rem;
    border: none;
    display: flex;
    justify-content: space-between;
    margin: 0.3rem;
  }
`;
export const PlaceholderLeft = styled(Placeholder)`
  &&& {
    width: 75%;
    display: inline-block;
  }
`;

export const PlaceholderRight = styled(Placeholder)`
  &&& {
    width: 10%;
    display: inline-block;
    margin-top: 0px !important;
  }
`;

export const Separation = styled(Placeholder.Image)`
  &&& {
    height: 1rem !important;
    background-color: white;
  }
`;
