// Dependencies
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../components/header/header';
import Content from '../components/content/content';
import Footer from '../components/footer/footer';
import InspectionDetails from '../components/inspection/inspectionDetails';

/**
 * @name inspectionDetailsPage.tsx
 *
 * @path /pages/inspectionDetailsPage.tsx
 *
 * @description Defines inspection details page's content for a defined mission
 */
const InspectionDetailsPage: React.FC = ({ history }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  return (
    <div className='pages'>
      <Helmet>
        <title>
          {t('inspectionPageStrings.title')}
          {t('helmetString')}
        </title>
      </Helmet>

      <Header />
      <Content
        title={t('inspectionPageStrings.title')}
        homepage={false}
        history={history}
      />
      <InspectionDetails />
      <Footer />
    </div>
  );
};

export default InspectionDetailsPage;
