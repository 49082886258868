import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  createAddress(data: dataReqI) {
    return {
      type: actionTypes.CREATE_ADDRESS,
      payload: data
    };
  },
  getAllAddresses(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_ADDRESS,
      payload: data
    };
  },
  getOneAddress(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_ADDRESS,
      payload: data
    };
  },
  deleteAddress(data: dataReqI) {
    return {
      type: actionTypes.DELETE_ADDRESS,
      payload: data
    };
  },
  updateAddress(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_ADDRESS,
      payload: data
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys
      }
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys
      }
    };
  }
};
