import styled from "@emotion/styled";


export const DropWrapper = styled.div`
    display: inline-flex;
    float: none;
`

export const DropP = styled.div`
    font-weight: bold;
    margin: 0 0 0 10px;
    padding: 1px 8px;
    background: #e8e8e8;
    border-radius: 4px;
    text-align: left;
    font-size: 11px;
`