import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Divider, Transition, Message, Modal, Header } from 'semantic-ui-react';
import { Field, FieldArray, Formik, ErrorMessage } from 'formik';

import Wrapper from 'components/core/Wrapper/Wrapper';
import { Error } from 'components/Form';
import Button from 'components/Button';
import { vatObligationSchema } from 'interfaces/formSchema';
import Dropdown from 'components/Dropdown';

const Index = ({
  intl,
  dispatch,
  companyName,
  obligations,
  companyId,
  open,
  handleClose,
  euCountries,
  euCountriesLoading,
}) => {
  const [vatObligation, setVatObligation] = useState(null);
  const [countriesList, setcountriesList] = useState([]);

  useEffect(() => {
    if(obligations){
        let tab = []
        obligations.forEach((o, index) => {
          let c = euCountries.find((e) => e.id === o.Country_Id)
          tab.push({
            country: {
              key: c.id,
              value: c.country_code,
              text:  intl.formatMessage({
                id: `countries.name.${c.country_code}`,
              }),
            },
            periodicity: o.periodicity
          })
        })
        setVatObligation(tab)
    }
  }, [obligations, euCountries, intl]);

  useEffect(() => {
    if (euCountries !== null) {
      setcountriesList(
        euCountries.map((country, index) => {
          return {
            key: index,
            value: {
              key: country.id,
              value: country.country_code,
              text:  intl.formatMessage({
                id: `countries.name.${country.country_code}`,
              }),
            },
            text: intl.formatMessage({
              id: `countries.name.${country.country_code}`,
            }),
            flag: country.country_code.toLowerCase(),
          };
        })
      );
    }

  }, [euCountries, intl]);


  const save = (values) => {
    dispatch(
        actions.fiscalVat.createObligation({
          params: { companyId: companyId },
          data: {
            obligations: values.obligation.map(e => {
              return {
                periodicity: e.periodicity,
                country: e.country.key,
                iso: e.country.value
              }
            }),
          },
        })
      );
  };


  return (
    <Modal open={open} onClose={handleClose} loading={obligations && obligations.length === 0} closeIcon>
    <Header icon='plus circle' content={`${companyName} - Obligations`} />
    <Formik
      initialValues={{
        obligation: vatObligation || [], 
      }}
      enableReinitialize={true}
      onSubmit={save}
      validationSchema={vatObligationSchema}
    >
      {({
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
      }: any) => (
        <Wrapper>
          <Form onSubmit={handleSubmit}>
            <Wrapper padding='0' display='flex' flexDirection='column' alignItems='center'>
            <Divider hidden />
            <FieldArray
              name='obligation'
              render={(arrayHelpers) => (
                <>
                <Transition.Group
                  duration={200}
                >              
                  {obligations && obligations.length === 0 && (                            
                    <Message info>Assign some countries and obligations to this company account</Message>                            
                  )} 
                  <Divider hidden />             
                  {values.obligation.map((e: any, index) => {
                    return (                   
                    <Wrapper key={index} display='flex' width='60%' height='55px' alignItems='center' justifyContent='space-between' flexWrap='nowrap'> 
                      <Wrapper key={`${index}-a`} width='42%'>                         
                      <Field
                        key={`${index}-ab`}
                        placeholder='select country...'
                        name={`obligation[${index}].country`}
                        component={Dropdown}
                        options={countriesList}
                        loading={euCountriesLoading}             
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                      />
                      </Wrapper>
                      <Wrapper key={`${index}-abc`} width='42%'>                         
                      <Field
                        key={`${index}-abcd`}
                        placeholder='select periodicity...'
                        name={`obligation[${index}].periodicity`}
                        component={Dropdown}
                        options={[
                          {
                            key: 0,
                            value: 'Annually',
                            text: 'Annually'
                          },
                          {
                            key: 1,
                            value: 'Bimonthly',
                            text: 'Bimonthly'
                          },
                          {
                            key: 2,
                            value: 'Biannual',
                            text: 'Biannual'
                          },
                          {
                            key: 3,
                            value: 'Monthly',
                            text: 'Monthly'
                          },
                          {
                            key: 4,
                            value: 'Punctual',
                            text: 'Punctual'
                          },
                          {
                            key: 6,
                            value: 'Halfyearly',
                            text: 'Halfyearly'
                          },
                          {
                            key: 7,
                            value: 'Quartely',
                            text: 'Quartely'
                          },                                                                 
                        ]}
           
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                      />
                      </Wrapper>
                      <Button
                        icon='minus'
                        type='button'
                        color='red'
                        onClick={() => {
                          arrayHelpers.remove(index)
                        }}
                        maxHeight='2.7rem'
                      />                   
                    </Wrapper>
             
                  );
                })}
              </Transition.Group>
              <Divider hidden/>
              <Button
                icon='plus'
                type='button'
                onClick={() =>                       
                  arrayHelpers.push({
                    country: undefined,
                    periodicity: undefined,
                  })                           
                }
                margin='0px'
                position='center'
              /> 
            </>
          )}                                            
          />            
            </Wrapper>
            <ErrorMessage
              name='permissions'
              render={(msg) => <Error msg={msg} />}
            />
            <Divider hidden />
            <Button type='submit' content='Submit' color='green' margin='0px' />
          </Form>
          <Divider hidden/>   
        </Wrapper>
    
      )}
    </Formik>
 </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    generalPermissions: selectors.admin.permissionsSelector(state),
    operatorLoading: selectors.operators.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
