import actionTypes from 'store/company/invoice/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.CREATE_CREDIT:
        try {
          next({ type: actionTypes.CREATE_CREDIT_LOADING });
          const response = await request.createCredit({ ...action.payload });
          next({
            type: actionTypes.CREATE_CREDIT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_CREDIT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_UNPAID:
        try {
          next({ type: actionTypes.GET_UNPAID_LOADING });
          const response = await request.getUnpaid({ ...action.payload });
          next({
            type: actionTypes.GET_UNPAID_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_UNPAID_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ALL:
        try {
          next({ type: actionTypes.GET_ALL_LOADING });
          const response = await request.getAll({ ...action.payload });
          next({
            type: actionTypes.GET_ALL_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ONE:
        try {
          next({ type: actionTypes.GET_ONE_LOADING });

          const response = await request.getOne({ ...action.payload });

          const file = new Blob([response.data], {
            type: response.data.type,
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          next({
            type: actionTypes.GET_ONE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.PAY_INVOICE:
        try {
          next({ type: actionTypes.PAY_INVOICE_LOADING });
          const response = await request.payInvoice({ ...action.payload });
          next({
            type: actionTypes.PAY_INVOICE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.PAY_INVOICE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
