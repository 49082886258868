import React, { useState } from 'react';
import { Modal, Checkbox, Header, Divider } from 'semantic-ui-react';
import Button from 'components/Button';
import { actions } from 'store';

const Index = ({
  deliveryId,
  companyId,
  isModalOpen,
  handleClose,
  dispatch,
}: any) => {
  const [transporterNotification, setTransporterNotification] = useState(false);
  const [buyerNotification, setBuyerNotification] = useState(false);

  const onSend = () => {
    handleClose();
    dispatch(
      actions.quickfixes.askForProof({
        params: {
          deliveryId,
          companyId,
        },
        data: {
          transporter: transporterNotification,
          buyer: buyerNotification,
        },
      })
    );
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      closeOnEscape={true}
      size='tiny'
      closeIcon
    >
      <Header icon='bell' content='Ask for proofs' />
      <>
        <Modal.Content>
          <Checkbox
            label='Buyer'
            name='buyer'
            value='buyer'
            checked={buyerNotification}
            onChange={() => setBuyerNotification(!buyerNotification)}
          />
          <Divider hidden />
          <Checkbox
            label='Transporter'
            name='transporter'
            value='transporter'
            checked={transporterNotification}
            onChange={() =>
              setTransporterNotification(!transporterNotification)
            }
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            type='submit'
            content='Send request'
            color='blue'
            onClick={() => onSend()}
          />
        </Modal.Actions>
      </>
    </Modal>
  );
};

export default Index;
