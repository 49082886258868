// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import ContentButtonProperties from '../../interfaces/contentButtonProperties';

// Stylesheets
import './content.css';

/**
 * @name contentButton.tsx
 * @implements contentButtonProperties.tsx
 *
 * @path /components/content/contentButton.tsx
 *
 * @description Defines a button in page's body
 *
 * @param title button's title
 */
const ContentButton: React.FC<ContentButtonProperties> = ({
  title,
  history,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  // Defines the action that must be executed when user performed a click on back button
  const handleClickBackButton = () => {
    window.location.href = history;
  };

  return (
    <button className='contentButton' onClick={handleClickBackButton}>
      {t(title)}
    </button>
  );
};

export default ContentButton;
