import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Divider, Accordion } from 'semantic-ui-react';
import { Field, FieldArray, Formik, ErrorMessage } from 'formik';

import { Checkbox } from 'components/Form';
import SimpleCheckbox from 'components/Checkbox';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Error } from 'components/Form';
import Button from 'components/Button';
import { userPermissionsSchema } from 'interfaces/formSchema';

const Index = ({
  dispatch,
  operatorId,
  generalPermissions,
  permissions,
  operatorLoading,
}) => {
  const [groupedGeneralPermissions, setGroupedGeneralPermissions] = useState(
    {}
  );
  const [operatorPermissionId, setOperatorPermissionId] = useState([]);

  useEffect(() => {
    dispatch(actions.admin.getPermissions({
      params: {
        operatorId: operatorId
      }
    }));

    return () => {
      dispatch(actions.admin.reset(['permissions']));
    };
  }, [dispatch, operatorId]);

  useEffect(() => {
    if (generalPermissions) {
      let formatedPermissions = {};
     
      generalPermissions.permissions.forEach((permission) => {
        const groupName = permission.Service
          ? permission.Service.name
          : permission.group;
        formatedPermissions[groupName]
          ? formatedPermissions[groupName].push(permission)
          : (formatedPermissions[groupName] = [permission]);
      });

      setGroupedGeneralPermissions(formatedPermissions);
    }
  }, [generalPermissions]);

  useEffect(() => {
    setOperatorPermissionId(permissions.map((e) => e.id));
  }, [permissions]);

  const firstLetterUpperCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const save = (values) => {
    dispatch(
      actions.operators.updateOperatorRights({
        data: values.permissions,
        params: { operatorId: operatorId },
      })
    );
  };

  return (
    <Formik
      initialValues={{
        permissions: operatorPermissionId,
      }}
      enableReinitialize={true}
      onSubmit={save}
      validationSchema={userPermissionsSchema}
    >
      {({
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
      }: any) => (
        <Wrapper>
          <Form onSubmit={handleSubmit} loading={operatorLoading}>
            <Wrapper padding='0 0 0 14rem'>
              {Object.values(groupedGeneralPermissions).map(
                (permissions: any[], i) => {
                  const permissionsId = permissions.map((e) => e.id);
                  let isGroupFull = !permissionsId.some(
                    (e) => !values.permissions.includes(e)
                  );
                  const panels = [
                    {
                      key: i,
                      title: (
                        <Wrapper margin='0 0 0.5rem -1.5rem' key={i}>
                          <SimpleCheckbox
                            label={firstLetterUpperCase(
                              Object.keys(groupedGeneralPermissions)[i]
                            )}
                            checked={isGroupFull}
                            onChange={(checked) => {
                              const newPerms = !isGroupFull
                                ? Array.from(
                                    new Set(
                                      values.permissions.concat(permissionsId)
                                    )
                                  )
                                : values.permissions.filter(
                                    (e) => !permissionsId.includes(e)
                                  );
                              setFieldValue('permissions', newPerms);
                            }}
                          />
                        </Wrapper>
                      ),
                      content: (
                        <FieldArray
                          name='permissions'
                          render={(arrayHelper) => (
                            <>
                              {permissions.map((permission: any, i) => {
                                return (
                                  <Wrapper key={i}>
                                    <Wrapper>
                                      <Wrapper>
                                        <Field
                                          key={i}
                                          name={`permissions[${i}]`}
                                          component={Checkbox}
                                          label={firstLetterUpperCase(
                                            permission.action
                                          )}
                                          onChange={() => {
                                            if(values.permissions.includes(permission.id)){
                                              arrayHelper.remove(
                                                values.permissions.findIndex(
                                                  (e) => e === permission.id
                                                )
                                              );
                                            } else {
                                              arrayHelper.push(permission.id);
                                            }
                                          }}
                                          checked={values.permissions.includes(
                                            permission.id
                                          )}
                                          meta={{
                                            errors,
                                            touched,
                                            setFieldValue,
                                            setFieldTouched,
                                          }}
                                        />
                                      </Wrapper>
                                    </Wrapper>
                                  </Wrapper>
                                );
                              })}
                            </>
                          )}
                        />
                      ),
                    },
                  ];
                  return (
                    <Wrapper
                      display='inline-grid'
                      padding='0 0 5rem 5rem'
                      width='18rem'
                      key={i}
                    >
                      <Accordion panels={panels} />
                    </Wrapper>
                  );
                }
              )}
            </Wrapper>
            <ErrorMessage
              name='permissions'
              render={(msg) => <Error msg={msg} />}
            />
            <Divider hidden />
            <Button type='submit' content='Submit' color='green' margin='0px' />
          </Form>
        </Wrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    generalPermissions: selectors.admin.permissionsSelector(state),
    operatorLoading: selectors.operators.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
