import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Tab, Container, Table } from 'semantic-ui-react';

import EmptyTable from 'components/EmptyTableCurve';
import Wrapper from 'components/core/Wrapper/Wrapper';
import TableSpacedRowsSmall from 'components/TableSpacedRowsSmall';
import Button from 'components/Button';
import Permissions from './Permissions';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import Search from 'components/Search';

const Index = ({
  company,
  dispatch,
  companyUsers,
  companyUser,
  userPermissions,
  errorHandlerCompanyUser,
  errorHandlerCompany,
  loadingHandlerCompanyUser,
  loadingHandlerCompany,
}) => {
  const [users, setusers] = useState([]);
  const [runningAction, setRunningAction] = useState(null);
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [resetSearch, setresetSearch] = useState(false);
  const [manageUser, setManageUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    dispatch(actions.companyUser.reset(['error']));

    dispatch(
      actions.companyUser.getAllUsers({
        params: { companyId: company.id },
      })
    );

    return () => {
      dispatch(actions.companyUser.reset(['users', 'error']));
    };
  }, [dispatch, company]);

  useEffect(() => {
    if (runningAction !== null && !loadingHandlerCompanyUser) {
      if (!errorHandlerCompanyUser.includes(runningAction)) {
        dispatch(
          actions.companyUser.getAllUsers({
            params: { companyId: company.id },
          })
        );
      }
      setRunningAction(null);
    }
  }, [dispatch, 
    loadingHandlerCompanyUser, 
    runningAction, 
    company.id, 
    errorHandlerCompanyUser]);

  useEffect(() => {
    if (companyUsers !== null) {
      setusers(_.orderBy(companyUsers, ['lastName'], ['asc']));
    }
    resetSearch && setresetSearch(false);
  }, [companyUsers, resetSearch]);

  useEffect(() => {
    if (companyUser !== null) {
      setSelectedUser(companyUser);
    }
  }, [companyUser]);

  const onSearchResultChange = (newData) => {
    setusers(_.orderBy(newData, ['lastName'], ['asc']));
  };

  const getUser = (userId) => {
    dispatch(actions.companyUser.reset(['error']));
    dispatch(
      actions.companyUser.getOneUser({
        params: { companyId: company.id, userId: userId },
      })
    );
  };

  const handleDelete = (userId) => {
    dispatch(actions.companyUser.reset(['error']));
    dispatch(
      actions.companyUser.removeUser({
        params: { companyId: company.id, userId: userId },
      })
    );
    setRunningAction('removeUser');
  };

  return (
    <Tab.Pane
      loading={!company || loadingHandlerCompanyUser || loadingHandlerCompany}
    >
      <Container>
        {addUserModal === true && (
          <AddUserModal
            open={addUserModal}
            handleClose={() => setAddUserModal(false)}
            companyId={company.id}
            setRunningAction={setRunningAction}
          />
        )}
        {editUserModal === true && (
          <EditUserModal
            open={editUserModal}
            handleClose={() => setEditUserModal(false)}
            user={selectedUser}
            companyId={company.id}
            loadingHandlerCompanyUser={loadingHandlerCompanyUser}
            errorHandlerCompanyUser={errorHandlerCompanyUser}
          />
        )}
        {manageUser === false && (
          <>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              padding='2rem 10rem 2rem'
            >
              <Button
                icon='plus'
                type='submit'
                content='Add'
                onClick={() => {
                  setAddUserModal(true);
                }}
              />
              <Search
                dataSource={companyUsers}
                onSearchResultChange={onSearchResultChange}
                setResetSearch={setresetSearch}
              />
            </Wrapper>
            {users && (
              <Wrapper
                display='flex'
                justifyContent='center'
                padding='0 10rem 5rem'
              >
                <Wrapper display='inline-flex' width='100%'>
                  <TableSpacedRowsSmall>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                        <Table.HeaderCell>Administrator</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {(users.length === 0 && <EmptyTable colNumber={4} />) ||
                        users.map((user, i) => {
                          return (
                            <Table.Row key={i}>
                              <Table.Cell>{`${user.lastName} ${user.firstName}`}</Table.Cell>
                              <Table.Cell>{user.CompanyUsers.role}</Table.Cell>
                              <Table.Cell>
                                {user.CompanyUsers.admin ? 'Yes' : 'No'}
                              </Table.Cell>
                              <Table.Cell>
                                <Wrapper
                                  display='flex'
                                  justifyContent='flex-end'
                                >
                                  <Button
                                    content='Manage'
                                    icon='edit'
                                    type='button'
                                    color='blue'
                                    labelPosition='right'
                                    onClick={() => {
                                      setManageUser(true);
                                      getUser(user.id);
                                    }}
                                    margin='5px'
                                  />
                                  <Button
                                    content='Delete'
                                    icon='delete'
                                    type='button'
                                    color='red'
                                    labelPosition='right'
                                    onClick={() => handleDelete(user.id)}
                                    margin='5px'
                                  />
                                </Wrapper>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </TableSpacedRowsSmall>
                </Wrapper>
              </Wrapper>
            )}
          </>
        )}
        {manageUser === true && (
          <>
            <Wrapper display='flex' padding='2rem 5rem 2rem'>
              <Button
                icon='angle left'
                type='submit'
                content='Back'
                onClick={() => {
                  setManageUser(false);
                  setSelectedUser(null);
                }}
              />
            </Wrapper>
            {selectedUser !== null && (
              <>
                <Wrapper
                  display='flex'
                  justifyContent='center'
                  padding='0 15rem 5rem'
                >
                  <Wrapper display='inline-flex' width='100%'>
                    <TableSpacedRowsSmall>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>{`${selectedUser.firstName} ${selectedUser.lastName}`}</Table.Cell>
                          <Table.Cell>
                            {selectedUser.CompanyUsers.role}
                          </Table.Cell>
                          <Table.Cell>
                            <Wrapper display='flex' justifyContent='flex-end'>
                              <Button
                                content='Edit'
                                icon='edit'
                                type='button'
                                color='blue'
                                labelPosition='right'
                                onClick={() => setEditUserModal(true)}
                                margin='5px'
                              />
                              <Button
                                content='Delete'
                                icon='delete'
                                type='button'
                                color='red'
                                labelPosition='right'
                                onClick={() => {
                                  handleDelete(selectedUser.id);
                                  setManageUser(false);
                                }}
                                margin='5px'
                              />
                            </Wrapper>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </TableSpacedRowsSmall>
                  </Wrapper>
                </Wrapper>
                <Wrapper>
                  <Permissions
                    companyId={company.id}
                    userId={selectedUser.id}
                    userNotificationPerm={selectedUser.notification}
                    userEmitterPerm={selectedUser.emitterNotification}
                    errorHandlerCompanyUser={errorHandlerCompanyUser}
                    errorHandlerCompany={errorHandlerCompany}
                    loadingHandlerCompanyUser={loadingHandlerCompanyUser}
                  />
                  <Wrapper>
               
                </Wrapper>
                </Wrapper>

              </>
            )}
          </>
        )}
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    companyUsers: selectors.companyUser.usersSelector(state),
    companyUser: selectors.companyUser.userSelector(state),
    errorHandlerCompanyUser: selectors.companyUser.errorSelector(state),
    errorHandlerCompany: selectors.company.errorSelector(state),
    loadingHandlerCompanyUser: selectors.companyUser.loadingSelector(state),
    loadingHandlerCompany: selectors.company.loadingSelector(state),
    userPermissions: selectors.companyUser.userPermissionsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
