import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Modal, Header, Container, Divider } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import { actions, selectors } from 'store';
import * as CGV from 'config/cgvDrivers.json'

import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import ErrorHandler from 'components/ErrorHandler';
import { Input } from 'components/Form';
import { Checkbox } from 'components/Form';
import { addDriverSchema } from 'interfaces/formSchema';
import { ContainerPadded } from 'components/AddContact/styles';
import 'react-phone-input-2/lib/style.css'

const Index = ({
  errorHandler,
  open,
  handleClose,
  intl,
  dispatch,
  companyId,
  setRunningAction,
}) => {

  const [auth, setAuth]: any = useState(true)
  const companyphoneRef = useRef()

  const search = (values) => {
    dispatch(actions.companyDriver.addDriverToCompany({
      params: {
        companyId: companyId,
        uniqueId: values.uniqueId,
        pseudo: values.pseudo
      }
    }));
    setRunningAction('addUser');
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Modal.Content>
      <ContainerPadded textAlign='center'>
          <Container>
            <Header size='huge'>Add a driver</Header>
            <Divider hidden />
          <Formik
            ref={companyphoneRef}
            initialValues={{
              pseudo: '',
              uniqueId: '',
              cgv: null,
            }}
            onSubmit={search}
            validationSchema={addDriverSchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              values,
            }) => (
              <Form onSubmit={handleSubmit} width='15px'>
                {errorHandler && (
                  <>
                    <ErrorHandler
                      header='Fail to send request.'
                      message='Try to refresh the page and contact an administrator if the error persists.'
                    />
                  </>
                )}
                <Wrapper>
                  <Wrapper margin='10px 100px'>
                    <Form.Group widths='equal'> 
                    <Form.Field>
                        <Field
                          mandatory
                          label='Pseudo, Alias, Nickname'
                          placeholder='Pseudo'
                          name='pseudo'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>                 
                      <Form.Field>
                        <Field
                          mandatory
                          label='Unique ID'
                          placeholder='type...'
                          name='uniqueId'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>
                    </Form.Group>
                  </Wrapper>
                  <Wrapper margin='10px 25%' padding='10px' height='auto' overflow='auto'>   
                  <Divider hidden />         
                   <Form.Field>                    
                    <Field
                      label={`${window.navigator.language === 'fr-FR' ? CGV.fr.content :  CGV.en.content}`}
                      name='cgv'
                      type='checkbox'
                      component={Checkbox}
                      meta={{ 
                        errors, 
                        touched, 
                        setFieldTouched,
                        setFieldValue
                      }}
                      margin='20px'
                      onChange={() => { setFieldValue('cgv', auth ? setAuth(false) : setAuth(true))}}
                    />
                    </Form.Field>
                  </Wrapper>
                </Wrapper>
                <Button
                  disabled={auth}
                  type='submit'
                  content={intl.formatMessage({
                    id: 'button.save',
                  })}
                  margin='20px'
                  position='center'
                  color='blue'
                />
              </Form>
            )}
          </Formik>
        </Container>
        </ContainerPadded>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    addDriverToCompany: selectors.companyDriver.driverCompaniesSelector(state)
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);


