import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    createAddress: false,
    getAllAddresses: false,
    getOneAddress: false,
    deleteAddress: false,
    updateAddress: false,
  },
  errors: {
    createAddress: null,
    getAllAddresses: null,
    getOneAddress: null,
    deleteAddress: null,
    updateAddress: null,
  },
  loading: {
    createAddress: false,
    getAllAddresses: false,
    getOneAddress: false,
    deleteAddress: false,
    updateAddress: false,
  },
  addresses: null,
  address: null,
  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Get all Addressess
    case actionTypes.CREATE_ADDRESS_LOADING:
      return produce(state, draft => {
        draft.loading.createAddress = true;
      });

    case actionTypes.CREATE_ADDRESS_SUCCESS:
      return produce(state, draft => {
        draft.errors.createAddress = null;
        draft.loading.createAddress = false;
        draft.success.createAddress = true;
      });

    case actionTypes.CREATE_ADDRESS_ERROR:
      return produce(state, draft => {
        draft.errors.createAddress = true;
        draft.loading.createAddress = false;
        draft.success.createAddress = false;
        !draft.error.includes("createAddress") &&
          draft.error.push("createAddress");
      });

    // Get one Addresses
    case actionTypes.GET_ALL_ADDRESS_LOADING:
      return produce(state, draft => {
        draft.loading.getAllAddresses = true;
      });

    case actionTypes.GET_ALL_ADDRESS_SUCCESS:
      return produce(state, draft => {
        draft.errors.getAllAddresses = null;
        draft.loading.getAllAddresses = false;
        draft.success.getAllAddresses = true;
        draft.addresses = action.payload;
      });

    case actionTypes.GET_ALL_ADDRESS_ERROR:
      return produce(state, draft => {
        draft.errors.getAllAddresses = true;
        draft.loading.getAllAddresses = false;
        draft.success.getAllAddresses = false;
        !draft.error.includes("addresses") &&
          draft.error.push("addresses");
      });

    // Add Addresses
    case actionTypes.GET_ONE_ADDRESS_LOADING:
      return produce(state, draft => {
        draft.loading.getOneAddress = true;
      });

    case actionTypes.GET_ONE_ADDRESS_SUCCESS:
      return produce(state, draft => {
        draft.errors.getOneAddress = null;
        draft.loading.getOneAddress = false;
        draft.success.getOneAddress = true;
        draft.address = action.payload;
      });

    case actionTypes.GET_ONE_ADDRESS_ERROR:
      return produce(state, draft => {
        draft.errors.getOneAddress = true;
        draft.loading.getOneAddress = false;
        draft.success.getOneAddress = false;
        !draft.error.includes("address") && draft.error.push("address");
      });

    // Delete Addresses
    case actionTypes.DELETE_ADDRESS_LOADING:
      return produce(state, draft => {
        draft.loading.deleteAddress = true;
      });

    case actionTypes.DELETE_ADDRESS_SUCCESS:
      return produce(state, draft => {
        draft.errors.deleteAddress = null;
        draft.loading.deleteAddress = false;
        draft.success.deleteAddress = true;
      });

    case actionTypes.DELETE_ADDRESS_ERROR:
      return produce(state, draft => {
        draft.errors.deleteAddress = true;
        draft.loading.deleteAddress = false;
        draft.success.deleteAddress = false;
        !draft.error.includes("deleteAddress") && draft.error.push("deleteAddress");
      });

    // Validate Addresses
    case actionTypes.UPDATE_ADDRESS_LOADING:
      return produce(state, draft => {
        draft.loading.updateAddress = true;
      });

    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return produce(state, draft => {
        draft.errors.updateAddress = null;
        draft.loading.updateAddress = false;
        draft.success.updateAddress = true;
      });

    case actionTypes.UPDATE_ADDRESS_ERROR:
      return produce(state, draft => {
        draft.errors.updateAddress = true;
        draft.loading.updateAddress = false;
        draft.success.updateAddress = false;
        !draft.error.includes("updateAddress") && draft.error.push("updateAddress");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
