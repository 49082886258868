// Dependencies
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditText } from 'react-edit-text';
import DatePicker from 'react-date-picker';
import PdfViewer from '../pdfViewer/pdfViewer';

// Interfaces
import CreateInspectionProperties from '../../interfaces/createInspectionProperties';

// Resources
import { currentLanguage } from '../../../../views';
import saveLogo from '../../assets/images/logos/save.png';

// Stylesheets
import './createForm.css';
import 'react-edit-text/dist/index.css';

/**
 * @name createInspection.tsx
 *
 * @path /components/createForms/createInspection.tsx
 *
 * @description Defines form to create and add an inspection to a mission in progress
 */
const CreateInspection: React.FC<CreateInspectionProperties> = ({
  id,
  reference,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  // Reloader
  const [activeComponent, setActiveComponent] =
    useState<string>('GlobalInformation');

  // Inspection Type options
  const inspectionTypeOptions = [
    {
      id: 'assistance',
      label: t('createInspection.assistance'),
    },
    {
      id: 'administrativeInspection',
      label: t('createInspection.administrativeInspection'),
    },
    {
      id: 'fullInspection',
      label: t('createInspection.fullInspection'),
    },
  ];

  // Inspection Time options
  const inspectionTimeOptions = Array.from({ length: 11 }, (_, i) => i + 2);

  // Setters for dropdown buttons' visibility
  const [isOpenTimeSelector, setIsOpenTimeSelector] = useState<boolean>(false);

  // References
  const inspectionTimeRef = useRef<HTMLSelectElement>(null);

  // Variables to temporarily save values until they're send to backoffice
  const [inspectionTypeValue, setInspectionTypeValue] = useState<string>();
  const [inspectionDateValue, setInspectionDateValue] = useState<string>();
  const [inspectionSelectedTime, setInspectionSelectedTime] =
    useState<string>('2');
  const [inspectorFirstName, setInspectorFirstName] = useState<string>();
  const [inspectorLastName, setInspectorLastName] = useState<string>();
  const [inspectorMailAddress, setInspectorMailAddress] = useState<string>();
  const [inspectorPhoneNumber, setInspectorPhoneNumber] = useState<string>();
  const [submissionDateValue, setSubmissionDateValue] = useState<string>();

  // Functions to update temporary variables
  const handleInspectionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newType = event.target.value;
    setInspectionTypeValue(newType);
  };

  const handleInspectionDateChange = (newDate: any) => {
    setInspectionDateValue(newDate);
  };

  const handleInspectionTimeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newTime = event.target.value;
    setInspectionSelectedTime(newTime);
  };

  const handleInspectorFirstNameChange = ({ value }: { value: string }) => {
    setInspectorFirstName(value);
  };

  const handleInspectorLastNameChange = ({ value }: { value: string }) => {
    setInspectorLastName(value);
  };

  const handleInspectorMailAddressChange = ({ value }: { value: string }) => {
    setInspectorMailAddress(value);
  };

  const handleInspectorPhoneNumberChange = ({ value }: { value: string }) => {
    setInspectorPhoneNumber(value);
  };

  const handleSubmissionDateChange = (newDate: any) => {
    setSubmissionDateValue(newDate);
  };

  // Hiddeable elements
  const [isBackButtonHidden, setIsBackButtonHidden] = useState<boolean>(true);
  const [isNextButtonHidden, setIsNextButtonHidden] = useState<boolean>(false);
  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);

  // Form back button actions (with personalized action for each case in form)
  const handleClickBackButton = () => {
    switch (activeComponent) {
      case 'Employees': {
        clickFormButton('GlobalInformation');
        setIsBackButtonHidden(true);
        break;
      }
      case 'Proofs': {
        clickFormButton('Employees');
        break;
      }
      case 'InspectorInformation': {
        clickFormButton('Proofs');
        break;
      }
      default:
        return;
    }
  };

  function clickFormButton(activeComponent: string) {
    setActiveComponent(activeComponent);
    setIsErrorHidden(true);
    setIsBackButtonHidden(false);
    setIsNextButtonHidden(false);
  }

  // Form next button actions
  const handleClickNextButton = () => {
    switch (activeComponent) {
      case 'GlobalInformation': {
        if (inspectionTypeValue != null && inspectionDateValue != null) {
          clickFormButton('Employees');
        } else {
          setIsErrorHidden(false);
        }
        break;
      }
      case 'Employees': {
        clickFormButton('Proofs');
        break;
      }
      case 'Proofs': {
        clickFormButton('InspectorInformation');
        setIsNextButtonHidden(true);
        break;
      }
      default:
        return;
    }
  };

  const handleClickSaveButton = () => {
    if (
      inspectorFirstName != null &&
      inspectorLastName != null &&
      inspectorMailAddress != null &&
      inspectorPhoneNumber != null &&
      submissionDateValue != null
    ) {
      setIsErrorHidden(true);
      window.location.href = `/spw/missions/details/${id}/${reference}/inspection`;
    } else {
      setIsErrorHidden(false);
    }
  };

  return (
    <div className='inspectionPopupContainer'>
      {/* First page, display a form to fill in global information */}
      {activeComponent === 'GlobalInformation' && (
        <div className='inspectionPopupContainer'>
          <h4>{t('createInspection.generalInformation')}</h4>
          <div className='inspectionBlockGlobal'>
            <p>
              <b>{t('createInspection.inspectionType')}</b>
            </p>
            <div className='inspectionTypeOptions'>
              {inspectionTypeOptions.map((option) => (
                <label key={option.id}>
                  <input
                    type='radio'
                    name='inspectionOptions'
                    value={option.id}
                    checked={inspectionTypeValue === option.id}
                    onChange={handleInspectionTypeChange}
                  />
                  <pre> {option.label}</pre>
                </label>
              ))}
            </div>
          </div>
          <div className='inspectionBlockGlobal'>
            <p>
              <b>{t('createInspection.inspectionDate')}</b>
            </p>
            <div className='inspectionEditableOptions'>
              <DatePicker
                value={inspectionDateValue}
                className='inspectionDatePicker'
                format='dd/MM/yyyy'
                minDate={new Date()}
                onChange={handleInspectionDateChange}
                locale={currentLanguage.toUpperCase().split('-')[0]}
              />
            </div>
          </div>
          <div className='inspectionBlockGlobal'>
            <p>
              <b>{t('createInspection.inspectionTime')}</b>
            </p>
            <div className='inspectionEditableOptions'>
              <select
                id={'inspectionTimeSelector'}
                ref={inspectionTimeRef}
                className='inspectionTimeSelector'
                onClick={() => setIsOpenTimeSelector(!isOpenTimeSelector)}
                value={inspectionSelectedTime}
                onChange={handleInspectionTimeChange}
              >
                {inspectionTimeOptions.map((time) => {
                  return (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      )}
      {/* Second page, display a form to choose all inspected employees from current mission list of employees */}
      {activeComponent === 'Employees' && (
        <div className='inspectionPopupContainer'>
          <h4>{t('createInspection.employees')}</h4>
          <div className='inspectionBlockGlobal'>
            <p></p>
            <div></div>
          </div>
        </div>
      )}
      {/* Third page, display a form to upload inspection proofs */}
      {activeComponent === 'Proofs' && (
        <div className='inspectionPopupContainer'>
          <h4>{t('createInspection.proofs')}</h4>
          <div className='inspectionBlockProofs'>
            {/* <PdfViewer fileType='pdf' /> */}
          </div>
        </div>
      )}
      {/* Fourth page, display a form to fill in inspector's information */}
      {activeComponent === 'InspectorInformation' && (
        <div className='inspectionPopupContainer'>
          <h4>{t('createInspection.inspectorInformation')}</h4>
          <div className='inspectorInformationBlock'>
            <div className='inspectionSubblock'>
              <p>
                <b>{t('createInspection.inspectorFirstName')}</b>
              </p>
              <div className='inspectionEditText'>
                <EditText onSave={handleInspectorFirstNameChange} />
              </div>
            </div>
            <div className='inspectionSubblock'>
              <p>
                <b>{t('createInspection.inspectorLastName')}</b>
              </p>
              <div className='inspectionEditText'>
                <EditText onSave={handleInspectorLastNameChange} />
              </div>
            </div>
          </div>
          <div className='inspectorInformationBlock'>
            <div className='inspectionSubblock'>
              <p>
                <b>{t('createInspection.inspectorMailAddress')}</b>
              </p>
              <div className='inspectionEditText'>
                <EditText onSave={handleInspectorMailAddressChange} />
              </div>
            </div>
            <div className='inspectionSubblock'>
              <p>
                <b>{t('createInspection.inspectorPhoneNumber')}</b>
              </p>
              <div className='inspectionEditText'>
                <EditText onSave={handleInspectorPhoneNumberChange} />
              </div>
            </div>
          </div>
          <h4>{t('createInspection.documentSubmissionDeadline')}</h4>
          <div className='inspectionBlockGlobal'>
            <div className='inspectionEditableOptions'>
              <DatePicker
                value={submissionDateValue}
                className='inspectionDatePicker'
                format='dd/MM/yyyy'
                minDate={new Date()}
                onChange={handleSubmissionDateChange}
                locale={currentLanguage.toUpperCase().split('-')[0]}
              />
            </div>
          </div>
        </div>
      )}
      {/* Common footer for each pages, to navigate between them, and to display an error when user doesn't fill in all necessaries fields */}
      <div className='inspectionButtonsContainer'>
        <div className='inspectionBackButtonContainer'>
          <button
            onClick={handleClickBackButton}
            className='inspectionBackButton'
            hidden={isBackButtonHidden}
          >
            {t('globalStrings.back')}
          </button>
        </div>
        <div className='inspectionError' hidden={isErrorHidden}>
          <p>{t('orderPageStrings.error')}</p>
        </div>
        <div className='inspectionNextButtonContainer'>
          <button
            onClick={handleClickNextButton}
            className='inspectionNextButton'
            hidden={isNextButtonHidden}
          >
            {t('globalStrings.next')}
          </button>
          <button className='saveButton' hidden={!isNextButtonHidden}>
            <p>{t('popupStrings.saveText')}</p>
            <img
              src={saveLogo}
              alt={t('popupStrings.saveLogo')}
              className='saveLogo'
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateInspection;
