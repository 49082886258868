import actionTypes from 'store/internalTools/ssrRequest/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.TICKETSSR:
        try {
          next({ type: actionTypes.TICKETSSR_LOADING });
          const response = await request.ticketSsr(action.payload);
          next({
            type: actionTypes.TICKETSSR_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.TICKETSSR_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.SEND_RESULTS:
        try {
          next({ type: actionTypes.SEND_RESULTS_LOADING });
          await request.sendResults(action.payload);
          next({
            type: actionTypes.SEND_RESULTS_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SEND_RESULTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_FORM_SCHEMAS:
        try {
          next({ type: actionTypes.GET_FORM_SCHEMAS_LOADING });
          const response = await request.getFormSchemas();
          next({
            type: actionTypes.GET_FORM_SCHEMAS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_FORM_SCHEMAS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      default:
        next(action);
        break;
    }
  };
}
