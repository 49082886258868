import actionTypes from 'store/company/address/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      
      case actionTypes.CREATE_ADDRESS:
        try {
          next({ type: actionTypes.CREATE_ADDRESS_LOADING });
          await request.createAddress({ ...action.payload });
          next({
            type: actionTypes.CREATE_ADDRESS_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_ADDRESS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ALL_ADDRESS:
        try {
          next({ type: actionTypes.GET_ALL_ADDRESS_LOADING });
          const response = await request.getAllAddresses({ ...action.payload });
          next({
            type: actionTypes.GET_ALL_ADDRESS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_ADDRESS_ERROR,
            error,
          });
        }
        break;
      case actionTypes.GET_ONE_ADDRESS:
        try {
          next({ type: actionTypes.GET_ONE_ADDRESS_LOADING });
          const response = await request.getOneAddress({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_ONE_ADDRESS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_ADDRESS_ERROR,
            error,
          });
        }
        break;
      case actionTypes.DELETE_ADDRESS:
        try {
          next({ type: actionTypes.DELETE_ADDRESS_LOADING });
          await request.deleteAddress({ ...action.payload });
          next({
            type: actionTypes.DELETE_ADDRESS_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DELETE_ADDRESS_ERROR,
            error,
          });
        }
        break;
      case actionTypes.UPDATE_ADDRESS:
        try {
          next({ type: actionTypes.UPDATE_ADDRESS_LOADING });
          await request.updateAddress({ ...action.payload });
          next({
            type: actionTypes.UPDATE_ADDRESS_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ADDRESS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      default:
        next(action);
        break;
    }
  };
}
