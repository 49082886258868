import { resetState } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    createSubscriptionPayment: null,
    createToken: null,
    updateToken: null,
    unsubscribe: null,
    cancelUnsubscribe: null,
  },
  errors: {
    createSubscriptionPayment: null,
    createToken: null,
    updateToken: null,
    unsubscribe: null,
    cancelUnsubscribe: null,
  },
  loading: {
    createSubscriptionPayment: false,
    createToken: false,
    updateToken: false,
    unsubscribe: false,
    cancelUnsubscribe: false,
  },
  createSubscriptionPaymentFormToken: null,
  createTokenFormToken: null,
  updateTokenFormToken: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Create payment
    case actionTypes.CREATE_SUBSCRIPTION_PAYMENT_LOADING:
      return produce(state, (draft) => {
        draft.loading.createSubscriptionPayment = true;
      });

    case actionTypes.CREATE_SUBSCRIPTION_PAYMENT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createSubscriptionPayment = null;
        draft.loading.createSubscriptionPayment = false;
        draft.success.createSubscriptionPayment = true;
        draft.createSubscriptionPaymentFormToken = action.payload;
      });

    case actionTypes.CREATE_SUBSCRIPTION_PAYMENT_ERROR:
      return produce(state, (draft) => {
        draft.errors.createSubscriptionPayment = true;
        draft.loading.createSubscriptionPayment = false;
        draft.success.createSubscriptionPayment = false;
        !draft.error.includes("createSubscriptionPayment") &&
          draft.error.push("createSubscriptionPayment");
      });

    // Create token
    case actionTypes.CREATE_TOKEN_LOADING:
      return produce(state, (draft) => {
        draft.loading.createToken = true;
      });

    case actionTypes.CREATE_TOKEN_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createToken = null;
        draft.loading.createToken = false;
        draft.success.createToken = true;
        draft.createTokenFormToken = action.payload;
      });

    case actionTypes.CREATE_TOKEN_ERROR:
      return produce(state, (draft) => {
        draft.errors.createToken = true;
        draft.loading.createToken = false;
        draft.success.createToken = false;
        !draft.error.includes("createToken") && draft.error.push("createToken");
      });

    // Update token
    case actionTypes.UPDATE_TOKEN_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateToken = true;
      });

    case actionTypes.UPDATE_TOKEN_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateToken = null;
        draft.loading.updateToken = false;
        draft.success.updateToken = true;
        draft.updateTokenFormToken = action.payload;
      });

    case actionTypes.UPDATE_TOKEN_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateToken = true;
        draft.loading.updateToken = false;
        draft.success.updateToken = false;
        !draft.error.includes("updateToken") && draft.error.push("updateToken");
      });

    // unsubscribe
    case actionTypes.UNSUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.unsubscribe = true;
      });

    case actionTypes.UNSUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = null;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = true;
      });

    case actionTypes.UNSUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = true;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = false;
        !draft.error.includes("unsubscribe") && draft.error.push("unsubscribe");
      });

    //cancel unsubscribe
    case actionTypes.CANCEL_UNSUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.cancelUnsubscribe = true;
      });

    case actionTypes.CANCEL_UNSUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.cancelUnsubscribe = null;
        draft.loading.cancelUnsubscribe = false;
        draft.success.cancelUnsubscribe = true;
      });

    case actionTypes.CANCEL_UNSUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.cancelUnsubscribe = true;
        draft.loading.cancelUnsubscribe = false;
        draft.success.cancelUnsubscribe = false;
        !draft.error.includes("cancelUnsubscribe") &&
          draft.error.push("cancelUnsubscribe");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
