import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    contact: false,
  },
  errors: {
    contact: null,
  },
  loading: {
    contact: false,
  },

  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // contact
    case actionTypes.CONTACT_LOADING:
      return produce(state, draft => {
        draft.loading.contact = true;
      });
    case actionTypes.CONTACT_SUCCESS:
      return produce(state, draft => {
        draft.errors.contact = null;
        draft.loading.contact = false;
        draft.success.contact = true;
      });
    case actionTypes.CONTACT_ERROR:
      return produce(state, draft => {
        draft.errors.contact = true;
        draft.loading.contact = false;
        draft.success.contact = false;
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
