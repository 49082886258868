let actionTypes = {
  CREATE_ADDRESS: "MY_ASD.ADDRESS.CREATE_ADDRESS",
  CREATE_ADDRESS_LOADING: "MY_ASD.ADDRESS.CREATE_ADDRESS_LOADING",
  CREATE_ADDRESS_SUCCESS: "MY_ASD.ADDRESS.CREATE_ADDRESS_SUCCESS",
  CREATE_ADDRESS_ERROR: "MY_ASD.ADDRESS.CREATE_ADDRESS_ERROR",

  GET_ALL_ADDRESS: "MY_ASD.ADDRESS.GET_ALL_ADDRESS",
  GET_ALL_ADDRESS_LOADING: "MY_ASD.ADDRESS.GET_ALL_ADDRESS_LOADING",
  GET_ALL_ADDRESS_SUCCESS: "MY_ASD.ADDRESS.GET_ALL_ADDRESS_SUCCESS",
  GET_ALL_ADDRESS_ERROR: "MY_ASD.ADDRESS.GET_ALL_ADDRESS_ERROR",

  GET_ONE_ADDRESS: "MY_ASD.ADDRESS.GET_ONE_ADDRESS",
  GET_ONE_ADDRESS_LOADING: "MY_ASD.ADDRESS.GET_ONE_ADDRESS_LOADING",
  GET_ONE_ADDRESS_SUCCESS: "MY_ASD.ADDRESS.GET_ONE_ADDRESS_SUCCESS",
  GET_ONE_ADDRESS_ERROR: "MY_ASD.ADDRESS.GET_ONE_ADDRESS_ERROR",

  DELETE_ADDRESS: "MY_ASD.ADDRESS.DELETE_ADDRESS",
  DELETE_ADDRESS_LOADING: "MY_ASD.ADDRESS.DELETE_ADDRESS_LOADING",
  DELETE_ADDRESS_SUCCESS: "MY_ASD.ADDRESS.DELETE_ADDRESS_SUCCESS",
  DELETE_ADDRESS_ERROR: "MY_ASD.ADDRESS.DELETE_ADDRESS_ERROR",

  UPDATE_ADDRESS: "MY_ASD.ADDRESS.UPDATE_ADDRESS",
  UPDATE_ADDRESS_LOADING: "MY_ASD.ADDRESS.UPDATE_ADDRESS_LOADING",
  UPDATE_ADDRESS_SUCCESS: "MY_ASD.ADDRESS.UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_ERROR: "MY_ASD.ADDRESS.UPDATE_ADDRESS_ERROR",

  RESET: "MY_ASD.ADDRESS.RES",
  REFRESH_ERROR: "MY_ASD.ADDRESS.REFRESH_ERROR"
};

export default actionTypes;
