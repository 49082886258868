let actionTypes = {
  GET_UNPAID: 'MY_ASD.COMPANY_INVOICE.GET_UNPAID',
  GET_UNPAID_LOADING: 'MY_ASD.COMPANY_INVOICE.GET_UNPAID_LOADING',
  GET_UNPAID_SUCCESS: 'MY_ASD.COMPANY_INVOICE.GET_UNPAID_SUCCESS',
  GET_UNPAID_ERROR: 'MY_ASD.COMPANY_INVOICE.GET_UNPAID_ERROR',

  GET_ALL: 'MY_ASD.COMPANY_INVOICE.GET_ALL',
  GET_ALL_LOADING: 'MY_ASD.COMPANY_INVOICE.GET_ALL_LOADING',
  GET_ALL_SUCCESS: 'MY_ASD.COMPANY_INVOICE.GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'MY_ASD.COMPANY_INVOICE.GET_ALL_ERROR',

  GET_ONE: 'MY_ASD.COMPANY_INVOICE.GET_ONE',
  GET_ONE_LOADING: 'MY_ASD.COMPANY_INVOICE.GET_ONE_LOADING',
  GET_ONE_SUCCESS: 'MY_ASD.COMPANY_INVOICE.GET_ONE_SUCCESS',
  GET_ONE_ERROR: 'MY_ASD.COMPANY_INVOICE.GET_ONE_ERROR',

  PAY_INVOICE: 'MY_ASD.COMPANY_INVOICE.PAY_INVOICE',
  PAY_INVOICE_LOADING: 'MY_ASD.COMPANY_INVOICE.PAY_INVOICE_LOADING',
  PAY_INVOICE_SUCCESS: 'MY_ASD.COMPANY_INVOICE.PAY_INVOICE_SUCCESS',
  PAY_INVOICE_ERROR: 'MY_ASD.COMPANY_INVOICE.PAY_INVOICE_ERROR',

  CREATE_CREDIT: 'MY_ASD.COMPANY_INVOICE.CREATE_CREDIT',
  CREATE_CREDIT_LOADING: 'MY_ASD.COMPANY_INVOICE.CREATE_CREDIT_LOADING',
  CREATE_CREDIT_SUCCESS: 'MY_ASD.COMPANY_INVOICE.CREATE_CREDIT_SUCCESS',
  CREATE_CREDIT_ERROR: 'MY_ASD.COMPANY_INVOICE.CREATE_CREDIT_ERROR',

  RESET: 'MY_ASD.COMPANY_INVOICE.RES',
  REFRESH_ERROR: 'MY_ASD.COMPANY_INVOICE.REFRESH_ERROR',
};

export default actionTypes;
