export default {
  GET_ALL_CHED: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CHED',
  GET_ALL_CHED_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CHED_LOADING',
  GET_ALL_CHED_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CHED_SUCCESS',
  GET_ALL_CHED_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CHED_ERROR',

  GET_ONE_CHED: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_CHED',
  GET_ONE_CHED_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_CHED_LOADING',
  GET_ONE_CHED_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_CHED_SUCCESS',
  GET_ONE_CHED_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_CHED_ERROR',

  ADD_CHED: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_CHED',
  ADD_CHED_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_CHED_LOADING',
  ADD_CHED_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_CHED_SUCCESS',
  ADD_CHED_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_CHED_ERROR',

  CONVERT_FILE: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CONVERT_FILE',
  CONVERT_FILE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CONVERT_FILE_LOADING',
  CONVERT_FILE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CONVERT_FILE_SUCCESS',
  CONVERT_FILE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CONVERT_FILE_ERROR',

  RELOAD_LOGISTICOM: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.RELOAD_LOGISTICOM',
  RELOAD_LOGISTICOM_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.RELOAD_LOGISTICOM_LOADING',
  RELOAD_LOGISTICOM_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.RELOAD_LOGISTICOM_SUCCESS',
  RELOAD_LOGISTICOM_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.RELOAD_LOGISTICOM_ERROR',

  SEND_NOTICE_MAIL: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_NOTICE_MAIL',
  SEND_NOTICE_MAIL_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_NOTICE_MAIL_LOADING',
  SEND_NOTICE_MAIL_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_NOTICE_MAIL_SUCCESS',
  SEND_NOTICE_MAIL_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_NOTICE_MAIL_ERROR',

  REMOVE_CHED: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.REMOVE_CHED',
  REMOVE_CHED_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.REMOVE_CHED_LOADING',
  REMOVE_CHED_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.REMOVE_CHED_SUCCESS',
  REMOVE_CHED_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.REMOVE_CHED_ERROR',

  UPDATE_CHED_DATA: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_CHED_DATA',
  UPDATE_CHED_DATA_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_CHED_DATA_LOADING',
  UPDATE_CHED_DATA_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_CHED_DATA_SUCCESS',
  UPDATE_CHED_DATA_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_CHED_DATA_ERROR',

  GET_COMPANY_SUBSCRIBED:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPANY_SUBSCRIBED',
  GET_COMPANY_SUBSCRIBED_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPANY_SUBSCRIBED_LOADING',
  GET_COMPANY_SUBSCRIBED_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPANY_SUBSCRIBED_SUCCESS',
  GET_COMPANY_SUBSCRIBED_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPANY_SUBSCRIBED_ERROR',

  READ_CHAT: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.READ_CHAT',
  READ_CHAT_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.READ_CHAT_LOADING',
  READ_CHAT_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.READ_CHAT_SUCCESS',
  READ_CHAT_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.READ_CHAT_ERROR',

  SUBSCRIBE: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SUBSCRIBE',
  SUBSCRIBE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SUBSCRIBE_LOADING',
  SUBSCRIBE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SUBSCRIBE_SUCCESS',
  SUBSCRIBE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SUBSCRIBE_ERROR',

  UNSUBSCRIBE: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UNSUBSCRIBE',
  UNSUBSCRIBE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UNSUBSCRIBE_LOADING',
  UNSUBSCRIBE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UNSUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UNSUBSCRIBE_ERROR',

  SUBSCRIBE_PDF: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SUBSCRIBE_PDF',
  SUBSCRIBE_PDF_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SUBSCRIBE_PDF_LOADING',
  SUBSCRIBE_PDF_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SUBSCRIBE_PDF_SUCCESS',
  SUBSCRIBE_PDF_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SUBSCRIBE_PDF_ERROR',


  ADD_INTRANET_REFERENCE:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_INTRANET_REFERENCE',
  ADD_INTRANET_REFERENCE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_INTRANET_REFERENCE_LOADING',
  ADD_INTRANET_REFERENCE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_INTRANET_REFERENCE_SUCCESS',
  ADD_INTRANET_REFERENCE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_INTRANET_REFERENCE_ERROR',

  GET_ALL_CUSTOMS_OFFICE:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CUSTOMS_OFFICE',
  GET_ALL_CUSTOMS_OFFICE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CUSTOMS_OFFICE_LOADING',
  GET_ALL_CUSTOMS_OFFICE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CUSTOMS_OFFICE_SUCCESS',
  GET_ALL_CUSTOMS_OFFICE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CUSTOMS_OFFICE_ERROR',

  GET_PROCESSING_DECLARATIONS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PROCESSING_DECLARATIONS',
  GET_PROCESSING_DECLARATIONS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PROCESSING_DECLARATIONS_LOADING',
  GET_PROCESSING_DECLARATIONS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PROCESSING_DECLARATIONS_SUCCESS',
  GET_PROCESSING_DECLARATIONS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PROCESSING_DECLARATIONS_ERROR',

  GET_PROCESSING_DECLARATIONS_BY_FILTERS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PROCESSING_DECLARATIONS_BY_FILTERS',
  GET_PROCESSING_DECLARATIONS_BY_FILTERS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PROCESSING_DECLARATIONS_BY_FILTERS_LOADING',
  GET_PROCESSING_DECLARATIONS_BY_FILTERS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PROCESSING_DECLARATIONS_BY_FILTERS_SUCCESS',
  GET_PROCESSING_DECLARATIONS_BY_FILTERS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PROCESSING_DECLARATIONS_BY_FILTERS_ERROR',

  DELCARE: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELCARE',
  DELCARE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELCARE_LOADING',
  DELCARE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELCARE_SUCCESS',
  DELCARE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELCARE_ERROR',

  UPDATE_DECLARATION: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DECLARATION',
  UPDATE_DECLARATION_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DECLARATION_LOADING',
  UPDATE_DECLARATION_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DECLARATION_SUCCESS',
  UPDATE_DECLARATION_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DECLARATION_ERROR',

  UPDATE_STEP: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_STEP',
  UPDATE_STEP_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_STEP_LOADING',
  UPDATE_STEP_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_STEP_SUCCESS',
  UPDATE_STEP_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_STEP_ERROR',

  ADD_STEP: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_STEP',
  ADD_STEP_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_STEP_LOADING',
  ADD_STEP_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_STEP_SUCCESS',
  ADD_STEP_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_STEP_ERROR',

  GET_ALL: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL',
  GET_ALL_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_LOADING',
  GET_ALL_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_ERROR',

  GET_ONE: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE',
  GET_ONE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_LOADING',
  GET_ONE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_SUCCESS',
  GET_ONE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_ERROR',

  GET_COMPUTED: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPUTED',
  GET_COMPUTED_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPUTED_LOADING',
  GET_COMPUTED_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPUTED_SUCCESS',
  GET_COMPUTED_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPUTED_ERROR',

  GENERATE_BAE_EXTRACT: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GENERATE_BAE_EXTRACT',
  GENERATE_BAE_EXTRACT_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GENERATE_BAE_EXTRACT_LOADING',
  GENERATE_BAE_EXTRACT_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GENERATE_BAE_EXTRACT_SUCCESS',
  GENERATE_BAE_EXTRACT_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GENERATE_BAE_EXTRACT_ERROR',

  GET_DECLARATIONS_INVOICE:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_DECLARATIONS_INVOICE',
  GET_DECLARATIONS_INVOICE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_DECLARATIONS_INVOICE_LOADING',
  GET_DECLARATIONS_INVOICE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_DECLARATIONS_INVOICE_SUCCESS',
  GET_DECLARATIONS_INVOICE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_DECLARATIONS_INVOICE_ERROR',

  GET_DECLARATIONS_INVOICE_BY_FILTERS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_DECLARATIONS_INVOICE_BY_FILTERS',
  GET_DECLARATIONS_INVOICE_BY_FILTERS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_DECLARATIONS_INVOICE_BY_FILTERS_LOADING',
  GET_DECLARATIONS_INVOICE_BY_FILTERS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_DECLARATIONS_INVOICE_BY_FILTERS_SUCCESS',
  GET_DECLARATIONS_INVOICE_BY_FILTERS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_DECLARATIONS_INVOICE_BY_FILTERS_ERROR',

  UPDATE_DECLARATIONS_INVOICE:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DECLARATIONS_INVOICE',
  UPDATE_DECLARATIONS_INVOICE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DECLARATIONS_INVOICE_LOADING',
  UPDATE_DECLARATIONS_INVOICE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DECLARATIONS_INVOICE_SUCCESS',
  UPDATE_DECLARATIONS_INVOICE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DECLARATIONS_INVOICE_ERROR',

  CANCEL_DECLARATION: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CANCEL_DECLARATION',
  CANCEL_DECLARATION_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CANCEL_DECLARATION_LOADING',
  CANCEL_DECLARATION_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CANCEL_DECLARATION_SUCCESS',
  CANCEL_DECLARATION_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CANCEL_DECLARATION_ERROR',

  DOWNLOAD_DOCUMENTS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_DOCUMENTS',
  DOWNLOAD_DOCUMENTS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_DOCUMENTS_LOADING',
  DOWNLOAD_DOCUMENTS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_DOCUMENTS_SUCCESS',
  DOWNLOAD_DOCUMENTS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_DOCUMENTS_ERROR',

  EXTRACT_DOCUMENTS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.EXTRACT_DOCUMENTS',
  EXTRACT_DOCUMENTS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.EXTRACT_DOCUMENTS_LOADING',
  EXTRACT_DOCUMENTS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.EXTRACT_DOCUMENTS_SUCCESS',
  EXTRACT_DOCUMENTS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.EXTRACT_DOCUMENTS_ERROR',

  GET_ALL_CATEGORIES_DOCUMENTS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CATEGORIES_DOCUMENTS',
  GET_ALL_CATEGORIES_DOCUMENTS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CATEGORIES_DOCUMENTS_LOADING',
  GET_ALL_CATEGORIES_DOCUMENTS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CATEGORIES_DOCUMENTS_SUCCESS',
  GET_ALL_CATEGORIES_DOCUMENTS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_CATEGORIES_DOCUMENTS_ERROR',

  ADD_DOCUMENTS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_DOCUMENTS',
  ADD_DOCUMENTS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_DOCUMENTS_LOADING',
  ADD_DOCUMENTS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_DOCUMENTS_SUCCESS',
  ADD_DOCUMENTS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_DOCUMENTS_ERROR',

  DELETE_DOCUMENT: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_DOCUMENT',
  DELETE_DOCUMENT_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_DOCUMENT_LOADING',
  DELETE_DOCUMENT_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_DOCUMENT_ERROR',

  DOWNLOAD_DOCUMENT: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_DOCUMENT',
  DOWNLOAD_DOCUMENT_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_DOCUMENT_LOADING',
  DOWNLOAD_DOCUMENT_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_DOCUMENT_SUCCESS',
  DOWNLOAD_DOCUMENT_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_DOCUMENT_ERROR',

  GET_CHAT: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CHAT',
  GET_CHAT_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CHAT_LOADING',
  GET_CHAT_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CHAT_SUCCESS',
  GET_CHAT_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CHAT_ERROR',

  SEND_MESSAGE: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_MESSAGE',
  SEND_MESSAGE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_MESSAGE_LOADING',
  SEND_MESSAGE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_MESSAGE_ERROR',

  FOLLOW_DECLARATION: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.FOLLOW_DECLARATION',
  FOLLOW_DECLARATION_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.FOLLOW_DECLARATION_LOADING',
  FOLLOW_DECLARATION_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.FOLLOW_DECLARATION_SUCCESS',
  FOLLOW_DECLARATION_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.FOLLOW_DECLARATION_ERROR',

  UNFOLLOW_DECLARATION:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UNFOLLOW_DECLARATION',
  UNFOLLOW_DECLARATION_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UNFOLLOW_DECLARATION_LOADING',
  UNFOLLOW_DECLARATION_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UNFOLLOW_DECLARATION_SUCCESS',
  UNFOLLOW_DECLARATION_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UNFOLLOW_DECLARATION_ERROR',

  RESET: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.RESET',
  REFRESH_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.REFRESH_ERROR',
  
  GET_COMPUTED_CHEDS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPUTED_CHEDS',
  GET_COMPUTED_CHEDS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPUTED_CHEDS_LOADING',
  GET_COMPUTED_CHEDS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPUTED_CHEDS_SUCCESS',
  GET_COMPUTED_CHEDS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_COMPUTED_CHEDS_ERROR',

  EXTRACT_TRACES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.EXTRACT_TRACES',
  EXTRACT_TRACES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.EXTRACT_TRACES_LOADING',
  EXTRACT_TRACES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.EXTRACT_TRACES_SUCCESS',
  EXTRACT_TRACES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.EXTRACT_TRACES_ERROR',

  GET_ALL_HOSTED: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_HOSTED', 
  GET_ALL_HOSTED_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_HOSTED_LOADING', 
  GET_ALL_HOSTED_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_HOSTED_SUCCESS',
  GET_ALL_HOSTED_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_HOSTED_ERROR', 

  GET_ONE_HOSTED: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_HOSTED', 
  GET_ONE_HOSTED_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_HOSTED_LOADING', 
  GET_ONE_HOSTED_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_HOSTED_SUCCESS',
  GET_ONE_HOSTED_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_HOSTED_ERROR',
  
  ADD_PARTNERS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_PARTNERS', 
  ADD_PARTNERS_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_PARTNERS_LOADING', 
  ADD_PARTNERS_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_PARTNERS_SUCCESS',
  ADD_PARTNERS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_PARTNERS_ERROR', 

  GET_PARTNERS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PARTNERS', 
  GET_PARTNERS_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PARTNERS_LOADING', 
  GET_PARTNERS_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PARTNERS_SUCCESS',
  GET_PARTNERS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PARTNERS_ERROR', 
  
  ADD_PART_COMMENTS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_PART_COMMENTS', 
  ADD_PART_COMMENTS_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_PART_COMMENTS_LOADING', 
  ADD_PART_COMMENTS_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_PART_COMMENTS_SUCCESS',
  ADD_PART_COMMENTS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_PART_COMMENTS_ERROR', 

  GET_PART_COMMENTS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PART_COMMENTS', 
  GET_PART_COMMENTS_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PART_COMMENTS_LOADING', 
  GET_PART_COMMENTS_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PART_COMMENTS_SUCCESS',
  GET_PART_COMMENTS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PART_COMMENTS_ERROR', 

  ADD_MANDATE: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_MANDATE',
  ADD_MANDATE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_MANDATE_LOADING',
  ADD_MANDATE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_MANDATE_SUCCESS',
  ADD_MANDATE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_MANDATE_ERROR',

  GET_MANDATES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_MANDATES',
  GET_MANDATES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_MANDATES_LOADING',
  GET_MANDATES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_MANDATES_SUCCESS',
  GET_MANDATES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_MANDATES_ERROR',

  GET_ONE_MANDATE: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_MANDATE',
  GET_ONE_MANDATE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_MANDATE_LOADING',
  GET_ONE_MANDATE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_MANDATE_SUCCESS',
  GET_ONE_MANDATE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_MANDATE_ERROR',
  GET_PARKING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PARKING',
  GET_PARKING_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PARKING_LOADING',
  GET_PARKING_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PARKING_SUCCESS',
  GET_PARKING_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_PARKING_ERROR',


  GET_CONEX: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CONEX', 
  GET_CONEX_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CONEX_LOADING', 
  GET_CONEX_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CONEX_SUCCESS',
  GET_CONEX_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CONEX_ERROR',
  
  CHECK_CONEX: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CHECK_CONEX',
  CHECK_CONEX_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CHECK_CONEX_LOADING', 
  CHECK_CONEX_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CHECK_CONEX_SUCCESS', 
  CHECK_CONEX_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CHECK_CONEX_ERROR',  

  GET_ALL_LINES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_LINES', 
  GET_ALL_LINES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_LINES_LOADING', 
  GET_ALL_LINES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_LINES_SUCCESS',
  GET_ALL_LINES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_LINES_ERROR', 

  CREATE_LINES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_LINES', 
  CREATE_LINES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_LINES_LOADING', 
  CREATE_LINES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_LINES_SUCCESS',
  CREATE_LINES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_LINES_ERROR', 

  UPDATE_LINES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_LINES', 
  UPDATE_LINES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_LINES_LOADING', 
  UPDATE_LINES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_LINES_SUCCESS',
  UPDATE_LINES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_LINES_ERROR', 

  DELETE_LINES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_LINES', 
  DELETE_LINES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_LINES_LOADING', 
  DELETE_LINES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_LINES_SUCCESS',
  DELETE_LINES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_LINES_ERROR',
  
  GET_ONE_BY_REF: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_BY_REF', 
  GET_ONE_BY_REF_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_BY_REF_LOADING', 
  GET_ONE_BY_REF_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_BY_REF_SUCCESS',
  GET_ONE_BY_REF_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ONE_BY_REF_ERROR',
  
  GET_CATEGORIES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CATEGORIES',
  GET_CATEGORIES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CATEGORIES_LOADING', 
  GET_CATEGORIES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_CATEGORIES_ERROR',

  ADD_CONSIGNMENT_DATA: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_CONSIGNMENT_DATA',
  ADD_CONSIGNMENT_DATA_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_CONSIGNMENT_DATA_LOADING',
  ADD_CONSIGNMENT_DATA_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_CONSIGNMENT_DATA_SUCCESS',
  ADD_CONSIGNMENT_DATA_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.ADD_CONSIGNMENT_DATA_ERROR',

  UPDATE_CONSIGNMENT_DATA: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_CONSIGNMENT_DATA',
  UPDATE_CONSIGNMENT_DATA_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_CONSIGNMENT_DATA_LOADING',
  UPDATE_CONSIGNMENT_DATA_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_CONSIGNMENT_DATA_SUCCESS',
  UPDATE_CONSIGNMENT_DATA_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_CONSIGNMENT_DATA_ERROR',

  DELETE_CONSIGNMENT_DATA: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_CONSIGNMENT_DATA',
  DELETE_CONSIGNMENT_DATA_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_CONSIGNMENT_DATA_LOADING',
  DELETE_CONSIGNMENT_DATA_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_CONSIGNMENT_DATA_SUCCESS',
  DELETE_CONSIGNMENT_DATA_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DELETE_CONSIGNMENT_DATA_ERROR',

  SEND_QUEUE: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_QUEUE',
  SEND_QUEUE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_QUEUE_LOADING',
  SEND_QUEUE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_QUEUE_SUCCESS',
  SEND_QUEUE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.SEND_QUEUE_ERROR',

  CREATE_ACCISES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_ACCISES',
  CREATE_ACCISES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_ACCISES_LOADING',
  CREATE_ACCISES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_ACCISES_SUCCESS',
  CREATE_ACCISES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_ACCISES_ERROR',

  UPDATE_ACCISES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_ACCISES',
  UPDATE_ACCISES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_ACCISES_LOADING',
  UPDATE_ACCISES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_ACCISES_SUCCESS',
  UPDATE_ACCISES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_ACCISES_ERROR',

  GET_ACCISES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ACCISES',
  GET_ACCISES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ACCISES_LOADING',
  GET_ACCISES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ACCISES_SUCCESS',
  GET_ACCISES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ACCISES_ERROR',

  GET_ALL_ACCISES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_ACCISES',
  GET_ALL_ACCISES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_ACCISES_LOADING',
  GET_ALL_ACCISES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_ACCISES_SUCCESS',
  GET_ALL_ACCISES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_ALL_ACCISES_ERROR',

  CREATE_TRANSIT: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_TRANSIT',
  CREATE_TRANSIT_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_TRANSIT_LOADING',
  CREATE_TRANSIT_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_TRANSIT_SUCCESS',
  CREATE_TRANSIT_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_TRANSIT_ERROR',

  UPDATE_TRANSIT: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_TRANSIT',
  UPDATE_TRANSIT_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_TRANSIT_LOADING',
  UPDATE_TRANSIT_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_TRANSIT_SUCCESS',
  UPDATE_TRANSIT_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_TRANSIT_ERROR',

  GET_RATES: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_RATES',
  GET_RATES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_RATES_LOADING',
  GET_RATES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_RATES_SUCCESS',
  GET_RATES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.GET_RATES_ERROR',

  TRANSIT_RISK: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.TRANSIT_RISK',
  TRANSIT_RISK_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.TRANSIT_RISK_LOADING',
  TRANSIT_RISK_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.TRANSIT_RISK_SUCCESS',
  TRANSIT_RISK_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.TRANSIT_RISK_ERROR',

  CREATE_DEB: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_DEB',
  CREATE_DEB_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_DEB_LOADING', 
  CREATE_DEB_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_DEB_SUCCESS',
  CREATE_DEB_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.CREATE_DEB_ERROR',

  UPDATE_DEB: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DEB',
  UPDATE_DEB_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DEB_LOADING', 
  UPDATE_DEB_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DEB_SUCCESS',
  UPDATE_DEB_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.UPDATE_DEB_ERROR',

  DOWNLOAD_CHEDS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_CHEDS',
  DOWNLOAD_CHEDS_LOADING: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_CHEDS_LOADING',
  DOWNLOAD_CHEDS_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_CHEDS_SUCCESS',
  DOWNLOAD_CHEDS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMSDECLARATION.DOWNLOAD_CHEDS_ERROR',


};
