import { Field, Formik } from 'formik';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Header, Modal } from 'semantic-ui-react';
import { selectors } from 'store';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { Input } from 'components/Form';
import Wrapper from 'components/core/Wrapper/Wrapper';
import * as Yup from 'yup';

const Index = ({
  open,
  submitAction,
  data,
  handleClose,
  formOptions,
  versionsSchemas,
  countriesList,
  euCountries,
}) => {
  const formikRef = useRef();
  const [initialValues, setinitialValues] = useState(null);
  const [selectedVersion, setselectedVersion] = useState(null);
  const [schemaToConvert, setschemaToConvert] = useState(null);
  const [fieldsToReset, setfieldsToReset] = useState([]);
  const [yupSchema, setyupSchema] = useState({});
  const [
    searchformikValuesForSubmit,
    setsearchformikValuesForSubmit,
  ] = useState(false);
  const [submitLoading, setsubmitLoading] = useState(false);

  useEffect(() => {
    setinitialValues({});
  }, []);

  useEffect(() => {
    if (data) {
      const ref: any = formikRef.current;
      if (ref) {
        setselectedVersion(data.Version);
        Object.keys(data).forEach(key => {
          ref.setFieldValue(key, data[key]);
        });
      }
    }
  }, [data, initialValues]);

  useEffect(() => {
    setsearchformikValuesForSubmit(false);
    const ref: any = formikRef.current;
    if (ref) {
      ref.submitForm();
      setsubmitLoading(false);
    }
  }, [searchformikValuesForSubmit]);


  const sortSchema = (schema) => {
    let sortedSchema = {
      Version: schema.Version,
      Description: schema.Description,
      ExciseGoods: schema.ExciseGoods,
      CountryEtablishment: schema.CountryEtablishment,
      CountryFrom: schema.CountryFrom,
      CountryTo: schema.CountryTo,
      CountryImport: schema.CountryImport,
      Importer: schema.Importer,
      Exporter: schema.Exporter,
      PurchaseOrSale: schema.PurchaseOrSale,
      Marketplace: schema.Marketplace,
      MarketplaceCountryEstablishment: schema.MarketplaceCountryEstablishment,
      MarketplaceOSSChoice: schema.MarketplaceOSSChoice,
      TransportOrganiser: schema.TransportOrganiser,
      DsThresholdExceeded: schema.DsThresholdExceeded,
      TaxAtDestinationOption: schema.TaxAtDestinationOption,
      Value: schema.Value,
      OSSChoice: schema.OSSChoice,
      BuyerVATRegCountryTo: schema.BuyerVATRegCountryTo,
      BuyerEstablishedCountryTo: schema.BuyerEstablishedCountryTo,
      SellerVATRegCountryTo: schema.SellerVATRegCountryTo,
      SellerEstablishedCountryTo: schema.SellerEstablishedCountryTo,
      FiscalRepBuyerBE: schema.FiscalRepBuyerBE,
      FiscalRepSellerPT: schema.FiscalRepSellerPT,
      SupplyWithInstallation: schema.SupplyWithInstallation,
    };
    return sortedSchema;
  };

  const initializeValues = useCallback((version) => {
    let initialValues = {};
    const versionSchema = versionsSchemas.find(
      (schema) => schema.Version === version
    );

    if (versionSchema) {
      Object.keys(versionSchema).forEach((key) => {
        if (versionSchema[key] !== null) {
          switch (versionSchema[key].type) {
            case 'string':
              initialValues = { ...initialValues, ...{ [key]: '' } };
              break;
            case 'list':
              if (versionSchema[key].listType === 'string') {
                initialValues = { ...initialValues, ...{ [key]: '' } };
              } else {
                initialValues = { ...initialValues, ...{ [key]: null } };
              }
              break;
            case 'number':
              initialValues = { ...initialValues, ...{ [key]: null } };
              break;
          }
        } else {
          initialValues = { ...initialValues, ...{ [key]: null } };
        }
      });
      setinitialValues(initialValues);
    }

    const ref: any = formikRef.current;
    if (ref) {
      Object.keys(initialValues).forEach((key) => {
        ref.setFieldValue(key, initialValues[key]);
      });
    }
  }, [versionsSchemas]);

  useEffect(() => {
    if (selectedVersion) {
      initializeValues(selectedVersion);
      const schema = sortSchema(
        versionsSchemas.find((schema) => schema.Version === selectedVersion)
      );
      setschemaToConvert(schema);
    } else setschemaToConvert(null);
  }, [selectedVersion, versionsSchemas, initializeValues]);

  const options = { ...formOptions, worldCountries: countriesList };

  const buildYupSchema = () => {
    let yupShape = {};
    Object.keys(schemaToConvert).forEach((key) => {
      if (schemaToConvert[key] === null) {
        yupShape = { ...yupShape, [key]: Yup.mixed().oneOf([null, '']) };
      } else {
        switch (schemaToConvert[key].type) {
          case 'string':
            if (fieldsToReset.includes(key)) {
              yupShape = { ...yupShape, [key]: Yup.string() };
            } else {
              yupShape = {
                ...yupShape,
                [key]: Yup.string().required('Required'),
              };
            }
            break;
          case 'list':
            if (fieldsToReset.includes(key)) {
              yupShape = { ...yupShape, [key]: Yup.mixed().nullable('true') };
            } else {
              yupShape = {
                ...yupShape,
                [key]: Yup.mixed().required('Required'),
              };
            }
            break;
          case 'number':
            if (fieldsToReset.includes(key)) {
              yupShape = { ...yupShape, [key]: Yup.number().nullable('true') };
            } else {
              yupShape = {
                ...yupShape,
                [key]: Yup.number().required('Required'),
              };
            }
            break;
        }
      }
    });
    setyupSchema(Yup.object().shape(yupShape));
  };

  //////////////// CONDITIONS ////////////////////////
  const isInEu = (value) => {
    return euCountries.some((country) => country.country_code === value);
  };

  const isCountryTo = (value) => {
    const ref: any = formikRef.current;
    return ref.state.values.CountryTo === value;
  };
  const inferiorOrEqual150 = (value) => {
    const ref: any = formikRef.current;
    return ref.state.values.Value <= 150 && ref.state.values.Value
      ? true
      : false;
  };

  const dsThresholdExceeded = () => {
    const ref: any = formikRef.current;
    if (
      ref.state.values.DsThresholdExceeded === false &&
      !fieldsToReset.includes('DsThresholdExceeded')
    ) {
      return false;
    } else {
      return true;
    }
  };
  const isEqualToValue = (value) => {
    const ref: any = formikRef.current;
    return ref.state.values.Value === value ? true : false;
  };

  const buyerVATRegCountryTo = () => {
    const ref: any = formikRef.current;
    if (
      ref.state.values.BuyerVATRegCountryTo === false &&
      !fieldsToReset.includes('BuyerVATRegCountryTo')
    ) {
      return false;
    } else {
      return true;
    }
  };

  /////////////////////////////////////////////////////

  const getValueFromFormik = (valueName) => {
    const ref: any = formikRef.current;
    return ref.state.values[valueName];
  };

  const functionCaller = (functionName, value) => {
    switch (true) {
      case functionName.includes('isInEu'): {
        if (functionName[0] === '!') {
          return !isInEu(value);
        } else {
          return isInEu(value);
        }
      }
      case functionName.includes('isCountryTo'): {
        if (functionName[0] === '!') {
          return !isCountryTo(value);
        } else {
          return isCountryTo(value);
        }
      }
      case functionName.includes('dsThresholdExceeded'): {
        if (functionName[0] === '!') {
          return !dsThresholdExceeded();
        } else {
          return dsThresholdExceeded();
        }
      }
      case functionName.includes('buyerVATRegCountryTo'): {
        if (functionName[0] === '!') {
          return !buyerVATRegCountryTo();
        } else {
          return buyerVATRegCountryTo();
        }
      }
      case functionName.includes('inferiorOrEqual150'): {
        return inferiorOrEqual150(value);
      }
      case functionName.includes('isEqualToValue'): {
        return isEqualToValue(functionName.slice(-2));
      }
      case functionName.includes('False'): {
        return value ? false : true;
      }
      case functionName.includes('True'): {
        return value ? true : false;
      }
    }
  };

  const resetField = (fieldName) => {
    const ref: any = formikRef.current;
    if (ref) ref.setFieldValue(fieldName, initialValues[fieldName]);
  };

  const manageFieldsToReset = (conditionRes, fieldName) => {
    if (conditionRes === false) {
      let fields = fieldsToReset;
      if (!fields.find((field) => field === fieldName)) {
        fields.push(fieldName);
      }
      setfieldsToReset(fields);
    } else {
      let fields = fieldsToReset;
      const index = fields.indexOf(fieldName);
      if (index > -1) {
        fields.splice(index, 1);
      }
      setfieldsToReset(fields);
    }
  };

  const manageCondition = (condition, fieldName) => {
    if (condition) {
      //CONDITION
      if (Object.keys(condition).length === 1) {
        let functionName = condition[Object.keys(condition)[0]];
        if (functionName) {
          let formValue = getValueFromFormik(Object.keys(condition)[0]);
          const res = functionCaller(functionName, formValue);
          manageFieldsToReset(res, fieldName);

          return res;
        }
      }

      //CONDITION && CONDITION
      if (
        Object.keys(condition).find((condition) => condition === 'and') &&
        !Object.keys(condition).find((condition) => condition === 'and2')
      ) {
        const notAndFunctionKey = Object.keys(condition).find(
          (condition) => condition !== 'and'
        );
        const notAndFunctionName = condition[notAndFunctionKey];

        const andFunctionKey = Object.keys(condition).find(
          (condition) => condition === 'and'
        );
        const andFunctionName = Object.values(condition[andFunctionKey])[0];

        if (notAndFunctionName && andFunctionName) {
          const res1 = functionCaller(
            notAndFunctionName,
            getValueFromFormik(notAndFunctionKey)
          );
          const res2 = functionCaller(
            andFunctionName,
            getValueFromFormik(Object.keys(condition[andFunctionKey])[0])
          );
          manageFieldsToReset(res1 && res2, fieldName);
          return res1 && res2 ? true : false;
        }
      }
      //CONDITION && CONDITION && CONDITION
      if (
        Object.keys(condition).find((condition) => condition === 'and') &&
        Object.keys(condition).find((condition) => condition === 'and2') &&
        !Object.keys(condition).find((condition) => condition === 'or')
      ) {
        const notAndFunctionKey = Object.keys(condition).find(
          (condition) =>
            condition !== 'and' && condition !== 'and2' && condition !== 'or'
        );
        const notAndFunctionName = condition[notAndFunctionKey];

        const andFunctionKey = Object.keys(condition).find(
          (condition) => condition === 'and'
        );
        const andFunctionName = Object.values(condition[andFunctionKey])[0];

        const and2FunctionKey = Object.keys(condition).find(
          (condition) => condition === 'and2'
        );
        const and2FunctionName = Object.values(condition[and2FunctionKey])[0];

        if (notAndFunctionName && andFunctionName && and2FunctionName) {
          const res1 = functionCaller(
            notAndFunctionName,
            getValueFromFormik(notAndFunctionKey)
          );
          const res2 = functionCaller(
            andFunctionName,
            getValueFromFormik(Object.keys(condition[andFunctionKey])[0])
          );
          const res3 = functionCaller(
            and2FunctionName,
            getValueFromFormik(Object.keys(condition[and2FunctionKey])[0])
          );
          manageFieldsToReset(res1 && res2 && res3, fieldName);
          return res1 && res2 && res3 ? true : false;
        }
      }
      //CONDITION || CONDITION && CONDITION && CONDITION
      if (
        Object.keys(condition).find((condition) => condition === 'or') &&
        Object.keys(condition).find((condition) => condition === 'and') &&
        Object.keys(condition).find((condition) => condition === 'and2')
      ) {
        const notAndFunctionKey = Object.keys(condition).find(
          (condition) =>
            condition !== 'and' && condition !== 'and2' && condition !== 'or'
        );
        const notAndFunctionName = condition[notAndFunctionKey];

        const orFunctionKey = Object.keys(condition).find(
          (condition) => condition === 'or'
        );
        const orFunctionName = Object.values(condition[orFunctionKey])[0];

        const andFunctionKey = Object.keys(condition).find(
          (condition) => condition === 'and'
        );
        const andFunctionName = Object.values(condition[andFunctionKey])[0];

        const and2FunctionKey = Object.keys(condition).find(
          (condition) => condition === 'and2'
        );
        const and2FunctionName = Object.values(condition[and2FunctionKey])[0];

        if (
          notAndFunctionKey &&
          orFunctionKey &&
          andFunctionKey &&
          and2FunctionKey
        ) {
          const res1 = functionCaller(
            notAndFunctionName,
            getValueFromFormik(notAndFunctionKey)
          );
          const res2 = functionCaller(
            orFunctionName,
            getValueFromFormik(Object.keys(condition[orFunctionKey])[0])
          );
          const res3 = functionCaller(
            andFunctionName,
            getValueFromFormik(Object.keys(condition[andFunctionKey])[0])
          );
          const res4 = functionCaller(
            and2FunctionName,
            getValueFromFormik(Object.keys(condition[and2FunctionKey])[0])
          );
          manageFieldsToReset(res2 || (res1 && res3 && res4), fieldName);
          return res2 || (res1 && res3 && res4) ? true : false;
        }
      }
    } else {
      return true;
    }
  };

  const componentSwitch = (type) => {
    switch (type) {
      case 'string':
        return { component: Input };
      case 'list':
        return { component: Dropdown };
      case 'number':
        return { component: Input };
      default:
        return { component: Input };
    }
  };

  const preSubmit = () => {
    fieldsToReset.forEach((fieldName) => {
      resetField(fieldName);
    });
    const ref: any = formikRef.current;
    if (ref) ref.setFieldValue('Version', selectedVersion);
    buildYupSchema();
    setsearchformikValuesForSubmit(true);
  };

  const submit = (values) => {
    submitAction(values);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Header icon='plus circle' content='New activity' />
      {initialValues && (
        <Formik
          ref={formikRef}
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={yupSchema}
          enableReinitialize={true}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
            isSubmitting,
          }) => (
            <Modal.Content>
              <Wrapper margin='0 5rem'>
                <Form
                  onSubmit={handleSubmit}
                  loading={
                    submitLoading || (selectedVersion && !schemaToConvert)
                  }
                >
                  <Wrapper margin='1rem'>
                    <Field
                      mandatory
                      disabled={data !== undefined}
                      label='Choose a version'
                      placeholder='Choose a version'
                      name='Version'
                      options={formOptions.versions}
                      component={Dropdown}
                      onChange={setselectedVersion}
                      meta={{ errors, touched, setFieldValue, setFieldTouched }}
                    />
                  </Wrapper>

                  {schemaToConvert && (
                    <>
                      {Object.keys(schemaToConvert).forEach((key) => {
                        if (
                          schemaToConvert[key] !== null &&
                          key !== 'Version'
                        ) {
                          return (
                            <>
                              <Wrapper
                                key={key}
                                margin='1rem'
                                {...(!manageCondition(
                                  schemaToConvert[key].condition || null,
                                  key
                                ) && { display: 'none' })}
                              >
                                <Field
                                  mandatory
                                  label={schemaToConvert[key].label}
                                  placeholder={schemaToConvert[key].label}
                                  name={key}
                                  {...(schemaToConvert[key].type === 'list' && {
                                    options:
                                      options[schemaToConvert[key].options],
                                  })}
                                  {...componentSwitch(
                                    schemaToConvert[key].type
                                  )}
                                  {...(schemaToConvert[key].description && {
                                    bulleted: true,
                                    bullMsg: schemaToConvert[key].description,
                                  })}
                                  meta={{
                                    errors,
                                    touched,
                                    setFieldValue,
                                    setFieldTouched,
                                  }}
                                />
                              </Wrapper>
                            </>
                          );
                        }
                      })}
                      <Button
                        type='button'
                        content='Send'
                        color='green'
                        onClick={() => {
                          setsubmitLoading(true);
                          setTimeout(() => {
                            preSubmit();
                          }, 500);
                        }}
                      />
                    </>
                  )}
                </Form>
              </Wrapper>
            </Modal.Content>
          )}
        </Formik>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    euCountries: selectors.data.euCountriesSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
