import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {

    getDeclarationForComparison(data: dataReqI){
      const compaApi = routesApi.customs.statistics.getDeclarationForComparison;
      return api.request({
        method: compaApi.method,
        route: compaApi.path,
        data: data.data
      });
    },
  
    getLastCustoms(data: dataReqI) {
      const lastCustomsApi = routesApi.customs.statistics.getLastCustoms;
      return api.request({
        method: lastCustomsApi.method,
        route: lastCustomsApi.path,
        params: data.params,
        data: data.data
      });
    },

    getCustomsClerkStats(data?: dataReqI) {
      const clerksApi = routesApi.customs.statistics.getCustomsClerkStats;
      return api.request({
        method: clerksApi.method,
        route: clerksApi.path,
        data: data && data.data,
      });
    },

    getComputedCheds(data: dataReqI) {
      const getAllChedsApi = routesApi.customs.statistics.getComputedCheds;
      return api.request({
        method: getAllChedsApi.method,
        route: getAllChedsApi.path,
        params: data.params,
      });
    },

    getDeclarationsStatsWithFilters(data: dataReqI) {
      const customsStatsApi = routesApi.customs.statistics.getDeclarationsStatsWithFilters;
      return api.request({
        method: customsStatsApi.method,
        route: customsStatsApi.path,
        params: data.params,
        data: data.data
      });
    },

    getChedStats(data: dataReqI) {
      const getAllChedsApi = routesApi.customs.declaration.getChedStats;
      return api.request({
        method: getAllChedsApi.method,
        route: getAllChedsApi.path,
        params: data.params,
        data: data.data
      });
    },

    getStatsWithFilters(data: dataReqI) {
      const customsStatsApi = routesApi.customs.declaration.getStatsWithFilters;
      return api.request({
        method: customsStatsApi.method,
        route: customsStatsApi.path,
        params: data.params,
        data: data.data
      });
    },
    
  };
};
