import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { read, utils  } from 'xlsx'
//import Spreadsheet, { Options } from "x-data-spreadsheet";
import Wrapper from 'components/core/Wrapper/Wrapper';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import Button from 'components/Button';
import { Divider, Loader } from 'semantic-ui-react';
import { Viewer } from './excelStyles';


const Index = ({
  dispatch,
  match,
  history,
  excelId,
  companySelected,
  user,
  excelLoading,
  excel
}) => {

  const excelDocId = match.params.documentId
  const [loaded, setLoaded] = useState(null)
  const [data, setData] = useState(null)
  const userAdminOrOp = user.operator || user.admin;
  const companyId = (userAdminOrOp || user.partnership) ? match.params.companyId : companySelected;

  // const options: Options = { 
  //   view: {
  //     height: () => (document.documentElement.clientHeight*70)/100,
  //     width: () => document.documentElement.clientWidth-(document.documentElement.clientWidth*10/100),
  //   },
  //   row: {
  //     len: 90,
  //     height: 20,
  //   },
  //   col: {
  //     len: 10,
  //     width: document.documentElement.clientWidth*8.66/100,
  //     indexWidth: 60,
  //     minWidth: 60,
  //   },
  //   style: {
  //     bgcolor: '#ffffff',
  //     align: 'left',
  //     valign: 'middle',
  //     textwrap: false,
  //     strike: false,
  //     underline: false,
  //     color: '#0a0a0a',
  //     font: {
  //       name: 'Helvetica',
  //       size: 8,
  //       bold: false,
  //       italic: false,
  //     },
  //   },
  // }

  useEffect(() => {
    if(loaded !== true){
      dispatch(
        actions.fiscalVat.getVatDeclarationExcel({
          params: { 
            companyId: companyId, 
            excelId: excelId || excelDocId,
          },
        })
      );
      return () => {
        dispatch(
          actions.fiscalVat.reset(['excel', 'error'])
        );
      }
    }
  }, [dispatch, excelId, excelDocId, companyId]);


  const initStreadSheet = () => {
    // const spreadSheet = new Spreadsheet("#x-spreadsheet-demo", options)
    // return spreadSheet
  }


  useEffect(() => {
    if(excel && loaded === null){ 
      setLoaded(true)
      const reader = new FileReader();  
   
      if(typeof excel === 'object' && excel.size !== undefined){

        reader.readAsArrayBuffer(excel);         
        reader.onload = (e) => {
            const workbook = read(e.target.result, {type:'array', sheets: ["sheet1"]}); 

            const wsName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[wsName];
            const data: any = utils.sheet_to_json(worksheet, {header: 1, blankrows: false});

            const spreadSheet = initStreadSheet()
            //spreadSheet.loadData(data).change(data => { setData(data) });
  
            // build table 
            data.forEach((c, i) => {
              //spreadSheet.cellText(i, 0, c[0]).cellText(i, 1, c[1]).cellText(i, 2, c[2]).cellText(i, 3, c[3])
            })                 
        }
    }
    };
}, [excel])

  const sendFile = useCallback(() => {

    dispatch(
      actions.fiscalVat.updateVatDeclarationExcel({
        params: { 
          companyId: companyId, 
          excelId: match.params.documentId,
        },
        data: {
          data
        }
      })
    );
  }, [data])
  
  return (
    excelLoading && 
        (<Loader content='Loading' />)
    || 

    <Wrapper width='90%' margin='auto'>
     
      <Divider hidden />
      <Wrapper display='flex'>
        <ButtonBack 
        history={history} 
        addAction={() => {
          setData(null)
        }}
        />
      </Wrapper>
      <Divider hidden />
          <Button
            icon='edit'
            type='button'
            size='small'
            color='blue'
            content='save'
            margin='10px 0 0 0'
            onClick={() => sendFile()}
          />
      <Wrapper display='flex' justifyContent='space-between'>
        <Viewer id={"x-spreadsheet-demo"}></Viewer> 
      </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    excel: selectors.fiscalVat.excelSelector(state),
    excelLoading: selectors.fiscalVat.loadingSelector(state),
    companySelected: selectors.auth.companySelected(state)
  }
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
