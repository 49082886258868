import React from 'react';
import {
  Segment,
  Popup,
  Icon,
  Divider,
  Label,
  Dropdown as DropdownLabel,
  Grid,
} from 'semantic-ui-react';

import { Field } from 'formik';
import CustomInput from './CustomInput';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from 'components/Button';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import Dropdown from 'components/Dropdown';
import Wrapper from 'components/core/Wrapper/Wrapper';

const Index = ({
  setFieldValue,
  setFieldTouched,
  manageSteps,
  customsData,
  errors,
  touched,
  values,
}: any) => {
  const packageMethodList = [
    {
      key: 0,
      value: 'Pallet',
      text: 'Pallet',
    },
    {
      key: 1,
      value: 'Box',
      text: 'Box',
    },
    {
      key: 2,
      value: 'Package',
      text: 'Package',
    },
    {
      key: 3,
      value: 'Bulk',
      text: 'Bulk',
    },
    {
      key: 4,
      value: 'Contrary',
      text: 'Contrary',
    },
    {
      key: 5,
      value: 'Other',
      text: 'Other',
    },
  ];
  return (
    <Wrapper width='40%' margin='auto' minWidth='400px'>
      <Segment>
        <Grid columns='2'>
          <Grid.Column>
            <Field
              label={<MandatoryFieldLabel content='Method of packing' />}
              placeholder='Ex: pallets...'
              name='deliveryValue.packageMethod'
              options={packageMethodList}
              component={Dropdown}
              meta={{ errors, touched, setFieldValue, setFieldTouched }}
            />
          </Grid.Column>
          <Grid.Column>
            <Field
              label={<MandatoryFieldLabel content='Number of packages' />}
              name={'deliveryValue.packageNumber'}
              type='number'
              component={CustomInput}
              meta={{ errors, touched }}
            />
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Field
          placeholder='Ex: boxes...'
          label={<MandatoryFieldLabel content='Nature of the goods' />}
          name={'deliveryValue.natureOfGoods'}
          type='text'
          component={CustomInput}
          meta={{ errors, touched }}
          margin='5px'
          columns='2'
          labelVerticalPosition='center'
          justifyContent='space-between'
        />
        <Divider hidden />
        <Wrapper display='flex' justifyContent='center'>
          <Field
            label={<MandatoryFieldLabel content='Gross weight' />}
            sideLabel={
              <DropdownLabel
                defaultValue={values.deliveryValue.grossWeightUnit}
                options={[
                  { key: 'kg', text: 'kg', value: 'kg' },
                  { key: 't', text: 't', value: 't' },
                ]}
                onChange={(e, a: any) =>
                  setFieldValue('deliveryValue.grossWeightUnit', a.value)
                }
              />
            }
            sideLabelPosition='right'
            name={'deliveryValue.grossWeight'}
            type='number'
            component={CustomInput}
            meta={{ errors, touched }}
            margin='5px'
            columns='2'
            labelVerticalPosition='center'
            justifyContent='space-between'
          />
          <Field
            label='Volume'
            sideLabel={
              <DropdownLabel
                defaultValue={values.deliveryValue.volumeUnit}
                options={[
                  { key: 'm3', text: 'm3', value: 'm3' },
                  { key: 'cm3', text: 'cm3', value: 'cm3' },
                  { key: 'L', text: 'L', value: 'L' },
                  { key: 'U', text: 'U', value: 'U' },
                ]}
                onChange={(e, a: any) =>
                  setFieldValue('deliveryValue.volumeUnit', a.value)
                }
              />
            }
            sideLabelPosition='right'
            name='deliveryValue.volume'
            type='number'
            component={CustomInput}
            meta={{ errors, touched }}
            margin='5px'
            columns='2'
            labelVerticalPosition='center'
            justifyContent='space-between'
          />
        </Wrapper>
        <Divider hidden />
        <Field
          label={
            <p>
              <MandatoryFieldLabel content='Total value of your delivery' />
              <Popup
                trigger={<Icon name='info circle' />}
                content={'VAT excluded'}
              />
            </p>
          }
          sideLabel={<Label>€</Label>}
          sideLabelPosition='right'
          name={'deliveryValue.value'}
          type='number'
          component={CustomInput}
          meta={{ errors, touched }}
          margin='5px'
          columns='2'
          labelVerticalPosition='center'
          justifyContent='space-between'
        />
      </Segment>
      <Button
        type='button'
        size='large'
        icon='right arrow'
        labelPosition='right'
        color='blue'
        content='Next'
        margin='5px'
        onClick={() => manageSteps(3)}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
