import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    createVat: false,
    getAllVat: false,
    getOneVat: false,
    deleteVat: false,
    updateVat: false,
  },
  errors: {
    createVat: null,
    getAllVat: null,
    getOneVat: null,
    deleteVat: null,
    updateVat: null,
  },
  loading: {
    createVat: false,
    getAllVat: false,
    getOneVat: false,
    deleteVat: false,
    updateVat: false,
  },
  vats: null,
  vat: null,
  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Get all vatss
    case actionTypes.CREATE_VAT_LOADING:
      return produce(state, draft => {
        draft.loading.createVat = true;
      });

    case actionTypes.CREATE_VAT_SUCCESS:
      return produce(state, draft => {
        draft.errors.createVat = null;
        draft.loading.createVat = false;
        draft.success.createVat = true;
      });

    case actionTypes.CREATE_VAT_ERROR:
      return produce(state, draft => {
        draft.errors.createVat = true;
        draft.loading.createVat = false;
        draft.success.createVat = false;
        !draft.error.includes("createVat") &&
          draft.error.push("createVat");
      });

    // Get one vats
    case actionTypes.GET_ALL_VAT_LOADING:
      return produce(state, draft => {
        draft.loading.getAllVat = true;
      });

    case actionTypes.GET_ALL_VAT_SUCCESS:
      return produce(state, draft => {
        draft.errors.getAllVat = null;
        draft.loading.getAllVat = false;
        draft.success.getAllVat = true;
        draft.vats = action.payload;
      });

    case actionTypes.GET_ALL_VAT_ERROR:
      return produce(state, draft => {
        draft.errors.getAllVat = true;
        draft.loading.getAllVat = false;
        draft.success.getAllVat = false;
        !draft.error.includes("vats") &&
          draft.error.push("vats");
      });

    // Add vats
    case actionTypes.GET_ONE_VAT_LOADING:
      return produce(state, draft => {
        draft.loading.getOneVat = true;
      });

    case actionTypes.GET_ONE_VAT_SUCCESS:
      return produce(state, draft => {
        draft.errors.getOneVat = null;
        draft.loading.getOneVat = false;
        draft.success.getOneVat = true;
        draft.vat = action.payload;
      });

    case actionTypes.GET_ONE_VAT_ERROR:
      return produce(state, draft => {
        draft.errors.getOneVat = true;
        draft.loading.getOneVat = false;
        draft.success.getOneVat = false;
        !draft.error.includes("vat") && draft.error.push("vat");
      });

    // Delete vats
    case actionTypes.DELETE_VAT_LOADING:
      return produce(state, draft => {
        draft.loading.deleteVat = true;
      });

    case actionTypes.DELETE_VAT_SUCCESS:
      return produce(state, draft => {
        draft.errors.deleteVat = null;
        draft.loading.deleteVat = false;
        draft.success.deleteVat = true;
      });

    case actionTypes.DELETE_VAT_ERROR:
      return produce(state, draft => {
        draft.errors.deleteVat = true;
        draft.loading.deleteVat = false;
        draft.success.deleteVat = false;
        !draft.error.includes("deleteVat") &&
          draft.error.push("deleteVat");
      });

    // Validate vats
    case actionTypes.UPDATE_VAT_LOADING:
      return produce(state, draft => {
        draft.loading.updateVat = true;
      });

    case actionTypes.UPDATE_VAT_SUCCESS:
      return produce(state, draft => {
        draft.errors.updateVat = null;
        draft.loading.updateVat = false;
        draft.success.updateVat = true;
      });

    case actionTypes.UPDATE_VAT_ERROR:
      return produce(state, draft => {
        draft.errors.updateVat = true;
        draft.loading.updateVat = false;
        draft.success.updateVat = false;
        !draft.error.includes("updateVat") &&
          draft.error.push("updateVat");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
