// Dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

// Interfaces
import PopupProperties from '../../interfaces/popupProperties';

// Resources
import cross from '../../assets/images/logos/cross.png';
import saveLogo from '../../assets/images/logos/save.png';

// Stylesheets
import './popup.css';

/**
 * @name popUp.tsx
 * @implements popUpProperties.tsx
 *
 * @path /components/popUp/popUp.tsx
 *
 * @description Defines a popup in current page
 *
 * @param myRef popup reference
 * @param title popup title
 * @param children popup sub-components
 */
const Popup: React.FC<PopupProperties> = ({
  myRef,
  title,
  onClickClose,
  children,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <div className='popup'>
      <div ref={myRef} className='popup-inner'>
        <div className='popup-header'>
          <h1>{t(title)}</h1>
          <img
            src={cross}
            alt={t('popupStrings.cross')}
            onClick={onClickClose}
          />
        </div>
        <div className='popup-body'>{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default Popup;
