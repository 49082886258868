export default {
  LOCK: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.LOCK',
  LOCK_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.LOCK_LOADING',
  LOCK_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.LOCK_SUCCESS',
  LOCK_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.LOCK_ERROR',

  UNLOCK: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UNLOCK',
  UNLOCK_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UNLOCK_LOADING',
  UNLOCK_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UNLOCK_SUCCESS',
  UNLOCK_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UNLOCK_ERROR',

  CREATE_FILE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_FILE',
  CREATE_FILE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_FILE_LOADING',
  CREATE_FILE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_FILE_SUCCESS',
  CREATE_FILE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_FILE_ERROR',

  UPDATE_FILE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_FILE',
  UPDATE_FILE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_FILE_LOADING',
  UPDATE_FILE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_FILE_SUCCESS',
  UPDATE_FILE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_FILE_ERROR',

  GET_ONE_FILE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ONE_FILE',
  GET_ONE_FILE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ONE_FILE_LOADING',
  GET_ONE_FILE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ONE_FILE_SUCCESS',
  GET_ONE_FILE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ONE_FILE_ERROR',

  GET_START_SIGNATURE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_START_SIGNATURE',
  GET_START_SIGNATURE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_START_SIGNATURE_LOADING',
  GET_START_SIGNATURE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_START_SIGNATURE_SUCCESS',
  GET_START_SIGNATURE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_START_SIGNATURE_ERROR',

  GET_TRAILER_LICENSE_PLATE:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_TRAILER_LICENSE_PLATE',
  GET_TRAILER_LICENSE_PLATE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_TRAILER_LICENSE_PLATE_LOADING',
  GET_TRAILER_LICENSE_PLATE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_TRAILER_LICENSE_PLATE_SUCCESS',
  GET_TRAILER_LICENSE_PLATE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_TRAILER_LICENSE_PLATE_ERROR',

  ADD_TRAILER_LICENSE_PLATE:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_TRAILER_LICENSE_PLATE',
  ADD_TRAILER_LICENSE_PLATE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_TRAILER_LICENSE_PLATE_LOADING',
  ADD_TRAILER_LICENSE_PLATE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_TRAILER_LICENSE_PLATE_SUCCESS',
  ADD_TRAILER_LICENSE_PLATE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_TRAILER_LICENSE_PLATE_ERROR',

  DELETE_TRAILER_LICENSE_PLATE:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_TRAILER_LICENSE_PLATE',
  DELETE_TRAILER_LICENSE_PLATE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_TRAILER_LICENSE_PLATE_LOADING',
  DELETE_TRAILER_LICENSE_PLATE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_TRAILER_LICENSE_PLATE_SUCCESS',
  DELETE_TRAILER_LICENSE_PLATE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_TRAILER_LICENSE_PLATE_ERROR',

  GET_PAYMENT_RECEIPT: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_PAYMENT_RECEIPT',
  GET_PAYMENT_RECEIPT_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_PAYMENT_RECEIPT_LOADING',
  GET_PAYMENT_RECEIPT_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_PAYMENT_RECEIPT_SUCCESS',
  GET_PAYMENT_RECEIPT_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_PAYMENT_RECEIPT_ERROR',

  GET_END_SIGNATURE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_END_SIGNATURE',
  GET_END_SIGNATURE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_END_SIGNATURE_LOADING',
  GET_END_SIGNATURE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_END_SIGNATURE_SUCCESS',
  GET_END_SIGNATURE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_END_SIGNATURE_ERROR',

  GET_DECLARATION_IN_PROGRESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_DECLARATION_IN_PROGRESS',
  GET_DECLARATION_IN_PROGRESS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_DECLARATION_IN_PROGRESS_LOADING',
  GET_DECLARATION_IN_PROGRESS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_DECLARATION_IN_PROGRESS_SUCCESS',
  GET_DECLARATION_IN_PROGRESS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_DECLARATION_IN_PROGRESS_ERROR',

  GET_ISSUE_TIMES: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ISSUE_TIMES',
  GET_ISSUE_TIMES_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ISSUE_TIMES_LOADING',
  GET_ISSUE_TIMES_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ISSUE_TIMES_SUCCESS',
  GET_ISSUE_TIMES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ISSUE_TIMES_ERROR',

  ADD_ISSUE_TIME: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_ISSUE_TIME',
  ADD_ISSUE_TIME_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_ISSUE_TIME_LOADING',
  ADD_ISSUE_TIME_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_ISSUE_TIME_SUCCESS',
  ADD_ISSUE_TIME_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_ISSUE_TIME_ERROR',

  DELETE_ISSUE_TIME: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_ISSUE_TIME',
  DELETE_ISSUE_TIME_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_ISSUE_TIME_LOADING',
  DELETE_ISSUE_TIME_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_ISSUE_TIME_SUCCESS',
  DELETE_ISSUE_TIME_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_ISSUE_TIME_ERROR',

  UPDATE_ISSUE_TIME: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_ISSUE_TIME',
  UPDATE_ISSUE_TIME_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_ISSUE_TIME_LOADING',
  UPDATE_ISSUE_TIME_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_ISSUE_TIME_SUCCESS',
  UPDATE_ISSUE_TIME_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_ISSUE_TIME_ERROR',

  GET_FILES_DATA_BY_STEP: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_FILES_DATA_BY_STEP',
  GET_FILES_DATA_BY_STEP_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_FILES_DATA_BY_STEP_LOADING',
  GET_FILES_DATA_BY_STEP_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_FILES_DATA_BY_STEP_SUCCESS',
  GET_FILES_DATA_BY_STEP_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_FILES_DATA_BY_STEP_ERROR',

  GET_ISSUE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ISSUE',
  GET_ISSUE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ISSUE_LOADING',
  GET_ISSUE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ISSUE_SUCCESS',
  GET_ISSUE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ISSUE_ERROR',

  DOWNLOAD_PICTURES_ISSUES_ZIP:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DOWNLOAD_PICTURES_ISSUES_ZIP',
  DOWNLOAD_PICTURES_ISSUES_ZIP_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DOWNLOAD_PICTURES_ISSUES_ZIP_LOADING',
  DOWNLOAD_PICTURES_ISSUES_ZIP_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DOWNLOAD_PICTURES_ISSUES_ZIP_SUCCESS',
  DOWNLOAD_PICTURES_ISSUES_ZIP_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DOWNLOAD_PICTURES_ISSUES_ZIP_ERROR',

  GET_SUMMARY: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_SUMMARY',
  GET_SUMMARY_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_SUMMARY_LOADING',
  GET_SUMMARY_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_SUMMARY_SUCCESS',
  GET_SUMMARY_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_SUMMARY_ERROR',

  UPDATE_COMMENT: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_COMMENT',
  UPDATE_COMMENT_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_COMMENT_LOADING',
  UPDATE_COMMENT_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_COMMENT_SUCCESS',
  UPDATE_COMMENT_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_COMMENT_ERROR',

  GET_ALL_FILES: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ALL_FILES',
  GET_ALL_FILES_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ALL_FILES_LOADING',
  GET_ALL_FILES_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ALL_FILES_SUCCESS',
  GET_ALL_FILES_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ALL_FILES_ERROR',

  GET_ALL_FILES_BY_FILTERS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ALL_FILES_BY_FILTERS',
  GET_ALL_FILES_BY_FILTERS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ALL_FILES_BY_FILTERS_LOADING',
  GET_ALL_FILES_BY_FILTERS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ALL_FILES_BY_FILTERS_SUCCESS',
  GET_ALL_FILES_BY_FILTERS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_ALL_FILES_BY_FILTERS_ERROR',

  UPDATE_ISSUE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_ISSUE',
  UPDATE_ISSUE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_ISSUE_LOADING',
  UPDATE_ISSUE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_ISSUE_SUCCESS',
  UPDATE_ISSUE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_ISSUE_ERROR',

  ADD_ISSUE_PICTURES: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_ISSUE_PICTURES',
  ADD_ISSUE_PICTURES_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_ISSUE_PICTURES_LOADING',
  ADD_ISSUE_PICTURES_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_ISSUE_PICTURES_SUCCESS',
  ADD_ISSUE_PICTURES_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_ISSUE_PICTURES_ERROR',

  DELETE_ISSUE_PICTURE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_ISSUE_PICTURE',
  DELETE_ISSUE_PICTURE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_ISSUE_PICTURE_LOADING',
  DELETE_ISSUE_PICTURE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_ISSUE_PICTURE_SUCCESS',
  DELETE_ISSUE_PICTURE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DELETE_ISSUE_PICTURE_ERROR',

  GET_BILLINGS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_BILLINGS',
  GET_BILLINGS_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_BILLINGS_LOADING',
  GET_BILLINGS_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_BILLINGS_SUCCESS',
  GET_BILLINGS_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_BILLINGS_ERROR',

  GET_FILE_HAS_BILLINGS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_FILE_HAS_BILLINGS',
  GET_FILE_HAS_BILLINGS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_FILE_HAS_BILLINGS_LOADING',
  GET_FILE_HAS_BILLINGS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_FILE_HAS_BILLINGS_SUCCESS',
  GET_FILE_HAS_BILLINGS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_FILE_HAS_BILLINGS_ERROR',

  GET_CD_HAS_BILLINGS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_CD_HAS_BILLINGS',
  GET_CD_HAS_BILLINGS_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_CD_HAS_BILLINGS_LOADING',
  GET_CD_HAS_BILLINGS_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_CD_HAS_BILLINGS_SUCCESS',
  GET_CD_HAS_BILLINGS_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.GET_CD_HAS_BILLINGS_ERROR',


  ADD_INVOICING_LINE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_INVOICING_LINE',
  ADD_INVOICING_LINE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_INVOICING_LINE_LOADING',
  ADD_INVOICING_LINE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_INVOICING_LINE_SUCCESS',
  ADD_INVOICING_LINE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.ADD_INVOICING_LINE_ERROR',

  UPDATE_INVOICING_LINE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_INVOICING_LINE',
  UPDATE_INVOICING_LINE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_INVOICING_LINE_LOADING',
  UPDATE_INVOICING_LINE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_INVOICING_LINE_SUCCESS',
  UPDATE_INVOICING_LINE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_INVOICING_LINE_ERROR',

  REMOVE_INVOICING_LINE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.REMOVE_INVOICING_LINE',
  REMOVE_INVOICING_LINE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.REMOVE_INVOICING_LINE_LOADING',
  REMOVE_INVOICING_LINE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.REMOVE_INVOICING_LINE_SUCCESS',
  REMOVE_INVOICING_LINE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.REMOVE_INVOICING_LINE_ERROR',

  VALIDATE_FILE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.VALIDATE_FILE',
  VALIDATE_FILE_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.VALIDATE_FILE_LOADING',
  VALIDATE_FILE_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.VALIDATE_FILE_SUCCESS',
  VALIDATE_FILE_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.VALIDATE_FILE_ERROR',

  UPDATE_INVOICING_STEP: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_INVOICING_STEP',
  UPDATE_INVOICING_STEP_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_INVOICING_STEP_LOADING',
  UPDATE_INVOICING_STEP_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_INVOICING_STEP_SUCCESS',
  UPDATE_INVOICING_STEP_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.UPDATE_INVOICING_STEP_ERROR',

  VALIDATE_FILE_STATE: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.VALIDATE_FILE_STATE',
  VALIDATE_FILE_STATE_LOADING:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.VALIDATE_FILE_STATE_LOADING',
  VALIDATE_FILE_STATE_SUCCESS:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.VALIDATE_FILE_STATE_SUCCESS',
  VALIDATE_FILE_STATE_ERROR:
    'MY_ASD.CUSTOMS.CUSTOMS_CLERK.VALIDATE_FILE_STATE_ERROR',

  RESET: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.RESET',
  REFRESH_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.REFRESH_ERROR',

  CREATE_CONTROL_PDF: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_CONTROL_PDF',
  CREATE_CONTROL_PDF_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_CONTROL_PDF_LOADING',
  CREATE_CONTROL_PDF_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_CONTROL_PDF_SUCCESS',
  CREATE_CONTROL_PDF_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_CONTROL_PDF_ERROR',

  SUBSCRIBE_CREATE_CONTROL_PDF: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.SUBSCRIBE_CREATE_CONTROL_PDF',
  SUBSCRIBE_CREATE_CONTROL_PDF_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.SUBSCRIBE_CREATE_CONTROL_PDF_LOADING',
  SUBSCRIBE_CREATE_CONTROL_PDF_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.SUBSCRIBE_CREATE_CONTROL_PDF_SUCCESS',
  SUBSCRIBE_CREATE_CONTROL_PDF_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.SUBSCRIBE_CREATE_CONTROL_PDF_ERROR',

  DOWNLOAD_PDF: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DOWNLOAD_PDF',
  DOWNLOAD_PDF_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DOWNLOAD_PDF_LOADING',
  DOWNLOAD_PDF_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DOWNLOAD_PDF_SUCCESS',
  DOWNLOAD_PDF_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.DOWNLOAD_PDF_ERROR',

  CREATE_POCHETTE_PDF: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_POCHETTE_PDF', 
  CREATE_POCHETTE_PDF_LOADING: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_POCHETTE_PDF_LOADING', 
  CREATE_POCHETTE_PDF_SUCCESS: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_POCHETTE_PDF_SUCCESS', 
  CREATE_POCHETTE_PDF_ERROR: 'MY_ASD.CUSTOMS.CUSTOMS_CLERK.CREATE_POCHETTE_PDF_ERROR', 

};
