let actionTypes = {
  
  SET_DONE_KEYINFO: "MY_ASD.COMPANY_KEYINFO.SET_DONE_KEYINFO",
  SET_DONE_KEYINFO_LOADING: "MY_ASD.COMPANY_KEYINFO.SET_DONE_KEYINFO_LOADING",
  SET_DONE_KEYINFO_SUCCESS: "MY_ASD.COMPANY_KEYINFO.SET_DONE_KEYINFO_SUCCESS",
  SET_DONE_KEYINFO_ERROR: "MY_ASD.COMPANY_KEYINFO.SET_DONE_KEYINFO_ERROR",

  CHECK_DONE_KEYINFO: "MY_ASD.COMPANY_KEYINFO.CHECK_DONE_KEYINFO",
  CHECK_DONE_KEYINFO_LOADING: "MY_ASD.COMPANY_KEYINFO.CHECK_DONE_KEYINFO_LOADING",
  CHECK_DONE_KEYINFO_SUCCESS: "MY_ASD.COMPANY_KEYINFO.CHECK_DONE_KEYINFO_SUCCESS",
  CHECK_DONE_KEYINFO_ERROR: "MY_ASD.COMPANY_KEYINFO.CHECK_DONE_KEYINFO_ERROR",

  GET_KEYINFOS: "MY_ASD.COMPANY_KEYINFO.GET_KEYINFOS",
  GET_KEYINFOS_LOADING: "MY_ASD.COMPANY_KEYINFO.GET_KEYINFOS_LOADING",
  GET_KEYINFOS_SUCCESS: "MY_ASD.COMPANY_KEYINFO.GET_KEYINFOS_SUCCESS",
  GET_KEYINFOS_ERROR: "MY_ASD.COMPANY_KEYINFO.GET_KEYINFOS_ERROR",

  ADD_KEYINFO: "MY_ASD.COMPANY_KEYINFO.ADD_KEYINFO",
  ADD_KEYINFO_LOADING: "MY_ASD.COMPANY_KEYINFO.ADD_KEYINFO_LOADING",
  ADD_KEYINFO_SUCCESS: "MY_ASD.COMPANY_KEYINFO.ADD_KEYINFO_SUCCESS",
  ADD_KEYINFO_ERROR: "MY_ASD.COMPANY_KEYINFO.ADD_KEYINFO_ERROR",

  UPDATE_KEYINFO: "MY_ASD.COMPANY_KEYINFO.UPDATE_KEYINFO",
  UPDATE_KEYINFO_LOADING: "MY_ASD.COMPANY_KEYINFO.UPDATE_KEYINFO",
  UPDATE_KEYINFO_SUCCESS: "MY_ASD.COMPANY_KEYINFO.UPDATE_KEYINFO_SUCCESS",
  UPDATE_KEYINFO_ERROR: "MY_ASD.COMPANY_KEYINFO.UPDATE_KEYINFO_ERROR",

  REMOVE_KEYINFO: "MY_ASD.COMPANY_KEYINFO.REMOVE_KEYINFO",
  REMOVE_KEYINFO_LOADING: "MY_ASD.COMPANY_KEYINFO.REMOVE_KEYINFO_LOADING",
  REMOVE_KEYINFO_SUCCESS: "MY_ASD.COMPANY_KEYINFO.REMOVE_KEYINFO_SUCCESS",
  REMOVE_KEYINFO_ERROR: "MY_ASD.COMPANY_KEYINFO.REMOVE_KEYINFO_ERROR",

  RESET: "MY_ASD.COMPANY_KEYINFO.RES",
  REFRESH_ERROR: "MY_ASD.COMPANY_KEYINFO.REFRESH_ERROR",
};

export default actionTypes;
