import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Modal, Header, Message, Grid, Table, Icon, Divider } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import { actions, selectors} from 'store';

import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import { Checkbox, Input } from 'components/Form';
import Spacer from 'components/core/Spacer/Spacer';
import styled from '@emotion/styled';

const Index = ({
  errorHandler,
  open,
  data,
  handleClose,
  intl,
  dispatch,
  companyId,
  getContactsRights,
  handeSubmitRights,
  setRunningAction,
  getContactsRightsLoading,
  addContactsRightsLoading,
}) => {

  const [list, setList] = useState(null)
  const [cheds, setCheds] = useState(null)
  const [contracts, setContracts] = useState(null)
  const checkAdd = true
  const checkRead = true
  const checkDelete = true

  const inputHide = {
    display: 'none',
  };

  const Normal = styled.span(() => ({
    fontWeight: 'normal'
  }))

 useEffect(() => {
  data && dispatch(actions.contact.getContactRights({
      params: {
        companyId: companyId,
        contactId: data.id
      },
    }));
    return () => {
      dispatch(actions.contact.reset(['getContactsRights', 'error']));
    }
  }, [dispatch, companyId, data])

  useEffect(()=> {
    if(getContactsRights){
      const data = 
      getContactsRights.documents !== null ?
      getContactsRights.documents :
      [
        {
          "id": 1,
          "add": false,
          "name": "MRN",
          "read": false,
          "delete": false
        },
        {
          "id": 2,
          "add": false,
          "name": "Invoice",
          "read": false,
          "delete": false
        },
        {
          "id": 3,
          "add": false,
          "name": "Packaging List",
          "read": false,
          "delete": false
        },
        {
          "id": 4,
          "add": false,
          "name": "Packaging List CSV",
          "read": false,
          "delete": false
        },
        {
          "id": 5,
          "add": false,
          "name": "Certificates",
          "read": false,
          "delete": false
        },
        {
          "id": 6,
          "add": false,
          "name": "Other",
          "read": false,
          "delete": false
        },
        {
          "id": 7,
          "add": false,
          "name": "Sanitary certificate notices",
          "read": false,
          "delete": false
        },
        {
          "id": 8,
          "add": false,
          "name": "DAU Regime 42",
          "read": false,
          "delete": false
        },
        {
          "id": 9,
          "add": false,
          "name": "DAU Regime 40",
          "read": false,
          "delete": false
        }
      ]

      if(data.length){

        setList(prevState => ({
          ...prevState,
          data,
        }))

        const ch = 
        getContactsRights.ched !== null ?
        getContactsRights.ched : 
        [
          {
            "id": 11,
            "add": false,
            "name": "CHED",
            "read": false,
            "delete": false
          }
        ]
        setCheds(prevState => ({
          ...prevState,
          ch,
        }))

        const co =
        getContactsRights.contract !== undefined ?
        getContactsRights.contract :
        [
          {
            "Douane": false,
            "TaxAgent": false,
            "MandatSivep": false,
            "OriginPref": false
          }
        ]

        setContracts(prevState => ({
          ...prevState,
          co,
        }))
      }
    }
  }, [getContactsRights]);

  const setNativeCheckbox = (status, className: string) => {
    var checkbox = document.getElementsByClassName(className);
    for(var i = 0; i < checkbox.length; i++){
      Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked').set.call(checkbox[i], status);
      checkbox[i].dispatchEvent(new Event('click', { bubbles: true })); 
    }
  }
  

  return (
    <Modal open={open} onClose={handleClose} loading={(getContactsRightsLoading || list === null || list.data === null)}>
      <Modal.Content>
        <Wrapper>
          <Header>Change rights : <Normal>{data.name} </Normal></Header>
            <Grid columns={1} width='100%'> 
          <Grid.Column>
          <Formik 
            initialValues={{
              comments: (getContactsRights && getContactsRights.comments) || '' ,
              permissions: (list && list.data) || '',
              chat: (getContactsRights && getContactsRights.chat) || false,
              ched: (cheds && cheds.ch) ||  '',
              billing: (getContactsRights && getContactsRights.billing) || null,
              contracts: (contracts && contracts.co) || '',
              state: null,
              contactId: data.id
              }}
              enableReinitialize={true}
              onSubmit={handeSubmitRights}
            >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
            }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Wrapper margin='20px'>
                <Field
                  name={`comments`}
                  label='Comments '
                  placeholder='Permission defined...'
                  component={Input}                        
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              <Spacer height='20px' />                            
              <Grid.Column>
                <Table size="small" compact="very">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='tableHead'>Documents rights</Table.HeaderCell>
                      <Table.HeaderCell style={{'minHeight': '35px'}}>
                        <input
                          type='checkbox'
                          name='checkAdd'
                          value='add'
                          id='checkAdd'
                          style={inputHide}
                          onClick={() => {
                            let ched
                            setNativeCheckbox(checkAdd, "check_add")
                            values.permissions.forEach((e: any, i) => {
                              if(i === 1){
                                ched = !e.add
                              }
                              setFieldValue(`permissions[${i}].add`, !e.add)
                            })  
                            setFieldValue(`ched[0].add`, ched)                    
                          }}                      
                        />
                        <label style={{
                          background: '#ffffff',
                          padding: '2px 10px',
                          borderRadius: '10px',
                          cursor: 'pointer',
                          border: '1px solid #dcd7d7',
                          float: 'left',
                        }} htmlFor='checkAdd'>Add</label> 
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{'min-height': '55px'}}>
                        <input
                          type='checkbox'
                          name='checkRead'
                          value='Read'
                          id='checkRead'
                          style={inputHide}
                          onClick={() => {
                            setNativeCheckbox(checkRead, "check_read") 
                            let ched    
                            values.permissions.forEach((e: any, i) => {
                              if(i === 1){
                                ched = !e.read
                              }
                              setFieldValue(`permissions[${i}].read`, !e.read)
                            
                            }) 
                            setFieldValue(`ched[0].read`, ched)                                                  
                          }}                      
                        />
                        <label style={{
                          background: '#ffffff',
                          padding: '2px 10px',
                          borderRadius: '10px',
                          border: '1px solid #dcd7d7',
                          cursor: 'pointer',
                          float: 'left',
                        }} htmlFor='checkRead'>Read</label> 
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{'min-height': '35px'}}>
                        <input
                          type='checkbox'
                          name='checkDelete'
                          value='Delete'
                          id='checkDelete'
                          style={inputHide}
                          onClick={() => {  
                            let ched                                   
                            setNativeCheckbox(checkDelete, "check_delete")
                            values.permissions.forEach((e: any, i) => {
                              if(i === 1){
                                ched = !e.delete
                              }
                              setFieldValue(`permissions[${i}].delete`, !e.delete)
                            }) 
                            setFieldValue(`ched[0].delete`, ched)                         
                          }}                      
                        />
                        <label style={{
                          background: '#ffffff',
                          padding: '2px 10px',
                          borderRadius: '10px',
                          cursor: 'pointer',
                          border: '1px solid #dcd7d7',
                          float: 'left',
                        }} htmlFor='checkDelete'>Delete</label> 
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body> 
                          
                  { values.permissions.length > 0 &&
                    values.permissions.map((e: any, i) => {
                    return (
                      <Table.Row key={i}>
                          <Table.Cell key={0}> 
                            <Field
                              name={`permissions[${i}].name`}
                              value={`permissions[${i}].name`}
                              type='hidden'       
                              meta={{
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }}
                            />
                            <Field
                              name={`permissions[${i}].id`}
                              value={`permissions[${i}].id`}
                              type='hidden'                        
                              meta={{
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }}
                            />
                            {e.name}                       
                          </Table.Cell>
                          <Table.Cell key={1}>
                          <Field
                            key={i}
                            name={`permissions[${i}].add`}
                            checked={values.permissions[i].add}
                            value={`permissions[${i}].add`}
                            component={Checkbox}
                            label='Add'
                            onChange={() => {
                              setFieldValue(`permissions[${i}].add`, !values.permissions[i].add)
                            }}             
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                          /></Table.Cell>
                          <Table.Cell key={2}>  
                          <Field
                            key={i}
                            name={`permissions[${i}].read`}
                            checked={values.permissions[i].read}
                            value={`permissions[${i}].read`}
                            component={Checkbox}
                            label='Read'
                            onChange={() => {
                              setFieldValue(`permissions[${i}].read`, !values.permissions[i].read)
                            }}             
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell key={3}>  
                        <Field
                            key={i}
                            name={`permissions[${i}].delete`}
                            checked={values.permissions[i].delete}
                            value={`permissions[${i}].delete`}
                            component={Checkbox}
                            label='Delete'
                            onChange={() => {
                              setFieldValue(`permissions[${i}].delete`, !values.permissions[i].delete)
                            }}          
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                          />
                          </Table.Cell>
                      </Table.Row>             
                    );
                  })}                          
                  {values.ched && values.ched.length>0 && (
                        <Table.Row>
                        <Table.Cell key={0}>
                        <Field
                          name={`ched[0].name`}
                          type='hidden'
                          value={`ched[0].name`}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        /> 
                        <Field
                          name={`ched[0].id`}
                          type='hidden'
                          value={`ched[0].id`}             
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        /> 
                        CHED
                        </Table.Cell>
                                                                                                                      
                        <Table.Cell key={1}> 
                          <Field
                            label='Add'
                            name={`ched[0].add`}
                            component={Checkbox}
                            value={`ched[0].add`}
                            checked={values.ched[0].add}
                            onChange={() => {
                              setFieldValue(`ched[0].add`, !values.ched[0].add)
                            }}          
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell key={2}>  
                        <Field
                            label='Read'
                            name={`ched[0].read`}
                            component={Checkbox}
                            value={`ched[0].read`}
                            checked={values.ched[0].read}
                            onChange={() => {
                              setFieldValue(`ched[0].read`, !values.ched[0].read)
                            }}         
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell key={3}>  
                        <Field
                            label='Delete'
                            name={`ched[0].delete`}
                            component={Checkbox}
                            value={`ched[0].delete`}
                            checked={values.ched[0].delete}
                            onChange={() => {
                              setFieldValue(`ched[0].delete`, !values.ched[0].delete)
                            }}         
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                          /></Table.Cell>
                        </Table.Row>
                      )}                 
                </Table.Body>
                </Table>
                  <Message>
                  <Wrapper margin='20px'>
                    <Field
                      label='Enable - NewsFeed - on partner customs declaration page'
                      name='chat'
                      float='none'
                      checked={values.chat}
                      component={Checkbox}
                      onChange={() => {
                        setFieldValue('chat', !values.chat);
                      }}
                      
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                    <Field
                      name='billing'
                      label='Enable Billing'
                      float='none'
                      component={Checkbox}
                      checked={values.billing}
                      onChange={() => {
                        setFieldValue('billing', !values.billing);
                      }}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Wrapper>   
                  </Message> 
                </Grid.Column>
                <Divider hidden />
                <Grid.Column>
                <Grid columns={4}>
                {values.contracts.length > 0 &&
                    values.contracts.map((e: any, i) => {
                    return ( 
                      <>                
                        <Grid.Column> 
                        <Field
                          label='Douane'
                          name={`contracts[0].Douane`}
                          value={values.contracts[0].Douane}     
                          component={Checkbox}
                          checked={e.Douane}  
                          onChange={() => {
                            setFieldValue(`contracts[0].Douane`, !values.contracts[0].Douane);
                          }}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                   
                   </Grid.Column>
                   <Grid.Column>
                     <Field
                        label='Tax Agent'
                        name={`contracts[0].TaxAgent`}
                        value={values.contracts[0].TaxAgent}    
                        component={Checkbox}
                        checked={e.TaxAgent}  
                        onChange={() => {
                          setFieldValue(`contracts[0].TaxAgent`, !values.contracts[0].TaxAgent);
                        }}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                     />
        
                  </Grid.Column>
                  <Grid.Column>
                  <Field
                      label='Mandat Sivep'
                      name={`contracts[0].MandatSivep`}
                      value={values.contracts[0].MandatSivep}      
                      component={Checkbox}
                      checked={e.MandatSivep} 
                      onChange={() => {
                        setFieldValue(`contracts[0].MandatSivep`, !values.contracts[0].MandatSivep);
                      }}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                  />     
                </Grid.Column>
                <Grid.Column>
                  <Field
                      label='Origin Pref.'
                      name={`contracts[0].OriginPref`}
                      value={values.contracts[0].OriginPref}      
                      component={Checkbox}
                      checked={e.OriginPref} 
                      onChange={() => {
                        setFieldValue(`contracts[0].OriginPref`, !values.contracts[0].OriginPref);
                      }}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                  />     
                </Grid.Column>
                  </> 
                    )})}
                </Grid>
                </Grid.Column>
              </Wrapper>
            
              <Spacer height='20px' />
              <Button
                disabled={addContactsRightsLoading}
                {...(addContactsRightsLoading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Spacer height='20px' />
            </Form>
          )}
        </Formik>
        </Grid.Column>
        </Grid>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    companiesNotInContact: selectors.contact.companiesNotInContactSelector(
      state
    ),
    errorHandlerContact: selectors.contact.errorSelector(state),
    loadingHandlerContact: selectors.contact.loadingSelector(state),
    getContactsRights: selectors.contact.getContactRightsSelector(state),
    getContactsRightsLoading: selectors.contact.getContactRightsLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
