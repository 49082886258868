import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const createSettingErrorSelector = state => 
  state.companyCollectionBoxSettings.errors.createSetting;
const getCompanySettingErrorSelector = state => 
  state.companyCollectionBoxSettings.errors.getCompanySetting;
const deleteSettingErrorSelector = state => 
  state.companyCollectionBoxSettings.errors.deleteSetting;
const updateSettingErrorSelector = state => 
  state.companyCollectionBoxSettings.errors.updateSetting;
const updateCountriesErrorSelector = state => 
  state.companyCollectionBoxSettings.errors.createCountries;

const errorsSelector = createErrorsSelector([
  createSettingErrorSelector,
  getCompanySettingErrorSelector,
  deleteSettingErrorSelector,
  updateSettingErrorSelector,
  updateCountriesErrorSelector
]);

// Loading
const createSettingLoadingSelector = state => 
  state.companyCollectionBoxSettings.loading.createSetting;
const getCompanySettingsLoadingSelector = state => 
  state.companyCollectionBoxSettings.loading.getCompanySetting;
const deleteSettingLoadingSelector = state => 
  state.companyCollectionBoxSettings.loading.deleteSetting;
const updateSettingLoadingSelector = state => 
  state.companyCollectionBoxSettings.loading.updateSetting;
const getCountriesLoadingSelector = state => 
  state.companyCollectionBoxSettings.loading.countries;
const updateCountriesLoadingSelector = state => 
  state.companyCollectionBoxSettings.loading.createCountries;

const loadingSelector = createLoadingSelector([
  createSettingLoadingSelector,
  getCompanySettingsLoadingSelector,
  deleteSettingLoadingSelector,
  updateSettingLoadingSelector,
  updateCountriesLoadingSelector,
]);

// Success
const createSettingSuccessSelector = state => 
  state.companyCollectionBoxSettings.success.createSetting;
const getCompanySettingSuccessSelector = state => 
  state.companyCollectionBoxSettings.success.getCompanySetting;
const deleteSettingSuccessSelector = state => 
  state.companyCollectionBoxSettings.success.deleteSetting;
const updateSettingSuccessSelector = state => 
  state.companyCollectionBoxSettings.success.updateSetting;
const getCountriesSuccessSelector = state => 
  state.companyCollectionBoxSettings.success.countries;
const updateCountriesSuccessSelector = state => 
  state.companyCollectionBoxSettings.success.createCountries;

const successSelector = createSuccessSelector([
  createSettingSuccessSelector,
  getCompanySettingSuccessSelector,
  deleteSettingSuccessSelector,
  updateSettingSuccessSelector,
  updateCountriesSuccessSelector
]);

// Settings
const companyCollectionBoxSettingsSelector = state => state.companyCollectionBoxSettings.getCompanySetting;
const errorSelector = state => state.companyCollectionBoxSettings.error;
const getCountriesSelector = state => state.companyCollectionBoxSettings.countries;
const updateCountriesSelector = state => state.companyCollectionBoxSettings.success.createCountries;


export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  companyCollectionBoxSettingsSelector,
  getCompanySettingsLoadingSelector,
  getCountriesLoadingSelector,
  getCountriesSelector,
  updateCountriesSelector,
  errorSelector
};
