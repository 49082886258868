import actionTypes from 'store/fiscal/declaration/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.GET_COMPANY_SUBSCRIBED:
        try {
          next({ type: actionTypes.GET_COMPANY_SUBSCRIBED_LOADING });
          const response = await request.getCompanySubscribed();
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DECLARE:
        try {
          next({ type: actionTypes.DECLARE_LOADING });
          const response = await request.declare({
            ...action.payload,
          });
          next({
            type: actionTypes.DECLARE_SUCCESS,
            payload: response.data.data,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.DECLARE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GETDECLARATIONS:
        try {
          next({ type: actionTypes.GETDECLARATIONS_LOADING });
          const response = await request.getDeclarations({
            ...action.payload,
          });
          next({
            type: actionTypes.GETDECLARATIONS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GETDECLARATIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.SUBSCRIBE:
        try {
          next({ type: actionTypes.SUBSCRIBE_LOADING });
          await request.subscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.SUBSCRIBE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UNSUBSCRIBE:
        try {
          next({ type: actionTypes.UNSUBSCRIBE_LOADING });
          await request.unsubscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.UNSUBSCRIBE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UNSUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GETMAPPING:
        try {
          next({ type: actionTypes.GETMAPPING_LOADING });
          const response = await request.getMapping({
            ...action.payload,
          });
          next({
            type: actionTypes.GETMAPPING_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GETMAPPING_ERROR,
            error,
          });
        }
        break;
      case actionTypes.GETTA:
        try {
          next({ type: actionTypes.GETTA_LOADING });
          const response = await request.getTA({
            ...action.payload,
          });
          next({
            type: actionTypes.GETTA_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GETTA_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GETDECLARATION:
        try {
          next({ type: actionTypes.GETDECLARATION_LOADING });
          const response = await request.getDeclaration({
            ...action.payload,
          });
          next({
            type: actionTypes.GETDECLARATION_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GETDECLARATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GETLINES:
        try {
          next({ type: actionTypes.GETLINES_LOADING });
          const response = await request.getLines({
            ...action.payload,
          });
          next({
            type: actionTypes.GETLINES_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GETLINES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.VALIDATE:
        try {
          next({ type: actionTypes.VALIDATE_LOADING });
          await request.validate({ ...action.payload });
          next({
            type: actionTypes.VALIDATE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.VALIDATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.REMOVE:
        try {
          next({ type: actionTypes.REMOVE_LOADING });
          await request.remove({ ...action.payload });
          next({
            type: actionTypes.REMOVE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.REMOVE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
