import styled from '@emotion/styled';

export const MainWrapper = styled.div(
  ({ width, height }: { width?: string; height?: string }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    width,
    height,
    alignItems: 'center',
    flex: '1',
  })
);

export const Title = styled.p(() => ({
  fontSize: '2em',
  color: 'black',
  fontWeight: 'bold',
  paddingTop: '10px',
  margin: '0',
}));

export const LineSeparator = styled.div(() => ({
  border: '1px solid rgba(34,36,38,.15)',
  height: '1px',
  width: '90%',
  marginTop: '15px',
  marginBottom: '15px',
}));

export const UserName = styled.p(() => ({
  color: 'black',
  fontWeight: 'bold',
  margin: '0',
}));

export const MessageDate = styled.p(() => ({
  color: 'rgba(0, 0, 0, 0.5)',
  fontWeight: 'lighter',
  fontSize: '12px',
  lineHeight: '20px',
  paddingTop: '1px',
  margin: '0',
}));

export const UserMessage = styled.p(() => ({
  color: 'black',
  marginLeft: '0',
  marginTop: '0.5em',
  marginBottom: '1.2em',
  whiteSpace: 'pre-line'
}));

export const MessagesWrapper = styled.div(() => ({
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
  '&::-webkit-scrollbar-track': {
    borderRadius: '10px',
  },
}));

export const MessageWrapper = styled.div((props: { new: boolean }) => ({
  padding: '1.1em',
  margin: '0 10px 10px 10px',
  borderRadius: '10px',
  position: 'relative',
  width: 'calc(100% - 20px)',
  float: 'left',
  ...(!props.new && { backgroundColor: '#2183dd26' }),
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,.05)',
  },
}));

export const IconMessage = styled.div(() => ({
  position: 'absolute',
  bottom: '10px',
  width: '20px',
  height: '20px',
  right: '10px',
  zIndex: 100,
  color: 'green',
}));

export const NameDateWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent:  'space-between',
}));

export const MessageSendingWrapper = styled.div(
  (props: { active: string | null }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: props.active && 'rgba(0,0,0,.05)',
    marginBottom: '10px',
  })
);

export const MessageSender = styled.div(() => ({
  display: 'flex',
  flex: '1',
  justifyContent: 'flex-end',
  padding: '15px',
  width: '100%',
  flexDirection: 'column',
}));

export const OpenTag = styled.div(() => ({
  position: 'relative',
  float: 'left',
  width: '100%',
  cursor: 'pointer',
  backgroundColor: '#f9fafb',
  padding: '5px',
  borderRadius: '5px',
}))

export const Open = styled.div(() => ({
  position: 'relative',
  float: 'left',
  width: '100%',
}))

export const Status = styled.div(props => ({
  lineHeight: '46px',
  float: 'left',
  color: `${props.color}`
}))

export const Bullet = styled.div(props => ({
  height: '15px',
  width: '15px',
  borderRadius: '8px',
  float: 'right',
  background: `${props.color}`
}))
