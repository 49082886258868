export default {
  GET_ALL_OPERATOR: 'MY_ASD.OPERATOR.GET_ALL_OPERATOR',
  GET_ALL_OPERATOR_LOADING: 'MY_ASD.OPERATOR.GET_ALL_OPERATOR_LOADING',
  GET_ALL_OPERATOR_SUCCESS: 'MY_ASD.OPERATOR.GET_ALL_OPERATOR_SUCCESS',
  GET_ALL_OPERATOR_ERROR: 'MY_ASD.OPERATOR.GET_ALL_OPERATOR_ERROR',

  GET_ONE_OPERATOR: 'MY_ASD.OPERATOR.GET_ONE_OPERATOR',
  GET_ONE_OPERATOR_LOADING: 'MY_ASD.OPERATOR.GET_ONE_OPERATOR_LOADING',
  GET_ONE_OPERATOR_SUCCESS: 'MY_ASD.OPERATOR.GET_ONE_OPERATOR_SUCCESS',
  GET_ONE_OPERATOR_ERROR: 'MY_ASD.OPERATOR.GET_ONE_OPERATOR_ERROR',

  
  GET_ONE_OPERATOR_ROLE: 'MY_ASD.OPERATOR.GET_ONE_OPERATOR_ROLE',
  GET_ONE_OPERATOR_ROLE_LOADING: 'MY_ASD.OPERATOR.GET_ONE_OPERATOR_ROLE_LOADING',
  GET_ONE_OPERATOR_ROLE_SUCCESS: 'MY_ASD.OPERATOR.GET_ONE_OPERATOR_ROLE_SUCCESS',
  GET_ONE_OPERATOR_ROLE_ERROR: 'MY_ASD.OPERATOR.GET_ONE_OPERATOR_ROLE_ERROR',

  UPDATE_RIGHT_OPERATOR: 'MY_ASD.OPERATOR.UPDATE_RIGHT_OPERATOR',
  UPDATE_RIGHT_OPERATOR_LOADING: 'MY_ASD.OPERATOR.UPDATE_RIGHT_OPERATOR_LOADING',
  UPDATE_RIGHT_OPERATOR_SUCCESS: 'MY_ASD.OPERATOR.UPDATE_RIGHT_OPERATOR_SUCCESS',
  UPDATE_RIGHT_OPERATOR_ERROR: 'MY_ASD.OPERATOR.UPDATE_RIGHT_OPERATOR_ERROR',

  RESET: 'MY_ASD.OPERATOR.RESET',
}