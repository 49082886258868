import styled from '@emotion/styled';
import { Icon, Button } from 'semantic-ui-react';
import { keyframes } from '@emotion/core';

export const Segments = styled.div({
  borderTop: 'solid 2px #e9e9e9',
});

export const NotificationContentLeft = styled.div(
  ({ open, scrollFixed }: any) => ({
    width: '350px',
    position: scrollFixed ? 'fixed' : 'absolute',
    right: 0,
    margin: !open ? '0 -400px 0 0' : '0',
    top: scrollFixed ? '0' : '73px',
    backgroundColor: '#f8f8f8',
    height: '100%',
    color: 'black',
    OObjectPosition: 'fixed',
    transition: 'margin .5s linear',
    boxShadow: '0 0px 6px -2px black',
    zIndex: 99,
  })
);

export const Header = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 1rem;
`;

export const Title = styled.p(() => ({
  '&&&': {
    fontWeight: 'bold',
    fontSize: '1.8em',
    margin: 'auto',
  },
}));

export const AllContentNotification = styled.div(({ scrollFixed }: any) => ({
  overflowY: 'auto',
  maxHeight: '95%',
  paddingBottom: scrollFixed ? '60px' : '130px',
}));

const removeTransition = keyframes({
  from: {
    height: '100px',
  },
  to: {
    height: 0,
    padding: 0,
  },
});

export const ContentNotification = styled.div(
  ({ isRead, remove }: { isRead: boolean; remove: boolean }) => ({
    display: 'flex',
    padding: '.5rem .3rem .5rem 1rem',
    ...(!isRead && {
      backgroundColor: '#0a326d17',
      transition: 'transition: background-color 0.2s ease-out',
    }),
    ...(remove && {
      transition: 'opacity 0.2s ease-out',
      opacity: 0,
      animation: `0.9s ${removeTransition}`,
    }),
    '&&&:hover': {
      backgroundColor: isRead ? '#0000000d' : '#0a326d30',
    },
  })
);

export const Content = styled.a`
  &&& {
    margin: 0.8rem 0.2rem 1rem 0;
    color: black;
    width: 90%;
  }
`;

export const ContentLeftParagraph = styled.p({
  textAlign: 'left',
  fontSize: '.95em',
  marginRight: '.3rem',
});

export const NotificationDeleteIcon = styled(Icon)`
  &&& {
    font-size: 1.7em;
    color: red;
    margin: auto !important;
  }
`;

export const NotificationCloseIcon = styled(Icon)`
  &&& {
    font-size: 1.5em;
    color: black;
    margin: auto !important;
  }
`;

export const IconView = styled(Icon)`
  &&& {
    color: #0a326d;
    font-size: 1.7em;
    margin: auto !important;
  }
  &&&:hover {
    color: #0a326de0;
  }
`;

export const CloseButton = styled(Button)`
  &&& {
    background-color: transparent;
    padding: 0;
    margin: 1rem 0.5rem;
    text-align: center;
  }
`;

export const ButtonAllRead = styled.button`
  &&& {
    color: white;
    border-radius: 5px;
    margin: auto;
    cursor: pointer;
    padding: 0.4rem;
    border: none;
    background-color: #0a326d;
    font-size: 0.85rem;
  }
  &&&:hover {
    background-color: #0a326de0;
  }
`;

export const ButtonAllDelete = styled.button`
  &&& {
    color: white;
    border-radius: 5px;
    margin: auto;
    cursor: pointer;
    padding: 0.4rem;
    border: none;
    background-color: red;
    font-size: 0.85rem;
  }
`;

export const IconContent = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    border-left: solid 2px #e9e9e9;
    width: 18%;
  }
`;

export const ContentNotificationEmpty = styled.div({
  padding: '.5rem .3rem .5rem 1rem',
  display: 'block',
  height: '80%',
});

export const IconContentEmpty = styled(Icon)`
  position: absolute;
  font-size: 10em !important;
  color: #0a1b6d0a;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ParagraphEmpty = styled.p({
  textAlign: 'center',
  marginTop: '2rem',
});

export const FormatDateNotification = styled.p({
  fontSize: '.7em',
  color: 'grey',
  textAlign: 'right',
  paddingRight: '.5rem',
});
