import React from 'react';
import { Menu, Icon, Image } from 'semantic-ui-react';
import NavLinkImg from 'components/NavLinkImg';
import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';

import images from 'assets/images';

import NotificationBell from 'components/Layouts/Notification/NotificationBell';
import {
  MenuStyled,
  MenuItemWrapper,
  DropdownItemStyled,
  DropdownMenuStyled,
  DropdownStyled,
} from './styles';

const Index = ({
  dispatch,
  userCompanies,
  userCompaniesSelected,
  user,
  resetError,
  openSidebar,
}: any) => {
  return (
    <MenuStyled fluid pointing secondary size='massive'>
      <Menu.Item>
        <NavLinkImg to='/'>
          <Image width='60px' src={images.logo_asd_white} />
        </NavLinkImg>
      </Menu.Item>
      <Menu.Item onClick={openSidebar}>
        <Icon size='large' name='sidebar' inverted />
      </Menu.Item>
      <Menu.Menu position='right'>
        {user &&
          !user.admin &&
          !user.operator &&
          userCompanies &&
          userCompanies.length > 0 && (
            <MenuItemWrapper>
              <DropdownStyled
                item
                text={
                  (userCompaniesSelected &&
                    userCompanies.find((e) => e.id === userCompaniesSelected) &&
                    userCompanies.find((e) => e.id === userCompaniesSelected)
                      .name) ||
                  'No company selected'
                }
                onClick={() =>
                  dispatch(
                    actions.auth.getUserCompanies({
                      params: { userId: user.id },
                    })
                  )
                }
              >
                <DropdownMenuStyled>
                  <DropdownItemStyled
                    onClick={() => {
                      resetError();
                      dispatch({
                        type: 'MY_ASD.AUTH.CHANGE_COMPANY_SELECTED_SUCCESS',
                        payload: {
                          userCompaniesSelected: null,
                          userPerms: null,
                        },
                      });
                    }}
                  >
                    No company selected
                  </DropdownItemStyled>
                  {userCompanies.map((company, i) => {
                    return (
                      <DropdownItemStyled
                        key={i}
                        onClick={() => {
                          resetError();
                          dispatch(
                            actions.auth.changeCompanySelected({
                              params: {
                                companyId: company.id,
                                userId: user.id,
                              },
                            })
                          );
                        }}
                      >
                        {company.name}
                      </DropdownItemStyled>
                    );
                  })}
                </DropdownMenuStyled>
              </DropdownStyled>
            </MenuItemWrapper>
          )}
        <MenuItemWrapper margin='auto'>
          <NotificationBell />
        </MenuItemWrapper>
      </Menu.Menu>
    </MenuStyled>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    userCompanies: selectors.auth.userCompanies(state),
    userCompaniesSelected: selectors.auth.companySelected(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(Index);
