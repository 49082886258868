import actionTypes from 'store/status/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.GET_STATUS:
        try {
          next({ type: actionTypes.GET_STATUS_LOADING });
          const response = await request.getAllStatus();
          next({
            type: actionTypes.GET_STATUS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_STATUS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
        

      default:
        next(action);
        break;
    }
  };
}
