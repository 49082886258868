import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const settingApi = routesApi.company.collectionboxSetting;
  return {
    handleSettings(data: dataReqI) {
      return api.request({
        method: settingApi.createSetting.method,
        route: settingApi.createSetting.path,
        params: data.params,
        data: data.data
      });
    },
    getCompanySetting(data: dataReqI) {
      return api.request({
        method: settingApi.getCompanySetting.method,
        route: settingApi.getCompanySetting.path,
        params: data.params
      });
    },
    getAssignedCountries(data: dataReqI) {
      return api.request({
        method: settingApi.getAssignedCountries.method,
        route: settingApi.getAssignedCountries.path,
        params: data.params
      });
    },
    updateAssignedCountries(data: dataReqI) {
      return api.request({
        method: settingApi.updateAssignCountry.method,
        route: settingApi.updateAssignCountry.path,
        params: data.params,
        data: data.data
      });
    },
    updateSetting(data: dataReqI) {
      return api.request({
        method: settingApi.updateSetting.method,
        route: settingApi.updateSetting.path,
        params: data.params,
        data: data.data
      });
    },
    deleteSetting(data: dataReqI) {
      return api.request({
        method: settingApi.deleteSetting.method,
        route: settingApi.deleteSetting.path,
        params: data.params
      });
    },
  };
};
