// Dependencies
import React from 'react';

// Components
import Navbar from '../navbar/navbar';

// Resources
import ASDSPWLogo from '../../../../assets/images/logoSPWBlanc.png';

// Stylesheets
import './header.css';

/**
 * @name header.tsx
 *
 * @path /components/header/header.tsx
 *
 * @description Defines header's content
 */
const Header: React.FC = () => {
  // Defines the action that must be executed when user performed a click on ASD_SPW Logo
  const handleClick = () => {
    window.location.href = `/spw`;
  };

  return (
    <div className='headerContainer'>
      <img
        src={ASDSPWLogo}
        alt='ASD SPW White Logo'
        className='asdSPWLogo'
        onClick={handleClick}
      />
      <Navbar />
    </div>
  );
};

export default Header;
