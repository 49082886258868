import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Container,
  Button as Btn,
  Divider,
  Dimmer,
  Loader,
  Table,
  Popup,
  Icon,
} from 'semantic-ui-react';

import Checkbox from 'components/Checkbox';
import { Details, Informations } from 'components/DeclarationDetails';
import ButtonValidationModal from 'components/ButtonValidationModal';
import { Main } from 'components/Layouts';
import { actions, selectors } from 'store';

const Index = ({ dispatch, match, calculation, history, error }) => {
  const [info, setInfo] = useState({
    companyName: '',
    activity: '',
    tier: '',
    country: '',
    workshop: '',
    created_at: '',
    emitter: '',
    state: '',
    duplicates: [],
    statement: '',
  });
  const [statement, setStatement] = useState(null);
  const [note, setNote] = useState(false);

  useEffect(() => {
    dispatch(
      actions.fiscalDeclaration.getDeclaration({
        params: {
          companyName: match.params.companyName,
          declarationId: match.params.declarationId,
        },
      })
    );

    return () => {
      dispatch(actions.fiscalDeclaration.reset(['info', 'error']));
    };
  }, [dispatch, match]);

  useEffect(() => {
    if (calculation !== null){
      setInfo({
        companyName: calculation.client,
        activity: calculation.activity,
        tier: calculation.tier,
        country: calculation.declaration_country,
        workshop: calculation.workshop,
        created_at: calculation.created_at,
        emitter: calculation.emitter,
        state: calculation.state,
        duplicates: calculation.duplicates,
        statement: calculation.statement
      });
      calculation && setStatement(calculation.statement)
    }
     
  }, [calculation]);


  const validate = () => {
    dispatch(
      actions.fiscalDeclaration.validate({
        params: {
          companyName: match.params.companyName,
          declarationId: match.params.declarationId,
        },
        data: {
          note,
        },
      })
    );
    history.push('/fiscal/declaration');
  };

  const remove = () => {
    dispatch(
      actions.fiscalDeclaration.remove({
        params: {
          companyName: match.params.companyName,
          declarationId: match.params.declarationId,
        },
      })
    );
    history.push('/fiscal/declaration');
  };

  return (
    <Main>
      <Divider hidden />
      {(info === null && (
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      )) || (
        <Container>
          {!error && (
            <>
              <Informations info={info} />
              <Details
                statement={statement}
                error={statement && statement.error}
              />
              {(info.state === 'Validated' ||
                info.state === 'Not validated') && (
                <>
                  <Divider hidden />
                  <Table
                    color={
                      info.duplicates.length === 0
                        ? 'green'
                        : 'yellow'
                    }
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          Duplicate invoices{' '}
                          {typeof info.duplicates !==
                            'string' &&
                            `(${info.duplicates.length})`}{' '}
                          <Popup
                            trigger={<Icon name='info circle' />}
                            content={
                              'these invoices have not been integrated into the intranet'
                            }
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {(typeof info.duplicates === 'string' && (
                        <Table.Row>
                          <Table.Cell>
                            {info.duplicates}
                          </Table.Cell>
                        </Table.Row>
                      )) || info.duplicates.map(                                
                          (d, index) => (
                            ((typeof d === "object") && 
                            (
                            <Table.Row key={index}>
                              <Table.Cell>{d.n}</Table.Cell>
                              <Table.Cell>{d.p}</Table.Cell>
                            </Table.Row>
                            )) || 
                            (
                            <Table.Row key={index}>
                              <Table.Cell>{d}</Table.Cell>
                            </Table.Row>
                            )
                          )   
                        )}
                    </Table.Body>
                  </Table>
                </>
              )}
            </>
          )}
          {!error && (
            <Btn.Group floated='right'>
              {info.state !== 'Validated' && (
                <ButtonValidationModal
                  trgrContent='Delete'
                  headerContent='Delete'
                  modalContent='Do you confirm the deletion of this declaration?'
                  trgrColor='red'
                  trgrIcon='trash'
                  headerIcon='trash'
                  onClick={remove}
                  margin='5px'
                />
              )}
              {info.state === 'Finished' && (
                <ButtonValidationModal
                  trgrContent='Validate'
                  headerContent='Validate'
                  modalContent={
                    <div>
                      <p>
                        Be careful, this action will integrate the declaration
                        into the intranet. Do you want to validate it?
                      </p>
                      <p>Do you want to integrate the notes ?</p>
                      <Checkbox
                        name='note'
                        label='Add notes'
                        checked={note}
                        onChange={() => setNote(!note)}
                      />
                    </div>
                  }
                  trgrColor='green'
                  trgrIcon='checkmark'
                  headerIcon='checkmark'
                  onClick={validate}
                  margin='5px'
                />
              )}
            </Btn.Group>
          )}
        </Container>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    calculation: selectors.fiscalDeclaration.declarationSelector(
      state
    ),
    error: selectors.message.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);


