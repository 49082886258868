import { resetState } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    getAllCountries: null,
    getEuCountries: null,
    getCountryVatRate: null,
  },
  errors: {
    getAllCountries: null,
    getEuCountries: null,
    getCountryVatRate: null,
  },
  loading: {
    getAllCountries: false,
    getEuCountries: false,
    getCountryVatRate: false,
  },
  allCountries: null,
  euCountries: null,
  countryVatRate: null,
  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // GetAllCountries
    case actionTypes.GETALLCOUNTRIES_LOADING:
      return produce(state, draft => {
        draft.loading.getAllCountries = true;
      });

    case actionTypes.GETALLCOUNTRIES_SUCCESS:
      return produce(state, draft => {
        draft.errors.getAllCountries = null;
        draft.loading.getAllCountries = false;
        draft.success.getAllCountries = true;
        draft.allCountries = action.payload;
      });

    case actionTypes.GETALLCOUNTRIES_ERROR:
      return produce(state, draft => {
        draft.errors.getAllCountries = true;
        draft.loading.getAllCountries = false;
        draft.success.getAllCountries = false;
        !draft.error.includes("allCountries") && draft.error.push("allCountries");
      });

    // GetEuCountries
    case actionTypes.GETEUCOUNTRIES_LOADING:
      return produce(state, draft => {
        draft.loading.getEuCountries = true;
      });

    case actionTypes.GETEUCOUNTRIES_SUCCESS:
      return produce(state, draft => {
        draft.errors.getEuCountries = null;
        draft.loading.getEuCountries = false;
        draft.success.getEuCountries = true;
        draft.euCountries = action.payload;
      });

    case actionTypes.GETEUCOUNTRIES_ERROR:
      return produce(state, draft => {
        draft.errors.getEuCountries = true;
        draft.loading.getEuCountries = false;
        draft.success.getEuCountries = false;
        !draft.error.includes("euCountries") && draft.error.push("euCountries");
      });

    // GetCountryVateRate
    case actionTypes.GET_COUNTRY_VAT_RATE_LOADING:
      return produce(state, draft => {
        draft.loading.getCountryVatRate = true;
      });

    case actionTypes.GET_COUNTRY_VAT_RATE_SUCCESS:
      return produce(state, draft => {
        draft.errors.getCountryVatRate = null;
        draft.loading.getCountryVatRate = false;
        draft.success.getCountryVatRate = true;
        draft.countryVatRate = action.payload;
      });

    case actionTypes.GET_COUNTRY_VAT_RATE_ERROR:
      return produce(state, draft => {
        draft.errors.getCountryVatRate = true;
        draft.loading.getCountryVatRate = false;
        draft.success.getCountryVatRate = false;
        !draft.error.includes("countryVatRate") && draft.error.push("countryVatRate");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
