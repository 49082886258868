// Dependencies
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Content from '../components/content/content';
import EmployeeDetails from '../components/employees/employeeDetails';

// Stylesheets
import '../index.css';

interface EmployeeDetailsProperties {
  employeeId: number;
}

/**
 * @name employeesDetailsPage.tsx
 *
 * @path /pages/spwEmployeeDetailsPage.tsx
 *
 * @description Defines employee details page's content
 */
const EmployeeDetailsPage: React.FC = ({ history }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  // Employee id to get all datas about it by requests
  const { employeeId } = useParams<EmployeeDetailsProperties>();

  return (
    <div className='pages'>
      <Helmet>
        <title>
          {t('headerStrings.companyButtonStrings.employees')}
          {t('helmetString')}
        </title>
      </Helmet>
      <Header />
      <Content
        title={t('employeesDetailsPageStrings.title')}
        homepage={false}
        history={window.location.href.split('/').slice(0, -2).join('/')}
      />
      <EmployeeDetails id={employeeId} />
      <Footer />
    </div>
  );
};

export default EmployeeDetailsPage;
