import React from 'react';

import {
  GridContainer,
  Title,
  OfferType1Offset,
  Blank,
  OfferType2Offset,
  OfferType2,
  OfferType1,
  Gaps,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature5,
  Feature6,
  Feature7,
  Feature8,
  Feature9,
  Feature10,
  Asterisk,
  F1o1,
  F1o2,
  F201,
  F202,
  F301,
  F302,
  F401,
  F402,
  F501,
  F502,
  F601,
  F602,
  F701,
  F702,
  F801,
  F802,
  F901,
  F902,
  F1001,
  F1002,
} from './styles';
import { Icon } from 'semantic-ui-react';
import TextCustom from 'components/core/TextCustom/TextCustom';
import Wrapper from 'components/core/Wrapper/Wrapper';

const IconCheck = () => {
  return <Icon name='check' color='green' size='big' />;
};

const FeatureDescription = (children) => {
  return (
    <Wrapper padding='1rem 10rem 1rem 0' textAlign='justify !important'>
      {children}
    </Wrapper>
  );
};

const FeatureIncluded = (children) => {
  return (
    <Wrapper padding='1rem 2rem' textAlign='center'>
      <TextCustom fontWeight='bold' fontSize='larger' color='white'>
        {children}
      </TextCustom>
    </Wrapper>
  );
};

const createGaps = () => {
  let gaps = [];
  for (let i = 1; i < 19; i++) {
    gaps.push(<Gaps key={i} gapName={i} />);
  }

  return gaps;
};

const Index = (props) => {
  return (
    <GridContainer>
      {createGaps()}
      <Title>
        <TextCustom fontSize='3rem' fontWeight='bold'>
          COMPARE OUR OFFERS
        </TextCustom>
      </Title>
      <OfferType1Offset backgroundColor='tableYellow'>
        <Wrapper padding='2rem' textAlign='center'>
          <TextCustom
            color='white'
            fontSize='x-large'
            fontWeight='bold'
            margin='0 0 0 0'
          >
            Annual
          </TextCustom>
        </Wrapper>
      </OfferType1Offset>
      <Blank backgroundColor='tableOrange' />
      <OfferType1 backgroundColor='tableYellow'>
        <Wrapper textAlign='center'>
          <TextCustom color='white' fontWeight='bold' fontSize='larger'>
            €99 exc VAT / year <div>(€8.25 / month)</div>
          </TextCustom>
        </Wrapper>
      </OfferType1>
      <OfferType2Offset backgroundColor='tableOrange'>
        <Wrapper padding='2rem' textAlign='center'>
          <TextCustom
            color='white'
            fontSize='x-large'
            fontWeight='bold'
            margin='0 0 0 0'
          >
            Monthly
          </TextCustom>
        </Wrapper>
      </OfferType2Offset>
      <OfferType2 backgroundColor='tableOrange'>
        <Wrapper textAlign='center'>
          <TextCustom color='white' fontWeight='bold' fontSize='larger'>
            €9.99 exc VAT / month <div>(€119.88 / year)</div>
          </TextCustom>
        </Wrapper>
      </OfferType2>

      <Feature1>
        {FeatureDescription(
          `Access to your personal space “MY ASD / QUICKPROOF allowing you to manage your subscription, see the overview of your invoices and your payments, … `
        )}
      </Feature1>

      <Feature2>
        {FeatureDescription(`Access to a user guide and online help `)}
      </Feature2>

      <Feature3>
        {FeatureDescription(
          `Creation and operational management of intra-community deliveries (dates, identification of the different parties, input of the delivery amount, upload of the required proofs of transport (Type 1, 2 and quantity required))`
        )}
      </Feature3>

      <Feature4>
        {FeatureDescription(
          `Additional intra-community deliveries package beyond the 20 included in the basic package`
        )}
      </Feature4>

      <Feature5>
        {FeatureDescription(
          `Upload and validation of proofs of transport via single pages with no need to connect to the MY ASD - QUICKPROOF platform (document upload, download of an attestation, selection of the transporter, …) `
        )}
      </Feature5>

      <Feature6>
        {FeatureDescription(
          `Counters of uploaded evidence, deliveries received and estimation of the VAT risk `
        )}
      </Feature6>

      <Feature7>
        {FeatureDescription(`Calculation of the VAT risk / month `)}
      </Feature7>

      <Feature8>
        {FeatureDescription(
          `Search function according to invoice number, delivery note number, … `
        )}
      </Feature8>

      {/* <Feature9>
        {FeatureDescription(
          `Mass integration function (suppliers, customers and transporters listings, …) – according to a template file provided in your personal space* `
        )}
      </Feature9> */}

      <Feature10>
        {FeatureDescription(
          `Payment of the fees (1st payment at the opening of the service) `
        )}
      </Feature10>

      <Asterisk>
        <TextCustom fontSize='12px'>
          *In case of uncomplete excel file, reprocessing by ASD will be
          invoiced €135 / hour
        </TextCustom>
      </Asterisk>

      <F1o1 backgroundColor='tableYellow'>{FeatureIncluded(IconCheck())}</F1o1>
      <F1o2 backgroundColor='tableOrange'>{FeatureIncluded(IconCheck())}</F1o2>
      <F201 backgroundColor='tableYellow'>{FeatureIncluded(IconCheck())}</F201>
      <F202 backgroundColor='tableOrange'>{FeatureIncluded(IconCheck())}</F202>
      <F301 backgroundColor='tableYellow'>
        {FeatureIncluded(
          <>
            {IconCheck()}
            <div>20 deliveries included / month</div>
          </>
        )}
      </F301>
      <F302 backgroundColor='tableOrange'>
        {FeatureIncluded(
          <>
            {IconCheck()}
            <div>20 deliveries included / month</div>
          </>
        )}
      </F302>
      <F401 backgroundColor='tableYellow'>
        {FeatureIncluded('+ €0.10 / delivery')}
      </F401>
      <F402 backgroundColor='tableOrange'>
        {FeatureIncluded('+ €0.10 / delivery')}
      </F402>
      <F501 backgroundColor='tableYellow'>{FeatureIncluded(IconCheck())}</F501>
      <F502 backgroundColor='tableOrange'>{FeatureIncluded(IconCheck())}</F502>
      <F601 backgroundColor='tableYellow'>{FeatureIncluded(IconCheck())}</F601>
      <F602 backgroundColor='tableOrange'>{FeatureIncluded(IconCheck())}</F602>
      <F701 backgroundColor='tableYellow'>{FeatureIncluded(IconCheck())}</F701>
      <F702 backgroundColor='tableOrange'>{FeatureIncluded(IconCheck())}</F702>
      <F801 backgroundColor='tableYellow'>{FeatureIncluded(IconCheck())}</F801>
      <F802 backgroundColor='tableOrange'>{FeatureIncluded(IconCheck())}</F802>
      {/* <F901 backgroundColor="tableYellow">
        {FeatureIncluded("+ €15 / file")}
      </F901>
      <F902 backgroundColor="tableOrange">
        {FeatureIncluded("+ €15 / file")}
      </F902> */}
      <F1001 backgroundColor='tableYellow'>{FeatureIncluded('Annual')}</F1001>
      <F1002 backgroundColor='tableOrange'>{FeatureIncluded('Monthly')}</F1002>
    </GridContainer>
  );
};

export default Index;
