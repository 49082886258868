import actionTypes from "./actionTypes";
import { dataReqI } from 'interfaces';

export default {
  getAllCountries() {
    return {
      type: actionTypes.GETALLCOUNTRIES
    };
  },
  getEuCountries() {
    return {
      type: actionTypes.GETEUCOUNTRIES
    };
  }, 
  getCountryVatRate(data: dataReqI) {
    return {
      type: actionTypes.GET_COUNTRY_VAT_RATE,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys
      }
    };
  }
};
