import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Header, Divider } from 'semantic-ui-react';

import Button from 'components/Button';
import AskForProofs from 'components/QuickFixes/StartADelivery/DeliveryValidation/AskForProofs';
import ManageProofs from 'components/QuickFixes/DeliveryForm/DeliveriesTable/ManageProofs';
import ManageAttestation from 'components/QuickFixes/DeliveryForm/DeliveriesTable/ManageAttestation';
import EmptyTable from 'components/EmptyTable';
import { paths } from 'routes/fiscal/kyanite';
import Wrapper from 'components/core/Wrapper/Wrapper';
import PeriodAndCountryFilter from 'components/QuickFixes/DeliveryForm/PeriodAndCountryFilter';

const Index = ({
  history,
  column,
  direction,
  handleSort,
  data,
  companyId,
  dispatch,
  getAllBTSelector,
  user,
  applyFilters,
  resetFilters,
  totalPod,
  storedSearch
}: any) => {
  const userAdminOrOp = user.operator || user.admin;
  const [askForProofsModal, setAskForProofsModal] = useState(null);
  const [filterBar, setfilterBar] = useState(true);


  return (
    <Wrapper overflow='auto'>
      {askForProofsModal && (
        <AskForProofs
          isModalOpen={askForProofsModal !== null}
          deliveryId={askForProofsModal}
          companyId={companyId}
          dispatch={dispatch}
          handleClose={() => setAskForProofsModal(null)}
          setReceiverChoiceState
        />
      )}
      <Header size='huge'>All the deliveries</Header>
      <Divider hidden />
      <Wrapper display='flex' justifyContent='space-between'>
        <p>Number of deliveries: {totalPod}</p>
        <Button
          icon='filter'
          type='button'
          content='Filters'
          color='blue'
          onClick={() => {
            filterBar ? setfilterBar(false) : setfilterBar(true);
          }}
        />
      </Wrapper>
      <Wrapper display='flex' justifyContent='flex-end'>
        {!filterBar && (
          <PeriodAndCountryFilter
          isVisible={filterBar}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
          companyId={companyId}
          storedSearch={storedSearch}
          />
        )}
      </Wrapper>

      <Table color={'blue'} sortable selectable striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === 'ref' ? direction : null}
              onClick={() => handleSort('ref')}
            >
              Ref
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'createdAt' ? direction : null}
              onClick={() => handleSort('createdAt')}
            >
              Date
            </Table.HeaderCell>
            <Table.HeaderCell>42</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'Buyers.name' ? direction : null}
              onClick={() => handleSort('Buyers.name')}
            >
              Buyer
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'Transporters.name' ? direction : null}
              onClick={() => handleSort('Transporters.name')}
            >
              Transporter
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'departureCountry' ? direction : null}
              onClick={() => handleSort('departureCountry')}
            >
              Departure Country
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'arrivalCountry' ? direction : null}
              onClick={() => handleSort('arrivalCountry')}
            >
              Arrival Country
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'status' ? direction : null}
              onClick={() => handleSort('status')}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'documentStatus' ? direction : null}
              onClick={() => handleSort('documentStatus')}
            >
              Proofs
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'buyerReminderAt' ? direction : null}
              onClick={() => handleSort('buyerReminderAt')}
            >
              Buyer reminder
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'transporterReminderAt' ? direction : null}
              onClick={() => handleSort('transporterReminderAt')}
            >
              Transporter reminder
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {((data === null || data.length === 0) && (<EmptyTable colNumber={11} />)) ||
            data.map((e, i) => {
              return (
                <Table.Row key={i}>
                  <Link
                    to={
                      userAdminOrOp
                        ? paths.deliveriesManageOperator
                            .replace(':companyId', companyId)
                            .replace(':deliveryId', e.id)
                        : paths.deliveriesManage.replace(':deliveryId', e.id)
                    }
                    className='table-link'
                  >
                    <Table.Cell>{e.reference}</Table.Cell>
                    <Table.Cell>
                      {new Date(e.createdAt).toLocaleDateString('en', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Table.Cell>
                    <Table.Cell>{e.customsRegime ? 'Yes' : 'No'}</Table.Cell>
                    <Table.Cell>{e.Buyers.name}</Table.Cell>
                    <Table.Cell>
                      {(e.Transporters && e.Transporters.name) ||
                        'Unknown transport'}
                    </Table.Cell>
                    <Table.Cell>{e.departureCountry}</Table.Cell>
                    <Table.Cell>{e.arrivalCountry}</Table.Cell>
                    <ManageAttestation
                      data={e.status}
                      path={
                        userAdminOrOp
                          ? paths.deliveriesManageOperator
                              .replace(':companyId', companyId)
                              .replace(':deliveryId', e.id)
                          : paths.deliveriesManage.replace(':deliveryId', e.id)
                      }
                      history={history}
                    />
                    <ManageProofs
                      data={e.documentStatus}
                      proofsNbr={e.ProofsOfDeliveries}
                      path={
                        userAdminOrOp
                          ? paths.deliveriesManageOperator
                              .replace(':companyId', companyId)
                              .replace(':deliveryId', e.id)
                          : paths.deliveriesManage.replace(':deliveryId', e.id)
                      }
                      action={() => setAskForProofsModal(e.id)}
                      history={history}
                    />
                    <Table.Cell>
                      {(e.buyerReminderAt &&
                        new Date(e.buyerReminderAt).toLocaleDateString('en', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                        })) ||
                        'Never'}
                    </Table.Cell>
                    <Table.Cell>
                      {(e.transporterReminderAt &&
                        new Date(e.transporterReminderAt).toLocaleDateString(
                          'en',
                          {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                          }
                        )) ||
                        'Never'}
                    </Table.Cell>
                  </Link>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </Wrapper>
  );
};

export default Index;
