import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    getCompanySubscribed: null,
    getDeclarationsInvoice: null,
    getDeclarationsInvoiceByFilters: null,
    updateDeclarationsInvoice: null,
    declare: null,
    updateDeclaration: null,
    subscribe: null,
    unsubscribe: null,
    getAll: null,
    getAllHosted: null,
    getOneHosted: null,
    getOne: null,
    addDocuments: null,
    deleteDocument: null,
    downloadDocument: null,
    downloadDocuments: null,
    extractDocuments: null,
    getAllTraces: null,
    getChat: null,
    sendMessage: null,
    followDeclaration: null,
    unfollowDeclaration: null,
    getProcessingDeclarations: null,
    getProcessingDeclarationsByFilters: null,
    getAllCustomsOffice: null,
    updateStep: null,
    addStep: null,
    addPartnersComments: null,
    getPartnersComments: null,
    addPartner: null,
    cancelDeclaration: null,
    getAllCategoriesDocuments: null,
    addIntranetReference: null,
    getAllChed: null,
    getOneChed: null,
    addChed: null,
    removeChed: null,
    updateChedData: null,
    convertFile: null,
    reloadLogisticom: null,
    sendNoticeMail: null,
    computedDeclarations: null,
    baeExtract: null,
    getComputedCheds: null,
    addMandates: null,
    getMandates: null,
    downloadMandate: null,
    getParking: null,
    getConex: null,
    checkConex: null,
    getAllLines: null,
    addLines: null,
    updateLines: null,
    deleteLines: null,
    getOneByRef: null,
    getCategories: null,
    updateConsignment: null,
    addConsignment: null,
    deleteConsignment: null,
    accises: null,
    updateAccises: null,
    getAccises: null,
    getAllAccises: null,
    transit: null,
    updateTransit: null,
    transitRates: null,
    transitRisk: null,
    deb: null,
    chedFiles: null,
  },
  errors: {
    getCompanySubscribed: null,
    getDeclarationsInvoice: null,
    getDeclarationsInvoiceByFilters: null,
    updateDeclarationsInvoice: null,
    declare: null,
    updateDeclaration: null,
    subscribe: null,
    unsubscribe: null,
    getAll: null,
    getAllHosted: null,
    getOneHosted: null,
    getOne: null,
    downloadDocuments: null,
    extractDocuments: null,
    getAllTraces: null,
    addDocuments: null,
    deleteDocument: null,
    downloadDocument: null,
    getChat: null,
    sendMessage: null,
    followDeclaration: null,
    unfollowDeclaration: null,
    getProcessingDeclarations: null,
    getProcessingDeclarationsByFilters: null,
    getAllCustomsOffice: null,
    updateStep: null,
    addStep: null,
    cancelDeclaration: null,
    getAllCategoriesDocuments: null,
    addIntranetReference: null,
    getAllChed: null,
    getOneChed: null,
    addChed: null,
    removeChed: null,
    updateChedData: null,
    convertFile: null,
    reloadLogisticom: null,
    sendNoticeMail: null,
    computedDeclarations: null,
    baeExtract: null,
    getComputedCheds: null,
    addPartnersComments: null,
    getPartnersComments: null,
    addPartner: null,
    addMandates: null,
    getMandates: null,
    downloadMandate: null,
    getParking: null,
    getConex: null,
    checkConex: null,
    getAllLines: null,
    addLines: null,
    updateLines: null,
    deleteLines: null,
    getOneByRef: null,
    getCategories: null,
    updateConsignment: null,
    addConsignment: null,
    deleteConsignment: null,
    accises: null,
    updateAccises: null,
    getAccises: null,
    getAllAccises: null,
    transit: null,
    updateTransit: null,
    transitRates: null,
    transitRisk: null,
    deb: null,
    chedFiles: null,
  },
  loading: {
    getCompanySubscribed: false,
    getDeclarationsInvoice: false,
    getDeclarationsInvoiceByFilters: false,
    updateDeclarationsInvoice: false,
    declare: false,
    updateDeclaration: false,
    subscribe: false,
    unsubscribe: false,
    getAll: false,
    getAllHosted: false,
    getOneHosted: false,
    getOne: false,
    downloadDocuments: false,
    extractDocuments: false,
    getAllTraces: false,
    addDocuments: false,
    deleteDocument: false,
    downloadDocument: false,
    getChat: false,
    sendMessage: false,
    followDeclaration: false,
    unfollowDeclaration: false,
    getProcessingDeclarations: false,
    getProcessingDeclarationsByFilters: false,
    getAllCustomsOffice: false,
    updateStep: false,
    addStep: false,
    cancelDeclaration: false,
    getAllCategoriesDocuments: false,
    addIntranetReference: false,
    getAllChed: false,
    getOneChed: false,
    addChed: false,
    removeChed: false,
    updateChedData: false,
    convertFile: false,
    reloadLogisticom: false,
    sendNoticeMail: false,
    computedDeclarations: false,
    baeExtract: false,
    getComputedCheds: false,
    addPartnersComments: false,
    getPartnersComments: false,
    addPartner: false,
    addMandates: false,
    getMandates: false,
    downloadMandate: false,
    getParking: false,
    getConex: false,
    checkConex: false,
    getAllLines: false,
    addLines: false,
    updateLines: false,
    deleteLines: false,
    getOneByRef: false,
    getCategories: false,
    updateConsignment: false,
    addConsignment: false,
    deleteConsignment: false,
    accises: false,
    updateAccises: false,
    getAccises: false,
    getAllAccises: false,
    transit: false,
    updateTransit: false,
    transitRates: false,
    transitRisk: false,
    deb: false,
    chedFiles: false,
  },

  companySubscribed: null,
  processingDeclarations: null,
  processingDeclarationsByFilters: null,
  processingDeclarationsCount: null,
  customsOffices: null,
  declarations: null,
  declare: null,
  hostedDeclarations: null,
  declarationsCount: null,
  hostedDeclarationsCount: null,
  declaration: null,
  oneHosted: null,
  computedDeclarations: null,
  declarationChat: null,
  declarationsInvoice: null,
  declarationsInvoiceByFilters: null,
  declarationsInvoiceCount: null,
  categoriesDocuments: null,
  allChed: null,
  chedFile: null,
  baeExtract: null,
  computedCheds: null,
  addPartnersComments: null,
  getPartnersComments: null,
  addPartner: null,
  addMandates: null,
  getMandates: null,
  downloadMandate: null,
  getParking: null,
  getConex: null,
  checkConex: null,
  getAllLines: null,
  addLines: null,
  updateLines: null,
  deleteLines: null,
  getOneByRef: null,
  getCategories: null,
  updateConsignment: null,
  addConsignment: null,
  deleteConsignment: null,
  accises: null,
  updateAccises: null,
  getAccises: null,
  getAllAccises: null,
  transit: null,
  updateTransit: null,
  transitRates: null,
  transitRisk: null,
  deb: null,
  chedFiles: null,
  updateDeclaration: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

     // post deb
     case actionTypes.DOWNLOAD_CHEDS_LOADING:
      return produce(state, (draft) => {
        draft.loading.chedFiles = true;
      });
    case actionTypes.DOWNLOAD_CHEDS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.chedFiles = null;
        draft.loading.chedFiles = false;
        draft.success.chedFiles = true;
        draft.chedFiles = action.payload
      });
    case actionTypes.DOWNLOAD_CHEDS_ERROR:
      return produce(state, (draft) => {
        draft.errors.chedFiles = true;
        draft.loading.chedFiles = false;
        draft.success.chedFiles = false;
        !draft.error.includes('chedFiles') && 
        draft.error.push('chedFiles');
      });

     // post deb
    case actionTypes.CHECK_CONEX_LOADING:
      return produce(state, (draft) => {
        draft.loading.checkConex = true;
      });
    case actionTypes.CHECK_CONEX_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.checkConex = null;
        draft.loading.checkConex = false;
        draft.success.checkConex = true;
        draft.checkConex = action.payload
      });
    case actionTypes.CHECK_CONEX_ERROR:
      return produce(state, (draft) => {
        draft.errors.checkConex = true;
        draft.loading.checkConex = false;
        draft.success.checkConex = false;
        !draft.error.includes('checkConex') && 
        draft.error.push('checkConex');
      });
      


      // post deb
      case actionTypes.CREATE_DEB:
        return produce(state, (draft) => {
          draft.loading.deb = true;
        });
      case actionTypes.CREATE_DEB_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.deb = null;
          draft.loading.deb = false;
          draft.success.deb = true;
        });
      case actionTypes.CREATE_DEB_ERROR:
        return produce(state, (draft) => {
          draft.errors.deb = true;
          draft.loading.deb = false;
          draft.success.deb = false;
          !draft.error.includes('deb') && draft.error.push('deb');
        });
        
     // update accises
     case actionTypes.UPDATE_ACCISES:
      return produce(state, (draft) => {
        draft.loading.updateAccises = true;
      });
    case actionTypes.UPDATE_ACCISES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateAccises = null;
        draft.loading.updateAccises = false;
        draft.success.updateAccises = true;
      });
    case actionTypes.UPDATE_ACCISES_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateAccises = true;
        draft.loading.updateAccises = false;
        draft.success.updateAccises = false;
        !draft.error.includes('updateAccises') && draft.error.push('updateAccises');
      });


      // get transit risk
      case actionTypes.TRANSIT_RISK:
        return produce(state, (draft) => {
          draft.loading.transitRisk = true;
        });
      case actionTypes.TRANSIT_RISK_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.transitRisk = null;
          draft.loading.transitRisk = false;
          draft.success.transitRisk = true;
          draft.transitRisk = action.payload
        });
      case actionTypes.TRANSIT_RISK_ERROR:
        return produce(state, (draft) => {
          draft.errors.transitRisk = true;
          draft.loading.transitRisk = false;
          draft.success.transitRisk = false;
          !draft.error.includes('transitRisk') && draft.error.push('transitRisk');
        });

      // update transit
      case actionTypes.GET_RATES:
        return produce(state, (draft) => {
          draft.loading.transitRates = true;
        });
      case actionTypes.GET_RATES_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.transitRates = null;
          draft.loading.transitRates = false;
          draft.success.transitRates = true;
          draft.transitRates = action.payload
        });
      case actionTypes.GET_RATES_ERROR:
        return produce(state, (draft) => {
          draft.errors.transitRates = true;
          draft.loading.transitRates = false;
          draft.success.transitRates = false;
          !draft.error.includes('transitRates') && draft.error.push('transitRates');
        });

     // update transit
     case actionTypes.UPDATE_TRANSIT:
      return produce(state, (draft) => {
        draft.loading.updateTransit = true;
      });
    case actionTypes.UPDATE_TRANSIT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateTransit = null;
        draft.loading.updateTransit = false;
        draft.success.updateTransit = true;
      });
    case actionTypes.UPDATE_TRANSIT_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateTransit = true;
        draft.loading.updateTransit = false;
        draft.success.updateTransit = false;
        !draft.error.includes('updateTransit') && draft.error.push('updateTransit');
      });

     // create transit
     case actionTypes.CREATE_TRANSIT:
      return produce(state, (draft) => {
        draft.loading.transit = true;
      });
    case actionTypes.CREATE_TRANSIT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.transit = null;
        draft.loading.transit = false;
        draft.success.transit = true;
        draft.transit = action.payload;
      });
    case actionTypes.CREATE_TRANSIT_ERROR:
      return produce(state, (draft) => {
        draft.errors.transit = true;
        draft.loading.transit = false;
        draft.success.transit = false;
        !draft.error.includes('transit') && draft.error.push('transit');
      });

     // get all accises
     case actionTypes.GET_ALL_ACCISES:
      return produce(state, (draft) => {
        draft.loading.getAllAccises = true;
      });
    case actionTypes.GET_ALL_ACCISES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllAccises = null;
        draft.loading.getAllAccises = false;
        draft.success.getAllAccises = true;
        draft.getAllAccises = action.payload;
      });
    case actionTypes.GET_ALL_ACCISES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllAccises = true;
        draft.loading.getAllAccises = false;
        draft.success.getAllAccises = false;
        !draft.error.includes('getAllAccises') && draft.error.push('getAllAccises');
      });

     // get accises
     case actionTypes.GET_ACCISES:
      return produce(state, (draft) => {
        draft.loading.getAccises = true;
      });
    case actionTypes.GET_ACCISES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAccises = null;
        draft.loading.getAccises = false;
        draft.success.getAccises = true;
        draft.getAccises = action.payload;
      });
    case actionTypes.GET_ACCISES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAccises = true;
        draft.loading.getAccises = false;
        draft.success.getAccises = false;
        !draft.error.includes('getAccises') && draft.error.push('getAccises');
      });

     // create Accises
     case actionTypes.CREATE_ACCISES:
      return produce(state, (draft) => {
        draft.loading.accises = true;
      });
    case actionTypes.CREATE_ACCISES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.accises = null;
        draft.loading.accises = false;
        draft.success.accises = true;
        draft.accises = action.payload;
      });
    case actionTypes.CREATE_ACCISES_ERROR:
      return produce(state, (draft) => {
        draft.errors.accises = true;
        draft.loading.accises = false;
        draft.success.accises = false;
        !draft.error.includes('accises') && draft.error.push('accises');
      });

     // delete Consignment
     case actionTypes.DELETE_CONSIGNMENT_DATA:
      return produce(state, (draft) => {
        draft.loading.deleteConsignment = true;
      });
    case actionTypes.DELETE_CONSIGNMENT_DATA_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteConsignment = null;
        draft.loading.deleteConsignment = false;
        draft.success.deleteConsignment = true;
      });
    case actionTypes.DELETE_CONSIGNMENT_DATA_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteConsignment = true;
        draft.loading.deleteConsignment = false;
        draft.success.deleteConsignment = false;
        !draft.error.includes('deleteConsignment') && draft.error.push('deleteConsignment');
      });


    // update Consignment
    case actionTypes.UPDATE_CONSIGNMENT_DATA:
      return produce(state, (draft) => {
        draft.loading.updateConsignment = true;
      });
    case actionTypes.UPDATE_CONSIGNMENT_DATA_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateConsignment = null;
        draft.loading.updateConsignment = false;
        draft.success.updateConsignment = true;
        draft.updateConsignment = action.payload;
      });
    case actionTypes.UPDATE_CONSIGNMENT_DATA_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateConsignment = true;
        draft.loading.updateConsignment = false;
        draft.success.updateConsignment = false;
        !draft.error.includes('updateConsignment') && draft.error.push('updateConsignment');
      });

    // add Consignment
    case actionTypes.ADD_CONSIGNMENT_DATA:
      return produce(state, (draft) => {
        draft.loading.addConsignment = true;
      });
    case actionTypes.ADD_CONSIGNMENT_DATA_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addConsignment = null;
        draft.loading.addConsignment = false;
        draft.success.addConsignment = true;
        draft.addConsignment = action.payload;
      });
    case actionTypes.ADD_CONSIGNMENT_DATA_ERROR:
      return produce(state, (draft) => {
        draft.errors.addConsignment = true;
        draft.loading.addConsignment = false;
        draft.success.addConsignment = false;
        !draft.error.includes('addConsignment') && draft.error.push('addConsignment');
      });


     // getCategories
     case actionTypes.GET_CATEGORIES:
      return produce(state, (draft) => {
        draft.loading.getCategories = true;
      });
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getCategories = null;
        draft.loading.getCategories = false;
        draft.success.getCategories = true;
        draft.getCategories = action.payload;
      });
    case actionTypes.GET_CATEGORIES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getCategories = true;
        draft.loading.getCategories = false;
        draft.success.getCategories = false;
        !draft.error.includes('getCategories') && draft.error.push('getCategories');
      });

     // getAllLines
     case actionTypes.GET_ALL_LINES:
      return produce(state, (draft) => {
        draft.loading.getAllLines = true;
      });
    case actionTypes.GET_ALL_LINES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllLines = null;
        draft.loading.getAllLines = false;
        draft.success.getAllLines = true;
        draft.getAllLines = action.payload;
      });
    case actionTypes.GET_ALL_LINES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllLines = true;
        draft.loading.getAllLines = false;
        draft.success.getAllLines = false;
        !draft.error.includes('getAllLines') && draft.error.push('getAllLines');
      });

     // addLines
     case actionTypes.CREATE_LINES:
      return produce(state, (draft) => {
        draft.loading.addLines = true;
      });
    case actionTypes.CREATE_LINES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addLines = null;
        draft.loading.addLines = false;
        draft.success.addLines = true;
      });
    case actionTypes.CREATE_LINES_ERROR:
      return produce(state, (draft) => {
        draft.errors.addLines = true;
        draft.loading.addLines = false;
        draft.success.addLines = false;
        !draft.error.includes('addLines') && draft.error.push('addLines');
      });

       // updateLines
     case actionTypes.UPDATE_LINES:
      return produce(state, (draft) => {
        draft.loading.updateLines = true;
      });
    case actionTypes.UPDATE_LINES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateLines = null;
        draft.loading.updateLines = false;
        draft.success.updateLines = true;
      });
    case actionTypes.UPDATE_LINES_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateLines = true;
        draft.loading.updateLines = false;
        draft.success.updateLines = false;
        !draft.error.includes('updateLines') && draft.error.push('updateLines');
      });

    // delete
    case actionTypes.DELETE_LINES:
      return produce(state, (draft) => {
        draft.loading.deleteLines = true;
      });
    case actionTypes.DELETE_LINES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteLines = null;
        draft.loading.deleteLines = false;
        draft.success.deleteLines = true;
      });
    case actionTypes.DELETE_LINES_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteLines = true;
        draft.loading.deleteLines = false;
        draft.success.deleteLines = false;
        !draft.error.includes('deleteLines') && draft.error.push('deleteLines');
      });

    // getConex
     case actionTypes.GET_CONEX_LOADING:
      return produce(state, (draft) => {
        draft.loading.getConex = true;
      });
    case actionTypes.GET_CONEX_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getConex = null;
        draft.loading.getConex = false;
        draft.success.getConex = true;
        draft.getConex = action.payload;
      });
    case actionTypes.GET_CONEX_ERROR:
      return produce(state, (draft) => {
        draft.errors.getConex = true;
        draft.loading.getConex = false;
        draft.success.getConex = false;
        !draft.error.includes('getConex') && draft.error.push('getConex');
      });

         // getParking
      case actionTypes.GET_PARKING_LOADING:
        return produce(state, (draft) => {
          draft.loading.getParking = true;
        });
      case actionTypes.GET_PARKING_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.getParking = null;
          draft.loading.getParking = false;
          draft.success.getParking = true;
          draft.getParking = action.payload;
        });
      case actionTypes.GET_PARKING_ERROR:
        return produce(state, (draft) => {
          draft.errors.getParking = true;
          draft.loading.getParking = false;
          draft.success.getParking = false;
          !draft.error.includes('getParking') && draft.error.push('getParking');
        });
    
    // reloadLogisticom
    case actionTypes.RELOAD_LOGISTICOM_LOADING:
      return produce(state, (draft) => {
        draft.loading.reloadLogisticom = true;
      });
    case actionTypes.RELOAD_LOGISTICOM_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.reloadLogisticom = null;
        draft.loading.reloadLogisticom = false;
        draft.success.reloadLogisticom = true;
      });
    case actionTypes.RELOAD_LOGISTICOM_ERROR:
      return produce(state, (draft) => {
        draft.errors.reloadLogisticom = true;
        draft.loading.reloadLogisticom = false;
        draft.success.reloadLogisticom = false;
      });

    // sendNoticeMail
    case actionTypes.SEND_NOTICE_MAIL_LOADING:
      return produce(state, (draft) => {
        draft.loading.sendNoticeMail = true;
      });
    case actionTypes.SEND_NOTICE_MAIL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.sendNoticeMail = null;
        draft.loading.sendNoticeMail = false;
        draft.success.sendNoticeMail = true;
      });
    case actionTypes.SEND_NOTICE_MAIL_ERROR:
      return produce(state, (draft) => {
        draft.errors.sendNoticeMail = true;
        draft.loading.sendNoticeMail = false;
        draft.success.sendNoticeMail = false;
      });

    // convertFile
    case actionTypes.CONVERT_FILE_LOADING:
      return produce(state, (draft) => {
        draft.loading.convertFile = true;
      });
    case actionTypes.CONVERT_FILE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.convertFile = null;
        draft.loading.convertFile = false;
        draft.success.convertFile = true;
      });
    case actionTypes.CONVERT_FILE_ERROR:
      return produce(state, (draft) => {
        draft.errors.convertFile = true;
        draft.loading.convertFile = false;
        draft.success.convertFile = false;
      });

    // getAllChed
    case actionTypes.GET_ALL_CHED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllChed = true;
      });
    case actionTypes.GET_ALL_CHED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllChed = null;
        draft.loading.getAllChed = false;
        draft.success.getAllChed = true;
        draft.allChed = action.payload;
      });
    case actionTypes.GET_ALL_CHED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllChed = true;
        draft.loading.getAllChed = false;
        draft.success.getAllChed = false;
        !draft.error.includes('getAllChed') && draft.error.push('getAllChed');
      });

    // getOneChed
    case actionTypes.GET_ONE_CHED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneChed = true;
      });
    case actionTypes.GET_ONE_CHED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneChed = null;
        draft.loading.getOneChed = false;
        draft.success.getOneChed = true;
        draft.chedFile = action.payload;
      });
    case actionTypes.GET_ONE_CHED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneChed = true;
        draft.loading.getOneChed = false;
        draft.success.getOneChed = false;
        !draft.error.includes('getOneChed') && draft.error.push('getOneChed');
      });

    // addChed
    case actionTypes.ADD_CHED_LOADING:
      return produce(state, (draft) => {
        draft.loading.addChed = true;
      });
    case actionTypes.ADD_CHED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addChed = null;
        draft.loading.addChed = false;
        draft.success.addChed = true;
      });
    case actionTypes.ADD_CHED_ERROR:
      return produce(state, (draft) => {
        draft.errors.addChed = true;
        draft.loading.addChed = false;
        draft.success.addChed = false;
      });

    // removeChed
    case actionTypes.REMOVE_CHED_LOADING:
      return produce(state, (draft) => {
        draft.loading.removeChed = true;
      });
    case actionTypes.REMOVE_CHED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removeChed = null;
        draft.loading.removeChed = false;
        draft.success.removeChed = true;
      });
    case actionTypes.REMOVE_CHED_ERROR:
      return produce(state, (draft) => {
        draft.errors.removeChed = true;
        draft.loading.removeChed = false;
        draft.success.removeChed = false;
      });

    // updateChedData
    case actionTypes.UPDATE_CHED_DATA_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateChedData = true;
      });
    case actionTypes.UPDATE_CHED_DATA_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateChedData = null;
        draft.loading.updateChedData = false;
        draft.success.updateChedData = true;
      });
    case actionTypes.UPDATE_CHED_DATA_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateChedData = true;
        draft.loading.updateChedData = false;
        draft.success.updateChedData = false;
      });

    // getCompanySubscribed
    case actionTypes.GET_COMPANY_SUBSCRIBED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getCompanySubscribed = true;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = null;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = true;
        draft.companySubscribed = action.payload;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = true;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = false;
        !draft.error.includes('companySubscribed') &&
          draft.error.push('companySubscribed');
      });

    // getAllCustomsOffice
    case actionTypes.GET_ALL_CUSTOMS_OFFICE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllCustomsOffice = true;
      });
    case actionTypes.GET_ALL_CUSTOMS_OFFICE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllCustomsOffice = null;
        draft.loading.getAllCustomsOffice = false;
        draft.success.getAllCustomsOffice = true;
        draft.customsOffices = action.payload;
      });
    case actionTypes.GET_ALL_CUSTOMS_OFFICE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllCustomsOffice = true;
        draft.loading.getAllCustomsOffice = false;
        draft.success.getAllCustomsOffice = false;
        !draft.error.includes('customsOffices') &&
          draft.error.push('customsOffices');
      });

    // getAllCategoriesDocuments
    case actionTypes.GET_ALL_CATEGORIES_DOCUMENTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllCategoriesDocuments = true;
      });
    case actionTypes.GET_ALL_CATEGORIES_DOCUMENTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllCategoriesDocuments = null;
        draft.loading.getAllCategoriesDocuments = false;
        draft.success.getAllCategoriesDocuments = true;
        draft.categoriesDocuments = action.payload;
      });
    case actionTypes.GET_ALL_CATEGORIES_DOCUMENTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllCategoriesDocuments = true;
        draft.loading.getAllCategoriesDocuments = false;
        draft.success.getAllCategoriesDocuments = false;
        !draft.error.includes('categoriesDocuments') &&
          draft.error.push('categoriesDocuments');
      });

    // getAllProcessingDeclarations
    case actionTypes.GET_PROCESSING_DECLARATIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getProcessingDeclarations = true;
      });
    case actionTypes.GET_PROCESSING_DECLARATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getProcessingDeclarations = null;
        draft.loading.getProcessingDeclarations = false;
        draft.success.getProcessingDeclarations = true;
        draft.processingDeclarations = action.payload.rows;
        draft.processingDeclarationsCount = action.payload.count;
      });
    case actionTypes.GET_PROCESSING_DECLARATIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getProcessingDeclarations = true;
        draft.loading.getProcessingDeclarations = false;
        draft.success.getProcessingDeclarations = false;
        !draft.error.includes('processingDeclarations') &&
          draft.error.push('processingDeclarations');
      });

    // getAllProcessingDeclarationsByFilters
    case actionTypes.GET_PROCESSING_DECLARATIONS_BY_FILTERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getProcessingDeclarationsByFilters = true;
      });
    case actionTypes.GET_PROCESSING_DECLARATIONS_BY_FILTERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getProcessingDeclarationsByFilters = null;
        draft.loading.getProcessingDeclarationsByFilters = false;
        draft.success.getProcessingDeclarationsByFilters = true;
        draft.processingDeclarationsByFilters = action.payload.rows;
        draft.processingDeclarationsCount = action.payload.count;
      });
    case actionTypes.GET_PROCESSING_DECLARATIONS_BY_FILTERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getProcessingDeclarationsByFilters = true;
        draft.loading.getProcessingDeclarationsByFilters = false;
        draft.success.getProcessingDeclarationsByFilters = false;
        !draft.error.includes('processingDeclarationsByFilters') &&
          draft.error.push('processingDeclarationsByFilters');
      });

    // cancelDeclaration
    case actionTypes.CANCEL_DECLARATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.cancelDeclaration = true;
      });
    case actionTypes.CANCEL_DECLARATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.cancelDeclaration = null;
        draft.loading.cancelDeclaration = false;
        draft.success.cancelDeclaration = true;
      });
    case actionTypes.CANCEL_DECLARATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.cancelDeclaration = true;
        draft.loading.cancelDeclaration = false;
        draft.success.cancelDeclaration = false;
      });

    // addIntranetReference
    case actionTypes.ADD_INTRANET_REFERENCE_LOADING:
      return produce(state, (draft) => {
        draft.loading.addIntranetReference = true;
      });
    case actionTypes.ADD_INTRANET_REFERENCE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addIntranetReference = null;
        draft.loading.addIntranetReference = false;
        draft.success.addIntranetReference = true;
      });
    case actionTypes.ADD_INTRANET_REFERENCE_ERROR:
      return produce(state, (draft) => {
        draft.errors.addIntranetReference = true;
        draft.loading.addIntranetReference = false;
        draft.success.addIntranetReference = false;
      });

    // declare
    case actionTypes.DELCARE_LOADING:
      return produce(state, (draft) => {
        draft.loading.declare = true;
      });
    case actionTypes.DELCARE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.declare = null;
        draft.loading.declare = false;
        draft.success.declare = true;
        draft.declare = action.payload;
      });
    case actionTypes.DELCARE_ERROR:
      return produce(state, (draft) => {
        draft.errors.declare = true;
        draft.loading.declare = false;
        draft.success.declare = false;
      });

    // updateDeclaration
    case actionTypes.UPDATE_DECLARATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateDeclaration = true;
      });
    case actionTypes.UPDATE_DECLARATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateDeclaration = null;
        draft.loading.updateDeclaration = false;
        draft.success.updateDeclaration = true;
        draft.updateDeclaration = action.payload;
      });
    case actionTypes.UPDATE_DECLARATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateDeclaration = true;
        draft.loading.updateDeclaration = false;
        draft.success.updateDeclaration = false;
      });

      // add mandates
      case actionTypes.ADD_MANDATE_LOADING:
        return produce(state, (draft) => {
          draft.loading.addMandates = true;
        });
      case actionTypes.ADD_MANDATE_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.addMandates = null;
          draft.loading.addMandates = false;
          draft.success.addMandates = true;
        });
      case actionTypes.ADD_MANDATE_ERROR:
        return produce(state, (draft) => {
          draft.errors.addMandates = true;
          draft.loading.addMandates = false;
          draft.success.addMandates = false;
        });

        // getComputedDeclarations
    case actionTypes.GET_ONE_MANDATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadMandate = true;
      });
    case actionTypes.GET_ONE_MANDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadMandate = null;
        draft.loading.downloadMandate = false;
        draft.success.downloadMandate = true;
        draft.downloadMandate = action.payload;
      });
    case actionTypes.GET_ONE_MANDATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadMandate = true;
        draft.loading.downloadMandate = false;
        draft.success.downloadMandate = false;
        !draft.error.includes('downloadMandate') &&
          draft.error.push('downloadMandate');
      });

    // updateStep
    case actionTypes.UPDATE_STEP_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateStep = true;
      });
    case actionTypes.UPDATE_STEP_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateStep = null;
        draft.loading.updateStep = false;
        draft.success.updateStep = true;
      });
    case actionTypes.UPDATE_STEP_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateStep = true;
        draft.loading.updateStep = false;
        draft.success.updateStep = false;
      });

    // addStep
    case actionTypes.ADD_STEP_LOADING:
      return produce(state, (draft) => {
        draft.loading.addStep = true;
      });
    case actionTypes.ADD_STEP_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addStep = null;
        draft.loading.addStep = false;
        draft.success.addStep = true;
      });
    case actionTypes.ADD_STEP_ERROR:
      return produce(state, (draft) => {
        draft.errors.addStep = true;
        draft.loading.addStep = false;
        draft.success.addStep = false;
      });

      // getComputedDeclarations
    case actionTypes.GET_COMPUTED_LOADING:
      return produce(state, (draft) => {
        draft.loading.computedDeclarations = true;
      });
    case actionTypes.GET_COMPUTED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.computedDeclarations = null;
        draft.loading.computedDeclarations = false;
        draft.success.computedDeclarations = true;
        draft.computedDeclarations = action.payload.rows;
        draft.declarationsCount = action.payload.count;
      });
    case actionTypes.GET_COMPUTED_ERROR:
      return produce(state, (draft) => {
        draft.errors.computedDeclarations = true;
        draft.loading.computedDeclarations = false;
        draft.success.computedDeclarations = false;
        !draft.error.includes('computedDeclarations') &&
          draft.error.push('computedDeclarations');
      });

        // get mandates
        case actionTypes.GET_MANDATES_LOADING:
          return produce(state, (draft) => {
            draft.loading.getMandates = true;
          });
        case actionTypes.GET_MANDATES_SUCCESS:
          return produce(state, (draft) => {
            draft.errors.getMandates = null;
            draft.loading.getMandates = false;
            draft.success.getMandates = true;
            draft.getMandates = action.payload;
          });
        case actionTypes.GET_MANDATES_ERROR:
          return produce(state, (draft) => {
            draft.errors.getMandates = true;
            draft.loading.getMandates = false;
            draft.success.getMandates = false;
            !draft.error.includes('getMandates') &&
            draft.error.push('getMandates');
          });

      // generateBaeExtract
      case actionTypes.GENERATE_BAE_EXTRACT_LOADING:
        return produce(state, (draft) => {
          draft.loading.baeExtract = true;
        });
      case actionTypes.GENERATE_BAE_EXTRACT_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.baeExtract = null;
          draft.loading.baeExtract = false;
          draft.success.baeExtract = true;
          draft.baeExtract = action.payload.rows;
        });
      case actionTypes.GENERATE_BAE_EXTRACT_ERROR:
        return produce(state, (draft) => {
          draft.errors.baeExtract = true;
          draft.loading.baeExtract = false;
          draft.success.baeExtract = false;
          !draft.error.includes('baeExtract') &&
            draft.error.push('baeExtract');
        });
      
          // generateBaeExtract
      // case actionTypes.GENERATE_BAE_EXTRACT_LOADING:
      //   return produce(state, (draft) => {
      //     draft.loading.getAllTraces = true;
      //   });
      // case actionTypes.GENERATE_BAE_EXTRACT_SUCCESS:
      //   return produce(state, (draft) => {
      //     draft.errors.getAllTraces = null;
      //     draft.loading.getAllTraces = false;
      //     draft.success.getAllTraces = true;
      //     draft.baeExtract = action.payload.rows;
      //   });
      // case actionTypes.GENERATE_BAE_EXTRACT_ERROR:
      //   return produce(state, (draft) => {
      //     draft.errors.getAllTraces = true;
      //     draft.loading.getAllTraces = false;
      //     draft.success.getAllTraces = false;
      //     !draft.error.includes('getAllTraces') &&
      //       draft.error.push('getAllTraces');
      //   });

    // getAll
    case actionTypes.GET_ALL_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAll = true;
      });
    case actionTypes.GET_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAll = null;
        draft.loading.getAll = false;
        draft.success.getAll = true;
        draft.declarations = action.payload.rows;
        draft.declarationsCount = action.payload.count;
      });
    case actionTypes.GET_ALL_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAll = true;
        draft.loading.getAll = false;
        draft.success.getAll = false;
        !draft.error.includes('declarations') &&
          draft.error.push('declarations');
      });

    // getOne
    case actionTypes.GET_ONE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOne = true;
      });
    case actionTypes.GET_ONE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOne = null;
        draft.loading.getOne = false;
        draft.success.getOne = true;
        draft.declaration = action.payload;
      });
    case actionTypes.GET_ONE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOne = true;
        draft.loading.getOne = false;
        draft.success.getOne = false;
        !draft.error.includes('declaration') && draft.error.push('declaration');
      });

     // getAllHosted
     case actionTypes.GET_ALL_HOSTED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAll = true;
      });
    case actionTypes.GET_ALL_HOSTED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAll = null;
        draft.loading.getAll = false;
        draft.success.getAll = true;
        draft.declarations = action.payload.rows;
        draft.declarationsCount = action.payload.count;
      });
    case actionTypes.GET_ALL_HOSTED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAll = true;
        draft.loading.getAll = false;
        draft.success.getAll = false;
        !draft.error.includes('declarations') &&
          draft.error.push('declarations');
      });

    // getOneHosted
     case actionTypes.GET_ONE_HOSTED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneHosted = true;
      });
    case actionTypes.GET_ONE_HOSTED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneHosted = null;
        draft.loading.getOneHosted = false;
        draft.success.getOneHosted = true;
        draft.oneHosted = action.payload;
      });
    case actionTypes.GET_ONE_HOSTED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneHosted = true;
        draft.loading.getOneHosted = false;
        draft.success.getOneHosted = false;
        !draft.error.includes('oneHosted') &&
          draft.error.push('oneHosted');
      });

       // getOneByReference
     case actionTypes.GET_ONE_BY_REF_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneByRef = true;
      });
    case actionTypes.GET_ONE_BY_REF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneByRef = null;
        draft.loading.getOneByRef = false;
        draft.success.getOneByRef = true;
        draft.getOneByRef = action.payload;
      });
    case actionTypes.GET_ONE_BY_REF_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneByRef = true;
        draft.loading.getOneByRef = false;
        draft.success.getOneByRef = false;
        !draft.error.includes('getOneByRef') &&
          draft.error.push('getOneByRef');
      });

     // addPartnerComment
     case actionTypes.ADD_PART_COMMENTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.addPartnersComments = true;
      });
    case actionTypes.ADD_PART_COMMENTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addPartnersComments = null;
        draft.loading.addPartnersComments = false;
        draft.success.addPartnersComments = true;
        draft.addPartnersComments = action.payload;
      });
    case actionTypes.ADD_PART_COMMENTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.addPartnersComments = true;
        draft.loading.addPartnersComments = false;
        draft.success.addPartnersComments = false;
      });

     // addPartnerComment
     case actionTypes.GET_PART_COMMENTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPartnersComments = true;
      });
    case actionTypes.GET_PART_COMMENTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPartnersComments = null;
        draft.loading.getPartnersComments = false;
        draft.success.getPartnersComments = true;
        draft.getPartnersComments = action.payload;
      });
    case actionTypes.GET_PART_COMMENTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPartnersComments = true;
        draft.loading.getPartnersComments = false;
        draft.success.getPartnersComments = false;
      });

      // addPartner
     case actionTypes.ADD_PARTNERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.addPartner = true;
      });
    case actionTypes.ADD_PARTNERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addPartner = null;
        draft.loading.addPartner = false;
        draft.success.addPartner = true;
      });
    case actionTypes.ADD_PARTNERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.addPartner = true;
        draft.loading.addPartner = false;
        draft.success.addPartner = false;
      });

    // follow declaration
    case actionTypes.FOLLOW_DECLARATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.followDeclaration = true;
      });
    case actionTypes.FOLLOW_DECLARATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.followDeclaration = null;
        draft.loading.followDeclaration = false;
        draft.success.followDeclaration = true;
      });
    case actionTypes.FOLLOW_DECLARATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.followDeclaration = true;
        draft.loading.followDeclaration = false;
        draft.success.followDeclaration = false;
      });

    // unfollow declaration
    case actionTypes.UNFOLLOW_DECLARATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.unfollowDeclaration = true;
      });
    case actionTypes.UNFOLLOW_DECLARATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unfollowDeclaration = null;
        draft.loading.unfollowDeclaration = false;
        draft.success.unfollowDeclaration = true;
      });
    case actionTypes.UNFOLLOW_DECLARATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.unfollowDeclaration = true;
        draft.loading.unfollowDeclaration = false;
        draft.success.unfollowDeclaration = false;
      });

    // add documents
    case actionTypes.ADD_DOCUMENTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.addDocuments = true;
      });
    case actionTypes.ADD_DOCUMENTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addDocuments = null;
        draft.loading.addDocuments = false;
        draft.success.addDocuments = true;
      });
    case actionTypes.ADD_DOCUMENTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.addDocuments = true;
        draft.loading.addDocuments = false;
        draft.success.addDocuments = false;
      });

    // delete document
    case actionTypes.DELETE_DOCUMENT_LOADING:
      return produce(state, (draft) => {
        draft.loading.deleteDocument = true;
      });
    case actionTypes.DELETE_DOCUMENT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteDocument = null;
        draft.loading.deleteDocument = false;
        draft.success.deleteDocument = true;
      });
    case actionTypes.DELETE_DOCUMENT_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteDocument = true;
        draft.loading.deleteDocument = false;
        draft.success.deleteDocument = false;
      });

    // download document
    case actionTypes.DOWNLOAD_DOCUMENT_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadDocument = true;
      });
    case actionTypes.DOWNLOAD_DOCUMENT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadDocument = null;
        draft.loading.downloadDocument = false;
        draft.success.downloadDocument = true;
      });
    case actionTypes.DOWNLOAD_DOCUMENT_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadDocument = true;
        draft.loading.downloadDocument = false;
        draft.success.downloadDocument = false;
      });

    // download documents
    case actionTypes.DOWNLOAD_DOCUMENTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadDocuments = true;
      });
    case actionTypes.DOWNLOAD_DOCUMENTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadDocuments = null;
        draft.loading.downloadDocuments = false;
        draft.success.downloadDocuments = true;
      });
    case actionTypes.DOWNLOAD_DOCUMENTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadDocuments = true;
        draft.loading.downloadDocuments = false;
        draft.success.downloadDocuments = false;
        !draft.error.includes('downloadDocument') &&
          draft.error.push('downloadDocument');
      });

    // extract documents
    case actionTypes.EXTRACT_DOCUMENTS_LOADING:
      return produce(state, (draft) => {
        draft.loading.extractDocuments = true;
      });
    case actionTypes.EXTRACT_DOCUMENTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.extractDocuments = null;
        draft.loading.extractDocuments = false;
        draft.success.extractDocuments = true;
      });
    case actionTypes.EXTRACT_DOCUMENTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.extractDocuments = true;
        draft.loading.extractDocuments = false;
        draft.success.extractDocuments = false;
        !draft.error.includes('extractDocument') &&
          draft.error.push('extractDocument');
      });

    // getChat
    case actionTypes.GET_CHAT_LOADING:
      return produce(state, (draft) => {
        draft.loading.getChat = true;
      });
    case actionTypes.GET_CHAT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getChat = null;
        draft.loading.getChat = false;
        draft.success.getChat = true;
        draft.declarationChat = action.payload;
      });
    case actionTypes.GET_CHAT_ERROR:
      return produce(state, (draft) => {
        draft.errors.getChat = true;
        draft.loading.getChat = false;
        draft.success.getChat = false;
        !draft.error.includes('declarationChat') &&
          draft.error.push('declarationChat');
      });

    // sendMessage
    case actionTypes.SEND_MESSAGE_LOADING:
      return produce(state, (draft) => {
        draft.loading.sendMessage = true;
      });
    case actionTypes.SEND_MESSAGE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.sendMessage = null;
        draft.loading.sendMessage = false;
        draft.success.sendMessage = true;
      });
    case actionTypes.SEND_MESSAGE_ERROR:
      return produce(state, (draft) => {
        draft.errors.sendMessage = true;
        draft.loading.sendMessage = false;
        draft.success.sendMessage = false;
      });

    //Subscribe
    case actionTypes.SUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.subscribe = null;
        draft.loading.subscribe = false;
        draft.success.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.subscribe = true;
        draft.loading.subscribe = false;
        draft.success.subscribe = false;
      });

    //Unsubscribe
    case actionTypes.UNSUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = null;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = true;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = false;
      });

    // getDeclarationsInvoice
    case actionTypes.GET_DECLARATIONS_INVOICE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getDeclarationsInvoice = true;
      });
    case actionTypes.GET_DECLARATIONS_INVOICE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getDeclarationsInvoice = null;
        draft.loading.getDeclarationsInvoice = false;
        draft.success.getDeclarationsInvoice = true;
        draft.declarationsInvoice = action.payload.rows;
        draft.declarationsInvoiceCount = action.payload.count;
      });
    case actionTypes.GET_DECLARATIONS_INVOICE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getDeclarationsInvoice = true;
        draft.loading.getDeclarationsInvoice = false;
        draft.success.getDeclarationsInvoice = false;
      });

    // getDeclarationsInvoiceByFilters
    case actionTypes.GET_DECLARATIONS_INVOICE_BY_FILTERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getDeclarationsInvoiceByFilters = true;
      });
    case actionTypes.GET_DECLARATIONS_INVOICE_BY_FILTERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getDeclarationsInvoiceByFilters = null;
        draft.loading.getDeclarationsInvoiceByFilters = false;
        draft.success.getDeclarationsInvoiceByFilters = true;
        draft.declarationsInvoiceByFilters = action.payload.rows;
        draft.declarationsInvoiceCount = action.payload.count;
      });
    case actionTypes.GET_DECLARATIONS_INVOICE_BY_FILTERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getDeclarationsInvoiceByFilters = true;
        draft.loading.getDeclarationsInvoiceByFilters = false;
        draft.success.getDeclarationsInvoiceByFilters = false;
        !draft.error.includes('declarationsInvoiceByFilters') &&
          draft.error.push('declarationsInvoiceByFilters');
      });

    // updateDeclarationsInvoice
    case actionTypes.UPDATE_DECLARATIONS_INVOICE_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateDeclarationsInvoice = true;
      });
    case actionTypes.UPDATE_DECLARATIONS_INVOICE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateDeclarationsInvoice = null;
        draft.loading.updateDeclarationsInvoice = false;
        draft.success.updateDeclarationsInvoice = true;
      });
    case actionTypes.UPDATE_DECLARATIONS_INVOICE_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateDeclarationsInvoice = true;
        draft.loading.updateDeclarationsInvoice = false;
        draft.success.updateDeclarationsInvoice = false;
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
