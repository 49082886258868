import { selectors } from 'store/common';

const {
  createErrorsSelector,
} = selectors;

// Errors
const sendFormErrorSelector = (state) =>
  state.commercial.errors.sendForm;

const packagesErrorSelector = (state) =>
  state.commercial.errors.packages;

const getFormSchemasErrorSelector = (state) =>
  state.commercial.errors.getFormSchemas;
const errorsSelector = createErrorsSelector([
  sendFormErrorSelector,
  packagesErrorSelector,
  getFormSchemasErrorSelector,
]);

// Loading
const basicLoadingSelector = (state) =>
  state.commercial.loading.basic;
const getFormSchemasLoadingSelector = (state) =>
  state.commercial.loading.getFormSchemas;


const packagesLoadingSelector = (state) =>
  state.commercial.loading.packages;

const formSchemasSelector = (state) => 
  state.commercial.formSchemas;

const processResultSelector = (state) => state.commercial.processResult;
const packagesSelector = (state) => state.commercial.packages;
const basicSelector = (state) => state.commercial.basic;
const errorSelector = (state) => state.commercial.error;

export default {
  errorsSelector,
  formSchemasSelector,
  getFormSchemasLoadingSelector,
  processResultSelector,
  packagesSelector,
  packagesLoadingSelector,
  errorSelector,
  basicSelector,
  basicLoadingSelector
};
