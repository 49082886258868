import React from 'react';
import { Divider, Header, Modal } from 'semantic-ui-react';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import { Message, Span } from './styles'


const ModalCloseIntrastat = ({
  intl,
  open,
  intrastatId,
  isActive,
  declarationType,
  handleCloseIntrastat,
  handleClose,
}: ModalCloseIntrastatProps) => {

  // translate
  const closeIntr = intl.formatMessage({id: `intrastat.close`}) 
  const openIntr = intl.formatMessage({id: `intrastat.open`})
  const CloseIntr = intl.formatMessage({id: `intrastat.Close`}) 
  const OpenIntr = intl.formatMessage({id: `intrastat.Open`})
  const header = intl.formatMessage(
    {id: `intrastat.closeIntrastat.header`}, 
    {declarationType: intl.formatMessage(
      {id: `intrastat.${declarationType.toLowerCase()}`}
    ),
    openOrClose: !isActive ? CloseIntr : OpenIntr
    }
  )

  return (
    <Modal open={open} onClose={handleClose} closeIcon size='tiny'>
      <Header icn='cancel' content={header}/>
          <Modal.Content>
            <Message>{intl.formatMessage(
                {id: `intrastat.closeIntrastat`}, 
                {status: !isActive ? closeIntr : openIntr}
              )}
              <Span>{intl.formatMessage(
                {id: `intrastat.closeIntrastat.alert`}
                )}</Span>
            </Message>  
            <Divider hidden />
            <Wrapper display='flex' justifyContent='center' width='100%'>
              <Button
                type='button'
                color='blue'
                size='small'
                margin='5px'
                content={intl.formatMessage(
                  {id: `intrastat.yes`}
                )}
                onClick={() => handleCloseIntrastat(true, intrastatId, !isActive ? 4 : 3)}
              />
              <Button
                type='button'
                color='red'
                size='small'
                margin='5px'
                content={intl.formatMessage(
                  {id: `intrastat.no`}
                )}
                onClick={() => handleCloseIntrastat(false, intrastatId)}
              />
            </Wrapper>           
        </Modal.Content>     
    </Modal>
  );
};

interface ModalCloseIntrastatProps {
  intl: any
  open: boolean;
  intrastatId: string;
  isActive: boolean;
  declarationType: string;
  handleCloseIntrastat: (response: boolean, intrastatId: string, step?: number) => void;
  handleClose: () => void;
}

export default ModalCloseIntrastat;
