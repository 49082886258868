import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

export const Wrapper = styled.div(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around"
}));

export const Field = styled(Form.Field)`
  &&& {
    margin: 0;
    display: inline-block;
  }
`;

export const State =  styled.div`
    margin: 0;
    padding: 0.5em 1em;
    line-height: 1em;
    color: white;
    float: left;
    font-weight: bold;
    font-size: 12px;
    border-radius: 0.5em;
    background-color: ${props => props.defaultValue}
  `

  export const Period =  styled.div`
    border: 1px solid rgb(212, 212, 213);
    margin: 0 1rem 0 0;
    padding: 0.5em 1em;
    line-height: 1em;
    float: left;
    font-weight: bold;
    font-size: 12px;
    border-radius: 0.5em;
  `

  export const Spacer =  styled.div`
    padding-left: 5px;
    float: right;
    &&:before {
      content: '-';
      float: left;
      padding-right: 5px;
    }
  `

  export const Title = styled.td`
  border-collapse: 'collapse';
  margin: '0 !important';
  padding: '2px 5px !important';
  cell-padding: '0 !important';
  margin-top: 20px;
`
