import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    getPayment: false,
    removePayment: false,
  },
  errors: {
    getPayment: null,
    removePayment: null,
  },
  loading: {
    getPayment: false,
    removePayment: false,
  },
  payment: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Get payment
    case actionTypes.GET_PAYMENT_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPayment = true;
      });

    case actionTypes.GET_PAYMENT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPayment = null;
        draft.loading.getPayment = false;
        draft.success.getPayment = true;
        draft.payment = action.payload;
      });

    case actionTypes.GET_PAYMENT_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPayment = true;
        draft.loading.getPayment = false;
        draft.success.getPayment = false;
        !draft.error.includes("getPayment") && draft.error.push("getPayment");
      });

    // Remove payment
    case actionTypes.REMOVE_PAYMENT_LOADING:
      return produce(state, (draft) => {
        draft.loading.removePayment = true;
      });

    case actionTypes.REMOVE_PAYMENT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removePayment = null;
        draft.loading.removePayment = false;
        draft.success.removePayment = true;
      });

    case actionTypes.REMOVE_PAYMENT_ERROR:
      return produce(state, (draft) => {
        draft.errors.removePayment = true;
        draft.loading.removePayment = false;
        draft.success.removePayment = false;
        !draft.error.includes("removePayment") &&
          draft.error.push("removePayment");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
