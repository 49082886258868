import { Spacer, Wrapper } from 'components/core';
import React, { useState } from 'react';
import { Header, Modal } from 'semantic-ui-react';
import Cookies from 'js-cookie';

import Button from 'components/Button';
import ClickableText from 'components/ClickableText';
import CookieDetails from './components/CookieDetails';

const CookiesBanner: React.FunctionComponent<CookiesBannerI> = ({
  open,
  handleClose,
}) => {
  const [showCookiesDetails, setshowCookiesDetails] = useState(false);

  const acceptCookiesPolicy = () => {
    Cookies.set('consentCookiesPolicy', 'true', {
      expires: 182, //6 months
    });

    handleClose();
  };

  return (
    <Modal open={open} closeOnDimmerClick dimmer='inverted' size='tiny'>
      <Modal.Header>Cookies policy</Modal.Header>
      <Modal.Content>
        <Wrapper>
          {!showCookiesDetails && (
            <Wrapper
              padding='10px'
              textAlign='justify'
              childrenFontSize='1.25rem'
            >
              <p>
                MyASD uses internal cookies that are mandatory for its
                operation.
              </p>
              <Wrapper padding='0.5rem'>
                <ClickableText
                  color='blue'
                  onClick={() => {
                    setshowCookiesDetails(true);
                  }}
                >
                  Cookies details
                </ClickableText>
              </Wrapper>
              <Wrapper padding='0.5rem'>
                <ClickableText
                  color='blue'
                  onClick={() => {
                    window.open(
                      'https://my-asd.s3-eu-west-1.amazonaws.com/legal/COOKIE_USAGE_POLICY_MY-ASD_EN.pdf',
                      '_blank'
                    );
                  }}
                >
                  Cookie usage policy
                </ClickableText>
              </Wrapper>
            </Wrapper>
          )}
          {showCookiesDetails && (
            <Wrapper>
              <Wrapper display='flex'>
                <Button
                  labelPosition='left'
                  icon='angle left'
                  type='button'
                  content='Back'
                  size='small'
                  onClick={() => setshowCookiesDetails(false)}
                />
              </Wrapper>
              <Wrapper padding='20px 20px 0 20px'>
                <Header>Cookies details</Header>
              </Wrapper>
              <CookieDetails
                name='jwt'
                host='my-asd.com'
                period='1 week'
                type='Internal mandatory cookie'
                description='Used to prove the authentication of the user'
              />
              <CookieDetails
                name='consentCookiesPolicy'
                host='my-asd.com'
                period='6 months'
                type='Internal mandatory cookie'
                description={`Used to demonstrate the user's consent to the cookies policy`}
              />
            </Wrapper>
          )}
          <Spacer height='2rem' />
          <Button
            type='button'
            content='Accept and close'
            onClick={acceptCookiesPolicy}
          />
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
};

export default CookiesBanner;

interface CookiesBannerI {
  open: boolean;
  handleClose: () => void;
}
