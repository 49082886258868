import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const pulse = keyframes({
  '0%': { boxShadow: '0 0 0 0 rgba(33,131,221, 0.4)' },
  '70%': { boxShadow: '0 0 0 15px rgba(33,131,221, 0)' },
  '100%': { boxShadow: '0 0 0 0 rgba(33,131,221, 0)' },
});



const nextStep = keyframes({
  '0%': { width: '0%' },
  '100%': { width: '100%' },
});

export const Title = styled.h3(() => ({
  lineHeight: '1.28571429em',
  textTransform: 'none',
  margin: '0',
  color: 'rgba(0,0,0,.87)',
  float: 'left'
}))

export const ProgressBar = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  width: calc(100% - ${(props) => props.title});
  cursor: pointer;
  float: right;
  `;

export const Progression = styled.div(() => ({
  position: 'absolute',
  top: '13px',
  width: '100%',
  height: '5px',
  backgroundColor: 'rgba(211, 211, 211, 0.6)',
  zIndex: -1,
  borderRadius: '2px',
}));

const pulseAnimation = (props) => ({
  ...(props.state === 'active' && { animation: `${pulse} 1.5s infinite` }),
});
const nextStepAnimation = (props) => ({
  ...(props.state === 'complete' && {
    animation: `1s linear ${props.keyStep}s ${nextStep};`,
    animationFillMode: `forwards;`,
  }),
});

export const Step = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  margin-top: 6px;
  text-align: center;
  background: transparent;
  color: ${(props) => props.color};
  cursor: ${(props) => props.cliquable && 'pointer'};

  &:last-child::after {
    display: none;
  }

  &:before {
    position: absolute;
    top: -9px;
    content: '';
    cursor: pointer;
    z-index: -1;
    cursor: ${(props) => props.cliquable && 'pointer'};
    display: flex;
    left: 50%;
    margin-left: -17px;
    width: 35px;
    height: 35px;
    background: ${(props) => props.background || 'white'};
    border-radius: 19px;
    border: 2px solid
      ${(props) =>
        props.state === 'complete' || props.state === 'active'
          ? '#f8f8f8'
          : 'rgb(128 128 128 / 24%)'};
    color: white;
    transition: background 1s linear;
    ${pulseAnimation};
  }

  &:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 50%;
    width: 0%;
    cursor: pointer;
    transition: width 1s ease-in;
    height: 5px;
    background: ${(props) => (props.state === 'complete' ? '#2183dd' : 'grey')};
    z-index: -2;
    ${nextStepAnimation}
  }
`;
