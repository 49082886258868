export default {
  OPEN_NOTIFICATION: 'MY_ASD.NOTIFICATION.OPEN_NOTIFICATION',

  GET_ALL_NOTIFICATION: 'MY_ASD.NOTIFICATION.GET_ALL_NOTIFICATION',
  GET_ALL_NOTIFICATION_LOADING:
    'MY_ASD.NOTIFICATION.GET_ALL_NOTIFICATION_LOADING',
  GET_ALL_NOTIFICATION_SUCCESS:
    'MY_ASD.NOTIFICATION.GET_ALL_NOTIFICATION_SUCCESS',
  GET_ALL_NOTIFICATION_ERROR: 'MY_ASD.NOTIFICATION.GET_ALL_NOTIFICATION_ERROR',

  GET_NUMBER_UNREAD_NOTIFICATION:
    'MY_ASD.NOTIFICATION.GET_NUMBER_UNREAD_NOTIFICATION',
  GET_NUMBER_UNREAD_NOTIFICATION_LOADING:
    'MY_ASD.NOTIFICATION.GET_NUMBER_UNREAD_NOTIFICATION_LOADING',
  GET_NUMBER_UNREAD_NOTIFICATION_SUCCESS:
    'MY_ASD.NOTIFICATION.GET_NUMBER_UNREAD_NOTIFICATION_SUCCESS',
  GET_NUMBER_UNREAD_NOTIFICATION_ERROR:
    'MY_ASD.NOTIFICATION.GET_NUMBER_UNREAD_NOTIFICATION_ERROR',

  NOTIFICATION_IS_READ: 'MY_ASD.NOTIFICATION.NOTIFICATION_IS_READ',
  NOTIFICATION_IS_READ_LOADING: 'MY_ASD.NOTIFICATION.NOTIFICATION_IS_READ_LOADING',
  NOTIFICATION_IS_READ_SUCCESS: 'MY_ASD.NOTIFICATION.NOTIFICATION_IS_READ_SUCCESS',
  NOTIFICATION_IS_READ_ERROR: 'MY_ASD.NOTIFICATION.NOTIFICATION_IS_READ_ERROR',

  NOTIFICATION_ALL_READ: 'MY_ASD.NOTIFICATION.NOTIFICATION_ALL_READ',
  NOTIFICATION_ALL_READ_LOADING: 'MY_ASD.NOTIFICATION.NOTIFICATION_ALL_READ_LOADING',
  NOTIFICATION_ALL_READ_SUCCESS: 'MY_ASD.NOTIFICATION.NOTIFICATION_ALL_READ_SUCCESS',
  NOTIFICATION_ALL_READ_ERROR: 'MY_ASD.NOTIFICATION.NOTIFICATION_ALL_READ_ERROR',

  NOTIFICATION_IS_SHOW: 'MY_ASD.NOTIFICATION.NOTIFICATION_IS_SHOW',
  NOTIFICATION_IS_SHOW_LOADING: 'MY_ASD.NOTIFICATION.NOTIFICATION_IS_SHOW_LOADING',
  NOTIFICATION_IS_SHOW_SUCCESS: 'MY_ASD.NOTIFICATION.NOTIFICATION_IS_SHOW_SUCCESS',
  NOTIFICATION_IS_SHOW_ERROR: 'MY_ASD.NOTIFICATION.NOTIFICATION_IS_SHOW_ERROR',

  NOTIFICATION_ALL_DELETE: 'MY_ASD.NOTIFICATION.NOTIFICATION_ALL_DELETE',
  NOTIFICATION_ALL_DELETE_LOADING: 'MY_ASD.NOTIFICATION.NOTIFICATION_ALL_DELETE_LOADING',
  NOTIFICATION_ALL_DELETE_SUCCESS: 'MY_ASD.NOTIFICATION.NOTIFICATION_ALL_DELETE_SUCCESS',
  NOTIFICATION_ALL_DELETE_ERROR: 'MY_ASD.NOTIFICATION.NOTIFICATION_ALL_DELETE_ERROR',

  RESET: 'MY_ASD.NOTIFICATION.RESET',
};
