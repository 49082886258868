import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  openNotification() {
    return {
      type: actionTypes.OPEN_NOTIFICATION,
    };
  },

  getAllNotification() {
    return {
      type: actionTypes.GET_ALL_NOTIFICATION,
    };
  },

  getNumberReadNotification() {
    return {
      type: actionTypes.GET_NUMBER_UNREAD_NOTIFICATION,
    };
  },

  notificationIsRead(data: dataReqI) {
    return {
      type: actionTypes.NOTIFICATION_IS_READ,
      payload: data,
    };
  },

  notificationAllRead() {
    return {
      type: actionTypes.NOTIFICATION_ALL_READ,
    };
  },

  notificationIsShow(data: dataReqI) {
    return {
      type: actionTypes.NOTIFICATION_IS_SHOW,
      payload: data,
    };
  },

  notificationAllDelete(data: dataReqI) {
    return {
      type: actionTypes.NOTIFICATION_ALL_DELETE,
      payload: data,
    };
  },

  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
};
