import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const paymentApi = routesApi.company.payment;
  return {
    getPayment(data: dataReqI) {
      return api.request({
        method: paymentApi.getPayment.method,
        route: paymentApi.getPayment.path,
        params: data.params,
      });
    },
    removePayment(data: dataReqI) {
      return api.request({
        method: paymentApi.removePayment.method,
        route: paymentApi.removePayment.path,
        params: data.params,
      });
    },
  };
};
