import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    ticketSsr: false,
    sendForm: false,
    packages: false,
    basic: false
  },
  errors: {
    ticketSsr: null,
    sendForm: null,
    packages: null,
    basic: null
  },
  loading: {
    ticketSsr: false,
    sendForm: false,
    packages: false,
    basic: false
  },
  formSchemas: null,
  packages: null,
  basic: null,
  processResult: null,

  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

      //sendForm
      case actionTypes.GET_BASIC_LOADING:
        return produce(state, (draft) => {
          draft.loading.basic = true;
        });
      case actionTypes.GET_BASIC_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.basic = null;
          draft.loading.basic = false;
          draft.success.basic = true;
          draft.basic = action.payload;
        });
      case actionTypes.GET_BASIC_ERROR:
        return produce(state, (draft) => {
          draft.errors.basic = true;
          draft.loading.basic = false;
          draft.success.basic = false;
          !draft.error.includes('basic') && draft.error.push('basic');
        });

     //sendForm
     case actionTypes.SEND_FORM_LOADING:
      return produce(state, (draft) => {
        draft.loading.sendForm = true;
      });
    case actionTypes.SEND_FORM_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.sendForm = null;
        draft.loading.sendForm = false;
        draft.success.sendForm = true;
        draft.formSchemas = action.payload;
      });
    case actionTypes.SEND_FORM_ERROR:
      return produce(state, (draft) => {
        draft.errors.sendForm = true;
        draft.loading.sendForm = false;
        draft.success.sendForm = false;
        !draft.error.includes('formSchemas') && draft.error.push('formSchemas');
      });

     //packages
     case actionTypes.PACKAGES_LOADING:
      return produce(state, (draft) => {
        draft.loading.packages = true;
      });
    case actionTypes.PACKAGES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.packages = null;
        draft.loading.packages = false;
        draft.success.packages = true;
        draft.packages = action.payload;
      });
    case actionTypes.PACKAGES_ERROR:
      return produce(state, (draft) => {
        draft.errors.packages = true;
        draft.loading.packages = false;
        draft.success.packages = false;
        !draft.error.includes('packages') && draft.error.push('packages');
      });


    // packages
    case actionTypes.GET_FORM_SCHEMAS_LOADING:
      return produce(state, (draft) => {
        draft.loading.packages = true;
      });
    case actionTypes.GET_FORM_SCHEMAS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.packages = null;
        draft.loading.packages = false;
        draft.success.packages = true;
        draft.formSchemas = action.payload;
      });
    case actionTypes.GET_FORM_SCHEMAS_ERROR:
      return produce(state, (draft) => {
        draft.errors.packages = true;
        draft.loading.packages = false;
        draft.success.packages = false;
        !draft.error.includes('formSchemas') &&
          draft.error.push('formSchemas');
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
