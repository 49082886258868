import ProofManagementToken from "views/Fiscal/QuickFixes/Transporter/ProofManagementToken";
import paths from "./paths";

export default [
  {
    private: false,
    path: paths.proofManagementToken,
    component: ProofManagementToken,
    exact: true,
    authorizedRoles: [],
  },


];
