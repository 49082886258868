import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { actions, selectors } from 'store';
import { compose } from 'redux';
import {
  Tab,
  Container,
  Divider,
  Table,
  Modal,
  Header,
} from 'semantic-ui-react';

import Button from 'components/Button';
import ButtonValidationModal from 'components/ButtonValidationModal';
import Payzen from 'components/Payzen';
import Wrapper from 'components/core/Wrapper/Wrapper';
import TableSpacedRowsSmall from 'components/TableSpacedRowsSmall';
import EmptyTable from 'components/EmptyTableCurve';

const Index = ({
  company,
  user,
  dispatch,
  createTokenformToken,
  updateTokenformToken,
  companyPayment,
  paymentLoading,
}) => {
  const [createPayzenForm, setCreatePayzenForm] = useState(false);
  const [updatePayzenForm, setUpdatePayzenForm] = useState(false);

  useEffect(() => {
    setCreatePayzenForm(false);
    setUpdatePayzenForm(false);
    dispatch(actions.socket.subscribeUpdatePaymentCompany(company.id));
    dispatch(
      actions.companyPayment.getPayment({
        params: { companyId: company.id },
      })
    );

    return () => {
      dispatch(actions.socket.unsubscribeUpdatePaymentCompany(company.id));
      dispatch(actions.payment.reset(['createTokenFormToken', 'error']));
      dispatch(actions.companyPayment.reset(['payment', 'error']));
    };
  }, []);

  useEffect(() => {
    createTokenformToken && setCreatePayzenForm(true);
  }, [createTokenformToken]);

  useEffect(() => {
    updateTokenformToken && setUpdatePayzenForm(true);
  }, [updateTokenformToken]);

  const handleCloseCreateToken = () => {
    setCreatePayzenForm(false);
    dispatch(actions.payment.reset(['createTokenFormToken']));
  };

  const handleCloseUpdateToken = () => {
    setUpdatePayzenForm(false);
    dispatch(actions.payment.reset(['updateTokenFormToken']));
  };

  const addPaymentMethod = () => {
    dispatch(
      actions.payment.createToken({
        params: { companyId: company.id },
      })
    );
  };

  const updatePaymentMethod = () => {
    dispatch(
      actions.payment.updateToken({
        params: { companyId: company.id },
      })
    );
  };

  const deletePaymentMethod = () => {
    dispatch(
      actions.companyPayment.removePayment({
        params: { companyId: company.id },
      })
    );
  };

  return (
    <Tab.Pane loading={paymentLoading}>
      <Container>
        {!companyPayment && !createPayzenForm && (
          <Wrapper
            display='flex'
            justifyContent='space-between'
            padding='1rem 10rem 0'
          >
            <Button
              icon='plus'
              type='submit'
              content='Add'
              onClick={addPaymentMethod}
            />
            <Divider hidden />
          </Wrapper>
        )}
        {createPayzenForm && (
          <Modal
            open={createPayzenForm}
            onClose={handleCloseCreateToken}
            closeIcon
          >
            <Header textAlign='center'>Add a new payment method:</Header>
            <Divider hidden />
            <Payzen
              formToken={createTokenformToken}
              onSubmit={handleCloseCreateToken}
            />
          </Modal>
        )}
        {updatePayzenForm && (
          <Modal
            open={updatePayzenForm}
            onClose={handleCloseUpdateToken}
            closeIcon
          >
            <Header textAlign='center'>Update your payment method:</Header>
            <Divider hidden />
            <Payzen
              formToken={updateTokenformToken}
              onSubmit={handleCloseUpdateToken}
            />
          </Modal>
        )}
        <Wrapper padding='0 5rem'>
          <TableSpacedRowsSmall sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Card</Table.HeaderCell>
                <Table.HeaderCell>Expiration date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(companyPayment && (
                <Table.Row>
                  <>
                    <Table.Cell>
                      <Wrapper display='flex'>
                        {companyPayment.cbNumber}
                      </Wrapper>
                    </Table.Cell>
                    <Table.Cell>
                      <Wrapper display='flex'>
                        {companyPayment.expiration}
                      </Wrapper>
                    </Table.Cell>
                    <Table.Cell>
                      <Wrapper display='flex' justifyContent='flex-end'>
                        <Button
                          content='Edit'
                          icon='edit'
                          type='button'
                          color='blue'
                          labelPosition='right'
                          onClick={updatePaymentMethod}
                          margin='5px'
                        />
                        <ButtonValidationModal
                          trgrContent='Delete'
                          headerContent='Delete payment method'
                          modalContent={
                            <span>
                              <p>
                                Are you sure you want to delete your payment
                                method?
                              </p>
                              <p>
                                If no means of payment exists at the next due
                                date you will no longer have access to this
                              </p>
                            </span>
                          }
                          trgrColor='red'
                          trgrIcon='delete'
                          headerIcon='delete'
                          onClick={deletePaymentMethod}
                          margin='5px'
                        />
                      </Wrapper>
                    </Table.Cell>
                  </>
                </Table.Row>
              )) || (
                <EmptyTable
                  colNumber={3}
                  message='You do not have any registered payment method'
                />
              )}
            </Table.Body>
          </TableSpacedRowsSmall>
        </Wrapper>
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    createTokenformToken: selectors.payment.createTokenFormTokenSelector(state),
    updateTokenformToken: selectors.payment.updateTokenFormTokenSelector(state),
    companyPayment: selectors.companyPayment.paymentSelector(state),
    paymentLoading: selectors.companyPayment.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
