import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const serviceApi = routesApi.service;

  return {
    create(data: dataReqI) {
      return api.request({
        method: serviceApi.create.method,
        route: serviceApi.create.path,
        params: data.params,
        data: data.data,
      });
    },
    getOne(data: dataReqI) {
      return api.request({
        method: serviceApi.getOne.method,
        route: serviceApi.getOne.path,
        params: data.params,
      });
    },
    update(data: dataReqI) {
      return api.request({
        method: serviceApi.update.method,
        route: serviceApi.update.path,
        params: data.params,
        data: data.data,
      });
    },
    getAll() {
      return api.request({
        method: serviceApi.getAll.method,
        route: serviceApi.getAll.path,
      });
    },
    remove(data: dataReqI) {
      return api.request({
        method: serviceApi.remove.method,
        route: serviceApi.remove.path,
        params: data.params,
      });
    },
    createProduct(data: dataReqI) {
      return api.request({
        method: serviceApi.createProduct.method,
        route: serviceApi.createProduct.path,
        params: data.params,
        data: data.data,
      });
    },
    getOneProduct(data: dataReqI) {
      return api.request({
        method: serviceApi.getProduct.method,
        route: serviceApi.getProduct.path,
        params: data.params,
      });
    },
    updateProduct(data: dataReqI) {
      return api.request({
        method: serviceApi.updateProduct.method,
        route: serviceApi.updateProduct.path,
        params: data.params,
        data: data.data,
      });
    },
    getProductsOfService(data: dataReqI) {
      return api.request({
        method: serviceApi.getProductsOfService.method,
        route: serviceApi.getProductsOfService.path,
        params: data.params,
      });
    },
    getProductsOfServiceSub(data: dataReqI) {
      return api.request({
        method: serviceApi.getProductsOfServiceSub.method,
        route: serviceApi.getProductsOfServiceSub.path,
        params: data.params,
      });
    },
    removeProduct(data: dataReqI) {
      return api.request({
        method: serviceApi.removeProduct.method,
        route: serviceApi.removeProduct.path,
        params: data.params,
      });
    },
  };
};
