import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const declareErrorSelector = (state) => state.fiscalDeclaration.errors.declare;
const subscribeErrorSelector = (state) =>
  state.fiscalDeclaration.errors.subscribe;
const unsubscribeErrorSelector = (state) =>
  state.fiscalDeclaration.errors.unsubscribe;
const getMappingErrorSelector = (state) =>
  state.fiscalDeclaration.errors.getMapping;
const getTAErrorSelector = (state) => state.fiscalDeclaration.errors.getTA;
const getLinesErrorSelector = (state) =>
  state.fiscalDeclaration.errors.getLines;
const getDeclarationsErrorSelector = (state) =>
  state.fiscalDeclaration.errors.getDeclarations;
const getDeclarationErrorSelector = (state) =>
  state.fiscalDeclaration.errors.declaration;
const validateErrorSelector = (state) =>
  state.fiscalDeclaration.errors.validate;
const removeErrorSelector = (state) => state.fiscalDeclaration.errors.remove;

const errorsSelector = createErrorsSelector([
  declareErrorSelector,
  subscribeErrorSelector,
  unsubscribeErrorSelector,
  getMappingErrorSelector,
  getTAErrorSelector,
  getLinesErrorSelector,
  getDeclarationsErrorSelector,
  getDeclarationErrorSelector,
  validateErrorSelector,
  removeErrorSelector,
]);

// Loading
const declareLoadingSelector = (state) =>
  state.fiscalDeclaration.loading.declare;
const subscribeLoadingSelector = (state) =>
  state.fiscalDeclaration.loading.subscribe;
const unsubscribeLoadingSelector = (state) =>
  state.fiscalDeclaration.loading.unsubscribe;
const getMappingLoadingSelector = (state) =>
  state.fiscalDeclaration.loading.getMapping;
const getTALoadingSelector = (state) => state.fiscalDeclaration.loading.getTA;
const getLinesLoadingSelector = (state) =>
  state.fiscalDeclaration.loading.getLines;
const getDeclarationsLoadingSelector = (state) =>
  state.fiscalDeclaration.loading.getDeclarations;
const getDeclarationLoadingSelector = (state) =>
  state.fiscalDeclaration.loading.declaration;
const validateLoadingSelector = (state) =>
  state.fiscalDeclaration.loading.validate;
const removeLoadingSelector = (state) => state.fiscalDeclaration.loading.remove;

const loadingSelector = createLoadingSelector([
  declareLoadingSelector,
  subscribeLoadingSelector,
  unsubscribeLoadingSelector,
  getMappingLoadingSelector,
  getTALoadingSelector,
  getLinesLoadingSelector,
  getDeclarationsLoadingSelector,
  getDeclarationLoadingSelector,
  validateLoadingSelector,
  removeLoadingSelector,
]);

// Success
const declareSuccessSelector = (state) =>
  state.fiscalDeclaration.success.declare;
const subscribeSuccessSelector = (state) =>
  state.fiscalDeclaration.success.subscribe;
const unsubscribeSuccessSelector = (state) =>
  state.fiscalDeclaration.success.unsubscribe;
const getMappingSuccessSelector = (state) =>
  state.fiscalDeclaration.success.getMapping;
const getTASuccessSelector = (state) => state.fiscalDeclaration.success.getTA;
const getLinesSuccessSelector = (state) =>
  state.fiscalDeclaration.success.getLines;
const getDeclarationsSuccessSelector = (state) =>
  state.fiscalDeclaration.success.getDeclarations;
const getDeclarationSuccessSelector = (state) =>
  state.fiscalDeclaration.success.declaration;
const validateSuccessSelector = (state) =>
  state.fiscalDeclaration.success.validate;
const removeSuccessSelector = (state) => state.fiscalDeclaration.success.remove;

const successSelector = createSuccessSelector([
  declareSuccessSelector,
  subscribeSuccessSelector,
  unsubscribeSuccessSelector,
  getMappingSuccessSelector,
  getTASuccessSelector,
  getLinesSuccessSelector,
  getDeclarationsSuccessSelector,
  getDeclarationSuccessSelector,
  validateSuccessSelector,
  removeSuccessSelector,
]);

// Declarations
const declarationsSelector = (state) => state.fiscalDeclaration.declarations;

// Declaration
const declarationSelector = (state) => state.fiscalDeclaration.declaration;

// Lines
const linesSelector = (state) => state.fiscalDeclaration.lines;

// mapping
const mappingSelector = (state) => state.fiscalDeclaration.mapping;

// ta
const taSelector = (state) => state.fiscalDeclaration.ta;

//error
const errorSelector = (state) => state.fiscalDeclaration.error;

// mappingLoading
const mappingLoadingSelector = (state) =>
  state.fiscalDeclaration.getMappingLoadingSelector;

// taLoading
const taLoadingSelector = (state) =>
  state.fiscalDeclaration.getTALoadingSelector;

const companySubscribedSelector = (state) =>
  state.fiscalDeclaration.companySubscribed;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  declarationsSelector,
  declarationSelector,
  linesSelector,
  mappingSelector,
  taSelector,
  errorSelector,
  mappingLoadingSelector,
  taLoadingSelector,
  companySubscribedSelector,
  getDeclarationLoadingSelector,
  getDeclarationSuccessSelector,
};
