export default {
  picture: {
    fileType: ['png', 'jpg', 'jpeg'],
    fileSize: 10000000,
  },

  customsDeclaration: {
    fileType: ['csv'],
    fileSize: 15000000,
  },
  customsDocuments: {
    fileType: ['png', 'jpeg', 'jpg', 'docx', 'pdf', 'xls', 'xlsx', 'csv'],
    fileSize: 15000000,
  },
  fiscalDeclaration: {
    fileType: [
      'csv',
      'txt',
      'xls',
      'xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    fileSize: 70000000,
  },
  OssIntegration: {
    fileType: [
      'xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    fileSize: 70000000,
  },
  podProof: {
    fileType: ['png', 'jpeg', 'jpg', 'docx', 'pdf', 'xls', 'xlsx', 'csv'],
    fileSize: 45000000,
  },
  massIntegration: {
    fileType: ['csv'],
    fileSize: 15000000,
  },
  contactAttachment: {
    fileType: [
      'application/pdf',
      'image/png',
      'image/tiff',
      'image/jpeg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    fileSize: 1310720,
  },
};
