export default {

  LANG: 'MY_ASD.INTRASTAT.LANG',


  GET_COMPANY_SUBSCRIBED: 'MY_ASD.INTRASTAT.GET_COMPANY_SUBSCRIBED',
  GET_COMPANY_SUBSCRIBED_LOADING: 'MY_ASD.INTRASTAT.GET_COMPANY_SUBSCRIBED_LOADING',
  GET_COMPANY_SUBSCRIBED_SUCCESS: 'MY_ASD.INTRASTAT.GET_COMPANY_SUBSCRIBED_SUCCESS',
  GET_COMPANY_SUBSCRIBED_ERROR: 'MY_ASD.INTRASTAT.GET_COMPANY_SUBSCRIBED_ERROR',
  
  SUBSCRIBE: 'MY_ASD.INTRASTAT.SUBSCRIBE',
  SUBSCRIBE_LOADING: 'MY_ASD.INTRASTAT.SUBSCRIBE_LOADING',
  SUBSCRIBE_SUCCESS: 'MY_ASD.INTRASTAT.SUBSCRIBE_SUCCESS',
  SUBSCRIBE_ERROR: 'MY_ASD.INTRASTAT.SUBSCRIBE_ERROR',

  UNSUBSCRIBE: 'MY_ASD.INTRASTAT.UNSUBSCRIBE',
  UNSUBSCRIBE_LOADING: 'MY_ASD.INTRASTAT.UNSUBSCRIBE_LOADING',
  UNSUBSCRIBE_SUCCESS: 'MY_ASD.INTRASTAT.UNSUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_ERROR: 'MY_ASD.INTRASTAT.UNSUBSCRIBE_ERROR',

  GET_INTRASTAT_CATEGORIES: "MY_ASD.INTRASTAT.GET_INTRASTAT_CATEGORIES",
  GET_INTRASTAT_CATEGORIES_LOADING: "MY_ASD.INTRASTAT.GET_INTRASTAT_CATEGORIES_LOADING",
  GET_INTRASTAT_CATEGORIES_SUCCESS: "MY_ASD.INTRASTAT.GET_INTRASTAT_CATEGORIES_SUCCESS",
  GET_INTRASTAT_CATEGORIES_ERROR: "MY_ASD.INTRASTAT.GET_INTRASTAT_CATEGORIES_ERROR",

  GET_ALL_INTRASTATS: "MY_ASD.INTRASTAT.GET_ALL_INTRASTATS",
  GET_ALL_INTRASTATS_LOADING: "MY_ASD.INTRASTAT.GET_ALL_INTRASTATS_LOADING",
  GET_ALL_INTRASTATS_SUCCESS: "MY_ASD.INTRASTAT.GET_ALL_INTRASTATS_SUCCESS",
  GET_ALL_INTRASTATS_ERROR: "MY_ASD.INTRASTAT.GET_ALL_INTRASTATS_ERROR",

  GET_ONE_INTRASTAT: "MY_ASD.INTRASTAT.GET_ONE_INTRASTAT",
  GET_ONE_INTRASTAT_LOADING: "MY_ASD.INTRASTAT.GET_ONE_INTRASTAT_LOADING",
  GET_ONE_INTRASTAT_SUCCESS: "MY_ASD.INTRASTAT.GET_ONE_INTRASTAT_SUCCESS",
  GET_ONE_INTRASTAT_ERROR: "MY_ASD.INTRASTAT.GET_ONE_INTRASTAT_ERROR",

  UPDATE_INTRASTAT: "MY_ASD.INTRASTAT.UPDATE_INTRASTAT",
  UPDATE_INTRASTAT_LOADING: "MY_ASD.INTRASTAT.UPDATE_INTRASTAT_LOADING",
  UPDATE_INTRASTAT_SUCCESS: "MY_ASD.INTRASTAT.UPDATE_INTRASTAT_SUCCESS",
  UPDATE_INTRASTAT_ERROR: "MY_ASD.INTRASTAT.UPDATE_INTRASTAT_ERROR",

  CREATE_INTRASTAT: 'MY_ASD.INTRASTAT.CREATE_INTRASTAT',
  CREATE_INTRASTAT_LOADING: 'MY_ASD.INTRASTAT.CREATE_INTRASTAT_LOADING', 
  CREATE_INTRASTAT_SUCCESS: 'MY_ASD.INTRASTAT.CREATE_INTRASTAT_SUCCESS',
  CREATE_INTRASTAT_ERROR: 'MY_ASD.INTRASTAT.CREATE_INTRASTAT_ERROR',

  UPDATE_STEP: 'MY_ASD.INTRASTAT.UPDATE_STEP',
  UPDATE_STEP_LOADING: 'MY_ASD.INTRASTAT.UPDATE_STEP_LOADING', 
  UPDATE_STEP_SUCCESS: 'MY_ASD.INTRASTAT.UPDATE_STEP_SUCCESS',
  UPDATE_STEP_ERROR: 'MY_ASD.INTRASTAT.UPDATE_STEP_ERROR',

  FOLLOW_INTRASTAT: 'MY_ASD.INTRASTAT.FOLLOW_INTRASTAT',
  FOLLOW_INTRASTAT_LOADING: 'MY_ASD.INTRASTAT.FOLLOW_INTRASTAT_LOADING', 
  FOLLOW_INTRASTAT_SUCCESS: 'MY_ASD.INTRASTAT.FOLLOW_INTRASTAT_SUCCESS',
  FOLLOW_INTRASTAT_ERROR: 'MY_ASD.INTRASTAT.FOLLOW_INTRASTAT_ERROR',

  UNFOLLOW_INTRASTAT: 'MY_ASD.INTRASTAT.UNFOLLOW_INTRASTAT',
  UNFOLLOW_INTRASTAT_LOADING: 'MY_ASD.INTRASTAT.UNFOLLOW_INTRASTAT_LOADING', 
  UNFOLLOW_INTRASTAT_SUCCESS: 'MY_ASD.INTRASTAT.UNFOLLOW_INTRASTAT_SUCCESS',
  UNFOLLOW_INTRASTAT_ERROR: 'MY_ASD.INTRASTAT.UNFOLLOW_INTRASTAT_ERROR',

  INTRASTAT_ADD_DOCUMENTS: 'MY_ASD.INTRASTAT.INTRASTAT_ADD_DOCUMENTS',
  INTRASTAT_ADD_DOCUMENTS_LOADING: 'MY_ASD.INTRASTAT.INTRASTAT_ADD_DOCUMENTS_LOADING', 
  INTRASTAT_ADD_DOCUMENTS_SUCCESS: 'MY_ASD.INTRASTAT.INTRASTAT_ADD_DOCUMENTS_SUCCESS',
  INTRASTAT_ADD_DOCUMENTS_ERROR: 'MY_ASD.INTRASTAT.INTRASTAT_ADD_DOCUMENTS_ERROR',

  INTRASTAT_GET_DOC_CAT: 'MY_ASD.INTRASTAT.INTRASTAT_GET_DOC_CAT',
  INTRASTAT_GET_DOC_CAT_LOADING: 'MY_ASD.INTRASTAT.INTRASTAT_GET_DOC_CAT_LOADING', 
  INTRASTAT_GET_DOC_CAT_SUCCESS: 'MY_ASD.INTRASTAT.INTRASTAT_GET_DOC_CAT_SUCCESS',
  INTRASTAT_GET_DOC_CAT_ERROR: 'MY_ASD.INTRASTAT.INTRASTAT_GET_DOC_CAT_ERROR',

  UPDATE_DOC: 'MY_ASD.INTRASTAT.UPDATE_DOC',
  UPDATE_DOC_LOADING: 'MY_ASD.INTRASTAT.UPDATE_DOC_LOADING',
  UPDATE_DOC_SUCCESS: 'MY_ASD.INTRASTAT.UPDATE_DOC_SUCCESS',
  UPDATE_DOC_ERROR: 'MY_ASD.INTRASTAT.UPDATE_DOC_ERROR',

  DELETE_DOC: 'MY_ASD.INTRASTAT.DELETE_DOC',
  DELETE_DOC_LOADING: 'MY_ASD.INTRASTAT.DELETE_DOC_LOADING',
  DELETE_DOC_SUCCESS: 'MY_ASD.INTRASTAT.DELETE_DOC_SUCCESS',
  DELETE_DOC_ERROR: 'MY_ASD.INTRASTAT.DELETE_DOC_ERROR',

  DOWNLOAD_DOC: 'MY_ASD.INTRASTAT.DOWNLOAD_DOC',
  DOWNLOAD_DOC_LOADING: 'MY_ASD.INTRASTAT.DOWNLOAD_DOC_LOADING',
  DOWNLOAD_DOC_SUCCESS: 'MY_ASD.INTRASTAT.DOWNLOAD_DOC_SUCCESS',
  DOWNLOAD_DOC_ERROR: 'MY_ASD.INTRASTAT.DOWNLOAD_DOC_ERROR',

  TAG_OPERATION: 'MY_ASD.INTRASTAT.TAG_OPERATION',
  TAG_OPERATION_LOADING: 'MY_ASD.INTRASTAT.TAG_OPERATION_LOADING',
  TAG_OPERATION_SUCCESS: 'MY_ASD.INTRASTAT.TAG_OPERATION_SUCCESS',
  TAG_OPERATION_ERROR: 'MY_ASD.INTRASTAT.TAG_OPERATION_ERROR',

  GET_ARCHIVE_FILES: 'MY_ASD.INTRASTAT.GET_ARCHIVE_FILES',
  GET_ARCHIVE_FILES_LOADING: 'MY_ASD.INTRASTAT.GET_ARCHIVE_FILES_LOADING',
  GET_ARCHIVE_FILES_SUCCESS: 'MY_ASD.INTRASTAT.GET_ARCHIVE_FILES_SUCCESS',
  GET_ARCHIVE_FILES_ERROR: 'MY_ASD.INTRASTAT.GET_ARCHIVE_FILES_ERROR',

  GET_ARCHIVE_FILE: 'MY_ASD.INTRASTAT.GET_ARCHIVE_FILE',
  GET_ARCHIVE_FILE_LOADING: 'MY_ASD.INTRASTAT.GET_ARCHIVE_FILE_LOADING',
  GET_ARCHIVE_FILE_SUCCESS: 'MY_ASD.INTRASTAT.GET_ARCHIVE_FILE_SUCCESS',
  GET_ARCHIVE_FILE_ERROR: 'MY_ASD.INTRASTAT.GET_ARCHIVE_FILE_ERROR',

  SINGLE_FILE: 'MY_ASD.INTRASTAT.SINGLE_FILE',
  SINGLE_FILE_LOADING: 'MY_ASD.INTRASTAT.SINGLE_FILE_LOADING',
  SINGLE_FILE_SUCCESS: 'MY_ASD.INTRASTAT.SINGLE_FILE_SUCCESS',
  SINGLE_FILE_ERROR: 'MY_ASD.INTRASTAT.SINGLE_FILE_ERROR',

  FILTER_TAG: 'MY_ASD.INTRASTAT.FILTER_TAG',
  FILTER_TAG_LOADING: 'MY_ASD.INTRASTAT.FILTER_TAG_LOADING',
  FILTER_TAG_SUCCESS: 'MY_ASD.INTRASTAT.FILTER_TAG_SUCCESS',
  FILTER_TAG_ERROR: 'MY_ASD.INTRASTAT.FILTER_TAG_ERROR',

  ARCHIVE_ALL: 'MY_ASD.INTRASTAT.ARCHIVE_ALL',
  ARCHIVE_ALL_LOADING: 'MY_ASD.INTRASTAT.ARCHIVE_ALL_LOADING',
  ARCHIVE_ALL_SUCCESS: 'MY_ASD.INTRASTAT.ARCHIVE_ALL_SUCCESS',
  ARCHIVE_ALL_ERROR: 'MY_ASD.INTRASTAT.ARCHIVE_ALL_ERROR',

  BULK_FILTER: 'MY_ASD.INTRASTAT.BULK_FILTER',
  BULK_FILTER_LOADING: 'MY_ASD.INTRASTAT.BULK_FILTER_LOADING',
  BULK_FILTER_SUCCESS: 'MY_ASD.INTRASTAT.BULK_FILTER_SUCCESS',
  BULK_FILTER_ERROR: 'MY_ASD.INTRASTAT.BULK_FILTER_ERROR',

  SPLIT_DOCUMENT: 'MY_ASD.INTRASTAT.SPLIT_DOCUMENT',
  SPLIT_DOCUMENT_LOADING: 'MY_ASD.INTRASTAT.SPLIT_DOCUMENT_LOADING',
  SPLIT_DOCUMENT_SUCCESS: 'MY_ASD.INTRASTAT.SPLIT_DOCUMENT_SUCCESS',
  SPLIT_DOCUMENT_ERROR: 'MY_ASD.INTRASTAT.SPLIT_DOCUMENT_ERROR',

  RENAME_SEND_CHUNCKS: 'MY_ASD.INTRASTAT.RENAME_SEND_CHUNCKS',
  RENAME_SEND_CHUNCKS_LOADING: 'MY_ASD.INTRASTAT.RENAME_SEND_CHUNCKS_LOADING',
  RENAME_SEND_CHUNCKS_SUCCESS: 'MY_ASD.INTRASTAT.RENAME_SEND_CHUNCKS_SUCCESS',
  RENAME_SEND_CHUNCKS_ERROR: 'MY_ASD.INTRASTAT.RENAME_SEND_CHUNCKS_ERROR',

  GROUP_FILES: 'MY_ASD.INTRASTAT.GROUP_FILES',
  GROUP_FILES_LOADING: 'MY_ASD.INTRASTAT.GROUP_FILES_LOADING',
  GROUP_FILES_SUCCESS: 'MY_ASD.INTRASTAT.GROUP_FILES_SUCCESS',
  GROUP_FILES_ERROR: 'MY_ASD.INTRASTAT.GROUP_FILES_ERROR',

  FILTER_TAG_ARCHIVES: 'MY_ASD.INTRASTAT.FILTER_TAG_ARCHIVES',
  FILTER_TAG_ARCHIVES_LOADING: 'MY_ASD.INTRASTAT.FILTER_TAG_ARCHIVES_LOADING',
  FILTER_TAG_ARCHIVES_SUCCESS: 'MY_ASD.INTRASTAT.FILTER_TAG_ARCHIVES_SUCCESS',
  FILTER_TAG_ARCHIVES_ERROR: 'MY_ASD.INTRASTAT.FILTER_TAG_ARCHIVES_ERROR',

  GET_PERMS: 'MY_ASD.INTRASTAT.GET_PERMS',
  GET_PERMS_LOADING: 'MY_ASD.INTRASTAT.GET_PERMS_LOADING',
  GET_PERMS_SUCCESS: 'MY_ASD.INTRASTAT.GET_PERMS_SUCCESS',
  GET_PERMS_ERROR: 'MY_ASD.INTRASTAT.GET_PERMS_ERROR',

  CREATE_NONE_INTRASTAT: 'MY_ASD.INTRASTAT.CREATE_NONE_INTRASTAT',
  CREATE_NONE_INTRASTAT_LOADING: 'MY_ASD.INTRASTAT.CREATE_NONE_INTRASTAT_LOADING',
  CREATE_NONE_INTRASTAT_SUCCESS: 'MY_ASD.INTRASTAT.CREATE_NONE_INTRASTAT_SUCCESS',
  CREATE_NONE_INTRASTAT_ERROR: 'MY_ASD.INTRASTAT.CREATE_NONE_INTRASTAT_ERROR',

  DELETE_FILES: 'MY_ASD.INTRASTAT.DELETE_FILES',
  DELETE_FILES_LOADING: 'MY_ASD.INTRASTAT.DELETE_FILES_LOADING',
  DELETE_FILES_SUCCESS: 'MY_ASD.INTRASTAT.DELETE_FILES_SUCCESS',
  DELETE_FILES_ERROR: 'MY_ASD.INTRASTAT.DELETE_FILES_ERROR',

  FILES_STATE: 'MY_ASD.INTRASTAT.FILES_STATE',
  FILES_STATE_LOADING: 'MY_ASD.INTRASTAT.FILES_STATE_LOADING',
  FILES_STATE_SUCCESS: 'MY_ASD.INTRASTAT.FILES_STATE_SUCCESS',
  FILES_STATE_ERROR: 'MY_ASD.INTRASTAT.FILES_STATE_ERROR',

  GET_FILTERED_INTRASTATS: 'MY_ASD.INTRASTAT.GET_FILTERED_INTRASTATS',
  GET_FILTERED_INTRASTATS_LOADING: 'MY_ASD.INTRASTAT.GET_FILTERED_INTRASTATS_LOADING',
  GET_FILTERED_INTRASTATS_SUCCESS: 'MY_ASD.INTRASTAT.GET_FILTERED_INTRASTATS_SUCCESS',
  GET_FILTERED_INTRASTATS_ERROR: 'MY_ASD.INTRASTAT.GET_FILTERED_INTRASTATS_ERROR',

  SETTINGS: 'MY_ASD.INTRASTAT.SETTINGS',
  SETTINGS_LOADING: 'MY_ASD.INTRASTAT.SETTINGS_LOADING',
  SETTINGS_SUCCESS: 'MY_ASD.INTRASTAT.SETTINGS_SUCCESS',
  SETTINGS_ERROR: 'MY_ASD.INTRASTAT.SETTINGS_ERROR',

  SETTINGS_GET: 'MY_ASD.INTRASTAT.SETTINGS_GET',
  SETTINGS_GET_LOADING: 'MY_ASD.INTRASTAT.SETTINGS_GET_LOADING',
  SETTINGS_GET_SUCCESS: 'MY_ASD.INTRASTAT.SETTINGS_GET_SUCCESS',
  SETTINGS_GET_ERROR: 'MY_ASD.INTRASTAT.SETTINGS_GET_ERROR',

  RESET: "MY_ASD.DATA.RESET",
};
