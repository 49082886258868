let actionTypes = {
  CREATE_CLERKS_PRICING: "MY_ASD.CLERKS_PRICING.CREATE_CLERKS_PRICING",
  CREATE_CLERKS_PRICING_LOADING: "MY_ASD.CLERKS_PRICING.CREATE_CLERKS_PRICING_LOADING",
  CREATE_CLERKS_PRICING_SUCCESS: "MY_ASD.CLERKS_PRICING.CREATE_CLERKS_PRICING_SUCCESS",
  CREATE_CLERKS_PRICING_ERROR: "MY_ASD.CLERKS_PRICING.CREATE_CLERKS_PRICING_ERROR",

  GET_ALL_CLERKS_PRICING: "MY_ASD.CLERKS_PRICING.GET_ALL_CLERKS_PRICING",
  GET_ALL_CLERKS_PRICING_LOADING: "MY_ASD.CLERKS_PRICING.GET_ALL_CLERKS_PRICING_LOADING",
  GET_ALL_CLERKS_PRICING_SUCCESS: "MY_ASD.CLERKS_PRICING.GET_ALL_CLERKS_PRICING_SUCCESS",
  GET_ALL_CLERKS_PRICING_ERROR: "MY_ASD.CLERKS_PRICING.GET_ALL_CLERKS_PRICING_ERROR",

  GET_ONE_CLERKS_PRICING: "MY_ASD.CLERKS_PRICING.GET_ONE_CLERKS_PRICING",
  GET_ONE_CLERKS_PRICING_LOADING: "MY_ASD.CLERKS_PRICING.GET_ONE_CLERKS_PRICING_LOADING",
  GET_ONE_CLERKS_PRICING_SUCCESS: "MY_ASD.CLERKS_PRICING.GET_ONE_CLERKS_PRICING_SUCCESS",
  GET_ONE_CLERKS_PRICING_ERROR: "MY_ASD.CLERKS_PRICING.GET_ONE_CLERKS_PRICING_ERROR",

  DELETE_CLERKS_PRICING: "MY_ASD.CLERKS_PRICING.DELETE_CLERKS_PRICING",
  DELETE_CLERKS_PRICING_LOADING: "MY_ASD.CLERKS_PRICING.DELETE_CLERKS_PRICING_LOADING",
  DELETE_CLERKS_PRICING_SUCCESS: "MY_ASD.CLERKS_PRICING.DELETE_CLERKS_PRICING_SUCCESS",
  DELETE_CLERKS_PRICING_ERROR: "MY_ASD.CLERKS_PRICING.DELETE_CLERKS_PRICING_ERROR",

  UPDATE_CLERKS_PRICING: "MY_ASD.CLERKS_PRICING.UPDATE_CLERKS_PRICING",
  UPDATE_CLERKS_PRICING_LOADING: "MY_ASD.CLERKS_PRICING.UPDATE_CLERKS_PRICING_LOADING",
  UPDATE_CLERKS_PRICING_SUCCESS: "MY_ASD.CLERKS_PRICING.UPDATE_CLERKS_PRICING_SUCCESS",
  UPDATE_CLERKS_PRICING_ERROR: "MY_ASD.CLERKS_PRICING.UPDATE_CLERKS_PRICING_ERROR",

  RESET: "MY_ASD.CLERKS_PRICING.RES",
  REFRESH_ERROR: "MY_ASD.CLERKS_PRICING.REFRESH_ERROR"
};

export default actionTypes;
