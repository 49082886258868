import React, { useState } from 'react';
import { getIn, ErrorMessage } from 'formik';
import SignaturePad from 'react-signature-canvas';

import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import { Error } from 'components/Form';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';

const Signature = ({ meta, field, label, mandatory, margin, data }: any) => {
  const [signPad, setsignPad] = useState(null);

  const dataURLtoFile = (dataUrl, filename) => {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onDrawStop = () => {
    if (signPad) {
      const signImage = signPad.getTrimmedCanvas().toDataURL('image/png');
      const imageFile = dataURLtoFile(signImage, 'signature.png');
      meta.setFieldValue('signature', imageFile);
    }
  };

  const clear = () => {
    if (signPad) {
      signPad.clear();
      meta.setFieldValue('signature', null);
    }
  };

  return (
    <Wrapper display='block'>
      {label && (
        <label>
          {label}
          {mandatory && <MandatoryFieldLabel />}
        </label>
      )}
      <Wrapper
        height={margin || '200px'}
        backgroundColor='white'
        margin={margin || '0 auto 1rem'}
        border='1px solid rgba(34,36,38,.15)'
        borderRadius='10px'
      >
        <SignaturePad
          clearOnResize={false}
          canvasProps={{
            style: { width: '100%', height: '100%' },
          }}
          ref={(ref) => {
            setsignPad(ref);
          }}
          onEnd={() => onDrawStop()}
          onBlur={field.onBlur}
          value={field.value}
          error={
            getIn(meta.touched, field.name) && getIn(meta.errors, field.name)
              ? true
              : false
          }
        />
      </Wrapper>
      <ErrorMessage name={field.name} render={(msg) => <Error msg={msg} />} />
      <Wrapper>
        <Button
          labelPosition='left'
          icon='eraser'
          type='button'
          content='Erase signature'
          color='red'
          margin='5px'
          onClick={() => clear()}
        />
      </Wrapper>
    </Wrapper>
  );
};

export default Signature;
