import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const subscribeErrorSelector = (state) => state.intrastat.errors.subscribe;
const unsubscribeErrorSelector = (state) => state.intrastat.errors.unsubscribe;
const getAllIntrastatsErrorSelector = state => state.intrastat.errors.getAllIntrastats;
const getOneIntrastatErrorSelector = state => state.intrastat.errors.getOneIntrastat;
const createOneIntrastatErrorSelector = state => state.intrastat.errors.createIntrastat;
const downloadErrorSelector = (state) => state.intrastat.error.download;
const fileStateErrorSelector = (state) => state.intrastat.error.filesState;
const getIntrastatsByFiltersErrorSelector = (state) => state.intrastat.error.getByFilters;
const getSettingsErrorSelector = (state) => state.intrastat.error.getSettings;
const getCategoriesErrorSelector = (state) => state.intrastat.error.categories;

const errorsSelector = createErrorsSelector([
  getAllIntrastatsErrorSelector,
  getOneIntrastatErrorSelector,
  createOneIntrastatErrorSelector,
  downloadErrorSelector,
  fileStateErrorSelector,
  unsubscribeErrorSelector,
  subscribeErrorSelector,
  getIntrastatsByFiltersErrorSelector,
  getSettingsErrorSelector,
  getCategoriesErrorSelector
]);


// Loading
const subscribeLoadingSelector = (state) => state.intrastat.loading.subscribe;
const unsubscribeLoadingSelector = (state) => state.intrastat.loading.unsubscribe;
const getAllIntrastatsLoadingSelector = state => state.intrastat.loading.getAllIntrastats;
const getOneIntrastatLoadingSelector = state => state.intrastat.loading.getOneIntrastat;
const createOneIntrastatLoadingSelector = state => state.intrastat.loading.createIntrastat;
const getDocsFromCatLoadingSelector = state => state.intrastat.loading.docsFromCat;
const companySubscribedLoadingSelector = (state) => state.intrastat.loading.companySubscribed;
const tagLoadingSelector = state => state.intrastat.loading.tag;

const getFilesFullListLoadingSelector = state => state.intrastat.loading.archiveFiles;
const getArchiveLoadingSelector = state => state.intrastat.loading.archiveFile;
const createSingleFileLoadingSelector = state => state.intrastat.loading.singleFile;
const filterTagLoadingSelector = state => state.intrastat.loading.filterTag;
const archiveAllLoadingSelector = state => state.intrastat.loading.archiveAll;
const bulkFilterLoadingSelector = state => state.intrastat.loading.bulkFilter;
const splitDocumentLoadingSelector = state => state.intrastat.loading.split;
const renameSendChuncksLoadingSelector = state => state.intrastat.loading.renameSendChunk;
const groupFilesLoadingSelector = state => state.intrastat.loading.groupFiles;
const filterTagArchivesLoadingSelector = state => state.intrastat.loading.filterTagArchives;
const addIntrastatDocumentsLoadingSelector = state => state.intrastat.loading.docs;
const deleteIntrastatDocumentsLoadingSelector = state => state.intrastat.loading.deleteDoc;
const permsLoadingSelector = state => state.intrastat.loading.perms;
const noneCreateIntrastatLoadingSelector = state => state.intrastat.loading.none;
const downloadIntrastatLoadingSelector = (state) => state.intrastat.loading.download;
const deleteFilesLoadingSelector = (state) => state.intrastat.loading.deleteFiles;
const fileStateLoadingSelector = (state) => state.intrastat.loading.filesState;
const getIntrastatsByFiltersLoadingSelector = (state) => state.intrastat.loading.getByFilters;
const updateDocLoadingSelector = state => state.fiscalVat.loading.updateDoc;
const downloadDocLoadingSelector = state => state.fiscalVat.loading.downloadDoc;
const getCategoriesLoadingSelector = (state) => state.intrastat.loading.categories;


const loadingSelector = createLoadingSelector([
      (state) => state.intrastat.loading.getAllIntrastats,
      (state) => state.intrastat.loading.createIntrastat,
      (state) => state.fiscalVat.loading.downloadDoc
]);

const loadingSettingsSelector = createLoadingSelector([
  (state) => state.intrastat.loading.settings,
  (state) => state.intrastat.loading.getSettings,
]);

// Success
const createOneIntrastatSuccessSelector = state => state.intrastat.success.createIntrastat;
const tagSuccessSelector = state => state.intrastat.success.tag;
const renameSendChuncksSuccessSelector = state => state.intrastat.success.renameSendChunk;
const fileStateSuccessSelector = state => state.intrastat.success.filesState;

const successSelector = createSuccessSelector([
      (state) => state.intrastat.success.getAllIntrastats,
      (state) => state.intrastat.success.getOneIntrastat,
]);

// Countries
const subscribeSelector = (state) => state.intrastat.subscribe;
const unsubscribeSelector = (state) => state.intrastat.unsubscribe;
const allIntrastatsSelector = state => state.intrastat.getAllIntrastats;
const oneIntrastatSelector = state => state.intrastat.oneIntrastat;
const getDocsFromCatSelector = state => state.intrastat.docsFromCat;
const companySubscribedSelector = (state) => state.intrastat.companySubscribed;
const tagSelector = state => state.intrastat.tag;
const getFilesFullListSelector = state => state.intrastat.archiveFiles;
const getArchiveSelector = state => state.intrastat.archiveFile;
const createSingleFileSelector = state => state.intrastat.singleFile;
const filterTagSelector = state => state.intrastat.filterTag;
const archiveAllSelector = state => state.intrastat.archiveAll;
const bulkFilterSelector = state => state.intrastat.bulkFilter;
const splitDocumentSelector = state => state.intrastat.split;
const renameSendChuncksSelector = state => state.intrastat.renameSendChunk;
const groupFilesSelector = state => state.intrastat.groupFiles;
const filterTagArchivesSelector = state => state.intrastat.filterTagArchives;
const addIntrastatDocumentsSelector = state => state.intrastat.docs;
const deleteIntrastatDocumentsSelector = state => state.intrastat.deleteDoc;
const permsSelector = state => state.intrastat.perms;
const noneCreateIntrastatSelector = state => state.intrastat.none;
const downloadIntrastatSelector = (state) => state.intrastat.download;
const fileStateSelector = (state) => state.intrastat.filesState;
const getIntrastatsByFiltersSelector = (state) => state.intrastat.getByFilters;
const updateDocSelector = state => state.fiscalVat.updateDoc;
const settingsGetSelector = (state) => state.intrastat.getSettings;
const downloadDocsSelector = state => state.fiscalVat.downloadDoc;
const getCategoriesSelector = (state) => state.intrastat.categories;

// Error handler
const errorSelector = state => state.intrastat.error;
const langSelector = state => state.intrastat.lang;


export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  allIntrastatsSelector,
  oneIntrastatSelector,
  getOneIntrastatLoadingSelector,
  getAllIntrastatsLoadingSelector,
  createOneIntrastatLoadingSelector,
  createOneIntrastatSuccessSelector,
  errorSelector,
  getDocsFromCatSelector,
  getDocsFromCatLoadingSelector,
  subscribeSelector,
  unsubscribeSelector,
  companySubscribedSelector,
  companySubscribedLoadingSelector,
  tagSelector,
  tagSuccessSelector,
  tagLoadingSelector,
  getFilesFullListSelector,
  getFilesFullListLoadingSelector,
  getArchiveSelector,
  getArchiveLoadingSelector,
  createSingleFileSelector,
  createSingleFileLoadingSelector,
  filterTagSelector,
  filterTagLoadingSelector,
  archiveAllSelector,
  archiveAllLoadingSelector,
  bulkFilterSelector,
  bulkFilterLoadingSelector,
  splitDocumentSelector,
  splitDocumentLoadingSelector,
  renameSendChuncksSelector,
  renameSendChuncksLoadingSelector,
  renameSendChuncksSuccessSelector,
  groupFilesLoadingSelector,
  groupFilesSelector,
  filterTagArchivesSelector,
  filterTagArchivesLoadingSelector,
  addIntrastatDocumentsSelector,
  addIntrastatDocumentsLoadingSelector,
  deleteIntrastatDocumentsSelector,
  deleteIntrastatDocumentsLoadingSelector,
  permsSelector,
  permsLoadingSelector,
  noneCreateIntrastatSelector,
  noneCreateIntrastatLoadingSelector,
  downloadIntrastatLoadingSelector,
  deleteFilesLoadingSelector,
  fileStateSelector,
  fileStateLoadingSelector,
  fileStateSuccessSelector,
  downloadIntrastatSelector,
  getIntrastatsByFiltersLoadingSelector,
  getIntrastatsByFiltersSelector,
  updateDocLoadingSelector,
  updateDocSelector,
  loadingSettingsSelector,
  settingsGetSelector,
  downloadDocsSelector,
  getCategoriesLoadingSelector,
  getCategoriesSelector,
  langSelector
};
