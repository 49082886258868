import React from 'react';
import {ClickIcon, Wrapper, ClipBoardW, Pointer, PointerHover} from './clipboardStyles';
import { Icon } from 'semantic-ui-react';

const Index = ({target, link, token, onClick}: any) => {

  return (
      <ClipBoardW
        onClick={onClick}
      >                      
        <Wrapper>
          <ClickIcon><strong>{target}</strong>{process.env.REACT_APP_DEV_API_URL}/fiscal/quick-fixes/{link}/proof-management/{token}</ClickIcon>
        </Wrapper>
        <Pointer>
          <Icon name='copy' />   
        </Pointer> 
        <PointerHover className='pointerHover'>Copied to clipboard !</PointerHover>                                                  
      </ClipBoardW>
  );
};

export default Index;
