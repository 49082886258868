import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Container,
  Dimmer,
  Divider,
  Header,
  Step,
  Table,
} from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import LinkButton from 'components/core/LinkButton/LinkButton';
import { Main } from 'components/Layouts';
import NewDeclaration from 'views/Fiscal/Declaration/New';
import EmptyTable from 'components/EmptyTable';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Search from 'components/Search';
import { shortDate } from 'services/helper/dateFormater';

const Index = ({ dispatch, declarations, history, error }) => {
  const [modalStatus, setmodalStatus] = useState(false);
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState(null);
  const [resetSearch, setresetSearch] = useState(false);

  useEffect(() => {
    dispatch(actions.fiscalDeclaration.reset(['error']));
    dispatch(actions.fiscalDeclaration.getDeclarations());
    dispatch(actions.socket.subscribeDeclarations());
    dispatch(actions.socket.subscribeUpdateDeclarations());

    return () => {
      dispatch(actions.fiscalDeclaration.reset(['declarations', 'error']));
      dispatch(actions.socket.unsubscribeDeclarations());
      dispatch(actions.socket.unsubscribeUpdateDeclarations());
    };
  }, [dispatch]);

  useEffect(() => {
    if (declarations !== null)
      //declarations.forEach((e) => (e.date = new Date(e.date)));
    setdata(_.orderBy(declarations, ['id'], ['desc']));
    resetSearch && setresetSearch(false);
  }, [declarations, resetSearch]);

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['id'], ['desc']));
  };

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  return (
    <Main>
      <Divider hidden />
      <Header size='huge'>Declaration dashboard</Header>
      <Container>
        <Wrapper display='flex' justifyContent='space-between'>
          {!error && (
            <Button
              labelPosition='right'
              position='left'
              icon='plus circle'
              type='button'
              content='New declaration'
              onClick={() => setmodalStatus(true)}
            />
          )}
          <Search
            dataSource={declarations}
            onSearchResultChange={onSearchResultChange}
            setResetSearch={setresetSearch}
          />
        </Wrapper>
      </Container>

      <Divider hidden />
      {modalStatus && (
        <NewDeclaration
          open={modalStatus}
          handleClose={() => setmodalStatus(false)}
        />
      )}
      {(declarations === null && (
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      )) || (
        <Container>
          <Table columns='7' sortable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'companyName' ? direction : null}
                  onClick={() => handleSort('companyName')}
                >
                  Company Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'date' ? direction : 'ascending'}
                  onClick={() => handleSort('date')}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                  Declaration Country
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'workshop' ? direction : null}
                  onClick={() => handleSort('workshop')}
                >
                  Workshop
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'emitter' ? direction : null}
                  onClick={() => handleSort('emitter')}
                >
                  Emitter
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'state' ? direction : null}
                  onClick={() => handleSort('state')}
                >
                  State
                </Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data === null && <EmptyTable colNumber={7} />) ||
                data.map((e, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{e.client}</Table.Cell>
                      <Table.Cell>{shortDate(new Date(e.date))}</Table.Cell>
                      <Table.Cell>{e.declaration_country}</Table.Cell>
                      <Table.Cell>{e.workshop}</Table.Cell>
                      <Table.Cell>{e.emitter}</Table.Cell>
                      <Table.Cell>
                        <Step.Group size='small'>
                          <Step>{e.state}</Step>
                        </Step.Group>
                      </Table.Cell>
                      <Table.Cell>
                        <LinkButton
                          disabled={
                            e.state === 'Finished' ||
                            e.state === 'Validated' ||
                            e.state === 'Correction necessary' ||
                            e.state === 'Not validated'
                              ? false
                              : true
                          }
                          content='Details'
                          color='blue'
                          path={`/fiscal/declaration/${e.client}/${e.id}`}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Container>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    declarations: selectors.fiscalDeclaration.declarationsSelector(state),
    error: selectors.message.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
