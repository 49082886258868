import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Container,
  Divider,
  Header,
  Table,
  Grid,
  Segment,
  Icon,
  Popup,
  Message,
  GridColumn,
} from 'semantic-ui-react';

import DeliveryDetails from './DeliveryDetails';
import Loader from 'components/Loader';
import Seller from './Seller';
import Buyer from './Buyer';
import Transporter from './Transporter';
import ValuesDetails from './ValuesDetails/ValuesDetails';
import RequestReceiptValidation from './RequestReceiptValidation';
import ButtonValidationModalNoWrapper from 'components/ButtonValidationModalNoWrapper';
import { actions, selectors } from 'store';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Checkbox from 'components/Checkbox';
import { paths } from 'routes/fiscal/kyanite';

const Index = ({
  history,
  dispatch,
  values,
  setFieldValue,
  vatRisk,
  companyId,
  company,
  errorHandlerQuickfixes,
  loadingHandlerQuickfixes,
  user,
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const isSuperOperatorCompany = 49
  const [displayedVatRisk, setDisplayedVatRisk] = useState(null);
  const [deliveryCreationStarted, setDeliveryCreationStarted] = useState(false);
  const [deliveryCreationLoader, setDeliveryCreationLoader] = useState(false);
  const [requestValidationModal, setRequestValidationModal] = useState(false);
  const [missingData, setMissingData] = useState(false);
  const [buyerMail, setBuyerMail] = useState(false);
  const [transporterMail, setTransporterMail] = useState(false);

  const deliveryDetailsFieldsNames = {
    ref: 'deliveryInfos.deliveryRef',
    from: 'deliveryInfos.departureCountry',
    to: 'deliveryInfos.arrivalCountry',
    transporterInCharge: 'transport.shippingAgent.agentSelected',
    invoiceNumbers: 'deliveryInfos.invoiceNumbers',
    customsRegime: 'deliveryInfos.customsRegime',
    imaNumber: 'deliveryInfos.imaNumber',
    cmr: 'deliveryInfos.cmr',
    customsDeclarationId: 'deliveryInfos.customsDeclarationId',
  };
  const sellerFieldsNames = {
    name: 'seller.name',
    addressCountry: 'seller.country',
    addressCity: 'seller.city',
    address1: 'seller.address',
    email: 'seller.email',
    phone: 'seller.phone',
    vat: 'seller.vat',
    signature: 'seller.signature',
  };
  const buyerFieldsNames = {
    name: 'transport.shippingAgent.receiverName',
    addressCountry: 'transport.shippingAgent.addressCountry',
    addressCity: 'transport.shippingAgent.addressCity',
    address1: 'transport.shippingAgent.address1',
    email: 'transport.shippingAgent.email',
    phone: 'transport.shippingAgent.phone',
    vat: 'transport.shippingAgent.vat',
    signature: 'transport.shippingAgent.signature',
  };
  const transporterFieldsNames = {
    name: 'transport.transporter.name',
    email: 'transport.transporter.email',
    phone: 'transport.transporter.phone',
    vat: 'transport.transporter.vat',
    signature: 'transport.transporter.signature',
  };

  const valuesFieldsNames = {
    packageMethod: 'deliveryValue.packageMethod',
    packageNumber: 'deliveryValue.packageNumber',
    natureOfGoods: 'deliveryValue.natureOfGoods',
    grossWeight: 'deliveryValue.grossWeight',
    grossWeightUnit: 'deliveryValue.grossWeightUnit',
    volume: 'deliveryValue.volume',
    volumeUnit: 'deliveryValue.volumeUnit',
    value: 'deliveryValue.value',
  };

  const isTransporterDataOk = (belgiqueOK?) => {
    return !belgiqueOK ? (values.transport.shippingAgent.agent &&
      values.transport.shippingAgent.agent === companyId
      ? values.transporterId
        ? true
        : true // ????
      : true) : true; // end belge
  };

  const saveDelivery = () => {

    const belgiqueOK = 
        company.Subscriptions && 
        company.Subscriptions.length>0 && 
        company.Subscriptions[0].Products && 
        company.Subscriptions[0].Products.length>0 && 
        company.Subscriptions[0].Products[0].subscription === true && 
        company.Subscriptions[0].Products[0].SubscriptionProducts.periodTo !== ""

    const belgique = belgiqueOK ? {
      buyer: company.id,
      shippingAgent: company.id,
      buyerVatId: values.seller.vatId,
      buyerAddress: values.seller.addressId,
      transporterVat: values.seller.vatId 
    } : null

    const customsRefs = values.deliveryInfos.customsDeclarationId.ref === '' ? 
                      {id: values.deliveryInfos.customsDeclarationId.id, ref: 'None'} : 
                      values.deliveryInfos.customsDeclarationId 

    // ajustements pour belgique //
    dispatch(actions.quickfixes.reset(['error']));
    values.deliveryInfos.deliveryRef &&
    values.deliveryInfos.arrivalCountry &&
    values.deliveryInfos.departureCountry &&
    values.deliveryValue.value &&
    values.deliveryValue.packageMethod &&
    values.deliveryValue.packageNumber &&
    values.deliveryValue.natureOfGoods &&
    values.deliveryValue.grossWeight &&
    values.seller.vatId &&
    //values.transport.shippingAgent.receiverId &&
    //values.transport.shippingAgent.agent &&
    //values.transport.shippingAgent.receiverAddressId &&
    isTransporterDataOk(belgiqueOK)
      ? dispatch(
          actions.quickfixes.create({
            params: { companyId },
            data: {
              reference: values.deliveryInfos.deliveryRef,
              customsRegime: values.deliveryInfos.customsRegime,
              imaNumber: values.deliveryInfos.imaNumber,
              cmr: values.deliveryInfos.cmr,
              customsDeclarationId: customsRefs, // new GB
              invoiceNumbers: values.deliveryInfos.invoiceNumbers,
              arrivalCountry: values.deliveryInfos.arrivalCountry,
              departureCountry: values.deliveryInfos.departureCountry,
              totalHT: values.deliveryValue.value,
              packageMethod: values.deliveryValue.packageMethod,
              packageNumber: values.deliveryValue.packageNumber,
              natureOfGoods: values.deliveryValue.natureOfGoods,
              grossWeight: values.deliveryValue.grossWeight,
              grossWeightUnit: values.deliveryValue.grossWeightUnit,
              volume: values.deliveryValue.volume,
              volumeUnit: values.deliveryValue.volumeUnit,
              buyer: values.transport.shippingAgent.receiverId || (belgique && belgique.buyer), //new GB
              shippingAgent: parseInt(values.transport.shippingAgent.agent) || (belgique && belgique.shippingAgent), //new GB 
              estimatedTransportCost:
                values.transport.shippingAgent.estimatedDeliveryPrice,
              estimatedTransportDevise: values.transport.shippingAgent.currency,
              transporter: values.transporterId || (belgique && belgique.shippingAgent), // //new GB
              sellerVat: values.seller.vatId,
              buyerVat: values.transport.shippingAgent.vatId || (belgique && belgique.buyerVatId) || values.seller.vatId, //new GB 
              buyerAddress: values.transport.shippingAgent.receiverAddressId || (belgique && belgique.buyerAddress), //new GB
              transporterVat: values.transport.transporter.vatId || (belgique && belgique.transporterVat) || null, //new GB
              buyerMail,
              transporterMail,
            },
          })
        ) && setDeliveryCreationStarted(true)
      : setMissingData(true);
    
  };

  useEffect(() => {
    if (company) {
      setFieldValue('seller.name', company.name);
      setFieldValue('seller.email', company.email);
      setFieldValue('seller.phone', company.phoneNumber1 || '');
      company.Addresses.forEach((address) => {
        if (address.headquarters === true) {
          setFieldValue('seller.addressId', address.id);
          setFieldValue('seller.country', address.country);
          setFieldValue('seller.city', address.city);
          setFieldValue('seller.address', address.address1);
        }
      });
    }
  }, [company, setFieldValue]);

  useEffect(() => {
    dispatch(actions.quickfixes.reset(['error']));
    values.deliveryInfos.departureCountry !== '' &&
      values.deliveryValue.value != null &&
      dispatch(
        actions.quickfixes.getVatRisk({
          params: { companyId: companyId },
          data: {
            country: values.deliveryInfos.departureCountry,
            ht: values.deliveryValue.value,
          },
        })
      );

    return () => {
      dispatch(actions.quickfixes.reset(['vatRisk', 'error']));
    };
  }, [dispatch, values, companyId]);

  useEffect(() => {
    vatRisk !== null &&
      vatRisk !== undefined &&
      values.deliveryInfos.departureCountry !== '' &&
      setDisplayedVatRisk(vatRisk);
  }, [vatRisk, values]);

  useEffect(() => {
    if (deliveryCreationStarted) {
      if (loadingHandlerQuickfixes) {
        setDeliveryCreationLoader(true);
      } else {
        setDeliveryCreationLoader(false);
        if (errorHandlerQuickfixes.includes('create')) {
          setDeliveryCreationStarted(false);
        } else {
          setDeliveryCreationStarted(false);
          history.push(
            userAdminOrOp
              ? paths.kyaniteOperator.replace(':companyId', companyId)
              : paths.quickFixes
          );
        }
      }
    }
  }, [loadingHandlerQuickfixes, companyId, deliveryCreationStarted, errorHandlerQuickfixes, history, userAdminOrOp]);

  return (
    (deliveryCreationLoader === true && <Loader />) || (
      <Container fluid>
        {requestValidationModal === true && (
          <RequestReceiptValidation
            isModalOpen={requestValidationModal}
            setIsModalOpen={setRequestValidationModal}
            handleClose={() => setRequestValidationModal(false)}
            history={history}
            setFieldValue={setFieldValue}
          />
        )}
        <Container fluid>
        
          <Segment size='small'>
            <Grid columns={5}>
              <Grid.Row centered>
                <Grid.Column mobile={16} tablet={8} computer={3}>
                  <Header textAlign='center' size='medium'>
                    Delivery
                  </Header>
                  <DeliveryDetails
                    names={deliveryDetailsFieldsNames}
                    invoiceNumbers={values.deliveryInfos.invoiceNumbers}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={3}>
                  <Header textAlign='center' size='medium'>
                    Seller
                  </Header>
                  <Seller names={sellerFieldsNames} isSuperOperatorCompany={isSuperOperatorCompany}/>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={3}>
                  <Header textAlign='center' size='medium'>
                    Buyer
                  </Header>
                  <Buyer names={buyerFieldsNames} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={3}>
                  <Header textAlign='center' size='medium'>
                    Transporter
                  </Header>
                  <Transporter values={values} names={transporterFieldsNames} />
                </Grid.Column>
                <GridColumn mobile={16} tablet={8} computer={3}>
                  <Header textAlign='center' size='medium'>
                    Values
                  </Header>
                  <ValuesDetails values={values} names={valuesFieldsNames} />
                </GridColumn>
              </Grid.Row>
            </Grid>
          </Segment>




          <Divider hidden />
          <Divider hidden />
          <Wrapper width='50%' margin='auto'>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Delivery goods total</Table.HeaderCell>

                  <Table.HeaderCell>
                    VAT risk estimation
                    <Popup
                      trigger={<Icon name='info circle' />}
                      content='Estimation of the VAT risk on the basis of the standard VAT rate of the country of departure, excluding penalties and interest for late payment.'
                    />
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{`${values.deliveryValue.value} €`}</Table.Cell>
                  {displayedVatRisk !== null ? (
                    <Table.Cell>{`${displayedVatRisk} €`}</Table.Cell>
                  ) : (
                    <Table.Cell>{`VAT risk undefined`}</Table.Cell>
                  )}
                </Table.Row>
              </Table.Header>
            </Table>
          </Wrapper>
          <Divider hidden />
          {missingData && (
            <Message negative>
              Some data is missing, check the information entered.
            </Message>
          )}
          <ButtonValidationModalNoWrapper
            trgrContent='Save delivery'
            headerContent='Save delivery?'
            modalContent={
              <div>
                <p>
                  Do you want to invite your contacts to upload proofs of
                  delivery directly into the system ?
                </p>
                <Checkbox
                  name='buyerEmail'
                  label='Send an email to the buyer'
                  checked={buyerMail}
                  onChange={() => setBuyerMail(!buyerMail)}
                />
                {values.transporterId && (
                  <Checkbox
                    name='transporterEmail'
                    label='Send an email to the transporter'
                    checked={transporterMail}
                    onChange={() => setTransporterMail(!transporterMail)}
                  />
                )}
              </div>
            }
            trgrColor='green'
            trgrIcon='check'
            headerIcon='check'
            onClick={() => {
              saveDelivery();
            }}
            margin='5px'
          />
          <ButtonValidationModalNoWrapper
            trgrContent='Cancel'
            headerContent='Cancel the delivery'
            modalContent='Are you sure you want to cancel the creation of this delivery?'
            trgrColor='red'
            trgrIcon='trash'
            headerIcon='trash'
            onClick={() => {
              history.push(
                userAdminOrOp
                  ? paths.kyaniteOperator.replace(':companyId', companyId)
                  : paths.quickFixes
              );
            }}
            margin='5px'
          />
        </Container>
      </Container>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    vatRisk: selectors.quickfixes.vatRiskSelector(state),
    errorHandlerQuickfixes: selectors.quickfixes.errorSelector(state),
    loadingHandlerQuickfixes: selectors.quickfixes.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
