import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    registerIntegration: null,
    getCF: null,
    getIntegrations: null,
    subscribe: null,
    unsubscribe: null,
    getCompanySubscribed: null,
  },
  errors: {
    registerIntegration: null,
    getCF: null,
    getIntegrations: null,
    subscribe: null,
    unsubscribe: null,
    getCompanySubscribed: null,
  },
  loading: {
    registerIntegration: false,
    getCF: false,
    getIntegrations: false,
    subscribe: false,
    unsubscribe: false,
    getCompanySubscribed: false,
  },

  integrations: null,
  cf: null,
  companySubscribed: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // getCompanySubscribed
    case actionTypes.GET_COMPANY_SUBSCRIBED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getCompanySubscribed = true;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = null;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = true;
        draft.companySubscribed = action.payload;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = true;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = false;
        !draft.error.includes('companySubscribed') &&
          draft.error.push('companySubscribed');
      });

    //Subscribe
    case actionTypes.SUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.subscribe = null;
        draft.loading.subscribe = false;
        draft.success.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.subscribe = true;
        draft.loading.subscribe = false;
        draft.success.subscribe = false;
      });

    //Unsubscribe
    case actionTypes.UNSUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = null;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = true;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = false;
      });

    // registerIntegration
    case actionTypes.REGISTER_INTEGRATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.registerIntegration = true;
      });
    case actionTypes.REGISTER_INTEGRATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.registerIntegration = null;
        draft.loading.registerIntegration = false;
        draft.success.registerIntegration = true;
      });
    case actionTypes.REGISTER_INTEGRATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.registerIntegration = true;
        draft.loading.registerIntegration = false;
        draft.success.registerIntegration = false;
      });

    // getCF
    case actionTypes.GET_CF_LOADING:
      return produce(state, (draft) => {
        draft.loading.getCF = true;
      });
    case actionTypes.GET_CF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getCF = null;
        draft.loading.getCF = false;
        draft.success.getCF = true;
        draft.cf = action.payload;
      });
    case actionTypes.GET_CF_ERROR:
      return produce(state, (draft) => {
        draft.errors.getCF = true;
        draft.loading.getCF = false;
        draft.success.getCF = false;
        !draft.error.includes('CF') && draft.error.push('CF');
      });

    // getIntegrations
    case actionTypes.GET_OSS_INTEGRATIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getIntegrations = true;
      });
    case actionTypes.GET_OSS_INTEGRATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getIntegrations = null;
        draft.loading.getIntegrations = false;
        draft.success.getIntegrations = true;
        draft.integrations = action.payload;
      });
    case actionTypes.GET_OSS_INTEGRATIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getIntegrations = true;
        draft.loading.getIntegrations = false;
        draft.loading.getIntegrations = false;
        !draft.error.includes('Integrations') &&
          draft.error.push('Integrations');
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
