import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  getUnpaid(data: dataReqI) {
    return {
      type: actionTypes.GET_UNPAID,
      payload: data,
    };
  },
  getAll(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL,
      payload: data,
    };
  },
  getOne(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE,
      payload: data,
    };
  },

  payInvoice(data: dataReqI) {
    return {
      type: actionTypes.PAY_INVOICE,
      payload: data,
    };
  },

  createCredit(data: dataReqI) {
    return {
      type: actionTypes.CREATE_CREDIT,
      payload: data,
    };
  },

  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
