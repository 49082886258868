import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const addKeyInfoErrorSelector = (state) => 
state.companyKeyInfo.errors.addKeyInfo;
const removeKeyInfoErrorSelector = (state) => 
state.companyKeyInfo.errors.removeKeyInfo;
const updateKeyInfoErrorSelector = (state) =>
state.companyKeyInfo.errors.updateKeyInfo;
const getOneKeyInfoErrorSelector = (state) => 
  state.companyKeyInfo.errors.getOneKeyInfo;
const getKeyInfosErrorSelector = (state) =>
  state.companyKeyInfo.errors.getKeyInfos;
  const checkKeyInfosErrorSelector = (state) =>
  state.companyKeyInfo.errors.checkkeyInfoSetDone;

const errorsSelector = createErrorsSelector([
  addKeyInfoErrorSelector,
  removeKeyInfoErrorSelector,
  updateKeyInfoErrorSelector,
  getOneKeyInfoErrorSelector,
  getKeyInfosErrorSelector,
  checkKeyInfosErrorSelector,
]);

// Loading
const addKeyInfoLoadingSelector = (state) => 
  state.companyKeyInfo.loading.addKeyInfo;
const removeKeyInfoLoadingSelector = (state) =>
  state.companyKeyInfo.loading.removeKeyInfo;
const updateKeyInfoLoadingSelector = (state) =>
  state.companyKeyInfo.loading.updateKeyInfo;
const getOneKeyInfoLoadingSelector = (state) =>
  state.companyKeyInfo.loading.getOneKeyInfo;
const getKeyInfosLoadingSelector = (state) =>
  state.companyKeyInfo.loading.getKeyInfos;
const checkKeyInfosLoadingSelector = (state) =>
  state.companyKeyInfo.loading.checkkeyInfoSetDone;

const loadingSelector = createLoadingSelector([
  addKeyInfoLoadingSelector,
  removeKeyInfoLoadingSelector,
  updateKeyInfoLoadingSelector,
  getOneKeyInfoLoadingSelector,
  getKeyInfosLoadingSelector,
  checkKeyInfosLoadingSelector
]);

// Success
const addKeyInfoSuccessSelector = (state) => 
  state.companyKeyInfo.success.addKeyInfo;
const removeKeyInfoSuccessSelector = (state) =>
  state.companyKeyInfo.success.removeKeyInfo;
const updateKeyInfoSuccessSelector = (state) =>
  state.companyKeyInfo.success.updateKeyInfo;
const getOneKeyInfoSuccessSelector = (state) =>
  state.companyKeyInfo.success.getOneKeyInfo;
const getKeyInfosSuccessSelector = (state) =>
  state.companyKeyInfo.success.getKeyInfos;
const checkKeyInfosSuccessSelector = (state) =>
  state.companyKeyInfo.success.checkkeyInfoSetDone;

const successSelector = createSuccessSelector([
  addKeyInfoSuccessSelector,
  removeKeyInfoSuccessSelector,
  updateKeyInfoSuccessSelector,
  getKeyInfosSuccessSelector,
  getOneKeyInfoSuccessSelector,
  checkKeyInfosSuccessSelector,
]);

const getOneKeyInfoSelector = (state) => 
  state.companyKeyInfo.getOneKeyInfo;
const getKeyInfosSelector = (state) => 
  state.companyKeyInfo.getKeyInfos;
const checkKeyInfosSelector = (state) =>
  state.companyKeyInfo.checkkeyInfoSetDone;

const errorSelector = (state) => state.companyKeyInfo.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  checkKeyInfosSelector,
  checkKeyInfosLoadingSelector,
  addKeyInfoLoadingSelector,
  updateKeyInfoLoadingSelector,
  getKeyInfosSelector,
  getKeyInfosLoadingSelector,
  getOneKeyInfoSelector,
  errorSelector,
};
