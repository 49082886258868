import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const Index = (props) => {
  return (
    <Dimmer>
      <Loader active inline='centered' />
    </Dimmer>
  );
};

export default Index;
