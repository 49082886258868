import React, { useState } from 'react';
import { useField } from '@formiz/core';
import { Popup, Icon } from 'semantic-ui-react';
import { DropdownCustom as Dropdown } from './styles';
import Wrapper from 'components/core/Wrapper/Wrapper';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import InputErrorLabel from 'components/core/InputErrorLabel/InputErrorLabel';

const FormizDropdown = (props: FormizDropdownProps) => {
  const [isTouched, setIsTouched] = useState(false);
  const { errorMessage, isValid, isSubmitted, setValue, value } = useField(
    props
  );
  const {
    name,
    placeholder,
    disabled,
    label,
    options,
    onChange,
    loading,
    multiple = false,
    mandatory = false,
    bulleted = false,
    bullMsg,
    bullAlert = false,
  } = props;
  const showError = !isValid && (isTouched || isSubmitted);
  const error = showError && errorMessage;

  return (
    <Wrapper>
      <label>
        {label}
        {mandatory && <MandatoryFieldLabel />}
        {bulleted && (
          <Wrapper display='flex' float='right'>
            <Popup
              trigger={
                <Icon name='info circle' {...(bullAlert && { color: 'red' })} />
              }
              content={bullMsg}
              size='mini'
            />
          </Wrapper>
        )}
      </label>
      <Dropdown
        disabled={disabled}
        loading={loading}
        name={name}
        multiple={multiple}
        clearable
        fluid
        search
        selection
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={(e, { name, value }) => {
          if (onChange) onChange(value);
          setValue(value);
        }}
        onBlur={() => setIsTouched(true)}
        error={showError && errorMessage}
      />
      {error && <InputErrorLabel message={error} />}
    </Wrapper>
  );
};

export interface FormizDropdownProps {
  name: string;
  type?: string;
  options: object[];
  value?: string;
  disabled?: true;
  placeholder?: string;
  label?: string;
  required?: string;
  loading?: false;
  multiple?: boolean;
  mandatory?: boolean;
  bulleted?: boolean;
  bullMsg?: string;
  bullAlert?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
}

export default FormizDropdown;
