import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const reportBugErrorSelector = (state) => state.admin.errors.reportBug;
const getPermissionsErrorSelector = (state) =>
  state.admin.errors.getPermissions;

const errorsSelector = createErrorsSelector([
  reportBugErrorSelector,
  getPermissionsErrorSelector,
]);

// Loading
const reportBugLoadingSelector = (state) => state.admin.loading.reportBug;
const getPermissionsLoadingSelector = (state) =>
  state.admin.loading.getPermissions;

const loadingSelector = createLoadingSelector([
  reportBugLoadingSelector,
  getPermissionsLoadingSelector,
]);

// Success
const reportBugSuccessSelector = (state) => state.admin.success.reportBug;
const getPermissionsSuccessSelector = (state) =>
  state.admin.success.getPermissions;

const successSelector = createSuccessSelector([
  reportBugSuccessSelector,
  getPermissionsSuccessSelector,
]);

//Errors Handlers
const errorSelector = (state) => state.admin.error;

//Permissions
const permissionsSelector = (state) => state.admin.permissions;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  errorSelector,
  permissionsSelector,
};
