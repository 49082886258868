import React, { FunctionComponent } from 'react';

import { SpanStyled } from './SpanStyles';

type SpanProps = {
  children: any;
  whiteSpace?: string;
};

const Span: FunctionComponent<SpanProps> = ({ children, whiteSpace }) => {
  return <SpanStyled whiteSpace={whiteSpace}>{children}</SpanStyled>;
};

export default Span;
