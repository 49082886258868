import styled from "@emotion/styled";
import { Input } from "semantic-ui-react";

export const Wrapper = styled.div(() => ({}));
export const InputCustom = styled(Input)`
  &&& input {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
  }

  &&& input {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
  }

  &&&.disabled {
    opacity: 1;
  }
`;