import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Tab, Table } from 'semantic-ui-react';
import { selectors } from 'store';

import ButtonValidationModal from 'components/ButtonValidationModal';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';

const pane = {'border': 'none', 'padding-left': '0', 'padding-right': '0'}

const Index = ({ contact, loadingHandlerContact, handleDelete }) => {
  return (
    <Tab.Pane loading={loadingHandlerContact} style={pane}>
      {(loadingHandlerContact && <Loader />) || (
        <>
          {contact && (
            <>
              <Table basic columns='2'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <b>Name:</b>
                    </Table.Cell>
                    {contact && <Table.Cell>{contact.name}</Table.Cell>}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <b>Email:</b>
                    </Table.Cell>
                    {contact && <Table.Cell>{contact.email}</Table.Cell>}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <b>Phone:</b>
                    </Table.Cell>
                    {contact && contact.phoneNumber ? (
                      <Table.Cell>{contact.phoneNumber}</Table.Cell>
                    ) : (
                      <Table.Cell>
                        Phone number hasn't been filled in.
                      </Table.Cell>
                    )}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <b>Country:</b>
                    </Table.Cell>
                    {contact && <Table.Cell>{contact.country}</Table.Cell>}
                  </Table.Row>
                </Table.Body>
              </Table>

              <Wrapper padding='0.5rem'>
                {contact && (
                  <ButtonValidationModal
                    trgrContent='Delete'
                    headerContent='Delete'
                    modalContent={`Do you confirm removing ${contact.name} from your contacts?`}
                    trgrColor='red'
                    trgrIcon='trash'
                    headerIcon='trash'
                    onClick={() => {
                      handleDelete(contact.id);
                    }}
                    margin='5px'
                  />
                )}
              </Wrapper>
            </>
          )}
        </>
      )}
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    userPermissions: selectors.companyUser.userPermissionsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
