let actionTypes = {
  GET_ALL_CONTACTS: "MY_ASD.CONTACT.GET_ALL_CONTACTS",
  GET_ALL_CONTACTS_LOADING: "MY_ASD.CONTACT.GET_ALL_CONTACTS_LOADING",
  GET_ALL_CONTACTS_SUCCESS: "MY_ASD.CONTACT.GET_ALL_CONTACTS_SUCCESS",
  GET_ALL_CONTACTS_ERROR: "MY_ASD.CONTACT.GET_ALL_CONTACTS_ERROR",

  GET_ONE_CONTACT: "MY_ASD.CONTACT.GET_ONE_CONTACT",
  GET_ONE_CONTACT_LOADING: "MY_ASD.CONTACT.GET_ONE_CONTACT_LOADING",
  GET_ONE_CONTACT_SUCCESS: "MY_ASD.CONTACT.GET_ONE_CONTACT_SUCCESS",
  GET_ONE_CONTACT_ERROR: "MY_ASD.CONTACT.GET_ONE_CONTACT_ERROR",

  CREATE_CONTACT: "MY_ASD.CONTACT.CREATE_CONTACT",
  CREATE_CONTACT_LOADING: "MY_ASD.CONTACT.CREATE_CONTACT_LOADING",
  CREATE_CONTACT_SUCCESS: "MY_ASD.CONTACT.CREATE_CONTACT_SUCCESS",
  CREATE_CONTACT_ERROR: "MY_ASD.CONTACT.CREATE_CONTACT_ERROR",

  ADD_CONTACT: "MY_ASD.CONTACT.ADD_CONTACT",
  ADD_CONTACT_LOADING: "MY_ASD.CONTACT.ADD_CONTACT_LOADING",
  ADD_CONTACT_SUCCESS: "MY_ASD.CONTACT.ADD_CONTACT_SUCCESS",
  ADD_CONTACT_ERROR: "MY_ASD.CONTACT.ADD_CONTACT_ERROR",

  ADD_CONTACT_PARTNER: "MY_ASD.CONTACT.ADD_CONTACT_PARTNER",
  ADD_CONTACT_PARTNER_LOADING: "MY_ASD.CONTACT.ADD_CONTACT_PARTNER_LOADING",
  ADD_CONTACT_PARTNER_SUCCESS: "MY_ASD.CONTACT.ADD_CONTACT_PARTNER_SUCCESS",
  ADD_CONTACT_PARTNER_ERROR: "MY_ASD.CONTACT.ADD_CONTACT_PARTNER_ERROR",

  VALIDATE_CONTACT_PARTNER: "MY_ASD.CONTACT.VALIDATE_PARTNER_CONTACT",
  VALIDATE_CONTACT_PARTNER_LOADING: "MY_ASD.CONTACT.VALIDATE_CONTACT_PARTNER_LOADING",
  VALIDATE_CONTACT_PARTNER_SUCCESS: "MY_ASD.CONTACT.VALIDATE_CONTACT_PARTNER_SUCCESS",
  VALIDATE_CONTACT_PARTNER_ERROR: "MY_ASD.CONTACT.VALIDATE_CONTACT_PARTNER_ERROR",

  DELETE_CONTACT: "MY_ASD.CONTACT.DELETE_CONTACT",
  DELETE_CONTACT_LOADING: "MY_ASD.CONTACT.DELETE_CONTACT_LOADING",
  DELETE_CONTACT_SUCCESS: "MY_ASD.CONTACT.DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_ERROR: "MY_ASD.CONTACT.DELETE_CONTACT_ERROR",

  VALIDATE_CONTACT: "MY_ASD.CONTACT.VALIDATE_CONTACT",
  VALIDATE_CONTACT_LOADING: "MY_ASD.CONTACT.VALIDATE_CONTACT_LOADING",
  VALIDATE_CONTACT_SUCCESS: "MY_ASD.CONTACT.VALIDATE_CONTACT_SUCCESS",
  VALIDATE_CONTACT_ERROR: "MY_ASD.CONTACT.VALIDATE_CONTACT_ERROR",

  REFUSE_CONTACT: "MY_ASD.CONTACT.REFUSE_CONTACT",
  REFUSE_CONTACT_LOADING: "MY_ASD.CONTACT.REFUSE_CONTACT_LOADING",
  REFUSE_CONTACT_SUCCESS: "MY_ASD.CONTACT.REFUSE_CONTACT_SUCCESS",
  REFUSE_CONTACT_ERROR: "MY_ASD.CONTACT.REFUSE_CONTACT_ERROR",

  GET_COMPANIES_NOT_IN_CONTACT: "MY_ASD.CONTACT.GET_COMPANIES_NOT_IN_CONTACT",
  GET_COMPANIES_NOT_IN_CONTACT_LOADING: "MY_ASD.CONTACT.GET_COMPANIES_NOT_IN_CONTACT_LOADING",
  GET_COMPANIES_NOT_IN_CONTACT_SUCCESS: "MY_ASD.CONTACT.GET_COMPANIES_NOT_IN_CONTACT_SUCCESS",
  GET_COMPANIES_NOT_IN_CONTACT_ERROR: "MY_ASD.CONTACT.GET_COMPANIES_NOT_IN_CONTACT_ERROR",

  GET_PENDING_CONTACT: "MY_ASD.CONTACT.GET_PENDING_CONTACT",
  GET_PENDING_CONTACT_LOADING: "MY_ASD.CONTACT.GET_PENDING_CONTACT_LOADING",
  GET_PENDING_CONTACT_SUCCESS: "MY_ASD.CONTACT.GET_PENDING_CONTACT_SUCCESS",
  GET_PENDING_CONTACT_ERROR: "MY_ASD.CONTACT.GET_PENDING_CONTACT_ERROR",

  GET_REQUEST_CONTACT: "MY_ASD.CONTACT.GET_REQUEST_CONTACT",
  GET_REQUEST_CONTACT_LOADING: "MY_ASD.CONTACT.GET_REQUEST_CONTACT_LOADING",
  GET_REQUEST_CONTACT_SUCCESS: "MY_ASD.CONTACT.GET_REQUEST_CONTACT_SUCCESS",
  GET_REQUEST_CONTACT_ERROR: "MY_ASD.CONTACT.GET_REQUEST_CONTACT_ERROR",

  GET_ALL_CONTACT_TRANSPORTERS: "MY_ASD.CONTACT.GET_ALL_CONTACT_TRANSPORTERS",
  GET_ALL_CONTACT_TRANSPORTERS_LOADING: "MY_ASD.CONTACT.GET_ALL_CONTACT_TRANSPORTERS_LOADING",
  GET_ALL_CONTACT_TRANSPORTERS_SUCCESS: "MY_ASD.CONTACT.GET_ALL_CONTACT_TRANSPORTERS_SUCCESS",
  GET_ALL_CONTACT_TRANSPORTERS_ERROR: "MY_ASD.CONTACT.GET_ALL_CONTACT_TRANSPORTERS_ERROR",

  ADD_CONTACT_RIGHTS: "MY_ASD.CONTACT.ADD_CONTACT_RIGHTS",
  ADD_CONTACT_RIGHTS_LOADING: "MY_ASD.CONTACT.ADD_CONTACT_RIGHTS_LOADING",
  ADD_CONTACT_RIGHTS_SUCCESS: "MY_ASD.CONTACT.ADD_CONTACT_RIGHTS_SUCCESS",
  ADD_CONTACT_RIGHTS_ERROR: "MY_ASD.CONTACT.ADD_CONTACT_RIGHTS_ERROR",

  GET_CONTACT_RIGHTS: "MY_ASD.CONTACT.GET_CONTACT_RIGHTS",
  GET_CONTACT_RIGHTS_LOADING: "MY_ASD.CONTACT.GET_CONTACT_RIGHTS_LOADING",
  GET_CONTACT_RIGHTS_SUCCESS: "MY_ASD.CONTACT.GET_CONTACT_RIGHTS_SUCCESS",
  GET_CONTACT_RIGHTS_ERROR: "MY_ASD.CONTACT.GET_CONTACT_RIGHTS_ERROR",

  GET_ALL_CONTACT_RIGHTS: "MY_ASD.CONTACT.GET_ALL_CONTACT_RIGHTS",
  GET_ALL_CONTACT_RIGHTS_LOADING: "MY_ASD.CONTACT.GET_ALL_CONTACT_RIGHTS_LOADING",
  GET_ALL_CONTACT_RIGHTS_SUCCESS: "MY_ASD.CONTACT.GET_ALL_CONTACT_RIGHTS_SUCCESS",
  GET_ALL_CONTACT_RIGHTS_ERROR: "MY_ASD.CONTACT.GET_ALL_CONTACT_RIGHTS_ERROR",

  GET_BOTH_CONTACTS: "MY_ASD.CONTACT.GET_BOTH_CONTACTS",
  GET_BOTH_CONTACTS_LOADING: "MY_ASD.CONTACT.GET_BOTH_CONTACTS_LOADING",
  GET_BOTH_CONTACTS_SUCCESS: "MY_ASD.CONTACT.GET_BOTH_CONTACTS_SUCCESS",
  GET_BOTH_CONTACTS_ERROR: "MY_ASD.CONTACT.GET_BOTH_CONTACTS_ERROR",

  RESET: "MY_ASD.CONTACT.RES",
  REFRESH_ERROR: "MY_ASD.CONTACT.REFRESH_ERROR"
};

export default actionTypes;
