import styled from "@emotion/styled";
import { Dropdown, Form } from "semantic-ui-react";

export const Wrapper = styled.div(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around"
}));

export const Field = styled(Form.Field)`
  &&& {
    margin: 0;
    display: inline-block;
  }
`;

export const DropDownVat = styled(Dropdown)`
  border-radius: 10px !important;
  margin: 0 0 0 10px;
`;

export const Followers = styled.ul`
    margin: 0;
    padding: 0;
`

export const FList = styled.li`
    margin: 0;
    padding: 0;
`


export const State =  styled.div`
    margin: 0;
    padding: 0.5em 1em;
    line-height: 1em;
    color: white;
    float: left;
    font-weight: bold;
    font-size: 12px;
    border-radius: 0.5em;
    background-color: ${props => props.defaultValue}
  `

  export const Period =  styled.div`
    border: 1px solid rgb(212, 212, 213);
    background: #fff;
    margin: 0 1rem 0 0;
    padding: 0.5em 1em;
    line-height: 1em;
    float: left;
    font-weight: bold;
    font-size: 12px;
    border-radius: 0.5em;
  `

  export const Spacer =  styled.div`
    padding-left: 5px;
    float: right;
    &&:before {
      content: '-';
      float: left;
      padding-right: 5px;
    }
  `

  export const header_sub_css = {'display': 'flex', 'alignItems': 'center', 'justify-content': 'center'}
  export const HeaderSub = styled.div`
    float: none;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    padding: 5px 15px;
    margin: 0 20px;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 5px;
    background: white;
  `

  export const WrapperSelectors = styled.div`
    float: left;
    margin: 0 0 0 10px;
    padding: 5px;
    border: 1px solid;
    border-color: rgba(34, 36, 38, 0.15);
    border-radius: 1rem;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    background-color: #ffffff;
  `

