import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const subscribeErrorSelector = (state) => 
state.quickfixes.errors.subscribe;
const subscribeWithCodeErrorSelector = (state) =>
  state.quickfixes.errors.subscribeWithCode;
const unsubscribeErrorSelector = (state) => 
state.quickfixes.errors.unsubscribe;
const getAllErrorSelector = (state) => 
state.quickfixes.errors.getAll;
const getOneErrorSelector = (state) => 
state.quickfixes.errors.getOne;
const getPODOfOneTransporterErrorSelector = (state) =>
  state.quickfixes.errors.getPODOfOneTransporter;
const getPODOfOneBuyerErrorSelector = (state) =>
  state.quickfixes.errors.getPODOfOneBuyer;
const getPODOfOneSellerSelector = (state) =>
  state.quickfixes.errors.getPODOfOneSeller;
const getPODOfOneSellerByFiltersSelector = (state) =>
  state.quickfixes.errors.getPODOfOneSellerByFilters;
const createDeliveryErrorSelector = (state) => 
state.quickfixes.errors.create;
const updateDeliveryErrorSelector = (state) => 
state.quickfixes.errors.update;
const getTotalVatRiskErrorSelector = (state) =>
  state.quickfixes.errors.getTotalVatRisk;
const getNumberOfReceivedErrorSelector = (state) =>
  state.quickfixes.errors.getNumberOfReceived;
const getNumberOfPendingErrorSelector = (state) =>
  state.quickfixes.errors.getNumberOfPending;
const getNumberOfProofPendingErrorSelector = (state) =>
  state.quickfixes.errors.getNumberOfProofPending;
const uploadProofErrorSelector = (state) => 
state.quickfixes.errors.uploadProof;
const downloadAllProofErrorSelector = (state) =>
  state.quickfixes.errors.downloadAllProof;
const downloadOneProofErrorSelector = (state) =>
  state.quickfixes.errors.downloadOneProof;
const getVatRiskErrorSelector = (state) => 
state.quickfixes.errors.getVatRisk;
const validateProofErrorSelector = (state) =>
  state.quickfixes.errors.validateProof;
const refuseProofErrorSelector = (state) =>
  state.quickfixes.errors.refuseProofProof;
const validateDeliveryErrorSelector = (state) =>
  state.quickfixes.errors.validateDeliveryProof;
const getOneForBuyerErrorSelector = (state) =>
  state.quickfixes.errors.getOneForBuyerProof;
const getOneForTransporterErrorSelector = (state) =>
  state.quickfixes.errors.getOneForTransporter;
const contactErrorSelector = (state) => 
state.quickfixes.errors.contact;
const getCustomsDeclarationsIDsErrorSelector = (state) => 
state.quickfixes.errors.getCDIDs;
const getAllCDIDsErrorSelector = (state) => 
state.quickfixes.errors.searchAllDeclId;
const getAllBTErrorSelector = (state) => 
state.quickfixes.errors.getBuyersTransporters;
const getPrefilledDeliveryErrorSelector = (state) => 
state.quickfixes.errors.getPrefilledDelivery;
const setVatErrorSelector = (state) => 
state.quickfixes.errors.vat;
const checkQuickproofExistErrorSelector = (state) => 
state.quickfixes.errors.checkQuickproofExist;
const hasPaidSubscriptionErrorSelector = (state) => 
state.quickfixes.errors.hasPaidSubscription
const uploadSplitProofsErrorSelector = (state) => 
  state.quickfixes.errors.splitProofs

const errorsSelector = createErrorsSelector([
  subscribeErrorSelector,
  subscribeWithCodeErrorSelector,
  unsubscribeErrorSelector,
  getAllErrorSelector,
  getOneErrorSelector,
  getPODOfOneTransporterErrorSelector,
  getPODOfOneBuyerErrorSelector,
  getPODOfOneSellerSelector,
  getPODOfOneSellerByFiltersSelector,
  createDeliveryErrorSelector,
  getTotalVatRiskErrorSelector,
  getNumberOfReceivedErrorSelector,
  getNumberOfPendingErrorSelector,
  getNumberOfProofPendingErrorSelector,
  uploadProofErrorSelector,
  downloadAllProofErrorSelector,
  downloadOneProofErrorSelector,
  getVatRiskErrorSelector,
  validateProofErrorSelector,
  refuseProofErrorSelector,
  validateDeliveryErrorSelector,
  getOneForBuyerErrorSelector,
  getOneForTransporterErrorSelector,
  contactErrorSelector,
  getCustomsDeclarationsIDsErrorSelector,
  getAllCDIDsErrorSelector,
  getAllBTErrorSelector,
  getPrefilledDeliveryErrorSelector,
  checkQuickproofExistErrorSelector,
  hasPaidSubscriptionErrorSelector,
  updateDeliveryErrorSelector,
  uploadSplitProofsErrorSelector
]);

// Loading
const subscribeLoadingSelector = (state) => state.quickfixes.loading.subscribe;
const subscribeWithCodeLoadingSelector = (state) =>
  state.quickfixes.loading.subscribeWithCode;
const unsubscribeLoadingSelector = (state) =>
  state.quickfixes.loading.unsubscribe;
const getAllLoadingSelector = (state) => state.quickfixes.loading.getAll;
const getOneLoadingSelector = (state) => state.quickfixes.loading.getOne;
const getPODOfOneTransporterLoadingSelector = (state) =>
  state.quickfixes.loading.getPODOfOneTransporter;
const getPODOfOneBuyerLoadingSelector = (state) =>
  state.quickfixes.loading.getPODOfOneBuyer;
const getPODOfOneSellerLoadingSelector = (state) =>
  state.quickfixes.loading.getPODOfOneSeller;
const getPODOfOneSellerByFiltersLoadingSelector = (state) =>
  state.quickfixes.loading.getPODOfOneSellerByFilters;
const createDeliveryLoadingSelector = (state) =>
  state.quickfixes.loading.create;
const getTotalVatRiskLoadingSelector = (state) =>
  state.quickfixes.loading.getTotalVatRisk;
const getNumberOfReceivedLoadingSelector = (state) =>
  state.quickfixes.loading.getNumberOfReceived;
const getNumberOfPendingLoadingSelector = (state) =>
  state.quickfixes.loading.getNumberOfPending;
const getNumberOfProofPendingLoadingSelector = (state) =>
  state.quickfixes.loading.getNumberOfProofPending;
const uploadProofLoadingSelector = (state) =>
  state.quickfixes.loading.uploadProof;
const downloadAllProofLoadingSelector = (state) =>
  state.quickfixes.loading.downloadAllProof;
const downloadOneProofLoadingSelector = (state) =>
  state.quickfixes.loading.downloadOneProof;
const getVatRiskLoadingSelector = (state) =>
  state.quickfixes.loading.getVatRisk;
const validateProofLoadingSelector = (state) =>
  state.quickfixes.loading.validateProof;
const refuseProofLoadingSelector = (state) =>
  state.quickfixes.loading.refuseProof;
const validateDeliveryLoadingSelector = (state) =>
  state.quickfixes.loading.validateDeliveryProof;
const getOneForBuyerLoadingSelector = (state) =>
  state.quickfixes.loading.getOneForBuyerProof;
const getOneForTransporterLoadingSelector = (state) =>
  state.quickfixes.loading.getOneForTransporter;
const contactLoadingSelector = (state) => state.quickfixes.loading.contact;
const getCustomsDeclarationsIDsLoadingSelector = (state) => state.quickfixes.loading.getCDIDs;
const getAllCDIDsLoadingSelector = (state) => state.quickfixes.loading.searchAllDeclId;
const getAllBTLoadingSelector = (state) => state.quickfixes.loading.getBuyersTransporters;
const getPrefilledDeliveryLoadingSelector = (state) => state.quickfixes.loading.getPrefilledDelivery;
const setVatLoadingSelector = (state) => state.quickfixes.loading.vat;
const checkQuickproofExistLoadingSelector = (state) => state.quickfixes.loading.checkQuickproofExist;
const hasPaidSubscriptionLoadingSelector = (state) => state.quickfixes.loading.hasPaidSubscription;
const updateDeliveryLoadingSelector = (state) => 
state.quickfixes.loading.update;
const companySubscribedLoadingSelector = (state) => 
state.quickfixes.loading.companySubscribed;
//const oneDeliveryLoadingSelector = (state) => state.quickfixes.loading.oneDelivery;
const uploadSplitProofsLoadingSelector = (state) => 
  state.quickfixes.loading.splitProofs

const loadingSelector = createLoadingSelector([
  subscribeLoadingSelector,
  subscribeWithCodeLoadingSelector,
  unsubscribeLoadingSelector,
  getAllLoadingSelector,
  getOneLoadingSelector,
  getPODOfOneTransporterLoadingSelector,
  getPODOfOneBuyerLoadingSelector,
  getPODOfOneSellerLoadingSelector,
  getPODOfOneSellerByFiltersLoadingSelector,
  createDeliveryLoadingSelector,
  getTotalVatRiskLoadingSelector,
  getNumberOfReceivedLoadingSelector,
  getNumberOfPendingLoadingSelector,
  getNumberOfProofPendingLoadingSelector,
  uploadProofLoadingSelector,
  downloadAllProofLoadingSelector,
  downloadOneProofLoadingSelector,
  getVatRiskLoadingSelector,
  validateProofLoadingSelector,
  refuseProofLoadingSelector,
  validateDeliveryLoadingSelector,
  getOneForBuyerLoadingSelector,
  getOneForTransporterLoadingSelector,
  contactLoadingSelector,
  getCustomsDeclarationsIDsLoadingSelector,
  getAllCDIDsLoadingSelector,
  getPrefilledDeliveryLoadingSelector,
  checkQuickproofExistLoadingSelector,
  updateDeliveryLoadingSelector,
  uploadSplitProofsLoadingSelector
]);

// Success
const subscribeSuccessSelector = (state) => state.quickfixes.success.subscribe;
const subscribeWithCodeSuccessSelector = (state) =>
  state.quickfixes.success.subscribeWithCode;
const unsubscribeSuccessSelector = (state) =>
  state.quickfixes.success.unsubscribe;
const getAllSuccessSelector = (state) => state.quickfixes.success.getAll;
const getOneSuccessSelector = (state) => state.quickfixes.success.getOne;
const getPODOfOneTransporterSuccessSelector = (state) =>
  state.quickfixes.success.getPODOfOneTransporter;
const getPODOfOneBuyerSuccessSelector = (state) =>
  state.quickfixes.success.getPODOfOneBuyer;
const getPODOfOneSellerSuccessSelector = (state) =>
  state.quickfixes.success.getPODOfOneSeller;
const getPODOfOneSellerByFiltersSuccessSelector = (state) =>
  state.quickfixes.success.getPODOfOneSellerByFilters;
const createDeliverySuccessSelector = (state) =>
  state.quickfixes.success.create;
const getTotalVatRiskSuccessSelector = (state) =>
  state.quickfixes.success.getTotalVatRisk;
const getNumberOfReceivedSuccessSelector = (state) =>
  state.quickfixes.success.getNumberOfReceived;
const getNumberOfPendingSuccessSelector = (state) =>
  state.quickfixes.success.getNumberOfPending;
const getNumberOfProofPendingSuccessSelector = (state) =>
  state.quickfixes.success.getNumberOfProofPending;
const uploadProofSuccessSelector = (state) =>
  state.quickfixes.success.uploadProof;
const downloadAllProofSuccessSelector = (state) =>
  state.quickfixes.success.downloadAllProof;
const downloadOneProofSuccessSelector = (state) =>
  state.quickfixes.success.downloadOneProof;
const getVatRiskSuccessSelector = (state) =>
  state.quickfixes.success.getVatRisk;
const validateProofSuccessSelector = (state) =>
  state.quickfixes.success.validateProof;
const refuseProofSuccessSelector = (state) =>
  state.quickfixes.success.refuseProofProof;
const validateDeliverySuccessSelector = (state) =>
  state.quickfixes.success.validateDeliveryProof;
const getOneForBuyerSuccessSelector = (state) =>
  state.quickfixes.success.getOneForBuyerProof;
const getOneForTransporterSuccessSelector = (state) =>
  state.quickfixes.success.getOneForTransporter;
const contactSuccessSelector = (state) =>
  state.quickfixes.success.getOneForTransporter;
const getCustomsDeclarationsIDsSuccessSelector = (state) => state.quickfixes.success.customsDeclarationIDs;
const getAllCDIDsSuccessSelector = (state) => state.quickfixes.success.searchAllDeclId;
const getPrefilledDeliverySuccessSelector = (state) => state.quickfixes.success.getPrefilledDelivery;
const setVatSuccessSelector = (state) => state.quickfixes.success.vat;
const checkQuickproofExistSucessSelector = (state) => state.quickfixes.success.checkQuickproofExist;
const hasPaidSubscriptionSucessSelector = (state) => state.quickfixes.success.hasPaidSubscription;
const updateDeliverySuccesSelector = (state) => 
state.quickfixes.success.update;
const uploadSplitProofsSuccessSelector = (state) => 
  state.quickfixes.success.splitProofs

const successSelector = createSuccessSelector([
  subscribeSuccessSelector,
  subscribeWithCodeSuccessSelector,
  unsubscribeSuccessSelector,
  getAllSuccessSelector,
  getOneSuccessSelector,
  getPODOfOneTransporterSuccessSelector,
  getPODOfOneBuyerSuccessSelector,
  getPODOfOneSellerSuccessSelector,
  getPODOfOneSellerByFiltersSuccessSelector,
  createDeliverySuccessSelector,
  getTotalVatRiskSuccessSelector,
  getNumberOfReceivedSuccessSelector,
  getNumberOfPendingSuccessSelector,
  getNumberOfProofPendingSuccessSelector,
  uploadProofSuccessSelector,
  downloadAllProofSuccessSelector,
  downloadOneProofSuccessSelector,
  getVatRiskSuccessSelector,
  validateProofSuccessSelector,
  refuseProofSuccessSelector,
  validateDeliverySuccessSelector,
  getOneForBuyerSuccessSelector,
  getOneForTransporterSuccessSelector,
  contactSuccessSelector,
  getCustomsDeclarationsIDsSuccessSelector,
  getAllCDIDsSuccessSelector,
  getPrefilledDeliverySuccessSelector,
  checkQuickproofExistSucessSelector,
  updateDeliverySuccesSelector,
  uploadSplitProofsSuccessSelector
]);

const allDeliveriesSelector = (state) => state.quickfixes.allDeliveries;
const oneDeliverySelector = (state) => state.quickfixes.oneDelivery;
const podOfOneTransporterSelector = (state) =>
  state.quickfixes.podOfOneTransporter;
const podOfOneBuyerSelector = (state) => state.quickfixes.podOfOneBuyer;
const podOfOneSellerSelector = (state) => state.quickfixes.podOfOneSeller;
const podOfOneSellerByFiltersSelector = (state) =>
  state.quickfixes.podOfOneSellerByFilters;
const totalVatRiskSelector = (state) => state.quickfixes.totalVatRisk;
const numberOfReceivedSelector = (state) => state.quickfixes.numberOfReceived;
const numberOfPendingSelector = (state) => state.quickfixes.numberOfPending;
const numberOfProofPendingSelector = (state) =>
  state.quickfixes.numberOfProofPending;
const vatRiskSelector = (state) => state.quickfixes.vatRisk;
const oneProofSelector = (state) => state.quickfixes.oneProof;
const oneForBuyerSelector = (state) => state.quickfixes.oneForBuyer;
const oneForTransporterSelector = (state) => state.quickfixes.oneForTransporter;
const totalMassIntegrationsSelector = (state) =>
  state.quickfixes.totalMassIntegrations;
const massIntegrationsSelector = (state) => state.quickfixes.massIntegrations;
const massIntegrationSelector = (state) => state.quickfixes.massIntegration;
const deleteMassIntegrationLoadingSelector = (state) =>
  state.quickfixes.loading.deleteMassIntegration;
const getOneMassIntegrationLoadingSelector = (state) =>
  state.quickfixes.loading.getOneMassIntegration;
const getMassIntegrationsLoadingSelector = (state) =>
  state.quickfixes.loading.getMassIntegrations;
const createMassIntegrationLoadingSelector = (state) =>
  state.quickfixes.loading.createMassIntegration;
const totalPodOfOneSellerSelector = (state) =>
  state.quickfixes.totalPodOfOneSeller;
const companySubscribedSelector = (state) => 
state.quickfixes.companySubscribed;
const getCustomsDeclarationsIDsSelector = (state) => 
state.quickfixes.getCDIDs;
const getAllCDIDsSelector = (state) => 
state.quickfixes.searchAllDeclId;
// kyanite filters
const getAllBTSelector = (state) => 
state.quickfixes.getBuyersTransporters;
const getOneSellerByFiltersSelector = (state) =>
  state.quickfixes.getPODOfOneSellerByFilters;
const getPrefilledDeliverySelector = (state) => 
state.quickfixes.getPrefilledDelivery;
const setVatSelector = (state) => 
state.quickfixes.vat;
const checkQuickproofExistSelector = (state) => 
state.quickfixes.checkQuickproofExist;
const hasPaidSubscriptionSelector = (state) => state.quickfixes.hasPaidSubscription;
const updateDeliverySelector = (state) => 
state.quickfixes.update;
const uploadSplitProofsSelector = (state) => 
  state.quickfixes.splitProofs


const downloadLoadingSelector = createLoadingSelector([
  (state) => state.quickfixes.loading.downloadCustomsRegimeInvoice,
  (state) => state.quickfixes.loading.downloadAllProof,
  (state) => state.quickfixes.loading.downloadOneProof,
  (state) => state.quickfixes.loading.downloadAttestation,
  (state) => state.quickfixes.loading.downloadMassIntegrationTemplate,
  (state) => state.quickfixes.loading.getPrefilledDelivery,
]);

//error
const errorSelector = (state) => state.quickfixes.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  errorSelector,
  getPrefilledDeliverySelector,
  getPrefilledDeliveryLoadingSelector,
  getOneSellerByFiltersSelector,
  getPODOfOneSellerLoadingSelector,
  getPODOfOneSellerByFiltersLoadingSelector,
  allDeliveriesSelector,
  oneDeliverySelector,
  podOfOneTransporterSelector,
  podOfOneBuyerSelector,
  podOfOneSellerSelector,
  podOfOneSellerByFiltersSelector,
  totalVatRiskSelector,
  numberOfReceivedSelector,
  numberOfPendingSelector,
  numberOfProofPendingSelector,
  vatRiskSelector,
  oneProofSelector,
  oneForBuyerSelector,
  oneForTransporterSelector,
  subscribeWithCodeLoadingSelector,
  subscribeWithCodeSuccessSelector,
  subscribeWithCodeErrorSelector,
  contactLoadingSelector,
  massIntegrationsSelector,
  createMassIntegrationLoadingSelector,
  getMassIntegrationsLoadingSelector,
  massIntegrationSelector,
  getOneMassIntegrationLoadingSelector,
  totalMassIntegrationsSelector,
  totalPodOfOneSellerSelector,
  deleteMassIntegrationLoadingSelector,
  companySubscribedSelector,
  companySubscribedLoadingSelector,
  getCustomsDeclarationsIDsSelector,
  getCustomsDeclarationsIDsLoadingSelector,
  getAllCDIDsSelector,
  getAllCDIDsLoadingSelector,
  getAllBTSelector,
  getAllBTLoadingSelector,
  downloadLoadingSelector,
  setVatErrorSelector,
  setVatLoadingSelector,
  setVatSuccessSelector,
  setVatSelector,
  checkQuickproofExistSelector,
  hasPaidSubscriptionLoadingSelector,
  hasPaidSubscriptionSucessSelector,
  hasPaidSubscriptionSelector,
  updateDeliverySelector,
  uploadSplitProofsSelector,
  uploadSplitProofsLoadingSelector,
};
