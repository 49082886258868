import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Global, css } from '@emotion/core';
import Button from 'components/Button';

import { Main } from 'components/Layouts';
import { MainWrapper, NotFound } from './styles';
import styled from '@emotion/styled';

const NoMatch = ({ location, history }: any) => {

  const Desc = styled.p(() => ({
    color: 'rgba(0, 0, 0, 0.87)',
  }))

  return (
    <Main>
      <Global
        styles={(props) =>
          css`
            body {
              background-color: ${props.colors.fiscal};
            }
          `
        }
      />
      <MainWrapper>
        <NotFound>404</NotFound>
        <Desc>Route {location.pathname} not found</Desc>
        <Button
          size='massive'
          inverted
          color='black'
          content='Back'
          onClick={() => history.goBack()}
        />
      </MainWrapper>
    </Main>
  );
};

const enhance: any = compose(injectIntl, connect(null));

export default enhance(NoMatch);
