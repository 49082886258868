import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { actions, selectors } from 'store';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Label, Tab, Table } from 'semantic-ui-react';
import EmptyTable from '../../../../../../components/EmptyTable';
import { shortDate } from 'services/helper/dateFormater';

const Index = ({
  dispatch,
  match,
  selectors,
  history,
  user,
  companies,
  lastCustoms,
  loadingCustoms,
  loading
}: any) => {

  const [customs, setLastCustoms]: any = useState(null)
  const [companiesName, setCompaniesName]: any = useState(null)


  useEffect(() => {
    if(companies){
      dispatch(actions.customsStatistics.getLastCustoms(
        {
          params: {
            period: 3
          },
          data: {
            companies
          }
        }
      ));
    }

    return () => {
      dispatch(
        actions.customsStatistics.reset([
          'lastCustoms',
          'error',
        ])
      );
    }
  }, [dispatch, companies])

  const calcDate = (date1, date2, nBr?) => {
    //new date instance
    const dt_date1 = new Date(date1);
    const dt_date2 = new Date(date2);

    //Get the Timestamp
    const date1_time_stamp = dt_date1.getTime();
    const date2_time_stamp = dt_date2.getTime();

    let calc;

    //Check which timestamp is greater
    if (date1_time_stamp > date2_time_stamp) {
        calc = new Date(date1_time_stamp - date2_time_stamp);
    } else {
        calc = new Date(date2_time_stamp - date1_time_stamp);
    }
    //Retrieve the date, month and year
    const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
    //Convert to an array and store
    const calcFormat = calcFormatTmp.split("-");
    //Subtract each member of our array from the default date
    const days_passed = Number(Math.abs(Number(calcFormat[0])) - 1);
    const months_passed = Number(Math.abs(Number(calcFormat[1])) - 1);
    const years_passed = Number(Math.abs(Number(calcFormat[2])) - 1970);

    // Set up custom text
    const yrsTxt = ["year", "years"];
    const mnthsTxt = ["month ", "months"];
    const daysTxt = ["day", "days"];

    // Convert to days and sum together
    const total_days = (years_passed * 365) + (months_passed * 30.417) + days_passed;
    const total_secs = total_days * 24 * 60 * 60;
    const total_mins = total_days * 24 * 60;
    const total_hours = total_days * 24;
    const total_weeks = (total_days >= 7) ? total_days / 7 : 0;

    //display result with custom text
    const result = ((years_passed === 1) ? 
          years_passed + ' ' + yrsTxt[0] + ' ' : (years_passed > 1) ?
          years_passed + ' ' + yrsTxt[1] + ' ' : ' ') + ((Number(months_passed) === 1) ? months_passed + ' ' + mnthsTxt[0] : (months_passed > 1) ?
          months_passed + ' ' + mnthsTxt[1] + ' ' : ' ') + ((Number(days_passed) === 1) ? days_passed + ' ' + daysTxt[0] : (days_passed > 1) ?
          (nBr?(days_passed/nBr).toFixed(0):days_passed) + ' ' + daysTxt[1] : ' ');

    //return the result
    return {
        "total_days": Math.round(total_days),
        "total_weeks": Math.round(total_weeks),
        "total_hours" : Math.round(total_hours),
        "total_minutes" : Math.round(total_mins),
        "total_seconds": Math.round(total_secs),
        "result": result.trim()
    }
}
  

  useEffect(()=>{
    if (lastCustoms !== null) {
      setLastCustoms(lastCustoms)
    }

    if(companies){
      setCompaniesName(
        companies.map((e)=> {
          return {id: e.id, name: e.name}
        })
      )
    }

  } , [lastCustoms, companies])
 

  return (
    <Tab.Pane
      loading={loadingCustoms}
    >
      <Table columns={5} striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Customs Declaration</Table.HeaderCell>
            <Table.HeaderCell>Latest customs</Table.HeaderCell>
            <Table.HeaderCell>DIFF Today</Table.HeaderCell>
            <Table.HeaderCell>Average time spent by customs</Table.HeaderCell>
          </Table.Row>
      </Table.Header>
      <Table.Body>
      {(!customs && <EmptyTable colNumber={5} />) ||
        customs.map((cust, i) => {
          return cust.map((e, j) => {
              let result = companiesName.filter((comp) => comp.id === e.Company_Id)
              return (
                <Table.Row key={i+j}>
                  {j<1 && (<Table.Cell key={j+1} rowSpan={`${cust.length}`}>{result[0].name}</Table.Cell>)}
                  <Table.Cell key={j+2}>{e.id}</Table.Cell>
                  <Table.Cell key={j+3}>{shortDate(new Date(e.createdAt))}</Table.Cell>
                  <Table.Cell key={j+4}>{
                    (calcDate(e.createdAt, new Date()).result && 
                    <Label>{calcDate(e.createdAt, new Date()).result}</Label>) || 
                    (<>
                     <Label as='a' color='green'>Less than one day</Label>
                    </>)
                  }</Table.Cell>
                  {j<1 && (
                    <Table.Cell  key={j+4} rowSpan={`${cust.length}`}>
                      {(calcDate(cust[0].createdAt, cust[cust.length-1].createdAt, 3).result && 
                      <Label>{calcDate(cust[0].createdAt, cust[cust.length-1].createdAt, 3).result}</Label>) ||   <Label as='a' color='green'>Less than one day</Label>}
                    </Table.Cell>
                  )}
                </Table.Row>
              ) 
        })
      })}
      </Table.Body>
      </Table>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    lastCustoms: selectors.customsStatistics.getLastCustomsSelector(state),
    loadingCustoms: selectors.customsStatistics.getLastCustomsLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
