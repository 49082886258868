import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const subscribeErrorSelector = (state) => state.collectionbox.errors.subscribe;
const unsubscribeErrorSelector = (state) => state.collectionbox.errors.unsubscribe;
const getAllCollectionboxesErrorSelector = state => state.collectionbox.errors.getAllCollectionboxes;
const getOneCollectionboxErrorSelector = state => state.collectionbox.errors.getOneCollectionbox;
const createOneCollectionboxErrorSelector = state => state.collectionbox.errors.createCollectionbox;
const downloadErrorSelector = (state) => state.collectionbox.error.download;
const fileStateErrorSelector = (state) => state.collectionbox.error.filesState;
const getCollectionboxesByFiltersErrorSelector = (state) => state.collectionbox.error.getByFilters;
const getSettingsErrorSelector = (state) => state.collectionbox.error.getSettings;
const readChatErrorSelector = state => state.collectionbox.errors.readChat;
const categoriesErrorSelector = (state) => state.collectionbox.error.categories;
const obligationGetErrorSelector = (state) => state.collectionbox.error.obligation;
const createobligationGetErrorSelector = (state) => state.collectionbox.error.createObligation;
const getContactsSubscribedErrorSelector = (state) => state.collectionbox.error.subscribed;
const getCompaniesByIsoErrorSelector = (state) => state.collectionbox.error.companiesByIso;

const errorsSelector = createErrorsSelector([
  getAllCollectionboxesErrorSelector,
  getOneCollectionboxErrorSelector,
  createOneCollectionboxErrorSelector,
  downloadErrorSelector,
  fileStateErrorSelector,
  unsubscribeErrorSelector,
  subscribeErrorSelector,
  getCollectionboxesByFiltersErrorSelector,
  getSettingsErrorSelector,
  readChatErrorSelector,
  obligationGetErrorSelector,
  categoriesErrorSelector,
  getContactsSubscribedErrorSelector
]);


// Loading
const subscribeLoadingSelector = (state) => state.collectionbox.loading.subscribe;
const unsubscribeLoadingSelector = (state) => state.collectionbox.loading.unsubscribe;
const getAllCollectionboxesLoadingSelector = state => state.collectionbox.loading.getAllCollectionboxes;
const getOneCollectionboxLoadingSelector = state => state.collectionbox.loading.getOneCollectionbox;
const createOneCollectionboxLoadingSelector = state => state.collectionbox.loading.createCollectionbox;
const getDocsFromCatLoadingSelector = state => state.collectionbox.loading.docsFromCat;
const companySubscribedLoadingSelector = (state) => state.collectionbox.loading.companySubscribed;
const tagLoadingSelector = state => state.collectionbox.loading.tag;

const getFilesFullListLoadingSelector = state => state.collectionbox.loading.archiveFiles;
const getArchiveLoadingSelector = state => state.collectionbox.loading.archiveFile;

const createSingleFileLoadingSelector = state => state.collectionbox.loading.singleFile;
const filterTagLoadingSelector = state => state.collectionbox.loading.filterTag;
const archiveAllLoadingSelector = state => state.collectionbox.loading.archiveAll;
const bulkFilterLoadingSelector = state => state.collectionbox.loading.bulkFilter;
const splitDocumentLoadingSelector = state => state.collectionbox.loading.split;
const renameSendChuncksLoadingSelector = state => state.collectionbox.loading.renameSendChunk;
const groupFilesLoadingSelector = state => state.collectionbox.loading.groupFiles;
const filterTagArchivesLoadingSelector = state => state.collectionbox.loading.filterTagArchives;
const addCollectionboxDocumentsLoadingSelector = state => state.collectionbox.loading.docs;
const deleteCollectionboxDocumentsLoadingSelector = state => state.collectionbox.loading.deleteDoc;
const permsLoadingSelector = state => state.collectionbox.loading.perms;
const noneCreateCollectionboxLoadingSelector = state => state.collectionbox.loading.none;
const downloadCollectionboxLoadingSelector = (state) => state.collectionbox.loading.download;
const deleteFilesLoadingSelector = (state) => state.collectionbox.loading.deleteFiles;
const fileStateLoadingSelector = (state) => state.collectionbox.loading.filesState;
const getCollectionboxesByFiltersLoadingSelector = (state) => state.collectionbox.loading.getByFilters;
const updateDocLoadingSelector = state => state.fiscalVat.loading.updateDoc;
const readChatLoadingSelector = state => state.collectionbox.loading.readChat;
const categoriesLoadingSelector = state => state.collectionbox.loading.categories;
const obligationGetLoadingSelector = (state) => state.collectionbox.loading.obligation;
const createobligationGetLoadingSelector = (state) => state.collectionbox.loading.createObligation;
const getContactsSubscribedLoadingSelector = (state) => state.collectionbox.loading.subscribed;
const getCompaniesByIsoLoadingSelector = (state) => state.collectionbox.loading.companiesByIso;


const loadingSelector = createLoadingSelector([
      (state) => state.collectionbox.loading.getAllCollectionboxes,
      (state) => state.collectionbox.loading.createCollectionbox,
]);



const loadingSettingsSelector = createLoadingSelector([
  (state) => state.collectionbox.loading.settings,
  (state) => state.collectionbox.loading.getSettings,
]);

// Success
const createOneCollectionboxSuccessSelector = state => state.collectionbox.success.createCollectionbox;
const tagSuccessSelector = state => state.collectionbox.success.tag;
const renameSendChuncksSuccessSelector = state => state.collectionbox.success.renameSendChunk;
const fileStateSuccessSelector = state => state.collectionbox.success.filesState;
const readChatSuccessSelector = state => state.collectionbox.success.readChat;
const getCompaniesByIsoSuccessSelector = (state) => state.collectionbox.success.companiesByIso;

const successSelector = createSuccessSelector([
      (state) => state.collectionbox.success.getAllCollectionboxes,
      (state) => state.collectionbox.success.getOneCollectionbox,
]);

// Countries
const subscribeSelector = (state) => state.collectionbox.subscribe;
const unsubscribeSelector = (state) => state.collectionbox.unsubscribe;
const allCollectionboxesSelector = state => state.collectionbox.getAllCollectionboxes;
const oneCollectionboxSelector = state => state.collectionbox.oneCollectionbox;
const getDocsFromCatSelector = state => state.collectionbox.docsFromCat;
const companySubscribedSelector = (state) => state.collectionbox.companySubscribed;
const tagSelector = state => state.collectionbox.tag;
const getFilesFullListSelector = state => state.collectionbox.archiveFiles;
const getArchiveSelector = state => state.collectionbox.archiveFile;
const createSingleFileSelector = state => state.collectionbox.singleFile;
const filterTagSelector = state => state.collectionbox.filterTag;
const archiveAllSelector = state => state.collectionbox.archiveAll;
const bulkFilterSelector = state => state.collectionbox.bulkFilter;
const splitDocumentSelector = state => state.collectionbox.split;
const renameSendChuncksSelector = state => state.collectionbox.renameSendChunk;
const groupFilesSelector = state => state.collectionbox.groupFiles;
const filterTagArchivesSelector = state => state.collectionbox.filterTagArchives;
const addCollectionboxDocumentsSelector = state => state.collectionbox.docs;
const deleteCollectionboxDocumentsSelector = state => state.collectionbox.deleteDoc;
const permsSelector = state => state.collectionbox.perms;
const noneCreateCollectionboxSelector = state => state.collectionbox.none;
const downloadCollectionboxSelector = (state) => state.collectionbox.download;
const fileStateSelector = (state) => state.collectionbox.filesState;
const getCollectionboxesByFiltersSelector = (state) => state.collectionbox.getByFilters;
const updateDocSelector = state => state.fiscalVat.updateDoc;
const settingsGetSelector = (state) => state.collectionbox.getSettings;
const readChatSelector = state => state.collectionbox.readChat;
const categoriesSelector = state => state.collectionbox.categories;
const obligationGetSelector = (state) => state.collectionbox.obligation;
const createobligationGetSelector = (state) => state.collectionbox.createObligation;
const getContactsSubscribedSelector = (state) => state.collectionbox.subscribed;
const getCompaniesByIsoSelector = (state) => state.collectionbox.companiesByIso;

// Error handler
const errorSelector = state => state.collectionbox.error;


export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  allCollectionboxesSelector,
  oneCollectionboxSelector,
  getOneCollectionboxLoadingSelector,
  getAllCollectionboxesLoadingSelector,
  createOneCollectionboxLoadingSelector,
  createOneCollectionboxSuccessSelector,
  errorSelector,
  getDocsFromCatSelector,
  getDocsFromCatLoadingSelector,
  subscribeSelector,
  unsubscribeSelector,
  companySubscribedSelector,
  companySubscribedLoadingSelector,
  tagSelector,
  tagSuccessSelector,
  tagLoadingSelector,
  getFilesFullListSelector,
  getFilesFullListLoadingSelector,
  getArchiveSelector,
  getArchiveLoadingSelector,
  createSingleFileSelector,
  createSingleFileLoadingSelector,
  filterTagSelector,
  filterTagLoadingSelector,
  archiveAllSelector,
  archiveAllLoadingSelector,
  bulkFilterSelector,
  bulkFilterLoadingSelector,
  splitDocumentSelector,
  splitDocumentLoadingSelector,
  renameSendChuncksSelector,
  renameSendChuncksLoadingSelector,
  renameSendChuncksSuccessSelector,
  groupFilesLoadingSelector,
  groupFilesSelector,
  filterTagArchivesSelector,
  filterTagArchivesLoadingSelector,
  addCollectionboxDocumentsSelector,
  addCollectionboxDocumentsLoadingSelector,
  deleteCollectionboxDocumentsSelector,
  deleteCollectionboxDocumentsLoadingSelector,
  permsSelector,
  permsLoadingSelector,
  noneCreateCollectionboxSelector,
  noneCreateCollectionboxLoadingSelector,
  downloadCollectionboxLoadingSelector,
  deleteFilesLoadingSelector,
  fileStateSelector,
  fileStateLoadingSelector,
  fileStateSuccessSelector,
  downloadCollectionboxSelector,
  getCollectionboxesByFiltersLoadingSelector,
  getCollectionboxesByFiltersSelector,
  updateDocLoadingSelector,
  updateDocSelector,
  loadingSettingsSelector,
  settingsGetSelector,
  readChatSelector,
  readChatLoadingSelector,
  categoriesSelector,
  categoriesLoadingSelector,
  obligationGetSelector,
  obligationGetLoadingSelector,
  getContactsSubscribedLoadingSelector,
  getContactsSubscribedSelector,
  getCompaniesByIsoSelector,
  getCompaniesByIsoLoadingSelector
};
