import Customs from 'views/Customs/Declaration';
import CustomsService from 'views/Customs/Declaration/Service';
import CustomsServiceDetails from 'views/Customs/Declaration/Details/CustomsDeclarationDetails';
import CustomsServiceMandates from 'views/Customs/Declaration/Service/Mandates/Mandates';
import CustomsServiceAccises from 'views/Customs/Declaration/Service/Accises/Accises';
import CustomsServiceGlobalStats from 'views/Customs/Declaration/Operator/GlobalStatistic/statistic';
import CustomsServiceMonitoring from 'views/Customs/Declaration/Operator/Monitoring/CustomsDeclarationMonitoring';
import CustomsServiceParking from 'views/Customs/Declaration/Operator/Parking/CustomsDeclarationParking';
import paths from './paths';

export default [
  {
    private: true,
    path: paths.customsDeclaration,
    component: Customs,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.customsDeclarationCompany,
    component: CustomsService,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.customsDeclarationDetails,
    component: CustomsServiceDetails,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.customsDeclarationMandates,
    component: CustomsServiceMandates,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.customsDeclarationAccises,
    component: CustomsServiceAccises,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.customsDeclarationMonitoring,
    component: CustomsServiceMonitoring,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.customsDeclarationParking,
    component: CustomsServiceParking,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.customsDeclarationStatistic,
    component: CustomsServiceGlobalStats,
    exact: true,
    authorizedRoles: [],
  },
  // {
  //   private: true,
  //   path: paths.customsDeclarationStatisticDetail,
  //   component: CustomsServiceStatistic,
  //   exact: true,
  //   authorizedRoles: [],
  // },
];
