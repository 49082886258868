import React, { useEffect, useState, useRef, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors, actions } from 'store';
import { Grid } from 'semantic-ui-react';
import { useWindowScroll } from 'react-use';

import ModalDeleteAllNotifications from './components/ModalDeleteAllNotifications/ModalDeleteAllNotifications';
import { longDate } from 'services/helper/dateFormater';
import NotificationPlaceholder from './components/NotificationPlaceholder/NotificationPlaceholder';
import {
  NotificationContentLeft,
  Content,
  Segments,
  NotificationDeleteIcon,
  NotificationCloseIcon,
  CloseButton,
  Header,
  ContentNotification,
  ContentNotificationEmpty,
  IconView,
  IconContent,
  IconContentEmpty,
  ParagraphEmpty,
  FormatDateNotification,
  ContentLeftParagraph,
  ButtonAllRead,
  Title,
  AllContentNotification,
  ButtonAllDelete,
} from './notificationStyles';
import Wrapper from 'components/core/Wrapper/Wrapper';

const Notification = ({
  openNotification,
  user,
  dispatch,
  notifications,
  loaderNotification,
}) => {
  const notificationRef = useRef(null);

  const [
    transitionRemoveNotification,
    setTransitionRemoveNotification,
  ] = useState(false);
  const [notifToRemove, setnotifToRemove] = useState(null);
  const [
    modalRequestDeleteAllNotificationsStatus,
    setModalrequestDeleteAllNotificationsStatus,
  ] = useState(false);

  const handleClose = useCallback(() => {
    dispatch(actions.notifications.openNotification())
  }, [dispatch]);

  useEffect(() => {
    if (openNotification) {
      dispatch(actions.notifications.getAllNotification());
      dispatch(actions.socket.subscribeAllNotification(user.id));
      // return () => {
      //   dispatch(actions.notifications.reset(['allNotification', 'error']));
      //   dispatch(actions.socket.unsubscribeAllNotification(user.id));
      // }
    } else {
      dispatch(actions.notifications.reset(['allNotification', 'error']));
      dispatch(actions.socket.unsubscribeAllNotification(user.id));
    }
  }, [openNotification, dispatch, user.id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        !event.target.className.includes('notificationBell') &&
        !modalRequestDeleteAllNotificationsStatus
      ) {
        handleClose();
      }
    };
    if (openNotification) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    notificationRef,
    openNotification,
    modalRequestDeleteAllNotificationsStatus,
    handleClose
  ]);

  const { y: pageYOffset } = useWindowScroll();
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (pageYOffset > 75) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }, [pageYOffset]);

  const markNotificationAsRead = (id: number) => {
    dispatch(
      actions.notifications.notificationIsRead({
        params: { notificationId: id },
      })
    );
  };

  const allRead = () => {
    dispatch(actions.notifications.notificationAllRead());
  };

  const deleteAll = () => {
    dispatch(
      actions.notifications.notificationAllDelete({
        params: { userId: user.id },
      })
    );

    setModalrequestDeleteAllNotificationsStatus(false);
    handleClose();
  };

  const notificationIsShow = (id) => {
    setTransitionRemoveNotification(true);
    setnotifToRemove(id);
    dispatch(
      actions.notifications.notificationIsShow({
        params: { notificationId: id },
      })
    );
  };

  return (
    <>
      {modalRequestDeleteAllNotificationsStatus && (
        <ModalDeleteAllNotifications
          open={modalRequestDeleteAllNotificationsStatus}
          handleClose={() => setModalrequestDeleteAllNotificationsStatus(false)}
          deleteAll={deleteAll}
        />
      )}

      <NotificationContentLeft
        ref={notificationRef}
        open={openNotification}
        scrollFixed={fixed}
        className='scrollNotif'
      >
        <Header>
          <Title>Notifications</Title>
          {notifications && notifications.length > 0 && (
            <ButtonAllDelete
              type='button'
              onClick={() => setModalrequestDeleteAllNotificationsStatus(true)}
            >
              Delete all
            </ButtonAllDelete>
          )}
          {notifications && notifications.find((e) => !e.isRead) && (
            <ButtonAllRead type='button' onClick={allRead}>
              All read
            </ButtonAllRead>
          )}
          <CloseButton onClick={handleClose}>
            <NotificationCloseIcon name='close' />
          </CloseButton>
        </Header>
        <Segments />
        <AllContentNotification scrollFixed={fixed}>
          {notifications &&
            notifications.length !== 0 &&
            notifications.map((notification, index) => (
              <Wrapper key={index}>
                <ContentNotification
                  isRead={notification.isRead}
                  remove={
                    transitionRemoveNotification &&
                    notifToRemove === notification.id
                  }
                >
                  <Content
                    onClick={() => {
                      markNotificationAsRead(notification.id);
                      handleClose();
                    }}
                    href={notification.link}
                  >
                    <FormatDateNotification>
                      {longDate(new Date(notification.createdAt))}
                    </FormatDateNotification>
                    <ContentLeftParagraph>
                      {notification.message}
                    </ContentLeftParagraph>
                  </Content>
                  <IconContent>
                    <CloseButton>
                      <NotificationDeleteIcon
                        name='trash'
                        onClick={() => notificationIsShow(notification.id)}
                      />
                    </CloseButton>
                    {notification.isRead === false && (
                      <CloseButton>
                        <IconView
                          name='eye'
                          onClick={() =>
                            markNotificationAsRead(notification.id)
                          }
                        />
                      </CloseButton>
                    )}
                  </IconContent>
                </ContentNotification>
                <Segments />
              </Wrapper>
            ))}
          {loaderNotification && !notifications && (
            <>
              <Grid.Column>
                <NotificationPlaceholder />
              </Grid.Column>
            </>
          )}
          {notifications && notifications.length === 0 && (
            <ContentNotificationEmpty>
              <IconContentEmpty name='bell' />
              <ParagraphEmpty>You don't have any notifications</ParagraphEmpty>
            </ContentNotificationEmpty>
          )}
        </AllContentNotification>
      </NotificationContentLeft>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    notifications: selectors.notifications.allNotificationSelector(state),
    loaderNotification: selectors.notifications.allNotificationLoadingSelector(
      state
    ),
    openNotification: selectors.notifications.openNotificationSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Notification);
