// Dependencies
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditText } from 'react-edit-text';

// Components
import AddButton from '../addButton/addButton';
import EditButton from '../editButton/editButton';
import PdfViewer from '../pdfViewer/pdfViewer';
import DocumentSlot from '../documentSlot/documentSlot';

// Resources
import editLogo from '../../assets/images/logos/edit.png';

// Stylesheets
import './inspectionDetails.css';
import 'react-edit-text/dist/index.css';

interface DocumentTypeOptions {
  name: string;
  url: string;
}

/**
 * @name inspectionDetails.tsx
 *
 * @path /components/inspectionDetails.tsx
 *
 * @description Defines inspection details page content
 */
const InspectionDetails: React.FC = ({ user }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [deadline, setDeadline] = useState<string>();

  // Popups' references
  const childRefGlobal = useRef<HTMLDivElement>(null);
  const globalButtonRef = useRef<HTMLButtonElement>(null);
  const globalEditPopupRef = useRef<HTMLDivElement>(null);

  // Manage popups' visibility
  const [globalEditPopupIsVisible, setGlobalEditPopupIsVisible] =
    useState<boolean>(false);

  // Defines the action that must be executed when user performed a click on lodging edit button
  const handleClickEditGlobalButton = () => {
    setGlobalEditPopupIsVisible(!globalEditPopupIsVisible);
  };

  // Popup references
  const childRefEmployees = useRef<HTMLDivElement>(null);
  const employeesButtonRef = useRef<HTMLButtonElement>(null);
  const employeesEditPopupRef = useRef<HTMLDivElement>(null);

  // Choose document category
  const documentCategoryOptions = [
    { id: 1, value: t('inspectionPageStrings.timesheet') },
    { id: 2, value: t('inspectionPageStrings.noticeOfTaxAssessment') },
    { id: 3, value: t('inspectionPageStrings.missionContract') },
    { id: 4, value: t('inspectionPageStrings.paySlip') },
    { id: 5, value: t('inspectionPageStrings.other') },
  ];

  const documentCategoryRef = useRef<HTMLSelectElement>(null);
  const [isOpenDocumentCategory, setIsOpenDocumentCategory] = useState(false);
  const [selectedOptionDocumentCategory, setSelectedOptionDocumentCategory] =
    useState<string | null>();

  const handleSelectChangeDocumentCategory = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionDocumentCategory = event.target.value;

    setSelectedOptionDocumentCategory(newSelectedOptionDocumentCategory);
    setDocumentCategory(newSelectedOptionDocumentCategory);
  };

  // Manage popups' visibility
  const [employeesEditPopupIsVisible, setEmployeesEditPopupIsVisible] =
    useState<boolean>(false);

  // Defines the action that must be executed when user performed a click on lodging edit button
  const handleClickEditEmployeesButton = () => {
    setEmployeesEditPopupIsVisible(!employeesEditPopupIsVisible);
  };

  // Popups references
  const childRefDocuments = useRef<HTMLDivElement>(null);
  const documentsButtonRef = useRef<HTMLButtonElement>(null);
  const documentsEditPopupRef = useRef<HTMLDivElement>(null);

  // Manage popups visibility
  const [documentsEditPopupIsVisible, setDocumentsEditPopupIsVisible] =
    useState<boolean>(false);

  // Defines the action that must be executed when user performed a click on lodging edit button
  const handleClickEditDocumentsButton = () => {
    setDocumentsEditPopupIsVisible(!documentsEditPopupIsVisible);
  };

  // Add document
  const [documentsList, setDocumentsList] = useState<DocumentTypeOptions[]>([]); // Init

  const [documentCategory, setDocumentCategory] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  const handleDocumentNameChange = ({ value }: { value: string }) => {
    setUrl(value);
  };

  const handleClickSave = () => {
    const newDocumentsList: DocumentTypeOptions[] = [
      { name: `${documentCategory}`, url: `${url}` },
      ...documentsList,
    ];
    setDocumentsList(newDocumentsList);
    handleClickEditDocumentsButton();
  };

  return (
    <div className='inspectionDetailsContainer'>
      <div className='inspectionDetailsDetailsAndEmployeesContainer'>
        <div className='inspectionDetailsGlobalDetailsContainer'>
          <h4>{t('inspectionPageStrings.globalDetails')}</h4>
          <div className='inspectionDetailsContentArea'>
            <p>Content Area</p>
          </div>
          <EditButton
            popupVisible={globalEditPopupIsVisible}
            myRef={globalButtonRef}
            popupRef={globalEditPopupRef}
            title={t('inspectionPageStrings.updateGlobalDetails')}
            action={handleClickEditGlobalButton}
            children={<></>}
            onClickSave={() => ''}
          />
        </div>
        <div className='inspectionDetailsEmployeesContainer'>
          <h4>{t('inspectionPageStrings.employeesList')}</h4>
          <div className='inspectionDetailsContentArea'>
            <p>Content Area</p>
          </div>
          <EditButton
            popupVisible={employeesEditPopupIsVisible}
            myRef={employeesButtonRef}
            popupRef={employeesEditPopupRef}
            title={t('inspectionPageStrings.updateEmployeesList')}
            action={handleClickEditEmployeesButton}
            children={<></>}
            onClickSave={() => ''}
          />
        </div>
      </div>
      <div className='inspectionDetailsDocumentsContainer'>
        <div className='documentListHeader'>
          <h4>{t('inspectionPageStrings.documentsList')}</h4>
          <div className='documentListHeader'>
            <p>
              <b>{`${t(
                'inspectionPageStrings.documentDeadlineSubmission'
              )} XX/XX/XXX`}</b>
            </p>
            <button className='deadlineEditButton'>
              <img src={editLogo} alt='editLogo' />
            </button>
          </div>
        </div>
        <div className='inspectionDetailsContentAreaDocuments'>
          {documentsList.map((option) => {
            return (
              <DocumentSlot
                entitled={option.name}
                filename={option.url}
                fileType='pdf'
              />
            );
          })}
        </div>
        <AddButton
          popupVisible={documentsEditPopupIsVisible}
          myRef={documentsButtonRef}
          popupRef={documentsEditPopupRef}
          title={t('inspectionPageStrings.addDocument')}
          action={handleClickEditDocumentsButton}
          children={
            <div className='popupContent'>
              <div className='documentSpecs'>
                <p>
                  <b>{t('inspectionPageStrings.documentName')}</b>
                </p>
                <div className='documentName'>
                  <EditText onSave={handleDocumentNameChange} />
                </div>
                <p>
                  <b>{t('inspectionPageStrings.documentCategory')}</b>
                </p>
                <select
                  id={'documentCategorySelector'}
                  ref={documentCategoryRef}
                  className='documentCategorySelector'
                  onClick={() =>
                    setIsOpenDocumentCategory(!isOpenDocumentCategory)
                  }
                  value={selectedOptionDocumentCategory || ''}
                  onChange={handleSelectChangeDocumentCategory}
                >
                  <option key='defaultdocumentCategory' value='---'>
                    ---
                  </option>

                  {documentCategoryOptions.map((option, index) => {
                    return (
                      <option key={index} value={option.value}>
                        {option.value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <PdfViewer fileType='pdf' />
            </div>
          }
          onClickSave={handleClickSave}
        />
      </div>
    </div>
  );
};

export default InspectionDetails;
