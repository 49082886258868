import styled from '@emotion/styled';
import { Button } from 'semantic-ui-react';
import theme from 'theme';

const colors = theme.getTheme().colors;

const applyColor = (props) => {
  switch (props.btncolor) {
    case 'blue':
      return colors.mainButton;
    case 'red':
      return colors.deleteButton;
    default:
      return colors.mainButton;
  }
};

const applyHoverColor = (props) => {
  switch (props.btncolor) {
    case 'blue':
      return colors.mainButtonHover;
    case 'red':
      return colors.deleteButtonHover;
    default:
      return colors.mainButtonHover;
  }
};

export const ButtonStyled = styled(Button)`
  &&& {
    background-color: ${(props) => applyColor(props)};
    border-radius: 2em !important;
    color: #ffff;
  }
  &&&:hover {
    background-color: ${(props) => applyHoverColor(props)};
    border-radius: 2em !important;
    color: #ffff;
  }
`;

export const ButtonWrapper = styled.div(
  ({ margin, position, maxHeight }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    maxHeight: maxHeight ? maxHeight : 'none',
    justifyContent: position ? position : 'center',
    margin,
  })
);
