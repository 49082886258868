import Companies from "views/Companies";
import NewCompany from "views/Companies/New";
import CompanyValidation from "views/Companies/Validation";
import CompanyDetails from "views/Companies/Details";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.companies,
    component: Companies,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.newCompany,
    component: NewCompany,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.companyDetails,
    component: CompanyDetails,
    exact: true,
    authorizedRoles: []
  },
  {
    private: false,
    path: paths.companyValidation,
    component: CompanyValidation,
    exact: true,
    authorizedRoles: []
  }
];
