import { selectors } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { Key, useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { Buffer } from 'buffer';
import { IframeViewer } from './styles';


const IntrastatIframe = ({
  src,
  storage,
  companyId,
  fileLoading,
  file,
}: ModalIntrastatIframeProps) => {
  const viewId: Key = Number(Math.random);

  const [data, setData]: any = useState(null);
  const [dataUrl, setUrl]: any = useState(null);

  useEffect(() => { 
    if(src){
      setUrl(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/public/storage/${(storage !== '' && storage) || 's3'}/proof/${src.url}`
      );
    }  
  }, [src, companyId, dataUrl, storage]);

  useEffect(() => {
    if (file) {
      const bufferReader = Buffer.from(file);
      bufferReader && setData(bufferReader);
    }
  }, [file]);

  return (
    ((data === null || dataUrl === null) && fileLoading && <Loader />) ||
    (dataUrl && (
      <IframeViewer key={viewId} src={dataUrl ? dataUrl : data}></IframeViewer>
    )))
};

interface ModalIntrastatIframeProps {
  src;
  companyId;
  storage;
  fileLoading;
  file;
  dispatch: (value: any) => void;
}

const mapStateToProps = (state) => {
  return {
    file: selectors.intrastat.getArchiveSelector(state),
    fileLoading: selectors.intrastat.getArchiveLoadingSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(IntrastatIframe);
