import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Grid, Message, Segment, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import { Main } from 'components/Layouts';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { longDate } from 'services/helper/dateFormater';

const Index = ({
  dispatch,
  history,
  match,
  user,
  companySelected,
  massIntegration,
  massIntegrationLoading,
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected;

  useEffect(() => {
    dispatch(
      actions.quickfixes.getOneMassIntegration({
        params: {
          companyId,
          massIntegrationId: match.params.massIntegrationId,
        },
      })
    );
    return () => {
      dispatch(actions.quickfixes.reset(['massIntegration', 'error']));
    };
  }, [dispatch, companyId, match.params]);

  const handleDelete = (massIntegrationId) => {
    dispatch(
      actions.quickfixes.deleteMassIntegration({
        params: {
          companyId,
          massIntegrationId,
        },
      })
    );

    history.goBack();
  };

  return (
    <Main>
      <Wrapper width='70%'>
        <Divider hidden />
        <Wrapper display='flex'>
          <ButtonBack history={history} />
        </Wrapper>
        {massIntegrationLoading && <Loader />}
        {massIntegration !== null && (
          <>
            <Segment>
              <Grid columns={2} divided padded>
                <Grid.Column>
                  <div>
                    <b>Id: </b>
                    {massIntegration.id}
                  </div>
                  <div>
                    <b>Created at: </b>
                    {longDate(new Date(massIntegration.createdAt))}
                  </div>
                  <div>
                    <b>State: </b>
                    {massIntegration.state}
                  </div>
                </Grid.Column>
              </Grid>
            </Segment>
            {massIntegration.state === 'File error' && (
              <Table striped celled columns={2}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Line</Table.HeaderCell>
                    <Table.HeaderCell>Error</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Object.keys(JSON.parse(massIntegration.error)).map(
                    (line, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell>{line}</Table.Cell>
                          <Table.Cell>
                            <ul>
                              {JSON.parse(massIntegration.error)[line].map(
                                (error, i) => (
                                  <li key={i}>{error}</li>
                                )
                              )}
                            </ul>
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                  )}
                </Table.Body>
              </Table>
            )}
            {massIntegration.state === 'Error' && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <Divider hidden />
                {massIntegration.error}
              </Message>
            )}
            <Button
              type='submit'
              margin='5px'
              content='Delete'
              color='red'
              onClick={() => handleDelete(massIntegration.id)}
            />
          </>
        )}
      </Wrapper>
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    massIntegration: selectors.quickfixes.massIntegrationSelector(state),
    massIntegrationLoading: selectors.quickfixes.getOneMassIntegrationLoadingSelector(
      state
    ),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
