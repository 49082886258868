import User from "views/User/Profile";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.profile,
    component: User,
    exact: true,
    authorizedRoles: []
  }
];
