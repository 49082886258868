import { Manager } from 'socket.io-client';
import { actionTypes as authActionTypes } from 'store/auth';
import { actionTypes as socketActionTypes } from 'store/socket';
import config from 'config';
import routes from './routes';

let socket = null;
export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {

    if (store.getState().auth.connectedUser && socket === null) {
      const manager = new Manager(config.api.url, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        query: {user: store.getState().auth.user.email},
      });
      socket = manager.socket('/');
    }

    if(
      socket !== null &&
      (!store.getState().auth.connectedUser ||
        action.type === authActionTypes.LOGOUT_LOADING)
    ){
      socket.close();
      socket = null;
      return next(action);
    }

    if(socket && action.type === 'SOCKET'){
      
      const { handle, event, leave, emit, payload } = action;
      if (!event) {
        return next(action);
      }

      if (leave) {
        socket.removeListener(event);
        return next(action);
      }

      if (emit) {
        socket.emit(event, payload);
        return next(action);
      }

      return socket.on(event, (result) => {
        store.dispatch({ type: handle, payload: result });
      });

    }
    
    if(socket && action.type === 'SOCKET_CONNEXIONS'){
      const { handle, event, leave, emit, payload } = action;
        try {
          socket.on(event, (result) => {
            return next({
              type: event,
              payload: result,
            });
          });

          // if(!event){
          //   return next(action);
          // }
    
          if(leave){
            socket.removeListener(event);
            return next(action);
          }

        } catch (error) {
            return next({
              type: 'getConnexions_error',
              error,
            });
        }

    }

    if(action.type === 'MY_ASD.SOCKET.DISCONNECT'){
      try {
        next({ type: socketActionTypes.DISCONNECT_LOADING });
        
        const resp = await request.removeCookies({ ...action.payload });
        next({
          type: socketActionTypes.DISCONNECT_SUCCESS,
          payload: resp.data.data
        });
      } catch (error) {
        next({
          type: socketActionTypes.DISCONNECT_ERROR,
          error,
        });
      }
    }
  


    return next(action);
  };
}
