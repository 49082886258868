import styled from '@emotion/styled';
import { Form } from 'semantic-ui-react';

export const Wrapper = styled.div(
  ({ labelPosition, labelVerticalPosition, margin, justifyContent }: any) => ({
    alignItems: labelVerticalPosition === 'center' ? 'center' : 'unset',
    display: 'flex',
    flexDirection: labelPosition === 'left' ? 'row' : 'column',
    margin: margin,
    justifyContent: justifyContent,
    paddingRight: 0,
  })
);

export const Input = styled(Form.Input)`
  &&& {
    margin: 0;
    text-align: right !important;
    display: block !important;
  }

  &&&.disabled {
    opacity: 1;
  }

  &&& div.disabled input {
    opacity: 1;
    border-color: rgba(34,36,38,.15) !important;
  }

  &&& div {
    opacity: 0.6;
    display: block;
  }

  &&& div input {
    border-radius: 10px;
    border-color: #85b7d9;
  }

  &&& div input:focus {
    border-radius: 10px;
    border-color: #85b7d9 !important; 
  }
`;
