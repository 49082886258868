/**
 * Dependencies
 */
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources
 */
import Header from '../components/header/header';
import Content from '../components/content/content';
import Footer from '../components/footer/footer';
import MissionDetailsManagement from '../components/missionDetailsManagement/missionDetailsManagement';

/**
 *
 */
interface Params {
  companyId: number;
  reference: string;
}

/**
 *
 */
interface MissionDetailsManagementPageProps extends WithTranslation {
  companyId: number;
  reference: string;
}

/**
 *
 */
class MissionDetailsManagementPage extends Component<
  RouteComponentProps<Params>,
  MissionDetailsManagementPageProps
> {
  render() {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;
    const { companyId } = this.props.match.params;
    const { reference } = this.props.match.params;

    return (
      <div className='pages'>
        <Helmet>
          <title>
            {t('missionDetailsPageStrings.managementPageTitle')}
            {t('helmetString')}
          </title>
        </Helmet>

        <Header />
        <Content
          title={t('missionDetailsPageStrings.managementPageTitle')}
          homepage={false}
          history={window.location.href.split('/').slice(0, -1).join('/')}
        />
        <MissionDetailsManagement companyId={companyId} reference={reference} />
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(MissionDetailsManagementPage);
