export default {
  SUBSCRIBE: 'MY_ASD.FISCAL.OSS.SUBSCRIBE',
  SUBSCRIBE_LOADING: 'MY_ASD.FISCAL.OSS.SUBSCRIBE_LOADING',
  SUBSCRIBE_SUCCESS: 'MY_ASD.FISCAL.OSS.SUBSCRIBE_SUCCESS',
  SUBSCRIBE_ERROR: 'MY_ASD.FISCAL.OSS.SUBSCRIBE_ERROR',

  UNSUBSCRIBE: 'MY_ASD.FISCAL.OSS.UNSUBSCRIBE',
  UNSUBSCRIBE_LOADING: 'MY_ASD.FISCAL.OSS.UNSUBSCRIBE_LOADING',
  UNSUBSCRIBE_SUCCESS: 'MY_ASD.FISCAL.OSS.UNSUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_ERROR: 'MY_ASD.FISCAL.OSS.UNSUBSCRIBE_ERROR',

  GET_COMPANY_SUBSCRIBED: 'MY_ASD.FISCAL.OSS.GET_COMPANY_SUBSCRIBED',
  GET_COMPANY_SUBSCRIBED_LOADING:
    'MY_ASD.FISCAL.OSS.GET_COMPANY_SUBSCRIBED_LOADING',
  GET_COMPANY_SUBSCRIBED_SUCCESS:
    'MY_ASD.FISCAL.OSS.GET_COMPANY_SUBSCRIBED_SUCCESS',
  GET_COMPANY_SUBSCRIBED_ERROR:
    'MY_ASD.FISCAL.OSS.GET_COMPANY_SUBSCRIBED_ERROR',

  GET_CF: 'MY_ASD.FISCAL.OSS.CF',
  GET_CF_LOADING: 'MY_ASD.FISCAL.OSS.CF_LOADING',
  GET_CF_SUCCESS: 'MY_ASD.FISCAL.OSS.CF_SUCCESS',
  GET_CF_ERROR: 'MY_ASD.FISCAL.OSS.CF_ERROR',

  REGISTER_INTEGRATION: 'MY_ASD.FISCAL.OSS.REGISTER_INTEGRATION',
  REGISTER_INTEGRATION_LOADING:
    'MY_ASD.FISCAL.OSS.REGISTER_INTEGRATION_LOADING',
  REGISTER_INTEGRATION_SUCCESS:
    'MY_ASD.FISCAL.OSS.REGISTER_INTEGRATION_SUCCESS',
  REGISTER_INTEGRATION_ERROR: 'MY_ASD.FISCAL.OSS.REGISTER_INTEGRATION_ERROR',

  GET_OSS_INTEGRATIONS: 'MY_ASD.FISCAL.OSS.INTEGRATIONS',
  GET_OSS_INTEGRATIONS_LOADING: 'MY_ASD.FISCAL.OSS.INTEGRATIONS_LOADING',
  GET_OSS_INTEGRATIONS_SUCCESS: 'MY_ASD.FISCAL.OSS.INTEGRATIONS_SUCCESS',
  GET_OSS_INTEGRATIONS_ERROR: 'MY_ASD.FISCAL.OSS.INTEGRATIONS_ERROR',

  RESET: 'MY_ASD.FISCAL.OSS.RESET',
  REFRESH_ERROR: 'MY_ASD.FISCAL.OSS.REFRESH_ERROR',
};
