import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  create(data: dataReqI) {
    return {
      type: actionTypes.CREATE,
      payload: data,
    };
  },
  getAll() {
    return {
      type: actionTypes.GET_ALL,
    };
  },
  getOne(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE,
      payload: data,
    };
  },
  remove(data: dataReqI) {
    return {
      type: actionTypes.REMOVE,
      payload: data,
    };
  },
  update(data: dataReqI) {
    return {
      type: actionTypes.UPDATE,
      payload: data,
    };
  },

  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
