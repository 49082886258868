import React, { FunctionComponent, useEffect, useState } from 'react';
import { Header, Modal, Grid, Form } from 'semantic-ui-react';
import Button from 'components/Button';
import Spacer from 'components/core/Spacer/Spacer';
import { chedI, consI } from 'interfaces/data/customsClerkInterfaces';
import { Field, Formik } from 'formik';
import { Input } from 'components/Form';
import Dropdown from 'components/Dropdown';

type ModalAddConsignmentProps = {
  open: boolean;
  handleConsignment: (values: consI) => void;
  handleClose: () => void;
  chedData?: chedI;
  chedDetail?: chedI;
};


const ModalAddConsignment: FunctionComponent<ModalAddConsignmentProps> = ({
  open,
  chedData,
  handleConsignment,
  handleClose,
}) => {
  const [consignment, setConsignment]: any = useState(null)
  const [city, setCity]: any = useState(null)

  useEffect(() => {
    if(chedData && chedData.Consignments && chedData.Consignments.length>0){
      setCity(chedData.Consignments[0].CustomsOffice.name)
      let tab = []
      chedData.Consignments.forEach((e) => {
        tab.push({
          progress: e.progress,
          endedAt: e.endedAt.split('T')[0],
          consignmentStatus: e.status,
          city: e.city,
        })
      })
      tab.length>0 && setConsignment(tab)
    }else{
      setCity(null)
    }
  }, [chedData])


  const actions = [
    {
      key: 1,
      value: 'consigned',
      text: 'Consigned',
    },
    {
      key: 2,
      value: 'freed',
      text: 'Freed goods',
    },
    {
      key: 3,
      value: 'destroyed',
      text: 'Destroyed goods',
    },
  ]

  const status = [
    {
      key: 1,
      value: 'start',
      text: 'Start',
    },
    {
      key: 2,
      value: 'end',
      text: 'End',
    },
   
  ]



  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header
        icon={`${city ? 'edit' : 'plus circle'}`}
        content={`${city ? 'Update' : 'Add'} Consignment ${city ? city : ''}`}
      />
      <Modal.Content>
      <Formik
        enableReinitialize={true} 
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{ 
          consignmentArray: consignment ? consignment : [
            {
              progress: '',
              endedAt: '',
              consignmentStatus: '',
              city: '' || null
            }
          ],     
        }}
        onSubmit={handleConsignment}
        //validationSchema={consignmentSchema}
        >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
          <Grid> 
            {values.consignmentArray.map((e, index) => (
              index === 0 && <Grid.Row columns='3' key={index}>
                  <Grid.Column>
                  <Field
                      mandatory
                      label="Consignment status"
                      name={`consignmentArray[${index}].progress`}
                      placeholder='Choose action'                 
                      options={status}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}              
                    />               
                  </Grid.Column>
                  <Grid.Column>
                  <Field
                    mandatory
                    label='Consignment End'
                    name={`consignmentArray[${index}].endedAt`}
                    component={Input}
                    type='date'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                  </Grid.Column>
                  <Grid.Column>
                    <Field
                      mandatory
                      label="Action"
                      name={`consignmentArray[${index}].consignmentStatus`}
                      placeholder='Consigned'
                      options={actions}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />               
                  </Grid.Column>
                </Grid.Row>
            ))}

            {consignment && consignment.length > 0 && (
              <Grid.Row columns='4' key={1}>
                 <Grid.Column>
                 <Field
                     label="Consignment status"
                     name={`consignmentArray.1.progress`}
                     placeholder='Choose action'                 
                     options={status}
                     component={Dropdown}
                     meta={{
                       errors,
                       touched,
                       setFieldValue,
                       setFieldTouched,
                     }} 
                     onChange={() => {
                      setFieldValue('consignmentArray.1.progress', values)
                     }}             
                   />               
                 </Grid.Column>
                 <Grid.Column>
                 <Field
                   label='Consignment End'
                   name={`consignmentArray.1.endedAt`}
                   component={Input}
                   type='date'
                   meta={{
                     errors,
                     touched,
                     setFieldValue,
                     setFieldTouched,
                   }}
                 />
                 </Grid.Column>
                 <Grid.Column>
                   <Field
                     label="Action"
                     name={`consignmentArray.1.consignmentStatus`}
                     placeholder='Choose action' 
                     options={ [
                      {
                        key: 1,
                        value: 'Freed',
                        text: 'Freed goods',
                      },
                      {
                        key: 2,
                        value: 'Destroyed',
                        text: 'Destroyed goods',
                      },
                    ]} 
                     component={Dropdown}
                     meta={{
                       errors,
                       touched,
                       setFieldValue,
                       setFieldTouched,
                     }}
                   />               
                 </Grid.Column>
                 <Grid.Column>
                   <Field
                     label="Customs office"
                     name={`consignmentArray.1.city`}
                     placeholder='Choose action' 
                     options={[
                      {
                        key: 1,
                        value: 'Eurotunnel',
                        text: 'Eurotunnel',
                      },
                      {
                        key: 2,
                        value: 'Calais port',
                        text: 'Calais port',
                      },
                      {
                        key: 3,
                        value: 'Le Havre',
                        text: 'Le Havre',
                      },
                      {
                        key: 4,
                        value: 'Dunkerque',
                        text: 'Dunkerque',
                      },                     
                      {
                        key: 5,
                        value: 'Entrepot',
                        text: 'Entrepot',
                      },
                      {
                        key: 6,
                        value: 'Other',
                        text: 'Other',
                      },
                    ]} 
                     component={Dropdown}
                     meta={{
                       errors,
                       touched,
                       setFieldValue,
                       setFieldTouched,
                     }}
                   />               
                 </Grid.Column>
               </Grid.Row> 
               )}
           
               
            </Grid>
            <Spacer height='20px' />
              <Button 
                size='large' 
                type='submit' 
                content='Save' 
              />
          </Form>)}
        </Formik>
      </Modal.Content>
    </Modal>
  
  );
};

export default ModalAddConsignment;
