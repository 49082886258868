import React from 'react';

import Button from 'components/Button';

const Index = ({ history, companySelected }) => {
  return (
    <div>
      <span>
        <h1>
          You cannot subscribe to a new service because you have unpaid
          invoices.
        </h1>
        <h1>
          To subscribe to a service, please pay your invoices in the "Invoice"
          section in your company's interface.
        </h1>
      </span>
      <br />
      <Button
        content='Go to company'
        onClick={() => history.push(`/companies/${companySelected}`)}
      />
    </div>
  );
};

export default Index;
