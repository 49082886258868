import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const errorsSelector = createErrorsSelector([]);
// Loading
const removeLoadingSelector = (state) => state.socket.loading.remove;
const loadingSelector = createLoadingSelector([]);
// Success
const successSelector = createSuccessSelector([]);
const removeCookieSuccessSelector = (state) => state.socket.success.removeCookie;
const removeSuccessSelector = (state) => state.socket.success.remove;

const connectionSelector = (state) => state.socket.connection;
const removeCookieSelector = (state) => state.socket.removeCookie;
const removeSelector = (state) => state.socket.remove;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  connectionSelector,
  removeCookieSelector,
  removeSelector,
  removeLoadingSelector,
  removeCookieSuccessSelector,
  removeSuccessSelector
};
