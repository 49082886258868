import React from 'react';

import { Tab, Container } from 'semantic-ui-react';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Permissions from './Permissions';

const Index = ({ operator }) => {
  return (
    <Tab.Pane>
      <Container>
        {operator !== null && (
          <Wrapper padding='5rem 0 0 0'>
            <Permissions
              permissions={operator.Permissions}
              operatorId={operator.id}
            />
          </Wrapper>
        )}
      </Container>
    </Tab.Pane>
  );
};

export default Index;
