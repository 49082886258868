import styled from "@emotion/styled";

const BodyStyle = styled.div((props: any) => {
  return {
    position: "relative",
    minHeight: "100%",
    overflow: "hidden",
    display: "flex",
    boxSizing: "border-box",
    color: props.theme.typo,
  };
});

export default BodyStyle;
