import React from 'react';
import { Divider, Header, Modal } from 'semantic-ui-react';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import { Message, Span } from './styles';


const ModalCloseCollectionbox = ({
  open,
  collectionboxId,
  declarationType,
  handleCloseCollectionbox,
  handleClose,
}: ModalCloseCollectionboxProps) => {
  return (
    <Modal open={open} onClose={handleClose} closeIcon size='tiny'>
      <Header icn='cancel' content={`Collection box closing`} />
     
          <Modal.Content>
        
            <Message>Are you sure you want to close this declaration ?
              <Span>You will no longer be allowed to make changes</Span>
            </Message>
            <Divider hidden />
             <Wrapper display='flex' justifyContent='center' width='100%'>
              <Button
                type='button'
                color='blue'
                size='small'
                margin='5px'
                content='Yes'
                onClick={() => handleCloseCollectionbox(true, collectionboxId)}
              />
              <Button
                type='button'
                color='red'
                size='small'
                margin='5px'
                content='No'
                onClick={() => handleCloseCollectionbox(false, collectionboxId)}
              />
            </Wrapper>
            
          </Modal.Content>
      
    </Modal>
  );
};

interface ModalCloseCollectionboxProps {
  open: boolean;
  collectionboxId: string;
  declarationType: string;
  handleCloseCollectionbox: (response: boolean, collectionboxId: string) => void;
  handleClose: () => void;
}

export default ModalCloseCollectionbox;
