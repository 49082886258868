import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Header, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import { Link } from 'react-router-dom';
import Search from 'components/Search';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths as pathToCollectionbox } from 'routes/fiscal/collectionBox';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';

const Index = ({ 
  dispatch,
  history,
  match, 
  user, 
  companies,
  companiesLoading
 }) => {

  const userAdminOrOp = user && (user.operator || user.admin);
  const iso = match.params.iso.toLowerCase()
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [resetSearch, setresetSearch] = useState(false);

  useEffect(() => {
    dispatch(actions.collectionbox.getCompaniesByIso({
      params: {
        companyId: 49,
        iso: iso
      }
    }));
    return () => {
      dispatch(
        actions.collectionbox.reset(['companiesByIso', 'error'])
      );
    };
  }, [dispatch, iso]);


  useEffect(() => {
    setresetSearch(false);
    if(companies){
      setdata(_.orderBy(companies, ['name'], ['asc']));
    }
  }, [companies]);


  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['name'], ['asc']));
  };

  return (
    <Wrapper overflow='auto' width='80%' margin='auto'>
       <Divider hidden />
        <Wrapper display='flex'>
        <LinkedButton 
          labelPosition='left'
          icon='angle left'
          size='medium'
          type='button'
          content='Back'
          path={`${pathToCollectionbox.collectionbox}`}
        />
        </Wrapper>
      <Divider hidden />
      <Wrapper padding='2rem' textAlign='center'>
        <Header size='huge'>
          EMEBI - Collectionbox
          <Header.Subheader>(operators - companies)</Header.Subheader>
        </Header>
        <Wrapper>
        <Divider hidden />
        </Wrapper>
      </Wrapper>
      {((
        data === null || 
        companiesLoading || 
        companies === null
        ) && <Loader />
      ) || (
      <Wrapper>
          <Wrapper display='flex' justifyContent='space-between'>
          <Search
            dataSource={companies}
            onSearchResultChange={onSearchResultChange}
            setResetSearch={setresetSearch}
          />
          </Wrapper>
          <Table size='large' columns='3' sortable selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'name' ? direction : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'email' ? direction : null}
                  onClick={() => handleSort('email')}
                >
                  E-mail
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                  Country
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {(data.length === 0 && <EmptyTable colNumber={3} />) ||
                data.map((company, i) => {
                  return (
                    <Table.Row key={i}>
                      <Link
                        to={{pathname: `/fiscal/collectionbox/country/:iso/:companyId`
                        .replace(':companyId', company.id).replace(':iso', iso), state: {country: 'country', iso: iso}}}
                        className='table-link'
                      >
                        <Table.Cell>{company.name}</Table.Cell>
                        <Table.Cell>{company.email}</Table.Cell>
                        <Table.Cell>{iso.toUpperCase()}</Table.Cell>
                      </Link>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.collectionbox.getCompaniesByIsoSelector(state),
    companiesLoading: selectors.collectionbox.getCompaniesByIsoLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);

