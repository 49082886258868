import { routesApi } from "config/api";
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    getCompaniesByIso(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.companiesByIso;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data,
      });
    },
    getContactsSubscribed(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.subscribed;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
      });
    },
    getCategories(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.categories;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
      });
    },
    readChat(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.readChat;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
      });
    },
    subscribe(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.subscribe;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
      });
    },
    unsubscribe(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.unsubscribe;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
      });
    },
    getPermissions(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.perms;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
      });
    },
    getObligations(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.obligations.getObligation;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
      });
    },
    createObligation(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.obligations.createObligation;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    getCompanySubscribed() {
      const collectionboxApi = routesApi.fiscal.collectionbox.companySubscribed;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
      });
    },
    splitDocument(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.split;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    groupFiles(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.groupFiles;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    deleteFiles(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.deleteFiles;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data,
      });
    },
    changeFileState(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.changeFileState;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data,
      });
    },
    renameSendChunk(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.renameSendChunk;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    createSingleFile(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.singlefile;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    filterTagArchive(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.filterTagArchive;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    getCollectionboxesByFilters(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.getByFilters;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        query: data.query
      });
    },
    filterTag(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.filterTag;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    bulkFilter(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.bulkFilter;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    archiveAll(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.archiveAll;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    addCollectionboxDocuments(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.addDocuments;
      const form: any = new FormData();
      form.append('type', data.data.type);
      form.append('split', data.data.split);
      data.data.documents.forEach((e) => {
        e.files.forEach((file) => {
          form.append('documents', file);
        })
        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: e.id,
            number: e.number,
          })
        );
      });

      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    getDocsFromCat(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.docsFromCat;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        query: data.query
      });
    },
    deleteDocument(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.deleteDoc;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params
      });
    },
    downloadDocument(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.getDocument;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data,
        query: data.query,
        responseType: 'blob',
      });
    },
    getAllCollectionboxes(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.getAllCollectionboxes;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        query: data.query
      });
    },
    getOneCollectionbox(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.getOneCollectionbox;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
      });
    },
    getArchiveFile(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.getArchiveFile;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    getFilesFullList(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.getFilesFullList;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params
      });
    },
    updateCollectionbox(data: dataReqI) {
      const collectionboxApi = routesApi.fiscal.collectionbox.updateCollectionbox;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    handleTagOperations(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.tagOperations;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    createNoneCollectionbox(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.createNoneCollectionbox;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    createClientCollectionbox(data: dataReqI) {
      const createcollectionboxFile = routesApi.fiscal.collectionbox.createCollectionbox;
      const form: any = new FormData();
      data.data.none && form.set('none', data.data.none);
      data.data.contact && form.set('contact', data.data.contact);
      data.data.contactName && form.set('contactName', data.data.contactName);
      data.data.ownerId && form.set('ownerId', data.data.ownerId);
      form.set('type', data.data.type);
      form.set('taxPeriod', data.data.taxPeriod);
      form.set('country[]', JSON.stringify({
        iso: data.data.country.iso, 
        periodicity: data.data.country.periodicity,
        Country_Id: data.data.country.Country_Id
      }));
      data.data.clientReference && 
        form.set('clientReference', data.data.clientReference);
      data.data.documents.forEach((e) => {   
        e.files.forEach((file) => {
          form.append('documents', file);
        })
        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: e.id,
            number: e.number,
          })
        );
      });

      return api.request({
        method: createcollectionboxFile.method,
        route: createcollectionboxFile.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    // createCollectionbox(data: dataReqI) {
    //   const createcollectionboxFile = routesApi.customs.declaration.createCollectionbox;
    //   const form: any = new FormData();
    //   form.set('none', data.data.none);
    //   form.set('declarationType', data.data.declarationType);
    //   form.set('period', data.data.period);
    //   form.set('filingCountry', data.data.filingCountry);
    //   form.set('flow', data.data.flow);
    //   form.set('clientReference', data.data.clientReference);
    //   form.set('type', data.data.type);
    //   data.data.documents.forEach((document) => {
    //     form.append('documents', document.file);
    //     form.append(
    //       'documentCategories[]',
    //       JSON.stringify({
    //         id: document.id,
    //         number: document.number,
    //       })
    //     );
    //   });

    //   return api.request({
    //     method: createcollectionboxFile.method,
    //     route: createcollectionboxFile.path,
    //     data: form,
    //     params: data.params,
    //     headers: {
    //       'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
    //     },
    //   });
    // },
    updateStep(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.updateStep;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    updateDocument(data: dataReqI) {
      const fiscalVatApi = routesApi.fiscal.collectionbox.updateDocument;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params
      });
    },
    followCollectionbox(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.followCollectionbox;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    settings(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.settings;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
    getSettings(data: dataReqI){
      const collectionboxApi = routesApi.fiscal.collectionbox.getSettings;
      return api.request({
        method: collectionboxApi.method,
        route: collectionboxApi.path,
        params: data.params,
        data: data.data
      });
    },
  };
};
