import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';

import {
  Grid,
  Header,
  Modal,
  Divider,
  Form,
  Dropdown,
  Icon
} from 'semantic-ui-react';
import Loader from 'components/Loader';
import Iframe from './Iframe';
import { 
  FormArea,
  GridWrapper,
  Left, 
  Listing, 
  ListingEmpty, 
  ListingWrapper, 
  Nav, Right, Title, content, iframe } from './styles';
import Button from 'components/Button';
import { File } from 'components/Form';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import { Wrapper } from 'components/core';


const Index = ({
  companyId,
  deliveryId,
  storage,
  isSplitOpen,
  files,
  filesLoading,
  categoriesDocuments,
  categoriesLoading,
  handleClose,
  dispatch,
  userAdminOrOp,
}: SplitDocumentProps) => {

  const [documentUrl, setDocumentUrl]: any = useState({url: ''})
  const [categories, setCategories]: any = useState(null)
  const [selectedMenu, setSelectedMenu]: any = useState(null)
  const [list, setList]: any = useState([])
  const [load, setLoader]: any = useState({save: false, delete: false, split: false})

  let formikArray: any = useMemo(() => [], [])

  const documentOptions = [
    {
      key: 0,
      value: 'CMR',
      text: 'CMR',
    },
    {
      key: 1,
      value: 'BillOfLading',
      text: 'Bill of lading',
    },
    {
      key: 2,
      value: 'AirwayBill',
      text: 'Airway bill',
    },
    {
      key: 3,
      value: 'CarrierInvoice',
      text: "Carrier's invoice",
    },
    {
      key: 4,
      value: 'NotarialCertificate',
      text: 'Notarial certificate',
    },
    {
      key: 5,
      value: 'EvidenceOfPayment',
      text: 'Evidence of payment',
    },

    {
      key: 6,
      value: 'AttestationOfReceipt',
      text: 'Attestation of receipt',
    },
    {
      key: 7,
      value: 'Other',
      text: 'Other',
    },
  ]

  useEffect(() => {
    if(isSplitOpen){
      dispatch(
        actions.quickfixes.handleSplitProof({
          params: {
            companyId,
            deliveryId,
          },
          data: {
            companyType: 'seller',
            action: 'load'
          },
        })
      );
  
      dispatch(actions.socket.subscribehandleSplitProof(deliveryId))

      return () => {
        dispatch(actions.socket.unsubscribehandleSplitProof(deliveryId))
        dispatch(actions.quickfixes.reset(['splitProofs', 'errors']))
      }
    }

  }, [dispatch, companyId, deliveryId, isSplitOpen])

  useEffect(() => {
    if(files && files.Contents){
      setList(files.Contents.map((file, index) => {
        return {
          key: index,
          name: file.Key.split('/')[3],
          url: file.Key
        }
      }))
      setLoader(prevState => ({
        ...prevState,
        save: false,
        split: false,
        delete: false
      }))
    }else{
      setList([])
      setLoader(prevState => ({
        ...prevState,
        save: false,
        split: false,
        delete: false
      }))
    }
   
  }, [files])
  

  const uploadSplitProof = (values) => {
    setLoader(prevState => ({
      ...prevState,
      split: true
    }))
    dispatch(
      actions.quickfixes.handleSplitProof({
        params: {
          companyId,
          deliveryId,
        },
        data: {
          file: values.file,
          companyType: 'seller',
          action: 'split'
        },
      })
    );
  };

  const deleteSplitProof = () => {
    setLoader(prevState => ({
      ...prevState,
      delete: true
    }))
    dispatch(
      actions.quickfixes.handleSplitProof({
        params: {
          companyId,
          deliveryId,
        },
        data: {
          companyType: 'seller',
          action: 'delete'
        },
      })
    );
  };


  useEffect(() => {
    if(categoriesDocuments){
      setCategories(categoriesDocuments.map((e, index) => {
        return {
          key: index,
          value: e.id,
          text: e.fr
        }
    }))
    }
  }, [categoriesDocuments])

  const change = useCallback((index, name, file) => {
    formikArray[index] = {
      type: name, 
      id: index, 
      file: file
    }
  }, [formikArray])

  const save = useCallback(() => {
    if(formikArray.length){
      setLoader(prevState => ({
        ...prevState,
        save: true
      }))
      dispatch(
        actions.quickfixes.handleSplitProof({
          params: {
            companyId,
            deliveryId,
          },
          data: {
            categories: formikArray,
            companyType: 'seller',
            action: 'save'
          },
        }));
        return () => {
          dispatch(actions.quickfixes.reset(['splitProofs', 'errors']))
        }
    }
  }, [
    dispatch, 
    formikArray, 
    companyId, 
    deliveryId
  ])

 

  return (
    <Modal open={isSplitOpen} onClose={handleClose} style={{'position': 'relative', 'height': '90%'}}  size='fullscreen' closeIcon>
      <Header icon='plus circle' content='Manage tags'/>
      <Modal.Content style={content}>
      

      
       
      <Grid columns={2} style={GridWrapper}>
          <Grid.Column width={7} style={ListingWrapper}> 
          <FormArea>

          <Formik
            initialValues={{
              file: undefined,
            }}
            onSubmit={uploadSplitProof}
            //validationSchema={podProofUpload}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Modal.Content>
                <Form onSubmit={handleSubmit}>
                  <Field
                    label={<MandatoryFieldLabel content='File' />}
                    name='file'
                    component={File}
                    error={errors.file}
                    touched={touched.file}
                    meta={{ setFieldValue, setFieldTouched }}
                  />
                  <Divider hidden />
                  <Button
                  disabled={load.split}
                    type='submit'
                    {...((load.split && {
                      icon: <Icon loading name='spinner' />
                    }) || {
                      icon: <Icon name='check' />
                    })}                   
                    content='Submit'
                  />
                </Form>
              </Modal.Content>
            )}
          </Formik>
  
          </FormArea>
              {((filesLoading) && <Loader />) || (
                <>
                <Listing>              
                {list.map((file, index) => {                                 
                  return (
                    <Nav key={index} className={selectedMenu === index ? 'pdfSelected': ''}> 
                    <Left> 
                      <Title
                        onClick={() => {
                          setDocumentUrl(prevState => ({
                            ...prevState,
                              url: file.url
                            }))
                          setSelectedMenu(index)
                        }}
                      >{file.name}</Title>
                    </Left> 
                    <Right>
                      <Dropdown
                        key={`drop_${index}`}
                        placeholder='State'
                        field={`name_${index}`}
                        selection 
                        options={documentOptions}
                        onChange={async (e, {value}) => {
                          if(e){ 
                            await change(index, value, file)                         
                          }                 
                        }}
                      />
                    </Right> 
                    </Nav>
                  )})
                }
                {list.length === 0 && (
                  <ListingEmpty>Empty</ListingEmpty>
                )}
              </Listing>
            
             
         
             </>
              )}      
          </Grid.Column>
      <Grid.Column width={9} style={{'position': 'relative'}}>
        {(documentUrl.url !== '' && (
          <Iframe 
            style={iframe} 
            src={documentUrl}
            companyId={companyId}
            storage={storage}
            dispatch={dispatch}
            >
          </Iframe>
        )) || (<ListingEmpty>Viewer</ListingEmpty>)}
      </Grid.Column>
      </Grid>
      <Divider hidden/>
      <Wrapper>
      <Button
         disabled={load.save}
         {...(load.save && {
           icon: <Icon loading name='spinner' />,
         })}
          floated='left'
          type='button'
          content='Save documents'
          onClick={() => save() }
          color='blue'
        />
         <Button
           disabled={load.delete}
           {...(load.delete && {
             icon: <Icon loading name='spinner' />,
           })}
          floated='left'
          type='button'
          content='Delete'
          onClick={() => deleteSplitProof() }
          color='red'
        />
      </Wrapper>
    </Modal.Content>
  </Modal>);
};

interface SplitDocumentProps {
  companyId,
  deliveryId,
  isSplitOpen,
  storage,
  handleClose: () => void;
  handleTagOperations: () => void;
  finalize: (listFiles, session) => void;
  dispatch: (value: any) => void;
  filesLoading,
  files,
  filterTagArchiveLoading,
  listFiles,
  listFilesLoading,
  groupFilesLoading,
  deleteFilesLoading,
  fileStateLoading,
  userAdminOrOp,
  categoriesDocuments,
  categoriesLoading,
}

const mapStateToProps = (state) => {
  return {
    files: selectors.quickfixes.uploadSplitProofsSelector(state),
    filesLoading: selectors.quickfixes.uploadSplitProofsLoadingSelector(state),
    categoriesDocuments: selectors.intrastat.getDocsFromCatSelector(state),
    categoriesLoading: selectors.intrastat.getDocsFromCatLoadingSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(Index);
