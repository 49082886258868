let actionTypes = {
  GET_ALL_TRANSPORTERS_BY_TOKEN: "MY_ASD.TOKEN.GET_ALL_TRANSPORTERS_BY_TOKEN",
  GET_ALL_TRANSPORTERS_BY_TOKEN_LOADING:
    "MY_ASD.TOKEN.GET_ALL_TRANSPORTERS_BY_TOKEN_LOADING",
  GET_ALL_TRANSPORTERS_BY_TOKEN_SUCCESS:
    "MY_ASD.TOKEN.GET_ALL_TRANSPORTERS_BY_TOKEN_SUCCESS",
  GET_ALL_TRANSPORTERS_BY_TOKEN_ERROR:
    "MY_ASD.TOKEN.GET_ALL_TRANSPORTERS_BY_TOKEN_ERROR",

  CREATE_TRANSPORTER_BY_TOKEN: "MY_ASD.TOKEN.CREATE_TRANSPORTER_BY_TOKEN",
  CREATE_TRANSPORTER_BY_TOKEN_LOADING:
    "MY_ASD.TOKEN.CREATE_TRANSPORTER_BY_TOKEN_LOADING",
  CREATE_TRANSPORTER_BY_TOKEN_SUCCESS:
    "MY_ASD.TOKEN.CREATE_TRANSPORTER_BY_TOKEN_SUCCESS",
  CREATE_TRANSPORTER_BY_TOKEN_ERROR:
    "MY_ASD.TOKEN.CREATE_TRANSPORTER_BY_TOKEN_ERROR",

  GET_TRANSPORTER_VATS: "MY_ASD.TOKEN.GET_TRANSPORTER_VATS",
  GET_TRANSPORTER_VATS_LOADING: "MY_ASD.TOKEN.GET_TRANSPORTER_VATS_LOADING",
  GET_TRANSPORTER_VATS_SUCCESS: "MY_ASD.TOKEN.GET_TRANSPORTER_VATS_SUCCESS",
  GET_TRANSPORTER_VATS_ERROR: "MY_ASD.TOKEN.GET_TRANSPORTER_VATS_ERROR",

  RESET: "MY_ASD.TOKEN.RES",
  REFRESH_ERROR: "MY_ASD.TOKEN.REFRESH_ERROR",
};

export default actionTypes;
