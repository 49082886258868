import React, { FunctionComponent } from 'react';

import InputErrorLabel from 'components/core/InputErrorLabel/InputErrorLabel';
import { CheckboxStyled, CheckboxWrapper } from './CheckboxStyles';

const Checkbox: FunctionComponent<CheckboxProps> = ({
  label,
  name,
  id,
  disabled,
  className,
  value,
  checked,
  error,
  float,
  checkHide,
  onChange,
}) => {
  return (
    <CheckboxWrapper>
      <CheckboxStyled
        disabled={disabled}
        type='checkbox'
        id={id}
        name={name}
        checked={checked ?? false}
        onChange={onChange}
        className={className}
        error={error}
        float={float}
        checkHide={checkHide}
      />
      {label && <label htmlFor={id}>{label}</label>}
      {error && <InputErrorLabel message={error} />}
    </CheckboxWrapper>
  );
};

export interface CheckboxProps {
  name: string;
  error?: string;
  checked?: boolean;
  value?: boolean;
  id?: string;
  className?: string;
  disabled?: true;
  label?: string;
  float?: string;
  checkHide?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default Checkbox;
