export default {
  "service.category.fiscal": "Fiscal",
  "service.category.customs": "Douane",
  "service.category.social": "Social",
  "service.category.data": "Data",
  "service.category.client": "Clients",
  "service.name.fiscal.declarationTVA": "Declaration TVA",
  "service.name.customs.declaration": "Declaration",
  "service.name.user": "Utilisateurs"
};
