import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  addUser(data: dataReqI) {
    return {
      type: actionTypes.ADD_USER,
      payload: data,
    };
  },
  getUserCompanies(data: dataReqI) {
    return {
      type: actionTypes.GET_USER_COMPANIES,
      payload: data,
    };
  },
  removeUser(data: dataReqI) {
    return {
      type: actionTypes.REMOVE_USER,
      payload: data,
    };
  },
  updateUser(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_USER,
      payload: data,
    };
  },
  updateUserPermissions(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_USER_PERMISSIONS,
      payload: data,
    };
  },
  getUserPermissions(data: dataReqI) {
    return {
      type: actionTypes.GET_USER_PERMISSIONS,
      payload: data,
    };
  },
  getOneUser(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_USER,
      payload: data,
    };
  },
  getAllUsers(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_USERS,
      payload: data,
    };
  },

  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
