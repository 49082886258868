export const customsCategories = {
  transit: 'Transit',
};

export const customsDeclarationSteps = [
  'Awaiting processing',
  'T document added',
  'Clearance completed',
];

export const customsDeclarationStepColors = ['#cd2121', '#dda80b', '#21ba45'];
