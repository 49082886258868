// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import NavbarDropdownLanguage from '../navbarDropdown/navbarDropdownLanguage';
import NavbarDropdown from '../navbarDropdown/navbarDropdown';

// Stylesheets
import './navbar.css';

/**
 * @name navbar.tsx
 *
 * @path /components/navbar/navbar.tsx
 *
 * @description Defines navbar's content
 */
const Navbar: React.FC = ({ user }: any) => {
  // Parameter that must be used before ech translatable string to apply the good language version
  const { t } = useTranslation();

  let companyId: number;

  async function checkIfUserHasOneCompany(userId: number) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/userHasOneCompany`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: userId }),
        }
      );

      const result = await response.json();

      return result.companyId;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  // Defines the action that must be executed when user performed a click on information button
  const handleCompanyInformationClick = async () => {
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/company/details/` + companyId;
  };

  // Defines the action that must be executed when user performed a click on employees button
  const handleCompanyEmployeesClick = async () => {
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/company/employees/` + companyId;
  };

  const handleClickCompaniesButton = () => {
    window.location.href = `/spw/companies`;
  };

  const handleBackToMyASDClick = () => {
    sessionStorage.clear();
    window.location.href = `/`;
  };

  // All company button's options
  const companyOptions = [
    {
      label: t('headerStrings.companyButtonStrings.information'),
      action: handleCompanyInformationClick,
    },
    {
      label: t('headerStrings.companyButtonStrings.employees'),
      action: handleCompanyEmployeesClick,
    },
  ];

  const [isAdminOrOperator, setIsAdminOrOperator] = useState<boolean>(false);

  useEffect(() => {
    setIsAdminOrOperator(user.operator || user.admin);
  }, [isAdminOrOperator]);

  return (
    <div className='navbarContainer'>
      <div className='navbarLeftContainer'>
        {isAdminOrOperator == false ? (
          <NavbarDropdown
            title={t('headerStrings.companyButtonStrings.company')}
            options={companyOptions}
            isRight={false}
          />
        ) : (
          <button className='moreButton' onClick={handleClickCompaniesButton}>
            {t('headerStrings.companyButtonStrings.companies')}
          </button>
        )}
      </div>
      <div className='navbarRightContainer'>
        <NavbarDropdownLanguage />
        <button className='moreButton' onClick={handleBackToMyASDClick}>
          {t('headerStrings.moreButtonStrings.backToMyASD')}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Navbar);
