import Commercial from 'views/InternalTools/Commercial';
import paths from './paths';

export default [
  {
    private: true,
    path: paths.commercial,
    component: Commercial,
    exact: true,
    authorizedRoles: ['operator'],
  },
];
