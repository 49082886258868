import { routesApi } from "config/api";
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    getAllCountries() {
      const getAllCountriesApi = routesApi.data.allCountries;
      return api.request({
        method: getAllCountriesApi.method,
        route: getAllCountriesApi.path
      });
    },
    getEuCountries() {
      const getEuCountriesApi = routesApi.data.euCountries;
      return api.request({
        method: getEuCountriesApi.method,
        route: getEuCountriesApi.path
      });
    },
    getCountryVatRate(data: dataReqI) {
      const getCountryVatRateApi = routesApi.data.countryVatRate;
      return api.request({
        method: getCountryVatRateApi.method,
        route: getCountryVatRateApi.path,
        data: data.data
      });
    }
  };
};
