import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  createSubscriptionPayment(data: dataReqI) {
    return {
      type: actionTypes.CREATE_SUBSCRIPTION_PAYMENT,
      payload: data,
    };
  },
  createToken(data: dataReqI) {
    return {
      type: actionTypes.CREATE_TOKEN,
      payload: data,
    };
  },
  updateToken(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_TOKEN,
      payload: data,
    };
  },
  unsubscribe(data: dataReqI) {
    return {
      type: actionTypes.UNSUBSCRIBE,
      payload: data,
    };
  },
  cancelUnsubscribe(data: dataReqI) {
    return {
      type: actionTypes.CANCEL_UNSUBSCRIBE,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
};
