import React, { useState, FunctionComponent } from 'react';
import { Divider, Form, Modal } from 'semantic-ui-react';
import { Field } from 'formik';
import { Formik } from 'formik';

import Button from 'components/Button';
import Photo from 'components/Form/Photo/photo';
import { File as FileInput } from 'components/Form';
import TextCustom from 'components/core/TextCustom/TextCustom';
import Wrapper from 'components/core/Wrapper/Wrapper';

import { photoSchema } from 'interfaces/formSchema/customsClerk';

type PhotoModalProps = {
  open: boolean;
  handleSubmit: (values: any) => void;
  handleClose: () => void;
};

const PhotoModal: FunctionComponent<PhotoModalProps> = ({
  open,
  handleClose,
  handleSubmit,
}) => {
  const [hidePhoto, sethidePhoto] = useState<boolean>(false);
  const [displayField, setdisplayField] = useState<boolean>(true);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setdisplayField(true);
      }}
      closeIcon
    >
      <Wrapper maxWidth='90%' margin='2rem auto' textAlign='center'>
        <Formik
          initialValues={{
            photo: null,
          }}
          onSubmit={handleSubmit}
          validationSchema={photoSchema}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
          }: any) => (
            <Form onSubmit={handleSubmit}>
              <Field
                component={Photo}
                name='photo'
                hidePhoto={hidePhoto}
                mandatory
                onCapture={() => {
                  sethidePhoto(false);
                }}
                onCameraDisplay={() => {
                  setdisplayField(false);
                }}
                onCameraHide={() => {
                  setdisplayField(true);
                }}
                onClear={() => {
                  setdisplayField(true);
                }}
                meta={{
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  handleSubmit,
                }}
              />

              {displayField && (
                <>
                  <TextCustom>Or</TextCustom>
                  <Field
                    mandatory
                    name='photo'
                    component={FileInput}
                    meta={{
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                      handleSubmit,
                    }}
                    action={() => sethidePhoto(true)}
                    buttonType='button'
                  />
                </>
              )}
              <Divider hidden />
              <Button
                size='large'
                icon='right arrow'
                labelPosition='right'
                color='blue'
                content='Ok'
                margin='5px'
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Modal>
  );
};

export default PhotoModal;
