import { Field, Formik } from 'formik';
import React from 'react';
import { Divider, Form, Header, Modal } from 'semantic-ui-react';

import Button from 'components/Button';
import TextArea from 'components/Form/TextArea';

import { reasonForCancel } from 'interfaces/formSchema/customsValidators';

const ModalCancelVat = ({
  open,
  handleCancelVat,
  handleClose,
}: ModalCancelVatProps) => {
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='cancel' content='Reason for cancellation' />
      <Formik
        initialValues={{
          reason: '',
        }}
        onSubmit={handleCancelVat}
        validationSchema={reasonForCancel}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
          <Modal.Content>
            <Form onSubmit={handleSubmit}>
              <Field
                mandatory
                name={`reason`}
                label='Reason:'
                component={TextArea}
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
              />
              <Divider hidden />
              <Button
                disabled={isSubmitting}
                type='submit'
                content='Submit'
                color='green'
              />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

interface ModalCancelVatProps {
  open: boolean;
  handleCancelVat: (values: { reason: string }) => void;
  handleClose: () => void;
}

export default ModalCancelVat;
