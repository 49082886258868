import actionTypes from "./actionTypes";
import { dataReqI } from 'interfaces';

export default {
  getAllChats(data: dataReqI) {
    return {
      type: actionTypes.GET_CHATS,
      payload: data,
    };
  },
  postChats(data: dataReqI) {
    return {
      type: actionTypes.POST_CHATS,
      payload: data,
    };
  },
  readChat(data: dataReqI) {
    return {
      type: actionTypes.READ_CHAT,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys
      }
    };
  }
};
