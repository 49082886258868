import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Divider, Header, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import { Main } from 'components/Layouts';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths } from 'routes/fiscal/repository';
import ClickableText from 'components/ClickableText';
import LinkButton from 'components/core/LinkButton/LinkButton';
import NewDeclaration from './New';


const Index = ({ dispatch, user, subscribed, countries, countriesLoading }) => {
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [companyList, setCompaniesList] = useState(null);
  const [resetSearch, setresetSearch] = useState(false);
  const [countryList, setCountriesList] = useState(null);
  const [modalStatus, setmodalStatus] = useState(null);

  useEffect(() => {
    if(user){
      dispatch(actions.fiscalVat.getOperatorAssignedCountries({
        params: {
          operatorId: user.admin ? '00' : user.id
        }
      }))
    }
    dispatch(
      actions.fiscalVat.getCompanySubscribed()
    );

    return () => {
      dispatch(actions.fiscalVat.reset(['subscribed', 'getOperatorsCountries', 'error']));
      dispatch(actions.data.reset(['allCountries', 'error']));
    };
  }, [dispatch, user]);

  useEffect(() => {
    countries && setCountriesList(_.orderBy(countries, ['name'], ['asc']));
    subscribed && setCompaniesList(_.orderBy(subscribed, ['name'], ['asc']));
    resetSearch && setresetSearch(false);
  }, [subscribed, resetSearch, countries]);

  
  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setCountriesList(_.sortBy(countryList, [name]));
      return;
    }
    setCountriesList(countryList.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };


  return (
    <Main>
      <Divider hidden />
      <Wrapper padding='2rem'>
        <Header size='huge' textAlign='center'>Vat declarations operator
          <Header.Subheader>({user.firstName} {user.lastName})</Header.Subheader>
        </Header>
        <Wrapper textAlign='center'>(
        {(navigator.language === 'fr-FR' && (
          <ClickableText
            color='black'
            onClick={() => {
              window.open('https://my-asd.s3-eu-west-1.amazonaws.com/customs/Guide_de_l_utilisateur_-_Déclaration_en_douane.pdf', '_blank');
            }}
          >Guide d'utilisation</ClickableText>
          )) || (
          <ClickableText
            color='black'
            onClick={() => {
              window.open('https://my-asd.s3-eu-west-1.amazonaws.com/customs/User_guide_-_Customs_declaration.pdf', '_blank');
            }}
          >User guide</ClickableText>
          )})
        <Divider hidden />
      <Wrapper display='flex' justifyContent='center'>
        <LinkButton
          content='Go to monitoring'
          color='blue'
          path={paths.repositoryMonitoring}
        />
      </Wrapper>    
    </Wrapper>   
  </Wrapper> 
    {(countriesLoading || countryList === null || companyList === null) && (
      <Loader content='Loading' />
    )}
    {modalStatus && (
      <NewDeclaration
        open={modalStatus}
        handleClose={() => setmodalStatus(false)}
      />
    )}
    <Divider/>
    <Container>
      <Table size='large' columns='3' sortable selectable striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={column === 'name' ? direction : null}
            onClick={() => handleSort('name')}
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'iso' ? direction : null}
            onClick={() => handleSort('iso')}
          >
            Country
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
      {((companyList === null || companyList.length === 0) && <EmptyTable colNumber={2}/>) ||
        companyList.map((company, i) => {
          return (                  
            <Table.Row key={i}>
              <Link to={{
                pathname: paths.repositoryList.replace(
                  ':companyId',
                  company.id
                ),
                state: {company: company}
              }}
                className='table-link'>
                <Table.Cell>{company.name}</Table.Cell>
                <Table.Cell>{company.country}</Table.Cell>
              </Link>
            </Table.Row>
          )                
      })}
      </Table.Body>
    </Table>
    </Container>
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.fiscalVat.getOperatorAssignedSelector(state),
    countriesLoading: selectors.fiscalVat.getOperatorAssignedLoadingSelector(state),
    subscribed: selectors.fiscalVat.subscribedSelector(state),
    subscribedLoading: selectors.fiscalVat.subscribedLoadingSelector(state),
    errorHandlerClients: selectors.company.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
