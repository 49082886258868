export default {
  "button.connection": "Connection",
  "button.next": "Next",
  "button.back": "Back",
  "button.registration": "Registration",
  "button.login": "Connection",
  "button.send": "Send",
  "button.save": "Save",
  "button.submit": "Submit",
  "button.download": "Download",
};
