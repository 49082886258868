import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  const ssrApi = routesApi.internalTools.ssr;
  return {
    ticketSsr(data: dataReqI) {
      return api.request({
        method: ssrApi.ticketSsr.method,
        route: ssrApi.ticketSsr.path,
        data: data.data,
      });
    },
    sendResults(data: dataReqI) {
      return api.request({
        method: ssrApi.sendResults.method,
        route: ssrApi.sendResults.path,
        data: data.data,
      });
    },
    getFormSchemas() {
      return api.request({
        method: ssrApi.getFormSchemas.method,
        route: ssrApi.getFormSchemas.path,
      });
    },
  };
};
