import React, { useEffect, useState } from 'react';

import './uploadConfirmation.css';
interface UploadConfirmationProps {
  message: string;
  duration: number;
  onClose: () => void;
}

const UploadConfirmation: React.FC<UploadConfirmationProps> = ({
  message,
  duration,
  onClose,
}) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setFadeOut(true), duration - 500);
    const closeTimer = setTimeout(onClose, duration);
    return () => {
      clearTimeout(timer);
      clearTimeout(closeTimer);
    };
  }, [duration, onClose]);

  return (
    <div
      className={`uploadConfirmationStyle ${fadeOut ? 'fadeOut' : 'fadeIn'}`}
    >
      {message}
    </div>
  );
};

export default UploadConfirmation;
