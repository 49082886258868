import { orderBy } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Divider, Form, Modal } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import Segment from 'components/core/Segment/Segment';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Wrapper from 'components/core/Wrapper/Wrapper';
import NewCompanyModal from 'views/Companies/New/NewCompanyModal/NewCompanyModal';
import { Input } from 'components/Form';
import Signature from 'components/Form/Signature/signature';
import Loader from 'components/Loader';
import TextCustom from 'components/core/TextCustom/TextCustom';
import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import { startControlSchema } from 'interfaces/formSchema/customsClerk';
import SelectedCompanyInfo from './components/SelectedCompanyInfo/SelectedCompanyInfo';
import splitDateTime from 'services/helper/splitDateTime';
import { paths } from 'routes/customs/customsHelper';
import AddContact from 'components/AddContact/addContact';

const CreateFileStep = ({

  intl,
  dispatch,
  history,
  match,
  manageSteps,
  companies,
  company,
  loadingHandlerCompany,
  contacts,
  contactsTransporters,
  contactsLoading,
  contactsTransportersLoading,
  contactError,
  declarationInProgress,
  declarationInProgressLoading,
  allCountries,
  errorHandlerData,
  loadingHandlerData,
  customsOfficeLoading,
  customsOffice,
  declaration,
  file,
  isEdit,
  signatureStart,
  createdFileId,
  createFileLoading,
  user
}: any) => {
  const formikRef = useRef();
  const [companyList, setcompanyList] = useState([]);
  const [declarationList, setdeclarationList] = useState([]);
  const [allCountriesList, setAllCountriesList] = useState([]);
  const [customsOfficeList, setCustomsOfficeList] = useState([]);
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [createCompanyModal, setCreateCompanyModal] = useState(false);
  const [createDeclaration, setCreateDeclaration] = useState(false);
  const [updateForm, setupdateForm] = useState(false);
  const [imageDisplayed, setimageDisplayed] = useState(null);
  const [addContactModal, setaddContactModal] = useState(false);


  const isAdminOrOp = user.admin || user.operator

  useEffect(() => {
    dispatch(actions.customsClerk.reset(['declarationInProgress', 'error']));

    if (createdFileId && match.params.fileId) {
      manageSteps(2);
    }

    if (match.params.fileId) {
      dispatch(
        actions.customsClerk.getStartSignature({
          params: {
            fileId: match.params.fileId,
          },
        })
      );
    } else {
      dispatch(actions.company.getAll());
    }

    dispatch(actions.data.getAllCountries());
    dispatch(actions.customsDeclaration.getAllCustomsOffice());

    dispatch(actions.socket.subscribeUpdateCompany());

    return () => {
      dispatch(actions.socket.unsubscribeUpdateCompany());

      dispatch(
        actions.customsClerk.reset([
          'declarationInProgress',
          'createdFileId',
          'startSignature',
          'error',
        ])
      );
      dispatch(actions.company.reset(['companies', 'company', 'error']));
      dispatch(
        actions.contact.reset(['error', 'contacts', 'contactsTransporters'])
      );
      dispatch(actions.data.reset(['allCountries', 'error']));
      dispatch(
        actions.customsDeclaration.reset([
          'customsOffices',
          'declaration',
          'error',
        ])
      );
    };
  }, []);

  useEffect(() => {
    if (file && match.params.fileId) {
      const fileData = file;
      handleCompanySelected(fileData.CustomsDeclaration.Company.id);
      setcompanyList([
        {
          key: 0,
          value: fileData.CustomsDeclaration.Company.id,
          text: fileData.CustomsDeclaration.Company.name,
        },
      ]);
      setdeclarationList([
        {
          key: 0,
          value: fileData.CustomsDeclaration_Id,
          text: fileData.CustomsDeclaration_Id,
        },
      ]);

      const ref: any = formikRef.current;
      const splitedDate = fileData.startingAt.split('T');

      ref.setFieldValue('startDate', splitedDate[0]);
      ref.setFieldValue('startTime', splitedDate[1].substring(0, 5));
      ref.setFieldValue('companyId', fileData.CustomsDeclaration.Company.id);
      ref.setFieldValue('declarationId', fileData.CustomsDeclaration_Id);
      ref.setFieldValue(
        'truckLicensePlate',
        fileData.CustomsDeclaration.truckLicensePlate
      );
      ref.setFieldValue(
        'trailerLicensePlate',
        fileData.CustomsDeclaration.trailerLicensePlate
      );
      ref.setFieldValue('truckOrigin', fileData.CustomsDeclaration.truckOrigin);
      ref.setFieldValue(
        'trailerOrigin',
        fileData.CustomsDeclaration.trailerOrigin
      );
      ref.setFieldValue(
        'customsOffice',
        fileData.CustomsDeclaration.CustomsOffice_Id
      );
      ref.setFieldValue('transitNumber', fileData.transitNumber);
    }
  }, [file]);

  useEffect(() => {
    if (
      match.params.fileId &&
      signatureStart &&
      signatureStart instanceof Blob
    ) {
      const ref: any = formikRef.current;
      const signature = new File([signatureStart], 'signature.jpg');
      ref.setFieldValue('signature', signature);
    } else {
      const ref: any = formikRef.current;
      ref.setFieldValue('signature', null);
    }
  }, [signatureStart]);

  useEffect(() => {
    if (companies) {
      setcompanyList(
        companies.map((company, index) => {
          return {
            key: index,
            value: company.id,
            text: company.name,
          };
        })
      );
    }
  }, [companies]);

  useEffect(() => {
    if (customsOffice) {
      setCustomsOfficeList(
        customsOffice.map((office, index) => {
          return {
            key: index,
            value: office.id,
            text: office.name,
          };
        })
      );
    }
  }, [customsOffice]);

  useEffect(() => {
    if (allCountries) {
      setAllCountriesList(
        orderBy(
          allCountries.map((country, index) => {
            return {
              key: index,
              value: country.iso_code,
              text: intl.formatMessage({
                id: `countries.name.${country.iso_code}`,
              }),
              flag: country.iso_code.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [allCountries]);

  useEffect(() => {
    if (company) {
      manageSocket(company);

      const ref: any = formikRef.current;
      if (!isEdit) {
        ref.setFieldValue('transporterId', '');
        ref.setFieldValue('recipientId', '');
      }

      dispatch(
        actions.contact.getAllContacts({
          params: { companyId: company.id },
        })
      );
      if (!company.transporter) {
        dispatch(
          actions.contact.getAllContactTransporters({
            params: { companyId: company.id },
          })
        );
      } else {
        ref.setFieldValue('transporterId', company.id);
      }
      if (!isEdit) {
        let isSubed = company?.Subscriptions?.find(
          (sub) => sub.Service.name === 'customsDeclaration'
        );
        if (isSubed) {
          dispatch(
            actions.customsClerk.getDeclarationInProgress({
              params: {
                companyId: company.id,
              },
            })
          );
        } else {
          dispatch(actions.customsClerk.reset(['declarationInProgress']));
          setdeclarationList([]);
          handleDeclarationSelected(null);
        }
      }

      setSelectedCompanyData({
        id: company.id,
        name: company.name,
        email: company.email,
        country: company.country,
        address1: company.Addresses[0].address1,
        address2: company.Addresses[0].address2,
        city: company.Addresses[0].city,
        zipCode: company.Addresses[0].zipCode,
        transporter: company.transporter,
      });
    }
  }, [company]);

  useEffect(() => {
    if (declarationInProgress) {
      const declarations = declarationInProgress.map((declaration, index) => {
        return {
          key: index,
          value: declaration.id,
          text: `${declaration.trailerLicensePlate} (Trailer origin: ${declaration.trailerOrigin})`,
        };
      });

      declarations.unshift({
        key: declarations.length,
        value: false,
        text: 'New declaration',
      });

       setdeclarationList(declarations);
    }
  }, [declarationInProgress]);

  useEffect(() => {
    if (declaration) {
      const ref: any = formikRef.current;
      ref.setFieldValue('truckLicensePlate', declaration.truckLicensePlate);
      ref.setFieldValue('trailerLicensePlate', declaration.trailerLicensePlate);
      ref.setFieldValue('truckOrigin', declaration.truckOrigin);
      ref.setFieldValue('trailerOrigin', declaration.trailerOrigin);
      ref.setFieldValue('customsOffice', declaration.CustomsOffice_Id);
    }
  }, [declaration]);

  useEffect(() => {
    if(createdFileId && !match?.params?.fileId){
      history.push(
        paths.customsHelperFileEdition.replace(':fileId', createdFileId)
      );
    }
  }, [createdFileId]);

  const manageSocket = (company) => {
    if (company) {
      if (selectedCompanyData) {
        dispatch(actions.socket.unsubscribeAddContact(selectedCompanyData.id));
        if (!selectedCompanyData.transporter) {
          dispatch(actions.socket.unsubscribeAddContactTransporter(company.id));
        }
      } else {
        dispatch(actions.socket.subscribeAddContact(company.id));
      }
      dispatch(actions.socket.subscribeAddContact(company.id));
      if (!company.transporter) {
        dispatch(actions.socket.subscribeAddContactTransporter(company.id));
      }
    }
  };

  const handleCompanySelected = (companyId) => {
    if (companyId) {
      dispatch(
        actions.company.getOne({
          params: { companyId },
        })
      );
    } else {
      dispatch(actions.socket.unsubscribeAddContact(selectedCompanyData?.id));
      selectedCompanyData?.transporter &&
        dispatch(
          actions.socket.unsubscribeAddContactTransporter(
            selectedCompanyData.id
          )
        );
      setSelectedCompanyData(null);
    }
  };

  const handleDeclarationSelected = (declarationId) => {
    if (declarationId) {
      dispatch(
        actions.customsDeclaration.getOne({
          params: { declarationId, companyId: selectedCompanyData.id },
        })
      );
      setCreateDeclaration(false);
    } else {
      dispatch(actions.customsDeclaration.reset(['declaration']));
      const ref: any = formikRef.current;
      ref.setFieldValue('declarationId', '');
      ref.setFieldValue('truckLicensePlate', '');
      ref.setFieldValue('truckOrigin', '');
      ref.setFieldValue('trailerLicensePlate', '');
      ref.setFieldValue('trailerOrigin', '');
      ref.setFieldValue('customsOffice', '');
    
      setCreateDeclaration(!declarationId ? true : false);
    }
  };

  const createFile = (values) => {
    if (!isEdit) {
      dispatch(
        actions.customsClerk.createFile({
          params: {
            companyId: values.companyId,
          },
          data: {
            startingAt: `${values.startDate} ${values.startTime}`,
            transitNumber: values.transitNumber || '',
            ...(values.declarationId && {
              declarationId: values.declarationId,
            }),
            signatureStart: values.signature,
            ...(!values.declarationId && {
              declaration: {
                trailerLicensePlate: values.trailerLicensePlate,
                truckLicensePlate: values.truckLicensePlate,
                truckOrigin: values.truckOrigin,
                trailerOrigin: values.trailerOrigin,
                customsOffice: values.customsOffice,
              },
            }),
          },
        })
      );
    } else if (updateForm) {
      dispatch(
        actions.customsClerk.updateFile({
          params: {
            companyId: values.companyId,
            fileId: match.params.fileId,
          },
          data: {
            startingAt: `${values.startDate} ${values.startTime}`,
            transitNumber: values.transitNumber || '',
          },
        })
      );
      manageSteps(2);
    } else {
      manageSteps(2);
    }
  };

  return (
    <Wrapper>
      {createCompanyModal && (
        <NewCompanyModal
          open={createCompanyModal}
          handleClose={() => setCreateCompanyModal(false)}
        />
      )}
      {imageDisplayed && (
        <Modal
          open={imageDisplayed && true}
          onClose={() => {
            setimageDisplayed(null);
          }}
          closeIcon
        >
          <Wrapper padding='1rem'>
            <ImageCustom src={URL.createObjectURL(imageDisplayed)} />
          </Wrapper>
        </Modal>
      )}
      {createFileLoading && <Loader />}
     
      <Segment loading={!file}>
      
        <Formik
          initialValues={{
            startDate: splitDateTime(new Date()).date,
            startTime: splitDateTime(new Date()).time,
            companyId: null,
            declarationId: '',
            truckLicensePlate: '',
            trailerLicensePlate: '',
            truckOrigin: '',
            trailerOrigin: '',
            customsOffice: '',
            transitNumber: '',
            signature: null,
          }}
          onSubmit={createFile}
          validationSchema={startControlSchema}
          ref={formikRef}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            values,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Wrapper width='80%' margin='auto'>
                <Wrapper display='flex' justifyContent='center'>
                  <Wrapper width='50%'>
                    <Field
                      disabled={file?.signatureEndS3Path && true}
                      mandatory
                      label='Start date'
                      name='startDate'
                      component={Input}
                      type='date'
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      onChange={() => {
                        match.params.fileId && setupdateForm(true);
                      }}
                    />
                  </Wrapper>
                  <Wrapper width='10px' />
                  <Wrapper width='50%'>
                    <Field
                      disabled={file?.signatureEndS3Path && true}
                      mandatory
                      label='Start time'
                      name='startTime'
                      component={Input}
                      type='time'
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      onChange={() =>
                        match.params.fileId && setupdateForm(true)
                      }
                    />
                  </Wrapper>
                </Wrapper>
                <Divider hidden />
                <Wrapper
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Wrapper width='80%'>
                    <Field
                      disabled={isEdit}
                      mandatory
                      label='Company'
                      placeholder='Choose a company'
                      name='companyId'
                      options={companyList}
                      loading={loadingHandlerCompany}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      onChange={handleCompanySelected}
                    />
                  </Wrapper>
                  <Wrapper margin='auto 0 0 0'>
                    <Button
                      disabled={isEdit}
                      icon='plus'
                      type='button'
                      onClick={() => {
                        setCreateCompanyModal(true);
                      }}
                    />
                  </Wrapper>
                </Wrapper>
                {selectedCompanyData && values.companyId && (
                  <SelectedCompanyInfo company={selectedCompanyData} />
                )}
                <Divider hidden />
                {declarationList.length > 0 && (
                  <Field
                    disabled={
                      isEdit ||
                      (!selectedCompanyData && !declarationInProgressLoading)
                    }
                    mandatory
                    label='Declaration'
                    placeholder='Choose a declaration'
                    name='declarationId'
                    options={declarationList}
                    loading={declarationInProgressLoading}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    onChange={handleDeclarationSelected}
                  />
                )}
                <Divider hidden />
                {values.companyId && createDeclaration && (
                  <>
                    {addContactModal && (
                      <AddContact
                        open={addContactModal}
                        handleClose={() => setaddContactModal(false)}
                        companyId={values.companyId}
                      />
                    )}
                    <Wrapper display='flex' margin='0 0 1rem 0'>
                      <Button
                        icon='plus'
                        type='button'
                        content='Add contact'
                        onClick={() => {
                          setaddContactModal(true);
                        }}
                      />
                    </Wrapper>
                  </>
                )}

                {/* {values.companyId && (
                  <>
                    <Wrapper display='flex' justifyContent='space-between'>
                      {contactsTransporters && !isTransporter && (
                        <Wrapper width='50%'>
                          <Field
                            disabled={!createDeclaration}
                            mandatory
                            label='Transporter'
                            name='transporterId'
                            placeholder='Choose the transporter'
                            bulleted
                            bullMsg='Must be a transporter and in contact to appear'
                            options={contactsTransporterList}
                            component={Dropdown}
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                            loading={contactsTransportersLoading}
                            uniqueError={
                              contactError.includes('contactsTransporters')
                                ? true
                                : false
                            }
                          />
                        </Wrapper>
                      )}
                      <Wrapper width='10px' />
                      <Wrapper width='50%'>
                        <Field
                          disabled={!createDeclaration}
                          mandatory
                          label='Recipient'
                          name='recipientId'
                          placeholder='Choose the recipient'
                          bulleted
                          bullMsg='Must be in contact to appear'
                          options={contactsList}
                          component={Dropdown}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                          loading={contactsLoading}
                          uniqueError={
                            contactError.includes('contacts') ? true : false
                          }
                        />
                      </Wrapper>
                    </Wrapper>
                    <Divider hidden />
                  </>
                )} */}

                <Field
                  disabled={!createDeclaration && isEdit}
                  options={customsOfficeList}
                  loading={customsOfficeLoading}
                  mandatory
                  label='Customs office of arrival'
                  name='customsOffice'
                  placeholder='Customs office'
                  component={Dropdown}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                  uniqueError={
                    errorHandlerData.includes('customsOffice') ? true : false
                  }
                />
                <Divider hidden />
                <Wrapper display='flex' justifyContent='space-between'>
                  <Wrapper width='50%'>
                    <Field
                      disabled={!createDeclaration && isEdit}
                      mandatory
                      label="Truck's origin"
                      name='truckOrigin'
                      placeholder='Choose the country of origin of the truck'
                      options={allCountriesList}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      loading={loadingHandlerData}
                      uniqueError={
                        errorHandlerData.includes('allCountries') ? true : false
                      }
                    />
                  </Wrapper>
                  <Wrapper width='10px' />
                  <Wrapper width='50%'>
                    <Field
                      disabled={!createDeclaration && isEdit}
                      mandatory
                      label='Truck license plate'
                      name='truckLicensePlate'
                      placeholder='AA123AA'
                      component={Input}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Wrapper>
                </Wrapper>
                <Divider hidden />
                <Wrapper display='flex' justifyContent='space-between'>
                  <Wrapper width='50%'>
                    <Field
                      disabled={!createDeclaration && isEdit}
                      mandatory
                      label="Trailer's origin"
                      name='trailerOrigin'
                      placeholder='Choose the country of origin of the trailer'
                      options={allCountriesList}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      loading={loadingHandlerData}
                      uniqueError={
                        errorHandlerData.includes('allCountries') ? true : false
                      }
                    />
                  </Wrapper>
                  <Wrapper width='10px' />
                  <Wrapper width='50%'>
                    <Field
                      disabled={!createDeclaration && isEdit}
                      mandatory
                      label='Trailer license plate'
                      name='trailerLicensePlate'
                      placeholder='AA123AA'
                      component={Input}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Wrapper>
                </Wrapper>
                <Divider hidden />
                <Wrapper>
                  <Field
                    disabled={file?.signatureEndS3Path && true && isEdit}
                    label='TransitNumber'
                    placeholder='Transit number'
                    name='transitNumber'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    onChange={() => {
                      if (match.params.fileId) {
                        match.params.fileId && setupdateForm(true);
                      }
                    }}
                  />
                </Wrapper>
                <Divider hidden />
                {isEdit && (
                  <>
                    <Wrapper>
                      <TextCustom>Signature:</TextCustom>
                    </Wrapper>
                    <Wrapper display='flex' justifyContent='center'>
                      <Segment margin='0' padding='2rem'>
                        <ImageCustom
                          src={
                            values.signature &&
                            URL.createObjectURL(values.signature)
                          }
                          onClick={() => {
                            setimageDisplayed(values.signature);
                          }}
                        />
                      </Segment>
                    </Wrapper>
                  </>
                )}
                <Wrapper {...(isEdit && { display: 'none' })}>
                  <Field
                    component={Signature}
                    name='signature'
                    label='Signature'
                    mandatory
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                      handleSubmit,
                      values,
                    }}
                  />
                </Wrapper>
                <Divider hidden />
                <Wrapper display='inline-block' margin='0'>
                  <Button
                    type={
                      !match.params.fileId ||
                      (!file?.signatureEndS3Path && updateForm)
                        ? 'submit'
                        : 'button'
                    }
                    size='large'
                    icon='right arrow'
                    labelPosition='right'
                    color='blue'
                    content={
                      !match.params.fileId ||
                      (!file?.signatureEndS3Path && updateForm)
                        ? 'Save and next'
                        : 'Next'
                    }
                    {...(match.params.fileId &&
                      (file?.signatureEndS3Path || !updateForm) && {
                        onClick: () => {
                          manageSteps(2);
                        },
                      })}
                    margin='5px'
                  />
                </Wrapper>
              </Wrapper>
            </Form>
          )}
        </Formik>
      </Segment>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.company.companiesSelector(state),
    company: selectors.company.companySelector(state),
    loadingHandlerCompany: selectors.company.loadingSelector(state),
    declarationInProgress: selectors.customsClerk.declarationInProgressSelector(
      state
    ),
    declarationInProgressLoading: selectors.customsClerk.declarationInProgressLoadingSelector(
      state
    ),
    allCountries: selectors.data.allCountriesSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    loadingHandlerData: selectors.data.allCountryLoadingSelector(state),
    customsOfficeLoading: selectors.customsDeclaration.getAllCustomsOfficeLoadingSelector(
      state
    ),
    customsOffice: selectors.customsDeclaration.getAllCustomsOfficeSelector(
      state
    ),
    declaration: selectors.customsDeclaration.declarationSelector(state),
    createdFileId: selectors.customsClerk.createdFileIdSelector(state),
    createFileLoading: selectors.customsClerk.createFileLoadingSelector(state),

    signatureStart: selectors.customsClerk.startSignatureSelector(state),

    contacts: selectors.contact.contactsSelector(state),
    contactsLoading: selectors.contact.getContactsLoadingSelector(state),
    contactsTransporters: selectors.contact.contactsTransportersSelector(state),
    contactsTransportersLoading: selectors.contact.getAllContactTransportersLoadingSelector(
      state
    ),
    contactError: selectors.contact.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(CreateFileStep);
