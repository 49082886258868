import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

export const Wrapper = styled.div(({ labelPosition, labelVerticalPosition, margin }: any) => ({
  alignItems: (labelVerticalPosition === "center") ? "center" : "unset",
  display: "flex",
  flexDirection: (labelPosition === "left") ? "row" : "column",
  margin: margin,
  justifyContent: "space-evenly"
}));



export const Input = styled(Form.Input)`
  &&& {
    margin: 0;
  }
  &&&.disabled {
    opacity: 1;
  }
`;
