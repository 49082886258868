import { orderBy } from 'lodash';
import { Field, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Divider,
  Form,
  Grid,
  Header,
  List,
  Modal,
  Transition,
} from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { Input } from 'components/Form';
import { intrastat } from 'interfaces/formSchema/intrastatValidators';
//import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import Dropzone from 'react-dropzone';
import { Aside, AsideWrapper, DocList, Paragraph } from './styles';


const Index = ({
  dispatch,
  companyId,
  company,
  iso,
  open,
  handleClose,
  euCountries,
  euCountriesLoading,
  intCategories,
  intCategoriesLoading,
  intl,
  loadingHandlerData,
  categoriesDocuments,
  categoriesLoading,
}: IntrastatProps) => {

  const isFillingCountry = !iso ? false : iso.toUpperCase()
  const [allCountriesList, setAllCountriesList]: any = useState([]);
  const [fileList, setFileList]: any = useState(null);
  const [none, setNode]: any = useState(true)
  const [acceptedFiles, setAcceptedFiles]: any = useState(null);
  const [intCategoriesList, setintCategoriesList]: any = useState(null);
  const [categoriesDocumentsList, setCategoriesDocumentsList]: any = useState(null);
  const [type, setType]: any = useState(null);

  useEffect(() => {
    dispatch(actions.intrastat.getCategories({
      params: {
        companyId: companyId
      }
    }));
    dispatch(actions.companyIntrastatSettings.getAssignedCountries({
      params: {
        companyId: companyId
      }
    }));
    dispatch(
      actions.company.getOne({
        params: { companyId: companyId },
      })
    );
    return () => {
      dispatch(actions.intrastat.reset(['categories', 'error']));
      dispatch(actions.companyIntrastatSettings.reset(['countries', 'error']));
      dispatch(actions.data.reset(['euCountries', 'error']));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    if(intCategories){
      setintCategoriesList(intCategories.map((e, i) => {
        return {
          key: i,
          value: e.DeclarationCategory_Id,
          text: intl.formatMessage({
            id: `intrastat.${e.name.toLowerCase()}`,
          }),
        }
      }))
    }
  }, [intCategories, intl])

  useEffect(() => {
    if(euCountries){
      euCountries && setAllCountriesList(
        orderBy(
          euCountries.map((country, index) => {
            return {
              key: index,
              value: country.iso,
              text: intl.formatMessage({
                id: `countries.name.${country.iso}`,
              }),
              flag: country.iso === 'XI' ? 'gb' : country.iso.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [euCountries, intl])


  useEffect(() => {
    if(categoriesDocuments){
      setCategoriesDocumentsList(
        categoriesDocuments.map((category, index) => {
          return {
            key: index,
            value: category.id,
            text: category.fr,
          };
        })
      );
    }
  }, [categoriesDocuments]);


  const createClientIntrastat = async (values) => {
      values.documents[0].files = acceptedFiles ? acceptedFiles[0].docs : []
      dispatch(
        actions.intrastat.createClientIntrastat({
          params: {
            companyId: companyId,
          },
          data: {
            regularization: values.regularization,
            declarationType: values.declarationType,
            period: values.period,
            filingCountry: values.filingCountry,
            flow: values.flow,
            clientReference: values.clientReference, 
            type: type,
            documents: values.documents,
            documentCategories: values.documentCategories,
          },
        })
      )
    handleClose()
  }

  const createNoneIntrastat = (values) => {
    dispatch(
      actions.intrastat.createNoneIntrastat({
        params: {
          companyId: companyId,
        },
        data: {
          none: true,
          period: values.period,
          filingCountry: values.filingCountry,
          clientReference: values.clientReference, 
        },
      })
    )
    handleClose()
  }
   
  const retreiveDocType = (id) => {
    dispatch(actions.intrastat.getDocsFromCat({
      query: {type: id}
    }));
    setType(id)
    return () => {
      dispatch(actions.intrastat.reset(['docsFromCat', 'error']));
    };
  }


  const constfiles = (acceptedFiles, index) => {
    let obj = []
    obj.push({id: index, docs: acceptedFiles})
    setAcceptedFiles(obj)
    setFileList(
      acceptedFiles.map(file => (
        <DocList key={file.name}>
          {file.name} - {file.size} bytes
        </DocList>
      )))
  };

  
  return (
    <>
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content={intl.formatMessage({
                  id: `intrastat.new`,
                })} />
      <Formik
        initialValues={{
          declarationType: '',
          period: '',
          filingCountry: isFillingCountry || '',
          flow: '',
          clientReference: '', 
          documents: [
            {
              id: undefined,
              files: [],
            },
          ],
          type: type,
          regularization: false
        }}
        onSubmit={(none && createClientIntrastat) || createNoneIntrastat}
        validationSchema={none && intrastat}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
        }) => (
          <>
          <Modal.Content>
            <Form onSubmit={handleSubmit}>   
            <Grid>
            <Grid.Row columns='4'>
            <Grid.Column>
              <Field
                label={intl.formatMessage({
                  id: `intrastat.new.None`,
                })}
                name='none'
                component={Checkbox}
                meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                }}
                onChange={() => {
                  setNode(!none)
                }}
              />
            </Grid.Column>  
            <Grid.Column>
              <Field
                label={intl.formatMessage({
                  id: `intrastat.new.Reg`,
                })}
                name='regularization'
                component={Checkbox}
                meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                }}
                onChange={() => {
                  setFieldValue('regularization', !values.regularization)
                }}
              />
            </Grid.Column>        
            </Grid.Row>
            <Grid.Row columns='2'>
            <Grid.Column> 
              <Field
                mandatory={none}
                label={intl.formatMessage({
                  id: `intrastat.new.DeclaType`,
                })}
                name='declarationType'
                placeholder={intl.formatMessage({
                  id: `intrastat.new.pDeclaType`,
                })}
                loading={intCategoriesLoading}
                component={Dropdown}
                options={intCategoriesList}
                  meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
              }}
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                mandatory={none}
                label={intl.formatMessage({
                  id: `intrastat.new.FlowType`,
                })}
                placeholder={intl.formatMessage({
                  id: `intrastat.new.pFlowType`,
                })}
                name='flow'
                component={Dropdown}
                options={[
                  {
                      key: 0,
                      value: 'expedition',
                      text: intl.formatMessage({
                        id: `intrastat.expedition`,
                      }),
                  },
                  {
                      key: 1,
                      value: 'introduction',
                      text: intl.formatMessage({
                        id: `intrastat.introduction`,
                      }),
                  },
                ]}
                meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
              // onChange={(value) => {
              //   if(value === 'expedition'){
              //     retreiveDocType(0)
              //   }else{             
              //     retreiveDocType(1)
              //   }
              // }}
            />
            </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
            <Grid.Column>
              <Field
                mandatory
                label={intl.formatMessage({
                  id: `intrastat.new.Period`,
                })}
                name='period'
                component={Input}
                type='date'
                meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                }}
              />
            </Grid.Column>     
          {(!isFillingCountry && (
            <Grid.Column>
              <Field
                mandatory
                label={intl.formatMessage({
                  id: `intrastat.new.FilingCountry`,
                })}
                name='filingCountry'
                placeholder={intl.formatMessage({
                  id: `intrastat.new.pFilingCountry`,
                })}
                component={Dropdown}
                options={allCountriesList}
                loading={euCountriesLoading}
                meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                }}
              />
            </Grid.Column>
            )) || (
              <Grid.Column>
              <Field
                label={intl.formatMessage({
                  id: `intrastat.new.ClientRef`,
                })}
                name='clientReference'
                placeholder={intl.formatMessage({
                  id: `intrastat.new.pClientRef`,
                })}
                component={Input}
                meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                }}
              />
            </Grid.Column>
            )}
          </Grid.Row>
          {!isFillingCountry && (
            <Grid.Row columns={2}>
              <Grid.Column>
                <Field
                  label={intl.formatMessage({
                    id: `intrastat.new.ClientRef`,
                  })}
                  name='clientReference'
                  placeholder={intl.formatMessage({
                    id: `intrastat.new.pClientRef`,
                  })}
                  component={Input}
                  meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          
          </Grid>
            <Divider hidden />
            {/* <Header size='tiny' textAlign='center'>Documents</Header>                   */}
            {/* <FieldArray
              name='documents'
              render={() => (
                <Transition.Group
                  as={List}
                  duration={200}
                  size='medium'
                  verticalAlign='middle'
                >
                  <Grid columns='3'>
                    {values.documents.length > 0 &&
                      values.documents.map((document, index) => {
                        return (
                          <Grid.Row key={index}>
                            <Grid.Column width='5'>
                              <Field
                                name={`documents[${index}].id`}
                                arrayName='documents'
                                placeholder='Choose a Category'
                                component={Dropdown}
                                options={categoriesDocumentsList}
                                loading={categoriesLoading && categoriesDocumentsList !== null}
                                meta={{
                                  errors,
                                  touched,
                                  setFieldValue,
                                  setFieldTouched,
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column width='11'>
                            <Dropzone 
                              onDrop={acceptedFiles => constfiles(acceptedFiles, index)}>
                              {({getRootProps, getInputProps}) => (
                                <section>
                                  <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <Paragraph>Drag 'n' drop some files here, or click to select files</Paragraph>
                                  </div>
                                {fileList && (
                                  <AsideWrapper>
                                    <Aside>
                                      {fileList}                                  
                                    </Aside>
                                  </AsideWrapper>                                   
                                )}
                                </section>
                              )}
                            </Dropzone>
                            </Grid.Column>
                            <Divider hidden />
                          </Grid.Row>
                        );
                      })}
                  </Grid>
                </Transition.Group>
              )}
            />    */}
            <Divider hidden />
            <Button type='submit' content={intl.formatMessage({
                  id: `button.submit`,
                })} />
            </Form>
          </Modal.Content>
          </>
        )}
      </Formik>
    </Modal>
    </>
  );
};

interface IntrastatProps {
  dispatch: (value: any) => void;
  companyId: number;
  iso: string;
  open: boolean;
  handleClose: () => void;
  intCategories;
  intCategoriesLoading;
  company;
  euCountries;
  euCountriesLoading;
  intl;
  loadingHandlerData;
  categoriesDocuments;
  categoriesLoading;
}


const mapStateToProps = (state) => {
  return {
    intCategories: selectors.intrastat.getCategoriesSelector(state),
    intCategoriesLoading: selectors.intrastat.getCategoriesLoadingSelector(state),
    euCountries: selectors.companyIntrastatSettings.getCountriesSelector(state),
    euCountriesLoading: selectors.companyIntrastatSettings.getCountriesLoadingSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    loadingHandlerData: selectors.data.euCountriesLoadingSelector(state),
    categoriesDocuments: selectors.intrastat.getDocsFromCatSelector(state),
    categoriesLoading: selectors.intrastat.getDocsFromCatLoadingSelector(state),
    company: selectors.company.companySelector(state),
    loadingHandlerCompany: selectors.company.loadingSelector(state),
  };
  
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
