import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  return {
    createSubscriptionPayment(data: dataReqI) {
      const createSubscriptionApi = routesApi.payment.createSubscription;
      return api.request({
        method: createSubscriptionApi.method,
        route: createSubscriptionApi.path,
        params: data.params,
        data: data.data,
      });
    },
    createToken(data: dataReqI) {
      const createTokenApi = routesApi.payment.createToken;
      return api.request({
        method: createTokenApi.method,
        route: createTokenApi.path,
        params: data.params,
      });
    },
    updateToken(data: dataReqI) {
      const updateTokenApi = routesApi.payment.updateToken;
      return api.request({
        method: updateTokenApi.method,
        route: updateTokenApi.path,
        params: data.params,
      });
    },
    unsubscribe(data: dataReqI) {
      const unsubscribeApi = routesApi.payment.unsubscribe;
      return api.request({
        method: unsubscribeApi.method,
        route: unsubscribeApi.path,
        params: data.params,
      });
    },
    cancelUnsubscribe(data: dataReqI) {
      const cancelnsubscribeApi = routesApi.payment.cancelUnsubscribe;
      return api.request({
        method: cancelnsubscribeApi.method,
        route: cancelnsubscribeApi.path,
        params: data.params,
      });
    },
  };
};
