import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Modal, Header} from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import { actions } from 'store';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import ErrorHandler from 'components/ErrorHandler';
import { Input } from 'components/Form';
import { Checkbox } from 'components/Form';
import { addUserSchema } from 'interfaces/formSchema';

type AddUser = {
  email: string;
  role: string;
  admin: boolean;
  addUser?: boolean;
  firstName?: string;
  lastName?: string;
  password?: string;
  cPassword?: string;
  verification?: boolean;
}

const Index = ({
  errorHandler,
  open,
  handleClose,
  intl,
  dispatch,
  companyId,
  setRunningAction,
}) => {

  const [isNewAccount, setNewAccountForm]: any = useState(false)
  const user = useRef() 

  const save = (values: AddUser) => {
    dispatch(
      actions.companyUser.addUser({
        data: { ...values },
        params: { companyId: companyId },
      })
    );
    setRunningAction('addUser');
    handleClose();

    return () => {
      dispatch(actions.companyUser.reset(['user', 'error']));
    }
  };

  const createPassword = (a?: string, b?: string) => {
    const ref: any = user.current
    const ch = new Date().getTime()
    const first = a.slice(0, 1).toUpperCase()
    const last = b.slice(0, 1).toUpperCase()
    ref.setFieldValue('password', `${first}${a && a.substring(1, 4)}${last}${b && b.substring(1, 4)}${ch.toString().slice(-3)}@`)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Content>
        <Wrapper>
          <Header>Add a new user:</Header>
          <Formik
            ref={user}
            initialValues={{
              email: '',
              addUser: false,
              firstName: '',
              lastName: '',
              password: '',
              cPassword: '',
              role: '',
              admin: false,
              verification: false,
            }}
            onSubmit={save}
            validationSchema={addUserSchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              values,
            }) => (
              <Form onSubmit={handleSubmit} width='15px'>
                {errorHandler && (
                  <>
                    <ErrorHandler
                      header='Fail to send request.'
                      message='Try to refresh the page and contact an administrator if the error persists.'
                    />
                  </>
                )}
                <Wrapper>
              
                  <Wrapper margin='10px'>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          mandatory
                          label={intl.formatMessage({
                            id: 'input.label.email',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'input.label.email',
                          })}
                          name='email'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>               
                      <Form.Field>
                        <Field
                          label='Role'
                          placeholder='Role'
                          name='role'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>                  
                    </Form.Group>
                    {isNewAccount && (
                      <Form.Group widths='equal'>
                        <Form.Field>
                          <Field
                            label='First name'
                            placeholder='First name'
                            name='firstName'
                            type='text'
                            component={Input}
                            meta={{ errors, touched }}
                            onChange={(e) => {
                              e && createPassword(e, values.lastName)
                            }}
                            margin='20px'
                          />
                        </Form.Field>
                        <Form.Field>
                          <Field
                            label='Last name'
                            placeholder='Last name'
                            name='lastName'
                            type='text'
                            component={Input}
                            meta={{ errors, touched }}
                            onChange={(e) => {
                              e && createPassword(values.firstName, e)
                            }}
                            margin='20px'
                          />
                        </Form.Field>
                        </Form.Group>
                      )}
                    {isNewAccount && (
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          label='Password'
                          placeholder='Password'
                          name='password'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>
                      <Form.Field>
                        <Field
                          label='Confirm password'
                          placeholder='Confirm password'
                          name='cPassword'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'                                           
                        />
                      </Form.Field>
                    </Form.Group>
                    )}                 
                  </Wrapper>
                  <Wrapper margin='10px'>
                    <Form.Group widths='four'>
                    <Form.Field>
                      <Field
                        label='Admin'
                        name='admin'
                        type='text'
                        component={Checkbox}
                        checked={values.admin === true}
                        onChange={() => {
                          if (values.admin === true) {
                            setFieldValue('admin', false);
                          } else {
                            setFieldValue('admin', true);
                          }
                        }}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                        margin='20px'
                      />
                    </Form.Field>            
                    <Form.Field>
                        <Field
                          label='Create an account'
                          name='addUser'
                          component={Checkbox}
                          checked={values.addUser === true}
                          onChange={() => {                      
                              setFieldValue('addUser', !values.addUser);
                              setNewAccountForm(!isNewAccount)                            
                          }}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                          margin='20px'
                        />
                      </Form.Field>
                      {isNewAccount && (
                         <Form.Field>
                         <Field
                           label='Ask for verification email'
                           name='verification'
                           component={Checkbox}
                           checked={values.verification === true}
                           onChange={() => {                      
                               setFieldValue('verification', !values.verification);
                                                       
                           }}
                           meta={{
                             errors,
                             touched,
                             setFieldValue,
                             setFieldTouched,
                           }}
                           margin='20px'
                         />
                       </Form.Field>
                      )}                                         
                    </Form.Group>
                  </Wrapper>
                </Wrapper>
                <Button
                  disabled={isSubmitting}
                  type='submit'
                  content={intl.formatMessage({
                    id: 'button.save',
                  })}
                  margin='20px'
                  position='center'
                  color='blue'
                />
              </Form>
            )}
          </Formik>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {return {}};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
