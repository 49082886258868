import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const createNewsErrorSelector = (state) => state.news.errors.create;
const getAllErrorSelector = (state) => state.news.errors.getAll;
const getAllNewsErrorSelector = (state) => state.news.errors.getAllNews;
const isReadByUserErrorSelector = (state) => state.news.errors.isReadByUser;
const notOpenedNewsErrorSelector = (state) => state.news.errors.notOpenedNews;
const getOneErrorSelector = (state) => state.news.errors.getOne;
const deleteNewsErrorSelector = (state) => state.news.errors.remove;
const deleteAllNewsErrorSelector = (state) => state.news.errors.removeAll;
const updateNewsErrorSelector = (state) => state.news.errors.update;

const errorsSelector = createErrorsSelector([
  getAllErrorSelector,
  getAllNewsErrorSelector,
  isReadByUserErrorSelector,
  notOpenedNewsErrorSelector,
  getOneErrorSelector,
  createNewsErrorSelector,
  updateNewsErrorSelector,
  deleteNewsErrorSelector,
  deleteAllNewsErrorSelector,
]);

// Loading
const createNewsLoadingSelector = (state) => state.news.loading.create;
const getAllLoadingSelector = (state) => state.news.loading.getAll;
const getAllNewsLoadingSelector = (state) => state.news.loading.getAllNews;
const isReadByUserLoadingSelector = (state) => state.news.loading.isReadByUser;
const notOpenedNewsLoadingSelector = (state) => state.news.loading.notOpenedNews;
const getOneLoadingSelector = (state) => state.news.loading.getOne;
const deleteNewsLoadingSelector = (state) => state.news.loading.remove;
const deleteAllNewsLoadingSelector = (state) => state.news.loading.removeAll;
const updateNewsLoadingSelector = (state) => state.news.loading.update;

const loadingSelector = createLoadingSelector([
  getAllLoadingSelector,
  getAllNewsLoadingSelector,
  isReadByUserLoadingSelector,
  notOpenedNewsLoadingSelector,
  getOneLoadingSelector,
  createNewsLoadingSelector,
  updateNewsLoadingSelector,
  deleteNewsLoadingSelector,
  deleteAllNewsLoadingSelector,
]);

// Success
const createNewsSuccessSelector = (state) => state.news.success.create;
const getAllSuccessSelector = (state) => state.news.success.getAll;
const getAllNewsSuccessSelector = (state) => state.news.success.getAllNews;
const isReadByUserSuccessSelector = (state) => state.news.success.isReadByUser;
const notOpenedNewsSuccessSelector = (state) => state.news.success.notOpenedNews;
const getOneSuccessSelector = (state) => state.news.success.getOne;
const deleteNewsSuccessSelector = (state) => state.news.success.remove;
const deleteAllNewsSuccessSelector = (state) => state.news.success.removeAll;
const updateNewsSuccessSelector = (state) => state.news.success.update;

const successSelector = createSuccessSelector([
  getAllSuccessSelector,
  isReadByUserSuccessSelector,
  getAllNewsSuccessSelector,
  notOpenedNewsSuccessSelector,
  getOneSuccessSelector,
  createNewsSuccessSelector,
  updateNewsSuccessSelector,
  deleteNewsSuccessSelector,
  deleteAllNewsSuccessSelector,
]);

// allNews
const allNewsSelector = (state) => state.news.allNews;

// allReadNews
const notOpenedNewsSelector = (state) => state.news.notOpenedNews;

// isRead
const isReadSelector = (state) => state.news.isRead;

// News
const newsSelector = (state) => state.news.news;

//Errors Handlers
const errorSelector = (state) => state.news.error;

//Loading
const agenciesLoadingSelector = (state) => state.news.loading.getAll;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  allNewsSelector,
  isReadSelector,
  newsSelector,
  errorSelector,
  agenciesLoadingSelector,
  createNewsSuccessSelector,
  createNewsLoadingSelector,
  deleteNewsLoadingSelector,
  deleteAllNewsLoadingSelector,
  deleteNewsSuccessSelector,
  deleteAllNewsSuccessSelector,
  notOpenedNewsSelector,
};
