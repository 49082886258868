import Contact from "views/Contact";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.contact,
    component: Contact,
    exact: true,
    authorizedRoles: []
  }
];
