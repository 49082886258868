import React from 'react';

import {
  ProgressBar,
  Progression,
  Step
} from './vatTimeLineStyles';
import styled from '@emotion/styled';

const VatTimeLine: React.FunctionComponent<VatTimeLineProps> = ({
  taxPeriod,
  maxStep,
  actualStep,
  loading,
  declarationType,
  onClick,
}) => {

  const TaskWrapper = styled.div(() => ({
    height: '80px',
    margin: '20px 0 0 0',
  }))

  const TaskTitle = styled.div(() => ({
    position: 'absolute',
    top: '30px',
    left: '0px',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    textAlign: 'center',
    color: '#000'
  }))

  const TimelineTitle = styled.div(() => ({
    float: 'left',
    lineHeight: '28px'
  }))
  
  const defineStep = (stepKey: number) => {
    const STATES = {
      complete: 'complete',
      active: 'active',
      none: '',
    };

    if (actualStep > stepKey || actualStep === maxStep) return STATES.complete;
    else if (actualStep === stepKey) return STATES.active;
    else return STATES.none;
  };

  //const periods = new Date(taxPeriod)
  //const date = periods.getDate()

  // Tâches cron 
  // const tasks = [
  //   {task: periods.setDate(date + 0), status: 0, desc: 'Created'},
  //   {task: periods.setDate(date + 9), status: 1, desc: 'Documents are available'},
  //   {task: periods.setDate(date + 12), status: 2, desc: 'ASD Compliance'},
  //   {task: periods.setDate(date + 15), status: 3, desc: 'Customer Compliance'},
  //   {task: periods.setDate(date + 18), status: 4, desc: 'Proofs of payment'},
  //   {task: periods.setDate(date + 19), status: 5, desc: 'VAT declaration established'},
  //   {task: periods.setDate(date + 20), status: 6, desc: 'Closing'},
  // ]
  const tasks = [
    {status: 0, desc: 'Created'},
    {status: 1, desc: 'Documents are available'},
    {status: 2, desc: 'ASD Compliance'},
    {status: 3, desc: 'Customer Compliance'},
    {status: 4, desc: 'Proofs of payment'},
    {status: 5, desc: 'VAT declaration established'},
    {status: 6, desc: 'Close'},
  ]


  return (
    <TaskWrapper>
      <TimelineTitle><strong>{declarationType}</strong></TimelineTitle>
      <ProgressBar title={`${(declarationType.length*8)+10}px`}>
        <Progression />
        {tasks.map((e, i) => (
          <Step
            key={i}
            loading={loading && loading}
            onClick={() => onClick && onClick(i + 1)}
            keyStep={i}
            background={((e.status < actualStep) && 'green') || (e.status <= actualStep && 'orange')}
            color={e.status <= actualStep && 'white'}
            state={defineStep(i + 1)}
          >
            <TaskTitle>{e.desc}</TaskTitle>
            {i}
            {/* {onlyDateEN(new Date(e.task))} */}
          </Step>
        ))}
      </ProgressBar>
    </TaskWrapper>
  );
};

interface VatTimeLineProps {
  taxPeriod: Date;
  maxStep: number;
  actualStep: number;
  declarationType: string;
  loading?: boolean;
  onClick: (step: number) => void;
}

export default VatTimeLine;


