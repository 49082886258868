import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    createClerksPricing: false,
    getOneClerksPricing: false,
    deleteClerksPricing: false,
    updateClerksPricing: false,
  },
  errors: {
    createClerksPricing: null,
    getOneClerksPricing: null,
    deleteClerksPricing: null,
    updateClerksPricing: null,
  },
  loading: {
    createClerksPricing: false,
    getOneClerksPricing: false,
    deleteClerksPricing: false,
    updateClerksPricing: false,
  },
  createClerksPricing: null,
  getOneClerksPricing: null,
  deleteClerksPricing: null,
  updateClerksPricing: null,
  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Get all ClerksPricingess
    case actionTypes.CREATE_CLERKS_PRICING_LOADING:
      return produce(state, draft => {
        draft.loading.createClerksPricing = true;
      });

    case actionTypes.CREATE_CLERKS_PRICING_SUCCESS:
      return produce(state, draft => {
        draft.errors.createClerksPricing = null;
        draft.loading.createClerksPricing = false;
        draft.success.createClerksPricing = true;
      });

    case actionTypes.CREATE_CLERKS_PRICING_ERROR:
      return produce(state, draft => {
        draft.errors.createClerksPricing = true;
        draft.loading.createClerksPricing = false;
        draft.success.createClerksPricing = false;
        !draft.error.includes("createClerksPricing") &&
          draft.error.push("createClerksPricing");
      });

    // Add ClerksPricinges
    case actionTypes.GET_ONE_CLERKS_PRICING_LOADING:
      return produce(state, draft => {
        draft.loading.getOneClerksPricing = true;
      });

    case actionTypes.GET_ONE_CLERKS_PRICING_SUCCESS:
      return produce(state, draft => {
        draft.errors.getOneClerksPricing = null;
        draft.loading.getOneClerksPricing = false;
        draft.success.getOneClerksPricing = true;
        draft.getOneClerksPricing = action.payload;
      });

    case actionTypes.GET_ONE_CLERKS_PRICING_ERROR:
      return produce(state, draft => {
        draft.errors.getOneClerksPricing = true;
        draft.loading.getOneClerksPricing = false;
        draft.success.getOneClerksPricing = false;
        !draft.error.includes("getOneClerksPricing") && draft.error.push("getOneClerksPricing");
      });

    // Delete ClerksPricinges
    case actionTypes.DELETE_CLERKS_PRICING_LOADING:
      return produce(state, draft => {
        draft.loading.deleteClerksPricing = true;
      });

    case actionTypes.DELETE_CLERKS_PRICING_SUCCESS:
      return produce(state, draft => {
        draft.errors.deleteClerksPricing = null;
        draft.loading.deleteClerksPricing = false;
        draft.success.deleteClerksPricing = true;
      });

    case actionTypes.DELETE_CLERKS_PRICING_ERROR:
      return produce(state, draft => {
        draft.errors.deleteClerksPricing = true;
        draft.loading.deleteClerksPricing = false;
        draft.success.deleteClerksPricing = false;
        !draft.error.includes("deleteClerksPricing") && draft.error.push("deleteClerksPricing");
      });

    // Validate ClerksPricinges
    case actionTypes.UPDATE_CLERKS_PRICING_LOADING:
      return produce(state, draft => {
        draft.loading.updateClerksPricing = true;
      });

    case actionTypes.UPDATE_CLERKS_PRICING_SUCCESS:
      return produce(state, draft => {
        draft.errors.updateClerksPricing = null;
        draft.loading.updateClerksPricing = false;
        draft.success.updateClerksPricing = true;
      });

    case actionTypes.UPDATE_CLERKS_PRICING_ERROR:
      return produce(state, draft => {
        draft.errors.updateClerksPricing = true;
        draft.loading.updateClerksPricing = false;
        draft.success.updateClerksPricing = false;
        !draft.error.includes("updateClerksPricing") && draft.error.push("updateClerksPricing");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
