import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Modal } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import { actions } from 'store';

import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import ErrorHandler from 'components/ErrorHandler';
import { Input } from 'components/Form';
import { Checkbox } from 'components/Form';
import { updateCompanyUserSchema } from 'interfaces/formSchema';

const Index = ({
  errorHandler,
  open,
  handleClose,
  user,
  intl,
  dispatch,
  companyId,
  loadingHandlerCompanyUser,
  errorHandlerCompanyUser,
}) => {
  const [runningAction, setRunningAction] = useState(null);

  useEffect(() => {
    if (runningAction !== null && !loadingHandlerCompanyUser) {
      if (!errorHandlerCompanyUser.includes(runningAction)) {
        dispatch(
          actions.companyUser.getOneUser({
            params: { companyId: companyId, userId: user.id },
          })
        );
        handleClose();
      }
      setRunningAction(null);
    }
  }, [dispatch, loadingHandlerCompanyUser, errorHandlerCompanyUser, user, runningAction, handleClose, companyId]);

  const save = (values) => {
    dispatch(actions.companyUser.reset(['error']));

    dispatch(
      actions.companyUser.updateUser({
        data: { ...values },
        params: { companyId: companyId, userId: user.id },
      })
    );
    setRunningAction('updateUser');
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Modal.Content>
        <Wrapper>
          <Formik
            initialValues={{
              role: user.CompanyUsers.role,
              admin: user.CompanyUsers.admin,
            }}
            onSubmit={save}
            validationSchema={updateCompanyUserSchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              values,
            }) => (
              <Form onSubmit={handleSubmit} width='15px'>
                {errorHandler && (
                  <>
                    <ErrorHandler
                      header='Fail to send request.'
                      message='Try to refresh the page and contact an administrator if the error persists.'
                    />
                  </>
                )}
                <Wrapper>
                  <Wrapper margin='auto' maxWidth='20rem'>
                    <Form.Field>
                      <Field
                        mandatory
                        label='Role'
                        placeholder='Role'
                        name='role'
                        type='text'
                        component={Input}
                        meta={{ errors, touched }}
                        margin='20px'
                      />
                    </Form.Field>
                    <Form.Field>
                      <Field
                        label='Admin'
                        name='admin'
                        type='text'
                        component={Checkbox}
                        checked={values.admin === true}
                        onChange={() => {
                          if (values.admin === true) {
                            setFieldValue('admin', false);
                          } else {
                            setFieldValue('admin', true);
                          }
                        }}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                        margin='20px'
                      />
                    </Form.Field>
                  </Wrapper>
                </Wrapper>
                <Button
                  disabled={isSubmitting}
                  type='submit'
                  content={intl.formatMessage({
                    id: 'button.save',
                  })}
                  margin='20px'
                  position='center'
                  color='blue'
                />
              </Form>
            )}
          </Formik>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
