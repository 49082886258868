import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Form, Header, Modal } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { File, FiscalPeriod } from 'components/Form';
import { fiscalDeclarationSchema } from 'interfaces/formSchema';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';

const Index = ({
  dispatch,
  companies,
  mapping,
  ta,
  countries,
  open,
  handleClose,
  errorHandlerData,
  errorHandlerCompany,
  errorHandlerDeclaration,
  loadingHandlerTA,
  loadingHandlerData,
  loadingHandlercompanies,
  loadingHandlerDeclaration,
}) => {
  const [companiesList, setcompaniesList] = useState([]);
  const [tiersList, settiersList] = useState([]);
  const [activitiesList, setactivitiesList] = useState([]);
  const [mappingList, setmappingList]: any = useState([]);
  const [countriesList, setcountriesList]: any = useState([]);
  const [selectedCompany, setselectedCompany] = useState(null);
  const [theCompanyName, settheCompanyName] = useState('');
  const [theCompanyId, settheCompanyId] = useState('');

  useEffect(() => {
    dispatch(actions.fiscalDeclaration.getCompanySubscribed());
    dispatch(actions.data.getAllCountries());

    return () => {
      dispatch(
        actions.fiscalDeclaration.reset([
          'companySubscribed',
          'mapping',
          'ta',
          'error',
        ])
      );
      dispatch(actions.data.reset(['allCountries', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    const tab = [];
    if (companies !== null) {
      companies.forEach((company, index) => {
        if (company.asdId !== null) {
          tab.push(company);
        }
      });
      setcompaniesList(
        tab.map((company, index) => {
          return {
            key: index,
            value: company.asdId,
            flag: company.country.toLowerCase(),
            text: company.name,
          };
        })
      );
    }
  }, [companies]);

  useEffect(() => {
    if (mapping !== null) {
      setmappingList(
        mapping.map((workshop, index) => {
          return {
            key: index,
            value: workshop.name,
            text: workshop.name,
          };
        })
      );
    }
  }, [mapping]);

  useEffect(() => {
    if (countries !== null) {
      setcountriesList(
        countries.map((country, index) => {
          return {
            key: index,
            value: country.iso_code,
            text: country.english_name,
            flag: country.iso_code.toLowerCase(),
          };
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    if (ta) {
      // tier activité
      settiersList(
        ta.tiers.map((tiers, index) => {
          return {
            key: index,
            value: tiers.id,
            text: tiers.nom_societe,
          };
        })
      );

      setactivitiesList(
        ta.cf.map((activity, index) => {
          return {
            key: index,
            value: activity.id_activite,
            text: activity.activite,
          };
        })
      );
    }
  }, [ta]);

  const companiesSelected = (companyName) => {
    setactivitiesList([]);
    settiersList([]);
    dispatch(actions.fiscalDeclaration.reset(['ta']));

    if (companyName) {
      setselectedCompany(companyName);
      dispatch(
        actions.fiscalDeclaration.getTA({ params: { asdId: companyName } })
      );
      dispatch(actions.fiscalDeclaration.getMapping());
    } else {
      setselectedCompany(null);
    }

    companies.forEach((company, index) => {
      if (company.asdId === companyName) {
        settheCompanyName(company.name);
        settheCompanyId(company.id);
      }
    });
  };

  const declare = (values) => {
    dispatch(
      actions.fiscalDeclaration.declare({
        params: { companyId: theCompanyId, companyName: theCompanyName },
        data: {
          company: values.company,
          companyName: theCompanyName,
          tier: values.tier,
          activity: values.activity,
          date: values.date,
          country: values.country,
          workshop: values.workshop,
          file: values.file,
        },
      })
    );
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content='New declaration' />
      <Formik
        initialValues={{
          company: selectedCompany,
          companyName: theCompanyName,
          tier: '',
          activity: '',
          date: '',
          country: '',
          workshop: '',
        }}
        onSubmit={declare}
        validationSchema={fiscalDeclarationSchema}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
          <Modal.Content>
            <Form
              onSubmit={handleSubmit}
              loading={
                loadingHandlerData ||
                loadingHandlerDeclaration ||
                loadingHandlercompanies
              }
            >
              <Field
                mandatory
                label='Company'
                placeholder='Choose a company'
                name='company'
                options={companiesList}
                component={Dropdown}
                onChange={companiesSelected}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
                uniqueError={errorHandlerCompany.includes('companiesByService')}
              />
              <Divider hidden />
              <Field
                //mandatory
                disabled={!values.company}
                loading={loadingHandlerTA}
                label='Tiers'
                placeholder='Choose a tier'
                name='tier'
                options={tiersList}
                component={Dropdown}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
                uniqueError={
                  errorHandlerDeclaration.includes('tiers and activities') &&
                  values.company
                    ? true
                    : false
                }
              />
              <Divider hidden />
              <Field
                //mandatory
                disabled={!values.company}
                loading={loadingHandlerTA}
                label='Activities'
                placeholder='Choose an activity'
                name='activity'
                options={activitiesList}
                component={Dropdown}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
                uniqueError={
                  errorHandlerDeclaration.includes('tiers and activities') &&
                  values.company
                    ? true
                    : false
                }
              />
              <Divider hidden />
              <Field
                label={<MandatoryFieldLabel content='Tax period' />}
                placeholder='Tax period'
                name='date'
                component={FiscalPeriod}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
              />
              <Divider hidden />
              <Field
                mandatory
                label='Country'
                placeholder='Choose country'
                name='country'
                options={countriesList}
                component={Dropdown}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
                uniqueError={
                  errorHandlerData.includes('allCountries') ? true : false
                }
              />
              <Divider hidden />
              <Field
                mandatory
                label='Workshop'
                placeholder='Choose workshop'
                name='workshop'
                options={mappingList}
                component={Dropdown}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
                uniqueError={
                  errorHandlerDeclaration.includes('mapping') ? true : false
                }
              />
              <Divider hidden />
              <Field
                label={<MandatoryFieldLabel content='File' />}
                name='file'
                component={File}
                touched={touched?.file}
                error={errors?.file}
                meta={{ setFieldValue, setFieldTouched }}
              />
              <Divider hidden />
              <Button
                disabled={isSubmitting}
                type='submit'
                content='Submit'
                color='green'
              />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.fiscalDeclaration.companySubscribedSelector(state),
    ta: selectors.fiscalDeclaration.taSelector(state),
    mapping: selectors.fiscalDeclaration.mappingSelector(state),
    countries: selectors.data.allCountriesSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    errorHandlerCompany: selectors.company.errorSelector(state),
    errorHandlerDeclaration: selectors.fiscalDeclaration.errorSelector(state),
    loadingHandlerTA: selectors.fiscalDeclaration.taLoadingSelector(state),
    loadingHandlerData: selectors.data.allCountryLoadingSelector(state),
    loadingHandlercompanies: selectors.company.companiesLoadingSelector(state),
    loadingHandlerDeclaration: selectors.fiscalDeclaration.mappingLoadingSelector(
      state
    ),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
