import Ssr from 'views/InternalTools/Ssr';
import paths from './paths';

export default [
  {
    private: true,
    path: paths.ssr,
    component: Ssr,
    exact: true,
    authorizedRoles: ['operator'],
  },
];
