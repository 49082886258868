export default {
  GETALLCOUNTRIES: "MY_ASD.DATA.GETALLCOUNTRIES",
  GETALLCOUNTRIES_LOADING: "MY_ASD.DATA.GETALLCOUNTRIES_LOADING",
  GETALLCOUNTRIES_SUCCESS: "MY_ASD.DATA.GETALLCOUNTRIES_SUCCESS",
  GETALLCOUNTRIES_ERROR: "MY_ASD.DATA.GETALLCOUNTRIES_ERROR",

  GETEUCOUNTRIES: "MY_ASD.DATA.GETEUCOUNTRIES",
  GETEUCOUNTRIES_LOADING: "MY_ASD.DATA.GETEUCOUNTRIES_LOADING",
  GETEUCOUNTRIES_SUCCESS: "MY_ASD.DATA.GETEUCOUNTRIES_SUCCESS",
  GETEUCOUNTRIES_ERROR: "MY_ASD.DATA.GETEUCOUNTRIES_ERROR",

  GET_COUNTRY_VAT_RATE: "MY_ASD.DATA.GET_COUNTRY_VAT_RATE",
  GET_COUNTRY_VAT_RATE_LOADING: "MY_ASD.DATA.GET_COUNTRY_VAT_RATE_LOADING",
  GET_COUNTRY_VAT_RATE_SUCCESS: "MY_ASD.DATA.GET_COUNTRY_VAT_RATE_SUCCESS",
  GET_COUNTRY_VAT_RATE_ERROR: "MY_ASD.DATA.GET_COUNTRY_VAT_RATE_ERROR",

  RESET: "MY_ASD.DATA.RESET",
};
