import { Field, Formik } from 'formik';
import React from 'react';
import { Divider, Form, Header, Modal } from 'semantic-ui-react';
import Button from 'components/Button';
import TextArea from 'components/Form/TextArea';
import { reasonForCancel } from 'interfaces/formSchema/customsValidators';

const Index = ({
  intl,
  open,
  handleCancelIntrastat,
  handleClose,
}: ModalCancelIntrastatProps) => {
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='cancel' content={intl.formatMessage({ id: `intrastat.reason` })} />
      <Formik
        initialValues={{
          reason: '',
        }}
        onSubmit={handleCancelIntrastat}
        validationSchema={reasonForCancel}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
          <Modal.Content>
            <Form onSubmit={handleSubmit}>
              <Field
                mandatory
                name={`reason`}
                label={intl.formatMessage({
                  id: `intrastat.lReason` 
                  })}
                component={TextArea}
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
              />
              <Divider hidden />
              <Button
                disabled={isSubmitting}
                type='submit'
                content={intl.formatMessage({
                  id: `button.submit` 
                  })}
                color='green'
              />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

interface ModalCancelIntrastatProps {
  intl: any;
  open: boolean;
  handleCancelIntrastat: (values: { reason: string }) => void;
  handleClose: () => void;
}

export default Index;
