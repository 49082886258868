import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { List, Accordion, Divider, Label } from 'semantic-ui-react';

import InputFlexCustom from 'components/QuickFixes/DeliveryForm/InputFlexCustom';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Spacer } from 'components/core';

const Index = ({ errors, touched, names, invoiceNumbers, customsId, disabled, cmr}: any) => {
  const [showInvoiceNumbers, setshowInvoiceNumbers] = useState(false);
  const [invoiceNumbersArr, setinvoiceNumbersArr] = useState([]);


  useEffect(() => {
    if (invoiceNumbers && invoiceNumbers.length > 0) {
      if (typeof invoiceNumbers[0] === 'string') {
        setinvoiceNumbersArr(invoiceNumbers);
      } else {
        let invoiceNumbersArray = [];
        invoiceNumbers.forEach((invoice) => {
          invoiceNumbersArray.push(invoice.invoiceNumber);
        });
        setinvoiceNumbersArr(invoiceNumbersArray);
      }
    }
  }, [invoiceNumbers, customsId]);

  return (
    <>
      <Field
        label='Ref'
        name={names.ref}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        justifyContent='center'
        widthLabel='6'
        widthInput='10'
      />
      <Field
        label='From'
        name={names.from}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        justifyContent='center'
        widthLabel='6'
        widthInput='10'
      />
      <Field
        label='To'
        name={names.to}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        justifyContent='center'
        widthLabel='6'
        widthInput='10'
      />

      <Field
        label='Transporter in charge'
        name={names.transporterInCharge}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        justifyContent='center'
        widthLabel='6'
        widthInput='10'
      />
      {invoiceNumbers && invoiceNumbersArr && (
        <Wrapper textAlign='justify' margin='0 0.5rem 0 0'>
          <Accordion as={List} styled>
            <Accordion.Title
              active={showInvoiceNumbers}
              {...(invoiceNumbersArr.length > 1
                ? { content: 'Invoice numbers' }
                : { content: 'Invoice number' })}
              onClick={() =>
                showInvoiceNumbers
                  ? setshowInvoiceNumbers(false)
                  : setshowInvoiceNumbers(true)
              }
            />
            <Accordion.Content
              active={showInvoiceNumbers}
              content={
                invoiceNumbersArr.length === 0 ? (
                  <p>No invoice number.</p>
                ) : (
                  <List divided bulleted>
                    {invoiceNumbersArr.map((invoiceNumber) => {
                      return (
                        <List.Item>
                          <p style={{ overflowWrap: 'break-word' }}>
                            {invoiceNumber}
                          </p>
                        </List.Item>
                      );
                    })}
                  </List>
                )
              }
            />
          </Accordion>
        </Wrapper>
      )}
      {names.imaNumber && (
        <>
          <Divider hidden />
          <Field
            label='IMA number'
            name={names.imaNumber}
            type='text'
            component={InputFlexCustom}
            disabled={disabled}
            meta={{ errors, touched }}
            margin='5px'
            justifyContent='space-between'
          />
        </>
      )}
      {names.cmr && (
          <Field
            label='CMR number'
            name={names.cmr}
            type='text'
            component={InputFlexCustom}
            disabled={disabled}
            meta={{ errors, touched }}
            margin='5px'
            justifyContent='space-between'
          />
      )}
      {customsId && customsId.length && customsId[0].id !== undefined && (
         <>
          <Divider hidden />
          <Label>Customs ID : {customsId[0].id}</Label>
        </>
      )}
      {customsId && customsId.length && customsId[0].ref !== undefined && (
        <>
          <Spacer height="5px" />
          <Label>Client Ref : {customsId[0].ref}</Label>
        </>
      )}
      {(customsId && customsId.length && customsId[0].cmr !== undefined) && (
        <>
          <Spacer height="5px" />
          <Label>CMR : {cmr}</Label>
        </>
      )}
       {(customsId && customsId.length>1 && customsId[1] && customsId[1].cmr) && (
        <>
          <Spacer height="5px" />
          <Label>CMR : {customsId[1].cmr}</Label>
        </>
      )}
      {customsId && !customsId[1] && cmr && (
        <>
          <Spacer height="5px" />
          <Label>CMR : {cmr}</Label>
        </>
      )}
      
    </>
  );
};

export default Index;
