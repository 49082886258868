let actionTypes = {
  GET_PAYMENT: "MY_ASD.COMPANY.PAYMENT.GET_PAYMENT",
  GET_PAYMENT_LOADING: "MY_ASD.COMPANY.PAYMENT.GET_PAYMENT_LOADING",
  GET_PAYMENT_SUCCESS: "MY_ASD.COMPANY.PAYMENT.GET_PAYMENT_SUCCESS",
  GET_PAYMENT_ERROR: "MY_ASD.COMPANY.PAYMENT.GET_PAYMENT_ERROR",

  REMOVE_PAYMENT: "MY_ASD.COMPANY.PAYMENT.REMOVE_PAYMENT",
  REMOVE_PAYMENT_LOADING: "MY_ASD.COMPANY.PAYMENT.REMOVE_PAYMENT_LOADING",
  REMOVE_PAYMENT_SUCCESS: "MY_ASD.COMPANY.PAYMENT.REMOVE_PAYMENT_SUCCESS",
  REMOVE_PAYMENT_ERROR: "MY_ASD.COMPANY.PAYMENT.REMOVE_PAYMENT_ERROR",

  RESET: "MY_ASD.COMPANY.PAYMENT.RES",
  REFRESH_ERROR: "MY_ASD.COMPANY.PAYMENT.REFRESH_ERROR",
};

export default actionTypes;
