import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  const serviceApi = routesApi.fiscal.POD.token;
  return {
    getDeliveryByToken(data: dataReqI) {
      return api.request({
        method: serviceApi.getDeliveryByToken.method,
        route: serviceApi.getDeliveryByToken.path,
        params: data.params,
      });
    },
    transporterSelection(data: dataReqI) {
      const transporterSelection = serviceApi.transporterSelection;
      return api.request({
        method: transporterSelection.method,
        route: transporterSelection.path,
        params: data.params,
        data: data.data,
      });
    },
    uploadProof(data: dataReqI) {
      const uploadProof = serviceApi.uploadProof;
      const form: any = new FormData();
      form.append('file', data.data.file);
      form.set('type', data.data.type);

      return api.request({
        method: uploadProof.method,
        route: uploadProof.path,
        params: data.params,
        data: form,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    getOneProof(data: dataReqI) {
      const getOneProof = serviceApi.getOneProof;
      return api.request({
        method: getOneProof.method,
        route: getOneProof.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    downloadAttestation(data: dataReqI) {
      const downloadAttestation = serviceApi.downloadAttestation;
      return api.request({
        method: downloadAttestation.method,
        route: downloadAttestation.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    downloadCustomsRegimeInvoice(data: dataReqI) {
      const downloadCustomsRegimeInvoice =
        serviceApi.downloadCustomsRegimeInvoice;
      return api.request({
        method: downloadCustomsRegimeInvoice.method,
        route: downloadCustomsRegimeInvoice.path,
        params: data.params,
        responseType: 'blob',
      });
    },
  };
};
