import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    reportBug: false,
    getPermissions: false,
  },
  errors: {
    reportBug: null,
    getPermissions: null,
  },
  loading: {
    reportBug: false,
    getPermissions: false,
  },
  error: [],
  permissions: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // report bug
    case actionTypes.REPORTBUG_LOADING:
      return produce(state, (draft) => {
        draft.loading.reportBug = true;
      });

    case actionTypes.REPORTBUG_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.reportBug = null;
        draft.loading.reportBug = false;
        draft.success.reportBug = true;
      });

    case actionTypes.REPORTBUG_ERROR:
      return produce(state, (draft) => {
        draft.errors.reportBug = true;
        draft.loading.reportBug = false;
        draft.success.reportBug = false;
      });

    // getPermissions
    case actionTypes.GET_PERMISSIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPermissions = true;
      });

    case actionTypes.GET_PERMISSIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPermissions = null;
        draft.loading.getPermissions = false;
        draft.success.getPermissions = true;
        draft.permissions = action.payload;
      });

    case actionTypes.GET_PERMISSIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPermissions = true;
        draft.loading.getPermissions = false;
        draft.success.getPermissions = false;
        !draft.error.includes('permissions') && draft.error.push('permissions');
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
