import React, { useState, useEffect } from 'react';
import { actions, selectors } from 'store';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import { Table, Header, Button as Btn, Segment } from 'semantic-ui-react';
import ClickableText from 'components/ClickableText';
import Button from 'components/Button';
import DecisionModal from 'components/QuickFixes/DeliveryForm/DecisionModal';
import TextColored from 'components/QuickFixes/DeliveryForm/TextColored';

const Index = ({
  dispatch,
  receivedData,
  proofsLevel,
  deliveryId,
  disabledAction,
  token,
  companyId,
  companyType,
  userAdminOrOp,
}: any) => {
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setData] = useState(null);
  const [decisionModal, setDecisionModal] = useState(false);
  const [proofSelected, setProofSelected] = useState(null);

  const openDecisionModal = (proof) => {
    setDecisionModal(true);
    setProofSelected(proof);
  };

  useEffect(() => {
    receivedData.length > 0 && setData(receivedData);
  }, [receivedData]);

  const disable = (companyType, validated) => {
    if(companyType === 'seller' && validated){
      return true

    }else if((companyType === 'buyer' || companyType === 'transporter') && validated){
      return true

    }else if((companyType === 'buyer' || companyType === 'transporter') && !validated){
      return true
      
    }else{
      return false
    }
  }

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setData(_.sortBy(data, [name]));
      return;
    }
    setData(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const displayModal = () => {
    return (
      <DecisionModal
        isModalOpen={decisionModal}
        setIsModalOpen={setDecisionModal}
        handleClose={() => setDecisionModal(false)}
        thirdPartyEmail={proofSelected.senderEmail}
        documentName={proofSelected.document}
        thirdPartyName={proofSelected.documentSender}
        setReceiverChoiceState
        receiverChoice={true}
        deliveryId={+deliveryId}
        proofId={proofSelected.id}
        companyId={companyId}
      />
    );
  };

  const downloadDocument = (id) => {
    dispatch(
      actions.quickfixes.downloadOneProof({
        params: {
          companyId: companyId,
          deliveryId: +deliveryId,
          proofId: id,
        },
      })
    );
    return () => {
      dispatch(actions.quickfixes.reset(['oneProof', 'error']));
    };
  };

  const downloadDocumentByToken = (proofId) => {
    dispatch(
      actions.quickfixesToken.getOneProof({
        params: {
          token: token,
          proofId: proofId,
        },
      })
    );
  };

  return (
    <>
      {data === null && (
        <Segment>
          <Header
            textAlign='center'
            size='small'
          >{`No Level ${proofsLevel} proof has been uploaded.`}</Header>
        </Segment>
      )}

      {decisionModal === true && proofSelected !== null && displayModal()}

      {data !== null && data.length > 0 && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'date' ? direction : null}
                onClick={() => handleSort('date')}
              >
                Date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'type' ? direction : null}
                onClick={() => handleSort('type')}
              >
                Type
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'document' ? direction : null}
                onClick={() => handleSort('document')}
              >
                Document
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'state' ? direction : null}
                onClick={() => handleSort('state')}
              >
                State
              </Table.HeaderCell>
              {!disabledAction && (
                <Table.HeaderCell
                  sorted={column === 'decision' ? direction : null}
                  onClick={() => handleSort('decision')}
                >
                  Decision
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.map((e, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>
                    {new Date(e.createdAt).toLocaleDateString('en', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Table.Cell>
                  <Table.Cell>{e.type}</Table.Cell>
                  <Table.Cell>
                    <ClickableText
                      color='blue'
                      onClick={() => {
                        token
                          ? downloadDocumentByToken(e.id)
                          : downloadDocument(e.id);
                      }}
                    >
                      Download it
                    </ClickableText>
                  </Table.Cell>

                  <Table.Cell>
                    {e.validated === null ? (
                      <TextColored color='orange'>Waiting</TextColored>
                    ) : e.validated === false ? (
                      <TextColored color='red'>Refused</TextColored>
                    ) : (
                      <TextColored color='green'>Accepted</TextColored>
                    )}
                  </Table.Cell>
                
                    <Table.Cell>
                    {!disabledAction && (
                      <Btn.Group vertical widths='two'>
                        <Button
                          type='submit'
                          icon='chevron up'
                          color='blue'
                          disabled={disable(companyType, e.validated !== null)}
                          onClick={() => openDecisionModal(e)}
                          margin='5px'
                        />
                      </Btn.Group>
                           )}
                    </Table.Cell>
             
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorHandlerQuickfixesToken: selectors.quickfixesToken.errorSelector(state),
    errorHandlerQuickfixes: selectors.quickfixes.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
