import React from 'react';

import { Error } from 'components/Form';
import { Wrapper, ButtonStyled, ChosenFile, File } from './styles';

const Index = ({ label, field, meta, touched, error, action }: any) => {
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <label>{label}</label>
      <Wrapper onClick={handleClick} error={touched && error}>
        <ButtonStyled type='button'>Choose a file</ButtonStyled>
        <ChosenFile>
          {field.value ? field.value.name : 'no file selected'}
        </ChosenFile>
        <File
          name={field.name}
          type='file'
          ref={hiddenFileInput}
          onBlur={(e) => {
            meta.setFieldTouched(field.name, e.target.files[0]);
          }}
          onChange={(e) => {
            meta.setFieldValue(field.name, e.target.files[0]);
            meta.setFieldTouched(field.name, e.target.files[0]);
            action && action();
          }}
        />
      </Wrapper>
      {touched && error && <Error msg={error} />}
    </>
  );
};

export default Index;
