import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

export const Wrapper = styled.div(({ margin }: any) => ({
  margin: "0",
  display: "inline-block"
}));

export const WrapperInline = styled.div(({ margin }: any) => ({
  margin: "0",
  display: "inline-block",
  paddingLeft: "10rem",
  paddingRight: "10rem"
}));

export const BtnWrapper = styled.div(({ margin }: any) => ({
  display: "flex",
  justifyContent: "center"
}));

export const Field = styled(Form.Field)`
  &&& {
    margin: 0;
    display: inline-block;
  }
`;
