// Dependencies
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Content from '../components/content/content';
import CompanyInformation from '../components/companyInformation/companyInformation';

// Stylesheets
import '../index.css';

interface CompanyPageProperties {
  companyId: number;
}

/**
 * @name spwCompanyPage.tsx
 *
 * @path /pages/SPWNavbarPages/spwCompanyPage.tsx
 */
const CompanyPage: React.FC = ({ history, user }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const { companyId } = useParams<CompanyPageProperties>();
  const adminOrOperator: boolean = user.operator || user.admin;

  return (
    <div className='pages'>
      <Helmet>
        <title>
          {t('headerStrings.companyButtonStrings.information')}
          {t('helmetString')}
        </title>
      </Helmet>
      <Header />
      <Content
        title={t('companyPageStrings.companyTitle')}
        homepage={false}
        history={adminOrOperator ? `/spw/companies` : `/spw/`}
      />
      <CompanyInformation companyId={companyId} />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(CompanyPage);
