import React, { useEffect, useRef, useState } from 'react';
import { orderBy } from 'lodash';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Icon } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import { FiscalPeriod } from 'components/Form';
import { monitoringFiltersSchema } from 'interfaces/formSchema/collectionboxValidators';
import { Input } from 'components/Form';
import Dropdown from 'components/Dropdown';
import { VertSep } from './styles';


const SearchFilters = ({
  dispatch,
  companySelected,
  user,
  applyFilters,
  searchLoading,
  categories,
  contactsLoading,
  categoriesLoading,
  resetFilters,
  isVisible,
  companies,
  contacts,
  companyId
}: any) => {
  const [companiesList, setCompaniesList] = useState([]);
  const [categoriesList, setCategoriesList]: any = useState(null);
  const searchRef = useRef()

  type session = {
    collectionboxId: number,
    Company_Id: number,
    declarationType: string,
    intranetReference: string,
    taxPeriod: string,
    showAll: boolean,
  }

  const data: session = JSON.parse(sessionStorage.getItem('collection_search'))

  useEffect(() => {
    if(user.operator){
      dispatch(actions.collectionbox.getCompanySubscribed());
      return () => {
        dispatch(actions.collectionbox.reset(['companySubscribed', 'error']));
      };
    }else{
      dispatch(
        actions.contact.getAllContacts({
          params: { companyId: companySelected },
        })
      );
    }

    dispatch(actions.collectionbox.getCategories({
      params: {
        companyId: companyId
      }
    }));

    return () => {
      dispatch(
        actions.collectionbox.reset([
          'categories',
          'companySubscribed',
          'contacts',
          'error',
        ]));
    };
  }, [dispatch, companyId, companySelected, user]);

  useEffect(() => {
    if(companies || contacts){
      const list = companies || contacts
      setCompaniesList(
        list.map((company, index) => {
          return {
            key: index,
            value: company.id,
            flag: company.country.toLowerCase(),
            text: company.name,
          };
        })
      );
    }
  }, [companies, contacts]);

  useEffect(() => {
    if(categories){
      const list = orderBy(categories, ['name', 'ASC'])
      setCategoriesList(list.map((e) => {
        return {
          key: e.id,
          value: e.id,
          text: e.name
        }
      }))
    }
  }, [categories])


  return (
    <Segment
      {...(!isVisible && { display: 'none' })}
      margin='1rem 0 0 0'
      width='100%'
    >
      <Formik
        ref={searchRef}
        enableReinitialize={true} 
        validateOnChange={true}
        initialValues={data ? {
          collectionboxId: data.collectionboxId || '',
          declarationType: data.declarationType || null,
          intranetReference: data.intranetReference || '',
          taxPeriod: data.taxPeriod || '',
          Company_Id: data.Company_Id || '',
          showAll: data.showAll ? data.showAll : false,
        } : {
          collectionboxId: '',
          declarationType: '',
          intranetReference: '',
          taxPeriod: '',
          Company_Id: '',
          showAll: false,
        }}
        onSubmit={applyFilters}
        validationSchema={monitoringFiltersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper
              display='flex'
              justifyContent='center'
              flexDirection='column'
            >
             
              <Wrapper 
                display='flex' 
                margin='0.5rem' 
                justifyContent='center'
              >
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Collectionbox reference...'
                    name='collectionboxId'
                    id='collectionboxId'
                    type='text'
                    component={Input}
                    meta={{ 
                      errors, 
                      touched 
                    }}
                    margin='20px'
                  />
                </Wrapper>         
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Type...'
                    name='declarationType'
                    id='declarationType'
                    options={categoriesList}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper> 
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Company...'
                    name='Company_Id'
                    id='Company_Id'
                    options={companiesList}
                    loading={contactsLoading}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>                                                                                                       
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Intranet reference'
                    name='intranetReference'
                    id='intranetReference'
                    type='text'
                    component={Input}
                    meta={{ 
                      errors, 
                      touched 
                    }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='taxPeriod'
                    name='taxPeriod'
                    id='taxPeriod'
                    component={FiscalPeriod}
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>
            <Wrapper display='flex' margin='1rem' justifyContent='center' alignItems='center'>
                <Field              
                  name='showAll'
                  id='showAll'
                  label='Archives'
                  margin='20px'
                  checked={values.showAll}
                  component={Checkbox}
                  meta={{ 
                    errors, 
                    touched,
                    setFieldValue, 
                    setFieldTouched 
                  }}
                  onChange={() => {
                    setFieldValue('showAll', !values.showAll)
                  }}
                />
              <VertSep />
              <Button
                disabled={searchLoading}
                {...(searchLoading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Button
                type='button'
                content='Reset'
                color='red'
                onClick={() => {
                  resetFilters();
                  resetForm();
                }}
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: selectors.collectionbox.categoriesSelector(state),
    categoriesLoading: selectors.collectionbox.categoriesLoadingSelector(state),
    contactsLoading: selectors.collectionbox.getContactsSubscribedLoadingSelector(state),
    contacts: selectors.collectionbox.getContactsSubscribedSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(SearchFilters);

