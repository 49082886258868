// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import EditButton from '../editButton/editButton';
import UpdateGlobalCompanyDetails from '../updateForms/updateGlobalCompanyDetails';
import UpdateDirectorCompanyDetails from '../updateForms/updateDirectorCompanyDetails';
import UpdateAddressesCompanyDetails from '../updateForms/updateAddressesCompanyDetails';
import Loader from 'components/Loader';

/**
 * Resources
 */
import { currentLanguage } from 'views';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';

// Stylesheets
import './companyInformation.css';
import 'react-edit-text/dist/index.css';

interface CompanyInformationProperties {
  companyId: number;
}

/**
 * @name companyInformation.tsx
 *
 * @path /components/companyInformation/companyInformation.tsx
 *
 * @description Defines company information's screen
 */
const CompanyInformation: React.FC = ({ user }: any) => {
  const { companyId } = useParams<CompanyInformationProperties>();

  const childRefCompanyDetailsGlobal = useRef<HTMLDivElement>(null);
  const companyDetailsGlobalButtonRef = useRef<HTMLButtonElement>(null);
  const companyDetailsGlobalEditPopupRef = useRef<HTMLDivElement>(null);

  const [
    companyDetailsGlobalEditPopupIsVisible,
    setCompanyDetailsGlobalEditPopupIsVisible,
  ] = useState<boolean>(false);

  const handleClickEditGlobalButton = () => {
    setCompanyDetailsGlobalEditPopupIsVisible(
      !companyDetailsGlobalEditPopupIsVisible
    );
  };

  const childRefCompanyDetailsDirector = useRef<HTMLDivElement>(null);
  const companyDetailsDirectorButtonRef = useRef<HTMLButtonElement>(null);
  const companyDetailsDirectorEditPopupRef = useRef<HTMLDivElement>(null);

  const [
    companyDetailsDirectorEditPopupIsVisible,
    setCompanyDetailsDirectorEditPopupIsVisible,
  ] = useState<boolean>(false);

  const handleClickEditDirectorButton = () => {
    setCompanyDetailsDirectorEditPopupIsVisible(
      !companyDetailsDirectorEditPopupIsVisible
    );
  };

  // Popup references
  const childRefCompanyDetailsAddresses = useRef<HTMLDivElement>(null);
  const companyDetailsAddressesButtonRef = useRef<HTMLButtonElement>(null);
  const companyDetailsAddressesEditPopupRef = useRef<HTMLDivElement>(null);

  // Manage popup visibility
  const [
    companyDetailsAddressesEditPopupIsVisible,
    setCompanyDetailsAddressesEditPopupIsVisible,
  ] = useState<boolean>(false);

  const handleClickEditAddressesButton = () => {
    setCompanyDetailsAddressesEditPopupIsVisible(
      !companyDetailsAddressesEditPopupIsVisible
    );
  };

  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Variables to temporarily save values until they're send to backoffice
  const [companyName, setCompanyName] = useState<string>();
  const [legalStatus, setLegalStatus] = useState<string>();
  const [vatNumber, setVatNumber] = useState<string>();
  const [deliveryMainAddress, setDeliveryMainAddress] = useState<string>();
  const [deliveryAdditionalAddress, setDeliveryAdditionalAddress] =
    useState<string>();
  const [deliveryPostalCode, setDeliveryPostalCode] = useState<string>();
  const [deliveryCity, setDeliveryCity] = useState<string>();
  const [deliveryCountry, setDeliveryCountry] = useState<string>();
  const [deliveryCountryText, setDeliveryCountryText] = useState<string>();
  const [billingMainAddress, setBillingMainAddress] = useState<string>();
  const [billingAdditionalAddress, setBillingAdditionalAddress] =
    useState<string>();
  const [billingPostalCode, setBillingPostalCode] = useState<string>();
  const [billingCity, setBillingCity] = useState<string>();
  const [billingCountry, setBillingCountry] = useState<string>();
  const [billingCountryText, setBillingCountryText] = useState<string>();
  const [directorFirstName, setDirectorFirstName] = useState<string>();
  const [directorLastName, setDirectorLastName] = useState<string>();
  const [
    directorDateOfBirthWithoutFormatting,
    setDirectorDateOfBirthWithoutFormatting,
  ] = useState<string>();
  const [directorDateOfBirth, setDirectorDateOfBirth] = useState<string>();
  const [directorPlaceOfBirth, setDirectorPlaceOfBirth] = useState<string>();
  const [directorCountryOfBirth, setDirectorCountryOfBirth] =
    useState<string>();
  const [directorCountryOfBirthText, setDirectorCountryOfBirthText] =
    useState<string>();
  const [deliveryAddressId, setDeliveryAddressId] = useState<number>();
  const [billingAddressId, setBillingAddressId] = useState<number>();
  const [directorId, setDirectorId] = useState<number>();
  const [contactId, setContactId] = useState<number>();
  const [contactLastName, setContactLastName] = useState<string>();
  const [contactFirstName, setContactFirstName] = useState<string>();
  const [contactMailAddress, setContactMailAddress] = useState<string>();
  const [contactPhoneNumber, setContactPhoneNumber] = useState<string>();

  async function getCompanyDetails() {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getCompanyDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: companyId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setCompanyName(result.data.companyName);
        setLegalStatus(result.data.legalStatus);
        setVatNumber(result.data.vatOrRegistrationNumber);
        setDeliveryAddressId(result.data.deliveryAddress);
        setBillingAddressId(result.data.billingAddress);
        setDirectorId(result.data.director);
        setContactId(result.data.contact);

        setIsLoading(false);

        return JSON.stringify(result.data.companyId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getDeliveryAddress(addressId: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAddress`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: addressId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setDeliveryMainAddress(result.data.mainAddress);
        setDeliveryAdditionalAddress(result.data.additionalAddress);
        setDeliveryPostalCode(result.data.postalCode);
        setDeliveryCity(result.data.city);
        setDeliveryCountry(result.data.country);

        let deliveryCountry: string;
        if (currentLanguage == 'fr') {
          let country = countriesFR.find(
            (country) => country.code === result.data.country
          );
          deliveryCountry = country ? country.name : 'Country not found';
        } else {
          let country = countriesEN.find(
            (country) => country.code === result.data.country
          );
          deliveryCountry = country ? country.name : 'Country not found';
        }
        setDeliveryCountryText(deliveryCountry);

        setIsLoading(false);

        return JSON.stringify(result.data);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getBillingAddress(addressId: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAddress`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: addressId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setBillingMainAddress(result.data.mainAddress);
        setBillingAdditionalAddress(result.data.additionalAddress);
        setBillingPostalCode(result.data.postalCode);
        setBillingCity(result.data.city);
        setBillingCountry(result.data.country);

        let billingCountry: string;
        if (currentLanguage == 'fr') {
          let country = countriesFR.find(
            (country) => country.code === result.data.country
          );
          billingCountry = country ? country.name : 'Country not found';
        } else {
          let country = countriesEN.find(
            (country) => country.code === result.data.country
          );
          billingCountry = country ? country.name : 'Country not found';
        }
        setBillingCountryText(billingCountry);

        setIsLoading(false);

        return JSON.stringify(result.data);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getDirectorDetails(directorId: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getDirectorDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: directorId }),
        }
      );
      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setDirectorLastName(result.data.lastName);
        setDirectorFirstName(result.data.firstName);
        setDirectorDateOfBirthWithoutFormatting(result.data.dateOfBirth);

        let dateObject = new Date(result.data.dateOfBirth);

        let day = dateObject.getDate();
        let month = dateObject.getMonth() + 1;
        let year = dateObject.getFullYear();

        let formattedDate = `${day.toString().padStart(2, '0')}/${month
          .toString()
          .padStart(2, '0')}/${year}`;

        setDirectorDateOfBirth(formattedDate);
        setDirectorPlaceOfBirth(result.data.placeOfBirth);
        setDirectorCountryOfBirth(result.data.countryOfBirth);

        let directorCountryOfBirth: string;
        if (currentLanguage == 'fr') {
          let country = countriesFR.find(
            (country) => country.code === result.data.countryOfBirth
          );
          directorCountryOfBirth = country ? country.name : 'Country not found';
        } else {
          let country = countriesEN.find(
            (country) => country.code === result.data.countryOfBirth
          );
          directorCountryOfBirth = country ? country.name : 'Country not found';
        }
        setDirectorCountryOfBirthText(directorCountryOfBirth);

        setIsLoading(false);

        return JSON.stringify(result.data);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getContactDetais(contactId: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getContactDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: contactId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setContactLastName(result.data.lastName);
        setContactFirstName(result.data.firstName);
        setContactMailAddress(result.data.mailAddress);
        setContactPhoneNumber(result.data.phoneNumber);

        setIsLoading(false);

        return JSON.stringify(result.data);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  useEffect(() => {
    async function exec() {
      await getCompanyDetails();
    }
    exec();
  }, [deliveryAddressId, billingAddressId, directorId, contactId]);

  useEffect(() => {
    async function exec() {
      await getDeliveryAddress(deliveryAddressId);
    }
    exec();
  }, [deliveryAddressId]);

  useEffect(() => {
    async function exec() {
      await getBillingAddress(billingAddressId);
    }
    exec();
  }, [billingAddressId]);

  useEffect(() => {
    async function exec() {
      await getDirectorDetails(directorId);
    }
    exec();
  }, [directorId]);

  useEffect(() => {
    async function exec() {
      await getContactDetais(contactId);
    }
    exec();
  }, [contactId]);

  return (
    <>
      {(isLoading && <Loader />) ||
        (!isLoading && (
          <>
            <div className='companyInformationContainer'>
              <div className='topContainer'>
                <div className='globalInformationZone'>
                  <h4 className='sectionsEntitled'>
                    {t('missionDetailsPageStrings.globalInformationTitle')}
                  </h4>
                  <div className='globalCompanyDetailsContainer'>
                    <div className='globalCompanyDetails'>
                      <p>
                        <b>
                          {t('companyPageStrings.companyNameFieldPlaceHolder')}
                        </b>
                        {companyName}
                      </p>
                      <p>
                        <b>
                          {t('companyPageStrings.legalStatusFieldPlaceHolder')}
                        </b>
                        {legalStatus}
                      </p>
                      <p>
                        <b>
                          {t('companyPageStrings.vatNumberFieldPlaceHolder')}
                        </b>
                        {vatNumber}
                      </p>
                    </div>
                    <div className='contactDetails'>
                      <p>
                        <b>
                          {t(
                            'companyPageStrings.contactFullNameFieldPlaceHolder'
                          )}
                        </b>
                        {(contactLastName == undefined ||
                          contactLastName == '') &&
                        (contactFirstName == undefined ||
                          contactFirstName == '') ? (
                          <i>{t('globalStrings.notSpecified')}</i>
                        ) : (
                          contactFirstName + ' ' + contactLastName
                        )}
                      </p>
                      <p>
                        <b>
                          {t(
                            'companyPageStrings.contactMailAddressFieldPlaceHolder'
                          )}
                        </b>
                        <a href={`mailto: ${contactMailAddress}`}>
                          {contactMailAddress}
                        </a>
                      </p>
                      <p>
                        <b>
                          {t(
                            'companyPageStrings.contactPhoneNumberFieldPlaceHolder'
                          )}
                        </b>
                        <a href={`callto: ${contactPhoneNumber}`}>
                          {contactPhoneNumber}
                        </a>
                      </p>
                    </div>
                  </div>

                  <EditButton
                    popupVisible={companyDetailsGlobalEditPopupIsVisible}
                    myRef={companyDetailsGlobalButtonRef}
                    popupRef={companyDetailsGlobalEditPopupRef}
                    title={t('companyPageStrings.updateGlobalCompanyDetails')}
                    action={handleClickEditGlobalButton}
                    children={
                      <UpdateGlobalCompanyDetails
                        ref={childRefCompanyDetailsGlobal}
                        id={companyId}
                        defaultCompanyName={companyName}
                        defaultLegalStatus={legalStatus}
                        defaultVatNumber={vatNumber}
                        contactId={contactId}
                        defaultContactLastName={contactLastName}
                        defaultContactFirstName={contactFirstName}
                        defaultContactMailAddress={contactMailAddress}
                        defaultContactPhoneNumber={contactPhoneNumber}
                      />
                    }
                  />
                </div>
              </div>
              <hr />
              {/* Middle zone */}
              <div className='middleContainer'>
                <div className='deliveryAddress'>
                  <h4 className='sectionsEntitled'>
                    {t('companyPageStrings.deliveryAndBillingTitle')}
                  </h4>
                  <div className='addressesDetails'>
                    <div
                      id={'deliveryAddress'}
                      className='addressesDetailsBlock'
                    >
                      <p>
                        <b>
                          {t('companyPageStrings.mainDeliveryAddressField')}
                        </b>
                        {deliveryMainAddress}
                      </p>
                      <p>
                        <b>{t('companyPageStrings.additionalAddressField')}</b>
                        {deliveryAdditionalAddress == undefined ||
                        deliveryAdditionalAddress == '' ? (
                          <i>{t('globalStrings.notSpecified')}</i>
                        ) : (
                          deliveryAdditionalAddress
                        )}
                      </p>
                      <p>
                        <b>{t('companyPageStrings.postalCodeField')}</b>
                        {deliveryPostalCode}
                      </p>
                      <p>
                        <b>{t('companyPageStrings.cityField')}</b>
                        {deliveryCity}
                      </p>
                      <p>
                        <b>{t('companyPageStrings.countryField')}</b>
                        {deliveryCountryText}
                      </p>
                    </div>
                    <div className='addressesDetailsBlock'>
                      <p>
                        <b>{t('companyPageStrings.mainBillingAddressField')}</b>
                        {billingMainAddress}
                      </p>
                      <p>
                        <b>{t('companyPageStrings.additionalAddressField')}</b>
                        {billingAdditionalAddress == undefined ||
                        billingAdditionalAddress == '' ? (
                          <i>{t('globalStrings.notSpecified')}</i>
                        ) : (
                          billingAdditionalAddress
                        )}
                      </p>
                      <p>
                        <b>{t('companyPageStrings.postalCodeField')}</b>
                        {billingPostalCode}
                      </p>
                      <p>
                        <b>{t('companyPageStrings.cityField')}</b>
                        {billingCity}
                      </p>
                      <p>
                        <b>{t('companyPageStrings.countryField')}</b>
                        {billingCountryText}
                      </p>
                    </div>
                  </div>
                  <EditButton
                    popupVisible={companyDetailsAddressesEditPopupIsVisible}
                    myRef={companyDetailsAddressesButtonRef}
                    popupRef={companyDetailsAddressesEditPopupRef}
                    title={t(
                      'companyPageStrings.updateCompanyAddressesDetails'
                    )}
                    action={handleClickEditAddressesButton}
                    children={
                      <UpdateAddressesCompanyDetails
                        ref={childRefCompanyDetailsAddresses}
                        deliveryId={deliveryAddressId}
                        defaultDeliveryMainAddress={deliveryMainAddress}
                        defaultDeliveryAdditionalAddress={
                          deliveryAdditionalAddress
                        }
                        defaultDeliveryPostalCode={deliveryPostalCode}
                        defaultDeliveryCity={deliveryCity}
                        defaultDeliveryCountry={deliveryCountry}
                        billingId={billingAddressId}
                        defaultBillingMainAddress={billingMainAddress}
                        defaultBillingAdditionalAddress={
                          billingAdditionalAddress
                        }
                        defaultBillingPostalCode={billingPostalCode}
                        defaultBillingCity={billingCity}
                        defaultBillingCountry={billingCountry}
                      />
                    }
                  />
                </div>
              </div>
              <hr />
              <div className='bottomContainer'>
                <div className='bottomContainerBlock'>
                  <h4 className='sectionsEntitled'>
                    {t('companyPageStrings.directorInformationTitle')}
                  </h4>
                  <div className='directorZone'>
                    <div className='directorDetails'>
                      <div className='directorDetailsBlock'>
                        <p>
                          <b>
                            {t(
                              'companyPageStrings.directorLastNameFieldPlaceholder'
                            )}
                          </b>
                          {directorLastName}
                        </p>
                        <p>
                          <b>
                            {t(
                              'companyPageStrings.directorFirstNameFieldPlaceholder'
                            )}
                          </b>
                          {directorFirstName}
                        </p>
                        <p>
                          <b>
                            {t(
                              'companyPageStrings.directorDateOfBirthFieldPlaceholder'
                            )}
                          </b>
                          {directorDateOfBirth}
                        </p>
                      </div>
                      <div className='directorDetailsBlock'>
                        <p>
                          <b>
                            {t(
                              'companyPageStrings.directorPlaceOfBirthFieldPlaceholder'
                            )}
                          </b>
                          {directorPlaceOfBirth}
                        </p>
                        <p>
                          <b>
                            {t(
                              'companyPageStrings.directorCountryOfBirthFieldPlaceholder'
                            )}
                          </b>
                          {directorCountryOfBirthText}
                        </p>
                      </div>
                    </div>
                    <EditButton
                      popupVisible={companyDetailsDirectorEditPopupIsVisible}
                      myRef={companyDetailsDirectorButtonRef}
                      popupRef={companyDetailsDirectorEditPopupRef}
                      title={t(
                        'companyPageStrings.updateCompanyDirectorDetails'
                      )}
                      action={handleClickEditDirectorButton}
                      children={
                        <UpdateDirectorCompanyDetails
                          ref={childRefCompanyDetailsDirector}
                          id={directorId}
                          defaultLastName={directorLastName}
                          defaultFirstName={directorFirstName}
                          defaultDateOfBirth={
                            directorDateOfBirthWithoutFormatting
                          }
                          defaultPlaceOfBirth={directorPlaceOfBirth}
                          defaultCountryOfBirth={directorCountryOfBirth}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(CompanyInformation);
