import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import Loader from 'components/Loader';

// Stylesheets
import './companiesList.css';

const CompaniesList: React.FC = ({ user }: any) => {
  const { t } = useTranslation();

  const initialValue = 1;
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState<number>(initialValue);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [apiResult, setApiResult] = useState<[number, string][]>([]);

  const getCurrentPageItems = (data: [number, string][]) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const getAllCompanies = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAllCompanies`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setApiResult(result.data);
        return result;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  useEffect(() => {
    async function exec() {
      await getAllCompanies();
    }
    exec().then(() => setIsLoading(false));
  }, []);

  const handleClickDetails = (companyId: number) => {
    window.location.href = `/spw/company/details/` + companyId;
  };

  const handleClickEmployees = (companyId: number) => {
    window.location.href = `/spw/company/employees/` + companyId;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='companiesListContainer'>
          {/* Header */}
          <div className='companiesListHeader'>
            <div className='companiesListHeadingsSorters'>
              <b>{t('companiesPageStrings.companyName')}</b>
            </div>
          </div>
          {/* Body */}
          <div className='companiesListBody'>
            {getCurrentPageItems(apiResult).map(
              ([companyId, companyName], index) => {
                return (
                  <div key={index} className='companiesListCell'>
                    <div>
                      <p>{companyName}</p>
                    </div>
                    <div className='companiesListCellContent'>
                      <button
                        className='companiesListCellsButton'
                        onClick={() => handleClickDetails(companyId)}
                      >
                        <p>{t('companiesPageStrings.detailsButton')}</p>
                      </button>
                      <button
                        className='companiesListCellsButton'
                        onClick={() => handleClickEmployees(companyId)}
                      >
                        <p>{t('companiesPageStrings.employeesButton')}</p>
                      </button>
                    </div>
                  </div>
                );
              }
            )}
          </div>
          {/* Footer */}
          <div className='companiesListFooter'>
            <button
              onClick={
                currentPage > 5
                  ? () => setCurrentPage(currentPage - 5)
                  : () => setCurrentPage(1)
              }
              disabled={currentPage === 1}
            >
              ◀◀
            </button>
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              ◀
            </button>
            <span>{currentPage}</span>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage * itemsPerPage >= apiResult.length}
            >
              ▶
            </button>
            <button
              onClick={
                currentPage + 5 <= apiResult.length / itemsPerPage
                  ? () => setCurrentPage(currentPage + 5)
                  : () =>
                      setCurrentPage(Math.ceil(apiResult.length / itemsPerPage))
              }
              disabled={currentPage * itemsPerPage >= apiResult.length}
            >
              ▶▶
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(CompaniesList);
