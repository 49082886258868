import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button as Btn, Container, Divider, Table } from 'semantic-ui-react';

import LinkButton from 'components/core/LinkButton/LinkButton';
import TableSpacedRows from 'components/TableSpacedRows';
import { Main } from 'components/Layouts';
import { actions, selectors } from 'store';
import EmptyTable from 'components/EmptyTableCurve';
import Search from 'components/Search';
import Wrapper from 'components/core/Wrapper/Wrapper';

const Index = ({ dispatch, history, operators }) => {
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [resetSearch, setresetSearch] = useState(false);

  useEffect(() => {
    dispatch(actions.operators.getAllOperator());

    return () => {
      dispatch(actions.operators.reset(['allOperator', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    setdata(_.orderBy(operators, ['firstName'], ['asc']));
    resetSearch && setresetSearch(false);
  }, [operators, resetSearch]);

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['firstName'], ['asc']));
  };

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  return (
    <Main>
      <Divider hidden />
      <Container>
        <Wrapper display='flex'>
          <Search
            dataSource={operators}
            onSearchResultChange={onSearchResultChange}
            setResetSearch={setresetSearch}
          />
        </Wrapper>
      </Container>
      <Container>
        <TableSpacedRows sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'firstName' ? direction : null}
                onClick={() => handleSort('firstName')}
              >
                First name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'lastName' ? direction : null}
                onClick={() => handleSort('lastName')}
              >
                Last name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'email' ? direction : null}
                onClick={() => handleSort('email')}
              >
                Email
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(data.length === 0 && <EmptyTable colNumber={5} />) ||
              data.map((operator, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{operator.firstName}</Table.Cell>
                    <Table.Cell>{operator.lastName}</Table.Cell>
                    <Table.Cell>{operator.email}</Table.Cell>
                    <Table.Cell />
                    <Table.Cell>
                      <Btn.Group vertical widths='two'>
                        <LinkButton
                          content='Details'
                          icon='eye'
                          type='submit'
                          labelPosition='right'
                          path={`/operator/${operator.id}`}
                        />
                      </Btn.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </TableSpacedRows>
      </Container>
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    operators: selectors.operators.allOperatorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
