import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  getCompanySubscribed() {
    return {
      type: actionTypes.GET_COMPANY_SUBSCRIBED,
    };
  },
  declare(data: dataReqI) {
    return {
      type: actionTypes.DECLARE,
      payload: data,
    };
  },
  getDeclarations() {
    return {
      type: actionTypes.GETDECLARATIONS,
    };
  },
  subscribe(data: dataReqI) {
    return {
      type: actionTypes.SUBSCRIBE,
      payload: data,
    };
  },
  unsubscribe(data: dataReqI) {
    return {
      type: actionTypes.UNSUBSCRIBE,
      payload: data,
    };
  },
  getMapping() {
    return {
      type: actionTypes.GETMAPPING,
    };
  },
  getTA(data: dataReqI) {
    return {
      type: actionTypes.GETTA,
      payload: data,
    };
  },
  getLines(data: dataReqI) {
    return {
      type: actionTypes.GETLINES,
      payload: data,
    };
  },
  getDeclaration(data: dataReqI) {
    return {
      type: actionTypes.GETDECLARATION,
      payload: data,
    };
  },
  validate(data: dataReqI) {
    return {
      type: actionTypes.VALIDATE,
      payload: data,
    };
  },
  remove(data: dataReqI) {
    return {
      type: actionTypes.REMOVE,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
