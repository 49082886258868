import actionTypes from 'store/payment/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.CREATE_SUBSCRIPTION_PAYMENT:
        try {
          next({ type: actionTypes.CREATE_SUBSCRIPTION_PAYMENT_LOADING });
          const response = await request.createSubscriptionPayment({
            ...action.payload,
          });
          next({
            type: actionTypes.CREATE_SUBSCRIPTION_PAYMENT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_SUBSCRIPTION_PAYMENT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.CREATE_TOKEN:
        try {
          next({ type: actionTypes.CREATE_TOKEN_LOADING });
          const response = await request.createToken({
            ...action.payload,
          });
          next({
            type: actionTypes.CREATE_TOKEN_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_TOKEN_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UPDATE_TOKEN:
        try {
          next({ type: actionTypes.UPDATE_TOKEN_LOADING });
          const response = await request.updateToken({
            ...action.payload,
          });
          next({
            type: actionTypes.UPDATE_TOKEN_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_TOKEN_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UNSUBSCRIBE:
        try {
          next({ type: actionTypes.UNSUBSCRIBE_LOADING });
          await request.unsubscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.UNSUBSCRIBE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UNSUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.CANCEL_UNSUBSCRIBE:
        try {
          next({ type: actionTypes.CANCEL_UNSUBSCRIBE_LOADING });
          await request.cancelUnsubscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.CANCEL_UNSUBSCRIBE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.CANCEL_UNSUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
