import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button as Btn,
  Divider,
  Form,
} from 'semantic-ui-react';
import Loader from 'components/Loader';
import { commercialOfferList } from './main'
import { Formik } from 'formik';
import { selectors } from 'store';



const Index = ({
  history, user
}) => {
  

  useEffect(() => {
    user && commercialOfferList(process.env.REACT_APP_DEV_API_URL, user)
  }, [user])

  const submitForm = (values) => {}
 
  return (
    <Wrapper height='100%' width='60%' overflow='auto' margin='auto'>   
        <Wrapper>
          {false && (<Loader />)}
        </Wrapper>  
        <Divider hidden />    
          <Btn.Group>
            <Button
              floated='left'
              labelPosition='left'
              icon='angle left'
              position='flex-end'
              type='button'
              content='Back'
              margin='5px'
              padding='1rem'
              onClick={() => history.push('/')}
            />         
          </Btn.Group>
          <Wrapper width='90%' textAlign='center' margin='1rem auto'>
           
      
            <Wrapper>
            <Formik
              initialValues={{
                fr: '',
                en: 'en' || '',
                expirationDate: '',
                CompanyName: '',
                CompanyAdresse: '',
                CompanyId: '',
                CompanyVAT: '', 
                contactFirstName: '', 
                contactLastname: '', 
                contactEmail: '', 
                gooodsDetail: '',
                packages: [],            
              }}
              enableReinitialize={true}
              onSubmit={submitForm}
          >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}> 
           {/*} <Grid columns={2}>
              <Grid.Column>
                <Field
                  label='French'
                  name='fr'
                  value='fr'
                  onClick={(e, {value}) => {
                    setRadio(value)
                  }}
                  checked={radioButton === 'fr'}
                  component={Radio}
                  type='radio'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  label='English'
                  value='en'
                  name='en'
                  checked={radioButton === 'en'}
                  onClick={(e, {value}) => {
                    setRadio(value)
                  }}
                  component={Radio}
                  type='radio'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
            </Grid>

            <Header size='small'>Date d'expiration de l'offre</Header>
            <Grid columns={1}>
              <Grid.Column>
                <Field
                label='expiration date'
                  name='expirationDate'
                  component={Date}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
            </Grid>
            <Header size='small'>Détail de la société</Header>
            <Grid columns={2}>
            <Grid.Column>
              <Field
                label='Company name'
                placeholder='Nom de la société'
                name='CompanyName'
                component={Input}            
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                label='Addresse société'
                placeholder='Adresse de la société'  
                name='CompanyAdresse'
                component={Input}      
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
              />
            </Grid.Column>
            </Grid>
            <Grid columns={2}>
              <Grid.Column>
              <Field
                label='Siret'
                placeholder='N° Siret ou Immatriculation'
                name='CompanyId'
                component={Input}            
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
              />
              </Grid.Column>
              <Grid.Column>
              <Field
                label='N° de TVA'
                placeholder='N° TVA intracommunautaire'
                name='CompanyVAT'
                component={Input}          
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
              />
              </Grid.Column>
            </Grid>
          

            <Grid columns={2}>
              <Grid.Column>
                <Field
                  label='Prénom'
                  placeholder='prénom'
                  name='contactFirstName'
                  component={Input}                    
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  label='Nom'
                  placeholder='Nom'
                  name='contactLastname'
                  component={Input}            
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
            </Grid>

            <Grid>
              <Grid.Column>
                <Field
                  label='Email'
                  placeholder='Email'
                  name='contactEmail'
                  component={Input}          
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
            </Grid>

            <Header size='small'>Marchandise & volume</Header>
            <Grid>
              <Grid.Column>
                <Field
                  label='Marchandise'
                  placeholder='Marchandise & volume details'
                  name='goodDetails'
                  component={TextArea}        
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
            </Grid>
            <Divider hidden />*/}

      
        <h1 className=" d-flex justify-content-center">Offre commerciale</h1>
    <div className="text-center" id="language">
    <label>
        <input type="radio" name="language" value="fr"/>
        French
    </label>
    <label>
        <input type="radio" name="language" value="en" checked/>
        English
    </label>
    </div>

    <div className="container px-5 my-5 border rounded p-4  offer-form">
      <h4 className="mb-3 mt-2">Date d'expiration de l'offre</h4>
      <input type="date" className="form-control  form-control-sm" id="expirationDate"/>
      <h4 className="mb-3 mt-2">Détails de la société</h4>
        <div className="row g-3">
            <div className="col-md-3">
        <div className="form-group mb-2 mr-2">
            <input type="text" className="form-control form-control-sm" name="CompanyName" id="CompanyName" placeholder="Nom de la société" required/>
        </div></div>           
        <div className="col-md-3">
        <div className="form-group mb-2 mr-2">
            <input type="text" className="form-control form-control-sm" name="CompanyAdresse" id="CompanyAdresse" placeholder="Adresse de la société" required/>
        </div></div>       
        <div className="col-md-3">
        <div className="form-group mb-2 mr-2">
            <input type="text" className="form-control form-control-sm" name="CompanyId" id="CompanyId" placeholder="N° Siret ou Immatriculation" required/>
        </div></div>            
        <div className="col-md-3">
        <div className="form-group mb-2 mr-2">
            <input type="text" className="form-control form-control-sm" name="CompanyVAT" id="CompanyVAT" placeholder="N° TVA intracommunautaire" required/>
        </div>
      </div>
    </div>

  <h4 className="mb-3 mt-2">Contact informations</h4>
    <div className="row g-3">
      <div className="col-md-6">
        <div className="form-group mb-2 mr-2">
          <input type="text" className="form-control form-control-sm" name="contactfirstname" id="contactFirstname" placeholder="prénom" required/>
        </div>
      </div>        
      <div className="col-md-6">
        <div className="form-group mb-2 mr-2">
          <input type="text" className="form-control form-control-sm" name="contactLastname" id="contactLastname" placeholder="Nom" required/>
        </div>
      </div>
    </div>
      <div className="col-md-12">
        <div className="form-group mb-2 mr-2">
          <input type="email" className="form-control form-control-sm" name="contactEmail" id="contactEmail" placeholder="Email" required/>
        </div>
      </div>       
      <h4 className="mb-3 mt-2">Marchandise & volume</h4>
      <div className="form-floating margin-top">
        <textarea className="form-control" placeholder="Marchandise & volume details" id="goodDetails"></textarea>
      </div>
    </div>

    <div className="text-center">
      <div id="packages"></div>
      <div id="services"></div> 
      <form action="internalTools/commercial/generate-offer" method="post" id="form">
          <button type="submit" id="offer" name="submit">Générer l'offre</button>
          <div id="download"></div>
      </form>
    </div>

            {/* <FieldArray
              name='packages'
              render={(arrayHelper) => (                                             
                packagesList && 
                packagesList.length>0 &&
                packagesList.map((pack, i) => {                    
                return (              
                <Accordion key={i}>
                  <Accordion.Title
                    active={true}
                    index={i}
                  >
                    <Field
                      label={`${pack.package_name}`}
                      name={`packages[${i}].package_name`}               
                      component={Checkbox}
                      value={`${pack.package_name}` || ''}
                      checked={activeIndex === i}
                      onChange={() => {                  
                        setActiveIndex(activeIndex === i ? -1 : i) 
                        getSubPackage(pack.package_id, 'en') 
                        setFieldValue(`packages[${i}].package_name`, pack.package_name)                               
                      }}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === i} style={{marginBottom: '20px'}} key={i+1}>
                    {basicList.length>0 && (

            
                  <Grid columns='equal'>  
                  {basicList.map((list, j) => (
                  <Grid.Row key={list.service_id} style={{padding: '2px'}}>
                    <SubList key={`SubList_${j+1}`}>    
                      <Field
                        key={list.service_id}
                        label={`${list.service_name_fr} ${list.optionnal && '(Optional)' || ''}`}
                        name={`packages[${i}].service[${j}]`}
                        component={Checkbox}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                        onChange={() => {     
                          console.log(list)                 
                          setFieldValue(`packages[${i}].service[${j}]`, list)    
                        }}     
              
                      />
                    </SubList> 
                    <Field
                      key={`SubList_${j+2}`}
                      name={`packages[${i}].service[${j}].amount`}
                      placeholder={`${list.price_per_package}`}
                      value={`${list.price_per_package}`}
                      component={Input}
                      onChange={(e) => {
                        setFieldValue(`packages[${i}].service[${j}].amount`, e)
                      }}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                    <InlineCurrency 
                      key={`SubList_${j+3}`}
                    >
                      {list.service_price_details_fr}
                    </InlineCurrency>
                    <Field
                      placeholder="choose ..."
                      key={`SubList_${j+4}`}
                      name={`packages[${i}].service[${j}].option`}
                      component={Dropdown}
                      options={[
                        {key: 0, value: 'basic', text: 'Basic'},
                        {key: 1, value: 'complementary', text: 'Complementary'},
                        {key: 2, value: 'optional', text: 'Optional'},
                      ]}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Grid.Row>
                  ))} 
                  </Grid>
                          )}
                  </Accordion.Content>
                </Accordion>             
              )})
            )} />     */}
            {/* <Button type='submit' content='Submit' />*/}
            </Form>
            )}
            </Formik> 

            <div id="devis"></div>

            </Wrapper>
          </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    packages: selectors.commercial.packagesSelector(state),
    packagesLoading: selectors.commercial.packagesLoadingSelector(state),
    basic: selectors.commercial.basicSelector(state),
    basicLoading: selectors.commercial.basicLoadingSelector(state),
    document: selectors.commercial.formSchemasSelector(state),
    documentLoading: selectors.commercial.getFormSchemasLoadingSelector(state)
  }
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
