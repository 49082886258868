export default {

  GET_CONTACTS_HAS_SUBSCRIBED: "MY_ASD.COLLECTIONBOX.GET_CONTACTS_HAS_SUBSCRIBED",
  GET_CONTACTS_HAS_SUBSCRIBED_LOADING: "MY_ASD.COLLECTIONBOX.GET_CONTACTS_HAS_SUBSCRIBED_LOADING",
  GET_CONTACTS_HAS_SUBSCRIBED_SUCCESS: "MY_ASD.COLLECTIONBOX.GET_CONTACTS_HAS_SUBSCRIBED_SUCCESS",
  GET_CONTACTS_HAS_SUBSCRIBED_ERROR: "MY_ASD.COLLECTIONBOX.GET_CONTACTS_HAS_SUBSCRIBED_ERROR",

  GET_CATEGORIES: "MY_ASD.COLLECTIONBOX.GET_CATEGORIES",
  GET_CATEGORIES_LOADING: "MY_ASD.COLLECTIONBOX.GET_CATEGORIES_LOADING",
  GET_CATEGORIES_SUCCESS: "MY_ASD.COLLECTIONBOX.GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_ERROR: "MY_ASD.COLLECTIONBOX.GET_CATEGORIES_ERROR",

  READ_CHAT: 'MY_ASD.COLLECTIONBOX.READ_CHAT',
  READ_CHAT_LOADING: 'MY_ASD.COLLECTIONBOX.READ_CHAT_LOADING',
  READ_CHAT_SUCCESS: 'MY_ASD.COLLECTIONBOX.READ_CHAT_SUCCESS',
  READ_CHAT_ERROR: 'MY_ASD.COLLECTIONBOX.READ_CHAT_ERROR',

  GET_COMPANY_SUBSCRIBED: 'MY_ASD.COLLECTIONBOX.GET_COMPANY_SUBSCRIBED',
  GET_COMPANY_SUBSCRIBED_LOADING: 'MY_ASD.COLLECTIONBOX.GET_COMPANY_SUBSCRIBED_LOADING',
  GET_COMPANY_SUBSCRIBED_SUCCESS: 'MY_ASD.COLLECTIONBOX.GET_COMPANY_SUBSCRIBED_SUCCESS',
  GET_COMPANY_SUBSCRIBED_ERROR: 'MY_ASD.COLLECTIONBOX.GET_COMPANY_SUBSCRIBED_ERROR',

  GET_COMPANIES_BY_ISO: 'MY_ASD.COLLECTIONBOX.GET_COMPANIES_BY_ISO',
  GET_COMPANIES_BY_ISO_LOADING: 'MY_ASD.COLLECTIONBOX.GET_COMPANIES_BY_ISO_LOADING',
  GET_COMPANIES_BY_ISO_SUCCESS: 'MY_ASD.COLLECTIONBOX.GET_COMPANIES_BY_ISO_SUCCESS',
  GET_COMPANIES_BY_ISO_ERROR: 'MY_ASD.COLLECTIONBOX.GET_COMPANIES_BY_ISO_ERROR',

  GET_OBLIGATION: 'MY_ASD.COLLECTIONBOX.GET_OBLIGATION',
  GET_OBLIGATION_LOADING: 'MY_ASD.COLLECTIONBOX.GET_OBLIGATION_LOADING',
  GET_OBLIGATION_SUCCESS: 'MY_ASD.COLLECTIONBOX.GET_OBLIGATION_SUCCESS',
  GET_OBLIGATION_ERROR: 'MY_ASD.COLLECTIONBOX.GET_OBLIGATION_ERROR',

  OBLIGATION_CREATE: 'MY_ASD.COLLECTIONBOX.OBLIGATION_CREATE',
  OBLIGATION_CREATE_LOADING: 'MY_ASD.COLLECTIONBOX.OBLIGATION_CREATE_LOADING',
  OBLIGATION_CREATE_SUCCESS: 'MY_ASD.COLLECTIONBOX.OBLIGATION_CREATE_SUCCESS',
  OBLIGATION_CREATE_ERROR: 'MY_ASD.COLLECTIONBOX.OBLIGATION_CREATE_ERROR',

  OBLIGATION_UPDATE: 'MY_ASD.COLLECTIONBOX.OBLIGATION_UPDATE',
  OBLIGATION_UPDATE_LOADING: 'MY_ASD.COLLECTIONBOX.OBLIGATION_UPDATE_LOADING',
  OBLIGATION_UPDATE_SUCCESS: 'MY_ASD.COLLECTIONBOX.OBLIGATION_UPDATE_SUCCESS',
  OBLIGATION_UPDATE_ERROR: 'MY_ASD.COLLECTIONBOX.OBLIGATION_UPDATE_ERROR',

  OBLIGATION_DELETE: 'MY_ASD.COLLECTIONBOX.OBLIGATION_DELETE',
  OBLIGATION_DELETE_LOADING: 'MY_ASD.COLLECTIONBOX.OBLIGATION_DELETE_LOADING',
  OBLIGATION_DELETE_SUCCESS: 'MY_ASD.COLLECTIONBOX.OBLIGATION_DELETE_SUCCESS',
  OBLIGATION_DELETE_ERROR: 'MY_ASD.COLLECTIONBOX.OBLIGATION_DELETE_ERROR',
  
  SUBSCRIBE: 'MY_ASD.COLLECTIONBOX.SUBSCRIBE',
  SUBSCRIBE_LOADING: 'MY_ASD.COLLECTIONBOX.SUBSCRIBE_LOADING',
  SUBSCRIBE_SUCCESS: 'MY_ASD.COLLECTIONBOX.SUBSCRIBE_SUCCESS',
  SUBSCRIBE_ERROR: 'MY_ASD.COLLECTIONBOX.SUBSCRIBE_ERROR',

  UNSUBSCRIBE: 'MY_ASD.COLLECTIONBOX.UNSUBSCRIBE',
  UNSUBSCRIBE_LOADING: 'MY_ASD.COLLECTIONBOX.UNSUBSCRIBE_LOADING',
  UNSUBSCRIBE_SUCCESS: 'MY_ASD.COLLECTIONBOX.UNSUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_ERROR: 'MY_ASD.COLLECTIONBOX.UNSUBSCRIBE_ERROR',

  GET_ALL_COLLECTIONBOXES: "MY_ASD.COLLECTIONBOX.GET_ALL_COLLECTIONBOXES",
  GET_ALL_COLLECTIONBOXES_LOADING: "MY_ASD.COLLECTIONBOX.GET_ALL_COLLECTIONBOXES_LOADING",
  GET_ALL_COLLECTIONBOXES_SUCCESS: "MY_ASD.COLLECTIONBOX.GET_ALL_COLLECTIONBOXES_SUCCESS",
  GET_ALL_COLLECTIONBOXES_ERROR: "MY_ASD.COLLECTIONBOX.GET_ALL_COLLECTIONBOXES_ERROR",

  GET_ONE_COLLECTIONBOX: "MY_ASD.COLLECTIONBOX.GET_ONE_COLLECTIONBOX",
  GET_ONE_COLLECTIONBOX_LOADING: "MY_ASD.COLLECTIONBOX.GET_ONE_COLLECTIONBOX_LOADING",
  GET_ONE_COLLECTIONBOX_SUCCESS: "MY_ASD.COLLECTIONBOX.GET_ONE_COLLECTIONBOX_SUCCESS",
  GET_ONE_COLLECTIONBOX_ERROR: "MY_ASD.COLLECTIONBOX.GET_ONE_COLLECTIONBOX_ERROR",

  UPDATE_COLLECTIONBOX: "MY_ASD.COLLECTIONBOX.UPDATE_COLLECTIONBOX",
  UPDATE_COLLECTIONBOX_LOADING: "MY_ASD.COLLECTIONBOX.UPDATE_COLLECTIONBOX_LOADING",
  UPDATE_COLLECTIONBOX_SUCCESS: "MY_ASD.COLLECTIONBOX.UPDATE_COLLECTIONBOX_SUCCESS",
  UPDATE_COLLECTIONBOX_ERROR: "MY_ASD.COLLECTIONBOX.UPDATE_COLLECTIONBOX_ERROR",

  CREATE_COLLECTIONBOX: 'MY_ASD.COLLECTIONBOX.CREATE_COLLECTIONBOX',
  CREATE_COLLECTIONBOX_LOADING: 'MY_ASD.COLLECTIONBOX.CREATE_COLLECTIONBOX_LOADING', 
  CREATE_COLLECTIONBOX_SUCCESS: 'MY_ASD.COLLECTIONBOX.CREATE_COLLECTIONBOX_SUCCESS',
  CREATE_COLLECTIONBOX_ERROR: 'MY_ASD.COLLECTIONBOX.CREATE_COLLECTIONBOX_ERROR',

  UPDATE_STEP: 'MY_ASD.COLLECTIONBOX.UPDATE_STEP',
  UPDATE_STEP_LOADING: 'MY_ASD.COLLECTIONBOX.UPDATE_STEP_LOADING', 
  UPDATE_STEP_SUCCESS: 'MY_ASD.COLLECTIONBOX.UPDATE_STEP_SUCCESS',
  UPDATE_STEP_ERROR: 'MY_ASD.COLLECTIONBOX.UPDATE_STEP_ERROR',

  FOLLOW_COLLECTIONBOX: 'MY_ASD.COLLECTIONBOX.FOLLOW_COLLECTIONBOX',
  FOLLOW_COLLECTIONBOX_LOADING: 'MY_ASD.COLLECTIONBOX.FOLLOW_COLLECTIONBOX_LOADING', 
  FOLLOW_COLLECTIONBOX_SUCCESS: 'MY_ASD.COLLECTIONBOX.FOLLOW_COLLECTIONBOX_SUCCESS',
  FOLLOW_COLLECTIONBOX_ERROR: 'MY_ASD.COLLECTIONBOX.FOLLOW_COLLECTIONBOX_ERROR',

  UNFOLLOW_COLLECTIONBOX: 'MY_ASD.COLLECTIONBOX.UNFOLLOW_COLLECTIONBOX',
  UNFOLLOW_COLLECTIONBOX_LOADING: 'MY_ASD.COLLECTIONBOX.UNFOLLOW_COLLECTIONBOX_LOADING', 
  UNFOLLOW_COLLECTIONBOX_SUCCESS: 'MY_ASD.COLLECTIONBOX.UNFOLLOW_COLLECTIONBOX_SUCCESS',
  UNFOLLOW_COLLECTIONBOX_ERROR: 'MY_ASD.COLLECTIONBOX.UNFOLLOW_COLLECTIONBOX_ERROR',

  COLLECTIONBOX_ADD_DOCUMENTS: 'MY_ASD.COLLECTIONBOX.COLLECTIONBOX_ADD_DOCUMENTS',
  COLLECTIONBOX_ADD_DOCUMENTS_LOADING: 'MY_ASD.COLLECTIONBOX.COLLECTIONBOX_ADD_DOCUMENTS_LOADING', 
  COLLECTIONBOX_ADD_DOCUMENTS_SUCCESS: 'MY_ASD.COLLECTIONBOX.COLLECTIONBOX_ADD_DOCUMENTS_SUCCESS',
  COLLECTIONBOX_ADD_DOCUMENTS_ERROR: 'MY_ASD.COLLECTIONBOX.COLLECTIONBOX_ADD_DOCUMENTS_ERROR',

  COLLECTIONBOX_GET_DOC_CAT: 'MY_ASD.COLLECTIONBOX.COLLECTIONBOX_GET_DOC_CAT',
  COLLECTIONBOX_GET_DOC_CAT_LOADING: 'MY_ASD.COLLECTIONBOX.COLLECTIONBOX_GET_DOC_CAT_LOADING', 
  COLLECTIONBOX_GET_DOC_CAT_SUCCESS: 'MY_ASD.COLLECTIONBOX.COLLECTIONBOX_GET_DOC_CAT_SUCCESS',
  COLLECTIONBOX_GET_DOC_CAT_ERROR: 'MY_ASD.COLLECTIONBOX.COLLECTIONBOX_GET_DOC_CAT_ERROR',

  UPDATE_DOC: 'MY_ASD.COLLECTIONBOX.UPDATE_DOC',
  UPDATE_DOC_LOADING: 'MY_ASD.COLLECTIONBOX.UPDATE_DOC_LOADING',
  UPDATE_DOC_SUCCESS: 'MY_ASD.COLLECTIONBOX.UPDATE_DOC_SUCCESS',
  UPDATE_DOC_ERROR: 'MY_ASD.COLLECTIONBOX.UPDATE_DOC_ERROR',

  DELETE_DOC: 'MY_ASD.COLLECTIONBOX.DELETE_DOC',
  DELETE_DOC_LOADING: 'MY_ASD.COLLECTIONBOX.DELETE_DOC_LOADING',
  DELETE_DOC_SUCCESS: 'MY_ASD.COLLECTIONBOX.DELETE_DOC_SUCCESS',
  DELETE_DOC_ERROR: 'MY_ASD.COLLECTIONBOX.DELETE_DOC_ERROR',

  DOWNLOAD_DOC: 'MY_ASD.COLLECTIONBOX.DOWNLOAD_DOC',
  DOWNLOAD_DOC_LOADING: 'MY_ASD.COLLECTIONBOX.DOWNLOAD_DOC_LOADING',
  DOWNLOAD_DOC_SUCCESS: 'MY_ASD.COLLECTIONBOX.DOWNLOAD_DOC_SUCCESS',
  DOWNLOAD_DOC_ERROR: 'MY_ASD.COLLECTIONBOX.DOWNLOAD_DOC_ERROR',

  TAG_OPERATION: 'MY_ASD.COLLECTIONBOX.TAG_OPERATION',
  TAG_OPERATION_LOADING: 'MY_ASD.COLLECTIONBOX.TAG_OPERATION_LOADING',
  TAG_OPERATION_SUCCESS: 'MY_ASD.COLLECTIONBOX.TAG_OPERATION_SUCCESS',
  TAG_OPERATION_ERROR: 'MY_ASD.COLLECTIONBOX.TAG_OPERATION_ERROR',

  GET_ARCHIVE_FILES: 'MY_ASD.COLLECTIONBOX.GET_ARCHIVE_FILES',
  GET_ARCHIVE_FILES_LOADING: 'MY_ASD.COLLECTIONBOX.GET_ARCHIVE_FILES_LOADING',
  GET_ARCHIVE_FILES_SUCCESS: 'MY_ASD.COLLECTIONBOX.GET_ARCHIVE_FILES_SUCCESS',
  GET_ARCHIVE_FILES_ERROR: 'MY_ASD.COLLECTIONBOX.GET_ARCHIVE_FILES_ERROR',

  GET_ARCHIVE_FILE: 'MY_ASD.COLLECTIONBOX.GET_ARCHIVE_FILE',
  GET_ARCHIVE_FILE_LOADING: 'MY_ASD.COLLECTIONBOX.GET_ARCHIVE_FILE_LOADING',
  GET_ARCHIVE_FILE_SUCCESS: 'MY_ASD.COLLECTIONBOX.GET_ARCHIVE_FILE_SUCCESS',
  GET_ARCHIVE_FILE_ERROR: 'MY_ASD.COLLECTIONBOX.GET_ARCHIVE_FILE_ERROR',

  SINGLE_FILE: 'MY_ASD.COLLECTIONBOX.SINGLE_FILE',
  SINGLE_FILE_LOADING: 'MY_ASD.COLLECTIONBOX.SINGLE_FILE_LOADING',
  SINGLE_FILE_SUCCESS: 'MY_ASD.COLLECTIONBOX.SINGLE_FILE_SUCCESS',
  SINGLE_FILE_ERROR: 'MY_ASD.COLLECTIONBOX.SINGLE_FILE_ERROR',

  FILTER_TAG: 'MY_ASD.COLLECTIONBOX.FILTER_TAG',
  FILTER_TAG_LOADING: 'MY_ASD.COLLECTIONBOX.FILTER_TAG_LOADING',
  FILTER_TAG_SUCCESS: 'MY_ASD.COLLECTIONBOX.FILTER_TAG_SUCCESS',
  FILTER_TAG_ERROR: 'MY_ASD.COLLECTIONBOX.FILTER_TAG_ERROR',

  ARCHIVE_ALL: 'MY_ASD.COLLECTIONBOX.ARCHIVE_ALL',
  ARCHIVE_ALL_LOADING: 'MY_ASD.COLLECTIONBOX.ARCHIVE_ALL_LOADING',
  ARCHIVE_ALL_SUCCESS: 'MY_ASD.COLLECTIONBOX.ARCHIVE_ALL_SUCCESS',
  ARCHIVE_ALL_ERROR: 'MY_ASD.COLLECTIONBOX.ARCHIVE_ALL_ERROR',

  BULK_FILTER: 'MY_ASD.COLLECTIONBOX.BULK_FILTER',
  BULK_FILTER_LOADING: 'MY_ASD.COLLECTIONBOX.BULK_FILTER_LOADING',
  BULK_FILTER_SUCCESS: 'MY_ASD.COLLECTIONBOX.BULK_FILTER_SUCCESS',
  BULK_FILTER_ERROR: 'MY_ASD.COLLECTIONBOX.BULK_FILTER_ERROR',

  SPLIT_DOCUMENT: 'MY_ASD.COLLECTIONBOX.SPLIT_DOCUMENT',
  SPLIT_DOCUMENT_LOADING: 'MY_ASD.COLLECTIONBOX.SPLIT_DOCUMENT_LOADING',
  SPLIT_DOCUMENT_SUCCESS: 'MY_ASD.COLLECTIONBOX.SPLIT_DOCUMENT_SUCCESS',
  SPLIT_DOCUMENT_ERROR: 'MY_ASD.COLLECTIONBOX.SPLIT_DOCUMENT_ERROR',

  RENAME_SEND_CHUNCKS: 'MY_ASD.COLLECTIONBOX.RENAME_SEND_CHUNCKS',
  RENAME_SEND_CHUNCKS_LOADING: 'MY_ASD.COLLECTIONBOX.RENAME_SEND_CHUNCKS_LOADING',
  RENAME_SEND_CHUNCKS_SUCCESS: 'MY_ASD.COLLECTIONBOX.RENAME_SEND_CHUNCKS_SUCCESS',
  RENAME_SEND_CHUNCKS_ERROR: 'MY_ASD.COLLECTIONBOX.RENAME_SEND_CHUNCKS_ERROR',

  GROUP_FILES: 'MY_ASD.COLLECTIONBOX.GROUP_FILES',
  GROUP_FILES_LOADING: 'MY_ASD.COLLECTIONBOX.GROUP_FILES_LOADING',
  GROUP_FILES_SUCCESS: 'MY_ASD.COLLECTIONBOX.GROUP_FILES_SUCCESS',
  GROUP_FILES_ERROR: 'MY_ASD.COLLECTIONBOX.GROUP_FILES_ERROR',

  FILTER_TAG_ARCHIVES: 'MY_ASD.COLLECTIONBOX.FILTER_TAG_ARCHIVES',
  FILTER_TAG_ARCHIVES_LOADING: 'MY_ASD.COLLECTIONBOX.FILTER_TAG_ARCHIVES_LOADING',
  FILTER_TAG_ARCHIVES_SUCCESS: 'MY_ASD.COLLECTIONBOX.FILTER_TAG_ARCHIVES_SUCCESS',
  FILTER_TAG_ARCHIVES_ERROR: 'MY_ASD.COLLECTIONBOX.FILTER_TAG_ARCHIVES_ERROR',

  GET_PERMS: 'MY_ASD.COLLECTIONBOX.GET_PERMS',
  GET_PERMS_LOADING: 'MY_ASD.COLLECTIONBOX.GET_PERMS_LOADING',
  GET_PERMS_SUCCESS: 'MY_ASD.COLLECTIONBOX.GET_PERMS_SUCCESS',
  GET_PERMS_ERROR: 'MY_ASD.COLLECTIONBOX.GET_PERMS_ERROR',

  CREATE_NONE_COLLECTIONBOX: 'MY_ASD.COLLECTIONBOX.CREATE_NONE_COLLECTIONBOX',
  CREATE_NONE_COLLECTIONBOX_LOADING: 'MY_ASD.COLLECTIONBOX.CREATE_NONE_COLLECTIONBOX_LOADING',
  CREATE_NONE_COLLECTIONBOX_SUCCESS: 'MY_ASD.COLLECTIONBOX.CREATE_NONE_COLLECTIONBOX_SUCCESS',
  CREATE_NONE_COLLECTIONBOX_ERROR: 'MY_ASD.COLLECTIONBOX.CREATE_NONE_COLLECTIONBOX_ERROR',

  DELETE_FILES: 'MY_ASD.COLLECTIONBOX.DELETE_FILES',
  DELETE_FILES_LOADING: 'MY_ASD.COLLECTIONBOX.DELETE_FILES_LOADING',
  DELETE_FILES_SUCCESS: 'MY_ASD.COLLECTIONBOX.DELETE_FILES_SUCCESS',
  DELETE_FILES_ERROR: 'MY_ASD.COLLECTIONBOX.DELETE_FILES_ERROR',

  FILES_STATE: 'MY_ASD.COLLECTIONBOX.FILES_STATE',
  FILES_STATE_LOADING: 'MY_ASD.COLLECTIONBOX.FILES_STATE_LOADING',
  FILES_STATE_SUCCESS: 'MY_ASD.COLLECTIONBOX.FILES_STATE_SUCCESS',
  FILES_STATE_ERROR: 'MY_ASD.COLLECTIONBOX.FILES_STATE_ERROR',

  GET_FILTERED_COLLECTIONBOXES: 'MY_ASD.COLLECTIONBOX.GET_FILTERED_COLLECTIONBOXES',
  GET_FILTERED_COLLECTIONBOXES_LOADING: 'MY_ASD.COLLECTIONBOX.GET_FILTERED_COLLECTIONBOXES_LOADING',
  GET_FILTERED_COLLECTIONBOXES_SUCCESS: 'MY_ASD.COLLECTIONBOX.GET_FILTERED_COLLECTIONBOXES_SUCCESS',
  GET_FILTERED_COLLECTIONBOXES_ERROR: 'MY_ASD.COLLECTIONBOX.GET_FILTERED_COLLECTIONBOXES_ERROR',

  SETTINGS: 'MY_ASD.COLLECTIONBOX.SETTINGS',
  SETTINGS_LOADING: 'MY_ASD.COLLECTIONBOX.SETTINGS_LOADING',
  SETTINGS_SUCCESS: 'MY_ASD.COLLECTIONBOX.SETTINGS_SUCCESS',
  SETTINGS_ERROR: 'MY_ASD.COLLECTIONBOX.SETTINGS_ERROR',

  SETTINGS_GET: 'MY_ASD.COLLECTIONBOX.SETTINGS_GET',
  SETTINGS_GET_LOADING: 'MY_ASD.COLLECTIONBOX.SETTINGS_GET_LOADING',
  SETTINGS_GET_SUCCESS: 'MY_ASD.COLLECTIONBOX.SETTINGS_GET_SUCCESS',
  SETTINGS_GET_ERROR: 'MY_ASD.COLLECTIONBOX.SETTINGS_GET_ERROR',

  RESET: "MY_ASD.DATA.RESET",
};
