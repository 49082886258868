import actionTypes from 'store/fiscal/vat/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.GET_CATEGORIES:
        try {
          next({ type: actionTypes.GET_CATEGORIES_LOADING});
          const resp = await request.getCategories({...action.payload});
          next({
            type: actionTypes.GET_CATEGORIES_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          console.log(error)
          next({
            type: actionTypes.GET_CATEGORIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;


      case actionTypes.DELETE_FILES:
        try {
          next({ type: actionTypes.DELETE_FILES_LOADING});
          const resp = await request.deleteFiles({...action.payload});
          next({
            type: actionTypes.DELETE_FILES_SUCCESS,
            payload: resp.data,
          });
        } catch (error) {
          console.log(error)
          next({
            type: actionTypes.DELETE_FILES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_FULL_LIST_DOCUMENTS:
        try {
          next({ type: actionTypes.GET_FULL_LIST_DOCUMENTS_LOADING});
          const resp = await request.getFilesFullList({...action.payload});
          next({
            type: actionTypes.GET_FULL_LIST_DOCUMENTS_SUCCESS,
            payload: resp.data,
          });
        } catch (error) {
          console.log(error)
          next({
            type: actionTypes.GET_FULL_LIST_DOCUMENTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_EXCEL:
        try {
          next({ type: actionTypes.UPDATE_EXCEL_LOADING});
          const response = await request.updateVatDeclarationExcel({...action.payload});
          next({
            type: actionTypes.UPDATE_EXCEL_SUCCESS,
            payload: response.data,
          });
          store.dispatch(
            messageHandler.successMessage("")
          )
        } catch (error) {
          console.log(error)
          next({
            type: actionTypes.UPDATE_EXCEL_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;


      case actionTypes.GET_EXCEL:
        try {
          next({ type: actionTypes.GET_EXCEL_LOADING});
          const resp = await request.getVatDeclarationExcel({...action.payload});
          next({
            type: actionTypes.GET_EXCEL_SUCCESS,
            payload: resp.data,
          });
        } catch (error) {
          console.log(error)
          next({
            type: actionTypes.GET_EXCEL_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_OBLIGATION:
        try {
          next({ type: actionTypes.GET_OBLIGATION_LOADING });
          const response = await request.getObligation({...action.payload});
          next({
            type: actionTypes.GET_OBLIGATION_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_OBLIGATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.OBLIGATION_CREATE:
          try {
            next({ type: actionTypes.OBLIGATION_CREATE_LOADING });
            await request.createObligation({
              ...action.payload,
            });
            next({
              type: actionTypes.OBLIGATION_CREATE_SUCCESS,
            });
            store.dispatch(
              messageHandler.successMessage("")
            )
          } catch (error) {
            next({
              type: actionTypes.OBLIGATION_CREATE_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;

        

      case actionTypes.GET_ASSIG_COUNTRIES:
        try {
          next({ type: actionTypes.GET_ASSIG_COUNTRIES_LOADING });
           const resp = await request.getOperatorAssignedCountries({ ...action.payload });
          next({
            type: actionTypes.GET_ASSIG_COUNTRIES_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ASSIG_COUNTRIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.COUNTRY:
        try {
          next({ type: actionTypes.COUNTRY_LOADING });
           const resp = await request.getAllCountryVatDeclarations({ ...action.payload });
          next({
            type: actionTypes.COUNTRY_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.COUNTRY_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;


      case actionTypes.UPDATE_DOC:
        try {
          next({ type: actionTypes.UPDATE_DOC_LOADING });
            await request.updateDocument({ ...action.payload });
          next({
            type: actionTypes.UPDATE_DOC_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_DOC_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_MAPPING:
        try {
          next({ type: actionTypes.GET_MAPPING_LOADING });
            const resp = await request.getMapping({ ...action.payload });
          next({
            type: actionTypes.GET_MAPPING_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_MAPPING_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_COMPANY_SUBSCRIBED:
        try {
          next({ type: actionTypes.GET_COMPANY_SUBSCRIBED_LOADING });
            const resp = await request.getCompanySubscribed({ ...action.payload });
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.UNSUBSCRIBE:
        try {
          next({ type: actionTypes.UNSUBSCRIBE_LOADING });
            await request.unsubscribe({ ...action.payload });
          next({
            type: actionTypes.UNSUBSCRIBE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UNSUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.SUBSCRIBE:
        try {
          next({ type: actionTypes.SUBSCRIBE_LOADING });
            await request.subscribe({ ...action.payload });
          next({
            type: actionTypes.SUBSCRIBE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.SUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.DOWNLOAD_DOC:
        try {
          next({ type: actionTypes.DOWNLOAD_DOC_LOADING });
            const resp = await request.downloadDocument({ ...action.payload });
            saveAs(
              resp.data,
              resp.headers['content-disposition']
                .split('filename=')[1]
                .replace(/"/g, '')
            );
          next({
            type: actionTypes.DOWNLOAD_DOC_SUCCESS,
              payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_DOC_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.DELETE_DOC:
        try {
          next({ type: actionTypes.DELETE_DOC_LOADING });
            await request.deleteDoc({ ...action.payload });
          next({
            type: actionTypes.DELETE_DOC_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DELETE_DOC_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.VAT_REPO_GET_DOC_CAT:
        try {
          next({ type: actionTypes.VAT_REPO_GET_DOC_CAT_LOADING });
            const resp = await request.getDocsFromCat({ ...action.payload });
          next({
            type: actionTypes.VAT_REPO_GET_DOC_CAT_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.VAT_REPO_GET_DOC_CAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.VAT_REPO_ADD_DOCUMENTS:
        try {
          next({ type: actionTypes.VAT_REPO_ADD_DOCUMENTS_LOADING });
          const resp = await request.addVatDeclarationDocuments({ ...action.payload });
          next({
            type: actionTypes.VAT_REPO_ADD_DOCUMENTS_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.VAT_REPO_ADD_DOCUMENTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.FOLLOW_VAT_DECLARATION:
        try {
          next({ type: actionTypes.FOLLOW_VAT_DECLARATION_LOADING });
            await request.followVatDeclaration({ ...action.payload });
          next({
            type: actionTypes.FOLLOW_VAT_DECLARATION_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.FOLLOW_VAT_DECLARATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.UPDATE_STEP:
        try {
          next({ type: actionTypes.UPDATE_STEP_LOADING });
            await request.updateStep({ ...action.payload });
          next({
            type: actionTypes.UPDATE_STEP_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_STEP_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.CREATE_VAT_DECLARATION:
        try {
          next({ type: actionTypes.CREATE_VAT_DECLARATION_LOADING });
            await request.createVatDeclaration({ ...action.payload });
          next({
            type: actionTypes.CREATE_VAT_DECLARATION_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_VAT_DECLARATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.UPDATE_VAT_DECLARATION:
        try {
          next({ type: actionTypes.UPDATE_VAT_DECLARATION_LOADING });
            await request.updateVatDeclaration({ ...action.payload });
          next({
            type: actionTypes.UPDATE_VAT_DECLARATION_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_VAT_DECLARATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_ALL_VAT_DECLARATIONS:
        try {
          next({ type: actionTypes.GET_ALL_VAT_DECLARATIONS_LOADING });
          const resp = await request.getAllVatDeclarations({ ...action.payload });
          next({
            type: actionTypes.GET_ALL_VAT_DECLARATIONS_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_VAT_DECLARATIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_ONE_VAT_DECLARATION:
        try {
          next({ type: actionTypes.GET_ONE_VAT_DECLARATION_LOADING });
          const resp = await request.getVatDeclaration({ ...action.payload });
          next({
            type: actionTypes.GET_ONE_VAT_DECLARATION_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_VAT_DECLARATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;
  

      default:
        next(action);
        break;
    }
  };
}
