import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button as Btn, Container, Divider } from 'semantic-ui-react';

import Button from 'components/Button';
import { actions } from 'store';
import Wrapper from 'components/core/Wrapper/Wrapper';
import FormFields from './components/FormFields/FormFields';

const Index = ({ dispatch, history, user }) => {
  
  const handleSave = (values) => {
  
    dispatch(
      actions.company.create({
        data: values,
      })
    );

    history.push('/companies');
  };

  return (
    <Container>
      <>
        <Btn.Group>
          <Button
            floated='left'
            labelPosition='left'
            icon='angle left'
            position='flex-end'
            type='button'
            content='Back'
            margin='5px'
            onClick={() => history.push('/companies')}
          />
        </Btn.Group>

        <Container textAlign='center'>
          <Container>
            <Divider hidden />
            <Wrapper padding='0 25%'>
              <FormFields onSave={handleSave} user={user}/>
            </Wrapper>
          </Container>
        </Container>
      </>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
