import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const createSettingErrorSelector = state => 
  state.companyIntrastatSettings.errors.createSetting;
const getCompanySettingErrorSelector = state => 
  state.companyIntrastatSettings.errors.getCompanySetting;
const deleteSettingErrorSelector = state => 
  state.companyIntrastatSettings.errors.deleteSetting;
const updateSettingErrorSelector = state => 
  state.companyIntrastatSettings.errors.updateSetting;
const updateCountriesErrorSelector = state => 
  state.companyIntrastatSettings.errors.createCountries;
const getCountriesErrorSelector = state => 
  state.companyIntrastatSettings.errors.countries;

const errorsSelector = createErrorsSelector([
  createSettingErrorSelector,
  getCompanySettingErrorSelector,
  deleteSettingErrorSelector,
  updateSettingErrorSelector,
  updateCountriesErrorSelector,
  getCountriesErrorSelector
]);

// Loading
const createSettingLoadingSelector = state => 
  state.companyIntrastatSettings.loading.createSetting;
const getCompanySettingsLoadingSelector = state => 
  state.companyIntrastatSettings.loading.getCompanySetting;
const deleteSettingLoadingSelector = state => 
  state.companyIntrastatSettings.loading.deleteSetting;
const updateSettingLoadingSelector = state => 
  state.companyIntrastatSettings.loading.updateSetting;
const updateCountriesLoadingSelector = state => 
  state.companyIntrastatSettings.loading.createCountries;
const getCountriesLoadingSelector = state => 
  state.companyIntrastatSettings.loading.countries;

const loadingSelector = createLoadingSelector([
  createSettingLoadingSelector,
  getCompanySettingsLoadingSelector,
  deleteSettingLoadingSelector,
  updateSettingLoadingSelector,
  updateCountriesLoadingSelector,
  getCountriesLoadingSelector
]);

// Success
const createSettingSuccessSelector = state => 
  state.companyIntrastatSettings.success.createSetting;
const getCompanySettingSuccessSelector = state => 
  state.companyIntrastatSettings.success.getCompanySetting;
const deleteSettingSuccessSelector = state => 
  state.companyIntrastatSettings.success.deleteSetting;
const updateSettingSuccessSelector = state => 
  state.companyIntrastatSettings.success.updateSetting;
const updateCountriesSuccessSelector = state => 
  state.companyIntrastatSettings.success.createCountries;
const getCountriesSuccessSelector = state => 
  state.companyIntrastatSettings.success.countries;

const successSelector = createSuccessSelector([
  createSettingSuccessSelector,
  getCompanySettingSuccessSelector,
  deleteSettingSuccessSelector,
  updateSettingSuccessSelector,
  updateCountriesSuccessSelector,
  getCountriesSuccessSelector
]);

// Settings
const companyIntrastatSettingsSelector = state => state.companyIntrastatSettings.getCompanySetting;
const errorSelector = state => state.companyIntrastatSettings.error;
const updateCountriesSelector = state => 
  state.companyIntrastatSettings.createCountries;
const getCountriesSelector = state => 
  state.companyIntrastatSettings.countries;




export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  companyIntrastatSettingsSelector,
  getCompanySettingsLoadingSelector,
  errorSelector,
  updateCountriesSelector,
  getCountriesSelector,
  getCountriesLoadingSelector
};
