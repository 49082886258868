import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Divider, Header, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Search from 'components/Search';
import Button from 'components/Button';
import { Main } from 'components/Layouts';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths } from 'routes/fiscal/repository';
import ClickableText from 'components/ClickableText';
import LinkButton from 'components/core/LinkButton/LinkButton';
import NewDeclaration from '../New';

const Index = ({ dispatch, companies, history, match, subscribed, countries, countriesLoading, subscribedLoading }) => {
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState(null);
  const [resetSearch, setresetSearch] = useState(false);
  const [modalStatus, setmodalStatus] = useState(null);

  useEffect(() => {

    dispatch(actions.fiscalVat.getAllCountryVatDeclarations({
      params: {
        country: match.params.country
      }
    }));
    dispatch(actions.data.getAllCountries())
    return () => {
      dispatch(actions.fiscalVat.reset(['subscribed', 'countryVatDeclarations', 'error']));
      dispatch(actions.data.reset(['allCountries', 'error']))
    };
  }, [dispatch, match]);

  useEffect(() => {
    subscribed && setdata(_.orderBy(subscribed, ['name'], ['asc']));
    resetSearch && setresetSearch(false);
  }, [subscribed, resetSearch]);


  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['name'], ['asc']));
  };

  return (
    <Main>
      <Divider hidden />
      <Wrapper padding='2rem'>
        <Header size='huge' textAlign='center'>
          Country dashboard
          <Header.Subheader>Subscribed Companies</Header.Subheader>
        </Header>
        <Wrapper textAlign='center'>(
          {navigator.language === 'fr-FR' ? (
          <ClickableText
            color='black'
            onClick={() => {
              window.open(
                'https://my-asd.s3-eu-west-1.amazonaws.com/customs/Guide_de_l_utilisateur_-_Déclaration_en_douane.pdf',
                '_blank'
              );
              }}
            >
            Guide d'utilisation 
        </ClickableText>
       ) : (
        <ClickableText
              color='black'
              onClick={() => {
                window.open(
                  'https://my-asd.s3-eu-west-1.amazonaws.com/customs/User_guide_-_Customs_declaration.pdf',
                  '_blank'
                );
                }}
              >
            User guide 
        </ClickableText>
        )})
       <Divider hidden />
        <LinkButton
          content='Go to monitoring'
          color='blue'
          path={paths.repositoryMonitoring}
        />
        </Wrapper>   
      </Wrapper>
     
      {(subscribed === null || subscribedLoading || data === null) && (
       <Loader content='Loading' />
      )}

      <Wrapper display='flex' justifyContent='start'>
        <Button
          labelPosition='right'
          position='left'
          icon='plus circle'
          type='button'
          content='New declaration'
          onClick={() => setmodalStatus(true)}
        />
      </Wrapper>

      {modalStatus && (
        <NewDeclaration
          open={modalStatus}
          handleClose={() => setmodalStatus(false)}
        />
      )}
      <Container>
        <Search
          dataSource={subscribed}
          onSearchResultChange={onSearchResultChange}
          setResetSearch={setresetSearch}
        />
        <Table size='large' columns='3' sortable selectable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'name' ? direction : null}
                onClick={() => handleSort('name')}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'email' ? direction : null}
                onClick={() => handleSort('email')}
              >
                E-mail
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'country' ? direction : null}
                onClick={() => handleSort('country')}
              >
                Country
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(data === null  && <EmptyTable colNumber={3} />) ||
              data.map((company, i) => {
                return (                  
                  <Table.Row key={i}>
                    <Link
                      to={paths.repositoryList.replace(
                        ':companyId',
                        company.id
                      )}
                      className='table-link'
                    >
                      <Table.Cell>{company.name}</Table.Cell>
                      <Table.Cell>{company.email}</Table.Cell>
                      <Table.Cell>{company.country}</Table.Cell>
                    </Link>
                  </Table.Row>
                  )                
              })}
          </Table.Body>
        </Table>
      </Container>
  
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.data.allCountriesSelector(state),
    countriesLoading: selectors.data.allCountryLoadingSelector(state),
    subscribed: selectors.fiscalVat.subscribedSelector(state),
    subscribedLoading: selectors.fiscalVat.subscribedLoadingSelector(state),
    errorHandlerClients: selectors.company.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
