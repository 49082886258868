import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  const invoiceApi = routesApi.company.invoice;
  return {
    getUnpaid(data: dataReqI) {
      return api.request({
        method: invoiceApi.getUnpaid.method,
        route: invoiceApi.getUnpaid.path,
        params: data.params,
      });
    },
    createCredit(data: dataReqI){
      return api.request({
        method: invoiceApi.createCredit.method,
        route: invoiceApi.createCredit.path,
        params: data.params,
        data:  data.data
      });
    },
    getAll(data: dataReqI) {
      return api.request({
        method: invoiceApi.getAll.method,
        route: invoiceApi.getAll.path,
        params: data.params,
      });
    },
    getOne(data: dataReqI) {
      return api.request({
        method: invoiceApi.getOne.method,
        route: invoiceApi.getOne.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    payInvoice(data: dataReqI) {
      return api.request({
        method: invoiceApi.payInvoice.method,
        route: invoiceApi.payInvoice.path,
        params: data.params,
      });
    },
  };
};
