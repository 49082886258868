export default {
  GET_CHATS: "MY_ASD.CHAT.GET_CHATS",
  GET_CHATS_LOADING: "MY_ASD.CHAT.GET_CHATS_LOADING",
  GET_CHATS_SUCCESS: "MY_ASD.CHAT.GET_CHATS_SUCCESS",
  GET_CHATS_ERROR: "MY_ASD.CHAT.GET_CHATS_ERROR",

  POST_CHATS: "MY_ASD.CHAT.POST_CHATS",
  POST_CHATS_LOADING: "MY_ASD.CHAT.POST_CHATS_LOADING",
  POST_CHATS_SUCCESS: "MY_ASD.CHAT.POST_CHATS_SUCCESS",
  POST_CHATS_ERROR: "MY_ASD.CHAT.POST_CHATS_ERROR",

  READ_CHAT: 'MY_ASD.CHAT.READ_CHAT',
  READ_CHAT_LOADING: 'MY_ASD.CHAT.READ_CHAT_LOADING',
  READ_CHAT_SUCCESS: 'MY_ASD.CHAT.READ_CHAT_SUCCESS',
  READ_CHAT_ERROR: 'MY_ASD.CHAT.READ_CHAT_ERROR',
 
  RESET: "MY_ASD.CHAT.RESET",
};
