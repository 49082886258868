import styled from '@emotion/styled';
import { Form } from 'semantic-ui-react';
import theme from 'theme';

const color = theme.getTheme().colors.checkbox;

export const Wrapper = styled.div(() => ({ display: 'flex' }));

export const CheckboxStyled = styled(Form.Checkbox)`
  &&&.disabled {
    opacity: 1;
  }

  label::after {
    color: ${color} !important;
  }
`;

export const CheckboxIcon = styled.div(() => ({ marginLeft: '.5rem' }));
