import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    getAllOperator() {
      const getAllOperatorApi = routesApi.operator.allOperator;
      return api.request({
        method: getAllOperatorApi.method,
        route: getAllOperatorApi.path,
      });
    },
    getOneOperator(data: dataReqI) {
      const getOneOperatorApi = routesApi.operator.oneOperator;
      return api.request({
        method: getOneOperatorApi.method,
        route: getOneOperatorApi.path,
        params: data.params,
      });
    },

    addOneOperatorRole(data: dataReqI) {
      const addOneOperatorRoleApi = routesApi.operator.addOneOperatorRoleApi;
      return api.request({
        method: addOneOperatorRoleApi.method,
        route: addOneOperatorRoleApi.path,
        params: data.params,
        data: data.data,
      });
    },
  
    updateOperatorRights(data: dataReqI) {
      const updateOperatorRightsApi = routesApi.operator.updateOperatorRights;
      return api.request({
        method: updateOperatorRightsApi.method,
        route: updateOperatorRightsApi.path,
        params: data.params,
        data: data.data,
      });
    },
  };
};