export default {
  GET_COMPANY_SUBSCRIBED: 'MY_ASD.FISCAL.KYANITE.GET_COMPANY_SUBSCRIBED',
  GET_COMPANY_SUBSCRIBED_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_COMPANY_SUBSCRIBED_LOADING',
  GET_COMPANY_SUBSCRIBED_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_COMPANY_SUBSCRIBED_SUCCESS',
  GET_COMPANY_SUBSCRIBED_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_COMPANY_SUBSCRIBED_ERROR',

  SUBSCRIBE: 'MY_ASD.FISCAL.KYANITE.SUBSCRIBE',
  SUBSCRIBE_LOADING: 'MY_ASD.FISCAL.KYANITE.SUBSCRIBE_LOADING',
  SUBSCRIBE_SUCCESS: 'MY_ASD.FISCAL.KYANITE.SUBSCRIBE_SUCCESS',
  SUBSCRIBE_ERROR: 'MY_ASD.FISCAL.KYANITE.SUBSCRIBE_ERROR',

  SUBSCRIBE_WITH_CODE: 'MY_ASD.FISCAL.KYANITE.SUBSCRIBE_WITH_CODE',
  SUBSCRIBE_WITH_CODE_LOADING:
    'MY_ASD.FISCAL.KYANITE.SUBSCRIBE_WITH_CODE_LOADING',
  SUBSCRIBE_WITH_CODE_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.SUBSCRIBE_WITH_CODE_SUCCESS',
  SUBSCRIBE_WITH_CODE_ERROR: 'MY_ASD.FISCAL.KYANITE.SUBSCRIBE_WITH_CODE_ERROR',

  CONTACT: 'MY_ASD.FISCAL.KYANITE.CONTACT',
  CONTACT_LOADING: 'MY_ASD.FISCAL.KYANITE.CONTACT_LOADING',
  CONTACT_SUCCESS: 'MY_ASD.FISCAL.KYANITE.CONTACT_SUCCESS',
  CONTACT_ERROR: 'MY_ASD.FISCAL.KYANITE.CONTACT_ERROR',

  UNSUBSCRIBE: 'MY_ASD.FISCAL.KYANITE.UNSUBSCRIBE',
  UNSUBSCRIBE_LOADING: 'MY_ASD.FISCAL.KYANITE.UNSUBSCRIBE_LOADING',
  UNSUBSCRIBE_SUCCESS: 'MY_ASD.FISCAL.KYANITE.UNSUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_ERROR: 'MY_ASD.FISCAL.KYANITE.UNSUBSCRIBE_ERROR',

  DOWNLOAD_ATTESTATION: 'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ATTESTATION',
  DOWNLOAD_ATTESTATION_LOADING:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ATTESTATION_LOADING',
  DOWNLOAD_ATTESTATION_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ATTESTATION_SUCCESS',
  DOWNLOAD_ATTESTATION_ERROR:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ATTESTATION_ERROR',

  DOWNLOAD_CUSTOMS_REGIME_INVOICE:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_CUSTOMS_REGIME_INVOICE',
  DOWNLOAD_CUSTOMS_REGIME_INVOICE_LOADING:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_CUSTOMS_REGIME_INVOICE_LOADING',
  DOWNLOAD_CUSTOMS_REGIME_INVOICE_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_CUSTOMS_REGIME_INVOICE_SUCCESS',
  DOWNLOAD_CUSTOMS_REGIME_INVOICE_ERROR:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_CUSTOMS_REGIME_INVOICE_ERROR',

  GET_ALL: 'MY_ASD.FISCAL.KYANITE.GET_ALL',
  GET_ALL_LOADING: 'MY_ASD.FISCAL.KYANITE.GET_ALL_LOADING',
  GET_ALL_SUCCESS: 'MY_ASD.FISCAL.KYANITE.GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'MY_ASD.FISCAL.KYANITE.GET_ALL_ERROR',

  GET_ONE: 'MY_ASD.FISCAL.KYANITE.GET_ONE',
  GET_ONE_LOADING: 'MY_ASD.FISCAL.KYANITE.GET_ONE_LOADING',
  GET_ONE_SUCCESS: 'MY_ASD.FISCAL.KYANITE.GET_ONE_SUCCESS',
  GET_ONE_ERROR: 'MY_ASD.FISCAL.KYANITE.GET_ONE_ERROR',

  GET_KYANITE_OF_ONE_TRANSPORTER:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_TRANSPORTER',
  GET_KYANITE_OF_ONE_TRANSPORTER_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_TRANSPORTER_LOADING',
  GET_KYANITE_OF_ONE_TRANSPORTER_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_TRANSPORTER_SUCCESS',
  GET_KYANITE_OF_ONE_TRANSPORTER_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_TRANSPORTER_ERROR',

  GET_KYANITE_OF_ONE_BUYER: 'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_BUYER',
  GET_KYANITE_OF_ONE_BUYER_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_BUYER_LOADING',
  GET_KYANITE_OF_ONE_BUYER_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_BUYER_SUCCESS',
  GET_KYANITE_OF_ONE_BUYER_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_BUYER_ERROR',

  GET_KYANITE_OF_ONE_SELLER: 'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_SELLER',
  GET_KYANITE_OF_ONE_SELLER_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_SELLER_LOADING',
  GET_KYANITE_OF_ONE_SELLER_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_SELLER_SUCCESS',
  GET_KYANITE_OF_ONE_SELLER_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_SELLER_ERROR',

  GET_KYANITE_OF_ONE_SELLER_BY_FILTERS:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS',
  GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_LOADING',
  GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_SUCCESS',
  GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_ERROR',

  CREATE: 'MY_ASD.FISCAL.KYANITE.CREATE',
  CREATE_LOADING: 'MY_ASD.FISCAL.KYANITE.CREATE_LOADING',
  CREATE_SUCCESS: 'MY_ASD.FISCAL.KYANITE.CREATE_SUCCESS',
  CREATE_ERROR: 'MY_ASD.FISCAL.KYANITE.CREATE_ERROR',

  UPDATE: 'MY_ASD.FISCAL.KYANITE.UPDATE',
  UPDATE_LOADING: 'MY_ASD.FISCAL.KYANITE.UPDATE_LOADING',
  UPDATE_SUCCESS: 'MY_ASD.FISCAL.KYANITE.UPDATE_SUCCESS',
  UPDATE_ERROR: 'MY_ASD.FISCAL.KYANITE.UPDATE_ERROR',

  GET_TOTAL_VAT_RISK: 'MY_ASD.FISCAL.KYANITE.GET_TOTAL_VAT_RISK',
  GET_TOTAL_VAT_RISK_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_TOTAL_VAT_RISK_LOADING',
  GET_TOTAL_VAT_RISK_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_TOTAL_VAT_RISK_SUCCESS',
  GET_TOTAL_VAT_RISK_ERROR: 'MY_ASD.FISCAL.KYANITE.GET_TOTAL_VAT_RISK_ERROR',

  GET_NUMBER_OF_RECEIVED: 'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_RECEIVED',
  GET_NUMBER_OF_RECEIVED_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_RECEIVED_LOADING',
  GET_NUMBER_OF_RECEIVED_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_RECEIVED_SUCCESS',
  GET_NUMBER_OF_RECEIVED_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_RECEIVED_ERROR',

  GET_NUMBER_OF_PENDING: 'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_PENDING',
  GET_NUMBER_OF_PENDING_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_PENDING_LOADING',
  GET_NUMBER_OF_PENDING_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_PENDING_SUCCESS',
  GET_NUMBER_OF_PENDING_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_PENDING_ERROR',

  GET_NUMBER_OF_PROOF_PENDING:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_PROOF_PENDING',
  GET_NUMBER_OF_PROOF_PENDING_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_PROOF_PENDING_LOADING',
  GET_NUMBER_OF_PROOF_PENDING_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_PROOF_PENDING_SUCCESS',
  GET_NUMBER_OF_PROOF_PENDING_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_NUMBER_OF_PROOF_PENDING_ERROR',

  UPLOAD_PROOF: 'MY_ASD.FISCAL.KYANITE.UPLOAD_PROOF',
  UPLOAD_PROOF_LOADING: 'MY_ASD.FISCAL.KYANITE.UPLOAD_PROOF_LOADING',
  UPLOAD_PROOF_SUCCESS: 'MY_ASD.FISCAL.KYANITE.UPLOAD_PROOF_SUCCESS',
  UPLOAD_PROOF_ERROR: 'MY_ASD.FISCAL.KYANITE.UPLOAD_PROOF_ERROR',

  UPLOAD_SPLIT_PROOF: 'MY_ASD.FISCAL.KYANITE.UPLOAD_SPLIT_PROOF',
  UPLOAD_SPLIT_PROOF_LOADING: 'MY_ASD.FISCAL.KYANITE.UPLOAD_SPLIT_PROOF_LOADING',
  UPLOAD_SPLIT_PROOF_SUCCESS: 'MY_ASD.FISCAL.KYANITE.UPLOAD_SPLIT_PROOF_SUCCESS',
  UPLOAD_SPLIT_PROOF_ERROR: 'MY_ASD.FISCAL.KYANITE.UPLOAD_SPLIT_PROOF_ERROR',

  DOWNLOAD_ALL_PROOF: 'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ALL_PROOF',
  DOWNLOAD_ALL_PROOF_LOADING:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ALL_PROOF_LOADING',
  DOWNLOAD_ALL_PROOF_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ALL_PROOF_SUCCESS',
  DOWNLOAD_ALL_PROOF_ERROR: 'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ALL_PROOF_ERROR',

  DOWNLOAD_ONE_PROOF: 'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ONE_PROOF',
  DOWNLOAD_ONE_PROOF_LOADING:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ONE_PROOF_LOADING',
  DOWNLOAD_ONE_PROOF_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ONE_PROOF_SUCCESS',
  DOWNLOAD_ONE_PROOF_ERROR: 'MY_ASD.FISCAL.KYANITE.DOWNLOAD_ONE_PROOF_ERROR',

  GET_VAT_RISK: 'MY_ASD.FISCAL.KYANITE.GET_VAT_RISK',
  GET_VAT_RISK_LOADING: 'MY_ASD.FISCAL.KYANITE.GET_VAT_RISK_LOADING',
  GET_VAT_RISK_SUCCESS: 'MY_ASD.FISCAL.KYANITE.GET_VAT_RISK_SUCCESS',
  GET_VAT_RISK_ERROR: 'MY_ASD.FISCAL.KYANITE.GET_VAT_RISK_ERROR',

  VALIDATE_PROOF: 'MY_ASD.FISCAL.KYANITE.VALIDATE_PROOF',
  VALIDATE_PROOF_LOADING: 'MY_ASD.FISCAL.KYANITE.VALIDATE_PROOF_LOADING',
  VALIDATE_PROOF_SUCCESS: 'MY_ASD.FISCAL.KYANITE.VALIDATE_PROOF_SUCCESS',
  VALIDATE_PROOF_ERROR: 'MY_ASD.FISCAL.KYANITE.VALIDATE_PROOF_ERROR',

  REFUSE_PROOF: 'MY_ASD.FISCAL.KYANITE.REFUSE_PROOF',
  REFUSE_PROOF_LOADING: 'MY_ASD.FISCAL.KYANITE.REFUSE_PROOF_LOADING',
  REFUSE_PROOF_SUCCESS: 'MY_ASD.FISCAL.KYANITE.REFUSE_PROOF_SUCCESS',
  REFUSE_PROOF_ERROR: 'MY_ASD.FISCAL.KYANITE.REFUSE_PROOF_ERROR',

  VALIDATE_DELIVERY: 'MY_ASD.FISCAL.KYANITE.VALIDATE_DELIVERY',
  VALIDATE_DELIVERY_LOADING: 'MY_ASD.FISCAL.KYANITE.VALIDATE_DELIVERY_LOADING',
  VALIDATE_DELIVERY_SUCCESS: 'MY_ASD.FISCAL.KYANITE.VALIDATE_DELIVERY_SUCCESS',
  VALIDATE_DELIVERY_ERROR: 'MY_ASD.FISCAL.KYANITE.VALIDATE_DELIVERY_ERROR',

  GET_ONE_FOR_BUYER: 'MY_ASD.FISCAL.KYANITE.GET_ONE_FOR_BUYER',
  GET_ONE_FOR_BUYER_LOADING: 'MY_ASD.FISCAL.KYANITE.GET_ONE_FOR_BUYER_LOADING',
  GET_ONE_FOR_BUYER_SUCCESS: 'MY_ASD.FISCAL.KYANITE.GET_ONE_FOR_BUYER_SUCCESS',
  GET_ONE_FOR_BUYER_ERROR: 'MY_ASD.FISCAL.KYANITE.GET_ONE_FOR_BUYER_ERROR',

  GET_PREFILLED: 'MY_ASD.FISCAL.KYANITE.GET_PREFILLED',
  GET_PREFILLED_LOADING: 'MY_ASD.FISCAL.KYANITE.GET_PREFILLED_LOADING',
  GET_PREFILLED_SUCCESS: 'MY_ASD.FISCAL.KYANITE.GET_PREFILLED_SUCCESS',
  GET_PREFILLED_ERROR: 'MY_ASD.FISCAL.KYANITE.GET_PREFILLED_ERROR',

  GET_ONE_FOR_TRANSPORTER: 'MY_ASD.FISCAL.KYANITE.GET_ONE_FOR_TRANSPORTER',
  GET_ONE_FOR_TRANSPORTER_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_ONE_FOR_TRANSPORTER_LOADING',
  GET_ONE_FOR_TRANSPORTER_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_ONE_FOR_TRANSPORTER_SUCCESS',
  GET_ONE_FOR_TRANSPORTER_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_ONE_FOR_TRANSPORTER_ERROR',

  ASK_FOR_PROOF: 'MY_ASD.FISCAL.KYANITE.ASK_FOR_PROOF',
  ASK_FOR_PROOF_LOADING: 'MY_ASD.FISCAL.KYANITE.ASK_FOR_PROOF_LOADING',
  ASK_FOR_PROOF_SUCCESS: 'MY_ASD.FISCAL.KYANITE.ASK_FOR_PROOF_SUCCESS',
  ASK_FOR_PROOF_ERROR: 'MY_ASD.FISCAL.KYANITE.ASK_FOR_PROOF_ERROR',

  CREATE_MASSINTEGRATION: 'MY_ASD.FISCAL.KYANITE.CREATE_MASSINTEGRATION',
  CREATE_MASSINTEGRATION_LOADING:
    'MY_ASD.FISCAL.KYANITE.CREATE_MASSINTEGRATION_LOADING',
  CREATE_MASSINTEGRATION_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.CREATE_MASSINTEGRATION_SUCCESS',
  CREATE_MASSINTEGRATION_ERROR:
    'MY_ASD.FISCAL.KYANITE.CREATE_MASSINTEGRATION_ERROR',

  GET_MASSINTEGRATIONS: 'MY_ASD.FISCAL.KYANITE.GET_MASSINTEGRATIONS',
  GET_MASSINTEGRATIONS_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_MASSINTEGRATIONS_LOADING',
  GET_MASSINTEGRATIONS_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_MASSINTEGRATIONS_SUCCESS',
  GET_MASSINTEGRATIONS_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_MASSINTEGRATIONS_ERROR',

  GET_ONE_MASSINTEGRATION: 'MY_ASD.FISCAL.KYANITE.GET_ONE_MASSINTEGRATION',
  GET_ONE_MASSINTEGRATION_LOADING:
    'MY_ASD.FISCAL.KYANITE.GET_ONE_MASSINTEGRATION_LOADING',
  GET_ONE_MASSINTEGRATION_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.GET_ONE_MASSINTEGRATION_SUCCESS',
  GET_ONE_MASSINTEGRATION_ERROR:
    'MY_ASD.FISCAL.KYANITE.GET_ONE_MASSINTEGRATION_ERROR',

  DELETE_MASSINTEGRATION: 'MY_ASD.FISCAL.KYANITE.DELETE_MASSINTEGRATION',
  DELETE_MASSINTEGRATION_LOADING:
    'MY_ASD.FISCAL.KYANITE.DELETE_MASSINTEGRATION_LOADING',
  DELETE_MASSINTEGRATION_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.DELETE_MASSINTEGRATION_SUCCESS',
  DELETE_MASSINTEGRATION_ERROR:
    'MY_ASD.FISCAL.KYANITE.DELETE_MASSINTEGRATION_ERROR',

  DOWNLOAD_MASSINTEGRATION_TEMPLATE:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_MASSINTEGRATION_TEMPLATE',
  DOWNLOAD_MASSINTEGRATION_TEMPLATE_LOADING:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_MASSINTEGRATION_TEMPLATE_LOADING',
  DOWNLOAD_MASSINTEGRATION_TEMPLATE_SUCCESS:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_MASSINTEGRATION_TEMPLATE_SUCCESS',
  DOWNLOAD_MASSINTEGRATION_TEMPLATE_ERROR:
    'MY_ASD.FISCAL.KYANITE.DOWNLOAD_MASSINTEGRATION_TEMPLATE_ERROR',

  GET_CUSTOMS_DECLARATION_IDS: 'MY_ASD.FISCAL.KYANITE.GET_CUSTOMS_DECLARATION_IDS',
  GET_CUSTOMS_DECLARATION_IDS_LOADING: 'MY_ASD.FISCAL.KYANITE.GET_CUSTOMS_DECLARATION_IDS_LOADING',
  GET_CUSTOMS_DECLARATION_IDS_SUCCESS: 'MY_ASD.FISCAL.KYANITE.GET_CUSTOMS_DECLARATION_IDS_SUCCESS',
  GET_CUSTOMS_DECLARATION_IDS_ERROR: 'MY_ASD.FISCAL.KYANITE.GET_CUSTOMS_DECLARATION_IDS_ERROR',

  RESET: 'MY_ASD.FISCAL.KYANITE.RESET',
  REFRESH_ERROR: 'MY_ASD.FISCAL.KYANITE.REFRESH_ERROR',

  GET_CUSTOM_DECLARATIONS_ID: "MY_ASD.KYANITE.GET_CUSTOM_DECLARATIONS_ID",
  GET_CUSTOM_DECLARATIONS_ID_LOADING: "MY_ASD.KYANITE.GET_CUSTOM_DECLARATIONS_ID_LOADING",
  GET_CUSTOM_DECLARATIONS_ID_SUCCESS: "MY_ASD.KYANITE.GET_CUSTOM_DECLARATIONS_ID_SUCCESS",
  GET_CUSTOM_DECLARATIONS_ID_ERROR: "MY_ASD.KYANITE.GET_CUSTOM_DECLARATIONS_ID_ERROR",

  GET_CLIENT_REF: "MY_ASD.KYANITE.GET_CLIENT_REF",
  GET_CLIENT_REF_LOADING: "MY_ASD.KYANITE.GET_CLIENT_REF_LOADING",
  GET_CLIENT_REF_SUCCESS: "MY_ASD.KYANITE.GET_CLIENT_REF_SUCCESS",
  GET_CLIENT_REF_ERROR: "MY_ASD.KYANITE.GET_CLIENT_REF_ERROR",
  
  GET_FOR_ONE_ALL_BT: "MY_ASD.KYANITE.GET_FOR_ONE_ALL_BT", 
  GET_FOR_ONE_ALL_BT_LOADING: "MY_ASD.KYANITE.GET_FOR_ONE_ALL_BT_LOADING",
  GET_FOR_ONE_ALL_BT_SUCCESS: "MY_ASD.KYANITE.GET_FOR_ONE_ALL_BT_SUCCESS",
  GET_FOR_ONE_ALL_BT_ERROR: "MY_ASD.KYANITE.GET_FOR_ONE_ALL_BT_ERROR",

  SET_VAT: "MY_ASD.KYANITE.SET_VAT",
  SET_VAT_LOADING: "MY_ASD.KYANITE.SET_VAT_LOADING",
  SET_VAT_SUCCESS: "MY_ASD.KYANITE.SET_VAT_SUCCESS",
  SET_VAT_ERROR: "MY_ASD.KYANITE.SET_VAT_ERROR",

  CHECK_QUICKPROOF_EXIST: "MY_ASD.KYANITE.CHECK_QUICKPROOF_EXIST",
  CHECK_QUICKPROOF_EXIST_LOADING: "MY_ASD.KYANITE.CHECK_QUICKPROOF_EXIST_LOADING",
  CHECK_QUICKPROOF_EXIST_SUCCESS: "MY_ASD.KYANITE.CHECK_QUICKPROOF_EXIST_SUCCESS",
  CHECK_QUICKPROOF_EXIST_ERROR: "MY_ASD.KYANITE.CHECK_QUICKPROOF_EXIST_ERROR",

  HAS_PAID_SUBSCRIPTION: "MY_ASD.KYANITE.HAS_PAID_SUBSCRIPTION",
  HAS_PAID_SUBSCRIPTION_LOADING: "MY_ASD.KYANITE.HAS_PAID_SUBSCRIPTION_LOADING",
  HAS_PAID_SUBSCRIPTION_SUCCESS: "MY_ASD.KYANITE.HAS_PAID_SUBSCRIPTION_SUCCESS",
  HAS_PAID_SUBSCRIPTION_ERROR: "MY_ASD.KYANITE.HAS_PAID_SUBSCRIPTION_ERROR",


};
