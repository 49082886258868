import { resetState } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    getAllStatus: null,
  },
  errors: {
    getAllStatus: null,
  },
  loading: {
    getAllStatus: false,
  },
  allStatus: null,
  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_STATUS_LOADING:
      return produce(state, draft => {
        draft.loading.getAllStatus = true;
      });

    case actionTypes.GET_STATUS_SUCCESS:
      return produce(state, draft => {
        draft.errors.getAllStatus = null;
        draft.loading.getAllStatus = false;
        draft.success.getAllStatus = true;
        draft.allStatus = action.payload;
      });

    case actionTypes.GET_STATUS_ERROR:
      return produce(state, draft => {
        draft.errors.getAllStatus = true;
        draft.loading.getAllStatus = false;
        draft.success.getAllStatus = false;
        !draft.error.includes("allStatus") && draft.error.push("allStatus");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
