import actionTypes from 'store/internalTools/commercial/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
     
      case actionTypes.SEND_FORM:
        try {
          next({ type: actionTypes.SEND_FORM_LOADING });
          const resp = await request.sendForm(action.payload);
          next({
            type: actionTypes.SEND_FORM_SUCCESS,
            payload: resp.data.data,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SEND_FORM_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_BASIC:
          try {
            next({ type: actionTypes.GET_BASIC_LOADING });
            const resp = await request.getBasic(action.payload);
            next({
              type: actionTypes.GET_BASIC_SUCCESS,
              payload: resp.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_BASIC_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;

      case actionTypes.PACKAGES:
        try {
          next({ type: actionTypes.PACKAGES_LOADING });
          const resp = await request.packages();
          next({
            type: actionTypes.PACKAGES_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.PACKAGES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      default:
        next(action);
        break;
    }
  };
}
