import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  validateUser(data: dataReqI) {
    return {
      type: actionTypes.VALIDATE_USER,
      payload: data,
    };
  },
  getUsers() {
    return {
      type: actionTypes.GET_USERS,
    };
  },
  getUser(data: dataReqI) {
    return {
      type: actionTypes.GET_USER,
      payload: data,
    };
  },
  deleteUser(data: dataReqI) {
    return {
      type: actionTypes.DELETE_USER,
      payload: data,
    };
  },
  createUser(data: dataReqI) {
    return {
      type: actionTypes.CREATE_USER,
      payload: data,
    };
  },
  update(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_USER,
      payload: data,
    };
  },
  resetPassword(data: dataReqI) {
    return {
      type: actionTypes.RESET_PASSWORD_USER,
      payload: data,
    };
  },
  workmailRenewal(data: dataReqI) {
    return {
      type: actionTypes.WORKMAIL_USER,
      payload: data,
    };
  },
  updatePassword(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_PASSWORD_USER,
      payload: data,
    };
  },
  updatePasswordByToken(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_PASSWORD_BY_TOKEN_USER,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
