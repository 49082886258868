import React, { FunctionComponent } from 'react';
import { Modal } from 'semantic-ui-react';
import { Formiz, useForm } from '@formiz/core';
import XLSX from 'xlsx';
import { actions } from 'store';

import Spacer from 'components/core/Spacer/Spacer';
import Button from 'components/Button';
import FormizFileInput from 'components/FormizField/FormizFileInput/FormizFileInput';

type ModalConvertFileProps = {
  open: boolean;
  handleClose: () => void;
  dispatch: (action: any) => void;
};

const ModalConvertFile: FunctionComponent<ModalConvertFileProps> = ({
  open,
  handleClose,
  dispatch,
}) => {
  const convertFileForm = useForm();

  const handleConvertFile = (value) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      try {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary', raw: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
        });
        data.shift();
        data.pop();
        const formated = data.map((e, i) => {
          return {
            ref: e[6].trim(),
            description: e[10].trim(),
            origin: e[7].trim(),
            NDP: e[9].toString().trim(),
            number: e[3],
            grossWeight: e[12],
            netWeight: e[11],
            totalValue: e[15],
            supplementaryUnit: e[13].trim(),
          };
        });

        dispatch(
          actions.customsDeclaration.convertFile({
            data: formated,
          })
        );
      } catch (error) {
        dispatch(
          actions.message.errorMessage(
            400,
            `Error when transforming the file, please check that the file format is correct (${error.message})`
          )
        );
      }
    };
    reader.readAsBinaryString(value.file);

    handleClose();
  };
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Modal.Content>
        <Formiz connect={convertFileForm} onValidSubmit={handleConvertFile}>
          <form onSubmit={convertFileForm.submit}>
            <FormizFileInput
              name='file'
              label='file'
              acceptedFile={[
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              ]}
              required='file is required'
            />
            <Spacer />
            <Button size='large' type='submit' content='Save' />
          </form>
        </Formiz>
      </Modal.Content>
    </Modal>
  );
};

export default ModalConvertFile;
