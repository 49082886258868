import * as Yup from 'yup';

import config from 'config';

export const imageSchema = Yup.mixed()
  .required('Required')
  .test('fileType', 'Unsupported file format', (value) => {
    if (value) {
      const splitedName = value.name.split('.');
      return config.file.picture.fileType.includes(
        splitedName[splitedName.length - 1]
      );
    }
  })
  .test(
    'fileSize',
    'File is too large',
    (value) => value && value.size < config.file.picture.fileSize
  );

export const startControlSchema = Yup.object().shape({
  startDate: Yup.string().required('Required'),
  startTime: Yup.string().required('Required'),
  companyId: Yup.number().required('Required'),
  declarationId: Yup.string(),
  truckLicensePlate: Yup.string().required('Required'),
  trailerLicensePlate: Yup.string().required('Required'),
  truckOrigin: Yup.string().length(2).required('Required'),
  trailerOrigin: Yup.string().length(2).required('Required'),
  customsOffice: Yup.number().required('Required'),
  transitNumber: Yup.string(),
  signature: imageSchema.required('Required'),
});

export const consignmentSchema = Yup.object().shape({
  consignmentStatus: Yup.string().required('Required'),
  progress: Yup.string().required('Required'),
  endedAt: Yup.string().nullable(true),
})

export const controlSchema = Yup.object().shape({
  files: Yup.array(),
  nature: Yup.string().required('Required'),
  comment: Yup.string(),
  documentaryControl: Yup.boolean(),
  documentaryControlAskedDocuments: Yup.string(),
  documentaryControlInstructionsGiven: Yup.string(),
  physicalControl: Yup.boolean(),
  physicalControlInstructionsGiven: Yup.string(),
  packagingSealBroken: Yup.boolean(),
  sampleTaken: Yup.boolean(),
  reSealingNumber: Yup.string(),
  sampleSignature: Yup.boolean(),
});

export const photoSchema = Yup.object().shape({
  photo: Yup.mixed().required('Required'),
});

export const filtersSchema = Yup.object().shape({
  declarationId: Yup.string(),
  company: Yup.number(),
  startDate: Yup.string(),
  endDate: Yup.string(),
  paidBy: Yup.string(),
  minimalAmount: Yup.number(),
  maximalAmount: Yup.number(),
  licensePlate: Yup.string(),
});
