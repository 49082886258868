export default {
  SUBSCRIBE: "MY_ASD.FISCALVAT.SUBSCRIBE",
  SUBSCRIBE_LOADING: "MY_ASD.FISCALVAT.SUBSCRIBE_LOADING",
  SUBSCRIBE_SUCCESS: "MY_ASD.FISCALVAT.SUBSCRIBE_SUCCESS",
  SUBSCRIBE_ERROR: "MY_ASD.FISCALVAT.SUBSCRIBE_ERROR",

  UNSUBSCRIBE: "MY_ASD.FISCALVAT.UNSUBSCRIBE",
  UNSUBSCRIBE_LOADING: "MY_ASD.FISCALVAT.UNSUBSCRIBE_LOADING",
  UNSUBSCRIBE_SUCCESS: "MY_ASD.FISCALVAT.UNSUBSCRIBE_SUCCESS",
  UNSUBSCRIBE_ERROR: "MY_ASD.FISCALVAT.UNSUBSCRIBE_ERROR",

  GET_CATEGORIES: "MY_ASD.FISCALVAT.GET_CATEGORIES",
  GET_CATEGORIES_LOADING: "MY_ASD.FISCALVAT.GET_CATEGORIES_LOADING",
  GET_CATEGORIES_SUCCESS: "MY_ASD.FISCALVAT.GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_ERROR: "MY_ASD.FISCALVAT.GET_CATEGORIES_ERROR",

  GET_COMPANY_SUBSCRIBED: "MY_ASD.FISCALVAT.GET_COMPANY_SUBSCRIBED",
  GET_COMPANY_SUBSCRIBED_LOADING: "MY_ASD.FISCALVAT.GET_COMPANY_SUBSCRIBED_LOADING",
  GET_COMPANY_SUBSCRIBED_SUCCESS: "MY_ASD.FISCALVAT.GET_COMPANY_SUBSCRIBED_SUCCESS",
  GET_COMPANY_SUBSCRIBED_ERROR: "MY_ASD.FISCALVAT.GET_COMPANY_SUBSCRIBED_ERROR",

  GET_ALL_VAT_DECLARATIONS: "MY_ASD.FISCALVAT.GET_ALL_VAT_DECLARATIONS",
  GET_ALL_VAT_DECLARATIONS_LOADING: "MY_ASD.FISCALVAT.GET_ALL_VAT_DECLARATIONS_LOADING",
  GET_ALL_VAT_DECLARATIONS_SUCCESS: "MY_ASD.FISCALVAT.GET_ALL_VAT_DECLARATIONS_SUCCESS",
  GET_ALL_VAT_DECLARATIONS_ERROR: "MY_ASD.FISCALVAT.GET_ALL_VAT_DECLARATIONS_ERROR",

  GET_ALL_COMPANY_VAT_DECLARATIONS: "MY_ASD.FISCALVAT.GET_ALL_COMPANY_VAT_DECLARATIONS",
  GET_ALL_COMPANY_VAT_DECLARATIONS_LOADING: "MY_ASD.FISCALVAT.GET_ALL_COMPANY_VAT_DECLARATIONS_LOADING",
  GET_ALL_COMPANY_VAT_DECLARATIONS_SUCCESS: "MY_ASD.FISCALVAT.GET_ALL_COMPANY_VAT_DECLARATIONS_SUCCESS",
  GET_ALL_COMPANY_VAT_DECLARATIONS_ERROR: "MY_ASD.FISCALVAT.GET_ALL_COMPANY_VAT_DECLARATIONS_ERROR",

  GET_ONE_VAT_DECLARATION: "MY_ASD.FISCALVAT.GET_ONE_VAT_DECLARATION",
  GET_ONE_VAT_DECLARATION_LOADING: "MY_ASD.FISCALVAT.GET_ONE_VAT_DECLARATION_LOADING",
  GET_ONE_VAT_DECLARATION_SUCCESS: "MY_ASD.FISCALVAT.GET_ONE_VAT_DECLARATION_SUCCESS",
  GET_ONE_VAT_DECLARATION_ERROR: "MY_ASD.FISCALVAT.GET_ONE_VAT_DECLARATION_ERROR",

  UPDATE_VAT_DECLARATION: "MY_ASD.FISCALVAT.UPDATE_VAT_DECLARATION",
  UPDATE_VAT_DECLARATION_LOADING: "MY_ASD.FISCALVAT.UPDATE_VAT_DECLARATION_LOADING",
  UPDATE_VAT_DECLARATION_SUCCESS: "MY_ASD.FISCALVAT.UPDATE_VAT_DECLARATION_SUCCESS",
  UPDATE_VAT_DECLARATION_ERROR: "MY_ASD.FISCALVAT.UPDATE_VAT_DECLARATION_ERROR",

  CREATE_VAT_DECLARATION: 'MY_ASD.FISCALVAT.CREATE_VAT_DECLARATION',
  CREATE_VAT_DECLARATION_LOADING: 'MY_ASD.FISCALVAT.CREATE_VAT_DECLARATION_LOADING', 
  CREATE_VAT_DECLARATION_SUCCESS: 'MY_ASD.FISCALVAT.CREATE_VAT_DECLARATION_SUCCESS',
  CREATE_VAT_DECLARATION_ERROR: 'MY_ASD.FISCALVAT.CREATE_VAT_DECLARATION_ERROR',

  UPDATE_STEP: 'MY_ASD.FISCALVAT.UPDATE_STEP',
  UPDATE_STEP_LOADING: 'MY_ASD.FISCALVAT.UPDATE_STEP_LOADING', 
  UPDATE_STEP_SUCCESS: 'MY_ASD.FISCALVAT.UPDATE_STEP_SUCCESS',
  UPDATE_STEP_ERROR: 'MY_ASD.FISCALVAT.UPDATE_STEP_ERROR',

  FOLLOW_VAT_DECLARATION: 'MY_ASD.FISCALVAT.FOLLOW_VAT_DECLARATION',
  FOLLOW_VAT_DECLARATION_LOADING: 'MY_ASD.FISCALVAT.FOLLOW_VAT_DECLARATION_LOADING', 
  FOLLOW_VAT_DECLARATION_SUCCESS: 'MY_ASD.FISCALVAT.FOLLOW_VAT_DECLARATION_SUCCESS',
  FOLLOW_VAT_DECLARATION_ERROR: 'MY_ASD.FISCALVAT.FOLLOW_VAT_DECLARATION_ERROR',

  UNFOLLOW_VAT_DECLARATION: 'MY_ASD.FISCALVAT.UNFOLLOW_VAT_DECLARATION',
  UNFOLLOW_VAT_DECLARATION_LOADING: 'MY_ASD.FISCALVAT.UNFOLLOW_VAT_DECLARATION_LOADING', 
  UNFOLLOW_VAT_DECLARATION_SUCCESS: 'MY_ASD.FISCALVAT.UNFOLLOW_VAT_DECLARATION_SUCCESS',
  UNFOLLOW_VAT_DECLARATION_ERROR: 'MY_ASD.FISCALVAT.UNFOLLOW_VAT_DECLARATION_ERROR',

  VAT_REPO_ADD_DOCUMENTS: 'MY_ASD.FISCALVAT.VAT_REPO_ADD_DOCUMENTS',
  VAT_REPO_ADD_DOCUMENTS_LOADING: 'MY_ASD.FISCALVAT.VAT_REPO_ADD_DOCUMENTS_LOADING', 
  VAT_REPO_ADD_DOCUMENTS_SUCCESS: 'MY_ASD.FISCALVAT.VAT_REPO_ADD_DOCUMENTS_SUCCESS',
  VAT_REPO_ADD_DOCUMENTS_ERROR: 'MY_ASD.FISCALVAT.VAT_REPO_ADD_DOCUMENTS_ERROR',

  VAT_REPO_GET_DOC_CAT: 'MY_ASD.FISCALVAT.VAT_REPO_GET_DOC_CAT',
  VAT_REPO_GET_DOC_CAT_LOADING: 'MY_ASD.FISCALVAT.VAT_REPO_GET_DOC_CAT_LOADING', 
  VAT_REPO_GET_DOC_CAT_SUCCESS: 'MY_ASD.FISCALVAT.VAT_REPO_GET_DOC_CAT_SUCCESS',
  VAT_REPO_GET_DOC_CAT_ERROR: 'MY_ASD.FISCALVAT.VAT_REPO_GET_DOC_CAT_ERROR',

  DELETE_DOC: 'MY_ASD.FISCALVAT.DELETE_DOC',
  DELETE_DOC_LOADING: 'MY_ASD.FISCALVAT.DELETE_DOC_LOADING',
  DELETE_DOC_SUCCESS: 'MY_ASD.FISCALVAT.DELETE_DOC_SUCCESS',
  DELETE_DOC_ERROR: 'MY_ASD.FISCALVAT.DELETE_DOC_ERROR',

  UPDATE_DOC: 'MY_ASD.FISCALVAT.UPDATE_DOC',
  UPDATE_DOC_LOADING: 'MY_ASD.FISCALVAT.UPDATE_DOC_LOADING',
  UPDATE_DOC_SUCCESS: 'MY_ASD.FISCALVAT.UPDATE_DOC_SUCCESS',
  UPDATE_DOC_ERROR: 'MY_ASD.FISCALVAT.UPDATE_DOC_ERROR',

  DOWNLOAD_DOC: 'MY_ASD.FISCALVAT.DOWNLOAD_DOC',
  DOWNLOAD_DOC_LOADING: 'MY_ASD.FISCALVAT.DOWNLOAD_DOC_LOADING',
  DOWNLOAD_DOC_SUCCESS: 'MY_ASD.FISCALVAT.DOWNLOAD_DOC_SUCCESS',
  DOWNLOAD_DOC_ERROR: 'MY_ASD.FISCALVAT.DOWNLOAD_DOC_ERROR',

  GET_MAPPING: 'MY_ASD.FISCALVAT.GET_MAPPING',
  GET_MAPPING_LOADING: 'MY_ASD.FISCALVAT.GET_MAPPING_LOADING',
  GET_MAPPING_SUCCESS: 'MY_ASD.FISCALVAT.GET_MAPPING_SUCCESS',
  GET_MAPPING_ERROR: 'MY_ASD.FISCALVAT.GET_MAPPING_ERROR',

  GET_ASSIG_COUNTRIES: 'MY_ASD.FISCALVAT.GET_ASSIG_COUNTRIES',
  GET_ASSIG_COUNTRIES_LOADING: 'MY_ASD.FISCALVAT.GET_ASSIG_COUNTRIES_LOADING',
  GET_ASSIG_COUNTRIES_SUCCESS: 'MY_ASD.FISCALVAT.GET_ASSIG_COUNTRIES_SUCCESS',
  GET_ASSIG_COUNTRIES_ERROR: 'MY_ASD.FISCALVAT.GET_ASSIG_COUNTRIES_ERROR',

  COUNTRY: 'MY_ASD.FISCALVAT.COUNTRY',
  COUNTRY_LOADING: 'MY_ASD.FISCALVAT.COUNTRY_LOADING',
  COUNTRY_SUCCESS: 'MY_ASD.FISCALVAT.COUNTRY_SUCCESS',
  COUNTRY_ERROR: 'MY_ASD.FISCALVAT.COUNTRY_ERROR',

  GET_OBLIGATION: 'MY_ASD.CONFIGURATIONS.GET_OBLIGATION',
  GET_OBLIGATION_LOADING: 'MY_ASD.CONFIGURATIONS.GET_OBLIGATION_LOADING',
  GET_OBLIGATION_SUCCESS: 'MY_ASD.CONFIGURATIONS.GET_OBLIGATION_SUCCESS',
  GET_OBLIGATION_ERROR: 'MY_ASD.CONFIGURATIONS.GET_OBLIGATION_ERROR',

  OBLIGATION_CREATE: 'MY_ASD.FISCALVAT.OBLIGATION_CREATE',
  OBLIGATION_CREATE_LOADING: 'MY_ASD.FISCALVAT.OBLIGATION_CREATE_LOADING',
  OBLIGATION_CREATE_SUCCESS: 'MY_ASD.FISCALVAT.OBLIGATION_CREATE_SUCCESS',
  OBLIGATION_CREATE_ERROR: 'MY_ASD.FISCALVAT.OBLIGATION_CREATE_ERROR',

  OBLIGATION_UPDATE: 'MY_ASD.FISCALVAT.OBLIGATION_UPDATE',
  OBLIGATION_UPDATE_LOADING: 'MY_ASD.FISCALVAT.OBLIGATION_UPDATE_LOADING',
  OBLIGATION_UPDATE_SUCCESS: 'MY_ASD.FISCALVAT.OBLIGATION_UPDATE_SUCCESS',
  OBLIGATION_UPDATE_ERROR: 'MY_ASD.FISCALVAT.OBLIGATION_UPDATE_ERROR',

  OBLIGATION_DELETE: 'MY_ASD.FISCALVAT.OBLIGATION_DELETE',
  OBLIGATION_DELETE_LOADING: 'MY_ASD.FISCALVAT.OBLIGATION_DELETE_LOADING',
  OBLIGATION_DELETE_SUCCESS: 'MY_ASD.FISCALVAT.OBLIGATION_DELETE_SUCCESS',
  OBLIGATION_DELETE_ERROR: 'MY_ASD.FISCALVAT.OBLIGATION_DELETE_ERROR',

  GET_EXCEL: 'MY_ASD.FISCALVAT.GET_EXCEL',
  GET_EXCEL_LOADING: 'MY_ASD.FISCALVAT.GET_EXCEL_LOADING',
  GET_EXCEL_SUCCESS: 'MY_ASD.FISCALVAT.GET_EXCEL_SUCCESS',
  GET_EXCEL_ERROR: 'MY_ASD.FISCALVAT.GET_EXCEL_ERROR',

  UPDATE_EXCEL: 'MY_ASD.FISCALVAT.UPDATE_EXCEL',
  UPDATE_EXCEL_LOADING: 'MY_ASD.FISCALVAT.UPDATE_EXCEL_LOADING',
  UPDATE_EXCEL_SUCCESS: 'MY_ASD.FISCALVAT.UPDATE_EXCEL_SUCCESS',
  UPDATE_EXCEL_ERROR: 'MY_ASD.FISCALVAT.UPDATE_EXCEL_ERROR',

  GET_FULL_LIST_DOCUMENTS: 'MY_ASD.FISCALVAT.GET_FULL_LIST_DOCUMENTS',
  GET_FULL_LIST_DOCUMENTS_LOADING: 'MY_ASD.FISCALVAT.GET_FULL_LIST_DOCUMENTS_LOADING',
  GET_FULL_LIST_DOCUMENTS_SUCCESS: 'MY_ASD.FISCALVAT.GET_FULL_LIST_DOCUMENTS_SUCCESS',
  GET_FULL_LIST_DOCUMENTS_ERROR: 'MY_ASD.FISCALVAT.GET_FULL_LIST_DOCUMENTS_ERROR',

  DELETE_FILES: 'MY_ASD.FISCALVAT.DELETE_FILES',
  DELETE_FILES_LOADING: 'MY_ASD.FISCALVAT.DELETE_FILES_LOADING',
  DELETE_FILES_SUCCESS: 'MY_ASD.FISCALVAT.DELETE_FILES_SUCCESS',
  DELETE_FILES_ERROR: 'MY_ASD.FISCALVAT.DELETE_FILES_ERROR',


  RESET: "MY_ASD.DATA.RESET",
};
