import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ErrorMessage } from 'formik';
import { Loader as Loading } from 'semantic-ui-react';
import Webcam from 'react-webcam';

import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import { Error } from 'components/Form';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import ImageCustom from 'components/core/ImageCustom/ImageCustom';

const Photo = ({
  meta,
  field,
  label,
  mandatory,
  fileName,
  hidePhoto,
  clearPhoto,
  onCapture,
  onCameraDisplay,
  onCameraHide,
  onClear,
}: any) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [displayCamera, setdisplayCamera] = useState(false);
  const [loading, setloading] = useState(true);
  const [displayDeleteButton, setdisplayDeleteButton] = useState(true);

  const clearPicture = useCallback(() => {
    setImgSrc(null);
    meta.setFieldValue(field.name, null);
    setdisplayDeleteButton(false);
    onClear && onClear();
  }, [meta, field.name, onClear]);

  useEffect(() => {
    hidePhoto && hidePicture();
  }, [hidePhoto]);

  useEffect(() => {
    clearPhoto && clearPicture();
  }, [clearPhoto, clearPicture]);

  const webcamRef = useRef(null);
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'environment',
  };

  const dataURLtoFile = (dataUrl, filename) => {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.jpeg`, { type: mime });
  };

  const manageDisplay = () => {
    if (displayCamera) {
      setdisplayCamera(false);
      setloading(true);
      onCameraHide && onCameraHide();
    } else {
      setdisplayCamera(true);
      onCameraDisplay && onCameraDisplay();
    }
  };

  const hidePicture = () => {
    setImgSrc(null);
    setdisplayDeleteButton(false);
  };



  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const imageFile = dataURLtoFile(imageSrc, fileName);
    meta.setFieldValue(field.name, imageFile);
    setImgSrc(imageSrc);
    setdisplayDeleteButton(true);
    onCapture && onCapture();
  };

  return (
    <Wrapper display='block'>
      {label && (
        <label>
          {label}
          {mandatory && <MandatoryFieldLabel />}
        </label>
      )}
      <Wrapper display='block' margin='0'>
        <Button
          type='button'
          size='small'
          icon='camera'
          labelPosition='right'
          color='blue'
          {...(displayCamera
            ? { content: 'Hide camera' }
            : { content: 'Open camera' })}
          margin='5px'
          onClick={() => {
            manageDisplay();
          }}
        />
      </Wrapper>
      {displayCamera && (
        <>
          <Wrapper margin='1rem auto'>
            {loading && (
              <Wrapper>
                <Loading active content='Loading' />
              </Wrapper>
            )}

            <Webcam
              width='100%'
              height='400px'
              audio={false}
              ref={webcamRef}
              screenshotFormat='image/jpeg'
              videoConstraints={videoConstraints}
              onUserMedia={() => setloading(false)}
            />
          </Wrapper>
          {!loading && (
            <Wrapper display='block' margin='0'>
              <Button
                type='button'
                size='large'
                icon='camera'
                labelPosition='right'
                color='blue'
                content='Take photo'
                margin='5px'
                onClick={() => {
                  capture();
                  manageDisplay();
                }}
              />
            </Wrapper>
          )}
        </>
      )}
      {imgSrc && <ImageCustom height='400px' width='auto' src={imgSrc} />}
      {displayDeleteButton && imgSrc && (
        <Wrapper display='block' margin='0'>
          <Button
            type='button'
            size='large'
            icon='trash'
            color='red'
            margin='5px'
            onClick={() => {
              clearPicture();
            }}
          />
        </Wrapper>
      )}
      <ErrorMessage name={field.name} render={(msg) => <Error msg={msg} />} />
    </Wrapper>
  );
};

export default Photo;
