import React, { useState } from "react";

import { Form, Modal, Checkbox, Header } from "semantic-ui-react";
import Button from "components/Button";

const Index = ({
  meta,
  placeholder,
  type,
  field,
  name,
  disabled,
  errors,
  touched,
  label,
  margin,
  columns,
  column,
  direction,
  handleSort,
  details,
  total,
  areActionsBlocked,
  history,
  isModalOpen,
  handleClose
}: any) => {
  //const [checkboxState, setCheckboxState] = useState(null);
  const [isSubmited, setIsSubmited] = useState(false);

  const onSubmit = () => {
    setIsSubmited(true);
  };

  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <Modal.Content>
        <Form>
          {isSubmited === false && (
            <>
              <Header size="tiny">Ask for validation by email:</Header>
              <Form.Group inline>
                <Form.Field>
                  <Checkbox
                    // radio
                    label="Email"
                    name="checkboxRadioGroup"
                    value="email"
                    // checked={true}
                    // checked={checkboxState === "email"}
                    // onChange={(e, { value }) => {
                    //   setCheckboxState(value);
                    // }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    // radio
                    label="Notification"
                    name="checkboxRadioGroup"
                    value="notification"
                    // checked={checkboxState === "notification"}
                    // onChange={(e, { value }) => {
                    //   setCheckboxState(value);
                    // }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    // radio
                    label="Sms"
                    name="checkboxRadioGroup"
                    value="sms"
                    // checked={checkboxState === "sms"}
                    // onChange={(e, { value }) => {
                    //   setCheckboxState(value);
                    // }}
                  />
                </Form.Field>
              </Form.Group>

              <Button
                type="submit"
                content="Send the request"
                color="blue"
                onClick={() => onSubmit()}
              />
            </>
          )}
          {isSubmited === true && (
            <>
              <Header size="tiny">Your request has been sent.</Header>

              <Button
                type="button"
                content="Return to delivery validation"
                color="blue"
                onClick={() => handleClose()}
              />
            </>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default Index;
