import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Form,
  Tab,
  Container,
  Segment,
  Divider,
  Table,
  Grid,
  Button as Btn,
  Header
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';

import { updateCompanySchema } from 'interfaces/formSchema';
import { Input } from 'components/Form';
import Button from 'components/Button';
import { GoupButtonStyled } from '../styles';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { adresseSchema } from 'interfaces/formSchema';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';

const Index = ({
  companyData,
  agencies,
  user,
  dispatch,
  errorHandlerAgencies,
  errorHandlerCompany,
  errorHandlerAddress,
  loadingHandlerCompany,
  loadingHandlerAddress,
}) => {
  const [company, setcompany] = useState(null);
  const [address, setaddress] = useState(null);
  const [mailForChat, setMailForChat]: any = useState(false);
  const [agenciesList, setagenciesList]: any = useState([]);
  const [edit, setedit]: any = useState(false);
  const [underEditionAddress, setunderEditionAddress] = useState(null);
  const [showAddress, setshowAddress] = useState(false);
  const [runningAction, setRunningAction] = useState(null);

  useEffect(() => {
    dispatch(actions.company.reset(['error']));
    dispatch(actions.address.reset(['error']));

    (user.admin || user.operator) && dispatch(actions.agency.getAll());

    return () => {
      dispatch(actions.data.reset(['allCountries', 'agencies', 'error']));
    };
  }, [dispatch , user]);

  useEffect(() => {
    if (runningAction !== null && !loadingHandlerAddress) {
      if (!errorHandlerAddress.includes(runningAction)) {
        dispatch(
          actions.company.getOne({
            params: { companyId: company.id },
          })
        );
      }
      setRunningAction(null);
    }
  }, [dispatch, loadingHandlerAddress, company, runningAction, errorHandlerAddress]);

  useEffect(() => {
    if (companyData) {
      setcompany(companyData);
    }
    if (companyData && companyData.Addresses) {
      setaddress(companyData.Addresses[0]);
    }
  }, [companyData]);

  useEffect(() => {
    if (agencies !== null) {
      setagenciesList(
        agencies.map((agency, index) => {
          return {
            key: index,
            value: agency.id,
            text: agency.name,
            flag: agency.Address.country.toLowerCase(),
          };
        })
      );
    }
  }, [agencies]);


  useEffect(() => {
    if(company?.chatmail){
      setMailForChat(true)
    }else{
      setMailForChat(false)
    }
  }, [company])

  const reloadCompanyForm = (resetForm) => {
    setedit(false);
    const nextInitialState = {
      id: company.id || '',
      name: company.name || '',
      email: company.email || '',
      phoneNumber: company.phoneNumber || '',
      uniqueNumber: company.uniqueNumber || '',
      asdId: company.asdId || '',
      holdingAgency: company.holdingAgency || '',
      processingAgency: company.processingAgency || '',
      country: company.country || '',
      transporter: company.transporter,
      chatmail: company.chatmail || false,
      mailforchat: company.mailforchat || '',
      mandates: company.mandates || false,
    };
    resetForm(nextInitialState);
  };

  const reloadAddressForm = (resetForm) => {
    setunderEditionAddress(null);
    const nextInitialState = {
      id: address.id,
      country: address.country || '',
      city: address.city || '',
      zipCode: address.zipCode || '',
      address1: address.address1 || '',
      address2: address.address2 || '',
      headquarters: address.headquarters || false,
      phoneNumber: address.phoneNumber || '',
    };
    resetForm(nextInitialState);
  };

  const save = (values) => {
    setedit(false);
    dispatch(actions.company.reset(['error']));
  
    dispatch(
      actions.company.update({
        params: { companyId: company.id },
        data: {
          name: values.name,
          email: values.email,
          phoneNumber: values.phoneNumber,
          uniqueNumber: values.uniqueNumber,
          asdId: values.asdId,
          holdingAgency: values.holdingAgency,
          processingAgency: values.processingAgency,
          country: values.country,
          transporter: values.transporter,
          chatmail: values.chatmail || false,
          mailforchat: values.mailforchat || null,
          mandates: values.mandates || null,
          conex: values.conex || null,
        },
      })
    );
    setRunningAction('update');
  };

  const updateAddress = (values, i) => {
    setshowAddress(false);
    setunderEditionAddress(false);
    dispatch(actions.address.reset(['error']));
    dispatch(
      actions.address.updateAddress({
        data: {
          country: values.country,
          city: values.city,
          zipCode: values.zipCode,
          address1: values.address1,
          address2: values.address2,
          headquarters: values.headquarters,
          phoneNumber: values.phoneNumber,
        },
        params: { companyId: company.id, addressId: values.id },
      })
    );
    setRunningAction('updateAddress');
  };


  return (
    <Tab.Pane
      loading={!company || loadingHandlerCompany || loadingHandlerAddress}
    >
      <Container>
        {company && (
          <Formik
            initialValues={{
              id: company.id || '',
              name: company.name || '',
              email: company.email || '',
              phoneNumber: company.phoneNumber || '',
              uniqueNumber: company.uniqueNumber || '',
              asdId: company.asdId || '',
              holdingAgency: company.holdingAgency || '',
              processingAgency: company.processingAgency || '',
              country: company.country || '',
              transporter: company.transporter,
              chatmail: company.chatmail || false,
              mailforchat: company.mailforchat || '',
              mandates: company.mandates || null,
              conex: company.conex || null
            }}
            onSubmit={save}
            validationSchema={updateCompanySchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              resetForm,
              values,
              setFieldValue,
              setFieldTouched,
            }: any) => (
              <Form onSubmit={handleSubmit}>
                <>
                  {company.id !== values.id && reloadCompanyForm(resetForm)}
                  <Header>General:</Header>
                  <Wrapper padding='1rem 5rem'>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          mandatory
                          disabled={!edit}
                          label="Company's name"
                          placeholder="Company's name"
                          name='name'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Field
                          mandatory
                          disabled={!edit}
                          label="Company's email"
                          placeholder="Company's email"
                          name='email'
                          type='email'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Divider hidden />
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          disabled={!edit}
                          label='Phone number'
                          placeholder='Phone number'
                          name='phoneNumber'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                        />
                      </Form.Field>

                      <Form.Field>
                        {(user.admin || user.operator) && (
                          <Field
                            disabled={!edit}
                            label='ASD ID'
                            placeholder='ASD ID'
                            name='asdId'
                            type='text'
                            component={Input}
                            meta={{ errors, touched }}
                          />
                        )}
                      </Form.Field>
                    </Form.Group>

                    {(user.admin || user.operator) && (
                      // || user.superOp
                      <>
                        <Divider hidden />
                        <Form.Group widths='equal'>
                          <Form.Field>
                            <Field
                              disabled={!edit}
                              label='Holding ASD Agency'
                              placeholder='Choose agency'
                              name='holdingAgency'
                              options={agenciesList}
                              component={Dropdown}
                              meta={{
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }}
                              uniqueError={errorHandlerAgencies.includes(
                                'agencies'
                              )}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Field
                              disabled={!edit}
                              label='Processing ASD Agency'
                              placeholder='Choose agency'
                              name='processingAgency'
                              options={agenciesList}
                              component={Dropdown}
                              meta={{
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }}
                              uniqueError={errorHandlerAgencies.includes(
                                'agencies'
                              )}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Field>
                          <Wrapper padding='2rem 0 0'>
                            <Field
                              disabled={!edit}
                              label='Transporter'
                              checked={values.transporter === true}
                              name='transporter'
                              onChange={() => {
                                values.transporter === false
                                  ? setFieldValue('transporter', true)
                                  : setFieldValue('transporter', false);
                              }}
                              component={Checkbox}
                              meta={{
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }}
                            />
                          </Wrapper>
                        </Form.Field>
                        <Form.Group widths='equal'>
                        <Form.Field>
                          <Wrapper padding='2rem 0 0'>
                            <Field
                              disabled={!edit}
                              label='Receive by e-mail Customs declaration chat feed'
                              checked={values.chatmail === true}
                              name='chatmail'
                              onChange={() => {
                                if(values.chatmail === false){
                                  setFieldValue('chatmail', true)
                                  setMailForChat(true)
                                }else{
                                  setFieldValue('chatmail', false)
                                  setMailForChat(false)
                                }
                              }}
                              component={Checkbox}
                              meta={{
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }}
                            />
                          </Wrapper>
                        </Form.Field>
                        <Form.Field>
                       
                        {mailForChat && (
                            <Field
                              disabled={!edit}
                              label='Your email'
                              placeholder='Email'
                              name='mailforchat'
                              type='text'
                              component={Input}
                              meta={{ errors, touched }}
                            />
                        )}
                      </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field>
                          <Wrapper margin='2rem 0 0'>
                          <Field
                              disabled={!edit}
                              label='Mandates'
                              checked={values.mandates === true}
                              name='mandates'
                              onChange={() => {
                                values.mandates === null
                                  ? setFieldValue('mandates', true)
                                  : setFieldValue('mandates', null);
                              }}
                              component={Checkbox}
                              meta={{
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }}
                            />
                          </Wrapper>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field>
                          <Wrapper margin='2rem 0 0'>
                          <Field
                              disabled={!edit}
                              label='Conex'
                              checked={values.conex}
                              name='conex'
                              onChange={() => {                             
                                setFieldValue('conex', !values.conex)                          
                              }}
                              component={Checkbox}
                              meta={{
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }}
                            />
                          </Wrapper>
                        </Form.Field>
                      </Form.Group>
                      </>
                    )}
                  </Wrapper>
                  <Divider hidden />

                  <GoupButtonStyled fluid>
                    {!edit && (
                      <Button
                        content='Edit'
                        icon='edit'
                        type='button'
                        color='blue'
                        labelPosition='right'
                        onClick={() => setedit(true)}
                        margin='5px 5px 5px 0px'
                        float-righ
                      />
                    )}
                    {edit && (
                      <>
                        <Button
                          content='Validate'
                          icon='save'
                          type='submit'
                          color='blue'
                          labelPosition='right'
                          margin='5px 5px 5px 0px'
                          float-righ
                        />
                        <Button
                          content='Cancel'
                          icon='cancel'
                          type='button'
                          color='red'
                          labelPosition='right'
                          onClick={() => {
                            setedit(false);
                            resetForm();
                          }}
                          margin='5px 5px 5px 0px'
                          float-righ
                        />
                      </>
                    )}
                  </GoupButtonStyled>
                </>
              </Form>
            )}
          </Formik>
        )}
        <Divider />

        {address !== null && company.Addresses[0] && (
          <Formik
            initialValues={{
              id: address.id,
              country: address.country || '',
              city: address.city || '',
              zipCode: address.zipCode || '',
              address1: address.address1 || '',
              address2: address.address2 || '',
              headquarters: address.headquarters || false,
              phoneNumber: address.phoneNumber || '',
            }}
            onSubmit={updateAddress}
            validationSchema={adresseSchema}
          >
            {({ errors, touched, handleSubmit, resetForm, values }: any) => (
              <Wrapper>
                {address.id !== values.id && reloadAddressForm(resetForm)}
                <Header>Address:</Header>
                <Wrapper padding='0 5rem 2rem'>
                  <Table
                    size='large'
                    attached='top'
                    columns='5'
                    sortable
                    selectable
                  >
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>{address.country}</Table.Cell>
                        <Table.Cell>{address.city}</Table.Cell>
                        <Table.Cell>
                          <Btn.Group vertical widths='two'>
                            {!showAddress && (
                              <Button
                                content='Show'
                                icon='eye'
                                type='button'
                                labelPosition='right'
                                onClick={() => {
                                  setshowAddress(true);
                                }}
                                margin='5px'
                              />
                            )}
                            {showAddress && (
                              <Button
                                content='Hide'
                                icon='eye'
                                type='button'
                                labelPosition='right'
                                onClick={() => {
                                  setshowAddress(false);
                                  setunderEditionAddress(false);
                                }}
                                margin='5px'
                              />
                            )}
                          </Btn.Group>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  {showAddress && (
                    <Segment attached='bottom'>
                      <>
                        <Form onSubmit={handleSubmit}>
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <Field
                                mandatory
                                label='City'
                                placeholder='City'
                                name={`city`}
                                type='text'
                                component={Input}
                                meta={{ errors, touched }}
                                disabled={!underEditionAddress}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Field
                                label='Zip Code'
                                placeholder='Zip Code'
                                name={`zipCode`}
                                type='text'
                                component={Input}
                                meta={{ errors, touched }}
                                disabled={!underEditionAddress}
                              />
                            </Form.Field>
                          </Form.Group>
                          <Form.Field>
                            <Field
                              mandatory
                              label='Address 1'
                              placeholder='Address'
                              name={`address1`}
                              type='text'
                              component={Input}
                              meta={{ errors, touched }}
                              disabled={!underEditionAddress}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Field
                              label='Address 2'
                              placeholder='Building, floor ...'
                              name={`address2`}
                              type='text'
                              component={Input}
                              meta={{ errors, touched }}
                              disabled={!underEditionAddress}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Field
                              label='Phone number'
                              placeholder='Phone number'
                              name={`phoneNumber`}
                              type='text'
                              component={Input}
                              meta={{ errors, touched }}
                              disabled={!underEditionAddress}
                            />
                          </Form.Field>
                          <Grid verticalAlign='bottom' centered>
                            <Grid.Column>
                              <Wrapper display='flex' justifyContent='center'>
                                {!underEditionAddress && (
                                  <Button
                                    content='Edit'
                                    icon='edit'
                                    type='button'
                                    color='blue'
                                    labelPosition='right'
                                    onClick={() => setunderEditionAddress(true)}
                                    margin='5px 5px 5px 0px'
                                    float-righ
                                  />
                                )}
                                {underEditionAddress && (
                                  <>
                                    <Button
                                      content='Validate'
                                      icon='save'
                                      type='submit'
                                      color='blue'
                                      labelPosition='right'
                                      margin='5px 5px 5px 0px'
                                      float-righ
                                    />
                                    <Button
                                      content='Cancel'
                                      icon='cancel'
                                      type='button'
                                      color='red'
                                      labelPosition='right'
                                      onClick={() => {
                                        setunderEditionAddress(false);
                                        resetForm();
                                      }}
                                      margin='5px 5px 5px 0px'
                                      float-righ
                                    />
                                  </>
                                )}
                              </Wrapper>
                            </Grid.Column>
                          </Grid>
                        </Form>
                      </>
                    </Segment>
                  )}
                </Wrapper>
              </Wrapper>
            )}
          </Formik>
        )}
        
                      
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    companyAddress: selectors.address.addressSelector(state),
    agencies: selectors.agency.agenciesSelector(state),
    errorHandlerAgencies: selectors.agency.errorSelector(state),
    errorHandlerCompany: selectors.company.errorSelector(state),
    errorHandlerAddress: selectors.address.errorSelector(state),
    loadingHandlerCompany: selectors.company.loadingSelector(state),
    loadingHandlerAddress: selectors.address.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
