import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

// import Monitoring from './Monitoring';
import CompanyList from './Operator/Service';
import ServicePaused from 'components/ServicePaused';
import { selectors, actions } from 'store';
import Loader from 'components/Loader';
import { Container } from 'semantic-ui-react';

const Index = ({
  user,
  dispatch,
  history,
  companySelected,
  hasSubscribedTo
}: any) => {

  const userAdminOrOp = user.admin || user.operator
  const [uniqueId, setUniqueId] = useState(null)

  useEffect(() => {
    if(companySelected){
      setUniqueId(Date.now())
      dispatch(
        actions.company.hasSubscribedTo({
          params: {
            companyId: companySelected,
            serviceName: 'CollectionBox',
          },
        })
      );
      return () => {
        dispatch(actions.company.reset(['hasSubscribedTo', 'error']));
      };
    }
  }, [dispatch, companySelected]);


  return (
    (companySelected && hasSubscribedTo && !hasSubscribedTo.pause && uniqueId !== null && (
      <CompanyList history={history} dispatch={dispatch} user={user} companySelected={companySelected} hasSubscribedTo={hasSubscribedTo}/>
      // <Monitoring 
      //   dispatch={dispatch} 
      //   history={history} 
      //   user={user} 
      //   companySelected={companySelected} 
      // />
    )) ||
    ((companySelected === null || hasSubscribedTo === false) && !userAdminOrOp && (
      <Container textAlign='center'>
        <h1>You have not subscribed to this service</h1>
      </Container>
    )) ||
    (hasSubscribedTo && hasSubscribedTo.pause && (
      <ServicePaused history={history} companySelected={companySelected} />
    )) ||
    (userAdminOrOp && (
      <CompanyList history={history} dispatch={dispatch} user={user} companySelected={companySelected}/>
    )) ||
    <Loader />
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    hasSubscribedTo: selectors.company.hasSubscribedToSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
