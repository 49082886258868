// Dependencies
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import OrderProperties from '../../interfaces/orderProperties';

// Stylesheets
import './orderForm.css';

/**
 * @name numberOfEmployees.tsx
 *
 * @path /components/orderForm/numberOfEmployees.tsx
 *
 * @description Defines form's component to choose the number of employees to add during mission's creation
 */
const NumberOfEmployees: React.FC<OrderProperties> = ({ setCount }) => {
  // Parameter that must be used before each translatable string  to apply the good language
  const { t } = useTranslation();

  // Setters for dropdown button's visibility
  const [isOpenEmployees, setIsOpenEmployees] = useState(false);

  // Variables
  const numbers = Array.from({ length: 50 }, (_, index) => index + 1);
  const [selectedOptionNumber, setSelectedOptionNumber] = useState<
    string | null
  >(null);

  // References on html elements
  const numberOfEmployeesSelectorRef = useRef<HTMLSelectElement>(null);

  // Reloader
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  // Attach an event handler to update the selected option when the user makes a selection
  const handleSelectChangeEmployees = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionNumber = event.target.value;

    setSelectedOptionNumber(newSelectedOptionNumber);

    // Save the selected option to sessionStorage
    window.sessionStorage.setItem(
      'selectedOptionNumber',
      newSelectedOptionNumber
    );
    handleIncrement();
  };

  // Retrieve the selected option from sessionStorage when the component mounts (optional)
  useEffect(() => {
    const storedSelectedOptionNumber = window.sessionStorage.getItem(
      'selectedOptionNumber'
    );
    if (storedSelectedOptionNumber) {
      setSelectedOptionNumber(storedSelectedOptionNumber);
    }
  }, []);

  // Defines the action that must be performed when the user clicks outside of the component
  const handleOutsideClickNbEmployees = (event: MouseEvent) => {
    if (
      numberOfEmployeesSelectorRef.current &&
      !numberOfEmployeesSelectorRef.current.contains(event.target as Node)
    ) {
      setIsOpenEmployees(false);
    }
  };

  // Configuration of page's current state when handleOutsideClick is performed
  useEffect(() => {
    document.addEventListener('click', handleOutsideClickNbEmployees);

    return () => {
      document.removeEventListener('click', handleOutsideClickNbEmployees);
    };
  }, []);

  return (
    <div className='orderNumberOfEmployees'>
      <pre>
        <p id='obligatoire'>* </p>
      </pre>
      <pre>
        <p>{t('orderPageStrings.numberOfEmployees.title')}</p>
      </pre>
      <select
        id='select'
        ref={numberOfEmployeesSelectorRef}
        className='employeesDropdown'
        onClick={() => setIsOpenEmployees(!isOpenEmployees)}
        value={selectedOptionNumber || ''}
        onChange={handleSelectChangeEmployees}
      >
        <option key='defaultNumberOfEmployees' value=''>
          {t('---')}
        </option>
        {numbers.map((number) => (
          <option key={number} value={number}>
            {number}
          </option>
        ))}
      </select>
    </div>
  );
};

export default NumberOfEmployees;
