let actionTypes = {
  CREATE: "MY_ASD.AGENCY.CREATE",
  CREATE_LOADING: "MY_ASD.AGENCY.CREATE_LOADING",
  CREATE_SUCCESS: "MY_ASD.AGENCY.CREATE_SUCCESS",
  CREATE_ERROR: "MY_ASD.AGENCY.CREATE_ERROR",

  GET_ALL: "MY_ASD.AGENCY.GET_ALL",
  GET_ALL_LOADING: "MY_ASD.AGENCY.GET_ALL_LOADING",
  GET_ALL_SUCCESS: "MY_ASD.AGENCY.GET_ALL_SUCCESS",
  GET_ALL_ERROR: "MY_ASD.AGENCY.GET_ALL_ERROR",

  GET_ONE: "MY_ASD.AGENCY.GET_ONE",
  GET_ONE_LOADING: "MY_ASD.AGENCY.GET_ONE_LOADING",
  GET_ONE_SUCCESS: "MY_ASD.AGENCY.GET_ONE_SUCCESS",
  GET_ONE_ERROR: "MY_ASD.AGENCY.GET_ONE_ERROR",

  REMOVE: "MY_ASD.AGENCY.REMOVE",
  REMOVE_LOADING: "MY_ASD.AGENCY.REMOVE_LOADING",
  REMOVE_SUCCESS: "MY_ASD.AGENCY.REMOVE_SUCCESS",
  REMOVE_ERROR: "MY_ASD.AGENCY.REMOVE_ERROR",

  UPDATE: "MY_ASD.AGENCY.UPDATE",
  UPDATE_LOADING: "MY_ASD.AGENCY.UPDATE_LOADING",
  UPDATE_SUCCESS: "MY_ASD.AGENCY.UPDATE_SUCCESS",
  UPDATE_ERROR: "MY_ASD.AGENCY.UPDATE_ERROR",

  RESET: "MY_ASD.AGENCY.RES",
  REFRESH_ERROR: "MY_ASD.AGENCY.REFRESH_ERROR",
};

export default actionTypes;
