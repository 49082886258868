import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Tab, Loader, Dimmer, Grid, Form, Dropdown } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import { Main } from 'components/Layouts';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import GeneralPermissions from './GeneralPermissions';
import Segment from 'components/core/Segment/Segment';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Field, FieldArray, Formik } from 'formik';
import { Checkbox } from 'components/Form';
import IntrastatPermissions from './IntrastatPermissions';
import VatPermissions from './VatPermissions';
import { Alert } from './operatorSlyles';
import CollectionBoxPermissions from './CollectionBoxPermissions';

const Index = ({
  dispatch,
  match,
  operator,
  history,
  addRule,
  loadingHandlerOperator,
}) => {

  const [list, setList]: any = useState([])
  const [isFilled, setFilled]: any = useState(null)
  const [isAlert, setIsAlert]: any = useState(null)
  const [isAmount, setIsAmount]: any = useState(null)
  const [roleOptions]: any = useState([
    {key: 0, value: "declarant", text: "Declarant"},
    {key: 1, value: "commis", text: "Commis"},
    {key: 2, value: "traces", text: "Traces"},
    {key: 3, value: "commercial", text: "Commercial"},
  ])


  useEffect(() => {
    dispatch(
      actions.operators.getOneOperator({
        params: { operatorId: match.params.operatorId },
      })
    );
    dispatch(
      actions.socket.subscribePermissionOperator(match.params.operatorId)
    );

    return () => {
      dispatch(actions.socket.unsubscribePermissionOperator(match.params.operatorId));
      dispatch(actions.operators.reset(['getOneOperator', 'error']));
    };
  }, [dispatch, match.params.operatorId]);



  const addRole = (values) => {
    dispatch(actions.operators.addOneOperatorRole({
      params: {
        operatorId: match.params.operatorId
      },
      data: {
        type: values
      }
    }))
    return () => {
      dispatch(actions.operators.reset(['addOneOperatorRole', 'error']));
    }
  }

  useEffect(() => {
    if(operator && operator.role){
      let operatorTypes: any = []

      for (const opt of roleOptions){
        let obj: any = operator.role.find((e) => e.role === opt.value)
        if(obj){
          operatorTypes.push({key: obj.id, value: obj.role, checked: true, limit: obj.limit ? obj.limit: null})
        }else{
          operatorTypes.push({key: opt.key, value: opt.value, checked: false})
        }
      }

      operatorTypes.length === roleOptions.length && setList(operatorTypes) 
    }else{
      setList(roleOptions)
    }
    operator && console.log(operator)
  }, [operator, roleOptions])


  return (
    <>
      {(operator === null && loadingHandlerOperator && (
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      )) || (
        <Main>
          <Wrapper minWidth='60rem' maxWidth='80rem'>
            <Divider hidden />
            <Wrapper display='flex'>
              <ButtonBack history={history} />
            </Wrapper>
            {operator !== null && (
              <>
                <Segment margin='1rem 0 2rem 0'>
                  <Grid columns={2} divided padded>
                    <Grid.Column>
                      <div>
                        <b>First Name: </b>
                        {operator.firstName}
                      </div>
                      <div>
                        <b>Last Name: </b>
                        {operator.lastName}
                      </div>
                      <div>
                        <b>Email: </b>
                        {operator.email}
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                    {(addRule === undefined && 
                      <Dimmer active>
                       <Loader content='Loading' />
                      </Dimmer>
                    ) || (<>
                    <h5>Roles:</h5>
                    <Formik 
                      initialValues={{
                        group: list,
                      }}
                      enableReinitialize={true}
                      onSubmit={addRole}
                    >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      setFieldValue,
                      setFieldTouched,
                      submitForm,
                      values,
                      isSubmitting,
                    }) => (
                    <Form onSubmit={handleSubmit}>
                      <Grid columns={2}>
                        <Grid.Row textAlign='left'>
                        <Grid.Column>
                     
                        <FieldArray
                          name='group'
                          render={(arrayHelper) => (
                          <>
                          {(list.length === roleOptions.length) && list.map((type: any, i) => {
                            return (
                              <Wrapper key={i}>
                                <Wrapper>
                                  <Wrapper>
                                  <Field
                                    key={i}
                                    name={`group[${i}]`}
                                    component={Checkbox}
                                    label={`${type.value}`}
                                    onChange={() => {
                                                                    
                                        if (
                                          list[i].checked === true
                                        ) {
                                          setIsAlert(null)
                                          setIsAmount(false)
                                          setFilled(null) 
                                          arrayHelper.replace(
                                            values.group.findIndex(
                                              (e) => e.key === type.key
                                            ), {key: type.key, value: type.value, checked: false}
                                          );
                                          list[values.group.findIndex((e) => e.key === type.key)] = {key: type.key, value: type.value, checked: false}
                                          addRole(list)
                                        } else if(isFilled || list[i].checked === false){
                            
                                          if(type.value === 'commercial'){
                                            setIsAmount(true)
                                          }else{
                                            arrayHelper.replace(
                                              values.group.findIndex(
                                                (e) => e.key === type.key
                                              ), {key: type.key, value: type.value, checked: true}
                                            );
                                            list[values.group.findIndex((e) => e.key === type.key)] = {key: type.key, value: type.value, limit: isFilled, checked: true}                                       
                                            addRole(list)
                                          }
                                         
                                        }else{
                                          setIsAmount(true)
                                          setIsAlert('You should fill with a minimal amount before attempting to select commercial')
                                        }
                                                                       
                                      }}
                                    checked={type.checked}
                                    meta={{
                                      errors,
                                      touched,
                                      setFieldValue,
                                      setFieldTouched,
                                    }}
                                  />
                                  {type.value === 'commercial' && isAmount && (
                                    <>
                                    <Field
                                       key={i+1}
                                      css={{float: 'left'}}
                                      name={`group[3].limit`}
                                      placeholder='choose ...'
                                      component={Dropdown}
                                      options={[
                                        {key: 0, value: 'limited', text: 'Limited'},
                                        {key: 1, value: 'none', text: 'None'}
                                      ]}
                                      value={isFilled ? isFilled : type.limit} 
                                      onChange={(e, {value}) => {                      
                                          e && setFilled(value) 
                                          setIsAlert(null)
                                          list[values.group.findIndex((e) => e.key === type.key)] = {key: type.key, value: type.value, limit: value, checked: true} 
                                          addRole(list)                                                                                     
                                      }}
                                    />
                                    </>
                                  )}
                                  </Wrapper>
                                </Wrapper>
                              </Wrapper>
                              );
                            })}
                          </>
                        )}
                      />
                      {isAlert && (
                        <Alert>{isAlert}</Alert>
                      )}
                      </Grid.Column>
                      </Grid.Row>
                      </Grid>
                 
                    </Form>
                    )}
                    </Formik> 
                    </> 
                    )}                                      
                    </Grid.Column>
                  </Grid>
                </Segment>
                <Tab
                  panes={[
                    {
                      menuItem: 'Permissions',
                      render: () => <GeneralPermissions operator={operator} />,
                    },
                    {
                      menuItem: 'INTRASTAT Countries',
                      render: () => <IntrastatPermissions operator={operator} />,
                    },
                    {
                      menuItem: 'CollectionBox Countries',
                      render: () => <CollectionBoxPermissions operator={operator} />,
                    },
                    operator.vatPermissions && {
                      menuItem: 'VAT Repository',
                      render: () => <VatPermissions operator={operator} />,
                    },
                  ]}
                />
              </>
            )}
          </Wrapper>
        </Main>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    operator: selectors.operators.oneOperatorSelector(state),
    addRule: selectors.operators.addOneOperatorRoleSelector(state),
    loadingHandlerOperator: selectors.operators.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
