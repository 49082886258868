import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    validateUser: false,
    getUsers: false,
    getUser: false,
    deleteUser: false,
    createUser: false,
    updateUser: false,
    resetPassword: false,
    updatePassword: false,
    updatePasswordWithToken: false,
    workmailRenewal: false,
  },
  errors: {
    validateUser: null,
    getUsers: null,
    getUser: null,
    deleteUser: null,
    createUser: null,
    updateUser: null,
    resetPassword: null,
    updatePassword: null,
    updatePasswordWithToken: null,
    workmailRenewal: null,
  },
  loading: {
    validateUser: false,
    getUsers: false,
    getUser: false,
    createUser: false,
    deleteUser: false,
    updateUser: false,
    resetPassword: false,
    updatePassword: false,
    updatePasswordWithToken: false,
    workmailRenewal: false,
  },
  users: null,
  user: null,
  workmailRenewal: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // validate user
    case actionTypes.VALIDATE_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.validateUser = true;
      });

    case actionTypes.VALIDATE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.validateUser = null;
        draft.loading.validateUser = false;
        draft.success.validateUser = true;
      });

    case actionTypes.VALIDATE_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.validateUser = true;
        draft.loading.validateUser = false;
        draft.success.validateUser = false;
        !draft.error.includes("validateUser") &&
          draft.error.push("validateUser");
      });

    // getUsers
    case actionTypes.GET_USERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getUsers = true;
      });

    case actionTypes.GET_USERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getUsers = null;
        draft.loading.getUsers = false;
        draft.success.getUsers = true;
        draft.users = action.payload;
      });

    case actionTypes.GET_USERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getUsers = true;
        draft.loading.getUsers = false;
        draft.success.getUsers = false;
        !draft.error.includes("users") && draft.error.push("users");
      });

    // getUser
    case actionTypes.GET_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.getUser = true;
      });

    case actionTypes.GET_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getUser = null;
        draft.loading.getUser = false;
        draft.success.getUser = true;
        draft.user = action.payload;
      });

    case actionTypes.GET_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.getUser = true;
        draft.loading.getUser = false;
        draft.success.getUser = false;
        !draft.error.includes("user") && draft.error.push("user");
      });

    // deleteUser
    case actionTypes.DELETE_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.deleteUser = true;
      });

    case actionTypes.DELETE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteUser = null;
        draft.loading.deleteUser = false;
        draft.success.deleteUser = true;
      });

    case actionTypes.DELETE_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteUser = true;
        draft.loading.deleteUser = false;
        draft.success.deleteUser = false;
      });

    // createUser
    case actionTypes.CREATE_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.createUser = true;
      });

    case actionTypes.CREATE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createUser = null;
        draft.loading.createUser = false;
        draft.success.createUser = true;
      });

    case actionTypes.CREATE_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.createUser = true;
        draft.loading.createUser = false;
        draft.success.createUser = false;
        !draft.error.includes("createUser") && draft.error.push("createUser");
      });

    // resetPassword
    case actionTypes.RESET_PASSWORD_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.resetPassword = true;
      });

    case actionTypes.RESET_PASSWORD_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.resetPassword = null;
        draft.loading.resetPassword = false;
        draft.success.resetPassword = true;
      });

    case actionTypes.RESET_PASSWORD_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.resetPassword = true;
        draft.loading.resetPassword = false;
        draft.success.resetPassword = false;
        !draft.error.includes("resetPassword") &&
          draft.error.push("resetPassword");
      });

    // update password with token
    case actionTypes.UPDATE_PASSWORD_BY_TOKEN_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.updatePasswordWithToken = true;
      });

    case actionTypes.UPDATE_PASSWORD_BY_TOKEN_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updatePasswordWithToken = null;
        draft.loading.updatePasswordWithToken = false;
        draft.success.updatePasswordWithToken = true;
      });

    case actionTypes.UPDATE_PASSWORD_BY_TOKEN_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.updatePasswordWithToken = true;
        draft.loading.updatePasswordWithToken = false;
        draft.success.updatePasswordWithToken = false;
        !draft.error.includes("updatePasswordWithToken") &&
          draft.error.push("updatePasswordWithToken");
      });

    // update password
    case actionTypes.UPDATE_PASSWORD_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.updatePassword = true;
      });

    case actionTypes.UPDATE_PASSWORD_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updatePassword = null;
        draft.loading.updatePassword = false;
        draft.success.updatePassword = true;
      });

    case actionTypes.UPDATE_PASSWORD_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.updatePassword = true;
        draft.loading.updatePassword = false;
        draft.success.updatePassword = false;
        !draft.error.includes("updatePassword") &&
          draft.error.push("updatePassword");
      });

    // update
    case actionTypes.UPDATE_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateUser = true;
      });

    case actionTypes.UPDATE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateUser = null;
        draft.loading.updateUser = false;
        draft.success.updateUser = true;
      });

    case actionTypes.UPDATE_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateUser = true;
        draft.loading.updateUser = false;
        draft.success.updateUser = false;
        !draft.error.includes("updateUser") && draft.error.push("updateUser");
      });

     // reset workmail Password
     case actionTypes.WORKMAIL_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.workmailRenewal = true;
      });

    case actionTypes.WORKMAIL_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.workmailRenewal = null;
        draft.loading.workmailRenewal = false;
        draft.success.workmailRenewal = true;
        draft.workmailRenewal = action.payload;
      });

    case actionTypes.WORKMAIL_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.workmailRenewal = true;
        draft.loading.workmailRenewal = false;
        draft.success.workmailRenewal = false;
        !draft.error.includes("workmailRenewal") &&
          draft.error.push("workmailRenewal");
      });


    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
