import Dashboard from 'views/Dashboard/Dashboard';
import paths from './paths';

export default [
  {
    private: true,
    path: paths.dashboard,
    component: Dashboard,
    exact: true,
    authorizedRoles: [],
  },
];
