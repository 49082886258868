import actionTypes from 'store/chat/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.READ_CHAT:
        try {
          next({ type: actionTypes.READ_CHAT_LOADING });
          const resp = await request.readChat({...action.payload});
          next({
            type: actionTypes.READ_CHAT_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.READ_CHAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;


      case actionTypes.GET_CHATS:
        try {
          next({ type: actionTypes.GET_CHATS_LOADING });
            const resp = await request.getAllChats({ ...action.payload });
          next({
            type: actionTypes.GET_CHATS_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_CHATS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.POST_CHATS:
        try {
          next({ type: actionTypes.POST_CHATS_LOADING });
            const resp = await request.postChats({ ...action.payload });
          next({
            type: actionTypes.POST_CHATS_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.POST_CHATS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
