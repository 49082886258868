import actionTypes from 'store/customs/clerk/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.LOCK:
        try {
          next({ type: actionTypes.LOCK_LOADING });
          await request.lock(action.payload);
          next({
            type: actionTypes.LOCK_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.LOCK_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UNLOCK:
        try {
          next({ type: actionTypes.UNLOCK_LOADING });
          await request.unlock(action.payload);
          next({
            type: actionTypes.UNLOCK_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UNLOCK_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.CREATE_FILE:
        try {
          next({ type: actionTypes.CREATE_FILE_LOADING });
          const response = await request.createFile(action.payload);
          next({
            type: actionTypes.CREATE_FILE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_FILE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UPDATE_FILE:
        try {
          next({ type: actionTypes.UPDATE_FILE_LOADING });
          await request.updateFile(action.payload);
          next({
            type: actionTypes.UPDATE_FILE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_FILE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ONE_FILE:
        try {
          next({ type: actionTypes.GET_ONE_FILE_LOADING });
          const response = await request.getOneFile(action.payload);
          next({
            type: actionTypes.GET_ONE_FILE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_FILE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_START_SIGNATURE:
        try {
          next({ type: actionTypes.GET_START_SIGNATURE_LOADING });
          const response = await request.getStartSignature(action.payload);
          next({
            type: actionTypes.GET_START_SIGNATURE_SUCCESS,
            payload: response.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_START_SIGNATURE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_TRAILER_LICENSE_PLATE:
        try {
          next({ type: actionTypes.GET_TRAILER_LICENSE_PLATE_LOADING });
          const response = await request.getTrailerLicensePlate(action.payload);

          next({
            type: actionTypes.GET_TRAILER_LICENSE_PLATE_SUCCESS,
            payload:
              response.data.type === 'application/json' ? null : response.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_TRAILER_LICENSE_PLATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.ADD_TRAILER_LICENSE_PLATE:
        try {
          next({ type: actionTypes.ADD_TRAILER_LICENSE_PLATE_LOADING });
          await request.addTrailerLicensePlate(action.payload);
          next({
            type: actionTypes.ADD_TRAILER_LICENSE_PLATE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.ADD_TRAILER_LICENSE_PLATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.DELETE_TRAILER_LICENSE_PLATE:
        try {
          next({ type: actionTypes.DELETE_TRAILER_LICENSE_PLATE_LOADING });
          await request.removeTrailerLicensePlate(action.payload);
          next({
            type: actionTypes.DELETE_TRAILER_LICENSE_PLATE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DELETE_TRAILER_LICENSE_PLATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_PAYMENT_RECEIPT:
        try {
          next({ type: actionTypes.GET_PAYMENT_RECEIPT_LOADING });
          const response = await request.getPaymentReceipt(action.payload);
          next({
            type: actionTypes.GET_PAYMENT_RECEIPT_SUCCESS,
            payload: response.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_PAYMENT_RECEIPT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_END_SIGNATURE:
        try {
          next({ type: actionTypes.GET_END_SIGNATURE_LOADING });
          const response = await request.getEndSignature(action.payload);
          next({
            type: actionTypes.GET_END_SIGNATURE_SUCCESS,
            payload: response.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_END_SIGNATURE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_DECLARATION_IN_PROGRESS:
        try {
          next({ type: actionTypes.GET_DECLARATION_IN_PROGRESS_LOADING });
          const response = await request.getDeclarationInProgress(
            action.payload
          );
          next({
            type: actionTypes.GET_DECLARATION_IN_PROGRESS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_DECLARATION_IN_PROGRESS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ISSUE:
        try {
          next({ type: actionTypes.GET_ISSUE_LOADING });
          const res = await request.getIssue(action.payload);
          next({
            type: actionTypes.GET_ISSUE_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ISSUE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ISSUE_TIMES:
        try {
          next({ type: actionTypes.GET_ISSUE_TIMES_LOADING });
          const res = await request.getIssueTimes(action.payload);
          next({
            type: actionTypes.GET_ISSUE_TIMES_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ISSUE_TIMES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.ADD_ISSUE_TIME:
        try {
          next({ type: actionTypes.ADD_ISSUE_TIME_LOADING });
          await request.addIssueTime(action.payload);
          next({
            type: actionTypes.ADD_ISSUE_TIME_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.ADD_ISSUE_TIME_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UPDATE_ISSUE_TIME:
        try {
          next({ type: actionTypes.UPDATE_ISSUE_TIME_LOADING });
          await request.updateIssueTime(action.payload);
          next({
            type: actionTypes.UPDATE_ISSUE_TIME_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ISSUE_TIME_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.DELETE_ISSUE_TIME:
        try {
          next({ type: actionTypes.DELETE_ISSUE_TIME_LOADING });
          await request.removeIssueTime(action.payload);
          next({
            type: actionTypes.DELETE_ISSUE_TIME_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DELETE_ISSUE_TIME_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.DOWNLOAD_PICTURES_ISSUES_ZIP:
        try {
          next({ type: actionTypes.DOWNLOAD_PICTURES_ISSUES_ZIP_LOADING });
          const response = await request.downloadPicturesIssuesZIP(
            action.payload
          );
          next({
            type: actionTypes.DOWNLOAD_PICTURES_ISSUES_ZIP_SUCCESS,
            payload: response.data,
          });
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_PICTURES_ISSUES_ZIP_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_SUMMARY:
        try {
          next({ type: actionTypes.GET_SUMMARY_LOADING });
          const res = await request.getSummary(action.payload);
          next({
            type: actionTypes.GET_SUMMARY_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_SUMMARY_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ALL_FILES:
        try {
          next({ type: actionTypes.GET_ALL_FILES_LOADING });
          const res = await request.getAllFiles(action.payload);
          next({
            type: actionTypes.GET_ALL_FILES_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_FILES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ALL_FILES_BY_FILTERS:
        try {
          next({ type: actionTypes.GET_ALL_FILES_BY_FILTERS_LOADING });
          const res = await request.getAllFilesByFilters(action.payload);
          next({
            type: actionTypes.GET_ALL_FILES_BY_FILTERS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_FILES_BY_FILTERS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_ISSUE:
        try {
          next({ type: actionTypes.UPDATE_ISSUE_LOADING });
          await request.updateIssue(action.payload);
          next({
            type: actionTypes.UPDATE_ISSUE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ISSUE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.ADD_ISSUE_PICTURES:
        try {
          next({ type: actionTypes.ADD_ISSUE_PICTURES_LOADING });
          await request.addIssuePictures(action.payload);
          next({
            type: actionTypes.ADD_ISSUE_PICTURES_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.ADD_ISSUE_PICTURES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DELETE_ISSUE_PICTURE:
        try {
          next({ type: actionTypes.DELETE_ISSUE_PICTURE_LOADING });
          await request.deleteIssuePicture(action.payload);
          next({
            type: actionTypes.DELETE_ISSUE_PICTURE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DELETE_ISSUE_PICTURE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_BILLINGS:
        try {
          next({ type: actionTypes.GET_BILLINGS_LOADING });
          const res = await request.getBillings();
          next({
            type: actionTypes.GET_BILLINGS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_BILLINGS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_FILE_HAS_BILLINGS:
        try {
          next({ type: actionTypes.GET_FILE_HAS_BILLINGS_LOADING });
          const res = await request.getFileHasBillings(action.payload);
          next({
            type: actionTypes.GET_FILE_HAS_BILLINGS_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_FILE_HAS_BILLINGS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_CD_HAS_BILLINGS:
          try {
            next({ type: actionTypes.GET_CD_HAS_BILLINGS_LOADING });
            const res = await request.getCDHasBillings(action.payload);
            next({
              type: actionTypes.GET_CD_HAS_BILLINGS_SUCCESS,
              payload: res.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_CD_HAS_BILLINGS_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
          break;

      case actionTypes.ADD_INVOICING_LINE:
        try {
          next({ type: actionTypes.ADD_INVOICING_LINE_LOADING });
          await request.addInvoicingLine(action.payload);
          next({
            type: actionTypes.ADD_INVOICING_LINE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.ADD_INVOICING_LINE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_INVOICING_LINE:
        try {
          next({ type: actionTypes.UPDATE_INVOICING_LINE_LOADING });
          await request.updateInvoicingLine(action.payload);
          next({
            type: actionTypes.UPDATE_INVOICING_LINE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_INVOICING_LINE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.REMOVE_INVOICING_LINE:
        try {
          next({ type: actionTypes.REMOVE_INVOICING_LINE_LOADING });
          await request.removeInvoicingLine(action.payload);
          next({
            type: actionTypes.REMOVE_INVOICING_LINE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.REMOVE_INVOICING_LINE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.VALIDATE_FILE:
        try {
          next({ type: actionTypes.VALIDATE_FILE_LOADING });
          await request.validateFile(action.payload);
          next({
            type: actionTypes.VALIDATE_FILE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.VALIDATE_FILE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_INVOICING_STEP:
        try {
          next({ type: actionTypes.UPDATE_INVOICING_STEP_LOADING });
          await request.updateInvoicingStep(action.payload);
          next({
            type: actionTypes.UPDATE_INVOICING_STEP_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_INVOICING_STEP_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.VALIDATE_FILE_STATE:
        try {
          next({ type: actionTypes.VALIDATE_FILE_STATE_LOADING });
          await request.validateFileState(action.payload);
          next({
            type: actionTypes.VALIDATE_FILE_STATE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.VALIDATE_FILE_STATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.CREATE_CONTROL_PDF:
        try {
          next({ type: actionTypes.CREATE_CONTROL_PDF_LOADING });
          const res = await request.createControlPDF(action.payload);
          next({
            type: actionTypes.CREATE_CONTROL_PDF_SUCCESS,
            payload: res.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_CONTROL_PDF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.DOWNLOAD_PDF:
          try {
            next({ type: actionTypes.DOWNLOAD_PDF_LOADING });
            const response = await request.getPdfFile(action.payload);
            saveAs(
              response.data,
              response.headers['content-disposition']
                .split('filename=')[1]
                .replace(/"/g, '')
            );
            next({
              type: actionTypes.DOWNLOAD_PDF_SUCCESS,
            });
          } catch (error) {
            next({
              type: actionTypes.DOWNLOAD_PDF_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
        break;

        case actionTypes.CREATE_POCHETTE_PDF:
          try {
            next({ type: actionTypes.CREATE_POCHETTE_PDF_LOADING });
            const response = await request.createPochettePDF(action.payload);
            saveAs(
              response.data,
              response.headers['content-disposition']
                .split('filename=')[1]
                .replace(/"/g, '')
            );
            next({
              type: actionTypes.CREATE_POCHETTE_PDF_SUCCESS,
            });
          } catch (error) {
            next({
              type: actionTypes.CREATE_POCHETTE_PDF_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
        break;

      default:
        next(action);
        break;
    }
  };
}
