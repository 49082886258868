export default {
  RESET: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.RESET',
  REFRESH_ERROR: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.REFRESH_ERROR',

  PACKAGES: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.PACKAGES',
  PACKAGES_LOADING: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.PACKAGES_LOADING',
  PACKAGES_SUCCESS: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.PACKAGES_SUCCESS',
  PACKAGES_ERROR: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.PACKAGES_ERROR',

  GET_BASIC: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.GET_BASIC',
  GET_BASIC_LOADING: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.GET_BASIC_LOADING',
  GET_BASIC_SUCCESS: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.GET_BASIC_SUCCESS',
  GET_BASIC_ERROR: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.GET_BASIC_ERROR',


  SEND_FORM: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.SEND_FORM',
  SEND_FORM_LOADING: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.SEND_FORM_LOADING',
  SEND_FORM_SUCCESS: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.SEND_FORM_SUCCESS',
  SEND_FORM_ERROR: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.SEND_FORM_ERROR',

  GET_FORM_SCHEMAS: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.GET_FORM_SCHEMAS',
  GET_FORM_SCHEMAS_LOADING:
    'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.GET_FORM_SCHEMAS_LOADING',
  GET_FORM_SCHEMAS_SUCCESS:
    'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.GET_FORM_SCHEMAS_SUCCESS',
  GET_FORM_SCHEMAS_ERROR: 'MY_ASD.INTERNAL_TOOLS.COMMERCIAL.GET_FORM_SCHEMAS_ERROR',
};
