import React, { FunctionComponent } from 'react';

import { SpacerStyled } from './SpacerStyles';

type SpacerProps = {
  height?: string;
};

const Spacer: FunctionComponent<SpacerProps> = ({ height }) => {
  return <SpacerStyled height={height || '10px'} />;
};

export default Spacer;
