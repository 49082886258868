import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Yup from 'yup';
import { Container, Divider, Header, Form, Modal } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import { actions, selectors } from 'store';
import { ContainerPadded } from './styles';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import NewCompanyModal from 'views/Companies/New/NewCompanyModal/NewCompanyModal';

const AddContact = ({
  companiesNotInContact,
  loadingHandlerContact,
  errorHandlerContact,
  fromOtherComponents,
  dispatch,
  companyId,
  open,
  setRunningAction,
  handleClose,
}) => {
  const [
    companiesNotInContactList,
    setcompaniesNotInContactList,
  ]: any = useState([]);

  const [isCompanyExists, setisCompanyExists] = useState(true);

  useEffect(() => {
    dispatch(actions.data.getAllCountries());
    dispatch(
      actions.contact.getCompaniesNotInContact({
        params: { companyId: companyId },
      })
    );
    return () => {
      dispatch(actions.data.reset(['allCountries', 'error']));
      dispatch(actions.contact.reset(['companiesNotInContact', 'error']));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    if(companiesNotInContact){
      setcompaniesNotInContactList(
        companiesNotInContact.map((company, index) => {
          return {
            key: index,
            value: company.id,
            text: company.name,
          };
        })
      );
    }
  }, [companiesNotInContact]);

  const save = (values) => {
    dispatch(
      actions.contact.addContact({
        params: { companyId: companyId, contactId: values.contact },
        ...fromOtherComponents && {
          data: {
            fromOtherComponents: fromOtherComponents
          }
        }}
      )
    );
    !fromOtherComponents && setRunningAction('addContact')
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      {isCompanyExists && companiesNotInContactList && (
        <ContainerPadded textAlign='center'>
          <Container>
            <Header size='huge'>Add a contact</Header>
            <Divider hidden />
            <Formik
              initialValues={{
                contact: null,
              }}
              onSubmit={save}
              validationSchema={Yup.object().shape({
                contact: Yup.number().required('Required'),
              })}
            >
              {({
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                values,
              }: any) => (
                <Form>
                  <>
                    <label>
                      <MandatoryFieldLabel content='Company list' />
                    </label>
                    <Form.Field>
                      <Field
                        placeholder='Choose company'
                        name={`contact`}
                        options={companiesNotInContactList}
                        component={Dropdown}
                        loading={loadingHandlerContact}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                        uniqueError={errorHandlerContact.includes(
                          'companiesNotInContact'
                        )}
                      />
                    </Form.Field>

                    <Button
                      type='button'
                      content="The company isn't registered"
                      onClick={() => setisCompanyExists(false)}
                      position='start'
                      margin='0px'
                    />
                    <Divider hidden />
                    <Button
                      disabled={
                        companiesNotInContact &&
                        companiesNotInContact.length === 0
                      }
                      type='button'
                      content='Add contact'
                      onClick={() => {
                        handleSubmit();
                      }}
                      margin='0px'
                    />
                  </>
                </Form>
              )}
            </Formik>
          </Container>
        </ContainerPadded>
      )}
      {!isCompanyExists && (
        <NewCompanyModal
          open={!isCompanyExists}
          handleClose={() => {
            setisCompanyExists(false);
            handleClose();
          }}
          companyId={companyId}
          addContact
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    companiesNotInContact: selectors.contact.companiesNotInContactSelector(state),
    errorHandlerContact: selectors.contact.errorSelector(state),
    loadingHandlerContact: selectors.contact.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(AddContact);
