import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Divider,
  Grid,
  Header,
  Tab,
  Menu,
  Label
} from 'semantic-ui-react';

import ButtonBack from 'components/ButtonBack/ButtonBack';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import AccisesList from './AccisesList';
import { shortDate } from 'services/helper/dateFormater';
import DownloadFilesModal from './DownloadFilesModal/downloadFilesModal';
import Button from 'components/Button';

const Accises = ({
  dispatch,
  match,
  history,
  user,
  companySelected,
  accises,
  allAccises,
  userLoading,
  accisesLoading,
  allAccisesLoading
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected; //companySelected 

  const [downloadFilesModal, setdownloadFilesModal] = useState(false);
  const [data, setData] = useState(null);
  const [AllAccises, setAllAccises] = useState(null);

  useEffect(() => {
    dispatch(
      actions.customsDeclaration.getAccises({
        params: { companyId },
      })
    );
    dispatch(
      actions.customsDeclaration.getAllAccises({
        params: { companyId },
      })
    );

    dispatch(actions.socket.subscribeAccises(companyId));

    return () => {
      dispatch(
        actions.customsDeclaration.reset([
          'getAccises',
          'getAllAccises',
          'error',
        ])
      );
      dispatch(actions.socket.unsubscribeAccises(companyId));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    accises && setData(accises)
    allAccises && setAllAccises(allAccises)
  }, [accises, allAccises]);

  const getCurrentWeek = () => {
    const cDay = new Date();
    const firstday = cDay && new Date(cDay.setDate(cDay.getDate() - cDay.getDay()+1));
    const lastday = cDay && new Date(cDay.setDate(cDay.getDate() - cDay.getDay()+7));
    return { 
      from: shortDate(firstday), 
      to: shortDate(lastday)
    }
  }

  const accisesForWeek = (data) => {
    dispatch(
      actions.customsDeclaration.extractDocuments({
        params: {
          companyId,
          from: data.startDate,
          to: data.endDate,
        },
        data: {
          withDocuments: true,
          docType: undefined,
          customsType: 'accises',
          attributes: [
            'id',
            'Company_Id',
            'clientReference',
            'departure',
            'arrival',
            'startDate',
            'endDate',
            'createdAt',
          ],
        },
      })
    );
  };

  const handleClose = () => {
    setdownloadFilesModal(false)
  }

 
  return (
    <Wrapper display='flex' justifyContent='center'>
    {((!accises || !allAccises || userLoading) && <Loader />) || (
      <Wrapper height='100%' width='80%' overflow='auto'>
        <Divider hidden />
        <Wrapper display='flex'>
          <ButtonBack history={history} />
        </Wrapper>
        <Divider hidden />
        <Header size='huge' textAlign='center'>Accises </Header>
        <Wrapper display='flex' justifyContent='flex-end !important'>
          <Button
            labelPosition='right'
            position='left'
            icon='download'
            type='button'
            content='Download files'
            onClick={() => setdownloadFilesModal(true)}
          />
        </ Wrapper>
        <Wrapper height='100%' overflow='auto'>
        {downloadFilesModal && (
          <DownloadFilesModal
            accisesForWeek={accisesForWeek}
            open={downloadFilesModal}
            handleClose={handleClose}
            match={match}
          />
        )}
        {accises && (
        <Tab panes={[
            {
              menuItem: (
                <Menu.Item key={0}>
                  Envois hebdomadaire
                  <Label>{data && data.length}</Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Grid>
                    <Grid.Column>
                      <Header textAlign='center' size='small'>
                        Accises weekly - from: {getCurrentWeek().from} to: {getCurrentWeek().to} 
                      </Header>
                        <AccisesList
                          key={0}
                          data={data}
                          companyId={companyId}
                          loadingHandlerAccises={accisesLoading}
                        />                       
                    </Grid.Column>                     
                  </Grid>
                </Tab.Pane>
              ),
            },
            {
              menuItem: (
                <Menu.Item key={1}>
                  Tableau récapitulatif 
                  <Label>{AllAccises && AllAccises.length}</Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Grid>
                    <Grid.Column>
                      <Header textAlign='center' size='small'>
                        Accises since the beginning
                      </Header>
                      <AccisesList
                        key={0}                                               
                        companyId={companyId}
                        data={AllAccises}
                        loadingHandlerAccises={allAccisesLoading}                        
                      />                       
                    </Grid.Column>                     
                  </Grid>
                </Tab.Pane>
              ),
            }
        ]}/>
      )}
      <Wrapper>
      <Divider hidden />
      </Wrapper>
      </Wrapper>
  </Wrapper>
  )}
  </Wrapper> 
  )
}
const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    userLoading: selectors.auth.loadingSelector(state),
    companySelected: selectors.auth.companySelected(state),
    accises: selectors.customsDeclaration.getAccisesSelector(state),
    accisesLoading: selectors.customsDeclaration.getAccisesLoadingSelector(state),
    allAccises: selectors.customsDeclaration.getAllAccisesSelector(state),
    allAccisesLoading: selectors.customsDeclaration.getAllAccisesLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Accises);
