import React from 'react';

const FollowersList = ({ users, visibleNumber }: FollowersListI) => {
  return (
    <>
      {users.map(
        (e, i) =>
          i < visibleNumber && <li key={i}>{`${e.lastName}-${e.firstName}`}</li>
      )}
      {users.length > visibleNumber && (
        <p>... and {users.length - visibleNumber} other(s)</p>
      )}
    </>
  );
};

interface FollowersListI {
  visibleNumber: number;
  users: Array<{
    lastName: string;
    firstName: string;
  }>;
}

export default FollowersList;
