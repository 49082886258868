import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button as Btn, Container, Divider, Form } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import Button from 'components/Button';
import { actions, selectors } from 'store';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Input } from 'components/Form';
import Dropdown from 'components/Dropdown';
import ErrorHandler from 'components/ErrorHandler';
import { agencySchema } from 'interfaces/formSchema';

const Index = ({
  dispatch,
  history,
  countries,
  intl,
  errorHandler,
  errorHandlerData,
  errorHandlerAgency,
  loadingHandlerAgency,
}) => {
  const [runningAction, setRunningAction] = useState(null);
  const [countriesList, setcountriesList]: any = useState([]);

  useEffect(() => {
    if (runningAction !== null && !loadingHandlerAgency) {
      if (!errorHandlerAgency.includes(runningAction)) {
        history.push('./');
      }
      setRunningAction(null);
    }
  }, [loadingHandlerAgency]);

  useEffect(() => {
    dispatch(actions.data.getAllCountries());

    return () => {
      dispatch(actions.data.reset(['allCountries', 'error']));
    };
  }, [dispatch]);

  useEffect(() => {
    if (countries !== null) {
      setcountriesList(
        countries.map((country, index) => {
          return {
            key: index,
            value: country.iso_code,
            text: country.english_name,
            flag: country.iso_code.toLowerCase(),
          };
        })
      );
    }
  }, [countries]);

  const save = (values) => {
    dispatch(actions.agency.reset(['error']));
    dispatch(
      actions.agency.create({
        data: {
          name: values.name,
          vat: values.vat,
          phoneNumber: values.phoneNumber,
          country: values.country,
          city: values.city,
          address1: values.address1,
          address2: values.address2,
          zipCode: values.zipCode,
        },
      })
    );
    setRunningAction('create');
  };

  return (
    <Container>
      {(loadingHandlerAgency === true && <Loader />) || (
        <>
          <Btn.Group>
            <Button
              floated='left'
              labelPosition='left'
              icon='angle left'
              position='flex-end'
              type='button'
              content='Back'
              margin='5px'
              onClick={() => history.push('/agencies')}
            />
          </Btn.Group>

          <Container textAlign='center'>
            {(countries === null && <Loader />) || (
              <Container>
                <Divider hidden />
                <Wrapper padding='0 25%'>
                  <Formik
                    initialValues={{
                      name: '',
                      vat: '',
                      phoneNumber: '',
                      country: '',
                      city: '',
                      address1: '',
                      address2: '',
                      zipCode: '',
                    }}
                    onSubmit={save}
                    validationSchema={agencySchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched,
                    }) => (
                      <Form onSubmit={handleSubmit} width='15px'>
                        {errorHandler && (
                          <>
                            <ErrorHandler
                              header='Fail to send request.'
                              message='Try to refresh the page and contact an administrator if the error persists.'
                            />
                          </>
                        )}
                        <Wrapper margin='10px'>
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <Field
                                mandatory
                                label='Agency Name'
                                placeholder='Agency Name'
                                name='name'
                                type='text'
                                component={Input}
                                meta={{ errors, touched }}
                                margin='20px'
                              />
                            </Form.Field>
                            <Form.Field>
                              <Field
                                mandatory
                                label='VAT'
                                placeholder='VAT'
                                name='vat'
                                type='text'
                                component={Input}
                                meta={{ errors, touched }}
                                margin='20px'
                              />
                            </Form.Field>
                          </Form.Group>
                        </Wrapper>
                        <Wrapper margin='10px'>
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <Field
                                mandatory
                                placeholder='Choose country'
                                label='Country'
                                name='country'
                                options={countriesList}
                                component={Dropdown}
                                meta={{
                                  errors,
                                  touched,
                                  setFieldValue,
                                  setFieldTouched,
                                }}
                                uniqueError={errorHandlerData.includes(
                                  'allCountries'
                                )}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Field
                                mandatory
                                label='City'
                                placeholder='City'
                                name='city'
                                type='text'
                                component={Input}
                                meta={{ errors, touched }}
                                margin='20px'
                              />
                            </Form.Field>
                          </Form.Group>
                        </Wrapper>

                        <Wrapper margin='10px'>
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <Field
                                mandatory
                                label='Address'
                                placeholder='Address'
                                name='address1'
                                type='text'
                                component={Input}
                                meta={{ errors, touched }}
                                margin='20px'
                              />
                            </Form.Field>
                            <Form.Field>
                              <Field
                                mandatory
                                label='Zip code'
                                placeholder='Zip code'
                                name='zipCode'
                                type='text'
                                component={Input}
                                meta={{ errors, touched }}
                                margin='20px'
                              />
                            </Form.Field>
                          </Form.Group>
                        </Wrapper>
                        <Wrapper margin='10px'>
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <Field
                                label='Address 2'
                                placeholder='Address 2'
                                name='address2'
                                type='text'
                                component={Input}
                                meta={{ errors, touched }}
                                margin='20px'
                              />
                            </Form.Field>
                            <Form.Field>
                              <Field
                                mandatory
                                label='Phone number'
                                placeholder='Phone number'
                                name='phoneNumber'
                                type='text'
                                component={Input}
                                meta={{ errors, touched }}
                                margin='20px'
                              />
                            </Form.Field>
                          </Form.Group>
                        </Wrapper>
                        <Button
                          disabled={isSubmitting}
                          type='submit'
                          content={intl.formatMessage({
                            id: 'button.save',
                          })}
                          margin='20px'
                          position='center'
                          color='blue'
                        />
                      </Form>
                    )}
                  </Formik>
                </Wrapper>
              </Container>
            )}
          </Container>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.data.allCountriesSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    errorHandlerAgency: selectors.agency.errorSelector(state),
    loadingHandlerAgency: selectors.agency.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
