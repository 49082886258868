import styled from '@emotion/styled';

export const FloatingCardReloadStyled = styled.div(({ item }: FloatingCardReloadStyledProps) => ({
  backgroundColor: "#dda80b !important",
  borderRadius: '15px',
  maxWidth: "400px",
  height: "88px",
  width: "100%",
  zIndex: 2,
  transition: 'box-shadow 0.3s ease 0.05s, transform 0.3s ease',
  padding: "10px 5px 10px 20px",
  color: "#db2828!important",
  cursor: "pointer",
  overflow: "hidden",

  '&&&:hover': {

    '&:before': {
      opacity: '100%',
      transition: 'opacity 0.3s ease',
    },
  },

  '&.level-3': {
    '&:hover': {
      '&:before': {
        boxShadow: "0 0 80px"
      }
    }
  },

}));


interface FloatingCardReloadStyledProps {
  item?: any;
  height?: string;
}
