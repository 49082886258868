import _ from 'lodash';
import { createSelector } from 'reselect';

const createErrorsSelector = (selectors) => {
  createSelector(
    ...selectors,
    // @ts-ignore
    (...errors) => {
      return errors.filter((error) => !_.isNil(error));
    }
  );
};

const createLoadingSelector = (selectors) =>
  createSelector(
    ...selectors,
    // @ts-ignore
    (...loadings) => {
      return loadings.includes(true);
    }
  );

const createSuccessSelector = (selectors) =>
  createSelector(
    ...selectors,
    // @ts-ignore
    (...successes) => {
      return successes.includes(true);
    }
  );

export default {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
};
