import { selectors as authSelectors } from './auth';
import { selectors as dataSelectors } from './data';
import { selectors as statusSelectors } from './status';
import { selectors as fiscalDeclarationSelectors } from './fiscal/declaration';
import { selectors as fiscalOssSelectors } from './fiscal/oss';
import { selectors as fiscalVatSelectors } from './fiscal/vat';
import { selectors as quickfixesSelectors } from './fiscal/quickFixes';
import { selectors as collectionboxSelectors } from './fiscal/collectionBox';
import { selectors as quickfixesTokenSelectors } from './fiscal/quickFixes/token';
import { selectors as customsDeclarationSelectors } from './customs/declaration';
import { selectors as customsStaticticSelectors } from './customs/statistiques';
import { selectors as customsClerkSelectors } from './customs/clerk';
import { selectors as intrastatSelectors } from './intrastat';
import { selectors as chatSelectors } from './chat';
import { selectors as socketSelectors } from './socket';
import { selectors as userSelectors } from './user';
import { selectors as serviceSelectors } from './service';
import { selectors as adminSelectors } from './admin';
import { selectors as companySelectors } from './company';
import { selectors as contactSelectors } from './company/contact';
import { selectors as companyUserSelectors } from './company/user';
import { selectors as companyDriverSelectors } from './company/driver';
import { selectors as companyKeyInfoSelectors } from './company/keyinfo';
import { selectors as companyClerksPricingSelectors } from './company/clerksPricing';
import { selectors as companyIntrastatSettingsSelectors } from './company/settings/intrastat';
import { selectors as companyCollectionBoxSettingsSelectors } from './company/settings/collectionbox';
import { selectors as companyInvoiceSelectors } from './company/invoice';
import { selectors as vatSelectors } from './company/vat';
import { selectors as addressSelectors } from './company/address';
import { selectors as companyPaymentSelectors } from './company/payment';
import { selectors as companyByTokenSelectors } from './company/token';
import { selectors as messageSelector } from './message';
import { selectors as agencySelector } from './agencies';
import { selectors as paymentSelector } from './payment';
import { selectors as contactRequestSelector } from './contactRequest';
import { selectors as ssrRequestSelector } from './internalTools/ssrRequest';
import { selectors as commercialSelector } from './internalTools/commercial';
import { selectors as notificationSelector } from './notifications';
import { selectors as operatorSelector } from './operators';
import { selectors as newsSelector } from './news';
import { selectors as configurationsSelector } from './company/configurations';

export default {
  auth: authSelectors,
  fiscalDeclaration: fiscalDeclarationSelectors,
  fiscalVat: fiscalVatSelectors,
  collectionbox: collectionboxSelectors,
  fiscalOss: fiscalOssSelectors,
  quickfixes: quickfixesSelectors,
  quickfixesToken: quickfixesTokenSelectors,
  customsDeclaration: customsDeclarationSelectors,
  customsStatistics: customsStaticticSelectors,
  customsClerk: customsClerkSelectors,
  intrastat: intrastatSelectors,
  chat: chatSelectors,
  data: dataSelectors,
  socket: socketSelectors,
  user: userSelectors,
  service: serviceSelectors,
  admin: adminSelectors,
  company: companySelectors,
  contact: contactSelectors,
  companyUser: companyUserSelectors,
  companyDriver: companyDriverSelectors,
  companyKeyInfo: companyKeyInfoSelectors,
  companyClerksPricing: companyClerksPricingSelectors,
  companyIntrastatSettings: companyIntrastatSettingsSelectors,
  companyCollectionBoxSettings: companyCollectionBoxSettingsSelectors,
  companyInvoice: companyInvoiceSelectors,
  vat: vatSelectors,
  address: addressSelectors,
  companyByToken: companyByTokenSelectors,
  message: messageSelector,
  agency: agencySelector,
  payment: paymentSelector,
  companyPayment: companyPaymentSelectors,
  contactRequest: contactRequestSelector,
  ssrRequest: ssrRequestSelector,
  commercial: commercialSelector,
  notifications: notificationSelector,
  operators: operatorSelector,
  news: newsSelector,
  status: statusSelectors,
  configurations: configurationsSelector,
};
