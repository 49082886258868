import actionTypes from 'store/company/user/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.ADD_USER:
        try {
          next({ type: actionTypes.ADD_USER_LOADING });
          await request.addUser({ ...action.payload });
          next({
            type: actionTypes.ADD_USER_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.ADD_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.REMOVE_USER:
        try {
          next({ type: actionTypes.REMOVE_USER_LOADING });
          await request.removeUser({ ...action.payload });
          next({
            type: actionTypes.REMOVE_USER_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.REMOVE_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UPDATE_USER:
        try {
          next({ type: actionTypes.UPDATE_USER_LOADING });
          await request.updateUser({ ...action.payload });
          next({
            type: actionTypes.UPDATE_USER_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UPDATE_USER_PERMISSIONS:
        try {
          next({ type: actionTypes.UPDATE_USER_PERMISSIONS_LOADING });
          await request.updateUserPermissions({ ...action.payload });
          next({
            type: actionTypes.UPDATE_USER_PERMISSIONS_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_USER_PERMISSIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_USER_PERMISSIONS:
        try {
          next({ type: actionTypes.GET_USER_PERMISSIONS_LOADING });

          const response = await request.getUserPermissions({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_USER_PERMISSIONS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_USER_PERMISSIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_USER_COMPANIES:
        try {
          next({ type: actionTypes.GET_USER_COMPANIES_LOADING });
          const response = await request.getUserCompanies({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_USER_COMPANIES_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_USER_COMPANIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ONE_USER:
        try {
          next({ type: actionTypes.GET_ONE_USER_LOADING });
          const response = await request.getOneUser({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_ONE_USER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ALL_USERS:
        try {
          next({ type: actionTypes.GET_ALL_USERS_LOADING });
          const response = await request.getAllUsers({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_ALL_USERS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_USERS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
