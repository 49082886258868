import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const pulse = keyframes({
  '0%': { boxShadow: '0 0 0 0 rgba(33,131,221, 0.4)' },
  '70%': { boxShadow: '0 0 0 15px rgba(33,131,221, 0)' },
  '100%': { boxShadow: '0 0 0 0 rgba(33,131,221, 0)' },
});

const nextStep = keyframes({
  '0%': { width: '0%' },
  '100%': { width: '100%' },
});

export const ProgressBar = styled.div(() => ({
  position: 'relative',
  display: 'flex',
}));

export const Progression = styled.div(() => ({
  position: 'absolute',
  top: '13px',
  width: '100%',
  height: '5px',
  backgroundColor: 'rgba(211, 211, 211, 0.6)',
  zIndex: -1,
  borderRadius: '10px',
}));

const pulseAnimation = (props) => ({
  ...(props.state === 'active' && { animation: `${pulse} 1.5s infinite` }),
});
const nextStepAnimation = (props) => ({
  ...(props.state === 'complete' && {
    animation: `1s linear ${props.keyStep}s ${nextStep};`,
    animationFillMode: `forwards;`,
  }),
});

export const Step = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  &:last-child::after {
    display: none;
  }

  &:before {
    content: '';
    cursor: ${(props) => props.cliquable && 'pointer'};
    display: flex;
    margin: 0 auto;
    margin-bottom: 0;
    width: 30px;
    height: 30px;
    background: ${(props) =>
      props.state === 'complete'
        ? '#009900'
        : props.state === 'active'
        ? '#ffac44'
        : 'white'};
    border: 2px solid
      ${(props) =>
        props.state === 'complete' || props.state === 'active'
          ? '#f8f8f8'
          : 'grey'};
    border-radius: 100%;
    color: white;
    transition: background 1s linear;
    ${pulseAnimation};
  }

  &:after {
    content: '';
    position: absolute;
    top: 13px;
    left: 50%;
    width: 0%;
    transition: width 1s ease-in;
    height: 5px;
    background: ${(props) => (props.state === 'complete' ? '#2183dd' : 'grey')};
    z-index: -1;
    ${nextStepAnimation}
  }
`;
