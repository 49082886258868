import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  const commercialApi = routesApi.internalTools.commercial;
  return {
    sendForm(data: dataReqI) {
      return api.request({
        method: commercialApi.sendForm.method,
        route: commercialApi.sendForm.path,
        data: data.data,
      });
    },
    packages() {
      return api.request({
        method: commercialApi.packages.method,
        route: commercialApi.packages.path,
      });
    },
    getBasic(data: dataReqI) {
      return api.request({
        method: commercialApi.basic.method,
        route: commercialApi.basic.path,
        query: data.query,
        data: data.data,
      });
    },
    getFormSchemas() {
      return api.request({
        method: commercialApi.getFormSchemas.method,
        route: commercialApi.getFormSchemas.path,
      });
    },
  };
};
