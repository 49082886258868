import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Dropdown, Divider } from 'semantic-ui-react';
import { selectors, actions } from 'store';
import { paths } from 'routes/fiscal/kyanite';

import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import ClickableText from 'components/ClickableText';
import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import Payzen from 'components/Payzen';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import TextCustom from 'components/core/TextCustom/TextCustom';
import UnableToSubscribe from 'components/UnableToSubscribe';
import services from 'config/services.json';

const Index = ({
  history,
  match,
  dispatch,
  productOfServiceSub,
  createSubscriptionPaymentFormToken,
  serviceLoading,
  companySelected,
  hasSubscribedTo,
  unpaidInvoices,
}) => {
  const [offer, setOffer] = useState(null);
  const [termsOfService, setTermsOfService] = useState(false);
  const [createPaymentForm, setCreatePaymentForm] = useState(false);

  useEffect(() => {
    hasSubscribedTo &&
      !hasSubscribedTo.pause &&
      history.push(services.kyanite.path);

    setCreatePaymentForm(false);

    dispatch(
      actions.companyInvoice.getUnpaid({
        params: {
          companyId: companySelected,
        },
      })
    );

    return () => {
      dispatch(actions.services.reset(['productsOfServiceSub', 'error']));
      dispatch(actions.companyInvoice.reset(['unpaidInvoices', 'error']));
      dispatch(actions.company.reset(['subscribedServices', 'error']));
      dispatch(
        actions.payment.reset(['createSubscriptionPaymentFormToken', 'error'])
      );
    };
  }, [dispatch, companySelected, hasSubscribedTo, history]);

  useEffect(() => {
    if (unpaidInvoices && unpaidInvoices.length === 0) {
      dispatch(
        actions.services.getProductOfServiceSub({
          params: {
            serviceName: match.params.service,
          },
        })
      );
    }
  }, [unpaidInvoices, dispatch, match]);

  useEffect(() => {
    companySelected &&
      dispatch(
        actions.company.hasSubscribedTo({
          params: {
            companyId: companySelected,
            serviceName: match.params.service,
          },
        })
      );
  }, [companySelected, dispatch, match]);

  useEffect(() => {
    createSubscriptionPaymentFormToken && setCreatePaymentForm(true);
  }, [createSubscriptionPaymentFormToken]);

  const handleCloseCreatePayment = () => {
    setCreatePaymentForm(false);
    dispatch(actions.payment.reset(['createSubscriptionPaymentFormToken']));
    history.push(paths.quickFixesRoutes);
    //history.push('/');
  };

  const handleChangeOffer = (selectedOffer) => {
    setCreatePaymentForm(false);
    dispatch(
      actions.payment.reset(['createSubscriptionPaymentFormToken', 'error'])
    );
    setOffer(productOfServiceSub.find((offer) => offer.name === selectedOffer));
  };

  const handleSubscribe = () => {
    dispatch(
      actions.payment.createSubscriptionPayment({
        params: { companyId: companySelected },
        data: {
          service: match.params.service,
          product: offer.id,
        },
      })
    );
  };

  return (
    <>
      {(!companySelected && <h1>You must select a company</h1>) ||
        (companySelected && hasSubscribedTo && (
          <h1>You have already subscribed to this service</h1>
        )) ||
        (companySelected && unpaidInvoices && unpaidInvoices.length > 0 && (
          <UnableToSubscribe
            history={history}
            companySelected={companySelected}
          />
        )) || (
          <Container fluid>
            <Wrapper
              margin='50px 0 100px'
              textAlign='center'
              childrenDisplay='inline'
              childrenVerticalAlign='middle'
            >
              <ImageCustom
                src={`${process.env.REACT_APP_BACKEND}/${services.kyanite.image}`}
                alt='Logo ASD'
                height='100%'
                width='12.5rem'
                margin='0px 50px 0px 0px'
              />
              <TextCustom fontSize='2.7rem' fontWeight='bold'>
                WITH ASD QUICKPROOF, BRING YOUR VAT INTO COMPLIANCE
              </TextCustom>
            </Wrapper>
            <Wrapper maxWidth='40rem' margin='0 auto'>
              <Segment margin='5rem 0 2rem'>
                <Wrapper>
                  <Wrapper margin='0 0 5px'>
                    {productOfServiceSub && (
                      <Dropdown
                        loading={serviceLoading}
                        name='products'
                        clearable
                        fluid
                        search
                        selection
                        placeholder='Select one offer'
                        options={productOfServiceSub.map((e, i) => ({
                          key: i,
                          text: e.name,
                          value: e.name,
                        }))}
                        onChange={(e, { name, value }) => {
                          handleChangeOffer(value);
                        }}
                      />
                    )}
                    {offer && (
                      <>
                        <Divider hidden />
                        <Wrapper>
                          <Wrapper>
                            <b>Offer selected</b>: {offer.description}
                          </Wrapper>
                          <Wrapper>
                            <b>Price</b>: {offer.price}€ (exc VAT)
                          </Wrapper>
                          <Wrapper>
                            <b>Subscription frequency</b>:{' '}
                            {offer.frequency.toLowerCase()}
                          </Wrapper>
                          <Divider hidden />
                          <Wrapper display='flex'>
                            <Checkbox
                              name='termsOfService'
                              label='I agree to the'
                              checked={termsOfService}
                              onChange={() =>
                                setTermsOfService(!termsOfService)
                              }
                            />
                            &nbsp;
                            <ClickableText
                              onClick={() => {
                                window.open(
                                  'https://my-asd.s3-eu-west-1.amazonaws.com/legal/CONDITIONS_OF_SALE_KYANITE.pdf',
                                  '_blank'
                                );
                              }}
                            >
                              terms of Service.
                            </ClickableText>
                          </Wrapper>
                          <Divider hidden />
                          {!createPaymentForm && (
                            <Wrapper>
                              <Button
                                disabled={!termsOfService}
                                labelPosition='right'
                                icon='credit card outline'
                                type='button'
                                content='Subscribe'
                                onClick={handleSubscribe}
                              />
                            </Wrapper>
                          )}
                          {createPaymentForm && (
                            <>
                              <Payzen
                                formToken={createSubscriptionPaymentFormToken}
                                onSubmit={handleCloseCreatePayment}
                              />
                            </>
                          )}
                        </Wrapper>
                      </>
                    )}
                  </Wrapper>
                </Wrapper>
              </Segment>
            </Wrapper>
          </Container>
        )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companySelected: selectors.auth.companySelected(state),
    productOfServiceSub: selectors.service.productsOfServiceSubSelector(state),
    serviceLoading: selectors.service.loadingSelector(state),
    hasSubscribedTo: selectors.company.hasSubscribedToSelector(state),
    unpaidInvoices: selectors.companyInvoice.unpaidInvoices(state),
    createSubscriptionPaymentFormToken: selectors.payment.createSubscriptionPaymentFormTokenSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
