import * as ssr from "./Ssr";
import * as commercial from "./Commercial";


export const routes = [...ssr.routes, ...commercial.routes];

export const paths: any = {
  ...ssr.paths,
  ...commercial.paths
};
