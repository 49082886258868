import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    reportBug(data: dataReqI) {
      const reportBugApi = routesApi.admin.reports.reportBug;
      return api.request({
        method: reportBugApi.method,
        route: reportBugApi.path,
        data: data.data,
      });
    },
    getPermissions(data: dataReqI) {
      const getAllPermissionApi = routesApi.admin.permissions.getAll;
      return api.request({
        method: getAllPermissionApi.method,
        route: getAllPermissionApi.path,
        params: data.params,
      });
    },

  };
};
