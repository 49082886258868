import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';
import { Form, Icon, Checkbox } from 'semantic-ui-react';

import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { statisticsFiltersSchema } from 'interfaces/formSchema/customsValidators';
import Dropdown from 'components/Dropdown';

const YearFilters = ({
  dispatch,
  searchLoading,
  handleSubmitYears,
  apexChartsCompanies,
  companiesLoading,
}: any) => {

  const [companyList, setCompanyList]: any = useState([])


  useEffect(()=>{
    if (apexChartsCompanies !== null) {
      setCompanyList(
        apexChartsCompanies.map((company, index) => {
          return {
            key: index,
            value: company.id,
            text: company.name,
          };
        })
      );
    }

  } , [apexChartsCompanies])
 

  return (
   
      <Formik
        initialValues={{
          period: '',
          company: '',
          startDate: '',
          endDate: '',
          showAll: false,
        }}
        onSubmit={handleSubmitYears}
        validationSchema={statisticsFiltersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper
              display='flex'
              justifyContent='center'
              flexDirection='column'
            >
              <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
              <Wrapper margin='0.5rem'>
                  {/* <Field
                    placeholder='Choose filter...'
                    name='period'
                    options={[
                      {
                        key: 1,
                        value: 'day',
                        text: 'Week number',
                      },
                      {
                        key: 2,
                        value: 'monthName',
                        text: 'Named month',
                      },
                      {
                        key: 3,
                        value: 'month',
                        text: 'Month number'
                      }
                    ]}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  /> */}
                </Wrapper>
                <Wrapper margin='0.5rem'>
                  <Field
                    placeholder='Choose company...'
                    name='company'
                    options={companyList}
                    loading={companiesLoading}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem'>
                <Field
                    placeholder='Choose year...'
                    name='period'
                    options={[
                      {
                        key: 1,
                        value: `${new Date().getFullYear()-1}`,
                        text: `${new Date().getFullYear()-1}`,
                      },
                      {
                        key: 2,
                        value: `${new Date().getFullYear()-2}`,
                        text: `${new Date().getFullYear()-2}`,
                      },
                      {
                        key: 3,
                        value: `${new Date().getFullYear()-3}`,
                        text: `${new Date().getFullYear()-3}`,
                      }
                    ]}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>
              </Wrapper>
           
              <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
                <Field
                  label='Show archived'
                  name='showAll'
                  component={Checkbox}
                  checked={!values.showAll}
                  onClick={() => {
                    !values.showAll
                      ? setFieldValue('showAll', true)
                      : setFieldValue('showAll', false);
                  }}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Wrapper>
            </Wrapper>
            <Wrapper display='flex' margin='1rem' justifyContent='center'>
              <Button
                disabled={searchLoading}
                {...(searchLoading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Button
                type='button'
                content='Reset'
                color='red'
                onClick={() => {
                  resetForm();
                }}
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
  
  );
};

const mapStateToProps = (state) => {
  return {
    searchLoading: selectors.customsStatistics.loadingSelector(state)
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(YearFilters);
