import _ from 'lodash';

export default (previousState: any, initialState: any, keys: any = []) => {
  if (!_.isArray(keys) || keys.length === 0) {
    return initialState;
  }
  return _.transform(
    previousState,
    (result, value, key) => {
      if (keys.includes(key)) {
        result[key] = initialState[key];
      } else {
        result[key] = value;
      }
    },
    {}
  );
};
