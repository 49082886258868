import React from 'react';
import { ErrorMessage, getIn } from 'formik';
import { Wrapper } from './styles';
import { Error } from 'components/Form';
import { TextAreaStyled } from './styles';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import { Popup, Icon } from 'semantic-ui-react';

const Index = ({
  label,
  placeholder,
  field,
  meta,
  bulleted,
  bullMsg,
  mandatory,
  disabled,
}) => {
  return (
    <Wrapper>
      {label && (
        <label>
          {label}
          {mandatory && <MandatoryFieldLabel />}
          {bulleted && (
            <Wrapper float="right">
              <Popup
                trigger={<Icon name="info circle" />}
                content={bullMsg}
                size="mini"
              />
            </Wrapper>
          )}
        </label>
      )}
      <TextAreaStyled
        disabled={disabled}
        placeholder={placeholder}
        value={field.value}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        error={
          getIn(meta.touched, field.name) && getIn(meta.errors, field.name)
            ? true
            : false
        }
      />
      <ErrorMessage name={field.name} render={(msg) => <Error msg={msg} />} />
    </Wrapper>
  );
};

export default Index;
