let actionTypes = {
  ADD_USER: "MY_ASD.COMPANY_USER.ADD_USER",
  ADD_USER_LOADING: "MY_ASD.COMPANY_USER.ADD_USER_LOADING",
  ADD_USER_SUCCESS: "MY_ASD.COMPANY_USER.ADD_USER_SUCCESS",
  ADD_USER_ERROR: "MY_ASD.COMPANY_USER.ADD_USER_ERROR",

  REMOVE_USER: "MY_ASD.COMPANY_USER.REMOVE_USER",
  REMOVE_USER_LOADING: "MY_ASD.COMPANY_USER.REMOVE_USER_LOADING",
  REMOVE_USER_SUCCESS: "MY_ASD.COMPANY_USER.REMOVE_USER_SUCCESS",
  REMOVE_USER_ERROR: "MY_ASD.COMPANY_USER.REMOVE_USER_ERROR",

  UPDATE_USER: "MY_ASD.COMPANY_USER.UPDATE_USER",
  UPDATE_USER_LOADING: "MY_ASD.COMPANY_USER.UPDATE_USER_LOADING",
  UPDATE_USER_SUCCESS: "MY_ASD.COMPANY_USER.UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "MY_ASD.COMPANY_USER.UPDATE_USER_ERROR",

  UPDATE_USER_PERMISSIONS: "MY_ASD.COMPANY_USER.UPDATE_USER_PERMISSIONS",
  UPDATE_USER_PERMISSIONS_LOADING:
    "MY_ASD.COMPANY_USER.UPDATE_USER_PERMISSIONS_LOADING",
  UPDATE_USER_PERMISSIONS_SUCCESS:
    "MY_ASD.COMPANY_USER.UPDATE_USER_PERMISSIONS_SUCCESS",
  UPDATE_USER_PERMISSIONS_ERROR:
    "MY_ASD.COMPANY_USER.UPDATE_USER_PERMISSIONS_ERROR",

  GET_USER_PERMISSIONS: "MY_ASD.COMPANY_USER.GET_USER_PERMISSIONS",
  GET_USER_PERMISSIONS_LOADING:
    "MY_ASD.COMPANY_USER.GET_USER_PERMISSIONS_LOADING",
  GET_USER_PERMISSIONS_SUCCESS:
    "MY_ASD.COMPANY_USER.GET_USER_PERMISSIONS_SUCCESS",
  GET_USER_PERMISSIONS_ERROR: "MY_ASD.COMPANY_USER.GET_USER_PERMISSIONS_ERROR",

  GET_USER_PERMISSIONS_FOR_NAVIGATION:
    "MY_ASD.COMPANY_USER.GET_USER_PERMISSIONS_FOR_NAVIGATION",
  GET_USER_PERMISSIONS_FOR_NAVIGATION_LOADING:
    "MY_ASD.COMPANY_USER.GET_USER_PERMISSIONS_FOR_NAVIGATION_LOADING",
  GET_USER_PERMISSIONS_FOR_NAVIGATION_SUCCESS:
    "MY_ASD.COMPANY_USER.GET_USER_PERMISSIONS_FOR_NAVIGATION_SUCCESS",
  GET_USER_PERMISSIONS_FOR_NAVIGATION_ERROR:
    "MY_ASD.COMPANY_USER.GET_USER_PERMISSIONS_FOR_NAVIGATION_ERROR",

  GET_ONE_USER: "MY_ASD.COMPANY_USER.GET_ONE_USER",
  GET_ONE_USER_LOADING: "MY_ASD.COMPANY_USER.GET_ONE_USER_LOADING",
  GET_ONE_USER_SUCCESS: "MY_ASD.COMPANY_USER.GET_ONE_USER_SUCCESS",
  GET_ONE_USER_ERROR: "MY_ASD.COMPANY_USER.GET_ONE_USER_ERROR",

  GET_ALL_USERS: "MY_ASD.COMPANY_USER.GET_ALL_USERS",
  GET_ALL_USERS_LOADING: "MY_ASD.COMPANY_USER.GET_ALL_USERS_LOADING",
  GET_ALL_USERS_SUCCESS: "MY_ASD.COMPANY_USER.GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_ERROR: "MY_ASD.COMPANY_USER.GET_ALL_USERS_ERROR",

  GET_USER_COMPANIES: "MY_ASD.COMPANY.GET_USER_COMPANIES",
  GET_USER_COMPANIES_LOADING: "MY_ASD.COMPANY.GET_USER_COMPANIES_LOADING",
  GET_USER_COMPANIES_SUCCESS: "MY_ASD.COMPANY.GET_USER_COMPANIES_SUCCESS",
  GET_USER_COMPANIES_ERROR: "MY_ASD.COMPANY.GET_USER_COMPANIES_ERROR",

  RESET: "MY_ASD.COMPANY_USER.RES",
  REFRESH_ERROR: "MY_ASD.COMPANY_USER.REFRESH_ERROR",
};

export default actionTypes;
