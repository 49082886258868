import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const createAgencyErrorSelector = (state) => state.agency.errors.create;
const getAllErrorSelector = (state) => state.agency.errors.getAll;
const getOneErrorSelector = (state) => state.agency.errors.getOne;
const deleteAgencyErrorSelector = (state) => state.agency.errors.remove;
const updateAgencyErrorSelector = (state) => state.agency.errors.update;

const errorsSelector = createErrorsSelector([
  getAllErrorSelector,
  getOneErrorSelector,
  createAgencyErrorSelector,
  updateAgencyErrorSelector,
  deleteAgencyErrorSelector,
]);

// Loading
const createAgencyLoadingSelector = (state) => state.agency.loading.create;
const getAllLoadingSelector = (state) => state.agency.loading.getAll;
const getOneLoadingSelector = (state) => state.agency.loading.getOne;
const deleteAgencyLoadingSelector = (state) => state.agency.loading.remove;
const updateAgencyLoadingSelector = (state) => state.agency.loading.update;

const loadingSelector = createLoadingSelector([
  getAllLoadingSelector,
  getOneLoadingSelector,
  createAgencyLoadingSelector,
  updateAgencyLoadingSelector,
  deleteAgencyLoadingSelector,
]);

// Success
const createAgencySuccessSelector = (state) => state.agency.success.create;
const getAllSuccessSelector = (state) => state.agency.success.getAll;
const getOneSuccessSelector = (state) => state.agency.success.getOne;
const deleteAgencySuccessSelector = (state) => state.agency.success.remove;
const updateAgencySuccessSelector = (state) => state.agency.success.update;

const successSelector = createSuccessSelector([
  getAllSuccessSelector,
  getOneSuccessSelector,
  createAgencySuccessSelector,
  updateAgencySuccessSelector,
  deleteAgencySuccessSelector,
]);

// agencies
const agenciesSelector = (state) => state.agency.agencies;

// Agency
const agencySelector = (state) => state.agency.agency;

//Errors Handlers
const errorSelector = (state) => state.agency.error;

//Loading
const agenciesLoadingSelector = (state) => state.agency.loading.getAll;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  agenciesSelector,
  agencySelector,
  errorSelector,
  agenciesLoadingSelector,
};
