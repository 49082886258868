let actionTypes = {
  GET_DELIVERY_BY_TOKEN: 'MY_ASD.FISCAL.POD.TOKEN.GET_DELIVERY_BY_TOKEN',
  GET_DELIVERY_BY_TOKEN_LOADING:
    'MY_ASD.FISCAL.POD.TOKEN.GET_DELIVERY_BY_TOKEN_LOADING',
  GET_DELIVERY_BY_TOKEN_SUCCESS:
    'MY_ASD.FISCAL.POD.TOKEN.GET_DELIVERY_BY_TOKEN_SUCCESS',
  GET_DELIVERY_BY_TOKEN_ERROR:
    'MY_ASD.FISCAL.POD.TOKEN.GET_DELIVERY_BY_TOKEN_ERROR',

  TRANSPORTER_SELECTION: 'MY_ASD.FISCAL.POD.TOKEN.TRANSPORTER_SELECTION',
  TRANSPORTER_SELECTION_LOADING:
    'MY_ASD.FISCAL.POD.TOKEN.TRANSPORTER_SELECTION_LOADING',
  TRANSPORTER_SELECTION_SUCCESS:
    'MY_ASD.FISCAL.POD.TOKEN.TRANSPORTER_SELECTION_SUCCESS',
  TRANSPORTER_SELECTION_ERROR:
    'MY_ASD.FISCAL.POD.TOKEN.TRANSPORTER_SELECTION_ERROR',

  UPLOAD_PROOF: 'MY_ASD.FISCAL.POD.TOKEN.UPLOAD_PROOF',
  UPLOAD_PROOF_LOADING: 'MY_ASD.FISCAL.POD.TOKEN.UPLOAD_PROOF_LOADING',
  UPLOAD_PROOF_SUCCESS: 'MY_ASD.FISCAL.POD.TOKEN.UPLOAD_PROOF_SUCCESS',
  UPLOAD_PROOF_ERROR: 'MY_ASD.FISCAL.POD.TOKEN.UPLOAD_PROOF_ERROR',

  GET_ONE_PROOF: 'MY_ASD.FISCAL.POD.TOKEN.GET_ONE_PROOF',
  GET_ONE_PROOF_LOADING: 'MY_ASD.FISCAL.POD.TOKEN.GET_ONE_PROOF_LOADING',
  GET_ONE_PROOF_SUCCESS: 'MY_ASD.FISCAL.POD.TOKEN.GET_ONE_PROOF_SUCCESS',
  GET_ONE_PROOF_ERROR: 'MY_ASD.FISCAL.POD.TOKEN.GET_ONE_PROOF_ERROR',

  DOWNLOAD_ATTESTATION: 'MY_ASD.FISCAL.POD.TOKEN.DOWNLOAD_ATTESTATION',
  DOWNLOAD_ATTESTATION_LOADING:
    'MY_ASD.FISCAL.POD.TOKEN.DOWNLOAD_ATTESTATION_LOADING',
  DOWNLOAD_ATTESTATION_SUCCESS:
    'MY_ASD.FISCAL.POD.TOKEN.DOWNLOAD_ATTESTATION_SUCCESS',
  DOWNLOAD_ATTESTATION_ERROR:
    'MY_ASD.FISCAL.POD.TOKEN.DOWNLOAD_ATTESTATION_ERROR',

  DOWNLOAD_CUSTOMS_REGIME_INVOICE:
    'MY_ASD.FISCAL.POD.TOKEN.DOWNLOAD_CUSTOMS_REGIME_INVOICE',
  DOWNLOAD_CUSTOMS_REGIME_INVOICE_LOADING:
    'MY_ASD.FISCAL.POD.TOKEN.DOWNLOAD_CUSTOMS_REGIME_INVOICE_LOADING',
  DOWNLOAD_CUSTOMS_REGIME_INVOICE_SUCCESS:
    'MY_ASD.FISCAL.POD.TOKEN.DOWNLOAD_CUSTOMS_REGIME_INVOICE_SUCCESS',
  DOWNLOAD_CUSTOMS_REGIME_INVOICE_ERROR:
    'MY_ASD.FISCAL.POD.TOKEN.DOWNLOAD_CUSTOMS_REGIME_INVOICE_ERROR',

  RESET: 'MY_ASD.FISCAL.POD.TOKEN.RES',
  REFRESH_ERROR: 'MY_ASD.FISCAL.POD.TOKEN.REFRESH_ERROR',
};

export default actionTypes;
