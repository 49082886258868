import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Modal } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import { actions } from 'store';

import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import ErrorHandler from 'components/ErrorHandler';
import { Input } from 'components/Form';
import {updateDriverSchema } from 'interfaces/formSchema';
import { Spacer } from 'components/core';

const Index = ({
  errorHandler,
  open,
  handleClose,
  user,
  intl,
  dispatch,
  companyId,
  setRunningAction,
}) => {

  const save = (values) => {
    dispatch(actions.companyDriver.reset(['error']));
    dispatch(
      actions.companyDriver.updateDriver({
        data: {
          pseudo: values.pseudo,
          updatedAt: new Date()
        },
        params: { companyId: companyId, driverId: user.id },
      })
    );
    setRunningAction('updateDriver');
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Modal.Content>
        <Wrapper>
          <Formik
            initialValues={{
              pseudo: user.pseudo,
              updatedAt: new Date()
            }}
            onSubmit={save}
            validationSchema={updateDriverSchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              values,
            }) => (
              <Form onSubmit={handleSubmit} width='15px'>
                {errorHandler && (
                  <>
                    <ErrorHandler
                      header='Fail to send request.'
                      message='Try to refresh the page and contact an administrator if the error persists.'
                    />
                  </>
                )}
                <Wrapper>
                <Spacer/>
                <Spacer/>
                <Wrapper margin='10px'>
                    <Form.Group widths='equal'>                    
                      <Form.Field>
                        <Field
                          mandatory
                          label='Pseudo, Alias, Nickname'
                          placeholder='Pseudo'
                          name='pseudo'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>
                    </Form.Group>
                  </Wrapper>
                </Wrapper>
                <Button
                  disabled={isSubmitting}
                  type='submit'
                  content={intl.formatMessage({
                    id: 'button.save',
                  })}
                  margin='20px'
                  position='center'
                  color='blue'
                />
              </Form>
            )}
          </Formik>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
