import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';
import moment from 'moment';

import Loader from 'components/Loader';
import MissionSector from './missionSector';
import Countries from './countries';
import MissionType from './missionType';
import MissionTypeOptions from './missionTypeOptions';
import ConstructionCard from './constructionCard';
import NumberOfEmployees from './numberOfEmployees';
import Datepicker from './datepicker';
import InformationDocuments from './informationDocuments';
import LocationForm from './locationForm';
import LodgingForm from './lodgingForm';
import PaymentMethod from './paymentMethod';

import { currentLanguage } from '../../../../views';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';
import euCountries from '../../assets/json/euCountries.json';

import './orderForm.css';
import 'react-edit-text/dist/index.css';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import CountryDashboard from 'views/Fiscal/Vat/Operator/CountryDashboard';

export interface PricingParams {
  representation: number;
  documentStorage: number;
  management: number;
  sipsiDeclaration: number;
  constructionCard: number;
  registeredMail1: number;
  registeredMail2: number;
  registeredMail3: number;
  informationDocument: number;
  updateDocument: number;
  assistance: number;
}

const OrderForm: React.FC = ({ user }: any) => {
  let [tab] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);
  const [isBackButtonHidden, setIsBackButtonHidden] = useState<boolean>(false);
  const [isNextButtonHidden, setIsNextButtonHidden] = useState<boolean>(false);
  const [pricing, setPricing] = useState<PricingParams>(null);
  const [companyCountry, setCompanyCountry] = useState<string>(null);
  const [vatValue, setVatValue] = useState<number>(0);
  const [numberOfMonths, setNumberOfMonths] = useState<number | null>();
  const [mention, setMention] = useState<string>('');
  const [companyId, setCompanyId] = useState<number>();

  const { t } = useTranslation();

  async function getPricingPolicy() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getPricingPolicy`,
        {
          method: 'GET',
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        let pricingPolicy: PricingParams = {
          representation: result.data.representationPrice.pricing_amount,
          documentStorage: result.data.documentStoragePrice.pricing_amount,
          management: result.data.managementPrice.pricing_amount,
          sipsiDeclaration: result.data.sipsiDeclarationPrice.pricing_amount,
          constructionCard: result.data.constructionCardPrice.pricing_amount,
          registeredMail1: result.data.registeredMailPrice1.pricing_amount,
          registeredMail2: result.data.registeredMailPrice2.pricing_amount,
          registeredMail3: result.data.registeredMailPrice3.pricing_amount,
          informationDocument:
            result.data.informationDocumentPrice.pricing_amount,
          updateDocument: result.data.updateDocumentPrice.pricing_amount,
          assistance: result.data.assistancePrice.pricing_amount,
        };

        return pricingPolicy;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getCompanyCountry() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/userHasOneCompany`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: user.id,
          }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setCompanyId(result.companyId);
        return result.vatNumber;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  useEffect(() => {
    let getPricing;
    let getCountry;
    async function exec() {
      getPricing = await getPricingPolicy();
      if (!user.operator && !user.admin) {
        getCountry = await getCompanyCountry();
      }
    }
    exec().then(() => {
      setPricing(getPricing);
      if (!user.operator && !user.admin) {
        setCompanyCountry(getCountry.slice(0, 2));
        window.sessionStorage.setItem('companyInvoiceCountry', companyCountry);
        if (companyCountry === 'FR') {
          setVatValue(20);
          setMention('');
        } else {
          for (var i = 0; i < euCountries.length; i++) {
            if (companyCountry === euCountries[i].countryCode) {
              setMention(
                t('orderPageStrings.commandDetails.reverseChargeMention')
              );
              break;
            } else {
              setMention(
                t('orderPageStrings.commandDetails.vatNotApplicableMention')
              );
            }
          }
        }
      }
    });
  }, [companyCountry]);

  const handleClickBackButton = () => {
    switch (window.sessionStorage.getItem('activeComponent')) {
      case 'Employees':
        window.sessionStorage.setItem(
          'totalPrice',
          `${
            Number(window.sessionStorage.getItem('totalPrice')) -
            Number(pricing.representation)
          }`
        );
        clickFormButton('MissionSector');
        break;
      case 'DatePicker':
        window.sessionStorage.setItem(
          'totalPrice',
          `${
            Number(window.sessionStorage.getItem('totalPrice')) -
            Number(pricing.documentStorage) *
              Number(window.sessionStorage.getItem('selectedOptionNumber'))
          }`
        );
        clickFormButton('Employees');
        break;
      case 'CountriesSelectors':
        window.sessionStorage.setItem(
          'totalPrice',
          `${
            Number(window.sessionStorage.getItem('totalPrice')) -
            Number(pricing.management) *
              Number(window.sessionStorage.getItem('selectedOptionNumber')) *
              Number(window.sessionStorage.getItem('numberOfMonths'))
          }`
        );
        clickFormButton('DatePicker');
        break;
      // case 'MissionType':
      //   clickFormButton('CountriesSelectors');
      //   break;
      case 'MissionTypeOptions':
        clickFormButton('CountriesSelectors');
        break;
      // case 'NeedConstructionCard':
      //   window.sessionStorage.setItem(
      //     'totalPrice',
      //     `${
      //       Number(window.sessionStorage.getItem('totalPrice')) -
      //       Number(pricing.tranquilityOffer) *
      //         Number(window.sessionStorage.getItem('selectedOptionNumber')) *
      //         Number(window.sessionStorage.getItem('numberOfMonthsStarted')) -
      //       Number(pricing.sipsiDeclaration) *
      //         Number(window.sessionStorage.getItem('selectedOptionNumber'))
      //     }`
      //   );
      //   clickFormButton('MissionType');
      //   break;
      case 'InformationDocuments':
        // if (
        //   window.sessionStorage.getItem('selectedOption')!.replace(/"/g, '') ==
        //   'customMade'
        // ) {
        let sipsiSelectedOption = window.sessionStorage.getItem(
          'sipsiSelectedOption'
        );
        let constructionCardSelectedOption = window.sessionStorage.getItem(
          'constructionCardSelectedOption'
        );

        let newCardOptionSelectedOption = window.sessionStorage.getItem(
          'selectedNewCardOption'
        );
        if (sipsiSelectedOption == 'true') {
          window.sessionStorage.setItem(
            'totalPrice',
            `${
              Number(window.sessionStorage.getItem('totalPrice')) -
              Number(pricing.sipsiDeclaration) *
                Number(window.sessionStorage.getItem('selectedOptionNumber'))
            }`
          );
        }
        if (constructionCardSelectedOption == 'true') {
          if (Number(newCardOptionSelectedOption) == 0) {
            window.sessionStorage.setItem(
              'totalPrice',
              `${
                Number(window.sessionStorage.getItem('totalPrice')) -
                Number(pricing.updateDocument) *
                  Number(window.sessionStorage.getItem('selectedOptionNumber'))
              }`
            );
          } else {
            window.sessionStorage.setItem(
              'totalPrice',
              `${
                Number(window.sessionStorage.getItem('totalPrice')) -
                Number(pricing.constructionCard) *
                  Number(window.sessionStorage.getItem('selectedOptionNumber'))
              }`
            );

            let pricingRegisteredMail =
              Number(window.sessionStorage.getItem('selectedOptionNumber')) == 1
                ? pricing.registeredMail1
                : Number(
                    window.sessionStorage.getItem('selectedOptionNumber')
                  ) >= 10
                ? pricing.registeredMail3
                : pricing.registeredMail2;

            window.sessionStorage.setItem(
              'totalPrice',
              `${
                Number(window.sessionStorage.getItem('totalPrice')) -
                Number(pricingRegisteredMail)
              }`
            );
          }
        }
        clickFormButton('MissionTypeOptions');
        // }
        // else {
        //   if (
        //     Number(window.sessionStorage.getItem('selectedNewCardOption')) == 0
        //   ) {
        //     window.sessionStorage.setItem(
        //       'totalPrice',
        //       `${
        //         Number(window.sessionStorage.getItem('totalPrice')) -
        //         Number(pricing.updateDocument) *
        //           Number(window.sessionStorage.getItem('selectedOptionNumber'))
        //       }`
        //     );
        //   } else {
        //     window.sessionStorage.setItem(
        //       'totalPrice',
        //       `${
        //         Number(window.sessionStorage.getItem('totalPrice')) -
        //         Number(pricing.constructionCard) *
        //           Number(window.sessionStorage.getItem('selectedOptionNumber'))
        //       }`
        //     );

        //     let pricingRegisteredMail =
        //       Number(window.sessionStorage.getItem('selectedOptionNumber')) == 1
        //         ? pricing.registeredMail1
        //         : Number(
        //             window.sessionStorage.getItem('selectedOptionNumber')
        //           ) >= 10
        //         ? pricing.registeredMail3
        //         : pricing.registeredMail2;

        //     window.sessionStorage.setItem(
        //       'totalPrice',
        //       `${
        //         Number(window.sessionStorage.getItem('totalPrice')) -
        //         Number(pricingRegisteredMail)
        //       }`
        //     );
        //   }
        //   clickFormButton('NeedConstructionCard');
        // }
        break;
      case 'Location':
        let checkedLanguagesArray = JSON.parse(
          window.sessionStorage.getItem('checkedLanguagesArray')
        );

        if (checkedLanguagesArray != null) {
          window.sessionStorage.setItem(
            'totalPrice',
            `${
              Number(window.sessionStorage.getItem('totalPrice')) -
              Number(pricing.informationDocument) *
                Number(checkedLanguagesArray.length)
            }`
          );
        }

        clickFormButton('InformationDocuments');
        break;
      case 'Lodging':
        clickFormButton('Location');
        break;
      case 'Payment':
        clickFormButton('Lodging');
        break;
      default:
        return;
    }
  };

  function clickFormButton(activeComponent: string) {
    window.sessionStorage.setItem('activeComponent', activeComponent);
    setIsLoading(true);
    window.location.reload();
  }

  const isEmpty = (string: string) => {
    return string.trim() === '';
  };

  const handleClickNextButton = () => {
    switch (window.sessionStorage.getItem('activeComponent')) {
      case 'MissionSector': {
        if (
          window.sessionStorage.getItem('isClickedConstruction') == 'true' ||
          window.sessionStorage.getItem('isClickedOther') == 'true'
        ) {
          clickFormButton('Employees');
          window.sessionStorage.setItem(
            'totalPrice',
            `${
              Number(window.sessionStorage.getItem('totalPrice')) +
              Number(pricing.representation)
            }`
          );
        } else {
          console.log();
          setIsErrorHidden(false);
        }
        break;
      }
      case 'Employees': {
        let selectedOptionNumber = window.sessionStorage.getItem(
          'selectedOptionNumber'
        );

        if (selectedOptionNumber == '' || selectedOptionNumber == null) {
          setIsErrorHidden(false);
        } else {
          clickFormButton('DatePicker');
          window.sessionStorage.setItem(
            'totalPrice',
            `${
              Number(window.sessionStorage.getItem('totalPrice')) +
              Number(pricing.documentStorage) * Number(selectedOptionNumber)
            }`
          );
        }
        break;
      }
      case 'DatePicker': {
        let startDate = window.sessionStorage.getItem('startDate');
        let endDate = window.sessionStorage.getItem('endDate');

        if (
          startDate == null ||
          endDate == null ||
          startDate == 'null' ||
          endDate == 'null' ||
          startDate == '' ||
          endDate == ''
        ) {
          setIsErrorHidden(false);
        } else {
          clickFormButton('CountriesSelectors');
          calculateStartedMonths(startDate, endDate);
          calculateMonths(startDate, endDate);

          window.sessionStorage.setItem(
            'totalPrice',
            `${
              Number(window.sessionStorage.getItem('totalPrice')) +
              Number(pricing.management) *
                Number(window.sessionStorage.getItem('selectedOptionNumber')) *
                Number(window.sessionStorage.getItem('numberOfMonths'))
            }`
          );
          setIsErrorHidden(true);
        }
        break;
      }
      case 'CountriesSelectors': {
        let selectedCountryFrom = window.sessionStorage.getItem(
          'selectedOptionCountryFrom'
        );
        let selectedCountryTo = window.sessionStorage.getItem(
          'selectedOptionCountryTo'
        );

        if (
          selectedCountryFrom == null ||
          selectedCountryTo == null ||
          selectedCountryFrom == '' ||
          selectedCountryTo == ''
        ) {
          setIsErrorHidden(false);
        } else {
          clickFormButton('MissionTypeOptions');
        }
        break;
      }
      // case 'MissionType': {
      //   let selectedOption = window.sessionStorage.getItem('selectedOption');

      //   if (selectedOption == '' || selectedOption == null) {
      //     setIsErrorHidden(false);
      //   } else if (
      //     window.sessionStorage.getItem('selectedOption')!.replace(/"/g, '') ==
      //     'customMade'
      //   ) {
      //     clickFormButton('MissionTypeOptions');
      //     setIsErrorHidden(true);
      //   } else {
      //     window.sessionStorage.setItem(
      //       'totalPrice',
      //       `${
      //         Number(window.sessionStorage.getItem('totalPrice')) +
      //         Number(pricing.tranquilityOffer) *
      //           Number(window.sessionStorage.getItem('selectedOptionNumber')) *
      //           Number(window.sessionStorage.getItem('numberOfMonthsStarted')) +
      //         Number(pricing.sipsiDeclaration) *
      //           Number(window.sessionStorage.getItem('selectedOptionNumber'))
      //       }`
      //     );

      //     if (
      //       window.sessionStorage.getItem('isClickedConstruction') == 'true'
      //     ) {
      //       clickFormButton('NeedConstructionCard');
      //     } else {
      //       clickFormButton('InformationDocuments');
      //     }
      //     setIsErrorHidden(true);
      //   }
      //   break;
      // }
      case 'MissionTypeOptions': {
        let sipsiSelectedOption = window.sessionStorage.getItem(
          'sipsiSelectedOption'
        );
        let constructionCardSelectedOption = window.sessionStorage.getItem(
          'constructionCardSelectedOption'
        );

        let newCardOptionSelectedOption = window.sessionStorage.getItem(
          'selectedNewCardOption'
        );

        if (sipsiSelectedOption == '' || sipsiSelectedOption == null) {
          setIsErrorHidden(false);
        } else if (
          (constructionCardSelectedOption == '' ||
            constructionCardSelectedOption == null) &&
          window.sessionStorage.getItem('isClickedConstruction') === 'true'
        ) {
          setIsErrorHidden(false);
        } else if (
          (newCardOptionSelectedOption == '' ||
            newCardOptionSelectedOption == null) &&
          constructionCardSelectedOption == 'true'
        ) {
          setIsErrorHidden(false);
        } else {
          if (sipsiSelectedOption == 'true') {
            window.sessionStorage.setItem(
              'totalPrice',
              `${
                Number(window.sessionStorage.getItem('totalPrice')) +
                Number(pricing.sipsiDeclaration) *
                  Number(window.sessionStorage.getItem('selectedOptionNumber'))
              }`
            );
          }
          if (constructionCardSelectedOption == 'true') {
            if (Number(newCardOptionSelectedOption) == 0) {
              window.sessionStorage.setItem(
                'totalPrice',
                `${
                  Number(window.sessionStorage.getItem('totalPrice')) +
                  Number(pricing.updateDocument) *
                    Number(
                      window.sessionStorage.getItem('selectedOptionNumber')
                    )
                }`
              );
            } else {
              window.sessionStorage.setItem(
                'totalPrice',
                `${
                  Number(window.sessionStorage.getItem('totalPrice')) +
                  Number(pricing.constructionCard) *
                    Number(newCardOptionSelectedOption) +
                  Number(pricing.updateDocument) *
                    (Number(
                      window.sessionStorage.getItem('selectedOptionNumber')
                    ) -
                      Number(newCardOptionSelectedOption))
                }`
              );

              let pricingRegisteredMail =
                Number(newCardOptionSelectedOption) == 1
                  ? pricing.registeredMail1
                  : Number(newCardOptionSelectedOption) >= 10
                  ? pricing.registeredMail3
                  : pricing.registeredMail2;

              window.sessionStorage.setItem(
                'totalPrice',
                `${
                  Number(window.sessionStorage.getItem('totalPrice')) +
                  Number(pricingRegisteredMail)
                }`
              );
            }
          }
          clickFormButton('InformationDocuments');
        }
        break;
      }
      // case 'NeedConstructionCard': {
      //   if (
      //     window.sessionStorage.getItem('selectedNewCardOption') == null ||
      //     window.sessionStorage.getItem('selectedNewCardOption') == ''
      //   ) {
      //     setIsErrorHidden(false);
      //   } else {
      //     if (
      //       Number(window.sessionStorage.getItem('selectedNewCardOption')) == 0
      //     ) {
      //       window.sessionStorage.setItem(
      //         'totalPrice',
      //         `${
      //           Number(window.sessionStorage.getItem('totalPrice')) +
      //           Number(pricing.updateDocument) *
      //             Number(window.sessionStorage.getItem('selectedOptionNumber'))
      //         }`
      //       );
      //     } else {
      //       window.sessionStorage.setItem(
      //         'totalPrice',
      //         `${
      //           Number(window.sessionStorage.getItem('totalPrice')) +
      //           Number(pricing.constructionCard) *
      //             Number(window.sessionStorage.getItem('selectedOptionNumber'))
      //         }`
      //       );

      //       let pricingRegisteredMail =
      //         Number(window.sessionStorage.getItem('selectedOptionNumber')) == 1
      //           ? pricing.registeredMail1
      //           : Number(
      //               window.sessionStorage.getItem('selectedOptionNumber')
      //             ) >= 10
      //           ? pricing.registeredMail3
      //           : pricing.registeredMail2;

      //       window.sessionStorage.setItem(
      //         'totalPrice',
      //         `${
      //           Number(window.sessionStorage.getItem('totalPrice')) +
      //           Number(pricingRegisteredMail)
      //         }`
      //       );
      //     }
      //     clickFormButton('InformationDocuments');
      //   }
      //   break;
      // }
      case 'InformationDocuments': {
        let checkedLanguagesArray = JSON.parse(
          window.sessionStorage.getItem('checkedLanguagesArray')
        );

        if (checkedLanguagesArray != null) {
          window.sessionStorage.setItem(
            'totalPrice',
            `${
              Number(window.sessionStorage.getItem('totalPrice')) +
              Number(pricing.informationDocument) *
                Number(checkedLanguagesArray.length)
            }`
          );
        }

        clickFormButton('Location');
        break;
      }
      case 'Location': {
        let locationName = window.sessionStorage.getItem('locationName');
        let locationMainAddress = window.sessionStorage.getItem(
          'locationMainAddress'
        );
        let locationPostalCode =
          window.sessionStorage.getItem('locationPostalCode');
        let locationCity = window.sessionStorage.getItem('locationCity');
        let locationCountry = window.sessionStorage.getItem('locationCountry');

        if (
          locationName == null ||
          isEmpty(locationName) ||
          locationMainAddress == null ||
          isEmpty(locationMainAddress) ||
          locationPostalCode == null ||
          isEmpty(locationPostalCode) ||
          locationCity == null ||
          isEmpty(locationCity) ||
          locationCountry == null ||
          isEmpty(locationCountry)
        ) {
          setIsErrorHidden(false);
        } else {
          setIsErrorHidden(true);
          clickFormButton('Lodging');
        }
        break;
      }
      case 'Lodging': {
        let lodgingMainAddress =
          window.sessionStorage.getItem('lodgingMainAddress');
        let lodgingPostalCode =
          window.sessionStorage.getItem('lodgingPostalCode');
        let lodgingCity = window.sessionStorage.getItem('lodgingCity');
        let lodgingCountry = window.sessionStorage.getItem('lodgingCountry');

        if (
          lodgingMainAddress == null ||
          isEmpty(lodgingMainAddress) ||
          lodgingPostalCode == null ||
          isEmpty(lodgingPostalCode) ||
          lodgingCity == null ||
          isEmpty(lodgingCity) ||
          lodgingCountry == null ||
          isEmpty(lodgingCountry)
        ) {
          setIsErrorHidden(false);
        } else {
          setIsErrorHidden(true);
          clickFormButton('Payment');
        }

        break;
      }
      default: {
        return;
      }
    }
  };

  const calculateMonths = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);
    if (start.isValid() && end.isValid() && end.isAfter(start)) {
      const months = end.diff(start, 'months', true);
      const wholeMonths = Math.ceil(months);
      window.sessionStorage.setItem('numberOfMonths', `${wholeMonths}`);
    } else {
      setNumberOfMonths(null);
    }
  };

  const calculateStartedMonths = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);
    if (start.isValid() && end.isValid() && end.isSameOrAfter(start)) {
      const startMonth = moment(start).startOf('month');
      const endMonth = moment(end).startOf('month');
      const totalMonths = endMonth.diff(startMonth, 'months') + 1;

      window.sessionStorage.setItem('numberOfMonthsStarted', `${totalMonths}`);
    } else {
      setNumberOfMonths(null);
    }
  };

  function getString() {
    window.sessionStorage.removeItem('checkedLanguagesString');
    if (window.sessionStorage.getItem('checkedLanguagesArray') != null) {
      tab = JSON.parse(window.sessionStorage.getItem('checkedLanguagesArray')!);
    }

    tab.forEach((value) => {
      if (window.sessionStorage.getItem('checkedLanguagesString') == null) {
        window.sessionStorage.setItem(
          'checkedLanguagesString',
          ' - ' + t('orderPageStrings.informationDocuments.' + value)
        );
      } else {
        window.sessionStorage.setItem(
          'checkedLanguagesString',
          window.sessionStorage.getItem('checkedLanguagesString') +
            ' - ' +
            t('orderPageStrings.informationDocuments.' + value)
        );
      }
    });

    return window.sessionStorage.getItem('checkedLanguagesString');
  }

  const [billingMainAddress, setBillingMainAddress] = useState<string>();
  const [billingAdditionalAddress, setBillingAdditionalAddress] =
    useState<string>();
  const [billingPostalCode, setBillingPostalCode] = useState<string>();
  const [billingCity, setBillingCity] = useState<string>();

  const handleBillingMainAddressChange = ({ value }: { value: string }) => {
    setBillingMainAddress(value);
  };

  const handleBillingAdditionalAddressChange = ({
    value,
  }: {
    value: string;
  }) => {
    setBillingAdditionalAddress(value);
  };

  const handleBillingPostalCodeChange = ({ value }: { value: string }) => {
    setBillingPostalCode(value);
  };

  const handleBillingCityChange = ({ value }: { value: string }) => {
    setBillingCity(value);
  };

  const [selectedOptionBillingCountry, setSelectedOptionBillingCountry] =
    useState<string | null>();
  const [billingCountries, setBillingCountries] = useState<any[]>([]);

  const handleSelectChangeBillingCountry = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionBillingCountry = event.target.value;

    setSelectedOptionBillingCountry(newSelectedOptionBillingCountry);
  };

  useEffect(() => {
    if (
      window.sessionStorage.getItem('activeComponent') == '' ||
      window.sessionStorage.getItem('activeComponent') == null ||
      window.sessionStorage.getItem('activeComponent') == undefined
    ) {
      window.sessionStorage.setItem('activeComponent', 'MissionSector');
      setIsBackButtonHidden(true);
    }

    if (window.sessionStorage.getItem('activeComponent') == 'MissionSector') {
      setIsBackButtonHidden(true);
    }

    if (window.sessionStorage.getItem('activeComponent') == 'Payment') {
      setIsNextButtonHidden(true);
    }

    if (currentLanguage == 'fr') {
      setBillingCountries(
        countriesFR.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    } else {
      setBillingCountries(
        countriesEN.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    }

    setIsLoading(false);
  }, [isBackButtonHidden, isNextButtonHidden]);

  const [startDate, setStartDate] = useState<string | null>(
    window.sessionStorage.getItem('lodgingStartDate')
  );
  const [endDate, setEndDate] = useState<string | null>(
    window.sessionStorage.getItem('lodgingEndDate')
  );
  const [selectedOptionLodgingType, setSelectedOptionLodgingType] = useState<
    string | null
  >(window.sessionStorage.getItem('lodgingOptionId'));

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    window.sessionStorage.setItem('lodgingStartDate', date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    window.sessionStorage.setItem('lodgingEndDate', date);
  };

  const handleSelectChangeLodgingType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionLodgingType = event.target.value;

    setSelectedOptionLodgingType(newSelectedOptionLodgingType);
    window.sessionStorage.setItem('lodgingOptionId', event.target.value);
  };

  return (
    <div className='orderFormContainer'>
      <>
        {(isLoading && <Loader />) ||
          (!isLoading && (
            <>
              <div className='orderFormContainerDetails'>
                <div className='orderFormContainerLeft'>
                  {window.sessionStorage.getItem('activeComponent') ===
                    'MissionSector' && (
                    <div className='orderFormSectors'>
                      <pre>
                        <p id='obligatoire'>* </p>
                      </pre>
                      <p>{t('orderPageStrings.missionSector.title')}</p>
                      <MissionSector setCount={setCount} />
                    </div>
                  )}
                  {window.sessionStorage.getItem('activeComponent') ===
                    'CountriesSelectors' && (
                    <div className='orderFormCountries'>
                      <Countries setCount={setCount} />
                    </div>
                  )}
                  {/* {window.sessionStorage.getItem('activeComponent') ===
                    'MissionType' && (
                    <div className='orderDetailsMissionType'>
                      <MissionType setCount={setCount} />
                    </div>
                  )} */}
                  {window.sessionStorage.getItem('activeComponent') ===
                    'MissionTypeOptions' && (
                    <div className='orderDetailsMissionTypeContainer'>
                      <div className='orderDetailsMissionType'>
                        <MissionTypeOptions setCount={setCount} />
                      </div>
                      {window.sessionStorage.getItem(
                        'constructionCardSelectedOption'
                      ) == 'true' ? (
                        <div className='orderDetailsMissionType'>
                          <ConstructionCard setCount={setCount} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  {window.sessionStorage.getItem('activeComponent') ===
                    'NeedConstructionCard' && (
                    <div className='orderDetailsMissionType'>
                      <ConstructionCard setCount={setCount} />
                    </div>
                  )}
                  {window.sessionStorage.getItem('activeComponent') ===
                    'Employees' && (
                    <div className='orderNumberOfEmployees'>
                      <NumberOfEmployees setCount={setCount} />
                    </div>
                  )}
                  {window.sessionStorage.getItem('activeComponent') ===
                    'DatePicker' && (
                    <div className='orderFormDatePicker'>
                      <Datepicker setCount={setCount} />
                    </div>
                  )}
                  {window.sessionStorage.getItem('activeComponent') ===
                    'InformationDocuments' && (
                    <div className='orderFormInformationDocument'>
                      <InformationDocuments setCount={setCount} />
                    </div>
                  )}
                  {window.sessionStorage.getItem('activeComponent') ===
                    'Location' && (
                    <div className='orderFormLocation'>
                      <LocationForm />
                    </div>
                  )}

                  {window.sessionStorage.getItem('activeComponent') ===
                    'Lodging' && (
                    <div className='orderFormLodging'>
                      <LodgingForm
                        startDate={startDate}
                        endDate={endDate}
                        lodgingOption={selectedOptionLodgingType}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        handleLodgingOptionChange={
                          handleSelectChangeLodgingType
                        }
                      />
                    </div>
                  )}
                  {window.sessionStorage.getItem('activeComponent') ===
                    'Payment' && (
                    <div className='orderFormPaymentMethod'>
                      <PaymentMethod companyId={companyId} />
                    </div>
                  )}
                  {!isErrorHidden && (
                    <div className='error'>
                      <p>{t('orderPageStrings.error')}</p>
                    </div>
                  )}
                  <div className='orderFormNavigationButtons'>
                    {isNextButtonHidden ? (
                      <div></div>
                    ) : (
                      <button
                        id='formNext'
                        className='formButton'
                        onClick={handleClickNextButton}
                      >
                        <p>{t('globalStrings.next')}</p>
                      </button>
                    )}
                    {isBackButtonHidden ? (
                      <></>
                    ) : (
                      <button
                        id='formBack'
                        className='formButton'
                        onClick={handleClickBackButton}
                      >
                        <p>{t('globalStrings.back')}</p>
                      </button>
                    )}
                  </div>
                </div>
                <div className='orderFormContainerRight' key={count}>
                  <h2>{t('orderPageStrings.commandDetails.title')}</h2>
                  <div className='scroller'>
                    {window.sessionStorage.getItem('isClickedConstruction') ===
                    'true' ? (
                      <p>
                        {t('orderPageStrings.missionSector.title')}{' '}
                        {t('orderPageStrings.missionSector.construction')}
                      </p>
                    ) : window.sessionStorage.getItem('isClickedOther') ===
                      'true' ? (
                      <p>
                        {t('orderPageStrings.missionSector.title')}{' '}
                        {t('orderPageStrings.missionSector.other')}
                      </p>
                    ) : (
                      <p></p>
                    )}
                    {window.sessionStorage.getItem(
                      'selectedOptionCountryFromText'
                    ) == null ||
                    window.sessionStorage.getItem(
                      'selectedOptionCountryFromText'
                    ) == '' ||
                    window.sessionStorage.getItem(
                      'selectedOptionCountryFrom'
                    ) == null ||
                    window.sessionStorage.getItem(
                      'selectedOptionCountryFrom'
                    ) == '' ? (
                      <p></p>
                    ) : (
                      <>
                        {currentLanguage == 'fr' ? (
                          <p>
                            {t('orderPageStrings.missionCountry.from')}{' '}
                            {window.sessionStorage.getItem(
                              'selectedOptionCountryFrom'
                            )}
                          </p>
                        ) : (
                          <p>
                            {t('orderPageStrings.missionCountry.from')}{' '}
                            {window.sessionStorage.getItem(
                              'selectedOptionCountryFrom'
                            )}
                          </p>
                        )}
                      </>
                    )}
                    {window.sessionStorage.getItem(
                      'selectedOptionCountryToText'
                    ) == null ||
                    window.sessionStorage.getItem(
                      'selectedOptionCountryToText'
                    ) == '' ||
                    window.sessionStorage.getItem('selectedOptionCountryTo') ==
                      null ||
                    window.sessionStorage.getItem('selectedOptionCountryTo') ==
                      '' ? (
                      <p></p>
                    ) : (
                      <>
                        {currentLanguage == 'fr' ? (
                          <p>
                            {t('orderPageStrings.missionCountry.to')}{' '}
                            {window.sessionStorage.getItem(
                              'selectedOptionCountryTo'
                            )}
                          </p>
                        ) : (
                          <p>
                            {t('orderPageStrings.missionCountry.to')}{' '}
                            {window.sessionStorage.getItem(
                              'selectedOptionCountryTo'
                            )}
                          </p>
                        )}
                      </>
                    )}
                    {/* {window.sessionStorage.getItem('selectedOption') == null ||
                    window.sessionStorage.getItem('selectedOption') == '' ? (
                      <p></p>
                    ) : (
                      <p>
                        {t('orderPageStrings.missionType.title')}{' '}
                        {t(
                          `orderPageStrings.missionType.${window.sessionStorage.getItem(
                            'selectedOption'
                          )}`
                        )}
                      </p>
                    )} */}
                    {window.sessionStorage.getItem('selectedOptionNumber') ==
                      null ||
                    window.sessionStorage.getItem('selectedOptionNumber') ==
                      '' ? (
                      <p></p>
                    ) : (
                      <p>
                        {t('orderPageStrings.numberOfEmployees.title')}{' '}
                        {window.sessionStorage.getItem('selectedOptionNumber')}
                      </p>
                    )}
                    {window.sessionStorage.getItem('sipsiSelectedOption') ==
                      null ||
                    window.sessionStorage.getItem('sipsiSelectedOption') ==
                      '' ? (
                      <p></p>
                    ) : (
                      <>
                        {window.sessionStorage
                          .getItem('sipsiSelectedOption')!
                          .replace(/"/g, '') == 'true' ? (
                          <p>
                            &emsp;&emsp;&emsp;
                            {t(
                              'orderPageStrings.missionTypeOptions.sipsiTitle'
                            )}{' '}
                            {t('orderPageStrings.missionTypeOptions.yesOption')}
                          </p>
                        ) : (
                          <p>
                            &emsp;&emsp;&emsp;
                            {t(
                              'orderPageStrings.missionTypeOptions.sipsiTitle'
                            )}{' '}
                            {t('orderPageStrings.missionTypeOptions.noOption')}
                          </p>
                        )}
                      </>
                    )}
                    {window.sessionStorage.getItem(
                      'representationSelectedOption'
                    ) == null ||
                    window.sessionStorage.getItem(
                      'representationSelectedOption'
                    ) == '' ? (
                      <p></p>
                    ) : (
                      <>
                        {window.sessionStorage
                          .getItem('representationSelectedOption')!
                          .replace(/"/g, '') == 'true' ? (
                          <p>
                            &emsp;&emsp;&emsp;
                            {t(
                              'orderPageStrings.missionTypeOptions.representationTitle'
                            )}{' '}
                            {t('orderPageStrings.missionTypeOptions.yesOption')}
                          </p>
                        ) : (
                          <p>
                            &emsp;&emsp;&emsp;
                            {t(
                              'orderPageStrings.missionTypeOptions.representationTitle'
                            )}{' '}
                            {t('orderPageStrings.missionTypeOptions.noOption')}
                          </p>
                        )}
                      </>
                    )}
                    {window.sessionStorage.getItem(
                      'constructionCardSelectedOption'
                    ) == null ||
                    window.sessionStorage.getItem(
                      'constructionCardSelectedOption'
                    ) == '' ? (
                      <p></p>
                    ) : (
                      <>
                        {window.sessionStorage
                          .getItem('constructionCardSelectedOption')!
                          .replace(/"/g, '') == 'true' ? (
                          <p>
                            &emsp;&emsp;&emsp;
                            {t(
                              'orderPageStrings.missionTypeOptions.constructionCardTitle'
                            )}{' '}
                            {t('orderPageStrings.missionTypeOptions.yesOption')}
                            {' ( ' +
                              window.sessionStorage.getItem(
                                'selectedNewCardOption'
                              ) +
                              ' '}
                            {t('creation(s)') + ' )'}
                          </p>
                        ) : (
                          <p>
                            &emsp;&emsp;&emsp;
                            {t(
                              'orderPageStrings.missionTypeOptions.constructionCardTitle'
                            )}{' '}
                            {t('orderPageStrings.missionTypeOptions.noOption')}
                          </p>
                        )}
                      </>
                    )}
                    {window.sessionStorage.getItem('startDate') == null ||
                    window.sessionStorage.getItem('startDate') == 'null' ||
                    window.sessionStorage.getItem('startDate') == '' ? (
                      <p></p>
                    ) : (
                      <p>
                        {t('orderPageStrings.datePicker.start')}{' '}
                        {window.sessionStorage.getItem('startDateFormatted')}
                        {numberOfMonths}
                      </p>
                    )}
                    {window.sessionStorage.getItem('endDate') == null ||
                    window.sessionStorage.getItem('endDate') == 'null' ||
                    window.sessionStorage.getItem('endDate') == '' ? (
                      <p></p>
                    ) : (
                      <p>
                        {t('orderPageStrings.datePicker.end')}{' '}
                        {window.sessionStorage.getItem('endDateFormatted')}
                      </p>
                    )}
                    {getString() == '' ? (
                      <p></p>
                    ) : (
                      <p>
                        {t('orderPageStrings.informationDocuments.title')}{' '}
                        {t('orderPageStrings.informationDocuments.fr')}{' '}
                        {getString()}
                      </p>
                    )}
                  </div>
                  <hr />
                  <i className='mention'>{mention}</i>
                  <pre className='horizontRightContent'>
                    <p>
                      {t('orderPageStrings.commandDetails.totalInclVat')}{' '}
                      {(
                        Number(window.sessionStorage.getItem('totalPrice')) +
                        (Number(window.sessionStorage.getItem('totalPrice')) *
                          vatValue) /
                          100
                      ).toFixed(2)}
                      €
                    </p>
                    <p>
                      {t('orderPageStrings.commandDetails.totalExclVat')}{' '}
                      {Number(
                        window.sessionStorage.getItem('totalPrice')
                      ).toFixed(2)}
                      €
                    </p>
                  </pre>
                  <br />
                  {/* <InvoiceGenerator
                    invoiceNumber='FACSPW42032024404'
                    missionReference='SPWFR032024TRC01'
                    buyerId={1}
                    itemsDetail={[
                      {
                        description: 'Représentation',
                        quantity: 2,
                        amount: 30,
                      },
                      {
                        description: 'Déclaration SIPSI',
                        quantity: 2,
                        amount: 78,
                      },
                      { description: 'Carte BTP', quantity: 2, amount: 100 },
                      {
                        description: "Documents d'information",
                        quantity: 2,
                        amount: 6,
                      },
                      {
                        description: 'Offre tranquillité',
                        quantity: 2,
                        amount: 132,
                      },
                    ]}
                    amount='346'
                    // {`${
                    //   Number(window.sessionStorage.getItem('totalPrice'))
                    // }`}
                    mention={mention}
                    vatRate={vatValue}
                  /> */}
                </div>
              </div>
            </>
          ))}
      </>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(OrderForm);
