import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    declare: false,
    subscribe: null,
    unsubscribe: null,
    getMapping: null,
    getTA: null,
    getDeclarations: null,
    declaration: null,
    getLines: null,
    validate: null,
    remove: null,
    getCompanySubscribed: null,
  },
  errors: {
    declare: null,
    subscribe: null,
    unsubscribe: null,
    getMapping: null,
    getTA: null,
    getDeclarations: null,
    declaration: null,
    getLines: null,
    validate: null,
    remove: null,
    getCompanySubscribed: null,
  },
  loading: {
    declare: false,
    subscribe: false,
    unsubscribe: false,
    getMapping: false,
    getTA: false,
    getDeclarations: false,
    declaration: false,
    getLines: false,
    validate: false,
    remove: false,
    getCompanySubscribed: false,
  },

  companySubscribed: null,
  declarations: null,
  declaration: null,
  lines: null,
  mapping: null,
  declare: null,
  ta: null,

  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // getCompanySubscribed
    case actionTypes.GET_COMPANY_SUBSCRIBED:
      return produce(state, (draft) => {
        draft.loading.getCompanySubscribed = true;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = null;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = true;
        draft.companySubscribed = action.payload;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = true;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = false;
        !draft.error.includes('companySubscribed') &&
          draft.error.push('companySubscribed');
      });

    // Declare
    case actionTypes.DECLARE_LOADING:
      return produce(state, (draft) => {
        draft.loading.declare = true;
      });
    case actionTypes.DECLARE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.declare = null;
        draft.loading.declare = false;
        draft.success.declare = true;
        draft.declare = action.payload;
      });
    case actionTypes.DECLARE_ERROR:
      return produce(state, (draft) => {
        draft.errors.declare = true;
        draft.loading.declare = false;
        draft.success.declare = false;
      });

    //Subscribe
    case actionTypes.SUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.subscribe = null;
        draft.loading.subscribe = false;
        draft.success.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.subscribe = true;
        draft.loading.subscribe = false;
        draft.success.subscribe = false;
      });

    //Unsubscribe
    case actionTypes.UNSUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = null;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = true;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = false;
      });

    //GetMapping
    case actionTypes.GETMAPPING_LOADING:
      return produce(state, (draft) => {
        draft.loading.getMapping = true;
      });
    case actionTypes.GETMAPPING_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getMapping = null;
        draft.loading.getMapping = false;
        draft.success.getMapping = true;
        draft.mapping = action.payload;
      });
    case actionTypes.GETMAPPING_ERROR:
      return produce(state, (draft) => {
        draft.errors.getMapping = true;
        draft.loading.getMapping = false;
        draft.success.getMapping = false;
        !draft.error.includes('mapping') && draft.error.push('mapping');
      });

    //GetTA
    case actionTypes.GETTA_LOADING:
      return produce(state, (draft) => {
        draft.loading.getTA = true;
      });
    case actionTypes.GETTA_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getTA = null;
        draft.loading.getTA = false;
        draft.success.getTA = true;
        draft.ta = action.payload;
      });
    case actionTypes.GETTA_ERROR:
      return produce(state, (draft) => {
        draft.errors.getTA = true;
        draft.loading.getTA = false;
        draft.success.getTA = false;
        !draft.error.includes('tiers and activities') &&
          draft.error.push('tiers and activities');
      });

    // GetDeclarations
    case actionTypes.GETDECLARATIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getDeclarations = true;
      });
    case actionTypes.GETDECLARATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getDeclarations = null;
        draft.loading.getDeclarations = false;
        draft.success.getDeclarations = true;
        draft.declarations = action.payload;
      });
    case actionTypes.GETDECLARATIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getDeclarations = true;
        draft.loading.getDeclarations = false;
        draft.loading.getDeclarations = false;
        !draft.error.includes('declarations') &&
          draft.error.push('declarations');
      });

    // GetDeclaration details
    case actionTypes.GETDECLARATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.declaration = true;
      });
    case actionTypes.GETDECLARATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.declaration = null;
        draft.loading.declaration = false;
        draft.success.declaration = true;
        draft.declaration = action.payload;
      });
    case actionTypes.GETDECLARATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.declaration = true;
        draft.loading.declaration = false;
        draft.success.declaration = false;
        !draft.error.includes('declaration') && 
        draft.error.push('declaration');
      });

    // GetDeclaration statement
    case actionTypes.GETLINES_LOADING:
      return produce(state, (draft) => {
        draft.loading.getLines = true;
      });

    case actionTypes.GETLINES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getLines = null;
        draft.loading.getLines = false;
        draft.success.getLines = true;
        draft.lines = action.payload;
      });

    case actionTypes.GETLINES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getLines = true;
        draft.loading.getLines = false;
        draft.success.getLines = false;
        !draft.error.includes('lines') && draft.error.push('lines');
      });

    // Validate
    case actionTypes.VALIDATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.validate = true;
      });

    case actionTypes.VALIDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.validate = null;
        draft.loading.validate = false;
        draft.success.validate = true;
      });

    case actionTypes.VALIDATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.validate = true;
        draft.loading.validate = false;
        draft.success.validate = false;
      });

    // Remove
    case actionTypes.REMOVE_LOADING:
      return produce(state, (draft) => {
        draft.loading.remove = true;
      });

    case actionTypes.REMOVE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.remove = null;
        draft.loading.remove = false;
        draft.success.remove = true;
      });

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.errors.remove = true;
        draft.loading.remove = false;
        draft.success.remove = false;
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
