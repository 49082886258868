import CustomsHelper from 'views/Customs/Clerk/CustomsClerk';
import CustomsHelperNewFile from 'views/Customs/Clerk/NewFile/newFile';
import paths from './paths';

export default [
  {
    private: true,
    path: paths.customsHelper,
    component: CustomsHelper,
    exact: true,
    authorizedRoles: ['operator'],
  },
  {
    private: true,
    path: paths.customsHelperFileEdition,
    component: CustomsHelperNewFile,
    exact: true,
    authorizedRoles: ['operator'],
  },
  {
    private: true,
    path: paths.customsHelperNewFile,
    component: CustomsHelperNewFile,
    exact: true,
    authorizedRoles: ['operator'],
  },
];
