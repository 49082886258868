import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Header, Segment } from 'semantic-ui-react';
import EmptyTable from 'components/EmptyTable';
import { Period, Spacer, State, Title } from './styles';

const Index = ({
  key,
  data,
  iso,
  monitoringPathToDetail
}: CollectionboxMainListProps) => {

  const buildLink = (companyId: number, debId: string, iso?: string) => {

    return iso ?
    `/fiscal/collectionbox/country/:iso/:companyId/:collectionboxId`.replace(':iso', iso).replace(':companyId', `${companyId}`).replace(':collectionboxId', debId) : monitoringPathToDetail ?
    `/fiscal/collectionbox/:companyId/:collectionboxId`.replace(':companyId', `${companyId}`).replace(':collectionboxId', debId) :
    `/fiscal/collectionbox/:companyId/:collectionboxId`.replace(':companyId', `${companyId}`).replace(':collectionboxId', debId)
  }

  return (
    <>
      {data === null && (
        <Segment>
          <Header
            textAlign='center'
            size='small'
          >{`No Collectionbox have been uploaded.`}</Header>
        </Segment>
      )}

      {data !== null && (
        <Table sortable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Tax period
              </Table.HeaderCell>
  
              <Table.HeaderCell>
                Type
              </Table.HeaderCell>
              <Table.HeaderCell>
                id
              </Table.HeaderCell>
              <Table.HeaderCell>
                Company
              </Table.HeaderCell>
              <Table.HeaderCell>
                Country
              </Table.HeaderCell>
              <Table.HeaderCell>
                Created at
              </Table.HeaderCell>
              <Table.HeaderCell>
                Client reference
              </Table.HeaderCell>
              <Table.HeaderCell>
                Emitter
              </Table.HeaderCell> 
              <Table.HeaderCell>
                Followers
              </Table.HeaderCell>                         
              <Table.HeaderCell>
                State
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
          {
          (data && data.length === 0 && <EmptyTable colNumber={10} />) || 
          (data.map((e, i) => {
              return (
                <Table.Row key={i} verticalAlign='middle'>

                  <Link to={{ 
                    pathname: buildLink(e.Company_Id, `${e.id}`, iso), 
                    ...monitoringPathToDetail && {state: {locPath: 'monitoring'}} 
                    }} className='table-link'>

                  <Table.Cell key={i+1} style={{ width: '200px'}}>
                    <Period>
                      {new Date(e.taxPeriod).toLocaleDateString('en', {
                        month: 'long',
                      })}
                      <Spacer>
                      {new Date(e.taxPeriod).toLocaleDateString('en', {
                        year: 'numeric',
                      })}
                      </Spacer>
                    </Period>                                     
                  </Table.Cell>
                  <Table.Cell key={i+2} style={{ width: '200px'}}> 
                  {e.DeclarationHasCategory && 
                      e.DeclarationHasCategory.FiscalCollectionBoxCategory.name}
              
                  </Table.Cell>                             
                  <Table.Cell key={i+3} style={{ width: '180px'}}> 
                  {e.id}
                  </Table.Cell>                                
                  <Table.Cell key={i+4} style={{ width: '200px'}}>              
                      {e.Company.name}
                  </Table.Cell>                               
                  <Table.Cell key={i+5}> 
                    {e.country}                 
                  </Table.Cell>                                   
                  <Table.Cell key={i+6} style={{ width: '150px'}}>                    
                    {new Date(e.createdAt).toLocaleDateString('en', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                    })}
                  </Table.Cell>
                  <Table.Cell key={i+7} style={{ width: '180px'}}>{e.clientReference}</Table.Cell>
                  <Table.Cell key={i+8} style={{ width: '250px'}}>{e.emitter.fullname}</Table.Cell>
                  <Table.Cell key={i+9} style={{ width: '250px'}}>{e.follower && (
                    <ul style={{ margin: '0'}}>
                      {e.follower.map((e, i) => (
                        <li key={i}>{e.operator}</li>
                      ))}
                    </ul>
                  )}</Table.Cell>
                  <Table.Cell key={i+10} style={{ width: '120px'}}>
                    <State defaultValue={
                       !e.DeclarationHasCategory ? 'red' : e.DeclarationHasCategory.step === 1 ? 
                       '#00619b' :  e.DeclarationHasCategory.step === 4 ? 
                       '#009900' : (e.DeclarationHasCategory.step === 2 && e.DeclarationHasCategory.step === 3) ? 
                       '#00619b' : '#dda80b'}>{!e.DeclarationHasCategory ? 'Canceled' : e.DeclarationHasCategory.step === 1 ? 'Unprocessed' : e.state}</State></Table.Cell>  
                  </Link>
                </Table.Row>        
              )})
          )}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

interface CollectionboxMainListProps {
  key: number;
  data: Array<{
    id: number;
    Company: any;
    Company_Id: number;
    emitter: {
      id: number, 
      fullname: string
    };
    follower: any;
    clientReference: string;
    DeclarationHasCategory: any;
    taxPeriod: string;
    country: string;
    state: string;
    createdAt: string;
    Contact_Name: string;
  }>;
  companyId?: number;
  iso?: string;
  monitoringPathToDetail?: boolean;
}

export default Index;
