import React from 'react';

import Button from 'components/Button';

const ButtonBack = ({ history, addAction }: ButtonBackProps) => {
  return (
    <Button
      labelPosition='left'
      icon='angle left'
      type='button'
      size='medium'
      content='Back'
      onClick={() => {
        addAction && addAction();
        history.goBack()
      }}
    />
  );
};

interface ButtonBackProps {
  history: any;
  addAction?: () => void
}

export default ButtonBack;
