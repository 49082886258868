import styled from '@emotion/styled';

import theme from 'theme';

const { colors } = theme.getTheme();

export const MessagePlacholder = styled.div(() => ({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  height: '100%',
  zIndex: 1000,
}));

export const MessageWrapper = styled.div(({ error, reload }: any) => ({
  zIndex: 1500,
  display: 'block',
  margin: '5rem auto',
  padding: '1rem',
  width: '35rem',
  height: '12rem',
  borderRadius: '1rem',
  border: '1px solid',
  backgroundColor: colors.backgroundError,
  borderColor: colors.borderError,
  color: colors.textError,
}));

export const CloseButton = styled.span(({ error, reload }: any) => ({
  position: 'absolute',
  right: '1.5rem',
  cursor: 'pointer',

  '&:before': {
    position: 'absolute',
    content: '""',
    height: '15px',
    width: '3px',
    borderRadius: '5px',
    backgroundColor: error ? colors.textError : reload ? colors.textReload : colors.textSuccess,
    transform: 'rotate(45deg)',
  },
  '&:after': {
    position: 'absolute',
    content: '""',
    height: '15px',
    width: '3px',
    borderRadius: '5px',
    backgroundColor: error ? colors.textError : reload ? colors.textReload : colors.textSuccess,
    transform: 'rotate(-45deg)',
  },
}));
