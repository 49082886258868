import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const newsApi = routesApi.news;
  return {
    create(data: dataReqI) {
      return api.request({
        method: newsApi.create.method,
        route: newsApi.create.path,
        data: data.data,
      });
    },
    getAll() {
      return api.request({
        method: newsApi.getAll.method,
        route: newsApi.getAll.path,
      });
    },
    // getAllReadNews(data: dataReqI) {
    //   return api.request({
    //     method: newsApi.getAllReadNews.method,
    //     route: newsApi.getAllReadNews.path,
    //     params: data.params,
    //   });
    // },
    isReadByUser(data: dataReqI) {
      return api.request({
        method: newsApi.isReadByUser.method,
        route: newsApi.isReadByUser.path,
        params: data.params,
      });
    },
    readNews(data: dataReqI) {
      return api.request({
        method: newsApi.readNews.method,
        route: newsApi.readNews.path,
        params: data.params,
      });
    },
    notOpenedNews(data: dataReqI) {
      return api.request({
        method: newsApi.notOpenedNews.method,
        route: newsApi.notOpenedNews.path,
        params: data.params,
      });
    },
    getOne(data: dataReqI) {
      return api.request({
        method: newsApi.getOne.method,
        route: newsApi.getOne.path,
        params: data.params,
      });
    },
    remove(data: dataReqI) {
      return api.request({
        method: newsApi.remove.method,
        route: newsApi.remove.path,
        params: data.params,
      });
    },
    removeAll() {
      return api.request({
        method: newsApi.removeAll.method,
        route: newsApi.removeAll.path,
      });
    },
    update(data: dataReqI) {
      return api.request({
        method: newsApi.update.method,
        route: newsApi.update.path,
        params: data.params,
        data: data.data,
      });
    },

  };
};
