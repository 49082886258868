import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { injectIntl } from 'react-intl';
import { actions, selectors } from 'store';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Grid, Segment, Tab } from 'semantic-ui-react';
import Wrapper from 'components/core/Wrapper/Wrapper';
import EmptyTable from '../../../../../../components/EmptyTable';
import LoaderInverted from 'components/LoaderInverted';
import YearFilters from '../YearFilters/yearFilters';

const Index = ({
  dispatch,
  customsForComparison,
  comparisonLoading,
  apexChartsCompanies,
}: any) => {

  const [comparisonsOptions, setComparisonOptions]: any = useState(null)
  const [comparisonsSeries, setComparisonSeries]: any = useState(null)


  useEffect(() => {
    dispatch(
      actions.customsStatistics.getDeclarationForComparison({
        data: {
          companyId: null,
          year: `${new Date().getFullYear()}`,
        }
      })
    );

    return () => {
      dispatch(
        actions.customsStatistics.reset([
          'customsForComparison',
          'error',
        ])
      );
    }
  }, [dispatch])


  useEffect(()=>{
    if (customsForComparison !== null) {
      setComparisonOptions(customsForComparison.options)
      setComparisonSeries(customsForComparison.series)
    }

  } , [customsForComparison])

  
  const handleSubmitYears = (dataFilter) => {
      dispatch(actions.customsStatistics.reset(['customsForComparison', 'error']));
      dispatch(
        actions.customsStatistics.getDeclarationForComparison({
          data: {
            companyId: dataFilter.company || null,
            year: `${dataFilter.period}`,
          }
        })
      );
  }
 

  return (
    <Tab.Pane loading={comparisonLoading}>
    <Wrapper height='100%'>                   
      <Wrapper
        display='flex'
        justifyContent='center'
        height='100%'
        width='100%'
      >
      <Wrapper>
      <YearFilters
        apexChartsCompanies={apexChartsCompanies}
        handleSubmitYears={handleSubmitYears}
      /> 

      {(!comparisonsSeries && <EmptyTable colNumber={5} />) || (
         <Grid>  
         <Grid.Row>
           <h3>Comparison</h3>
         </Grid.Row> 
             <Grid.Row>                       
               <Grid.Column>
                  <Segment piled>
                    {(!comparisonsSeries && <LoaderInverted />) ||  (
                      <Chart options={comparisonsOptions} series={comparisonsSeries} type={comparisonsOptions.chart.type} height={comparisonsOptions.chart.height} width='100%' />
                    )}
                  </Segment>
               </Grid.Column>
             </Grid.Row>                         
        </Grid>
      )}
      </Wrapper>
     </Wrapper>
     </Wrapper>
    </Tab.Pane>
    );
  };
  
  const mapStateToProps = (state) => {
  return {
    customsForComparison: selectors.customsStatistics.getCustomsForComparisonSelector(state),
    comparisonLoading: selectors.customsStatistics.getCustomsForComparisonLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
