import actionTypes from 'store/news/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.CREATE:
        try {
          next({ type: actionTypes.CREATE_LOADING });
          await request.create({
            ...action.payload,
          });
          next({
            type: actionTypes.CREATE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ALL:
        try {
          next({ type: actionTypes.GET_ALL_LOADING });
          const response = await request.getAll();
          next({
            type: actionTypes.GET_ALL_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_ALL_READ:
          try {
            next({ type: actionTypes.GET_ALL_READ_LOADING });
            const resp = await request.notOpenedNews({ ...action.payload });
            next({
              type: actionTypes.GET_ALL_READ_SUCCESS,
              payload: resp.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_ALL_READ_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
          break;

      case actionTypes.IS_READ_BY_USER:
        try {
          next({ type: actionTypes.IS_READ_BY_USER_LOADING });
          const response = await request.isReadByUser({ ...action.payload });
          next({
            type: actionTypes.IS_READ_BY_USER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.IS_READ_BY_USER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.READ_NEWS:
        try {
          next({ type: actionTypes.READ_NEWS_LOADING });
          await request.readNews({ ...action.payload });
          next({
            type: actionTypes.READ_NEWS_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.READ_NEWS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ONE:
        try {
          next({ type: actionTypes.GET_ONE_LOADING });
          const response = await request.getOne({ ...action.payload });
          next({
            type: actionTypes.GET_ONE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.REMOVE:
        try {
          next({ type: actionTypes.REMOVE_LOADING });
          await request.remove({
            ...action.payload,
          });
          next({
            type: actionTypes.REMOVE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.REMOVE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.REMOVE_ALL:
        try {
          next({ type: actionTypes.REMOVE_ALL_LOADING });
          await request.removeAll();
          next({
            type: actionTypes.REMOVE_ALL_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.REMOVE_ALL_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE:
        try {
          next({ type: actionTypes.UPDATE_LOADING });
          await request.update({
            ...action.payload,
          });
          next({
            type: actionTypes.UPDATE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
