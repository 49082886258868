import React, { useEffect, useRef, useState } from 'react';
import { Form, Divider, Loader, Icon } from 'semantic-ui-react';
import { Field, Formik } from 'formik';

import Button from 'components/Button';
import TextArea from 'components/Form/TextArea';
import {
  MainWrapper,
  UserName,
  MessageDate,
  UserMessage,
  MessageWrapper,
  MessageSendingWrapper,
  NameDateWrapper,
  MessageSender,
  IconMessage,
  MessagesWrapper,
  Title,
  LineSeparator,
} from './VatChatStyles';
import { longDate } from 'services/helper/dateFormater';

import { textSchema } from 'interfaces/formSchema';

const VatChat = ({
  messages,
  userRole,
  chatLoading,
  isOwner,
  handleSubmitMessage,
}: VatChatProps) => {
  const messageEl = useRef(null);

  const [messageSubmited, setmessageSubmited] = useState(null);

  useEffect(() => {
    if(messageEl || messages){
      const observer = new ResizeObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.target.scroll({
            top: mutation.target.scrollHeight,
            behavior: 'smooth' // Optionally add smooth scrolling
          });
        });
      });
      observer.observe(messageEl.current);
    }
  }, [messages, messageEl]);


  useEffect(() => {
    setmessageSubmited(null);
  }, [messages]);

  const submitMessage = (values, actions) => {
    actions.resetForm({ message: '' });
    setmessageSubmited(values.message);
    handleSubmitMessage(values);
  };

  return (
    <MainWrapper height='900px'>
      <Title>News feed</Title>
      <LineSeparator />
      <MessagesWrapper ref={messageEl}>
        {messages &&
          messages.map((msg, i) => (
            msg.hideToCustomer === null && userRole === 'customer' ? 
            <MessageWrapper
              key={i}
              new={
                userRole === 'customer'
                  ? msg.readByCustomer
                  : userRole === 'operator'
                  ? msg.readByOperator
                  : true
              }
            >
              <NameDateWrapper>
                <UserName>
                  {msg.User_Id
                    ? `${msg.User.lastName} ${msg.User.firstName}`
                    : 'SYSTEM'}
                </UserName>
                <MessageDate>
                  {longDate(new Date(msg.createdAt))}
                </MessageDate>
              </NameDateWrapper>

              <UserMessage>{msg.message}</UserMessage>
              </MessageWrapper>
                  : (userRole === 'operator' && 
                  <MessageWrapper
                    key={i}
                    new={userRole === 'operator'
                        ? msg.readByOperator
                        : true
                    }
                  >
                    <NameDateWrapper>
                      <UserName>
                        {msg.User_Id
                          ? `${msg.User.lastName} ${msg.User.firstName}`
                          : 'SYSTEM'}
                      </UserName>
                      <MessageDate>
                        {longDate(new Date(msg.createdAt))}
                      </MessageDate>
                    </NameDateWrapper>
      
                    <UserMessage>{msg.message}</UserMessage>
                      {userRole === 'operator'
                          ? msg.readByCustomer ? (<IconMessage><Icon name='check circle' /></IconMessage>) : null
                          : null}
                    </MessageWrapper>                        
                  )
                 ))}

      </MessagesWrapper>
      <MessageSender>
        <MessageSendingWrapper active={messageSubmited}>
          {messageSubmited && (
            <>
              {messageSubmited}
              <Loader inline active size='mini' />
            </>
          )}
        </MessageSendingWrapper>
        <Formik
          initialValues={{
            message: '',
          }}
          onSubmit={submitMessage}
          validationSchema={textSchema}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                name='message'
                type='text'
                component={TextArea}
                placeholder='Message...'
                value={values.message}
                meta={{
                  errors,
                  touched,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  values,
                }}
              />
              <Divider hidden />
              <Button
                disabled={!isValid}
                type='submit'
                content='Send'
                labelPosition='left'
                icon='edit'
              />
            </Form>
          )}
        </Formik>
      </MessageSender>
    </MainWrapper>
  );
};

interface VatChatProps {
  messages: Array<{
    readByOperator: boolean;
    readByCustomer: boolean;
    message: string;
    createdAt: string;
    User_Id: number;
    hideToCustomer: boolean;
    User: {
      lastName: string;
      firstName: string;
    } | null;
  }>;
  chatLoading: boolean;
  userRole: 'customer' | 'operator';
  isOwner: boolean
  handleSubmitMessage: (value: { message: string }) => void;
}

export default VatChat;
