import styled from "@emotion/styled";
import theme from "theme";

const colors = theme.getTheme().colors;

const selectColor = (color) => {
  return Object.keys(colors).includes(color) ? colors[color] : color;
};

export const GridContainer = styled.div(() => ({
  display: "grid",
  gridTemplateColumns:
    "minmax(10em, 50em) 1em minmax(5rem, 13em) 1em minmax(5rem, 13em)",
  gridTemplateRows: "3.5em 4.5em 4em repeat(12, auto)",
  gap: "0 0em",
  gridTemplateAreas: `
  '. . . . OfferType2Offset'
  'Title . OfferType1Offset . Blank '
  '. . OfferType1 . OfferType2' 
  'Feature1 Gaps1 F1o1 Gaps10 F1o2' 
  'Feature2 Gaps2 F201 Gaps11 F202' 
  'Feature3 Gaps3 F301 Gaps12 F302' 
  'Feature4 Gaps4 F401 Gaps13 F402' 
  'Feature5 Gaps5 F501 Gaps14 F502' 
  'Feature6 Gaps6 F601 Gaps15 F602' 
  'Feature7 Gaps7 F701 Gaps16 F702' 
  'Feature8 Gaps8 F801 Gaps17 F802' 
  // 'Feature9 Gaps9 F901 Gaps19 F902' 
  'Feature10 Gaps9 F1001 Gaps18 F1002' 
  '. . ActionOffer1 . ActionOffer2' 
  'Asterisk . . . .'`,
}));

export const Title = styled.div(() => ({ gridArea: "Title" }));

export const OfferType1Offset = styled.div((props: any) => ({
  gridArea: "OfferType1Offset",
  backgroundColor: selectColor(props.backgroundColor),
  borderRadius: "2.5rem 2.5rem 0 0",
}));
export const Blank = styled.div((props: any) => ({
  gridArea: "Blank",
  backgroundColor: selectColor(props.backgroundColor),
}));
export const OfferType2Offset = styled.div((props: any) => ({
  gridArea: "OfferType2Offset",
  backgroundColor: selectColor(props.backgroundColor),
  borderRadius: "2.5rem 2.5rem 0 0",
}));

export const OfferType2 = styled.div((props: any) => ({
  gridArea: "OfferType2",
  backgroundColor: selectColor(props.backgroundColor),
}));
export const OfferType1 = styled.div((props: any) => ({
  gridArea: "OfferType1",
  backgroundColor: selectColor(props.backgroundColor),
}));

export const Gaps = styled.div((props: any) => ({
  gridArea: `Gaps${props.gapName}`,
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));

export const Feature1 = styled.div(() => ({
  gridArea: "Feature1",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Feature2 = styled.div(() => ({
  gridArea: "Feature2",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Feature3 = styled.div(() => ({
  gridArea: "Feature3",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Feature4 = styled.div(() => ({
  gridArea: "Feature4",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Feature5 = styled.div(() => ({
  gridArea: "Feature5",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Feature6 = styled.div(() => ({
  gridArea: "Feature6",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Feature7 = styled.div(() => ({
  gridArea: "Feature7",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Feature8 = styled.div(() => ({
  gridArea: "Feature8",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Feature9 = styled.div(() => ({
  gridArea: "Feature9",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Feature10 = styled.div(() => ({
  gridArea: "Feature10",
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const Asterisk = styled.div(() => ({ gridArea: "Asterisk" }));

export const F1o1 = styled.div((props: any) => ({
  gridArea: "F1o1",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F1o2 = styled.div((props: any) => ({
  gridArea: "F1o2",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F201 = styled.div((props: any) => ({
  gridArea: "F201",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F202 = styled.div((props: any) => ({
  gridArea: "F202",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F301 = styled.div((props: any) => ({
  gridArea: "F301",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F302 = styled.div((props: any) => ({
  gridArea: "F302",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F401 = styled.div((props: any) => ({
  gridArea: "F401",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F402 = styled.div((props: any) => ({
  gridArea: "F402",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F501 = styled.div((props: any) => ({
  gridArea: "F501",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F502 = styled.div((props: any) => ({
  gridArea: "F502",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F601 = styled.div((props: any) => ({
  gridArea: "F601",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F602 = styled.div((props: any) => ({
  gridArea: "F602",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F701 = styled.div((props: any) => ({
  gridArea: "F701",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F702 = styled.div((props: any) => ({
  gridArea: "F702",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F801 = styled.div((props: any) => ({
  gridArea: "F801",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F802 = styled.div((props: any) => ({
  gridArea: "F802",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F901 = styled.div((props: any) => ({
  gridArea: "F901",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F902 = styled.div((props: any) => ({
  gridArea: "F902",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
}));
export const F1001 = styled.div((props: any) => ({
  gridArea: "F1001",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
  borderRadius: "0 0 2.5rem 2.5rem",
}));
export const F1002 = styled.div((props: any) => ({
  gridArea: "F1002",
  backgroundColor: selectColor(props.backgroundColor),
  borderTop: "1px solid rgba(100, 100, 100, 0.6)",
  borderRadius: "0 0 2.5rem 2.5rem",
}));
