// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import DashboardButtonProperties from '../../interfaces/dashboardButtonProperties';

// Stylesheets
import './dashboard.css';

/**
 * @name dashboardButton.tsx
 * @implements dashboardProperties.tsx
 *
 * @path /components/dashboard/dashboardButton.tsx
 *
 * @description Defines a button in homepage's dashboard.
 *
 * @param title button's title
 * @param logo button's start logo
 * @param buttonAction what must be executed when user performed a click on current button
 */
const DashboardButton: React.FC<DashboardButtonProperties> = ({
  title,
  logo,
  buttonAction,
}) => {
  // Parameter that must be used before translatable string to apply the good language version
  const { t } = useTranslation();

  return (
    <button className='dashboardButton' onClick={buttonAction}>
      <img src={logo} alt='logo' />
      <p>&nbsp;{t(title)}</p>
    </button>
  );
};

export default DashboardButton;
