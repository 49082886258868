import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    addUser: false,
    removeUser: false,
    updateUser: false,
    updateUserPermissions: false,
    getUserPermissions: false,
    getOneUser: false,
    getAllUsers: false,
    getUserCompanies: false,
  },
  errors: {
    addUser: null,
    removeUser: null,
    updateUser: null,
    updateUserPermissions: null,
    getUserPermissions: null,
    getOneUser: null,
    getAllUsers: null,
    getUserCompanies: null,
  },
  loading: {
    addUser: false,
    removeUser: false,
    updateUser: false,
    updateUserPermissions: false,
    getUserPermissions: false,
    getOneUser: false,
    getAllUsers: false,
    getUserCompanies: false,
  },
  user: null,
  users: null,
  userPermissions: null,
  userCompanies: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Get user companies
    case actionTypes.GET_USER_COMPANIES_LOADING:
      return produce(state, (draft) => {
        draft.loading.getUserCompanies = true;
      });

    case actionTypes.GET_USER_COMPANIES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getUserCompanies = null;
        draft.loading.getUserCompanies = false;
        draft.success.getUserCompanies = true;
        draft.userCompanies = action.payload;
      });

    case actionTypes.GET_USER_COMPANIES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getUserCompanies = true;
        draft.loading.getUserCompanies = false;
        draft.success.getUserCompanies = false;
        !draft.error.includes("userCompanies") &&
          draft.error.push("userCompanies");
      });
    // Add user
    case actionTypes.ADD_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.addUser = true;
      });

    case actionTypes.ADD_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addUser = null;
        draft.loading.addUser = false;
        draft.success.addUser = true;
      });

    case actionTypes.ADD_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.addUser = true;
        draft.loading.addUser = false;
        draft.success.addUser = false;
        !draft.error.includes("addUser") && draft.error.push("addUser");
      });

    // remove users
    case actionTypes.REMOVE_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.removeUser = true;
      });

    case actionTypes.REMOVE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removeUser = null;
        draft.loading.removeUser = false;
        draft.success.removeUser = true;
      });

    case actionTypes.REMOVE_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.removeUser = true;
        draft.loading.removeUser = false;
        draft.success.removeUser = false;
        !draft.error.includes("removeUser") && draft.error.push("removeUser");
      });

    // update users
    case actionTypes.UPDATE_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateUser = true;
      });

    case actionTypes.UPDATE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateUser = null;
        draft.loading.updateUser = false;
        draft.success.updateUser = true;
      });

    case actionTypes.UPDATE_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateUser = true;
        draft.loading.updateUser = false;
        draft.success.updateUser = false;
        !draft.error.includes("updateUser") && draft.error.push("updateUser");
      });

    // Update user permissions
    case actionTypes.UPDATE_USER_PERMISSIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateUserPermissions = true;
        draft.loading.updateUserPermissions = true;
      });

    case actionTypes.UPDATE_USER_PERMISSIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateUserPermissions = null;
        draft.loading.updateUserPermissions = false;
        draft.success.updateUserPermissions = true;
      });

    case actionTypes.UPDATE_USER_PERMISSIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateUserPermissions = true;
        draft.loading.updateUserPermissions = false;
        draft.success.updateUserPermissions = false;
        !draft.error.includes("updateUserPermissions") &&
          draft.error.push("updateUserPermissions");
      });

    // Get user permissions
    case actionTypes.GET_USER_PERMISSIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getUserPermissions = true;
      });
    case actionTypes.GET_USER_PERMISSIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getUserPermissions = null;
        draft.loading.getUserPermissions = false;
        draft.success.getUserPermissions = true;
        draft.userPermissions = action.payload;
      });
    case actionTypes.GET_USER_PERMISSIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getUserPermissions = true;
        draft.loading.getUserPermissions = false;
        draft.success.getUserPermissions = false;
        !draft.error.includes("getUserPermissions") &&
          draft.error.push("getUserPermissions");
      });

    // Add one user
    case actionTypes.GET_ONE_USER_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneUser = true;
      });

    case actionTypes.GET_ONE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneUser = null;
        draft.loading.getOneUser = false;
        draft.success.getOneUser = true;
        draft.user = action.payload;
      });

    case actionTypes.GET_ONE_USER_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneUser = true;
        draft.loading.getOneUser = false;
        draft.success.getOneUser = false;
        !draft.error.includes("user") && draft.error.push("user");
      });

    // Get all users
    case actionTypes.GET_ALL_USERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllUsers = true;
      });

    case actionTypes.GET_ALL_USERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllUsers = null;
        draft.loading.getAllUsers = false;
        draft.success.getAllUsers = true;
        draft.users = action.payload;
      });

    case actionTypes.GET_ALL_USERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllUsers = true;
        draft.loading.getAllUsers = false;
        draft.success.getAllUsers = false;
        !draft.error.includes("users") && draft.error.push("users");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
