import React from 'react';
import { Table, Button as Btn } from 'semantic-ui-react';
import Button from 'components/Button';
import ButtonValidationModal from 'components/ButtonValidationModal';
import styled from '@emotion/styled';

const Documents = ({
  docList,
  document,
  declarationState,
  index,
  handleShowDocument,
  handleDeleteDocument,
}: DocumentsProps) => {

  const disabled =  (document.CustomsDeclarationDocumentState && document.CustomsDeclarationDocumentState.id !== 4)
  const id = (document.CustomsDeclarationDocumentState && document.CustomsDeclarationDocumentState.id) 

  const State = styled.div(() => ({
    float: 'left',
    marginTop: '0.4rem',
    padding: '0 0.5rem',
    borderRadius: '0.7rem',
    color: id === 3 ? 'white' : 'black',
    fontWeight: 'bold',
    background: id === 3 ? '#d11b1be0' : '#dde5eb',
    fontSize: '10px',
  }))

    return (  
          <>
        <Table.Row key={index}>
            <Table.Cell>{document.name} {disabled && (<State>{document.CustomsDeclarationDocumentState.state}</State>)}</Table.Cell>
            <Table.Cell>
              {
                document
                  .CustomsDeclarationDocumentCategory
                  .name
              }
            </Table.Cell>
            <Table.Cell>
              {new Date(
                document.createdAt
              ).toLocaleDateString('en', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Table.Cell>
            <Table.Cell>
             
              <Btn.Group vertical widths='two'>
                <Button
                  type='submit'
                  margin='5px'
                  content='Show'
                  color='blue'
                  disabled={disabled}
                  onClick={() =>
                    handleShowDocument(document.id)
                  }
                />
                {(declarationState && 
                  docList && 
                  docList.find((e) => Number(e.id) === document.CustomsDeclarationDocumentCategory_Id && (e.delete === true)) &&    
                    <ButtonValidationModal
                      noSubmit
                      trgrContent='Delete'
                      headerContent='Delete'
                      modalContent='Do you confirm the deletion of this document?'
                      trgrColor='red'
                      disabled={disabled}
                      onClick={() => {
                        handleDeleteDocument(document.id);
                      }}
                      margin='5px'
                    />
                ) || (declarationState && 
                    <ButtonValidationModal
                      noSubmit
                      trgrContent='Delete'
                      headerContent='Delete'
                      modalContent='Do you confirm the deletion of this document?'
                      trgrColor='red'
                      disabled={disabled}
                      onClick={() => {
                        handleDeleteDocument(document.id);
                      }}
                      margin='5px'
                    />)             
                }
              </Btn.Group>
            
            </Table.Cell>
          </Table.Row>
        </>      
    )
}

interface DocumentsProps {
    docList?: any,
    declarationState: boolean, 
    document: any,
    index: number,
    handleShowDocument: (docId) => void;
    handleDeleteDocument: (docId) => void;
  }
  
  export default Documents;