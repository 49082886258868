/**
 * Dependencies.
 */
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources.
 */
import './orderForm.css';
import PaymentPage from '../payzen/payementPage';
import Popup from '../popup/popup';
import Loader from 'components/Loader';

/**
 * PaymentMethodProps interface defines the structure of props
 * expected by the PaymentMethod component.
 */
interface PaymentMethodProps extends WithTranslation {
  companyId: number;
}

/**
 * PaymentMethodState interface defines the structure of props
 * expected by the PaymentMethod component.
 */
interface PaymentMethodState {
  showPaymentForm: boolean;
  popupVisible: boolean;
  isLoading: boolean;
}

/**
 * PaymentMethod component displays a part of OrderForm,
 * that gives user possibility to chose a method of payment
 * for his new mission
 */
class PaymentMethod extends Component<PaymentMethodProps, PaymentMethodState> {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      showPaymentForm: false,
      popupVisible: false,
      isLoading: false,
    };

    this.showPaymentForm = this.showPaymentForm.bind(this);
    this.showPopUpInformation = this.showPopUpInformation.bind(this);
    this.setIsLoading = this.setIsLoading.bind(this);
  }

  showPaymentForm() {
    this.setState((prevState) => ({
      showPaymentForm: !prevState.showPaymentForm,
    }));
  }

  showPopUpInformation() {
    this.setState((prevState) => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  setIsLoading() {
    this.setState((prevState) => ({
      isLoading: !prevState.isLoading,
    }));
  }

  reload() {
    window.location.reload();
  }

  render() {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;
    const uCompanyId = this.props.companyId;

    const sector: string =
      window.sessionStorage.getItem('isClickedConstruction') === 'true'
        ? 'Construction'
        : 'Other';
    const countryFrom: string = window.sessionStorage.getItem(
      'selectedOptionCountryFrom'
    );
    const countryTo: string = window.sessionStorage.getItem(
      'selectedOptionCountryTo'
    );
    const type: string = window.sessionStorage.getItem('selectedOption');
    const numberOfEmployees: number = Number(
      window.sessionStorage.getItem('selectedOptionNumber')
    );
    const withSipsi: boolean =
      window.sessionStorage.getItem('sipsiSelectedOption')?.toLowerCase() ===
      'true';
    const withConstructionCard: boolean =
      window.sessionStorage
        .getItem('constructionCardSelectedOption')
        ?.toLowerCase() === 'true';
    const numberOfCards: number = Number(
      window.sessionStorage.getItem('selectedNewCardOption')
    );
    const startDate: string = window.sessionStorage.getItem('startDate');
    const endDate: string = window.sessionStorage.getItem('endDate');
    const languagesTab: string[] = JSON.parse(
      window.sessionStorage.getItem('checkedLanguagesArray')!
    );
    const englishDocument: boolean = checkLanguageTab('en');
    const bulgarianDocument: boolean = checkLanguageTab('bg');
    const czechDocument: boolean = checkLanguageTab('cz');
    const germanDocument: boolean = checkLanguageTab('de');
    const italianDocument: boolean = checkLanguageTab('it');
    const spanishDocument: boolean = checkLanguageTab('es');
    const portugueseDocument: boolean = checkLanguageTab('pt');
    const polishDocument: boolean = checkLanguageTab('pl');
    const arabicDocument: boolean = checkLanguageTab('ar');
    const romanianDocument: boolean = checkLanguageTab('ro');
    const amount: number = Number(
      Number(window.sessionStorage.getItem('totalPrice')).toFixed(2)
    );
    const locationName: string = window.sessionStorage.getItem('locationName');
    const locationMainAddress: string = window.sessionStorage.getItem(
      'locationMainAddress'
    );
    const locationAdditionalAddress: string = window.sessionStorage.getItem(
      'locationAdditionalAddress'
    );
    const locationPostalCode: string =
      window.sessionStorage.getItem('locationPostalCode');
    const locationCity: string = window.sessionStorage.getItem('locationCity');
    const locationCountry: string =
      window.sessionStorage.getItem('locationCountry');
    const lodgingStartDate: string =
      window.sessionStorage.getItem('lodgingStartDate');
    const lodgingEndDate: string =
      window.sessionStorage.getItem('lodgingEndDate');
    const lodgingOption: string =
      window.sessionStorage.getItem('lodgingOptionId');
    const lodgingMainAddress: string =
      window.sessionStorage.getItem('lodgingMainAddress');
    const lodgingAdditionalAddress: string = window.sessionStorage.getItem(
      'lodgingAdditionalAddress'
    );
    const lodgingPostalCode: string =
      window.sessionStorage.getItem('lodgingPostalCode');
    const lodgingCity: string = window.sessionStorage.getItem('lodgingCity');
    const lodgingCountry: string =
      window.sessionStorage.getItem('lodgingCountry');
    let reference: string;

    function checkLanguageTab(countryKey: string): boolean {
      if (languagesTab?.includes(countryKey)) {
        return true;
      } else {
        return false;
      }
    }

    async function createMissionReference() {
      let month = new Date().getMonth() + 1;
      let newMissionReference: string =
        'SPW' +
        uCompanyId +
        countryTo +
        (month.toString().length < 2 ? '0' + month.toString() : month) +
        new Date().getFullYear();

      newMissionReference += 'CM';

      if (sector === 'Construction') {
        newMissionReference += 'C';
      } else {
        newMissionReference += 'O';
      }

      newMissionReference += await updateCurrentMissionValue();
      reference = newMissionReference;
      return newMissionReference;
    }

    async function getCurrentMissionValue() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getLastMonthlyMissionId`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: 3,
            }),
          }
        );

        const result = await response.json();

        return result.data;
      } catch (error) {
        console.log('Erreur', error);
      }
    }

    async function updateCurrentMissionValue() {
      const currentMissionValue: number = await getCurrentMissionValue();
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateLastMonthlyMissionId`,
          {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: 3,
              updatedValue: currentMissionValue + 1,
            }),
          }
        );

        const result = await response.json();

        return result.data;
      } catch (error) {
        console.log('Erreur', error);
      }
    }

    async function createNewMission(newReference: string): Promise<number> {
      const values = JSON.stringify({
        missionReference: newReference,
        companyId: uCompanyId,
        missionSector: sector,
        missionCountryFrom: countryFrom,
        missionCountryTo: countryTo,
        missionType: 'customMade',
        missionNumberOfEmployees: numberOfEmployees,
        missionWithSipsi: withSipsi,
        missionWithConstructionCard: withConstructionCard,
        missionNumberOfCardToCreate: numberOfCards,
        missionStartDate: startDate,
        missionEndDate: endDate,
        missionFrenchDocument: true,
        missionEnglishDocument: englishDocument,
        missionBulgarianDocument: bulgarianDocument,
        missionCzechDocument: czechDocument,
        missionGermanDocument: germanDocument,
        missionItalianDocument: italianDocument,
        missionSpanishDocument: spanishDocument,
        missionPortugueseDocument: portugueseDocument,
        missionPolishDocument: polishDocument,
        missionArabicDocument: arabicDocument,
        missionRomanianDocument: romanianDocument,
        amount: amount,
        isPaid: false,
        locationName: locationName,
        locationMainAddress: locationMainAddress,
        locationAdditionalAddress: locationAdditionalAddress,
        locationPostalCode: locationPostalCode,
        locationCity: locationCity,
        locationCountry: locationCountry,
        lodgingStartDate: lodgingStartDate,
        lodgingEndDate: lodgingEndDate,
        lodgingType: lodgingOption,
        lodgingMainAddress: lodgingMainAddress,
        lodgingAdditionalAddress: lodgingAdditionalAddress,
        lodgingPostalCode: lodgingPostalCode,
        lodgingCity: lodgingCity,
        lodgingCountry: lodgingCountry,
        creationDate: new Date(),
      });
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/newMission`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: values,
          }
        );

        const result = await response.json();

        return result.data;
      } catch (error) {
        console.log('Erreur', error);
      }
    }

    function setIsLoading() {
      this.setIsLoading();
    }

    async function createBankTransferMission() {
      createMissionReference().then(() =>
        createNewMission(reference).then(() =>
          setTimeout(() => {
            sessionStorage.clear();
            window.location.href = `/spw/missions/${uCompanyId}/details/${reference}`;
          }, 1000)
        )
      );
    }

    return (
      <>
        {(this.state.isLoading && <Loader />) ||
          (!this.state.isLoading && (
            <div className='orderFormPaymentMethod'>
              {!this.state.showPaymentForm && (
                <i>{t('orderPageStrings.paymentMethod.details')}</i>
              )}
              <div className='paymentMethodButtonsContainer'>
                {this.state.showPaymentForm ? (
                  <div>
                    <button className='returnButton' onClick={this.reload}>
                      ←
                    </button>
                    <PaymentPage companyId={this.props.companyId} />
                  </div>
                ) : (
                  <>
                    <button
                      className='paymentButton1'
                      onClick={this.showPaymentForm}
                    >
                      {t('orderPageStrings.paymentMethod.payByCard')}
                    </button>
                    <button
                      className='paymentButton2'
                      onClick={this.showPopUpInformation}
                    >
                      {t('orderPageStrings.paymentMethod.payByBankTransfer')}
                    </button>
                  </>
                )}
              </div>
              {this.state.popupVisible && (
                <Popup
                  myRef={undefined}
                  title={t('bankTransferStrings.title')}
                  onClickClose={this.showPopUpInformation}
                >
                  <div className='warningContainer'>
                    <p className='warningText'>
                      {t('bankTransferStrings.content')}
                      <a href='https://nc.asd-int.com/s/xx3isZFn9o36DYe/download?path=%2F&files=CIC_ASD_FRANCE.pdf'>
                        {t('bankTransferStrings.download')}
                      </a>
                    </p>

                    <button
                      className='paymentButton2'
                      onClick={createBankTransferMission}
                    >
                      {t('bankTransferStrings.confirm')}
                    </button>
                  </div>
                </Popup>
              )}
            </div>
          ))}
      </>
    );
  }
}

export default withTranslation()(PaymentMethod);
