import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Wrapper, LoaderWrapper } from './styles';

const Index = (props) => {
  return (
    <Wrapper>
      <Dimmer active={props.active || true}>
        <LoaderWrapper>
          <Loader content='Loading' />
        </LoaderWrapper>
      </Dimmer>
    </Wrapper>
  );
};

export default Index;
