import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  getDeclarationForComparison(data: dataReqI){
    return {
      type: actionTypes.GET_CUSTOMS_COMPARISON,
      payload: data,
    };
  },
  getLastCustoms(data: dataReqI) {
    return {
      type: actionTypes.GET_LAST_CUSTOMS,
      payload: data,
    };
  },
  getCustomsClerkStats(data?: dataReqI) {
    return {
      type: actionTypes.GET_CLERK_STATS,
      ...data && {payload: data}
    };
  },
  getComputedCheds(data: dataReqI) {
    return {
      type: actionTypes.GET_COMPUTED_CHEDS,
      payload: data,
    };
  },
  getDeclarationsStatsWithFilters(data: dataReqI) {
    return {
      type: actionTypes.GET_STATS_BY_FILTERS,
      payload: data,
    };
  },
  getChedStats(data: dataReqI) {
    return {
      type: actionTypes.GET_CHED_STATS,
      payload: data,
    };
  },
  getStatsWithFilters(data: dataReqI) {
    return {
      type: actionTypes.GET_FILTERED_STATS,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
};
