import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Tab, Container, Table } from 'semantic-ui-react';

import EmptyTable from 'components/EmptyTableCurve';
import Wrapper from 'components/core/Wrapper/Wrapper';
import TableSpacedRowsSmall from 'components/TableSpacedRowsSmall';
import Button from 'components/Button';
import AddDriverModal from './AddDriverModal';
import EditDriverModal from './EditDriverModal';
import Search from 'components/Search';

const Index = ({
  company,
  dispatch,
  companyDrivers,
  companyDriver,
  errorHandlerCompanyDriver,
  errorHandlerCompany,
  loadingHandlerCompanyDriver,
  loadingHandlerCompany,
}) => {
  const [drivers, setdrivers] = useState([]);
  const [runningAction, setRunningAction] = useState(null);
  const [addDriverModal, setAddDriverModal] = useState(false);
  const [editDriverModal, setEditDriverModal] = useState(false);
  const [resetSearch, setresetSearch] = useState(false);
  const [manageDriver, setManageDriver] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);

  useEffect(() => {
    dispatch(actions.companyDriver.reset(['error']));

    dispatch(
      actions.companyDriver.getAllDrivers({
        params: { companyId: company.id },
      })
    );

    return () => {
      dispatch(actions.companyDriver.reset(['drivers', 'error']));
    };
  }, [dispatch, company]);

  useEffect(() => {
    if (runningAction !== null && !loadingHandlerCompanyDriver) {
      if (!errorHandlerCompanyDriver.includes(runningAction)) {
        dispatch(
          actions.companyDriver.getAllDrivers({
            params: { companyId: company.id },
          })
        );
      }
      setRunningAction(null);
    }
  }, [dispatch, 
    loadingHandlerCompanyDriver, 
    errorHandlerCompanyDriver, 
    runningAction, company.id]);

  useEffect(() => {
    if (companyDrivers !== null) {
      setdrivers(_.orderBy(companyDrivers, ['lastName'], ['asc']));
    }
    resetSearch && setresetSearch(false);
  }, [companyDrivers, resetSearch]);

  useEffect(() => {
    if (companyDriver !== null) {
      setSelectedDriver(companyDriver);
    }
  }, [companyDriver]);

  const onSearchResultChange = (newData) => {
    setdrivers(_.orderBy(newData, ['lastName'], ['asc']));
  };

  // const getDriver = (userId) => {
  //   dispatch(actions.companyDriver.reset(['error']));
  //   dispatch(
  //     actions.companyDriver.getOneDriver({
  //       params: { companyId: company.id, userId: userId },
  //     })
  //   );
  // };

  const handleDelete = (userId) => {
    dispatch(actions.companyDriver.reset(['error']));
    dispatch(
      actions.companyDriver.removeDriver({
        params: { companyId: company.id, driverId: userId },
      })
    );
    setRunningAction('removeDriver');
  };

  return (
    <Tab.Pane
      loading={!company || loadingHandlerCompanyDriver || loadingHandlerCompany}
    >
      <Container>
        {addDriverModal === true && (
          <AddDriverModal
            open={addDriverModal}
            handleClose={() => setAddDriverModal(false)}
            companyId={company.id}
            setRunningAction={setRunningAction}
          />
        )}
        {editDriverModal === true && (
          <EditDriverModal
            open={editDriverModal}
            handleClose={() => setEditDriverModal(false)}
            user={selectedDriver}
            companyId={company.id}
            setRunningAction={setRunningAction}
            loadingHandlercompanyDriver={loadingHandlerCompanyDriver}
            errorHandlercompanyDriver={errorHandlerCompanyDriver}
          />
        )}
        {manageDriver === false && (
          <>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              padding='2rem 10rem 2rem'
            >
              <Button
                icon='plus'
                type='submit'
                content='Add'
                onClick={() => {
                  setAddDriverModal(true);
                }}
              />
              <Search
                dataSource={companyDrivers}
                onSearchResultChange={onSearchResultChange}
                setResetSearch={setresetSearch}
              />
            </Wrapper>
            {drivers && (
              <Wrapper
                display='flex'
                justifyContent='center'
                padding='0 10rem 5rem'
              >
                <Wrapper display='inline-flex' width='100%'>
                  <TableSpacedRowsSmall>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Pseudo</Table.HeaderCell>
                        <Table.HeaderCell>Unique ID</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {(drivers.length === 0 && <EmptyTable colNumber={4} />) ||
                        drivers.map((user, i) => {
                          return (
                            <Table.Row key={i}>
                              <Table.Cell>{`${user.pseudo}`}</Table.Cell>
                              <Table.Cell>
                                {user.Unique_Id}
                              </Table.Cell>
                              <Table.Cell>
                                <Wrapper
                                  display='flex'
                                  justifyContent='flex-end'
                                >
                                  <Button
                                    content='Edit'
                                    icon='edit'
                                    type='button'
                                    color='blue'
                                    labelPosition='right'
                                    onClick={() => {
                                      setSelectedDriver(user);
                                      setEditDriverModal(true);
                                    }}
                                    margin='5px'
                                  />
                                  <Button
                                    content='Delete'
                                    icon='delete'
                                    type='button'
                                    color='red'
                                    labelPosition='right'
                                    onClick={() => handleDelete(user.id)}
                                    margin='5px'
                                  />
                                </Wrapper>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </TableSpacedRowsSmall>
                </Wrapper>
              </Wrapper>
            )}
          </>
        )}
        {manageDriver === true && (
          <>
            <Wrapper display='flex' padding='2rem 5rem 2rem'>
              <Button
                icon='angle left'
                type='submit'
                content='Back'
                onClick={() => {
                  setManageDriver(false);
                  setSelectedDriver(null);
                }}
              />
            </Wrapper>
            {selectedDriver !== null && (
              <>
                <Wrapper
                  display='flex'
                  justifyContent='center'
                  padding='0 15rem 5rem'
                >
                  <Wrapper display='inline-flex' width='100%'>
                    <TableSpacedRowsSmall>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>{`${selectedDriver.firstName} ${selectedDriver.lastName}`}</Table.Cell>
                          <Table.Cell>
                            {selectedDriver.companyDrivers.role}
                          </Table.Cell>
                          <Table.Cell>
                            <Wrapper display='flex' justifyContent='flex-end'>
                              <Button
                                content='Edit'
                                icon='edit'
                                type='button'
                                color='blue'
                                labelPosition='right'
                                onClick={() => setEditDriverModal(true)}
                                margin='5px'
                              />
                              <Button
                                content='Delete'
                                icon='delete'
                                type='button'
                                color='red'
                                labelPosition='right'
                                onClick={() => {
                                  handleDelete(selectedDriver.id);
                                  setManageDriver(false);
                                }}
                                margin='5px'
                              />
                            </Wrapper>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </TableSpacedRowsSmall>
                  </Wrapper>
                </Wrapper>
                <Wrapper>
                </Wrapper>
              </>
            )}
          </>
        )}
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    companyDrivers: selectors.companyDriver.getAllDriversSelector(state),
    companyDriver: selectors.companyDriver.driverSelector(state),
    errorHandlerCompanyDriver: selectors.companyDriver.errorSelector(state),
    errorHandlerCompany: selectors.company.errorSelector(state),
    loadingHandlerCompanyDriver: selectors.companyDriver.loadingSelector(state),
    loadingHandlerCompany: selectors.company.loadingSelector(state),
    driverPermissions: selectors.companyDriver.driverPermissionsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
