import { resetState } from 'store/common';
import actionTypes from "./actionTypes";
import produce from "immer";
const INITIAL_STATE = {
  success: {
    connection: false,
    remove: false,
  },
  errors: {
    connection: null,
    removeCookie: null,
    remove: null,
  },
  loading: {
    connection: false,
    remove: false
  },
  connection: null,
  removeCookie: null,
  remove: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    // connections
    case 'getConnexions':
      return produce(state, (draft) => {
        draft.loading.connection = true;
        draft.connection = action.payload;
      });
    case 'removeCookie':
      return produce(state, (draft) => {
        draft.removeCookie = true;
      });

   
    // remove connexions
    case actionTypes.DISCONNECT_LOADING:
      return produce(state, (draft) => {
        draft.loading.remove = true;
      });
    case actionTypes.DISCONNECT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.remove = null;
        draft.loading.remove = false;
        draft.success.remove = true;
        draft.remove = action.payload;
      });
    case actionTypes.DISCONNECT_ERROR:
      return produce(state, (draft) => {
        draft.errors.remove = true;
        draft.loading.remove = false;
        draft.success.remove = false;
        !draft.error.includes("remove") &&
          draft.error.push("remove");
      });
  

         // Reset
    case 'resetGetConnexions':
      return resetState(state, INITIAL_STATE, action.payload.keys);
  

    default:
      return state;
  }
};
