import styled from "@emotion/styled";
import bg from "assets/images/bg-footer.png";
import bgBottom from "assets/images/bg-bottom.png"

export const FooterStyled = styled.div((props: any) => {
  return {
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: "0.7em",
    textAlign: "center",
    background: `url(${bg}) 100%`,
    backgroundSize: "cover",
    color: "white",
    fontSize: "1.2em",
    fontWeight: "bold",
    zIndex: 103,
  };
});

export const BgBottom = styled.div((props: any) => {
  return {
    position: "absolute",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    background: `url(${bgBottom})`,
    backgroundSize: "cover",
    padding: "10rem",
    zIndex: -1,
  };
});
