import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  return {
    validateUser(data: dataReqI) {
      const validateUserApi = routesApi.user.validate;
      return api.request({
        method: validateUserApi.method,
        route: validateUserApi.path,
        params: data.params,
      });
    },
    getUsers() {
      const getUsersApi = routesApi.user.getAll;
      return api.request({
        method: getUsersApi.method,
        route: getUsersApi.path,
      });
    },
    getUser(data: dataReqI) {
      const getUserApi = routesApi.user.getOne;
      return api.request({
        method: getUserApi.method,
        route: getUserApi.path,
        params: data.params,
      });
    },
    deleteUser(data: dataReqI) {
      const deleteUserApi = routesApi.user.remove;
      return api.request({
        method: deleteUserApi.method,
        route: deleteUserApi.path,
        params: data.params,
      });
    },
    createUser(data: dataReqI) {
      const createUserApi = routesApi.user.create;
      return api.request({
        method: createUserApi.method,
        route: createUserApi.path,
        data: data.data,
      });
    },
    resetPassword(data: dataReqI) {
      const resetPasswordApi = routesApi.user.resetPassword;
      return api.request({
        method: resetPasswordApi.method,
        route: resetPasswordApi.path,
        data: data.data,
      });
    },
    workmailRenewal(data: dataReqI) {
      const workmailRenewalApi = routesApi.user.workmailRenewal;
      return api.request({
        method: workmailRenewalApi.method,
        route: workmailRenewalApi.path,
        data: data.data,
      });
    },
    updateUser(data: dataReqI) {
      const updateUserApi = routesApi.user.update;
      return api.request({
        method: updateUserApi.method,
        route: updateUserApi.path,
        params: data.params,
        data: data.data,
      });
    },
    updatePassword(data: dataReqI) {
      const updatePasswordApi = routesApi.user.updatePassword;
      return api.request({
        method: updatePasswordApi.method,
        route: updatePasswordApi.path,
        params: data.params,
        data: data.data,
      });
    },
    updatePasswordWithToken(data: dataReqI) {
      const updatePasswordWhithTokenApi =
        routesApi.user.updatePasswordWhithToken;
      return api.request({
        method: updatePasswordWhithTokenApi.method,
        route: updatePasswordWhithTokenApi.path,
        params: data.params,
        data: data.data,
      });
    },
  };
};
