import actionTypes from "./actionTypes";
import { dataReqI } from "interfaces";

export default {
  addDriver(data: dataReqI) {
    return {
      type: actionTypes.ADD_DRIVER,
      payload: data,
    };
  },
  getDrivers(data: dataReqI) {
    return {
      type: actionTypes.GET_DRIVER_COMPANIES,
      payload: data,
    };
  },
  removeDriver(data: dataReqI) {
    return {
      type: actionTypes.REMOVE_DRIVER,
      payload: data,
    };
  },
  updateDriver(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_DRIVER,
      payload: data,
    };
  },
  updateDriverPermissions(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_DRIVER_PERMISSIONS,
      payload: data,
    };
  },
  getDriverPermissions(data: dataReqI) {
    return {
      type: actionTypes.GET_DRIVER_PERMISSIONS,
      payload: data,
    };
  },
  getOneDriver(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_DRIVER,
      payload: data,
    };
  },
  addDriverToCompany(data: dataReqI){
    return {
      type: actionTypes.ADD_DRIVER_TO_COMPANY,
      payload: data,
    }
  },
  getAllDrivers(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_DRIVERS,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
