export default {
  CREATE: "MY_ASD.SERVICE.CREATE",
  CREATE_LOADING: "MY_ASD.SERVICE.CREATE_LOADING",
  CREATE_SUCCESS: "MY_ASD.SERVICE.CREATE_SUCCESS",
  CREATE_ERROR: "MY_ASD.SERVICE.CREATE_ERROR",

  GET_ONE: "MY_ASD.SERVICE.GET_ONE",
  GET_ONE_LOADING: "MY_ASD.SERVICE.GET_ONE_LOADING",
  GET_ONE_SUCCESS: "MY_ASD.SERVICE.GET_ONE_SUCCESS",
  GET_ONE_ERROR: "MY_ASD.SERVICE.GET_ONE_ERROR",

  UPDATE: "MY_ASD.SERVICE.UPDATE",
  UPDATE_LOADING: "MY_ASD.SERVICE.UPDATE_LOADING",
  UPDATE_SUCCESS: "MY_ASD.SERVICE.UPDATE_SUCCESS",
  UPDATE_ERROR: "MY_ASD.SERVICE.UPDATE_ERROR",

  GET_ALL: "MY_ASD.SERVICE.GET_ALL",
  GET_ALL_LOADING: "MY_ASD.SERVICE.GET_ALL_LOADING",
  GET_ALL_SUCCESS: "MY_ASD.SERVICE.GET_ALL_SUCCESS",
  GET_ALL_ERROR: "MY_ASD.SERVICE.GET_ALL_ERROR",

  REMOVE: "MY_ASD.SERVICE.REMOVE",
  REMOVE_LOADING: "MY_ASD.SERVICE.REMOVE_LOADING",
  REMOVE_SUCCESS: "MY_ASD.SERVICE.REMOVE_SUCCESS",
  REMOVE_ERROR: "MY_ASD.SERVICE.REMOVE_ERROR",

  CREATE_PRODUCT: "MY_ASD.SERVICE.CREATE_PRODUCT",
  CREATE_PRODUCT_LOADING: "MY_ASD.SERVICE.CREATE_PRODUCT_LOADING",
  CREATE_PRODUCT_SUCCESS: "MY_ASD.SERVICE.CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_ERROR: "MY_ASD.SERVICE.CREATE_PRODUCT_ERROR",

  GET_ONE_PRODUCT: "MY_ASD.SERVICE.GET_ONE_PRODUCT",
  GET_ONE_PRODUCT_LOADING: "MY_ASD.SERVICE.GET_ONE_PRODUCT_LOADING",
  GET_ONE_PRODUCT_SUCCESS: "MY_ASD.SERVICE.GET_ONE_PRODUCT_SUCCESS",
  GET_ONE_PRODUCT_ERROR: "MY_ASD.SERVICE.GET_ONE_PRODUCT_ERROR",

  UPDATE_PRODUCT: "MY_ASD.SERVICE.UPDATE_PRODUCT",
  UPDATE_PRODUCT_LOADING: "MY_ASD.SERVICE.UPDATE_PRODUCT_LOADING",
  UPDATE_PRODUCT_SUCCESS: "MY_ASD.SERVICE.UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_ERROR: "MY_ASD.SERVICE.UPDATE_PRODUCT_ERROR",

  GET_PRODUCT_OF_SERVICE: "MY_ASD.SERVICE.GET_PRODUCT_OF_SERVICE",
  GET_PRODUCT_OF_SERVICE_LOADING:
    "MY_ASD.SERVICE.GET_PRODUCT_OF_SERVICE_LOADING",
  GET_PRODUCT_OF_SERVICE_SUCCESS:
    "MY_ASD.SERVICE.GET_PRODUCT_OF_SERVICE_SUCCESS",
  GET_PRODUCT_OF_SERVICE_ERROR: "MY_ASD.SERVICE.GET_PRODUCT_OF_SERVICE_ERROR",

  GET_PRODUCT_OF_SERVICE_SUB: "MY_ASD.SERVICE.GET_PRODUCT_OF_SERVICE_SUB",
  GET_PRODUCT_OF_SERVICE_SUB_LOADING:
    "MY_ASD.SERVICE.GET_PRODUCT_OF_SERVICE_SUB_LOADING",
  GET_PRODUCT_OF_SERVICE_SUB_SUCCESS:
    "MY_ASD.SERVICE.GET_PRODUCT_OF_SERVICE_SUB_SUCCESS",
  GET_PRODUCT_OF_SERVICE_SUB_ERROR:
    "MY_ASD.SERVICE.GET_PRODUCT_OF_SERVICE_SUB_ERROR",

  REMOVE_PRODUCT: "MY_ASD.SERVICE.REMOVE_PRODUCT",
  REMOVE_PRODUCT_LOADING: "MY_ASD.SERVICE.REMOVE_PRODUCT_LOADING",
  REMOVE_PRODUCT_SUCCESS: "MY_ASD.SERVICE.REMOVE_PRODUCT_SUCCESS",
  REMOVE_PRODUCT_ERROR: "MY_ASD.SERVICE.REMOVE_PRODUCT_ERROR",

  RESET: "MY_ASD.SERVICE.RESET",
};
