import React, { useState, useEffect, useRef} from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Icon } from 'semantic-ui-react';

import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import { Checkbox, FiscalPeriod } from 'components/Form';
import { periodAndCountryFiltersSchema } from 'interfaces/formSchema';
import Dropdown from 'components/Dropdown';
import { Input } from 'components/Form';

const Index = ({
  dispatch,
  applyFilters,
  countries,
  companyId,
  loadingHandlerCountries,
  errorHandlerCountries,
  CTransporters,
  CLoadingTransporters,
  resetFilters,
  isVisible,
  loadingQuickfixes,
  buyersLoading,
  buyers,
  storedSearch,
}: any) => {
  const [countriesList, setcountriesList] = useState([]);
  const [buyersList, setbuyersList] = useState([]);
  const [transportersList, setTransportersList] = useState([])

  const formikRef = useRef();

  useEffect(() => {
   
    dispatch(actions.data.getAllCountries());

    dispatch(
      actions.contact.getAllContactTransporters({
        params: { companyId },
      })
    );

    dispatch(
      actions.contact.getAllContacts({
        params: { companyId: companyId },
      })
    );
    return () => {
      dispatch(actions.data.reset(['allCountries', 'CTransporters', 'buyers', 'error']));
    };
  }, [dispatch, companyId]);

 
  useEffect(() => {
    if (countries !== null) {
      setcountriesList(
        countries.map((country, index) => {
          return {
            key: index,
            value: country.iso_code,
            text: country.english_name,
            flag: country.iso_code.toLowerCase(),
          };
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    if (CTransporters !== null) {
      setTransportersList(
        CTransporters.map((trans, index) => {
          return {
            key: index,
            value: parseInt(trans.id),
            text: trans.name,
          };
        })
      );
    }
  }, [CTransporters]);

  useEffect(() => {
    if (buyers !== null) {
      setbuyersList(
        buyers.map((buyer, index) => {
          return {
            key: index,
            value: parseInt(buyer.id),
            text: buyer.name,
          };
        })
      );
    }
  }, [buyers]);

  useEffect(() => {
    const ref: any = formikRef.current;
    if (ref && storedSearch) {
      ref.setFieldValue('reference', JSON.parse(storedSearch).reference || '');
      ref.setFieldValue('clientReference', JSON.parse(storedSearch).clientReference || '');
      ref.setFieldValue('startDate', JSON.parse(storedSearch).startDate || '');
      ref.setFieldValue('endDate', JSON.parse(storedSearch).endDate || '');
      ref.setFieldValue('buyer', JSON.parse(storedSearch).buyer || '');
      ref.setFieldValue('transporter', JSON.parse(storedSearch).transporter || '');
      ref.setFieldValue('country', JSON.parse(storedSearch).country || '');
      ref.setFieldValue('invoiceNumber', JSON.parse(storedSearch).invoiceNumber || '');
      ref.setFieldValue('awaiting', JSON.parse(storedSearch).awaiting || false);
    }
  }, [storedSearch])

  return (
    <Segment
      {...(isVisible && { display: 'none' })}
      margin='1rem 0 0 0'
      width='100%'
    >
      <Formik
        ref={formikRef}
        initialValues={{
          reference: '',
          clientReference: '',
          startDate: '',
          endDate: '',
          buyer: '',
          transporter: '',
          country: '',
          invoiceNumber: '',
          awaiting: false,
        }}
        enableReinitialize={true}
        onSubmit={applyFilters}
        validationSchema={periodAndCountryFiltersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper display='flex' justifyContent='center'>
              <Wrapper margin='0.5rem' width='12.5%'>
                <Field
                  placeholder='Reference'
                  name='reference'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                />
              </Wrapper>
              <Wrapper margin='0.5rem' width='12.5%'>
                <Field
                  placeholder='Client reference'
                  name='clientReference'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Wrapper>       
              <Wrapper margin='0.5rem' width='12.5%'>
                <Field
                  placeholder='From'
                  name='startDate'
                  component={FiscalPeriod}
                  meta={{ errors, touched, setFieldValue, setFieldTouched }}
                />
              </Wrapper>
              <Wrapper margin='0.5rem' width='12.5%'>
                <Field
                  placeholder='To'
                  name='endDate'
                  component={FiscalPeriod}
                  meta={{ errors, touched, setFieldValue, setFieldTouched }}
                />
              </Wrapper>
              {buyersList.length>0 && (
              <Wrapper margin='0.5rem' width='12.5%'>
                <Field
                  placeholder='Choose Buyer'
                  name='buyer'
                  options={buyersList}
                  loading={buyersLoading}
                  component={Dropdown}
                  meta={{ errors, touched, setFieldValue, setFieldTouched }}
                />
              </Wrapper>
              )}
              {transportersList.length>0 && (
                  <Wrapper margin='0.5rem' width='12.5%'>
                  <Field
                    placeholder='Choose Transporter'
                    name='transporter'
                    options={transportersList}
                    loading={CLoadingTransporters}
                    component={Dropdown}
                    meta={{ errors, touched, setFieldValue, setFieldTouched }}
                  />
                  </Wrapper>
              )}
              <Wrapper margin='0.5rem' width='12.5%'>
                <Field
                  placeholder='Choose country'
                  name='country'
                  options={countriesList}
                  component={Dropdown}
                  meta={{ errors, touched, setFieldValue, setFieldTouched }}
                  loading={loadingHandlerCountries}
                  uniqueError={
                    errorHandlerCountries.includes('allCountries')
                      ? true
                      : false
                  }
                />
              </Wrapper>
              <Wrapper margin='0.5rem' width='12.5%'>
                <Field
                  placeholder='Invoice number'
                  name='invoiceNumber'
                  type='text'
                  component={Input}
                  meta={{ errors, touched }}
                  margin='20px'
                />
              </Wrapper>
            </Wrapper>
            <Wrapper display='inline-flex' margin='1rem 0 0.5rem 0 '>
              <Wrapper margin='0.5rem 1.5rem 0.5rem 0'>
                <Field
                  label='Awaiting for proofs'
                  name='awaiting'
                  component={Checkbox}
                  meta={{ 
                    errors, 
                    touched, 
                    setFieldValue, 
                    setFieldTouched 
                  }}
                  checked={values.awaiting === true ? true : false}
                  onChange={() => {
                    setFieldValue('awaiting', !values.awaiting)           
                  }}
                />
              </Wrapper>
              <Button
                disabled={loadingQuickfixes}
                {...(loadingQuickfixes && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Button
                type='button'
                content='Reset'
                color='red'
                onClick={() => {
                  resetForm();
                  resetFilters();
                }}
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.data.allCountriesSelector(state),
    loadingHandlerCountries: selectors.data.allCountryLoadingSelector(state),
    errorHandlerCountries: selectors.data.errorSelector(state),
    loadingQuickfixes: selectors.quickfixes.loadingSelector(state),
    CTransporters: selectors.contact.contactsTransportersSelector(state),
    CLoadingTransporters: selectors.contact.getAllContactTransportersLoadingSelector(state),
    buyers: selectors.contact.contactsSelector(state),
    buyersLoading: selectors.contact.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);


