import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const ticketSsrErrorSelector = (state) =>
  state.ssrRequest.errors.ticketSsr;
const sendResultsErrorSelector = (state) =>
  state.ssrRequest.errors.sendResults;
const getFormSchemasErrorSelector = (state) =>
  state.ssrRequest.errors.getFormSchemas;
const errorsSelector = createErrorsSelector([
  ticketSsrErrorSelector,
  sendResultsErrorSelector,
  getFormSchemasErrorSelector,
]);

// Loading
const ticketSsrLoadingSelector = (state) =>
  state.ssrRequest.loading.ticketSsr;
const sendResultsLoadingSelector = (state) =>
  state.ssrRequest.loading.sendResults;
const getFormSchemasLoadingSelector = (state) =>
  state.ssrRequest.loading.getFormSchemas;
const loadingSelector = createLoadingSelector([
  ticketSsrLoadingSelector,
  sendResultsLoadingSelector,
  getFormSchemasLoadingSelector,
]);

// Success
const ticketSsrSuccessSelector = (state) =>
  state.ssrRequest.success.ticketSsr;
const sendResultsSuccessSelector = (state) =>
  state.ssrRequest.success.sendResults;
const getFormSchemasSuccessSelector = (state) =>
  state.ssrRequest.success.getFormSchemas;
const successSelector = createSuccessSelector([
  ticketSsrSuccessSelector,
  sendResultsSuccessSelector,
  getFormSchemasSuccessSelector,
]);


const formSchemasSelector = (state) => state.ssrRequest.formSchemas;
const processResultSelector = (state) => state.ssrRequest.processResult;

const errorSelector = (state) => state.ssrRequest.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  formSchemasSelector,
  processResultSelector,
  errorSelector,
};
