import React from 'react';

import InputErrorLabel from 'components/core/InputErrorLabel/InputErrorLabel';
import { TextAreaStyled, TextAreaWrapper } from './TextAreaInputStyles';

const TextAreaInput = ({
  label,
  name,
  placeholder,
  error,
  disabled,
  required,
  value,
  autoFocus,
  onChange,
  onBlur,
}: TextAreaInputProps) => {
  return (
    <TextAreaWrapper>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && ' *'}
        </label>
      )}

      <TextAreaStyled
        autoFocus={autoFocus}
        disabled={disabled}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
      />

      {error && <InputErrorLabel message={error} />}
    </TextAreaWrapper>
  );
};

export interface TextAreaInputProps {
  name: string;
  value?: string;
  error?: string;
  disabled?: true;
  placeholder?: string;
  label?: string;
  required?: string;
  autoFocus?: true;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
}

export default TextAreaInput;
