import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const getContactsErrorSelector = state => 
  state.contact.errors.getAllContacts;
const getOneContactErrorSelector = state => 
  state.contact.errors.getOneContact;
const createContactErrorSelector = state => 
  state.contact.errors.createContact;
const addContactErrorSelector = state => 
  state.contact.errors.addContact;
const deleteContactErrorSelector = state => 
  state.contact.errors.deleteContact;
const validateContactErrorSelector = state =>
  state.contact.errors.validateContac;
const refuseContactErrorSelector = state => 
  state.contact.errors.refuseContact;
const getCompaniesNotInContactErrorSelector = state =>
  state.contact.errors.getCompaniesNotInContact;
const getPendingContactErrorSelector = state =>
  state.contact.errors.getPendingContact;
const getRequestContactErrorSelector = state =>
  state.contact.errors.getRequestContact;
const getAllContactTransportersErrorSelector = state =>
  state.contact.errors.getAllContactTransporters;
const addContactPartnerErrorSelector = state =>
  state.contact.errors.partners;
const verifyContactPartnerErrorSelector = state =>
  state.contact.errors.checkedpartner;
const addContactRightsErrorSelector = state => 
  state.contact.error.addContactRights;
const getContactRightsErrorSelector = state => 
  state.contact.error.getContactRights;
const getAllContactRightsErrorSelector = state => 
  state.contact.error.getAllContactRights;
const getBothContactsErrorSelector = state => 
  state.contact.error.getBothContacts;

const errorsSelector = createErrorsSelector([
  getContactsErrorSelector,
  getOneContactErrorSelector,
  createContactErrorSelector,
  addContactErrorSelector,
  deleteContactErrorSelector,
  validateContactErrorSelector,
  refuseContactErrorSelector,
  getCompaniesNotInContactErrorSelector,
  getPendingContactErrorSelector,
  getRequestContactErrorSelector,
  getAllContactTransportersErrorSelector,
  addContactPartnerErrorSelector,
  verifyContactPartnerErrorSelector,
  addContactRightsErrorSelector,
  getContactRightsErrorSelector,
  getAllContactRightsErrorSelector,
  getBothContactsErrorSelector
]);

// Loading
const getContactsLoadingSelector = state => 
  state.contact.loading.getAllContacts;
const getOneContactLoadingSelector = state =>
  state.contact.loading.getOneContact;
const createContactLoadingSelector = state => 
  state.contact.loading.createContact;
const addContactLoadingSelector = state => 
  state.contact.loading.addContact;
const deleteContactLoadingSelector = state =>
  state.contact.loading.deleteContact;
const validateContactLoadingSelector = state =>
  state.contact.loading.validateContact;
const refuseContactLoadingSelector = state =>
  state.contact.loading.refuseContact;
const getCompaniesNotInContactLoadingSelector = state =>
  state.contact.loading.getCompaniesNotInContact;
const getPendingContactLoadingSelector = state =>
  state.contact.loading.getPendingContact;
const getRequestContactLoadingSelector = state =>
  state.contact.loading.getRequestContact;
const getAllContactTransportersLoadingSelector = state =>
  state.contact.loading.getAllContactTransporters;
const addContactPartnerLoadingSelector = state =>
  state.contact.loading.partners;
const verifyContactPartnerLoadingSelector = state =>
  state.contact.loading.checkedpartner;
const addContactRightsLoadingSelector = state => 
  state.contact.loading.addContactRights;
const getContactRightsLoadingSelector = state => 
  state.contact.loading.getContactRights;
const getAllContactRightsLoadingSelector = state => 
  state.contact.loading.getAllContactRights;
const getBothContactsLoadingSelector = state => 
  state.contact.loading.getBothContacts;


const loadingSelector = createLoadingSelector([
  getContactsLoadingSelector,
  getOneContactLoadingSelector,
  createContactLoadingSelector,
  addContactLoadingSelector,
  deleteContactLoadingSelector,
  validateContactLoadingSelector,
  refuseContactLoadingSelector,
  getCompaniesNotInContactLoadingSelector,
  getPendingContactLoadingSelector,
  getRequestContactLoadingSelector,
  getAllContactTransportersLoadingSelector,
  addContactPartnerLoadingSelector,
  verifyContactPartnerLoadingSelector,
  addContactRightsLoadingSelector,
  getContactRightsLoadingSelector,
  getAllContactRightsLoadingSelector,
  getBothContactsLoadingSelector
]);

// Success
const getContactsSuccessSelector = state => 
  state.contact.success.getAllContacts;
const getOneContactSuccessSelector = state =>
  state.contact.success.getOneContact;
const createContactSuccessSelector = state => 
  state.contact.success.createContact;
const addContactSuccessSelector = state => 
  state.contact.success.addContact;
const deleteContactSuccessSelector = state =>
  state.contact.success.deleteContact;
const validateContactSuccessSelector = state =>
  state.contact.success.validateContact;
const refuseContactSuccessSelector = state =>
  state.contact.success.refuseContact;
const getCompaniesNotInContactSuccessSelector = state =>
  state.contact.success.getCompaniesNotInContact;
const getPendingContactSuccessSelector = state =>
  state.contact.success.getPendingContact;
const getRequestContactSuccessSelector = state =>
  state.contact.success.getRequestContact;
const getAllContactTransportersSuccessSelector = state =>
  state.contact.success.getAllContactTransporters;
const addContactPartnerSuccessSelector = state =>
  state.contact.success.partners;
const verifyContactPartnerSuccessSelector = state =>
  state.contact.success.checkedpartner;
const addContactRightsSuccessSelector = state => 
  state.contact.success.addContactRights;
const getContactRightsSuccessSelector = state => 
  state.contact.success.getContactRights;
  const getAllContactRightsSuccessSelector = state => 
  state.contact.success.getAllContactRights;
const getBothContactsSuccessSelector = state => 
  state.contact.success.getBothContacts;


const successSelector = createSuccessSelector([
  getContactsSuccessSelector,
  getOneContactSuccessSelector,
  createContactSuccessSelector,
  addContactSuccessSelector,
  deleteContactSuccessSelector,
  validateContactSuccessSelector,
  refuseContactSuccessSelector,
  getCompaniesNotInContactSuccessSelector,
  getPendingContactSuccessSelector,
  getRequestContactSuccessSelector,
  getAllContactTransportersSuccessSelector,
  addContactPartnerSuccessSelector,
  verifyContactPartnerSuccessSelector,
  verifyContactPartnerSuccessSelector,
  addContactRightsSuccessSelector,
  getContactRightsSuccessSelector,
  getAllContactRightsSuccessSelector,
  getBothContactsSuccessSelector,
]);


const contactsSelector = state => state.contact.contacts;
const contactSelector = state => state.contact.contact;
const companiesNotInContactSelector = state => state.contact.companiesNotInContact;
const pendingContactSelector = state => state.contact.pendingContact;
const requestContactSelector = state => state.contact.requestContact;
const contactsTransportersSelector = state => state.contact.contactsTransporters;
const errorSelector = state => state.contact.error;
const addContactPartnerSelector = state => state.contact.partners;
const verifyContactPartnerSelector = state => state.contact.checkedpartner;
const addContactRightsSelector = state => state.contact.addContactRights; 
const getContactRightsSelector = state => state.contact.getContactRights;
const getAllContactRightsSelector = state => state.contact.getAllContactRights;
const getBothContatcsSelector = state => state.contact.getBothContacts;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  contactsSelector,
  contactSelector,
  requestContactSelector,
  pendingContactSelector,
  companiesNotInContactSelector,
  contactsTransportersSelector,
  getAllContactTransportersLoadingSelector,
  getAllContactTransportersSuccessSelector,
  getContactsLoadingSelector,
  getContactsSuccessSelector,
  errorSelector,
  addContactPartnerSelector,
  addContactSuccessSelector,
  addContactPartnerLoadingSelector,
  addContactPartnerSuccessSelector,
  deleteContactSuccessSelector,
  verifyContactPartnerSelector,
  addContactRightsSelector,
  getContactRightsSelector,
  getAllContactRightsSelector,
  getContactRightsLoadingSelector,
  addContactRightsLoadingSelector,
  getBothContatcsSelector,
  
};
