import styled from '@emotion/styled';

import theme from 'theme';

const { colors } = theme.getTheme();

export const CheckboxWrapper = styled.div(() => ({}));

export const CheckboxStyled = styled.input(({ error, float, checkHide }: { error: string, float: string, checkHide: string }) => ({
  borderRadius: '10px',
  border: `1px solid ${error ? colors.borderError : 'rgb(204, 204, 204)'}`,
  padding: '1em',
  ...(!float && {float: 'left'}),
  ...(checkHide && {checkHide: 'left'}),
  margin: '0.2em 0.5em 0.1em 0',
  ...(error && { backgroundColor: colors.backgroundError }),
  ...(error && { color: colors.textError }),
  '&:focus': {
    outline: 'none',
    boxShadow: '0px 0px 3px #c5c5c5',
  },
}));
