import React from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Icon, Modal } from 'semantic-ui-react';

import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { FiscalPeriod } from 'components/Form';

const DownloadFilesModal = ({
  accisesForWeek,
  loading,
  open,
  onMount,
  handleClose,
}: any) => {
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Modal.Content>
        <Formik
          initialValues={{
            dau: false,
          }}
          onSubmit={accisesForWeek}
          validationSchema={false}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Wrapper
                display='flex'
                justifyContent='center'
                flexDirection='column'
              >
                <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
                  <Wrapper margin='0.5rem'>
                    <Field
                      placeholder='From'
                      name='startDate'
                      component={FiscalPeriod}
                      meta={{ errors, touched, setFieldValue, setFieldTouched }}
                    />
                  </Wrapper>
                  <Wrapper margin='0.5rem'>
                    <Field
                      placeholder='To'
                      name='endDate'
                      component={FiscalPeriod}
                      meta={{ errors, touched, setFieldValue, setFieldTouched }}
                    />
                  </Wrapper>
                </Wrapper>
              </Wrapper>
              <Wrapper display='flex' margin='1rem' justifyContent='center'>
                <Button
                  disabled={loading}
                  {...(loading && {
                    icon: <Icon loading name='spinner' />,
                  })}
                  type='submit'
                  content='Download'
                  color='blue'
                />
              </Wrapper>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(DownloadFilesModal);
