const splitDateTime = (date: Date) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const formatedDate = `${year}-${month}-${day}`;

  const hours = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const formatedTime = `${hours}:${minutes}`;

  return {
    date: formatedDate,
    time: formatedTime,
  };
};

export default splitDateTime;
