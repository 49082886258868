import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    getDeliveryByToken: false,
    transporterSelection: null,
    uploadProof: null,
    getOneProof: null,
    downloadAttestation: null,
    downloadCustomsRegimeInvoice: null,
  },
  errors: {
    getDeliveryByToken: null,
    transporterSelection: null,
    uploadProof: null,
    getOneProof: null,
    downloadAttestation: null,
    downloadCustomsRegimeInvoice: null,
  },
  loading: {
    getDeliveryByToken: false,
    transporterSelection: false,
    uploadProof: false,
    getOneProof: false,
    downloadAttestation: false,
    downloadCustomsRegimeInvoice: false,
  },
  deliveryByToken: null,
  oneProof: null,
  attestation: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Get delivery
    case actionTypes.GET_DELIVERY_BY_TOKEN_LOADING:
      return produce(state, (draft) => {
        draft.loading.getDeliveryByToken = true;
      });

    case actionTypes.GET_DELIVERY_BY_TOKEN_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getDeliveryByToken = null;
        draft.loading.getDeliveryByToken = false;
        draft.success.getDeliveryByToken = true;
        draft.deliveryByToken = action.payload;
      });

    case actionTypes.GET_DELIVERY_BY_TOKEN_ERROR:
      return produce(state, (draft) => {
        draft.errors.getDeliveryByToken = true;
        draft.loading.getDeliveryByToken = false;
        draft.success.getDeliveryByToken = false;
        !draft.error.includes('deliveryByToken') &&
          draft.error.push('deliveryByToken');
      });

    //transporterSelection
    case actionTypes.TRANSPORTER_SELECTION_LOADING:
      return produce(state, (draft) => {
        draft.loading.transporterSelection = true;
      });

    case actionTypes.TRANSPORTER_SELECTION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.transporterSelection = null;
        draft.loading.transporterSelection = false;
        draft.success.transporterSelection = true;
      });

    case actionTypes.TRANSPORTER_SELECTION_ERROR:
      return produce(state, (draft) => {
        draft.errors.transporterSelection = true;
        draft.loading.transporterSelection = false;
        draft.success.transporterSelection = false;
        !draft.error.includes('transporterSelection') &&
          draft.error.push('transporterSelection');
      });

    // Upload Proof
    case actionTypes.UPLOAD_PROOF_LOADING:
      return produce(state, (draft) => {
        draft.loading.uploadProof = true;
      });

    case actionTypes.UPLOAD_PROOF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.uploadProof = null;
        draft.loading.uploadProof = false;
        draft.success.uploadProof = true;
      });

    case actionTypes.UPLOAD_PROOF_ERROR:
      return produce(state, (draft) => {
        draft.errors.uploadProof = true;
        draft.loading.uploadProof = false;
        draft.success.uploadProof = false;
        !draft.error.includes('uploadProof') && draft.error.push('uploadProof');
      });

    //getOneProof
    case actionTypes.GET_ONE_PROOF_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneProof = true;
      });

    case actionTypes.GET_ONE_PROOF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneProof = null;
        draft.loading.getOneProof = false;
        draft.success.getOneProof = true;
        draft.oneProof = action.payload;
      });

    case actionTypes.GET_ONE_PROOF_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneProof = true;
        draft.loading.getOneProof = false;
        draft.success.getOneProof = false;
        !draft.error.includes('oneProof') && draft.error.push('oneProof');
      });

    //downloadAttestation
    case actionTypes.DOWNLOAD_ATTESTATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadAttestation = true;
      });

    case actionTypes.DOWNLOAD_ATTESTATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadAttestation = null;
        draft.loading.downloadAttestation = false;
        draft.success.downloadAttestation = true;
        draft.attestation = action.payload;
      });

    case actionTypes.DOWNLOAD_ATTESTATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadAttestation = true;
        draft.loading.downloadAttestation = false;
        draft.success.downloadAttestation = false;
        !draft.error.includes('attestation') && draft.error.push('attestation');
      });

    //downloadCustomsRegimeInvoice
    case actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadCustomsRegimeInvoice = true;
      });

    case actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadCustomsRegimeInvoice = null;
        draft.loading.downloadCustomsRegimeInvoice = false;
        draft.success.downloadCustomsRegimeInvoice = true;
      });

    case actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadCustomsRegimeInvoice = true;
        draft.loading.downloadCustomsRegimeInvoice = false;
        draft.success.downloadCustomsRegimeInvoice = false;
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
