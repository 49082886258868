import actionTypes from 'store/admin/actionTypes';
import routes from './routes';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.REPORTBUG:
        try {
          next({ type: actionTypes.REPORTBUG_LOADING });
          await request.reportBug({ ...action.payload });
          next({
            type: actionTypes.REPORTBUG_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.REPORTBUG_ERROR,
            error,
          });
        }
        break;

      case actionTypes.GET_PERMISSIONS:
        try {
          next({ type: actionTypes.GET_PERMISSIONS_LOADING });
          const response = await request.getPermissions({ ...action.payload });
          next({
            type: actionTypes.GET_PERMISSIONS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.REPORTBUG_ERROR,
            error,
          });
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
