import React from 'react';
import { Formiz, useForm } from '@formiz/core';
import { isPattern } from '@formiz/validations';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions } from 'store';

import { passwordRegex } from 'interfaces/formSchema';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';
import Spacer from 'components/core/Spacer/Spacer';
import TextCustom from 'components/core/TextCustom/TextCustom';
import authPaths from 'routes/auth/paths';

const Index = ({ dispatch, match, history, intl }) => {
  const forgottenPasswordForm = useForm();

  const save = (values) => {
    dispatch(
      actions.user.updatePasswordByToken({
        params: { token: match.params.token },
        data: { password: values.password },
      })
    );

    history.push(authPaths.login);
  };

  return (
    <Wrapper
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Segment padding='3rem 5rem 1rem 5rem'>
        <TextCustom fontWeight='bold' fontSize='1.5em'>
          Choose a new password:
        </TextCustom>
        <Spacer height='20px' />
        <Formiz connect={forgottenPasswordForm} onValidSubmit={save}>
          <form onSubmit={forgottenPasswordForm.submit}>
            <FormizSimpleInput
              type='password'
              name='password'
              label='Password'
              placeholder='Password'
              required='Password is required'
              validations={[
                {
                  rule: isPattern(passwordRegex),
                  message:
                    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
                },
              ]}
            />
            <Spacer height='10px' />
            <FormizSimpleInput
              type='password'
              name='confirmPassword'
              label='Confirm password'
              placeholder='Password'
              required='Password is required'
              validations={[
                {
                  rule: (value) =>
                    forgottenPasswordForm.values.password === value,
                  deps: [forgottenPasswordForm.values.password],
                  message: 'Passwords do not match',
                },
              ]}
            />
            <Spacer height='20px' />
            <Button
              type='submit'
              content={intl.formatMessage({
                id: 'button.save',
              })}
            />
          </form>
        </Formiz>
      </Segment>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
