// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Popup from '../popup/popup';

// Resources
import editLogo from '../../assets/images/logos/edit.png';

// Interfaces
import EditButtonProperties from '../../interfaces/editButtonProperties';

// Stylesheets
import './editButton.css';

/**
 * @name spwEditButton.tsx
 * @implements EditButtonProperties.tsx
 *
 * @path /components/SPWEditButton/spwEditButton.tsx
 *
 * @description Defines edit button structure
 */
const EditButton: React.FC<EditButtonProperties> = ({
  popupVisible,
  myRef,
  popupRef,
  title,
  action,
  children,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  return (
    <div className='editButtonContainer'>
      <button ref={myRef} className='editButton' onClick={action}>
        <p>{t('globalStrings.editButtonTitle')}</p>
        <img src={editLogo} alt='Edit logo' className='editLogo' />
      </button>

      {popupVisible ? (
        <Popup myRef={popupRef} title={t(title)} onClickClose={action}>
          {children}
        </Popup>
      ) : null}
    </div>
  );
};

export default EditButton;
