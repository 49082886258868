import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Tab, Container, Table } from 'semantic-ui-react';

import EmptyTable from 'components/EmptyTableCurve';
import Wrapper from 'components/core/Wrapper/Wrapper';
import TableSpacedRowsSmall from 'components/TableSpacedRowsSmall';
import Button from 'components/Button';
import KeyInfosModal from './KeyInfosModal';
import { EmptySpace } from './KeyInfosModal/keyinfoStyles';

const Index = ({
  company,
  dispatch,
  keyinformations,
  user,
  keyInfosLoading,
}) => {
  const [keyinfo, setkeyinfos] = useState([]);
  const [addKeyinfoModal, setAddKeyinfoModal] = useState(null);
  const [selectedInfo, setSelectedKeyinfo] = useState(null);

  useEffect(() => {
    dispatch(actions.companyKeyInfo.getKeyInfos({params: {companyId: company.id}}));
    dispatch(actions.socket.subscribeKeyInfos(company.id));

    return () => {
      dispatch(actions.companyKeyInfo.reset(['getKeyInfos', 'error']));
      dispatch(actions.socket.unsubscribeKeyInfos(company.id));
    };
  }, [dispatch, company]);


  useEffect(() => {
    if(keyinformations){
      setkeyinfos(_.orderBy(keyinformations, ['createdAt'], ['asc']));
    }
  }, [keyinformations]);


  const handleDelete = (infoId) => {
    dispatch(
      actions.companyKeyInfo.removeKeyInfo({
        params: { companyId: company.id, id: infoId },
      })
    );
  };

  return (
    <Tab.Pane
      loading={keyInfosLoading}
    >
      <Container>
        {addKeyinfoModal && (
          <KeyInfosModal
            actionType={addKeyinfoModal}
            selectedInfo={selectedInfo}
            handleClose={() => setAddKeyinfoModal(null)}
            companyId={company.id}
            user={user}
          />
        )}
        <Wrapper
          display='flex'
          justifyContent='space-between'
          padding='2rem 10rem 2rem'
        >
        <Button
          icon='plus'
          type='submit'
          content='Add'
          onClick={() => {
            setSelectedKeyinfo(null);
            setAddKeyinfoModal('add');
          }}
        />
        </Wrapper>
        {keyinfo && (
          <Wrapper
            display='flex'
            justifyContent='center'
            padding='0 10rem 5rem'
          >
          <Wrapper display='inline-flex' width='100%'>
            <TableSpacedRowsSmall>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>group</Table.HeaderCell>
                  <Table.HeaderCell>info</Table.HeaderCell>                 
                </Table.Row>
              </Table.Header>
                  <Table.Body>
                    {(keyinfo.length === 0 && <EmptyTable colNumber={3} />) ||
                      keyinfo.map((info, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>{`${info.group}`}</Table.Cell>
                            <Table.Cell>
                            {info.emitter} - {info.info}
                            </Table.Cell>
                            <Table.Cell>
                              <Wrapper
                                display='flex'
                                justifyContent='flex-end'
                              >
                              {((user.lastName === info.emitter) || user.superOperator) ? (
                                <>
                                <Button
                                  content='Edit'
                                  icon='edit'
                                  type='button'
                                  color='blue'
                                  labelPosition='right'
                                  onClick={() => {
                                    setSelectedKeyinfo(info);
                                    setAddKeyinfoModal('update');
                                  }}
                                  margin='5px'
                                />
                                <Button
                                    content='Delete'
                                    icon='delete'
                                    type='button'
                                    color='red'
                                    labelPosition='right'
                                    onClick={() => handleDelete(info.id)}
                                    margin='5px'
                                />
                                </>
                                ) : (
                                  <EmptySpace />
                                )}
                              </Wrapper>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </TableSpacedRowsSmall>
              </Wrapper>
            </Wrapper>
          )}            
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    keyinformations: selectors.companyKeyInfo.getKeyInfosSelector(state),
    keyInfosLoading: selectors.companyKeyInfo.getKeyInfosLoadingSelector(state),
    errorkeyInfos: selectors.companyKeyInfo.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
