import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    getUnpaid: false,
    getAll: false,
    getOne: false,
    payInvoice: false,
    createCredit: false,
  },
  errors: {
    getUnpaid: null,
    getAll: null,
    getOne: null,
    payInvoice: null,
    createCredit: null,
  },
  loading: {
    getUnpaid: false,
    getAll: false,
    getOne: false,
    payInvoice: false,
    createCredit: false,
  },
  unpaidInvoices: null,
  invoices: null,
  invoice: null,
  paymentToken: null,
  createCredit: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
     // create credit invoices
     case actionTypes.CREATE_CREDIT_LOADING:
      return produce(state, (draft) => {
        draft.loading.createCredit = true;
      });

    case actionTypes.CREATE_CREDIT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createCredit = null;
        draft.loading.createCredit = false;
        draft.success.createCredit = true;
        draft.createCredit = action.payload;
      });

    case actionTypes.CREATE_CREDIT_ERROR:
      return produce(state, (draft) => {
        draft.errors.createCredit = true;
        draft.loading.createCredit = false;
        draft.success.createCredit = false;
        !draft.error.includes('createCredit') &&
          draft.error.push('createCredit');
      });

    // Get unpaid invoices
    case actionTypes.GET_UNPAID_LOADING:
      return produce(state, (draft) => {
        draft.loading.getUnpaid = true;
      });

    case actionTypes.GET_UNPAID_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getUnpaid = null;
        draft.loading.getUnpaid = false;
        draft.success.getUnpaid = true;
        draft.unpaidInvoices = action.payload;
      });

    case actionTypes.GET_UNPAID_ERROR:
      return produce(state, (draft) => {
        draft.errors.getUnpaid = true;
        draft.loading.getUnpaid = false;
        draft.success.getUnpaid = false;
        !draft.error.includes('unpaidInvoices') &&
          draft.error.push('unpaidInvoices');
      });
    // Get All invoices
    case actionTypes.GET_ALL_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAll = true;
      });

    case actionTypes.GET_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAll = null;
        draft.loading.getAll = false;
        draft.success.getAll = true;
        draft.invoices = action.payload;
      });

    case actionTypes.GET_ALL_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAll = true;
        draft.loading.getAll = false;
        draft.success.getAll = false;
        !draft.error.includes('invoices') && draft.error.push('invoices');
      });
    // Add on invoice
    case actionTypes.GET_ONE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOne = true;
      });

    case actionTypes.GET_ONE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOne = null;
        draft.loading.getOne = false;
        draft.success.getOne = true;
        draft.invoice = action.payload;
      });

    case actionTypes.GET_ONE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOne = true;
        draft.loading.getOne = false;
        draft.success.getOne = false;
        !draft.error.includes('invoice') && draft.error.push('invoice');
      });

    // Pay an invoice
    case actionTypes.PAY_INVOICE_LOADING:
      return produce(state, (draft) => {
        draft.loading.payInvoice = true;
      });

    case actionTypes.PAY_INVOICE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.payInvoice = null;
        draft.loading.payInvoice = false;
        draft.success.payInvoice = true;
        draft.paymentToken = action.payload;
      });

    case actionTypes.PAY_INVOICE_ERROR:
      return produce(state, (draft) => {
        draft.errors.payInvoice = true;
        draft.loading.payInvoice = false;
        draft.success.payInvoice = false;
        !draft.error.includes('payInvoice') && draft.error.push('payInvoice');
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
