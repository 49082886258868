import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getPaymentErrorSelector = (state) =>
  state.companyPayment.errors.getPayment;
const removePaymentErrorSelector = (state) =>
  state.companyPayment.errors.removePayment;

const errorsSelector = createErrorsSelector([
  getPaymentErrorSelector,
  removePaymentErrorSelector,
]);

// Loading
const getPaymentLoadingSelector = (state) =>
  state.companyPayment.loading.getPayment;
const removePaymentLoadingSelector = (state) =>
  state.companyPayment.loading.removePayment;

const loadingSelector = createLoadingSelector([
  getPaymentLoadingSelector,
  removePaymentLoadingSelector,
]);

// Success
const getPaymentSuccessSelector = (state) =>
  state.companyPayment.success.getPayment;
const removePaymentSuccessSelector = (state) =>
  state.companyPayment.success.removePayment;

const successSelector = createSuccessSelector([
  getPaymentSuccessSelector,
  removePaymentSuccessSelector,
]);

// Payment
const paymentSelector = (state) => state.companyPayment.payment;

const errorSelector = (state) => state.companyPayment.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  paymentSelector,
  errorSelector,
};
