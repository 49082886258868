import { Field, Formik, FieldArray } from 'formik';
import Dropdown from 'components/Dropdown';
import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import {
  Divider,
  Form,
  Grid,
  Header,
  List,
  Modal,
  Transition,
  Message,
} from 'semantic-ui-react';
import Button from 'components/Button';
import { File } from 'components/Form';
import { arrayOfCustomsDocuments } from 'interfaces/formSchema/customsValidators';

const ModalAddCustomsDocuments = ({
  open,
  categoriesDocuments,
  categoriesLoading,
  handleAddDocuments,
  handleClose,
  dispatch,
}: ModalAddCustomsDocumentsProps) => {
  const [categoriesDocumentsList, setCategoriesDocumentsList]: any = useState([]);

  useEffect(() => {
    dispatch(actions.customsDeclaration.getAllCategoriesDocuments({
      query: {type: 1}
    }));
    return () => {
      dispatch(actions.customsDeclaration.reset(['categoriesDocuments']));
    };
  }, [dispatch]);

  useEffect(() => {
    if (categoriesDocuments) {
          setCategoriesDocumentsList(
            categoriesDocuments.map((category, index) => {
              return {
                key: index,
                value: category.id,
                text: category.name,
              };
            })
          );
        }
  }, [categoriesDocuments]);

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content='Add customs documents' />
      <Formik
        initialValues={{
          documents: [
            {
              file: undefined,
              id: undefined,
            },
          ],
          type: 1
        }}
        onSubmit={handleAddDocuments}
        validationSchema={arrayOfCustomsDocuments}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
          isValid,
        }) => (
          <Modal.Content>
            <Message warning>
              CHED's should now be added to the CHED table with all the
              information needed to process your files
            </Message>
            <Form onSubmit={handleSubmit}>
              <>
                <FieldArray
                  name='documents'
                  render={(arrayHelpers) => (
                    <Transition.Group
                      as={List}
                      duration={200}
                      size='medium'
                      verticalAlign='middle'
                    >
                      <Grid columns='3'>
                        {values.documents.length > 0 &&
                          values.documents.map((document, index) => {
                            return (
                              <Grid.Row key={index}>
                                <Grid.Column width='5'>
                                  <Field
                                    name={`documents[${index}].id`}
                                    arrayName='documents'
                                    placeholder='Choose a Category'
                                    component={Dropdown}
                                    options={categoriesDocumentsList}
                                    loading={categoriesLoading}
                                    meta={{
                                      errors,
                                      touched,
                                      setFieldValue,
                                      setFieldTouched,
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column width='10'>
                                  <Field
                                    name={`documents[${index}].file`}
                                    component={File}
                                    error={errors.documents?.[index]?.file}
                                    touched={touched.documents?.[index]?.file}
                                    meta={{
                                      setFieldValue,
                                      setFieldTouched,
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column width='1'>
                                  <Button
                                    icon='minus'
                                    type='button'
                                    color='red'
                                    onClick={() => arrayHelpers.remove(index)}
                                    maxHeight='2.7rem'
                                  />
                                </Grid.Column>
                                <Divider hidden />
                              </Grid.Row>
                            );
                          })}
                      </Grid>
                      <Divider hidden />
                      <Button
                        icon='plus'
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            file: undefined,
                            id: undefined,
                          })
                        }
                        margin='0px'
                        position='center'
                      />
                    </Transition.Group>
                  )}
                />
                <Divider hidden />
              </>
              <Button
                disabled={!isValid}
                type='submit'
                content='Submit'
                color='green'
              />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

interface ModalAddCustomsDocumentsProps {
  open: boolean;
  handleAddDocuments: (values: {
    documents: Array<{
      file: File;
      id: number;
    }>;
    type: number;
  }) => void;
  handleClose: () => void;
  categoriesDocuments?: any;
  categoriesLoading?: boolean;
  dispatch: (value: any) => void;
}

const mapStateToProps = (state) => {
  return {
    categoriesDocuments: selectors.customsDeclaration.getAllCategoriesDocumentsSelector(
      state
    ),
    categoriesLoading: selectors.customsDeclaration.getAllCategoriesDocumentsLoadingSelector(
      state
    ),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(ModalAddCustomsDocuments);
