import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const tokenApi = routesApi.company.token;
  return {
    getAllTransportersByToken(data: dataReqI) {
      return api.request({
        method: tokenApi.getAllTransportersByToken.method,
        route: tokenApi.getAllTransportersByToken.path,
        params: data.params,
      });
    },
    createTransporterByToken(data: dataReqI) {
      return api.request({
        method: tokenApi.createTransporterByToken.method,
        route: tokenApi.createTransporterByToken.path,
        params: data.params,
        data: data.data,
      });
    },
    getTransporterVats(data: dataReqI) {
      return api.request({
        method: tokenApi.getTransporterVats.method,
        route: tokenApi.getTransporterVats.path,
        params: data.params,
      });
    },
  };
};
