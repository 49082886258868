import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  reportBug(data: dataReqI) {
    return {
      type: actionTypes.REPORTBUG,
      payload: data,
    };
  },
  getPermissions(data: dataReqI) {
    return {
      type: actionTypes.GET_PERMISSIONS,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
