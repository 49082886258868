import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Icon, Modal } from 'semantic-ui-react';
import { actions } from 'store';

import FormField from '../components/FormFields/FormFields';

const NewCompanyModal = ({
  dispatch,
  open,
  handleClose,
  addContact,
  companyId,
}: NewCompanyModalI) => {
  const handleSave = (values) => {
    if (addContact) {
      dispatch(
        actions.contact.createContact({
          params: { companyId: companyId },
          data: values,
        })
      );
    } else {
      dispatch(
        actions.company.create({
          data: values,
        })
      );
    }

    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Modal.Header>
        <Icon name='plus' />
        Add new company
      </Modal.Header>
      <Modal.Content>
        <FormField onSave={handleSave} />
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(NewCompanyModal);

interface NewCompanyModalI {
  dispatch: (action: any) => void;
  open: boolean;
  handleClose: () => void;
  addContact?: boolean;
  companyId?: number;
}
