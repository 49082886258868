import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const contactApi = routesApi.company.contact;
  return {
    getAllContacts(data: dataReqI) {
      return api.request({
        method: contactApi.getAllContacts.method,
        route: contactApi.getAllContacts.path,
        params: data.params
      });
    },
    getOneContact(data: dataReqI) {
      return api.request({
        method: contactApi.getOneContact.method,
        route: contactApi.getOneContact.path,
        params: data.params
      });
    },
    getBothContact(data: dataReqI) {
      return api.request({
        method: contactApi.getBothContact.method,
        route: contactApi.getBothContact.path,
        params: data.params
      });
    },
    validateContactPartner(data: dataReqI) {
      return api.request({
        method: contactApi.validateContactPartner.method,
        route: contactApi.validateContactPartner.path,
        params: data.params
      });
    },
    createContact(data: dataReqI) {
      return api.request({
        method: contactApi.createContact.method,
        route: contactApi.createContact.path,
        params: data.params,
        data: data.data
      });
    },
    addContact(data: dataReqI) {
      return api.request({
        method: contactApi.addContact.method,
        route: contactApi.addContact.path,
        params: data.params,
        data: data.data
      });
    },
    addContactPartner(data: dataReqI) {
      return api.request({
        method: contactApi.addContactPartner.method,
        route: contactApi.addContactPartner.path,
        params: data.params,
        data: data.data
      });
    },
    addContactRights(data: dataReqI) {
      return api.request({
        method: contactApi.addContactRights.method,
        route: contactApi.addContactRights.path,
        params: data.params,
        data: data.data
      });
    },
    getContactRights(data: dataReqI) {
      return api.request({
        method: contactApi.getContactRights.method,
        route: contactApi.getContactRights.path,
        params: data.params,
        data: data.data
      });
    },
    getAllContactRights(data: dataReqI) {
      return api.request({
        method: contactApi.getAllContactRights.method,
        route: contactApi.getAllContactRights.path,
        params: data.params,
        data: data.data
      });
    },
    deleteContact(data: dataReqI) {
      return api.request({
        method: contactApi.deleteContact.method,
        route: contactApi.deleteContact.path,
        params: data.params
      });
    },
    validateContact(data: dataReqI) {
      return api.request({
        method: contactApi.validateContact.method,
        route: contactApi.validateContact.path,
        params: data.params
      });
    },
    refuseContact(data: dataReqI) {
      return api.request({
        method: contactApi.refuseContact.method,
        route: contactApi.refuseContact.path,
        params: data.params
      });
    },
    getCompaniesNotInContact(data: dataReqI) {
      return api.request({
        method: contactApi.getCompaniesNotInContact.method,
        route: contactApi.getCompaniesNotInContact.path,
        params: data.params
      });
    },
    getPendingContact(data: dataReqI) {
      return api.request({
        method: contactApi.getPendingContact.method,
        route: contactApi.getPendingContact.path,
        params: data.params
      });
    },
    getRequestContact(data: dataReqI) {
      return api.request({
        method: contactApi.getRequestContact.method,
        route: contactApi.getRequestContact.path,
        params: data.params
      });
    },
    getAllContactTransporters(data: dataReqI) {
      return api.request({
        method: contactApi.getAllContactTransporters.method,
        route: contactApi.getAllContactTransporters.path,
        params: data.params
      });
    }


  };
};
