import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Loader, Dimmer } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import { Main } from 'components/Layouts';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import General from './General';
import Wrapper from 'components/core/Wrapper/Wrapper';

const Index = ({ dispatch, match, agency, history, user }) => {
  useEffect(() => {
    dispatch(
      actions.agency.getOne({
        params: { agencyId: match.params.agencyId },
      })
    );
    return () => {
      dispatch(actions.agency.reset(['agency', 'error']));
    };
  }, [dispatch]);

  return (
    <>
      {(agency === null && (
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      )) || (
        <Main>
          <Wrapper maxWidth='60rem'>
            <Divider hidden />
            <Wrapper display='flex'>
              <ButtonBack history={history} />
            </Wrapper>
            {agency !== null && (
              <General agencyData={agency} user={user} history={history} />
            )}
          </Wrapper>
        </Main>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    agency: selectors.agency.agencySelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
