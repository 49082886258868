export default {
  VALIDATE_USER: "MY_ASD.USER.VALIDATE_USER",
  VALIDATE_USER_LOADING: "MY_ASD.USER.VALIDATE_USER_LOADING",
  VALIDATE_USER_SUCCESS: "MY_ASD.USER.VALIDATE_USER_SUCCESS",
  VALIDATE_USER_ERROR: "MY_ASD.USER.VALIDATE_USER_ERROR",

  GET_USERS: "MY_ASD.USER.GET_USERS",
  GET_USERS_LOADING: "MY_ASD.USER.GET_USERS_LOADING",
  GET_USERS_SUCCESS: "MY_ASD.USER.GET_USERS_SUCCESS",
  GET_USERS_ERROR: "MY_ASD.USER.GET_USERS_ERROR",

  GET_USER: "MY_ASD.USER.GET_USER",
  GET_USER_LOADING: "MY_ASD.USER.GET_USER_LOADING",
  GET_USER_SUCCESS: "MY_ASD.USER.GET_USER_SUCCESS",
  GET_USER_ERROR: "MY_ASD.USER.GET_USER_ERROR",

  DELETE_USER: "MY_ASD.USER.DELETE_USER",
  DELETE_USER_LOADING: "MY_ASD.USER.DELETE_USER_LOADING",
  DELETE_USER_SUCCESS: "MY_ASD.USER.DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: "MY_ASD.USER.DELETE_USER_ERROR",

  CREATE_USER: "MY_ASD.USER.CREATE_USER",
  CREATE_USER_LOADING: "MY_ASD.USER.CREATE_USER_LOADING",
  CREATE_USER_SUCCESS: "MY_ASD.USER.CREATE_USER_SUCCESS",
  CREATE_USER_ERROR: "MY_ASD.USER.CREATE_USER_ERROR",

  UPDATE_USER: "MY_ASD.USER.UPDATE_USER",
  UPDATE_USER_LOADING: "MY_ASD.USER.UPDATE_USER_LOADING",
  UPDATE_USER_SUCCESS: "MY_ASD.USER.UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "MY_ASD.USER.UPDATE_USER_ERROR",

  RESET_PASSWORD_USER: "MY_ASD.USER.RESET_PASSWORD_USER",
  RESET_PASSWORD_USER_LOADING: "MY_ASD.USER.RESET_PASSWORD_USER_LOADING",
  RESET_PASSWORD_USER_SUCCESS: "MY_ASD.USER.RESET_PASSWORD_USER_SUCCESS",
  RESET_PASSWORD_USER_ERROR: "MY_ASD.USER.RESET_PASSWORD_USER_ERROR",

  WORKMAIL_USER: "MY_ASD.USER.WORKMAIL_USER",
  WORKMAIL_USER_LOADING: "MY_ASD.USER.WORKMAIL_USER_LOADING",
  WORKMAIL_USER_SUCCESS: "MY_ASD.USER.WORKMAIL_USER_SUCCESS",
  WORKMAIL_USER_ERROR: "MY_ASD.USER.WORKMAIL_USER_ERROR",

  UPDATE_PASSWORD_USER: "MY_ASD.USER.UPDATE_PASSWORD_USER",
  UPDATE_PASSWORD_USER_LOADING: "MY_ASD.USER.UPDATE_PASSWORD_USER_LOADING",
  UPDATE_PASSWORD_USER_SUCCESS: "MY_ASD.USER.UPDATE_PASSWORD_USER_SUCCESS",
  UPDATE_PASSWORD_USER_ERROR: "MY_ASD.USER.UPDATE_PASSWORD_USER_ERROR",

  UPDATE_PASSWORD_BY_TOKEN_USER: "MY_ASD.USER.UPDATE_PASSWORD_BY_TOKEN_USER",
  UPDATE_PASSWORD_BY_TOKEN_USER_LOADING:
    "MY_ASD.USER.UPDATE_PASSWORD_BY_TOKEN_USER_LOADING",
  UPDATE_PASSWORD_BY_TOKEN_USER_SUCCESS:
    "MY_ASD.USER.UPDATE_PASSWORD_BY_TOKEN_USER_SUCCESS",
  UPDATE_PASSWORD_BY_TOKEN_USER_ERROR:
    "MY_ASD.USER.UPDATE_PASSWORD_BY_TOKEN_USER_ERROR",

  RESET: "MY_ASD.USER.RESET",
  REFRESH_ERROR: "MY_ASD.USER.REFRESH_ERROR",
};
