import React from 'react';

import { Segment, Wrapper } from 'components/core';

const CookiesDetails: React.FunctionComponent<CookiesDetailsI> = ({
  name,
  host,
  period,
  type,
  description,
}) => {
  return (
    <Segment
      display='flex'
      flexDirection='column'
      padding='10px 20px 10px 20px'
    >
      <Wrapper display='flex' margin='0 0 10px 0'>
        <Wrapper width='30%'>Name</Wrapper>
        <Wrapper width='70%' word-break='break-word'>
          {name}
        </Wrapper>
      </Wrapper>
      <Wrapper display='flex' margin='0 0 10px 0'>
        <Wrapper width='30%'>Host</Wrapper>
        <Wrapper width='70%' word-break='break-word'>
          {host}
        </Wrapper>
      </Wrapper>
      <Wrapper display='flex' margin='0 0 10px 0'>
        <Wrapper width='30%'>Retention period</Wrapper>
        <Wrapper width='70%' word-break='break-word'>
          {period}
        </Wrapper>
      </Wrapper>
      <Wrapper display='flex' margin='0 0 10px 0'>
        <Wrapper width='30%'>Type</Wrapper>
        <Wrapper width='70%' word-break='break-word'>
          {type}
        </Wrapper>
      </Wrapper>
      <Wrapper display='flex' margin='0 0 10px 0'>
        <Wrapper width='30%'>Description</Wrapper>
        <Wrapper width='70%' wordBreak='break-word'>
          {description}
        </Wrapper>
      </Wrapper>
    </Segment>
  );
};

export default CookiesDetails;

interface CookiesDetailsI {
  name: string;
  host: string;
  period: string;
  type: string;
  description: string;
}
