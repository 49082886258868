import React, { useState } from 'react';
import { Modal, Header } from 'semantic-ui-react';
import { ButtonStyled } from './styles';

const Index = ({
  trgrColor,
  trgrContent,
  headerContent,
  modalContent,
  onClick,
  trgrIcon,
  headerIcon,
  noSubmit,
  margin,
  position,
  maxHeight,
  labelPosition,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleConfirmClick = () => {
    onClick();
    setIsOpen(false);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeOnEscape={true}
      size='tiny'
      trigger={
        <ButtonStyled
          content={trgrContent}
          icon={trgrIcon}
          {...(noSubmit ? { type: 'button' } : { type: 'submit' })}
          labelPosition={labelPosition}
          margin='5px'
          btncolor={trgrColor}
          onClick={handleOpen}
        />
      }
      closeIcon
    >
      <Header icon={headerIcon} content={headerContent} />
      <Modal.Content>
        <div>{modalContent}</div>
      </Modal.Content>
      <Modal.Actions>
        <ButtonStyled
          content='Yes'
          icon='checkmark'
          {...(noSubmit ? { type: 'button' } : { type: 'submit' })}
          btncolor='blue'
          onClick={handleConfirmClick}
        />
        <ButtonStyled
          content='No'
          icon='remove'
          {...(noSubmit ? { type: 'button' } : { type: 'submit' })}
          btncolor='red'
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default Index;
