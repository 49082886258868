import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import styled from '@emotion/styled';
import {
  Tab,
  Container,
  Table,
  Modal,
  Header,
  Form,
  Popup,
  Icon,
  Radio,
  Grid,
  Divider,
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';

import TableSpacedRowsSmall from 'components/TableSpacedRowsSmall';
import Button from 'components/Button';
import EmptyTable from 'components/EmptyTableCurve';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Dropdown from 'components/Dropdown';
import ButtonValidationModal from 'components/ButtonValidationModal';
import { serviceSelectionSchema } from 'interfaces/formSchema';
import servicesConf from 'config/services.json';
import { Spacer } from 'components/core';

const Index = ({
  company,
  services,
  user,
  subscribedServices,
  dispatch,
  errorHandlerServices,
  history,
  servicesLoading,
}) => {
  const [runningAction, setRunningAction] = useState(null);
  const [servicesList, setservicesList] = useState([]);
  const [checked, setChecked] = useState('')
  const [quickfixesSelected, setQuickfixesSelected] = useState(false);
  const [subscribeServiceModal, setsubscribeServiceModal] = useState(false);

  useEffect(() => {
    if (subscribedServices && services) {
      setservicesList(builtServiceSelector(services, subscribedServices));
    }
  }, [services, subscribedServices]);

  const builtServiceSelector = (services, subscribedServices) => {
    const result = [];

    services.forEach((service, index) => {
      const serviceAlreadySub = subscribedServices.find(
        (e) => e.Service.id === service.id
      );
      !serviceAlreadySub &&
        result.push({ key: index, value: service.name, text: service.name });
    });

    return result;
  };

  const subscribeService = (values) => {
    dispatch(
      actions[servicesConf[values.service].frontName].subscribe({
        params: { companyId: company.id },
        data: {
          productId: values.productId,
        }
      })
    );
    setsubscribeServiceModal(false);
    setRunningAction('subscribe');
  };

  const unsubscribeHandle = (subscription) => {
    if (!subscription.terminationDate) {
      dispatch(
        actions.payment.unsubscribe({
          params: { companyId: company.id, serviceId: subscription.Service_Id },
        })
      );
    } else {
      dispatch(
        actions.payment.cancelUnsubscribe({
          params: { companyId: company.id, serviceId: subscription.Service_Id },
        })
      );
    }
  };

  const Subscription = styled.div(() => ({
    color: 'rgba(0,0,0,.87)', 
    fontWeight: 'bold',
    display: 'inline',
    fontSize: '10px',
    padding: '0.2rem 0.9rem',
    background: '#e7e7e7',
    height: '1.8rem',
    lineHeight: '1.5rem',
    borderRadius: '1.6rem',
    margin: '10px 0',
  }));


  return (
    <Tab.Pane loading={!company}>
      <Container>
        <Wrapper padding='0 5rem'>
          {user && (user.admin || user.operator) && (
            <>
              {subscribeServiceModal === true && (
                <Modal
                  open={subscribeServiceModal}
                  onClose={() => {
                    dispatch(actions.services.reset(['services', 'error']));
                    setsubscribeServiceModal(false);
                  }}
                >
                  <Modal.Content>
                    <Wrapper padding='2rem'>
                      <Header>Subscribe to a service:</Header>
                      <Formik
                        initialValues={{ 
                          service: '',
                          subscriptionType: ''
                        }}
                        onSubmit={subscribeService}
                        validationSchema={serviceSelectionSchema}
                      >
                        {({
                          errors,
                          touched,
                          handleSubmit,
                          setFieldValue,
                          setFieldTouched,
                          values,
                        }: any) => (
                          <Form onSubmit={handleSubmit}>
                            <Grid>
                            <Grid.Column>
                              <Grid.Row>
                                <Field
                                  loading={servicesLoading}
                                  label='Service'
                                  mandatory
                                  placeholder='Choose service'
                                  name={`service`}
                                  options={servicesList}
                                  component={Dropdown}
                                  meta={{
                                    errors,
                                    touched,
                                    setFieldValue,
                                    setFieldTouched,
                                  }}
                                  uniqueError={errorHandlerServices.includes(
                                    'services'
                                  )}
                                  onChange={(e) => { 
                                    if(e === 'kyanite'){
                                      setQuickfixesSelected(true)
                                    }else{
                                      setQuickfixesSelected(false)
                                    }
                                  }
                                  }
                                />
                            </Grid.Row>                    
                            {quickfixesSelected && (
                                <>
                                <Divider hidden />
                                <Grid.Row>
                                  <Grid columns={3}>
                                  <Grid.Column>   
                                    <Radio
                                      label='Quickproof monthly subscription'
                                      name='productId'
                                      checked={checked === 'monthly'}
                                      onChange={() => {
                                        setChecked('monthly')
                                        setFieldValue('productId', 1)
                                      }}
                                    />
                                  </Grid.Column>
                                  <Grid.Column>  
                                    <Radio
                                      label='Quickproof annual subscription'
                                      name='productId'
                                      checked={checked === 'annually'}
                                      onChange={() => {
                                        setChecked('annually')
                                        setFieldValue('productId', 2)
                                      }}
                                    />
                                  </Grid.Column>                                                                                      
                                  <Grid.Column>  
                                    <Radio
                                      label='Quickproof deliveries'
                                      name='productId'
                                      checked={checked === 'Deliveries'}
                                      onChange={() => {
                                        setChecked('Deliveries')
                                        setFieldValue('productId', 3)
                                      }}
                                    />
                                  </Grid.Column>
                                  </Grid>                            
                                </Grid.Row>
                                <Divider hidden />
                                </>
                              )}                     
                            </Grid.Column>
                            </Grid>
                            <Spacer />
                            <Wrapper margin='auto 0 0 0'>
                              <Button
                                icon='check'
                                content='Subscribe'
                                type='submit'
                              />
                            </Wrapper>
                        </Form>
                        )}
                      </Formik>
                    </Wrapper>
                  </Modal.Content>
                </Modal>
              )}
              <Wrapper display='flex' padding='1rem 0 0 0'>
                <Button
                  icon='plus'
                  type='submit'
                  content='Add'
                  onClick={() => {
                    dispatch(actions.services.getAll());
                    setsubscribeServiceModal(true);
                  }}
                />
              </Wrapper>
            </>
          )}
          <TableSpacedRowsSmall>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>name</Table.HeaderCell>
                <Table.HeaderCell>since</Table.HeaderCell>
                <Table.HeaderCell>termination</Table.HeaderCell>
                <Table.HeaderCell>pause</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(subscribedServices.length === 0 && (
                <EmptyTable colNumber={4} />
              )) ||
                subscribedServices.map((subscribedService, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{subscribedService.Service.name}</Table.Cell>
                      <Table.Cell>
                        {new Date(
                          subscribedService.startDate
                        ).toLocaleDateString('en', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </Table.Cell>
                      <Table.Cell>
                        {subscribedService.terminationDate &&
                          subscribedService.terminationDate}
                      </Table.Cell>
                      <Table.Cell>
                        {subscribedService.pause && (
                          <p>
                            yes
                            <Popup
                              trigger={<Icon name='info circle' />}
                              content={
                                <span>
                                  <p>
                                    Your service has been paused because an
                                    invoice has not been paid.
                                  </p>
                                  <p>
                                    To reactivate your service you must pay the
                                    outstanding bills.
                                  </p>
                                </span>
                              }
                            />
                          </p>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Wrapper
                          margin={subscribedService.Service.internal && '23px'}
                          display='flex'
                          justifyContent='flex-end'
                        >
                          {!subscribedService.Service.internal && (
                            <>
                              {user.admin && (
                                <ButtonValidationModal
                                  trgrContent={
                                    subscribedService.terminationDate
                                      ? 'Cancel unsubscription'
                                      : 'Unsubscribe'
                                  }
                                  headerContent={
                                    subscribedService.terminationDate
                                      ? 'Cancel unsubscription'
                                      : 'Unsubscribe'
                                  }
                                  modalContent={
                                    (subscribedService.terminationDate && (
                                      <p>
                                        Are you sure you want to cancel your
                                        unsubscription?
                                      </p>
                                    )) || (
                                      <p>
                                        <p>
                                          Are you sure you want to unsubscribe
                                          to the{' '}
                                          {subscribedService.Service.name}{' '}
                                          service?
                                        </p>
                                        <p>
                                          The service will remain available to
                                          you until the next expiry date.
                                        </p>
                                      </p>
                                    )
                                  }
                                  trgrColor={
                                    !subscribedService.terminationDate && 'red'
                                  }
                                  trgrIcon={
                                    subscribedService.terminationDate
                                      ? 'cancel'
                                      : 'trash'
                                  }
                                  headerIcon={
                                    subscribedService.terminationDate
                                      ? 'cancel'
                                      : 'trash'
                                  }
                                  onClick={() =>
                                    unsubscribeHandle(subscribedService)
                                  }
                                  margin='5px'
                                />
                              )}

                              {subscribedService.emitter !== null && (subscribedService && subscribedService.Products.find((el) => el.Service_Id === 3 && el.prePayment)) && (
                                <Subscription>Manual - Invoiced subscription</Subscription>
                              )}
                           
                              {(subscribedService && subscribedService.Products.find((el) => el.Service_Id === 3 && el.prePayment)) && (
                                <Subscription>CDD - Invoiced subscription</Subscription>
                              )}

                              {((Number(subscribedService.Service_Id) === 3) && subscribedService.Products.length===0) && (
                                <Subscription>Manual subscription</Subscription>
                              )} 
                                                            
                              <Button
                                content='Go'
                                icon='angle right'
                                type='button'
                                labelPosition='right'
                                onClick={() =>
                                  history.push(
                                    servicesConf[subscribedService.Service.name]
                                      .path
                                  )
                                }
                                margin='5px'
                              />
                            </>
                          )}
                        </Wrapper>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </TableSpacedRowsSmall>
        </Wrapper>
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: selectors.data.allCountriesSelector(state),
    services: selectors.service.servicesSelector(state),
    servicesLoading: selectors.service.serviceLoadingSelector(state),
    user: selectors.auth.userSelector(state),
    errorHandlerServices: selectors.service.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
