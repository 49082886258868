import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    getAllTransportersByToken: false,
    createTransporterByToken: false,
    getTransporterVats: false,
  },
  errors: {
    getAllTransportersByToken: null,
    createTransporterByToken: null,
    getTransporterVats: null,
  },
  loading: {
    getAllTransportersByToken: false,
    createTransporterByToken: false,
    getTransporterVats: false,
  },
  transportersByToken: null,
  transporterVats: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //GET_ALL_TRANSPORTERS_BY_TOKEN
    case actionTypes.GET_ALL_TRANSPORTERS_BY_TOKEN_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllTransportersByToken = true;
      });

    case actionTypes.GET_ALL_TRANSPORTERS_BY_TOKEN_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllTransportersByToken = null;
        draft.loading.getAllTransportersByToken = false;
        draft.success.getAllTransportersByToken = true;
        draft.transportersByToken = action.payload;
      });

    case actionTypes.GET_ALL_TRANSPORTERS_BY_TOKEN_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllTransportersByToken = true;
        draft.loading.getAllTransportersByToken = false;
        draft.success.getAllTransportersByToken = false;
        !draft.error.includes("transportersByToken") &&
          draft.error.push("transportersByToken");
      });

    //CREATE_TRANSPORTER_BY_TOKEN
    case actionTypes.CREATE_TRANSPORTER_BY_TOKEN_LOADING:
      return produce(state, (draft) => {
        draft.loading.createTransporterByToken = true;
      });

    case actionTypes.CREATE_TRANSPORTER_BY_TOKEN_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createTransporterByToken = null;
        draft.loading.createTransporterByToken = false;
        draft.success.createTransporterByToken = true;
      });

    case actionTypes.CREATE_TRANSPORTER_BY_TOKEN_ERROR:
      return produce(state, (draft) => {
        draft.errors.createTransporterByToken = true;
        draft.loading.createTransporterByToken = false;
        draft.success.createTransporterByToken = false;
        !draft.error.includes("createTransporter") &&
          draft.error.push("createTransporter");
      });

    //GET_TRANSPORTER_VATS
    case actionTypes.GET_TRANSPORTER_VATS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getTransporterVats = true;
      });

    case actionTypes.GET_TRANSPORTER_VATS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getTransporterVats = null;
        draft.loading.getTransporterVats = false;
        draft.success.getTransporterVats = true;
        draft.transporterVats = action.payload;
      });

    case actionTypes.GET_TRANSPORTER_VATS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getTransporterVats = true;
        draft.loading.getTransporterVats = false;
        draft.success.getTransporterVats = false;
        !draft.error.includes("transporterVats") &&
          draft.error.push("transporterVats");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
