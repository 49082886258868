import React from "react";
import { Dropdown } from "semantic-ui-react";
import { ErrorMessage, getIn } from "formik";

import { Wrapper } from "./styles";
import { Error } from "components/Form";

const Index = ({
  meta,
  placeholder,
  field,
  disabled,
  label,
  onChange,
  options,
  loading,
  uniqueError,
  setterFunction,
  error,
  multiple = false
}: any) => {
  return (
    <Wrapper>
      <label>{label}</label>
      <Dropdown
        disabled={disabled}
        loading={loading}
        name={field.name}
        multiple={multiple}
        clearable
        fluid
        search
        selection
        placeholder={placeholder}
        options={options}
        value={field.value}
        onChange={(e, { name, value }) => {
          if (onChange) onChange(value);
          meta.setFieldValue(name, value);
          setterFunction(value);
        }}
        onBlur={(e, { name, value }) => {
          meta.setFieldTouched(name, value);
        }}
        error={
          (getIn(meta.touched, field.name) && getIn(meta.errors, field.name)
            ? true
            : false) ||
          error ||
          (uniqueError ? true : false)
        }
      />

      <ErrorMessage name={field.name} render={msg => <Error msg={msg} />} />
    </Wrapper>
  );
};

export default Index;
