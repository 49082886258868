import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const userApi = routesApi.company.user;
  return {
    addUser(data: dataReqI) {
      return api.request({
        method: userApi.addUser.method,
        route: userApi.addUser.path,
        params: data.params,
        data: data.data,
      });
    },
    getUserCompanies(data: dataReqI) {
      return api.request({
        method: userApi.getUserCompanies.method,
        route: userApi.getUserCompanies.path,
        params: data.params,
      });
    },
    removeUser(data: dataReqI) {
      return api.request({
        method: userApi.removeUser.method,
        route: userApi.removeUser.path,
        params: data.params,
      });
    },
    updateUser(data: dataReqI) {
      return api.request({
        method: userApi.updateUser.method,
        route: userApi.updateUser.path,
        params: data.params,
        data: data.data,
      });
    },
    updateUserPermissions(data: dataReqI) {
      return api.request({
        method: userApi.updateUserPermissions.method,
        route: userApi.updateUserPermissions.path,
        params: data.params,
        data: data.data,
      });
    },
    getUserPermissions(data: dataReqI) {
      return api.request({
        method: userApi.getUserPermissions.method,
        route: userApi.getUserPermissions.path,
        params: data.params,
      });
    },
    getOneUser(data: dataReqI) {
      return api.request({
        method: userApi.getOneUser.method,
        route: userApi.getOneUser.path,
        params: data.params,
      });
    },
    getAllUsers(data: dataReqI) {
      return api.request({
        method: userApi.getAllUsers.method,
        route: userApi.getAllUsers.path,
        params: data.params,
      });
    },
  };
};
