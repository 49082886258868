import { actionTypes as fiscalDeclarationActionTypes } from 'store/fiscal/declaration';
import { actionTypes as fiscalOssActionTypes } from 'store/fiscal/oss';
import { actionTypes as vatActionTypes } from 'store/fiscal/vat';
import { actionTypes as customsDeclarationActionTypes } from 'store/customs/declaration';
import { actionTypes as customsClerkActionTypes } from 'store/customs/clerk';
import { actionTypes as intrastatActionTypes } from 'store/intrastat';
import { actionTypes as chatActionTypes } from 'store/chat';
import { actionTypes as userActionTypes } from 'store/user';
import { actionTypes as contactActionTypes } from 'store/company/contact';
import { actionTypes as driversActionTypes } from 'store/company/driver';
import { actionTypes as companyClerksPricingTypes } from 'store/company/clerksPricing';
import { actionTypes as companyKeyInfoTypes } from 'store/company/keyinfo';
import { actionTypes as companyActionTypes } from 'store/company';
import { actionTypes as companyUserActionTypes } from 'store/company/user';
import { actionTypes as companyPaymentActionTypes } from 'store/company/payment';
import { actionTypes as companyInvoiceActionTypes } from 'store/company/invoice';
import { actionTypes as companyIntrastatSettingsTypes } from 'store/company/settings/intrastat';
import { actionTypes as companyCollectionBoxSettingsTypes } from 'store/company/settings/collectionbox';
import { actionTypes as podActionTypes } from 'store/fiscal/quickFixes';
import { actionTypes as collectionboxActionTypes } from 'store/fiscal/collectionBox';
import { actionTypes as authActionTypes } from 'store/auth';
import { actionTypes as notificationActionTypes } from 'store/notifications';
import { actionTypes as operatorActionTypes } from 'store/operators';
import { actionTypes as newsActionTypes } from 'store/news';
import { actionTypes as socketActionTypes } from 'store/socket';
import { actionTypes as configurationsActionTypes } from 'store/company/configurations';
import { dataReqI } from 'interfaces';

export default {
  removeCookies(data: dataReqI) {
    return {
      type: socketActionTypes.DISCONNECT,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: socketActionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  subscribeAppState(email: string){
    return {
      type: 'SOCKET',
      event: `removeCookie:${email}`,
      handle: socketActionTypes.DISCONNECT,
    };
  },
  unsubscribeAppState(email: string){
    return {
      type: 'SOCKET',
      event: `removeCookie:${email}`,
      leave: true,
    };
  },
  subscribeCollectionboxesALLFiltered(companyId: number){
    return {
      type: 'SOCKET',
      event: `collectionbox:${companyId}:monitoring`,
      handle: collectionboxActionTypes.GET_FILTERED_COLLECTIONBOXES,
    };
  },
  unsubscribeCollectionboxesALLFiltered(companyId: number){
    return {
      type: 'SOCKET',
      event: `collectionbox:${companyId}:monitoring`,
      leave: true,
    };
  },
  subscribeOneCollectionbox(companyId: number, collectionboxId: string){
    return {
      type: 'SOCKET',
      event: `collectionbox:${companyId}:${collectionboxId}:one`,
      handle: collectionboxActionTypes.GET_ONE_COLLECTIONBOX,
    };
  },
  unsubscribeOneCollectionbox(companyId: number, collectionboxId: string){
    return {
      type: 'SOCKET',
      event: `collectionbox:${companyId}:${collectionboxId}:one`,
      leave: true,
    };
  },
  subscribeCBoxObligations( 
    companyId: number){
      return {
        type: 'SOCKET',
        event: `collectionbox:${companyId}:obligations`,
        handle: collectionboxActionTypes.GET_OBLIGATION,
      };
    },

  unsubscribeCBoxObligations( 
    companyId: number){
      return {
        type: 'SOCKET',
        event: `collectionx:${companyId}:obligations`,
        leave: true
      };
    },

    subscribeCollectionBoxesCountries(companyId: number, iso: string){
      return {
        type: 'SOCKET',
        event: `collectionbox:${companyId}:countries:${iso}`,
        handle: collectionboxActionTypes.GET_OBLIGATION,
      };
    },

    unsubscribeCollectionBoxesCountries(companyId: number, iso: string){
      return {
        type: 'SOCKET',
        event: `collectionbox:${companyId}:countries:${iso}`,
        leave: true
      };
    },

  subscribeConnectedUsers(){
    return {
      type: 'SOCKET_CONNEXIONS',
      event: `getConnexions`,
      handle: 'getConnexions',
    };
  },
  resetConnexions(keys) {
    return {
      type: 'SOCKET_CONNEXIONS',
      payload: {
        keys,
      },
      handle: 'resetGetConnexions',
    };
  },
  unsubscribeConnectedUsers(){
    return {
      type: 'SOCKET_CONNEXIONS',
      event: `userLogged`,
      leave: true,
    };
  },

  subscribeObligations( 
    companyId: number){
      return {
        type: 'SOCKET',
        event: `obligations:${companyId}`,
        handle: vatActionTypes.GET_OBLIGATION,
      };
    },

  unsubscribeObligations( 
      companyId: number){
        return {
          type: 'SOCKET',
          event: `obligations:${companyId}`,
          leave: true
        };
      },

  
  subscribeFiscalVatFiles( 
    companyId: number, declarationId: string, repository: string){
      return {
        type: 'SOCKET',
        event: `vats:${companyId}:${declarationId}:${repository}:list`,
        handle: vatActionTypes.GET_FULL_LIST_DOCUMENTS,
      };
    },

    unsubscribeFiscalVatFiles( 
      companyId: number, declarationId: string, repository: string){
        return {
          type: 'SOCKET',
          event: `vats:${companyId}:${declarationId}:${repository}:list`,
          leave: true
        };
      },

  subscribeVatDeclarations( 
    companyId: number){
      return {
        type: 'SOCKET',
        event: `vats:${companyId}`,
        handle: vatActionTypes.GET_ALL_VAT_DECLARATIONS,
      };
    },

  unsubscribeVatDeclarations( 
    companyId: number){
      return {
        type: 'SOCKET',
        event: `vats:${companyId}`,
        leave: true
      };
    },

  subscribeOneVat(companyId: number, declarationId: string){
      return {
        type: 'SOCKET',
        event: `vatDeclaration:${companyId}:${declarationId}:one`,
        handle: vatActionTypes.GET_ONE_VAT_DECLARATION,
      };
    },
    
  unsubscribeOneVat(companyId: number, declarationId: string){
      return {
        type: 'SOCKET',
        event: `vatDeclaration:${companyId}:${declarationId}:one`,
        leave: true
      };
    },

  subscribeOneIntrastat(companyId: number, intrastatId: string){
      return {
        type: 'SOCKET',
        event: `intrastat:${companyId}:${intrastatId}:one`,
        handle: intrastatActionTypes.GET_ONE_INTRASTAT,
      };
    },

  unsubscribeOneIntrastat(companyId: number, intrastatId: string){
      return {
        type: 'SOCKET',
        event: `intrastat:${companyId}:${intrastatId}:one`,
        leave: true
      };
    },
  
  subscribeIntrastatFiles(companyId: number, intrastatId: string, documentId: number, folder: string, status: string){
      return {
        type: 'SOCKET',
        event: `intrastat:files:${companyId}:${intrastatId}:${documentId}:${folder}:${status}`,
        handle: intrastatActionTypes.GET_ARCHIVE_FILES,
      };
    },
  unsubscribeIntrastatFiles(companyId: number, intrastatId: string, documentId: number, folder: string, status: string){
    return {
      type: 'SOCKET',
      event: `intrastat:files:${companyId}:${intrastatId}:${documentId}:${folder}:${status}`,
      leave: true
    };
  },

  subscribeCollectionBoxFiles(companyId: number, collectionboxId: string, documentId: number, folder: string, status: string){
    return {
      type: 'SOCKET',
      event: `collectionbox:files:${companyId}:${collectionboxId}:${documentId}:${folder}:${status}`,
      handle: collectionboxActionTypes.GET_ARCHIVE_FILES,
    };
  },
  unsubscribeCollectionBoxFiles(companyId: number, collectionboxId: string, documentId: number, folder: string, status: string){
    return {
      type: 'SOCKET',
      event: `collectionbox:files:${companyId}:${collectionboxId}:${documentId}:${folder}:${status}`,
      leave: true
    };
  },

  subscribeTransitRisk(companyId: number){
    return {
      type: 'SOCKET',
      event: `company:transit:${companyId}:risk`,
      handle: customsDeclarationActionTypes.TRANSIT_RISK,
    };
},

  unsubscribeTransitRisk(companyId: number){
    return {
      type: 'SOCKET',
      event: `company:transit:${companyId}:risk`,
      leave: true,
    };
  },

  subscribeMonitoring(companyId: number){
      return {
        type: 'SOCKET',
        event: `company:transit:${companyId}`,
        handle: configurationsActionTypes.GET_THRESHOLD,
      };
  },

  unsubscribeMonitoring(companyId: number){
    return {
      type: 'SOCKET',
      event: `company:transit:${companyId}`,
      leave: true,
    };
  },

  subscribeIntrastatsALL( 
    companyId: number){
      return {
        type: 'SOCKET',
        event: `intrastat:${companyId}`,
        handle: intrastatActionTypes.GET_ALL_INTRASTATS,
      };
    },

    unsubscribeIntrastatsALL( 
    companyId: number){
      return {
        type: 'SOCKET',
        event: `intrastat:${companyId}`,
        leave: true
      };
    },

    subscribeIntrastatsByFilters( 
    limit: number, state: string){
      return {
        type: 'SOCKET',
        event: `intrastat:filters:${limit}:${state}`,
        handle: intrastatActionTypes.GET_ALL_INTRASTATS,
      };
    },

    unsubscribeIntrastatsByFilters( 
      limit: number, state: string){
        return {
          type: 'SOCKET',
          event: `intrastat:filters:${limit}:${state}`,
          handle: intrastatActionTypes.GET_ALL_INTRASTATS,
        };
    },
    
  subscribeIntrastats( 
    companyId: number,
    operator: boolean,
    country_code: string){
      return {
        type: 'SOCKET',
        event: `intrastat:${companyId}:${operator}:${country_code}`,
        handle: intrastatActionTypes.GET_ALL_INTRASTATS,
      };
    },

  unsubscribeIntrastats( 
    companyId: number,
    operator: boolean,
    country_code: string){
      return {
        type: 'SOCKET',
        event: `intrastat:${companyId}:${operator}:${country_code}`,
        leave: true
      };
    },

  subscribeAccises( 
    companyId: number){
      return {
        type: 'SOCKET',
        event: `customs:accises:${companyId}`,
        handle: customsDeclarationActionTypes.GET_ACCISES,
      };
    },

  unsubscribeAccises( 
    companyId: number){
      return {
        type: 'SOCKET',
        event: `customs:accises:${companyId}`,
        leave: true,
      };
    },


  subscribeUpdateCustomsClerkLines( 
    companyId: number,
	  declarationId: string){
      return {
        type: 'SOCKET',
        event: `customs:clerk:file:${companyId}:${declarationId}:getAllLines`,
        handle: customsDeclarationActionTypes.GET_ALL_LINES,
      };
    },

  unsubscribeUpdateCustomsClerkLines( 
    companyId: number,
    declarationId: string){
      return {
        type: 'SOCKET',
        event: `customs:clerk:file:${companyId}:${declarationId}:getAllLines`,
        leave: true,
      };
  },

  subscribeRefreshPartnerComments(
    companyId: number,
	  declarationId: string,
  ){
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:${declarationId}:getComments`,
      handle: customsDeclarationActionTypes.GET_PART_COMMENTS,
    };
  },

  unsubscribeRefreshPartnerComments(
    companyId: number,
	  declarationId: string,
  ){
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:${declarationId}:getComments`,
      leave: true,
    };
  },


  subscribeRefreshCustomsDeclarationChed(
    companyId: number,
    declarationId: string
  ) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:${declarationId}:getChed`,
      handle: customsDeclarationActionTypes.GET_ALL_CHED,
    };
  },

  unsubscribeRefreshCustomsDeclarationChed(
    companyId: number,
    declarationId: string
  ) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:${declarationId}:getChed`,
      leave: true,
    };
  },

  subscribeRefreshCustomsClerkFiles() {
    return {
      type: 'SOCKET',
      event: 'customs:clerk:files',
      handle: customsClerkActionTypes.GET_ALL_FILES,
    };
  },

  unsubscribeRefreshCustomsClerkFiles() {
    return {
      type: 'SOCKET',
      event: 'customs:clerk:files',
      leave: true,
    };
  },

  subscribeValidateCustomsClerkFiles(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}`,
      handle: customsClerkActionTypes.GET_ONE_FILE,
    };
  },

  unsubscribeValidateCustomsClerkFiles(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}`,
      leave: true,
    };
  },

  subscribeUpdateCustomsClerkFilesInvoicing(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}:invoicing`,
      handle: customsClerkActionTypes.GET_FILE_HAS_BILLINGS,
    };
  },

  unsubscribeUpdateCustomsClerkFilesInvoicing(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}:invoicing`,
      leave: true,
    };
  },

  subscribeUpdateIssueTime(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}:issue:time`,
      handle: customsClerkActionTypes.GET_ISSUE_TIMES,
    };
  },

  unsubscribeUpdateIssueTime(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}:issue:time`,
      leave: true,
    };
  },

  subscribeUpdateTrailerLicensePlate(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}:trailerLicensePlate`,
      handle: customsClerkActionTypes.GET_TRAILER_LICENSE_PLATE,
    };
  },

  unsubscribeUpdateTrailerLicensePlate(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}:trailerLicensePlate`,
      leave: true,
    };
  },

  subscribeUpdateIssuePicture(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}:issue:picture`,
      handle: customsClerkActionTypes.DOWNLOAD_PICTURES_ISSUES_ZIP,
    };
  },

  unsubscribeUpdateIssuePicture(customsClerkFileId) {
    return {
      type: 'SOCKET',
      event: `customs:clerk:file:${customsClerkFileId}:issue:picture`,
      leave: true,
    };
  },

  subscribeDeclarations() {
    return {
      type: 'SOCKET',
      event: 'fiscal:declaration:new',
      handle: fiscalDeclarationActionTypes.GETDECLARATIONS,
    };
  },

  subscribeUpdateDeclarations() {
    return {
      type: 'SOCKET',
      event: 'fiscal:declaration:update',
      handle: fiscalDeclarationActionTypes.GETDECLARATIONS,
    };
  },

  subscribeIntegrations() {
    return {
      type: 'SOCKET',
      event: 'fiscal:oss:new',
      handle: fiscalOssActionTypes.GET_OSS_INTEGRATIONS,
    };
  },

  unsubscribeIntegrations() {
    return {
      type: 'SOCKET',
      event: 'fiscal:oss:new',
      leave: true,
    };
  },

  subscribeUpdatePermissionsCompanySelected(userId, companyId) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:user:${userId}:permission:update`,
      handle: authActionTypes.UPDATE_PERMISSIONS_COMPANY_SELECTED,
    };
  },

  unsubscribeUpdatePermissionsCompanySelected(userId, companyId) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:user:${userId}:permission:update`,
      leave: true,
    };
  },

  subscribeUserCompanies(userId) {
    return {
      type: 'SOCKET',
      event: `user:${userId}:companies`,
      handle: authActionTypes.GET_USER_COMPANIES,
    };
  },
  
  unsubscribeUserCompanies(userId) {
    return {
      type: 'SOCKET',
      event: `user:${userId}:companies`,
      leave: true,
    };
  },

  subscribeUpdateUserCompany(userId: number) {
    return {
      type: 'SOCKET',
      event: `user:${userId}:company:update`,
      handle: companyUserActionTypes.GET_USER_COMPANIES,
    };
  },

  unsubscribeUpdateUserCompany(userId: number) {
    return {
      type: 'SOCKET',
      event: `user:${userId}:company:update`,
      leave: true,
    };
  },

  subscribeAddContact(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:contact:update`,
      handle: contactActionTypes.GET_ALL_CONTACTS,
    };
  },

  unsubscribeAddContact(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:contact:update`,
      leave: true,
    };
  },

  subscribeAddContactTransporter(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:contact:transporter:update`,
      handle: contactActionTypes.GET_ALL_CONTACT_TRANSPORTERS,
    };
  },

  unsubscribeAddContactTransporter(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:contact:transporter:update`,
      leave: true,
    };
  },

  subscribeAllNotification(userId: number) {
    return {
      type: 'SOCKET',
      event: `notification:${userId}`,
      handle: notificationActionTypes.GET_ALL_NOTIFICATION,
    };
  },

  unsubscribeAllNotification(userId: number) {
    return {
      type: 'SOCKET',
      event: `notification:${userId}`,
      leave: true,
    };
  },

  subscribePermissionOperator(operatorId: number) {
    return {
      type: 'SOCKET',
      event: `operator:${operatorId}:update`,
      handle: operatorActionTypes.GET_ONE_OPERATOR,
    };
  },
  unsubscribePermissionOperator(operatorId: number) {
    return {
      type: 'SOCKET',
      event: `operator:${operatorId}:update`,
      leave: true,
    };
  },

  subscribeNumberNotification(userId: number) {
    return {
      type: 'SOCKET',
      event: `notification:number:${userId}`,
      handle: notificationActionTypes.GET_NUMBER_UNREAD_NOTIFICATION,
    };
  },
  unsubscribeNumberNotification(userId: number) {
    return {
      type: 'SOCKET',
      event: `notification:number:${userId}`,
      leave: true,
    };
  },

  subscribeUpdateCompany() {
    return {
      type: 'SOCKET',
      event: 'company:update',
      handle: companyActionTypes.GET_ALL,
    };
  },

  unsubscribeUpdateCompany() {
    return {
      type: 'SOCKET',
      event: 'company:update',
      leave: true,
    };
  },

  subscribeGetIntrastSetting(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:intrastat:${companyId}`,
      handle: companyIntrastatSettingsTypes.GET_SETTING,
    };
  },

  unsubscribeGetIntrastSetting(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:intrastat:${companyId}`,
      leave: true,
    };
  },

  subscribeGetCollectionBoxSetting(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:collectionbox:${companyId}`,
      handle: companyCollectionBoxSettingsTypes.GET_SETTING,
    };
  },

  unsubscribeGetCollectionBoxSetting(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:collectionbox:${companyId}`,
      leave: true,
    };
  },

  subscribeGetCollectionBoxAssignedCountry(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:collectionbox:${companyId}:assignedCountry`,
      handle: companyCollectionBoxSettingsTypes.GET_ASSIGNED_COUNTRIES,
    };
  },

  unsubscribeGetCollectionBoxAssignedCountry(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:collectionbox:${companyId}:assignedCountry`,
      leave: true,
    };
  },

  subscribeGetIntrastatAssignedCountry(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:intrastat:${companyId}:assignedCountry`,
      handle: companyIntrastatSettingsTypes.GET_ASSIGNED_COUNTRIES,
    };
  },

  unsubscribeGetIntrastatAssignedCountry(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:intrastat:${companyId}:assignedCountry`,
      leave: true,
    };
  },

  subscribeUpdateCompanyClerkPricing(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:clerkPricing:${companyId}:update`,
      handle: companyClerksPricingTypes.GET_ONE_CLERKS_PRICING,
    };
  },

  unsubscribeUpdateCompanyClerkPricing(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:clerkPricing:${companyId}:update`,
      leave: true,
    };
  },

  subscribeGetBilling(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:clerkPricing:${companyId}:get`,
      handle: customsClerkActionTypes.GET_BILLINGS,
    };
  },

  unsubscribeGetBilling(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:clerkPricing:${companyId}:get`,
      leave: true,
    };
  },

  subscribeUpdatePaymentCompany(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:payment:update`,
      handle: companyPaymentActionTypes.GET_PAYMENT,
    };
  },
  unsubscribeUpdatePaymentCompany(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:payment:update`,
      leave: true,
    };
  },

  subscribeUpdateInvoiceCompany(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:invoice:update`,
      handle: companyInvoiceActionTypes.GET_ALL,
    };
  },

  unsubscribeUpdateInvoiceCompany(companyId: number) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:invoice:update`,
      leave: true,
    };
  },

  subscribeDeclarationsInvoice() {
    return {
      type: 'SOCKET',
      event: `customs:declaration:monitoring:invoice`,
      handle: customsDeclarationActionTypes.GET_DECLARATIONS_INVOICE,
    };
  },

  unsubscribeDeclarationsInvoice() {
    return {
      type: 'SOCKET',
      event: `customs:declaration:monitoring:invoice`,
      leave: true,
    };
  },

  subscribeCustomsClerkControlPdf() {
    return {
      type: 'SOCKET',
      events: `customs:clerk:summary:pdf:new`,
      handle: customsClerkActionTypes.SUBSCRIBE_CREATE_CONTROL_PDF,
    };
  },

  unsubscribeCustomsClerkControlPdf() {
    return {
      type: 'SOCKET',
      events: `customs:clerk:summary:pdf:new`,
      leave: true,
    };
  },

  subscribeKyaniteMassIntegration(companyId: number) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:massIntegration:update:${companyId}`,
      handle: podActionTypes.GET_MASSINTEGRATIONS,
    };
  },
  unsubscribeKyaniteMassIntegration(companyId: number) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:massIntegration:update:${companyId}`,
      leave: true,
    };
  },

  subscribeUpdateCustomsDeclaration(companyId: number) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}`,
      handle: customsDeclarationActionTypes.GET_ALL,
    };
  },

  subscribeUpdateDrivers(companyId: number) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:driver:${companyId}`,
      handle: driversActionTypes.GET_ALL_DRIVERS,
    };
  },
  unsubscribeUpdateDrivers(companyId: number) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:driver:${companyId}`,
      leave: true,
    };
  },
  unsubscribeUpdateCustomsDeclaration(companyId: number) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}`,
      leave: true,
    };
  },

  subscribeUpdateCustomsDeclarationProcessing() {
    return {
      type: 'SOCKET',
      event: `customs:declaration:monitoring`,
      handle: customsDeclarationActionTypes.GET_PROCESSING_DECLARATIONS,
    };
  },
  unsubscribeUpdateCustomsDeclarationProcessing() {
    return {
      type: 'SOCKET',
      event: `customs:declaration:monitoring`,
      leave: true,
    };
  },

  subscribeVatDeclarationsProcessing() {
    return {
      type: 'SOCKET',
      event: `fiscal:fiscalVat:monitoring`,
      handle: customsDeclarationActionTypes.GET_PROCESSING_DECLARATIONS,
    };
  },

  unsubscribeVatDeclarationsProcessing() {
    return {
      type: 'SOCKET',
      event: `fiscal:fiscalVat:monitoring`,
      leave: true
    };
  },

  subscribeUpdateChat(
    companyId: number,
    id: string
  ) {
    return {
      type: 'SOCKET',
      event: `chat:${companyId}:${id}`,
      handle: chatActionTypes.GET_CHATS,
    };
  },

  unsubscribeUpdateChat(
    companyId: number,
    id: string
  ) {
    return {
      type: 'SOCKET',
      event: `chat:${companyId}:${id}`,
      leave: true,
    };
  },

  subscribeUpdateCustomsDeclarationChat(
    companyId: number,
    declarationId: string
  ) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:${declarationId}:getChat`,
      handle: customsDeclarationActionTypes.GET_CHAT,
    };
  },
  unsubscribeUpdateCustomsDeclarationChat(
    companyId: number,
    declarationId: string
  ) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:${declarationId}:getChat`,
      leave: true,
    };
  },

  subscribeUpdateOneCustomsDeclaration(
    companyId: number,
    declarationId: string
  ) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:${declarationId}`,
      handle: customsDeclarationActionTypes.GET_ONE,
    };
  },
  subscribeUpdateOneHostedCustomsDeclaration(
    companyId: number,
    declarationId: string
  ) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:${declarationId}:hosted`,
      handle: customsDeclarationActionTypes.GET_ONE_HOSTED,
    };
  },
  unsubscribeUpdateOneCustomsDeclaration(
    companyId: number,
    declarationId: string
  ) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:${declarationId}`,
      leave: true,
    };
  },

  subscribeUpdateCustomsClerkFilesBillings(customsClerkFileId: number) {
    return {
      type: 'SOCKET',
      event: `customs:billing:${customsClerkFileId}`,
      handle: customsClerkActionTypes.GET_FILE_HAS_BILLINGS,
    };
  },
  unsubscribeUpdateCustomsClerkFilesBillings(customsClerkFileId: number) {
    return {
      type: 'SOCKET',
      event: `customs:billing:${customsClerkFileId}`,
      leave: true,
    };
  },

  subscribeUpdateDelivery(delivery) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:delivery:${delivery}`,
      handle: podActionTypes.GET_ONE,
    };
  },

  subscribehandleSplitProof(deliveryId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:delivery:${deliveryId}:split`,
      handle: podActionTypes.UPLOAD_SPLIT_PROOF,
    };
  },
  unsubscribehandleSplitProof(deliveryId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:delivery:${deliveryId}:split`,
      leave: true,
    };
  },

  unsubscribeUpdateDelivery(delivery) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:delivery:${delivery}`,
      leave: true,
    };
  },

  subscribeUpdateDeliveryCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      handle: podActionTypes.GET_KYANITE_OF_ONE_SELLER,
    };
  },

  unsubscribeUpdateDeliveryCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      leave: true,
    };
  },

  subscribeUpdateUser(userId) {
    return {
      type: 'SOCKET',
      event: `user:update:${userId}`,
      handle: authActionTypes.UPDATE,
    };
  },

  unsubscribeUpdateUser(userId) {
    return {
      type: 'SOCKET',
      event: `user:update:${userId}`,
      leave: true,
    };
  },

  subscribeUpdateTotalVatRiskCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      handle: podActionTypes.GET_TOTAL_VAT_RISK,
    };
  },

  unsubscribeUpdateTotalVatRiskCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      leave: true,
    };
  },

  subscribeUpdateNumberOfDeliveryPendingCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      handle: podActionTypes.GET_NUMBER_OF_PENDING,
    };
  },

  unsubscribeUpdateNumberOfDeliveryPendingCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      leave: true,
    };
  },

  subscribeUpdateNumberOfProofPendingCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      handle: podActionTypes.GET_NUMBER_OF_PROOF_PENDING,
    };
  },

  unsubscribeUpdateNumberOfProofPendingCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      leave: true,
    };
  },

  subscribeUpdateNumberOfDeliveryReceiveCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      handle: podActionTypes.GET_NUMBER_OF_RECEIVED,
    };
  },

  unsubscribeUpdateNumberOfDeliveryReceiveCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:${companyId}`,
      leave: true,
    };
  },

  subscribeUpdatePodDelivery(deliveryId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:delivery:${deliveryId}`,
      handle: podActionTypes.GET_KYANITE_OF_ONE_SELLER,
    };
  },

  unsubscribeUpdatePodDelivery(deliveryId) {
    return {
      type: 'SOCKET',
      event: `fiscal:kyanite:delivery:${deliveryId}`,
      leave: true,
    };
  },

  subscribeUpdateOneCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:update`,
      handle: companyActionTypes.GET_ONE,
    };
  },

  unsubscribeUpdateOneCompany(companyId) {
    return {
      type: 'SOCKET',
      event: `company:${companyId}:update`,
      leave: true,
    };
  },

  subscribeUpdateUsers() {
    return {
      type: 'SOCKET',
      event: 'user:update',
      handle: userActionTypes.GET_USERS,
    };
  },

  unsubscribeUpdateUsers() {
    return {
      type: 'SOCKET',
      event: 'user:update',
      leave: true,
    };
  },

  unsubscribeUpdateRoles() {
    return {
      type: 'SOCKET',
      event: 'role:update',
      leave: true,
    };
  },

  unsubscribeDeclarations() {
    return {
      type: 'SOCKET',
      event: 'declarations',
      leave: true,
    };
  },

  unsubscribeUpdateDeclarations() {
    return {
      type: 'SOCKET',
      event: 'fiscal:declaration:update',
      leave: true,
    };
  },

  subscribeUpdateNews() {
    return {
      type: 'SOCKET',
      event: 'news:update',
      handle: newsActionTypes.GET_ALL,
    };
  },

  unsubscribeUpdateNews() {
    return {
      type: 'SOCKET',
      event: 'news:update',
      leave: true,
    };
  },

  subscribeReadNews(userId, serviceId) {
    return {
      type: 'SOCKET',
      event: `news:${userId}:${serviceId}:read`,
      handle: newsActionTypes.GET_ALL_READ,
    };
  },

  unsubscribeReadNews(userId, serviceId) {
    return {
      type: 'SOCKET',
      event: `news:${userId}:${serviceId}:read`,
      leave: true,
    };
  },

  subscribeMandates(companyId) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:mandates`,
      handle: customsDeclarationActionTypes.GET_MANDATES,
    };
  },

  unsubscribeMandates(companyId) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:mandates`,
      leave: true,
    };
  },

  subscribeKeyInfos(companyId) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:keyinfos`,
      handle: companyKeyInfoTypes.GET_KEYINFOS,
    };
  },

  unsubscribeKeyInfos(companyId) {
    return {
      type: 'SOCKET',
      event: `customs:declaration:${companyId}:keyinfos`,
      leave: true,
    };
  },

  subscribeParkingList() {
    return {
      type: 'SOCKET',
      event: `user:parking`,
      handle: customsDeclarationActionTypes.GET_PARKING,
    };
  },

  unsubscribeParkingList() {
    return {
      type: 'SOCKET',
      event: `user:parking`,
      leave: true,
    };
  },
};
