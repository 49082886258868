import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const getAllCompanyDeclarationsErrorSelector = state => state.fiscalVat.errors.companyDeclarations;
const getOneDeclarationErrorSelector = state => state.fiscalVat.errors.getVatDeclaration;
const createOneDeclarationErrorSelector = state => state.fiscalVat.errors.createDeclaration;
const getDocsFromCatErrorSelector = state => state.fiscalVat.errors.docsFromCat;
const deleteDocumentErrorSelector = state => state.fiscalVat.errors.deleteDoc;
const addDocumentErrorSelector = state => state.fiscalVat.errors.docs;
const downloadErrorSelector = state => state.fiscalVat.errors.downloadDoc;
const subscribeErrorSelector = state => state.fiscalVat.errors.subscribe;
const unsubscribeErrorSelector = state => state.fiscalVat.errors.unsubscribe;
const mappingErrorSelector = state => state.fiscalVat.errors.mapping;
const countryVatErrorSelector = state => state.fiscalVat.errors.countryVatDeclarations;
const getOperatorAssignedErrorSelector = state => state.fiscalVat.errors.getOperatorsCountries;
const excelErrorSelector = state => state.fiscalVat.errors.excel;
const updateExcelErrorSelector = state => state.fiscalVat.errors.updateExcel;
const getfiscalVatDeclarationsErrorSelector = state => state.fiscalVat.errors.taxflows;
const getFullListErrorSelector = state => state.fiscalVat.errors.archiveFiles;
const getCategoriesErrorSelector = state => state.fiscalVat.errors.categories;


const errorsSelector = createErrorsSelector([
  getfiscalVatDeclarationsErrorSelector,
  getOneDeclarationErrorSelector,
  getAllCompanyDeclarationsErrorSelector,
  createOneDeclarationErrorSelector,
  getDocsFromCatErrorSelector,
  deleteDocumentErrorSelector,
  addDocumentErrorSelector,
  downloadErrorSelector,
  subscribeErrorSelector,
  unsubscribeErrorSelector,
  mappingErrorSelector,
  countryVatErrorSelector,
  getOperatorAssignedErrorSelector,
  excelErrorSelector,
  updateExcelErrorSelector,
  getFullListErrorSelector,
]);


// Loading
const getAllCompanyDeclarationsLoadingSelector = state => state.fiscalVat.loading.companyDeclarations;
const getOneDeclarationLoadingSelector = state => state.fiscalVat.loading.getVatDeclaration;
const createOneDeclarationLoadingSelector = state => state.fiscalVat.loading.createDeclaration;
const getDocsFromCatLoadingSelector = state => state.fiscalVat.loading.docsFromCat;
const deleteDocumentLoadingSelector = state => state.fiscalVat.loading.deleteDoc;
const addDocumentLoadingSelector = state => state.fiscalVat.loading.docs;
const downloadLoadingSelector = state => state.fiscalVat.loading.downloadDoc;
const subscribeLoadingSelector = state => state.fiscalVat.loading.subscribe;
const unsubscribeLoadingSelector = state => state.fiscalVat.loading.unsubscribe;
const subscribedLoadingSelector = state => state.fiscalVat.loading.subscribed;
const mappingLoadingSelector = state => state.fiscalVat.loading.mapping;
const updateDocLoadingSelector = state => state.fiscalVat.loading.updateDoc;
const countryVatLoadingSelector = state => state.fiscalVat.loading.countryVatDeclarations;
const getOperatorAssignedLoadingSelector = state => state.fiscalVat.loading.getOperatorsCountries;
const obligationGetLoadingSelector = (state) => state.configurations.loading.obligation;
const obligationCreateLoadingSelector = (state) => state.configurations.loading.obligationCreate;
const obligationUpdateLoadingSelector = (state) => state.configurations.loading.obligationUpdate;
const getfiscalVatDeclarationsLoadingSelector = state => state.fiscalVat.loading.taxflows;
const getFullListLoadingSelector = state => state.fiscalVat.loading.archiveFiles;
const deleteFilesLoadingSelector = (state) => state.fiscalVat.loading.deleteFiles;
const getCategoriesLoadingSelector = state => state.fiscalVat.loading.categories;


const loadingSelector = createLoadingSelector([
      (state) => state.fiscalVat.loading.getAllDeclarations,
      (state) => state.fiscalVat.loading.getOneDeclaration,
      (state) => state.fiscalVat.loading.createDeclaration,
      (state) => state.fiscalVat.loading.excel,
      (state) => state.fiscalVat.loading.updateExcel,
]);

const obligationLoadingSelector = createLoadingSelector([
  obligationGetLoadingSelector,
  obligationCreateLoadingSelector,
  obligationUpdateLoadingSelector,
]);

// Success
const getAllCompanyDeclarationsSuccessSelector = state => state.fiscalVat.success.companyDeclarations;
const getOneDeclarationSuccessSelector = state => state.fiscalVat.success.getVatDeclaration;
const createOneDeclarationSuccessSelector = state => state.fiscalVat.success.createDeclaration;
const getDocsFromCatSuccessSelector = state => state.fiscalVat.success.docsFromCat;
const deleteDocumentSuccessSelector = state => state.fiscalVat.success.deleteDoc;
const addDocumentSuccessSelector = state => state.fiscalVat.success.docs;
const downloadSuccessSelector = state => state.fiscalVat.success.downloadDoc;
const subscribeSuccessSelector = state => state.fiscalVat.success.subscribe;
const unsubscribeSuccessSelector = state => state.fiscalVat.success.unsubscribe;
const mappingSuccessSelector = state => state.fiscalVat.success.mapping;
const countryVatSuccessSelector = state => state.fiscalVat.success.countryVatDeclarations;
const getOperatorAssignedSuccessSelector = state => state.fiscalVat.success.getOperatorsCountries;
const excelSuccessSelector = state => state.fiscalVat.success.excel;
const updateExcelSuccessSelector = state => state.fiscalVat.success.updatExcel;
const getfiscalVatDeclarationsSuccessSelector = state => state.fiscalVat.success.taxflows;
const getFullListSuccessSelector = state => state.fiscalVat.success.archiveFiles;
const getCategoriesSuccessSelector = state => state.fiscalVat.success.categories;


const successSelector = createSuccessSelector([
    getfiscalVatDeclarationsSuccessSelector,
    getAllCompanyDeclarationsSuccessSelector,
    getOneDeclarationSuccessSelector,
    getDocsFromCatSuccessSelector,
    deleteDocumentSuccessSelector,
    addDocumentSuccessSelector,
    downloadSuccessSelector,
    subscribeSuccessSelector,
    unsubscribeSuccessSelector,
    mappingSuccessSelector,
    countryVatSuccessSelector,
    getOperatorAssignedSuccessSelector,
    excelSuccessSelector,
    updateExcelSuccessSelector,
    getFullListSuccessSelector
]);

// Countries
const getAllCompanyDeclarationsSelector = state => state.fiscalVat.companyDeclarations;
const oneDeclarationSelector = state => state.fiscalVat.getVatDeclaration;
const getDocsFromCatSelector = state => state.fiscalVat.docsFromCat;
const deleteDocumentSelector = state => state.fiscalVat.deleteDoc;
const addDocumentSelector = state => state.fiscalVat.docs;
const downloadSelector = state => state.fiscalVat.downloadDoc;
const subscribeSelector = state => state.fiscalVat.subscribe;
const unsubscribeSelector = state => state.fiscalVat.unsubscribe;
const subscribedSelector = state => state.fiscalVat.subscribed;
const mappingSelector = state => state.fiscalVat.mapping;
const updateDocSelector = state => state.fiscalVat.updateDoc;
const countryVatSelector = state => state.fiscalVat.countryVatDeclarations;
const getOperatorAssignedSelector = state => state.fiscalVat.getOperatorsCountries;
const excelSelector = state => state.fiscalVat.excel;
const updateExcelSelector = state => state.fiscalVat.updateExcel;
const getfiscalVatDeclarationsSelector = state => state.fiscalVat.taxflows;
const getFullListSelector = state => state.fiscalVat.archiveFiles;
const getCategoriesSelector = state => state.fiscalVat.categories;

// configurations
const obligationGetSelector = (state) => state.fiscalVat.obligation;
const obligationCreateSelector = (state) => state.fiscalVat.obligationCreate;
const obligationUpdateSelector = (state) => state.fiscalVat.obligationUpdate;

// Error handler
const errorSelector = state => state.fiscalVat.error;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  getfiscalVatDeclarationsSelector,
  oneDeclarationSelector,
  getOneDeclarationLoadingSelector,
  getfiscalVatDeclarationsLoadingSelector,
  createOneDeclarationLoadingSelector,
  createOneDeclarationSuccessSelector,
  errorSelector,
  getDocsFromCatSelector,
  getDocsFromCatLoadingSelector,
  getAllCompanyDeclarationsSelector,
  getAllCompanyDeclarationsLoadingSelector,
  deleteDocumentLoadingSelector,
  addDocumentLoadingSelector,
  downloadLoadingSelector,
  subscribeLoadingSelector,
  subscribeSelector,
  unsubscribeSelector,
  unsubscribeLoadingSelector,
  subscribedSelector,
  subscribedLoadingSelector,
  mappingSelector,
  mappingLoadingSelector,
  updateDocSelector,
  updateDocLoadingSelector,
  countryVatSelector,
  countryVatLoadingSelector,
  getOperatorAssignedSelector,
  getOperatorAssignedLoadingSelector,
  obligationLoadingSelector,
  obligationGetSelector,
  obligationCreateSelector,
  obligationUpdateSelector,
  excelSelector,
  updateExcelSelector,
  deleteDocumentSelector,
  addDocumentSelector,
  downloadSelector,
  getFullListLoadingSelector,
  getFullListSelector,
  deleteFilesLoadingSelector,
  getCategoriesSelector,
  getCategoriesLoadingSelector
};
