import Agencies from "views/Agencies";
import NewAgency from "views/Agencies/New";
import AgencyDetails from "views/Agencies/Details";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.agencies,
    component: Agencies,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.newAgency,
    component: NewAgency,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.agencyDetails,
    component: AgencyDetails,
    exact: true,
    authorizedRoles: []
  },
];
