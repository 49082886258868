import { Field, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Divider,
  Form,
  Header,
  Container,
  Button as Btn,
  Icon,
} from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { File, Input, TextArea } from 'components/Form';
import { contactRequestSchema } from 'interfaces/formSchema';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';

const Index = ({
  dispatch,
  loadingHandlerContact,
  successHandlerContact,
  companySelected,
  companyData,
  user,
  history,
}) => {
  const [company, setcompany] = useState(null);
  const [sent, setsent] = useState(false);
  const formikRef = useRef();

  const servicesList = [
    {
      key: 0,
      value: 'None',
      text: 'None',
    },
    {
      key: 1,
      value: 'Kyanite',
      text: 'Kyanite',
    },
  ];
  const subjectsList = [
    {
      key: 0,
      value: 'Account creation',
      text: 'Account creation',
    },
    {
      key: 1,
      value: 'Company creation',
      text: 'Company creation',
    },
    {
      key: 2,
      value: 'Delivery creation',
      text: 'Delivery creation',
    },
    {
      key: 3,
      value: 'Delivery proof management',
      text: 'Delivery proof management',
    },
    {
      key: 4,
      value: 'Contact creation',
      text: 'Contact creation',
    },
    {
      key: 5,
      value: 'User creation',
      text: 'User creation',
    },
    {
      key: 6,
      value: 'Service subscription',
      text: 'Service subscription',
    },
    {
      key: 7,
      value: 'Service billing',
      text: 'Service billing',
    },
    {
      key: 8,
      value: 'Other',
      text: 'Other',
    },
  ];

  useEffect(() => {
    if (companySelected) {
      dispatch(
        actions.company.getOne({ params: { companyId: companySelected } })
      );
    } else {
      setcompany(null);
    }

    return () => {
      dispatch(actions.company.reset(['company', 'error']));
    };
  }, [dispatch, companySelected]);

  useEffect(() => {
    const ref: any = formikRef.current;
    if (companyData) {
      setcompany(companyData);
      if (ref) {
        ref.setFieldValue('company', companyData.name);
        ref.setFieldValue('phoneNumber', companyData.phoneNumber || '');
      }
    }
  }, [companyData]);

  useEffect(() => {
    if (sent && !loadingHandlerContact && successHandlerContact) {
      history.push('/');
    }
  }, [sent, loadingHandlerContact]);

  const contact = (values) => {
    dispatch(
      actions.contactRequest.contact({
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company,
          email: values.email,
          phoneNumber: values.phoneNumber,
          service: values.service,
          contactSubject: values.contactSubject,
          contactDescription: values.contactDescription,
          attachedFile: values.attachedFile,
        },
      })
    );
    setsent(true);
  };

  return (
    <Container>
      <Btn.Group>
        <Button
          floated='left'
          labelPosition='left'
          icon='angle left'
          position='flex-end'
          type='button'
          content='Back'
          margin='5px'
          onClick={() => history.push('/companies')}
        />
      </Btn.Group>

      <Wrapper textAlign='center' width='36rem' margin='0 auto'>
        <Header size='huge'>Contact us</Header>
        <Divider hidden />
        <Wrapper>
          <Formik
            ref={formikRef}
            initialValues={{
              firstName: user.firstName || '',
              lastName: user.lastName || '',
              company: (company && company.name) || '',
              email: user.email || '',
              phoneNumber: (company && company.phoneNumber) || '',
              service: '',
              contactSubject: '',
              contactDescription: '',
              attachedFile: undefined,
            }}
            onSubmit={contact}
            validationSchema={contactRequestSchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              isSubmitting,
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <Form.Field>
                    <Field
                      label={<MandatoryFieldLabel content='Company Name' />}
                      placeholder='Company Name'
                      name='company'
                      type='text'
                      component={Input}
                      meta={{ errors, touched }}
                      margin='20px'
                    />
                  </Form.Field>
                  <Wrapper>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          label={<MandatoryFieldLabel content='First Name' />}
                          placeholder='First Name'
                          name='firstName'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>
                      <Form.Field>
                        <Field
                          label={<MandatoryFieldLabel content='Last Name' />}
                          placeholder='Last name'
                          name='lastName'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>
                    </Form.Group>
                  </Wrapper>
                  <Wrapper>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          label={<MandatoryFieldLabel content='Email' />}
                          placeholder='Email'
                          name='email'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>
                      <Form.Field>
                        <Field
                          label='Phone number'
                          placeholder='Phone number'
                          name='phoneNumber'
                          type='text'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Form.Field>
                    </Form.Group>
                  </Wrapper>
                  <Wrapper>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field
                          mandatory
                          label='Service'
                          placeholder='Choose a service'
                          name='service'
                          options={servicesList}
                          component={Dropdown}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Field
                          mandatory
                          label='Subject'
                          placeholder='Subject'
                          name='contactSubject'
                          options={subjectsList}
                          component={Dropdown}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Wrapper>
                  <Form.Field>
                    <Field
                      label={<MandatoryFieldLabel content='Message' />}
                      placeholder='Message'
                      name='contactDescription'
                      type='text'
                      component={TextArea}
                      meta={{ errors, touched }}
                      margin='20px'
                    />
                  </Form.Field>
                  <Field
                    label='Attachment'
                    name='attachedFile'
                    component={File}
                    touched={touched?.attachedFile}
                    error={errors?.attachedFile}
                    meta={{ setFieldValue, setFieldTouched }}
                  />
                  <Divider hidden />
                  <Button
                    type='submit'
                    content='Send'
                    color='green'
                    disabled={loadingHandlerContact}
                    {...(loadingHandlerContact && {
                      icon: <Icon loading name='spinner' />,
                    })}
                  />
                </Form>
              </>
            )}
          </Formik>
        </Wrapper>
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    companySelected: selectors.auth.companySelected(state),
    companyData: selectors.company.companySelector(state),
    user: selectors.auth.userSelector(state),
    loadingHandlerContact: selectors.contactRequest.loadingSelector(state),
    successHandlerContact: selectors.contactRequest.successSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
