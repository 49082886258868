let actionTypes = {
  CREATE_VAT: "MY_ASD.VAT.CREATE_VAT",
  CREATE_VAT_LOADING: "MY_ASD.VAT.CREATE_VAT_LOADING",
  CREATE_VAT_SUCCESS: "MY_ASD.VAT.CREATE_VAT_SUCCESS",
  CREATE_VAT_ERROR: "MY_ASD.VAT.CREATE_VAT_ERROR",

  GET_ALL_VAT: "MY_ASD.VAT.GET_ALL_VAT",
  GET_ALL_VAT_LOADING: "MY_ASD.VAT.GET_ALL_VAT_LOADING",
  GET_ALL_VAT_SUCCESS: "MY_ASD.VAT.GET_ALL_VAT_SUCCESS",
  GET_ALL_VAT_ERROR: "MY_ASD.VAT.GET_ALL_VAT_ERROR",

  GET_ONE_VAT: "MY_ASD.VAT.GET_ONE_VAT",
  GET_ONE_VAT_LOADING: "MY_ASD.VAT.GET_ONE_VAT_LOADING",
  GET_ONE_VAT_SUCCESS: "MY_ASD.VAT.GET_ONE_VAT_SUCCESS",
  GET_ONE_VAT_ERROR: "MY_ASD.VAT.GET_ONE_VAT_ERROR",

  DELETE_VAT: "MY_ASD.VAT.DELETE_VAT",
  DELETE_VAT_LOADING: "MY_ASD.VAT.DELETE_VAT_LOADING",
  DELETE_VAT_SUCCESS: "MY_ASD.VAT.DELETE_VAT_SUCCESS",
  DELETE_VAT_ERROR: "MY_ASD.VAT.DELETE_VAT_ERROR",

  UPDATE_VAT: "MY_ASD.VAT.UPDATE_VAT",
  UPDATE_VAT_LOADING: "MY_ASD.VAT.UPDATE_VAT_LOADING",
  UPDATE_VAT_SUCCESS: "MY_ASD.VAT.UPDATE_VAT_SUCCESS",
  UPDATE_VAT_ERROR: "MY_ASD.VAT.UPDATE_VAT_ERROR",

  RESET: "MY_ASD.VAT.RES",
  REFRESH_ERROR: "MY_ASD.VAT.REFRESH_ERROR"
};

export default actionTypes;
