import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Header, Step } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import { actions, selectors } from 'store';
import Loader from 'components/Loader';
import {
  DeliveryInformations,
  DeliveryValue,
  DeliveryBuyer,
  DeliveryTransporter,
  DeliveryValidation,
} from 'components/QuickFixes/StartADelivery';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths } from 'routes/fiscal/kyanite';

const Index = ({
  history,
  match,
  intl,
  dispatch,
  company,
  vats,
  user,
  resetForm,
  setFieldValue,
  companySelected,
  prefilledData,
  prefilledDataLoading,
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const isSuperOperatorCompany = 49;
  const prefilled = match.params.customsId ? match.params.customsId : false
  const companyId = userAdminOrOp ? match.params.companyId : companySelected;
  const [companyData, setCompanyData] = useState(null);
  const [customData, setCustomData] = useState(null);
  const [isCompanyAlreadyExists, setIsCompanyAlreadyExists] = useState(false);
  const [stepState, setStepState] = useState(1);
  const [highestStep, setHighestStep] = useState(1);
  const [isSellerVatsExists, setIsSellerVatsExists] = useState(false);
  const [sellerVats, setSellerVats] = useState([]);
  const [transporterId, setTransporterId] = useState(null);
  const [buyerAddress, setBuyerAddress] = useState(null);



  useEffect(() => {
    if(prefilled){
      dispatch(
        actions.quickfixes.getPrefilledDelivery({ 
          params: {
            companyId: companyId, 
            customsId: prefilled
          } 
        })
      );
    }
    dispatch(
      actions.company.getOne({
        params: {
          companyId,
        },
      })
    );
    dispatch(actions.vat.getAllVat({ params: { companyId } }));
 
    return () => {
      dispatch(actions.quickfixes.reset(['getPrefilledDelivery', 'error']));
      dispatch(actions.company.reset(['company', 'error']));
      dispatch(actions.data.reset(['vats', 'error']));
    };
  }, [dispatch, prefilled, companyId]);

  useEffect(() => {
    if(prefilledData){
      setCustomData(prefilledData)
    }else{
      setCustomData(null)
    }
  }, [prefilledData, customData])

  useEffect(() => {
    if (!isCompanyAlreadyExists && company === null) {
      setCompanyData(company);
    } else if (!isCompanyAlreadyExists) {
      setCompanyData(company);
      setIsCompanyAlreadyExists(true);
    }
  }, [company, isCompanyAlreadyExists]);

  useEffect(() => {
    if (vats && !isSellerVatsExists) {
      setSellerVats(vats);
      setIsSellerVatsExists(true);
    }
  }, [vats, isSellerVatsExists]);

  const manageSteps = (step) => {
    setStepState(step);
    step > highestStep && setHighestStep(step);
  };

  const freezeSteps = (step) => {
    setHighestStep(step);
  };

  const save = () => {
    alert('Delivery has been saved');
  };

  const startDeliverySchema = Yup.object().shape({
    deliveryInfos: Yup.object().shape({
      deliveryRef: Yup.string().required('Required'),
      customsRegime: Yup.boolean(),
      imaNumber: Yup.string().when('customsRegime', {
        is: true,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      cmr: Yup.string(),
      customsDeclarationId: Yup.array(),
      departureCountry: Yup.string().required('Required'),
      arrivalCountry: Yup.string().required('Required'),
      invoiceNumbers: Yup.array().of(Yup.string().required('Required')),
    }),
    deliveryValue: {
      packageMethod: Yup.string().required('Required'),
      packageNumber: Yup.number().required('Required'),
      natureOfGoods: Yup.string().required('Required'),
      grossWeight: Yup.number().required('Required'),
      grossWeightUnit: Yup.string().required('Required'),
      value: Yup.number().required('Required'),
      volume: Yup.number(),
      volumeUnit: Yup.string().required('Required'),
    },
    seller: Yup.object().shape({
      company: Yup.string(),
      name: Yup.string(),
      address: Yup.string(),
      email: Yup.string().email().trim(),
      phone: Yup.string(),
      country: Yup.string(),
      city: Yup.string(),
      vatId: Yup.number().required('Required'),
      vat: Yup.string(),
      vatsCountries: Yup.array(),
      signature: Yup.bool(),
    }),
    transporterId: Yup.number(),
    transport: Yup.object().shape({
      shippingAgent: Yup.object().shape({
        agent: Yup.number().required('Required'),
        agentSelected: Yup.string(),
        company: Yup.string().required('Required'),
        receiverId: Yup.number().required('Required'),
        receiverName: Yup.string().required('Required'),
        receiverAddressId: Yup.number().required('Required'),
        vatId: Yup.number().required('Required'),
        vatCountry: Yup.string(),
        addressCountry: Yup.string(),
        addressCity: Yup.string(),
        country: Yup.string().required('Required'),
        email: Yup.string().email().trim().required('Required'),
        phone: Yup.string().required('Required'),
        vat: Yup.string(),
        signature: Yup.bool(),
        estimatedDeliveryPrice: Yup.number().required('Required'),
        currency: Yup.string().required('Required'),
      }),
      transporter: Yup.object().shape({
        transporterId: Yup.number(),
        company: Yup.string(),
        address: Yup.string(),
        name: Yup.string(),
        email: Yup.string().email().trim(),
        phone: Yup.string(),
        vatId: Yup.number(),
        vat: Yup.string(),
      }),
    }),
    newProofsLevel1: Yup.array().of(
      Yup.object().shape({
        documentType: Yup.string().required('Required'),
        document: Yup.mixed().required('Required'),
        depositary: Yup.string().required('Required'),
      })
    ),
    newProofsLevel2: Yup.array().of(
      Yup.object().shape({
        documentType: Yup.string().required('Required'),
        document: Yup.mixed().required('Required'),
        depositary: Yup.string().required('Required'),
      })
    ),
    proofsLevel1: Yup.array().of(
      Yup.object().shape({
        documentType: Yup.string(),
        document: Yup.mixed(),
        depositary: Yup.string(),
      })
    ),
    proofsLevel2: Yup.array().of(
      Yup.object().shape({
        documentType: Yup.string(),
        document: Yup.mixed(),
        depositary: Yup.string(),
      })
    ),
  });

  return (
    <Wrapper margin='auto' width='80%'>
    
        <Divider hidden />
        <Divider hidden/>
 
        <Button
          position='flex-start'
          labelPosition='left'
          icon='angle left'
          type='button'
          content='Back to dashboard'
          margin='5px'
          onClick={() =>
            history.push(
              userAdminOrOp
                ? paths.kyaniteOperator.replace(':companyId', companyId)
                : paths.deliveries
            )
          }
        />

      <Wrapper textAlign='center'>
        <Header size='huge'>Start a delivery</Header>
        <Step.Group>
          <Step
            {...(stepState === 1 && { active: true })}
            onClick={() => (          
              manageSteps(1)
              )
            }
          >
            <Step.Content>
              <Step.Title>1</Step.Title>
              <Step.Description>Delivery information</Step.Description>
            </Step.Content>
          </Step>
          <Step
            {...(stepState === 2 && { active: true })}
            {...(highestStep >= 2 ? { disabled: false } : { disabled: true })}
            onClick={() => manageSteps(2)}
          >
            <Step.Content>
              <Step.Title>2</Step.Title>
              <Step.Description>Delivery value</Step.Description>
            </Step.Content>
          </Step>
          <Step
            {...(stepState === 3 && { active: true })}
            {...(highestStep >= 3 ? { disabled: false } : { disabled: true })}
            onClick={() => manageSteps(3)}
          >
            <Step.Content>
              <Step.Title>3</Step.Title>
              <Step.Description>Buyer</Step.Description>
            </Step.Content>
          </Step>
          <Step
            {...(stepState === 4 && { active: true })}
            {...(highestStep >= 4 ? { disabled: false } : { disabled: true })}
            onClick={() => manageSteps(4)}
          >
            <Step.Content>
              <Step.Title>4</Step.Title>
              <Step.Description>Transporter</Step.Description>
            </Step.Content>
          </Step>
          <Step
            {...(stepState === 5 && { active: true })}
            {...(highestStep === 5 ? { disabled: false } : { disabled: true })}
            onClick={() => manageSteps(5)}
          >
            <Step.Content>
              <Step.Title>5</Step.Title>
              <Step.Description>Validation</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        <Divider hidden />

        {/* DeliveryValidation & formulaire */}
        {((companyData === null && customData === null) && <Loader />) || (
          <Formik
            enableReinitialize={true}
            initialValues={(customData === null) ? {
              deliveryInfos: {
                deliveryRef: '',
                customsRegime: false,
                customsDeclarationId: '',
                imaNumber: '',
                cmr: '',
                departureCountry: '',
                arrivalCountry: '',
                invoiceNumbers: [],
              },
              deliveryValue: {
                packageMethod: '',
                packageNumber: 0,
                natureOfGoods: '',
                grossWeight: 0,
                grossWeightUnit: 'kg',
                volume: 0,
                volumeUnit: 'm3',
                value: 0,
              },
              seller: {
                company: '',
                name: '',
                address: '',
                email: '',
                phone: '',
                country: '',
                city: '',
                vatId: null,
                vat: '',
                vatsCountries: [],
              },
              transporterId: null,
              transport: {
                shippingAgent: {
                  agent: null,
                  agentSelected: '',
                  company: '',
                  receiverId: null,
                  receiverName: '',
                  receiverAddressId: null,
                  country: '',
                  email: '',
                  phone: '',
                  vatId: null,
                  vatCountry: '',
                  vat: '',
                  addressCountry: '',
                  estimatedDeliveryPrice: 0,
                  currency: 'EUR',
                },
                transporter: {
                  transporterId: null,
                  company: '',
                  address: '',
                  name: '',
                  email: '',
                  phone: '',
                  vatId: null,
                  vat: '',
                },
              },

              newProofsLevel1: [],
              newProofsLevel2: [],
              proofsLevel1: [],
              proofsLevel2: [],
            } : {
              deliveryInfos: {
                deliveryRef: customData.conex.reference,
                customsRegime: true,
                customsDeclarationId: customData.conex.customsDeclarationId,
                imaNumber: customData.conex.imaNumber,
                cmr: customData.conex.cmr,
                departureCountry: customData.conex.departureCountry,
                arrivalCountry: customData.conex.arrivalCountry,
                invoiceNumbers: customData.conex.invoiceNumbers,
              },
              deliveryValue: {
                packageMethod: customData.conex.packageMethod,
                packageNumber: customData.conex.packageNumber,
                natureOfGoods: customData.conex.natureOfGoods,
                grossWeight: customData.conex.grossWeight,
                grossWeightUnit: customData.conex.grossWeightUnit,
                volume: 0,
                volumeUnit: customData.conex.volumeUnit,
                value: customData.conex.totalHT,
              },
              seller: {
                company: customData.seller.id,
                name: customData.seller.name,
                address: customData.seller.address,
                email: customData.seller.email,
                phone: customData.seller.phone,
                country: customData.seller.country,
                city: customData.seller.city,
                vatId: customData.seller.Vats[0].id,
                vat: customData.seller.Vats[0].vatNumber,
                vatsCountries: [],
              },
              transporterId: customData.conex.transporter,
              transport: {
                shippingAgent: {
                  agent: customData.conex.shippingAgent,
                  agentSelected: customData.conex.shippingAgent,
                  company: customData.buyer.id,
                  receiverId: customData.buyer.id,
                  receiverName: customData.buyer.name,
                  receiverAddressId: customData.buyer.Addresses[0].address,
                  country: customData.buyer.Addresses[0].country,
                  email: customData.buyer.email,
                  phone: customData.buyer.phoneNumber,
                  vatId: customData.buyer.Vats.length>0 ? customData.buyer.Vats[0].id : customData.seller.Vats[0].id,
                  vatCountry: customData.buyer.Vats.length>0 ? customData.buyer.Vats[0].country : customData.seller.Vats[0].country,
                  vat: customData.buyer.Vats.length>0 ? customData.buyer.Vats[0].vatNumber : customData.seller.Vats[0].vatNumber,
                  addressCountry: customData.conex.buyerVat,
                  estimatedDeliveryPrice: customData.conex.estimatedTransportCost,
                  currency: customData.conex.estimatedTransportDevise,
                },
                transporter: {
                  transporterId: customData.conex.transporter,
                  company: customData.transporter.name,
                  address: customData.transporter.Addresses[0].address,
                  name: customData.transporter.name,
                  email: customData.transporter.email,
                  phone: customData.transporter.phoneNumber,
                  vatId: customData.transporter.Vats.length>0 ? customData.transporter.Vats[0].id : customData.seller.Vats[0].id,
                  vat: customData.transporter.Vats.length>0 ? customData.transporter.Vats[0].vatNumber: customData.seller.Vats[0].vatNumber,
                },
              },

              newProofsLevel1: [],
              newProofsLevel2: [],
              proofsLevel1: [],
              proofsLevel2: [],
            }}
            onSubmit={save}
            validationSchema={startDeliverySchema}
          >
            {({
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              resetForm,
              values,
            }: any) => (
              <>
                {stepState === 1 && (
                  <DeliveryInformations
                    intl={intl}
                    manageSteps={manageSteps}
                    freezeSteps={freezeSteps}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    values={values}
                    companyId={companyId}
                    company={company}
                    vats={sellerVats}
                    customsData={customData && customData.conex.customsDeclarationId}
                    isSuperOperatorCompany={isSuperOperatorCompany}
                  />
                )}
                {stepState === 2 && (
                  <DeliveryValue
                    manageSteps={manageSteps}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    values={values}
                  />
                )}
                {stepState === 3 && (
                  <DeliveryBuyer
                    manageSteps={manageSteps}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    values={values}
                    companyId={companyId}
                    setBuyerAddress={setBuyerAddress}
                    buyerAddress={buyerAddress}
                  />
                )}
                {stepState === 4 && (
                  <DeliveryTransporter
                    manageSteps={manageSteps}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    values={values}
                    companyId={companyId}
                    transporterId={transporterId}
                    setTransporter={setTransporterId}
                    customsData={prefilled && {
                      seller: customData.seller.id, 
                      transporter: customData.conex.transporter,
                      transporterName: customData.transporter.name,
                      transporterId: customData.conex.transporter.id,
                     
                    }}
                  />
                )}
                {stepState === 5 && (              
                  <DeliveryValidation
                    manageSteps={manageSteps}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    values={values}
                    history={history}
                    companyId={companyId}
                    company={company}
                    user={user}
                  />
                )}
              </>
            )}
          </Formik>
        )}
      </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    company: selectors.company.companySelector(state),
    hasPaidSubscription: selectors.quickfixes.hasPaidSubscriptionSelector(state),
    vats: selectors.vat.vatsSelector(state),
    errorHandlerCompany: selectors.company.errorSelector(state),
    errorHandlerVat: selectors.vat.errorSelector(state),
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    prefilledDataLoading: selectors.quickfixes.getPrefilledDeliveryLoadingSelector(state),
    prefilledData: selectors.quickfixes.getPrefilledDeliverySelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
