import { orderBy } from 'lodash';
import { Field, Formik, FieldArray } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Checkbox,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  List,
  Modal,
  Popup,
  Transition,
} from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { File, Input } from 'components/Form';
import AddDriverModal from 'components/AddDriverModal';
import { Spacer, Wrapper } from 'components/core';
import { customsDeclaration } from 'interfaces/formSchema/customsValidators';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import styled from '@emotion/styled';
import InfosTooltip from 'components/InfosTooltip';
import InputLabeled from 'components/Form/InputLabeled';
import AddContact from 'components/AddContact/addContact';

const Index = ({
  dispatch,
  companySelected,
  contacts,
  contactsLoading,
  contactsError,
  user,
  match,
  open,
  handleClose,
  allCountries,
  intl,
  errorHandlerData,
  loadingHandlerData,
  contactsSuccess,
  customsOfficeLoading,
  drivers,
  driversLoading,
  customsOffice,
  categoriesDocuments,
  getContactsRights,
  categoriesLoading,
  categories,
  transitRates,
  transitRatesLoading,
  debcategoriesDocuments,
  addcontactsSuccess,
  transporters,
  debcategoriesLoading 
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const isSuperOperatorCompany = 49;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected;
  const hasPartnership = user.partnership;

  const [addContactModal, setaddContactModal]: any = useState(null)
  const [modalStatus, setmodalStatus]: any = useState(false)
  const [runningAction, setRunningAction]: any = useState(false)
  const [allCountriesList, setAllCountriesList]: any = useState([]);
  const [transportersList, setTransporterList]: any = useState([]);
  const [customsOfficeList, setCustomsOfficeList]: any = useState([]);
  const [categoriesList, setCategoriesList]: any = useState([]);
  const [categoriesDocumentsList, setCategoriesDocumentsList]: any = useState([]);
  const [contactsList, setcontactsList]: any = useState(false);
  const [partnersList, setPartnersList]: any = useState([]);
  const [driversList, setDriversList]: any = useState(false);
  const [arrivalError, setArrivalError]: any = useState();
  const [documentList, setDocumentList]: any = useState(0);
  const [acciseInitialValue, setAcciseInitialValues] = useState(null)
  const [transitInitialValues, setTransitInitialValues] = useState(null)
  const [debInitialValues, setDebInitialValues] = useState(null)
  const [taxRate, setTaxRates] = useState(null)
  const [dutyRate, setDutyRates] = useState(null)
  const [extraList, setExtraList]: any = useState([])
  const [business, setIBusiness]: any = useState([])
  const [tableInfo, setTableInfo] = useState({
    departure: '', 
    arrival: '', 
    cDep: null, 
    cArr: null, 
    gmr: null
  })
  const [required, setRequired]: any = useState(null)
  const [categoryTypes, setCategoryTypes]: any = useState(null)


  const ArrayHelperRef: any = useRef()
  let val: any = [];

  const PopupTile = styled.span`
    cursor: pointer;
    margin: 20px 0 0 0;
    width: 100%;
    height: 38px;
    line-height: 38px;
    padding: 0 20px;
    display: block;
    float: left;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;`
   
  const Instruction = styled.div(() => ({
    margin: '0.2rem 0',
    padding: '0.2rem 0'
  }))

  useEffect(() => {
    dispatch(
      actions.contact.getAllContacts({
        params: { companyId: companyId },
      })
    );
    dispatch(actions.contact.getAllContactTransporters({
      params: {
        companyId: companyId 
      }
    }));
  
    dispatch(actions.data.getAllCountries());
    dispatch(actions.customsDeclaration.getCategories({
      params: { companyId: companyId},
      query: {
        type: true
      }
    }));
    dispatch(actions.customsDeclaration.getTransitRates({
      params: { companyId: companyId, type: 'all'},
    }));
    dispatch(actions.customsDeclaration.getAllCustomsOffice());
    dispatch(actions.customsDeclaration.getAllCategoriesDocuments({
      query: {type: 0}
    }));
      dispatch(
        actions.companyDriver.getAllDrivers({
          params: { companyId: companyId },
        })
      );
    
    dispatch(actions.socket.subscribeAddContact(companyId));
    dispatch(actions.socket.subscribeAddContactTransporter(companyId));
    dispatch(actions.socket.subscribeUpdateDrivers(companyId));
   

    return () => {
      dispatch(actions.data.reset(['allCountries', 'error']));
      dispatch(actions.companyDriver.reset(['companyDrivers', 'error']));
      dispatch(actions.contact.reset(['error', 'contacts', 'contactsTransporters']));
      dispatch(
        actions.customsDeclaration.reset([
          'customsOffices',
          'transitRates',
          'error',
          'categoriesDocuments',
        ])
      );
      dispatch(actions.socket.unsubscribeAddContact(companyId));
      dispatch(actions.socket.unsubscribeAddContactTransporter(companyId));
      dispatch(actions.socket.unsubscribeUpdateDrivers(companyId));
    };
  }, [dispatch, companyId]);



  useEffect(() => {
    if (categoriesDocuments) {
      setCategoriesDocumentsList(
        categoriesDocuments.map((category, index) => {
          return {
            key: index,
            value: category.id,
            text: category.name,
          };
        })
      );
    }
  }, [categoriesDocuments]);

  useEffect(() => {
    if (debcategoriesDocuments) {
      setCategoriesDocumentsList(
        debcategoriesDocuments.map((category, index) => {
          return {
            key: index,
            value: category.id,
            text: category.name,
          };
        })
      );
    }
  }, [debcategoriesDocuments]);

  useEffect(() => {
    if (customsOffice) {
      setCustomsOfficeList(
        customsOffice.map((office, index) => {
          return {
            key: index,
            value: office.id,
            text: office.name,
          };
        })
      );
    }
  }, [customsOffice]);


  useEffect(() => {
      if (contacts) {   
        let tab = []
        contacts.forEach((e) => {
          if(e.id !== isSuperOperatorCompany){
            tab.push(e)
          }
        })
        setcontactsList(
          orderBy(
            tab.map((contact, index) => {      
              return {
                key: index,
                value: {
                  id: contact.id, 
                  name: contact.name,
                  transporter: contact.transporter,
                },
                text: contact.name,
              };
            
            }),
            ['text']
          )
        );
      }
    // description enrichie du transporter obj JSON
    if(transporters){
      setTransporterList(
        orderBy(
          transporters.map((e, index) => {
            return {
              key: index,
              value: { name: e.name, id: e.id, country: e.country, address: e.address },
              text: `${e.name} - (${e.country} - ${e.Addresses[0].city})`,
            }
          })
        ), ['text']);
    }
      
     
  }, [contacts, transporters, isSuperOperatorCompany]);


  useEffect(() => {
    if (allCountries) {
      setAllCountriesList(
        orderBy(
          allCountries.map((country, index) => {
            return {
              key: index,
              value: country.iso_code,
              text: intl.formatMessage({
                id: `countries.name.${country.iso_code}`,
              }),
              flag: country.iso_code.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [allCountries, intl]);

  useEffect(() => {
    if(transitRates){
      let dutyRates = [] 
      let taxsRate = []
      transitRates.forEach((e, index) => {
        if(e.type === 1){
          dutyRates.push({key: index, value: e.rate, text: e.name})
        }else if(e.type === 2){
          taxsRate.push({key: index, value: e.rate, text: e.name})
        }
      })
      setTaxRates(taxsRate)
      setDutyRates(dutyRates)
    }
  }, [transitRates])


  useEffect(() => {
    if(drivers){
      setDriversList(
        orderBy(
          drivers.map((driver, index) => {
            return {
              key: index,
              value: parseInt(driver.id),
              text: driver.pseudo,
            };
          }),
          ['text']
        )
      );
    }
  }, [drivers]);


  // partners
  useEffect(() => {
    if(!partnersList){
      setPartnersList(
        orderBy(
          partnersList.map((partner, index) => {
            return {
              key: index,
              value: partner.value,
              text: partner.text,
            };
          }),
          ['text']
        )
      );
    }
  }, [partnersList]);

  useEffect(() => {
    if(categories){
      let defaultTab: any = []
      let extraTab: any = []
      categories.forEach((cat) => {  
        if(cat.default){
          defaultTab.push({
            key: cat.id,
            value: cat.name,
            text: cat.label
          })
        }
        else{
          extraTab.push({
            key: cat.id,
            name: cat.name,
            description: cat.description,
            text: cat.description
          })
        }    
    })
      setCategoriesList(defaultTab)
      setExtraList(extraTab)
    }
  }, [categories])

  useEffect(() => {
    if(arrivalError === false){
       if(
        tableInfo.departure === 'France and/or EU' && tableInfo.arrival === 'GB'){
        //setIBusiness({a: 'EU Export', b: 'euExport'}) 
        setIBusiness([
          {a: 'EU Export', b: 'euExport'},
          {a: 'GB Import', b: 'gbImport'},
          {a: 'GMR', b: 'gmr'}
        ])
       }
       else if(
        tableInfo.departure === 'GB' && tableInfo.arrival === 'France and/or EU'){
        //setIBusiness({a: 'EU Import', b: 'euImport'})
        setIBusiness([
          {a: 'EU Import', b: 'euImport'},
          {a: 'GB Export', b: 'gbExport'}
        ])
       }
       else if( // outside -> france xxx
        tableInfo.departure === 'Outside EU' && tableInfo.arrival === 'France and/or EU'){
        //setIBusiness({a: 'Import from outside EU', b: 'outsideEuImport'})
        setIBusiness([
          {a: 'EU Import', b: 'euImport'},
          {a: 'EU Export', b: 'euExport'}
        ]) 
      }
      else if( // outside -> france xxx
        tableInfo.departure === 'France and/or EU' && tableInfo.arrival === 'Outside EU'){
        //setIBusiness({a: 'Export to outside EU', b: 'outsideEuExport'})
        setIBusiness([
          {a: 'EU Export', b: 'euExport'},
          {a: 'EU Import', b: 'euImport'}
        ])  
      } 
       else{
        setIBusiness([{a: 'Error'}]) 
       }
      }
  }, [arrivalError, tableInfo])


  const declare = async (values) => {

    // default types
    const type = [values.type];
    let isTraces = type.includes('traces') ? true : null
    let isClearance = type.includes('customsClearance') ? true : null
    let isClerk = type.includes('customsClerk') ? true : null
    let isCustomsOnly = type.includes('customsOnly')  ? true : null
  
    if(isClearance){ 
      values.customsTrade.includes('euImport') && values.customsTrade.push('tracesImport');
      values.customsTrade.includes('euExport') && values.customsTrade.push('tracesExport');
      values.customsTrade.push('customsClerk'); 

      type.push('customsClerk');
      type.push('traces');
    }
    if(isTraces){ 
      values.customsTrade.includes('euImport') && 
      values.customsTrade.splice(values.customsTrade.indexOf('euImport'), 1) && 
      values.customsTrade.push('tracesImport');

      values.customsTrade.includes('euExport') && 
      values.customsTrade.splice(values.customsTrade.indexOf('euImport'), 1) && 
      values.customsTrade.push('tracesExport');
      values.customsTrade.push('customsClerk');  

      type.push('customsClerk');
    }
    if(isCustomsOnly){ 
      values.customsTrade.push('customsClerk');  
      type.push('customsClerk');
    }
    if(isClerk){ 
      values.customsTrade.push('customsClerk');  
    }
   

    dispatch(
      actions.customsDeclaration.declare({
        params: {
          companyId: companyId,
        },
        data: {
          type,
          truckLicensePlate: values.truckLicensePlate,
          trailerLicensePlate: values.trailerLicensePlate,
          truckOrigin: values.truckOrigin,
          trailerOrigin: values.trailerOrigin,
          customsOffice: values.customsOffice,
          departure: values.departure,
          arrival: values.arrival,
          fileDeclaDeparture: values.fileDeclaAtDeparture && "awaiting",
          fileDeclaArrival: values.fileDeclaAtArrival && "awaiting",
          fileGmrDocument: values.fileGmrDocument && "awaiting",
          estimatedDateTimeArrival: new Date(
            `${values.estimatedDateArrival}T${values.estimatedTimeArrival}`
          ),
          meanTimeOffset: new Date(
            `${values.estimatedDateArrival}T${values.estimatedTimeArrival}`
          ).getTimezoneOffset()/60,
          documents: values.documents,
          transporterPhoneNumber: values.transporterPhoneNumber,
          transporter: values.transporter,
          clientReference: values.clientReference,
          CompanyDriver_Id: values.driver,
          customsTrade: values.customsTrade,
          fortyTwoRegime: values.fortyTwoRegime,
          indRepresentation: values.indRepresentation,
          purchaseNumber: values.purchaseNumber,
          permissions: values.permissions,
          dauBea: values.dauBea,
          ...(values.file && { file: values.file }),
          crossingStatus: values.crossingStatus
        },
      })
    );
    handleClose()
  };

  const createAccises = async (values) => {
    dispatch(
    actions.customsDeclaration.createAccises({
        params: {
          companyId: companyId,
        },
        data: {
          type: ['accises'],
          customsOffice: 1,
          truckLicensePlate: 'accises',
          trailerLicensePlate: 'accises',
          fortyTwoRegime: false,
          indRepresentation: false,
          truckOrigin: 'AC',
          trailerOrigin: 'AC',
          clientReference: values.clientReference,
          departure: values.departure,
          arrival: values.arrival,
          startDate: values.startDate,
          endDate: values.endDate,
          documents: values.documents,
        },
      })
    )
    handleClose()
  }

  const createTransit = async (values) => {
    dispatch(
    actions.customsDeclaration.createTransit({
        params: {
          companyId: companyId,
        },
        data: {
          type: ['transit'],
          customsOffice: values.customsOffice ? values.customsOffice : '',
          truckLicensePlate: 'transit',
          trailerLicensePlate: 'transit',
          fortyTwoRegime: false,
          indRepresentation: false,
          truckOrigin: 'TR',
          trailerOrigin: 'TR',
          clientReference: values.clientReference ? values.clientReference : '',
          departure: values.departure,
          arrival: values.arrival,
          startDate: values.startDate,
          endDate: values.endDate,
          estimatedTimeDeparture: values.estimatedTimeDeparture,
          transporter: values.transporter,
          CompanyDriver_Id: values.driver,
          transporterPhoneNumber: values.transporterPhoneNumber,
          purchaseNumber: values.purchaseNumber,
          preTaxInvoice: values.preTaxInvoice,
          customsDutyRate: values.customsDutyRate*0.01,
          transitVatRate: values.transitVatRate*0.01,
          accisesTaxAmount: values.accisesTaxAmount ? values.accisesTaxAmount : '', 
          documents: values.documents,
        },
      })
    )
    handleClose()
  }

 
  const findCategories = (cat) => {
    dispatch(actions.customsDeclaration.getAllCategoriesDocuments({
      query: {type: cat}
    }));
  }

  const findSubCategories = useCallback((name: string) => {
    if(categories.name === name){
      setCategoryTypes(prevState => ({
        ...prevState,
        list: categories.CustomsDeclarationCategoriesSubs
      }))
  }
  }, [categories, setCategoryTypes])

  const Alert = styled.div(() => ({
    fontSize: '12px',
    textAlign: 'center',
    padding: '0 1rem',
    margin: '0.5rem 0',
    lineHeight: '12px',
  }));
  const Mandatory = styled.span(() => ({
    color: 'red',
    float: 'right',
    display: 'inline-block',
  }))


  return (
    <>
    {modalStatus && (
    <AddDriverModal 
      open={modalStatus}
      transportersList={transportersList}
      handleClose={() => setmodalStatus(false)}
      companyId={companyId}
      setRunningAction={setRunningAction}
    />
    )}
     {userAdminOrOp && addContactModal && (
      <AddContact
        open={addContactModal}
        handleClose={() => {
          if(addcontactsSuccess){
            setaddContactModal(false)
          }else{
            setaddContactModal(false)
          }               
        }}
        companyId={companyId}
        fromOtherComponents={true}
      />
    )}
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content='New declaration' />
      <Formik
        enableReinitialize={true} 
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          type: '',
          indRepresentation: false,
          documents: [],
          truckLicensePlate: '',
          trailerLicensePlate: '',
          truckOrigin: '',
          trailerOrigin: '',
          customsOffice: '',
          departure: '',
          arrival: '',
          startDate: '',
          endDate: '',
          estimatedDateArrival: '',
          estimatedTimeArrival: '',
          transporterPhoneNumber: '',
          transporter: '',
          driver: '',
          clientReference: '',
          fortyTwoRegime: false,
          permissions: [],
          purchaseNumber: '',
          dauBea: '',
          customsTrade: [],
          crossingStatus: 'Awaiting documents',
        }}
        onSubmit={
          acciseInitialValue ? createAccises : transitInitialValues ? createTransit : declare 
        }
        validationSchema={(acciseInitialValue || transitInitialValues) ? false : customsDeclaration}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
        }) => (
          <Modal.Content>
          <Form onSubmit={handleSubmit}>
            <Field
              mandatory
              label='Type'
              name='type'
              placeholder='Choose a file type'
              component={Dropdown}
              loading={categoriesLoading}
              options={categoriesList}
              onChange={(value) => {
                switch(value){
                  case 'customsClearance':
                    setFieldValue('file', null);
                    setFieldValue('sivep', true);
                    findCategories(0)
                    //findSubCategories(value)
                    break;

                  case 'customsOnly':
                    setFieldValue('file', null);
                    setFieldValue('sivep', false);
                    findCategories(0)
                    break;

                  case 'traces':
                    setFieldValue('file', null);
                    setFieldValue('sivep', true);
                    delete values.file;
                    delete values.sivep;
                    findCategories(0)
                    break;

                  case 'customsClerk':
                    setFieldValue('file', null);
                    setFieldValue('sivep', false);
                    delete values.file;
                    delete values.sivep;
                    findCategories(0)
                    break;

                  case 'accises':                                 
                    setAcciseInitialValues(true);
                    findCategories(2)                 
                    break;

                  case 'transit':                                
                    setTransitInitialValues(true);
                    findCategories(3)                 
                    break;

                  case 'mandat':
                    findCategories(23)
                    break

                  default: 
                    setFieldValue('file', null);
                    setFieldValue('sivep', false);
                } 
              }}
              meta={{ errors, touched, setFieldValue, setFieldTouched }}
            />
            <Divider hidden />
            {values.type && (
            <>
            {(values.type === 'customsOnly') && contactsSuccess && (
              <>
                <Grid columns={4} verticalAlign='middle'>
                <Grid.Column>
                  <Field
                    label='Reverse charged/Régime 42 ?'
                    checked={values.fortyTwoRegime}
                    name='fortyTwoRegime'
                    onClick={() => {
                      setFieldValue('fortyTwoRegime', !values.fortyTwoRegime);
                    }}
                    component={Checkbox}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    label='IR - Indirect Representation'
                    checked={values.indRepresentation}
                    name='indRepresentation'
                    onClick={() => {
                      setFieldValue('indRepresentation', !values.indRepresentation);
                    }}
                    component={Checkbox}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                {userAdminOrOp && (<Button
                  justifyContent='end'
                  icon='plus'
                  type='button'
                  content='Add contact'
                  onClick={() => {
                    setaddContactModal(true);
                  }}
                  margin='0px'
                  position='center'
                />)}
                </Grid.Column>
                <Grid.Column>
                <Button
                  justifyContent='start'
                  icon='plus'
                  type='button'
                  content='Add drivers'
                  onClick={() => setmodalStatus(true)}
                  margin='0px'
                  position='center'
                />
                </Grid.Column>                
              </Grid>
              <Grid>
              <Grid.Row>
               <Grid.Column>
               <Field
                 label="Add partners"
                 name='permissions'
                 placeholder='Choose partners'
                 options={contactsList}
                 fluid 
                 multiple                   
                 clearable
                 selection
                 component={Dropdown}
                 meta={{
                   errors,
                   touched,
                   setFieldValue,
                   setFieldTouched,
                 }}
                 loading={contactsLoading}
                 uniqueError={
                   contactsError.includes('contactsList')
                     ? true
                     : false
                 }
                 onChange={(value) => {                                                      
                   if(value){
                     val.push(value)
                     contactsList.forEach((contact, index) => {
                       if(contact.value.id === val[0].id) {
                         val.push(index, {
                           name: contact.value.name,
                           user_Id: contact.value.id,
                           transporter: contact.value.transporter,
                         })
                       }
                     })
                     val = []
                   }                         
                 }}
               />
             </Grid.Column>
             </Grid.Row> 
             </Grid> 
             </>                                     
            )}
            {(values.type === 'customsClerk' || values.type === 'mandat' || values.type === 'transit') && (
              <Grid columns={5}>
               <Grid.Column>
               {userAdminOrOp && (<Button
                 justifyContent='start'
                 icon='plus'
                 type='button'
                 content='Add contact'
                 onClick={() => {
                  setaddContactModal(true);
                }}
                 margin='0px'
                 position='center'
               />)}
               </Grid.Column>
              {(values.type === 'customsClerk' || values.type === 'mandat') && (
               <Grid.Column>
               <Button
                 justifyContent='start'
                 icon='plus'
                 type='button'
                 content='Add drivers'
                 onClick={() => setmodalStatus(true)}
                 margin='0px'
                 position='center'
               />
               </Grid.Column>)}
              </Grid>
            )}
            {(values.type === 'traces') && contactsSuccess && (
              <>
                <Grid columns={5}>
               <Grid.Column>
                {userAdminOrOp && (
                  <Button
                    icon='plus'
                    type='button'
                    content='Add contact'
                    onClick={() => {
                      setaddContactModal(true);
                    }}
                />)} 
                </Grid.Column>      
                <Grid.Column>
                <Button
                  icon='plus'
                  type='button'
                  content='Add drivers'
                  onClick={() => setmodalStatus(true)}
                  margin='0px'
                  position='center'
                />
                </Grid.Column>
              </Grid>
              <Grid>
              <Grid.Row>
               <Grid.Column>
               <Field
                 label="Add partners"
                 name='permissions'
                 placeholder='Choose partners'
                 options={contactsList}
                 fluid 
                 multiple                   
                 clearable
                 selection
                 component={Dropdown}
                 meta={{
                   errors,
                   touched,
                   setFieldValue,
                   setFieldTouched,
                 }}
                 loading={contactsLoading}
                 uniqueError={
                   contactsError.includes('contactsList')
                     ? true
                     : false
                 }
                 onChange={(value) => {                                                      
                   if(value){
                     val.push(value)
                     contactsList.forEach((contact, index) => {
                       if(contact.value.id === val[0].id) {
                         val.push(index, {
                           name: contact.value.name,
                           user_Id: contact.value.id,
                           transporter: contact.value.transporter,
                         })
                       }
                     })
                     val = []
                   }                         
                 }}
               />
              </Grid.Column>
              </Grid.Row> 
              </Grid> 
              </>                                     
              )}
              {(values.type === 'customsClearance') && contactsSuccess && (
                  <>
                  <Grid columns={4} verticalAlign='middle'>
                  <Grid.Column>
                    <Field
                      label='Reverse charged/Régime 42 ?'
                      checked={values.fortyTwoRegime}
                      name='fortyTwoRegime'
                      onClick={() => {
                        
                        setFieldValue('fortyTwoRegime', !values.fortyTwoRegime);
                  
                      }}
                      component={Checkbox}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Field
                      label='IR - Indirect Representation'
                      checked={values.indRepresentation}
                      name='indRepresentation'
                      onClick={() => {
                        setFieldValue('indRepresentation', !values.indRepresentation);
                      }}
                      component={Checkbox}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                  {userAdminOrOp && (<Button
                      justifyContent='end'
                      icon='plus'
                      type='button'
                      content='Add contact'
                      onClick={() => {
                        setaddContactModal(true);
                      }}
                    />)}
                  </Grid.Column>       
                  <Grid.Column>
                    <Button
                      justifyContent='start'
                      icon='plus'
                      type='button'
                      content='Add drivers'
                      onClick={() => setmodalStatus(true)}
                      margin='0px'
                      position='center'
                    />
                  </Grid.Column>
                  </Grid>               
                  {contactsList && contactsList.length>0 && hasPartnership && (
                      <Grid>                 
                        <Grid.Column>
                          <Field
                            label="Partners"
                            name='permissions'
                            placeholder='Choose partners'
                            options={contactsList}
                            fluid 
                            multiple                   
                            clearable
                            selection
                            component={Dropdown}
                            meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                            }}
                            loading={contactsLoading}
                            uniqueError={
                              contactsError.includes('contactsList')
                                ? true
                                : false
                            }
                            onChange={(value) => {                                                      
                              if(value){
                                val.push(value)
                                contactsList.forEach((contact, index) => {
                                  if(contact.value.id === val[0].id) {
                                    val.push(index, {
                                      name: contact.value.name,
                                      user_Id: contact.value.id,
                                      transporter: contact.value.transporter,
                                    })
                                  }
                                })
                                val = []
                              }                         
                            }}
                          />
                        </Grid.Column>                          
                      </Grid>                 
                    )}                    
                  </>
                )} 
                <Grid>
                {userAdminOrOp && 
                transportersList && 
                transportersList.length>0 && 
                (values.type !== 'accises') && 
                (<>                  
                  <Grid.Column>
                    <Field
                      label="Transporter"
                      name='transporter'
                      placeholder='Choose transporter'
                      options={transportersList}
                      component={Dropdown}
                      loading={contactsLoading}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      uniqueError={
                        errorHandlerData.includes('contacts')
                          ? true
                          : false
                      }
                      onChange={(value) => {
                        if(value){
               
                          setDriversList(false)
                          if(values.type === 'transit'){
                            value.address && setFieldValue('transporterPhoneNumber', value.address.phoneNumber)
                          }
                          if(values.type !== 'transit'){
                            dispatch(
                              actions.companyDriver.getAllDrivers({
                                params: { companyId: value.id }
                              }))
                          }
                                                                         
                        }else{
                          if(values.type === 'transit'){
                            setFieldValue('transporterPhoneNumber', '')
                          }
                          if(values.type !== 'transit'){
                          dispatch(
                            actions.companyDriver.getAllDrivers({
                              params: { companyId: companyId },
                            })
                          );
                          }        
                        }                                                    
                      }}
                    />
                  </Grid.Column> 
                  </>)}             
              </Grid>
              {(values.type === 'accises') && (
                <>
              <Grid columns='2'>
                  <Grid.Column>
                    <Field
                        mandatory
                        label="Departure"
                        name='departure'
                        placeholder='Choose the country of origin'
                        options={allCountriesList}
                        component={Dropdown}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                        loading={loadingHandlerData}
                        uniqueError={
                          errorHandlerData.includes('countries')
                            ? true
                            : false
                        }
                      />
                  </Grid.Column>
                  <Grid.Column>
                    <Field
                        mandatory
                        label="Arrival"
                        name='arrival'
                        placeholder='Choose the country of arrival'
                        options={allCountriesList}
                        component={Dropdown}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                        loading={loadingHandlerData}
                        uniqueError={
                          errorHandlerData.includes('countries')
                            ? true
                            : false
                        }
                      />
                  </Grid.Column>                    
                </Grid>
                <Grid columns='2'>
                <Grid.Column>
                  <Field
                    mandatory
                    label='Start date'
                    name='startDate'
                    component={Input}
                    type='date'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
               </Grid.Column>
               <Grid.Column>
                  <Field
                    mandatory
                    label='End date'
                    name='endDate'
                    component={Input}
                    type='date'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>                    
              </Grid>
              <Grid>
                <Grid.Column>
                <Field
                  label='Client reference'
                  name='clientReference'
                  placeholder='123456789'
                  component={Input}
                  type='text'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
                </Grid.Column>
              </Grid>
              </>
              )}

              {(values.type === 'transit') && (
                <Grid>
                  <Grid.Column>
                    <Field
                      mandatory
                      label='Transporter Phone number'
                      name='transporterPhoneNumber'
                      placeholder='XXXXXXXXXX'
                      component={Input}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Grid.Column>
                </Grid>
              )}

              {(values.type === 'transit') && (
              <>
              <Grid columns='2'>
                  <Grid.Column>
                    <Field
                      mandatory
                      label="Departure"
                      name='departure'
                      placeholder='Choose the country of origin'
                      options={allCountriesList}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      loading={loadingHandlerData}
                      uniqueError={
                        errorHandlerData.includes('countries')
                          ? true
                          : false
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Field
                      mandatory
                      label="Arrival"
                      name='arrival'
                      placeholder='Choose the country of arrival'
                      options={allCountriesList}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      loading={loadingHandlerData}
                      uniqueError={
                        errorHandlerData.includes('countries')
                          ? true
                          : false
                      }
                    />
                  </Grid.Column>                    
                </Grid>
                <Grid columns='3'>
                <Grid.Column>
                  <Field
                    mandatory
                    label='Start date'
                    name='startDate'
                    component={Input}
                    type='date'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
               </Grid.Column>
               <Grid.Column>
                  <Field
                    mandatory
                    label='Departure time'
                    name='estimatedTimeDeparture'
                    component={Input}
                    type='time'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
               </Grid.Column>
               <Grid.Column>
                  <Field
                    mandatory
                    label='End date'
                    name='endDate'
                    component={Input}
                    type='date'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>                    
              </Grid>
              <Grid columns='4'>
                <Grid.Column>
                  <Field
                    mandatory
                    label="Pre-tax invoice"
                    name='preTaxInvoice'
                    placeholder='1000.00'
                    component={Input}
                    type='text'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}                   
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    mandatory
                    label="Customs duty rate"
                    name='customsDutyRate'
                    placeholder='type rate...'
                    options={dutyRate}
                    loading={transitRatesLoading}
                    component={InputLabeled}
                    labeled='%'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }} 
                  />
                </Grid.Column>
              <Grid.Column>
              <Field
                  label="Select tax rate"
                  name='vatRate'
                  placeholder='Choose rate...'
                  options={taxRate}
                  loading={transitRatesLoading}
                  component={Dropdown}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }} 
                  onChange={(e) => {
                    if(e === 0.025){
                      setFieldValue('transitVatRate', e*100)
                    }else{
                      setFieldValue('transitVatRate', '')
                    }
                   
                  }}
                />
              </Grid.Column>
              <Grid.Column>
              <Field
                  mandatory
                  label="Transit tax rate"
                  name='transitVatRate'
                  placeholder={values.transitVatRate}                
                  component={InputLabeled}
                  labeled='%'
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }} 
                />
              </Grid.Column>
              </Grid>
              <Grid columns='4'>
              <Grid.Column>
                  <Field
                    label='Accises amount'
                    name='accisesTaxAmount'
                    placeholder='1000...'
                    component={Input}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    uniqueError={
                      errorHandlerData.includes('accisesTaxAmount') ? true : false
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    options={customsOfficeList}
                    loading={customsOfficeLoading}
                    label='Customs office at arrival'
                    name='customsOffice'
                    placeholder='Customs office'
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    uniqueError={
                      errorHandlerData.includes('customsOffice') ? true : false
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    options={customsOfficeList}
                    label='PO (Purchase order)'
                    name='purchaseNumber'
                    placeholder='po...'
                    component={Input}
                    type='text'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    uniqueError={
                      errorHandlerData.includes('purchaseNumber') ? true : false
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    options={customsOfficeList}
                    label='Client reference'
                    name='clientReference'
                    placeholder='ref...'
                    component={Input}
                    type='text'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    uniqueError={
                      errorHandlerData.includes('clientReference') ? true : false
                    }
                  />
                </Grid.Column>
              </Grid>
              </>
              )}

              {(values.type !== 'accises' && values.type !== 'transit') && contactsSuccess && (
              <>
              <Spacer height='2rem' />
              <InfosTooltip content='Customs workflow' description='To set the declaration type, select the departure and arrival, then check the result in the "Export or Import" output'/>
              <Divider />                
                <Grid columns='2'>                             
                  <Grid.Column>
                    <Field
                      mandatory
                      label="Departure"
                      name='departure'
                      placeholder='Choose departure UK France and/or EU'
                      options={[
                        {
                          key: 0,
                          value: 'GB',
                          text: 'GB'
                        },
                        {
                          key: 1,
                          value: 'France and/or EU',
                          text: 'France and/or EU'
                        },
                        {
                          key: 2,
                          value: 'Outside EU',
                          text: 'Outside EU'
                        }
                      ]}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }} 
                      onChange={
                        (e) => {                     
                          switch(e === values.arrival){
                            case true:
                              setArrivalError(true)
                              break;
                            case false:
                              setArrivalError(false)                         
                              break;
                            case undefined:
                              setArrivalError(false)
                              break;
                            default: 
                              setArrivalError(false)                             
                          }

                          setTableInfo(prevState => ({
                            ...prevState,
                            departure: e
                        }));                         
                        }
                      }               
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Field
                      label={<MandatoryFieldLabel content='Arrival' error={arrivalError} errorMessage='must be diffrent from departure'/>}
                      name='arrival'
                      placeholder='Choose arrival UK France and/or EU'
                      options={[
                        {
                          key: 0,
                          value: 'GB',
                          text: 'GB'
                        },
                        {
                          key: 1,
                          value: 'France and/or EU',
                          text: 'France and/or EU'
                        },
                        {
                          key: 2,
                          value: 'Outside EU',
                          text: 'Outside EU'
                        }
                      ]}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      onChange={
                        (e) => {                         
                          switch(e === values.departure){
                            case true:
                              setArrivalError(true)                    
                            break;
                            case false:
                              setArrivalError(false)                     
                            break;
                            case undefined:
                              setArrivalError(false)                          
                            break;
                            default: 
                              setArrivalError(false)                            
                          }

                          setTableInfo(prevState => ({
                            ...prevState,
                            arrival: e
                        }));
                        }
                      }               
                    />
                  </Grid.Column>
                </Grid>
                <Divider hidden />
                <Grid columns='2'>                  
                  <Grid.Column>
                    <Field
                      mandatory
                      label="Truck's origin"
                      name='truckOrigin'
                      placeholder='Choose the country of origin of the truck'
                      options={allCountriesList}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      loading={loadingHandlerData}
                      uniqueError={
                        errorHandlerData.includes('countries')
                          ? true
                          : false
                      }
                    />
                  </Grid.Column>              
                  <Grid.Column>
                    <Field
                      mandatory
                      label='Truck license plate'
                      name='truckLicensePlate'
                      placeholder='AA123AA'
                      component={Input}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Grid.Column>
                </Grid>
                <Divider hidden />
                <Grid columns='2'>
                  <Grid.Column>
                    <Field
                      mandatory
                      label='Trailer origin'
                      name='trailerOrigin'
                      placeholder='Choose the country of origin of the trailer'
                      options={allCountriesList}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      loading={loadingHandlerData}
                      uniqueError={
                        errorHandlerData.includes('countries')
                          ? true
                          : false
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Field
                      mandatory
                      label='Trailer license plate'
                      name='trailerLicensePlate'
                      placeholder='AA123AA'
                      component={Input}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Grid.Column>
                </Grid>
                <Grid columns='2'>
                  <Grid.Column>
                    <Wrapper display='flex' justifyContent='space-evenly'>
                      <Wrapper width='50%'>
                      {(driversList.length && 
                        (<Field
                          options={driversList}
                          label='Drivers'
                          name='driver'
                          placeholder='Select driver'
                          component={Dropdown}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                          loading={driversLoading}
                          uniqueError={
                            errorHandlerData.includes('driver')
                              ? true
                              : false
                          }
                        />)) || (
                      <Field
                        label='Phone number'
                        name='transporterPhoneNumber'
                        placeholder='XXXXXXXXXX'
                        component={Input}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                      />)} 
                      </Wrapper>
                      <Wrapper width='10px' />
                      <Wrapper width='50%'>
                      {(values.type !== 'mandat' && (<>
                        <Popup trigger={
                          <PopupTile><Icon name='dropdown'/>Customs types <Mandatory>*</Mandatory></PopupTile>
                        } flowing hoverable>
                        <Instruction>Select multiple</Instruction>
                        <FieldArray
                          name='customsTrade'
                          render={(arrayHelper) => (
                            <>
                              {business.length>0 && business.map((el: any, i) => {                         
                                  return (
                                    <Wrapper key={i}>
                                      <Wrapper>
                                        <Wrapper>
                                          <Field
                                            key={i}
                                            name={`customsTrade[${i}]`}
                                            component={Checkbox}
                                            label={
                                              el.a
                                            }
                                            onChange={() => {
                                              if (
                                                values.customsTrade.includes(
                                                  el.b
                                                )
                                              ) {
                                                arrayHelper.remove(
                                                  values.customsTrade.findIndex(
                                                    (e) => e === el.b
                                                  )
                                                );
                                              } else {
                                                arrayHelper.push(el.b);
                                              }
                                            }}
                                            checked={                             
                                              values.customsTrade.includes(
                                                el.b
                                              )
                                            }
                                            meta={{
                                              errors,
                                              touched,
                                              setFieldValue,
                                              setFieldTouched,
                                            }}
                                          />
                                        </Wrapper>
                                      </Wrapper>
                                    </Wrapper>
                                  );
                              
                              })}
                            </>
                        )}             
                        /></Popup>
                         {errors.customsTrade && touched.customsTrade && (
                          <div className="ui red pointing basic label css-0">Required</div>
                        )}
                        </>
                      ))}
                      </Wrapper>
                    </Wrapper>
                  </Grid.Column>
                  <Grid.Column>
                    <Wrapper display='flex' justifyContent='space-evenly'>
                      <Wrapper width='50%'>
                        <Field
                          mandatory
                          label='Estimated arrival date'
                          name='estimatedDateArrival'
                          component={Input}
                          type='date'
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                      </Wrapper>
                      <Wrapper width='10px' />
                      <Wrapper width='50%'>
                        <Field
                          mandatory
                          label='Estimated arrival time'
                          name='estimatedTimeArrival'
                          component={Input}
                          type='time'
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                      </Wrapper>
                    </Wrapper>
                  </Grid.Column>
                </Grid>
                <Divider hidden />
                <Field
                  options={customsOfficeList}
                  loading={customsOfficeLoading}
                  mandatory
                  label='Customs office at arrival'
                  name='customsOffice'
                  placeholder='Customs office'
                  component={Dropdown}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                  uniqueError={
                    errorHandlerData.includes('customsOffice') ? true : false
                  }
                />
                <Divider hidden />
                <Grid columns='2'>
                <Grid.Column>
                  <Field // si regime 42 client ref mandatory
                    mandatory={values.fortyTwoRegime}
                    label='Client reference'
                    name='clientReference'
                    placeholder='123456789'
                    component={Input}
                    type='text'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    label='PO'
                    name='purchaseNumber'
                    placeholder='po...'
                    component={Input}
                    type='text'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
                </Grid>
                </>
              )}
            
     
                
                <Divider hidden />
                <Header size='tiny' textAlign='center'>
                  Documents
                </Header>
                <Alert>Warning: at this stage, you can upload only one file. But you can upload your other files once the {values.type === 'accises'? 'accises declaration' : 'customs declaration'} file is created.</Alert>
                <FieldArray
                  name='documents'
                  render={(arrayHelpers) => ( 
                    <>                                  
                    <Transition.Group
                      as={List}
                      duration={200}
                      size='medium'
                      verticalAlign='middle'
                    >
                      <Grid columns='4'>
                        {values.documents.length > 0 &&
                          values.documents.map((document, index) => {
                            ArrayHelperRef.current = arrayHelpers
                            setDocumentList(index+1)
                            return (
                              <Grid.Row key={index}>
                                <Grid.Column width='5'>
                                  <Field
                                    name={`documents[${index}].id`}
                                    arrayName='documents'
                                    placeholder='Choose a Category'
                                    component={Dropdown}
                                    options={categoriesDocumentsList}
                                    meta={{
                                      errors,
                                      touched,
                                      setFieldValue,
                                      setFieldTouched,
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column width='9'>
                                  <Field
                                    name={`documents[${index}].file`}
                                    component={File}
                                    error={errors.documents?.[index]?.file}
                                    touched={touched.documents?.[index]?.file}
                                    meta={{
                                      setFieldValue,
                                      setFieldTouched,
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column width='2'>
                                  <Button
                                    icon='minus'
                                    type='button'
                                    color='red'
                                    onClick={() => {
                                      arrayHelpers.remove(index)                                  
                                      setDocumentList(0)
                                    }}
                                    maxHeight='2.7rem'
                                  />
                                </Grid.Column>
                                <Divider hidden />
                              </Grid.Row>
                            );
                          })}
                      </Grid>
                      <Divider hidden />                                             
                     
                    </Transition.Group>
                     {documentList === 0 && (
                      <Button
                        icon='plus'
                        type='button'
                        onClick={() =>                       
                            arrayHelpers.push({
                              file: undefined,
                              id: undefined,
                              number: '',
                            })                           
                        }
                        margin='0px'
                        position='center'
                      />
                    )}
                    </>
                  )}
                />
                </>
              )}
              <Divider hidden />
          <Button type='submit' content='Submit' />
          </Form>
        </Modal.Content>
        )}
      </Formik>
    </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    drivers: selectors.companyDriver.getAllDriversSelector(state),
    driversLoading: selectors.companyDriver.getAllDriversLoadingSelector(state),
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    addcontactsSuccess : selectors.contact.addContactSuccessSelector(state),
    transporters: selectors.contact.contactsTransportersSelector(state),
    contacts: selectors.contact.contactsSelector(state),
    contactsError: selectors.contact.errorSelector(state),
    contactsLoading: selectors.contact.getContactsLoadingSelector(state),
    contactsSuccess: selectors.contact.getContactsSuccessSelector(state),
    contactError: selectors.contact.errorSelector(state),
    allCountries: selectors.data.allCountriesSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    loadingHandlerData: selectors.data.allCountryLoadingSelector(state),
    customsOfficeLoading: selectors.customsDeclaration.getAllCustomsOfficeLoadingSelector(state),
    customsOffice: selectors.customsDeclaration.getAllCustomsOfficeSelector(state),
    categoriesDocuments: selectors.customsDeclaration.getAllCategoriesDocumentsSelector(state),
    partners: selectors.customsDeclaration.addPartnersSelector(state),
    partnersLoading: selectors.customsDeclaration.addDocumentsLoadingSelector(state),
    transitRates: selectors.customsDeclaration.getTransitRatesSelector(state),
    transitRatesLoading: selectors.customsDeclaration.getTransitRatesLoadingSelector(state),
    categoriesLoading: selectors.customsDeclaration.getCategoriesLoadingSelector(state),
    categories: selectors.customsDeclaration.getCategoriesSelector(state),
  };
  
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
