

export const longDate = (date: Date): string => {
  return date.toLocaleDateString('en', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const mediumDate = (date: Date): string => {
  return date.toLocaleDateString('en', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const shortDate = (date: Date): string => {
  return date.toLocaleDateString('en', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const shortDateFR = (date: Date): string => {
  return date.toLocaleDateString('fr', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const getYear = (date: Date): string => {
  return date.toLocaleDateString('en', {
    year: 'numeric',
  });
};

export const getMonth = (date: Date): string => {
  return date.toLocaleDateString('en', {
    month: 'long',
  });
};

export const yearMonth = (date: Date): string => {
  return date.toLocaleDateString('en', {
    year: 'numeric',
    month: '2-digit',
  });
};

export const onlyDate = (date: Date): string => {
  return date.toLocaleDateString('en', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  });
};

export const onlyDateFR = (date: Date): string => {
  return date.toLocaleDateString('fr', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
};


export const meanTime = (arrival: Date) => {
  //let arrivalDate = arrival.getTimezoneOffset()/60
  //let diff = meanTimeOffset - arrivalDate
  //let result = arrival.getHours()-arrivalDate
  //arrival.setHours(result)

  return arrival.toLocaleTimeString(`${navigator.language}`, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export const reallyshortDate = (date: Date): string => {
  return date.toLocaleDateString('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
};
