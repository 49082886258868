import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    getCompanySubscribed() {
      const companySubscribedApi =
        routesApi.fiscal.VATdeclaration.companySubscribed;
      return api.request({
        method: companySubscribedApi.method,
        route: companySubscribedApi.path,
      });
    },
    declare(data: dataReqI) {
      const declareApi = routesApi.fiscal.VATdeclaration.declare;
      const form: any = new FormData();
      form.set('company', data.data.company);
      form.set('companyName', data.data.companyName);
      form.set('tier', data.data.tier);
      form.set('activity', data.data.activity);
      form.set('date', data.data.date);
      form.set('workshop', data.data.workshop);
      form.set('country', data.data.country);
      form.append('file', data.data.file);
      return api.request({
        method: declareApi.method,
        route: declareApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    getDeclarations(data: dataReqI) {
      const getDeclarationsApi = routesApi.fiscal.VATdeclaration.getDeclarations;
      return api.request({
        method: getDeclarationsApi.method,
        route: getDeclarationsApi.path,
        query: data.query,
      });
    },
    subscribe(data: dataReqI) {
      const subscribeApi = routesApi.fiscal.VATdeclaration.subscribe;
      return api.request({
        method: subscribeApi.method,
        route: subscribeApi.path,
        params: data.params,
      });
    },
    unsubscribe(data: dataReqI) {
      const unsubscribeApi = routesApi.fiscal.VATdeclaration.unsubscribe;
      return api.request({
        method: unsubscribeApi.method,
        route: unsubscribeApi.path,
        params: data.params,
      });
    },
    getMapping(data: dataReqI) {
      const getMappingeApi = routesApi.fiscal.VATdeclaration.getMapping;
      return api.request({
        method: getMappingeApi.method,
        route: getMappingeApi.path,
      });
    },
    getTA(data: dataReqI) {
      const getTAApi = routesApi.fiscal.VATdeclaration.getTA;
      return api.request({
        method: getTAApi.method,
        route: getTAApi.path,
        params: data.params,
        data: data.data,
      });
    },
    getDeclaration(data: dataReqI) {
      const getDeclarationApi = routesApi.fiscal.VATdeclaration.getDeclaration;
      return api.request({
        method: getDeclarationApi.method,
        route: getDeclarationApi.path,
        params: data.params,
        data: data.data
      });
    },
    getLines(data: dataReqI) {
      const getLinesApi = routesApi.fiscal.VATdeclaration.getLines;
      return api.request({
        method: getLinesApi.method,
        route: getLinesApi.path,
        params: data.params,
      });
    },
    validate(data: dataReqI) {
      const validateApi = routesApi.fiscal.VATdeclaration.validate;
      return api.request({
        method: validateApi.method,
        route: validateApi.path,
        params: data.params,
        data: data.data,
      });
    },
    remove(data: dataReqI) {
      const removeApi = routesApi.fiscal.VATdeclaration.remove;
      return api.request({
        method: removeApi.method,
        route: removeApi.path,
        params: data.params,
      });
    },
  };
};
