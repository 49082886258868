import { Spacer } from 'components/core';
import React from 'react';
import { Divider, Grid, Icon, Popup } from 'semantic-ui-react';

const PartnersList = ({ userData, hostedUsers }: PartnersListI) => {

  return (
    userData.length>0 && (
     <>
     <Spacer height='0.5rem'/><b>Partners: </b>
     {userData.map((partner, i) => {
      let contact = hostedUsers.filter(e => e.Contact.Contact_Id === userData[i].id)        
          return (
            <>
            <Divider/>
            <Grid key={i} columns={3} divided>
              <Grid.Row key={`Row-${i}`}>
                <Grid.Column width={13}>
                  <span>{partner.name}   </span>
                  {contact.length>0 && contact[0].Contact.contract && (
                    <Popup content={`
                      ${contact[0].Contact.contract[0].Douane ? '• Custom' : ''}
                      ${contact[0].Contact.contract[0].TaxAgent ? '• Tax Agent' : ''}
                      ${contact[0].Contact.contract[0].MandatSivep ? '• Mandat Sivep' : ''}
                      ${contact[0].Contact.contract[0].OriginPref ? '• Origin Pref.' : ''}`
                    } trigger={<Icon name='info circle' />} wide/>
                  )}                                            
                </Grid.Column>
              </Grid.Row>
            </Grid>
            </>
          )     
      })}
     </>
     )
  );
}
interface PartnersListI {
  userData: any [];
  hostedUsers: any [];
}

export default PartnersList;
