import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  getDeliveryByToken(data: dataReqI) {
    return {
      type: actionTypes.GET_DELIVERY_BY_TOKEN,
      payload: data,
    };
  },
  transporterSelection(data: dataReqI) {
    return {
      type: actionTypes.TRANSPORTER_SELECTION,
      payload: data,
    };
  },
  uploadProof(data: dataReqI) {
    return {
      type: actionTypes.UPLOAD_PROOF,
      payload: data,
    };
  },
  getOneProof(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_PROOF,
      payload: data,
    };
  },
  downloadAttestation(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_ATTESTATION,
      payload: data,
    };
  },
  downloadCustomsRegimeInvoice(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
