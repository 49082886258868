import * as Yup from 'yup';

import config from 'config';

const customsDocuments = Yup.mixed()
  .test('fileType', 'Unsupported file format', (value) => {
    if (value) {
      const splitedName = value.name.split('.');
      return config.file.customsDocuments.fileType.includes(
        splitedName[splitedName.length - 1].toLowerCase()
      );
    }
  })
  .test('fileSize', 'File is too large', (value) => {
    return value && value.size < config.file.customsDocuments.fileSize;
  })
  .required('Required');

  export const updateCollectionbox = Yup.object().shape({
    taxPeriod: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    type: Yup.number().required('Required'), 
    clientReference: Yup.string(),
  })

  export const arrayOfVatDocuments = Yup.object().shape({
    documents: Yup.array().of(
      Yup.object()
        .shape({
          files: Yup.array().of(
            Yup.object().shape({
              path: Yup.string().required('Required'),
              name: Yup.string().required('Required')
            })
          ),
          id: Yup.number().required('Required'),
      }).nullable(true)
    ),
    split: Yup.boolean().nullable(true),
    type: Yup.number()
})

export const chunks = Yup.object().shape({
  start: Yup.number().min(1, 'Value cannot be inferior to 1').required(),
  end: Yup.number().required(),
  newName: Yup.string().required(),
  category: Yup.number().required(),
  tag: Yup.string().required(),
})


export const collectionbox = Yup.object().shape({
  taxPeriod: Yup.string().required('Required'),
  country: Yup.string(),
  clientReference: Yup.string().nullable(true),
  documents: Yup.array().of(
    Yup.object()
      .shape({
        files: Yup.array().of(
          Yup.object().shape({
            path: Yup.string().required('Required'),
            name: Yup.string().required('Required')
          })
        ),
        id: Yup.object().shape({
          id: Yup.number(),
          type: Yup.number()
        }).nullable(true),
      }).nullable(true)
  ),
  contact: Yup.object().shape({
        id: Yup.number(),
        name: Yup.string()
    }).nullable(true),
});

export const settingsSchema =  Yup.object().shape({
  settings: Yup.array().of(
    Yup.object().shape({
      Country: Yup.string().required(),
      flow: Yup.string().required(),
      declarationType: Yup.string().required(),
    })
    )
})

export const monitoringFiltersSchema = Yup.object().shape({
  id: Yup.string().nullable(true),
  companyId: Yup.number().nullable(true),
  declarationType: Yup.string().nullable(true),
  intranetReference: Yup.string().nullable(true),
  taxPeriod: Yup.string().nullable(true),
  showAll: Yup.boolean().nullable(true),
});


