import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  return {
    contact(data: dataReqI) {
      const contactApi = routesApi.contact;
      const form: any = new FormData();
      form.set("firstName", data.data.firstName);
      form.set("lastName", data.data.lastName);
      form.set("company", data.data.company);
      form.set("email", data.data.email);
      form.set("phoneNumber", data.data.phoneNumber);
      form.set("service", data.data.service);
      form.set("contactSubject", data.data.contactSubject);
      form.set("contactDescription", data.data.contactDescription);
      form.append("attachedFile", data.data.attachedFile);
      return api.request({
        method: contactApi.method,
        route: contactApi.path,
        data: form,
        params: data.params,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
  };
};
