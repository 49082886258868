import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Divider,
  Header
} from 'semantic-ui-react';
import { Spacer} from 'components/core'
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import AddCollectionboxModal from '../../New';
import Obligation from '../../components/Obligations';
import Button from 'components/Button';
import Pagination from 'components/Pagination';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';
import { CollectionboxDropdown, Right } from './style';
import SearchFilters from '../../components/SearchFilters/searchFilters';
import CollectionboxMainList from '../../components/CollectionboxMainList';


const Index = ({
  dispatch,
  match,
  user,
  subscribed,
  companySelected,
  collectionboxes,
  operators,
  operatorsLoading,
  collectionboxesLoading,
  createCollectionboxLoading,
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = companySelected || match.params.companyId || 49;
  const LIMIT = 100;
  const monitoringPathToDetail = window.location.pathname.includes('monitoring')
  const storedSearch = useMemo(() => JSON.parse(sessionStorage.getItem('collection_search')), [])
  let storedPage = sessionStorage.getItem('collection_page') !== undefined ? JSON.parse(sessionStorage.getItem('collection_page')) : null

  const [CollectionboxModal, setCollectionboxModal]: any = useState(false);
  const [modalObligations, setmodalObligations]: any = useState(false);
  const [data, setData]: any = useState(null);
  const [operatorList, setOperators]: any = useState(null);
  const [monthsList, setMonthList]: any = useState([])
  const [isoList, setIsoList]: any = useState([])
  const [filterBar, setfilterBar] = useState(false);
  const [session, setSession]: any = useState({operator: `${user.lastName}-${user.firstName}`, period: '', iso: ''})

  useEffect(() => {
    if(!companySelected){
      dispatch(actions.operators.getAllOperator());
      return () => {
        dispatch(actions.operators.reset(['allOperator', 'error']));
      };
    }
  }, [dispatch, companySelected]);
  
  useEffect(() => {

    let queryObj = {}
    Object.assign(queryObj, {
      limit: LIMIT,
      state: 'processing',
      page: storedPage ? storedPage : 1,
      ...storedSearch && storedSearch,
    })

    sessionStorage.setItem('collection_page', storedPage ? storedPage : 1)
    sessionStorage.setItem('collection_search', JSON.stringify({"limit": LIMIT,"page":1,"state":"processing","showAll":false}));
    
    dispatch(actions.collectionbox.getCollectionboxesByFilters({
        query: queryObj
      }));

    dispatch(actions.socket.subscribeCollectionboxesALLFiltered(companyId))

    return () => {
      dispatch(
        actions.collectionbox.reset(['getByFilters', 'error'])
      );
      dispatch(actions.socket.unsubscribeCollectionboxesALLFiltered(companyId))
    };

  }, [dispatch, storedSearch, companySelected, storedPage, companyId]);

  useEffect(() => {
    operators && setOperators(operators.map((e, index) => {
      return {
        key: index,
        value: `${e.lastName}-${e.firstName}`,
        text: `${e.lastName}-${e.firstName}` 
      }
    }));
  }, [operators]);


  const resetSearchFilters = () => {
    sessionStorage.removeItem('collection_search');
    dispatch(
      actions.collectionbox.getCollectionboxesByFilters({
        query: {
          limit: LIMIT,
          state: 'processing',
        },
      })
    );
    setfilterBar(!filterBar)
    return () => {
      dispatch(
        actions.collectionbox.reset(['getByFilters', 'error'])
      );
    }
};


  const getCollectionboxsByFilters = (values: SearchCollectionboxs, {activePage}) => {

    let queryObj = {}
    Object.assign(queryObj, {
      limit: LIMIT,
      ...values.collectionboxId && {collectionboxId: values.collectionboxId },
      ...values.Company_Id && {Company_Id: values.Company_Id },
      ...values.declarationType && {declarationType: values.declarationType },
      ...values.taxPeriod && {taxPeriod: values.taxPeriod },
      ...values.intranetReference && {intranetReference: values.intranetReference },
      ...activePage ? { page: Math.ceil(activePage) } : { page: 1 },
      state: 'processing',
      showAll: values.showAll ? values.showAll : false
    })
    
    sessionStorage.setItem('collection_page', JSON.stringify(activePage ? activePage : 1));
    sessionStorage.setItem('collection_search', JSON.stringify(queryObj));

    dispatch(
      actions.collectionbox.getCollectionboxesByFilters({
        params: {
          companyId: companyId
        },
        query: queryObj
      })
    );
    return () => {
      dispatch(
        actions.collectionbox.reset([
          'getByFilters',
          'error',
        ])
      );
    }
}

  useEffect(() => {
    if(collectionboxes){
      // create filter lists
      const activePeriods: any = [], iso: any = [];
      for(const item of collectionboxes.rows){
        const found = activePeriods.some((value) => value === item.taxPeriod);
        const isoFound = iso.some((value) => value === item.country);
        if (!found) {
          activePeriods.push(item.taxPeriod);
        }
        if(!isoFound){
          iso.push(item.country)
        }
      }
      setMonthList(activePeriods.map((month, index) => {
        return {
          key: index,
          value: month,
          text: `${month.split('-')[0]} - ${new Date(month.split('-')[1]).toLocaleDateString('en', {
            month: 'long',
          })}`
        }
      }))
      setIsoList(iso.map((el, index) => {
        return {
          key: index,
          value: el,
          text: el,
        }}))

      // init filters with stored values
      const session = JSON.parse(sessionStorage.getItem(`collection_monit`))
      session && setSession(prevState => ({
        ...prevState,
        iso: session.iso,
        period: session.period
      })) 
    }
  }, [collectionboxes, companyId]);


  useEffect(() => {
    // period and country filters
    const result = session && collectionboxes && collectionboxes.rows.filter(
      (value) => 
        (session.period !== '' && session.iso !== '') ?
          value.taxPeriod === session.period && value.country === session.iso 
        :
        (session.period === '' && session.iso) ?
          value.country === session.iso 
        :
        (session.iso === '' && session.period) ?
          value.taxPeriod === session.period 
        :
        (session.period === '' && session.iso === '') ?
          value : null
      );

      let newData
      if(result){
        if(operators){
          sessionStorage.setItem(`collection_monit`, JSON.stringify(session))
          // First follower is "Company Collectionbox Setting" assigned
          newData = 
            session.operator !== '' ? 
            result.filter((value) => (value.follower && value.follower[0].operator) === session.operator) : 
            result 
        }else{
          newData = result
        }
        setData(newData)
      }
  }, [collectionboxes, session, user, operators])


  return (
    <Wrapper display='flex' justifyContent='center'>
    {CollectionboxModal && (
      <AddCollectionboxModal 
        open={CollectionboxModal}
        handleClose={() => setCollectionboxModal(false)}
        companyId={companyId}
        ownerId={companyId}
        userAdminOrOp={userAdminOrOp}
      />
    )}
    {modalObligations && (
      <Obligation
        open={modalObligations}
        companyId={companyId}
        handleClose={() => setmodalObligations(false)}
      />
    )}
    {(( 
        collectionboxesLoading || 
        createCollectionboxLoading
      ) && <Loader />) || (
      <Wrapper height='100%' width='80%' overflow='auto'>
          <Divider hidden />        
          <Wrapper display='flex'>
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content='Back'
              float='left'
              path={`/fiscal/collectionbox`}
            />
          </Wrapper>           
        <Header size='huge' textAlign='center'>Collectionbox monitoring</Header>
        <Divider hidden />
          Numbers of declarations displayed with filter: {data && data.length}
        <Spacer/>
        <Wrapper display='flex' justifyContent='space-between' alignItems='center'>
          <Right>
            <Button
              icon='filter'
              type='button'
              content='Search Filters'
              color='blue'
              onClick={() => setfilterBar(!filterBar)}
            />
            {!userAdminOrOp && (
              <Button
                labelPosition='right'
                position='left'
                icon='plus circle'
                type='button'
                content='New Declaration'
                onClick={() => setCollectionboxModal(true)}
              />
            )}
            {!userAdminOrOp && (
              <Button
                labelPosition='right'
                position='left'
                icon='plus circle'
                type='button'
                content='Obligations'
                onClick={() => setmodalObligations(true)}
              />
            )}           
            {userAdminOrOp && (
              <CollectionboxDropdown
                clearable
                search
                selection
                placeholder='Operator...'
                options={operatorList}
                loading={operatorsLoading}
                defaultValue={`${user.lastName}-${user.firstName}` || null}
                onChange={(e, {value}) => {
                  e && setSession(prevState => ({
                    ...prevState,
                    operator: value
                  }))
                }}
              />
            )}          
            <CollectionboxDropdown
              clearable
              search
              selection
              placeholder='Filing period...'
              options={monthsList}
              defaultValue={session.period || null}
              onChange={(e, {value}) => {
                e && setSession(prevState => ({
                  ...prevState,
                  period: value
                }))
              }}
            />
            <CollectionboxDropdown
              clearable
              search
              selection
              placeholder='Country...'
              options={isoList}
              defaultValue={session.iso || null}
              onChange={(e, {value}) => {
                e && setSession(prevState => ({
                  ...prevState,
                  iso: value
                }))
              }}
            />
          </Right>    
        </Wrapper>
        <Wrapper display='flex' justifyContent='flex-end'>
          <SearchFilters
            user={user}
            companySelected={companySelected}
            companyId={companyId}
            isVisible={filterBar}
            companies={subscribed}
            applyFilters={getCollectionboxsByFilters}
            resetFilters={resetSearchFilters}
          />
        </Wrapper>
        <Spacer height='15px' />
        <Wrapper height='100%' overflow='auto' position='relative' minHeight='300px'>
          <CollectionboxMainList
            key={0}
            data={data}
            companyId={companyId}
            monitoringPathToDetail={monitoringPathToDetail}
          />                                                               
        <Wrapper>
        <Divider hidden />
        {collectionboxes && collectionboxes.count>0 && (
          <Pagination
            totalPages={collectionboxes.count / LIMIT}
            storedPage={sessionStorage.getItem('collection_page') !== null ? parseInt(sessionStorage.getItem('collection_page')) : 1}
            onChange={getCollectionboxsByFilters}
          />
        )}   
      </Wrapper>
      </Wrapper>
  </Wrapper>
  )}
  </Wrapper> 
  )
}

type SearchCollectionboxs = {
  limit: number,
  state: string,
  collectionboxId: string,
  Company_Id: number,
  declarationType: string,
  taxPeriod: string,
  intranetReference: string,
  page: number,
  showAll: boolean
}


const mapStateToProps = (state) => {
  return {
    subscribed: selectors.collectionbox.companySubscribedSelector(state),
    operators: selectors.operators.allOperatorSelector(state),
    operatorsLoading: selectors.operators.getAllOperatorLoadingSelector(state),
    companySelected: selectors.auth.companySelected(state),
    collectionboxes: selectors.collectionbox.getCollectionboxesByFiltersSelector(state),
    collectionboxesLoading: selectors.collectionbox.getCollectionboxesByFiltersLoadingSelector(state),
    createCollectionboxLoading: selectors.collectionbox.createOneCollectionboxLoadingSelector(state)
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
