import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Form, Header, Modal } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { File } from 'components/Form';
import { registerOssSchema } from 'interfaces/formSchema';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';

const Index = ({
  dispatch,
  companies,
  cf,
  open,
  handleClose,
  loadingHandlerCF,
  cfSelectorError,
}) => {
  const [companiesList, setcompaniesList] = useState([]);
  const [activitiesList, setactivitiesList] = useState([]);

  useEffect(() => {
    dispatch(actions.fiscalOss.getCompanySubscribed());

    return () => {
      dispatch(
        actions.fiscalOss.reset(['cf', 'date', 'error', 'companySubscribed'])
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (companies !== null) {
      setcompaniesList(
        companies.map((company, index) => {
          return {
            key: index,
            value: company.id,
            flag: company.country.toLowerCase(),
            text: company.name,
          };
        })
      );
    }
  }, [companies]);

  useEffect(() => {
    if (cf !== null) {
      setactivitiesList(
        cf.map((activity, index) => {
          return {
            key: index,
            value: activity.id_activite,
            text: activity.activite,
          };
        })
      );
    }
  }, [cf]);

  const companiesSelected = (companyId) => {
    companyId && dispatch(actions.fiscalOss.getCF({ params: { companyId } }));
  };

  const registerIntegration = (values) => {
    dispatch(
      actions.fiscalOss.registerIntegration({
        params: { companyId: values.company },
        data: {
          cfId: values.cf,
          file: values.file,
        },
      })
    );
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content='New OSS registration' />
      <Formik
        initialValues={{
          company: '',
          cf: '',
          file: '',
        }}
        onSubmit={registerIntegration}
        validationSchema={registerOssSchema}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
          <Modal.Content>
            <Form onSubmit={handleSubmit}>
              <Field
                mandatory
                label='Client'
                placeholder='Choose a client'
                name='company'
                options={companiesList}
                component={Dropdown}
                onChange={companiesSelected}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
              />
              <Divider hidden />
              <Field
                mandatory
                disabled={!values.company}
                loading={loadingHandlerCF}
                label='Conditions Facturation'
                placeholder='Choose a CF'
                name='cf'
                options={activitiesList}
                component={Dropdown}
                meta={{ errors, touched, setFieldValue, setFieldTouched }}
              />
              <Divider hidden />
              <Field
                label={<MandatoryFieldLabel content='File' />}
                name='file'
                component={File}
                touched={touched?.file}
                error={errors?.file}
                meta={{ setFieldValue, setFieldTouched }}
              />
              <Divider hidden />
              <Button
                disabled={isSubmitting}
                type='submit'
                content='Submit'
                color='green'
              />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.fiscalOss.companySubscribedSelector(state),
    cf: selectors.fiscalOss.cfSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    loadingHandlerCF: selectors.fiscalOss.cfLoadingSelector(state),
    loadingHandlercompanies: selectors.company.companiesLoadingSelector(state),
    loadingHandlerData: selectors.data.allCountryLoadingSelector(state),
    cfSelectorError: selectors.fiscalOss.cfSelectorError(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
