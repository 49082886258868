import VatServiceMonitoring from 'views/Fiscal/Vat/Operator/Monitoring'
import RepositoryCountry from 'views/Fiscal/Vat/Operator/CountryDashboard'
import VatRepository from 'views/Fiscal/Vat'
import VatDetails from 'views/Fiscal/Vat/Details'
import VatDetailExcel from 'views/Fiscal/Vat/Excel'
import RepositoryDashboard from 'views/Fiscal/Vat/Operator/CompanyDashboard'

import paths from "./paths";

export default [
  {
    private: true,
    path: paths.repository,
    component: VatRepository,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.repositoryMonitoring,
    component: VatServiceMonitoring,
    exact: true,
    authorizedRoles: ["operator"]
  },
  {
    private: true,
    path: paths.repositoryDetails,
    component: VatDetails,
    exact: true,
    authorizedRoles: []
  },
  {
    private: true,
    path: paths.repositoryList,
    component: RepositoryDashboard,
    exact: true,
    authorizedRoles: [] // op
  },
  {
    private: true,
    path: paths.repositoryCountry,
    component: RepositoryCountry,
    exact: true,
    authorizedRoles: ["operator"]
  },
  {
    private: true,
    path: paths.repositoryExcel,
    component: VatDetailExcel,
    exact: true,
    authorizedRoles: ["operator"]
  }
];
