// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Popup from '../popup/popup';

// Resources
import addLogo from '../../assets/images/logos/add.png';

// Interfaces
import AddButtonProperties from '../../interfaces/addButtonProperties';

// Stylesheets
import './addButton.css';

const AddButton: React.FC<AddButtonProperties> = ({
  popupVisible,
  myRef,
  popupRef,
  title,
  action,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className='addButtonContainer'>
      <button ref={myRef} className='addButton' onClick={action}>
        <p>{t('globalStrings.addButtonTitle')}</p>
        <img src={addLogo} alt='Add logo' className='addLogo' />
      </button>

      {popupVisible ? (
        <Popup myRef={popupRef} title={t(title)} onClickClose={action}>
          {children}
        </Popup>
      ) : null}
    </div>
  );
};

export default AddButton;
