import { orderBy } from 'lodash';
import { Field, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
  Divider,
  Form,
  Grid,
  Header,
  Modal
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { Input } from 'components/Form';

import { updateAccises } from 'interfaces/formSchema/customsValidators';


const ModalEditAccises = ({
  open,
  actualData,
  handleEditAccises,
  handleClose,
  dispatch,
  companyId,
  intl,
  allCountries,
  allCountriesLoading,
  errorHandlerData,
}: ModalEditAccisesProps) => {

  const [allCountriesList, setAllCountriesList]: any = useState([]);


  const formikRef = useRef();

  useEffect(() => {
    dispatch(actions.data.getAllCountries());
    dispatch(actions.customsDeclaration.getAllCustomsOffice());
    dispatch(actions.customsDeclaration.getCategories({
      params: { companyId: companyId},
      query: {
        type: true
      }
    }));
    dispatch(actions.customsDeclaration.getTransitRates({
      params: { companyId: companyId, type: 'all'},
    }));

    dispatch(actions.contact.getAllContactTransporters({
      params: {
        companyId:  companyId 
      }
    }));

    return () => {
      dispatch(actions.data.reset(['allCountries', 'error']));
      dispatch(actions.customsDeclaration.reset(['customsOffices', 'transitRates', 'error']));
      dispatch(actions.contact.reset(['contactsTransporters', 'error']));
    };
  }, [dispatch, actualData, companyId]);

 
  useEffect(() => {
    if (allCountries) {
      setAllCountriesList(
        orderBy(
          allCountries.map((country, index) => {
            return {
              key: index,
              value: country.iso_code,
              text: intl.formatMessage({
                id: `countries.name.${country.iso_code}`,
              }),
              flag: country.iso_code.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [allCountries, intl]);

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='edit' content='Edit Accises declaration' />
      <Formik
        ref={formikRef}
        initialValues={{
          clientReference: actualData.clientReference || '',
          departure: actualData.departure || '',
          arrival: actualData.arrival || '',
          startDate: actualData.startDate,
          endDate: actualData.endDate,
        }}
        enableReinitialize={true}
        onSubmit={handleEditAccises}
        validationSchema={updateAccises}
      >
      {({
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
        isSubmitting,
      }) => (
        <Modal.Content>
          <Form onSubmit={handleSubmit}>   
              <Grid columns='2'>
                    <Grid.Column>
                      <Field
                          mandatory
                          label="Departure"
                          name='departure'
                          placeholder='Choose the country of origin'
                          options={allCountriesList}
                          component={Dropdown}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                          loading={allCountriesLoading}
                          uniqueError={
                            errorHandlerData.includes('allCountries')
                              ? true
                              : false
                          }
                        />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                          mandatory
                          label="Arrival"
                          name='arrival'
                          placeholder='Choose the country of arrival'
                          options={allCountriesList}
                          component={Dropdown}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                          loading={allCountriesLoading}
                          uniqueError={
                            errorHandlerData.includes('allCountries')
                              ? true
                              : false
                          }
                        />
                    </Grid.Column>                    
                  </Grid>
                  <Grid columns='2'>
                  <Grid.Column>
                    <Field
                      mandatory
                      label='Start date'
                      name='startDate'
                      component={Input}
                      type='date'
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                 </Grid.Column>
                 <Grid.Column>
                  <Field
                      mandatory
                      label='End date'
                      name='endDate'
                      component={Input}
                      type='date'
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Grid.Column>                    
                </Grid>
                <Grid>
                  <Grid.Column>
                  <Field

                      label='Client reference'
                      name='clientReference'
                      placeholder='123456789'
                      component={Input}
                      type='text'
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  </Grid.Column>
                </Grid>
             
            <Divider hidden />
            <Button
              disabled={isSubmitting}
              type='submit'
              content='Submit'
              color='green'
            />
          </Form>
        </Modal.Content>
      )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    allCountries: selectors.data.allCountriesSelector(state),
    allCountriesLoading: selectors.data.allCountryLoadingSelector(state),
    transitRates: selectors.customsDeclaration.getTransitRatesSelector(state),
    transitRatesLoading: selectors.customsDeclaration.getTransitRatesLoadingSelector(state),
    customsOfficeLoading: selectors.customsDeclaration.getAllCustomsOfficeLoadingSelector(state),
    customsOffice: selectors.customsDeclaration.getAllCustomsOfficeSelector(state),
    company: selectors.company.companySelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

type accisesData = {
  clientReference: string,
  departure: string,
  arrival: string,
  startDate: string,
  endDate: string,
};

type actualData = {
  clientReference: string,
  departure: string,
  arrival: string,
  startDate: string,
  endDate: string,
};

interface ModalEditAccisesProps {
  open: boolean;
  actualData: actualData;
  handleEditAccises: (values: accisesData) => void;
  handleClose: () => void;
  dispatch: any;
  customsOffice;
  companyId: number;
  intl;
  allCountries;
  allCountriesLoading;
  customsOfficeLoading;
  errorHandlerData;
  company;
}

export default enhance(ModalEditAccises);
