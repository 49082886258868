import { saveAs } from 'file-saver';
import actionTypes from 'store/fiscal/collectionBox/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';


export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.GET_COMPANIES_BY_ISO:
        try{
          next({ type: actionTypes.GET_COMPANIES_BY_ISO_LOADING });
            const resp = await request.getCompaniesByIso({ ...action.payload });
          next({
            type: actionTypes.GET_COMPANIES_BY_ISO_SUCCESS,
            payload: resp.data.data
          });
        }catch(error){
          next({
            type: actionTypes.GET_COMPANIES_BY_ISO_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_CONTACTS_HAS_SUBSCRIBED:
        try{
          next({ type: actionTypes.GET_CONTACTS_HAS_SUBSCRIBED_LOADING });
            const resp = await request.getContactsSubscribed({ ...action.payload });
          next({
            type: actionTypes.GET_CONTACTS_HAS_SUBSCRIBED_SUCCESS,
            payload: resp.data.data
          });
        }catch(error){
          next({
            type: actionTypes.GET_CONTACTS_HAS_SUBSCRIBED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.OBLIGATION_CREATE:
        try{
          next({ type: actionTypes.OBLIGATION_CREATE_LOADING });
            await request.createObligation({ ...action.payload });
          next({
            type: actionTypes.OBLIGATION_CREATE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        }catch(error){
          next({
            type: actionTypes.OBLIGATION_CREATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_CATEGORIES:
        try {
          next({ type: actionTypes.GET_CATEGORIES_LOADING });
            const resp = await request.getCategories({ ...action.payload });
          next({
            type: actionTypes.GET_CATEGORIES_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_CATEGORIES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.READ_CHAT:
        try {
          next({ type: actionTypes.READ_CHAT_LOADING });
            await request.readChat({ ...action.payload });
          next({
            type: actionTypes.READ_CHAT_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.READ_CHAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.SETTINGS_GET:
        try {
          next({ type: actionTypes.SETTINGS_GET_LOADING });
            const resp = await request.getSettings({ ...action.payload });
          next({
            type: actionTypes.SETTINGS_GET_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.SETTINGS_GET_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.SETTINGS:
        try {
          next({ type: actionTypes.SETTINGS_LOADING });
            await request.settings({ ...action.payload });
          next({
            type: actionTypes.SETTINGS_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SETTINGS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;


      case actionTypes.UPDATE_DOC:
        try {
          next({ type: actionTypes.UPDATE_DOC_LOADING });
            await request.updateDocument({ ...action.payload });
          next({
            type: actionTypes.UPDATE_DOC_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_DOC_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_FILTERED_COLLECTIONBOXES:
        try {
          next({type: actionTypes.GET_FILTERED_COLLECTIONBOXES_LOADING});
            const resp = await request.getCollectionboxesByFilters({...action.payload});
          next({
            type: actionTypes.GET_FILTERED_COLLECTIONBOXES_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.GET_FILTERED_COLLECTIONBOXES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.FILES_STATE:
        try {
          next({type: actionTypes.FILES_STATE_LOADING});
            await request.changeFileState({...action.payload});
          next({
            type: actionTypes.FILES_STATE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.FILES_STATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.DELETE_FILES:
        try {
          next({type: actionTypes.DELETE_FILES_LOADING});
            await request.deleteFiles({...action.payload});
          next({
            type: actionTypes.DELETE_FILES_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.DELETE_FILES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.CREATE_NONE_COLLECTIONBOX:
        try {
          next({type: actionTypes.CREATE_NONE_COLLECTIONBOX_LOADING});
            await request.createNoneCollectionbox({...action.payload});
          next({
            type: actionTypes.CREATE_NONE_COLLECTIONBOX_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_NONE_COLLECTIONBOX_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_PERMS:
        try {
          next({type: actionTypes.GET_PERMS_LOADING});
          const resp = await request.getPermissions({...action.payload});
          next({
            type: actionTypes.GET_PERMS_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_PERMS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.FILTER_TAG_ARCHIVES:
        try {
          next({type: actionTypes.FILTER_TAG_ARCHIVES_LOADING});
          const resp = await request.filterTagArchive({...action.payload});
          next({
            type: actionTypes.FILTER_TAG_ARCHIVES_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.FILTER_TAG_ARCHIVES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GROUP_FILES:
        try {
          next({type: actionTypes.GROUP_FILES_LOADING});
          const resp = await request.groupFiles(action.payload);
          saveAs(
            resp.data,
            resp.headers['content-disposition']
              .split('filename=')[1]
              .replace(/"/g, '')
          );
          next({
            type: actionTypes.GROUP_FILES_SUCCESS
          });
        } catch (error) {
          next({
            type: actionTypes.GROUP_FILES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.RENAME_SEND_CHUNCKS:
        try {
          next({ type: actionTypes.RENAME_SEND_CHUNCKS_LOADING});
          const resp = await request.renameSendChunk({...action.payload});
          next({
            type: actionTypes.RENAME_SEND_CHUNCKS_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.RENAME_SEND_CHUNCKS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.SPLIT_DOCUMENT:
        try {
          next({ type: actionTypes.SPLIT_DOCUMENT_LOADING});
          const resp = await request.splitDocument({...action.payload});
          next({
            type: actionTypes.SPLIT_DOCUMENT_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.SPLIT_DOCUMENT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.BULK_FILTER:
        try {
          next({ type: actionTypes.BULK_FILTER_LOADING});
          const resp = await request.bulkFilter({...action.payload});
          next({
            type: actionTypes.BULK_FILTER_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.BULK_FILTER_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.ARCHIVE_ALL:
        try {
          next({ type: actionTypes.ARCHIVE_ALL_LOADING});
          const resp = await request.archiveAll({...action.payload});
          next({
            type: actionTypes.ARCHIVE_ALL_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.ARCHIVE_ALL_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.FILTER_TAG:
        try {
          next({ type: actionTypes.FILTER_TAG_LOADING });
          const resp = await request.filterTag({...action.payload});
          next({
            type: actionTypes.FILTER_TAG_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.FILTER_TAG_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.SINGLE_FILE:
        try {
          next({ type: actionTypes.SINGLE_FILE_LOADING });
          const resp = await request.createSingleFile({...action.payload});
          next({
            type: actionTypes.SINGLE_FILE_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.SINGLE_FILE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_ARCHIVE_FILE:
        try {
          next({ type: actionTypes.GET_ARCHIVE_FILE_LOADING });
          const resp = await request.getArchiveFile({...action.payload});
          next({
            type: actionTypes.GET_ARCHIVE_FILE_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ARCHIVE_FILE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_ARCHIVE_FILES:
        try {
          next({ type: actionTypes.GET_ARCHIVE_FILES_LOADING });
          const resp = await request.getFilesFullList({...action.payload});
          next({
            type: actionTypes.GET_ARCHIVE_FILES_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ARCHIVE_FILES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.TAG_OPERATION:
        try {
          next({ type: actionTypes.TAG_OPERATION_LOADING });
          const resp = await request.handleTagOperations({...action.payload});
          next({
            type: actionTypes.TAG_OPERATION_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.TAG_OPERATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_COMPANY_SUBSCRIBED:
        try {
          next({ type: actionTypes.GET_COMPANY_SUBSCRIBED_LOADING });
          const resp = await request.getCompanySubscribed();
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_OBLIGATION:
          try {
            next({ type: actionTypes.GET_OBLIGATION_LOADING });
            const response = await request.getObligations({...action.payload});
            next({
              type: actionTypes.GET_OBLIGATION_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_OBLIGATION_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;
        
      case actionTypes.SUBSCRIBE:
        try {
          next({ type: actionTypes.SUBSCRIBE_LOADING });
          await request.subscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.SUBSCRIBE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UNSUBSCRIBE:
        try {
          next({ type: actionTypes.UNSUBSCRIBE_LOADING });
          await request.unsubscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.UNSUBSCRIBE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UNSUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.DOWNLOAD_DOC:
        try {
          next({ type: actionTypes.DOWNLOAD_DOC_LOADING });
            const resp = await request.downloadDocument({ ...action.payload });
            saveAs(
              resp.data,
              resp.headers['content-disposition']
                .split('filename=')[1]
                .replace(/"/g, '')
            );
          next({
            type: actionTypes.DOWNLOAD_DOC_SUCCESS,
              payload: resp.data.data
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.DOWNLOAD_DOC_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.DELETE_DOC:
        try {
          next({ type: actionTypes.DELETE_DOC_LOADING });
            await request.deleteDocument({ ...action.payload });
          next({
            type: actionTypes.DELETE_DOC_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.DELETE_DOC_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.COLLECTIONBOX_GET_DOC_CAT:
        try {
          next({ type: actionTypes.COLLECTIONBOX_GET_DOC_CAT_LOADING });
            const resp = await request.getDocsFromCat({ ...action.payload });
          next({
            type: actionTypes.COLLECTIONBOX_GET_DOC_CAT_SUCCESS,
            payload: resp.data.data
          });
        } catch (error) {
          next({
            type: actionTypes.COLLECTIONBOX_GET_DOC_CAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.COLLECTIONBOX_ADD_DOCUMENTS:
        try {
          next({ type: actionTypes.COLLECTIONBOX_ADD_DOCUMENTS_LOADING });
            const resp = await request.addCollectionboxDocuments({ ...action.payload });
          next({
            type: actionTypes.COLLECTIONBOX_ADD_DOCUMENTS_SUCCESS,
            payload: resp.data.data
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.COLLECTIONBOX_ADD_DOCUMENTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.FOLLOW_COLLECTIONBOX:
        try {
          next({ type: actionTypes.FOLLOW_COLLECTIONBOX_LOADING });
            await request.followCollectionbox({ ...action.payload });
          next({
            type: actionTypes.FOLLOW_COLLECTIONBOX_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.FOLLOW_COLLECTIONBOX_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.UPDATE_STEP:
        try {
          next({ type: actionTypes.UPDATE_STEP_LOADING });
            await request.updateStep({ ...action.payload });
          next({
            type: actionTypes.UPDATE_STEP_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_STEP_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.CREATE_COLLECTIONBOX:
        try {
          next({ type: actionTypes.CREATE_COLLECTIONBOX_LOADING });
            await request.createClientCollectionbox({ ...action.payload });
          next({
            type: actionTypes.CREATE_COLLECTIONBOX_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.CREATE_COLLECTIONBOX_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE_COLLECTIONBOX:
        try {
          next({ type: actionTypes.UPDATE_COLLECTIONBOX_LOADING });
            await request.updateCollectionbox({ ...action.payload });
          next({
            type: actionTypes.UPDATE_COLLECTIONBOX_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_COLLECTIONBOX_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ALL_COLLECTIONBOXES:
        try {
          next({ type: actionTypes.GET_ALL_COLLECTIONBOXES_LOADING });
          const resp = await request.getAllCollectionboxes({ ...action.payload });
          next({
            type: actionTypes.GET_ALL_COLLECTIONBOXES_SUCCESS,
            payload: resp.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_COLLECTIONBOXES_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.GET_ONE_COLLECTIONBOX:
          try {
            next({ type: actionTypes.GET_ONE_COLLECTIONBOX_LOADING });
            const resp = await request.getOneCollectionbox({ ...action.payload });
            next({
              type: actionTypes.GET_ONE_COLLECTIONBOX_SUCCESS,
              payload: resp.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.GET_ONE_COLLECTIONBOX_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
          break;
  

      default:
        next(action);
        break;
    }
  };
}
