import actionTypes from 'store/company/contact/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.GET_BOTH_CONTACTS:
        try {
          next({ type: actionTypes.GET_BOTH_CONTACTS_LOADING });
          const response = await request.getBothContact({ ...action.payload });
          next({
            type: actionTypes.GET_BOTH_CONTACTS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_BOTH_CONTACTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
      break;

      case actionTypes.GET_ALL_CONTACT_RIGHTS:
        try {
          next({ type: actionTypes.GET_ALL_CONTACT_RIGHTS_LOADING });
          const response = await request.getAllContactRights({ ...action.payload });
          next({
            type: actionTypes.GET_ALL_CONTACT_RIGHTS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_CONTACT_RIGHTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_CONTACT_RIGHTS:
        try {
          next({ type: actionTypes.GET_CONTACT_RIGHTS_LOADING });
          const response = await request.getContactRights({ ...action.payload });
          next({
            type: actionTypes.GET_CONTACT_RIGHTS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_CONTACT_RIGHTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
        case actionTypes.ADD_CONTACT_RIGHTS:
          try {
            next({ type: actionTypes.ADD_CONTACT_RIGHTS_LOADING });
            const response = await request.addContactRights({ ...action.payload });
            next({
              type: actionTypes.ADD_CONTACT_RIGHTS_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.ADD_CONTACT_RIGHTS_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.getErrorMessage(error.status, error.message)
            );
          }
          break;
      case actionTypes.GET_ALL_CONTACTS:
        try {
          next({ type: actionTypes.GET_ALL_CONTACTS_LOADING });
          const response = await request.getAllContacts({ ...action.payload });
          next({
            type: actionTypes.GET_ALL_CONTACTS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_CONTACTS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ONE_CONTACT:
        try {
          next({ type: actionTypes.GET_ONE_CONTACT_LOADING });
          const response = await request.getOneContact({ ...action.payload });
          next({
            type: actionTypes.GET_ONE_CONTACT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_CONTACT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.CREATE_CONTACT:
        try {
          next({ type: actionTypes.CREATE_CONTACT_LOADING });
          await request.createContact({
            ...action.payload,
          });
          next({
            type: actionTypes.CREATE_CONTACT_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_CONTACT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.ADD_CONTACT:
        try {
          next({ type: actionTypes.ADD_CONTACT_LOADING });
          const response = await request.addContact({ ...action.payload });
          next({
            type: actionTypes.ADD_CONTACT_SUCCESS,
            payload: response.data.data,
          });
          store.dispatch(messageHandler.successMessage('Contact added'));
        } catch (error) {
          next({
            type: actionTypes.ADD_CONTACT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
        case actionTypes.ADD_CONTACT_PARTNER:
          try {
            next({ type: actionTypes.ADD_CONTACT_PARTNER_LOADING });
            const response = await request.addContactPartner({ ...action.payload });
            next({
              type: actionTypes.ADD_CONTACT_PARTNER_SUCCESS,
              payload: response.data.data,
            });
            store.dispatch(messageHandler.successMessage('Partner added. He will receive a verification email'));
          } catch (error) {
            next({
              type: actionTypes.ADD_CONTACT_PARTNER_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
        break;
        case actionTypes.VALIDATE_CONTACT_PARTNER:
          try {
            next({ type: actionTypes.VALIDATE_CONTACT_PARTNER_LOADING });
            const response = await request.validateContactPartner({ ...action.payload });
            next({
              type: actionTypes.VALIDATE_CONTACT_PARTNER_SUCCESS,
              payload: response.data.data,
            });
            store.dispatch(messageHandler.successMessage('your Partner is now authorized'));
          } catch (error) {
            next({
              type: actionTypes.VALIDATE_CONTACT_PARTNER_ERROR,
              error,
            });
            store.dispatch(
              messageHandler.errorMessage(error.status, error.message)
            );
          }
        break;
      case actionTypes.DELETE_CONTACT:
        try {
          next({ type: actionTypes.DELETE_CONTACT_LOADING });
          await request.deleteContact({ ...action.payload });
          next({
            type: actionTypes.DELETE_CONTACT_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage('Contact deleted'));
        } catch (error) {
          next({
            type: actionTypes.DELETE_CONTACT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.VALIDATE_CONTACT:
        try {
          next({ type: actionTypes.VALIDATE_CONTACT_LOADING });
          await request.validateContact({ ...action.payload });
          next({
            type: actionTypes.VALIDATE_CONTACT_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.VALIDATE_CONTACT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.REFUSE_CONTACT:
        try {
          next({ type: actionTypes.REFUSE_CONTACT_LOADING });
          await request.refuseContact({ ...action.payload });
          next({
            type: actionTypes.REFUSE_CONTACT_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.REFUSE_CONTACT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_COMPANIES_NOT_IN_CONTACT:
        try {
          next({ type: actionTypes.GET_COMPANIES_NOT_IN_CONTACT_LOADING });
          const response = await request.getCompaniesNotInContact({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_COMPANIES_NOT_IN_CONTACT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_COMPANIES_NOT_IN_CONTACT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_PENDING_CONTACT:
        try {
          next({ type: actionTypes.GET_PENDING_CONTACT_LOADING });
          const response = await request.getPendingContact({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_PENDING_CONTACT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_PENDING_CONTACT_ERROR,
            error,
          });
        }
        break;
      case actionTypes.GET_REQUEST_CONTACT:
        try {
          next({ type: actionTypes.GET_REQUEST_CONTACT_LOADING });
          const response = await request.getRequestContact({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_REQUEST_CONTACT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_REQUEST_CONTACT_ERROR,
            error,
          });
        }
        break;
      case actionTypes.GET_ALL_CONTACT_TRANSPORTERS:
        try {
          next({ type: actionTypes.GET_ALL_CONTACT_TRANSPORTERS_LOADING });
          const response = await request.getAllContactTransporters({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_ALL_CONTACT_TRANSPORTERS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_CONTACT_TRANSPORTERS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      default:
        next(action);
        break;
    }
  };
}
