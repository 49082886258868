import React, { FunctionComponent } from 'react';

import InputErrorLabel from 'components/core/InputErrorLabel/InputErrorLabel';
import {
  FileInputWrapper,
  ButtonStyled,
  ChosenFile,
  File,
  Mandatory,
} from './fileInputStyles';

type FileInputProps = {
  name: string;
  value?: File;
  error?: string;
  disabled?: true;
  label?: string;
  required?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getClickEvent: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FileInput: FunctionComponent<FileInputProps> = ({
  label,
  name,
  error,
  disabled,
  required,
  value,
  onChange,
  getClickEvent
}) => {
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    if(event){
      hiddenFileInput.current.click();
      getClickEvent(hiddenFileInput.current)
    }
  };

  return (
    <>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && (<Mandatory> *</Mandatory>)}
        </label>
      )}
      <FileInputWrapper onClick={handleClick} error={error}>
        <ButtonStyled type='button'>Choose a file</ButtonStyled>
        <ChosenFile>{value?.name ?? 'no file selected'}</ChosenFile>
        <File
          disabled={disabled}
          name={name}
          id={name}
          type='file'
          ref={hiddenFileInput}
          onChange={onChange}
        />
      </FileInputWrapper>
      {error && <InputErrorLabel message={error} />}
    </>
  );
};

export default FileInput;
