let actionTypes = {
  CREATE_SETTING: "MY_ASD.SETTINGS.INTRASTAT.CREATE_SETTING",
  CREATE_SETTING_LOADING: "MY_ASD.SETTINGS.INTRASTAT.CREATE_SETTING_LOADING",
  CREATE_SETTING_SUCCESS: "MY_ASD.SETTINGS.INTRASTAT.CREATE_SETTING_SUCCESS",
  CREATE_SETTING_ERROR: "MY_ASD.SETTINGS.INTRASTAT.CREATE_SETTING_ERROR",

  GET_SETTING: "MY_ASD.SETTINGS.INTRASTAT.GET_SETTING",
  GET_SETTING_LOADING: "MY_ASD.SETTINGS.INTRASTAT.GET_SETTING_LOADING",
  GET_SETTING_SUCCESS: "MY_ASD.SETTINGS.INTRASTAT.GET_SETTING_SUCCESS",
  GET_SETTING_ERROR: "MY_ASD.SETTINGS.INTRASTAT.GET_SETTING_ERROR",

  UPDATE_SETTING: "MY_ASD.SETTINGS.INTRASTAT.UPDATE_SETTING",
  UPDATE_SETTING_LOADING: "MY_ASD.SETTINGS.INTRASTAT.UPDATE_SETTING_LOADING",
  UPDATE_SETTING_SUCCESS: "MY_ASD.SETTINGS.INTRASTAT.UPDATE_SETTING_SUCCESS",
  UPDATE_SETTING_ERROR: "MY_ASD.SETTINGS.INTRASTAT.UPDATE_SETTING_ERROR",

  DELETE_SETTING: "MY_ASD.SETTINGS.INTRASTAT.DELETE_SETTING",
  DELETE_SETTING_LOADING: "MY_ASD.SETTINGS.INTRASTAT.DELETE_SETTING_LOADING",
  DELETE_SETTING_SUCCESS: "MY_ASD.SETTINGS.INTRASTAT.DELETE_SETTING_SUCCESS",
  DELETE_SETTING_ERROR: "MY_ASD.SETTINGS.INTRASTAT.DELETE_SETTING_ERROR",

  GET_ASSIGNED_COUNTRIES: "MY_ASD.SETTINGS.INTRASTAT.GET_ASSIGNED_COUNTRIES",
  GET_ASSIGNED_COUNTRIES_LOADING: "MY_ASD.SETTINGS.INTRASTAT.GET_ASSIGNED_COUNTRIES_LOADING",
  GET_ASSIGNED_COUNTRIES_SUCCESS: "MY_ASD.SETTINGS.INTRASTAT.GET_ASSIGNED_COUNTRIES_SUCCESS",
  GET_ASSIGNED_COUNTRIES_ERROR: "MY_ASD.SETTINGS.INTRASTAT.GET_ASSIGNED_COUNTRIES_ERROR",

  UPDATE_ASSIGNED_COUNTRIES: "MY_ASD.SETTINGS.INTRASTAT.UPDATE_ASSIGNED_COUNTRIES",
  UPDATE_ASSIGNED_COUNTRIES_LOADING: "MY_ASD.SETTINGS.INTRASTAT.UPDATE_ASSIGNED_COUNTRIES_LOADING",
  UPDATE_ASSIGNED_COUNTRIES_SUCCESS: "MY_ASD.SETTINGS.INTRASTAT.UPDATE_ASSIGNED_COUNTRIES_SUCCESS",
  UPDATE_ASSIGNED_COUNTRIES_ERROR: "MY_ASD.SETTINGS.INTRASTAT.UPDATE_ASSIGNED_COUNTRIES_ERROR",

  RESET: "MY_ASD.SETTINGS.INTRASTAT.RES",
  REFRESH_ERROR: "MY_ASD.SETTINGS.INTRASTAT.REFRESH_ERROR"
};

export default actionTypes;
