import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

export const Wrapper = styled.div(({ margin }: any) => ({
  margin: "0",
  display: "inline-block"
}));

export const Field = styled(Form.Field)`
  &&& {
    margin: 0;
    display: inline-block;
  }
`;

export const VertSep = styled.div(() => ({
  float: 'left',
  height: '18px',
  margin: '10px',
  background: 'rgba(34,36,38,.15)',
  width: '2px',
}))

