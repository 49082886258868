import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Operator from 'views/Fiscal/QuickFixes/Operator';
import Presentation from 'views/Fiscal/QuickFixes/Visitor';
import Seller from 'views/Fiscal/QuickFixes/Seller';
import ServicePaused from 'components/ServicePaused';
import { selectors, actions } from 'store';
import Loader from 'components/Loader';

const Index = ({
  user,
  dispatch,
  history,
  companySelected,
  hasSubscribedTo,
}: any) => {
  useEffect(() => {
    if (companySelected) {
      dispatch(
        actions.company.hasSubscribedTo({
          params: {
            companyId: companySelected,
            serviceName: 'kyanite',
          },
        })
      );
    } else {
      dispatch(actions.company.reset(['hasSubscribedTo', 'error']));
    }

    return () => {
      dispatch(actions.company.reset(['hasSubscribedTo', 'error']));
    };
  }, [dispatch, companySelected]);

  return (
    ((user.admin || user.operator) && (
      <Operator dispatch={dispatch} history={history} />
    )) ||
    ((companySelected === null || hasSubscribedTo === false) && (
      <Presentation history={history} />
    )) ||
    (hasSubscribedTo && hasSubscribedTo.pause && (
      <ServicePaused history={history} companySelected={companySelected} />
    )) ||
    (companySelected && hasSubscribedTo && !hasSubscribedTo.pause && (
      <Seller history={history}/>
    )) || <Loader />
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    hasSubscribedTo: selectors.company.hasSubscribedToSelector(state),
    hasSubscribedToLoading: selectors.company.hasSubscribedToLoadingSelector(
      state
    ),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
