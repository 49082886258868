import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Divider, TextArea } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import { operatorSchema } from 'interfaces/formSchema';
import { SpecificsH4 } from 'views/Intrastat/IntrastatDetails/styles';

const Index = ({
  intl,
  dispatch,
  companyId,
  settings,
  notes,
  intrastatId,
  setSpecificEdit,
  settingsLoading,
}) => {

  const save = (values) => {
    dispatch(
        actions.companyIntrastatSettings.handleSettings({
          params: { 
            companyId: companyId 
          },
          data: {
            action: 'create',
            notes: values.notes,
            intrastatId: intrastatId
        }})
      );
      setSpecificEdit(false)
  };

  const deleteNote = () => {
    dispatch(
        actions.companyIntrastatSettings.handleSettings({
          params: { 
            companyId: companyId 
          },
          data: {
            action: 'delete',
            settings: true,
            intrastatId: intrastatId
        }})
      );
      setSpecificEdit(false)
  };

  return (
    <Formik
        enableReinitialize={true}
        initialValues={{
            notes: notes || '', 
        }}
        onSubmit={save}
        validationSchema={operatorSchema}
    >
    {({
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        values,
        isValid,
    }) => (
        <>
        <SpecificsH4>{intl.formatMessage({id: `intrastat.specifications`})}</SpecificsH4>
        <Form onSubmit={handleSubmit}>
                            
            <Wrapper>                         
                <Field  
                label='Notes'            
                placeholder={intl.formatMessage({id: `intrastat.specifications.placholder`})}
                defaultValue={values.notes}
                name='notes'
                component={TextArea} 
                style={{ 
                    minHeight: 100, 
                    overflow: 'auto',   
                    boxSizing: 'border-box',
                    resize: 'none',                        
                }}       
                meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                }}
                onChange={(e, {value}) => {
                    e && setFieldValue('notes', value)
                }}
                />
                <Divider hidden />
                <Wrapper 
                    display='flex'
                    justifyContent='center'> 
                <Button type='submit' content={intl.formatMessage({id: `button.submit`})}/>
                <Button 
                    color='red'
                    icon='delete'
                    content={intl.formatMessage({id: `intrastat.Delete`})}
                    onClick={() => deleteNote()}
                />
                <Button 
                    color='red'
                    content={intl.formatMessage({id: `intrastat.note.close`})}
                    onClick={() => setSpecificEdit(false)}
                />
                </Wrapper>
            </Wrapper> 
            </Form>
        </>
        )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    generalPermissions: selectors.admin.permissionsSelector(state),
    settingsLoading: selectors.companyIntrastatSettings.getCompanySettingsLoadingSelector(state),
    settings: selectors.companyIntrastatSettings.companyIntrastatSettingsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
