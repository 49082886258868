import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Formiz, useForm } from '@formiz/core';
import { Modal, Grid, Divider, Header } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import FormizFileInput from 'components/FormizField/FormizFileInput/FormizFileInput';
import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';


const Index = ({
  dispatch,
  companySelected,
  user,
  match,
  open,
  handleClose,
}) => {

  const uploadForm = useForm();

  const userAdminOrOp = user.operator || user.admin;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected;


  const save = (values) => {
    dispatch(
      actions.customsDeclaration.addMandate({
        params: { companyId: companyId },
        data: {
          title: values.title,
          file: values.file,
        },
      })
    );
    handleClose();
  }

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
    <Header icon='plus circle' content='Upload documents' />
    <Formiz connect={uploadForm} onValidSubmit={save}>
    <Modal.Content>
    <form onSubmit={uploadForm.submit}>
      <Grid>
          <Grid.Row>
            <Grid.Column width='5'>
            <FormizSimpleInput
                type='text'
                name='title'
                label='Reference'
                placeholder='document ref...'
                required='Title is required'
              />
          </Grid.Column>
          <Grid.Column width='11'>
              <FormizFileInput
                name='file'
                label="File"  
                acceptedFile={['application/pdf']}
                required='File is required'
                mandates={true}
              />
          </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Button type='submit' content='Submit' />
        <Divider hidden />
        </form>
    </Modal.Content>
    </Formiz>
    </Modal>
 );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
