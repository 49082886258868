import actionTypes from 'store/company/configurations/actionTypes';
import routes from './routes';
import { actions as notificationHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {

      case actionTypes.GET_THRESHOLD:
        try {
          next({ type: actionTypes.GET_THRESHOLD });
          const response = await request.getThreshold({...action.payload});
          next({
            type: actionTypes.GET_THRESHOLD_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_THRESHOLD_ERROR,
            error,
          });
          store.dispatch(
            notificationHandler.errorMessage(error.status, error.message)
          );
        }
        break;

        case actionTypes.THRESHOLD_CREATE:
          try {
            next({ type: actionTypes.THRESHOLD_CREATE });
            await request.createThreshold({
              ...action.payload,
            });
            next({
              type: actionTypes.THRESHOLD_CREATE_SUCCESS,
            });
            store.dispatch(
              notificationHandler.successMessage("")
            )
          } catch (error) {
            next({
              type: actionTypes.THRESHOLD_CREATE_ERROR,
              error,
            });
            store.dispatch(
              notificationHandler.errorMessage(error.status, error.message)
            );
          }
          break;

          case actionTypes.THRESHOLD_UPDATE:
          try {
            next({ type: actionTypes.THRESHOLD_UPDATE });
            await request.updateThreshold({
              ...action.payload,
            });
            next({
              type: actionTypes.THRESHOLD_UPDATE_SUCCESS,
            });
            store.dispatch(
              notificationHandler.successMessage("")
            )
          } catch (error) {
            next({
              type: actionTypes.THRESHOLD_UPDATE_ERROR,
              error,
            });
            store.dispatch(
              notificationHandler.errorMessage(error.status, error.message)
            );
          }
          break;

      case actionTypes.THRESHOLD_DELETE:
        try {
          next({ type: actionTypes.THRESHOLD_DELETE });
          await request.deleteThreshold({
            ...action.payload,
          });
          next({
            type: actionTypes.THRESHOLD_DELETE_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.THRESHOLD_DELETE_ERROR,
            error,
          });
          store.dispatch(
            notificationHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
