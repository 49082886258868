import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { apiMiddlewares } from './middlewares';
import rootReducers from './rootReducers';

const composeEnhancers = composeWithDevTools;

export default (defaultState, services) => {
  const api = services.get('api');
  const storage = services.get('storage');

  const persistConfig = {
    key: 'MY_ASD',
    storage,
    blacklist: ["customsStatistics"]
  };


  const persistedReducer = persistReducer(persistConfig, rootReducers);

  const store = createStore(
    persistedReducer,
    defaultState,
    composeEnhancers(applyMiddleware(thunk, ...apiMiddlewares({ api })))
  );

  const persistor = persistStore(store);

  return { store, persistor };
};
