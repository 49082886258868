import React, { useEffect, useState } from "react";

const Index = ({
  name,
  value,
  defaultIndeterminate,
  checked,
  onChange,
}: any) => {
    const [t, setT]:any = useState(null)

useEffect(() => {
    setT(defaultIndeterminate)
}, [defaultIndeterminate])

  return (
    <input
        type='checkbox'
        value={value}
        name={name}
        checked={t}
        onChange={() => onChange(checked)}
      />
  );
};

export default Index;
