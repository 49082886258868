import Declaration from "views/Fiscal/Declaration";
import Details from "views/Fiscal/Declaration/Details";
import Statement from "views/Fiscal/Declaration/Statement";
import paths from "./paths";

export default [
  {
    private: true,
    path: paths.declarations,
    component: Declaration,
    exact: true,
    authorizedRoles: ["operator"]
  },
  {
    private: true,
    path: paths.declarationStatement,
    component: Statement,
    exact: true,
    authorizedRoles: ["operator"]
  },
  {
    private: true,
    path: paths.declarationDetails,
    component: Details,
    exact: true,
    authorizedRoles: ["operator"]
  }
];


