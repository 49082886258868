export default {
  CREATE_SUBSCRIPTION_PAYMENT: "MY_ASD.PAYMENT.CREATE_SUBSCRIPTION_PAYMENT",
  CREATE_SUBSCRIPTION_PAYMENT_LOADING:
    "MY_ASD.PAYMENT.CREATE_SUBSCRIPTION_PAYMENT_LOADING",
  CREATE_SUBSCRIPTION_PAYMENT_SUCCESS:
    "MY_ASD.PAYMENT.CREATE_SUBSCRIPTION_PAYMENT_SUCCESS",
  CREATE_SUBSCRIPTION_PAYMENT_ERROR:
    "MY_ASD.PAYMENT.CREATE_SUBSCRIPTION_PAYMENT_ERROR",

  CREATE_TOKEN: "MY_ASD.PAYMENT.CREATE_CREATE_TOKEN",
  CREATE_TOKEN_LOADING: "MY_ASD.PAYMENT.CREATE_CREATE_TOKEN_LOADING",
  CREATE_TOKEN_SUCCESS: "MY_ASD.PAYMENT.CREATE_CREATE_TOKEN_SUCCESS",
  CREATE_TOKEN_ERROR: "MY_ASD.PAYMENT.CREATE_CREATE_TOKEN_ERROR",

  UPDATE_TOKEN: "MY_ASD.PAYMENT.UPDATE_TOKEN",
  UPDATE_TOKEN_LOADING: "MY_ASD.PAYMENT.UPDATE_TOKEN_LOADING",
  UPDATE_TOKEN_SUCCESS: "MY_ASD.PAYMENT.UPDATE_TOKEN_SUCCESS",
  UPDATE_TOKEN_ERROR: "MY_ASD.PAYMENT.UPDATE_TOKEN_ERROR",

  UNSUBSCRIBE: "MY_ASD.PAYMENT.UNSUBSCRIBE",
  UNSUBSCRIBE_LOADING: "MY_ASD.PAYMENT.UNSUBSCRIBE_LOADING",
  UNSUBSCRIBE_SUCCESS: "MY_ASD.PAYMENT.UNSUBSCRIBE_SUCCESS",
  UNSUBSCRIBE_ERROR: "MY_ASD.PAYMENT.UNSUBSCRIBE_ERROR",

  CANCEL_UNSUBSCRIBE: "MY_ASD.PAYMENT.CANCEL_UNSUBSCRIBE",
  CANCEL_UNSUBSCRIBE_LOADING: "MY_ASD.PAYMENT.CANCEL_UNSUBSCRIBE_LOADING",
  CANCEL_UNSUBSCRIBE_SUCCESS: "MY_ASD.PAYMENT.CANCEL_UNSUBSCRIBE_SUCCESS",
  CANCEL_UNSUBSCRIBE_ERROR: "MY_ASD.PAYMENT.CANCEL_UNSUBSCRIBE_ERROR",

  RESET: "MY_ASD.PAYMENT.RESET",
};
