import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Form,
  Tab,
  Container,
  Divider,
  Table
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';

import { Input } from 'components/Form';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { specificQuotesSchema } from 'interfaces/formSchema';
import { Separation } from 'components/Layouts/Notification/components/NotificationPlaceholder/notificationPlaceholderStyles';
import { 
  Description, 
  Disappear, 
  HeaderTitle, 
  LinkToClerks, 
  LinkToCustoms, 
  LinkWrapper } from './pricingStyles';

const Index = ({
  company,
  dispatch,
  pricing,
  billing,
  billingLoading,
  pricingLoading,
}) => {

  const [defaultListStatus, setDefaultListStatus]: any = useState(false);
  const [billingList, setBillingList]: any = useState([]);
  const [customsBillingList, setCustomsBillingList]: any = useState([]);
  const [openCustomsPricing, setOpenCustomsPricing]: any = useState(false)
  const [openClerkssPricing, setOpenClerksPricing]: any = useState(true);
  const formikRef = useRef()

  useEffect(() => {
    dispatch(actions.customsClerk.getBillings())
    dispatch(actions.companyClerksPricing.getOneClerksPricing({
      params: { companyId: company.id },
    }))

    dispatch(actions.socket.subscribeUpdateCompanyClerkPricing(company.id))
    dispatch(actions.socket.subscribeGetBilling(company.id))

    return () => {
      dispatch(actions.customsClerk.reset(['billing', 'error']))
      dispatch(actions.companyClerksPricing.reset(['pricing', 'error']))

      dispatch(actions.socket.unsubscribeUpdateCompanyClerkPricing(company.id))
      dispatch(actions.socket.unsubscribeGetBilling(company.id))
    };
  }, [dispatch, company]);


  useEffect(() => {
   if(pricing && pricing.customized.length>0){
      let clerksTabs: any = []
      let customsTabs: any = []
      pricing.customized.forEach((e, i) => {
        if(!e.inCustoms){
          clerksTabs.push(e)
        }else{
          customsTabs.push(e)
        }
      })
      clerksTabs.length>0 && setBillingList(clerksTabs)
      customsTabs.length>0 && setCustomsBillingList(customsTabs)
    }

  }, [pricing])

 
  const savePrice = (values) => {
    let document: any = []
    let n = values.pricing.length 
    for(let index=1; index<n; index+=1){
      if(values.pricing[index] !== undefined){
        document.push({
          Company_Id: company.id,
          CustomsClerkBilling_Id: values.id[index],
          pricing: values.pricing[index],
        })
      }
    }
    dispatch(
      actions.companyClerksPricing.createClerksPricing({
        data: document,
        params: { companyId: company.id }
    }))
    setDefaultListStatus(0)
  }

  const resetPrice = () => {
    const ref: any = formikRef.current 
        dispatch(
          actions.companyClerksPricing.deleteClerksPricing({
            data: document,
            params: { companyId: company.id },
        }))
    ref.resetForm() 
    setDefaultListStatus(true)
  }

  const updatePrice = (values) => {
    let document: any = []

    if(defaultListStatus && openClerkssPricing && billingList.length>0 && values === undefined){
      let n = openClerkssPricing ? billingList.length : customsBillingList.length
      for(let index=0; index<n; index+=1){
          document.push({
            Company_Id: company.id,
            CustomsClerkBilling_Id: (openClerkssPricing && billingList[index].id) ? billingList[index].id : customsBillingList[index].id,
            pricing: (openClerkssPricing && billingList[index].amount) ? billingList[index].amount : customsBillingList[index].amount,
          })
      }
    }else{
      let n = values.pricing.length 
      for(let index=0; index<n; index+=1){
        if(values.pricing[index+1] !== undefined && values.pricing[index+1] !== null){
          document.push({
            Company_Id: company.id,
            CustomsClerkBilling_Id: (openClerkssPricing && billingList[index].id) ? billingList[index].id : customsBillingList[index].id,
            pricing: values.pricing[index+1],

          })
        }
      }
    }
    dispatch(
      actions.companyClerksPricing.updateClerksPricing({
        data: document,
        params: { companyId: company.id },
    }))
    setDefaultListStatus(0) 
   
  }


  return (
    <Tab.Pane loading={billingList.length<30 || pricingLoading || billingLoading}>
      <Container>  
      <Wrapper
        display='flex'
        justifyContent='center'
        padding='0 10rem 5rem'
      >
      <Wrapper display='inline-flex' width='100%'>                      
        {billingList.length>0 && (
          <Formik
            ref={formikRef}
            enableReinitialize={true}
            initialValues={{
              documents: [],
              id: ((openClerkssPricing && billingList.id) ? billingList.id : customsBillingList.id) || '',
              category: ((openClerkssPricing && billingList.category) ? billingList.category : customsBillingList.category) || '',
              description: ((openClerkssPricing && billingList.description) ? billingList.description : customsBillingList.description) || '',
              amount: ((openClerkssPricing && billingList.amount) ? billingList.amount : customsBillingList.amount) || '',
              pricing: ((openClerkssPricing && billingList.pricing) ? billingList.pricing : customsBillingList.pricing) || '',
            }}
            onSubmit={(pricing.pricings.length===0) ? savePrice : updatePrice}
            validationSchema={specificQuotesSchema}
            >
            {({
              errors,
              touched,
              handleSubmit,
              resetForm,
              values,
              setFieldValue,
              setFieldTouched,
            }: any) => (
              openClerkssPricing ? 
              <Form onSubmit={handleSubmit}>
                <HeaderTitle>
                <LinkWrapper>
                  <LinkToClerks onClick={() => {
                        setOpenCustomsPricing(false)
                        setOpenClerksPricing(true)
                  }} color={openClerkssPricing}>Clerks pricing</LinkToClerks>
                  <LinkToCustoms onClick={() => {
                      setOpenCustomsPricing(true)
                      setOpenClerksPricing(false)
                      resetForm()
                  }} color={openCustomsPricing}>Customs pricing</LinkToCustoms> 
                <Separation />
                {(billingList.length>0 ) && (
                  <Button
                    content={pricingLoading ? `Updating...` : `Save`}
                    icon='save'
                    type='submit'
                    labelPosition='right'
                    margin='5px'
                  /> 
                )}
                </LinkWrapper>                
                </HeaderTitle>
                <Wrapper padding='0 5rem 2rem'>                          
                  <Table
                    size='small'
                    attached='top'
                    columns='3'
                  >
                    <Table.Body>  
                      {billingList.map((e, index) => (                               
                        <Table.Row key={index}>
                          <Table.Cell key={0} width={12}> 
                          <Disappear>
                          <Form.Field>
                            <Field
                              name={`id[${index}]`}
                              value={index+1}
                              type='hidden'
                              meta={{ errors, touched }}     
                            />
                          </Form.Field>
                          </Disappear>
                            <Description>
                              {e.category ? e.category  : e.CustomsClerkBilling.category}<br></br>
                              {e.category ? e.description : e.CustomsClerkBilling.description}
                            </Description>
                          </Table.Cell>
                          <Table.Cell key={1}> 
                            {e.pricing ? e.pricing : e.amount } Euros
                          </Table.Cell>
                          <Table.Cell key={2}> 
                          <Form.Field>
                            <Field
                              placeholder={`${e.pricing ? e.pricing : e.amount !== null ? e.amount : 'type amount' }`}
                              name={`pricing[${index+1}]`}
                              type='number'
                              value={e.pricing ? e.pricing : e.amount }
                              component={Input}
                              meta={{ errors, touched }}
                              onChange={(values) => {
                                if(values){
                                  setFieldValue(`id[${index+1}]`, e.id)
                                  setFieldValue(`description[${index+1}]`, e.description)
                                  setFieldValue(`amount[${index+1}]`, e.amount)
                                  setFieldValue(`pricing[${index+1}]`, values)
                                  setFieldValue(`reference[${index+1}]`, e.reference)
                                }
                              }}     
                            />
                          </Form.Field>
                          </Table.Cell>
                        </Table.Row>                      
                      ))
                    }             
                  </Table.Body>
                  </Table>
                  <Divider hidden/>
                  {(billingList.length>0 ) && (
                    <Button
                      content={pricingLoading ? `Updating...` : `Save`}
                      icon='save'
                      type='submit'
                      labelPosition='right'
                      margin='5px'
                    /> 
                  )}
                    <Button
                      content='Reset'
                      icon='eye'
                      type='button'
                      color='red'
                      labelPosition='right'
                      margin='5px'
                      onClick={() => {
                        resetPrice()
                      }}
                    />                                                                                                                              
              </Wrapper>
              </Form> :
               <Form onSubmit={handleSubmit}>
               <HeaderTitle>
                <LinkWrapper>
                  <LinkToClerks onClick={() => {
                    setOpenCustomsPricing(false)
                    setOpenClerksPricing(true)
                    resetForm()
                  }} color={openClerkssPricing}>Clerks pricing</LinkToClerks>
                  <LinkToCustoms onClick={() => {
                    setOpenCustomsPricing(true)
                    setOpenClerksPricing(false)
              
                  }} color={openCustomsPricing}>Customs pricing</LinkToCustoms> 
                  <Separation />
                  {(billingList.length>0 ) && (
                    <Button
                      content={pricingLoading ? `Updating...` : `Save`}
                      icon='save'
                      type='submit'
                      labelPosition='right'
                      margin='5px'
                    /> 
                  )}
                </LinkWrapper>     
               </HeaderTitle>
               <Wrapper padding='0 5rem 2rem'>                        
                 <Table
                   size='small'
                   attached='top'
                   columns='3'
                 >
                   <Table.Body>  
                     {customsBillingList.map((e, index) => (                               
                       <Table.Row key={index}>
                         <Table.Cell key={0} width={12}> 
                         <Disappear>
                         <Form.Field>
                           <Field
                             name={`id[${index}]`}
                             value={index+1}
                             type='hidden'
                             meta={{ errors, touched }}     
                           />
                         </Form.Field>
                         </Disappear>
                           <Description>
                             {e.category ? e.category  : e.CustomsClerkBilling.category}<br></br>
                             {e.category ? e.description : e.CustomsClerkBilling.description}
                           </Description>
                         </Table.Cell>
                         <Table.Cell key={1}> 
                           {e.pricing ? e.pricing : e.amount } Euros
                         </Table.Cell>
                         <Table.Cell key={2}> 
                         <Form.Field>
                           <Field
                             placeholder={`${e.pricing ? e.pricing : e.amount !== null ? e.amount : 'type amount' }`}
                             name={`pricing[${index+1}]`}
                             type='number'
                             value={e.pricing ? e.pricing : e.amount }
                             component={Input}
                             meta={{ errors, touched }}
                             onChange={(values) => {
                               if(values){
                                 setFieldValue(`id[${index+1}]`, e.id)
                                 setFieldValue(`description[${index+1}]`, e.description)
                                 setFieldValue(`amount[${index+1}]`, e.amount)
                                 setFieldValue(`pricing[${index+1}]`, values)
                                 setFieldValue(`reference[${index+1}]`, e.reference)
                               }
                             }}     
                           />
                         </Form.Field>
                         </Table.Cell>
                       </Table.Row>                    
                    ))
                  }            
                 </Table.Body>
                 </Table>
                 <Divider hidden/>
                 {(customsBillingList.length>0 ) && (
                   <Button
                     content={pricingLoading ? `Updating...` : `Save`}
                     icon='save'
                     type='submit'
                     labelPosition='right'
                     margin='5px'
                   /> 
                 )}
                   <Button
                     content='Reset'
                     icon='eye'
                     type='button'
                     color='red'
                     labelPosition='right'
                     margin='5px'
                     onClick={() => {
                       resetPrice()
                     }}
                   />                                                                                                                 
            
             </Wrapper>
             </Form>
            )}
          </Formik>         
        )}
        </Wrapper></Wrapper>
      </Container>
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return {
    billing: selectors.customsClerk.billingsSelector(state),
    billingLoading: selectors.customsClerk.billingsLoadingSelector(state),
    pricing: selectors.companyClerksPricing.getOneClerksPricingSelector(state),
    pricingLoading: selectors.companyClerksPricing.loadingSelector(state)
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
