import styled from "@emotion/styled";
import { Dropdown } from "semantic-ui-react";

export const CollectionboxDropdown = styled(Dropdown)(({ props }: any) => ({
    borderRadius: "10px !important",
    border: "1px solid rgba(34,36,38,.15)",
    marginLeft: "10px",
}))

export const Right = styled.div`
    width: '80%';
    float: left;
    display: flex;
    align-items: center;
    `
