import React from 'react';
import { Grid, Header, Divider, Tab } from 'semantic-ui-react';
import ProofsDecisions from 'components/QuickFixes/DeliveryForm/ProofsDecisions';

const Index = ({ transporterId, proofs, token }: any) => {
  return (
    <>
      <Header textAlign='center'>Upload Delivery Proofs</Header>
      <Divider hidden />
      <Tab
        panes={[
          {
            menuItem: 'My Proofs',
            render: () => (
              <Tab.Pane>
                <Grid columns='2' divided>
                  <Grid.Column>
                    <Header textAlign='center' size='small'>
                      Level 1 proofs
                    </Header>
                    <ProofsDecisions
                      key={5}
                      receivedData={proofs.filter(
                        (proof) =>
                          proof.level === 1 &&
                          proof.Company_Id === transporterId
                      )}
                      proofsLevel={1}
                      disabledAction
                      token={token}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Header textAlign='center' size='small'>
                      Level 2 proofs
                    </Header>
                    <ProofsDecisions
                      key={6}
                      receivedData={proofs.filter(
                        (proof) =>
                          proof.level === 2 &&
                          proof.Company_Id === transporterId
                      )}
                      proofsLevel={2}
                      disabledAction
                      token={token}
                    />
                  </Grid.Column>
                </Grid>
              </Tab.Pane>
            ),
          },
        ]}
      />
    </>
  );
};

export default Index;
