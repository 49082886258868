import { selectors } from 'store/common';

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const getCFErrorSelector = (state) => state.fiscalOss.errors.getCF;
const getIntegrationsErrorSelector = (state) =>
  state.fiscalOss.errors.getIntegrations;

const errorsSelector = createErrorsSelector([
  getCFErrorSelector,
  getIntegrationsErrorSelector,
]);
const companySubscribedErrorSelector = (state) => state.fiscalOss.error.companySubscribed;

// Loading
const getCFLoadingSelector = (state) => state.fiscalOss.loading.getCF;
const getIntegrationsLoadingSelector = (state) =>
  state.fiscalOss.loading.getIntegrations;

const loadingSelector = createLoadingSelector([
  getCFLoadingSelector,
  getIntegrationsLoadingSelector,
]);
const companySubscribedLoadingSelector = (state) => state.fiscalOss.loading.companySubscribed;

// Success
const getCFSuccessSelector = (state) => state.fiscalOss.success.getCF;
const getIntegrationsSuccessSelector = (state) =>
  state.fiscalOss.success.getIntegrations;

const successSelector = createSuccessSelector([
  getCFSuccessSelector,
  getIntegrationsSuccessSelector,
]);
const companySubscribedSuccessSelector = (state) => state.fiscalOss.success.companySubscribed;

const cfSelector = (state) => state.fiscalOss.cf;
const cfSelectorError = (state) => state.fiscalOss.error.cf;
const errorSelector = (state) => state.fiscalOss.error;
const cfLoadingSelector = (state) => state.fiscalOss.getCFLoadingSelector;
const integrationsSelector = (state) => state.fiscalOss.integrations;
const companySubscribedSelector = (state) => state.fiscalOss.companySubscribed;

export default {
  errorsSelector,
  successSelector,
  loadingSelector,
  cfSelector,
  cfSelectorError,
  errorSelector,
  cfLoadingSelector,
  companySubscribedSelector,
  integrationsSelector,
  companySubscribedSuccessSelector,
  companySubscribedErrorSelector,
  companySubscribedLoadingSelector,
};
