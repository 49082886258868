import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  lock(data: dataReqI) {
    return {
      type: actionTypes.LOCK,
      payload: data,
    };
  },
  unlock(data: dataReqI) {
    return {
      type: actionTypes.UNLOCK,
      payload: data,
    };
  },
  getIssueTimes(data: dataReqI) {
    return {
      type: actionTypes.GET_ISSUE_TIMES,
      payload: data,
    };
  },
  addIssueTime(data: dataReqI) {
    return {
      type: actionTypes.ADD_ISSUE_TIME,
      payload: data,
    };
  },
  updateIssueTime(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_ISSUE_TIME,
      payload: data,
    };
  },
  removeIssueTime(data: dataReqI) {
    return {
      type: actionTypes.DELETE_ISSUE_TIME,
      payload: data,
    };
  },
  updateFile(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_FILE,
      payload: data,
    };
  },
  getOneFile(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE_FILE,
      payload: data,
    };
  },
  getStartSignature(data: dataReqI) {
    return {
      type: actionTypes.GET_START_SIGNATURE,
      payload: data,
    };
  },
  getTrailerLicensePlate(data: dataReqI) {
    return {
      type: actionTypes.GET_TRAILER_LICENSE_PLATE,
      payload: data,
    };
  },
  addTrailerLicensePlate(data: dataReqI) {
    return {
      type: actionTypes.ADD_TRAILER_LICENSE_PLATE,
      payload: data,
    };
  },
  removeTrailerLicensePlate(data: dataReqI) {
    return {
      type: actionTypes.DELETE_TRAILER_LICENSE_PLATE,
      payload: data,
    };
  },
  getPaymentReceipt(data: dataReqI) {
    return {
      type: actionTypes.GET_PAYMENT_RECEIPT,
      payload: data,
    };
  },
  getEndSignature(data: dataReqI) {
    return {
      type: actionTypes.GET_END_SIGNATURE,
      payload: data,
    };
  },
  getDeclarationInProgress(data: dataReqI) {
    return {
      type: actionTypes.GET_DECLARATION_IN_PROGRESS,
      payload: data,
    };
  },
  createFile(data: dataReqI) {
    return {
      type: actionTypes.CREATE_FILE,
      payload: data,
    };
  },
  getIssue(data: dataReqI) {
    return {
      type: actionTypes.GET_ISSUE,
      payload: data,
    };
  },
  downloadPicturesIssuesZIP(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_PICTURES_ISSUES_ZIP,
      payload: data,
    };
  },
  getSummary(data: dataReqI) {
    return {
      type: actionTypes.GET_SUMMARY,
      payload: data,
    };
  },
  getAllFiles(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_FILES,
      payload: data,
    };
  },
  getAllFilesByFilters(data: dataReqI) {
    return {
      type: actionTypes.GET_ALL_FILES_BY_FILTERS,
      payload: data,
    };
  },
  updateIssue(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_ISSUE,
      payload: data,
    };
  },
  addIssuePictures(data: dataReqI) {
    return {
      type: actionTypes.ADD_ISSUE_PICTURES,
      payload: data,
    };
  },
  deleteIssuePicture(data: dataReqI) {
    return {
      type: actionTypes.DELETE_ISSUE_PICTURE,
      payload: data,
    };
  },
  getBillings() {
    return {
      type: actionTypes.GET_BILLINGS,
    };
  },
  getFileHasBillings(data: dataReqI) {
    return {
      type: actionTypes.GET_FILE_HAS_BILLINGS,
      payload: data,
    };
  },
  getCDHasBillings(data: dataReqI) {
    return {
      type: actionTypes.GET_CD_HAS_BILLINGS,
      payload: data,
    };
  },
  addInvoicingLine(data: dataReqI) {
    return {
      type: actionTypes.ADD_INVOICING_LINE,
      payload: data,
    };
  },
  updateInvoicingLine(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_INVOICING_LINE,
      payload: data,
    };
  },
  removeInvoicingLine(data: dataReqI) {
    return {
      type: actionTypes.REMOVE_INVOICING_LINE,
      payload: data,
    };
  },
  validateFile(data: dataReqI) {
    return {
      type: actionTypes.VALIDATE_FILE,
      payload: data,
    };
  },
  updateInvoicingStep(data: dataReqI) {
    return {
      type: actionTypes.UPDATE_INVOICING_STEP,
      payload: data,
    };
  },
  validateFileState(data: dataReqI) {
    return {
      type: actionTypes.VALIDATE_FILE_STATE,
      payload: data,
    };
  },
  createControlPDF(data: dataReqI) {
    return {
      type: actionTypes.CREATE_CONTROL_PDF,
      payload: data,
    };
  },
  getPdfFile(data: dataReqI) {
    return {
      type: actionTypes.DOWNLOAD_PDF,
      payload: data,
    };
  },
  createPochettePDF(data: dataReqI) {
    return {
      type: actionTypes.CREATE_POCHETTE_PDF,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
