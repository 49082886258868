import styled from "@emotion/styled";

export const Wrapper = styled.div(({ margin }: any) => ({
  margin: "0"
}));

export const BorderRadius = styled.div`
  &&& {
    margin: 0;
  }
  &&& div {
    // max-height: 2rem;
  }
  
  &&& div input {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
  }

  &&& div input:focus {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
  }

  &&&.disabled {
    opacity: 1;
  }
`;

