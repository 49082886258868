import actionTypes from 'store/company/vat/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.CREATE_VAT:
        try {
          next({ type: actionTypes.CREATE_VAT_LOADING });
          await request.createVat({ ...action.payload });
          next({
            type: actionTypes.CREATE_VAT_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_VAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ALL_VAT:
        try {
          next({ type: actionTypes.GET_ALL_VAT_LOADING });
          const response = await request.getAllVat({ ...action.payload });
          next({
            type: actionTypes.GET_ALL_VAT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_VAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_ONE_VAT:
        try {
          next({ type: actionTypes.GET_ONE_VAT_LOADING });
          const response = await request.getOneVat({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_ONE_VAT_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_VAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.DELETE_VAT:
        try {
          next({ type: actionTypes.DELETE_VAT_LOADING });
          await request.deleteVat({ ...action.payload });
          next({
            type: actionTypes.DELETE_VAT_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.DELETE_VAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.UPDATE_VAT:
        try {
          next({ type: actionTypes.UPDATE_VAT_LOADING });
          await request.updateVat({ ...action.payload });
          next({
            type: actionTypes.UPDATE_VAT_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_VAT_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
      default:
        next(action);
        break;
    }
  };
}
