import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import Chart from "react-apexcharts";

import {
  Divider,
  Grid,
  Header,
  Segment,
  Statistic as Stats,
  Tab
} from 'semantic-ui-react';

import ButtonBack from 'components/ButtonBack/ButtonBack';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import SearchFilters from './SearchFilters/searchFilters';
import Commercial from './Commercial';
import Comparisons from './Comparisons';
import LoaderInverted from 'components/LoaderInverted';

const Statistic = ({
  dispatch,
  match,
  history,
  user,
  cheds,
  customs,
  comparisonLoading,
  customsForComparison,
  allCustoms,
  clerkStats,
  loading
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = userAdminOrOp ? match.params.companyId : match.params.companyId; //companySelected 

  const [data, setData]: any = useState()
  const [countData, setCountData]: any = useState(null)
  const [apexChartsSeries, setApexChartsSeries]: any = useState(null)
  const [apexColumnsSeries, setApexColumnsSeries]: any = useState(null)
  const [apexChartsOptions, setApexChartsOptions]: any = useState(null)
  const [apexChartsCompanies, setApexChartsCompanies]: any = useState(null)
  const [apexChartsColumns, setApexChartsColumns]: any = useState(null)
  const [update, setUpdate]: any = useState(false)
  const [year, setYear]: any = useState(null)
  const [pie, setPie]: any = useState(null)
  const [clerks, setclerkStats]: any = useState(0)



  useEffect(() => {
    dispatch(actions.customsStatistics.reset(['customs', 'cheds', 'clerkStats', 'error']));
    dispatch(actions.company.getAll());
    dispatch(
      actions.customsStatistics.getComputedCheds({
        params: { globalStatistics: true }
      })
    );
    dispatch(actions.customsStatistics.getCustomsClerkStats({
      params: {
        companyId,
        globalStatistics: true
      }
    }));

    dispatch(
      actions.customsStatistics.getDeclarationsStatsWithFilters({
        data: {
          company: '',
          startDate: '',
          endDate: '',
          showAll: true,
          globalStatistics: true
        }
      })
    );

    return () => {
      dispatch(
        actions.customsStatistics.reset([
          'cheds',
          'allCustoms',
          'clerkStats',
          'error',
        ])
      );
    };
  }, [dispatch, companyId]);

  const clerksValidation = (data) => {
    let totalTime = 0
    if(data.length>0){
      data.forEach((e) => {
        totalTime += (new Date(e.updatedAt).getTime() - new Date(e.createdAt).getTime())
      })
    }
    return `${((totalTime / (1000 * 3600 * 24)) / data.length).toFixed(2)} days`
  }

  useEffect(() => {
    if(clerkStats){
      setclerkStats(clerkStats)
    }
   
    if(allCustoms){
      if(update){
        // START UPDATE
        let tab = []
        allCustoms.charts.series.forEach((serie) => {
          tab[0] = serie
        })
        allCustoms.columnOptions.series.forEach((serie) => {
          tab[1] = serie
        })
        setApexChartsSeries(tab[0])
        setApexColumnsSeries(tab[1])

        setApexChartsOptions(allCustoms.charts.options)
        setApexChartsColumns(allCustoms.columnOptions.options)
        // END UPDATE
        setUpdate(false)
      }
      setCountData(allCustoms.count)
      setYear(allCustoms.year)

      // customs 2022
      setApexChartsSeries(allCustoms.charts.series)
      setApexChartsOptions(allCustoms.charts.options)

      // Companies with customs
      setApexChartsCompanies(allCustoms.companies)
      // ched data
  
      if(allCustoms.chedData.length>0){
        setData(allCustoms.chedData)
      }
   
      // pie customs
      setPie(allCustoms.pie)

      // days week
      setApexColumnsSeries(allCustoms.columnOptions.series)
      setApexChartsColumns(allCustoms.columnOptions.options)

    }

  }, [cheds, user, allCustoms, clerkStats, apexChartsSeries, apexChartsOptions, apexChartsCompanies, update]);


  const chedsData = (data) => {
    if(data.length>0){
      let totalCheds = 0
      let cheds = []
      data.forEach((e) => {
        if(e.CustomsDeclarationCheds.length){
          totalCheds += e.CustomsDeclarationCheds.length
          cheds.push(e)
        }    
      })
      return {totalCheds, cheds} 
    }
  }

  const totalValidatedCheds = (data) => {
    let validatedCheds = 0
    if(data.length>0){
    chedsData(data).cheds.forEach((ched) => {
      ched.CustomsDeclarationCheds.forEach((e) => {
        if(e.validatedAt !== null){
          validatedCheds += 1;
        }
      })
    })
  }
    return validatedCheds
  }

  const chedAverageLines = (data) => {
    if(data.length>0){
      let totalLines = 0
      chedsData(data).cheds.forEach((ched) => {
        ched.CustomsDeclarationCheds.forEach((e) => {
          if(e.validatedAt !== null){
            totalLines += e.countLines
          }
        })
      })
      return  (totalLines / chedsData(data).totalCheds).toFixed(2) !== `NaN` ? 
              `${(totalLines / chedsData(data).totalCheds).toFixed(2)} lines` : 
              `-------`
    }
  }

  const chedAverageWeight = (data) => {
    if(data.length>0){
      let totalWeight = 0
      chedsData(data).cheds.forEach((ched) => {
        ched.CustomsDeclarationCheds.forEach((e) => {
          if(e.validatedAt !== null){
            totalWeight += e.weight
          }
        })
      })
      return ((totalWeight / chedsData(data).totalCheds)).toFixed(2) !== `NaN` ? 
              `${((totalWeight / chedsData(data).totalCheds) / 1000).toFixed(2)} tons` : `-------`
    }
  }

  const chedProcessingTime = (data) => {
    if(data.length>0){
      let totalTime = 0
      let validatedCheds = 0
      chedsData(data).cheds.forEach((ched) => {
        ched.CustomsDeclarationCheds.forEach((e) => {
          if(e.validatedAt !== null){
            validatedCheds += 1;
            totalTime += (new Date(e.validatedAt).getTime() - new Date(e.createdAt).getTime())
          }
        })
      })
      return ((totalTime / (1000 * 3600 * 24)) / validatedCheds).toFixed(0) !== `NaN` ? 
              `${((totalTime / (1000 * 3600 * 24)) / validatedCheds).toFixed(0)} days` : `-------`
    }
  }

  const totalClerks = (data) => {
    if(data.length>0){
      let trucks = 0
      let clercks = []
      data.forEach((e, i) => {
        trucks += i+1
          clercks.push(e)   
      })
      return {trucks, clercks} 
    }
  }

  const clerksBilling = (data) => {
    if(data.length>0){
      let totalSpendings = 0
      data.forEach((e) => {
        if(e.totalAmount){
          totalSpendings += e.totalAmount
        }    
      })
      return { 
        average : (totalSpendings / totalClerks(data).trucks).toFixed(2) !== `NaN` ? 
                  `${(totalSpendings / totalClerks(data).trucks).toFixed(2)} €` : `-------`, 
        total: `${totalSpendings.toFixed(2)} €`
      }
    }
  }


  const handleSubmitFilters = (dateFilters) => {
    setUpdate(true)
    dispatch(actions.customsStatistics.reset(['allCustoms', 'clerks', 'error']));
    dispatch(
      actions.customsStatistics.getDeclarationsStatsWithFilters({
        params: { companyId: companyId },
        data: dateFilters
      })
    );
   
}

  return (
    <Wrapper display='flex' justifyContent='center'>
    {(!apexChartsSeries && <Loader />) || (
      <Wrapper height='100%' width='80%' overflow='auto'>
      <Divider hidden />
      <Wrapper display='flex'>
        <ButtonBack history={history} />
      </Wrapper>
      <Divider hidden />
      <Header size='huge' textAlign='center'>Customs declaration statistics dashboard Global</Header>
      <Divider hidden />
              <Tab
                  panes={[
                    {
                      menuItem: 'Global charts',
                      render: () => (
                      <Tab.Pane  loading={loading}>
                        <Wrapper height='100%'>                   
                          <Wrapper
                            display='flex'
                            justifyContent='center'
                            height='100%'
                            width='100%'
                          >
                          <Wrapper>
                          <SearchFilters
                            apexChartsCompanies={apexChartsCompanies}
                            handleSubmitFilters={handleSubmitFilters}
                          /> 
                          <Divider hidden />
                          {(loading && <Loader />) || ( 
                            <>                                     
                        <Grid padded> 
                            <Grid.Row> 
                              <Grid.Column><h3>{countData} Customs Declarations {year && (`in ${year}`)}</h3></Grid.Column>
                            </Grid.Row>                        
                              <Grid.Row>                       
                                <Grid.Column width={4}>
                                <Segment piled>
                                    {(!pie && <Loader/>) || (
                                      <Chart options={pie.options} series={pie.series} type="pie" height={300} width='100%' />
                                    )}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column width={12}>
                                <Segment piled>
                                    {(update && <LoaderInverted />) ||  (
                                      <Chart options={apexChartsOptions} series={apexChartsSeries} type="area" height={500} width='100%' />
                                    )}
                                    </Segment>
                                </Grid.Column>
                              </Grid.Row>                         
                              <Grid.Row>                                    
                                <Grid.Column width={8}>
                                  <Segment piled>
                                    {(update && <LoaderInverted />) || (
                                      <Chart options={apexChartsColumns} series={apexColumnsSeries} type="bar" height={400} width='100%' />
                                    )}
                                  </Segment>
                                </Grid.Column>                                           
                                <Grid.Column width={8}>                             
                                  <Segment piled>
                                    {(update && !allCustoms.officeOptions && <LoaderInverted />) || (
                                      <Chart options={allCustoms.officeOptions.options} series={allCustoms.officeOptions.series} type="bar" height={400} width='100%' />
                                    )}
                                  </Segment>                                
                                </Grid.Column>
                                </Grid.Row>  
                                <Grid.Row> 
                                  <Grid.Column><h3>CHEDS</h3></Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                <Grid columns={4}>                 
                                  <Grid.Column>
                                  <Segment piled>
                                    <Stats size='tiny'>
                                      <Stats.Value>{chedsData(data).totalCheds}</Stats.Value>
                                      <Stats.Label>Cheds</Stats.Label>
                                    </Stats>
                                 
                                 
                                    <Stats size='tiny'>
                                      <Stats.Value>{totalValidatedCheds(data)}</Stats.Value>
                                      <Stats.Label>validated cheds</Stats.Label>
                                    </Stats>
                                    </Segment>
                                    </Grid.Column>
                                  <Grid.Column>
                                  <Segment piled>
                                  <Stats size='tiny'>
                                      <Stats.Value>{chedProcessingTime(data)}</Stats.Value>
                                      <Stats.Label>Average time</Stats.Label>
                                    </Stats>
                                    </Segment>
                                    </Grid.Column>
                                  <Grid.Column>
                                  <Segment piled>
                                  <Stats size='tiny'>
                                      <Stats.Value>{chedAverageWeight(data)}</Stats.Value>
                                      <Stats.Label>Average weight</Stats.Label>
                                    </Stats>
                                    </Segment>
                                    </Grid.Column>
                                  <Grid.Column>
                                  <Segment piled>
                                  <Stats size='tiny'>
                                      <Stats.Value>{chedAverageLines(data)}</Stats.Value>
                                      <Stats.Label>Average lines</Stats.Label>
                                    </Stats> 
                                    </Segment>                              
                                  </Grid.Column>                               
                                </Grid> 
                              </Grid.Row>                                               
                              <Divider hidden />
                                                    
                           
                              <Grid.Row> 
                              <Grid.Column><h3>TRUCKS (Validated Custom clerks)</h3></Grid.Column>
                              </Grid.Row>
                            
                             
                              <Grid.Row>
                                <Grid columns={4}>                  
                                  <Grid.Column width={4}>
                                  <Segment piled>
                                  {(totalClerks(clerks) === undefined && <Loader />) || (<Stats size='tiny'>
                                    <Stats.Value>{totalClerks(clerks).trucks}</Stats.Value>
                                    <Stats.Label>Trucks</Stats.Label>
                                  </Stats>)}
                                  </Segment>
                                  </Grid.Column>
                                  <Grid.Column width={4}>
                                  <Segment piled>
                                  {(clerksBilling(clerks) === undefined && <Loader />) || (<Stats size='tiny'>
                                    <Stats.Value>{clerksBilling(clerks).total}</Stats.Value>
                                    <Stats.Label>Total payments</Stats.Label>
                                  </Stats>)}
                                  </Segment>
                                  </Grid.Column>
                                  <Grid.Column width={4}>
                                  <Segment piled>
                                  {(clerksBilling(clerks) === undefined && <Loader />) || (<Stats size='tiny'>
                                    <Stats.Value>{clerksBilling(clerks).average}</Stats.Value>
                                    <Stats.Label>Average payments</Stats.Label>
                                  </Stats>)} 
                                  </Segment>         
                                  </Grid.Column>
                                  <Grid.Column width={4}>
                                  <Segment piled>
                                  {(clerksValidation(clerks) === undefined && <Loader />) || (<Stats size='tiny'>
                                    <Stats.Value>{clerksValidation(clerks)}</Stats.Value>
                                    <Stats.Label>Average spent time</Stats.Label>
                                  </Stats>)}
                                  </Segment>
                                  </Grid.Column>
                                </Grid> 
                                </Grid.Row>             
                        </Grid>                          
                        </>                         
                      )} 
                        </Wrapper>
                        </Wrapper>
                        </Wrapper> 
                        </Tab.Pane>  
                      ),
                    },
                    {
                      menuItem: 'Last Customs',
                      render: () => <Commercial user={user} companies={apexChartsCompanies} />,
                    },
                    {
                      menuItem: 'Comparison charts',
                      render: () => <Comparisons  
                          user={user} 
                          companies={apexChartsCompanies}  
                          apexChartsCompanies={apexChartsCompanies}                                    
                      />,
                    }
                  ]}
                />

             
       
      
       </Wrapper>
    )}
  </Wrapper>
);
}



const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    cheds: selectors.customsStatistics.getComputedChedsSelector(state),
    clerkStats: selectors.customsStatistics.getClerksStatsSelector(state),
    allCustoms: selectors.customsStatistics.getStatsSelector(state),
    loading: selectors.customsStatistics.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Statistic);
