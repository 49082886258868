import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    getCompanySubscribed(data: dataReqI) {
      const companySubscribedApi =
        routesApi.fiscal.OssIntegration.companySubscribed;
      return api.request({
        method: companySubscribedApi.method,
        route: companySubscribedApi.path,
        data: data.data,
      });
    },

    subscribe(data: dataReqI) {
      const subscribeApi = routesApi.fiscal.OssIntegration.subscribe;
      return api.request({
        method: subscribeApi.method,
        route: subscribeApi.path,
        params: data.params,
      });
    },

    unsubscribe(data: dataReqI) {
      const unsubscribeApi = routesApi.fiscal.OssIntegration.unsubscribe;
      return api.request({
        method: unsubscribeApi.method,
        route: unsubscribeApi.path,
        params: data.params,
      });
    },

    getIntegrations(data: dataReqI) {
      const getIntegrationApi = routesApi.fiscal.OssIntegration.getIntegrations;
      return api.request({
        method: getIntegrationApi.method,
        route: getIntegrationApi.path,
        data: data.data,
      });
    },

    registerIntegration(data: dataReqI) {
      const declareApi = routesApi.fiscal.OssIntegration.registerIntegration;
      const form: any = new FormData();
      form.set('cfId', data.data.cfId);
      form.append('file', data.data.file);

      return api.request({
        method: declareApi.method,
        route: declareApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },

    getCF(data: dataReqI) {
      const getCFApi = routesApi.fiscal.OssIntegration.getCF;
      return api.request({
        method: getCFApi.method,
        route: getCFApi.path,
        params: data.params,
        data: data.data
      });
    },
  };
};
