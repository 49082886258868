import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import * as Yup from 'yup';
import { Container, Divider, Header, Form, Modal } from 'semantic-ui-react';
import { Formik, Field } from 'formik';

import DropdownSetter from 'components/DropdownSetter';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { ContainerPadded } from './styles';
import Input from 'components/Form/Input';
import AddCompanyForm from 'views/Companies/New/components/FormFields/FormFields';

const Index = ({
  dispatch,
  countries,
  open,
  handleClose,
  refreshDelivery,
  transportersByToken,
  transporterVats,
  errorHandlerCompanyByToken,
  errorHandlerQuickfixesToken,
  loadingHandlerCompanyByToken,
  loadingHandlerQuickfixesToken,
  token,
}) => {
  const [transporterList, setTransporterList]: any = useState([]);
  const [vatList, setVatsList]: any = useState([]);
  const [isTransporterExists, setIsTransporterExists] = useState(true);
  const [runningAction, setRunningAction] = useState(null);

  useEffect(() => {
    dispatch(actions.quickfixesToken.reset(['error']));
    dispatch(actions.companyByToken.reset(['error']));
    dispatch(
      actions.companyByToken.getAllTransportersByToken({
        params: { token: token },
      })
    );
    return () => {
      dispatch(actions.companyByToken.reset(['transportersByToken', 'error']));
    };
  }, [dispatch, token]);

  useEffect(() => {
    if (transportersByToken !== null) {
      setTransporterList(
        transportersByToken.map((company, index) => {
          return {
            key: index,
            value: company.id,
            text: company.name,
          };
        })
      );
    }
  }, [transportersByToken]);

  useEffect(() => {
    if (transporterVats !== null) {
      setVatsList(
        transporterVats.map((vat, index) => {
          return {
            key: index,
            value: vat.id,
            text: vat.vatNumber,
          };
        })
      );
    }
  }, [transporterVats]);

  useEffect(() => {
    if (runningAction !== null && !loadingHandlerQuickfixesToken) {
      if (!errorHandlerQuickfixesToken.includes(runningAction)) {
        refreshDelivery();
      }
      setRunningAction(null);
      handleClose();
    }
  }, [errorHandlerQuickfixesToken, 
    loadingHandlerQuickfixesToken, 
    runningAction, 
    refreshDelivery,
    handleClose]);

  useEffect(() => {
    if (runningAction !== null && !loadingHandlerCompanyByToken) {
      if (!errorHandlerCompanyByToken.includes(runningAction)) {
        dispatch(actions.companyByToken.reset(['error']));
        dispatch(
          actions.companyByToken.getAllTransportersByToken({
            params: { token: token },
          })
        );
        setIsTransporterExists(true);
      } else {
        handleClose();
      }
      setRunningAction(null);
    }
  }, [dispatch, loadingHandlerCompanyByToken]);

  const manageTransporterChange = (value, setFieldValue) => {
    setVatsList([]);
    setFieldValue('vat', null);
    dispatch(actions.companyByToken.reset(['transporterVats', 'error']));
    value &&
      dispatch(
        actions.companyByToken.getTransporterVats({
          params: {
            token: token,
            transporterId: value,
          },
        })
      );
    return () => {
      dispatch(actions.companyByToken.reset(['transporterVats', 'error']));
    };
  };

  const save = (values) => {
    dispatch(actions.companyByToken.reset(['error']));
    dispatch(
      actions.companyByToken.createTransporterByToken({
        params: { token: token },
        data: values,
      })
    );
    setRunningAction('createTransporter');
  };

  const submit = (values) => {
    dispatch(actions.quickfixesToken.reset(['error']));
    dispatch(
      actions.quickfixesToken.transporterSelection({
        params: { token: token },
        data: {
          transporterId: values.transporter,
          transporterVatId: values.vat,
          estimatedTransportCost: values.estimatedShippingPrice,
          estimatedTransportDevise: 'EUR',
        },
      })
    );
    setRunningAction('transporterSelection');
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      {isTransporterExists &&
        ((loadingHandlerQuickfixesToken === true && <Loader />) || (
          <ContainerPadded textAlign='center'>
            <Container>
              <Header size='huge'>Designate a transporter</Header>
              <Divider hidden />
              <Formik
                initialValues={{
                  transporter: null,
                  vat: null,
                  estimatedShippingPrice: 0,
                }}
                onSubmit={submit}
                validationSchema={Yup.object().shape({
                  transporter: Yup.number().required('Required'),
                  vat: Yup.string().required('Required'),
                  estimatedShippingPrice: Yup.number().required('Required'),
                })}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  values,
                }: any) => (
                  <Form onSubmit={handleSubmit}>
                    <>
                      <Form.Field>
                        <Field
                          mandatory
                          label='Transporter:'
                          placeholder='Choose a transporter'
                          name={`transporter`}
                          options={transporterList}
                          component={DropdownSetter}
                          loading={loadingHandlerCompanyByToken}
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                          setterFunction={manageTransporterChange}
                          uniqueError={errorHandlerCompanyByToken.includes(
                            'transportersByToken'
                          )}
                        />
                      </Form.Field>
                      {values.transporter && vatList && (
                        <>
                          <Form.Field>
                            <Field
                              mandatory
                              label="Transporter's VAT:"
                              placeholder='Choose a vat'
                              name={`vat`}
                              options={vatList}
                              component={Dropdown}
                              loading={loadingHandlerCompanyByToken}
                              meta={{
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }}
                              uniqueError={errorHandlerCompanyByToken.includes(
                                'vatsByToken'
                              )}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Field
                              mandatory
                              label='Estimated shipping price (€):'
                              name={`estimatedShippingPrice`}
                              type='text'
                              component={Input}
                              meta={{ errors, touched }}
                            />
                          </Form.Field>
                        </>
                      )}

                      <Button
                        type='button'
                        content="The transporter isn't registered"
                        onClick={() => setIsTransporterExists(false)}
                        position='start'
                        margin='0px'
                      />
                      <Divider hidden />
                      <Button
                        disabled={errorHandlerCompanyByToken.length > 0}
                        type='submit'
                        content='Designate this transporter'
                        color='green'
                        margin='0px'
                      />
                    </>
                  </Form>
                )}
              </Formik>
            </Container>
          </ContainerPadded>
        ))}
      {!isTransporterExists &&
        ((loadingHandlerCompanyByToken === true && <Loader />) || (
          <ContainerPadded textAlign='center'>
            {(countries === null && <Loader />) || (
              <Container>
                <Header size='huge'>Create new transporter</Header>
                <Divider hidden />
                <AddCompanyForm onSave={save} />
              </Container>
            )}
          </ContainerPadded>
        ))}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    transportersByToken: selectors.companyByToken.transportersByTokenSelector(
      state
    ),
    transporterVats: selectors.companyByToken.transporterVatsSelector(state),
    errorHandlerCompanyByToken: selectors.companyByToken.errorSelector(state),
    errorHandlerQuickfixesToken: selectors.quickfixesToken.errorSelector(state),
    loadingHandlerCompanyByToken: selectors.companyByToken.loadingSelector(
      state
    ),
    loadingHandlerQuickfixesToken: selectors.quickfixesToken.loadingSelector(
      state
    ),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
