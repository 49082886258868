import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    hasPaidSubscription(data: dataReqI){
      const hasApi = routesApi.fiscal.POD.hasPaidSubscription;
      return api.request({
        method: hasApi.method,
        route: hasApi.path,
        params: data.params,
      });
    },
    checkQuickproofExist(data: dataReqI){
      const checkApi = routesApi.fiscal.POD.checkQuickproofExist;
      return api.request({
        method: checkApi.method,
        route: checkApi.path,
        params: data.params,
      });
    },
    setVatNumber(data: dataReqI) {
      const setVatApi = routesApi.fiscal.POD.setVatNumber;
      return api.request({
        method: setVatApi.method,
        route: setVatApi.path,
        params: data.params,
      });
    },
    getCompanySubscribed() {
      const getCompanySubscribedApi = routesApi.fiscal.POD.getCompanySubscribed;
      return api.request({
        method: getCompanySubscribedApi.method,
        route: getCompanySubscribedApi.path,
      });
    },
    subscribe(data: dataReqI) {
      const subscribe = routesApi.fiscal.POD.subscribe;
      return api.request({
        method: subscribe.method,
        route: subscribe.path,
        params: data.params,
        data: data.data
      });
    },
    subscribeWithCode(data: dataReqI) {
      const subscribeWithCode = routesApi.fiscal.POD.subscribeWithCode;
      return api.request({
        method: subscribeWithCode.method,
        route: subscribeWithCode.path,
        params: data.params,
        data: data.data,
      });
    },
    contact(data: dataReqI) {
      const contact = routesApi.fiscal.POD.contact;
      return api.request({
        method: contact.method,
        route: contact.path,
        data: data.data,
      });
    },
    unsubscribe(data: dataReqI) {
      const unsubscribe = routesApi.fiscal.POD.unsubscribe;
      return api.request({
        method: unsubscribe.method,
        route: unsubscribe.path,
        params: data.params,
      });
    },
    askForProof(data: dataReqI) {
      const askForProof = routesApi.fiscal.POD.askForProof;
      return api.request({
        method: askForProof.method,
        route: askForProof.path,
        params: data.params,
        data: data.data,
      });
    },
    downloadAttestation(data: dataReqI) {
      const downloadAttestation = routesApi.fiscal.POD.downloadAttestation;
      return api.request({
        method: downloadAttestation.method,
        route: downloadAttestation.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    getAll() {
      const getAll = routesApi.fiscal.POD.getAll;
      return api.request({
        method: getAll.method,
        route: getAll.path,
      });
    },
    getOne(data: dataReqI) {
      const getOne = routesApi.fiscal.POD.getOne;
      return api.request({
        method: getOne.method,
        route: getOne.path,
        params: data.params,
      });
    },
    getPODOfOneTransporter(data: dataReqI) {
      const getPODOfOneTransporter =
        routesApi.fiscal.POD.getPODOfOneTransporter;
      return api.request({
        method: getPODOfOneTransporter.method,
        route: getPODOfOneTransporter.path,
        params: data.params,
      });
    },
    getPrefilledDelivery(data: dataReqI) {
      const getPrefilledApi = routesApi.fiscal.POD.getPrefilledDelivery;
      return api.request({
        method: getPrefilledApi.method,
        route: getPrefilledApi.path,
        params: data.params,
      });
    },
    getPODOfOneBuyer(data: dataReqI) {
      const getPODOfOneBuyer = routesApi.fiscal.POD.getPODOfOneBuyer;
      return api.request({
        method: getPODOfOneBuyer.method,
        route: getPODOfOneBuyer.path,
        params: data.params,
      });
    },
    getPODOfOneSeller(data: dataReqI) {
      const getPODOfOneSeller = routesApi.fiscal.POD.getPODOfOneSeller;
      return api.request({
        method: getPODOfOneSeller.method,
        route: getPODOfOneSeller.path,
        params: data.params,
        query: data.query,
      });
    },
    getPODOfOneSellerByFilters(data: dataReqI) {
      const getPODOfOneSellerByFilters =
        routesApi.fiscal.POD.getPODOfOneSellerByFilters;
      return api.request({
        method: getPODOfOneSellerByFilters.method,
        route: getPODOfOneSellerByFilters.path,
        params: data.params,
        data: data.data,
        query: data.query,
      });
    },
    create(data: dataReqI) {
      const create = routesApi.fiscal.POD.create;
      return api.request({
        method: create.method,
        route: create.path,
        params: data.params,
        data: data.data,
      });
    },
    update(data: dataReqI) {
      const update = routesApi.fiscal.POD.update;
      return api.request({
        method: update.method,
        route: update.path,
        params: data.params,
        data: data.data,
      });
    },
    getTotalVatRisk(data: dataReqI) {
      const getTotalVatRisk = routesApi.fiscal.POD.getTotalVatRisk;
      return api.request({
        method: getTotalVatRisk.method,
        route: getTotalVatRisk.path,
        params: data.params,
      });
    },
    getNumberOfReceived(data: dataReqI) {
      const getNumberOfReceived = routesApi.fiscal.POD.getNumberOfReceived;
      return api.request({
        method: getNumberOfReceived.method,
        route: getNumberOfReceived.path,
        params: data.params,
      });
    },
    getNumberOfPending(data: dataReqI) {
      const getNumberOfPending = routesApi.fiscal.POD.getNumberOfPending;
      return api.request({
        method: getNumberOfPending.method,
        route: getNumberOfPending.path,
        params: data.params,
      });
    },
    getNumberOfProofPending(data: dataReqI) {
      const getNumberOfProofPending =
        routesApi.fiscal.POD.getNumberOfProofPending;
      return api.request({
        method: getNumberOfProofPending.method,
        route: getNumberOfProofPending.path,
        params: data.params,
      });
    },
    handleSplitProof(data: dataReqI) {
      const uploadProof = routesApi.fiscal.POD.uploadSplitProof;
      if(data.data.file){
        const form: any = new FormData();
        form.append('file', data.data.file);
        form.append('action', data.data.action);
        form.set('companyType', data.data.companyType);
  
        return api.request({
          method: uploadProof.method,
          route: uploadProof.path,
          params: data.params,
          data: form,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
          },
        });
      }else{
        return api.request({
          method: uploadProof.method,
          route: uploadProof.path,
          params: data.params,
          data: data
        });
      }
     
    },
    uploadProof(data: dataReqI) {
      const uploadProof = routesApi.fiscal.POD.uploadProof;
      const form: any = new FormData();
      form.append('file', data.data.file);
      form.set('type', data.data.type);
      form.set('companyType', data.data.companyType);

      return api.request({
        method: uploadProof.method,
        route: uploadProof.path,
        params: data.params,
        data: form,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    downloadAllProof(data: dataReqI) {
      const downloadAllProof = routesApi.fiscal.POD.downloadAllProof;
      return api.request({
        method: downloadAllProof.method,
        route: downloadAllProof.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    downloadOneProof(data: dataReqI) {
      const downloadOneProof = routesApi.fiscal.POD.downloadOneProof;
      return api.request({
        method: downloadOneProof.method,
        route: downloadOneProof.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    downloadCustomsRegimeInvoice(data: dataReqI) {
      const downloadCustomsRegimeInvoice =
        routesApi.fiscal.POD.downloadCustomsRegimeInvoice;
      return api.request({
        method: downloadCustomsRegimeInvoice.method,
        route: downloadCustomsRegimeInvoice.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    getVatRisk(data: dataReqI) {
      const getVatRisk = routesApi.fiscal.POD.getVatRisk;
      return api.request({
        method: getVatRisk.method,
        route: getVatRisk.path,
        params: data.params,
        data: data.data,
      });
    },
    validateProof(data: dataReqI) {
      const validateProof = routesApi.fiscal.POD.validateProof;
      return api.request({
        method: validateProof.method,
        route: validateProof.path,
        params: data.params,
      });
    },
    refuseProof(data: dataReqI) {
      const refuseProof = routesApi.fiscal.POD.refuseProof;
      return api.request({
        method: refuseProof.method,
        route: refuseProof.path,
        params: data.params,
        data: data.data,
      });
    },
    validateDelivery(data: dataReqI) {
      const validateDelivery = routesApi.fiscal.POD.validateDelivery;
      return api.request({
        method: validateDelivery.method,
        route: validateDelivery.path,
        params: data.params,
      });
    },
    getOneForBuyer(data: dataReqI) {
      const getOneForBuyer = routesApi.fiscal.POD.getOneForBuyer;
      return api.request({
        method: getOneForBuyer.method,
        route: getOneForBuyer.path,
        params: data.params,
      });
    },
    getOneForTransporter(data: dataReqI) {
      const getOneForTransporter = routesApi.fiscal.POD.getOneForTransporter;
      return api.request({
        method: getOneForTransporter.method,
        route: getOneForTransporter.path,
        params: data.params,
      });
    },
    getMassIntegrations(data: dataReqI) {
      const getMassIntegration = routesApi.fiscal.POD.getMassIntegrations;
      return api.request({
        method: getMassIntegration.method,
        route: getMassIntegration.path,
        params: data.params,
        query: data.query,
      });
    },
    getCustomsDeclarationsIDs(data: dataReqI) {
      const getCDIDs = routesApi.fiscal.POD.getCDIDs;
      return api.request({
        method: getCDIDs.method,
        route: getCDIDs.path,
        params: data.params,
      });
    },
    searchForClientRef(data: dataReqI) {
      const searchForClientRef = routesApi.fiscal.POD.searchForClientRef;
      return api.request({
        method: searchForClientRef.method,
        route: searchForClientRef.path,
        params: data.params,
        query: data.query,
      });
    },
    downloadMassIntegrationTemplate(data: dataReqI) {
      const downloadMassIntegrationTemplate =
        routesApi.fiscal.POD.downloadMassIntegrationTemplate;
      return api.request({
        method: downloadMassIntegrationTemplate.method,
        route: downloadMassIntegrationTemplate.path,
        params: data.params,
        responseType: 'blob',
      });
    },
    getOneMassIntegration(data: dataReqI) {
      const getOneMassIntegration = routesApi.fiscal.POD.getOneMassIntegration;
      return api.request({
        method: getOneMassIntegration.method,
        route: getOneMassIntegration.path,
        params: data.params,
      });
    },
    createMassIntegration(data: dataReqI) {
      const createMassIntegration = routesApi.fiscal.POD.createMassIntegration;
      const form: any = new FormData();
      form.append('file', data.data.file);

      return api.request({
        method: createMassIntegration.method,
        route: createMassIntegration.path,
        params: data.params,
        data: form,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    deleteMassIntegration(data: dataReqI) {
      const deleteMassIntegration = routesApi.fiscal.POD.deleteMassIntegration;
      return api.request({
        method: deleteMassIntegration.method,
        route: deleteMassIntegration.path,
        params: data.params,
      });
    },
    getCDIDs(data: dataReqI) {
      const getCDIDs = routesApi.fiscal.POD.getCDIDs;
      return api.request({
        method: getCDIDs.method,
        route: getCDIDs.path,
        params: data.params,
        data: data.data,
      });
    },
    getBuyersTransporters(data: dataReqI) {
      const getBuyerTransporterApi = routesApi.fiscal.POD.getBuyersTransporters;
      return api.request({
        method: getBuyerTransporterApi.method,
        route: getBuyerTransporterApi.path,
        params: data.params,
      });
    },

  };
};
