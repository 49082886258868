import { resetState } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: false,
  error: false,
  getError: false,
  message: null,
  httpCode: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SUCCESS_MESSAGE:
      return produce(state, (draft) => {
        draft.message = action.payload;
        draft.error = false;
        draft.success = true;
      });
    case actionTypes.ERROR_MESSAGE:
      return produce(state, (draft) => {
        draft.httpCode = action.payload.status;
        draft.message = action.payload.message;
        draft.error = true;
        draft.success = false;
      });
    case actionTypes.GET_ERROR_MESSAGE:
      return produce(state, (draft) => {
        draft.httpCode = action.payload.status;
        draft.message = action.payload.message;
        draft.getError = true;
        draft.success = false;
      });
    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
