import { resetState, refreshError } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    addKeyInfo: false,
    removeKeyInfo: false,
    updateKeyInfo: false,
    keyInfoSetDone: false,
    getKeyInfos: false,
    checkkeyInfoSetDone: false,
  },
  errors: {
    addKeyInfo: null,
    removeKeyInfo: null,
    updateKeyInfo: null,
    keyInfoSetDone: null,
    getKeyInfos: null,
    checkkeyInfoSetDone: null,
  },
  loading: {
    addKeyInfo: false,
    removeKeyInfo: false,
    updateKeyInfo: false,
    keyInfoSetDone: false,
    getKeyInfos: false,
    checkkeyInfoSetDone: false
  },
  addKeyInfo: null,
  removeKeyInfo: null,
  updateKeyInfo: null,
  keyInfoSetDone: null,
  getKeyInfos: null,
  checkkeyInfoSetDone: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

     // Add KeyInfo
     case actionTypes.ADD_KEYINFO_LOADING:
      return produce(state, (draft) => {
        draft.loading.addKeyInfo = true;
      });

    case actionTypes.ADD_KEYINFO_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.addKeyInfo = null;
        draft.loading.addKeyInfo = false;
        draft.success.addKeyInfo = true;
      });

    case actionTypes.ADD_KEYINFO_ERROR:
      return produce(state, (draft) => {
        draft.errors.addKeyInfo = true;
        draft.loading.addKeyInfo = false;
        draft.success.addKeyInfo = false;
        !draft.error.includes("addKeyInfo") && draft.error.push("addKeyInfo");
      });

       // remove KeyInfos
    case actionTypes.REMOVE_KEYINFO_LOADING:
      return produce(state, (draft) => {
        draft.loading.removeKeyInfo = true;
      });

    case actionTypes.REMOVE_KEYINFO_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.removeKeyInfo = null;
        draft.loading.removeKeyInfo = false;
        draft.success.removeKeyInfo = true;
      });

    case actionTypes.REMOVE_KEYINFO_ERROR:
      return produce(state, (draft) => {
        draft.errors.removeKeyInfo = true;
        draft.loading.removeKeyInfo = false;
        draft.success.removeKeyInfo = false;
        !draft.error.includes("removeKeyInfo") && draft.error.push("removeKeyInfo");
      });

       // update KeyInfos
    case actionTypes.UPDATE_KEYINFO_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateKeyInfo = true;
      });

    case actionTypes.UPDATE_KEYINFO_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateKeyInfo = null;
        draft.loading.updateKeyInfo = false;
        draft.success.updateKeyInfo = true;
      });

    case actionTypes.UPDATE_KEYINFO_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateKeyInfo = true;
        draft.loading.updateKeyInfo = false;
        draft.success.updateKeyInfo = false;
        !draft.error.includes("updateKeyInfo") && draft.error.push("updateKeyInfo");
      });

     // keyInfoSetDone
     case actionTypes.SET_DONE_KEYINFO_LOADING:
      return produce(state, (draft) => {
        draft.loading.keyInfoSetDone = true;
      });

    case actionTypes.SET_DONE_KEYINFO_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.keyInfoSetDone = null;
        draft.loading.keyInfoSetDone = false;
        draft.success.keyInfoSetDone = true;
        draft.keyInfoSetDone = action.payload;
      });

    case actionTypes.SET_DONE_KEYINFO_ERROR:
      return produce(state, (draft) => {
        draft.errors.keyInfoSetDone = true;
        draft.loading.keyInfoSetDone = false;
        draft.success.keyInfoSetDone = false;
        !draft.error.includes("keyInfoSetDone") && 
        draft.error.push("keyInfoSetDone");
      });

       // checkkeyInfoSetDone
     case actionTypes.CHECK_DONE_KEYINFO_LOADING:
      return produce(state, (draft) => {
        draft.loading.checkkeyInfoSetDone = true;
      });

    case actionTypes.CHECK_DONE_KEYINFO_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.checkkeyInfoSetDone = null;
        draft.loading.checkkeyInfoSetDone = false;
        draft.success.checkkeyInfoSetDone = true;
        draft.checkkeyInfoSetDone = action.payload;
      });

    case actionTypes.CHECK_DONE_KEYINFO_ERROR:
      return produce(state, (draft) => {
        draft.errors.checkkeyInfoSetDone = true;
        draft.loading.checkkeyInfoSetDone = false;
        draft.success.checkkeyInfoSetDone = false;
        !draft.error.includes("checkkeyInfoSetDone") && 
        draft.error.push("checkkeyInfoSetDone");
      });


    // Get KeyInfos
    case actionTypes.GET_KEYINFOS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getKeyInfos = true;
      });

    case actionTypes.GET_KEYINFOS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getKeyInfos = null;
        draft.loading.getKeyInfos = false;
        draft.success.getKeyInfos = true;
        draft.getKeyInfos = action.payload;
      });

    case actionTypes.GET_KEYINFOS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getKeyInfos = true;
        draft.loading.getKeyInfos = false;
        draft.success.getKeyInfos = false;
        !draft.error.includes("getKeyInfos") &&
        draft.error.push("getKeyInfos");
      });
   

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
