import { selectors } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { Key, useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { Buffer } from 'buffer';
import { IframeViewer, IframeViewerFull } from './styles';
import Button from 'components/Button';
import { Message } from 'semantic-ui-react';

const IntrastatIframe = ({
  src,
  companyId,
  fileLoading,
  file,
  fullscreenTv,
  loadDocument,
}: ModalIntrastatIframeProps) => {
  const viewId: Key = Number(Math.random);

  const [data, setData]: any = useState(null);
  const [dataUrl, setUrl]: any = useState(null);
  const [download, setDownLoad]: any = useState(null);
  const [documentData, setDocument]: any = useState(null);

  useEffect(() => {
    if (src && src.url && src.context === 'session' && companyId) {
      const session = src.url.split('/')[1];
      const folder = src.url.split('/')[2];
      const document = src.url.split('/')[3];

      if (
        session &&
        folder &&
        folder.split('.')[1] !== 'group' &&
        document &&
        src.context === 'session'
      ) {
        setUrl(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/user/${companyId}/documents/${session}/${folder}/${document}/intrastat`
        );
      } else {
        setUrl(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/user/${companyId}/documents/${session}/none/${folder}`
        );
      }
    } else if (src && src.url) {
      let intrastatId;
      let folder;
      let document;
      let s3Repository;

      //TODO: Mettre à jour ce fix pour qu'il fonctionne dans tous les cas possibles
      if (src.url.split('/').length === 4) {
        intrastatId = src.url.split('/')[1];
        folder = src.url.split('/')[2];
        document = src.url.split('/')[3];
        s3Repository = src.url.split('/')[0];
      } else {
        intrastatId = src.url.split('/')[2];
        folder = src.url.split('/')[3];
        document = src.url.split('/')[4];
        s3Repository = src.url.split('/')[0] + '/' + src.url.split('/')[1];
      }

      setDocument({ id: folder.split('-')[0], name: document });

      if (!document.endsWith('.PDF') && !document.endsWith('.pdf')) {
        setDownLoad((prevState) => ({
          ...prevState,
          src,
        }));
      } else {
        setDownLoad(null);
        setUrl(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/user/${s3Repository}/documents/${intrastatId}/${folder}/${document}/intrastat`
        );
      }
    }
  }, [src, companyId]);

  useEffect(() => {
    if (file) {
      const bufferReader = Buffer.from(file);
      bufferReader && setData(bufferReader);
    }
  }, [file]);

  return (
    ((data === null || dataUrl === null) && fileLoading && <Loader />) ||
    (download && documentData && (
      <>
        <Message
          icon='download'
          header='Download'
          content={`${download.src.ext} cannot be read with conventional pdf viewer. please download file`}
        />
        <Button
          type='submit'
          margin='5px'
          content='Download'
          color='blue'
          onClick={() => {
            loadDocument(documentData);
          }}
        />
      </>
    )) ||
    (fullscreenTv === null && (
      <IframeViewer key={viewId} src={dataUrl ? dataUrl : data}></IframeViewer>
    )) || (
      <IframeViewerFull
        key={viewId}
        src={dataUrl ? dataUrl : data}
      ></IframeViewerFull>
    )
  );
};

interface ModalIntrastatIframeProps {
  src;
  companyId;
  fileLoading;
  file;
  fullscreenTv;
  loadDocument: (documentData: any) => void;
  dispatch: (value: any) => void;
}

const mapStateToProps = (state) => {
  return {
    file: selectors.intrastat.getArchiveSelector(state),
    fileLoading: selectors.intrastat.getArchiveLoadingSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(IntrastatIframe);
