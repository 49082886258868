import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const createAddressErrorSelector = state => state.address.errors.createAddress;
const getAllAddressesErrorSelector = state => state.address.errors.getAllAddresses;
const getOneAddressErrorSelector = state => state.address.errors.getOneAddress;
const deleteAddressErrorSelector = state => state.address.errors.deleteAddress;
const updateAddressErrorSelector = state => state.address.errors.updateAddress;

const errorsSelector = createErrorsSelector([
  createAddressErrorSelector,
  getAllAddressesErrorSelector,
  getOneAddressErrorSelector,
  deleteAddressErrorSelector,
  updateAddressErrorSelector,
]);

// Loading
const createAddressLoadingSelector = state => state.address.loading.createAddress;
const getAllAddressesLoadingSelector = state => state.address.loading.getAllAddresses;
const getOneAddressLoadingSelector = state => state.address.loading.getOneAddress;
const deleteAddressLoadingSelector = state => state.address.loading.deleteAddress;
const updateAddressLoadingSelector = state => state.address.loading.updateAddress;

const loadingSelector = createLoadingSelector([
  createAddressLoadingSelector,
  getAllAddressesLoadingSelector,
  getOneAddressLoadingSelector,
  deleteAddressLoadingSelector,
  updateAddressLoadingSelector,
]);

// Success
const createAddressSuccessSelector = state => state.address.success.createAddress;
const getAllAddressesSuccessSelector = state => state.address.success.getAllAddresses;
const getOneAddressSuccessSelector = state => state.address.success.getOneAddress;
const deleteAddressSuccessSelector = state => state.address.success.deleteAddress;
const updateAddressSuccessSelector = state => state.address.success.updateAddress;

const successSelector = createSuccessSelector([
  createAddressSuccessSelector,
  getAllAddressesSuccessSelector,
  getOneAddressSuccessSelector,
  deleteAddressSuccessSelector,
  updateAddressSuccessSelector,
]);

// Addresss
const addressesSelector = state => state.address.addresses;

// Address
const addressSelector = state => state.address.address;

const errorSelector = state => state.address.error;




export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  addressesSelector,
  addressSelector,
  errorSelector
};
