import actionTypes from 'store/fiscal/oss/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.GET_COMPANY_SUBSCRIBED:
        try {
          next({ type: actionTypes.GET_COMPANY_SUBSCRIBED_LOADING });
          const response = await request.getCompanySubscribed({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_COMPANY_SUBSCRIBED_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.SUBSCRIBE:
        try {
          next({ type: actionTypes.SUBSCRIBE_LOADING });
          await request.subscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.SUBSCRIBE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.SUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UNSUBSCRIBE:
        try {
          next({ type: actionTypes.UNSUBSCRIBE_LOADING });
          await request.unsubscribe({
            ...action.payload,
          });
          next({
            type: actionTypes.UNSUBSCRIBE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UNSUBSCRIBE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_OSS_INTEGRATIONS:
        try {
          next({ type: actionTypes.GET_OSS_INTEGRATIONS_LOADING });
          const response = await request.getIntegrations({
            ...action.payload,
          });

          next({
            type: actionTypes.GET_OSS_INTEGRATIONS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_OSS_INTEGRATIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.REGISTER_INTEGRATION:
        try {
          next({ type: actionTypes.REGISTER_INTEGRATION_LOADING });
          await request.registerIntegration({
            ...action.payload,
          });
          next({
            type: actionTypes.REGISTER_INTEGRATION_SUCCESS,
          });
          store.dispatch(
            messageHandler.successMessage('OSS was registered correctly !')
          );
        } catch (error) {
          next({
            type: actionTypes.REGISTER_INTEGRATION_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_CF:
        try {
          next({ type: actionTypes.GET_CF_LOADING });
          const response = await request.getCF({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_CF_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_CF_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
