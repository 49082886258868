import React, { FunctionComponent } from 'react';
import { MessagePlacholder, MessageWrapper } from './alertMessageStyles';
import { Divider } from 'semantic-ui-react';
import { TextCustom } from 'components/core';
import Button from 'components/Button';

const AlertMessage: FunctionComponent<FloatingMessageI> = ({
  intl,
  reloadAssets
}) => {

  return (
    <MessagePlacholder>
      <MessageWrapper>
        <TextCustom fontWeight='bold'>
          Session expiry
        </TextCustom>
        <Divider />
        <p>Your session expires. Please reload the login page and log in again.</p>
        <Divider />
        <Button content='Close' onClick={() => {
          reloadAssets()
          }} />
      </MessageWrapper>
    </MessagePlacholder>
  );
};

interface FloatingMessageI {
  intl: any;
  reloadAssets: () => void
  message: string;
}

export default AlertMessage;
