import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import {
  Divider,
  Grid,
  Header,
  Message,
  Segment,
  Table,
  Icon,
} from 'semantic-ui-react';
import Button from 'components/Button';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import ModalAddIntranetReference from '../components/ModalAddIntranetReference/ModalAddIntranetReference';
import ModalEditVat from '../components/ModalEditVat/ModalEditVat';
import { yearMonth, onlyDate} from 'services/helper/dateFormater';
import { FolderLink, FolderWrapper, Linked, ResponsiveWrapper } from '../Details/vatDetailsStyles';
import { paths as pathToCompany } from 'routes/companies';
import { Link } from 'react-router-dom';
import VatChat from '../components/VatChat/VatChat';
import ModalCancelVat from '../components/ModalCancelVat/ModalCancelVat';
import VatDocuments from '../components/VatDocuments/Documents';
import ModalAddVatDocuments from '../components/ModalAddVatDocuments/ModalAddDocuments';
import VatTimeLine from '../components/VatTimeLine/VatTimeLine';


const Index = ({
  dispatch,
  match,
  history,
  vat,
  VatLoading,
  chat,
  chatLoading,
  companySelected,
  user,
  deleteDocumentLoading,
  addDocumentLoading,
  downloadLoading
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = (userAdminOrOp || user.partnership) ? match.params.companyId : companySelected;
  const declarationId = match.params.declarationId
  const isOwner =  Number(match.params.companyId) === Number(companySelected);

  const [modalAddDocumentsStatus, setmodalAddDocumentsStatus] = useState({open: false, type: null});
  const [modalCancelVat, setmodalCancelVat] = useState(false);
  const [modalEditVat, setmodalEditVat] = useState(false);
  const [modalAddIntranetReference, setmodalAddIntranetReference] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [acceptedFiles, setAcceptedFiles]: any = useState(null);

  useEffect(() => {
    dispatch(
      actions.fiscalVat.getVatDeclaration({
        params: { 
          companyId: companyId, 
          declarationId: declarationId 
        },
      })
    );
    dispatch(actions.fiscalVat.getCategories({
      params: {
        companyId: companyId
      }
    }));
    dispatch(
      actions.chat.getAllChats({
        params: { 
          companyId: companyId,  
          id: declarationId 
        },
      })
    );
    dispatch(
      actions.socket.subscribeUpdateChat(
        companyId,
        declarationId
      )
     );
    dispatch(
      actions.socket.subscribeOneVat(
        companyId,
        declarationId
        )
    );
    return () => {
      dispatch(
        actions.fiscalVat.reset([
          'getVatDeclaration',
          'chat',
          'categoriesDocuments',
          'error'])
      );
      dispatch(actions.socket.unsubscribeUpdateChat(companyId, declarationId));
      dispatch(actions.socket.unsubscribeOneVat(companyId, declarationId));
    }
  }, [dispatch, companyId, declarationId]);

  
  useEffect(() => {
    if(vat){
      if(vat.follower && user){
        setIsFollowing(vat.follower.find((e) => e.id === user.id) ? true : false)
      }
    }
  }, [vat, user])


  const follow = (action: boolean) => {
    dispatch(
      actions.fiscalVat.followVat({
        params: {
          declarationId: declarationId,
          companyId,
        },
        data: {
          action: action
        }
      })
    );
  };

  const updateStep = (step: number) => {
    // classic
    dispatch(
      actions.fiscalVat.updateStep({
        params: {
          declarationId: declarationId,
          companyId: companyId,
        },
        data: { step },
      })
    );
  };


  const cancelVat = (values: { reason: string }) => {
    dispatch(
      actions.fiscalVat.updateVatDeclaration({
        params: {
          declarationId: declarationId,
          companyId: companyId,
        },
        data: { 
          reason: values.reason,
        },
      })
    );
    setmodalCancelVat(false);
  };


  const editOneVat = (values: {
    taxPeriod: string,
    country: {},
    clientReference: string, 
}): void => {

  dispatch(
    actions.fiscalVat.updateVatDeclaration({
      params: {
        declarationId: declarationId,
        companyId: companyId,
      },
      data: {
        taxPeriod: values.taxPeriod,
        country: values.country,
        clientReference: values.clientReference, 
      },
    }))
   setmodalEditVat(false);
}

  const addIntranetReference = (intranetReference: string ) => {
    dispatch(actions.fiscalVat.updateVatDeclaration({
      params: {
        declarationId: declarationId,
        companyId: Number(companyId),
      },
      data: {
       intranetReference: intranetReference
      },
    }))
    setmodalAddIntranetReference(false);
  };

  const handleShowDocument = (documentId: number) => {
    dispatch(
      actions.fiscalVat.downloadDocument({
        params: {
          declarationId: declarationId,
          companyId,
          documentId,
        },
      })
    );
  };

  const handleDeleteDocument = (documentId: number) => {
    dispatch(
      actions.fiscalVat.deleteDocument({
        params: {
          declarationId: declarationId,
          companyId,
          documentId,
        },
      })
    );
  };

  const handleCheckDocument = (documentId: number, status: string) => {
    dispatch(
      actions.fiscalVat.updateDocument({
        params: {
          companyId: companyId,
          declarationId: declarationId,
          docId: documentId,
          validation: status
        },
      })
    );
  }

  const getAllFiles = (acceptedFiles: any[], index: number) => {
    let obj: any = []
    obj.push({id: index, docs: acceptedFiles})
    setAcceptedFiles(obj)
  }

  const handleAddDocuments = (values: {
    type: number;
    documents: any;
    documentCategories: Array<{
      id: number;
      number: string | null;
    }>;
  }) => {
    values.documents.forEach((e, i) => {
      values.documents[i].files = acceptedFiles[i].docs
    })

    dispatch(
      actions.fiscalVat.addDocuments({
        params: {
          declarationId: declarationId,
          companyId,
        },
        data: {
          type: '0',
          documents: values.documents,
          documentCategories: values.documentCategories,
        },
      })
    );
    setmodalAddDocumentsStatus(prevState => ({
      ...prevState,
      open: false,
      type: undefined
    }));
   
  };

  const handleSubmitMessage = (values: { userId: number; message: string }) => {
    dispatch(
      actions.chat.postChats({
        params: {
          id: declarationId,
          companyId,
        },
        data: {
          userId: user.id,
          message: values.message
        }
      })
    );
  };

  const closeVat = (declarationId: string) => {
    dispatch(
      actions.fiscalVat.updateStep({
        params: {
          declarationId: declarationId,
          companyId: Number(companyId),
        },
        data: { step: 7 },
      })
    );
  }

  return (
    <Wrapper display='flex' justifyContent='center'> 
       {modalAddDocumentsStatus.open && (
        <ModalAddVatDocuments
          userAdminOrOp={userAdminOrOp}
          declarationType={vat.Type.name}
          open={modalAddDocumentsStatus.open}
          handleClose={() => setmodalAddDocumentsStatus(prevState => ({
            ...prevState,
            open: false,
            type: undefined
          }))}
          handleAddDocuments={handleAddDocuments}
          getAllFiles={getAllFiles}
          dispatch={dispatch}
        />
      )}
      {modalCancelVat && (
        <ModalCancelVat
          open={modalCancelVat}
          handleClose={() => setmodalCancelVat(false)}
          handleCancelVat={cancelVat}
        />
      )}
      {modalAddIntranetReference && (
        <ModalAddIntranetReference
          open={modalAddIntranetReference}
          handleClose={() => setmodalAddIntranetReference(false)}
          handleAddIntranetReference={addIntranetReference}
        />
      )}
      {modalEditVat && (
        <ModalEditVat
          open={modalEditVat}
          handleClose={() => setmodalEditVat(false)}
          handleEditVat={editOneVat}
          dispatch={dispatch}
          companyId={vat.Company_Id}
          actualData={{
            type: vat.Type.id,
            taxPeriod: vat.taxPeriod,
            country: {iso: vat.country, periodicity: vat.periodicity},
            clientReference: vat.clientReference,
            intranetReference: vat.intranetReference,
          }}
        />
      )}

      {(
      (downloadLoading ||
        addDocumentLoading ||
        deleteDocumentLoading || 
        vat === null ||
        VatLoading) && 
        <Loader content='Loading' />
      )} 

      <Wrapper height='100%' width='80%' overflow='auto'>
        <Divider hidden />
        <Wrapper display='flex'>
          <ButtonBack history={history}/>
        </Wrapper>
        <Divider hidden />       
          <Wrapper
            display='flex'
            justifyContent='center'
            height='100%'
            width='100%'
          >
            <Wrapper width='80%'>
            {vat !== null && vat.DeclarationHasCategory && (
            <Wrapper>
            <VatTimeLine
              taxPeriod={vat.createdAt}
              declarationType={vat.Type.name}
              loading={VatLoading}
              onClick={
                !VatLoading &&
                    userAdminOrOp &&
                    ((step) => {
                      step !==
                      vat.DeclarationHasCategory.step &&
                        updateStep(step);
                    })
                  }
              maxStep={5}
              actualStep={vat.DeclarationHasCategory.step}
            />
            </Wrapper>)}               


            {(<>
              {vat !== null && (
                <>           
                <Segment>   
                  <Grid columns={2} divided padded>
                    <Grid.Column>
                      <FolderWrapper className='folder_wrapper'>
                        <b>Company: </b>
                        <FolderLink>
                          <Link to={pathToCompany.companyDetails.replace(':companyId', companyId)} className='folder_link_link'>{vat.Company.name}</Link>
                        </FolderLink>                   
                      </FolderWrapper>
                      <div><b>Declaration type: </b>{vat.Type.name}</div>
                      <div><b>Period: </b>{yearMonth(new Date(vat.taxPeriod))}</div>
                          <Divider hidden/>
                      <div><strong>Country: </strong>{vat.country} - <strong>Periodicity:</strong> {vat.periodicity} - <strong>nth:</strong> {vat.nth}</div>
                      <div><strong>Deadline: </strong>{onlyDate(new Date(vat.endDate))}</div>
                      <Divider hidden />  
                    </Grid.Column>
                    <Grid.Column>
                      <div>
                        <b>Id: </b>
                        {vat.id}
                      </div>
                      <div>
                        <b>Created at: </b>
                        {onlyDate(new Date(vat.createdAt))}
                      </div>
                      <div>
                        <b>Emitter: </b>
                        {vat.emitter && vat.emitter.fullname}
                      </div>
                      <div>
                        <b>Followers: </b>
                        {(vat.follower && 
                        vat.follower.length &&  
                        vat.follower.map((user, i) => (
                              <li
                                key={i}
                              >{`${user.operator}`}</li>
                            ))
                         ) || 'none'}
                      </div>

                      {vat.clientReference && (
                        <div>
                          <b>Client reference: </b>
                          {vat.clientReference === '' ? '' : vat.clientReference}
                        </div>
                      )}
                    
                      {userAdminOrOp && (                                         
                        <FolderWrapper>
                          <b>Intranet reference: </b>
                          {vat.intranetReference && (
                          <FolderLink>
                            <Linked href={`${process.env.REACT_APP_INTRANET_DOUANE_URL}${vat.intranetReference}`} className='folder_link_link'>
                              {vat.intranetReference}</Linked>  
                              <Icon
                                className='customs-link-folder'
                                name='folder open'
                                size='small'
                                color='black'                    
                              />                             
                          </FolderLink> 
                          )}                                          
                        </FolderWrapper>                                                                               
                      )}
                      {vat.purchaseNumber && (
                        <div><b>PO: </b>{vat.purchaseNumber}</div>
                      )}                     
                    
                    </Grid.Column>
                  </Grid>
                  <Divider hidden />
                  {
                    vat.state !== 'completed' &&
                    vat.state !== 'cancelled' && (
                      <Button
                        icon='edit'
                        type='button'
                        size='small'
                        color='blue'
                        content='Edit data'
                        margin='10px 0 0 0'
                        onClick={() => setmodalEditVat(true)}
                      />
                  )}
                </Segment>              
                <Divider hidden />
                {!vat.errorMessage && (
                  <Grid centered>              
                    {userAdminOrOp && 
                      vat.state !== 'cancelled' && (
                      <Button
                        icon='plus'
                        type='button'
                        size='large'
                        color='blue'
                        content='add intranet reference'
                        margin='5px'
                        onClick={() => setmodalAddIntranetReference(true)}
                      />
                    )}                   
                    {userAdminOrOp && 
                      vat.state !== 'cancelled' && 
                      vat.state !== 'completed' && (
                      <Button
                        icon='user md'
                        type='button'
                        size='large'
                        color='blue'
                        content={isFollowing ? 'Unfollow' : 'Follow'}
                        margin='5px'
                        onClick={() => {
                          isFollowing ? follow(false) : follow(true)
                        }}
                      />
                    )}
                    {userAdminOrOp &&
                      vat.state !== 'cancelled' &&
                      vat.state !== 'completed' && (
                      <Button
                        icon='cancel'
                        type='button'
                        size='large'
                        color='red'
                        content='Cancel declaration'
                        margin='5px'
                        onClick={() => setmodalCancelVat(true)}
                      />
                    )}

                    {
                      userAdminOrOp && 
                     (vat.state !== 'completed' && vat.state !== 'cancelled') &&              
                      (
                      <Button
                        type='button'
                        color='blue'
                        size='large'
                        margin='5px'
                        content='Close declaration'
                        onClick={() => closeVat(vat.id)}
                      />
                    )}
                </Grid>
                )}
                {!vat.errorMessage && (
                  <>
                    <Divider hidden />
                    <Divider />                 
                    <ResponsiveWrapper>                              
                        <Wrapper margin='5px' width='100%'>                    
                            <Wrapper display='flex' justifyContent='space-between'>
                            <Header>Documents</Header>
                            {vat.state !== 'cancelled' && (
                              <Button
                                icon='plus'
                                type='button'
                                color='blue'
                                content='Add documents'
                                disabled={vat.state === 'completed'}
                                onClick={() =>  setmodalAddDocumentsStatus(prevState => ({
                                  ...prevState,
                                  open: true,
                                  type: 1
                                }))}
                              />
                            )}
                          </Wrapper>                       
                          <Table striped columns='6' selectable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>File name</Table.HeaderCell>
                                <Table.HeaderCell>Category</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Control</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            {
                             (vat.TaxFlowDocuments.length===0 &&
                               <EmptyTable colNumber={6} />
                             ) || (vat.TaxFlowDocuments && vat.TaxFlowDocuments.map(
                                 (document, index) => 
                                 (                                                      
                                  <VatDocuments
                                    key={index}
                                    userAdminOrOp={userAdminOrOp}
                                    document={document}
                                    companyId={companyId}
                                    declarationId={vat.id}
                                    declarationState={vat.state === 'processing'}
                                    index={index}
                                    handleShowDocument={handleShowDocument}
                                    handleDeleteDocument={handleDeleteDocument} 
                                    handleCheckDocument={handleCheckDocument}
                                    // openTagManager={() => {                                                     
                                    //     setTagFilterManagerModal(prevState => ({
                                    //       ...prevState,
                                    //       open: true, 
                                    //       document: document,                       
                                    //       declarationId: vat.id || null,
                                    //     }))
                                    //   }}
                                  />
                                 )))}
                            </Table.Body>
                          </Table> 
                        </Wrapper>                                                        
                    </ResponsiveWrapper>
                    </>                
                    )}
                    {vat.errorMessage && (
                      <Message error>
                        <Message.Header>Error</Message.Header>
                        <Divider hidden />
                        {vat.errorMessage}
                      </Message>
                    )}
                </>
              )}
              </>
              )}
              </Wrapper>
              <Wrapper width='40%' minHeight='767px' padding='0 0 0 20px'>
              {(userAdminOrOp || isOwner) && (
                    <VatChat
                      messages={chat}
                      chatLoading={chatLoading}
                      isOwner={isOwner}
                      userRole={
                        user.operator
                          ? 'operator'
                          : !user.operator && !user.admin
                          ? 'customer'
                          : null
                      }
                      handleSubmitMessage={handleSubmitMessage}
                    />
                  )                        
              } 
             
              <Divider hidden/>
              <Divider hidden/>           
            </Wrapper>                
            </Wrapper>     
        </Wrapper>  
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    vat: selectors.fiscalVat.oneDeclarationSelector(state),
    VatLoading: selectors.fiscalVat.getOneDeclarationLoadingSelector(state),
    chat: selectors.chat.getAllMsgSelector(state),
    chatLoading: selectors.chat.getAllMsgLoadingSelector(state),
    companySelected: selectors.auth.companySelected(state),
    deleteDocumentLoading: selectors.fiscalVat.deleteDocumentLoadingSelector(state),
    addDocumentLoading: selectors.fiscalVat.addDocumentLoadingSelector(state),
    downloadLoading: selectors.fiscalVat.downloadLoadingSelector(state),
    categoriesDocuments: selectors.fiscalVat.getCategoriesSelector(state),
    categoriesDocListLoading: selectors.fiscalVat.getCategoriesLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
