import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  const companyApi = routesApi.company;
  return {
    getAllTransporter() {
      return api.request({
        method: companyApi.getAllTransporter.method,
        route: companyApi.getAllTransporter.path,
      });
    },
    hasSubscribedTo(data: dataReqI) {
      return api.request({
        method: companyApi.hasSubscribedTo.method,
        route: companyApi.hasSubscribedTo.path,
        params: data.params,
      });
    },
    getAll() {
      return api.request({
        method: companyApi.getAll.method,
        route: companyApi.getAll.path,
      });
    },
    getPermissions(data: dataReqI) {
      return api.request({
        method: companyApi.getPermissions.method,
        route: companyApi.getPermissions.path,
        params: data.params,
      });
    },
    getOne(data: dataReqI) {
      return api.request({
        method: companyApi.getOne.method,
        route: companyApi.getOne.path,
        params: data.params,
      });
    },
    create(data: dataReqI) {
      return api.request({
        method: companyApi.create.method,
        route: companyApi.create.path,
        data: data.data,
      });
    },

    update(data: dataReqI) {
      return api.request({
        method: companyApi.update.method,
        route: companyApi.update.path,
        params: data.params,
        data: data.data,
      });
    },
    remove(data: dataReqI) {
      return api.request({
        method: companyApi.remove.method,
        route: companyApi.remove.path,
        params: data.params,
      });
    },
  };
};
