import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Spacer from 'components/core/Spacer/Spacer';
import authPath from 'routes/auth/paths';
import images from 'assets/images';

const Index = ({ 
  dispatch, 
  history, 
  intl, 
  match,
  validationSuccess,
  validation,
}) => {

  useEffect(() => {
    if (match.params.uniqueNumber) {
      dispatch(
        actions.contact.validateContactPartner({ params: { token: match.params.uniqueNumber } })
      );
      if(validationSuccess || validation){
        history.push('/companies')
      }
    }
  }, []);

  const handleSave = () => {
    history.push('/companies');
  };

  return (
    <Wrapper
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <ImageCustom
        src={images.logo_asd_color}
        alt='Logo ASD'
        margin='50px'
        maxWidth='20em'
      />

    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    validation: selectors.contact.verifyContactPartnerSelector(state),
    validationSuccess: selectors.contact.successSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
