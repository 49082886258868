// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// Components
import EditButton from '../editButton/editButton';
import PdfViewer from '../pdfViewer/pdfViewer';

// Resources
import downloadLogo from '../../assets/images/logos/download.png';
import checkLogo from '../../assets/images/logos/check.png';

// Stylesheets
import './documentSlot.css';

interface DocumentSlotProperties {
  entitled: string;
  fileType: string;
  fileSlot: number;
  isConstructionCardSlot?: boolean;
  employeeId: number;
  employeeName: string;
}

const DocumentSlot: React.FC<DocumentSlotProperties> = ({
  entitled,
  fileType,
  fileSlot,
  isConstructionCardSlot,
  employeeId,
  employeeName,
}) => {
  const { t } = useTranslation();

  const [
    editRelatedDocumentPopupIsVisible,
    setEditRelatedDocumentPopupIsVisible,
  ] = useState<boolean>(false);
  const [isDocumentExists, setIsDocumentExists] = useState<boolean>();
  const [downloadUrl, setDownloadUrl] = useState<string>();

  const editRelatedDocumentButtonRef = useRef<HTMLButtonElement>(null);
  const editRelatedDocumentPopupRef = useRef<HTMLDivElement>(null);

  const handleClickEditEmployeeRelatedDocuments = () => {
    setEditRelatedDocumentPopupIsVisible(!editRelatedDocumentPopupIsVisible);
  };

  const handleClickDownload = async () => {
    let filePath = downloadUrl;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/downloadEmployeeDocument`,
        { filePath }, // Envoyer le nom du fichier dans le corps de la requête
        {
          responseType: 'blob', // Attendre une réponse de type blob (fichier binaire)
        }
      );
      const fileType = response.headers['content-type']; // Récupérer le type MIME depuis les headers
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: fileType })
      );

      window.open(url, '_blank');
    } catch (error) {
      console.error('Error during file download:', error);
    }
  };

  const getEmployeeDocumentsList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getDocuments`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: employeeId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        switch (fileSlot) {
          case 1:
            if (result.data.identityDocument == null) {
              setIsDocumentExists(false);
            } else {
              setIsDocumentExists(true);
              setDownloadUrl(result.data.identityDocument);
            }
            break;
          case 2:
            if (result.data.identityPhoto == null) {
              setIsDocumentExists(false);
            } else {
              setIsDocumentExists(true);
              setDownloadUrl(result.data.identityPhoto);
            }
            break;
          case 3:
            if (result.data.constructionCard == null) {
              setIsDocumentExists(false);
            } else {
              setIsDocumentExists(true);
              setDownloadUrl(result.data.constructionCard);
            }
            break;
          case 4:
            if (result.data.medicalCertificate == null) {
              setIsDocumentExists(false);
            } else {
              setIsDocumentExists(true);
              setDownloadUrl(result.data.medicalCertificate);
            }
            break;
          case 5:
            if (result.data.a1Form == null) {
              setIsDocumentExists(false);
            } else {
              setIsDocumentExists(true);
              setDownloadUrl(result.data.a1Form);
            }
            break;
          case 6:
            if (result.data.employementContract == null) {
              setIsDocumentExists(false);
            } else {
              setIsDocumentExists(true);
              setDownloadUrl(result.data.employementContract);
            }
            break;
          default:
            setIsDocumentExists(false);
            return;
        }
        return result;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  useEffect(() => {
    async function exec() {
      await getEmployeeDocumentsList();
    }

    exec();
  }, [isDocumentExists]);

  return (
    <div className='employeeDocumentSlot'>
      <div className='documentSlotEntitled'>
        <p>{entitled}</p>
      </div>

      {isDocumentExists ? (
        <div className='checkLogoContainer'>
          <img src={checkLogo} alt='Check logo' className='checkLogo' />
        </div>
      ) : (
        <></>
      )}

      <div
        className={
          isDocumentExists
            ? 'documentSlotDetails'
            : 'documentSlotDetailsWithoutDocument'
        }
      >
        {isDocumentExists ? (
          <div className='downloadButtonContainer'>
            <button className='downloadButton' onClick={handleClickDownload}>
              <p>{t('globalStrings.downloadButtonTitle')}</p>
              <img
                src={downloadLogo}
                alt='Download logo'
                className='downloadLogo'
              />
            </button>
          </div>
        ) : (
          <i>{t('globalStrings.noLoadedDocument')}</i>
        )}
      </div>

      <div className='documentSlotEditButton'>
        <EditButton
          popupVisible={editRelatedDocumentPopupIsVisible}
          myRef={editRelatedDocumentButtonRef}
          popupRef={editRelatedDocumentPopupRef}
          title={t('documentSlotPopupStrings.title')}
          action={handleClickEditEmployeeRelatedDocuments}
          children={
            <div className='updateDocument'>
              {isConstructionCardSlot ? (
                <PdfViewer
                  fileType={fileType}
                  isConstructionCardViewer={true}
                  fileSlot={fileSlot}
                  employeeId={employeeId}
                  employeeName={employeeName}
                />
              ) : (
                <PdfViewer
                  fileType={fileType}
                  fileSlot={fileSlot}
                  employeeId={employeeId}
                  employeeName={employeeName}
                />
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default DocumentSlot;
