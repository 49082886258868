import { resetState } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    subscribe: null,
    unsubscribe: null,
    getAllIntrastats: null,
    getOneIntrastat: null,
    updateIntrastat: null,
    createIntrastat: null,
    step: null,
    follow: null,
    unfollow: null,
    docs: null,
    docsFromCat: null,
    deleteDoc: null,
    downloadDoc: null,
    getCompanySubscribed: null,
    tag: null,
    archiveFiles: null,
    archiveFile: null,
    singleFile: null,
    filterTag: null,
    archiveAll: null,
    bulkFilter: null,
    groupFiles: null,
    split: null,
    renameSendChunk: null,
    filterTagArchives: null,
    perms: null,
    deleteFiles: null,
    filesState: null,
    getByFilters: null,
    updateDoc: null,
    settings: null,
    getSettings: null,
    none:  null,
    categories: null,
  },
  errors: {
    subscribe: null,
    unsubscribe: null,
    getAllIntrastats: null,
    getOneIntrastat: null,
    updateIntrastat: null,
    createIntrastat: null,
    step: null,
    follow: null,
    unfollow: null,
    docs: null,
    docsFromCat: null,
    deleteDoc: null,
    downloadDoc: null,
    getCompanySubscribed: null,
    tag: null,
    archiveFiles: null,
    archiveFile: null,
    singleFile: null,
    filterTag: null,
    archiveAll: null,
    bulkFilter: null,
    split: null,
    renameSendChunk: null,
    groupFiles: null,
    filterTagArchives: null,
    perms: null,
    deleteFiles: null,
    filesState: null,
    getByFilters: null,
    updateDoc: null,
    settings: null,
    getSettings: null,
    none:  null,
    categories: null,
  },
  loading: {
    subscribe: false,
    unsubscribe: false,
    getAllIntrastats: false,
    getOneIntrastat: false,
    updateIntrastat: false,
    createIntrastat: false,
    step: false,
    follow: false,
    unfollow: false,
    docs: false,
    docsFromCat: false,
    deleteDoc: false,
    downloadDoc: false,
    getCompanySubscribed: false,
    tag: false,
    archiveFiles: false,
    archiveFile: false,
    singleFile: false,
    filterTag: false,
    archiveAll: false,
    bulkFilter: false,
    split: false,
    renameSendChunk: false,
    groupFiles: false,
    filterTagArchives: false,
    perms: false,
    deleteFiles: false,
    filesState: false,
    getByFilters: false,
    updateDoc: false,
    settings: false,
    getSettings: false,
    none: false,
    categories: false,
  },
  subscribe: null,
  unsubscribe: null,
  getAllIntrastats: null,
  oneIntrastat: null,
  updateIntrastat: null,
  createIntrastat: null,
  step: null,
  follow: null,
  unfollow: null,
  docs: null,
  docsFromCat: null,
  deleteDoc: null,
  downloadDoc: null,
  companySubscribed: null,
  tag: null,
  archiveFiles: null,
  archiveFile: null,
  singleFile: null,
  filterTag: null,
  archiveAll: null,
  bulkFilter: null,
  split: null,
  renameSendChunk: null,
  groupFiles: null,
  filterTagArchives: null,
  perms: null,
  none: null,
  deleteFiles: null,
  filesState: null,
  getByFilters: null,
  updateDoc: null,
  settings: null,
  getSettings: null,
  categories: null,
  lang: null,
  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    // get categories
    case actionTypes.LANG:
      return produce(state, (draft) => {
        draft.lang = action.payload;
      });

     // get categories
     case actionTypes.GET_INTRASTAT_CATEGORIES_LOADING:
      return produce(state, (draft) => {
        draft.loading.categories = true;
      });
    case actionTypes.GET_INTRASTAT_CATEGORIES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.categories = null;
        draft.loading.categories = false;
        draft.success.categories = true;
        draft.categories = action.payload;
      });
    case actionTypes.GET_INTRASTAT_CATEGORIES_ERROR:
      return produce(state, (draft) => {
        draft.errors.categories = true;
        draft.loading.categories = false;
        draft.success.categories = false;
        !draft.error.includes('categories') 
        && draft.error.push('categories');
      });

      // create settings
      case actionTypes.SETTINGS_GET_LOADING:
        return produce(state, (draft) => {
          draft.loading.getSettings = true;
        });
      case actionTypes.SETTINGS_GET_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.getSettings = null;
          draft.loading.getSettings = false;
          draft.success.getSettings = true;
          draft.getSettings = action.payload;
        });
      case actionTypes.SETTINGS_GET_ERROR:
        return produce(state, (draft) => {
          draft.errors.getSettings = true;
          draft.loading.getSettings = false;
          draft.success.getSettings = false;
          !draft.error.includes('getSettings') 
          && draft.error.push('getSettings');
        });

     // create settings
     case actionTypes.SETTINGS_LOADING:
      return produce(state, (draft) => {
        draft.loading.settings = true;
      });
    case actionTypes.SETTINGS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.settings = null;
        draft.loading.settings = false;
        draft.success.settings = true;
        draft.settings = action.payload;
      });
    case actionTypes.SETTINGS_ERROR:
      return produce(state, (draft) => {
        draft.errors.settings = true;
        draft.loading.settings = false;
        draft.success.settings = false;
        !draft.error.includes('settings') 
        && draft.error.push('settings');
      });

     // company with subscriptions
     case actionTypes.UPDATE_DOC_LOADING:
      return produce(state, (draft) => {
        draft.loading.updateDoc = true;
      });
    case actionTypes.UPDATE_DOC_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateDoc = null;
        draft.loading.updateDoc = false;
        draft.success.updateDoc = true;
        draft.updateDoc = action.payload;
      });
    case actionTypes.UPDATE_DOC_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateDoc = true;
        draft.loading.updateDoc = false;
        draft.success.updateDoc = false;
        !draft.error.includes('updateDoc') 
        && draft.error.push('updateDoc');
      });

     // delete files
     case actionTypes.GET_FILTERED_INTRASTATS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getByFilters = true;
      });
    case actionTypes.GET_FILTERED_INTRASTATS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getByFilters = null;
        draft.loading.getByFilters = false;
        draft.success.getByFilters = true;
        draft.getByFilters = action.payload;
      });
    case actionTypes.GET_FILTERED_INTRASTATS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getByFilters = true;
        draft.loading.getByFilters = false;
        draft.success.getByFilters = false;
        !draft.error.includes('getByFilters') &&
          draft.error.push('getByFilters');
      });

     // delete files
     case actionTypes.FILES_STATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.filesState = true;
      });
    case actionTypes.FILES_STATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.filesState = null;
        draft.loading.filesState = false;
        draft.success.filesState = true;
      });
    case actionTypes.FILES_STATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.filesState = true;
        draft.loading.filesState = false;
        draft.success.filesState = false;
        !draft.error.includes('filesState') &&
          draft.error.push('filesState');
      });

     // delete files
     case actionTypes.DELETE_FILES_LOADING:
      return produce(state, (draft) => {
        draft.loading.deleteFiles = true;
      });
    case actionTypes.DELETE_FILES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteFiles = null;
        draft.loading.deleteFiles = false;
        draft.success.deleteFiles = true;
      });
    case actionTypes.DELETE_FILES_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteFiles = true;
        draft.loading.deleteFiles = false;
        draft.success.deleteFiles = false;
        !draft.error.includes('deleteFiles') &&
          draft.error.push('deleteFiles');
      });

     // session files
     case actionTypes.CREATE_NONE_INTRASTAT_LOADING:
      return produce(state, (draft) => {
        draft.loading.none = true;
      });
    case actionTypes.CREATE_NONE_INTRASTAT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.none = null;
        draft.loading.none = false;
        draft.success.none = true;
      });
    case actionTypes.CREATE_NONE_INTRASTAT_ERROR:
      return produce(state, (draft) => {
        draft.errors.none = true;
        draft.loading.none = false;
        draft.success.none = false;
        !draft.error.includes('none') &&
          draft.error.push('none');
      });


     // session files
     case actionTypes.GET_PERMS_LOADING:
      return produce(state, (draft) => {
        draft.loading.perms = true;
      });
    case actionTypes.GET_PERMS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.perms = null;
        draft.loading.perms = false;
        draft.success.perms = true;
        draft.perms = action.payload;
      });
    case actionTypes.GET_PERMS_ERROR:
      return produce(state, (draft) => {
        draft.errors.perms = true;
        draft.loading.perms = false;
        draft.success.perms = false;
        !draft.error.includes('perms') &&
          draft.error.push('perms');
      });


     // session files
     case actionTypes.GET_ARCHIVE_FILE_LOADING:
      return produce(state, (draft) => {
        draft.loading.archiveFile = true;
      });
    case actionTypes.GET_ARCHIVE_FILE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.archiveFile = null;
        draft.loading.archiveFile = false;
        draft.success.archiveFile = true;
        draft.archiveFile = action.payload;
      });
    case actionTypes.GET_ARCHIVE_FILE_ERROR:
      return produce(state, (draft) => {
        draft.errors.archiveFile = true;
        draft.loading.archiveFile = false;
        draft.success.archiveFile = false;
        !draft.error.includes('archiveFile') &&
          draft.error.push('archiveFile');
      });

     // session files
     case actionTypes.GET_ARCHIVE_FILES_LOADING:
      return produce(state, (draft) => {
        draft.loading.archiveFiles = true;
      });
    case actionTypes.GET_ARCHIVE_FILES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.archiveFiles = null;
        draft.loading.archiveFiles = false;
        draft.success.archiveFiles = true;
        draft.archiveFiles = action.payload;
      });
    case actionTypes.GET_ARCHIVE_FILES_ERROR:
      return produce(state, (draft) => {
        draft.errors.archiveFiles = true;
        draft.loading.archiveFiles = false;
        draft.success.archiveFiles = false;
        !draft.error.includes('archiveFiles') &&
          draft.error.push('archiveFiles');
      });

      // filterTagArchive
      case actionTypes.FILTER_TAG_ARCHIVES_LOADING:
        return produce(state, (draft) => {
          draft.loading.filterTagArchives = true;
        });
      case actionTypes.FILTER_TAG_ARCHIVES_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.filterTagArchives = null;
          draft.loading.filterTagArchives = false;
          draft.success.filterTagArchives = true;
          draft.filterTagArchives = action.payload;
        });
      case actionTypes.FILTER_TAG_ARCHIVES_ERROR:
        return produce(state, (draft) => {
          draft.errors.filterTagArchives = true;
          draft.loading.filterTagArchives = false;
          draft.success.filterTagArchives = false;
          !draft.error.includes('filterTagArchives') &&
            draft.error.push('filterTagArchives');
        });

     // groupFiles
     case actionTypes.GROUP_FILES_LOADING:
      return produce(state, (draft) => {
        draft.loading.groupFiles = true;
      });
    case actionTypes.GROUP_FILES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.groupFiles = null;
        draft.loading.groupFiles = false;
        draft.success.groupFiles = true;
        draft.groupFiles = action.payload;
      });
    case actionTypes.GROUP_FILES_ERROR:
      return produce(state, (draft) => {
        draft.errors.groupFiles = true;
        draft.loading.groupFiles = false;
        draft.success.groupFiles = false;
        !draft.error.includes('groupFiles') &&
          draft.error.push('groupFiles');
      });

     // renameSendChunk
     case actionTypes.RENAME_SEND_CHUNCKS_LOADING:
      return produce(state, (draft) => {
        draft.loading.renameSendChunk = true;
      });
    case actionTypes.RENAME_SEND_CHUNCKS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.renameSendChunk = null;
        draft.loading.renameSendChunk = false;
        draft.success.renameSendChunk = true;
        draft.renameSendChunk = action.payload;
      });
    case actionTypes.RENAME_SEND_CHUNCKS_ERROR:
      return produce(state, (draft) => {
        draft.errors.renameSendChunk = true;
        draft.loading.renameSendChunk = false;
        draft.success.renameSendChunk = false;
        !draft.error.includes('renameSendChunk') &&
          draft.error.push('renameSendChunk');
      });


     // split
     case actionTypes.SPLIT_DOCUMENT_LOADING:
      return produce(state, (draft) => {
        draft.loading.split = true;
      });
    case actionTypes.SPLIT_DOCUMENT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.split = null;
        draft.loading.split = false;
        draft.success.split = true;
        draft.split = action.payload;
      });
    case actionTypes.SPLIT_DOCUMENT_ERROR:
      return produce(state, (draft) => {
        draft.errors.split = true;
        draft.loading.split = false;
        draft.success.split = false;
        !draft.error.includes('split') &&
          draft.error.push('split');
      });

     // bulk filter
     case actionTypes.BULK_FILTER_LOADING:
      return produce(state, (draft) => {
        draft.loading.bulkFilter = true;
      });
    case actionTypes.BULK_FILTER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.bulkFilter = null;
        draft.loading.bulkFilter = false;
        draft.success.bulkFilter = true;
        draft.bulkFilter = action.payload;
      });
    case actionTypes.BULK_FILTER_ERROR:
      return produce(state, (draft) => {
        draft.errors.bulkFilter = true;
        draft.loading.bulkFilter = false;
        draft.success.bulkFilter = false;
        !draft.error.includes('bulkFilter') &&
          draft.error.push('bulkFilter');
      });

     // filter tag
     case actionTypes.ARCHIVE_ALL_LOADING:
      return produce(state, (draft) => {
        draft.loading.archiveAll = true;
      });
    case actionTypes.ARCHIVE_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.archiveAll = null;
        draft.loading.archiveAll = false;
        draft.success.archiveAll = true;
        draft.archiveAll = action.payload;
      });
    case actionTypes.ARCHIVE_ALL_ERROR:
      return produce(state, (draft) => {
        draft.errors.archiveAll = true;
        draft.loading.archiveAll = false;
        draft.success.archiveAll = false;
        !draft.error.includes('archiveAll') &&
          draft.error.push('archiveAll');
      });

     // filter tag
     case actionTypes.FILTER_TAG_LOADING:
      return produce(state, (draft) => {
        draft.loading.filterTag = true;
      });
    case actionTypes.FILTER_TAG_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.filterTag = null;
        draft.loading.filterTag = false;
        draft.success.filterTag = true;
        draft.filterTag = action.payload;
      });
    case actionTypes.FILTER_TAG_ERROR:
      return produce(state, (draft) => {
        draft.errors.filterTag = true;
        draft.loading.filterTag = false;
        draft.success.filterTag = false;
        !draft.error.includes('filterTag') &&
          draft.error.push('filterTag');
      });

     // create single file
     case actionTypes.SINGLE_FILE_LOADING:
      return produce(state, (draft) => {
        draft.loading.singleFile = true;
      });
    case actionTypes.SINGLE_FILE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.singleFile = null;
        draft.loading.singleFile = false;
        draft.success.singleFile = true;
        draft.singleFile = action.payload;
      });
    case actionTypes.SINGLE_FILE_ERROR:
      return produce(state, (draft) => {
        draft.errors.singleFile = true;
        draft.loading.singleFile = false;
        draft.success.singleFile = false;
        !draft.error.includes('singleFile') &&
          draft.error.push('singleFile');
      });



     // tag
     case actionTypes.TAG_OPERATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.tag = true;
      });
    case actionTypes.TAG_OPERATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.tag = null;
        draft.loading.tag = false;
        draft.success.tag = true;
        draft.tag = action.payload;
      });
    case actionTypes.TAG_OPERATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.tag = true;
        draft.loading.tag = false;
        draft.success.tag = false;
        !draft.error.includes('tag') &&
          draft.error.push('tag');
      });

     // getCompanySubscribed
     case actionTypes.GET_COMPANY_SUBSCRIBED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getCompanySubscribed = true;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = null;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = true;
        draft.companySubscribed = action.payload;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = true;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = false;
        !draft.error.includes('companySubscribed') &&
          draft.error.push('companySubscribed');
      });

     // deb delete docs
     case actionTypes.DOWNLOAD_DOC_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadDoc = true;
      });
    case actionTypes.DOWNLOAD_DOC_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadDoc = null;
        draft.loading.downloadDoc = false;
        draft.success.downloadDoc = true;
        draft.downloadDoc = action.payload;
      });
    case actionTypes.DOWNLOAD_DOC_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadDoc = true;
        draft.loading.downloadDoc = false;
        draft.success.downloadDoc = false;
        !draft.error.includes('downloadDoc') && 
        draft.error.push('downloadDoc');
      });

     // deb delete docs
     case actionTypes.DELETE_DOC_LOADING:
      return produce(state, (draft) => {
        draft.loading.deleteDoc = true;
      });
    case actionTypes.DELETE_DOC_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteDoc = null;
        draft.loading.deleteDoc = false;
        draft.success.deleteDoc = true;
      });
    case actionTypes.DELETE_DOC_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteDoc = true;
        draft.loading.deleteDoc = false;
        draft.success.deleteDoc = false;
        !draft.error.includes('deleteDoc') && 
        draft.error.push('deleteDoc');
      });

     // deb delete docs
     case actionTypes.INTRASTAT_GET_DOC_CAT_LOADING:
      return produce(state, (draft) => {
        draft.loading.docsFromCat = true;
      });
    case actionTypes.INTRASTAT_GET_DOC_CAT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.docsFromCat = null;
        draft.loading.docsFromCat = false;
        draft.success.docsFromCat = true;
        draft.docsFromCat = action.payload;
      });
    case actionTypes.INTRASTAT_GET_DOC_CAT_ERROR:
      return produce(state, (draft) => {
        draft.errors.docsFromCat = true;
        draft.loading.docsFromCat = false;
        draft.success.docsFromCat = false;
        !draft.error.includes('docsFromCat') && 
        draft.error.push('docsFromCat');
      });


      // deb add docs
      case actionTypes.INTRASTAT_ADD_DOCUMENTS_LOADING:
        return produce(state, (draft) => {
          draft.loading.docs = true;
        });
      case actionTypes.INTRASTAT_ADD_DOCUMENTS_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.docs = null;
          draft.loading.docs = false;
          draft.success.docs = true;
          draft.docs = action.payload;
        });
      case actionTypes.INTRASTAT_ADD_DOCUMENTS_ERROR:
        return produce(state, (draft) => {
          draft.errors.docs = true;
          draft.loading.docs = false;
          draft.success.docs = false;
          !draft.error.includes('docs') && 
          draft.error.push('docs');
        });
  
  

     // post deb
     case actionTypes.UNFOLLOW_INTRASTAT_LOADING:
      return produce(state, (draft) => {
        draft.loading.unfollow = true;
      });
    case actionTypes.UNFOLLOW_INTRASTAT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unfollow = null;
        draft.loading.unfollow = false;
        draft.success.unfollow = true;
      });
    case actionTypes.UNFOLLOW_INTRASTAT_ERROR:
      return produce(state, (draft) => {
        draft.errors.unfollow = true;
        draft.loading.unfollow = false;
        draft.success.unfollow = false;
        !draft.error.includes('unfollow') && 
        draft.error.push('unfollow');
      });


     // post deb
     case actionTypes.FOLLOW_INTRASTAT_LOADING:
      return produce(state, (draft) => {
        draft.loading.follow = true;
      });
    case actionTypes.FOLLOW_INTRASTAT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.follow = null;
        draft.loading.follow = false;
        draft.success.follow = true;
      });
    case actionTypes.FOLLOW_INTRASTAT_ERROR:
      return produce(state, (draft) => {
        draft.errors.follow = true;
        draft.loading.follow = false;
        draft.success.follow = false;
        !draft.error.includes('follow') && 
        draft.error.push('follow');
      });

     // post deb
     case actionTypes.UPDATE_STEP_LOADING:
      return produce(state, (draft) => {
        draft.loading.step = true;
      });
    case actionTypes.UPDATE_STEP_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.step = null;
        draft.loading.step = false;
        draft.success.step = true;
      });
    case actionTypes.UPDATE_STEP_ERROR:
      return produce(state, (draft) => {
        draft.errors.step = true;
        draft.loading.step = false;
        draft.success.step = false;
        !draft.error.includes('step') && 
        draft.error.push('step');
      });

     // post deb
     case actionTypes.CREATE_INTRASTAT_LOADING:
      return produce(state, (draft) => {
        draft.loading.createIntrastat = true;
      });
    case actionTypes.CREATE_INTRASTAT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createIntrastat = null;
        draft.loading.createIntrastat = false;
        draft.success.createIntrastat = true;
      });
    case actionTypes.CREATE_INTRASTAT_ERROR:
      return produce(state, (draft) => {
        draft.errors.createIntrastat = true;
        draft.loading.createIntrastat = false;
        draft.success.createIntrastat = false;
        !draft.error.includes('createIntrastat') && 
        draft.error.push('createIntrastat');
      });

     // update debs
    case actionTypes.UPDATE_INTRASTAT_LOADING:
      return produce(state, draft => {
        draft.loading.updateIntrastat = true;
      });
    case actionTypes.UPDATE_INTRASTAT_SUCCESS:
      return produce(state, draft => {
        draft.errors.updateIntrastat = null;
        draft.loading.updateIntrastat = false;
        draft.success.updateIntrastat = true;
      });
    case actionTypes.UPDATE_INTRASTAT_ERROR:
      return produce(state, draft => {
        draft.errors.updateIntrastat = true;
        draft.loading.updateIntrastat = false;
        draft.success.updateIntrastat = false;
        !draft.error.includes("updateIntrastat") && 
        draft.error.push("updateIntrastat");
      });


    // Get all debs
    case actionTypes.GET_ALL_INTRASTATS_LOADING:
      return produce(state, draft => {
        draft.loading.getAllIntrastats = true;
      });
    case actionTypes.GET_ALL_INTRASTATS_SUCCESS:
      return produce(state, draft => {
        draft.errors.getAllIntrastats = null;
        draft.loading.getAllIntrastats = false;
        draft.success.getAllIntrastats = true;
        draft.getAllIntrastats = action.payload;
      });
    case actionTypes.GET_ALL_INTRASTATS_ERROR:
      return produce(state, draft => {
        draft.errors.getAllIntrastats = true;
        draft.loading.getAllIntrastats = false;
        draft.success.getAllIntrastats = false;
        !draft.error.includes("getAllIntrastats") && 
        draft.error.push("getAllIntrastats");
      });

     // Get one deb
    case actionTypes.GET_ONE_INTRASTAT_LOADING:
      return produce(state, draft => {
        draft.loading.getOneIntrastat = true;
      });
    case actionTypes.GET_ONE_INTRASTAT_SUCCESS:
      return produce(state, draft => {
        draft.errors.getOneIntrastat = null;
        draft.loading.getOneIntrastat = false;
        draft.success.getOneIntrastat = true;
        draft.oneIntrastat = action.payload;
      });
    case actionTypes.GET_ONE_INTRASTAT_ERROR:
      return produce(state, draft => {
        draft.errors.getOneIntrastat = true;
        draft.loading.getOneIntrastat = false;
        draft.success.getOneIntrastat = false;
        !draft.error.includes("oneIntrastat") && 
        draft.error.push("oneIntrastat");
      });

    //Subscribe
    case actionTypes.SUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.subscribe = null;
        draft.loading.subscribe = false;
        draft.success.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.subscribe = true;
        draft.loading.subscribe = false;
        draft.success.subscribe = false;
      });

    //Unsubscribe
    case actionTypes.UNSUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = null;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = true;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = false;
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
