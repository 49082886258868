import styled from '@emotion/styled';

import theme from 'theme';

const { colors } = theme.getTheme();

export const InputWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const InputStyled = styled.input(({ error, hide }: InputStyledProps) => ({
  borderRadius: '10px',
  border: `1px solid ${error ? colors.borderError : 'rgb(204, 204, 204)'}`,
  padding: '0.67857143em 1em',
  marginTop: '0.2em',
  lineHeight: '1.21428571em',
  ...(hide && {display: 'none'}),
  ...(error && { backgroundColor: colors.backgroundError }),
  ...(error && { color: colors.textError }),

  '&::placeholder': {
    color: 'rgba(191,191,191,.87)',
    ...(error && { color: colors.borderError }),
  },

  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
    //boxShadow: '0px 0px 3px #c5c5c5',
  },
}));

interface InputStyledProps {
  error: string;
  hide?: boolean;
}
