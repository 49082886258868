import styled from "@emotion/styled";

export const Company = styled.div(() => ({
    'height': '48px',
    'lineHeight': '48px'
  }))

  export const MinHeight = {'min-height': '50px'}
  export const Cancelled = 'red'
  export const Processing = '#dda80b'
  export const Unprocessed = '#00619b'