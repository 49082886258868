import React from "react";
import { Table } from "semantic-ui-react";

const Index = ({ colNumber }) => {
  return (
    <Table.Row>
      <Table.Cell colSpan={colNumber} warning textAlign="center" singleLine>
        Empty
      </Table.Cell>
    </Table.Row>
  );
};

export default Index;
