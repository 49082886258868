import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  getCompanySubscribed() {
    return {
      type: actionTypes.GET_COMPANY_SUBSCRIBED,
    };
  },
  subscribe(data: dataReqI) {
    return {
      type: actionTypes.SUBSCRIBE,
      payload: data,
    };
  },
  unsubscribe(data: dataReqI) {
    return {
      type: actionTypes.UNSUBSCRIBE,
      payload: data,
    };
  },
  getIntegrations() {
    return {
      type: actionTypes.GET_OSS_INTEGRATIONS,
    };
  },
  getCF(data: dataReqI) {
    return {
      type: actionTypes.GET_CF,
      payload: data,
    };
  },
  registerIntegration(data: dataReqI) {
    return {
      type: actionTypes.REGISTER_INTEGRATION,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
