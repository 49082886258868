import { resetState } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    threshold: null,
    thresholdCreate: null,
    thresholdUpdate: null,
    thresholdDelete: null,
  },
  errors: {
    threshold: null,
    thresholdCreate: null,
    thresholdUpdate: null,
    thresholdDelete: null,
  },
  loading: {
    threshold: false,
    thresholdCreate: false,
    thresholdUpdate: false,
    thresholdDelete: false,
  },

  threshold: null,
  thresholdCreate: null,
  thresholdUpdate: null,
  thresholdDelete: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

     // Get
     case actionTypes.THRESHOLD_UPDATE:
      return produce(state, (draft) => {
        draft.loading.thresholdUpdate = true;
      });
    case actionTypes.THRESHOLD_UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.thresholdUpdate = null;
        draft.loading.thresholdUpdate = false;
        draft.success.thresholdUpdate = true;
        draft.thresholdUpdate = action.payload;
      });
    case actionTypes.THRESHOLD_UPDATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.thresholdUpdate = true;
        draft.loading.thresholdUpdate = false;
        draft.success.thresholdUpdate = false;
        draft.error.includes('thresholdUpdate') &&
          draft.error.push('thresholdUpdate');
      });
   
    // Get
    case actionTypes.GET_THRESHOLD:
      return produce(state, (draft) => {
        draft.loading.threshold = true;
      });
    case actionTypes.GET_THRESHOLD_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.threshold = null;
        draft.loading.threshold = false;
        draft.success.threshold = true;
        draft.threshold = action.payload;
      });
    case actionTypes.GET_THRESHOLD_ERROR:
      return produce(state, (draft) => {
        draft.errors.threshold = true;
        draft.loading.threshold = false;
        draft.success.threshold = false;
        draft.error.includes('threshold') &&
          draft.error.push('threshold');
      });

    // GetNumberReadNotifications
    case actionTypes.THRESHOLD_CREATE:
      return produce(state, (draft) => {
        draft.loading.thresholdCreate = true;
      });
    case actionTypes.THRESHOLD_CREATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.thresholdCreate = null;
        draft.loading.thresholdCreate = false;
        draft.success.thresholdCreate = true;
        draft.thresholdCreate = action.payload;
      });
    case actionTypes.THRESHOLD_CREATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.thresholdCreate = true;
        draft.loading.thresholdCreate = false;
        draft.success.thresholdCreate = false;
        draft.error.includes('thresholdCreate') &&
          draft.error.push('thresholdCreate');
      });

    // PostUpdateOneNotifation
    case actionTypes.THRESHOLD_DELETE:
      return produce(state, (draft) => {
        draft.loading.thresholdDelete = true;
      });
    case actionTypes.THRESHOLD_DELETE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.thresholdDelete = null;
        draft.loading.thresholdDelete = false;
        draft.success.thresholdDelete = true;
      });
    case actionTypes.THRESHOLD_DELETE_ERROR:
      return produce(state, (draft) => {
        draft.errors.thresholdDelete = true;
        draft.loading.thresholdDelete = false;
        draft.success.thresholdDelete = false;
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
