import React from 'react';
import { ErrorMessage, getIn } from 'formik';
import { Popup, Icon } from 'semantic-ui-react';

import { DropdownCustom as Dropdown } from './styles';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Error } from 'components/Form';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';

const Index = ({
  meta,
  placeholder,
  field,
  disabled,
  label,
  onChange,
  options,
  loading,
  uniqueError,
  error,
  multiple = false,
  mandatory,
  bulleted,
  bullMsg,
  bullAlert,
}: any) => {
  return (
    <Wrapper>
      <label>
        {label}
        {mandatory && <MandatoryFieldLabel />}
        {bulleted && (
          <Wrapper display='flex' float='right'>
            <Popup
              trigger={
                <Icon name='info circle' {...(bullAlert && { color: 'red' })} />
              }
              content={bullMsg}
              size='mini'
            />
          </Wrapper>
        )}
      </label>
      <Dropdown
        disabled={disabled}
        loading={loading}
        name={field.name}
        multiple={multiple}
        clearable
        fluid
        search
        selection
        placeholder={placeholder}
        options={options}
        value={field.value}
        onChange={(e, { name, value }) => {
          if (onChange) onChange(value);
          meta.setFieldValue(name, value);
        }}
        onBlur={(e, { name, value }) => {
          meta.setFieldTouched(name, value);
        }}
        error={
          (getIn(meta.touched, field.name) && getIn(meta.errors, field.name)
            ? true
            : false) ||
          error ||
          (uniqueError ? true : false)
        }
      />

      <ErrorMessage name={field.name} render={(msg) => <Error msg={msg} />} />
    </Wrapper>
  );
};

export default Index;
