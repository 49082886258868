import { routesApi } from "config/api";
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    getAllChats(data: dataReqI) {
      const chatApi = routesApi.chat.getAllMsgs;
      return api.request({
        method: chatApi.method,
        route: chatApi.path,
        params: data.params,
      });
    },
    postChats(data: dataReqI) {
      const chatApi = routesApi.chat.postMsgs;
      return api.request({
        method: chatApi.method,
        route: chatApi.path,
        params: data.params,
        data: data.data
      });
    },
    readChat(data: dataReqI) {
      const readChatApi = routesApi.chat.readChat;
      return api.request({
        method: readChatApi.method,
        route: readChatApi.path,
        params: data.params,
      });
    },
  };
};
