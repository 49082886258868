import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector,
} = selectors;

// Errors
const createSubscriptionPaymentErrorSelector = (state) =>
  state.payment.errors.createSubscriptionPayment;
const createTokenErrorSelector = (state) => state.payment.errors.createToken;
const updateTokenErrorSelector = (state) => state.payment.errors.updateToken;

const errorsSelector = createErrorsSelector([
  createSubscriptionPaymentErrorSelector,
  createTokenErrorSelector,
  updateTokenErrorSelector,
]);

// Loading
const createSubscriptionPaymentLoadingSelector = (state) =>
  state.payment.loading.createSubscriptionPayment;
const createTokenLoadingSelector = (state) => state.payment.loading.createToken;
const updateTokenLoadingSelector = (state) => state.payment.loading.updateToken;

const loadingSelector = createLoadingSelector([
  createSubscriptionPaymentLoadingSelector,
  createTokenLoadingSelector,
  updateTokenLoadingSelector,
]);

// Success
const createSubscriptionPaymentSuccessSelector = (state) =>
  state.payment.success.createSubscriptionPayment;
const createTokenSuccessSelector = (state) => state.payment.success.createToken;
const updateTokenSuccessSelector = (state) => state.payment.success.updateToken;

const successSelector = createSuccessSelector([
  createSubscriptionPaymentSuccessSelector,
  createTokenSuccessSelector,
  updateTokenSuccessSelector,
]);

// Error handler
const errorSelector = (state) => state.payment.error;
const createSubscriptionPaymentFormTokenSelector = (state) =>
  state.payment.createSubscriptionPaymentFormToken;
const createTokenFormTokenSelector = (state) =>
  state.payment.createTokenFormToken;
const updateTokenFormTokenSelector = (state) =>
  state.payment.updateTokenFormToken;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  errorSelector,
  createSubscriptionPaymentFormTokenSelector,
  createTokenFormTokenSelector,
  updateTokenFormTokenSelector,
};
