import React, { useEffect, useState } from 'react';
import { Divider } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Link } from 'react-router-dom';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import TextCustom from 'components/core/TextCustom/TextCustom';
import DateTimeHelper from '../../../helpers/DateTimeHelper/dateTimeHelper';
import { paths } from 'routes/customs/declaration';
import Loader from 'components/Loader';
import Button from 'components/Button';

const NewSummaryStep = ({
  dispatch,
  match,
  file,
  company,
  createdPdf,
  user,
  operatorData,
}: any) => {
  const [isSub, setisSub] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [hasValidationPermission, sethasValidationPermission] = useState(false);
  const dateTimeHelper = new DateTimeHelper();

  useEffect(() => {
    if (user?.operator) {
      dispatch(
        actions.operators.getOneOperator({
          params: { operatorId: user.id },
        })
      );
    }
    if (user?.admin) {
      sethasValidationPermission(true);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (operatorData) {
      if (operatorData.Permissions?.length > 0) {
        operatorData.Permissions.filter(
          (permission) =>
            permission.group === 'customs' && permission.action === 'invoicing'
        ).length > 0 && sethasValidationPermission(true);
      }
    }
  }, [operatorData]);

  useEffect(() => {
    const companyId = file?.CustomsDeclaration?.Company_Id;
    if (companyId) {
      dispatch(
        actions.company.getOne({
          params: { companyId },
        })
      );
    }
  }, [dispatch, file]);


  // download
  const handleDownloadPdf = (declarationId, sessionPath) => {
    dispatch(
      actions.customsClerk.getPdfFile({
        params: {
          sessionPath: sessionPath,
          declarationId: declarationId,
        },
      })
    );
  }

  useEffect(() => {
    const isSub = company?.Subscriptions?.find(
      (sub) => sub.Service.name === 'customsDeclaration'
    );
    setisSub(isSub ? true : false);
  }, [company]);

  const validate = (reOpen: string) => {
    const companyId = file?.CustomsDeclaration?.Company_Id;
    dispatch(
      actions.customsClerk.validateFileState({
        params: {
          companyId: companyId,
          fileId: match.params.fileId,
          reOpen: reOpen
        },
      })
    );
  };

  const genPdf = (declarationId) => {
    setisLoading(true)
    dispatch(
      actions.customsClerk.reset([
        'clerkControlPdfFile',
        'error',
      ])
    );
    dispatch(
      actions.customsClerk.createControlPDF({
        params: {
          fileId: match.params.fileId,
          companyId: company.id,
          declarationId: declarationId,
        },
      })
    );

  }

  useEffect(() => {
    if(createdPdf){
      setisLoading(false)
    }
  }, [createdPdf])


  const lock = () => {
    dispatch(
      actions.customsClerk.lock({
        params: {
          fileId: match.params.fileId,
        },
      })
    );
  };

  const unlock = () => {
    dispatch(
      actions.customsClerk.unlock({
        params: {
          fileId: match.params.fileId,
        },
      })
    );
  };

  return (
    <> 
      <Segment width='60%' margin='auto'>
        {(!file && <Loader />) || (
          <Wrapper margin='0.3rem 2rem' textAlign='left'>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 2rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Declaration id:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                {isSub ? (
                  <Link
                    to={paths.customsDeclarationDetails
                      .replace(':companyId', file.CustomsDeclaration.Company_Id)
                      .replace(':declarationId', file.CustomsDeclaration_Id)}
                  >
                    {file?.CustomsDeclaration_Id}
                  </Link>
                ) : (
                  <TextCustom wordWrap='break-word'>
                    {file?.CustomsDeclaration_Id}
                  </TextCustom>
                )}
              </Wrapper>
            </Wrapper>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 0.3rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Company name:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                <TextCustom wordWrap='break-word'>
                  {file?.CustomsDeclaration?.Company?.name}
                </TextCustom>
              </Wrapper>
            </Wrapper>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 0.3rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Company country:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                <TextCustom wordWrap='break-word'>
                  {file?.CustomsDeclaration?.Company?.country}
                </TextCustom>
              </Wrapper>
            </Wrapper>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 2rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Company email:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                <TextCustom wordWrap='break-word'>
                  {file?.CustomsDeclaration?.Company?.email}
                </TextCustom>
              </Wrapper>
            </Wrapper>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 0.3rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Payment method:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                <TextCustom wordWrap='break-word'>{file?.paidBy}</TextCustom>
              </Wrapper>
            </Wrapper>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 0.3rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Total amount:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                <TextCustom wordWrap='break-word'>
                  {file?.totalAmount || '0'} €
                </TextCustom>
              </Wrapper>
            </Wrapper>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 2rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Tax amount:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                <TextCustom wordWrap='break-word'>
                  {file?.taxeAmount || '0'} €
                </TextCustom>
              </Wrapper>
            </Wrapper>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 0.3rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Created at:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                <TextCustom wordWrap='break-word'>
                  {file?.createdAt &&
                    dateTimeHelper.formatDateTime(file.createdAt)}
                </TextCustom>
              </Wrapper>
            </Wrapper>

            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 0.3rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Last update:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                <TextCustom wordWrap='break-word'>
                  {file?.updatedAt &&
                    dateTimeHelper.formatDateTime(file.updatedAt)}
                </TextCustom>
              </Wrapper>
            </Wrapper>

            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 0.3rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Validated at:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                {file?.closingAt ? (
                  <TextCustom wordWrap='break-word'>
                    {file?.closingAt &&
                      dateTimeHelper.formatDateTime(file.closingAt)}
                  </TextCustom>
                ) : (
                  <TextCustom wordWrap='break-word'>
                    Not validated yet
                  </TextCustom>
                )}
              </Wrapper>
            </Wrapper>

            <Divider hidden />
          </Wrapper>
        )}
      </Segment>
      {file?.signatureEndS3Path && !file?.validated && hasValidationPermission && (
        <Wrapper display='block' margin='0'>
          <Button
            type='button'
            size='large'
            icon='check'
            labelPosition='right'
            color='blue'
            content='Validate'
            margin='5px'
            onClick={() => {
              validate('no')
            }}
          />
        </Wrapper>
      )}
      {!file?.validated &&
        hasValidationPermission &&
        (file.Operator_Id === user.id || user.admin) && (
          <Button
            type='button'
            size='large'
            icon='unlock'
            labelPosition='right'
            color='blue'
            content='Unlock'
            margin='5px'
            onClick={unlock}
          />
      )}
      {!file?.validated &&
        hasValidationPermission &&
        (file.Operator_Id === null || user.admin) && (
          <Button
            type='button'
            size='large'
            icon='lock'
            labelPosition='right'
            color='blue'
            content='Lock'
            margin='5px'
            onClick={lock}
          />
      )}
      <Divider hidden />
      {file?.validated && (file.Operator_Id === user.id || user.admin) && (<>
        <Button
            type='button'
            size='large'
            icon='plus'
            labelPosition='right'
            color='blue'
            content='Generate control pdf'
            margin='5px'
            onClick={() => genPdf(file?.CustomsDeclaration_Id)}
        />
        <Button
            type='button'
            size='large'
            icon='plus'
            labelPosition='right'
            color='red'
            content='Open Custom Clerk'
            margin='5px'
            onClick={() => validate('yes')}
        />
        </>)}
       <Divider hidden />
       {(!createdPdf?.issues?.nature && isLoading && <Loader />) || 
       (file?.validated && createdPdf && (
          <Segment width='60%' margin='auto'>
          <Wrapper margin='0.3rem 2rem' textAlign='left'>
            <Wrapper
              display='flex'
              justifyContent='space-between'
              margin='0 0 2rem 0'
            >
              <TextCustom fontWeight='bold' margin='0 2rem 0 0'>
                Generated pdf file:
              </TextCustom>
              <Wrapper maxWidth='80%'>
                  <TextCustom fontWeight='bold' margin='0'><p>{createdPdf?.issues?.nature} attestation</p></TextCustom>
                  <TextCustom fontWeight='normal' margin='0'>
                    {createdPdf?.customClerkFile?.updatedAt && <p>{dateTimeHelper.formatDateTime(createdPdf?.customClerkFile?.updatedAt)}</p>}
                    </TextCustom>            
                  <Button
                    type='button'
                    size='large'
                    icon='download'
                    labelPosition='right'
                    color='blue'
                    content='Download PDF'
                    margin='5px'
                    onClick={() => handleDownloadPdf(file?.CustomsDeclaration?.id, createdPdf?.reqFileSession?.genId)}
                  />  
              </Wrapper>
          </Wrapper>
        </Wrapper>
        </Segment>
       ))
        }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    file: selectors.customsClerk.oneFileSelector(state),
    createdPdf: selectors.customsClerk.createClerckControlPdfSelector(state),
    loading: selectors.customsClerk.loadingSelector(state),
    company: selectors.company.companySelector(state),
    user: selectors.auth.userSelector(state),
    operatorData: selectors.operators.oneOperatorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(NewSummaryStep);
